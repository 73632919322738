import { Typography, Card } from 'antd';
import { useState, useEffect } from 'react';
import { RightOutlined, CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { RWebShare } from 'react-web-share';
import { useSelector } from 'react-redux';
import { notificationGibobs } from '../../../HerramientasVenta/reusableItems';
import { useNavigate } from 'react-router-dom';
import { ReduxStore } from '../DocumentationCore';
const { Text } = Typography;
const iconCourse = require('../../../../assets/onboarding/iconCourse.png');
const backgroundMortgage = require('../../../../assets/cardsMkt/Programa.png');
const backgroundAmigobs = require('../../../../assets/cardsMkt/Amigobs.png');
const backgroundCalculator = require('../../../../assets/cardsMkt/Calculadora.png');
const backgroundChoose = require('../../../../assets/cardsMkt/Tipo_Hipoteca.png');
const backgroundSign = require('../../../../assets/cardsMkt/Antes_Firmar.png');

const linkStyle = {
	display: 'flex',
	alignItems: 'center',
	fontSize: 14,
	fontWeight: 500,
	color: '#0CB18C',
	justifyContent: 'flex-end'
};

interface Props {
	type: string;
	viability?: boolean;
}

const CardsMkt = ({ type, viability = false }: Props) => {
	const { t } = useTranslation();
	const dataProfile = useSelector<ReduxStore, any>((state) => state.data.dataProfile);
	const [mortgage, setMorgage] = useState<string>('fixed');
	const mortgageValues = ['fixed', 'mixed', 'variable'];
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const navigate = useNavigate();
	const temp = `Si estás pensando en comprarte una casa o cambiarte de hipoteca porque tu cuota no para de subir, gibobs puede ayudarte. 💪🏡\n1. Es 100% gratuito para ti por sus acuerdos con los bancos\n2. Te buscarán la mejor hipoteca entre todas las opciones de mercado\n3. Tendrás un analista personal que te acompañará en todo el proceso\nYo lo he usado y estoy muy contento. Deja que analicen tu caso y vean si pueden ayudarte también a tí. 🎉\n`;

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % mortgageValues.length);
		}, 20000);

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	useEffect(() => {
		setMorgage(mortgageValues[currentIndex]);
	}, [currentIndex]);

	const downloadApp = () => {
		const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
		if (isIOS) {
			window.open('https://apps.apple.com/es/app/gibobs/id1582157732', '_blank');
		} else {
			window.open('https://play.google.com/store/apps/details?id=com.gibobs.mobile', '_blank');
		}
	};

	const shareLink = () => {
		const shareData = {
			text: temp,
			url: dataProfile?.friendLink,
			title: t('amigobs.shareLink')
		};
		if (navigator.share) {
			navigator
				.share(shareData)
				.then(() => console.log('Enlace compartido'))
				.catch((error) => console.error('Error al compartir enlace', error));
		} else {
			const aux = document.createElement('input');
			aux.setAttribute(
				'value',
				`https://www.gibobs.com/plan-amigobs/?friend=${dataProfile.friendName}`
			);
			document.body.appendChild(aux);
			aux.select();
			document.execCommand('copy');
			document.body.removeChild(aux);
			notificationGibobs('success', t('form.codeCopiedSuccessfully'));
		}
	};

	const handleClick = (type: string) => {
		if (isMobile) {
			if (type === 'mortgageProgram') {
				downloadApp();
			} else if (type === 'amigobsPlain') {
				shareLink();
			} else if (type === 'quoteCalculator') {
				navigate('/calculators/fee');
			}else if (type === 'quoteCalculatorAllDenied') {
				navigate('/calculators/financing');
			}
		}
	};

	const cardStyle = (background: any) => {
		return {
			border: 'none',
			borderRadius: 8,
			backgroundImage: `url("${background}")`,
			backgroundSize: 'cover',
			backgroundPosition: 'center',
			minHeight: 161,
			height: '100%',
			opacity: 0.8,
			minWidth: 320
			
		};
	};

	const contentCardStyle = (type: string) => {
		return {
			fontSize: 14,
			fontWeight: 400,
			color: '#2F4858',
			marginBottom: type === 'chooseMortgage' ? 8 : 14
		};
	};

	if (type === 'mortgageProgram') {
		return (
			<Card
				className="cardMkt"
				style={cardStyle(backgroundMortgage)}
				onClick={() => handleClick('mortgageProgram')}>
				<Text style={{ fontSize: 20, fontWeight: 600, color: '#0CB18C' }}>
					{viability ? t('cards.mortgageProgram2') : t('cards.mortgageProgram')}
				</Text>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						flexWrap: 'wrap',
						marginTop: 16
					}}>
					<div style={{ display: 'flex', minWidth: 300, maxWidth: '70%' }}>
						<img
							src={iconCourse}
							alt="Icono curso"
							width={16}
							height={16}
							style={{ marginTop: 3, marginRight: 8 }}
						/>
						<Text style={contentCardStyle('mortgageProgram')}>
							{viability ? t('cards.course2') : t('cards.course')}
						</Text>
					</div>
					{!isMobile && (
						<div style={{ position: 'absolute', bottom: 16, right: 16 }}>
							<a
								target="_blank"
								style={linkStyle}
								href="https://play.google.com/store/apps/details?id=com.gibobs.mobile">
								{t('cards.android')} <RightOutlined />
							</a>
							<a
								target="_blank"
								style={linkStyle}
								href="https://apps.apple.com/es/app/gibobs/id1582157732">
								{t('cards.ios')} <RightOutlined />
							</a>
						</div>
					)}
				</div>
			</Card>
		);
	} else if (type === 'amigobsPlain') {
		return (
			<Card
				className="cardMkt"
				style={cardStyle(backgroundAmigobs)}
				onClick={() => handleClick('amigobsPlain')}>
				<Text style={{ fontSize: 20, fontWeight: 600 }}>
					<span style={{ color: '#0CB18C' }}> {t('cards.earn500')} </span>
					{isMobile ? <br /> : ' '}
					<span style={{ color: '#2F4858' }}> {t('cards.amigobs')} </span>
				</Text>
				<div style={{ marginTop: 16 }}>
					<div style={{ minWidth: 300, maxWidth: '70%' }}>
						<div style={contentCardStyle('amigobsPlain')}>
							<CheckOutlined />
							&nbsp;
							<span style={{ marginTop: -4 }}> {t('cards.code')} </span>
						</div>
						<div style={contentCardStyle('amigobsPlain')}>
							<CheckOutlined />
							&nbsp;
							<span style={{ marginTop: -4 }}> {t('cards.sign')} </span>
						</div>
						<div style={contentCardStyle('amigobsPlain')}>
							<CheckOutlined />
							&nbsp;
							<span style={{ marginTop: -4 }}> {t('cards.free')} </span>
						</div>
					</div>
					<div style={{ position: 'absolute', bottom: 16, right: 16 }}>
						<RWebShare
							data={{
								text: temp,
								url: dataProfile?.friendLink,
								title: t('amigobs.shareLink')
							}}>
							<a style={linkStyle}>
								{' '}
								{t('modalFriend.shareLink')} <RightOutlined />{' '}
							</a>
						</RWebShare>
						{!isMobile && (
							<a
								target="_blank"
								href="https://www.gibobs.com/plan-amigobs-terminos-y-condiciones/"
								style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>
								{t('cards.info')}
							</a>
						)}
					</div>
				</div>
			</Card>
		);
	} else if (type === 'quoteCalculator') {
		return (
			<Card
				className="cardMkt"
				style={cardStyle(backgroundCalculator)}
				onClick={() => handleClick('quoteCalculator')}>
				<Text style={{ fontSize: 20, fontWeight: 600 }}>
					<span style={{ color: '#2F4858' }}> {t('cards.calculator')} </span>
					<span style={{ color: '#0CB18C' }}> {t('cards.quote')} </span>
				</Text>
				<div style={{ marginTop: 16, minWidth: 300, maxWidth: '70%' }}>
					<div style={contentCardStyle('quoteCalculator')}>
						<CheckOutlined />
						&nbsp;
						<span style={{ marginTop: -4 }}>
							{' '}
							{t('cards.savings')} {t('cards.discover')}
						</span>
					</div>
					{!isMobile && (
						<div style={{ position: 'absolute', bottom: 16, right: 16 }}>
							<a onClick={() => navigate('/calculators/fee')} style={linkStyle}>
								{' '}
								{t('cards.linkCalculator')} <RightOutlined />{' '}
							</a>
						</div>
					)}
				</div>
			</Card>
		);
	} else if (type === 'quoteCalculatorAllDenied') {
		return (
			<Card
				className="cardMkt"
				style={cardStyle(backgroundCalculator)}
				
				onClick={() => handleClick('quoteCalculatorAllDenied')}>
				<Text style={{ fontSize: 20, fontWeight: 600 }}>
					<span style={{ color: '#2F4858' }}> {t('cards.calculator')} </span>
					<span style={{ color: '#0CB18C' }}> {t('cards.all')} </span>
				</Text>
				<div style={{ marginTop: 16, minWidth: 300, maxWidth: '70%' }}>
					<div style={contentCardStyle('quoteCalculatorAllDenied')}>
						<CheckOutlined />
						&nbsp;
						<span style={{ marginTop: -4 }}> {t('cards.descriptionAllDenied')} </span>
					</div>
					{!isMobile && (
						<div style={{ position: 'absolute', bottom: 16, right: 16 }}>
							<a onClick={() => navigate('/calculators/financing')} style={linkStyle}>
								{' '}
								{t('goTo.CalculatorAll')} <RightOutlined />{' '}
							</a>
						</div>
					)}
				</div>
			</Card>
		);
	} else if (type === 'noOffers') {
		return (

            <Card
				className="cardMkt"
				style={cardStyle(backgroundMortgage)}
				onClick={() => handleClick('mortgageProgram')}>
				<Text style={{ fontSize: 20, fontWeight: 600 }}>
					<span style={{ color: '#0CB18C' }}> {t('card.noOffersKnow')} </span>
				</Text>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						flexWrap: 'wrap',
						marginTop: 16
					}}>
					<div style={{ display: 'flex', minWidth: 300, maxWidth: '70%' }}>
						<img
							src={iconCourse}
							alt="Icono curso"
							width={16}
							height={16}
							style={{ marginTop: 3, marginRight: 8 }}
						/>
						<Text style={contentCardStyle('noOffers')}>
							{t('card.noOffersKnow1')}
						</Text>
					</div>
					{!isMobile && (
						<div style={{ position: 'absolute', bottom: 16, right: 16 }}>
							<a
								target="_blank"
								style={linkStyle}
								href="https://play.google.com/store/apps/details?id=com.gibobs.mobile">
								{t('cards.android')} <RightOutlined />
							</a>
							<a
								target="_blank"
								style={linkStyle}
								href="https://apps.apple.com/es/app/gibobs/id1582157732">
								{t('cards.ios')} <RightOutlined />
							</a>
						</div>
					)}
				</div>
			</Card>


			
		);
	} else if (type === 'chooseMortgage') {
		return (
			<Card className="cardMkt" style={cardStyle(backgroundChoose)}>
				{viability ? (
					<Text style={{ fontSize: 20, fontWeight: 600, color: '#2F4858' }}>
						<span>{t('cards.howToChoose')}</span>&nbsp;
						<span
							style={{
								color:
									mortgage === 'fixed' ? '#0CB18C' : mortgage === 'mixed' ? '#E5B937' : '#037FB8'
							}}>
							{t(`cards.${mortgage}`)}
						</span>
						<span>...</span>
					</Text>
				) : (
					<Text style={{ fontSize: 20, fontWeight: 600 }}>
						<span style={{ color: '#2F4858' }}>{t('cards.chooseMortgage')} </span>
						<span
							style={{
								color:
									mortgage === 'fixed' ? '#0CB18C' : mortgage === 'mixed' ? '#E5B937' : '#037FB8'
							}}>
							{t(`cards.${mortgage}`)}
						</span>
						<span style={{ color: '#2F4858' }}>?</span>
					</Text>
				)}
				<div style={{ marginTop: 13 }}>
					<div style={contentCardStyle('chooseMortgage')}>
						<CheckOutlined /> &nbsp;
						<span style={{ marginTop: -4 }}>
							{viability && mortgage !== 'variable'
								? t(`cards.advantage${mortgage}3`)
								: t(`cards.advantage${mortgage}1`)}
						</span>
					</div>
					<div style={contentCardStyle('chooseMortgage')}>
						<CheckOutlined /> &nbsp;
						<span style={{ marginTop: -4 }}>
							{viability && mortgage === 'mixed'
								? t('cards.advantagemixed4')
								: t(`cards.advantage${mortgage}2`)}
						</span>
					</div>
				</div>
				<div style={{ height: 8 }}></div>
				<div
					style={{
						display: 'flex',
						gap: 8,
						position: 'absolute',
						bottom: 16,
						left: '50%',
						transform: 'translateX(-50%)'
					}}>
					<div
						style={{
							width: 8,
							height: 8,
							borderRadius: 50,
							backgroundColor: mortgage === 'fixed' ? '#2F4858' : '#C0DAEE'
						}}></div>
					<div
						style={{
							width: 8,
							height: 8,
							borderRadius: 50,
							backgroundColor: mortgage === 'mixed' ? '#2F4858' : '#C0DAEE'
						}}></div>
					<div
						style={{
							width: 8,
							height: 8,
							borderRadius: 50,
							backgroundColor: mortgage === 'variable' ? '#2F4858' : '#C0DAEE'
						}}></div>
				</div>
			</Card>
		);
	} else if (type === 'beforeSign') {
		return (
			<Card className="cardMkt" style={cardStyle(backgroundSign)}>
				<Text style={{ fontSize: 20, fontWeight: 600, color: '#2F4858' }}>
					{t('cards.beforeSign')}
				</Text>
				<div style={{ marginTop: 10 }}>
					<div style={{ display: 'flex', alignItems: 'start' }}>
						<CheckOutlined /> &nbsp; <span style={{ marginTop: -4 }}>{t('cards.appraisal')}</span>
					</div>
					<div style={{ display: 'flex', alignItems: 'start', marginTop: 8 }}>
						<CheckOutlined /> &nbsp; <span style={{ marginTop: -4 }}>{t('cards.days')}</span>
					</div>
				</div>
			</Card>
		);
	}
};
export default CardsMkt;
