import React, { useState } from 'react';
import { Card, Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import withoutImage from '../../../assets/promoter/photo.png';
import editIcon from '../../../assets/promoter/EditIcon.svg';
import numeral from 'numeral';
import moment from 'moment';
import ModalSaveProject from '../SimulationSummary/ModalSaveProject';
import { useDispatch } from 'react-redux';
import { getListMyProyects, getPromoterData } from '../../../store/promoter/actions';

const { Text } = Typography;

function DetailProductPromoter({
	projectName,
	municipality,
	price,
	date,
	stage,
	index,
	image,
	proyects,
	getDataOperations,
	projectData,
	operationStatus,
	status
}) {
	const navigate = useNavigate();;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [modalSaveProject, setModalSaveProject] = useState(false);

	const detailsProject = (title, data, color) => {
		return (
			<div style={{ display: 'flex', marginTop: 6 }}>
				<div className="paragraph-project-data">{t(title)}: </div>
				<div
					className="paragraph-project-data"
					style={{ fontWeight: 600, marginLeft: 4, color: color }}>
					{data === undefined ? '-' : data}
				</div>
			</div>
		);
	};

	const nameStages = {
		simulation: t('m_viability.simulationTitle'),
		analysis: t('document.analystics'),
		documentation: t('document.documentation'),
		oferts: t('m_offers.title'),
		formalization: t('formalisation.formalisation'),
		finish: t('stages.closed'),
		SALE: t('promoterLoan.saleTitle'),
		RESIDENTIAL: t('promoterLoan.residentialTitle'),
		OTHER: t('promoterLoan.otherTitle'),
		SINGLE_FAMILY: t('promoterLoan.singleFamily'),
		MULTI_FAMILY: t('promoterLoan.multiFamily'),
		undefined: '-'
	};

	return (
		<Card
			className="gb-card"
			style={{
				height: '100%',
				borderRadius: 4
			}}
			cover={
				<>
					<div style={{ overflow: 'hidden', width: 'auto', height: isMobileOnly ? 64 : 120 }}>
						<img
							src={image !== undefined ? image : withoutImage}
							alt={`${image}-${index}`}
							style={{
								height: '100%',
								width: '100%',
								borderLeft:
									operationStatus && operationStatus.status === 'finish'
										? '6px solid #748EA0'
										: operationStatus && operationStatus.operationStatus === 'postpone'
											? '6px solid #FBC14F'
											: operationStatus &&
											operationStatus.operationStatus === 'active' &&
											'6px solid #02C3CD'
							}}
						/>
					</div>
				</>
			}>
			<img
				src={editIcon}
				alt="editIcon"
				onClick={() => setModalSaveProject(true)}
				style={{
					position: 'absolute',
					top: !isMobileOnly ? 8 : 13,
					right: 8,
					cursor: 'pointer'
				}}
			/>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<Text className="title-page">{projectName}</Text>
				<Text className="paragraph-style">{municipality}</Text>
				{detailsProject('promoterLoan.amountFinancing', numeral(price).format('0,0 $'), '#02C3CD')}
				{detailsProject('mydocuments.createDate', moment(date).format('DD/MM/YYYY'))}
				{detailsProject('m_amigobs.stage', nameStages[stage])}
				<Button
					type="primary"
					className="button-primari-gibobs"
					disabled={status === 'finish' ? true : false}
					style={{ marginTop: 16 }}
					onClick={() => {
						if (stage === 'simulation') {
							navigate(`/promoter-loan/step-form/${index}`);
						} else {
							navigate(`/promoter-loan/${stage}/${index}`);
						}
						dispatch(getPromoterData(proyects));
					}}>
					{t('promoterLoan.seeDetails')}
				</Button>
			</div>
			<ModalSaveProject
				key={modalSaveProject}
				visible={modalSaveProject}
				onCreate={() => {
					setModalSaveProject(false);
					getDataOperations();
					dispatch(getListMyProyects());
				}}
				onCancel={() => setModalSaveProject(false)}
				width={!isMobileOnly && 690}
				initialValuesData={
					projectData && projectData.projectData
						? { ...projectData['projectData'], operationId: index }
						: {
							province:
								projectData.generalData &&
								projectData.generalData.province &&
								projectData.generalData.province,
							operationId: index
						}
				}
			/>
		</Card>
	);
}

export default DetailProductPromoter;
