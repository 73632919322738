import React, { useEffect, useState } from 'react';
import { Layout, Card, Typography, Button, Form, InputNumber, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import env from '../../../../environment';
import { InputDate } from 'afrodita';
import chebronBlack from '../../../../assets/loans/chebronBlack.png';

import dayjs from 'dayjs';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');
dayjs.locale('vi');

const { Text } = Typography;

const LoanAmortizeMobileManual = ({}) => {
	const { t } = useTranslation();
	let { loanId } = useParams();
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [loan, setLoan] = useState(undefined);
	const userToken = useSelector((state) => state.auth.token);

	useEffect(() => {
		getLoan();
	}, []);

	const getLoan = () => {
		axios
			.get(`${env.api.url}/v1/loans/get-loan/${loanId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				// setDataValues(response.data.data);
				setLoan(response.data.data.loan);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const onChangeAmount = (value) => {
		axios
			.post(
				`${env.api.url}/v1/loans/update/${loan.id}`,
				{
					amount: value,
					version: '2023.v1'
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then(() => {
				getLoan();
			})
			.catch((error) => {
        console.log(error)
      });
	};
	const onChangeDeb = (value) => {
		axios
			.post(
				`${env.api.url}/v1/loans/update/${loan.id}`,
				{
					finish: dayjs(value, 'DD-MM-YYYY').format('YYYY-MM-DD'),
					version: '2023.v1'
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then(() => {
				getLoan();
			})
	};

	const handlePreviusPage = () => {
		navigate(`/my-financial-profile/credits/all-detail-loan/${loanId}`);
	};

	return (
		<Layout style={{ padding: 16 }}>
			<div>
				<Row style={{ width: '100%' }}>
					<Col span={24}>
						<a onClick={() => handlePreviusPage()}>
							<img src={chebronBlack} alt="as" style={{ width: 24, height: 24, marginTop: -4 }} />
						</a>
						<Text
							style={{
								color: '#2F4858',
								fontSize: 16,
								fontWeight: 600,
								marginLeft: 12
							}}>
							{t('loan.enterDataDetp')}
						</Text>
					</Col>
				</Row>
				{loan && (
					<Form
						form={form}
						layout="vertical"
						className="gb-form-profile-headlines-mobile"
						name="loan"
						initialValues={{
							debt: loan && loan.amount,
							quotePending: loan && dayjs(loan.finish).format('DD-MM-YYYY')
						}}
						style={{ marginTop: 24 }}>
						<Form.Item
							name="debt"
							label={t('loan.debt')}
							rules={[
								{
									required: true
								}
							]}
							style={{
								width: '100%'
							}}>
							<InputNumber
								onChange={(value) => onChangeAmount(value)}
								style={{
									width: '100%'
								}}
								placeholder={'0 €'}
							/>
						</Form.Item>

						<Form.Item
							name="quotePending"
							label={t('loan.dateFinish')}
							rules={[
								{
									required: true
								}
							]}
							style={{
								width: '100%'
							}}>
							<InputDate
								type="date"
								onChange={(value) => onChangeDeb(value)}
								style={{
									width: '100%',
									borderRadius: '4px',
									borderColor: '#C0DAEE'
								}}
							/>
						</Form.Item>

						<Card className="cardButtonStyle" style={{ left: 0 }}>
							<Button
								className=".button-primari-gibobs-mobile"
								onClick={() => handlePreviusPage()}
								type="primary"
								style={{ width: '100%', height: 48 }}>
								{t('form.save')}
							</Button>
						</Card>
					</Form>
				)}
			</div>
		</Layout>
	);
};

export default LoanAmortizeMobileManual;
