import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Layout, Typography, Card, Row, Col, Button, Modal, Form, Input } from 'antd';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined, GiftOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import env from '../../environment';
import { RWebShare } from 'react-web-share';
import PlainFriendDataMobile from './PlainFriendDataMobile';

const { Content } = Layout;
const { Text } = Typography;

const PlainFriendMobile = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { t } = useTranslation();
	const [valuesData, setValuesData] = useState([]);
	const userToken = useSelector((state) => state.auth.token);
	const [dataPersonal, setDataPersonal] = useState([]);
	const [form] = Form.useForm();

	const [visibleCode, setVisibleCode] = useState(false);

	useEffect(() => {
		getInfoFriend();
		getProfile();
	}, []);

	const getProfile = () => {
		axios
			.get(`${env.api.url}/v1/user/profile`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setDataPersonal(response.data.data.profile);
			});
	};

	const getInfoFriend = () => {
		axios
			.get(`${env.api.url}/v1/operations/list-b2b`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setValuesData(response.data.data);

				if (response.data.data.length > 0) {
					navigate('/friendInfo');
				}
			});
	};

	const saveDataCodeLink = (values) => {
		axios
			.post(
				`${env.api.url}/v1/friends/customize-name`,
				{ friendName: values },

				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then(() => {
				getProfile();
			});
	};

	const showAmigobsDetails = () => {
		navigate('/friend/details');
	};

	const backToHome = () => {
		navigate('/friend');
	};

	const showModalLinkCode = () => {
		setVisibleCode(!visibleCode);
	};

	return (
		<>
			{(location.pathname === '/friendInfo' ||
				location.pathname === '/friendInfo/operations-details' ||
				location.pathname === '/friendInfo/details/info') && ( //Cambiar condicion
				<PlainFriendDataMobile valuesData={valuesData}></PlainFriendDataMobile>
			)}
			{location.pathname === '/friend' && valuesData && valuesData.length === 0 && (
				<>
					<div className="gb-backgroud-friend-mobile">
						<Content style={{ padding: 16,paddingTop: 30 }}>
							<div>
								<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>
									{t('header.plain')}
								</Text>
								<a onClick={() => showAmigobsDetails()}>
									<InfoCircleOutlined style={{ color: '#02C3CD', fontSize: 20, marginLeft: 10 }} />
								</a>
							</div>

							{dataPersonal && (
								<Card
									className="card-second"
									style={{
										background: '#EFFCFC',
										borderRadius: 4,
										border: '1px solid #C0F0F3',
										marginTop: 30
									}}>
									<Row gutter={16}>
										<Col lg={19} xs={19}>
											<Text style={{ fontSize: 14, fontWeight: 500, color: '#2F4858' }}>
												{t('amigobs.code')}
											</Text>
											&nbsp;
											<Text style={{ fontSize: 14, fontWeight: 400, color: '#748EA0' }}>
												{dataPersonal.friendName}
											</Text>
										</Col>
										<Col lg={5} xs={5} style={{ width: '100%' }}>
											<a onClick={() => showModalLinkCode()}>
												{' '}
												<Text style={{ fontSize: 14, fontWeight: 500, color: '#02C3CD' }}>
													{t('form.edit')}
												</Text>
											</a>
										</Col>
									</Row>
								</Card>
							)}

							<div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
								<GiftOutlined
									style={{ color: '#02C3CD', fontSize: 60, marginTop: 70, marginBottom: 21 }}
								/>
								<Text style={{ color: '#02C3CD', fontSize: 16, fontWeight: 400 }}>
									{t('plainFriend.recommend1')}{' '}
								</Text>
								<Text style={{ color: '#02C3CD', fontSize: 16, fontWeight: 600, marginBottom: 24 }}>
									{t('plainFriend.recommend2')}{' '}
								</Text>

								<Text
									style={{
										color: '#748EA0',
										fontSize: 14,
										fontWeight: 400,
										width: '100%',
										alignSelf: 'center',
										marginBottom: 200
									}}>
									{t('m_amigobs.description')}{' '}
								</Text>

								<div style={{ alignContent: 'center' }}>
									<RWebShare
										data={{
											text: t('amigobs.share1'),
											url: dataPersonal.friendLink,
											title: t('amigobs.shareLink')
										}}
										onClick={() => console.log('shared successfully!')}>
										<Button
											className="button-primari-gibobs"
											type="primary"
											style={{
												width: '92%',
												height: 48,
												fontSize: '16px',
												// top: 50,
												position: 'fixed',
												left: 16,
												bottom: 16
											}}
											// onClick={() => sharedSocialMendia()}
										>
											{t('plainFriend.inviteFriend')}
										</Button>
									</RWebShare>
								</div>
							</div>
						</Content>
					</div>
				</>
			)}
			{location.pathname === '/friend/details' && valuesData && valuesData.length === 0 && (
				<div>
					<Content style={{ padding: 16 }}>
						<div style={{ display: 'flex' }}>
							<a onClick={() => backToHome()}>
								<ArrowLeftOutlined style={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }} />{' '}
							</a>
							<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 600, marginLeft: 16 }}>
								{t('plainFriend.moreInfo')}{' '}
							</Text>
						</div>

						<div style={{ marginTop: 16, width: '100%' }}>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
								{t('plainFriend.moreInfo1')}{' '}
							</Text>
						</div>
						<div style={{ marginTop: 8, width: '100%' }}>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
								{t('plainFriend.moreInfo2')}{' '}
							</Text>
						</div>

						<div style={{ marginTop: 16, width: '100%' }}>
							<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
								{t('plainFriend.moreInfo3')}{' '}
							</Text>
						</div>

						<div style={{ marginTop: 16, width: '100%' }}>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
								{t('plainFriend.moreInfo4')}{' '}
							</Text>
						</div>

						<div style={{ marginTop: 8, width: '100%' }}>
							<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', width: '100%' }}>
								<div
									dangerouslySetInnerHTML={{
										__html: t('plainFriend.moreInfo5')
									}}
								/>
							</Text>
						</div>
					</Content>
				</div>
			)}
			<Modal
				className="modal-common-mobile"
				cancelButtonProps={{
					style: { height: 40, width: '100%' },
					className: 'button-secundary-gibobs'
				}}
				width={328}
				okButtonProps={{
					style: { height: 40, width: '100%' },
					className: 'button-primari-gibobs'
				}}
				open={visibleCode}
				title={
					<Text style={{ fontSize: 16, color: '#2F4858', fontWeight: 600 }}>
						{t('amigobs.code')}
					</Text>
				}
				okText={t('offers.accept')}
				cancelText={t('mydocuments.deleteCancel')}
				destroyOnClose={true}
				onCancel={() => {
					setVisibleCode(false);
				}}
				onOk={() => {
					form
						.validateFields()
						.then((values) => {
							form.resetFields();
							saveDataCodeLink(values.code);
							setVisibleCode(false);
						})
						.catch((info) => {
							console.log('Validate Failed:', info);
						});
				}}>
				<Text style={{ fontSize: 14, color: '#748EA0', fontWeight: 400, marginTop: -30 }}>
					{t('amigobs.codeDesc')}
				</Text>
				<Form form={form} layout="vertical">
					<Form.Item
						name="code"
						label={'https://www.gibobs.com/plan-amigobs/'}
						style={{ marginTop: 24 }}
						rules={[
							{
								validator: (e, value) => {
									return new Promise((resolve, reject) => {
										let match = [];
										if (value.match(/^[\-a-zA-Z0-9]+$/g)) {
											match = value.match(/^[\-a-zA-Z0-9]+$/g);
										}
										if (match === null) {
											reject('Formato inválido');
										}
										if (!value.match(/^[\-a-zA-Z0-9]+$/g)) {
											reject('Formato inválido');
										} else {
											resolve();
										}
									});
								}
							}
						]}>
						<Input style={{ width: '98%' }} placeholder={dataPersonal.friendName} />
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default PlainFriendMobile;
