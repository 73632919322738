import React, { useEffect, useState } from 'react';
import { Form, Select, Col, Row, Button, Typography, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { notificationGibobs } from '../../HerramientasVenta/reusableItems';
import HelpButtonMobile from './HelpButtonMobile';
import StepPromoterMobile from './StepPromoterMobile';
import { useSelector, useDispatch } from 'react-redux';
import { getPromoterData, getScenariosData } from '../../../store/promoter/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { ReduxStore } from './welcomePageMobile';
import axios from 'axios';
import env from '../../../environment';
import { InputNumber } from 'afrodita';
import numeral from 'numeral';
const DownOutlinedMobile = require('../../../assets/icons/DownOutlinedMobile.svg');
const { Text } = Typography;
const { Option } = Select;

export const FormCapitalStructure = (form) => {
	const { t } = useTranslation();
	const [typeOfUnit, setTypeOfUnit] = useState('TOTAL_EUROS');
	const [showFinancingRequired, setShowFinancingRequired] = useState(0);
	const [financingRequired, setFinancingRequired] = useState(0);
	const [financingBuild, setFinancingBuild] = useState(0);
	const [financingUnit, setFinancingUnit] = useState(0);
	const [financingPeriod, setFinancingPeriod] = useState(0);
	const dataPromoter = useSelector<ReduxStore, any>((state) => state.promoter.dataFormPromoter);
	const [totalCosts, setTotalCosts] = useState(0);
	const [unit, setUnit] = useState(0);
	const [m2t, setM2t] = useState(0);

	useEffect(() => {
		setUnit(
			dataPromoter && dataPromoter.generalData ? Number(dataPromoter.generalData.salesUnits) : 0
		);
		setM2t(
			dataPromoter && dataPromoter.generalData ? Number(dataPromoter.generalData.buildability) : 0
		);
		setTotalCosts(
			dataPromoter && dataPromoter.totalCosts
				? Number(dataPromoter.totalCosts)
				: dataPromoter.economicData && dataPromoter.economicData.projectCosts.totalCosts
				? Number(dataPromoter.economicData.projectCosts.totalCosts)
				: 0
		);
		setFinancingPeriod(
			dataPromoter && dataPromoter.capitalStructure && dataPromoter.capitalStructure.financingPeriod
				? Number(dataPromoter.capitalStructure.financingPeriod)
				: 0
		)
		setFinancingRequired(
			dataPromoter && dataPromoter.capitalStructure && dataPromoter.capitalStructure.equity
				? Number(dataPromoter.capitalStructure.equity)
				: 0
		);
		setTypeOfUnit(
			dataPromoter && dataPromoter.typeOfUnit ? dataPromoter.typeOfUnit : 'TOTAL_EUROS'
		);
	}, [dataPromoter]);

	useEffect(() => {
		form.setFieldsValue({
			financingRequired: totalCosts - financingRequired,
			financingBuild: (totalCosts / m2t - financingBuild).toFixed(2),
			financingUnit: (totalCosts / unit - financingUnit).toFixed(2)
		});
		setShowFinancingRequired(totalCosts - financingRequired);
	}, [totalCosts, , financingBuild, financingUnit]);

	useEffect(() => {
		form.setFieldsValue({
			ownFundsBuild: Number(
				Number(
					dataPromoter && dataPromoter.capitalStructure && dataPromoter.capitalStructure.equity
						? dataPromoter.capitalStructure.equity / m2t
						: 0
				).toFixed(2)
			)
		});
	}, [m2t]);

	useEffect(() => {
		form.setFieldsValue({
			ownFundsUnit: Number(
				Number(
					dataPromoter && dataPromoter.capitalStructure && dataPromoter.capitalStructure.equity
						? dataPromoter.capitalStructure.equity / unit
						: 0
				).toFixed(2)
			)
		});
	}, [unit]);

	const calculateWithEuro = (calculate, value) => {
		setFinancingRequired(value);
		setFinancingBuild(value / m2t);
		setFinancingUnit(value / unit);

		form.setFieldsValue({
			[calculate[0]]: (value / m2t).toFixed(2),
			[calculate[1]]: (value / unit).toFixed(2)
		});
	};

	const calculateWithBuild = (calculate, value) => {
		const num = Number(value) * m2t;
		setFinancingRequired(num);
		setFinancingBuild(value);
		setFinancingUnit(num / unit);

		form.setFieldsValue({
			[calculate[0]]: num.toFixed(2),
			[calculate[1]]: (num / unit).toFixed(2)
		});
	};
	const calculateWithUnit = (calculate, value) => {
		const num = Number(value) * unit;
		setFinancingRequired(num);
		setFinancingBuild(num / m2t);
		setFinancingUnit(value);

		form.setFieldsValue({
			[calculate[0]]: num.toFixed(2),
			[calculate[1]]: (num / m2t).toFixed(2)
		});
	};

	const inputForm = (name, label, placeholder, rule, calculate) => {
		return (
			<Form.Item
				name={name}
				label={t(label)}
				rules={[
					{
						required: typeOfUnit === rule ? true : false
					}
				]}>
				<InputNumber
					gbType="number"
					suffix={placeholder}
					defaultValue={''}
					disabled={typeOfUnit !== rule ? true : false}
					placeholder={'0'}
					style={{ borderRadius: 8, height: 40 }}
					onChange={(e) => {
						if (e !== '') {
							rule === 'TOTAL_EUROS'
								? calculateWithEuro(calculate, Number(e))
								: rule === 'EUROS_BUILDABILITY'
								? calculateWithBuild(calculate, Number(e))
								: rule === 'EUROS_UNIT' && calculateWithUnit(calculate, Number(e));
						}
					}}
				/>
			</Form.Item>
		);
	};

	const onChange = (value: string) => {
		setTypeOfUnit(value);
	};

	return (
		<Row gutter={8}>
			<Col span={24}>
				<Row gutter={16} style={{ marginBottom: 16 }}>
					<Col span={8}>
						<Button
							className={
								typeOfUnit === 'TOTAL_EUROS' ? 'button-gibobs-promoter2' : 'button-gibobs-promoter'
							}
							onClick={() => onChange('TOTAL_EUROS')}>
							{t('€ total')}
						</Button>
					</Col>
					<Col span={8}>
						<Button
							className={
								typeOfUnit === 'EUROS_BUILDABILITY'
									? 'button-gibobs-promoter2'
									: 'button-gibobs-promoter'
							}
							onClick={() => onChange('EUROS_BUILDABILITY')}>
							{t('€/m²t')}
						</Button>
					</Col>
					<Col span={8}>
						<Button
							className={
								typeOfUnit === 'EUROS_UNIT' ? 'button-gibobs-promoter2' : 'button-gibobs-promoter'
							}
							onClick={() => onChange('EUROS_UNIT')}
							value={'EUROS_UNIT'}>
							{t('€/ud.')}
						</Button>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				{inputForm('equity', 'promoterLoan.ownFunds', '€', 'TOTAL_EUROS', [
					'ownFundsBuild',
					'ownFundsUnit'
				])}
			</Col>
			<Col span={12}>
				{inputForm('ownFundsBuild', '', '€/m²t', 'EUROS_BUILDABILITY', ['equity', 'ownFundsUnit'])}
			</Col>
			<Col span={12}>
				{inputForm('ownFundsUnit', '', '€/ud.', 'EUROS_UNIT', ['equity', 'ownFundsBuild'])}
			</Col>
			<Col span={24} style={{ display: 'none' }}>
				{inputForm('financingRequired', 'promoterLoan.financingRequired', '000.0000', false, false)}
				{inputForm('financingBuild', '', '€/m²t', false, false)}
				{inputForm('financingUnit', '', '€/ud.', false, false)}
			</Col>
			<Col span={24}>
				<Form.Item
					name="financingPeriod"
					label={t('promoterLoan.financingTerm')}
					rules={[
						{
							required: true
						}
					]}>
					<Select
						suffixIcon={<img src={DownOutlinedMobile.default} alt="DownOutlinedMobile" />}
						defaultValue={t('form.select')}
						style={{ width: '100%' }}
						onChange={(e) => setFinancingPeriod(Number(e))}>
						<Option value={6}>6 {t('viability.months')}</Option>
						<Option value={12}>12 {t('viability.months')}</Option>
						<Option value={18}>18 {t('viability.months')}</Option>
						<Option value={24}>24 {t('viability.months')}</Option>
						<Option value={30}>30 {t('viability.months')}</Option>
						<Option value={36}>36 {t('viability.months')}</Option>
					</Select>
				</Form.Item>
			</Col>
			<Col
				span={24}
				style={{
					border: '1px solid #02C3CD',
					padding: '16px 24px',
					borderRadius: 4,
					textAlign: 'center'
				}}>
				<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858 ' }}>
					{t('promoter.need')}
				</Text>
				<Divider style={{ borderColor: '#748EA0', margin: 8 }} />
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<Text style={{ fontSize: 20, fontWeight: 600, color: '#02C3CD' }}>
						{showFinancingRequired ? numeral(showFinancingRequired).format('0,0') : '-'} €
					</Text>
					&nbsp;
					<Text style={{ fontSize: 14, fontWeight: 500, color: '#02C3CD' }}>
						{t('promoter.months', {
							months: financingPeriod ? financingPeriod : '-'
						})}
					</Text>
				</div>
				<Text style={{ fontSize: 10, fontWeight: 500, color: '#748EA0' }}>
					{t('promoterLoan.totalCosts')}: {totalCosts ? numeral(totalCosts).format('0,0') : '-'} € -
					&nbsp;
					{t('promoterLoan.ownFunds')}:{' '}
					{financingRequired ? numeral(financingRequired).format('0,0') : '-'} €
				</Text>
			</Col>
		</Row>
	);
};

const CapitalStructureFormMobile = () => {
	const { t } = useTranslation();
	const params = useParams<{ operationId: string }>();
	const operationId = params.operationId;
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userToken = useSelector<ReduxStore, string>((state) => state.auth.token);
	const dataPromoter = useSelector<ReduxStore, any>((state) => state.promoter.dataFormPromoter);
	const [loading, setLoading] = useState(false);

	const openNotificationWithIcon = () => {
		notificationGibobs('error', t('m_full.errorTitle'), t('form.incompleteInformation'));
	};

	const validateMessages = {
		required: '${label}' + ' ' + t('form.formRequired'),
		types: {
			email: t('form.emailValidated'),
			number: '${label} is not a valid number!'
		},
		number: {
			range: t('form.maxBudgetNewRequestValidated') + '${min}'
		}
	};

	const editScenarios = (values) => {
		/*axios
			.delete(
				`${env.api.url}/promoters/financing-scenarios/remove/${operationId}`,

				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					createScenarios(values);
				}
			})
			.catch((error) => {});*/

		axios
			.post(
				`${env.api.url}/promoters/simulate-scenarios/update/${operationId}?scenarioOwner=CLIENT`,
				values,
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					setLoading(false);
					dispatch(getScenariosData(response.data.data));
					dispatch(getPromoterData({ ...values }));

					navigate(`/promoter-loan/analysis/${operationId}`);
				}
			});
	};

	const createScenarios = (values) => {
		axios
			.post(
				`${env.api.url}/promoters/simulate-scenarios/create/${operationId}`,
				{},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					dispatch(getScenariosData(response.data.data));
					dispatch(getPromoterData(values));
					setLoading(false);
					navigate(`/promoter-loan/analysis/${operationId}`);
				}
			})
			.catch((error) => {
				editScenarios(values);
			});
	};

	const capitalStructureDataVerificated = (values) => {
		setLoading(true);
		const obj = {
			equity: Number(values.equity),
			financingRequired: Number(values.financingRequired),
			financingPeriod: Number(values.financingPeriod)
		};

		axios
			.post(`${env.api.url}/promoters/promoter-projects/capital-structure/${operationId}`, obj, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				if (response.data.success) {
					setLoading(false);
					createScenarios(response.data.data.data);
				}
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			});
	};

	return (
		<div style={{ padding: 16 }}>
			<StepPromoterMobile stage={3} steps={3} />
			<div
				style={{
					marginTop: 24,
					marginBottom: 24,
					fontSize: 14,
					color: '#748EA0',
					fontWeight: 400
				}}>
				{t('promoterLoan.capitalStructureDescription')}&nbsp;
				{t('promoter.desiredUnit')}
			</div>
			<Form
				form={form}
				className="form-promoter-mobile"
				style={{ minHeight: '90vh' }}
				onFinishFailed={openNotificationWithIcon}
				scrollToFirstError={true}
				onFinish={capitalStructureDataVerificated}
				layout="vertical"
				name="Password"
				validateMessages={validateMessages}
				initialValues={dataPromoter ? dataPromoter.capitalStructure : {}}>
				{FormCapitalStructure(form)}
				<div
					style={{
						marginTop: 24,
						marginBottom: 24,
						fontSize: 14,
						color: '#748EA0',
						fontWeight: 400,
						width: '100%',
						textAlign: 'center',
						padding: '0px 24px'
					}}>
					{t('promoter.scenarios')}
					<br />
					{t('promoter.letsDoIt')}
				</div>
				<div
					style={{
						position: 'fixed',
						width: '100%',
						height: 80,
						bottom: 0,
						left: 0,
						boxShadow: '0px -2px 8px 0px rgba(47, 72, 88, 0.10)',
						background: '#fff',
						zIndex: 999,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: 16
					}}>
					<Row gutter={8} style={{ width: '100%' }}>
						<Col span={12}>
							<Button
								type="dashed"
								className="button-secundary-gibobs"
								style={{
									width: '100%',
									height: 48,
									borderRadius: 8
								}}
								onClick={() => navigate(`/promoter-loan/financial-data/${operationId}`)}>
								{t('form.previous')}
							</Button>
						</Col>
						<Col span={12}>
							<Button
								htmlType="submit"
								type="primary"
								style={{
									width: '100%',
									height: 48,
									borderRadius: 8
								}}
								loading={loading}>
								{t('init.simulate')}
							</Button>
						</Col>
					</Row>
				</div>
			</Form>
			<HelpButtonMobile origin={'IN_FORM_THREE_STEPS'} simulation={undefined} />
		</div>
	);
};

export default CapitalStructureFormMobile;
