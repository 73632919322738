import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card, Typography, Button } from "antd";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

function RequestComponent({ title, parrafo, icon, url, button, eco }) {
  const { t } = useTranslation();
  const navigate = useNavigate();;

  return (
    <Card
      className={
        !isMobile
          ? "gibobs-widget-products-inmo"
          : "gibobs-widget-products-inmo-mobile"
      }
      style={{
        backgroundColor: title === "init.MortgageSimulator" && "#EFFCFC",
        boxShadow: title === "init.MortgageSimulator" && "none",
        border: title === "init.MortgageSimulator" && "1px solid #C0F0F3",
        cursor: title === "init.MortgageSimulator" && "pointer",
      }}
      onClick={() => isMobile && navigate(url)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: !isMobile && 18,
        }}
      >
        <div
          style={{
            fontSize: "16px",
            color: "#02C3CD",
            // alignSelf: "center",
            marginRight: 12,
            width: isMobile ? 24 : 24,
            height: isMobile ? 24 : 24,
          }}
        >
          {icon}
        </div>
        <Text
          style={{
            fontSize: "16px",
            color: title === "init.MortgageSimulator" ? "#02AFB8" : "#2F4858",
            fontWeight: 400,
            lineHeight: "24px",
          }}
        >
          {t(title)}
        </Text>
      </div>
      {!isMobile && (
        <div>
          <div>
            <Text className="description-cards">{t(parrafo)}</Text>
          </div>

          <NavLink
            to={`${url}`}
            style={{
              position: "absolute",
              bottom: 72,
            }}
          >
            <Button
              className={
                eco ? "button-primary-gibobs-eco" : "button-primary-gibobs-inmo"
              }
              type="primary"
              style={{
                height: 40,
                width: 260,
                position: "absolute",
                border: "none",
              }}
            >
              {t(button)}
            </Button>
          </NavLink>
        </div>
      )}
    </Card>
  );
}

export default RequestComponent;
