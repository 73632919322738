import axios from "axios";
import env from "../../environment";
import {
  DOCUMENTS,
  BREADCRUMB,
  FOLDER,
  LOADING,
  VIRTUAL
} from "./constants";
import { store } from '..';



export const loadFolder = (parentId) => async (dispatch) => {
  dispatch(getMyDocuments(parentId));
};



export const getMyDocuments = (parentId) => async (dispatch) => {
  dispatch({
    type: LOADING,
    payload: true,
  });

  if (!parentId) {
    parentId = ''
  } else {
    parentId = `/${parentId}`;
  }

  axios.get(`${env.api.url}/v1/documents/my-documents${parentId}`, {
    headers: { Authorization: `Bearer ${store.getState().auth.token}` },
  }).then((response) => {
    if (response.data.success) {
      const documents = response.data.data;

      dispatch({
        type: DOCUMENTS,
        payload: documents.files,
      });

      dispatch({
        type: VIRTUAL,
        payload: documents.virtual,
      });

      dispatch({
        type: FOLDER,
        payload: documents.folder,
      });

      dispatch({
        type: BREADCRUMB,
        payload: (documents.folder.breadcrumbs) ? documents.folder.breadcrumbs : [],
      });

      dispatch({
        type: LOADING,
        payload: false,
      });
    } else {
      dispatch({
        type: LOADING,
        payload: false,
      });
    }
  }).catch((error) => {
    dispatch({
      type: LOADING,
      payload: false,
    });
  });

  return "LIST";
};




export const createFolder = (name, parentId) => async (dispatch) => {
  dispatch({
    type: LOADING,
    payload: true,
  });


  axios.post(`${env.api.url}/v1/documents/my-documents/create-folder`, {
    parentId,
    name
  }, {
    headers: { Authorization: `Bearer ${store.getState().auth.token}` },
  }).then((response) => {
    if (response.data.success) {
      dispatch(loadFolder(parentId));
    } else {
      dispatch({
        type: LOADING,
        payload: false,
      });
    }
  }).catch((error) => {
    dispatch({
      type: LOADING,
      payload: false,
    });
  });

  return "CREATEFOLDER";
};




export const documentRename = (documentId, name, parentId) => async (dispatch) => {
  dispatch({
    type: LOADING,
    payload: true,
  });


  axios.post(`${env.api.url}/v1/documents/my-documents/rename`, {
    documentAliasId: documentId,
    name
  }, {
    headers: { Authorization: `Bearer ${store.getState().auth.token}` },
  }).then((response) => {
    if (response.data.success) {
      dispatch(loadFolder(parentId));
    } else {
      dispatch({
        type: LOADING,
        payload: false,
      });
    }
  }).catch((error) => {
    dispatch({
      type: LOADING,
      payload: false,
    });
  });

  return "DOCUMENTRENAME";
};




export const uploadFile = (name, file, parentId) => async (dispatch) => {
  dispatch({
    type: LOADING,
    payload: true,
  });


  axios.post(`${env.api.url}/v1/documents/my-documents/upload`, {
    folderId: parentId,
    fileName: name,
    file,
  }, {
    headers: { Authorization: `Bearer ${store.getState().auth.token}` },
  }).then((response) => {
    if (response.data.success) {
      dispatch(loadFolder(parentId));
    } else {
      dispatch({
        type: LOADING,
        payload: false,
      });
    }
  }).catch((error) => {
    dispatch({
      type: LOADING,
      payload: false,
    });
  });

  return "CREATEFOLDER";
};



export const removeDocument = (documentId, parentId) => async (dispatch) => {

  return new Promise((resolve, reject) => {

    let temp = "";


    dispatch({
      type: LOADING,
      payload: true,
    });

    axios.post(`${env.api.url}/v1/documents/my-documents/delete`, {
      documentAliasId: documentId
    }, {
      headers: { Authorization: `Bearer ${store.getState().auth.token}` },
    }).then((response) => {
      if (response.data.success) {
        dispatch(loadFolder(parentId));
      } else {
        temp = response.data.success;
        dispatch({
          type: LOADING,
          payload: false,
        });
      }

      resolve(["DOCUMENTRENAME", temp])
    }).catch((error) => {
      reject(error)
      dispatch({
        type: LOADING,
        payload: false,
      });
    });


   
  })
};