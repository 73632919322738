import LoanPageFormDesktop from './LoansDesktop/LoanPageFormDesktop';

const LoanSwitchMobileOrDesktopForm = ({}) => {
	return (
		<>
			<LoanPageFormDesktop />
		</>
	);
};

export default LoanSwitchMobileOrDesktopForm;
