import React from "react";
import { Typography } from "antd";
import { ClockCircleOutlined, HomeOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

function StressLevel({
  stressTestNormal,
  stressTestSellRealestate,
  stressTestNormalMonth,
  stressTestSellRealestateMonth,
}) {
  const { t } = useTranslation();
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ marginBottom: "16px" }}>
          <Text
            strong
            style={{
              color: "#2F4858",
              fontSize: "14px",
            }}
          >
            {t("scoring.stressTest")}
          </Text>
        </div>
        <div
          style={{
            marginBottom: "16px",
            width: "250px",
            marginRight: "40px",
          }}
        >
          <Text
            style={{
              color: "#748EA0",
              fontSize: "12px",
            }}
          >
            {t("balance.descriptionStressTest")}
          </Text>
        </div>
      </div>

      <div style={{ display: "flex", marginTop: "20px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <ClockCircleOutlined
              style={{
                color: "#02C3CD",
                fontSize: "28px",
                marginRight: "8px",
                alignSelf: "center",
              }}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text
                style={{
                  fontSize: "12px",
                  textAlign: "left",
                  color: "#748EA0",
                }}
              >
                {t("balance.normalStressTest")}
              </Text>
              <Text
                strong
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#2F4858",
                  marginRight: "4px",
                }}
              >
                {stressTestNormal} {t("viability.years")}  {t("viability.and")} {stressTestNormalMonth} {t("viability.months")}
              </Text>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              marginTop: "20px",
            }}
          >
            <HomeOutlined
              style={{
                color: "#02C3CD",
                fontSize: "28px",
                marginRight: "8px",
                alignSelf: "center",
              }}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text
                style={{
                  fontSize: "12px",
                  textAlign: "left",
                  color: "#748EA0",
                }}
              >
                {t("balance.sellRealeStateStressTest")}
              </Text>
              <Text
                strong
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#2F4858",
                  marginRight: "4px",
                }}
              >
                {stressTestSellRealestate}  {t("viability.years")} {t("viability.and")}{" "}
                {stressTestSellRealestateMonth}  {t("viability.months")}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StressLevel;
