import React from 'react';
import { Col, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import { isMobileOnly } from 'react-device-detect';


const TotalDifference = ({ visible, onCancel, dataOperation, dataModals }) => {
	const type = dataOperation.interest;
	const numQuotesRest = dataModals?.savingsByRest?.section?.to - dataModals?.savingsByRest?.section?.from + 1;
	const yearPendings = +dataOperation.dateEndMortgage.substring(0, 4) - new Date().getFullYear();
	const { t } = useTranslation();
	const detailOptions = (name, quantity, color, condition) => {
		return (
			<Row
				style={{
					display: 'flex',
					justifyContent: name !== '' && 'space-between',
					marginBottom: 6,
					marginTop: 6
				}}>
				<div
					className="amounts-name-mortgage"
					style={{
						fontWeight: condition === 'total' && 600,
						fontSize: name === 'viabilityNew.totalDifferenceGibobs' ? 20 : 14
					}}>
					{t(name)}
					{name === 'viabilityNew.totalDifferenceGibobs' && <span> *</span>}
				</div>

				<div
					className="amounts-name-mortgage amounts-quantity"
					style={{
						fontWeight: condition === 'total' && 600,
						fontSize: name === 'viabilityNew.totalDifferenceGibobs' ? 20 : 14,
						color: color
					}}>
					{quantity}
				</div>
			</Row>
		);
	};
	const detailOptionsTotal = (name, description, quantity, color, condition) => {
		return (
			<Row
				style={{
					display: 'flex',
					marginBottom: 12,
					alignItems: 'center'
				}}>
				<Col span={15} style={{ display: 'flex', flexDirection: 'column' }}>
					<div
						style={{
							fontSize: 12,
							fontWeight: 400
						}}>
						{t(description)}
					</div>
				</Col>

				<Col
					span={9}
					style={{
						fontWeight: condition === 'total' && 600,
						fontSize: name === 'onboarding.totalDifference' && 16,
						color: color,
						textAlign: 'right'
					}}>
					{quantity}
				</Col>
			</Row>
		);
	};

	return (
		<Modal
			centered
			zIndex={1200}
			className={
				isMobileOnly
					? 'gb-modal-32-padding ModalCommonSmallDelete modal-onboarding-amount'
					: 'ModalCommon ModalCommonSmallDelete modal-onboarding-amount'
			}
			open={visible}
			title={
				dataModals?.title === 'viabilityNew.typeFixed'
					? t('viabilityNew.fixedSavings')
					: dataModals.title === 'viabilityNew.typeMixed'
						? t('viabilityNew.mixedSavings')
						: t('viabilityNew.variableSavings')
			}
			width={600}
			onCancel={() => {
				onCancel();
			}}>
			{/* TIPO FIJO */}
			{dataModals?.savingsBySection?.length === 0 &&
				(type === 'fixed' || type === 'variable') &&
				dataModals?.title === 'viabilityNew.typeFixed' && (
					<>
						<div style={{ fontSize: 16, fontWeight: 400, color: '#748EA0' }}>
							{t('fee.inQuotas')}
						</div>
						{detailOptions(
							'viabilityNew.estimatedCurrentQuota',
							numeral(dataModals?.savingsByRest?.quoteCompared).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
							'#748EA0'
						)}
						{detailOptions(
							'viabilityNew.quotaGibobs',
							numeral(dataModals?.savingsByRest?.section?.monthlyPayment).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
							'#02C3CD'
						)}
						<div style={{ border: '1px solid #C0DAEE', marginBottom: 5 }}></div>
						{detailOptionsTotal(
							'onboarding.differenceInQuota',
							`(${numeral(dataModals?.savingsByRest?.quoteCompared).format('0,0.0 $')} 
							- ${numeral(dataModals?.savingsByRest?.section?.monthlyPayment).format('0,0.0 $')}) 
							x ${numQuotesRest} ${t('onboarding.quotas').toLowerCase()}`,
							numeral(dataModals?.savingsByRest?.totalSavingQuote).format('0,0.00 $'),
							dataModals?.savingsByRest?.totalSavingQuote < 0
								? '#FF4D4F'
								: '#52C41A',
							'total'
						)}
					</>
				)}
			{dataModals?.savingsBySection?.length === 1 &&
				type === 'mixed' &&
				dataModals?.title === 'viabilityNew.typeFixed' && (
					<>
						<div style={{ fontSize: 16, fontWeight: 400, color: '#748EA0' }}>
							{t('viabilityNew.inQuotasNextYears',
								{ years: Math.trunc((dataModals?.savingsBySection[0]?.section?.to - dataModals?.savingsBySection[0]?.section?.from+1) / 12) }
							)}
						</div>
						{detailOptions(
							'viabilityNew.estimatedCurrentQuota',
							numeral(dataModals?.savingsBySection[0]?.quoteCompared).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
							'#748EA0'
						)}
						{detailOptions(
							'viabilityNew.quotaGibobs',
							numeral(dataModals?.savingsBySection[0]?.section?.monthlyPayment).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
							'#02C3CD'
						)}
						<div style={{ border: '1px solid #C0DAEE', marginBottom: 5 }}></div>
						{detailOptionsTotal(
							'onboarding.differenceInQuota',
							`(${numeral(dataModals?.savingsBySection[0]?.quoteCompared).format('0,0.0 $')} 
							- ${numeral(dataModals?.savingsBySection[0]?.section?.monthlyPayment).format('0,0.0 $')}) 
							x ${(dataModals?.savingsBySection[0]?.section?.to - dataModals?.savingsBySection[0]?.section?.from + 1)} ${t('onboarding.quotas').toLowerCase()}`,
							numeral(dataModals?.savingsBySection[0]?.totalSavingQuote).format('0,0.00 $'),
							dataModals?.savingsBySection[0]?.totalSavingQuote > 0
								? '#52C41A'
								: '#FF4D4F',
							'total'
						)}
						<div style={{ fontSize: 16, fontWeight: 400, color: '#748EA0', marginTop: 26 }}>
							{t('viabilityNew.inQuotasRestYears')} 
						</div>
						{detailOptions(
							'viabilityNew.estimatedCurrentQuota',
							numeral(dataModals?.savingsByRest?.quoteCompared).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
							'#748EA0'
						)}
						{detailOptions(
							'viabilityNew.quotaGibobs',
							numeral(dataModals?.savingsByRest?.section?.monthlyPayment).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
							'#02C3CD'
						)}
						<div style={{ border: '1px solid #C0DAEE', marginBottom: 5 }}></div>
						{detailOptionsTotal(
							'onboarding.differenceInQuota',
							`(${numeral(dataModals?.savingsByRest?.quoteCompared).format('0,0.0 $')} 
							- ${numeral(dataModals?.savingsByRest?.section?.monthlyPayment).format('0,0.0 $')}) 
							x ${numQuotesRest} ${t('onboarding.quotas').toLowerCase()}`,
							numeral(dataModals?.savingsByRest?.totalSavingQuote).format('0,0.00 $'),
							dataModals?.savingsByRest?.totalSavingQuote > 0
								? '#52C41A'
								: '#FF4D4F',
							'total'
						)}
					</>
				)}
			{dataModals?.savingsBySection?.length === 0 &&
				type === 'mixed' &&
				dataModals?.title === 'viabilityNew.typeFixed' && (
					<>
						<div style={{ fontSize: 16, fontWeight: 400, color: '#748EA0' }}>
							{t('fee.inQuotas')}
						</div>
						{detailOptions(
							'viabilityNew.estimatedCurrentQuota',
							numeral(dataModals?.savingsByRest?.quoteCompared).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
							'#748EA0'
						)}
						{detailOptions(
							'viabilityNew.quotaGibobs',
							numeral(dataModals?.savingsByRest?.section?.monthlyPayment).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
							'#02C3CD'
						)}
						<div style={{ border: '1px solid #C0DAEE', marginBottom: 5 }}></div>
						{detailOptionsTotal(
							'onboarding.differenceInQuota',
							`(${numeral(dataModals?.savingsByRest?.quoteCompared).format('0,0.0 $')} 
							- ${numeral(dataModals?.savingsByRest?.section?.monthlyPayment).format('0,0.0 $')}) 
							x ${numQuotesRest} ${t('onboarding.quotas').toLowerCase()}`,
							numeral(dataModals?.savingsByRest?.totalSavingQuote).format('0,0.00 $'),
							dataModals?.savingsByRest?.totalSavingQuote > 0
								? '#52C41A'
								: '#FF4D4F',
							'total'
						)}
					</>
				)}
			{/* TIPO MIXTO Y VARIABLE */}
			{dataModals?.savingsBySection?.length === 1 &&
				(type === 'fixed' || type === 'variable') &&
				(dataModals?.title === 'viabilityNew.typeSpread' || dataModals?.title === 'viabilityNew.typeMixed') && (
					<>
						<div style={{ fontSize: 16, fontWeight: 400, color: '#748EA0' }}>
							{dataModals?.title === 'viabilityNew.typeSpread'
								? t('viabilityNew.inQuotasFirstYear')
								: t('viabilityNew.inQuotasFirstYears',
									{ years: (dataModals?.savingsBySection[0]?.section?.to - dataModals?.savingsBySection[0]?.section?.from + 1) / 12 }
								)
							}
						</div>
						{detailOptions(
							'viabilityNew.estimatedCurrentQuota',
							numeral(dataModals?.savingsBySection[0]?.quoteCompared).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
							'#748EA0'
						)}
						{detailOptions(
							'viabilityNew.quotaGibobs',
							numeral(dataModals?.savingsBySection[0]?.section?.monthlyPayment).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
							'#02C3CD'
						)}
						<div style={{ border: '1px solid #C0DAEE', marginBottom: 5 }}></div>
						{detailOptionsTotal(
							'onboarding.differenceInQuota',
							`(${numeral(dataModals?.savingsBySection[0]?.quoteCompared).format('0,0.0 $')} 
							- ${numeral(dataModals?.savingsBySection[0]?.section?.monthlyPayment).format('0,0.0 $')}) 
							x ${dataModals?.savingsBySection[0]?.section?.to - dataModals?.savingsBySection[0]?.section?.from + 1} ${t('onboarding.quotas').toLowerCase()}`,
							numeral(dataModals?.savingsBySection[0]?.totalSavingQuote).format('0,0.00 $'),
							dataModals?.savingsBySection[0]?.totalSavingQuote > 0
								? '#52C41A'
								: '#FF4D4F',
							'total'
						)}

						<div style={{ marginTop: 26 }}>
							<div style={{ fontSize: 16, fontWeight: 400, color: '#748EA0' }}>
								{t('viabilityNew.inQuotasRestYears')}
							</div>
							{detailOptions(
								'viabilityNew.estimatedCurrentQuota',
								numeral(dataModals?.savingsByRest?.quoteCompared).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
								'#748EA0'
							)}
							{detailOptions(
								'viabilityNew.quotaGibobs',
								numeral(dataModals?.savingsByRest?.section?.monthlyPayment).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
								'#02C3CD'
							)}
							<div style={{ border: '1px solid #C0DAEE', marginBottom: 5 }}></div>
							{detailOptionsTotal(
								'onboarding.differenceInQuota',
								`(${numeral(dataModals?.savingsByRest?.quoteCompared).format('0,0.0 $')} 
								- ${numeral(dataModals?.savingsByRest?.section?.monthlyPayment).format('0,0.0 $')}) 
								x ${dataModals?.savingsByRest?.section?.to - dataModals?.savingsByRest?.section?.from + 1} ${t('onboarding.quotas').toLowerCase()}`,
								numeral(dataModals?.savingsByRest?.totalSavingQuote).format('0,0.00 $'),
								dataModals?.savingsByRest?.totalSavingQuote > 0
									? '#52C41A'
									: '#FF4D4F',
								'total'
							)}
						</div>
					</>
				)}
			{dataModals?.savingsBySection?.length === 2 &&
				type === 'mixed' &&
				(dataModals?.title === 'viabilityNew.typeSpread' || dataModals?.title === 'viabilityNew.typeMixed') && (
					<>
						<div style={{ fontSize: 16, fontWeight: 400, color: '#748EA0' }}>
							{dataModals?.title === 'viabilityNew.typeSpread'
								? t('viabilityNew.inQuotasFirstYear')
								: t('viabilityNew.inQuotasFirstYears',
									{ years: Math.trunc((dataModals?.savingsBySection[0]?.section?.to - dataModals?.savingsBySection[0]?.section?.from + 1) / 12) }
								)
							}
						</div>
						{detailOptions(
							'viabilityNew.estimatedCurrentQuota',
							numeral(dataModals?.savingsBySection[0]?.quoteCompared).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
							'#748EA0'
						)}
						{detailOptions(
							'viabilityNew.quotaGibobs',
							numeral(dataModals?.savingsBySection[0]?.section?.monthlyPayment).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
							'#02C3CD'
						)}
						<div style={{ border: '1px solid #C0DAEE', marginBottom: 5 }}></div>
						{detailOptionsTotal(
							'onboarding.differenceInQuota',
							`(${numeral(dataModals?.savingsBySection[0]?.quoteCompared).format('0,0.0 $')} 
							- ${numeral(dataModals?.savingsBySection[0]?.section?.monthlyPayment).format('0,0.0 $')}) 
							x ${dataModals?.savingsBySection[0]?.section?.to - dataModals?.savingsBySection[0]?.section?.from + 1} ${t('onboarding.quotas').toLowerCase()}`,
							numeral(dataModals?.savingsBySection[0]?.totalSavingQuote).format('0,0.00 $'),
							dataModals?.savingsBySection[0]?.totalSavingQuote > 0
								? '#52C41A'
								: '#FF4D4F',
							'total'
						)}
						
						<div style={{ marginTop: 26 }}>
							<div style={{ fontSize: 16, fontWeight: 400, color: '#748EA0' }}>
								{ dataModals?.title === 'viabilityNew.typeMixed' 
									? t('viabilityNew.inQuotasBetweenYears', {
										year1: Math.trunc((dataModals?.savingsBySection[1]?.section.from)/12),
										year2: Math.trunc((dataModals?.savingsBySection[1]?.section.to+1)/12) })
									: t('viabilityNew.inQuotasBetweenYears', {
										year1: 1,
										year2: Math.trunc((dataModals?.savingsBySection[1]?.section.to+1)/12) })								}
							</div>
							{detailOptions(
								'viabilityNew.estimatedCurrentQuota',
								numeral(dataModals?.savingsBySection[1]?.quoteCompared).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
								'#748EA0'
							)}
							{detailOptions(
								'viabilityNew.quotaGibobs',
								numeral(dataModals?.savingsBySection[1]?.section?.monthlyPayment).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
								'#02C3CD'
							)}
							<div style={{ border: '1px solid #C0DAEE', marginBottom: 5 }}></div>
							{detailOptionsTotal(
								'onboarding.differenceInQuota',
								`(${numeral(dataModals?.savingsBySection[1]?.quoteCompared).format('0,0.0 $')} 
								- ${numeral(dataModals?.savingsBySection[1]?.section?.monthlyPayment).format('0,0.0 $')}) 
								x ${dataModals?.savingsBySection[1]?.section?.to - dataModals?.savingsBySection[1]?.section?.from + 1} ${t('onboarding.quotas').toLowerCase()}`,
								numeral(dataModals?.savingsBySection[1]?.totalSavingQuote).format('0,0.00 $'),
								dataModals?.savingsBySection[1]?.totalSavingQuote > 0
									? '#52C41A'
									: '#FF4D4F',
								'total'
							)}
						</div>

						<div style={{ marginTop: 26 }}>
							<div style={{ fontSize: 16, fontWeight: 400, color: '#748EA0' }}>
								{t('viabilityNew.inQuotasRestYears')}
							</div>
							{detailOptions(
								'viabilityNew.estimatedCurrentQuota',
								numeral(dataModals?.savingsByRest?.quoteCompared).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
								'#748EA0'
							)}
							{detailOptions(
								'viabilityNew.quotaGibobs',
								numeral(dataModals?.savingsByRest?.section?.monthlyPayment).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
								'#02C3CD'
							)}
							<div style={{ border: '1px solid #C0DAEE', marginBottom: 5 }}></div>
							{detailOptionsTotal(
								'onboarding.differenceInQuota',
								`(${numeral(dataModals?.savingsByRest?.quoteCompared).format('0,0.0 $')} 
								- ${numeral(dataModals?.savingsByRest?.section?.monthlyPayment).format('0,0.0 $')}) 
								x ${dataModals?.savingsByRest?.section?.to - dataModals?.savingsByRest?.section?.from + 1} ${t('onboarding.quotas').toLowerCase()}`,
								numeral(dataModals?.savingsByRest?.totalSavingQuote).format('0,0.00 $'),
								dataModals?.savingsByRest?.totalSavingQuote > 0
									? '#52C41A'
									: '#FF4D4F',
								'total'
							)}
						</div>
					</>
				)}
			{dataModals?.savingsBySection?.length === 1 &&
				type === 'mixed' &&
				(dataModals?.title === 'viabilityNew.typeSpread' || dataModals?.title === 'viabilityNew.typeMixed') && (
					<>
						<div style={{ fontSize: 16, fontWeight: 400, color: '#748EA0' }}>
							{dataModals?.title === 'viabilityNew.typeSpread'
								? t('viabilityNew.inQuotasFirstYear')
								: t('viabilityNew.inQuotasFirstYears',
									{ years: Math.trunc((dataModals?.savingsBySection[0]?.section?.to - dataModals?.savingsBySection[0]?.section?.from + 1) / 12) }
								)
							}
						</div>
						{detailOptions(
							'viabilityNew.estimatedCurrentQuota',
							numeral(dataModals?.savingsBySection[0]?.quoteCompared).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
							'#748EA0'
						)}
						{detailOptions(
							'viabilityNew.quotaGibobs',
							numeral(dataModals?.savingsBySection[0]?.section?.monthlyPayment).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
							'#02C3CD'
						)}
						<div style={{ border: '1px solid #C0DAEE', marginBottom: 5 }}></div>
						{detailOptionsTotal(
							'onboarding.differenceInQuota',
							`(${numeral(dataModals?.savingsBySection[0]?.quoteCompared).format('0,0.0 $')} 
							- ${numeral(dataModals?.savingsBySection[0]?.section?.monthlyPayment).format('0,0.0 $')}) 
							x ${dataModals?.savingsBySection[0]?.section?.to - dataModals?.savingsBySection[0]?.section?.from + 1} ${t('onboarding.quotas').toLowerCase()}`,
							numeral(dataModals?.savingsBySection[0]?.totalSavingQuote).format('0,0.00 $'),
							dataModals?.savingsBySection[0]?.totalSavingQuote > 0
								? '#52C41A'
								: '#FF4D4F',
							'total'
						)}
						
						<div style={{ marginTop: 26 }}>
							<div style={{ fontSize: 16, fontWeight: 400, color: '#748EA0' }}>
								{t('viabilityNew.inQuotasRestYears')}
							</div>
							{detailOptions(
								'viabilityNew.estimatedCurrentQuota',
								numeral(dataModals?.savingsByRest?.quoteCompared).format('0,0.00 $') +  '/' + t('viability.monthsEstimated'),
								'#748EA0'
							)}
							{detailOptions(
								'viabilityNew.quotaGibobs',
								numeral(dataModals?.savingsByRest?.section?.monthlyPayment).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
								'#02C3CD'
							)}
							<div style={{ border: '1px solid #C0DAEE', marginBottom: 5 }}></div>
							{detailOptionsTotal(
								'onboarding.differenceInQuota',
								`(${numeral(dataModals?.savingsByRest?.quoteCompared).format('0,0.0 $')} 
								- ${numeral(dataModals?.savingsByRest?.section?.monthlyPayment).format('0,0.0 $')}) 
								x ${dataModals?.savingsByRest?.section?.to - dataModals?.savingsByRest?.section?.from + 1} ${t('onboarding.quotas').toLowerCase()}`,
								numeral(dataModals?.savingsByRest?.totalSavingQuote).format('0,0.00 $'),
								dataModals?.savingsByRest?.totalSavingQuote > 0
									? '#52C41A'
									: '#FF4D4F',
								'total'
							)}
						</div>
					</>
				)}

			{/* EN VINCULACIONES*/}
			<div style={{ marginTop: 26 }}>
				<div style={{ fontSize: 16, fontWeight: 400, color: '#748EA0' }}>
					{t('viabilityNew.inConnections')}
				</div>
				{detailOptions(
					'viabilityNew.currentAnnualCost',
					numeral(dataOperation?.costOtherProducts ? dataOperation.costOtherProducts : 0).format('0,0.00 $') + '/' + t('common.year'),
					'#748EA0'
				)}
				{dataModals?.costLinkagesGibobs &&
					detailOptions(
						'viabilityNew.gibobsAnnualCost',
						numeral(dataModals?.costLinkagesGibobs).format('0,0.00 $') + '/' + t('common.year'),
						'#02C3CD'
					)}
				<div style={{ border: '1px solid #C0DAEE', marginBottom: 5 }}></div>
				{dataModals?.costLinkagesGibobs &&
					detailOptionsTotal(
						'onboarding.differenceInLinkages',
						`(${numeral(dataOperation && dataOperation.costOtherProducts ? dataOperation.costOtherProducts : 0).format('0,0.00 $')} - ${numeral(
							dataModals?.costLinkagesGibobs 
						).format('0,0.00 $')}) x ${yearPendings ? yearPendings : 0} ${t('onboarding.yearsPending')}`,
						numeral(dataModals?.savingsCostsLinkages).format('0,0.00 $'),
						dataModals?.savingsCostsLinkages < 0
							? '#FF4D4F'
							: '#52C41A',
						'total'
					)}

				{/* AHORRAS EN TOTAL */}
				<div style={{ marginTop: 24 }}>
					{detailOptions(
						'viabilityNew.totalDifferenceGibobs',
						numeral(dataModals?.savings).format('0,0.00 $'),
						dataModals?.savings < 0 ? '#FF4D4F' : '#52C41A',
						'total'
					)}
				</div>
				<div className="description-viability" style={{ fontSize: 12 }}>
					{t('viabilityNew.excludingCancellation')}
				</div>
			</div>
		</Modal>
	);
};

export default TotalDifference;
