import React from "react";

import { IconProps } from "../../interfaces";
import { IconList } from "./list";

export default function Icon({ name, data, onClose, onClick }: IconProps) {
  const { path, viewBox = "0 0 24 24", color, e } = IconList[name];

  const handleOnButtonClicked = () => {
    const text = JSON.parse(data.text)
    onClick && onClick(name); // callback
    e(encodeURIComponent(data.url), encodeURIComponent(text[name]), data.title);
    onClose();
  };

  return (
    <>
      {name === 'instagram' || name === 'twitter' ? (
        <div 
          onClick={handleOnButtonClicked}
          aria-label={name}
          style={{ 
            background: color, 
            borderRadius: 8, 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            cursor: 'pointer' }}>
          <img 
            src={path} 
            style={{ width: name === 'twitter' ? '50%' : '60%', height: name === 'twitter' ? '50%' : '60%' }}/>
        </div>
      ) : (
        <button
          onClick={handleOnButtonClicked}
          aria-label={name}
          className="rws-icon"
          style={{ background: color }}
        >
          <svg fill="white" viewBox={viewBox}>
            {path}
          </svg>
        </button>
      )}
    </>
  );
}
