import React, { useEffect, useState } from 'react';
import { Row, Button, Card, Col, Typography, Form, Select, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { renderComponent } from '../../../select/typeSelect';
import axios from 'axios';
import env from '../../../environment';
import _ from 'lodash';
import { notificationGibobs } from '../../HerramientasVenta/reusableItems';
import { InputNumber } from 'afrodita';

const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const CalculatorsFeeDatasMobileAndDesktop = ({}) => {
	const location = useLocation();
	const { t } = useTranslation();
	const rol = useSelector((state) => state.auth.roles);
	const navigate = useNavigate();;
	const [form] = Form.useForm();
	const [countries, setContries] = useState();
	const [, setHouseType] = useState(undefined);
	const [participants, setParticipants] = useState(location?.state?.participants);
	const lang = localStorage.getItem('i18nextLng');
	const userToken = useSelector((state) => state.auth.token);
	const [, setResult] = useState(undefined);

	useEffect(() => {
		axios.get(`${env.api.url}/v1/countries`).then((response) => {
			setContries(response.data.data);
		});

		//getvaluesForm(location.state.id)
	}, []);

	const getvaluesForm = (id) => {
		axios
			.get(`${env.api.url}/mortgages/simulations/get-simulation/${id}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setParticipants(response.data.data.simulationData.owner2 !== undefined ? '2' : '1');
				setResult(response.data.data);

				
				navigate(`/calculators/fee`,{
					state: {
						result: response.data.data,
						formValues: form.getFieldsValue()
					}
				});
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const sendValues = (values) => {
		//enviar a back

		let owners = [];

		if (participants === '2') {
			owners = [
				{
					activity: values.activity1,
					seniority: values.seniority1,
					netIncomes: values.netIncomes1,
					loanPayment: values.loanPayment1,
					age: values.age1,
					cityzenship: values.cityzenship1
				},
				{
					activity: values.activity2,
					seniority: values.seniority2,
					netIncomes: values.netIncomes2,
					loanPayment: values.loanPayment2,
					age: values.age2,
					cityzenship: values.cityzenship2
				}
			];
		} else {
			owners = [
				{
					activity: values.activity1,
					seniority: values.seniority1,
					netIncomes: values.netIncomes1,
					loanPayment: values.loanPayment1,
					age: values.age1,
					cityzenship: values.cityzenship1
				}
			];
		}

		axios
			.post(
				`${env.api.url}/mortgages/simulations/quota-and-viability/mortgage-viability`,
				{
					id: !_.isNil(location.state) ? location.state.id : undefined,
					simulationType: rol === 'realestate' ? 'B2B' : 'B2C',
					maxBudget: _.isNil(location.state.values.price)
						? location.state.maxBudget
						: location.state.values.price,
					savings: _.isNil(location.state.values.savings)
						? location.state.savings
						: location.state.values.savings,
					propertyType: _.isNil(location.state.values.propertyType)
						? location.state.propertyType
						: location.state.values.propertyType,
					province: _.isNil(location.state.values.province)
						? location.state.province
						: location.state.values.province,
					fixed: location.state.fixed,
					quotes: location.state.quotes,

					participants: {
						houseType: values.houseType,
						participants: parseInt(values.participants),
						owners: owners
					}
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				getvaluesForm(response.data.data.simulation.id);
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const participantsTabs = [];
	const participantsTabsDesktop = [];

	const renderTabs = (index) => {
		return (
			<TabPane
				tab={<span>{t(`onboarding.owner${index}`)}</span>}
				key={`${index}`}
				forceRender={true}>
				{renderForm(index)}
			</TabPane>
		);
	};

	const renderTabsDesktop = (index) => {
		return (
			<TabPane
				tab={<span>{t(`onboarding.owner${index}`)}</span>}
				key={`${index}`}
				forceRender={true}>
				{renderFormDesktop(index)}
			</TabPane>
		);
	};

	const renderFormDesktop = (data) => {
		return (
			<>
				<Row gutter={[60, 10]} style={{ width: '100%' }}>
					<Col span={6}>
						<Form.Item
							rules={[
								{
									required: true
								}
							]}
							label={
								<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
									{t('form.activity')}
								</Text>
							}
							name={`activity${data}`}
							style={{ width: '100%' }}>
							<Select id={`activity${data}`} placeholder={t('form.selected')}>
								{renderComponent('activity2').options.map((situation) => {
									return (
										<Option key={situation.value} value={situation.value}>
											{t(situation.label)}
										</Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>

					<Col span={6}>
						<Form.Item
							rules={[
								{
									required: true
								}
							]}
							label={
								<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
									{t('form.seniority')}
								</Text>
							}
							name={`seniority${data}`}>
							<InputNumber
								defaultValue={''}
								max={40}
								gbType={'number'}
								className='gb-input-new-years'
								id={`seniority${data}`}
								placeholder={t('0')}
								style={{
									width: '100%'
								}}
							/>
						</Form.Item>
					</Col>

					<Col span={6}>
						<Form.Item
							rules={[
								{
									required: true
								}
							]}
							label={
								<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
									{t('form.netIncomesOwner')}
								</Text>
							}
							name={`netIncomes${data}`}>
							<InputNumber
								defaultValue={''}
								id={`netIncomes${data}`}
								suffix={'€/' + t('viability.monthsEstimated')}
								step={100}
								type={'number'}
								placeholder={t('0')}
								style={{
									borderColor: '#c0daee',
									width: '100%',
									borderRadius: '4px'
								}}
							/>
						</Form.Item>
					</Col>

					<Col span={6}>
						<Form.Item
							label={
								<div>
									<Text
										style={{
											color: '#2F4858',
											fontSize: 12,
											fontWeight: 400,
											marginBottom: 10
										}}>
										{t('onboarding.haveOtherLoans')}
									</Text>
								</div>
							}
							rules={[
								{
									required: true
								}
							]}
							name={`loanPayment${data}`}>
							<InputNumber
								defaultValue={''}
								id={`loanPayment${data}`}
								type={'number'}
								suffix={'€/' + t('viability.monthsEstimated')}
								step={100}
								placeholder={t('0')}
								style={{
									borderColor: '#c0daee',
									width: '100%',
									borderRadius: '4px'
								}}
							/>
						</Form.Item>
						<div
							className="explination-others-incomes"
							style={{
								marginTop: 10,
								color: '#748EA0',
								fontSize: 10,
								fontWeight: 400
							}}>
							{t('form.haveLoansInmo0')}
						</div>
					</Col>

					<Col span={6} style={{ marginTop: -24 }}>
						<Form.Item
							label={
								<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
									{t('calculator.age')}
								</Text>
							}
							rules={[
								{
									required: true
								}
							]}
							name={`age${data}`}>
							<InputNumber
								defaultValue={''}
								id={`age${data}`}
								step={1}
								min={18}
								max={70}
								gbType={'number'}
								className='gb-input-new-years'
								placeholder={t('18')}
								style={{
									borderColor: '#c0daee',
									width: '100%',
									borderRadius: '4px'
								}}
							/>
						</Form.Item>
					</Col>

					<Col span={6} style={{ marginTop: -24 }}>
						<Form.Item
							label={
								<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
									{t('form.country')}
								</Text>
							}
							rules={[
								{
									required: true
								}
							]}
							name={`cityzenship${data}`}>
							<Select
								id="country"
								showSearch
								placeholder={t('form.country')}
								style={{ width: '100%' }}
								filterOption={(input, option) => {
									return (
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
										option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
									);
								}}>
								{countries &&
									countries.countries.map((paises) => {
										return (
											<Option key={paises.code} value={paises.code}>
												{lang === 'en' ? paises.name_en : paises.name_es}
											</Option>
										);
									})}
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<div style={{ textAlign: 'center', width: '100%', marginTop: 24 }}>
					<Button
						id="gb-mortgage-buy-situation-mobile"
						className="button-primary-gibobs-inmo"
						type="primary"
						style={{
							fontSize: 14,
							height: 40,
							fontWeight: 600
						}}
						htmlType="submit">
						{t('calculator.buttonCalculate')}
					</Button>
				</div>
			</>
		);
	};

	const renderForm = (data) => {
		return (
			<>
				<Row style={{ width: '100%' }}>
					<Col span={24}>
						<Form.Item
							rules={[
								{
									required: true
								}
							]}
							name={`activity${data}`}
							label={t('form.activity')}
							style={{ width: '100%' }}>
							<Select id={`activity${data}`} placeholder={t('form.activity')}>
								{renderComponent('activity2').options.map((situation) => {
									return (
										<Option key={situation.value} value={situation.value}>
											{t(situation.label)}
										</Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							rules={[
								{
									required: true
								}
							]}
							name={`seniority${data}`}
							label={t('form.seniority')}>
							<InputNumber
								defaultValue={''}
								max={40}
								gbType='number'
								className='gb-input-new-years'
								id={`seniority${data}`}
								placeholder={t('0')}
								style={{
									width: '100%'
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							rules={[
								{
									required: true
								}
							]}
							name={`netIncomes${data}`}
							label={
								rol === 'realestate' ? t('form.netIncomesOwner') : t('form.netIncomesOwnerInmo')
							}>
							<InputNumber
								defaultValue={''}
								id={`netIncomes${data}`}
								step={100}
								suffix={'€/' + t('viability.monthsEstimated')}
								placeholder={t('form.netIncomesOwner')}
								style={{
									borderColor: '#c0daee',
									width: '100%',
									borderRadius: '4px'
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							rules={[
								{
									required: true
								}
							]}
							name={`loanPayment${data}`}
							label={
								<>
									<div>
										<div>
											{rol === 'realestate'
												? t('onboarding.haveOtherLoans')
												: t('calculator.haveLoansInmo')}
										</div>
										<div
											className="explination-others-incomes"
											style={{ marginTop: 1, marginBottom: 0 }}>
											<Text style={{ color: '#748EA0', fontSize: 10, fontWeight: 400 }}>
												{t('form.haveLoansInmo0')}
											</Text>
										</div>
									</div>
								</>
							}>
							<InputNumber
								defaultValue={''}
								id={`loanPayment${data}`}
								step={100}
								suffix={'€/' + t('viability.monthsEstimated')}
								placeholder={'0'}
								style={{
									borderColor: '#c0daee',
									width: '100%',
									borderRadius: '4px'
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							rules={[
								{
									required: true
								}
							]}
							name={`age${data}`}
							label={t('calculator.age')}>
							<InputNumber
								defaultValue={''}
								id={`age${data}`}
								step={1}
								placeholder={t('calculator.age')}
								gbType='number'
								className='gb-input-new-years'
								style={{
									borderColor: '#c0daee',
									width: '100%',
									borderRadius: '4px'
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							rules={[
								{
									required: true
								}
							]}
							name={`cityzenship${data}`}
							label={t('form.country')}>
							<Select
								id="country"
								showSearch
								placeholder={t('form.country')}
								style={{ width: '100%' }}
								filterOption={(input, option) => {
									return (
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
										option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
									);
								}}>
								{countries &&
									countries.countries.map((paises) => {
										return (
											<Option key={paises.code} value={paises.code}>
												{lang === 'en' ? paises.name_en : paises.name_es}
											</Option>
										);
									})}
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<Form.Item>
					<Card
						className="cardButtonStyle"
						style={{
							left: 0,
							backgroundColor: '#FAFEFE'
						}}>
						<Button
							id="gb-mortgage-buy-situation-mobile"
							className="button-primary-gibobs-inmo"
							type="primary"
							style={{
								fontSize: 16,
								width: '100%',
								height: 48,
								fontWeight: 600
							}}
							htmlType="submit">
							{t('calculator.buttonCalculate')}
						</Button>
					</Card>
				</Form.Item>
			</>
		);
	};

	for (let i = 1; i <= parseInt(participants); i++) {
		participantsTabs.push(renderTabs(i));
	}

	for (let i = 1; i <= parseInt(participants); i++) {
		participantsTabsDesktop.push(renderTabsDesktop(i));
	}

	const validateMessages = {
		required: t('form.required2'),
		types: {
			email: 'Debe introducir',
			number: '${label} is not a valid number!'
		}
	};

	const openNotificationWithIcon = () => {
		notificationGibobs('error', t('m_full.errorTitle'), t('form.incompleteInformation'));
	};

	const renderBack = () => {
		if (location.state?.values?.state?.result === undefined) {
			navigate(`/calculators/fee`);
		} else {
			
			navigate(`/calculators/fee/viability`,{
				state: {
					result: location.state?.values?.state?.result,
					formValues: location.state?.values?.state?.formValues
				}
			});
		}
	};

	if (isMobileOnly) {
		return (
			<>
				<div style={{ padding: 16 }}>
					<Row style={{ width: '100%' }}>
						<Col span={24}>
							<a
								onClick={() => {
									location !== undefined && location.state.quality
										? navigate('/calculators/qualify/client')
										: renderBack();
								}}>
								<ArrowLeftOutlined style={{ color: '#2F4858', fontSize: 20 }} />
							</a>
							<Text
								style={{
									color: '#2F4858',
									fontSize: 16,
									fontWeight: 600,
									marginLeft: 16
								}}>
								{t('calculator.viabilityTitle')}
							</Text>
						</Col>
					</Row>
					<div style={{ marginBottom: 200 }}>
						<Form
							requiredMark={false}
							labelAlign="left"
							colon={false}
							className="gb-form-profile-headlines-mobile"
							labelCol={{
								flex: '50%'
							}}
							labelWrap
							wrapperCol={{
								flex: '50%'
							}}
							layout="horizontal"
							scrollToFirstError={true}
							form={form}
							name="basic"
							onFinish={sendValues}
							onFinishFailed={openNotificationWithIcon}
							validateMessages={validateMessages}
							initialValues={{
								houseType: location.state.houseType,
								participants: location.state.participants,
								price: 180000,
								savings: 55000,
								cityzenship: 'ES',
								cityzenship1: 'ES',
								cityzenship2: 'ES',
								...location?.state?.values?.state?.formValues
							}}>
							<Row style={{ width: '100%', marginTop: 16 }}>
								<Col span={24}>
									<Form.Item
										rules={[
											{
												required: true
											}
										]}
										name="houseType"
										label={t('viability.expectedUse')}>
										<Select
											placeholder={t('loan.sincronizationChosse')}
											style={{ width: '100%', height: 40 }}
											onChange={(value) => {
												setHouseType(value);
											}}>
											<Option value="main">{t('form.main')}</Option>
											<Option value="second">{t('form.second')}</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item
										rules={[
											{
												required: true
											}
										]}
										name="participants"
										label={
											rol === 'realestate'
												? t('calculator.viabilityAloneIn')
												: t('calculator.viabilityAlone')
										}>
										<Select
											placeholder={t('loan.sincronizationChosse')}
											style={{ width: '100%', height: 40 }}
											onChange={(value) => {
												setParticipants(value);
											}}>
											<Option value="1">{t('calculator.formAlone')}</Option>
											<Option value="2">{t('calculator.formAccompanied')}</Option>
										</Select>
									</Form.Item>
								</Col>
							</Row>

							{participants === '2' && (
								<Tabs defaultActiveKey="1" centered>
									{participantsTabs}
								</Tabs>
							)}
							{participants === '1' && (
								<Row gutter={24} style={{ padding: 16 }}>
									{renderForm('1')}
								</Row>
							)}
						</Form>
					</div>
				</div>
			</>
		);
	} else {
		return (
			<>
				<div style={{ padding: 40 }}>
					<Row style={{ width: '100%' }}>
						<Col span={24}>
							<a
								onClick={() => {
									location !== undefined && location.state.quality
										? navigate('/calculators/qualify/client')
										: navigate('/calculators/fee');
								}}>
								<ArrowLeftOutlined style={{ color: '#2F4858', fontSize: 20 }} />
							</a>
							<Text
								style={{
									color: '#2F4858',
									fontSize: 16,
									fontWeight: 600,
									marginLeft: 16
								}}>
								{t('calculator.viabilityTitle')}
							</Text>
						</Col>
					</Row>
					<Card className="gb-card" style={{ marginTop: 24, marginBottom: 200 }}>
						<Form
							className="gb-form-profile-headlines-mobile"
							scrollToFirstError={true}
							form={form}
							layout="vertical"
							onFinishFailed={openNotificationWithIcon}
							name="basic"
							onFinish={sendValues}
							initialValues={{
								price: 180000,
								savings: 55000,
								cityzenship: 'ES',
								cityzenship1: 'ES',
								cityzenship2: 'ES'
							}}
							validateMessages={validateMessages}>
							<Row gutter={[60, 40]} style={{ width: '100%', marginTop: 14 }}>
								<Col span={6}>
									<Form.Item
										name="houseType"
										rules={[
											{
												required: true
											}
										]}
										label={
											<Text
												style={{
													color: '#2F4858',
													fontSize: 12,
													fontWeight: 400,
												}}>
												{t('viability.expectedUse')}
											</Text>
										}>
										<Select
											placeholder={t('loan.sincronizationChosse')}
											style={{ width: '100%', height: 40 }}
											onChange={(value) => {
												setHouseType(value);
											}}>
											<Option value="main">{t('form.main')}</Option>
											<Option value="second">{t('form.second')}</Option>
										</Select>
									</Form.Item>
								</Col>

								<Col span={6}>
									<Form.Item
										rules={[
											{
												required: true
											}
										]}
										label={
											<Text
												style={{
													color: '#2F4858',
													fontSize: 12,
													fontWeight: 400
												}}>
												{rol === 'realestate'
													? t('calculator.viabilityAloneIn')
													: t('calculator.viabilityAlone')}
											</Text>
										}
										name="participants">
										<Select
											placeholder={t('loan.sincronizationChosse')}
											style={{ width: '100%', height: 40 }}
											onChange={(value) => {
												setParticipants(value);
											}}>
											<Option value="1">{t('calculator.formAlone')}</Option>
											<Option value="2">{t('calculator.formAccompanied')}</Option>
										</Select>
									</Form.Item>
								</Col>
							</Row>

							{participants === '2' && (
								<Tabs defaultActiveKey="1" centered style={{ marginTop: 16 }}>
									{participantsTabsDesktop}
								</Tabs>
							)}
							{participants === '1' && (
								<Row gutter={24} style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 16 }}>
									{renderFormDesktop('1')}
								</Row>
							)}
						</Form>
					</Card>
				</div>
			</>
		);
	}
};

export default CalculatorsFeeDatasMobileAndDesktop;
