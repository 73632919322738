import {
  LOADING,
  OPERATIONS
} from "./constants";


export const INITIAL_STATE = {
  operations: [],
  loading: true
};


export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPERATIONS:
      return { ...state, operations: action.payload };
    case LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};