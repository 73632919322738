import React from "react";
import { Progress } from "antd";
import { isMobile, isMobileOnly } from "react-device-detect";

const ProgressBySteps = ({ steps, stage }) => {
  return (
    <div className="Progress-by-steps">
      <div className="steps-and-title">
        <div style={{ display: "flex" }}>
          <div
            style={{
              color: "#02C3CD",
              fontWeight: 600,
              fontSize: isMobile ? 14 : 16,
              lineHeight: "24px",
            }}
          >
            Paso {stage}
          </div>
          <div
            style={{
              color: "#02C3CD",
              fontWeight: 400,
              fontSize: isMobile ? 14 : 16,
              lineHeight: "24px",
              marginLeft: 4,
            }}
          >
            de {steps}
          </div>
        </div>
        <Progress
          className={isMobileOnly ? "steps-mobile" : "steps-desktop"}
          percent={(stage * 100) / steps}
          steps={steps}
          showInfo={false}
          strokeColor={"#02C3CD"}
          trailColor={"#C0F0F3"}
          strokeWidth={4}
        />
      </div>
    </div>
  );
};

export default ProgressBySteps;
