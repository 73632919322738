import React, { useState } from "react";
import { Select, Modal, Row, Col, Form, Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import help from "../../assets/icons/help.png";
import DownOutlinedMobile from "../../assets/icons/DownOutlinedMobile.svg";
import TextArea from "antd/lib/input/TextArea";
import { useSelector } from "react-redux";
import axios from "axios";
import env from "../../environment";
import { notificationGibobs } from "../HerramientasVenta/reusableItems";
import { useParams } from "react-router-dom";

const { Option } = Select;

const HelpButton = ({ simulation, primary, origin }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const params = useParams();
  const operationId = params.operationId;
  const [showModalHelp, setShowModalHelp] = useState(false);
  const userToken = useSelector((state) => state.auth.token);
  const dataPromoter = useSelector((state) => state.promoter.dataFormPromoter);

  const sendContactData = (values) => {
    for (var propName in values) {
      if (values[propName] === undefined) {
        delete values[propName];
      }
    }
    axios
      .post(
        `${env.api.url}/promoters/promoter-projects/contact-data/${operationId}?origin=${origin}`,
        values,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        if (response.data.success) {
          notificationGibobs("success", t("promoterLoan.ModalTextSuccess"));
        }
        if (!response.data.success) {
          notificationGibobs("error", t("promoterLoan.ModalTextError"));
        }
      })
      .catch(() => {
        notificationGibobs("error", t("promoterLoan.ModalTextError"));
      });
    setShowModalHelp(!showModalHelp);
  };

  const handleOk = (values) => {
    sendContactData(values);
  };

  const validateMessages = {
    required: "${label}" + " " + t("form.formRequired"),
    types: {
      email: t("form.emailValidated"),
      number: "${label} is not a valid number!",
    },
    number: {
      range: t("form.maxBudgetNewRequestValidated") + "${min}",
    },
  };

  return (<>
    {simulation ? (
      <Button
        type={primary ? "primary" : "dashed"}
        className={
          primary ? "button-primari-gibobs" : "button-secundary-gibobs"
        }
        onClick={() => setShowModalHelp(!showModalHelp)}
        style={{ backgroundColor: !primary && "transparent" }}
      >
        {t("form.wantCalled")}
      </Button>
    ) : (
      <div
        style={{
          position: "fixed",
          bottom: 16,
          right: 16,
          cursor: "pointer",
        }}
        onClick={() => setShowModalHelp(!showModalHelp)}
      >
        <img style={{ width: 64, height: 64 }} alt="" src={help}></img>
      </div>
    )}
    <Modal
      title={
        simulation ? t("promoterLoan.contactForm") : t("button.initHelp")
      }
      className="ModalCommon modal-promoter-header"
      cancelButtonProps={{
        className: "button-secundary-gibobs",
        style: { width: "46%" },
      }}
      okButtonProps={{
        className: "button-primari-gibobs",
        style: { width: "46%" },
      }}
      open={showModalHelp}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleOk(values);
            form.resetFields();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={() => setShowModalHelp(!showModalHelp)}
      okText={t("form.send")}
    >
      <div
        className="paragraph-style"
        style={{ marginBottom: 16, fontSize: 12 }}
      >
        {t("promoterLoan.analystContact")}
      </div>
      <Form
        form={form}
        className={"gb-form-profile-headlines-mobile"}
        layout="vertical"
        name="Contacto"
        validateMessages={validateMessages}
        initialValues={
          dataPromoter && dataPromoter.owner
            ? { ...dataPromoter["owner"] }
            : {}
        }
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="phone"
              label={t("form.phone")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder={t("form.phone")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="contactScheduleTypes"
              label={t("form.contactTime")}
            >
              <Select
                defaultValue={t("form.select")}
                style={{
                  width: "100%",
                }}
                suffixIcon={
                  <img src={DownOutlinedMobile} alt="DownOutlinedMobile" />
                }
              >
                <Option value="MORNINGS">{t("form.morning")}</Option>
                <Option value="NOON">{t("form.afternoon")}</Option>
                <Option value="AFTERNOONS">{t("form.evening")}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="comments" label={t("form.comments")}>
              <TextArea
                placeholder={t("form.comments")}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  </>);
};

export default HelpButton;
