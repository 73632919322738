import axios from "axios";
import env from "../../environment";
import {
  OPERATIONS,
  LOADING
} from "./constants";
import { store } from '../../store';

export const getOperations = () => async (dispatch) => {
  dispatch({
    type: LOADING,
    payload: true,
  });
  
  axios.get(`${env.api.url}/v1/operations/list`, {
    headers: { Authorization: `Bearer ${store.getState().auth.token}` },
  }).then((response) => {
    if (response.data.success) {
      const operations = response.data.data;
      
      dispatch({
        type: OPERATIONS,
        payload: operations,
      });

      dispatch({
        type: LOADING,
        payload: false,
      });
    } else {
      dispatch({
        type: LOADING,
        payload: false,
      });
    }
  }).catch((error) => {
    dispatch({
      type: LOADING,
      payload: false,
    });
  });

  return "LIST";
};