import React, { useState, useEffect } from "react";
import {
  Card,
  Layout,
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  message,
  Tooltip,
  notification,
  Tabs,
  Badge,
  InputNumber,
} from "antd";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import numeral from "numeral";
import axios from "axios";
import env from "../../environment";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { InputDate, ProvinceSelector } from 'afrodita';
import LoaderLogo from "../../components/LoaderLogo";
import DownOutlinedMobile from "../../assets/icons/DownOutlinedMobile.svg";
import dayjs from 'dayjs';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');
dayjs.locale('vi');
const { Option } = Select;
const { TabPane } = Tabs;

function CertificatedBuySell({ ecoBudget }) {
  const navigate = useNavigate();;
  const location = useLocation();
  const ecoBudgetPrice = ecoBudget ? location.state.price : null;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const userToken = useSelector((state) => state.auth.token);
  const [tabs, setTabs] = useState("0");
  const [values, setValues] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  const [, forceUpdate] = useState({});
  const [loading, setLoading] = useState(false);
  const [valuesForm1, setValuesForm1] = useState();
  const [completeData, setCompletedData] = useState(true);

  useEffect(() => {
    forceUpdate({});
  }, []);
  useEffect(() => {
    forceUpdate({});
  }, [values]);

  const newRows = [];
  const quantityParticipants = (value) => {
    for (let i = 0; i < value; i++) {
      newRows.push({
        firstname: "",
        surname1: "",
        surname2: "",
        birthdate: "",
        nif: "",
        phone: "",
        email: "",
        nifDueDate:" "
      });
      setValues(newRows);
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    let buyOrSell;
    if (location.pathname !== "/sales-tools/certificated-buy-sell") {
      buyOrSell = false;
      values.maxBudget = numeral(values.maxBudget).value();
    } else {
      buyOrSell = true;
      values.maxBudget = numeral(values.maxBudget).value();
      values.maxBuySellHouse = numeral(values.maxBuySellHouse).value();
      values.totalSavings = numeral(values.totalSavings).value();
    }

    axios
      .post(
        `${env.api.url}/v1/certificates/create`,
        {
          ...values,
          toBuy: buyOrSell,
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        if (response.data.success === false) {
          if(response.data.message === 'Phone already exists with a different email'){
            message.error(t('form.errorPhone'));
            setLoading(false);
          }else if(response.data.message === 'Email already exists with a different phone number'){
            message.error(t('form.errorMail'));
            setLoading(false);
          }else{
            message.error(t("certificate.messageErrorWifi"));
          }
        } else {
          // navigate(location.pathname + "/documentation")
          navigate("/sales-tools/follow-up", [true, "certificated"]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <div
        style={{
          textAlign: "center",
          height: "100vh",
          backgroundColor: "white",
        }}
      >
        <LoaderLogo />
      </div>
    );
  }

  const validateMessages = {
    required: "${label}" + t("form.formRequired"),
    types: {
      email: t("form.emailValidated"),
      number: "${label} is not a valid number!",
    },
    number: {
      range: t("form.maxBudgetNewRequestValidated") + "${min}",
    },
  };

 

 

  const openNotificationWithIcon = () => {
    notification["error"]({
      message: (
        <div style={{ fontSize: "12px" }}>
          {t("form.incompleteInformation")}
        </div>
      ),
    });
  };
  const handleOnChange = (index, name, value) => {
    const copyRows = [...values];
    if (name !== "birthdate" && name !== "nifDueDate") {
      copyRows[index] = {
        ...copyRows[index],

        [name]: value,
      };
    } else {
      copyRows[index] = {
        ...copyRows[index],
        [name]: dayjs(value, ["YYYY-MM-DD", "DD-MM-YYYY"]).format(
          "YYYY-MM-DD"
        ),
      };
    }

    prueba(copyRows[index]);
    setValues(copyRows);
  };


  const prueba = (object) => {
    let valores = Object.values(object);
    if (valores.includes("")) {
      setCompletedData(true);
    } else {
      setCompletedData(false);
    }
  };

  const onFinishForm1 = (values) => {
    setValuesForm1(values);
    setPageNumber(2);
  };
  const onFinishForm2 = () => {
    const newValues = { ...valuesForm1, participants: [...values] };
    if (parseInt(tabs) === values.length - 1) {
      onFinish(newValues);
    } else {
      setTabs(`${parseInt(tabs) + 1}`);
    }
  };

  const form1 = (
    <Form
      className={
        isMobile
          ? "gb-form-profile-headlines-mobile"
          : "gb-form-common-new-style"
      }
      onFinishFailed={openNotificationWithIcon}
      scrollToFirstError={true}
      form={form}
      name="nest-messages"
      onFinish={onFinishForm1}
      layout="vertical"
    >
      {location.pathname !== "/sales-tools/certificated-buy-sell" && (
        <Row gutter={24}>
          <Col lg={6} md={8} sm={12} xs={24}>
            <Form.Item
              label={t("form.maxRentHouse")}
              name="maxBudget"
              rules={[{ required: true }]}
            >
              <InputNumber
                step={100}
                style={{
                  width: "100%",
                }}
                defaultValue={valuesForm1 && valuesForm1.maxBudget}
                placeholder={t("form.maxRentHouse")}
              />
            </Form.Item>
          </Col>
          <Col lg={6} md={8} sm={12} xs={24}>
            <Form.Item
              label={t("form.participantsNumber")}
              name="participants"
              rules={[{ required: true }]}
              defaultValue={valuesForm1 ? valuesForm1.participants : 1}
            >
              <Select
                suffixIcon={
                  <img src={DownOutlinedMobile} alt="DownOutlinedMobile" />
                }
                style={{ width: "100%" }}
                placeholder={t("form.participantsNumber")}
                onChange={(e) => quantityParticipants(e)}
              >
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )}

      {location.pathname === "/sales-tools/certificated-buy-sell" && (
        <Row gutter={40}>
          <Col lg={6} md={8} sm={12} xs={24}>
            <Form.Item
              label={t("form.maxBudgetNewRequest")}
              name="maxBuySellHouse"
              rules={[{ required: true }]}
            >
              <InputNumber
                step={100}
                style={{
                  width: "100%",
                }}
                defaultValue={valuesForm1 && valuesForm1.maxBuySellHouse}
                placeholder={t("form.maxBudgetNewRequest")}
              />
            </Form.Item>
          </Col>
          <Col lg={6} md={8} sm={12} xs={24}>
            <Form.Item
              label={t("viability.provinceViability")}
              name="province"
              rules={[{ required: true }]}
            >
              <ProvinceSelector placeholder={t("form.province")} style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col lg={6} md={8} sm={12} xs={24}>
            <Form.Item
              label={t("budget.typeHome")}
              name="typeHome"
              rules={[{ required: true }]}
            >
              <Select
                suffixIcon={
                  <img src={DownOutlinedMobile} alt="DownOutlinedMobile" />
                }
                style={{ width: "100%" }}
              >
                <Option value="newConstruction">{t("form.newBuild")}</Option>
                <Option value="secondHand">{t("form.secondHand")}</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col lg={6} md={8} sm={12} xs={24}>
            <Form.Item
              label={t("form.GoBuy")}
              name="goBuy"
              rules={[{ required: true }]}
            >
              <Select
                suffixIcon={
                  <img src={DownOutlinedMobile} alt="DownOutlinedMobile" />
                }
                style={{ width: "100%" }}
                placeholder={t("form.GoBuy")}
              >
                <Option value="main">{t("form.main")}</Option>
                <Option value="second">{t("form.second")}</Option>
                <Option value="investor">{t("form.investor")}</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col lg={6} md={8} sm={12} xs={24}>
            <Form.Item
              label={
                <Tooltip
                  placement="right"
                  title={"Del cómputo total de participantes"}
                  color={"#2F4858"}
                  overlayClassName="toolTipProperty"
                >
                  {t("form.moneyOfAllOwner")}
                </Tooltip>
              }
              name="totalSavings"
              rules={[{ required: true }]}
            >
              <InputNumber
                step={100}
                style={{
                  width: "100%",
                }}
                placeholder={t("form.moneyOfAllOwner")}
                defaultValue={valuesForm1 && valuesForm1.totalSavings}
              />
            </Form.Item>
          </Col>
          <Col lg={6} md={8} sm={12} xs={24}>
            <Form.Item
              label={t("form.participantsNumber")}
              name="participants"
              rules={[{ required: true }]}
            >
              <Select
                suffixIcon={
                  <img src={DownOutlinedMobile} alt="DownOutlinedMobile" />
                }
                style={{ width: "100%" }}
                placeholder={t("form.participantsNumber")}
                onChange={(e) => quantityParticipants(e)}
              >
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )}
      {!isMobile && (
        <Button
          type="primary"
          className="button-primary-gibobs-inmo"
          style={{
            margin: "auto",
            display: "block",
            width: 120,
          }}
          htmlType="submit"
        >
          {t("form.next")}
        </Button>
      )}
      {isMobile && (
        <Card
          className="cardButtonStyle"
          style={{
            left: 0,
            marginBottom: -2,
            backgroundColor: "#FAFEFE",
          }}
        >
          <Button
            id="gb-mortgage-buy-situation-mobile"
            className="button-primary-gibobs-inmo"
            type="primary"
            style={{
              fontSize: 16,
              width: "100%",
              height: 48,
              fontWeight: 600,
            }}
            htmlType="submit"
            loading={loading}
          >
            {t("form.next")}
          </Button>
        </Card>
      )}
    </Form>
  );

  const callback = (key) => {
    setTabs(key);
  };

  
 
  const form2 = (
    <Form
      className={
        isMobile
          ? "gb-form-profile-headlines-mobile"
          : "gb-form-common-new-style"
      }
      onFinishFailed={openNotificationWithIcon}
      scrollToFirstError={true}
      form={form}
      name="nest-messages"
      onFinish={onFinishForm2}
      layout="vertical"
      initialValues={{
        ecoBudget: ecoBudgetPrice ? ecoBudgetPrice : null,
      }}
      validateMessages={validateMessages}
    >
      <Form.Item name="participants">
        <Tabs activeKey={tabs} onChange={callback} className="tabs-inmo">
          {values.map((data, index) => (
            <TabPane
              tab={
                <div style={{ display: "flex", alignItems: "center" }}>
                  {tabs == index ? (
                    <div style={{ fontWeight: 600 }}>
                      {t("mydocuments.participant") + " " + parseInt(index + 1)}
                    </div>
                  ) : (
                    t("mydocuments.participant") + " " + parseInt(index + 1)
                  )}
                  {completeData && (
                    <Badge
                      size="small"
                      dot={true}
                      style={{
                        float: "right",
                        width: "4px",
                        height: "6px",
                        marginLeft: "4px",
                        marginTop: 6,
                      }}
                    />
                  )}
                </div>
              }
              key={index}
            >
              <>
                <Row gutter={24} style={{ marginTop: isMobile ? 8 : 26 }}>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      label={t("form.name")}
                      name={[index, "firstname"]}
                      rules={[{ required: true }]}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                        placeholder={t("form.name")}
                        value={data.name}
                        onChange={(e) =>
                          handleOnChange(index, "firstname", e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      label={t("form.surnameOne")}
                      name={[index, "surname1"]}
                      rules={[{ required: true }]}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                        placeholder={t("form.surnameOne")}
                        defaultValue=""
                        value={data.surname1}
                        onChange={(e) =>
                          handleOnChange(index, "surname1", e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      label={t("form.surnameTwo")}
                      name={[index, "surname2"]}
                      rules={[{ required: true }]}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                        placeholder={t("form.surnameTwo")}
                        value={data.surname2}
                        onChange={(e) =>
                          handleOnChange(index, "surname2", e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      label={t("form.birthdate")}
                      name={[index, "birthdate"]}
                      rules={[{ required: true }]}
                    >
                      <InputDate
                        type="date"
                        style={{
                          width: "100%",
                        }}
                        placeholder={t("form.birthdate")}
                        value={data.birthdate}
                        onBlur={(e) =>
                          handleOnChange(index, "birthdate", e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      label={t("form.dni")}
                      name={[index, "nif"]}
                      rules={[{ required: true }]}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                        placeholder={t("form.dni")}
                        value={data.nif}
                        onChange={(e) =>
                          handleOnChange(index, "nif", e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      label={t("form.dniDate")}
                      name={[index, "nifDueDate"]}
                      style={{
                        width: "100%",
                      }}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputDate
                        type="date"
                        style={{
                          width: "100%",
                          height: "40px",
                          borderRadius: "4px",
                        }}
                        value={data.nifDueDate}
                        onBlur={(e) =>
                          handleOnChange(index, "nifDueDate", e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      label={t("form.phone")}
                      name={[index, "phone"]}
                      rules={[{ required: true }]}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                        placeholder={t("form.phone")}
                        value={data.phone}
                        onChange={(e) =>
                          handleOnChange(index, "phone", e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      label={t("form.email")}
                      name={[index, "email"]}
                      rules={[{ required: true, type: "email" }]}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                        placeholder={t("email@email.com")}
                        value={data.email}
                        onChange={(e) =>
                          handleOnChange(index, "email", e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            </TabPane>
          ))}
        </Tabs>
      </Form.Item>
      {!isMobile && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            type="dashed"
            className="button-secundary-gibobs-inmo"
            style={{
              width: 120,
              marginRight: 8,
            }}
            onClick={() =>
              parseInt(tabs) === 0
                ? setPageNumber(1)
                : setTabs(`${parseInt(tabs) - 1}`)
            }
          >
            {t("form.previous")}
          </Button>
          <Button
            type="primary"
            className="button-primary-gibobs-inmo"
            style={{
              width: 120,
            }}
            htmlType="submit"
            disabled={
              form.getFieldValue().length > 12 ||
              !!form.getFieldsError().filter(({ errors }) => errors.length)
                .length
            }
          >
            {parseInt(tabs) === values.length - 1
              ? t("form.send")
              : t("form.next")}
          </Button>
        </div>
      )}
      {isMobile && (
        <Card
          className="cardButtonStyle"
          style={{
            left: 0,
            marginBottom: -2,
            backgroundColor: "#FAFEFE",
          }}
        >
          <Button
            id="gb-mortgage-buy-situation-mobile"
            className="button-secundary-gibobs-inmo"
            type="dashed"
            style={{
              fontSize: 16,
              width: "48%",
              height: 48,
              fontWeight: 600,
              marginRight: "10px",
            }}
            onClick={() =>
              parseInt(tabs) === 0
                ? setPageNumber(1)
                : setTabs(`${parseInt(tabs) - 1}`)
            }
          >
            {t("form.previous")}
          </Button>
          <Button
            id="gb-mortgage-buy-situation-mobile"
            className="button-primary-gibobs-inmo"
            type="primary"
            style={{
              fontSize: 16,
              width: "48%",
              height: 48,
              fontWeight: 600,
            }}
            htmlType="submit"
            loading={loading}
            disabled={
              form.getFieldValue().length > 12 ||
              !!form.getFieldsError().filter(({ errors }) => errors.length)
                .length
            }
          >
            {parseInt(tabs) === values.length - 1
              ? t("form.send")
              : t("form.next")}
          </Button>
        </Card>
      )}
    </Form>
  );

  return (
    <Layout>
      {!isMobile && (
        <>
          {pageNumber === 1 && (
            <Card
              className="CardStyleRealeState"
              style={{
                marginLeft: isMobile ? "16px" : "40px",
                width: isMobile ? "calc(100% - 32px)" : "calc(100% - 80px)",
                marginTop: 40,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: isMobile && "16px",
                  alignItems: "center",
                  marginBottom: !isMobile && "40px",
                }}
              >
                <div className="typography-titles">
                  {t("certificated.certified")}
                </div>
                <div className="typography-blue-text">
                  {t("form.propertyData")}
                </div>
              </div>
              {form1}
            </Card>
          )}

          {pageNumber === 2 && (
            <Card
              className="CardStyleRealeState"
              style={{
                marginLeft: isMobile ? "16px" : "40px",
                width: isMobile ? "calc(100% - 32px)" : "calc(100% - 80px)",
                marginTop: 40,
                marginBottom: 100,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: isMobile && "16px",
                  alignItems: "center",
                  marginBottom: !isMobile && "40px",
                }}
              >
                <div className="typography-titles">
                  {t("certificated.certified")}
                </div>
                <div className="typography-blue-text">
                  Datos de los participantes
                </div>
              </div>
              {form2}
            </Card>
          )}
        </>
      )}

      {isMobile && (
        <>
          {pageNumber === 1 && (
            <div className="gb-form-inmo-scroll">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: isMobile && "16px",
                  alignItems: "center",
                  marginBottom: !isMobile ? "40px" : "8px",
                }}
              >
                <div className="typography-titles">
                  {t("certificated.certified")}
                </div>
                <div className="typography-blue-text">
                  {t("form.propertyData")}
                </div>
              </div>
              <div style={{ margin: "0 16px", marginBottom: 50 }}>{form1}</div>
            </div>
          )}
          {pageNumber === 2 && (
            <div className="gb-form-inmo-scroll">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: isMobile && "16px",
                  alignItems: "center",
                  marginBottom: !isMobile ? "40px" : "8px",
                }}
              >
                <div className="typography-titles">
                  {t("certificated.certified")}
                </div>
                <div className="typography-blue-text">
                  Datos de los participantes
                </div>
              </div>
              <div style={{ margin: "0 16px", marginBottom: 50 }}>{form2}</div>
            </div>
          )}
        </>
      )}
    </Layout>
  );
}

export default CertificatedBuySell;
