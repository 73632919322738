import React from 'react';
import { Col, Form, Radio, Space, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import DownOutlinedMobile from '../../../../assets/icons/DownOutlinedMobile.svg';
import { InputNumber } from 'afrodita';

const { Option } = Select;

const TargetsForm1 = ({ edit }) => {
	const { t } = useTranslation();
	const colSpan = {
		xxl: edit === undefined ? 24 : 6,
		xl: edit === undefined ? 24 : 6,
		lg: edit === undefined ? 24 : 8,
		md: edit === undefined ? 24 : 12,
		sm: 24,
		xs: 24
	};
	return (
		<>
			<Col {...colSpan}>
				<Form.Item
					name="maxBudget"
					label={t('onboarding.propertyTaxesExcluded')}
					validateTrigger="onBlur"
					rules={[
						{
							required: true,
							validator: (e, value) =>
								value >= 50000
									? Promise.resolve()
									: Promise.reject(new Error(t('property.inputValidator')))
						}
					]}>
					<InputNumber
						defaultValue={''}
						id={'maxBudget'}
						gbType="currency"
						max={99000000}
						maxLength="15"
						placeholder={t('0')}
					/>
				</Form.Item>
			</Col>
			{edit === undefined && (
				<Col span={24}>
					<Form.Item name="purchaseForecast" label={t('onboarding.PlanToBuyProperty')}
					rules={[
						{
							required: true,
							validator: (e, value) =>
								value !== undefined
									? Promise.resolve()
									: Promise.reject(new Error(t('onboarding.PlanToBuyProperty')))
						}
					]}
					
					>
						<Radio.Group id="purchaseForecast">
							<Space direction="vertical">
								<Radio value="lessThanSixMonths">{t('form.lessThanSixMonths')}</Radio>
								<Radio value="withinSixOrTwelveMonths">{t('form.withinSixOrTwelveMonths')}</Radio>
								<Radio value="whitinOneOrTwoYears">{t('form.oneYearOMore')}</Radio>
							</Space>
						</Radio.Group>
					</Form.Item>
				</Col>
			)}
			{edit !== undefined && (
				<Col {...colSpan}>
					<Form.Item name="purchaseForecast" label={t('onboarding.PlanToBuyProperty')}>
						<Select
							id="purchaseForecast"
							placeholder={t('form.activity')}
							suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}>
							<Option value="lessThanSixMonths">{t('form.lessThanSixMonths')}</Option>
							<Option value="withinSixOrTwelveMonths">{t('form.withinSixOrTwelveMonths')}</Option>
							<Option value="whitinOneOrTwoYears">{t('form.oneYearOMore')}</Option>
						</Select>
					</Form.Item>
				</Col>
			)}
		</>
	);
};

export default TargetsForm1;
