import React, { useEffect, useState } from 'react';
import {
	Row,
	Button,
	Card,
	Skeleton,
	Col,
	Typography,
	Form,
	Select,
	Progress,
	Slider,
	Modal,
	Collapse
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { renderComponent } from '../../../select/typeSelect';
import edit from '../../../assets/calculators/edit.png';
import calculator2 from '../../../assets/calculators/calculator2.png';
import CalculatorQuoteComponent from './CalculatorQuoteComponent';
import axios from 'axios';
import env from '../../../environment';
import numeral from 'numeral';
import _ from 'lodash';
import calculatorImage from '../../../assets/calculators/calculatorImage.png';
import { InputNumber } from 'afrodita';
import ComplicatedViability from '../../onboarding/Components/ComplicatedViability';
import ViabilityClientProgress from './ViabilityClientProgress';
import MortgageAmountModal from '../quotaB2C/Modals/MortgageAmountModal';
import ModalWithoutButtons from '../../Financiero/Loans/Modals/ModalWithoutButtons';
import { renderDesc, renderLabelButton, renderTitle } from './common/RenderCommonTexts';

const { Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

const CalculatorsFeeDesktop = ({}) => {
	const location = useLocation();
	const { t } = useTranslation();
	const rol = useSelector((state) => state.auth.roles);
	const navigate = useNavigate();;
	const [loading, setLoading] = useState(true);
	const [form] = Form.useForm();
	const [term, setTerm] = useState(false);
	const [amountHp, setAmountHp] = useState(false);
	const [rate, setRate] = useState(false);
	const [pay, setPay] = useState(false);
	const [finish, setFinish] = useState(false);
	const dataProfile = useSelector((state) => state.data.dataProfile);
	const [result, setResult] = useState(undefined);
	const userToken = useSelector((state) => state.auth.token);
	const [fixed, setFixed] = useState(undefined);
	const [quotes, setQuotes] = useState(40 * 12);
	const [price, setPrice] = useState(180000);
	const [savings, setSavings] = useState(55000);
	const [province, setProvince] = useState(
		dataProfile && dataProfile.province ? dataProfile.province : 'Valencia'
	);
	const formatter = (value) => `${value}%`;
	const [participants, setParticipants] = useState(undefined);

	const [resultForm, setResultForm] = useState(undefined);
	const [result2, setResult2] = useState(undefined);
	const [fixedTmp, setFixedTmp] = useState(undefined);
	const [variableTmp, setVariableTmmp] = useState(undefined);
	const [euriborTmp, setEuriborTmp] = useState(undefined);
	const [timeoutReference, setTimeoutReference] = useState(undefined);
	const [, setDataUltimateSimulation] = useState(undefined);
	const [, setIdUltimateSimulation] = useState(undefined);
	const [fromValuesClient, setFromValuesClient] = useState(undefined);
	const dataSummary = useSelector((state) => state.data.dataSummary);
	const [locationTmpText, setLocationTmpText] = useState(undefined);

	useEffect(() => {
		if (rol !== 'realestate') {
			getUltimateSimulation();
			if (dataSummary.filter((data) => data.subtype === 'mortgage').length > 0) {
				getText();
			}
		}
	}, []);

	useEffect(() => {
		if (variableTmp && euriborTmp && fixedTmp) {
			let varMasEur = variableTmp + euriborTmp;

			let total = varMasEur <= fixedTmp ? varMasEur : fixedTmp;

			setFixed(total);
		}
	}, [variableTmp && euriborTmp && fixedTmp]);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, [1500]);
		if (!_.isNil(location.state)) {
			getData(location.state.result.id);
			setResult(location.state.result);
			setSavings(location.state.result.simulationData.savings);
			setPrice(location.state.result.simulationData.maxBudget);

			setResultForm(location.state.formValues);
			setParticipants(
				location?.state?.result?.simulationData['owner2']?.activity !== undefined ? '2' : '1'
			);
		}
		getFixAndVariable();
		if (_.isNil(location.state) && fixed && province && rol === 'realestate') {
			firtSimulation();
		}
	}, [fixed]);

	useEffect(() => {
		if (result2) {
			getData(result2);
		}
	}, [result2]);

	useEffect(() => {
		if (location?.state?.formValues) {
			if (result?.simulationData.owner2) {
				location.state.formValues.activity2 = result?.simulationData.owner2.activity;
				location.state.formValues.age2 = result?.simulationData.owner2.age;
				location.state.formValues.cityzenship2 = result?.simulationData.owner2.cityzenship;
				location.state.formValues.loanPayment2 = result?.simulationData.owner2.loanPayment;
				location.state.formValues.netIncomes2 = result?.simulationData.owner2.netIncomes;
				location.state.formValues.seniority2 = result?.simulationData.owner2.seniority;
			}

			setResultForm(location.state.formValues);
		}
	}, [location]);

	const firtSimulation = () => {
		axios
			.post(
				`${env.api.url}/mortgages/simulations/quota-and-viability/mortgage-viability`,
				{
					id: !_.isNil(location.state) ? location.state.result.simulation.id : result2,
					simulationType: rol === 'realestate' ? 'B2B' : 'B2C',
					maxBudget: 180000,
					savings: 55000,
					propertyType: 'secondHand',
					province: province,
					fixed: fixed ? fixed : 2.9,
					quotes: quotes,
					participants: undefined
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				getData(response.data.data.simulation.id);
			})
			.catch((error) => {
                console.log(error)
            });
	};

	const getText = () => {
		axios
			.post(
				`${env.api.url}/campaigns/runner/experiment/by-event`,
				{
					event: 'questions-mortgages',
					data: {
						operationId: dataSummary[0].operationId
					}
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.data.mortgageQuestions === '2022') {
                    if (dataSummary[0].stage === 'basic') {
                        setLocationTmpText(
                            `/testA/operations/${dataSummary[0].operationId}/${dataSummary[0].stage}`
                        );
                    }else {
                        setLocationTmpText(
                            `/operations/${dataSummary[0].operationId}/${dataSummary[0].stage}`
                        );
                    }
					
				} else {
                    if (dataSummary[0].stage === 'basic') {
                        setLocationTmpText(
                            `/testA/operations/${dataSummary[0].operationId}/${dataSummary[0].stage}`
                        );
                    }else {
                        setLocationTmpText(
                            `/operations/${dataSummary[0].operationId}/${dataSummary[0].stage}`
                        );
                    }
				}
			});
	};

	const getUltimateSimulation = () => {
		axios
			.get(
				`${env.api.url}/mortgages/simulations/get-last-simulation?simulationMortgageType=QUOTA_CALCULATION`,
				{
					headers: {
						Authorization: `Bearer ${userToken}`
					}
				}
			)
			.then((response) => {
				setDataUltimateSimulation(response.data.data);
				if (response?.data?.data?.id && response.data.data.simulationData.houseType) {
					setIdUltimateSimulation(response.data.data.id);
					firtsCallSimulationTheLast(response.data.data);
				} else {
					firtSimulation();
				}
			});
	};

	const firtsCallSimulationTheLast = (allValues) => {
		const objValues = {
			id: allValues.id,
			savings: allValues.simulationData.savings,
			propertyType: allValues.simulationData.propertyType,
			simulationType: 'B2C',
			numerousFamily: false,
			province: allValues.simulationData.province,
			maxBudget: allValues.simulationData.maxBudget,
			fixed: allValues.simulationData.fixed,
			quotes: allValues.simulationData.quotes,
			participants: {
				houseType: allValues.simulationData.houseType,
				participants: allValues.simulationData.owner2 ? 2 : 1,
				owners: [allValues.simulationData.owner1, allValues.simulationData.owner2]
			}
		};
		if (_.isNil(allValues.simulationData.owner2)) {
			objValues.participants.participants = 1;
			objValues.participants.owners = [allValues.simulationData.owner1];
		} else {
			objValues.participants.participants = 2;
			objValues.participants.owners = [
				allValues.simulationData.owner1,
				allValues.simulationData.owner2
			];
		}
		axios
			.post(
				`${env.api.url}/mortgages/simulations/quota-and-viability/mortgage-viability`,
				objValues,
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				const result = response.data.data.simulation;
				let tmpValue = {};
				if (result?.simulationData.owner2) {
					tmpValue.activity2 = result?.simulationData.owner2.activity;
					tmpValue.age2 = result?.simulationData.owner2.age;
					tmpValue.cityzenship2 = result?.simulationData.owner2.cityzenship;
					tmpValue.loanPayment2 = result?.simulationData.owner2.loanPayment;
					tmpValue.netIncomes2 = result?.simulationData.owner2.netIncomes;
					tmpValue.seniority2 = result?.simulationData.owner2.seniority;
					tmpValue.houseType = result?.simulationData.houseType;
					tmpValue.propertyType = result?.simulationData.propertyType;
				}
				if (result?.simulationData.owner1) {
					tmpValue.activity1 = result?.simulationData.owner1.activity;
					tmpValue.age1 = result?.simulationData.owner1.age;
					tmpValue.cityzenship1 = result?.simulationData.owner1.cityzenship;
					tmpValue.loanPayment1 = result?.simulationData.owner1.loanPayment;
					tmpValue.netIncomes1 = result?.simulationData.owner1.netIncomes;
					tmpValue.seniority1 = result?.simulationData.owner1.seniority;
					tmpValue.houseType = result?.simulationData.houseType;
					tmpValue.propertyType = result?.simulationData.propertyType;
				}
				if (result?.simulationData.owner2) {
					tmpValue.participants = '2';
				} else {
					tmpValue.participants = '1';
				}

				setFromValuesClient(tmpValue);
				setResultForm(tmpValue);
				getData(response.data.data.simulation.id);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const getData = (id) => {
		axios
			.get(`${env.api.url}/mortgages/simulations/get-simulation/${id}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setResult2(response.data.data.id);
				setResult(response.data.data);
				setParticipants(response.data.data.simulationData.owner2 !== undefined ? '2' : '1');
			})
			.catch((error) => {
                console.log(error)
            });
	};

	const getFixAndVariable = () => {
		axios
			.get(`${env.api.url}/v1/options/get-option/rate-gibobs-variable-tae`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				setVariableTmmp(response.data);
			});

		axios
			.get(`${env.api.url}/v1/options/get-option/rate-gibobs-fixed-tae`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				setFixedTmp(response.data);
			});

		axios
			.get(`${env.api.url}/v1/options/get-option/euribor`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				setEuriborTmp(response.data);
			});
	};

	const renderSliderFix = () => {
		let varMasEur = variableTmp + euriborTmp;

		let total = varMasEur <= fixedTmp ? varMasEur : fixedTmp;

		return total;
	};

	const sendFinish = () => {
		if (rol === 'realestate') {
			setFinish(false);
			
			navigate(`/sales-tools/mortgages`,{
                state: {
					values: { ...form.getFieldsValue() },
					fixed,
					quotes,
					result: result !== undefined ? result : undefined
				}
            });
		}
		if (rol !== 'realestate') {
			setFinish(false);
			axios
				.post(
					`${env.api.url}/mortgages/simulations/quota-and-viability/create-operation-mortgage/${result2}`,
					{},
					{
						headers: {
							Authorization: `Bearer ${userToken}`
						}
					}
				)
				.then((response) => {
					if (response.data.success) {
						const id = response.data.data.operationId;
						axios
							.post(
								`${env.api.url}/campaigns/runner/experiment/by-event`,
								{
									event: 'questions-mortgages',
									data: {
										operationId: id
									}
								},
								{
									headers: { Authorization: `Bearer ${userToken}` }
								}
							)
							.then((response) => {
								if (response.data.data.mortgageQuestions === '2022') {
									navigate(`/testA/operations/${id}/basic`);
								} else {
									navigate(`/operations/${id}/basic`);
								}
							});
					}
				});
		}
	};

	const onChangeFixed = (value) => {
		setFixed(value);
		const tmp = form.getFieldsValue();
		const tmpValues = {
			fixed: value,
			quotes: undefined
		};
		onChangeForm(tmpValues, tmp);
	};

	const onChangeTerm = (value) => {
		setQuotes(value * 12);
		const tmp = form.getFieldsValue();
		const tmpValues = {
			fixed: undefined,
			quotes: value * 12
		};
		onChangeForm(tmpValues, tmp);
	};

	const onChangeForm = (values, allValues) => {
		setSavings(allValues.savings);
		setPrice(allValues.price);
		setProvince(allValues.province);

		let owners = [];

		let participantsTmp = undefined;

		if (resultForm?.activity2) {
			participantsTmp = '2';

			if (participantsTmp !== participants) {
				setParticipants(participantsTmp);
			} else {
				participantsTmp = participants;
			}
		} else {
			participantsTmp = '1';

			if (participantsTmp !== participants) {
				setParticipants(participantsTmp);
			} else {
				participantsTmp = participants;
			}
		}

		if (participantsTmp === '2' && resultForm !== undefined) {
			owners = [
				{
					activity: resultForm.activity1,
					seniority: resultForm.seniority1 !== undefined ? resultForm.seniority1 : 0,
					netIncomes: resultForm.netIncomes1 !== undefined ? resultForm.netIncomes1 : 0,
					loanPayment: resultForm.loanPayment1 !== undefined ? resultForm.loanPayment1 : 0,
					age: resultForm.age1 !== undefined ? resultForm.age1 : 0,
					cityzenship: resultForm.cityzenship1
				},
				{
					activity: resultForm.activity2,
					seniority: resultForm.seniority2 !== undefined ? resultForm.seniority2 : 0,
					netIncomes: resultForm.netIncomes2 !== undefined ? resultForm.netIncomes2 : 0,
					loanPayment: resultForm.loanPayment2 !== undefined ? resultForm.loanPayment2 : 0,
					age: resultForm.age2 !== undefined ? resultForm.age2 : 0,
					cityzenship: resultForm.cityzenship2
				}
			];
		} else {
			if (participantsTmp === '1' && resultForm !== undefined) {
				owners = [
					{
						activity: resultForm.activity1,
						seniority: resultForm.seniority1 !== undefined ? resultForm.seniority1 : 0,
						netIncomes: resultForm.netIncomes1 !== undefined ? resultForm.netIncomes1 : 0,
						loanPayment: resultForm.loanPayment1 !== undefined ? resultForm.loanPayment1 : 0,
						age: resultForm.age1 !== undefined ? resultForm.age1 : 0,
						cityzenship: resultForm.cityzenship1
					}
				];
			}
		}

		if (timeoutReference) {
			clearTimeout(timeoutReference);
		}

		setTimeoutReference(
			setTimeout(() => {
				axios
					.post(
						`${env.api.url}/mortgages/simulations/quota-and-viability/mortgage-viability`,
						{
							id: result?.id,
							simulationType: rol === 'realestate' ? 'B2B' : 'B2C',
							maxBudget: allValues.price !== null ? allValues.price : 0,
							savings: allValues.savings !== null ? allValues.savings : 0,
							propertyType: allValues.propertyType,
							province: allValues.province !== undefined ? allValues.province : dataProfile.provice,
							fixed: values.fixed !== undefined ? values.fixed : fixed,
							quotes: values.quotes !== undefined ? values.quotes : quotes,
							participants:
								owners[0] !== undefined
									? {
											houseType:
												result?.simulationData?.houseType !== undefined
													? result?.simulationData?.houseType
													: result?.simulationData?.houseType,
											participants: parseInt(participantsTmp),
											owners: owners
									  }
									: undefined
						},
						{
							headers: { Authorization: `Bearer ${userToken}` }
						}
					)
					.then((response) => {
						getData(response.data.data.simulation.id);
					})
					.catch((error) => {
                        console.log(error)
                    });

				clearTimeout(timeoutReference);
			}, 500)
		);
	};

	const renderMax = () => {
		if (result) {
			if (result.simulationData) {
				const maxAge2 = result.simulationData.owner2 ? result.simulationData.owner2.age : 0;
				const maxAge1 = result.simulationData.owner1 ? result.simulationData.owner1.age : 40;

				const tmpAge = maxAge2 >= maxAge1 ? maxAge2 : maxAge1;

				const tmpAgeMin = 75 - tmpAge;

				const ageValue = tmpAgeMin <= 40 ? tmpAgeMin : 40;

				return ageValue;
			}
		}
	};

	const renderNumeral = (result) => {
		const amount = result.simulationData.resultQuoteMortage.operation.property;
		const impuestos = result.simulationData.resultQuoteMortage.operation.taxes + 2000;
		const intereses =
			result?.simulationData?.resultQuoteMortage?.operation.types.fixed.quote *
				result?.simulationData?.resultQuoteMortage.operation.quotes -
			result?.simulationData?.resultQuoteMortage?.operation?.mortgageAmount;
		const total = amount + impuestos + intereses;
		return numeral(total).format('0,0 $');
	};

	return (<>
        <div style={{ padding: 40, marginBottom: 100 }}>
            <Row style={{ width: '100%' }}>
                <Col span={24}>
                    <a onClick={() => rol === 'realestate' ? navigate('/sales-tools/new-requests') : navigate('/calculators')}>
                        <ArrowLeftOutlined style={{ color: '#2F4858', fontSize: 20 }} />
                    </a>
                    <Text
                        style={{
                            color: '#2F4858',
                            fontSize: 16,
                            fontWeight: 600,
                            marginLeft: 16
                        }}>
                        {rol === 'realestate' ? t('calculator.cardTitle1') : t('fee.calculator')}
                    </Text>
                </Col>
            </Row>

            <Card className="gb-card" style={{ marginTop: 24 }}>
                <Row gutter={[24, 24]}>
                    <Col span={8}>
                        <Card
                            className="gb-card-24px"
                            cover={<img src={calculator2} alt="asdf"></img>}
                            style={{
                                border: '1px solid #C0F0F3',
                                background: '#EFFCFC',
                                borderRadius: 4
                            }}>
                            <Skeleton active loading={loading} paragraph={false} />

                            {!loading && (
                                <Text
                                    style={{
                                        color: '#02C3CD',
                                        fontSize: 16,
                                        fontWeight: 600
                                    }}>
                                    {t('fee.calculator')}
                                </Text>
                            )}
                            {!loading && (
                                <div>
                                    <Form
                                        labelAlign="left"
                                        colon={false}
                                        className="gb-form-profile-headlines-mobile"
                                        labelCol={{
                                            flex: '50%'
                                        }}
                                        labelWrap
                                        wrapperCol={{
                                            flex: '50%'
                                        }}
                                        layout="horizontal"
                                        scrollToFirstError={true}
                                        form={form}
                                        name="basic"
                                        onValuesChange={(values, allValues) => onChangeForm(values, allValues)}
                                        initialValues={{
                                            price:
                                                result && result.simulationData !== undefined
                                                    ? result.simulationData.maxBudget
                                                    : 180000,
                                            savings:
                                                result && result.simulationData !== undefined
                                                    ? result.simulationData.savings
                                                    : 55000,
                                            propertyType:
                                                result && result.simulationData !== undefined
                                                    ? result.simulationData.propertyType
                                                    : 'secondHand',
                                            province:
                                                result && result.simulationData !== undefined
                                                    ? result.simulationData.province
                                                    : province
                                        }}>
                                        <Row
                                            gutter={24}
                                            style={{
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                                marginTop: 16
                                            }}>
                                            <Row style={{ width: '100%' }}>
                                                <Col span={24} style={{ textAlign: 'right' }}>
                                                    <Form.Item name="price" label={t('budget.price')}>
                                                        <InputNumber
                                                            defaultValue={''}
                                                            suffix={'€'}
                                                            min={0}
                                                            style={{
                                                                height: 40,
                                                                width: '100%'
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24} style={{ textAlign: 'right' }}>
                                                    <Form.Item name="savings" label={t('fee.calculatorformSavings')}>
                                                        <InputNumber
                                                            defaultValue={''}
                                                            suffix={'€'}
                                                            min={0}
                                                            style={{
                                                                height: 40,
                                                                width: '100%'
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name="propertyType"
                                                        label={
                                                            rol === 'realestate'
                                                                ? t('fee.calculatorFormTypeIn')
                                                                : t('fee.calculatorFormType')
                                                        }>
                                                        <Select style={{ width: '100%' }}>
                                                            <Option value="newConstruction">{t('form.newBuild')}</Option>
                                                            <Option value="secondHand">{t('form.secondHand')}</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item name="province" label={t('viability.provinceViability')}>
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            defaultValue={dataProfile && dataProfile.province}
                                                            showSearch>
                                                            {renderComponent('province').options.map((province) => {
                                                                return (
                                                                    <Option key={province.value} value={province.value}>
                                                                        {province.label}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Row>

                                        {!_.isNil(location.state) &&
                                            location?.state?.result?.simulationData?.resultViabilityMortage &&
                                            rol === 'realestate' && (
                                                <Collapse
                                                    defaultActiveKey={['1']}
                                                    expandIconPosition={'end'}
                                                    style={{ background: '#FFFFFF' }}>
                                                    <Panel
                                                        header={
                                                            <Text
                                                                style={{
                                                                    color: '#2F4858',
                                                                    fontSize: 16,
                                                                    fontWeight: 400
                                                                }}>
                                                                {t('data.viabilityTitle')}
                                                            </Text>
                                                        }
                                                        key="1">
                                                        <CalculatorQuoteComponent
                                                            idSimulation={result2}
                                                            setResult={setResult}
                                                            price={price}
                                                            savings={savings}
                                                            province={province}
                                                            formValues={location.state.formValues}
                                                            setPrice={setPrice}
                                                            fixed={fixed}
                                                            quotes={quotes}></CalculatorQuoteComponent>
                                                    </Panel>
                                                </Collapse>
                                            )}
                                        {rol !== 'realestate' && fromValuesClient && (
                                            <Collapse
                                                defaultActiveKey={['1']}
                                                expandIconPosition={'end'}
                                                style={{ background: '#FFFFFF' }}>
                                                <Panel
                                                    header={
                                                        <Text
                                                            style={{
                                                                color: '#2F4858',
                                                                fontSize: 16,
                                                                fontWeight: 400
                                                            }}>
                                                            {t('data.viabilityTitle')}
                                                        </Text>
                                                    }
                                                    key="1">
                                                    <CalculatorQuoteComponent
                                                        idSimulation={result2}
                                                        setResult={setResult}
                                                        price={price}
                                                        savings={savings}
                                                        province={province}
                                                        formValues={fromValuesClient}
                                                        setPrice={setPrice}
                                                        fixed={fixed}
                                                        quotes={quotes}></CalculatorQuoteComponent>
                                                </Panel>
                                            </Collapse>
                                        )}
                                    </Form>
                                </div>
                            )}
                        </Card>
                    </Col>
                    <Col span={16} style={{ textAlign: 'center' }}>
                        <Skeleton active loading={loading} paragraph={false} />

                        {!loading && (
                            <Row style={{ width: '100%', marginTop: 32 }}>
                                <Col span={12} style={{ marginTop: 16 }}>
                                    <Text
                                        style={{
                                            color: '#2F4858',
                                            fontSize: 14,
                                            fontWeight: 400
                                        }}>
                                        {t('loan.rate')}
                                    </Text>
                                    <InfoCircleOutlined
                                        onClick={() => setRate(true)}
                                        style={{ color: '#02C3CD', marginLeft: 8 }}
                                    />
                                    {result?.simulationData !== undefined && (
                                        <div style={{ marginTop: 8, marginLeft: 58 }}>
                                            <Text
                                                style={{
                                                    color: '#016B71',
                                                    fontSize: 12,
                                                    fontWeight: 400
                                                }}>
                                                {t('fee.calculatorEuribor') +
                                                    ' ' +
                                                    numeral(
                                                        result?.simulationData?.resultQuoteMortage?.rates?.euribor
                                                    ).format('0,0.00 %')}
                                            </Text>
                                        </div>
                                    )}
                                </Col>
                                <Col
                                    span={12}
                                    style={{
                                        textAlign: 'right',
                                        marginTop: 12,
                                        display: 'flex'
                                    }}>
                                    <Slider
                                        onChange={(value) => onChangeFixed(value)}
                                        step={0.01}
                                        defaultValue={renderSliderFix()}
                                        min={0}
                                        max={10}
                                        tooltip={{
                                            formatter,
                                            formatter: (value) => `${value} %`
                                        }}
                                        style={{ width: 250, color: '#02C3CD' }} />
                                    <Text
                                        style={{
                                            color: '#016B71',
                                            fontSize: 12,
                                            fontWeight: 400,
                                            marginLeft: 10
                                        }}>
                                        {fixed !== 1.9 ? fixed + '%' : renderSliderFix() + '%'}
                                    </Text>
                                </Col>
                            </Row>
                        )}
                        <Skeleton active loading={loading} paragraph={false} />

                        {!loading && (
                            <Row style={{ width: '100%', marginTop: 32 }}>
                                <Col span={12} style={{ marginTop: 0 }}>
                                    <Text
                                        style={{
                                            color: '#2F4858',
                                            fontSize: 14,
                                            fontWeight: 400
                                        }}>
                                        {t('loan.term')}
                                    </Text>
                                    <InfoCircleOutlined
                                        onClick={() => setTerm(true)}
                                        style={{ color: '#02C3CD', marginLeft: 8 }}
                                    />
                                </Col>
                                <Col span={12} style={{ textAlign: 'right', display: 'flex' }}>
                                    <Slider
                                        onChange={(value) => onChangeTerm(value)}
                                        defaultValue={renderMax()}
                                        min={renderMax() === undefined ? 40 : 5}
                                        max={renderMax()}
                                        style={{ width: 250 }}
                                        tooltip={{
                                            formatter: (value) => `${value} ${t('form.years')}`
                                        }}
                                    />
                                    <Text
                                        style={{
                                            color: '#016B71',
                                            fontSize: 12,
                                            fontWeight: 400,
                                            marginLeft: 10
                                        }}>
                                        <Text
                                            style={{
                                                color: '#016B71',
                                                fontSize: 12,
                                                fontWeight: 400
                                            }}>
                                            {quotes !== 40 * 12
                                                ? quotes / 12 + ' ' + t('form.years')
                                                : result !== undefined
                                                ? renderMax() + ' ' + t('form.years')
                                                : quotes / 12 + ' ' + t('form.years')}
                                        </Text>
                                    </Text>
                                </Col>
                            </Row>
                        )}

                        <Card
                            className="card-second"
                            style={{
                                borderColor: '#02C3CD',
                                borderRadius: 4,
                                width: '100%',
                                marginTop: 40
                            }}>
                            <Skeleton active loading={loading} paragraph={false} />

                            {!loading && (
                                <div style={{ textAlign: 'center' }}>
                                    <Text
                                        style={{
                                            color: '#2F4858',
                                            fontSize: 20,
                                            fontWeight: 600
                                        }}>
                                        {t('m_viability.title')}
                                    </Text>
                                </div>
                            )}
                            {!loading && (
                                <Row gutter={[24, 24]}>
                                    <Col span={12}>
                                        <Card
                                            className="gb-card-24px"
                                            style={{
                                                marginTop: 12,
                                                textAlign: 'left',
                                                minHeight: 80
                                            }}>
                                            {rol === 'realestate' && (
                                                <Row style={{ alignItems: 'center' }}>
                                                    {/* span a 10 si hay viabilidad sin no 20*/}
                                                    <Col span={!_.isNil(location.state) ? 10 : 12}>
                                                        <Text
                                                            style={{
                                                                color: '#748EA0',
                                                                fontSize: 16,
                                                                fontWeight: 400
                                                            }}>
                                                            {rol === 'realestate'
                                                                ? t('calculator.cardTitleInmo1')
                                                                : t('calculator.bankViability')}
                                                        </Text>
                                                    </Col>
                                                    <Col
                                                        span={!_.isNil(location.state) ? 4 : 12}
                                                        style={{
                                                            cursor: 'pointer',
                                                            textAlign: !_.isNil(location.state) ? undefined : 'right'
                                                        }}>
                                                        {_.isNil(location.state) && (
                                                            <Progress
                                                                onClick={() => {
                                                                   
                                                                    navigate(`/calculators/fee/datas`,
                                                                    {state: {
                                                                        values: { ...form.getFieldsValue() },
                                                                        fixed,
                                                                        quotes,
                                                                        id: result !== undefined ? result?.id : undefined
                                                                    }}
                                                                    );
                                                                }}
                                                                type="circle"
                                                                percent={100}
                                                                width={40}
                                                                format={() => (
                                                                    <img
                                                                        src={edit}
                                                                        alt="asdf"
                                                                        style={{
                                                                            width: 20,
                                                                            height: 20,
                                                                            marginTop: -5
                                                                        }}></img>
                                                                )}
                                                            />
                                                        )}
                                                        {!_.isNil(location.state) && (
                                                            <Progress
                                                                type="circle"
                                                                strokeColor={'#52C41A'}
                                                                trailColor={'#F1F7F8'}
                                                                percent={
                                                                    ((result?.simulationData?.resultViabilityMortage
                                                                        ? result?.simulationData?.resultViabilityMortage.viability
                                                                        : 0) *
                                                                        100) /
                                                                    5
                                                                }
                                                                width={40}
                                                                format={() => (
                                                                    <Text
                                                                        strong
                                                                        style={{
                                                                            fontSize: '12px',
                                                                            color: '#2F4858',
                                                                            marginBottom: '0px',
                                                                            display: 'grid'
                                                                        }}>
                                                                        {result?.simulationData?.resultViabilityMortage
                                                                            ? numeral(
                                                                                    result?.simulationData?.resultViabilityMortage.viability
                                                                              ).format('0.0')
                                                                            : numeral().format('0.0')}
                                                                    </Text>
                                                                )}
                                                            />
                                                        )}
                                                    </Col>
                                                    {!_.isNil(location.state) && (
                                                        <Col span={10} style={{ textAlign: 'right' }}>
                                                            <Text
                                                                style={{
                                                                    color: '#02C3CD',
                                                                    fontSize: 14,
                                                                    fontWeight: 400
                                                                }}>
                                                                {t('onboarding.why')}
                                                            </Text>
                                                            <InfoCircleOutlined
                                                                onClick={() => {
                                                                    
                                                                    navigate(`/calculators/fee/viability`,{
                                                                        state: { result, formValues: resultForm }
                                                                    });
                                                                }}
                                                                style={{ color: '#02C3CD', marginLeft: 8 }}
                                                            />
                                                        </Col>
                                                    )}
                                                </Row>
                                            )}
                                            {/**para el cliente */}

                                            {rol !== 'realestate' && result && (
                                                <ViabilityClientProgress
                                                    result={result}
                                                    resultForm={resultForm}
                                                    form={form}
                                                    edit={edit}
                                                    fixed={fixed}
                                                    quotes={quotes}></ViabilityClientProgress>
                                            )}
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Card
                                            className="gb-card-24px"
                                            style={{
                                                marginTop: 12,
                                                background: '#EFFCFC',
                                                minHeight: 83
                                            }}>
                                            <Row style={{ marginTop: 10 }}>
                                                <Col span={12} style={{ textAlign: 'left' }}>
                                                    <Text
                                                        style={{
                                                            color: '#748EA0',
                                                            fontSize: 16,
                                                            fontWeight: 400
                                                        }}>
                                                        {t('viability.monthlyPayments')}
                                                    </Text>
                                                </Col>
                                                <Col span={12} style={{ textAlign: 'right' }}>
                                                    <Text
                                                        style={{
                                                            color: '#2F4858',
                                                            fontSize: 16,
                                                            fontWeight: 600
                                                        }}>
                                                        {result?.simulationData &&
                                                            result?.simulationData?.resultQuoteMortage?.operation?.types?.fixed
                                                                ?.quote &&
                                                            numeral(
                                                                result?.simulationData?.resultQuoteMortage?.operation?.types
                                                                    ?.fixed?.quote
                                                            ).format('0,0 $') + '/mes'}
                                                    </Text>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            )}
                            {!loading && (
                                <Row gutter={[24, 24]} style={{ minHeight: 80, marginTop: 12 }}>
                                    <Col span={12}>
                                        <Card className="gb-card-24px" style={{ marginTop: 12 }}>
                                            <Row>
                                                <Col span={14} style={{ textAlign: 'left', display: 'flex' }}>
                                                    <Text
                                                        style={{
                                                            color: '#748EA0',
                                                            fontSize: 16,
                                                            fontWeight: 400
                                                        }}>
                                                        {t('onboarding.amountCalculation')}
                                                    </Text>
                                                    <InfoCircleOutlined
                                                        onClick={() => setAmountHp(true)}
                                                        style={{
                                                            color: '#02C3CD',
                                                            fontSize: 16,
                                                            marginLeft: 8,
                                                            marginTop: 4
                                                        }}
                                                    />
                                                </Col>

                                                <Col span={10} style={{ textAlign: 'right' }}>
                                                    <Text
                                                        style={{
                                                            color: '#2F4858',
                                                            fontSize: 16,
                                                            fontWeight: 600
                                                        }}>
                                                        {result &&
                                                            result?.simulationData &&
                                                            result?.simulationData?.resultQuoteMortage?.operation
                                                                ?.mortgageAmount !== undefined &&
                                                            numeral(
                                                                result?.simulationData?.resultQuoteMortage?.operation
                                                                    ?.mortgageAmount + 2000
                                                            ).format('0,0 $')}
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row style={{ marginTop: 8 }}>
                                                <Col span={16} style={{ textAlign: 'left' }}>
                                                    <Text
                                                        style={{
                                                            color: '#748EA0',
                                                            fontSize: 16,
                                                            fontWeight: 400
                                                        }}>
                                                        {t('onboarding.percentageFinancing')}
                                                    </Text>
                                                </Col>

                                                <Col span={8} style={{ textAlign: 'right' }}>
                                                    <Text
                                                        style={{
                                                            color: '#2F4858',
                                                            fontSize: 16,
                                                            fontWeight: 600
                                                        }}>
                                                        {result &&
                                                            result?.simulationData !== undefined &&
                                                            (
                                                                (result?.simulationData?.resultQuoteMortage?.operation
                                                                    ?.mortgageAmount *
                                                                    100) /
                                                                result?.simulationData?.resultQuoteMortage?.operation?.property
                                                            ).toFixed('1') + '%'}
                                                    </Text>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Card className="gb-card-24px" style={{ marginTop: 12, height: '90%' }}>
                                            <Row>
                                                <Col
                                                    span={rol === 'realestate' ? 14 : 12}
                                                    style={{ textAlign: 'left', display: 'flex' }}>
                                                    <Text
                                                        style={{
                                                            color: '#748EA0',
                                                            fontSize: 16,
                                                            fontWeight: 400
                                                        }}>
                                                        {rol === 'realestate'
                                                            ? t('calculator.bankViability2Inmo')
                                                            : t('calculator.bankViability2')}
                                                    </Text>
                                                    <InfoCircleOutlined
                                                        onClick={() => setPay(true)}
                                                        style={{
                                                            color: '#02C3CD',
                                                            fontSize: 16,
                                                            marginTop: 16
                                                        }}
                                                    />
                                                </Col>

                                                <Col
                                                    span={rol === 'realestate' ? 10 : 12}
                                                    style={{ textAlign: 'right', marginTop: 16 }}>
                                                    <Text
                                                        style={{
                                                            color: '#2F4858',
                                                            fontSize: 16,
                                                            fontWeight: 600
                                                        }}>
                                                        {result &&
                                                            result?.simulationData !== undefined &&
                                                            renderNumeral(result)}
                                                    </Text>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            )}
                        </Card>

                        {result &&
                            result.simulationData &&
                            result.simulationData?.resultViabilityMortage?.excepcionalViability !==
                                undefined && (
                                <ComplicatedViability
                                    title={
                                        result?.simulationData?.resultViabilityMortage && t(renderTitle(rol, result))
                                    }
                                    description={
                                        result?.simulationData?.resultViabilityMortage && t(renderDesc(rol, result))
                                    }
                                    subrogation={true}
                                    ajust={true}
                                />
                            )}

                        {dataSummary.filter((data) => data.subtype === 'mortgage').length > 0 && (
                            <Card className="card-financing-second" style={{ marginTop: 40 }}>
                                <Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>
                                    {t('calculatorFinancing.mortgage')}
                                </Text>
                                <div>
                                    <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 500 }}>
                                        {t('calculatorFinancing.mortgage1')}
                                    </Text>
                                    &nbsp;
                                    <Text
                                        style={{
                                            color: '#02C3CD',
                                            fontSize: 14,
                                            fontWeight: 500,
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => navigate(locationTmpText)}>
                                        {t('calculatorFinancing.mortgage2')}
                                    </Text>
                                    &nbsp;
                                    <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 500 }}>
                                        {t('calculatorFinancing.mortgage3')}
                                    </Text>
                                </div>
                            </Card>
                        )}

                        <Button
                            id="gb-mortgage-buy-situation-mobile"
                            className="button-primary-gibobs-inmo"
                            type="primary"
                            style={{
                                fontSize: 14,
                                height: 40,
                                fontWeight: 600,
                                marginTop: 40
                            }}
                            onClick={() => {
                                if (result) {
                                    if (result?.simulationData?.resultViabilityMortage !== undefined) {
                                        setFinish(true);
                                    } else if (result?.simulationData?.resultViabilityMortage === undefined) {
                                       
                                        navigate( `/calculators/fee/datas`,{
                                            state: {
                                                values: { ...form.getFieldsValue() },
                                                fixed,
                                                quotes,
                                                id: result !== undefined ? result.simulationData.id : undefined
                                            }
                                        });
                                    }
                                } else {
                                   
                                    navigate(`/calculators/fee/datas`,{
                                        state: {
                                            values: { ...form.getFieldsValue() },
                                            fixed,
                                            quotes,
                                            id: result !== undefined ? result.simulation.id : undefined
                                        }
                                    });
                                }
                            }}
                            disabled={
                                result?.simulationData?.resultViabilityMortage?.excepcionalViability !==
                                    undefined ||
                                dataSummary.filter((data) => data.subtype === 'mortgage').length > 0
                                    ? true
                                    : false
                            }>
                            {t(renderLabelButton(rol, result))}
                        </Button>
                    </Col>
                </Row>
            </Card>
        </div>
        <ModalWithoutButtons
            key={'rate'}
            visible={rate}
            onCreate={() => setRate(false)}
            onCancel={() => setRate(false)}
            title={t('loan.rateCurrent')}
            text={t('calculator.modal1')}
        />
        <ModalWithoutButtons
            key={'term'}
            visible={term}
            onCreate={() => setTerm(false)}
            onCancel={() => setTerm(false)}
            title={t('scoring.term')}
            text={t('calculator.modal2')}
        />
        <MortgageAmountModal
            tmp={'amountHp'}
            visible={amountHp}
            result={result}
            onCancel={() => setAmountHp(false)}
        />
        <MortgageAmountModal
            tmp={'pay'}
            visible={pay}
            result={result}
            onCancel={() => setPay(false)}
        />
        <Modal
            className="modal-promoter ModalCommon"
            okButtonProps={{
                className: 'button-primari-gibobs',
                style: { width: '94%' }
            }}
            open={finish}
            width={480}
            title={<img src={calculatorImage} alt="financing" width={'100%'} height="90px" />}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={() => setFinish(false)}
            onOk={() => sendFinish()}>
            <div style={{ marginBottom: 0, marginTop: 30 }}>
                {rol === 'realestate' ? (
                    <Text strong>{t('modal6.titleGood') + ' ' + dataProfile.name}</Text>
                ) : (
                    <div
                        style={{ fontWeight: 600 }}
                        dangerouslySetInnerHTML={{
                            __html: t('calculator.modalQuotaCalculatorClient', { name: dataProfile.name })
                        }}
                    />
                )}
            </div>
            <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                {t('calculator.modalFinish')}
            </Text>
        </Modal>
    </>);
};

export default CalculatorsFeeDesktop;
