import React from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import euro from "../../../../assets/loans/euro.svg";
import calendar from "../../../../assets/loans/calendar.png";

const ModalAmortizationExplination = ({
  visible,
  onCreate,
  onCancel,
  title,
  text,
}) => {
  const { t } = useTranslation();

  const explinations = (title, description, image) => {
    return (
      <div style={{ marginTop: 16 }}>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <img src={image} alt={`${image}`} height={20} width={20} />
          <div style={{ color: "#02C3CD", marginLeft: 11 }}>{t(title)}</div>
        </div>
        <div>{t(description)}</div>
      </div>
    );
  };
  return (
    (<Modal
      className="modal-without-buttons-loans"
      cancelButtonProps={{
        className: "button-secundary-gibobs",
        style: { width: "100%", height: 40 },
      }}
      okButtonProps={{
        className: "button-primari-gibobs",
        style: { width: "96%", height: 40 },
      }}
      width={480}
      open={visible}
      onCancel={onCancel}
      onOk={onCreate}
      title={t(title)}
      centered
    >
      <div
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
      {explinations(
        "loan.amortizeInstallment",
        "loan.amortizeInstallmentDescription",
        euro
      )}
      {explinations(
        "loan.amortizeTermTitle",
        "loan.amortizeTermDescription",
        calendar
      )}
    </Modal>)
  );
};

export default ModalAmortizationExplination;
