import React from 'react';
import { Typography, Card, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import loanInit from '../../../assets/loans/initLoan.png';
import numeral from 'numeral';
import { BankOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import TipsList from '../../../Pages/Financiero/Loans/LoansDesktop/TipsList';
import updateLoan from '../../../assets/loans/updateLoan.png';

const { Text } = Typography;

function DashBoardDataLoanMobile({ myLoansList }) {
	const { t } = useTranslation();
	const navigate = useNavigate();;


	const colSpan = {
		xxl: 6,
		xl: 6,
		lg: 8,
		md: 12,
		sm: 24,
		xs: myLoansList.loans.length === 1 ? 24 : 20
	};

	return (
		<div
			style={{
				display: 'flex',
				overflowX: 'scroll',
				marginLeft: 0,
				marginTop: -15
			}}>
			{myLoansList.loans.map((loan) => {
				return (
					<Col {...colSpan} style={{ marginRight: -15 }}>
						<Card
							className="init-card-mobile"
							onClick={() => {
								if (loan?.version !== "2023.v1" ) {
									navigate(`/my-financial-profile/credits/edit/form/${loan.id}`);
								} else {
									navigate(`/my-financial-profile/credits/all-detail-loan/${loan.id}`);
								}
							}}
							cover={
								<>
									<img
										src={loan?.version !== "2023.v1"  ? updateLoan : loanInit}
										alt="initCard"
										style={{ width: '100%', height: 'auto' }}
									/>
								</>
							}>
							<Row style={{ width: '100%' }}>
								<Col span={4}>
									{loan && loan.bank !== undefined && (
										<img
											src={
												'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/banks/' +
												`${loan.bank.bankCode}` +
												'_iso.png'
											}
											alt="Bank"
											style={{ width: '100%', height: 'auto' }}
										/>
									)}
									{loan && loan.bank === undefined && (
										<BankOutlined
											style={{ fontSize: 30, color: '#02C3CD', marginTop: 10 }}></BankOutlined>
									)}
								</Col>

								<Col
									span={14}
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginLeft: 16
									}}>
									{/* <Text
										style={{
											fontSize: 16,
											color: '#2F4858',
											fontWeight: 600
										}}>
										{t('init.myLoan')}j
									</Text> */}
									{loan.name === undefined ||
										(loan.name === null && (
											<Text className="title-page">
												{t('loan.typeMortgageTitle')}&nbsp;
												{t('loanType.' + loan.rateType)}
											</Text>
										))}

									{loan.name !== undefined && loan.name !== null && (
										<Text className="title-page">{loan.name}</Text>
									)}
									<Text
										style={{
											fontSize: 14,
											color: '#02C3CD',
											fontWeight: 500
										}}>
										{numeral(loan.quota).format('0,0 $') + ' ' + t('balance.savingsToMonth')}
									</Text>
								</Col>
							</Row>
							<TipsList loan={loan}></TipsList>
						</Card>
					</Col>
				);
			})}
		</div>
	);
}

export default DashBoardDataLoanMobile;
