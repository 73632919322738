import React from "react";
import { Modal, Steps } from "antd";
import { useTranslation } from "react-i18next";
import financingPhoto from "../../assets/promoter/financing.svg";
const { Step } = Steps;

export const StepsProcess = (data) => {
  const { t } = useTranslation();

  return (
    <Steps
      direction="vertical"
      size="small"
      current={-1}
      className={"gibobs-steps-financing-promoter"}
      style={{ marginTop: 8 }}
    >
      {data.map((data) => (
        <Step title={t(data.title)} description={t(data.description)} />
      ))}
    </Steps>
  );
};

function ModalFinancingProcess({ visible, onCancel, onCreate, width }) {
  const { t } = useTranslation();

  const data = [
    {
      title: "promoterLoan.modalFinancingStep1",
      description: "promoterLoan.modalFinancingStep1Description",
    },
    {
      title: "promoterLoan.modalFinancingStep2",
      description: "promoterLoan.modalFinancingStep2Description",
    },
    {
      title: "promoterLoan.modalFinancingStep3",
      description: "promoterLoan.modalFinancingStep3Description",
    },
    {
      title: "promoterLoan.modalFinancingStep4",
      description: "promoterLoan.modalFinancingStep4Description",
    },
    {
      title: "promoterLoan.modalFinancingStep5",
      description: "promoterLoan.modalFinancingStep5Description",
    },
  ];

  return (
    (<Modal
      className="modal-promoter ModalCommon "
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{
        className: "button-primari-gibobs",
        style: { textAlign: "center" },
      }}
      open={visible}
      title={<img src={financingPhoto} alt="financing" width={"100%"} />}
      okText={t("form.ok")}
      cancelText={t("Cancelar")}
      width={width}
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        onCreate();
      }}
    >
      <div
        className="title-primary"
        style={{ marginBottom: 24, marginTop: 24 }}
      >
        {t("promoterLoan.financingProcessPromoters")}
      </div>
      <div
        className="title-primary"
        style={{ fontSize: 14, fontWeight: 500, color: "#02C3CD" }}
      >
        {t("promoterLoan.stepsTitleModal")}
      </div>
      {StepsProcess(data)}
    </Modal>)
  );
}

export default ModalFinancingProcess;
