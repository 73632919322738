import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Layout,
  Typography,
  Row,
  Col,
  Card,
  Input,
  Modal,
  Form,
  Button,
} from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  loadFolder,
  createFolder,
  documentRename,
  removeDocument,
} from "../../store/documents/actions";
import { changeModelist } from "../../store/data/actions";
import DocumentItem from "./item";
import bankIcon from "../../assets/icons/bank.svg";
import networkIcon from "../../assets/icons/network.svg";
import padlockIcon from "../../assets/icons/padlock.svg";
import { notification } from "antd";
import { isMobile, isMobileOnly } from "react-device-detect";
import {
  UnorderedListOutlined,
  AppstoreOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import LayoutMeans from "../LayoutMeans";
import CardLinkAmigobs from "../../components/widgets/CardLinksApps/CardLinkAmigobs";

const { Text, Paragraph } = Typography;
const { Content } = Layout;

const DocumentsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const documents = useSelector((state) => state.documents.documents);
  const breadcrumb = useSelector((state) => state.documents.breadcrumb);
  const virtual = useSelector((state) => state.documents.virtual);
  const { folderId } = useParams();
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalRenameVisible, setModalRenameVisible] = useState(false);
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false);
  const [renameDocument, setRenameDocument] = useState({});
  const [deleteDocument, setDeleteDocument] = useState({});
  const location = useLocation();
  const [deleteFolder, setDeleteFolder] = useState(undefined);

  const modeList = useSelector((state) => state.data.modeList);
  const [filterMode, setFilterMode] = useState("name");
  const [fileList, setFileList] = useState(undefined);
  const [filterModeFile, setFilterModeFile] = useState("name");
  const navigate = useNavigate();;

  const nameHP = useSelector((state) =>
    state.data.dataSummary.length > 0
      ? state.data.dataSummary[0].name
      : undefined
  );
  const stage = useSelector((state) =>
    state.data.dataSummary.length > 0
      ? state.data.dataSummary[0].stage
      : undefined
  );
  const [modalVisibleShow, setModalVisibleShow] = useState(false);
  const mortgageIdHp = useSelector((state) =>
    state.data.dataSummary.length > 0
      ? state.data.dataSummary[0].mortgageId
      : undefined
  );
  const rol = useSelector((state) => state.auth.roles);


  useEffect(() => {
    if (
      !folderId &&
      (stage === "documents" || stage === "analysis" || stage === "banks")
    ) {
      setModalVisibleShow(true);
    }
  }, [folderId]);

  useEffect(() => {
    dispatch(loadFolder(folderId));
    getFilesList();
  }, [folderId, location, modeList, filterMode, filterModeFile]);

  const folderCreate = (name) => {
    dispatch(createFolder(name, folderId));
  };

  const rename = (documentId, name) => {
    dispatch(documentRename(documentId, name, folderId));
  };

  const deleteDocumentAction = async (document) => {
    const temp = await dispatch(removeDocument(document.id, folderId));
    if (temp[1] === false) {
      notification.error({
        message: `Notification error`,
        description: t("mydocuments.error"),
        placement: "topRight",
      });
    }
  };

  const folders = documents
    .filter((item) => !item.document)
    .map((item) => {
      return (
        <DocumentItem
          document={item}
          onModalRename={() => {
            setRenameDocument(item);
            form.resetFields();
            setModalRenameVisible(true);
          }}
          onModalDelete={( deleteFolderEmtpy) => {
            setDeleteFolder(deleteFolderEmtpy);
            setDeleteDocument(item);
            form.resetFields();
            setModalDeleteVisible(true);
          }}
          virtual={virtual}
        />
      );
    });

  const folderList = documents
    .filter((item) => !item.document)
    .sort(function (a, b) {
      if (filterMode === "name") {
        return a.name.localeCompare(b.name);
      } else if (filterMode === "date") {
        return a.createdAt.localeCompare(b.createdAt);
      } else if (filterMode === "file") {
        return a.files - b.files;
      } else if (filterMode === "folder") {
        return a.folders - b.folders;
      } else if (filterMode === "name1") {
        return b.name.localeCompare(a.name);
      } else if (filterMode === "date1") {
        return b.createdAt.localeCompare(a.createdAt);
      } else if (filterMode === "file1") {
        return b.files - a.files;
      } else if (filterMode === "folder1") {
        return b.folders - a.folders;
      }
    })
    .map((item) => {
      return (
        <Row>
          <DocumentItem
            document={item}
            onModalRename={() => {
              setRenameDocument(item);
              form.resetFields();
              setModalRenameVisible(true);
            }}
            onModalDelete={( deleteFolderEmtpy) => {
              setDeleteFolder(deleteFolderEmtpy);
              setDeleteDocument(item);
              form.resetFields();
              setModalDeleteVisible(true);
            }}
            virtual={virtual}
          />
        </Row>
      );
    });

  const files2 = documents
    .filter((item) => item.document)
    .map((item) => {
      return (
        <DocumentItem
          document={item}
          onModalRename={() => {
            setRenameDocument(item);
            form.resetFields();
            setModalRenameVisible(true);
          }}
          onModalDelete={() => {
            setDeleteDocument(item);
            form.resetFields();
            setModalDeleteVisible(true);
          }}
          virtual={virtual}
        />
      );
    });

  const getFilesList = () => {
    setFileList(
      documents
        .filter((item) => item.document)
        .sort(function (a, b) {
          if (filterModeFile === "name") {
            return a.name.localeCompare(b.name);
          } else if (filterModeFile === "date") {
            return a.createdAt.localeCompare(b.createdAt);
          } else if (filterModeFile === "name1") {
            return b.name.localeCompare(a.name);
          } else if (filterModeFile === "date1") {
            return b.createdAt.localeCompare(a.createdAt);
          }
        })
        .map((item) => {
          return (
            <DocumentItem
              document={item}
              onModalRename={() => {
                setRenameDocument(item);
                form.resetFields();
                setModalRenameVisible(true);
              }}
              onModalDelete={() => {
                setDeleteDocument(item);
                form.resetFields();
                setModalDeleteVisible(true);
              }}
              virtual={virtual}
            />
          );
        })
    );
  };
  //const breadcrumbs = [];
  const dataBread = [
    {
      name: t("mydocuments.title"),
      link: "/documents",
    },
  ];

  breadcrumb.forEach((document) => {
    dataBread.push({
      name: t(`mydocuments.${document.name}`, document.name),
      link: `/documents/${document.id}`,
    });
  });

  const handleClickModeList = () => {
    dispatch(changeModelist(!modeList));
  };

  const handleModalOk = () => {
    navigate(`/operations/${mortgageIdHp}/${stage}`);
  };

  const onCancel = () => {
    setModalVisibleShow(false);
  };

  return (
    (<Layout className="documents" style={{ marginBottom: isMobile ? 300 : "100px" }}>
      {/* <MyBreadcrumb data={dataBread} /> */}
      <LayoutMeans folderId={breadcrumb.map((data) => data.id)} rol={rol} />

      {isMobileOnly && rol !== "realestate" &&

        <CardLinkAmigobs></CardLinkAmigobs>

      }
      <Content
        style={{
          marginTop: 10,
          marginLeft: isMobileOnly ? 3 : 40,
          marginRight: -20,
          width: "93%",
        }}
      >
        <Modal
          className="ModalCommon"
          cancelButtonProps={{
            style: {
              border: "none",
              color: "#02C3CD",
              fontSize: 12,
              fontWeight: 500,
            },
          }}
          okButtonProps={{
            style: {
              background: "#02C3CD",
              color: "white",
              width: 96,
              height: 32,
            },
          }}
          open={modalVisibleShow}
          title={t("form.ModalDocuments")}
          okText={t("form.yes")}
          cancelText={t("form.ModalDocumentsCancel")}
          width={496}
          onCancel={() => {
            onCancel();
          }}
          onOk={() => {
            handleModalOk();
          }}
        >
          <Text style={{ fontWeight: 400, fontSize: 12, color: "#2F4858" }}>
            {t("form.ModalDocumentsDesc") + nameHP} ?
          </Text>
        </Modal>

        <Card
          className="gcard4"
          style={{ position: "relative", left: -40, width: "100%" }}
          key={"rde"}
        >
          <img
            width={16}
            src={padlockIcon}
            style={{ position: "relative", top: 0 }}
          />
          <Text
            style={{
              fontSize: "12px",
              color: "#748EA0",
              marginLeft: 14,
            }}
          >
            {t("mydocuments.lock")}
          </Text>
        </Card>

        <Row gutter={40}>
          <Col
            flex="1"
            style={{ paddingLeft: isMobileOnly ? 0 : 20, width: 100 }}
          >
            <Card
              className="gcard"
              style={{
                marginBottom: isMobileOnly ? 32 : undefined,
                marginLeft: isMobileOnly ? "auto" : undefined,
                marginRight: isMobileOnly ? "auto" : undefined,
                width: isMobileOnly ? "90%" : undefined,
              }}
            >
              {!modeList && (
                <Button
                  style={{ border: "none", fontSize: 24, float: "right" }}
                  onClick={() => handleClickModeList()}
                >
                  <UnorderedListOutlined />
                </Button>
              )}
              {modeList && (
                <Button
                  style={{ border: "none", fontSize: 24, float: "right" }}
                  onClick={() => handleClickModeList()}
                >
                  <AppstoreOutlined />
                </Button>
              )}
              {!modeList && (
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Text className="title-label">
                      {t("mydocuments.folders")}
                    </Text>
                    <div className="document-container">
                      {folders}
                      {virtual !== true && (
                        <DocumentItem
                          isAddItem={true}
                          addItemType="folder"
                          onModal={() => {
                            setModalVisible(true);
                          }}
                          folderId={folderId}
                          modeList={modeList}
                        />
                      )}
                    </div>
                    <Text className="title-label">
                      {t("mydocuments.files")}
                    </Text>
                    <div className="document-container">
                      {files2}
                      {virtual !== true && (
                        <DocumentItem
                          isAddItem={true}
                          addItemType="file"
                          folderId={folderId}
                          modeList={modeList}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              )}
              {modeList && (
                <Col span={24} className="scrollListDocuments">
                  <Text className="title-label">
                    {t("mydocuments.folders")}

                    <div
                      style={{
                        display: "flex",
                        marginTop: 10,
                        fontSize: 12,
                        fontWeight: 600,
                        width: 800,
                      }}
                    >
                      <Text className="titleFolder" style={{ marginRight: 4 }}>
                        {t("form.name")}
                      </Text>
                      <div
                        className="filter"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: 1,
                        }}
                      >
                        <CaretUpOutlined
                          onClick={() => setFilterMode("name")}
                          style={{ marginBottom: -3, color: "#80E1E6" }}
                        />
                        <CaretDownOutlined
                          onClick={() => setFilterMode("name1")}
                          style={{ color: "#80E1E6" }}
                        />
                      </div>
                      <Text
                        className="titleFolder"
                        style={{
                          width: 200,
                          marginLeft: 165,
                          marginRight: -12,
                        }}
                      >
                        {t("mydocuments.createDate")}
                      </Text>
                      <div
                        className="filter"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: 85,
                          marginLeft: -75,
                        }}
                      >
                        <CaretUpOutlined
                          onClick={() => setFilterMode("date")}
                          style={{ marginBottom: -3, color: "#80E1E6" }}
                        />
                        <CaretDownOutlined
                          onClick={() => setFilterMode("date1")}
                          style={{ color: "#80E1E6" }}
                        />
                      </div>
                      <Text
                        className="titleFolder"
                        style={{ width: 200, marginLeft: -4, marginRight: -3 }}
                      >
                        {t("mydocuments.numberFile")}
                      </Text>
                      <div
                        className="filter"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: 50,
                          marginLeft: -75,
                        }}
                      >
                        <CaretUpOutlined
                          onClick={() => setFilterMode("file")}
                          style={{ marginBottom: -3, color: "#80E1E6" }}
                        />
                        <CaretDownOutlined
                          onClick={() => setFilterMode("file1")}
                          style={{ color: "#80E1E6" }}
                        />
                      </div>
                      <Text
                        className="titleFolder"
                        style={{ width: 200, marginLeft: 15, marginRight: 7 }}
                      >
                        {t("mydocuments.numberFolder")}
                      </Text>
                      <div
                        className="filter"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: 0,
                          marginLeft: -85,
                        }}
                      >
                        <CaretUpOutlined
                          onClick={() => setFilterMode("folder")}
                          style={{ marginBottom: -3, color: "#80E1E6" }}
                        />
                        <CaretDownOutlined
                          onClick={() => setFilterMode("folder1")}
                          style={{ color: "#80E1E6" }}
                        />
                      </div>
                    </div>
                  </Text>

                  <div className="document-container-mode-list">
                    {folderList}
                    {virtual !== true && (
                      <DocumentItem
                        isAddItem={true}
                        addItemType="folder"
                        onModal={() => {
                          setModalVisible(true);
                        }}
                        folderId={folderId}
                        modeList={modeList}
                      />
                    )}
                  </div>
                  <Text className="title-label">{t("mydocuments.files")}</Text>
                  <div
                    style={{
                      display: "flex",
                      marginTop: 10,
                      fontSize: 12,
                      fontWeight: 600,
                      width: 800,
                    }}
                  >
                    <Text className="titleFolder">{t("form.name")}</Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: 1,
                      }}
                    >
                      <CaretUpOutlined
                        onClick={() => setFilterModeFile("name")}
                        style={{ marginBottom: -3, color: "#80E1E6" }}
                      />
                      <CaretDownOutlined
                        onClick={() => setFilterModeFile("name1")}
                        style={{ color: "#80E1E6" }}
                      />
                    </div>
                    <Text
                      className="titleFolder"
                      style={{ width: 200, marginLeft: 185, marginRight: -10 }}
                    >
                      {t("mydocuments.createDate")}
                    </Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: 85,
                        marginLeft: -75,
                      }}
                    >
                      <CaretUpOutlined
                        onClick={() => setFilterModeFile("date")}
                        style={{ marginBottom: -3, color: "#80E1E6" }}
                      />
                      <CaretDownOutlined
                        onClick={() => setFilterModeFile("date1")}
                        style={{ color: "#80E1E6" }}
                      />
                    </div>
                  </div>
                  <div className="document-container-mode-list">
                    {fileList}
                    {virtual !== true && (
                      <DocumentItem
                        isAddItem={true}
                        addItemType="file"
                        folderId={folderId}
                        modeList={modeList}
                      />
                    )}
                  </div>
                </Col>
              )}
            </Card>
          </Col>
          <Col flex={isMobileOnly ? undefined : "250px"}>
            <Card className="gcard tips">
              <Row>
                <Col flex={24}>
                  <Text className="title-label">
                    {t("mydocuments.bannerProtected")}
                  </Text>
                  <Paragraph className="tip-p">
                    <img src={padlockIcon} />
                    <br />
                    {t("mydocuments.bannerProtectedText")}
                  </Paragraph>
                  <Paragraph className="tip-p">
                    <img src={networkIcon} />
                    <br />
                    {t("mydocuments.bannerAwsText")}
                  </Paragraph>
                  <Paragraph className="tip-p">
                    <img src={bankIcon} />
                    <br />
                    {t("mydocuments.bannerBankText")}
                  </Paragraph>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
      <Modal
        className="ModalCommonSmall"
        cancelButtonProps={{
          className: "button-secundary-gibobs",
          style: { width: "96px" },
        }}
        okButtonProps={{
          className: "button-primari-gibobs",
          style: { width: "96px", padding: 0 },
        }}
        open={modalVisible}
        title="Crear nueva carpeta"
        okText="Crear"
        cancelText="Cancelar"
        destroyOnClose={true}
        onCancel={() => {
          setModalVisible(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              folderCreate(values.name);
              form.resetFields();
              setModalVisible(false);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Carpeta"
            rules={[
              { required: true, message: t("mydocuments.formFolderRequire") },
            ]}
          >
            <Input
              style={{ width: "100%" }}
              placeholder={t("mydocuments.formFolderName")}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        className="ModalCommonSmall"
        cancelButtonProps={{
          className: "button-secundary-gibobs",
          style: { width: "96px" },
        }}
        okButtonProps={{
          className: "button-primari-gibobs",
          style: { width: "96px", padding: 0 },
        }}
        open={modalRenameVisible}
        title={t("mydocuments.rename")}
        okText={t("mydocuments.renameButton")}
        cancelText={t("mydocuments.deleteCancel")}
        destroyOnClose={true}
        onCancel={() => {
          setModalRenameVisible(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              rename(values.documentId, values.name);
              form.resetFields();
              setModalRenameVisible(false);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            name: renameDocument.name,
            documentId: renameDocument.id,
          }}
        >
          <Form.Item
            name="name"
            label="Carpeta"
            rules={[
              { required: true, message: t("mydocuments.formElementRequire") },
            ]}
          >
            <Input
              style={{ width: "100%" }}
              placeholder={t("mydocuments.formElementName")}
            />
          </Form.Item>
          <Form.Item hidden={true} name="documentId">
            <Input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        className="ModalCommonSmall ModalCommonSmallDelete"
        cancelButtonProps={{
          className: "button-secundary-gibobs",
          style: { width: "96px" },
        }}
        okButtonProps={{
          className: "button-primari-delete-gibobs",
          style: { width: "96px", padding: 0, borderColor: "#ff4d4f" },
        }}
        open={modalDeleteVisible}
        title={t("mydocuments.deleteTitle")}
        okText={t("mydocuments.deleteOk")}
        cancelText={t("mydocuments.deleteCancel")}
        destroyOnClose={true}
        onCancel={() => {
          setModalDeleteVisible(false);
        }}
        onOk={() => {
          deleteDocumentAction(deleteDocument);
          setModalDeleteVisible(false);
        }}
      >
        {deleteFolder && deleteFolder.isEmpty ? (
          <Text>{t("mydocuments.deleteFolderText")}</Text>
        ) : (
          <Text>{t("mydocuments.deleteFolderEmpty")}</Text>
        )}
      </Modal>
    </Layout>)
  );
};
export default DocumentsPage;
