import React from "react";
import { Form, Modal, Input, Col, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import DownOutlinedMobile from "../assets/icons/DownOutlinedMobile.svg";
import { useSelector } from "react-redux";
const { TextArea } = Input;
const { Option } = Select;

const ContactoPage = ({
  visible,
  onCancel,
  onCreate,
  // dataPersonal,
  realState,
  title,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dataPersonal = useSelector((state) => state.data.dataProfile);
  const contactTimeName = {
    morning: "Mañanas",
    afternoon: "Mediodias",
    evening: "Tardes",
  };
  const validateMessages = {
    required: "${label}" + " " + t("form.formRequired"),
    types: {
      email: t("form.emailValidated"),
      number: "${label} is not a valid number!",
    },
    number: {
      range: t("form.maxBudgetNewRequestValidated") + "${min}",
    },
  };
  return (
    (<Modal
      className="ModalCommon"
      cancelButtonProps={{ className: "button-secundary-gibobs" }}
      okButtonProps={{ className: "button-primari-gibobs" }}
      open={visible}
      title={realState ? t("form.infoProducts") : t("form.contactUs")}
      okText={t("form.send")}
      cancelText={t("mydocuments.deleteCancel")}
      width={1000}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
            form.resetFields();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="Contacto"
        initialValues={{
          name: dataPersonal ? dataPersonal.name : "",
          surname: dataPersonal ? dataPersonal.surname : "",
          email: dataPersonal ? dataPersonal.email : "",
          phone: dataPersonal ? dataPersonal.phone : "",
          companyName: dataPersonal ? dataPersonal.companyName : "",
          contactTime: dataPersonal
            ? contactTimeName[dataPersonal.contactTime]
            : "",
          comments: "",
        }}
        style={{
          marginRight: "auto",
          marginLeft: "auto",
        }}
        validateMessages={validateMessages}
      >
        <Row gutter={24}>
          <Col lg={8} xs={24}>
            <Form.Item
              name="name"
              label={t("form.name")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                placeholder={t("form.name")}
                style={{
                  width: isMobile ? "100%" : "280px",
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={8} xs={24}>
            <Form.Item
              name="surname"
              label={t("form.surname")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                placeholder={t("form.surname")}
                style={{
                  width: isMobile ? "100%" : "280px",
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={8} xs={24}>
            <Form.Item
              name="email"
              label={t("form.email")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                placeholder={t("form.email")}
                style={{
                  width: isMobile ? "100%" : "280px",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col lg={8} xs={24}>
            <Form.Item
              name="phone"
              label={t("form.phone")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                placeholder={t("form.phone")}
                style={{
                  width: isMobile ? "100%" : "280px",
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={8} xs={24}>
            {realState ? (
              <Form.Item name="contactReason" label="Producto Financiero">
                <Select
                  suffixIcon={
                    <img src={DownOutlinedMobile} alt="DownOutlinedMobile" />
                  }
                  defaultValue={title}
                  style={{ width: isMobile ? "100%" : "280px" }}
                >
                  <Option value="Préstamo promotor">
                    {t("productsComponent.promoterLoan")}
                  </Option>
                  <Option value="Líneas de crédito">
                    {t("productsComponent.creditLines")}
                  </Option>
                  <Option value="Hipotecas de empresa">
                    {t("productsComponent.businessMortgages")}
                  </Option>
                  <Option value="Proyecto solar">
                    {t("productsComponent.solarProject")}
                  </Option>
                </Select>
              </Form.Item>
            ) : (
              <Form.Item
                name="contactReason"
                defaultValue="Seleccione"
                label={t("form.contactReason")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  suffixIcon={
                    <img src={DownOutlinedMobile} alt="DownOutlinedMobile" />
                  }
                  style={{ width: isMobile ? "100%" : "280px" }}
                >
                  <Option value="Problemas con mi cuenta">
                    {t("form.problemAccount")}
                  </Option>
                  <Option value="Necesito asesoramiento hipotecario">
                    {t("form.needAdvice")}
                  </Option>
                  <Option value="Quiero que me llamen">
                    {t("form.wantCalled")}
                  </Option>
                  <Option value="Tengo dudas sobre algún servicio de gibobs">
                    {t("form.doubtsGibobs")}
                  </Option>
                  <Option value="otro">{t("form.others")}</Option>
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col lg={8} xs={24}>
            <Form.Item name="contactTime" label={t("form.contactTime")}>
              <Select
                suffixIcon={
                  <img src={DownOutlinedMobile} alt="DownOutlinedMobile" />
                }
                style={{ width: isMobile ? "100%" : "280px" }}
              >
                <Option value="morning">{t("form.morning")}</Option>
                <Option value="afternoon">{t("form.afternoon")}</Option>
                <Option value="evening">{t("form.evening")}</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {realState ? (
          <Row gutter={24}>
            <Col lg={8} xs={24}>
              <Form.Item
                name="companyName"
                label={t("form.companyName")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder={t("form.companyName")}
                  style={{
                    width: isMobile ? "100%" : "280px",
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={8} xs={24}>
              <Form.Item
                name="positionInCompany"
                label={t("form.positionInCompany")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder={t("form.positionInCompany")}
                  style={{
                    width: isMobile ? "100%" : "280px",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        ) : null}

        <Form.Item
          name="comments"
          label={realState ? t("form.description") : t("form.comments")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea
            style={{
              height: "200px",
              borderRadius: "4px",
              borderColor: "#C0DAEE",
              fontSize: "12px",
              width: "100%",
            }}
            type="text"
            placeholder={realState ? t("form.doubt") : t("form.details")}
          />
        </Form.Item>
      </Form>
    </Modal>)
  );
};

export default ContactoPage;
