import React, { useContext, useState, useEffect, useRef } from "react";
import { Table, Input, Button, Form, Typography, Popover } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import numeral from "numeral";
import { useTranslation } from "react-i18next";

const EditableContext = React.createContext(null);
const { Text } = Typography;

const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableTablePassive = ({
  name,
  icon,
  showAddModal,
  taskEditHandler,
  index,
  dataToSource,
  showDeleteConfirm,
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: "Nombre",
      width: "30%",
      dataIndex: "name",
      editable: true,
    },
    {
      title: "Plazo",
      dataIndex: "term",
      editable: true,
    },
    {
      title: "Interes",
      dataIndex: "interest",
      editable: true,
    },
    {
      title: "Pendiente",
      dataIndex: "pending",
      editable: true,
    },
  ];

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  const interestName = {
    large: "common.large",
    medium: "common.medium",
    short: "common.short",
  };

  const dataSource = dataToSource.map((dataObj, i) => {
    return {
      id: dataObj.id,
      key: i + `${dataObj.name}`,
      name: (
        <Popover
          overlayClassName="popOverTableEditable"
          placement="right"
          content={
            <div>
              <EditOutlined
                style={{ color: "#02C3CD", marginRight: "10px" }}
                onClick={() => taskEditHandler(dataObj.id)}
              />
              <DeleteOutlined
                style={{ color: "#02C3CD" }}
                onClick={() => showDeleteConfirm(dataObj.id)}
              />
            </div>
          }
          trigger="hover"
        >
          {dataObj.name}
        </Popover>
      ),
      term: (
        <div style={{ color: "#748EA0" }}>{t(interestName[dataObj.term])}</div>
      ),
      interest: (
        <div style={{ color: "#748EA0" }}>{`${dataObj.interest} %`}</div>
      ),
      pending: (
        <div style={{ color: "#748EA0" }}>
          {numeral(dataObj.pending).format("0,0 $")}
        </div>
      ),
      value: numeral(dataObj.value).format("0,0 $"),
    };
  });

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          alignSelf: "center",
          width: "35%",
          display: "flex",
          marginLeft: "16px",
        }}
      >
        <Button
          type="primary"
          className="button-primari-gibobs"
          icon={
            <PlusOutlined style={{ display: "flex", placeContent: "center" }} />
          }
          style={{
            width: "24px",
            height: "24px",
            marginRight: "16px",
            placeSelf: "center",
          }}
          onClick={() => showAddModal(index)}
        />
        <Text
          style={{
            color: "#2F4858",
            fontSize: "12px",
            marginRight: "8px",
            alignSelf: "center",
          }}
        >
          {name}
        </Text>
        <div style={{ color: "#2F4858", fontSize: "16px" }}>{icon}</div>
      </div>
      {dataSource.length !== 0 ? (
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          dataSource={dataSource}
          columns={columns}
          showHeader={false}
          pagination={false}
          style={{ width: "100%" }}
        />
      ) : (
        <div style={{ height: "30px", marginBottom: "10px" }}></div>
      )}
    </div>
  );
};

export default EditableTablePassive;
