import React, { useState, useEffect } from "react";
import { Typography, Steps, Progress, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import axios from "axios";
import env from "../../environment";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";


import _ from "lodash";
import {
  DoubleRightOutlined,
  LockOutlined,
} from "@ant-design/icons";
const { Text } = Typography;
const { Step } = Steps;
const { TabPane } = Tabs;


const DataBasicINMO = ({ dataMortgage, userMortgageId, stages, status }) => {
  const { t } = useTranslation();
  const userToken = useSelector((state) => state.auth.token);
  const [existAppraisal, setExistAppraisal] = useState(false);
  const [fein, setfein] = useState(false);
  const [banks, setBanks] = useState(false);
  const [dataScoring, setDataScoring] = useState();
  const [valuesDataForMortgage, setValuesDataForMortgage] = useState([]);
  const [participants, setParticipants] = useState([]);
  const valuesData = [];
  const [clickTab, setClickTab] = useState("owner1");

  useEffect(() => {
    dataValueInfoUpload();
    dataValueBanks();
    dataScoringCall();
    dataParticipants()

    axios
      .get(
        `${env.api.url}/v1/documents/request-operation-documentation/${userMortgageId}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        setValuesDataForMortgage(response.data.data);
        // setPercentN(
        //   (response.data.data.documentsCompleted * 100) /
        //   response.data.data.documentsNeeded
        // );
      })
      .catch((error) => {  console.log(error)});

  }, []);

  const dataParticipants = () => {
    axios
      .get(`${env.api.url}/v1/mortgages/data/get/${userMortgageId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setParticipants(response.data.data);
      })
      .catch((error) => {
        console.log(error)
       });
  }

  const dataScoringCall = () => {
    axios
      .post(`${env.api.url}/mortgages/conventional-viability-mortgage/get-viability/${userMortgageId}`,{}, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setDataScoring(response.data.data);
      });
  };


  const dataValueBanks = () => {
    axios
      .get(
        `${env.api.url}/v1/banks/get-operation-bank-requests/${userMortgageId}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        setBanks(response.data.data.find((bank) => bank.status === "assigned"));
      })
      .catch((error) => { 
        console.log(error)
      });
  };

  const dataValueInfoUpload = () => {
    axios
      .get(
        `${env.api.url}/v1/documents/get-operation-documents/${userMortgageId}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        setExistAppraisal(
          response.data.data.some((value) => value.type === "appraisal")
        );

        setfein(response.data.data.some((value) => value.type === "fein"));
      })
      .catch((error) => {
        console.log(error)
       });
  };

  const renderDocumentInformation = (index, type) => {
    valuesData.filter((item) => {
      let documentFlag = false;

      if (item.owner === `${type}${index}`) {
        documentFlag = true;
      } else if (type === "owner" && index === 1 && !item.owner) {
        documentFlag = true;
      }

      return documentFlag;
    });
  }

  const renderTabs = (index, type) => {

    return (
      <TabPane
        tab={
          <span>


            {t(`mydocuments.${type}${index}`)}
          </span>
        }
        key={`${type}${index}`}
      >
        {renderDocumentInformation(index, type)}
      </TabPane>
    );
  };

  const numParticipants = parseInt(
    _.get(participants, "mortgage.participants", 0)
  );
  const numGuarantors = parseInt(_.get(participants, "mortgage.guarantors", 0));

  const documentTabs = [];

  for (let i = 1; i <= numParticipants; i++) {
    documentTabs.push(renderTabs(i, "owner"));
  }

  for (let i = 1; i <= numGuarantors; i++) {
    documentTabs.push(renderTabs(i, "guarantor"));
  }

  return (
    <div>
      {stages === "basic" &&
        !isMobile && (
          <div>
            <div>
              <Text style={{ fontSize: "12px" }}>
                <div style={{ width: "550px" }}>{t("details.basic")}</div>
              </Text>
            </div>
            <div
              className="ProgressBar"
              style={{
                width: "72px",
                height: "72px",
                float: "right",
                marginRight: "2px",
                marginTop: "-90px",
              }}
            >
              <Progress
                type="dashboard"
                strokeColor={"#52C41A"}
                trailColor={"#F1F7F8"}
                percent={dataScoring && dataScoring.viability !== null
                  ? (dataScoring.viability * 100) / 5
                  : "0"}
                format={() => (
                  <div>
                    <Text
                      strong
                      style={{
                        fontSize: "24px",
                        color: "#2F4858",
                        marginBottom: "-4px",
                        display: "grid",
                        marginTop: -45,
                      }}
                    >
                      {dataScoring && dataScoring.viability !== null
                        ? dataScoring.viability
                        : "0"}
                    </Text>
                    <Text
                      style={{
                        fontSize: "14px",
                        color: "#2F4858",
                        marginTop: -20,
                      }}
                    >{`de 5`}</Text>
                  </div>
                )}
                width={132}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "4px",
                  marginBottom: "24px",
                  color: "black",
                }}
              />
            </div>
          </div>
        )}
      {stages === "basic" &&
        isMobile && (
          <div>

            <div
              className="ProgressBar"
              style={{
                width: "72px",
                height: "72px",
                marginRight: "2px",
                display: "inline"
              }}
            >
              <Progress
                type="dashboard"
                strokeColor={"#52C41A"}
                trailColor={"#F1F7F8"}
                percent={dataScoring && dataScoring.viability !== null
                  ? (dataScoring.viability * 100) / 5
                  : "0"}
                format={() => (
                  <div>
                    <Text
                      strong
                      style={{
                        fontSize: "32px",
                        color: "#2F4858",
                        marginBottom: "-4px",
                        display: "grid",
                        marginTop: 10,

                      }}
                    >
                      {dataScoring && dataScoring.viability !== null
                        ? dataScoring.viability
                        : "0"}
                    </Text>
                    <Text
                      style={{
                        fontSize: "14px",
                        color: "#2F4858",
                        marginTop: -16,
                      }}
                    >{`de 5`}</Text>
                  </div>
                )}
                width={132}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "4px",
                  marginBottom: "5px",
                  color: "black",
                }}
              />
            </div>
            <div>
              <Text style={{ fontSize: "12px" }}>
                <div style={{ width: "100%", fontSize: 14, fontWeight: 400, color: "#02C3CD", textAlign: "center", marginBottom: 24 }}>{dataMortgage && dataMortgage.owner1 && dataMortgage.owner1.name}</div>
              </Text>
            </div>
            <div>
              {status !== "finish" &&
                <div style={{ display: "flex", justifyContent: "center", marginTop: -10, marginBottom: 25 }}>
                  <DoubleRightOutlined style={{ color: 'green', fontSize: 16, }}></DoubleRightOutlined>
                  <Text style={{ fontSize: "12px", color: 'green', marginLeft: 5 }}>
                    {t("operationStatus.open")}
                  </Text>
                </div>

              }
              {status === "finish" &&
                <div style={{ display: "flex", justifyContent: "center", marginTop: -10, marginBottom: 25 }}>
                  <LockOutlined style={{ color: '#1890FF', fontSize: 16, }}></LockOutlined>
                  <Text style={{ fontSize: "12px", color: '#1890FF', marginLeft: 5 }}>
                    {t("operationStatus.closed")}
                  </Text>
                </div>

              }
            </div>
            <div>
              <Text style={{ fontSize: "12px" }}>
                <div style={{ width: "100%", fontSize: 14, fontWeight: 600, textAlign: "left", color: "#2F4858", marginBottom: 16 }}>{t("Stages.basic")}</div>
              </Text>
            </div>
            <div>
              <Text style={{ fontSize: "12px" }}>
                <div style={{ width: "105%", fontSize: 12, fontWeight: 400, textAlign: "left", color: "#748EA0", marginBottom: -16 }}>{t("details.basicMobile")}</div>
              </Text>
            </div>
          </div>
        )}
      {stages === "full" && !isMobile && (
        <div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "550px" }}>{t("details.full")}</div>
            </Text>
          </div>
          <div
            className="ProgressBar"
            style={{
              width: "72px",
              height: "72px",
              float: "right",
              marginRight: "2px",
              marginTop: "-90px",
            }}
          >
            <Progress
              type="dashboard"
              strokeColor={"#52C41A"}
              trailColor={"#F1F7F8"}
              percent={dataScoring && dataScoring.viability !== null
                ? (dataScoring.viability * 100) / 5
                : "0"}
              format={() => (
                <div>
                  <Text
                    strong
                    style={{
                      fontSize: "24px",
                      color: "#2F4858",
                      marginBottom: "-4px",
                      display: "grid",
                      marginTop: -45,
                    }}
                  >{dataScoring && dataScoring.viability !== null
                    ? dataScoring.viability
                    : "0"}</Text>
                  <Text
                    style={{
                      fontSize: "14px",
                      color: "#2F4858",
                      marginTop: -20,
                    }}
                  >{`de 5`}</Text>
                </div>
              )}
              width={132}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "4px",
                marginBottom: "24px",
                color: "black",
              }}
            />
          </div>
        </div>
      )}

      {stages === "full" && isMobile && (
        <div>

          <div
            className="ProgressBar"
            style={{
              width: "72px",
              height: "72px",
              marginRight: "2px",
              marginTop: "-90px",
              display: "inline"
            }}
          >
            <Progress
              type="dashboard"
              strokeColor={"#52C41A"}
              trailColor={"#F1F7F8"}
              percent={dataScoring && dataScoring.viability !== null
                ? (dataScoring.viability * 100) / 5
                : "0"}
              format={() => (
                <div>
                  <Text
                    strong
                    style={{
                      fontSize: "32px",
                      color: "#2F4858",
                      marginBottom: "-4px",
                      display: "grid",
                      marginTop: 10,

                    }}
                  >
                    {dataScoring && dataScoring.viability !== null
                      ? dataScoring.viability
                      : "0"}
                  </Text>
                  <Text
                    style={{
                      fontSize: "14px",
                      color: "#2F4858",
                      marginTop: -20,
                    }}
                  >{`de 5`}</Text>
                </div>
              )}
              width={132}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "4px",
                marginBottom: "5px",
                color: "black",
              }}
            />
          </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "100%", fontSize: 14, fontWeight: 400, color: "#02C3CD", textAlign: "center", marginBottom: 24 }}>{dataMortgage && dataMortgage.owner1 && dataMortgage.owner1.name}</div>
            </Text>
          </div>
          <div>
              {status !== "finish" &&
                <div style={{ display: "flex", justifyContent: "center", marginTop: -10, marginBottom: 25 }}>
                  <DoubleRightOutlined style={{ color: 'green', fontSize: 16, }}></DoubleRightOutlined>
                  <Text style={{ fontSize: "12px", color: 'green', marginLeft: 5 }}>
                    {t("operationStatus.open")}
                  </Text>
                </div>

              }
              {status === "finish" &&
                <div style={{ display: "flex", justifyContent: "center", marginTop: -10, marginBottom: 25 }}>
                  <LockOutlined style={{ color: '#1890FF', fontSize: 16, }}></LockOutlined>
                  <Text style={{ fontSize: "12px", color: '#1890FF', marginLeft: 5 }}>
                    {t("operationStatus.closed")}
                  </Text>
                </div>

              }
            </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "100%", fontSize: 14, fontWeight: 600, textAlign: "left", color: "#2F4858", marginBottom: 16 }}>{t("stages.profile")}</div>
            </Text>
          </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "105%", fontSize: 12, fontWeight: 400, textAlign: "left", color: "#748EA0", marginBottom: -120 }}>{t("details.fullMobile")}</div>
            </Text>
          </div>
        </div>
      )}


      {stages === "documents" && isMobile && (
        <div>

          <div
            className="ProgressBar"
            style={{
              width: "72px",
              height: "72px",
              marginRight: "2px",
              marginTop: "-90px",
              display: "inline"
            }}
          >
            <Progress
              type="dashboard"
              strokeColor={"#52C41A"}
              trailColor={"#F1F7F8"}
              percent={dataScoring && dataScoring.viability !== null
                ? (dataScoring.viability * 100) / 5
                : "0"}
              format={() => (
                <div>
                  <Text
                    strong
                    style={{
                      fontSize: "32px",
                      color: "#2F4858",
                      marginBottom: "-4px",
                      display: "grid",
                      marginTop: 10,
                    }}
                  >
                    {dataScoring && dataScoring.viability !== null
                      ? dataScoring.viability
                      : "0"}
                  </Text>
                  <Text
                    style={{
                      fontSize: "14px",
                      color: "#2F4858",
                      marginTop: -20,
                    }}
                  >{`de 5`}</Text>
                </div>
              )}
              width={132}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "4px",
                marginBottom: "5px",
                color: "black",
              }}
            />
          </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "100%", fontSize: 14, fontWeight: 400, color: "#02C3CD", textAlign: "center", marginBottom: 24 }}>{dataMortgage && dataMortgage.owner1 && dataMortgage.owner1.name}</div>
            </Text>
          </div>
          <div>
              {status !== "finish" &&
                <div style={{ display: "flex", justifyContent: "center", marginTop: -10, marginBottom: 25 }}>
                  <DoubleRightOutlined style={{ color: 'green', fontSize: 16, }}></DoubleRightOutlined>
                  <Text style={{ fontSize: "12px", color: 'green', marginLeft: 5 }}>
                    {t("operationStatus.open")}
                  </Text>
                </div>

              }
              {status === "finish" &&
                <div style={{ display: "flex", justifyContent: "center", marginTop: -10, marginBottom: 25 }}>
                  <LockOutlined style={{ color: '#1890FF', fontSize: 16, }}></LockOutlined>
                  <Text style={{ fontSize: "12px", color: '#1890FF', marginLeft: 5 }}>
                    {t("operationStatus.closed")}
                  </Text>
                </div>

              }
            </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "100%", fontSize: 14, fontWeight: 600, textAlign: "left", color: "#2F4858", marginBottom: 16 }}>{t("stages.documents")}</div>
            </Text>
          </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "105%", fontSize: 12, fontWeight: 400, textAlign: "left", color: "#748EA0", marginBottom: -120 }}>{t("details.documentsMobile")}</div>
            </Text>
          </div>

          <div style={{ marginTop: 150 }}>

            <Tabs
              type="card"
              style={{
                color: "#2F4858",
                fontStyle: "normal",
                fontWeight: "normal",
              }}
              onChange={(values) => setClickTab(values)}
            >
              {documentTabs}


            </Tabs>



            <div
              style={{
                width: "100%",
                height: "auto",
                fontSize: "12px",
                backgroundColor: "white",
                marginTop: "6px",
                marginLeft: "-5px",
                float: "left",
                color: "#748EA0",
              }}
            >
              {valuesDataForMortgage.documents
                ? valuesDataForMortgage.documents.map((data) => {
                  if (
                    data.completed.owner1 === false &&
                    clickTab === "owner1" &&
                    data.group !== "operation"
                  ) {
                    return (
                      <div
                        style={{
                          marginBottom:
                            data.participants.owner1 === "required" ? -15 : 0,
                          marginTop:
                            data.participants.owner1 === "required" ? -1 : 0,
                          whiteSpace: "nowrap",
                          marginLeft: "5px",
                          fontWeight:
                            data.participants.owner1 === "required"
                              ? "bold"
                              : undefined,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {data.participants.owner1 === "required" ||
                          data.participants.owner1 === "optional"
                          ? data.title
                          : null}
                        {data.participants.owner1 === "required" ||
                          data.participants.owner1 === "optional" ? (
                          <div
                            style={{
                              borderTop: "1px solid #F1F7F8",
                              width: "100%",
                              marginBottom: 10,
                              overflow: "hidden",
                            }}
                          ></div>
                        ) : null}
                        {data.participants.owner1 === "required" ? (
                          <div
                            style={{
                              float: "right",
                              display: "flex",
                              justifyContent: "flex-end",
                              position: "relative",
                              top: -35,
                            }}
                          >

                          </div>
                        ) : undefined}
                      </div>
                    );
                  } else if (
                    data.completed.owner2 === false &&
                    clickTab === "owner2" &&
                    data.group !== "operation"
                  ) {
                    return (
                      <div
                        style={{
                          marginBottom:
                            data.participants.owner2 === "required" ? -15 : 0,
                          marginTop:
                            data.participants.owner2 === "required" ? -1 : 0,
                          whiteSpace: "nowrap",
                          marginLeft: "5px",
                          fontWeight:
                            data.participants.owner2 === "required"
                              ? "bold"
                              : undefined,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {data.participants.owner2 === "required" ||
                          data.participants.owner2 === "optional"
                          ? data.title
                          : null}
                        {data.participants.owner2 === "required" ||
                          data.participants.owner2 === "optional" ? (
                          <div
                            style={{
                              borderTop: "1px solid #F1F7F8",
                              width: "100%",
                              marginBottom: 10,
                              overflow: "hidden",
                            }}
                          ></div>
                        ) : null}
                        {data.participants.owner2 === "required" ? (
                          <div
                            style={{
                              float: "right",
                              display: "flex",
                              justifyContent: "flex-end",
                              position: "relative",
                              top: -35,
                            }}
                          >

                          </div>
                        ) : undefined}
                      </div>
                    );
                  } else if (
                    data.completed.guarantor1 === false &&
                    data.group !== "operation" &&
                    (clickTab === "guarantor" || clickTab === "guarantor1")
                  ) {
                    return (
                      <div
                        style={{
                          marginBottom:
                            data.participants.guarantor1 === "required" ? -15 : 0,
                          marginTop:
                            data.participants.guarantor1 === "required" ? -1 : 0,
                          whiteSpace: "nowrap",
                          marginLeft: "5px",
                          fontWeight:
                            data.participants.guarantor1 === "required"
                              ? "bold"
                              : undefined,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {data.participants.guarantor1 === "required" ||
                          data.participants.guarantor1 === "optional"
                          ? data.title
                          : null}
                        {data.participants.guarantor1 === "required" ||
                          data.participants.guarantor1 === "optional" ? (
                          <div
                            style={{
                              borderTop: "1px solid #F1F7F8",
                              width: "100%",
                              marginBottom: 10,
                              overflow: "hidden",
                            }}
                          ></div>
                        ) : null}
                        {data.participants.guarantor1 === "required" ? (
                          <div
                            style={{
                              float: "right",
                              display: "flex",
                              justifyContent: "flex-end",
                              position: "relative",
                              top: -35,
                            }}
                          >

                          </div>
                        ) : undefined}
                      </div>
                    );
                  } else if (data.group === "operation" && clickTab === "buy") {
                    return (
                      <div style={{ whiteSpace: "nowrap", marginLeft: "5px" }}>
                        {data.participants.owner1 === "required" ||
                          data.participants.owner1 === "optional"
                          ? data.title
                          : null}
                        {data.participants.owner1 === "required" ||
                          data.participants.owner1 === "optional" ? (
                          <div
                            style={{
                              borderTop: "1px solid #F1F7F8",
                              marginTop: "2px",
                              width: "97%",
                            }}
                          ></div>
                        ) : null}
                      </div>
                    );
                  } else if (
                    data.completed.guarantor2 === false &&
                    clickTab === "guarantor2" &&
                    data.group !== "operation"
                  ) {
                    return (
                      <div
                        style={{
                          marginBottom:
                            data.participants.guarantor2 === "required" ? -15 : 0,
                          marginTop:
                            data.participants.guarantor2 === "required" ? -1 : 0,
                          whiteSpace: "nowrap",
                          marginLeft: "5px",
                          fontWeight:
                            data.participants.guarantor2 === "required"
                              ? "bold"
                              : undefined,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {data.participants.guarantor2 === "required" ||
                          data.participants.guarantor2 === "optional"
                          ? data.title
                          : null}
                        {data.participants.guarantor2 === "required" ||
                          data.participants.guarantor2 === "optional" ? (
                          <div
                            style={{
                              borderTop: "1px solid #F1F7F8",
                              width: "100%",
                              marginBottom: 10,
                              overflow: "hidden",
                            }}
                          ></div>
                        ) : null}
                        {data.participants.guarantor2 === "required" ? (
                          <div
                            style={{
                              float: "right",
                              display: "flex",
                              justifyContent: "flex-end",
                              position: "relative",
                              top: -35,
                            }}
                          >

                          </div>
                        ) : undefined}
                      </div>
                    );
                  }
                })
                : null}
            </div>

          </div>





        </div>
      )}

      {stages === "analysis" && !isMobile && (
        <div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "550px" }}>{t("details.analysis")}</div>
            </Text>
          </div>
          <div
            className="ProgressBar"
            style={{
              width: "72px",
              height: "72px",
              float: "right",
              marginRight: "2px",
              marginTop: "-90px",
            }}
          >
            <Progress
              type="dashboard"
              strokeColor={"#52C41A"}
              trailColor={"#F1F7F8"}
              percent={dataScoring && dataScoring.viability !== null
                ? (dataScoring.viability * 100) / 5
                : "0"}
              format={() => (
                <div>
                  <Text
                    strong
                    style={{
                      fontSize: "24px",
                      color: "#2F4858",
                      marginBottom: "-4px",
                      display: "grid",
                      marginTop: -45,
                    }}
                  > {dataScoring && dataScoring.viability !== null
                    ? dataScoring.viability
                    : "0"}</Text>
                  <Text
                    style={{
                      fontSize: "14px",
                      color: "#2F4858",
                      marginTop: -20,
                    }}
                  >{`de 5`}</Text>
                </div>
              )}
              width={132}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "4px",
                marginBottom: "24px",
                color: "black",
              }}
            />
          </div>
        </div>
      )}



      {stages === "analysis" && isMobile && (

        <div>

          <div
            className="ProgressBar"
            style={{
              width: "72px",
              height: "72px",
              marginRight: "2px",
              marginTop: -150,
              display: "inline"
            }}
          >
            <Progress
              type="dashboard"
              strokeColor={"#52C41A"}
              trailColor={"#F1F7F8"}
              percent={dataScoring && dataScoring.viability !== null
                ? (dataScoring.viability * 100) / 5
                : "0"}
              format={() => (
                <div>
                  <Text
                    strong
                    style={{
                      fontSize: "32px",
                      color: "#2F4858",
                      marginBottom: "-4px",
                      display: "grid",
                      marginTop: 10,
                    }}
                  >
                    {dataScoring && dataScoring.viability !== null
                      ? dataScoring.viability
                      : "0"}
                  </Text>
                  <Text
                    style={{
                      fontSize: "14px",
                      color: "#2F4858",
                      marginTop: -20,
                    }}
                  >{`de 5`}</Text>
                </div>
              )}
              width={132}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "-20px",
                marginBottom: "5px",
                color: "black",
              }}
            />
          </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "100%", fontSize: 14, fontWeight: 400, color: "#02C3CD", textAlign: "center", marginBottom: 24 }}>{dataMortgage && dataMortgage.owner1 && dataMortgage.owner1.name}</div>
            </Text>
          </div>
          <div>
              {status !== "finish" &&
                <div style={{ display: "flex", justifyContent: "center", marginTop: -10, marginBottom: 25 }}>
                  <DoubleRightOutlined style={{ color: 'green', fontSize: 16, }}></DoubleRightOutlined>
                  <Text style={{ fontSize: "12px", color: 'green', marginLeft: 5 }}>
                    {t("operationStatus.open")}
                  </Text>
                </div>

              }
              {status === "finish" &&
                <div style={{ display: "flex", justifyContent: "center", marginTop: -10, marginBottom: 25 }}>
                  <LockOutlined style={{ color: '#1890FF', fontSize: 16, }}></LockOutlined>
                  <Text style={{ fontSize: "12px", color: '#1890FF', marginLeft: 5 }}>
                    {t("operationStatus.closed")}
                  </Text>
                </div>

              }
            </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "100%", fontSize: 14, fontWeight: 600, textAlign: "left", color: "#2F4858", marginBottom: 16 }}>{t("document.analystics")}</div>
            </Text>
          </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "105%", fontSize: 12, fontWeight: 400, textAlign: "left", color: "#748EA0", marginBottom: -120 }}>{t("details.analysis")}</div>
            </Text>
          </div>
        </div>



      )}
      {stages === "banks" && !isMobile && (

        <div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "550px" }}>{t("details.banks")}</div>
            </Text>
          </div>
          <div
            className="ProgressBar"
            style={{
              width: "72px",
              height: "72px",
              float: "right",
              marginTop: "-90px",
            }}
          >
            <Progress
              type="dashboard"
              strokeColor={"#52C41A"}
              trailColor={"#F1F7F8"}
              percent={dataScoring && dataScoring.viability !== null
                ? (dataScoring.viability * 100) / 5
                : "0"}
              format={() => (
                <div>
                  <Text
                    strong
                    style={{
                      fontSize: "24px",
                      color: "#2F4858",
                      marginBottom: "-4px",
                      display: "grid",
                      marginTop: -45,
                    }}
                  > {dataScoring && dataScoring.viability !== null
                    ? dataScoring.viability
                    : "0"}</Text>
                  <Text
                    style={{
                      fontSize: "14px",
                      color: "#2F4858",
                      marginTop: -20,
                    }}
                  >{`de 5`}</Text>
                </div>
              )}
              width={132}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "4px",
                marginBottom: "24px",
                color: "black",
              }}
            />
          </div>
          <div style={{ width: 500, marginLeft: 100, marginTop: 40 }}>
            <Steps className="StepsAppraisal2" current={banks ? "2" : "1"}>
              <Step description="Enviada" />
              <Step description="Asignada" />
              <Step description="Oferta disponible" />
            </Steps>
          </div>
        </div>
      )}

      {stages === "banks" && isMobile && (

        <div>

          <div
            className="ProgressBar"
            style={{
              width: "72px",
              height: "72px",
              marginRight: "2px",
              marginTop: -150,
              display: "inline"
            }}
          >
            <Progress
              type="dashboard"
              strokeColor={"#52C41A"}
              trailColor={"#F1F7F8"}
              percent={dataScoring && dataScoring.viability !== null
                ? (dataScoring.viability * 100) / 5
                : "0"}
              format={() => (
                <div>
                  <Text
                    strong
                    style={{
                      fontSize: "32px",
                      color: "#2F4858",
                      marginBottom: "-4px",
                      display: "grid",
                      marginTop: 10,
                    }}
                  >
                    {dataScoring && dataScoring.viability !== null
                      ? dataScoring.viability
                      : "0"}
                  </Text>
                  <Text
                    style={{
                      fontSize: "14px",
                      color: "#2F4858",
                      marginTop: -20,
                    }}
                  >{`de 5`}</Text>
                </div>
              )}
              width={132}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "-20px",
                marginBottom: "5px",
                color: "black",
              }}
            />
          </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "100%", fontSize: 14, fontWeight: 400, color: "#02C3CD", textAlign: "center", marginBottom: 24 }}>{dataMortgage && dataMortgage.owner1 && dataMortgage.owner1.name}</div>
            </Text>
          </div>
          <div>
              {status !== "finish" &&
                <div style={{ display: "flex", justifyContent: "center", marginTop: -10, marginBottom: 25 }}>
                  <DoubleRightOutlined style={{ color: 'green', fontSize: 16, }}></DoubleRightOutlined>
                  <Text style={{ fontSize: "12px", color: 'green', marginLeft: 5 }}>
                    {t("operationStatus.open")}
                  </Text>
                </div>

              }
              {status === "finish" &&
                <div style={{ display: "flex", justifyContent: "center", marginTop: -10, marginBottom: 25 }}>
                  <LockOutlined style={{ color: '#1890FF', fontSize: 16, }}></LockOutlined>
                  <Text style={{ fontSize: "12px", color: '#1890FF', marginLeft: 5 }}>
                    {t("operationStatus.closed")}
                  </Text>
                </div>

              }
            </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "100%", fontSize: 14, fontWeight: 600, textAlign: "left", color: "#2F4858", marginBottom: 16 }}>{t("stages.banks")}</div>
            </Text>
          </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "100%", fontSize: 12, fontWeight: 400, textAlign: "left", color: "#748EA0", marginBottom: -120 }}>{t("details.banks")}</div>
            </Text>
          </div>
          <div style={{ width: 260, marginTop: 150 }}>
            <Steps className="StepsAppraisalMovile" current={banks ? "2" : "1"}>
              <Step description="Enviada" />
              <Step description="Asignada" />
              <Step description="Oferta disponible" />
            </Steps>
          </div>
        </div>



      )}

      {stages === "oferts" && !isMobile && (

        <div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "550px" }}>{t("details.oferts1")}</div>
            </Text>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "550px", marginTop: 15 }}>
                {t("details.oferts2")}
              </div>
            </Text>
          </div>
        </div>
      )}

      {stages === "oferts" && isMobile && (



        <div>

          <div
            className="ProgressBar"
            style={{
              width: "72px",
              height: "72px",
              marginRight: "2px",
              marginTop: -150,
              display: "inline"
            }}
          >
            <Progress
              type="dashboard"
              strokeColor={"#52C41A"}
              trailColor={"#F1F7F8"}
              percent={dataScoring && dataScoring.viability !== null
                ? (dataScoring.viability * 100) / 5
                : "0"}
              format={() => (
                <div>
                  <Text
                    strong
                    style={{
                      fontSize: "32px",
                      color: "#2F4858",
                      marginBottom: "-4px",
                      display: "grid",
                      marginTop: 10,
                    }}
                  >
                    {dataScoring && dataScoring.viability !== null
                      ? dataScoring.viability
                      : "0"}
                  </Text>
                  <Text
                    style={{
                      fontSize: "14px",
                      color: "#2F4858",
                      marginTop: -20,
                    }}
                  >{`de 5`}</Text>
                </div>
              )}
              width={132}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "-20px",
                marginBottom: "5px",
                color: "black",
              }}
            />
          </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "100%", fontSize: 14, fontWeight: 400, color: "#02C3CD", textAlign: "center", marginBottom: 24 }}>{dataMortgage && dataMortgage.owner1 && dataMortgage.owner1.name}</div>
            </Text>
          </div>
          <div>
              {status !== "finish" &&
                <div style={{ display: "flex", justifyContent: "center", marginTop: -10, marginBottom: 25 }}>
                  <DoubleRightOutlined style={{ color: 'green', fontSize: 16, }}></DoubleRightOutlined>
                  <Text style={{ fontSize: "12px", color: 'green', marginLeft: 5 }}>
                    {t("operationStatus.open")}
                  </Text>
                </div>

              }
              {status === "finish" &&
                <div style={{ display: "flex", justifyContent: "center", marginTop: -10, marginBottom: 25 }}>
                  <LockOutlined style={{ color: '#1890FF', fontSize: 16, }}></LockOutlined>
                  <Text style={{ fontSize: "12px", color: '#1890FF', marginLeft: 5 }}>
                    {t("operationStatus.closed")}
                  </Text>
                </div>

              }
            </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "100%", fontSize: 14, fontWeight: 600, textAlign: "left", color: "#2F4858", marginBottom: 16 }}>{t("offers.offert")}</div>
            </Text>
          </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "100%", fontSize: 12, fontWeight: 400, textAlign: "left", color: "#748EA0" }}>{t("details.oferts1")}</div>
            </Text>
          </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "100%", fontSize: 12, fontWeight: 400, textAlign: "left", color: "#748EA0" }}>{t("details.oferts2")}</div>
            </Text>
          </div>

        </div>




      )}

      {stages === "appraisal" && !isMobile && (
        <div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "550px" }}>{t("details.appraisal")}</div>
            </Text>
          </div>
          <div style={{ width: 500, marginLeft: 100, marginTop: 40 }}>
            <Steps
              className="StepsAppraisal2"
              current={existAppraisal ? "2" : ""}
            >
              <Step description="Pago tasacion" />
              <Step description="Informe tasacion" />
              <Step description="Tasacion aprobada" />
            </Steps>
          </div>
        </div>
      )}

      {stages === "appraisal" && isMobile && (
        <div>

          <div
            className="ProgressBar"
            style={{
              width: "72px",
              height: "72px",
              marginRight: "2px",
              marginTop: -150,
              display: "inline"
            }}
          >
            <Progress
              type="dashboard"
              strokeColor={"#52C41A"}
              trailColor={"#F1F7F8"}
              percent={dataScoring && dataScoring.viability !== null
                ? (dataScoring.viability * 100) / 5
                : "0"}
              format={() => (
                <div>
                  <Text
                    strong
                    style={{
                      fontSize: "32px",
                      color: "#2F4858",
                      marginBottom: "-4px",
                      display: "grid",
                      marginTop: 10,
                    }}
                  >
                    {dataScoring && dataScoring.viability !== null
                      ? dataScoring.viability
                      : "0"}
                  </Text>
                  <Text
                    style={{
                      fontSize: "14px",
                      color: "#2F4858",
                      marginTop: -20,
                    }}
                  >{`de 5`}</Text>
                </div>
              )}
              width={132}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "-20px",
                marginBottom: "5px",
                color: "black",
              }}
            />
          </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "100%", fontSize: 14, fontWeight: 400, color: "#02C3CD", textAlign: "center", marginBottom: 24 }}>{dataMortgage && dataMortgage.owner1 && dataMortgage.owner1.name}</div>
            </Text>
          </div>
          <div>
              {status !== "finish" &&
                <div style={{ display: "flex", justifyContent: "center", marginTop: -10, marginBottom: 25 }}>
                  <DoubleRightOutlined style={{ color: 'green', fontSize: 16, }}></DoubleRightOutlined>
                  <Text style={{ fontSize: "12px", color: 'green', marginLeft: 5 }}>
                    {t("operationStatus.open")}
                  </Text>
                </div>

              }
              {status === "finish" &&
                <div style={{ display: "flex", justifyContent: "center", marginTop: -10, marginBottom: 25 }}>
                  <LockOutlined style={{ color: '#1890FF', fontSize: 16, }}></LockOutlined>
                  <Text style={{ fontSize: "12px", color: '#1890FF', marginLeft: 5 }}>
                    {t("operationStatus.closed")}
                  </Text>
                </div>

              }
            </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "100%", fontSize: 14, fontWeight: 600, textAlign: "left", color: "#2F4858", marginBottom: 16 }}>{t("appraisal.appraisal")}</div>
            </Text>
          </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "105%", fontSize: 12, fontWeight: 400, textAlign: "left", color: "#748EA0" }}>{t("details.appraisalMobile")}</div>
            </Text>
          </div>


        </div>

      )}

      {stages === "formalization" && !isMobile && (
        <div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "550px" }}>
                {t("details.formalization1")}
              </div>
            </Text>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "550px", marginTop: 15 }}>
                {t("details.formalization2")}
              </div>
            </Text>
          </div>
          <div style={{ width: 500, marginLeft: 100, marginTop: 20 }}>
            <Steps
              className="StepsAppraisal2"
              current={
                dataMortgage.mortgage && dataMortgage.mortgage.signed
                  ? "2"
                  : "" ||
                    (dataMortgage.mortgage &&
                      dataMortgage.mortgage.signedDocumentation)
                    ? "2"
                    : "" ||
                      (dataMortgage.mortgage &&
                        dataMortgage.mortgage.digitalContractLink !==
                        undefined) ||
                      fein
                      ? "1"
                      : ""
              }
            >
              <Step description={t("details.fein")} />
              <Step description={t("details.signature")} />
              <Step description={t("details.buy")} />
            </Steps>
          </div>
        </div>
      )}

      {stages === "formalization" && isMobile && (


        <div>

          <div
            className="ProgressBar"
            style={{
              width: "72px",
              height: "72px",
              marginRight: "2px",
              marginTop: -150,
              display: "inline"
            }}
          >
            <Progress
              type="dashboard"
              strokeColor={"#52C41A"}
              trailColor={"#F1F7F8"}
              percent={dataScoring && dataScoring.viability !== null
                ? (dataScoring.viability * 100) / 5
                : "0"}
              format={() => (
                <div>
                  <Text
                    strong
                    style={{
                      fontSize: "32px",
                      color: "#2F4858",
                      marginBottom: "-4px",
                      display: "grid",
                      marginTop: 10,
                    }}
                  >
                    {((dataScoring && dataScoring.viability !== null) && (dataScoring && dataScoring.viability !== undefined))
                      ? dataScoring.viability
                      : "0"}
                  </Text>
                  <Text
                    style={{
                      fontSize: "14px",
                      color: "#2F4858",
                      marginTop: -20,
                    }}
                  >{`de 5`}</Text>
                </div>
              )}
              width={132}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "-20px",
                marginBottom: "5px",
                color: "black",
              }}
            />
          </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "100%", fontSize: 14, fontWeight: 400, color: "#02C3CD", textAlign: "center", marginBottom: 24 }}>{dataMortgage && dataMortgage.owner1 && dataMortgage.owner1.name}</div>
            </Text>
          </div>
          <div>
              {status !== "finish" &&
                <div style={{ display: "flex", justifyContent: "center", marginTop: -10, marginBottom: 25 }}>
                  <DoubleRightOutlined style={{ color: 'green', fontSize: 16, }}></DoubleRightOutlined>
                  <Text style={{ fontSize: "12px", color: 'green', marginLeft: 5 }}>
                    {t("operationStatus.open")}
                  </Text>
                </div>

              }
              {status === "finish" &&
                <div style={{ display: "flex", justifyContent: "center", marginTop: -10, marginBottom: 25 }}>
                  <LockOutlined style={{ color: '#1890FF', fontSize: 16, }}></LockOutlined>
                  <Text style={{ fontSize: "12px", color: '#1890FF', marginLeft: 5 }}>
                    {t("operationStatus.closed")}
                  </Text>
                </div>

              }
            </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "100%", fontSize: 14, fontWeight: 600, textAlign: "left", color: "#2F4858", marginBottom: 16 }}>{t("formalisation.formalisation")}</div>
            </Text>
          </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "100%", fontSize: 12, fontWeight: 400, textAlign: "left", color: "#748EA0" }}>{t("details.formalization1")}</div>
            </Text>
          </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              <div style={{ width: "100%", fontSize: 12, fontWeight: 400, textAlign: "left", color: "#748EA0" }}>{t("details.formalization2")}</div>
            </Text>
          </div>
          <div style={{ width: 240, marginTop: 50 }}>
            <Steps
              className="StepsAppraisal2"
              current={
                dataMortgage.mortgage && dataMortgage.mortgage.signed
                  ? "2"
                  : "" ||
                    (dataMortgage.mortgage &&
                      dataMortgage.mortgage.signedDocumentation)
                    ? "2"
                    : "" ||
                      (dataMortgage.mortgage &&
                        dataMortgage.mortgage.digitalContractLink !==
                        undefined) ||
                      fein
                      ? "1"
                      : ""
              }

            >
              <Step description={t("details.fein")} className="mobileStepsInmo" />
              <Step description={t("details.signature")} className="mobileStepsInmo" />
              <Step description={t("details.buy")} className="mobileStepsInmo" />
            </Steps>
          </div>
        </div>






      )}
    </div>
  );
};

export default DataBasicINMO;
