import React, { useEffect, useState } from 'react';
import { Select, Button, Spin, Typography, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import HelpButtonMobile from '../HelpButtonMobile';
import DifferentScenarioCardsMobile from './differentScenarioCardsMobile';
import ProjectDataMobile from './ProjectDataMobile';
import LayoutPromoterMobile from '../LayoutPromoterMobile';
import { isMobileOnly } from 'react-device-detect';
import ModalFinancingProcessMobile from '../ModalFinnancingProcessMobile';
import numeral from 'numeral';
import ModalSaveProject from '../../SimulationSummary/ModalSaveProject';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
	getFinancingScenarios,
	getListMyProyects,
	getPromoterData
} from '../../../../store/promoter/actions';
import { LoadingOutlined } from '@ant-design/icons';
import ModalShareUs from '../../CommonComponents/ModalShareUs';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ReduxStore } from '../welcomePageMobile';
const infoSecurity = require('../../../../assets/icons/infoSecurity.svg');
const bankSecurity = require('../../../../assets/icons/bankSecurity.svg');
const asprima = require('../../../../assets/icons/logo-asprima-mobile.png');
const editIcon = require('../../../../assets/promoter/edit.svg');
const infoIcon = require('../../../../assets/promoter/info.svg');

const { Option } = Select;
const { Text } = Typography;

const SimulationSummaryMobile = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams<{ operationId: string; stage: any }>();
	const operationId = params.operationId;
	const operationStages = params.stage;
	const [modalDescription, setModalDescription] = useState(false);
	const [modalSaveProject, setModalSaveProject] = useState(false);
	const [modalShareUs, setModalShareUs] = useState(false);
	const [loading, setLoading] = useState(false);
	const scenariosData = useSelector<ReduxStore, any>((state) => state.promoter.dataScenarios);
	const dataPromoter = useSelector<ReduxStore, any>((state) => state.promoter.dataFormPromoter);
	const proyects = useSelector<ReduxStore, any>((state) => state.promoter.dataListMyProyects);
	const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

	const addingNumber = (title: string, number: number) => {
		return (
			<div style={{ textAlign: 'center' }}>
				<Text
					style={{
						fontSize: 10,
						fontWeight: title === 'promoter.toFinanced' ? 500 : 400,
						color: '#748EA0'
					}}>
					{t(title)}
				</Text>
				<br />
				<Text
					style={{
						fontSize: title === 'promoter.toFinanced' ? 14 : 10,
						fontWeight: title === 'promoter.toFinanced' ? 500 : 400,
						color: '#748EA0'
					}}>
					{numeral(number).format('0,0 $')}
				</Text>
			</div>
		);
	};

	useEffect(() => {
		// if (scenariosData && scenariosData.length === 0) {
		dispatch(getFinancingScenarios(operationId));
		// }
	}, []);

	useEffect(() => {
		if (
			proyects &&
			proyects.length !== 0 &&
			(Object.values(dataPromoter).length === 0 || Object.values(dataPromoter).length === 1)
		) {
			const projectArray = proyects.filter((data) => data.id === operationId);
			dispatch(
				getPromoterData(projectArray && projectArray[0] && projectArray[0].operationDataClient)
			);
		}
	}, [proyects]);

	const findScenario = (name, type, option) => {
		const obj = scenariosData && scenariosData.find((data) => data.loanType === name);
		return obj && obj.scenarioData[type][option];
	};

	const scenarioFavorite = (name, type) => {
		const obj = scenariosData && scenariosData.find((data) => data.loanType === name);
		return obj && obj[type];
	};
	const nameStages = {
		simulation: t('m_viability.simulationTitle'),
		analysis: t('document.analystics'),
		documentation: t('document.documentation'),
		oferts: t('m_offers.title'),
		formalization: t('formalisation.formalisation'),
		finish: t('stages.closed'),
		SALE: t('promoterLoan.saleTitle'),
		RESIDENTIAL: t('promoterLoan.residentialTitle'),
		OTHER: t('promoterLoan.otherTitle'),
		SINGLE_FAMILY: t('promoterLoan.singleFamily'),
		MULTI_FAMILY: t('promoterLoan.multiFamily'),
		undefined: '-'
	};

	const checkViability = () => {
		let allScenariosZero = true;
		scenariosData.forEach((object) => {
			const { loanType, scenarioData } = object;
			if (
				(loanType === 'LOAN_PROMOTER_BANK' ||
					loanType === 'ALTERNATIVE_LOAN_PROMOTER_BANK' ||
					loanType === 'BRIDGE_LOAN' ||
					loanType === 'ALTERNATIVE_CAPITAL_INCREASE') &&
				scenarioData.generalData.viability !== 0
			) {
				allScenariosZero = false;
			}
		});
		return allScenariosZero;
	};

	return (
		<>
			<LayoutPromoterMobile
				setModalDescription={(e) => setModalDescription(e)}
				name={
					dataPromoter && dataPromoter.projectData && dataPromoter.projectData.projectName
						? dataPromoter.projectData.projectName
						: 'Proyecto 01'
				}
				stages={nameStages[operationStages]}
			/>

			<div style={{ padding: 16, marginBottom: 150 }}>
				{loading && <Spin indicator={antIcon} className="spin" />}
				{!loading && (
					<>
						<div className="title-primary" style={{ textAlign: 'center' }}>
							{t('promoterLoan.yourSimulationResults')}
						</div>
						<DifferentScenarioCardsMobile
							title={'promoterLoan.case0title'}
							number={0}
							equity={scenariosData && findScenario('OWN_FUNDING', 'generalData', 'equityMultiple')}
							margin={scenariosData && findScenario('OWN_FUNDING', 'generalData', 'marginOnsales')}
							viability={undefined}
							tooltipText={'promoterLoan.case0Tooltip'}
						/>
						<div className="title-primary" style={{ textAlign: 'center', marginTop: 24 }}>
							{t('promoterLoan.financingOptions')}
						</div>
						{dataPromoter?.capitalStructure?.financingRequired < 0 && (
							<div className="update-scenarios-styles">
								<InfoCircleOutlined style={{ color: '#FF4D4F', marginRight: 10, fontSize: 20 }} />
								<div className="text-detail-small">
									{t('promoterLoan.exceptionalCaseFinancing')}
								</div>
							</div>
						)}
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								marginTop: 16
							}}>
							<div style={{ display: 'flex', gap: 3 }}>
								{addingNumber(
									'promoterLoan.totalCosts',
									Number(dataPromoter?.economicData?.projectCosts?.totalCosts)
								)}
								<Text style={{ fontSize: 10, fontWeight: 400, color: '#748EA0', marginTop: 5 }}>
									-
								</Text>
								{addingNumber(
									'promoterLoan.ownFunds',
									Number(dataPromoter?.capitalStructure?.equity)
								)}
								<Text style={{ fontSize: 10, fontWeight: 400, color: '#748EA0', marginTop: 5 }}>
									=
								</Text>
								{addingNumber(
									'promoter.toFinanced',
									Number(dataPromoter?.capitalStructure?.financingRequired)
								)}
							</div>
							<div
								style={{ display: 'flex', gap: 4 }}
								onClick={() => {
									navigate(`/promoter-loan/general-data/${operationId}`, {
										state: {
											edit: true
										}
									});
								}}>
								<Text style={{ fontSize: 14, fontWeight: 400, color: '#02C3CD' }}>
									{t('form.edit')}
								</Text>
								<img src={editIcon.default} alt="Edit icon" />
							</div>
						</div>
						{checkViability() && (
							<Alert
								className="viabilityZero"
								style={{ marginTop: 16, borderRadius: 8, borderColor: '#FAAD14' }}
								description={t('promoter.viabilityZero')}
								type="warning"
								showIcon
							/>
						)}
						{dataPromoter?.capitalStructure?.financingRequired > 0 && (
							<>
								<DifferentScenarioCardsMobile
									title={'promoterLoan.case1title'}
									number={1}
									equity={
										scenariosData &&
										findScenario('LOAN_PROMOTER_BANK', 'generalData', 'equityMultiple')
									}
									margin={
										scenariosData &&
										findScenario('LOAN_PROMOTER_BANK', 'generalData', 'marginOnsales')
									}
									viability={
										scenariosData && findScenario('LOAN_PROMOTER_BANK', 'generalData', 'viability')
									}
									tooltipText={'promoterLoan.case1Tooltip'}
								/>
								<DifferentScenarioCardsMobile
									title={'promoterLoan.case2title'}
									number={2}
									equity={
										scenariosData &&
										findScenario('ALTERNATIVE_LOAN_PROMOTER_LOAN', 'generalData', 'equityMultiple')
									}
									margin={
										scenariosData &&
										findScenario('ALTERNATIVE_LOAN_PROMOTER_LOAN', 'generalData', 'marginOnsales')
									}
									viability={findScenario(
										'ALTERNATIVE_LOAN_PROMOTER_LOAN',
										'generalData',
										'viability'
									)}
									tooltipText={'promoterLoan.case2Tooltip'}
								/>
								<DifferentScenarioCardsMobile
									title={'promoterLoan.case3title'}
									number={3}
									equity={
										scenariosData && findScenario('BRIDGE_LOAN', 'generalData', 'equityMultiple')
									}
									margin={
										scenariosData && findScenario('BRIDGE_LOAN', 'generalData', 'marginOnsales')
									}
									viability={findScenario('BRIDGE_LOAN', 'generalData', 'viability')}
									tooltipText={'promoterLoan.case3Tooltip'}
								/>
								<DifferentScenarioCardsMobile
									title={'promoterLoan.case4title'}
									number={4}
									equity={
										scenariosData &&
										findScenario('ALTERNATIVE_CAPITAL_INCREASE', 'generalData', 'equityMultiple')
									}
									margin={
										scenariosData &&
										findScenario('ALTERNATIVE_CAPITAL_INCREASE', 'generalData', 'marginOnsales')
									}
									viability={
										scenariosData &&
										findScenario('ALTERNATIVE_CAPITAL_INCREASE', 'generalData', 'viability')
									}
									tooltipText={'promoterLoan.case4Tooltip'}
								/>
							</>
						)}
					</>
				)}
				<div
					style={{
						borderRadius: 4,
						background: '#D4EAFF80',
						padding: 8,
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						gap: 10,
						marginTop: 16
					}}>
					<img src={infoIcon.default} alt="Info icon" />
					<Text style={{ fontSize: 12, fontWeight: 500, color: '#748EA0' }}>
						{t('promoter.seeDetails')}
					</Text>
				</div>
				<div style={{ marginTop: 16, fontSize: 10, color: '#748EA0' }}>
					{t('promoterLoan.resultsDescription')}
				</div>
				<div style={{ width: '100%', textAlign: 'center', marginBottom: 80 }}>
					<img
						src={infoSecurity.default}
						alt="infoSecurity"
						width={250}
						style={{ marginTop: 16 }}
					/>
					<img
						src={bankSecurity.default}
						alt="bankSecurity"
						width={280}
						style={{ marginTop: 16 }}
					/>
					<img src={asprima} alt="asprima" width={120} style={{ marginTop: 16 }} />
				</div>
				<div
					style={{
						position: 'fixed',
						width: '100%',
						height: 80,
						bottom: 0,
						left: 0,
						boxShadow: '0px -2px 8px 0px rgba(47, 72, 88, 0.10)',
						background: '#fff',
						zIndex: 999,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: 16
					}}>
					<Button
						type={'primary'}
						className={'button-primari-gibobs'}
						style={{
							width: '100%',
							height: 48,
							borderRadius: 8
						}}
						onClick={() => setModalSaveProject(true)}>
						{t('promoterLoan.saveProject')}
					</Button>
				</div>

				<ModalFinancingProcessMobile
					// key={modalDescription}
					visible={modalDescription}
					onCancel={() => setModalDescription(false)}
				/>
				{dataPromoter && (
					<ModalSaveProject
						// key={modalSaveProject}
						save={true}
						visible={modalSaveProject}
						onCreate={(e) => {
							setModalSaveProject(false);
							setModalShareUs(true);
							dispatch(getListMyProyects());
							dispatch(
								getPromoterData({
									...dataPromoter,
									projectData: {
										...e
									}
								})
							);
						}}
						onCancel={() => setModalSaveProject(false)}
						width={!isMobileOnly && 690}
						initialValuesData={
							dataPromoter && dataPromoter.projectData
								? { ...dataPromoter['projectData'] }
								: {
										province:
											dataPromoter.generalData &&
											dataPromoter.generalData.province &&
											dataPromoter.generalData.province
								  }
						}
					/>
				)}
				<ModalShareUs
					// key={modalShareUs}
					visible={modalShareUs}
					onCreate={() => {
						setModalShareUs(false);
					}}
					onCancel={() => {
						setModalShareUs(false);
					}}
					width={!isMobileOnly && 640}
					save={true}
				/>
				<HelpButtonMobile origin={'IN_FORM_THREE_STEPS'} simulation={true} />
			</div>
		</>
	);
};

export default SimulationSummaryMobile;
