import { useEffect, useState } from 'react';
import { Form, Select, Col, Row, Card, Button, Typography, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { notificationGibobs } from '../../HerramientasVenta/reusableItems';
import { EuroOutlined, EyeOutlined } from '@ant-design/icons';
import ModalDescriptionFinancial from '../ModalDescriptionFinancial';
import HelpButtonMobile from './HelpButtonMobile';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getPromoterData } from '../../../store/promoter/actions';
import axios from 'axios';
import env from '../../../environment';
import { InputNumber } from 'afrodita';
import { ReduxStore } from './welcomePageMobile';
import StepPromoterMobile from './StepPromoterMobile';
import numeral from 'numeral';

const { Option } = Select;
const { Text } = Typography;

export const FormFinancialData = (lg, form) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();
	const [typeOfUnit, setTypeOfUnit] = useState('TOTAL_EUROS');
	const [totalCosts, setTotalCosts] = useState(0);
	const [totalBuildCosts, setTotalBuildCosts] = useState(0);
	const [totalUnitCosts, setTotalUnitCosts] = useState(0);
	const [unit, setUnit] = useState(0);
	const [m2t, setM2t] = useState(0);

	const [incomesObj, setIncomesObj] = useState<any>({
		// incomes: 0,
		landCosts: 0,
		constructionsCosts: 0,
		associatedCosts: 0,
		businessCosts: 0,
		managementCosts: 0
	});
	const [euroBuildObj, setEuroBuildObj] = useState<any>({
		// euroBuildProject: 0,
		euroBuildLand: 0,
		euroBuildConstruction: 0,
		euroBuildAssociated: 0,
		euroBuildBusiness: 0,
		euroBuildManagement: 0
	});
	const [euroUnitObj, setEuroUnitObj] = useState<any>({
		// euroUnitProject: 0,
		euroUnitLand: 0,
		euroUnitConstruction: 0,
		euroUnitAssociated: 0,
		euroUnitBusiness: 0,
		euroUnitManagement: 0
	});

	const dataPromoter = useSelector<ReduxStore, any>((state) => state.promoter.dataFormPromoter);

	const sumaTotal = (obj, name, value, setObj, setTotal) => {
		const array = obj;
		array[name] = value;
		let sum = 0;
		for (const key in array) {
			if (array[key] !== '') {
				sum += array[key];
			}
		}
		setObj(array);
		setTotal(sum);
	};
	const sumaUnit = (obj, setTotal) => {
		const array = obj;
		let sum = 0;
		for (const key in array) {
			if (array[key] !== '') {
				sum += array[key];
			}
		}
		setTotal(sum);
	};

	useEffect(() => {
		(async () => {
			form.setFieldsValue({
				totalCosts: totalCosts && totalCosts,
				euroBuildTotal: totalBuildCosts && totalBuildCosts.toFixed(2),
				euroUnitTotal: totalUnitCosts && totalUnitCosts.toFixed(2)
			});
			dispatch(getPromoterData({ ...dataPromoter, totalCosts: totalCosts }));
		})();
	}, [totalCosts, totalBuildCosts, totalUnitCosts]);

	useEffect(() => {
		(async () => {
			form.setFieldsValue({
				incomes:
					dataPromoter &&
						dataPromoter.economicData &&
						dataPromoter.economicData.projectIncomes.incomes
						? Number(dataPromoter.economicData.projectIncomes.incomes)
						: ''
			});
			const incomes = {
				landCosts:
					dataPromoter &&
						dataPromoter.economicData &&
						dataPromoter.economicData.projectCosts.landCosts
						? Number(dataPromoter.economicData.projectCosts.landCosts)
						: '',
				constructionsCosts:
					dataPromoter &&
						dataPromoter.economicData &&
						dataPromoter.economicData.projectCosts.constructionsCosts
						? Number(dataPromoter.economicData.projectCosts.constructionsCosts)
						: '',
				associatedCosts:
					dataPromoter &&
						dataPromoter.economicData &&
						dataPromoter.economicData.projectCosts.associatedCosts
						? Number(dataPromoter.economicData.projectCosts.associatedCosts)
						: '',
				businessCosts:
					dataPromoter &&
						dataPromoter.economicData &&
						dataPromoter.economicData.projectCosts.businessCosts
						? Number(dataPromoter.economicData.projectCosts.businessCosts)
						: '',
				managementCosts:
					dataPromoter &&
						dataPromoter.economicData &&
						dataPromoter.economicData.projectCosts.managementCosts
						? Number(dataPromoter.economicData.projectCosts.managementCosts)
						: ''
			};
			form.setFieldsValue(incomes);
			setIncomesObj(incomes);
			sumaUnit(incomes, setTotalCosts);
		})();
	}, []);

	useEffect(() => {
		(async () => {
			setUnit(dataPromoter?.generalData ? Number(dataPromoter.generalData.salesUnits) : undefined);
			setM2t(dataPromoter?.generalData ? Number(dataPromoter.generalData.buildability) : undefined);
		})();
	}, [dataPromoter]);

	useEffect(() => {
		(async () => {
			if (dataPromoter !== undefined) {
				form.setFieldsValue({
					euroBuildProject:
						dataPromoter &&
							dataPromoter.economicData &&
							dataPromoter.economicData.projectIncomes.incomes
							? Number((Number(dataPromoter.economicData.projectIncomes.incomes) / m2t).toFixed(2))
							: ''
				});
				const euroBuild = {
					euroBuildLand:
						dataPromoter &&
							dataPromoter.economicData &&
							dataPromoter.economicData.projectCosts.landCosts
							? Number((Number(dataPromoter.economicData.projectCosts.landCosts) / m2t).toFixed(2))
							: '',
					euroBuildConstruction:
						dataPromoter &&
							dataPromoter.economicData &&
							dataPromoter.economicData.projectCosts.constructionsCosts
							? Number(
								(Number(dataPromoter.economicData.projectCosts.constructionsCosts) / m2t).toFixed(
									2
								)
							)
							: '',
					euroBuildAssociated:
						dataPromoter &&
							dataPromoter.economicData &&
							dataPromoter.economicData.projectCosts.associatedCosts
							? Number(
								(Number(dataPromoter.economicData.projectCosts.associatedCosts) / m2t).toFixed(2)
							)
							: '',
					euroBuildBusiness:
						dataPromoter &&
							dataPromoter.economicData &&
							dataPromoter.economicData.projectCosts.businessCosts
							? Number(
								(Number(dataPromoter.economicData.projectCosts.businessCosts) / m2t).toFixed(2)
							)
							: '',
					euroBuildManagement:
						dataPromoter &&
							dataPromoter.economicData &&
							dataPromoter.economicData.projectCosts.managementCosts
							? Number(
								(Number(dataPromoter.economicData.projectCosts.managementCosts) / m2t).toFixed(2)
							)
							: ''
				};
				form.setFieldsValue(euroBuild);
				sumaUnit(euroBuild, setTotalBuildCosts);
				setEuroBuildObj(euroBuild);
			}
		})();
	}, [m2t]);

	useEffect(() => {
		(async () => {
			if (dataPromoter !== undefined) {
				form.setFieldsValue({
					euroUnitProject:
						dataPromoter &&
							dataPromoter.economicData &&
							dataPromoter.economicData.projectIncomes.incomes
							? Number((Number(dataPromoter.economicData.projectIncomes.incomes) / unit).toFixed(2))
							: ''
				});
				const euroUnit = {
					euroUnitLand:
						dataPromoter &&
							dataPromoter.economicData &&
							dataPromoter.economicData.projectCosts.landCosts
							? Number((dataPromoter.economicData.projectCosts.landCosts / unit).toFixed(2))
							: '',
					euroUnitConstruction:
						dataPromoter &&
							dataPromoter.economicData &&
							dataPromoter.economicData.projectCosts.constructionsCosts
							? Number(
								(dataPromoter.economicData.projectCosts.constructionsCosts / unit).toFixed(2)
							)
							: '',
					euroUnitAssociated:
						dataPromoter &&
							dataPromoter.economicData &&
							dataPromoter.economicData.projectCosts.associatedCosts
							? Number((dataPromoter.economicData.projectCosts.associatedCosts / unit).toFixed(2))
							: '',
					euroUnitBusiness:
						dataPromoter &&
							dataPromoter.economicData &&
							dataPromoter.economicData.projectCosts.businessCosts
							? Number((dataPromoter.economicData.projectCosts.businessCosts / unit).toFixed(2))
							: '',
					euroUnitManagement:
						dataPromoter &&
							dataPromoter.economicData &&
							dataPromoter.economicData.projectCosts.managementCosts
							? Number((dataPromoter.economicData.projectCosts.managementCosts / unit).toFixed(2))
							: ''
				};
				form.setFieldsValue(euroUnit);
				sumaUnit(euroUnit, setTotalUnitCosts);
				setEuroUnitObj(euroUnit);
			}
		})();
	}, [unit]);

	const calculateWithEuro = (calculate, value, name) => {
		if (name !== 'incomes') {
			sumaTotal(incomesObj, name, Number(value), setIncomesObj, setTotalCosts);
			sumaTotal(
				euroBuildObj,
				calculate[0],
				Number(value / m2t),
				setEuroBuildObj,
				setTotalBuildCosts
			);
			sumaTotal(euroUnitObj, calculate[1], Number(value / unit), setEuroUnitObj, setTotalUnitCosts);
		}

		form.setFieldsValue({
			[calculate[0]]: (value / m2t).toFixed(2),
			[calculate[1]]: (value / unit).toFixed(2)
		});
	};

	const calculateWithBuild = (calculate, value, name) => {
		const num = Number(value) * m2t;
		if (name !== 'euroBuildProject') {
			sumaTotal(incomesObj, calculate[0], num, setIncomesObj, setTotalCosts);
			sumaTotal(euroBuildObj, name, Number(value), setEuroBuildObj, setTotalBuildCosts);
			sumaTotal(euroUnitObj, calculate[1], num / unit, setEuroUnitObj, setTotalUnitCosts);
		}

		// setTotalBuildCosts(totalBuildCosts + value);
		// setTotalUnitCosts(totalUnitCosts + num / unit);

		form.setFieldsValue({
			[calculate[0]]: num.toFixed(2),
			[calculate[1]]: (num / unit).toFixed(2)
		});
	};

	const calculateWithUnit = (calculate, value, name) => {
		const num = Number(value) * unit;
		if (name !== 'euroUnitProject') {
			sumaTotal(incomesObj, calculate[0], num, setIncomesObj, setTotalCosts);
			sumaTotal(euroBuildObj, calculate[1], num / m2t, setEuroBuildObj, setTotalBuildCosts);
			sumaTotal(euroUnitObj, name, value, setEuroUnitObj, setTotalUnitCosts);
		}

		// setTotalBuildCosts(totalBuildCosts + num / m2t);
		// setTotalUnitCosts(totalUnitCosts + value);

		form.setFieldsValue({
			[calculate[0]]: num.toFixed(2),
			[calculate[1]]: (num / m2t).toFixed(2)
		});
	};
	const mandatoryValues = [
		'incomes',
		'euroBuildProject',
		'euroUnitProject',
		'landCosts',
		'euroBuildLand',
		'euroUnitLand'
	];

	const inputForm = (name, label, placeholder, rule, calculate) => {
		const colSpan1 = {
			xs: 24,
			lg: name === 'totalCosts' ? 12 : rule !== 'TOTAL_EUROS' ? 6 : 12
		};
		let suffix = '';

		if (rule === 'TOTAL_EUROS') {
			suffix = '€';
		} else if (rule === 'EUROS_BUILDABILITY') {
			suffix = '€/m²t';
		} else {
			suffix = '€/ud';
		}

		const validateMessage = (name: string) => {
			if (name === 'incomes' || name === 'euroBuildProject' || name === 'euroUnitProject') {
				return t('balance.incomesTitle') + ' ' + t('form.formRequired')
			} else if (name === 'landCosts' || name === 'euroBuildLand' || name === 'euroUnitLand') {
				return t('promoterLoan.landCosts') + ' ' + t('form.formRequired')
			}
		}

		return (
			<Col {...colSpan1} style={{ paddingRight: 4, paddingLeft: 4 }}>
				<Form.Item
					className="input-financial-data-error-mobile"
					name={name}
					label={label && (
						<Text style={{ fontSize: 12 }}>{t(label)}</Text>
					)
					}
					rules={[
						{
							required: mandatoryValues.includes(name) && typeOfUnit === rule ? true : false,
							message: validateMessage(name)
						}
					]}>
					<InputNumber
						gbType={undefined}
						defaultValue={''}
						suffix={suffix}
						disabled={typeOfUnit !== rule ? true : false}
						placeholder={placeholder}
						style={{
							height: 40,
							width: '100%',
							borderRadius: 8,
						}}
						onChange={(e) => {
							if (e !== '') {
								rule === 'TOTAL_EUROS'
									? calculateWithEuro(calculate, Number(e), name)
									: rule === 'EUROS_BUILDABILITY'
										? calculateWithBuild(calculate, Number(e), name)
										: rule === 'EUROS_UNIT' && calculateWithUnit(calculate, Number(e), name);
							}
						}}
					/>
				</Form.Item>
			</Col>
		);
	};
	const onChange = (value) => {
		setTypeOfUnit(value);
		dispatch(getPromoterData({ ...dataPromoter, typeOfUnit: value }));
	};
	return (
		<>
			<div className="paragraph-style" style={{ marginBottom: 16, marginTop: 16 }}>
				{t('promoter.unit')}
			</div>
			<Form.Item name={'radio-options'} label={''}>
				{/*<Radio.Group
					className={lg === 8 ? 'radio-gibobs-promoter' : 'radio-gibobs-promoter-b'}
					onChange={onChange}
						defaultValue={typeOfUnit}>*/}
				<Row gutter={16}>
					<Col span={8}>
						<Button
							className={
								typeOfUnit === 'TOTAL_EUROS' ? 'button-gibobs-promoter2' : 'button-gibobs-promoter'
							}
							onClick={() => onChange('TOTAL_EUROS')}>
							{t('€ total')}
						</Button>
					</Col>
					<Col span={8}>
						<Button
							className={
								typeOfUnit === 'EUROS_BUILDABILITY'
									? 'button-gibobs-promoter2'
									: 'button-gibobs-promoter'
							}
							onClick={() => onChange('EUROS_BUILDABILITY')}>
							{t('€/m²t')}
						</Button>
					</Col>
					<Col span={8}>
						<Button
							className={
								typeOfUnit === 'EUROS_UNIT' ? 'button-gibobs-promoter2' : 'button-gibobs-promoter'
							}
							onClick={() => onChange('EUROS_UNIT')}
							value={'EUROS_UNIT'}>
							{t('€/ud.')}
						</Button>
					</Col>
				</Row>
				{/*</Radio.Group>*/}
			</Form.Item>

			<div
				className="sub-title-promoter-gibobs"
				style={{
					marginBottom: 16,
					marginTop: 32,
					width: '100%',
					display: 'flex',
					justifyContent: 'center'
				}}>
				<EuroOutlined style={{ color: '#52C41A', fontSize: 18, marginRight: 4 }} />
				{t('promoterLoan.projectIncome')}
			</div>
			<Row>
				<div style={{ display: 'flex', alignItems: 'center', padding: '0 3px 6px', gap: 3 }}>
					<div style={{ color: '#ff4d4f', fontSize: 16, marginTop: 4 }}>*</div>
					<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858' }}>{t('balance.incomesTitle')}</Text>
				</div>
				<Col span={24}>
					{inputForm('incomes', undefined, '€', 'TOTAL_EUROS', [
						'euroBuildProject',
						'euroUnitProject'
					])}
				</Col>
			</Row>
			<Row style={{ width: '100%', marginTop: -5 }}>
				<Col span={12}>
					{inputForm('euroBuildProject', undefined, '0 €/m²t', 'EUROS_BUILDABILITY', [
						'incomes',
						'euroUnitProject'
					])}
				</Col>
				<Col span={12}>
					{inputForm('euroUnitProject', undefined, '0 €/ud.', 'EUROS_UNIT', [
						'incomes',
						'euroBuildProject'
					])}
				</Col>
			</Row>
			<div
				className="sub-title-promoter-gibobs"
				style={{
					marginTop: 16,
					marginBottom: 16,
					width: '100%',
					display: 'flex',
					justifyContent: 'center'
				}}>
				<EuroOutlined style={{ color: '#FF4D4F', fontSize: 18, marginRight: 4 }} />
				{t('promoterLoan.projectCosts')}
			</div>
			<Row>
				<div style={{ display: 'flex', alignItems: 'center', padding: '0 3px 6px', gap: 3 }}>
					<div style={{ color: '#ff4d4f', fontSize: 16, marginTop: 4 }}>*</div>
					<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858' }}>{t('promoterLoan.landCosts')}</Text>
				</div>
				<Col span={24}>
					{inputForm('landCosts', undefined, '€', 'TOTAL_EUROS', [
						'euroBuildLand',
						'euroUnitLand'
					])}
				</Col>
			</Row>
			<Row style={{ marginTop: -5 }}>
				<Col span={12}>
					{inputForm('euroBuildLand', undefined, '0 €/m²t', 'EUROS_BUILDABILITY', [
						'landCosts',
						'euroUnitLand'
					])}
				</Col>
				<Col span={12}>
					{inputForm('euroUnitLand', undefined, '0 €/ud.', 'EUROS_UNIT', ['landCosts', 'euroBuildLand'])}
				</Col>
			</Row>

			<Row>
				{inputForm('constructionsCosts', 'promoterLoan.constuctionCosts', '€', 'TOTAL_EUROS', [
					'euroBuildConstruction',
					'euroUnitConstruction'
				])}
			</Row>
			<Row style={{ marginTop: -5 }}>
				<Col span={12}>
					{inputForm('euroBuildConstruction', undefined, '0 €/m²t', 'EUROS_BUILDABILITY', [
						'constructionsCosts',
						'euroUnitConstruction'
					])}
				</Col>
				<Col span={12}>
					{inputForm('euroUnitConstruction', undefined, '0 €/ud.', 'EUROS_UNIT', [
						'constructionsCosts',
						'euroBuildConstruction'
					])}
				</Col>
			</Row>
			<Row>
				{inputForm('associatedCosts', 'promoterLoan.associatedCosts', '€', 'TOTAL_EUROS', [
					'euroBuildAssociated',
					'euroUnitAssociated'
				])}
			</Row>
			<Row style={{ marginTop: -5 }}>
				<Col span={12}>
					{inputForm('euroBuildAssociated', undefined, '0 €/m²t', 'EUROS_BUILDABILITY', [
						'associatedCosts',
						'euroUnitAssociated'
					])}
				</Col>

				<Col span={12}>
					{inputForm('euroUnitAssociated', undefined, '0 €/ud.', 'EUROS_UNIT', [
						'associatedCosts',
						'euroBuildAssociated'
					])}
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					{inputForm('businessCosts', 'promoterLoan.commercialCosts', '€', 'TOTAL_EUROS', [
						'euroBuildBusiness',
						'euroUnitBusiness'
					])}
				</Col>
			</Row>
			<Row style={{ marginTop: -5 }}>
				<Col span={12}>
					{inputForm('euroBuildBusiness', undefined, '0 €/m²t', 'EUROS_BUILDABILITY', [
						'businessCosts',
						'euroUnitBusiness'
					])}
				</Col>
				<Col span={12}>
					{inputForm('euroUnitBusiness', undefined, '0 €/ud.', 'EUROS_UNIT', [
						'businessCosts',
						'euroBuildBusiness'
					])}
				</Col>
			</Row>
			<Row>
				{inputForm('managementCosts', 'promoterLoan.managementCosts', '€', 'TOTAL_EUROS', [
					'euroBuildManagement',
					'euroUnitManagement'
				])}
			</Row>
			<Row style={{ marginTop: -5 }}>
				<Col span={12}>
					{inputForm('euroBuildManagement', undefined, '0 €/m²t', 'EUROS_BUILDABILITY', [
						'managementCosts',
						'euroUnitManagement'
					])}
				</Col>
				<Col span={12}>
					{inputForm('euroUnitManagement', undefined, '0 €/ud.', 'EUROS_UNIT', [
						'managementCosts',
						'euroBuildManagement'
					])}
				</Col>
			</Row>
			<Divider style={{ marginTop: 0, marginBottom: 16, border: '2px solid #C0DAEE' }} />
			<Row style={{ display: 'none'}}>
				{inputForm('totalCosts', 'promoterLoan.totalCosts', '€', undefined, undefined)}
				{inputForm('euroBuildTotal', '€/m²t', '0', undefined, undefined)}
				{inputForm('euroUnitTotal', '€/ud.', '0', undefined, undefined)}
			</Row>
			<Row style={{ textAlignLast: 'center' }}>
				<Col span={8} style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
					<Text style={{
						fontSize: 12,
						fontWeight: typeOfUnit === 'TOTAL_EUROS' ? 600 : 400,
						color: typeOfUnit === 'TOTAL_EUROS' ? '#2F4858' : '#748EA0'
					}}>
						{t('promoterLoan.totalCosts')}
					</Text>
					<Text style={{ fontSize: 14, fontWeight: 400, color: '#2F4858' }}>
						{totalCosts && numeral(totalCosts).format('0,0$')}
					</Text>
				</Col>
				<Col span={8} style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
					<Text style={{
						fontSize: 12,
						fontWeight: typeOfUnit === 'EUROS_BUILDABILITY' ? 600 : 400,
						color: typeOfUnit === 'EUROS_BUILDABILITY' ? '#2F4858' : '#748EA0'
					}}>
						€/m²t
					</Text>
					<Text style={{ fontSize: 14, fontWeight: 400, color: '#2F4858' }}>
						{totalBuildCosts && totalBuildCosts.toFixed(2)}
					</Text>
				</Col>
				<Col span={8} style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
					<Text style={{
						fontSize: 12,
						fontWeight: typeOfUnit === 'EUROS_UNIT' ? 600 : 400,
						color: typeOfUnit === 'EUROS_UNIT' ? '#2F4858' : '#748EA0'
					}}>
						€/ud
					</Text>
					<Text style={{ fontSize: 14, fontWeight: 400, color: '#2F4858' }}>
						{totalUnitCosts && totalUnitCosts.toFixed(2)}
					</Text>
				</Col>
			</Row>
		</>
	);
};

const FinancialDataFormMobile = () => {
	const [form] = Form.useForm();
	const params = useParams<{ operationId: string }>();
	const operationId = params.operationId;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [modalDescription, setModalDescription] = useState(false);
	const dataPromoter = useSelector<ReduxStore, any>((state) => state.promoter.dataFormPromoter);
	const userToken = useSelector<ReduxStore, any>((state) => state.auth.token);
	const [loading, setLoading] = useState(false);

	const openNotificationWithIcon = () => {
		notificationGibobs('error', t('m_full.errorTitle'), t('form.incompleteInformation'));
	};

	const sendFinancialData = (values) => {
		setLoading(true);
		axios
			.post(`${env.api.url}/promoters/promoter-projects/economic-data/${operationId}`, values, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				if (response.data.success) {
					setLoading(false);
					dispatch(getPromoterData({ ...dataPromoter, economicData: values }));
					navigate(`/promoter-loan/capital-structure/${operationId}`);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const finanlcialDataVerificated = (values) => {
		const objFinancial = {
			economicDataUnits: 'TOTAL_EUROS',
			projectIncomes: {
				incomes: Number(values.incomes)
			},
			projectCosts: {
				landCosts: Number(values.landCosts),
				businessCosts: Number(values.businessCosts),
				constructionsCosts: Number(values.constructionsCosts),
				managementCosts: Number(values.managementCosts),
				associatedCosts: Number(values.associatedCosts),
				totalCosts: Number(values.totalCosts)
			}
		};
		sendFinancialData(objFinancial);
	};
	return (
		<>
			<div
				style={{
					padding: 16
				}}>
				<StepPromoterMobile stage={2} steps={3} />

				<Card
					className="card-promoter-revision"
					style={{ marginTop: 16, width: '110%', marginLeft: -16 }}>
					<Row gutter={12}>
						<Col span={2}>
							<EyeOutlined style={{ color: '#1890FF', marginTop: 12, fontSize: 20 }} />
						</Col>
						<Col span={17} style={{ marginLeft: 12 }}>
							<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
								{t('promoter.revision')}
							</Text>
						</Col>
						<Col span={4} style={{ marginTop: 12, textAlign: 'right' }} onClick={() => setModalDescription(!modalDescription)}>
							<Text style={{ color: '#126CBF', fontSize: 14, fontWeight: 400 }}>
								{t('doc.seeNew')}
							</Text>
						</Col>
					</Row>
				</Card>

				<Form
					form={form}
					className={'form-promoter-mobile'}
					style={{ minHeight: '180vh' }}
					onFinishFailed={openNotificationWithIcon}
					scrollToFirstError={true}
					onFinish={finanlcialDataVerificated}
					layout="vertical"
					name="Password"
					initialValues={
						dataPromoter
							? {
								'radio-options': 'TOTAL_EUROS',
								...dataPromoter
							}
							: { 'radio-options': 'TOTAL_EUROS' }
					}>
					{FormFinancialData(12, form)}

					<div style={{
						position: 'fixed',
						width: '100%',
						height: 80,
						bottom: 0,
						left: 0,
						boxShadow: '0px -2px 8px 0px rgba(47, 72, 88, 0.10)',
						background: '#fff',
						zIndex: 999,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: 16
					}}>
						<Row gutter={8} style={{ width: '100%' }}>
							<Col span={12}>
								<Button
									type="dashed"
									className="button-secundary-gibobs"
									style={{
										width: '100%',
										height: 48,
										borderRadius: 8
									}}
									onClick={() => navigate(`/promoter-loan/general-data/${operationId}`)}>
									{t('form.previous')}
								</Button>
							</Col>
							<Col span={12}>
								<Button
									htmlType="submit"
									type="primary"
									style={{
										width: '100%',
										height: 48,
										borderRadius: 8
									}}
									loading={loading}>
									{t('form.next')}
								</Button>
							</Col>
						</Row>
					</div>
				</Form>
				<ModalDescriptionFinancial
					visible={modalDescription}
					onCreate={() => setModalDescription(false)}
					onCancel={() => setModalDescription(false)}
					width={328}
					mobile={true}
				/>
				<HelpButtonMobile
					origin={'IN_FORM_THREE_STEPS'}
					simulation={undefined}
				/>
			</div>
		</>
	);
};

export default FinancialDataFormMobile;
