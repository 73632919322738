import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Layout,

} from "antd";
import axios from "axios";
import env from "../../environment";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { stages } from "../../store/auth/actions";
import { isMobile } from "react-device-detect";
import LayoutProducts from "../Products/LayoutProducts";
import FollowUpOperations from "../HerramientasVenta/FollowUpOperations/Components/FollowUpOperations";
import EcoFollowUp from "../HerramientasVenta/FollowUpOperations/EcoFollowUp";


const OperationPage = ({ idIMMO, snooze }) => {
  const dispatch = useDispatch();
  let { operationId, stage } = useParams();
  const userToken = useSelector((state) => state.auth.token);
  const userMortgageId = idIMMO !== undefined ? idIMMO : operationId;
  const operations = useSelector((state) => state.auth.operations);
  const [operationsData, setOperationsData] = useState([]);
  const [dataMortgage, setDataMortgage] = useState();
  const navigate = useNavigate();;
  const refreshDataChange = useSelector((state) => state.auth.refreshData);
  let stagesRedux = useSelector((state) => state.auth.stages);

  const rol = useSelector((state) => state.auth.roles);
  let operationsINMO = useSelector((state) => state.realestate.operations);
  const [, setDataAllMortgage] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 1);
   
    let currentoperation = null;
    if (rol !== "realestate") {
      currentoperation = operations.find(
        (operation) => operation.operationId === operationId
      );
    }

    if (
      stagesRedux !== "" &&
      stagesRedux !== null &&
      stage !== stagesRedux &&
      rol !== "realestate"
    ) {
      //stage = stagesRedux
      /*if (subStage) {
        navigate(`/operations/${userMortgageId}/${stage}/${subStage}`)
      }else{
      navigate(`/operations/${userMortgageId}/${stage}`)
      }*/
    }
    refreshData();
    if (!idIMMO && currentoperation) {
      if (operations !== undefined && operations.length > 0) {
        if (currentoperation.stage !== stage) {
          navigate("/operations/" + currentoperation.operationId + "/" + currentoperation.stage);
        } else {
          navigate(
            "/operations/" + currentoperation.operationId + "/" + stage
          );
        }
      }

      setOperationsData(currentoperation);
    } else {
      if (operations) {
        
      }
      currentoperation = operationsINMO.find(
        (operation) => operation.id === idIMMO
      );

      setOperationsData(currentoperation);
    }
  }, [operationId, operations, operationsINMO]);

  useEffect(() => {
    if (
      operationsData &&
      operationsData.stage !== undefined &&
      operationsData.stage !== null
    ) {
      dispatch(stages(operationsData.stage));
    }

    if (
      operationsData !== undefined &&
      operationsData !== null &&
      operationsData.stage !== undefined &&
      operationsData.stage !== null &&
      operationsData.stage !== stage &&
      rol !== "realestate"
    ) {
      if (operationsData.stage !== stage) {
        navigate(`/operations/${userMortgageId}/${stage}`);
      } else {
        let link = null;

        if (
          (operationsData.stage === "full" &&
            operationsData.operationData.payments === undefined) ||
          (operationsData.stage === "full" &&
            operationsData.operationData.payments.refundableDepositPaid ===
              undefined)
        ) {
          link = `/operations/${operationsData.mortgageId}/${operationsData.stage}`;
          navigate(link);
        }
      }
    }
  }, [operationsData]);

  const refreshData = () => {
    axios
      .get(`${env.api.url}/v1/operations/${userMortgageId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setDataAllMortgage(response.data.data);
        setDataMortgage(response.data.data.operationData);
      });
  };
  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    refreshData();
    dispatch(stages(operationsData.stage));
  }, [userMortgageId, userToken, refreshDataChange]);

  

  let layoutProducts;
  if (!idIMMO && !isMobile) {
    layoutProducts = (
      <LayoutProducts name={operationsData && operationsData.name} />
    );
  } else if (!idIMMO && operationsData && isMobile) {
    layoutProducts = (
      <LayoutProducts name={operationsData && operationsData.name} />
    );
  }

  if (dataMortgage) {
    return (
      <Layout style={{ marginTop: isMobile ? -20 : undefined }}>
        {layoutProducts}

        {rol === "realestate" &&
          operationsData &&
          operationsData.length !== 0 && (
            <FollowUpOperations
              stages={operationsData && operationsData.stage}
              dataMortgage={dataMortgage}
              userMortgageId={idIMMO}
              nameHP={
                operationsData && operationsData.name && operationsData.name
              }
              closed={operationsData}
              status={operationsData.status}
              statusOperation={operationsData.operationStatus}
              snooze={snooze}
            />
          )}
        {rol === "realestate" && operationsData === undefined && (
          <EcoFollowUp
            stages={operationsData && operationsData.stage}
            dataMortgage={dataMortgage}
            userMortgageId={idIMMO}
          />
        )}
       
      </Layout>
    );
  } else {
    return <></>;
  }
};

export default OperationPage;
