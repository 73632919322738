import React, { useState, useEffect } from 'react';
import { Button, Table, Modal, Tooltip, Spin, Card, Row, Col, Typography } from 'antd';

import Layout from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import {
	FilePdfOutlined,
	CarOutlined,
	HeartOutlined,
	FileDoneOutlined,
	LockOutlined,
	HomeOutlined,
	ContainerOutlined,
	EuroOutlined,
	CreditCardOutlined,
	ArrowLeftOutlined
} from '@ant-design/icons';
import axios from 'axios';
import env from '../../../environment';
import numeral from 'numeral';
import { useSelector, useDispatch } from 'react-redux';
import { refreshData } from '../../../store/auth/actions';
import { refreshStages } from '../../../store/auth/actions';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { trace } from '../../../components/tagmanager';
import { LoadingOutlined } from '@ant-design/icons';
import PersonalAnalyst from '../../../components/widgets/Different-Widgets/personalAnalyst';
import Stages from '../../../components/widgets/Different-Widgets/stagesMortgagesWidget';
import { getAnalytics, logEvent } from 'firebase/analytics';
import paymentsSecure from '../../../assets/icons/paymentsSecure.png';

const { Text } = Typography;

const OfertsTable = ({  }) => {
	const [, setValuesData] = useState([]);
	const { t } = useTranslation();
	const userToken = useSelector((state) => state.auth.token);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const operations = useSelector((state) => state.auth.operations);
	const { operationId } = useParams();
	const userMortgageId = operationId;
	const nameOperations =
		operations !== undefined &&
		operations.find((operation) => operation.operationId === operationId);
	const rol = useSelector((state) => state.auth.roles);
	const location = useLocation();
	const analytics = getAnalytics();

	let data = [
		{
			key: '1',
			name: (
				<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 400 }}>
					{t('appraisal.nameTableEntailment')}
				</Text>
			),
			money: '',
			address: '',
			code: 'linkages',
			fontSize: '12px'
		},
		{
			key: '2',
			name: (
				<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 400 }}>
					{t('offers.quoteMonthOneYear')}
				</Text>
			),
			money: '',
			address: '',
			code: 'quote'
		},
		{
			key: '3',
			name: (
				<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 400 }}>
					{t('appraisal.nameTableAmount')}
				</Text>
			),
			money: '',
			address: '',
			code: 'amount'
		},
		{
			key: '4',
			name: (
				<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 400 }}>
					{t('appraisal.nameTableTerm')}
				</Text>
			),
			money: '',
			address: '',
			code: 'term'
		},
		{
			key: '5',
			name: (
				<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 400 }}>
					{t('appraisal.nameTableSpread')}
				</Text>
			),
			money: '',
			address: '',
			code: 'ratio'
		},
		{
			key: '6',
			name: (
				<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 400 }}>
					{t('offers.tinOneYear')}
				</Text>
			),
			money: '',
			address: '',
			code: 'tin'
		},

		{
			key: '7',
			name: (
				<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 400 }}>
					{t('offers.taeOneYear')}
				</Text>
			),
			money: '',
			address: '',
			code: 'tae'
		},

		{
			key: '8',
			name: (
				<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 400 }}>
					{t('appraisal.nameTableReview')}
				</Text>
			),
			money: '',
			address: '',
			code: 'revision'
		},
		{
			key: '9',
			name: (
				<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 400 }}>
					{t('appraisal.nameTableOpeningCommission')}
				</Text>
			),
			money: '',
			address: '',
			code: 'openFee'
		},
		{
			key: '10',
			name: (
				<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 400 }}>
					{t('appraisal.nameTablePartialAmortization')}
				</Text>
			),
			money: '',
			address: '',
			code: 'partialFee'
		},
		{
			key: '11',
			name: (
				<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 400 }}>
					{t('appraisal.nameTableTotalAmortization')}
				</Text>
			),
			money: '',
			address: '',
			code: 'totalFee'
		},
		{
			key: '12',
			name: (
				<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 400 }}>
					{t('offers.offersTableTitle')}
				</Text>
			),
			money: '',
			address: '',
			code: 'but'
		}
	];

	const selectImagebank = (bankName, bankCode) => {
		return (
			<div>
				<img
					src={
						'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/banks/' +
						`${bankCode}` +
						'.png'
					}
					alt="Oferta"
					style={{ marginRight: '5px', height: 32 }}
				/>
			</div>
		);
	};
	const [columns, setColums] = useState([
		{
			title: '',
			dataIndex: 'name',
			width: 184,
			backgroundColor: 'white',
			textWrap: 'word-break'
		}
	]);
	const [idBank, setIdBank] = useState();
	const refreshDataChange = useSelector((state) => state.auth.refreshData);
	const dispatch = useDispatch();
	const changeStages = useSelector((state) => state.auth.refreshStages);
	const [nameBank, setBankName] = useState('');
	const [loading, setLoading] = useState(false);
	const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
	const navigate = useNavigate();;

	const handleOk = () => {
		setIsModalVisible(false);
		setLoading(true);
		logEvent(analytics, 'select_content', {
			content_type: 'operation.offerSelection',
			content_id: 'select.offer.button'
		});
		axios
			.get(`${env.api.url}/api/offers/${userMortgageId}/selectOffer/${idBank}?isHadmin=${false}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then(() => {
				setTimeout(() => {
					setLoading(false);
					dispatch(refreshData(!refreshDataChange));
					dispatch(refreshStages(!changeStages));
					navigate(`/operations/${userMortgageId}/appraisal`);
				}, 3000);
			});
	};

	

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const handleClickChoose = (id, bankName) => {
		setBankName(bankName);
		setIsModalVisible(true);
		setIdBank(id);
	};

	useEffect(() => {
		setValuesData(location.state.stage);

		const offerColums = JSON.parse(JSON.stringify(columns));

		location.state.stage &&
			location.state.stage.map((bank, index) => {
				if (index <= 4 && offerColums.length <= 4) {
					let years = bank.term / 12;
					offerColums.push({
						title: selectImagebank(bank.bankName, bank.bankCode),
						align: 'center',
						width: '184px',
						render: (item) => {
							var field = [];
							const [entailment] = bank.data.entailments;
							if (item.code === 'linkages') {
								if (entailment.linkages !== undefined) {
									if (
										entailment.linkages.insecurance !== undefined &&
										entailment.linkages.insecurance !== false
									) {
										field.push(
											<Tooltip placement="bottom" title="Seguro de vida">
												<HeartOutlined className="iconOffersTable" />{' '}
											</Tooltip>
										);
									}
									if (entailment.linkages.car !== undefined && entailment.linkages.car !== false) {
										field.push(
											<Tooltip placement="bottom" title="Seguro del coche">
												<CarOutlined className="iconOffersTable" />{' '}
											</Tooltip>
										);
									}
									if (
										entailment.linkages.payroll !== undefined &&
										entailment.linkages.payroll !== false
									) {
										field.push(
											<Tooltip placement="bottom" title="Domiciliación de nómina">
												<FileDoneOutlined className="iconOffersTable" />{' '}
											</Tooltip>
										);
									}
									if (
										entailment.linkages.unemployment !== undefined &&
										entailment.linkages.unemployment !== false
									) {
										field.push(
											<Tooltip placement="bottom" title="Seguro de desempleo">
												<LockOutlined className="iconOffersTable" />{' '}
											</Tooltip>
										);
									}
									if (
										entailment.linkages.home !== undefined &&
										entailment.linkages.home !== false
									) {
										field.push(
											<Tooltip placement="bottom" title="Seguro del hogar">
												<HomeOutlined className="iconOffersTable" />{' '}
											</Tooltip>
										);
									}
									if (
										entailment.linkages.payments !== undefined &&
										entailment.linkages.payments !== false
									) {
										field.push(
											<Tooltip placement="bottom" title="Domiciliación de recibos">
												<ContainerOutlined className="iconOffersTable" />{' '}
											</Tooltip>
										);
									}
									if (
										entailment.linkages.pensionPlan !== undefined &&
										entailment.linkages.pensionPlan !== false
									) {
										field.push(
											<Tooltip placement="bottom" title="Plan de pensiones">
												<EuroOutlined className="iconOffersTable" />{' '}
											</Tooltip>
										);
									}
									if (
										entailment.linkages.cards !== undefined &&
										entailment.linkages.cards !== false
									) {
										field.push(
											<Tooltip placement="bottom" title="Tarjeta de credito/débito">
												<CreditCardOutlined className="iconOffersTable" />{' '}
											</Tooltip>
										);
									}

									if (
										entailment.linkages.protectedPayments !== undefined &&
										entailment.linkages.protectedPayments !== false
									) {
										field.push(
											<Tooltip placement="bottom" title={t('icon.viability2')}>
												<img width={26} height={26} src={paymentsSecure} alt="" />{' '}
											</Tooltip>
										);
									}
								}
							} else if (item.code === 'amount') {
								field = (
									<Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 600 }}>
										{numeral(Number(bank.amount)).format('0,0.00 $')}
									</Text>
								);
							} else if (item.code === 'term') {
								field = (
									<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
										{years + ' ' + t('viability.years')}
									</Text>
								);
							} else if (item.code === 'ratio') {
								field = (
									<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
										{bank.ratio !== 'Seleccione' ? bank.ratio : 'No aplica'}
									</Text>
								);
							} else if (item.code === 'tin') {
								if (
									bank.data &&
									bank.data.entailments &&
									bank.data.entailments[0].stages &&
									bank.data.entailments[0].stages.length > 0
								) {
									return (
										<div
											style={{
												color: '#748EA0',
												fontSize: 14,
												fontWeight: 600
											}}>
											{numeral(Number(bank.data.entailments[0].stages[0].tin)).format('0.00') +
												' %'}
										</div>
									);
								}
							} else if (item.code === 'tinAll') {
								if (
									bank.data &&
									bank.data.entailments &&
									bank.data.entailments[0].stages &&
									bank.data.entailments[0].stages[1] !== undefined
								) {
									return (
										<div
											style={{
												color: '#748EA0',
												fontSize: 14,
												fontWeight: 600
											}}>
											{numeral(Number(bank.data.entailments[0].stages[1].tin)).format('0.00') +
												' %'}
										</div>
									);
								} else {
									return (
										<div
											style={{
												color: '#748EA0',
												fontSize: 14,
												fontWeight: 600
											}}>
											{bank?.data?.entailments?.[0]?.stages?.[0]?.tin
												? numeral(Number(bank?.data?.entailments?.[0]?.stages?.[0]?.tin)).format(
														'0.00'
												  ) + ' %'
												: '-'}
										</div>
									);
								}
							} else if (item.code === 'tae') {
								if (
									bank.data &&
									bank.data.entailments &&
									bank.data.entailments[0].stages &&
									bank.data.entailments[0].stages.length > 0
								) {
									return (
										<div
											style={{
												color: '#748EA0',
												fontSize: 14,
												fontWeight: 600
											}}>
											{bank?.data?.entailments?.[0]?.stages?.[0]?.tae
												? numeral(Number(bank?.data?.entailments?.[0]?.stages?.[0]?.tae)).format(
														'0.00'
												  ) + ' %'
												: '-'}
										</div>
									);
								}
							} else if (item.code === 'taeAll') {
								if (
									bank.data &&
									bank.data.entailments &&
									bank.data.entailments[0].stages &&
									bank.data.entailments[0].stages[1] !== undefined
								) {
									return (
										<div
											style={{
												color: '#748EA0',
												fontSize: 14,
												fontWeight: 600
											}}>
											{numeral(Number(bank.data.entailments[0].stages[1].tae)).format('0.00') +
												' %'}
										</div>
									);
								} else {
									return (
										<div
											style={{
												color: '#748EA0',
												fontSize: 14,
												fontWeight: 600
											}}>
											{bank?.data?.entailments?.[0]?.stages?.[0]?.tae
												? numeral(Number(bank?.data?.entailments?.[0]?.stages?.[0]?.tae)).format(
														'0.00'
												  ) + ' %'
												: '-'}
										</div>
									);
								}
							} else if (item.code === 'quote') {
								if (
									bank.data &&
									bank.data.entailments &&
									bank.data.entailments[0].stages &&
									bank.data.entailments[0].stages.length > 0
								) {
									field = (
										<Text
											style={{
												color: '#748EA0',
												fontSize: 14,
												fontWeight: 600
											}}>
											{numeral(Number(bank.data.entailments[0].stages[0].quote)).format('0,0.00 $')}
										</Text>
									);
								}
							} else if (item.code === 'revision') {
								field = (
									<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
										{bank.revision !== 'Seleccione' ? bank.revision : 'No aplica'}
									</Text>
								);
							} else if (item.code === 'openFee') {
								field = (
									<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
										{numeral(Number(bank.openFee)).format('0,0.00 $')}
									</Text>
								);
							} else if (item.code === 'partialFee') {
								field = (
									<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
										{bank.partialFee ? bank.partialFee : ' '}
									</Text>
								);
							} else if (item.code === 'totalFee') {
								field = (
									<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
										{bank.totalFee ? bank.totalFee : ' '}
									</Text>
								);
							}

							if (item.code === 'but') {
								field.push(
									<div
										style={{
											display: 'inline-block',
											gridTemplateRows: '0.5fr 0.5fr'
										}}>
										<div style={{ display: 'flex' }}>
											<Button
												href={`${env.api.url}/v1/banks/generate-pdf-bank/${bank.id}?token=${userToken}`}
												target={'_blank'}
												style={{
													width: '60px',
													textAlign: 'center',
													fontSize: '12px',
													border: 'none',
													display: 'contents',
													color: '#748EA0'
												}}>
												<FilePdfOutlined style={{ fontSize: '20px' }} />
												<div
													style={{
														marginTop: '-4px',
														marginLeft: '10px'
													}}>
													{t('offers.seePDF')}
												</div>
											</Button>
										</div>
										<div>
											<Button
												onClick={() => handleClickChoose(bank.id, bank.bankName)}
												style={{
													backgroundColor: 'white',
													borderColor: '#02c3cd',
													borderRadius: 4,
													color: '#02c3cd',
													width: '88px',
													height: '24px',
													fontWeight: 600,
													padding: 0,
													fontSize: '12px',
													marginTop: '36px'
												}}>
												{t('offers.choose')}
											</Button>
										</div>
									</div>
								);
							}

							return field;
						}
					});
				}
			});

		setColums(offerColums);

		trace({
			location,
			userToken,
			rol,
			stage: 'oferts',
			operationName: nameOperations && nameOperations.name
			//extra: operationsData
		});

		logEvent(analytics, 'screen_view', {
			screen_name: 'm.component.offerSelection'
		});
	}, []);

	return (
        (<Layout style={{ width: 'calc(100% - 40px)', paddingLeft: 40, paddingRight: 40 }}>
            <div style={{ marginTop: 24, marginBottom: 24 }}>
				<ArrowLeftOutlined
					onClick={() => navigate(`/operations/${userMortgageId}/oferts`)}
					style={{
						color: '#02C3CD',
						fontSize: 16,
						marginRight: 16,
						cursor: 'pointer'
					}}
				/>
				<Text style={{ color: '#02C3CD', fontSize: 16, fontWeight: 600 }}>
					{t('oferts.selectionTable')}
				</Text>
			</div>
            {loading && (
				<div
					style={{
						textAlign: 'center',
						height: '100vh',
						backgroundColor: 'white',
						marginTop: 300
					}}>
					<Spin indicator={antIcon} className="spin" />
				</div>
			)}
            {!loading && (
				<Row gutter={[24, 24]} style={{ marginBottom: 100 }}>
					<Col span={19}>
						<Card className="gb-card">
							<Text className="title-card">{t('oferts.compareOffers')}</Text>

							<Table
								columns={columns}
								dataSource={data}
								bordered
								pagination={false}
								style={{
									marginLeft: '32px',
									marginRight: '32px',
									marginTop: 32
								}}
							/>
						</Card>
					</Col>

					<Col span={5} style={{ display: 'flex', flexDirection: 'column' }}>
						<Card className="gb-card" style={{ textAlign: 'center' }}>
							<Text className="title-card">{t('document.personalAnalyst')}</Text>
							<PersonalAnalyst analyst={nameOperations}></PersonalAnalyst>
						</Card>
						<Card className="gb-card" style={{ marginTop: 24, textAlign: 'center' }}>
							<Text className="title-card">{t('init.mortgage')}</Text>
							<Stages></Stages>
						</Card>
					</Col>
				</Row>
			)}
            <Modal
				className="modal-common-mobile"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { height: 32, fontSize: 14, marginTop: 20 }
				}}
				okButtonProps={{
					className: 'button-primari-gibobs',
					style: { height: 32, fontSize: 14, marginTop: 20 }
				}}
				open={isModalVisible}
				title={
					<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
						{t('appraisal.nameTitleModalElection')}
					</Text>
				}
				okText={t('offers.choose')}
				cancelText={t('offers.cancel')}
				width={400}
				onCancel={handleCancel}
				onOk={handleOk}>
				<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
					{t('offers.question1') + ' ' + nameBank + ' ' + t('offers.question2')}
				</Text>
			</Modal>
        </Layout>)
    );
};

export default OfertsTable;
