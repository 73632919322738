import React, { useState, useEffect } from "react";
import { Button, Upload, Typography, Steps, Card, Modal, notification } from "antd";
import { useTranslation } from "react-i18next";
import {
  FilePdfOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PlusOutlined,
  FileOutlined,
  LikeOutlined,
} from "@ant-design/icons";
import axios from "axios";
import env from "../../../environment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CheckOutComponent from "../../ProductsImo/CheckOutComponent";
import { refreshData } from "../../../store/auth/actions";
import { trace } from "../../../components/tagmanager";
import { useParams, useLocation } from "react-router-dom";
import { InputDate } from 'afrodita';

import dayjs from 'dayjs';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');
dayjs.locale('vi');


const { Text } = Typography;
const { Step } = Steps;
const { confirm } = Modal;

const FormalizationMobile = ({ dataMortgage, userMortgageId, stages }) => {
  const { t } = useTranslation();
  const [valuesData, setValuesData] = useState([]);
  const userToken = useSelector((state) => state.auth.token);
  const [saveFileDataField, setSaveFileDataField] = useState(null);
  const [existAppraisal, setExistAppraisal] = useState(false);
  const [typeFile, setTypeFile] = useState();
  const [fein, setfein] = useState(false);
  const [finishStep, setFinishStep] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();;
  const [bankOffer, setBankOffer] = useState();
  const [ewallet, setEwallet] = useState(0);
  const showPayBoolean = useSelector((state) => state.data.showPay);
  const [checkBoxData, setCheckBoxData] = useState(
    dataMortgage !== undefined &&
      dataMortgage.mortgage &&
      dataMortgage.mortgage.signedDocumentation === true
      ? true
      : false
  );
  const [mortgageData, setMortgageData] = useState();
  const [idFileFein, setIdFileFein] = useState();
  const [dataClient, setDataClient] = useState("");
  const operations = useSelector((state) => state.auth.operations);

  const { operationId } = useParams();
  const nameOperations =
    operations !== undefined &&
    operations.find((operation) => operation.operationId === operationId);
  const rol = useSelector((state) => state.auth.roles);
  const location = useLocation();

  let countFormalization = 0;

  useEffect(() => {
    getEwallet();
    dataValueInfoUpload();
    getMortgageData();
    getTokenPay();

    if (stages === "formalization") {
      trace({
        location,
        userToken,
        rol,
        stage: "formalization",
        operationName: nameOperations && nameOperations.name,
      });
    }
  }, []);
  useEffect(() => {
    getBankOffer();
  }, []);

  const getBankOffer = () => {
    axios
      .get(
        `${env.api.url}/v1/mortgages/selected-bank-offer/${userMortgageId}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        setBankOffer(response.data.data.selectedOffer);
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const getTokenPay = () => {
    axios
      .post(
        `${env.api.url}/v1/payments/appraisal/checkout`,
        {
          operationId: userMortgageId,
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        setDataClient(response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getEwallet = () => {
    axios
      .get(`${env.api.url}/v1/user/refundable-deposit`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setEwallet(response.data.data.amount);
      });
  };

  useEffect(() => {
    setExistAppraisal(valuesData.some((value) => value.type === "appraisal"));
  }, [valuesData]);

  const dataValueInfoUpload = () => {
    axios
      .get(
        `${env.api.url}/v1/documents/get-operation-documents/${userMortgageId}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        setValuesData(response.data.data);

        setExistAppraisal(
          response.data.data.some((value) => value.type === "appraisal")
        );

        setfein(response.data.data.some((value) => value.type === "fein"));

        if (response.data.data) {
          response.data.data.map((data) => {
            if (data.type === "fein" && data.owner === null) {
              setIdFileFein(data.id);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const handleUploadFile = (file) => {
    axios
      .put(
        `${env.api.url}/api/mortgages/attachment`,
        {
          mortgageId: userMortgageId,
          file: file[1].file,
          fileName: file[1].fileName,
          type: typeFile,
          owner: "owner1",
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then(() => {
        dataValueInfoUpload();
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const handleClickPlus = (typeFile) => {
    setTypeFile(typeFile);
  };

  const saveFileData = (file, name) => {
    const documentFile = [saveFileDataField];
    documentFile.push({
      file,
      fileName: name,
    });
    setSaveFileDataField(documentFile);
    handleUploadFile(documentFile);
  };

  const uploadProps = {
    beforeUpload: (file) => {
      return new Promise(() => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        if (file.size < 50000000) {
          reader.onload = () => {
            const base64File = reader.result.split(",")[1];
            saveFileData(base64File, file.name);
          };
        }else{
          notification.error({
            message: 'Problema al subir archivo',
            description:
            "Documento demasiado pesado, debe subir documentos que no superen los 50MB por seguridad.",
          });
      }

      });
    },
  };

  const handleFileDownload = (documentId) => {
    axios
      .get(
        `${env.api.url}/v1/documents/get-document-url/${documentId}?token=${userToken}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        if (response.data.success) {
          window.open(response.data.data.url, "_blank");
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const modalDelete = (documentId) => {
    confirm({
      className: "ModalCommonConfirm",
      title: "Borrar documento",
      icon: null,
      content: "¿Seguro que deseas borrar este documento?",
      okText: "Borrar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteDocument(documentId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const buttonStyles = (documentId) => {
    return (
      <div
        className="gibobs-document-button-overlay"
        style={{
          position: "absolute",
          width: 88,
          height: 88,
          top: 0,
          left: 0,
          backgroundColor: "#02C3CD",
          lineHeight: 10,
          borderRadius: "4px",
        }}
      >
        {!existAppraisal ? null : (
          <Button
            type="text"
            size="small"
            onClick={() => {
              modalDelete(documentId);
            }}
          >
            <DeleteOutlined
              style={{
                marginTop: "10px",
                color: "#ffff",
              }}
            />
          </Button>
        )}
        <Button
          type="text"
          size="small"
          onClick={() => {
            handleFileDownload(documentId);
          }}
        >
          <DownloadOutlined
            style={{
              marginTop: "10px",
              marginRight: "3px",
              color: "#ffff",
            }}
          />
        </Button>
      </div>
    );
  };

  const deleteDocument = (documentId) => {
    axios
      .get(`${env.api.url}/v1/documents/delete-document/${documentId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then(() => {
        dataValueInfoUpload();
        setExistAppraisal(false);
        countFormalization = countFormalization - 1;
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const saveData = (participant, key, value) => {
    axios
      .post(
        `${env.api.url}/v1/mortgages/data/update/${userMortgageId}`,
        { key, participant, value },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        if (response.data.success) {
          getMortgageData();
          refreshData();
          setCheckBoxData(response.data.data.mortgage.signedDocumentation);
        }
      });

    if (key === "signed" && value) {
      setIsModalVisible(true);
    }
    // if (key === "signedDocumentation" && value === true) {
    //   setFinishStep(true);
    // }
  };

  const getMortgageData = () => {
    axios
      .get(`${env.api.url}/v1/mortgages/data/get/${userMortgageId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setMortgageData(response.data.data.mortgage);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    // if (isMobile) {
    //   navigate(`/my-financial-profile/credits`);
    // }
    // if (!isMobile) {
    //   navigate(`/my-financial-profile/credits/form/${userMortgageId}`);
    // }
  };

  const ownerList = [];
  if (dataMortgage && dataMortgage.mortgage) {
    for (let i = 1; i <= Number(dataMortgage.mortgage.participants); i++) {
      ownerList.push(`owner${i}`);
    }
    for (let i = 0; i < Number(dataMortgage.mortgage.guarantors); i++) {
      ownerList.push(`guarantor${i + 1}`);
    }
  }

  return (
    (<div style={{ marginBottom: 150 }}>
      {stages === "formalization" &&
        !showPayBoolean &&
        mortgageData &&
        !mortgageData.signed && (
          <div style={{ marginTop: 10, marginBottom: 24 }}>
            {mortgageData && (
              <div>
                <div style={{ width: "auto" }}>
                  <Steps
                    direction="vertical"
                    size="small"
                    icon="none"
                    current={
                      mortgageData && mortgageData.signedDocumentation ? 1 : 0
                    }
                    className="StepsAppraisal4"
                  >
                    <Step
                      title={t("formalization.condictions")}
                      description={
                        <>
                          {mortgageData.digitalContractLink === undefined &&
                            !mortgageData.signedDocumentation && (
                              <div
                                style={{
                                  lineHeight: "20px",
                                 
                                }}
                              >
                                <Text
                                  style={{ fontSize: "14px", fontWeight: 400 }}
                                >
                                  {t("formalization.documents")}
                                </Text>
                              </div>
                            )}
                          {!mortgageData.signedDocumentation &&
                            mortgageData.digitalContractLink !== undefined && (
                              <div
                                style={{
                                  lineHeight: "20px",
                                  width: "280px",
                                }}
                              >
                                <Text
                                  style={{ fontSize: "14px", fontWeight: 400 }}
                                >
                                  {t("formalization.filesAvailables")}
                                </Text>
                              </div>
                            )}
                          {mortgageData.digitalContractLink !== undefined &&
                            checkBoxData === false &&
                            (mortgageData.signedDocumentation === false ||
                              mortgageData.signedDocumentation === undefined) &&
                            !fein && (
                              <div style={{ marginTop: 16 }}>
                                <div style={{ width: 280 }}>
                                  <Text
                                    style={{ fontSize: 14, fontWeight: 400 }}
                                  >
                                    {t("formalization.pdf")}:
                                  </Text>
                                  <a
                                    style={{
                                      fontSize: "14px",
                                      color: "#02C3CD",
                                      border: "none",
                                      background: "none",
                                      marginLeft: 8,
                                    }}
                                    target={"_blank"}
                                    href="/guiaLiberbank.pdf"
                                  >
                                    {t("formalization.signature")}
                                  </a>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    height: "auto",
                                  }}
                                >
                                  <Text
                                    style={{ fontSize: 14, fontWeight: 400 }}
                                  >
                                    {t("formalization.link")}:
                                  </Text>
                                  <a
                                    style={{
                                      fontSize: "14px",
                                      color: "#02C3CD",
                                      border: "none",
                                      background: "none",
                                      marginLeft: 8,
                                      width: "150px",
                                    }}
                                    target={"_blank"}
                                    href={`${
                                      mortgageData.digitalContractLink !==
                                      undefined
                                        ? mortgageData.digitalContractLink
                                        : ""
                                    }`}
                                  >
                                    <div style={{ width: 150 }}>
                                      {mortgageData.digitalContractLink !==
                                      undefined
                                        ? mortgageData.digitalContractLink
                                        : ""}
                                    </div>
                                  </a>
                                </div>
                                <div
                                  style={{
                                    marginTop: 8,
                                  }}
                                >
                                  {ownerList &&
                                    ownerList.map((owner) => {
                                      return (
                                        <div
                                          style={{
                                            width: 280,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 14,
                                              fontWeight: 400,
                                            }}
                                          >
                                            {t(`form.${owner}`)}:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: "14px",
                                              color: "#02C3CD",
                                              marginLeft: 8,
                                            }}
                                          >
                                            {dataMortgage[owner].userBankCode}
                                          </Text>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            )}

                          {fein &&
                            !finishStep &&
                            !showPayBoolean &&
                            (mortgageData.signedDocumentation === false ||
                              mortgageData.signedDocumentation ===
                                undefined) && (
                              <div style={{ marginTop: 16 }}>
                                <div>
                                  <Text
                                    style={{ fontSize: 14, fontWeight: 400 }}
                                  >
                                    {t("formalization.pdf")}:
                                  </Text>
                                  <a
                                    style={{
                                      fontSize: "14px",
                                      color: "#02C3CD",
                                      border: "none",
                                      background: "none",
                                      marginLeft: 8,
                                    }}
                                    target={"_blank"}
                                    href="/guia/guia.pdf"
                                  >
                                    {t("formalization.signature")}
                                  </a>
                                </div>
                                <div style={{ width: 280 }}>
                                  <Text
                                    style={{ fontSize: 14, fontWeight: 400 }}
                                  >
                                    {t("formalization.pdf")}:
                                  </Text>
                                  <a
                                    style={{
                                      fontSize: "14px",
                                      color: "#02C3CD",
                                      border: "none",
                                      background: "none",
                                      marginLeft: 8,
                                    }}
                                    onClick={() => {
                                      handleFileDownload(idFileFein);
                                    }}
                                  >
                                    Documento FEIN
                                  </a>
                                </div>
                                <div
                                  style={{
                                    whiteSpace: "nowrap",
                                    display: "flex",
                                    marginTop: 24,
                                  }}
                                >
                                  {valuesData &&
                                    valuesData.map((data) => {
                                      if (
                                        data.type === "signedFein" &&
                                        data.owner !== null
                                      ) {
                                        countFormalization =
                                          countFormalization + 1;
                                        return (
                                          <div
                                            style={{
                                              whiteSpace: "nowrap",
                                              marginRight: 8,
                                            }}
                                          >
                                            <Button
                                              className="gibobs-document-button"
                                              style={{
                                                width: "88px",
                                                height: "88px",
                                                backgroundColor: "white",
                                                borderRadius: "4px",
                                              }}
                                            >
                                              <div>
                                                <FilePdfOutlined />
                                              </div>
                                              <div
                                                className="textButton"
                                                style={{
                                                  width: "72px",
                                                  height: "54px",
                                                  fontSize: "10px",
                                                  whiteSpace: "normal",
                                                }}
                                              >
                                                {buttonStyles(data.id)}
                                                <div
                                                  style={{
                                                    width: "100%",
                                                    height: "30px",
                                                    overflowWrap: "break-word",
                                                    marginBottom: "-7px",
                                                  }}
                                                >
                                                  {data.name.substr(0, 25)}...
                                                </div>
                                              </div>
                                            </Button>
                                          </div>
                                        );
                                      }
                                    })}

                                  {countFormalization !== 2 && (
                                    <Upload {...uploadProps}>
                                      <Button
                                        onClick={() =>
                                          handleClickPlus("signedFein")
                                        }
                                        style={{
                                          width: "88px",
                                          height: "88px",
                                          backgroundColor: "white",
                                          borderRadius: "4px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "20px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <PlusOutlined
                                            style={{
                                              color: "#02C3CD",
                                              fontSize: "30px",
                                            }}
                                          />
                                        </div>
                                        <div
                                          className="textButton"
                                          style={{
                                            width: "72px",
                                            height: "34px",
                                            fontSize: "10px",
                                            whiteSpace: "break-spaces",
                                            marginLeft: "-10px",
                                            marginTop: "-20px",
                                          }}
                                        >
                                          {t("document.attach")}
                                        </div>
                                      </Button>
                                    </Upload>
                                  )}
                                </div>
                              </div>
                            )}
                        </>
                      }
                    />
                    <Step
                      title={t("formalization.finish")}
                      description={
                        (mortgageData &&
                          mortgageData &&
                          mortgageData.signedDocumentation) ||
                        finishStep
                          ? !showPayBoolean && (
                              <>
                                <div
                                  style={{
                                    lineHeight: "20px",
                                    marginBottom: 16,
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 400,
                                      color: "#748EA0",
                                    }}
                                  >
                                    {t("formalization.date")}
                                  </Text>
                                </div>
                                <div
                                  style={{
                                    width: "auto",
                                   
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: "12px",
                                      color: "#2F4858",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {t("formalization.dateEstimated")}
                                  </Text>
                                  {(mortgageData !== undefined ||
                                    mortgageData !== undefined) && (
                                    <InputDate
                                      type="date"
                                      value={
                                        mortgageData !== undefined &&
                                        mortgageData.estimatedSignDate !==
                                          undefined &&
                                        mortgageData.estimatedSignDate !==
                                          "Invalid date"
                                          ? dayjs(
                                              mortgageData.estimatedSignDate
                                            ).format("DD-MM-YYYY")
                                          : ""
                                      }
                                      style={{
                                        height: "32px",
                                        borderRadius: "4px",
                                        borderColor: "#C0DAEE",
                                        fontSize: "12px",
                                        marginTop: "8px",
                                        width: 150
                                      }}
                                      placeholder={"Fecha"}
                                      onChange={(e) => {
                                        {
                                          saveData(
                                            "mortgage",
                                            "estimatedSignDate",
                                            dayjs(e, "DD-MM-YYYY").format(
                                              "YYYY-MM-DD"
                                            )
                                          );
                                        }
                                      }}
                                    />
                                  )}
                                </div>
                              </>
                            )
                          : null
                      }
                    />
                  </Steps>

                  {mortgageData.digitalContractLink !== undefined &&
                    checkBoxData === false &&
                    (mortgageData.signedDocumentation === false ||
                      mortgageData.signedDocumentation === undefined) &&
                    !fein && (
                      <Card
                        className="cardButtonStyle"
                        style={{ left: 0, marginBottom: -2 }}
                      >
                        <Button
                          id="gb-mortgage-buy-situation-mobile"
                          className="button-primari-gibobs"
                          type="primary"
                          style={{
                            fontSize: "14px",
                            width: "100%",
                            height: 48,
                            fontWeight: 600,
                          }}
                          onClick={() =>
                            saveData("mortgage", "signedDocumentation", true)
                          }
                        >
                          {t("formalization.signatureFilesApp")}
                        </Button>
                      </Card>
                    )}
                  {(mortgageData.signedDocumentation === false ||
                    mortgageData.signedDocumentation === undefined) &&
                    fein && (
                      <Card
                        className="cardButtonStyle"
                        style={{ left: 0, marginBottom: -2 }}
                      >
                        <Button
                          id="gb-mortgage-buy-situation-mobile"
                          className="button-primari-gibobs"
                          type="primary"
                          style={{
                            fontSize: "14px",
                            width: "100%",
                            height: 48,
                            fontWeight: 600,
                          }}
                          onClick={() => {
                            saveData("mortgage", "signedDocumentation", true);
                            setFinishStep(true);
                          }}
                        >
                          {t("formalization.signatureFilesApp")}
                        </Button>
                      </Card>
                    )}
                  {(mortgageData &&
                    mortgageData &&
                    mortgageData.signedDocumentation &&
                    !mortgageData.signed) ||
                  finishStep
                    ? !showPayBoolean && (
                        <Card
                          className="cardButtonStyle"
                          style={{ left: 0, marginBottom: -2 }}
                        >
                          <Button
                            id="gb-mortgage-buy-situation-mobile"
                            className="button-primari-gibobs"
                            type="primary"
                            style={{
                              fontSize: "14px",
                              width: "100%",
                              height: 48,
                              fontWeight: 600,
                            }}
                            onClick={() => saveData("mortgage", "signed", true)}
                            disabled={
                              !mortgageData.estimatedSignDate ? true : false
                            }
                          >
                            {!mortgageData.estimatedSignDate
                              ? t("formalization.confirmDate")
                              : t("formalization.signed")}
                          </Button>
                        </Card>
                      )
                    : null}
                </div>
              </div>
            )}
          </div>
        )}
      {mortgageData && mortgageData.signed && (
        <div style={{ textAlign: "center", marginTop: "60px" }}>
          <LikeOutlined
            style={{
              fontSize: "48px",
              marginBottom: 24,
              color: "#02C3CD",
            }}
          />
          {/* <div>
            <Text style={{ color: "#02C3CD", fontWeight: 400, fontSize: 16 }}>
              Muchas gracias
            </Text>
          </div> */}
          <div>
            <Text style={{ color: "#02C3CD", fontWeight: 600, fontSize: 16 }}>
              {t("formalization.thanks1")}
            </Text>
          </div>
          <div style={{ width: 296, margin: "auto", marginTop: 24 }}>
            <Text
              style={{
                color: "#748EA0",
                fontWeight: 400,
                fontSize: 14,
              }}
            >
              Hemos creado tu dashboard satisfactoriamente
            </Text>
          </div>
          <Card
            className="cardButtonStyle"
            style={{ left: 0, marginBottom: -2 }}
          >
            <Button
              id="gb-mortgage-buy-situation-mobile"
              className="button-primari-gibobs"
              type="primary"
              style={{
                fontSize: "14px",
                width: "100%",
                height: 48,
                fontWeight: 600,
              }}
              onClick={() => navigate("/dashboard-client")}
            >
              {t("viability.myInterest")}
            </Button>
          </Card>
        </div>
      )}
      {(mortgageData && mortgageData && mortgageData.signedDocumentation) ||
      finishStep
        ? !showPayBoolean && (
            <div style={{ marginLeft: "-12px" }}>
              {isModalVisible && (
                <Modal
                  okButtonProps={{ className: "button-primari-gibobs" }}
                  className="ModalCommonSmall"
                  cancelButtonProps={{
                    type: "dashed",
                    style: { color: "#02C3CD" },
                  }}
                  open={isModalVisible}
                  title="Muchas gracias por confiar en Gibobs"
                  okText="Aceptar"
                  cancelText="Cancelar"
                  onCancel={handleCancel}
                  onOk={handleOk}
                >
                  <p>{t("formalization.thanks")}</p>
                </Modal>
              )}
            </div>
          )
        : null}
      {showPayBoolean && mortgageData && (
        <div style={{ marginBottom: 40, marginTop: 280 }}>
          <CheckOutComponent
            amount={mortgageData.appraisalAmount - ewallet}
            showPayBoolean={showPayBoolean}
            mortgageId={userMortgageId}
            clientSecretToken={dataClient.paymentSeat.session.client_secret}
            dataClient={dataClient}
          />
        </div>
      )}
      {mortgageData && !mortgageData.signed && (
        <div>
          <div
            style={{
              color: "#2F4858",
              fontSize: "12px",
              fontWeight: 600,
              marginBottom: "12px",
              marginLeft: 16,
            }}
          >
            {t("offers.previousDocument").toUpperCase()}
          </div>
          <div
            style={{
              height: 40,
              background: "#FFFFFF",
              padding: 10,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0 16px",
            }}
          >
            <div>
              <FileOutlined style={{ color: "#02C3CD", marginRight: 16 }} />
              <Text style={{ fontSize: "14px", fontWeight: 400 }}>
                {t("offers.selected")}
              </Text>
            </div>
            <a
              href={`${env.api.url}/v1/banks/generate-pdf-bank/${bankOffer}?token=${userToken}`}
              target={"_blank"}
            >
              <DownloadOutlined style={{ color: "#2F4858" }} />
            </a>
          </div>
          <div
            style={{
              height: 40,
              background: "#FFFFFF",
              padding: 10,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0 16px",
            }}
          >
            <div>
              <FileOutlined style={{ color: "#02C3CD", marginRight: 16 }} />
              <Text style={{ fontSize: "14px", fontWeight: 400 }}>
                {t("appraisal.infoAppraisal")}
              </Text>
            </div>
            <DownloadOutlined
              style={{ color: "#2F4858" }}
              onClick={() => {
                if (
                  valuesData.find((element) => element.type === "appraisal")
                ) {
                  const idDocument = valuesData.find(
                    (element) => element.type === "appraisal"
                  ).id;
                  handleFileDownload(idDocument);
                } else {
                  alert("No file upload by analyst");
                }
              }}
            />
          </div>
        </div>
      )}
    </div>)
  );
};

export default FormalizationMobile;
