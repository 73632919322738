import React from "react";
import { Col, Form, Radio, Space, Select } from "antd";
import { useTranslation } from "react-i18next";
import DownOutlinedMobile from "../../../../assets/icons/DownOutlinedMobile.svg";

const { Option } = Select;

const TargetsSubrogationForm1 = ({ edit }) => {
  const { t } = useTranslation();

  const colSpan = {
    xxl: edit === undefined ? 24 : 6,
    xl: edit === undefined ? 24 : 6,
    lg: edit === undefined ? 24 : 8,
    md: edit === undefined ? 24 : 12,
    sm: 24,
    xs: 24,
  };
  return (
    <>
      {edit === undefined && (
        <Col span={24}>
          <Form.Item name="reason" label={t("onboarding.changeYourMortgage")}  rules={[{ required: true }]}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="improveMortgage">
                  {t("onboarding.improveConditions")}
                </Radio>
                <Radio value="increaseCapitalRenovations">
                  {t("form.optionPurpose2")}
                </Radio>
                <Radio value="increaseCapitalLiquidity">
                  {t("form.optionPurpose3")}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      )}
      {edit !== undefined && (
        <Col {...colSpan}>
          <Form.Item name="reason" label={t("onboarding.reasonForChange")}>
            <Select
              placeholder={t("form.activity")}
              suffixIcon={
                <img src={DownOutlinedMobile} alt="DownOutlinedMobile" />
              }
            >
              <Option value="improveMortgage">
                {t("onboarding.improveConditions")}
              </Option>
              <Option value="increaseCapitalRenovations">
                {t("form.optionPurpose2")}
              </Option>
              <Option value="increaseCapitalLiquidity">
                {t("form.optionPurpose3")}
              </Option>
            </Select>
          </Form.Item>
        </Col>
      )}
    </>
  );
};

export default TargetsSubrogationForm1;
