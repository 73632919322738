import React from "react";
import { Col, Form, Radio, Space, Select } from "antd";
import { useTranslation } from "react-i18next";
import DownOutlinedMobile from "../../../../assets/icons/DownOutlinedMobile.svg";
import { ProvinceSelector } from "afrodita";

const { Option } = Select;

const TaxCalculationForm2 = ({ edit }) => {
  const { t } = useTranslation();
  const colSpan = {
    xxl: edit === undefined ? 24 : 6,
    xl: edit === undefined ? 24 : 6,
    lg: edit === undefined ? 24 : 8,
    md: edit === undefined ? 24 : 12,
    sm: 24,
    xs: 24,
  };
  return (
    <>
      <Col {...colSpan}>
        <Form.Item
          name="province"
          label={t("viability.provinceViability")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ProvinceSelector
            id="province"
            placeholder={t("form.province")}
            style={{ width: "100%" }} />
        </Form.Item>
      </Col>
      {edit !== undefined && (
        <Col {...colSpan}>
          <Form.Item name="propertyType" label={t("form.propertyType")}>
            <Select
              id="propertyType"
              suffixIcon={
                <img src={DownOutlinedMobile} alt="DownOutlinedMobile" />
              }
              placeholder={t("form.select")}
              style={{ width: "100%" }}
            >
              <Option value="newConstruction">{t("form.newBuild")}</Option>
              <Option value="secondHand">{t("form.secondHand")}</Option>
            </Select>
          </Form.Item>
        </Col>
      )}
      {edit === undefined && (
        <Col span={24}>
          <Form.Item name="propertyType" 
          rules={[
            {
              required: true,
            },
          ]}
          label={t("form.propertyType")}>
            <Radio.Group id="propertyType">
              <Space direction="vertical">
                <Radio value="newConstruction">{t("form.newBuild")}</Radio>
                <Radio value="secondHand">{t("form.secondHand")}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      )}
    </>
  );
};

export default TaxCalculationForm2;
