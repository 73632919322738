import React, { useEffect, useState } from 'react';
import { Card, Typography, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import checkBlack from '../../../../assets/loans/checkBlack.png';
import check from '../../../../assets/loans/check.png';
import numeral from 'numeral';
import moment from 'moment';
import updateYelleow from '../../../../assets/loans/updateYelleow.png';

const { Text } = Typography;

const TipsList = ({ loan }) => {
	const dataProfileDetails = useSelector((state) => state.data.dataProfile);
	const { t } = useTranslation();
	const [diffe, setDiffe] = useState();

	let tips = loan.tips;

	useEffect(() => {
		let dateSigned = moment(loan.signed);
		let today = moment(new Date());

		setDiffe(today.diff(dateSigned, 'days'));
	}, []);

	if (loan?.version !== '2023.v1') {
		return (
			<Card className="gb-card-loan-init-update" style={{ marginTop: 16 }}>
				<Row>
					<Col span={3}>
						<img src={updateYelleow} alt="updateYelleow" style={{ width: 24, height: 24 }}></img>
					</Col>
					<Col span={21} style={{ display: 'flex', flexDirection: 'column' }}>
						<Text
							style={{
								color: '#2F4858',
								fontSize: 12,
								fontWeight: 600
							}}>
							{t('update.loanInit')}
						</Text>
						<Text
							style={{
								color: '#2F4858',
								fontSize: 12,
								fontWeight: 500
							}}>
							{t('update.loanInit2')}
						</Text>
					</Col>
				</Row>
			</Card>
		);
	} else {
		if (tips.length > 0 && tips[0].category !== 'WORSE' && loan.creationType !== 'MANUAL') {
			return (
				<>
					{tips[0].category === 'BETTER' && tips[0].type === 'BETTER_VARIABLE_RATE' && (
						<Card className="gb-card-loan-init-green-12px" style={{ marginTop: 16 }}>
							<Row>
								<Col span={3}>
									<img src={check} alt="asdf" style={{ width: 20, height: 20, marginTop: 2 }}></img>
								</Col>
								<Col span={21} style={{ maxWidth: 260 }}>
									<div
										style={{
											color: '#2F4858',
											fontSize: 12,
											fontWeight: 600
										}}
										dangerouslySetInnerHTML={{
											__html: t('m_loan.tipVariableToVariableTitle', {
												name: dataProfileDetails.name,
												savings: numeral(tips[0].savings).format('0,0.00 $')
											})
										}}
									/>
								</Col>
							</Row>
						</Card>
					)}
					{tips[0].category === 'BETTER' && tips[0].type === 'BETTER_FIXED_RATE' && (
						<Card className="gb-card-loan-init-green-12px" style={{ marginTop: 16 }}>
							<Row>
								<Col span={3}>
									<img src={check} alt="asdf" style={{ width: 20, height: 20, marginTop: 2 }}></img>
								</Col>
								<Col span={21}>
									<div
										style={{
											color: '#2F4858',
											fontSize: 12,
											fontWeight: 600
										}}
										dangerouslySetInnerHTML={{
											__html: t('m_loan.tipFixedToFixedTitle', {
												name: dataProfileDetails.name,
												savings: numeral(Math.abs(tips[0].savings)).format('0,0.00 $')
											})
										}}
									/>
								</Col>
							</Row>
						</Card>
					)}
					{tips[0].category === 'BETTER' && tips[0].type === 'CHANGE_TO_VARIABLE_RATE' && (
						<Card className="gb-card-loan-init-green-12px" style={{ marginTop: 16 }}>
							<Row>
								<Col span={3}>
									<img src={check} alt="asdf" style={{ width: 20, height: 20, marginTop: 2 }}></img>
								</Col>
								<Col span={21}>
									<div
										style={{
											color: '#2F4858',
											fontSize: 12,
											fontWeight: 600
										}}
										dangerouslySetInnerHTML={{
											__html: t('m_loan.tipFixedToVariableTitle', {
												name: dataProfileDetails.name,
												savings: numeral(tips[0].savings).format('0,0.00 $')
											})
										}}
									/>
								</Col>
							</Row>
						</Card>
					)}

					{tips[0].category === 'BETTER' && tips[0].type === 'CHANGE_TO_FIXED_RATE' && (
						<Card className="gb-card-loan-init-green-12px" style={{ marginTop: 16 }}>
							<Row>
								<Col span={3} style={{ marginTop: 35 }}>
									<img src={check} alt="asdf" style={{ width: 20, height: 20, marginTop: 2 }}></img>
								</Col>
								<Col span={21} style={{ maxWidth: 270 }}>
									<div
										style={{
											color: '#2F4858',
											fontSize: 12,
											fontWeight: 600
										}}
										dangerouslySetInnerHTML={{
											__html: t('m_loan.tipVariableToFixedTitle', {
												name: dataProfileDetails.name,
												savings: numeral(tips[0].savings).format('0,0.00 $')
											})
										}}
									/>
								</Col>
							</Row>
						</Card>
					)}
				</>
			);
		} else if (tips.length > 0 && tips[0].category !== 'WORSE' && loan.creationType === 'MANUAL') {
			return (
				<>
					{tips[0].category === 'BETTER' && tips[0].type === 'BETTER_VARIABLE_RATE' && (
						<Card className="gb-card-loan-init-green" style={{ marginTop: 16 }}>
							<Row>
								<Col span={3}>
									<img src={check} alt="asdf" style={{ width: 20, height: 20, marginTop: 2 }}></img>
								</Col>
								<Col span={21}>
									<Text
										style={{
											color: '#2F4858',
											fontSize: 12,
											fontWeight: 600
										}}>
										{dataProfileDetails?.name +
											', ' +
											t('m_loan.tipVariableToVariableManualDetailTitle', {
												savings: numeral(tips[0].savings).format('0,0.00 $')
											})}
									</Text>
								</Col>
							</Row>
						</Card>
					)}
					{tips[0].category === 'BETTER' && tips[0].type === 'BETTER_FIXED_RATE' && (
						<Card className="gb-card-loan-init-green" style={{ marginTop: 16 }}>
							<Row>
								<Col span={3}>
									<img src={check} alt="asdf" style={{ width: 20, height: 20, marginTop: 2 }}></img>
								</Col>
								<Col span={21}>
									<Text
										style={{
											color: '#2F4858',
											fontSize: 12,
											fontWeight: 600
										}}>
										{t('m_loan.tipFixedToFixedTitle', {
											name: dataProfileDetails?.name,
											savings: numeral(tips[0].savings).format('0,0.00 $')
										})}
									</Text>
								</Col>
							</Row>
						</Card>
					)}
					{tips[0].category === 'BETTER' && tips[0].type === 'CHANGE_TO_VARIABLE_RATE' && (
						<Card className="gb-card-loan-init-green" style={{ marginTop: 16 }}>
							<Row>
								<Col span={3}>
									<img src={check} alt="asdf" style={{ width: 20, height: 20, marginTop: 2 }}></img>
								</Col>
								<Col span={21}>
									<Text
										style={{
											color: '#2F4858',
											fontSize: 14,
											fontWeight: 600
										}}>
										{dataProfileDetails.name + ', ' + t('init.myLoanDescCardVariable')}
									</Text>
								</Col>
							</Row>
						</Card>
					)}

					{tips[0].category === 'BETTER' && tips[0].type === 'CHANGE_TO_FIXED_RATE' && (
						<Card className="gb-card-loan-init-green" style={{ marginTop: 16 }}>
							<Row>
								<Col span={2}>
									<img src={check} alt="asdf" style={{ width: 20, height: 20, marginTop: 2 }}></img>
								</Col>
								<Col span={20} style={{ marginLeft: 12 }}>
									<Text
										style={{
											color: '#2F4858',
											fontSize: 14,
											fontWeight: 600
										}}>
										{t('m_loan.tipVariableToFixedManualDetailTitle', {
											name: dataProfileDetails?.name,
											savings: numeral(tips[0].savings).format('0,0.00')
										})}
									</Text>
								</Col>
							</Row>
						</Card>
					)}
				</>
			);
		} else {
			return (
				<Card
					className="gcardMovile-12-padding"
					style={{
						border: '1px solid #C0DAEE',
						background: '#F1F7F8',
						borderRadius: 4,
						marginTop: 16
					}}>
					<Row>
						<Col xs={3} md={3} sm={3} lg={3} xl={3} xxl={3}>
							<img src={checkBlack} style={{ height: 24, width: 24 }}></img>
						</Col>
						<Col xs={21} md={21} sm={21} lg={21} xl={21} xxl={21}>
							<Text
								style={{
									color: '#2F4858',
									fontSize: 12,
									fontWeight: 600
								}}>
								{diffe && diffe < 365
									? t('loan.detailsLoanOwnersTitleAdvi')
									: dataProfileDetails &&
									  dataProfileDetails.name + ', ' + t('loan.detailsLoanOwnersAdvi')}
							</Text>
						</Col>
					</Row>
				</Card>
			);
		}
	}
};

export default TipsList;
