import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import {
  Layout,
  Typography,
  Card,
  Row,
  Col,
  Table,
  Tooltip,
  Button,
  List,
  notification,
  Modal,
  Select,
  Input,
} from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getOperations } from "../../store/realestate/actions";
import {
  ExclamationOutlined,
  WhatsAppOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import moment from "moment";
import queryString from "query-string";
import { isMobile } from "react-device-detect";
import LayoutTools from "./LayountTools";
import env from "../../environment";
import axios from "axios";
import card1 from "../../assets/Products/card1Certificate.png";
import card2 from "../../assets/Products/card2Certificate.png";
import card3 from "../../assets/Products/card3Certificate.png";
import certificateInmo from "../../assets/icons/certificateInmo.svg";
import greenMortgageIcon from "../../assets/icons/greenMortgageIcon.svg";
import activeIcon from "../../assets/icons/activeIcon.svg";
import lockIcon from "../../assets/icons/lockIcon.svg";
import userIconInmo from "../../assets/icons/userIconInmo.svg";
import FileOutlinedIcon from "../../assets/icons/FileOutlinedIcon.svg";
import mortgageIcon from "../../assets/icons/mortgageIcon.svg";
import gibobsLogo from "../../assets/icons/gibobsLogo.svg";
import locationIcon from "../../assets/icons/location.svg";
import subrogationIcon from "../../assets/icons/SubrogacionMobile.svg";
import VerticalAlignBottomOutlinedIcon from "../../assets/icons/VerticalAlignBottomOutlinedIcon.svg";
import calendarIcon from "../../assets/icons/calendarIcon.svg";
import greenSubrogationIcon from "../../assets/icons/SubrrogacionVerde.svg";
import DownOutlinedMobile from "../../assets/icons/DownOutlinedMobile.svg";
import { notificationGibobs } from "./reusableItems";

const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;

const FollowUpPage = ({ modal, tabSelected }) => {
  const navigate = useNavigate();;
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let tempIndexOwner = 0;

  let query = queryString.parse(location.search);
  const loading = useSelector((state) => state.realestate.loading);
  let operations = useSelector((state) => state.realestate.operations);
  const userToken = useSelector((state) => state.auth.token);
  const profile = useSelector((state) => state.data.dataProfile);

  const [columns, setColumns] = useState([]);
  const [columnsCert, setColumnsCert] = useState([]);
  const [columnsEco, setColumnsEco] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [dataCertificated, setDataCertificated] = useState([]);
  const [dataSolarValue, setDataSolarValue] = useState([]);
  const [dataMobile, setDataMobile] = useState("operations");
  const [filters, setFilters] = useState({
    status: query.status ? query.status.split(";") : undefined,
    type: query.type ? query.type.split(";") : undefined,
    agent: query.agent ? query.agent.split(";") : undefined,
    stage: query.stage ? query.stage.split(";") : undefined,
    closeReason: query.closeReason ? query.closeReason.split(";") : undefined,
  });

  const [filtersCert, setFiltersCert] = useState({
    status: query.status ? query.status.split(";") : undefined,
    stage: query.stage ? query.stage.split(";") : undefined,
    subtype: query.subtype ? query.subtype.split(";") : undefined,
    // createAt: query.createAt ? query.createAt.split(";") : undefined,
  });

  const statusName = {
    basic: t("stages.basic"),
    full: t("stages.full"),
    documents: t("stages.documents"),
    analysis: t("stages.analysis"),
    banks: t("stages.banks"),
    oferts: t("oferts.selectionTable"),
    appraisal: t("stages.appraisal"),
    formalization: t("stages.formalization"),
    mortgage: t("product.mortgage"),
    suborgation: t("form.subrrogacion"),
    "certificate-rent": t("b2bOperationList.newCertRent"),
    "certificate-buy": t("b2bOperationList.newCertBuy"),
    finish: t("certitificate.finishStep"),
    "green-subrogation": t("operationTypes.green-subrogation"),
    "green-mortgage": t("operationTypes.green-mortgage"),
    subrogation: t("operationTypes.subrogation"),
  };

  const ecoName = {
    individual: t("budget.individual"),
    adosado: t("budget.townhouse"),
    pareado: t("budget.couplet"),
    casaRustica: t("budget.rusticHouse"),
    piso: t("budget.house"),
  };

  const copiarAlPortapapeles = (link) => {
    var aux = document.createElement("input");
    aux.setAttribute("value", link);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    notification.success({
      message: `Enlace copiado al portapapeles`,
      //description: t("mydocuments.error"),
      placement: "topRight",
    });
    // alert("Enlace copiado!")
  };
  const handleFileDownload = (id) => {
    axios
      .get(`${env.api.url}/v1/documents/get-operation-documents/${id}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        notificationGibobs("info", t("form.requestedDocument"));
        if (response.data.success) {
          const file = response.data.data.find(
            (item) => item.type === "certificate"
          );
          if (file) {
            axios
              .get(`${env.api.url}/v1/certificates/generate-pdf/${id}`, {
                headers: { Authorization: `Bearer ${userToken}` },
              })
              .then((response) => {
                window.open(response.data.data.url, "_blank"); //to open new page
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      })
      
  };

  useEffect(() => {
    dispatch(getOperations());
    getCertificates("");
    getBudgetSVH("");

    if (modal === true) {
      setVisibleModal(true);
    }

    if (tabSelected === "certificated") {
      setDataMobile("dataCertificated");
    }

    if (tabSelected === "svh") {
      setDataMobile("dataSolarValue");
    }
    if (tabSelected === "mortgage") {
      setDataMobile("operations");
    }
  }, []);

  const handleCancelModal = () => {
    setVisibleModal(false);
    navigate({ state: [false] });
  };

  const followUpWhatsapp = (
    preregisterId,
    client,
    phone,
    operation,
    link,
    brand
  ) => {
    let whatsappPhone = phone;
    if (whatsappPhone && whatsappPhone.startsWith("00")) {
      whatsappPhone = `+${whatsappPhone.substring(2)}`;
    } else if (whatsappPhone && whatsappPhone.indexOf("+") === -1) {
      whatsappPhone = `+34${whatsappPhone}`;
    }
    if (whatsappPhone) {
      whatsappPhone = whatsappPhone.replace("+", "%2B");
    }

    axios
      .get(`${env.api.url}/api/v2/preregisters-idiom/${preregisterId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        let textWs;
        if (response.data.language === "es") {
          if (brand) {
            textWs = `Estimad@ ${client}, soy tu agente ${operation}. Necesitamos que confirmes tu registro para que GIBOBS pueda empezar a estudiar tu financiación. Por favor, pincha en este enlace: ${link}`;
          } else {
            textWs = `Estimad@ ${client}, soy agente de ${operation}. Necesitamos que confirmes tu registro para que GIBOBS pueda empezar a estudiar tu financiación. Por favor, pincha en este enlace: ${link}`;
          }
        } else if (response.data.language === "en") {
          if (brand) {
            textWs = `Dear ${client}, I am your agent ${operation}. We need you to confirm your registration so that GIBOBS can start studying your financing. Please, click on this link: ${link}`;
          } else {
            textWs = `Dear ${client}, I am an agent of ${operation}. We need you to confirm your registration so that GIBOBS can start studying your financing. Please, click on this link: ${link}`;
          }
        } else {
          if (brand) {
            textWs = `Estimeu@ ${client}, soc el teu agent ${operation}. Necesitamos que confirmis el teu registre perquè GIBOBS pugui començar a estudiar el teu finançament. Si us plau, punxa en aquest enllaç: ${link}`;
          } else {
            textWs = `Estimeu@ ${client}, sóc un agent de ${operation}. Necesitamos que confirmis el teu registre perquè GIBOBS pugui començar a estudiar el teu finançament. Si us plau, punxa en aquest enllaç: ${link}`;
          }
        }

        const linkWs = document.createElement("a");
        linkWs.href = `https://api.whatsapp.com/send?phone=${whatsappPhone}&text=${textWs}`;
        linkWs.target = "_blank";
        document.body.appendChild(linkWs);
        linkWs.click();
      });
  };

  const getCertificates = (query) => {
    //}/v1/operations/list-b2b?type=certificate&status=active
    /*   Estados:
          not-verified
          active
          finish*/
    if (query === "") {
      axios
        .get(`${env.api.url}/v1/operations/list-b2b?type=certificate`, {
          headers: { Authorization: `Bearer ${userToken}` },
        })
        .then((response) => {
          setDataCertificated(response.data.data);
        });
    } else {
      axios
        .get(
          `${env.api.url}/v1/operations/list-b2b?type=certificate&${query}`,
          {
            headers: { Authorization: `Bearer ${userToken}` },
          }
        )
        .then((response) => {
          setDataCertificated(response.data.data);
        });
    }
  };
  const getBudgetSVH = (query) => {
    //}/v1/operations/list-b2b?type=certificate&status=active
    /*   Estados:
          not-verified
          active
          finish*/
    if (query === "") {
      axios
        .get(`${env.api.url}/v1/operations/list-b2b?type=solar-value`, {
          headers: { Authorization: `Bearer ${userToken}` },
        })
        .then((response) => {
          const solarValue = response.data.data;
          solarValue.sort((a, b) => (a.subname < b.subname ? 1 : -1));
          setDataSolarValue(isMobile ? solarValue : response.data.data);
        });
    } else {
      axios
        .get(
          `${env.api.url}/v1/operations/list-b2b?type=solar-value&${query}`,
          {
            headers: { Authorization: `Bearer ${userToken}` },
          }
        )
        .then((response) => {
          const solarValue = response.data.data;
          solarValue.sort((a, b) => (a.subname < b.subname ? 1 : -1));
          setDataSolarValue(isMobile ? solarValue : response.data.data);
        });
    }
  };

  useEffect(() => {
    const agentFilter = [];
    const closeFilter = [];

    operations.forEach((operation) => {
      const agent = operation.accountName || operation.agent;
      let close = operation.closeReason || "";

      if (!agentFilter.find((element) => element.value === agent)) {
        agentFilter.push({ text: agent, value: agent });
      }

      if (
        close !== "" &&
        !closeFilter.find((element) => element.value === close)
      ) {
        closeFilter.push({ text: close, value: close });
      }
    });

    const dynamicColumns = [];
    const dynamicColumnsCert = [];
    const dynamicColumnsEco = [];

    //HIPOTECA/SUBROGACION START
    dynamicColumns.push({
      title: t("b2bOperationList.status"),
      align: "center",
      dataIndex: "status",
      key: "status",
      width: 90,
      filters: [
        {
          text: (
            <>
              <ExclamationOutlined
                style={{ color: "#FAAD14", fontSize: 20, marginRight: 8 }}
              />
              {t(`operationStatus.preregister`)}
            </>
          ),
          value: "preregister",
        },
        {
          text: (
            <>
              <img
                src={activeIcon}
                alt="activeIcon"
                height={16}
                style={{ marginRight: 8 }}
              />

              {t(`operationStatus.open`)}
            </>
          ),
          value: "open",
        },
        {
          text: (
            <>
              <img
                src={lockIcon}
                alt="lockIcon"
                height={16}
                style={{ marginRight: 8 }}
              />
              {t(`operationStatus.closed`)}
            </>
          ),
          value: "closed",
        },
      ],
      filteredValue: (dataMobile === "operations" && filters.status) || [],
      onFilter: (value, operation) => {
        return (
          (value === "open" &&
            operation.status !== "finish" &&
            !operation.accountKAM &&
            (!operation.closeDate || operation.closeDate === "")) ||
          (value === "closed" && operation.status === "finish") ||
          (value === "preregister" && operation.accountKAM)
        );
      },
      render: (text, operation) => {
       
        let type = "preregister";
        let icon = (
          <ExclamationOutlined style={{ color: "#FAAD14", fontSize: 20 }} />
        );

        if (!operation.accountKAM) {
          if (operation.status === "finish") {
           
            type = "closed";
            icon = <img src={lockIcon} alt="lockIcon" height={16} />;
          } else {
            
            type = "open";
            icon = <img src={activeIcon} alt="activeIcon" height={16} />;
          }
        }

        return <Tooltip title={t(`operationStatus.${type}`)}>{icon}</Tooltip>;
      },
    });

    dynamicColumns.push({
      title: t("b2bOperationList.stage"),
      dataIndex: "stage",
      key: "stage",
      align: "center",
      filteredValue: filters.stage || [],
      filters: [
        { text: t("stages.basic"), value: "basic" },
        { text: t("stages.full"), value: "full" },
        { text: t("stages.documents"), value: "documents" },
        { text: t("stages.analysis"), value: "analysis" },
        { text: t("stages.banks"), value: "banks" },
        { text: t("oferts.selectionTable"), value: "oferts" },
        { text: t("stages.appraisal"), value: "appraisal" },
        { text: t("stages.formalization"), value: "formalization" },
        // { text: t("stages.not"), value: "null" },
      ],
      onFilter: (value, operation) =>
        dataMobile === "operations" && value === operation.stage,
      render: (text, operation) => {
        if (!text) {
          if (operation.link && operation.link !== "") {
            if (operation.phone.charAt("0") !== "+") {
              operation.phone = "+34" + operation.phone;
            }
            return (
              <>
                <a
                  clasname="textCopy"
                  style={{
                    color: "#02C3CD",
                    border: "none",
                    marginRight: 5,
                    fontSize: 12,
                  }}
                  onClick={() => copiarAlPortapapeles(operation.link)}
                >
                  {t("init.copyLink")}
                </a>
                <CopyOutlined style={{ color: "#02C3CD", marginTop: 3 }} />
                <br />
                <div
                  onClick={() =>
                    followUpWhatsapp(
                      operation.id,
                      operation.clientName,
                      operation.phone,
                      operation.brandName === null
                        ? operation.accountName
                        : operation.brandName,
                      operation.link,
                      operation.brandName === null ? true : false
                    )
                  }
                  style={{ color: "#02C3CD", fontSize: 12, cursor: "pointer" }}
                >
                  {t("inmo.whatsappShare")}
                  <WhatsAppOutlined
                    style={{ marginLeft: "8px", fontSize: 14 }}
                  />
                </div>

                {/* {operation.brandName !== null && (
                  <a
                    href={
                      "https://api.whatsapp.com/send?phone=" +
                      operation.phone +
                      "&text=Estimad@ " +
                      operation.clientName +
                      ", soy agente de " +
                      operation.brandName +
                      " Necesitamos que confirmes tu registro para que GIBOBS pueda empezar a estudiar tu financiación. Por favor, pincha en este enlace: " +
                      operation.link
                    }
                    target="_blank"
                  >
                    Compartir{" "}
                    <WhatsAppOutlined
                      style={{ marginLeft: "8px", fontSize: 14 }}
                      onClick={() => followUpWhatsapp(operation.id)}
                    />
                  </a>
                )} */}

                {/* {operation.brandName === null && (
                  <a
                    href={
                      "https://api.whatsapp.com/send?phone=" +
                      operation.phone +
                      "&text=Estimad@ " +
                      operation.clientName +
                      ", soy el agente " +
                      operation.accountName +
                      " Necesitamos que confirmes tu registro para que GIBOBS pueda empezar a estudiar tu financiación. Por favor, pincha en este enlace: " +
                      operation.link
                    }
                    target="_blank"
                  >
                    Compartir{" "}
                    <WhatsAppOutlined
                      style={{ marginLeft: "8px", fontSize: 14 }}
                    />
                  </a>
                )} */}
              </>
            );
          } else {
            return;
          }
        }
        return (
          <>
            <div style={{ fontSize: 12, fontWeight: 400, color: "#2F4858" }}>
              {text === "oferts"
                ? t("oferts.selectionTable")
                : t(`stages.${text}`)}
            </div>
            {operation.operationStatus === "postpone" && (
              <div style={{ fontSize: 12, fontWeight: 400, color: "#748EA0" }}>
                {t("form.postponedTitle")}
              </div>
            )}

            {/* <Progress
              percent={stages[text] * 11}
              steps={10}
              size="small"
              strokeColor="#02c3cd"
              showInfo={false}
            /> */}
          </>
        );
      },
    });

    dynamicColumns.push({
      title: t("b2bOperationList.type"),
      dataIndex: "type",
      key: "type",
      align: "center",
      width: 75,
      filteredValue: filters.type || [],
      filters: [
        {
          text: (
            <>
              <img
                src={mortgageIcon}
                alt="mortgageIcon"
                height={15}
                style={{ marginRight: 8, marginTop: -2 }}
              />

              {t("product.mortgage")}
            </>
          ),
          value: "mortgage",
        },
        {
          text: (
            <>
              <img
                src={subrogationIcon}
                alt="subrogationIcon"
                height={15}
                style={{ marginRight: 8, marginTop: -2 }}
              />
              {t("form.subrrogacion")}
            </>
          ),
          value: "subrogation",
        },
        {
          text: (
            <>
              <img
                src={greenMortgageIcon}
                alt="greenMortgageIcon"
                height={15}
                style={{ marginRight: 8, marginTop: -2 }}
              />
              {t("operationTypes.green-mortgage")}
            </>
          ),
          value: "green-mortgage",
        },
        {
          text: (
            <>
              <img
                src={greenSubrogationIcon}
                alt="greenSubrogationIcon"
                height={18}
                style={{ marginRight: 8, marginTop: -2 }}
              />
              {t("operationTypes.green-subrogation")}
            </>
          ),
          value: "green-subrogation",
        },
        {
          text: (
            <>
              <img
                src={userIconInmo}
                alt="userIconInmo"
                height={18}
                style={{ marginRight: 8, marginTop: -2 }}
              />
              Pendiente de verificar
            </>
          ),
          value: "not",
        },
      ],
      onFilter: (value, operation) => {
        if (value === "not" && dataMobile === "operations") {
          return !operation.type;
        }
        if (dataMobile === "operations") {
          return value === operation.subtype;
        }
      },
      render: (text, operation) => {
        let icon;
        let type = operation.subtype;

        if (type === "mortgage" || type === "mortgage-promotion") {
          icon = <img src={mortgageIcon} alt="mortgageIcon" height={15} />;
        } else if (type === "green-mortgage") {
          icon = (
            <img src={greenMortgageIcon} alt="greenMortgageIcon" height={15} />
          );
        } else if (type === "subrogation") {
          icon = (
            <img src={subrogationIcon} alt="subrogationIcon" height={13} />
          );
        } else if (type === "green-subrogation") {
          icon = (
            <img
              src={greenSubrogationIcon}
              alt="greenSubrogationIcon"
              height={13}
            />
          );
        } else if (operation.accountKAM) {
          type = "preregister";
          icon = <img src={userIconInmo} alt="userIconInmo" height={15} />;
        } else {
          icon = type;
        }

        return <Tooltip title={t(`operationTypes.${type}`)}>{icon}</Tooltip>;
      },
    });

    dynamicColumns.push({
      title: "ID",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (text, operation) => {
        if (operation.accountKAM) {
          return text;
        } else {
          return (
            <NavLink style={{ color: '#02c3cd' }}
              to={{
                pathname: `/sales-tools/follow-up/detail-operation/${operation.id}/${operation.stage}`,
            
              }}
              state={operation.name}
            >
              {text}
            </NavLink>
          );
        }
      },
    });

    dynamicColumns.push({
      title: t("b2bOperationList.agent"),
      dataIndex: "agent",
      key: "agent",
      filteredValue: filters.agent || [],
      filters: [...new Set(agentFilter)],
      onFilter: (value, operation) =>
        (dataMobile === "operations" && operation.agent === value) ||
        (dataMobile === "operations" && operation.accountName === value),
      render: (text, operation) => {
        return text || operation.accountName;
      },
    });

    dynamicColumns.push({
      title: t("b2bOperationList.client"),
      dataIndex: "client",
      key: "client",
      render: (text, operation) => {
        return operation &&
          operation.operationData &&
          operation.operationData.owner1 &&
          operation.operationData.owner1.name
          ? operation.operationData.owner1.name
          : text || operation.clientName;
      },
    });

    dynamicColumns.push({
      title: (
        <>
          {t("b2bOperationList.date")}
          <br />
          {t("b2bOperationList.create")}
        </>
      ),
      dataIndex: "createAt",
      key: "createAt",
      align: "center",
      width: 120,
      defaultSortOrder: "descending",
      backgroundColor: "white",
      sorter: (a, b) => {
        const aCreate = a.createAt || a.createdAt;
        const bCreate = b.createAt || b.createdAt;
        
				return aCreate.localeCompare(bCreate);

      },
      render: (text, operation) => {
        if (operation.createdAt) {
          return moment(operation.createdAt).format("DD/MM/YYYY");
        }else {
          return moment(operation.createAt).format("DD/MM/YYYY");
        }
      },
    });

    dynamicColumns.push({
      title: (
        <>
          {t("b2bOperationList.date")}
          <br />
          {t("b2bOperationList.close")}
        </>
      ),
      dataIndex: "closeDate",
      key: "closeDate",
      align: "center",
      width: 120,
      defaultSortOrder: "descending",
      sorter: (a, b) => {
        const aCreate = a.createAt || a.createdAt;
        const bCreate = b.createAt || b.createdAt;

        return (
          moment(aCreate).format("YYYYMMDD") -
          moment(bCreate).format("YYYYMMDD")
        );
      },
      render: (text) => {
        if (text) {
          return moment(text).format("DD-MM-YYYY");
        }
      },
    });

    dynamicColumns.push({
      title: t("b2bOperationList.reason"),
      dataIndex: "closeReason",
      key: "closeReason",
      align: "center",
      filteredValue: filters.closeReason || [],
      filters: [...new Set(closeFilter)],
      onFilter: (value, operation) =>
        dataMobile === "operations" && operation.closeReason === value,
      render: (text) => {
        return text;
      },
    });

    dynamicColumns.push({
      title: t("b2bOperationList.subreason"),
      dataIndex: "closeSubReason",
      key: "closeSubReason",
      align: "center",
      render: (text) => {
        return text;
      },
    });

    setColumns(dynamicColumns);

    //HIPOTECA/SUBROGACION END

    //CERTIFICADO START
    dynamicColumnsCert.push({
      title: t("b2bOperationList.status"),
      align: "center",
      dataIndex: "status",
      key: "status",
      width: 90,
      filters: [
        {
          text: (
            <>
              <ExclamationOutlined
                style={{ color: "#FAAD14", fontSize: 20, marginRight: 8 }}
              />
              {t(`operationStatus.preregister`)}
            </>
          ),
          value: "not-verified",
        },
        {
          text: (
            <>
              <img
                src={activeIcon}
                alt="activeIcon"
                height={15}
                style={{ marginRight: 8 }}
              />
              {t(`operationStatus.open`)}
            </>
          ),
          value: "active",
        },
        {
          text: (
            <>
              <img
                src={lockIcon}
                alt="lockIcon"
                height={15}
                style={{ marginRight: 8 }}
              />

              {t(`operationStatus.closed`)}
            </>
          ),
          value: "finish",
        },
      ],
      filteredValue: filtersCert.status || [],
      onFilter: (value, operation) => {
        return (
          (value === "active" &&
            operation.status !== "finish" &&
            (!operation.closeDate || operation.closeDate === "")) ||
          (value === "finish" && operation.status === "finish") ||
          value === "not-verified"
        );
      },
      render: ( operation) => {
       
        let type = "not-verified";
        let icon = (
          <ExclamationOutlined style={{ color: "#FAAD14", fontSize: 20 }} />
        );

        if (operation.status === "finish") {
          type = "finish";
          icon = <img src={lockIcon} alt="lockIcon" height={16} />;
        } else if (operation.status === "active") {
         
          type = "active";
          icon = <img src={activeIcon} alt="activeIcon" height={16} />;
        }

        return <Tooltip title={t(`operationStatus.${type}`)}>{icon}</Tooltip>;
      },
    });

    dynamicColumnsCert.push({
      title: t("b2bOperationList.stage"),
      dataIndex: "stage",
      key: "stage",
      align: "center",
      width: 180,
      filteredValue: filtersCert.stage || [],
      filters: [
        { text: t("stages.documents"), value: "documents" },
        { text: t("stages.analysis"), value: "analysis" },
        // { text: t("operationStatus.closed"), value: "finish" },
      ],
      onFilter: (value, operation) => value === operation.stage,
      render: (text) => {
        if (text === "analysis") {
          text = "Analisis";
          return text;
        } else if (text === "documents") {
          text = "Documentación";
          return text;
        } else {
          text = "";
          return text;
        }
      },
    });

    dynamicColumnsCert.push({
      title: t("b2bOperationList.type"),
      dataIndex: "subtype",
      key: "subtype",
      align: "center",
      width: 75,
      filteredValue: filtersCert.subtype || [],
      filters: [
        {
          text: <>{t("balance.shoppingTitle")}</>,
          value: "certificate-buy",
        },
        {
          text: <>{t("form.rental")}</>,
          value: "certificate-rent",
        },
      ],
      onFilter: (value, operation) => {
        if (value === "not") {
          return !operation.subtype;
        }

        return value === operation.subtype;
      },
      render: (text) => {
        if (text === "certificate-buy") {
          text = t("balance.shoppingTitle");
          return text;
        } else {
          text = "certificate-rent";
          text = t("form.rental");
          return text;
        }
        return text;
      },
    });

    dynamicColumnsCert.push({
      title: "ID",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "160px",
      render: (text, operation) => {
        if (operation.accountKAM) {
          return text;
        } else {
          let link = undefined;
          if (operation.subtype === "certificate-buy") {
            link = `/sales-tools/certificated-buy-sell/${operation.id}/${operation.stage}`;
          } else {
            link = `/sales-tools/certificated-rent/${operation.id}/${operation.stage}`;
          }

          return (
            <NavLink style={{ color: '#02c3cd' }}
              to={{
                pathname: link
              }}
              state={operation.name}
            >
              {operation.subname}
            </NavLink>
          );
        }
      },
    });

    dynamicColumnsCert.push({
      title: <>{t("certificated.dateApplication")}</>,
      dataIndex: "createAt",
      key: "createAt",
      align: "center",
      width: 160,
      defaultSortOrder: "descending",
      sorter: (a, b) => {
        const aCreate = a.createAt || a.createdAt;
        const bCreate = b.createAt || b.createdAt;

        return moment(aCreate) - moment(bCreate);
      },
      render: (text) => {
        if (text) {
          return moment(text).format("DD/MM/YYYY (hh:mm A)");
        }
      },
    });

    dynamicColumnsCert.push({
      title: <>{t("m_products.fullTitle")}</>,
      dataIndex: "fullTitle",
      key: "fullTitle",
      align: "left",
      width: 180,

      render: (text, operation) => {
        let numberParticipants = 0;
        let array = [];
        if (operation && operation.operationData && operation.operationData.operation && operation.operationData.operation.owners && operation.operationData.operation.guarantors !== undefined) {
          numberParticipants =
            operation.operationData.operation.owners +
            operation.operationData.operation.guarantors;
          for (let index = 0; index < numberParticipants; index++) {
            tempIndexOwner = index + 1;
            if (
              operation.operationData[`owner` + tempIndexOwner]
                .confirmedDocumentation === true
            ) {
              array.push(
                <>
                  <div>
                    {/*<CheckOutlined style={{ color: "#52C41A" }} />&nbsp;*/}{" "}
                    {operation.operationData[`owner` + tempIndexOwner].name}
                  </div>{" "}
                  <br />
                </>
              );
            } else if (
              operation.operationData[`owner` + tempIndexOwner]
                .confirmedDocumentation === undefined ||
              operation.operationData[`owner` + tempIndexOwner]
                .confirmedDocumentation === false
            ) {
              array.push(
                <>
                  <div>
                    {/*<ClockCircleOutlined style={{ color: "#1890FF" }} />  &nbsp; */}{" "}
                    {operation.operationData[`owner` + tempIndexOwner].name}
                  </div>
                </>
              );
            } else {
              return "";
            }
          }

          return array;
        }
      },
    });

    dynamicColumnsCert.push({
      title: <>{t("certificated.certified")}</>,
      dataIndex: "certified",
      key: "certified",
      align: "center",
      width: 120,

      render: (text, operation) => {
        if (
          operation &&
          operation.stage === "finish" &&
          operation.operationData.certificate.ringTheBell
        ) {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ color: "#02C3CD", marginRight: 8 }}>
                {t("init.download")}
              </div>

              <img
                src={VerticalAlignBottomOutlinedIcon}
                alt="VerticalAlignBottomOutlinedIcon"
                height={18}
                onClick={() => handleFileDownload(operation.id, operation)}
              />
            </div>
          );
        }
      },
    });

    //CERTIFICADO END

    //ECO START
    dynamicColumnsEco.push({
      title: t("b2bOperationList.status"),
      align: "center",
      dataIndex: "status",
      key: "status",
      width: 90,

      render: (text, operation) => {
        let type = "not-verified";
        let icon = (
          <ExclamationOutlined style={{ color: "#FAAD14", fontSize: 20 }} />
        );

        if (operation.status === "finish") {
          type = "finish";
          icon = (
            <div style={{ color: "#2F4858", fontSize: 12, fontWeight: 400 }}>
              {t("operationStatus.closed")}
            </div>
          );
        } else if (operation.status === "active") {
          type = "active";
          icon = (
            <div style={{ color: "#2F4858", fontSize: 12, fontWeight: 400 }}>
              {t("init.requestedDeb").substring(
                0,
                t("init.requestedDeb").length - 1
              )}
            </div>
          );
        }

        return <Tooltip title={t(`operationStatus.${type}`)}>{icon}</Tooltip>;
      },
    });

    dynamicColumnsEco.push({
      title: "ID",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "160px",
      render: (text, operation) => {
        if (operation.accountKAM) {
          return text;
        } else {
          let link = undefined;

          link = `/sales-tools/follow-up/detail-operation/${operation.id}/${operation.stage}`;

          return (
            <NavLink style={{ color: '#02c3cd' }}
              to={{
                pathname: link,
              
              }}
              state={operation.name}
            >
              {operation.subname}
            </NavLink>
          );
        }
      },
    });
    dynamicColumnsEco.push({
      title: <>{t("certificated.dateApplication")}</>,
      dataIndex: "createAt",
      key: "createAt",
      align: "center",
      width: 120,
      defaultSortOrder: "descending",
      sorter: (a, b) => {
        const aCreate = a.createAt || a.createdAt;
        const bCreate = b.createAt || b.createdAt;

        return moment(aCreate) - moment(bCreate);
      },
      render: (text) => {
        if (text) {
          return moment(text).format("DD/MM/YYYY");
        }
      },
    });
    dynamicColumnsEco.push({
      title: <>{t("budget.typeHome")}</>,
      dataIndex: "ecoHouseType",
      key: "ecoHouseType",
      align: "center",
      width: 120,
      defaultSortOrder: "descending",
      render: (text, operation) => {
        return ecoName[operation.operationData["solar-value"].ecoHouseType];
      },
    });
    dynamicColumnsEco.push({
      title: <>{t("m_register.direction")}</>,
      dataIndex: "address",
      key: "address",
      align: "center",
      width: 120,
      defaultSortOrder: "descending",
      render: (text, operation) => {
        return operation.operationData["solar-value"].address;
      },
    });
    dynamicColumnsEco.push({
      title: <>{t("form.population")}</>,
      dataIndex: "city",
      key: "city",
      align: "center",
      width: 120,
      defaultSortOrder: "descending",
      render: (text, operation) => {
        return operation.operationData["solar-value"].city;
      },
    });
    dynamicColumnsEco.push({
      title: <>{t("form.provinces")}</>,
      dataIndex: "province",
      key: "province",
      align: "center",
      width: 120,
      defaultSortOrder: "descending",
      render: (text, operation) => {
        return operation.operationData["solar-value"].province;
      },
    });

    setColumnsCert(dynamicColumnsCert);
    setColumnsEco(dynamicColumnsEco);
  }, [operations, filters, filtersCert]);

  const handleTableChange = ( pagination, filters, sorter) => {
    const queryFilters = {};

    if (filters.status) {
      queryFilters.status = filters.status.join(";");
    }

    if (filters.type) {
      queryFilters.type = filters.type.join(";");
    }

    if (filters.agent) {
      queryFilters.agent = filters.agent.join(";");
    }

    if (filters.stage) {
      queryFilters.stage = filters.stage.join(";");
    }

    if (filters.closeReason) {
      queryFilters.closeReason = filters.closeReason.join(";");
    }

    const query = queryString.stringify(queryFilters);

    navigate(`/sales-tools/follow-up?${query}`);
    setFilters(filters);
  };

  const handleTableChangeCert = (pagination, filtersCert) => {
    const queryFilters = {};

    if (filtersCert.status && filtersCert.status.length > 0) {
      queryFilters.status = filtersCert.status.join(";");
    }

    if (filtersCert.subtype && filtersCert.subtype.length > 0) {
      queryFilters.subtype = filtersCert.subtype.join(";");
    }

    if (filtersCert.stage && filtersCert.stage.length > 0) {
      queryFilters.stage = filtersCert.stage.join(";");
    }

    let query = queryString.stringify(queryFilters);

    navigate(`/sales-tools/follow-up?${query}`);
    setFiltersCert(filtersCert);

    query = filtersCert.status
      .map((status) => `multiStatus[]=${status}`)
      .join("&");

    getCertificates(query);
  };

  const list = [];

  list.push(t("createMortgageComponent.mortgagesTitle"));
  if (
    profile &&
    (profile.email === "inmobiliaria@gibobs.com" ||
      profile.email === "israel.tena@gibobs.com")
  ) {
    list.push(t("tabs.certified"));
  }
  list.push(t("form.budgetGibobs"));

  const textModal = [
    {
      img: card1,
      text1: t("product.certificateStep1"),
      text2: t("modalInmo.description1"),
    },
    {
      img: card2,
      text1: t("modalInmo.title2"),
      text2: t("modalInmo.description2"),
    },
    {
      img: card3,
      text1: t("modalInmo.title3"),
      text2: t("modalInmo.description3"),
    },
  ];

  if (!isMobile) {
    return (
      (<Layout style={{ marginBottom: "100px" }}>
        <LayoutTools />
        <Content
          style={{ margin: 40, position: "relative", top: -25, marginTop: 24 }}
        >
          <div style={{ marginBottom: 8, fontSize: 12, fontWeight: 400 }}>
            {t("form.operationTypeTitle")}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 24,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ marginRight: 16 }}>
                <Select
                  suffixIcon={
                    <img src={DownOutlinedMobile} alt="DownOutlinedMobile" />
                  }
                  className="select-indivual-inmo"
                  value={dataMobile}
                  style={{ width: "280px" }}
                  onChange={(v) => setDataMobile(v)}
                >
                  <Option value="operations">
                    {t("createMortgageComponent.mortgagesTitle") +
                      "/" +
                      t("createMortgageComponent.subrogationTitle")}
                  </Option>
                  {profile &&
                    (profile.email === "edgardo.vazquez@hannanpiper.com" ||
                      profile.email === "inmobiliaria@gibobs.com" ||
                      profile.email === "israel.tena@gibobs.com") && (
                      <Option value="dataCertificated">
                        {t("tabs.certified")}
                      </Option>
                    )}
                  <Option value="dataSolarValue">
                    {t("form.budgetGibobs")}
                  </Option>
                </Select>
              </div>
              <Button
                type="dashed"
                className="button-secundary-gibobs-inmo"
                style={{ marginRight: 8, fontSize: "14px" }}
              >
                {dataMobile === "operations" ? (
                  <NavLink
                    to={{
                      pathname: `/sales-tools/mortgages`,
                    }}
                  >
                    {t("b2bOperationList.newMortgage")}
                  </NavLink>
                ) : dataMobile === "dataCertificated" ? (
                  <NavLink
                    to={{
                      pathname: `/sales-tools/certificated-buy-sell`,
                    }}
                  >
                    {t("b2bOperationList.newCertBuy")}
                  </NavLink>
                ) : (
                  <NavLink
                    to={{
                      pathname: `/sales-tools/new-requests/budgetEco`,
                    }}
                  >
                    {t("newRequests.newbudget")}
                  </NavLink>
                )}
              </Button>
              {dataMobile === "dataCertificated" && (
                <Button
                  style={{ fontSize: "14px" }}
                  type="dashed"
                  className="button-secundary-gibobs-inmo"
                >
                  <NavLink
                    to={{
                      pathname: `/sales-tools/certificated-rent`,
                    }}
                  >
                    {t("b2bOperationList.newCertRent")}
                  </NavLink>
                </Button>
              )}
            </div>
            {/* <Input
              placeholder="Buscar por operación"
              style={{
                height: 32,
                width: "280px",
              }}
              disabled={true}
            ></Input> */}
          </div>
          <Card>
            {dataMobile === "operations" && (
              <Table
                rowClassName="table-row"
                bordered={true}
                loading={loading}
                showHeader={true}
                showFooter={true}
                columns={columns}
                dataSource={operations}
                pagination={{ position: "bottom" }}
                onChange={handleTableChange}
                sticky
                locale={{
                  Pagination: {
                    page: "pagina",
                  },
                }}
                scroll={{ x: 1220 }}
              />
            )}
            {dataMobile === "dataCertificated" && (
              <Table
                rowClassName="table-row"
                bordered={true}
                loading={loading}
                showHeader={true}
                showFooter={true}
                columns={columnsCert}
                dataSource={dataCertificated}
                pagination={{ position: "bottom" }}
                onChange={handleTableChangeCert}
                sticky
                locale={{
                  Pagination: {
                    page: "pagina",
                  },
                }}
                scroll={{ x: 1220 }}
              />
            )}
            {dataMobile === "dataSolarValue" && (
              <Table
                rowClassName="table-row"
                bordered={true}
                loading={loading}
                showHeader={true}
                showFooter={true}
                columns={columnsEco}
                dataSource={dataSolarValue}
                pagination={{ position: "bottom" }}
                onChange={handleTableChange}
                sticky
                locale={{
                  Pagination: {
                    page: "pagina",
                  },
                }}
                scroll={{ x: 1000 }}
              />
            )}
          </Card>
        </Content>
        <Modal
          className="ModalCommonNewPageCert"
          open={visibleModal}
          title={t("productsComponent.modalCertificateTitle")}
          width={696}
          height={"100%"}
          onCancel={handleCancelModal}
          closable={true}
          maskClosable={true}
          footer={false}
        >
          <Row gutter={24}>
            {textModal &&
              textModal.map((data) => {
                return (
                  <Col lg={8} md={8} sm={8} xs={24}>
                    <Card
                      className="CardViability"
                      style={{
                        width: "100%",
                        height: 465,
                        boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
                        borderRadius: 4,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={data.img}
                          alt={data.img}
                          style={{ width: 112, height: 75 }}
                        ></img>
                        <Text
                          style={{
                            fontSize: "16px",
                            color: "##2F4858",
                            fontWeight: 600,
                            marginTop: 12,
                            width: 152,
                          }}
                        >
                          {data.text1}
                        </Text>
                        <Text
                          style={{
                            fontSize: "14px",
                            color: "#2F4858",
                            fontWeight: 400,
                            marginTop: 14,
                            width: 152,
                          }}
                        >
                          {data.text2}
                        </Text>
                      </div>
                    </Card>
                  </Col>
                );
              })}
          </Row>
        </Modal>
      </Layout>)
    );
  } else {
    return (
      <Layout style={{ marginBottom: "100px" }}>
        <LayoutTools></LayoutTools>

        <Content style={{ margin: 16, position: "relative", top: -25 }}>
          <Row gutter={16}>
            <Col flex="1">
              <div style={{ marginBottom: 8, fontSize: 12, fontWeight: 400 }}>
                {t("form.operationTypeTitle")}
              </div>
              <Select
                suffixIcon={
                  <img src={DownOutlinedMobile} alt="DownOutlinedMobile" />
                }
                className="select-indivual-inmo"
                value={dataMobile}
                style={{ width: "100%" }}
                onChange={(v) => setDataMobile(v)}
              >
                <Option value="operations">
                  {t("createMortgageComponent.mortgagesTitle") +
                    "/" +
                    t("createMortgageComponent.subrogationTitle")}
                </Option>
                {profile &&
                  (profile.email === "edgardo.vazquez@hannanpiper.com" ||
                    profile.email === "inmobiliaria@gibobs.com" ||
                    profile.email === "israel.tena@gibobs.com") && (
                    <Option value="dataCertificated">
                      {t("tabs.certified")}
                    </Option>
                  )}
                <Option value="dataSolarValue">{t("form.budgetGibobs")}</Option>
              </Select>

              <Input
                placeholder="Buscar por operación"
                style={{
                  height: 40,
                  width: "100%",
                  marginTop: 8,
                  marginBottom: 24,
                }}
                disabled={true}
              ></Input>
              <div style={{ marginBottom: 12, fontSize: 12, fontWeight: 600 }}>
                {t("m.rstools").toUpperCase()}
              </div>

              <List
                itemLayout="vertical"
                size="large"
                pagination={{
                  onChange: (page) => {
                    console.log(page);
                  },
                  pageSize: 5,
                  style: { textAlign: "center" },
                }}
                dataSource={
                  dataMobile === "operations"
                    ? operations.filter((data) => {
                        if (
                          filters &&
                          filters.status &&
                          filters.status[0] === "closed"
                        ) {
                          return (
                            data.status === "finish" &&
                            filters.status &&
                            filters.status[0] === "closed"
                          );
                        } else if (
                          filters &&
                          filters.status &&
                          filters.status[0] === "open"
                        ) {
                          return (
                            data.status !== "finish" &&
                            data.isVerified !== false &&
                            filters.status &&
                            filters.status[0] === "open"
                          );
                        } else if (
                          filters &&
                          filters.status &&
                          filters.status[0] === "preregister"
                        ) {
                          return (
                            data.isVerified === false &&
                            filters.status &&
                            filters.status[0] === "preregister"
                          );
                        } else {
                          return data;
                        }
                      })
                    : dataMobile === "dataCertificated"
                    ? dataCertificated
                    : dataSolarValue
                }
                footer={
                  <div>
                    <b></b>
                  </div>
                }
                renderItem={(operation) => {
                  let type = "preregister";
                  let icon = (
                    <ExclamationOutlined
                      style={{
                        color: "#FAAD14",
                        fontSize: 20,
                        marginRight: 16,
                      }}
                    />
                  );

                  if (!operation.accountKAM) {
                    if (
                      operation.status === "finish" &&
                      operation.closeReason === "Firmado con gibobs"
                    ) {
                      type = "closed";
                      icon = (
                        <img
                          src={gibobsLogo}
                          alt="gibobsLogo"
                          style={{
                            color: "red",
                            fontSize: 20,
                            marginRight: 16,
                          }}
                        />
                      );
                    } else if (operation.status === "finish") {
                      type = "closed";
                      icon = <img src={lockIcon} alt="lockIcon" height={18} />;
                    } else {
                    
                      type = "open";
                      icon = (
                        <img src={activeIcon} alt="activeIcon" height={15} />
                      );
                    }
                  }

                  const tooltip = (
                    <Tooltip title={t(`operationStatus.${type}`)}>
                      <div
                        style={{
                          height: 24,
                          width: 24,
                          textAlign: "center",
                          marginRight: 16,
                        }}
                      >
                        {icon}
                      </div>
                    </Tooltip>
                  );

                  let icon2;
                  let type2 =
                    operation.type === "mortgage"
                      ? operation.subtype
                      : operation.type;

                  if (type2 === "mortgage" || type2 === "mortgage-promotion") {
                    icon2 = (
                      <img src={mortgageIcon} alt="mortgageIcon" height={18} />
                    );
                  } else if (type2 === "subrogation") {
                    icon2 = (
                      <img
                        src={subrogationIcon}
                        alt="subrogationIcon"
                        height={18}
                      />
                    );
                  } else if (type2 === "green-subrogation") {
                    icon2 = (
                      <img
                        src={greenSubrogationIcon}
                        alt="greenSubrogationIcon"
                        height={18}
                      />
                    );
                  } else if (type2 === "green-mortgage") {
                    icon2 = (
                      <img
                        src={greenMortgageIcon}
                        alt="greenMortgageIcon"
                        height={18}
                      />
                    );
                  } else if (type2 === "certificate") {
                    if (operation.subtype === "certificate-rent") {
                      icon2 = (
                        <img
                          src={FileOutlinedIcon}
                          alt="FileOutlinedIcon"
                          height={20}
                        />
                      );
                    } else {
                      icon2 = (
                        <img
                          style={{
                            color: "#02C3CD",
                            fontSize: 20,
                          }}
                          src={certificateInmo}
                          alt="certificateInmo"
                        />
                      );
                    }
                  } else if (operation.accountKAM) {
                    type2 = "preregister";
                    icon2 = (
                      <img
                        src={userIconInmo}
                        alt="FileOutlinedIcon"
                        height={19}
                      />
                    );
                  } else {
                    icon2 = type2;
                  }

                  const tooltip2 = (
                    <Tooltip title={t(`operationTypes.${type}`)}>
                      <div
                        style={{
                          height: 24,
                          width: 24,
                          textAlign: "center",
                          marginRight: 16,
                        }}
                      >
                        {icon2}
                      </div>
                    </Tooltip>
                  );
                  return (
                    <>
                      {
                        <NavLink
                          to={{
                            pathname:
                              operation.type === "mortgage" ||
                              operation.type === "solar-value"
                                ? `/sales-tools/follow-up/detail-operation/${operation.id}/${operation.stage}`
                                : operation.type === "certificate" &&
                                  operation.subtype === "certificate-buy"
                                ? `/sales-tools/certificated-buy-sell/${operation.id}/${operation.stage}`
                                : operation.type === "certificate" &&
                                  operation.subtype === "certificate-rent"
                                ? `/sales-tools/certificated-rent/${operation.id}/${operation.stage}`
                                : undefined,
                          
                          }}
                          state={operation.name}
                          style={{ fontSize: 16, fontWeight: 600 }}
                        >
                          <List.Item
                            key={operation.id}
                            style={{
                              padding: 16,
                              backgroundColor: "white",
                              marginTop: 8,
                              marginBottom: 8,
                              boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
                            }}
                          >
                            {dataMobile !== "dataSolarValue" && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: 16,
                                }}
                              >
                                {tooltip}
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    className="description-cards"
                                    style={{ fontSize: 14, marginTop: 0 }}
                                  >
                                    {operation.status === "finish"
                                      ? t("m_amigobs.status")
                                      : t("m_amigobs.stage")}
                                  </div>
                                  <div className="title-cards">
                                    {operation.isVerified === false
                                      ? "Preregistro"
                                      : operation.status === "finish"
                                      ? t("stages.closed")
                                      : operation.stage !== "finish" &&
                                        statusName[operation.stage]}
                                  </div>
                                </div>
                              </div>
                            )}
                            {dataMobile !== "dataSolarValue" && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom:
                                    operation.isVerified === false ? 0 : 16,
                                }}
                              >
                                <div
                                  style={{
                                    height: 24,
                                    width: 24,
                                    textAlign: "center",
                                    marginRight: 16,
                                  }}
                                >
                                  <img
                                    src={userIconInmo}
                                    alt="FileOutlinedIcon"
                                    height={19}
                                  />
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    className="description-cards"
                                    style={{ fontSize: 14, marginTop: 0 }}
                                  >
                                    {t("b2bOperationList.client")}
                                  </div>
                                  <div className="title-cards">
                                    {operation &&
                                    operation.operationData &&
                                    operation.operationData.owner1 &&
                                    operation.operationData.owner1.name
                                      ? operation.operationData.owner1.name
                                      : operation.client ||
                                        operation.clientName}
                                  </div>
                                </div>
                              </div>
                            )}
                            {operation.isVerified !== false &&
                              dataMobile !== "dataSolarValue" && (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {tooltip2}
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div
                                        className="description-cards"
                                        style={{ fontSize: 14, marginTop: 0 }}
                                      >
                                        {operation.type === "mortgage"
                                          ? statusName[operation.subtype]
                                          : operation.type === "certificate" &&
                                            statusName[operation.subtype]}
                                      </div>
                                      <div
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 600,
                                          color: "#02C3CD",
                                        }}
                                      >
                                        {operation.type === "mortgage"
                                          ? operation.name
                                          : operation.subname}
                                      </div>
                                    </div>
                                  </div>

                                  {operation.stage === "finish" &&
                                    operation.type === "certificate" && (
                                      <div
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleFileDownload(
                                            operation.id,
                                            operation
                                          );
                                        }}
                                      >
                                        <div
                                          style={{
                                            height: 24,
                                            width: 24,
                                            textAlign: "center",
                                            marginRight: 16,
                                          }}
                                        >
                                          <img
                                            src={
                                              VerticalAlignBottomOutlinedIcon
                                            }
                                            alt="VerticalAlignBottomOutlinedIcon"
                                            height={15}
                                          />
                                        </div>
                                      </div>
                                    )}
                                </div>
                              )}
                            {dataMobile === "dataSolarValue" && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: 16,
                                }}
                              >
                                <div
                                  style={{
                                    height: 24,
                                    width: 24,
                                    textAlign: "center",
                                    marginRight: 16,
                                  }}
                                >
                                  <img
                                    src={calendarIcon}
                                    alt="calendarIcon"
                                    height={19}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    className="description-cards"
                                    style={{ fontSize: 14, marginTop: 0 }}
                                  >
                                    {t("certificated.dateApplication")}
                                  </div>
                                  <div className="title-cards">
                                    {operation.createAt &&
                                      moment(operation.createAt).format(
                                        "DD/MM/YYYY"
                                      )}
                                  </div>
                                </div>
                              </div>
                            )}
                            {dataMobile === "dataSolarValue" && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: 16,
                                }}
                              >
                                <div
                                  style={{
                                    height: 24,
                                    width: 24,
                                    textAlign: "center",
                                    marginRight: 16,
                                  }}
                                >
                                  <img
                                    src={mortgageIcon}
                                    alt="mortgageIcon"
                                    height={19}
                                  />
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    className="description-cards"
                                    style={{ fontSize: 14, marginTop: 0 }}
                                  >
                                    {t("budget.typeHome")}
                                  </div>
                                  <div className="title-cards">
                                    {operation.operationData &&
                                    operation.operationData["solar-value"]
                                      ? ecoName[
                                          operation.operationData["solar-value"]
                                            .ecoHouseType
                                        ]
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            )}
                            {dataMobile === "dataSolarValue" && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    height: 24,
                                    width: 24,
                                    textAlign: "center",
                                    marginRight: 16,
                                  }}
                                >
                                  <img
                                    src={locationIcon}
                                    alt="locationIcon"
                                    style={{
                                      fontSize: 20,
                                    }}
                                  />
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    className="description-cards"
                                    style={{ fontSize: 14, marginTop: 0 }}
                                  >
                                    {t("m_register.direction")}
                                  </div>
                                  <div className="title-cards">
                                    {operation.operationData &&
                                    operation.operationData["solar-value"]
                                      ? operation.operationData["solar-value"]
                                          .address
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            )}
                          </List.Item>
                        </NavLink>
                      }
                    </>
                  );
                }}
              />
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
};

export default FollowUpPage;
