import React, { useEffect, useState } from "react";
import {
  Row,
  Card,
  Skeleton,
  Col,
  Typography,
  Form,
  Modal,
  Button,
  Radio,
  Slider,
  DatePicker,
  InputNumber,
} from "antd";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ArrowLeftOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { isMobileOnly } from "react-device-detect";
import { useSelector } from "react-redux";
import axios from "axios";
import env from "../../../environment";
import numeral from "numeral";
import _ from "lodash";
import calculatorImage from "../../../assets/calculators/calculatorSubrogation.png";
import bulp from "../../../assets/loans/bulp.png";
import rightIcon from "../../../assets/loans/rigth.png";
import edit from "../../../assets/calculators/edit.png";
import ModalShiftCalculation from "./ModalShiftCalculation";

const { Text } = Typography;

const CalculatorSubrogation = ({}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();;
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [result, setResult] = useState(undefined);
  const userToken = useSelector((state) => state.auth.token);
  const [, setCapitalPending] = useState(0);
  const [, setPercent] = useState(0);
  const [visibleResult, setVisibleResult] = useState(false);
  const [visibleModalCalculationAmount, setVisibleModalCalculationAmount] =
    useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [euribor, setEuribor] = useState(undefined);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [1500]);
    if (!_.isNil(location.state)) {
      getData(location.state.result.id);
    }
    getEuribor()
  }, []);

  const getEuribor = () => {
    axios
      .get(`${env.api.url}/v1/rates/by-month/euribor`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setEuribor(response.data.data.rates);
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const getData = (id) => {
    axios
      .get(`${env.api.url}/mortgages/simulations/get-simulation/${id}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setResult(response.data.data.simulationData.result);
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const onChangeForm = (values, allValues) => {
    console.log(allValues);
    setCapitalPending(allValues.pendingCapital);

    if (allValues.pendingCapital && allValues.amountToBeAmortized) {
      const total =
        (allValues.amountToBeAmortized * 100) / allValues.pendingCapital;
      setPercent(total);
    }

    axios
      .post(
        `${env.api.url}/mortgages/simulations/subrogation-calculation/subrogation-viability`,
        {
          id: result ? result?.id : undefined,
          dateOfSaleInitial: allValues.dateOfSaleInitial,
          pendingAmount: parseInt(allValues.pendingAmount),
          pendingQuotes: parseInt(allValues.pendingQuotes),
          actualInterestRate: parseInt(allValues.actualInterestRate / 100),
          newInterestRate: parseInt(allValues.newInterestRate / 100),
          actualPercentageCancelCommission: parseFloat(
            allValues.actualPercentageCancelCommission / 100
          ),
          newPercentageCancelCommission: parseInt(
            allValues.newPercentageCancelCommission / 100
          ),

          actualAnnualCostsOtherProducts: parseInt(
            allValues.actualAnnualCostsOtherProducts
          ),
          newActualCostsOtherProducts: parseInt(
            allValues.newActualCostsOtherProducts
          ),
         
          amountToBeAmortized: parseInt(allValues.amountToBeAmortized),
          
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        console.log(response);
        // getData(response.data.data.id);
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const formSubrogationInitial = (
    <Form
      labelAlign="left"
      colon={false}
      className="gb-form-profile-headlines-mobile"
      labelCol={{
        flex: !isMobileOnly ? "50%" : "45%",
      }}
      wrapperCol={{
        flex: isMobileOnly && "55%",
      }}
      labelWrap
      layout={"horizontal"}
      scrollToFirstError={true}
      form={form}
      name="basic"
      onValuesChange={(values, allValues) => onChangeForm(values, allValues)}
    >
      <Row
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 16,
        }}
      >
        <Col span={24}>
          <Form.Item
            name="dateOfSaleInitial"
            label={t("fee.dateInitialPurchase")}
          >
            <DatePicker
              id="dateOfSaleInitial"
              format={"DD/MM/YYYY"}
              style={{
                width: "100%",
                border: "1px solid #C0DAEE",
                borderRadius: 4,
                height: 40,
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="pendingAmount" label={t("fee.amountOutstanding")}>
            <InputNumber
            
              suffix={"€"}
              id="pendingAmount"
              placeholder={t("0")}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="pendingQuotes"
            label={t("fee.outstandingInstallment")}
          >
            <InputNumber
             
             
              suffix={t("viability.months")}
              id="pendingQuotes"
              placeholder={t("0")}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="actualInterestRate"
            label={
              <>
                {t("balance.interest")}
                <InfoCircleOutlined
                  onClick={() => setVisibleResult(true)}
                  style={{ color: "#02C3CD", fontSize: 16, marginLeft: 8 }}
                />
              </>
            }
            rules={[
              {
                validator: (e, value) => {
                  return new Promise((resolve, reject) => {
                    if (value >= 0.1 && value <= 10) {
                      resolve();
                    } else {
                      reject(
                        t("Interes actual debe ser minimo 0.10% y maximo 10%")
                      );
                    }
                  });
                },
              },
            ]}
          >
            <InputNumber
              suffix={"%"}
              id="actualInterestRate"
              placeholder={t("0")}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="actualQuote"
            label={t("subrogation.quotaSubrogation")}
          >
            <InputNumber
              suffix={"€"}
              id="actualQuote"
              placeholder={t("0")}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("fee.fullCancellationFee")}>
            <Radio.Group>
              <Radio value="yes">{t("form.yes")}</Radio>
              <Radio value="no">{t("form.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="actualPercentageCancelCommission"
            label={t("fee.indicatesCommission")}
          >
            <InputNumber
              suffix={"%"}
              id="actualTin"
              placeholder={t("0")}
            />
          </Form.Item>
        </Col>
        <div className="description-estimated-costs">
          {t("fee.costOtherPurchase")}
        </div>
        <Col span={24}>
          <Form.Item
            name="newActualCostsOtherProducts"
            label={t("loan.costAnnual")}
          >
            <InputNumber
              suffix={"€"}
              id="newActualCostsOtherProducts"
              placeholder={t("0")}
            
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  const sectionTitleAndDescription = (title, description, color, total) => {
    return (
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            className="title-subrogation-calculators"
            style={{ color: total && "#2F4858", fontWeight: total && 600 }}
          >
            {t(title)}
          </div>
          {total && (
            <InfoCircleOutlined
              onClick={() => setVisibleModalCalculationAmount(true)}
              style={{
                color: "#02C3CD",
                fontSize: 16,
                marginLeft: 8,
              }}
            />
          )}
        </div>
        <div
          className="description-subrogation-calculators"
          style={{ color: color && color }}
        >
          {t(description)}
        </div>
      </div>
    );
  };

  const subrogationCalculatorPage1 = (
    <Col xs={24} sm={24} md={24} lg={10} xl={10}>
      {!isMobileOnly && (
        <Card
          className="card-calculator-amortization"
          cover={<img src={calculatorImage} alt="asdf"></img>}
        >
          <Skeleton active loading={loading} paragraph={false} />

          {!loading && (
            <div
              className="title-current-mortgage-conditions"
              dangerouslySetInnerHTML={{
                __html: t("fee.currentMortgageConditions"),
              }}
            />
          )}
          {!loading && <div>{formSubrogationInitial}</div>}
        </Card>
      )}

      {isMobileOnly && !loading && (
        <>
          {!loading && (
            <div
              style={{ marginTop: 8 }}
              className="title-current-mortgage-conditions"
              dangerouslySetInnerHTML={{
                __html: t("fee.currentMortgageConditions"),
              }}
            />
          )}
          {formSubrogationInitial}
        </>
      )}
      {isMobileOnly && (
        <Skeleton
          active
          loading={loading}
          paragraph={false}
          style={{ marginTop: 8 }}
        />
      )}

      {isMobileOnly && (
        <Card
          className="card-button-style-onboading"
          style={{
            left: 0,
            marginBottom: -2,
            backgroundColor: "#FAFEFE",
            height: 80,
          }}
        >
          <Button
            type="primary"
            className="button-primari-gibobs"
            style={{ width: "100%" }}
            onClick={() => setPageNumber(2)}
          >
            {t("viability.myInterest")}
          </Button>
        </Card>
      )}
    </Col>
  );

  const subrogationCalculatorPage2 = (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={14}
      xl={14}
      style={{ textAlign: "center", marginBottom: isMobileOnly && 100 }}
    >
      <Skeleton active loading={loading} paragraph={false} />
      {!loading && (
        <div style={{ textAlign: "center" }}>
          <Text
            style={{
              color: "#2F4858",
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            {t("fee.newBankOfferYou")}
          </Text>
        </div>
      )}
      {!loading && (
        <div style={{ textAlign: "left", marginTop: isMobileOnly ? 24 : 8 }}>
          <Text
            style={{
              color: "#748EA0",
              fontSize: 14,
              fontWeight: 400,
            }}
          >
            {t("fee.newBankOfferDescription")}
          </Text>
        </div>
      )}
      {!loading && (
        <Row
          style={{
            width: "100%",
            marginTop: !isMobileOnly ? 65 : 24,
            justifyContent: "center",
          }}
        >
          <Form
            labelAlign="left"
            colon={false}
            className="gb-form-profile-headlines-mobile"
            labelCol={{
              flex: "40%",
            }}
            labelWrap
            wrapperCol={{
              flex: !isMobileOnly ? "60%" : "60%",
            }}
            layout="horizontal"
            scrollToFirstError={true}
            form={form}
            name="basic"
            onValuesChange={(values, allValues) =>
              onChangeForm(values, allValues)
            }
            style={{ width: "100%", padding: !isMobileOnly && "0px 27px" }}
          >
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  name="newPercentageCancelCommission"
                  label={
                    <>
                      {t("loan.rate")}
                      <InfoCircleOutlined
                        onClick={() => setVisibleResult(true)}
                        style={{
                          color: "#02C3CD",
                          fontSize: 16,
                          marginLeft: 8,
                        }}
                      />
                    </>
                  }
                >
                  <Slider
                    min={0.1}
                    max={10}
                    trackStyle={{ backgroundColor: "#02C3CD" }}
                    railStyle={{ backgroundColor: "#C0DAEE" }}
                  />
                </Form.Item>
                <div className="current-euribor-calculator">
                {euribor && (
                          <Text
                            style={{
                              color: "#016B71",
                              fontSize: 12,
                              fontWeight: 400,
                            }}
                          >
                            {t("fee.calculatorEuribor") +
                              " " +
                              euribor[euribor.length - 1].index.toFixed(2) +
                              "%"}
                          </Text>
                      )}
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  name="actualAnnualCostsOtherProducts"
                  label={t("fee.annualCostLinkages")}
                >
                  <InputNumber
                    suffix={"€"}
                    id="actualAnnualCostsOtherProducts"
                    placeholder={t("0")}
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
      )}
      <Skeleton active loading={loading} paragraph={false} />
      {!loading && (
        <div style={{ textAlign: "center", marginTop: 32 }}>
          <Text
            style={{
              color: "#2F4858",
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            {t("fee.newDescriptionSubrogation")}
          </Text>
        </div>
      )}

      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: isMobileOnly && "column",
          columnGap: 24,
          rowGap: isMobileOnly && 8,
          marginTop: 16,
        }}
      >
        <Card className="card-your-quote-calculators">
          <Skeleton active loading={loading} paragraph={false} />

          {!loading && (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              {sectionTitleAndDescription(
                "onboarding.newQuota",
                numeral(result?.newQuote).format("0,0") +
                  " " +
                  t("common.euroAtMonth")
              )}
              {sectionTitleAndDescription(
                "onboarding.youWouldPay",
                numeral(result?.quoteDifference).format("0,0") +
                  " " +
                  t("common.euroAtMonth"),
                result?.quoteDifference > 0 ? "#FF4D4F" : "#52C41A"
              )}
            </div>
          )}
        </Card>
        <Card className="card-savings-amortization-calculators">
          <Skeleton active loading={loading} paragraph={false} />

          {!loading && (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              {sectionTitleAndDescription(
                "onboarding.youWillPay",
                numeral(result?.totalDifference).format("0,0") +
                  " " +
                  t("common.euroAtMonth"),
                result?.totalDifference > 0 ? "#FF4D4F" : "#52C41A",
                true
              )}
            </div>
          )}
        </Card>
      </div>

      {!loading && (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            columnGap: 24,
            marginTop: 16,
          }}
        >
          <Card className="card-save-data-calculators">
            <div className="wrapper-save-data">
              <img src={bulp} alt="bulp" height={24} />
              <div style={{ textAlign: "left" }}>
                <div className="title">{t("onboarding.gibobsExplanation")}</div>
                <div className="wrapper-title-icon">
                  <div className="sub-title">{t("onboarding.saveData")}</div>
                  <img src={rightIcon} alt="right" height={24} />
                </div>
              </div>
            </div>
          </Card>
        </div>
      )}
      {!loading && (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            columnGap: 24,
            marginTop: 16,
          }}
        >
          <Card className="card-messages-calculators">
            <div style={{ textAlign: "left" }}>
              <div className="title">
                {t("onboarding.lessThanOneYearTitle")}
              </div>
              <div className="description">
                {t("fee.lessThanYearDescriptionSubrogation")}
              </div>
            </div>
          </Card>
        </div>
      )}
      {!loading && (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            columnGap: 24,
            marginTop: 16,
          }}
        >
          <Card className="card-messages-calculators">
            <div style={{ textAlign: "left" }}>
              <div className="title">{t("fee.analyzingSubrogation")}</div>
              <div className="description">
                {t("fee.analyzingSubrogationDescription")}
              </div>
            </div>
          </Card>
        </div>
      )}
      {!loading && !isMobileOnly && (
        <div style={{ marginTop: 32 }}>
          <Button type="primary" className="button-primari-gibobs">
            {t("fee.analyzeSubrogationButton")}
          </Button>
        </div>
      )}
      {!loading && isMobileOnly && (
        <Card
          className="card-button-style-onboading"
          style={{
            left: 0,
            marginBottom: -2,
            backgroundColor: "#FAFEFE",
            height: 80,
          }}
        >
          <Button
            type="primary"
            className="button-primari-gibobs"
            style={{ width: "100%" }}
          >
            {t("fee.analyzeSubrogationButton")}
          </Button>
        </Card>
      )}
    </Col>
  );

  return (
    <>
      <div style={{ padding: isMobileOnly ? 16 : 40, marginBottom: 100 }}>
        {pageNumber === 1 && (
          <Row style={{ width: "100%" }}>
            <Col span={24}>
              <a onClick={() => navigate("/sales-tools/new-requests")}>
                <ArrowLeftOutlined style={{ color: "#2F4858", fontSize: 20 }} />
              </a>
              <Text
                style={{
                  color: "#2F4858",
                  fontSize: 16,
                  fontWeight: 600,
                  marginLeft: 16,
                }}
              >
                {t("fee.calculatorSubrogation")}
              </Text>
            </Col>
          </Row>
        )}

        {!isMobileOnly && (
          <Card className="gb-card" style={{ marginTop: 24 }}>
            <Row gutter={[24, 24]}>
              {subrogationCalculatorPage1}
              {subrogationCalculatorPage2}
            </Row>
          </Card>
        )}
        {isMobileOnly && pageNumber === 1 && <>{subrogationCalculatorPage1}</>}
        {isMobileOnly && pageNumber === 2 && (
          <>
            <div
              className="edit-data-calculators-subrogation"
              onClick={() => setPageNumber(1)}
            >
              {t("fee.editCurrentMortgage")}
              <img src={edit} alt="edit" height={16} />
            </div>
            {subrogationCalculatorPage2}
          </>
        )}
      </div>

      {/* START MODAL SECTION */}
      <Modal
        className="ModalMobileFooterColum"
        okButtonProps={{ style: { display: "none" } }}
        open={visibleResult}
        title={t("loan.rate")}
        width={480}
        cancelButtonProps={{ style: { display: "none" } }}
        closable={true}
        onCancel={() => setVisibleResult(false)}
        centered
      >
        <div style={{ marginBottom: -24 }}>
          <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 400 }}>
            {t("fee.explanationModalRate")}
          </Text>
        </div>
      </Modal>
      <ModalShiftCalculation
        key={"visibleModalCalculationAmount"}
        visible={visibleModalCalculationAmount}
        onCreate={() => setVisibleModalCalculationAmount(false)}
        onCancel={() => setVisibleModalCalculationAmount(false)}
        width={800}
      />
    </>
  );
};

export default CalculatorSubrogation;
