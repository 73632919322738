import React, { useState, useEffect } from 'react';
import { Card, Button, Form, Row, Modal } from 'antd';
import ProgressBySteps from './ProgressBySteps';
import { useTranslation } from 'react-i18next';
import questionIcon from '../../../assets/onboarding/questionIcon.svg';
import { isMobileOnly } from 'react-device-detect';
import { notificationGibobs } from '../../HerramientasVenta/reusableItems';
import AdditionalGuarantee from '../Pages/Modals/AdditionalGuarantee';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import env from '../../../environment';
import { useParams, useNavigate } from 'react-router-dom';

import { moveScroolToTop } from '../../../store/data/actions';
import { getAnalytics, logEvent } from 'firebase/analytics';
import TargetsForm1 from '../Pages/mortageForms/TargetsForm1';
import TaxCalculationForm2 from '../Pages/mortageForms/TaxCalculationForm2';
import RequiredFinancingForm3 from '../Pages/mortageForms/RequiredFinancingForm3';
import MortgageTermForm4 from '../Pages/mortageForms/MortgageTermForm4';
import DetailHoldersForm5 from '../Pages/mortageForms/DetailHoldersForm5';
import ResultAndViabilityGeneral from '../Pages/ViabilityAndResult/ResultAndViabilityGeneral';
import TargetsSubrogationForm1 from '../Pages/subrogationForms/TargetsSubrogationForm1';
import CurrentMortgageForm2 from '../Pages/subrogationForms/CurrentMortgageForm2';
import CurrentConditionsForm3 from '../Pages/subrogationForms/CurrentConditionsForm3';
import HavePendingForm4 from '../Pages/subrogationForms/HavePendingForm4';
import ResultSubrogation from '../Pages/ViabilityAndResult/ResultSubrogation';
import { useAnalytics } from 'use-analytics';
import { VIABILITYDATASUBROGATION } from '../../../store/viability/constants';

import dayjs from 'dayjs';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');
dayjs.locale('vi');

const DifferentSteps = ({
	stage,
	setPages,
	pages,
	type,
	dataOperation,
	refreshData,
	dataOperationAll,
	setDataOperation
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();;
	const { t } = useTranslation();
	const { operationId } = useParams();
	const userToken = useSelector((state) => state.auth.token);
	const [loading, setLoading] = useState(false);
	const [visibleModalExplination, setVisibleModalExplination] = useState(false);
	const [visibleModalGuarantee, setVisibleModalGuarantee] = useState(false);
	const [form] = Form.useForm();
	const moveTop = useSelector((state) => state.data.changePage);

	const [, forceUpdate] = useState({});
	const analytics = getAnalytics();
	const analyticsNew = useAnalytics();

	const stepsAnalyticsHp = [
		'onboarding2022.h_step1',
		'onboarding2022.h_step2',
		'onboarding2022.h_step3',
		'onboarding2022.h_step4',
		'onboarding2022.h_step5'
	];

	const stepsAnalyticsSub = [
		'onboarding2022.s_step1',
		'onboarding2022.s_step2',
		'onboarding2022.s_step3',
		'onboarding2022.s_step4',
		'onboarding2022.s_step5'

	];

	useEffect(() => {
		logEvent(analytics, 'screen_view', {
			screen_name: type === 'mortgage' ? stepsAnalyticsHp[stage] : stepsAnalyticsSub[stage]
		});

		analyticsNew.track('onboarding', {
			platform: 'gibobs',
			page: type === 'mortgage' ? stepsAnalyticsHp[stage] : stepsAnalyticsSub[stage],
			operationType: type
		});
	}, [stage]);

	useEffect(() => {
		forceUpdate({});
		//document.getElementsByClassName("ant-layout-content")[0].scrollTo(0, 0);

	}, [pages]);

	const openNotificationWithIcon = () => {
		notificationGibobs('error', t('m_full.errorTitle'), t('form.incompleteInformation'));
	};

	const validateMessages = {
		required: '${label}' + ' ' + t('form.formRequired'),
		types: {
			email: t('form.emailValidated'),
			number: '${label} is not a valid number!'
		},
		number: {
			range: t('form.maxBudgetNewRequestValidated') + '${min}'
		}
	};

	const renderPage = (type, pages, typeProp) => {
		const stepsData = {
			mortgage: [
				{
					title: 'onboarding.knowYourTarget',
					component: <TargetsForm1 />,
					steps: 5
				},
				{
					title: 'onboarding.calculateTaxes',
					component: <TaxCalculationForm2 />,
					modalText: 'onboarding.calculateTaxesPurchaseHome',
					modalTitle: t('onboarding.why2'),
					steps: 5
				},
				{
					title: 'onboarding.financingYouNeed',
					component: <RequiredFinancingForm3 dataOperation={dataOperation} />,
					modalText: 'onboarding.buyHouseNeed',
					modalTitle: t('onboarding.why3'),
					steps: 5
				},
				{
					title: 'onboarding.maximumMortgageTerm',
					component: <MortgageTermForm4 dataOperation={dataOperation} />,
					modalText: 'onboarding.maximumMortgageTermDescription',
					modalTitle: t('onboarding.why4'),
					steps: 5
				},
				{
					title: 'onboarding.detailHeadlines',
					component: (
						<DetailHoldersForm5
							participant={
								dataOperation && dataOperation.mortgage && dataOperation.mortgage.participants
							}
							form={form}
						/>
					),
					owners: true,
					steps: 5
				},
				{
					component: (
						<ResultAndViabilityGeneral
							dataOperation={dataOperation}
							refreshData={() => refreshData()}
						/>
					)
				}
			],
			subrogation: [
				{
					title: 'onboarding.knowYourTarget',
					component: <TargetsSubrogationForm1 />,
					steps: 5
				},
				{
					title: 'onboarding.yourCurrentMortgage',
					component: <CurrentMortgageForm2 dataOperation={dataOperation} />,
					steps: 5
				},
				{
					title: 'onboarding.pendingstep3',
					component: <HavePendingForm4 />,
					steps: 5
				},
				{
					title: 'loan.detailsLoanCondictions',
					component: (
						<CurrentConditionsForm3
							interestData={
								dataOperation && dataOperation.subrogation && dataOperation.subrogation.interest
							}
							dataOperation={dataOperation}
							form={form}
						/>
					),
					steps: 5
				},
				{
					title: 'onboarding.detailHeadlinesSub',
					component: (
						<DetailHoldersForm5
							participant={
								dataOperation && dataOperation.mortgage && dataOperation.mortgage.participants
							}
							subrogation={true}
							form={form}
						/>
					),
					owners: true,
					steps: 5
				},

				{
					component: (
						<ResultSubrogation
							dataOperation={dataOperation}
							dataOperationAll={dataOperationAll}
							refreshData={() => refreshData()}
						/>
					)
				}
			]
		};

		if (typeProp === 'component') {
			return stepsData[type][pages].component;
		}

		if (typeProp === 'modalTitle') {
			return stepsData[type][pages].modalTitle;
		}

		if (typeProp === 'modalText') {
			return stepsData[type][pages].modalText;
		}

		if (typeProp === 'steps') {
			return stepsData[type][pages].steps;
		}
		if (typeProp === 'title') {
			return stepsData[type][pages].title;
		}
		if (typeProp === 'owners') {
			return stepsData[type][pages].owners;
		}
	};

	const onFinishForms = (values) => {
		const obj = {};

		for (let clave in values) {
			if (clave !== 'dateEndMortgage') {
				if (typeof values[clave] === 'string') {
					if (!isNaN(values[clave]?.replace(/,/g, '.'))) {
						values[clave] = Number(values[clave].replace(/,/g, '.'));
					}
				}
			}
			if (clave === 'dateEndMortgage') {
				values.dateEndMortgage = dayjs(dayjs(values.dateEndMortgage).format('YYYY/MM/15'));
			}
		}

		if (renderPage(type, pages, 'owners') !== undefined) {
			const owner1 = {
				owner1: {
					activity: values.activity1,
					seniority: Number(values.seniority1),
					netIncomes: values.netIncomes1,
					loanPayment: values.loanPayment1 ?? 0,
					cityzenship: 'ES'
				}
			};
			const owner2 = {
				owner2: {
					activity: values.activity2,
					seniority: Number(values.seniority2),
					netIncomes: values.netIncomes2,
					loanPayment: values.loanPayment2 ?? 0
				}
			};
			Object.assign(obj, owner1);
			if (values.activity2 !== undefined) {
				Object.assign(obj, owner2);
			}
		} else {
			Object.assign(obj, values);
		}
		
		axios
			.post(`${env.api.url}/mortgages/${type}/step${stage + 1}/${operationId}`, obj, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setLoading(true);
				if (response.data.success) {
					refreshData();
					isMobileOnly && dispatch(moveScroolToTop(!moveTop));

					if (stage < 4) {
						setLoading(false);
						setLoading(false);
						setPages(stage + 1);
					} else if (stage === 4 && type === 'subrogation') {
						axios
							.post(
								`${env.api.url}/v1/mortgages/data/update/${operationId}`,
								{
									key: 'basicDeclarativesCompleted',
									participant: 'process',
									value: true
								},
								{
									headers: { Authorization: `Bearer ${userToken}` }
								}
							)
							.then((response) => {
								if (response.data.success) {
									setPages(stage + 1);
									dispatch({
										type: VIABILITYDATASUBROGATION,
										payload: undefined
									});
								}
							});
					} else {
						axios
							.post(
								`${env.api.url}/mortgages/pre-viability/checks`,
								{
									operationId: operationId
								},
								{
									headers: { Authorization: `Bearer ${userToken}` }
								}
							)
							.then((response) => {
								if (response.data.data.additionalGuarantee) {
									setVisibleModalGuarantee(response.data.data.additionalGuarantee);
									setLoading(false);
								} else {
									sendAdditionalGuarantee(false); // by default not question
								}
							});
					}


				}
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const sendAdditionalGuarantee = (values) => {
		axios
			.post(
				`${env.api.url}/mortgages/mortgage/step5extra/${operationId}`,
				{ canHavePaidOwnedHousesOrCanHaveGuarantors: values },
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				setLoading(true);
				if (response.data.success) {
					axios
						.post(
							`${env.api.url}/v1/mortgages/data/update/${operationId}`,
							{
								key: 'basicDeclarativesCompleted',
								participant: 'process',
								value: true
							},
							{
								headers: { Authorization: `Bearer ${userToken}` }
							}
						)
						.then(() => {
							//dispatch(setDataViabilityForMortgage(operationId, userToken));
							setVisibleModalGuarantee(false);
							setPages(stage + 1);
						});
				}
			})
			.catch((error) => {
				console.log(error)
			});
	};

	return (stage === 5 && type === 'mortgage') || (stage === 5 && type === 'subrogation') ? (
		renderPage(type, pages, 'component')
	) : (
		<div
			className={!isMobileOnly && 'desktop-background'}
			style={{
				fontFamily: 'Inter',
				marginBottom: isMobileOnly ? 200 : 200,
				// marginTop: 20,
				height:
					!isMobileOnly &&
					(stage === 2 && type === 'subrogation'
						? 'calc(100vh + 220px)'
						: ((stage !== 4 && type === 'mortgage') || (stage !== 2 && type === 'subrogation')) &&
						'calc(100vh + 50px)')
			}}>
			{!isMobileOnly && (
				<div style={{ textAlign: 'left', padding: '40px 40px 24px' }}>
					<div style={{ color: '#02C3CD', fontSize: 16, fontWeight: 600 }}>
						{type === 'subrogation'
							? t('onboarding.improveCurrentMortgage')
							: t('onboarding.findBestMortgage')}
					</div>
				</div>
			)}
			<div
				style={{
					alignSelf: 'center',
					width: !isMobileOnly && stage === 4 ? '60%' : isMobileOnly ? "100%" : '28%',
					margin: 'auto',
					marginBottom: (stage === 4 || stage === 5) && '10%',
					marginTop: isMobileOnly ? 16 : 0

				}}>
				<ProgressBySteps steps={renderPage(type, pages, 'steps')} stage={stage + 1} />
				<div
					className="title-stepper-component"
					style={{
						marginTop: !isMobileOnly && 40
					}}>
					<div className="title">
						{isMobileOnly ? (
							<span
								dangerouslySetInnerHTML={{
									__html: t(renderPage(type, pages, 'title')).replace(/\\n/, '<br>')
								}}></span>
						) : (
							t(renderPage(type, pages, 'title')).replace(/\\n/, ' ')
						)}

						{renderPage(type, pages, 'modalText') !== undefined && (
							<img
								style={{ marginLeft: 8, cursor: 'pointer' }}
								src={questionIcon}
								alt="questionIcon"
								onClick={() => setVisibleModalExplination(true)}
							/>
						)}
					</div>
				</div>
				<div>
					<Form
						className={'gb-form-profile-headlines-mobile gb-form-separate-24'}
						onFinishFailed={openNotificationWithIcon}
						scrollToFirstError={true}
						form={form}
						name="nest-messages"
						onFinish={onFinishForms}
						layout="vertical"
						validateMessages={validateMessages}
						initialValues={
							dataOperation === undefined
								? { interest: 'fixed' }
								: type === 'mortgage' &&
									dataOperation &&
									dataOperation.mortgage &&
									dataOperation.owner1 &&
									dataOperation.owner2
									? {
										...dataOperation['mortgage'],
										...dataOperation.owner1,
										participants: undefined,
										activity1: dataOperation.owner1.activity,
										seniority1: dataOperation.owner1.seniority,
										netIncomes1: dataOperation.owner1.netIncomes,
										loanPayment1: dataOperation.owner1.loanPayment ?? 0,
										activity2: dataOperation.owner2.activity,
										seniority2: dataOperation.owner2.seniority,
										netIncomes2: dataOperation.owner2.netIncomes,
										loanPayment2: dataOperation.owner2.loanPayment ?? 0,
										cityzenship: dataOperation.owner1.cityzenship
											? dataOperation.owner1.cityzenship
											: 'ES'
									}
									: type === 'mortgage' &&
										dataOperation &&
										dataOperation.mortgage &&
										dataOperation.owner1
										? {
											...dataOperation['mortgage'],
											...dataOperation.owner1,
											participants: undefined,
											activity1: dataOperation.owner1.activity,
											seniority1: dataOperation.owner1.seniority,
											netIncomes1: dataOperation.owner1.netIncomes,
											loanPayment1: dataOperation.owner1.loanPayment ?? 0,
											age: dataOperation?.owner1?.age,
											cityzenship: dataOperation.owner1.cityzenship
												? dataOperation.owner1.cityzenship
												: 'ES'
										}
										: type === 'subrogation' &&
										dataOperation.subrogation && {
											...dataOperation['subrogation'],
											finalTypeVariable: 'variable',
											initialTypeVariable: 'fixed',
											costOtherProducts: 0,
											dateEndMortgage: dataOperation.subrogation.dateEndMortgage
												? dayjs(dataOperation.subrogation.dateEndMortgage)
												: undefined,
											dateSign: dataOperation.subrogation.dateSign
												? dayjs(dataOperation.subrogation.dateSign)
												: undefined
										}
						}

					>
						<Row
							gutter={isMobileOnly ? 32 : 24}
							style={{
								marginLeft: 'auto',
								marginRight: 'auto'
							}}>
							{renderPage(type, pages, 'component')}
						</Row>

						{isMobileOnly && (
							<Card
								className="card-button-style-onboading"
								style={{
									left: 0,
									marginBottom: -2,
									backgroundColor: '#FAFEFE',
									height: 80
								}}>
								{pages !== 0 ? (
									<Button
										id="gb-mortgage-buy-situation-mobile"
										className="button-secundary-gibobs-inmo"
										type="dashed"
										style={{
											fontSize: '14px',
											width: '48%',
											height: 48,
											fontWeight: 600,
											marginRight: '4%'
										}}
										onClick={() => {
											setDataOperation(undefined);
											setPages(stage - 1);
											refreshData();
										}}>
										{t('form.previous')}
									</Button>
								) : (
									<Button
										id="gb-mortgage-buy-situation-mobile"
										className="button-secundary-gibobs-inmo"
										type="dashed"
										style={{
											fontSize: '14px',
											width: '48%',
											height: 48,
											fontWeight: 600,
											marginRight: '4%'
										}}
										onClick={() => navigate('/dashboard-client')}>
										{t('form.leaveButton')}
									</Button>
								)}
								<Form.Item shouldUpdate style={{ width: '48%', margin: 0 }}>
									{() => (
										<Button
											id="gb-mortgage-buy-situation-mobile"
											className="button-primary-gibobs-inmo"
											type="primary"
											style={{
												fontSize: '14px',
												width: '100%',
												height: 48,
												fontWeight: 600
											}}
											loading={loading}
											htmlType="submit"
											disabled={
												stage !== 3
													? Object.entries(form.getFieldsValue(false)).some(
														([key, value]) => {
															return !key.includes('loanPayment') && value === undefined
														}
													) ||
													!!form.getFieldsError().filter(({ errors }) => errors.length).length
													: false
											}>
											{t('viability.continue')}
										</Button>
									)}
								</Form.Item>
							</Card>
						)}
						{!isMobileOnly && (
							<div
								style={{
									display: 'flex',
									marginTop: 40,
									justifyContent: 'center'
								}}>
								{pages !== 0 ? (
									<Button
										id="gb-mortgage-buy-situation-mobile"
										className="button-secundary-gibobs-inmo"
										type="dashed"
										style={{
											fontSize: '14px',
											width: 120,
											height: 40,
											fontWeight: 600,
											marginRight: '4%'
										}}
										onClick={() => {
											setDataOperation(undefined);
											setPages(stage - 1);
											refreshData();
										}}>
										{t('form.back')}
									</Button>
								) : (
									<Button
										id="gb-mortgage-buy-situation-mobile"
										className="button-secundary-gibobs-inmo"
										type="dashed"
										style={{
											fontSize: '14px',
											width: 120,
											height: 40,
											fontWeight: 600,
											marginRight: '4%'
										}}
										onClick={() => navigate('/dashboard-client')}>
										{t('form.leaveButton')}
									</Button>
								)}

								<Form.Item shouldUpdate style={{ width: 120, margin: 0 }}>
									{() => (
										<Button
											id="gb-mortgage-buy-situation-mobile"
											className="button-primary-gibobs-inmo"
											type="primary"
											style={{
												fontSize: '14px',
												width: '100%',
												height: 40,
												fontWeight: 600
											}}
											loading={loading}
											htmlType="submit"
											disabled={
												stage !== 3
													? Object.entries(form.getFieldsValue(false)).some(
														([key, value]) => {
															return !key.includes('loanPayment') && value === undefined
														}
													) ||
													!!form.getFieldsError().filter(({ errors }) => errors.length).length
													: false
											}>
											{t('viability.continue')}
										</Button>
									)}
								</Form.Item>
							</div>
						)}
					</Form>
				</div>
				<Modal
					centered
					title={renderPage(type, pages, 'modalTitle')}
					className="modal-onboarding-stepper"
					width={500}
					cancelButtonProps={{
						className: 'button-secundary-gibobs',
						style: { display: 'none' }
					}}
					okButtonProps={{
						className: 'button-primari-gibobs',
						style: { display: 'none' }
					}}
					cancelText={t('form.back')}
					open={visibleModalExplination}
					onCancel={() => {
						setVisibleModalExplination(!visibleModalExplination);
					}}
					destroyOnClose={true}
					closable={true}>
					{t(renderPage(type, pages, 'modalText'))}
				</Modal>

				<AdditionalGuarantee
					key={'visibleModalGuarantee'}
					visible={visibleModalGuarantee}
					onCreate={(e) => sendAdditionalGuarantee(e)}
					onCancel={() => setVisibleModalGuarantee(false)}
					width={800}
				/>
			</div>
		</div>
	);
};

export default DifferentSteps;
