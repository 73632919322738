import React, { useState, useEffect, useRef } from 'react';
import { Layout, Card, Button, Form, Modal, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import env from '../../../../environment';
import ModalWithoutButtons from '../Modals/ModalWithoutButtons';
import { notificationGibobs } from '../../../HerramientasVenta/reusableItems';
import { RightOutlined } from '@ant-design/icons';
import CreateManualForm from '../CreateManualForm';
import leftArrowIcon from '../../../../assets/icons/leftArrowIcon.svg';
import { isMobileOnly } from 'react-device-detect';
import { LoadingOutlined } from '@ant-design/icons';

import numeral from 'numeral';
import { getAnalytics, logEvent } from 'firebase/analytics';

import dayjs from 'dayjs';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');
dayjs.locale('vi');

const LoanPageFormMobile = ({}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();;
	const [form] = Form.useForm();
	let { bankId, loanId } = useParams();
	const userToken = useSelector((state) => state.auth.token);
	const [typeLoan, setTypeLoan] = useState('fixed');
	const [valueCuota, setValueCuota] = useState(undefined);
	const [modalTypeOfInterestVariable, setModalTypeOfInterestVariable] = useState(false);
	const [modalTypeOfInterest, setModalTypeOfInterest] = useState(false);
	const [modalLinks, setModalLinks] = useState(false);
	const [modalQuota, setModalQuota] = useState(false);
	const [euriborValue, setEuriborValue] = useState();
	const [spreadValue, setSpreadValue] = useState(0);
	const [, setDateEuribor] = useState();
	const [modalHousePrice, setModalHousePrice] = useState(false);
	const [modalDataCorrect, setModalDataCorrect] = useState(false);
	const [modalSomenthingIsWrong, setModalSomenthingIsWrong] = useState(false);
	const [modalDateFinishCorrect, setModalDateFinishCorrect] = useState(false);
	const [modalFinishProcess, setModalFinishProcess] = useState(false);
	const [modalDebtAndPrincipalOutstanding, setModalDebtAndPrincipalOutstanding] = useState(false);
	const [button, setButton] = useState('');
	const [loan, setLoan] = useState(undefined);
	const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

	const simulationId = useSelector((state) => state.calculators.simulationId);
	const previousPage = useSelector((state) => state.calculators.previousPage);
	const amortizationCalculatorData = useSelector(
		(state) => state.calculators.amortizationCalculatorData
	);
	const analytics = getAnalytics();
	const refTitle = useRef(null);

	useEffect(() => {
		if (loanId !== undefined) {
			getLoan();
		}
		if (loanId === undefined && amortizationCalculatorData) {
			setLoan(amortizationCalculatorData);
		}
	}, []);

	useEffect(() => {
		if (loanId === undefined) {
			document.getElementsByClassName('ant-layout-content')[0].scrollTo(0, 0);
		}
	}, [loanId, history]);

	useEffect(() => {
		if (loan !== undefined) {
			setTypeLoan(loan && loan.rateType);
			setValueCuota(loan && loan.quota ? loan.quota : undefined);

			if (loan && loan.rateType === 'variable') {
				setEuriborValue(loan && loan.index);
				setSpreadValue(loan && loan.spread);
			}
		}
	}, [loan]);

	const getLoan = () => {
		axios
			.get(`${env.api.url}/v1/loans/get-loan/${loanId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setLoan(response.data.data.loan); // si el prestamo es automatico hacer la llamada cada 3s
				// if (
				//   response.data.data.loan.rateType === "fixed" ||
				//   response.data.data.loan.rateType === "Fijo"
				// ) {
				//   setTypeCurrent(false);
				// } else {
				//   setTypeCurrent(true);
				// }
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const onFinishFailed = () => {
		notificationGibobs('error', t('loan.formErrorCreateTitle'), t('loan.formErrorCreate'));
	};

	const calculateQuote = (values) => {
		const objValues = {
			indexType: 'euribor',
			rateType: typeLoan,
			amount: values.amount,
			term: Number(values.term),
			rate: typeLoan === 'variable' ? spreadValue : values.rate.toString().replace(/,/g, '.')
		};
		if (typeLoan === 'variable') {
			objValues.euribor = values.index;
		}
		axios
			.post(`${env.api.url}/v1/loans/calculate-monthly-quote`, objValues, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				if (response.data.success) {
					if (response.data.data.quota > 0) {
						setValueCuota(response.data.data.quota);
					}
					if (response.data.data.quota < 0) {
						setModalSomenthingIsWrong(true);
					}
				}
				if (!response.data.success) {
					setModalSomenthingIsWrong(true);
				}
			})
			.catch(() => {
				setModalSomenthingIsWrong(true);
			});
	};

	const verificatedData = (values, create) => {
		create === false ? setModalDataCorrect(values) : createManual(values);
	};

	const createManual = (values) => {
		values.signed = dayjs(values.signed);
		values.finish = dayjs(values.finish);
		values.amount = Number(values.amount);

		if (typeLoan === 'fixed') {
			values.rate = Number(values.rate.toString().replace(/,/g, '.'));
		}

		if (
			values.initialHousePrice !== undefined &&
			values.initialHousePrice !== 0 &&
			values.initialHousePrice !== null
		) {
			values.initialHousePrice = Number(values.initialHousePrice);
		} else {
			delete values.initialHousePrice;
		}
		if (
			values.initialAmount !== undefined &&
			values.initialAmount !== 0 &&
			values.initialAmount !== null
		) {
			values.initialAmount = Number(values.initialAmount);
		} else {
			delete values.initialAmount;
		}
		if (values.spread !== undefined) {
			values.spread = Number(spreadValue);
		}
		if (values.term !== undefined) {
			values.term = Number(values.term);
		}
		if (values.totalEntailments !== undefined) {
			values.totalEntailments = Number(values.totalEntailments);
		} else {
			values.totalEntailments = 0;
		}

		const objValues = {
			indexType: 'euribor',
			rateType: typeLoan,
			...values
		};
		if (loan?.status === 'PENDING_USER') {
			objValues.status = 'IN_PROGRESS';
		}
		if (valueCuota !== undefined) {
			objValues.quota = valueCuota;
			objValues.type = 'mortgage';
		}
		if (bankId !== 'undefined' && valueCuota !== undefined) {
			objValues.bankId = bankId;
		}

		if (typeLoan === 'variable') {
			objValues.rate = spreadValue;
		}

		if (simulationId && loanId === undefined) {
			objValues.simulationId = simulationId;
		}

		objValues.version = '2023.v1';

		if (values.initialHousePrice || values.initialAmount) {
			logEvent(analytics, 'screen_view', {
				screen_name: 'completed.loan.optionalInputs'
			});
		}
		if (values.amount > 0 && values.initialAmount > 0 && values.initialAmount < values.amount) {
			setModalDebtAndPrincipalOutstanding(true);
		} else {
			axios
				.post(
					loanId !== undefined
						? `${env.api.url}/v1/loans/update/${loanId}`
						: `${env.api.url}/v1/loans/create-manual`,
					objValues,
					{
						headers: { Authorization: `Bearer ${userToken}` }
					}
				)
				.then((response) => {
					if (response.data.success) {
						logEvent(analytics, 'screen_view', {
							screen_name: 'created.loan.completed'
						});
						logEvent(analytics, 'screen_view', {
							screen_name: 'click.manual.form'
						});
						 navigate(
							`/my-financial-profile/credits/all-detail-loan/${response.data.data.loan.id}`
						);
					}
				})
				.catch((error) => {
					console.log(error)
				});
		}
	};

	const validateMessages = {
		required: '${label}' + ' ' + t('form.formRequired')
	};

	const formCreate = (
		<Form
			className={'gb-form-profile-headlines-mobile'}
			form={form}
			name="loan"
			layout="vertical"
			onFinish={
				valueCuota !== undefined && button === 'Yes'
					? (values) => verificatedData(values, true)
					: valueCuota !== undefined && button === 'No'
					? (values) => verificatedData(values, false)
					: calculateQuote
			}
			onFinishFailed={onFinishFailed}
			validateMessages={validateMessages}
			style={{ marginTop: 16, width: '100%' }}
			initialValues={{
				finish: loan && loan.finish ? dayjs(loan.finish) : undefined,
				signed: loan && loan.signed ? dayjs(loan.signed) : undefined,
				amount: loan && loan.amount,
				initialAmount: loan && loan.initialAmount,
				totalEntailments: loan && loan.totalEntailments,
				initialHousePrice: loan && loan.initialHousePrice,
				rateType: loan && loan.rateType,
				term: loan && loan.term,
				reviewPeriod: loan && loan.reviewPeriod,
				index: loan && loan.index,
				rate: loan && loan.rate,
				quota: loan && loan.quota,
				spread: loan && loan.spread === undefined ? loan?.rate : loan?.spread
			}}>
			<CreateManualForm
				setModalHousePrice={(e) => setModalHousePrice(e)}
				setModalLinks={(e) => setModalLinks(e)}
				setModalQuota={(e) => setModalQuota(e)}
				setModalTypeOfInterest={(e) => setModalTypeOfInterest(e)}
				setModalTypeOfInterestVariable={(e) => setModalTypeOfInterestVariable(e)}
				setTypeLoan={(e) => setTypeLoan(e)}
				setSpreadValue={(e) => setSpreadValue(e)}
				setModalDateFinishCorrect={(e) => setModalDateFinishCorrect(e)}
				setValueCuota={(e) => setValueCuota(e)}
				setModalDebtAndPrincipalOutstanding={(e) => setModalDebtAndPrincipalOutstanding(e)}
				typeLoan={typeLoan}
				spreadValue={spreadValue}
				valueCuota={valueCuota}
				setButton={(e) => setButton(e)}
				form={form}
				euriborValue={euriborValue}
				setEuriborValue={(e) => setEuriborValue(e)}
				setDateEuribor={(e) => setDateEuribor(e)}
				loan={loan && loan}
			/>
		</Form>
	);

	const handlePreviusPage = () => {
		if (loan?.status === 'PENDING_USER') {
			navigate(`/my-financial-profile/credits/list`);
		} else if (loanId !== undefined) {
			navigate(`/my-financial-profile/credits/all-detail-loan/${loanId}`);
		} else {
			setModalFinishProcess(true);
		}
	};

	return (
        (<Layout style={{ padding: isMobileOnly ? 16 : 40 }} ref={refTitle}>
            <div
				style={{
					marginBottom: 8,
					display: 'flex',
					alignItems: 'center',
					marginBottom: isMobileOnly ? 12 : 24
				}}>
				<div style={{ marginRight: 16, cursor: 'pointer' }} onClick={() => handlePreviusPage()}>
					<img src={leftArrowIcon} alt="leftArrowIcon" height={14} />
				</div>
				<div style={{ fontSize: 16, fontWeight: 600, color: '#2F4858' }}>
					{loanId === undefined
						? t('loan.completeMortgageInformation')
						: t('loan.modifyMortgageData')}
				</div>
			</div>
            <div
				style={{
					width: '100%',
					marginTop: 16,
					marginBottom: !isMobileOnly && 100
				}}>
				{!isMobileOnly && (
					<Card className="gb-card">
						<div className="title-loan-form">{t('loan.improveMortgageTitle')}</div>
						{(loanId !== undefined || amortizationCalculatorData) &&
							(loan !== undefined ? formCreate : <Spin indicator={antIcon} className="spin" />)}
						{bankId !== undefined && (previousPage === 'new-loan' || !previousPage) && formCreate}
					</Card>
				)}
				{isMobileOnly && (
					<>
						<div className="title-loan-form" style={{ fontSize: 14, marginTop: 0 }}>
							{t('loan.improveMortgageTitle')}
						</div>
						{(loanId !== undefined || amortizationCalculatorData) &&
							(loan !== undefined ? formCreate : <Spin indicator={antIcon} className="spin" />)}
						{bankId !== undefined && (previousPage === 'new-loan' || !previousPage) && formCreate}
					</>
				)}
			</div>
            <ModalWithoutButtons
				key={'modalTypeOfInterest'}
				visible={modalTypeOfInterest}
				onCreate={() => setModalTypeOfInterest(false)}
				onCancel={() => setModalTypeOfInterest(false)}
				title={'loan.tinTitle'}
				text={t('loan.tinDescription')}
			/>
            <ModalWithoutButtons
				key={'modalLinks'}
				visible={modalLinks}
				onCreate={() => setModalLinks(false)}
				onCancel={() => setModalLinks(false)}
				title={'loan.linksModalTitle'}
				text={t('loan.linksDescription')}
			/>
            <ModalWithoutButtons
				key={'modalQuota'}
				visible={modalQuota}
				onCreate={() => setModalQuota(false)}
				onCancel={() => setModalQuota(false)}
				title={'loan.calculateQuotaModalTitle'}
				text={t('loan.calculateQuotaModalDescription')}
			/>
            <ModalWithoutButtons
				key={'modalTypeOfInterestVariable'}
				visible={modalTypeOfInterestVariable}
				onCreate={() => setModalTypeOfInterestVariable(false)}
				onCancel={() => setModalTypeOfInterestVariable(false)}
				title={'loan.whatIsYourInterestRate'}
				text={t('loan.yourInterestRateDescription')}
			/>
            <ModalWithoutButtons
				key={'modalSomenthingIsWrong'}
				visible={modalSomenthingIsWrong}
				onCreate={() => setModalSomenthingIsWrong(false)}
				onCancel={() => setModalSomenthingIsWrong(false)}
				title={'loan.somethingIsNotRight'}
				text={t('loan.somethingIsNotRightDescription')}
			/>
            <ModalWithoutButtons
				key={'modalDateFinishCorrect'}
				visible={modalDateFinishCorrect}
				onCreate={() => setModalDateFinishCorrect(false)}
				onCancel={() => setModalDateFinishCorrect(false)}
				title={'loan.modalDateCorrectTitle'}
				text={t('loan.modalDateCorrectDescription')}
			/>
            <Modal
				className="ModalCommon"
				key={'modalHousePrice'}
				open={modalHousePrice}
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { width: '46%', height: 40 }
				}}
				okButtonProps={{
					className: 'button-primari-gibobs',
					style: { width: '46%', height: 40 }
				}}
				title={t('loan.housingPriceTitleModal', {
					percentage: numeral(modalHousePrice).format('0')
				})}
				width={480}
				onCancel={() => setModalHousePrice(false)}
				onOk={() => setModalHousePrice(false)}
				okText={t('loan.thisIsCorrect')}
				cancelText={t('loan.modifyButton')}
				centered>
				<div
					style={{ marginTop: -30, color: '#748EA0' }}
					dangerouslySetInnerHTML={{
						__html: t('loan.housingPriceDescriptionModal', {
							percentage: numeral(modalHousePrice).format('0')
						})
					}}
				/>
			</Modal>
            <Modal
				className="ModalCommon"
				key={'modalFinishProcess'}
				open={modalFinishProcess}
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { width: '46%', height: 40 }
				}}
				okButtonProps={{
					className: 'button-primari-gibobs',
					style: { width: '46%', height: 40 }
				}}
				title={t('¿Quieres salir de esta pantalla?')}
				width={480}
				onCancel={() => setModalFinishProcess(false)}
				onOk={() => navigate('/my-financial-profile/credits/add/loan')}
				okText={t('offers.accept')}
				cancelText={t('offers.cancel')}
				centered>
				<div
					style={{ marginTop: -30, color: '#748EA0' }}
					dangerouslySetInnerHTML={{
						__html: t(
							'Si abandonas esta pantalla sin guardar, los datos de tu hipoteca se perderán.'
						)
					}}
				/>
			</Modal>
            <Modal
				className="modal-without-buttons-loans"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { width: '100%', height: 40 }
				}}
				okButtonProps={{
					className: 'button-primari-gibobs',
					style: { width: '96%', height: 40 }
				}}
				open={modalDataCorrect}
				title={t('loan.isTheDataCorrect')}
				width={480}
				destroyOnClose={true}
				onCreate={() => setModalDataCorrect(false)}
				onCancel={() => setModalDataCorrect(false)}
				okText={t('loan.thisIsCorrect')}
				cancelText={t('loan.modifyButton')}
				centered>
				<div
					dangerouslySetInnerHTML={{
						__html: t('loan.isTheDataCorrectDescription')
					}}
				/>
				<Button
					type="primary"
					className="button-primari-gibobs"
					style={{ width: '100%', height: 40, marginTop: 32 }}
					onClick={() => {
						setValueCuota(undefined);
						setModalDataCorrect(false);
					}}>
					{t('loan.editMyData')}
				</Button>
				<div
					style={{
						marginTop: 16,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						cursor: 'pointer'
					}}
					onClick={() => {
						verificatedData(modalDataCorrect, true);
						setModalDataCorrect(false);
					}}>
					<div style={{ color: '#02C3CD', fontWeight: 500 }}>{t('loan.iDontCareContinue')}</div>
					<RightOutlined style={{ fontSize: 12, color: '#02C3CD', marginLeft: 11 }} />
				</div>
			</Modal>
            <Modal
				className="modal-without-buttons-loans"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { width: '100%', height: 40 }
				}}
				okButtonProps={{
					className: 'button-primari-gibobs',
					style: { width: '96%', height: 40 }
				}}
				open={modalDebtAndPrincipalOutstanding}
				title={t('loan.modalDebtAndPrincipalOutstandingTitle')}
				width={480}
				destroyOnClose={true}
				onCreate={() => setModalDebtAndPrincipalOutstanding(false)}
				onCancel={() => setModalDebtAndPrincipalOutstanding(false)}
				okText={t('loan.thisIsCorrect')}
				cancelText={t('loan.modifyButton')}
				centered>
				<div
					dangerouslySetInnerHTML={{
						__html: t('loan.modalDebtAndPrincipalOutstandingDescription')
					}}
				/>
				<Button
					type="primary"
					className="button-primari-gibobs"
					style={{ width: '100%', height: 40, marginTop: 32 }}
					onClick={() => {
						setValueCuota(undefined);
						setModalDebtAndPrincipalOutstanding(false);
					}}>
					{t('loan.modifyButton')}
				</Button>
			</Modal>
        </Layout>)
    );
};

export default LoanPageFormMobile;
