import React, { useState, useEffect } from "react";
import {
  Card,
  Layout,
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  Spin,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import env from "../../environment";
import { useSelector, useDispatch } from "react-redux";
import { isMobile } from "react-device-detect";
import { callAct } from "../../store/auth/actions";
import { useParams, useNavigate } from "react-router-dom";
import { notificationGibobs } from "../HerramientasVenta/reusableItems";
import DownOutlinedMobile from "../../assets/icons/DownOutlinedMobile.svg";

const { Option } = Select;

function RequestInformation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();;
  const { t } = useTranslation();
  let { contactReason } = useParams();
  const [dataPersonal, setDataPersonal] = useState(null);
  const userToken = useSelector((state) => state.auth.token);
  const changeCallAct = useSelector((state) => state.auth.callAct);
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

  const nameContactReason = {
    "credit-lines": "Líneas de crédito",
    "business-mortgage": "Hipotecas de empresa",
    "industrial-solar-project": "Proyecto solar",
  };

  useEffect(() => {
    dataPersonalOperation();
  }, [changeCallAct]);

  const dataPersonalOperation = () => {
    axios
      .get(`${env.api.url}/v1/user/profile`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setDataPersonal(response.data.data.profile);
        dispatch(callAct(false));
      });
  };

  const openNotificationWithIcon = () => {
    notificationGibobs(
      "error",
      t("m_full.errorTitle"),
      t("form.incompleteInformation")
    );
  };

  const enviarNuevoMensaje = (values) => {
    axios
      .post(`${env.api.url}/v1/user/contact-email`, values, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then(() => {
        notificationGibobs(
          "success",
          t("m_common.requestSuccessTitle"),
          t("form.formSuccessful")
        );
        navigate("/products-business");
      });
  };

  const formMortgage = (
    <>
      <Form
        className={
          isMobile
            ? "gb-form-profile-headlines-mobile"
            : "gb-form-common-new-style"
        }
        onFinishFailed={openNotificationWithIcon}
        scrollToFirstError={true}
        name="nest-messages"
        onFinish={enviarNuevoMensaje}
        layout="vertical"
        initialValues={{
          name: dataPersonal ? dataPersonal.name : "",
          surname: dataPersonal ? dataPersonal.surname : "",
          email: dataPersonal ? dataPersonal.email : "",
          phone: dataPersonal ? dataPersonal.phone : "",
          companyName: dataPersonal ? dataPersonal.companyName : "",
          contactTime: dataPersonal ? dataPersonal.contactTime : "",
          comments: "",
          contactReason: nameContactReason[contactReason],
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: isMobile && "16px",
            alignItems: "center",
            marginBottom: !isMobile && "40px",
          }}
        >
          <div className="typography-titles">
            {t("menuComponent.productsNav")}
          </div>
          <div className="typography-blue-text">
            {t("form.titleDataApplicant")}
          </div>
        </div>
        <Row
          gutter={24}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: isMobile && "150px",
          }}
        >
          <Col lg={6} xs={24}>
            <Form.Item name="contactReason" label={t("form.financialProduct")}>
              <Select
                suffixIcon={
                  <img src={DownOutlinedMobile} alt="DownOutlinedMobile" />
                }
                style={{ width: "100%" }}
              >
                <Option value="Préstamo promotor">
                  {t("productsComponent.promoterLoan")}
                </Option>
                <Option value="Líneas de crédito">
                  {t("productsComponent.creditLines")}
                </Option>
                <Option value="Hipotecas de empresa">
                  {t("productsComponent.businessMortgages")}
                </Option>
                <Option value="Proyecto solar">
                  {t("productsComponent.solarProject")}
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item name="contactTime" label={t("form.contactTime")}>
              <Select
                suffixIcon={
                  <img src={DownOutlinedMobile} alt="DownOutlinedMobile" />
                }
                style={{ width: "100%" }}
              >
                <Option value="morning">{t("form.morning")}</Option>
                <Option value="afternoon">{t("form.afternoon")}</Option>
                <Option value="evening">{t("form.evening")}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item
              name="name"
              label={t("form.name")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                placeholder={t("form.name")}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item
              name="surname"
              label={t("form.surname")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                placeholder={t("form.surname")}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item
              name="phone"
              label={t("form.phone")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                placeholder={t("form.phone")}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item
              name="email"
              label={t("form.email")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                placeholder={t("form.email")}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item
              name="companyName"
              label={t("form.companyName")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                placeholder={t("form.companyName")}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item
              name="positionInCompany"
              label={t("form.positionInCompany")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                placeholder={t("form.positionInCompany")}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>

          <Col lg={24} xs={24}>
            <Form.Item
              name="comments"
              label={t("form.descriptionInmo")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea
                style={{
                  height: "60px",
                  borderRadius: "4px",
                  fontSize: "12px",
                  width: "100%",
                }}
                type="text"
                placeholder={t("form.details")}
              />
            </Form.Item>
          </Col>
        </Row>
        {isMobile && (
          <Card
            className="cardButtonStyle"
            style={{ left: 0, marginBottom: -2 }}
          >
            <Button
              id="gb-mortgage-buy-situation-mobile"
              className="button-primari-gibobs"
              type="primary"
              style={{
                fontSize: 16,
                width: "100%",
                height: 48,
                fontWeight: 600,
              }}
              htmlType="submit"
            >
              {t("form.send")}
            </Button>
          </Card>
        )}
        {!isMobile && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 60,
            }}
          >
            <Button
              id="gb-mortgage-buy-situation-mobile"
              className="button-primari-gibobs"
              type="primary"
              style={{
                fontSize: "14px",
                width: 120,
                height: 40,
                fontWeight: 600,
              }}
              htmlType="submit"
            >
              {t("form.send")}
            </Button>
          </div>
        )}
      </Form>
    </>
  );

  return (
    <Layout>
      {!isMobile &&
        (dataPersonal !== null ? (
          <Card
            className="CardStyleRealeState"
            style={{
              marginLeft: "40px",
              width: "calc(100% - 80px)",
              marginTop: 40,
              marginBottom: "100px",
            }}
          >
            {formMortgage}
          </Card>
        ) : (
          <Spin indicator={antIcon} className="spin" />
        ))}
      {isMobile &&
        (dataPersonal !== null ? (
          <div style={{ marginBottom: 50 }}> {formMortgage}</div>
        ) : (
          <Spin indicator={antIcon} className="spin" />
        ))}
    </Layout>
  );
}

export default RequestInformation;
