import React from 'react';
import { Layout, Row, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';

const LayoutMeans = ({ folderId, rol }) => {
	const location = useLocation();
	const { t } = useTranslation();

	const styleTextTab = {
		fontSize: 14,
		fontWeight: 600,
		color: 'white',
		backgroundColor: '#02C3CD',
		borderRadius: '4px',
		border: 'none',
		height: 32,
		textAlign: 'center'
	};

	return (
		<Layout
			style={{ overflowX: !isMobileOnly && undefined, width: '100%' }}
			className={isMobileOnly && 'scroll-layout-means-sources'}>
			<Row gutter={[24, 24]} style={{ marginLeft: 0, marginRight: 0 }}>
				<div className={isMobileOnly ? 'newSubmenusTabsMobile' : 'newSubmenusTabs'}>
					<div
						style={{
							background: '#EFFCFC',
							borderRadius: 5,
							borderBottom: 'none',
							borderBottomColor: 'white',
							display: 'flex',
							alignItems: 'center',
							overflowY: 'scroll',
							overflowX: 'none',
							padding: '8px 0'
						}}
						className="scroolHorizontal"
						selectedKeys={[`${location.pathname}${location.search}`]}
						mode="horizontal">
							{rol !== 'realestate' && 
						<NavLink className="buttonOperations" to="/calculators">
							<Button
								className="buttonOperations"
								style={location.pathname === '/calculators' ? styleTextTab : undefined}>
								{t('menuComponent.Calculators')}
							</Button>
						</NavLink>
						}

						<NavLink className="buttonOperations" to="/documents">
							<Button
								className="buttonOperations"
								style={
									location.pathname === '/documents' ||
									(folderId !== undefined && folderId.map((data) => data === location.pathname))
										? styleTextTab
										: undefined
								}>
								{t('menuComponent.myFilesNav')}
							</Button>
						</NavLink>

						
					</div>
				</div>
			</Row>
		</Layout>
	);
};

export default LayoutMeans;
