import React from "react";
import {
  Layout,
  message,
  Row,
  Col,
  Card,
  Form,
  Input,
  Button,
  Space,
} from "antd";
import {
  MailTwoTone,
  LockTwoTone,
  GoogleOutlined,
  FacebookFilled,
} from "@ant-design/icons";
import gibobsLogo from "../../assets/icons/Gibobs.svg";
import { login } from "../../store/auth/actions";
import { useDispatch } from "react-redux";
import { isMobileOnly } from "react-device-detect";
import env from "../../environment";
import { useNavigate } from "react-router-dom";

const { Content } = Layout;

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const onFinish = async (values) => {
    dispatch(
      login({ username: values.username, password: values.password, navigate })
    );
  };

  const onFinishFailed = () => {
    message.error("Debe completar todos los datos");
  };

  return (
    <Layout
      style={{
        width: isMobileOnly ? 400 : undefined,
        marginTop: isMobileOnly ? "20%" : undefined,
      }}
    >
      <Content style={{ marginTop: "10%", marginLeft: "-4%" }}>
        <Row justify="space-around" align="middle" style={{ height: "100%" }}>
          <Col lg={6} md={12} xs={24} sm={24}>
            <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}>
              <Form
                name="login"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item style={{ textAlign: "center" }}>
                  <img
                    src={gibobsLogo}
                    style={{ height: "150px" }}
                    alt="Gibobs all banks"
                  ></img>
                </Form.Item>
                <Form.Item
                  name="username"
                  rules={[{ required: true, message: "Introduzca su email" }]}
                >
                  <Input
                    type="email"
                    prefix={<MailTwoTone className="site-form-item-icon" />}
                    placeholder="Usuario"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, introduzca su contraseña",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockTwoTone className="site-form-item-icon" />}
                    placeholder="Contraseña"
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" block>
                    Acceder
                  </Button>
                </Form.Item>
                <Form.Item style={{ textAlign: "center" }}>
                  <Space>
                    <Button
                      style={{ background: "#ee3314", border: "none" }}
                      block
                      type="primary"
                      icon={<GoogleOutlined />}
                      href={`${env.api.url}/auth/google?target=app_login`}
                    >
                      Google
                    </Button>
                    <Button
                      style={{ background: "#1877f2", border: "none" }}
                      type="primary"
                      icon={<FacebookFilled />}
                      block
                      href={`${env.api.url}/auth/facebook?target=app_login`}
                    >
                      Facebook
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};
