import React, { useState, useEffect } from 'react';
import { Card, Progress } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import numeral from 'numeral';
import { isMobileOnly } from 'react-device-detect';

const TermWidget = ({ pendingTerm, endDate, dateSigned }) => {
	const [randomNumber, setRandomNumber] = useState();

	const { t } = useTranslation();
	const percentTerm = numeral(
		100 - (pendingTerm * 100) / moment(endDate).diff(moment(dateSigned), 'month')
	).format('0,0');

	useEffect(async () => {
		setRandomNumber(Math.floor(Math.random() * 3) + 1);
	}, []);

	let textTerm;
	if (percentTerm < 25) {
		textTerm = t(`loan.termUnder25text${randomNumber}`);
	} else if (percentTerm < 50 && percentTerm >= 25) {
		textTerm = t(`loan.termOver25AndLess50Text${randomNumber}`);
	} else if (percentTerm >= 50 && percentTerm < 75) {
		textTerm = t(`loan.termOver50AndLess75Text${randomNumber}`);
	} else if (percentTerm >= 75 && percentTerm < 100) {
		textTerm = t(`loan.termOver75AndLess100Text${randomNumber}`);
	}
	return (
		<Card
			className="gcardMovile card-heigth-loan"
			style={{ marginTop: isMobileOnly ? 12 : 16, height: 'calc(100% - 36px)' }}>
			<div>
				<div className="term-widget-text">{t('loan.quotePending')}</div>
				<div className="term-widget-data" style={{ color: '#02C3CD' }}>
					{pendingTerm ? pendingTerm : 0}
				</div>
			</div>
			<div style={{ marginTop: 12 }}>
				<div className="term-widget-text">{t('loan.endDateMortgage')}</div>
				<div className="term-widget-data">
					{moment(endDate).format('MM/YYYY') !== 'Invalid date'
						? moment(endDate).format('MM/YYYY')
						: '00/00'}
				</div>
			</div>
			<div style={{ marginTop: 41 }}>
				<div className="term-widget-text" style={{ marginBottom: 11 }}>
					{textTerm}
				</div>
				<Progress
					percent={percentTerm}
					strokeColor={{
						'0%': '#80E1E6',
						'100%': '#02AFB8'
					}}
				/>
				<div style={{ display: 'flex', justifyContent: 'space-between', width: '88%' }}>
					<div className="term-widget-text" style={{ fontSize: 10 }}>
						<div>{t('menuComponent.initNav')}:</div>
						<div>
							{moment(dateSigned).format('MM/YYYY') !== 'Invalid date'
								? moment(dateSigned).format('MM/YYYY')
								: '00/00'}
						</div>
					</div>
					<div className="term-widget-text" style={{ fontSize: 10, textAlign: 'right' }}>
						<div>{t('loan.explanationProgress')}:</div>
						<div>
							{moment(endDate).format('MM/YYYY') !== 'Invalid date'
								? moment(endDate).format('MM/YYYY')
								: '00/00'}
						</div>
					</div>
				</div>
			</div>
		</Card>
	);
};

export default TermWidget;
