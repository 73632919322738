import React, { useState } from "react";
import { Layout, Card, Steps, Button, Form, Col, Row, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import savillasAnLogo from "../../../assets/icons/savillsAnLogo.png";
import logoGrupoTasvalor from "../../../assets/icons/logoGrupoTasvalor.png";
import gesvaltLogo from "../../../assets/icons/gesvaltLogo.jpg";
import globalLogo from "../../../assets/icons/globalLogo.png";
import IVvaloracionesLogo from "../../../assets/icons/IVvaloracionesLogo.jpg";
import tecnitasaLogo from "../../../assets/icons/tecnitasaLogo.jpg";
import { notificationGibobs } from "../reusableItems";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import env from "../../../environment";
import axios from "axios";

const { Step } = Steps;

const PropertyAppraisal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();;
  const userToken = useSelector((state) => state.auth.token);

  const [pageNumber, setPageNumber] = useState(1);

  const initialPage = (
    <>
      <div className="title-appraisal" style={{ marginBottom: 16 }}>
        {t("appraisal.processSteps").toUpperCase()}
      </div>
      <Steps
        direction="vertical"
        size="small"
        current={5}
        className={
          !isMobile
            ? "gibobs-steps-appraisal"
            : "gibobs-steps-appraisal gibobs-steps-appraisal-mobile"
        }
        style={{ marginBottom: 26 }}
      >
        <Step
          status="process"
          title={t("appraisal.propertyDetails")}
          description={t("appraisal.propertyDetailsDescription")}
        />
        <Step
          status="process"
          title={t("appraisal.ChooseAnAppraiser")}
          description={t("appraisal.ChooseAnAppraiserDescription")}
        />
        <Step
          status="process"
          title={t("appraisal.makeAppraisalPayment")}
          description={t("appraisal.makeAppraisalPaymentDescription")}
        />
        <Step
          status="process"
          title={t("appraisal.getAppraisalReport")}
          description={t("appraisal.getAppraisalReportDescription")}
        />
      </Steps>
      <div className="title-appraisal" style={{ marginBottom: 16 }}>
        {t("appraisal.appraisersWithWhichWeWork").toUpperCase()}
      </div>
      <div
        style={{
          width: isMobile ? "auto" : "100%",
          height: "auto",
          marginBottom: 80,
          display: "flex",
          rowGap: 24,
          columnGap: !isMobile && 24,
          flexWrap: "wrap",
          justifyContent: isMobile && "center",
        }}
      >
        <div style={{ display: "flex", columnGap: isMobile ? 14 : 24 }}>
          <img src={gesvaltLogo} alt="gesvaltLogo" height={32} />

          <img src={logoGrupoTasvalor} alt="logoGrupoTasvalor" height={36} />
        </div>
        <div style={{ display: "flex", columnGap: isMobile ? 14 : 24 }}>
          <img src={globalLogo} alt="globalLogo" height={36} />

          <img src={IVvaloracionesLogo} alt="IVvaloracionesLogo" height={30} />
        </div>
        <div style={{ display: "flex", columnGap: isMobile ? 14 : 24 }}>
          <img src={savillasAnLogo} alt="savillasAnLogo" height={56} />

          <img src={tecnitasaLogo} alt="tecnitasaLogo" height={32} />
        </div>
      </div>
      {isMobile && (
        <Card
          className="cardButtonStyle"
          style={{
            left: 0,
            marginBottom: -2,
            backgroundColor: "#FAFEFE",
          }}
        >
          <Button
            id="gb-mortgage-buy-situation-mobile"
            className="button-primary-gibobs-inmo"
            type="primary"
            style={{
              fontSize: 16,
              width: "100%",
              height: 48,
              fontWeight: 600,
            }}
            htmlType="submit"
            onClick={() => setPageNumber(2)}
          >
            {t("appraisal.requestAppraisalButton")}
          </Button>
        </Card>
      )}
      {!isMobile && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 60,
          }}
        >
          <Button
            id="gb-mortgage-buy-situation-mobile"
            className="button-primary-gibobs-inmo"
            type="primary"
            style={{
              fontSize: "14px",
              width: 180,
              height: 40,
              fontWeight: 600,
            }}
            htmlType="submit"
            onClick={() => setPageNumber(2)}
          >
            {t("appraisal.requestAppraisalButton")}
          </Button>
        </div>
      )}
    </>
  );

  const openNotificationWithIcon = () => {
    notificationGibobs(
      "error",
      t("m_full.errorTitle"),
      t("form.incompleteInformation")
    );
  };
  const validateMessages = {
    required: "${label}" + " " + t("form.formRequired"),
    types: {
      email: t("form.emailValidated"),
      number: "${label} is not a valid number!",
    },
    number: {
      range: t("form.maxBudgetNewRequestValidated") + "${min}",
    },
  };

  const sendMessage = (values) => {
    axios
      .post(`${env.api.url}/v1/operations/appraisal-contact`, values, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then(() => {
        notificationGibobs(
          "success",
          t("m_common.requestSuccessTitle"),
          t("form.formSuccessful")
        );
        navigate("/dashboard-business");
      });
  };

  const formPage = (
    <Form
      className={
        isMobile
          ? "gb-form-profile-headlines-mobile"
          : "gb-form-common-new-style"
      }
      onFinishFailed={openNotificationWithIcon}
      scrollToFirstError={true}
      // form={form}
      name="nest-messages"
      onFinish={sendMessage}
      layout="vertical"
      validateMessages={validateMessages}
      initialValues={{ resident: "yes", language: "es" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: isMobile && "12px",
          alignItems: "center",
          marginBottom: !isMobile ? "40px" : "12px",
        }}
      >
        <div className="typography-titles">{t("stages.appraisal")}</div>
        <div className="typography-blue-text">
          {t("m_products.basicPersonalDataTitle")}
        </div>
      </div>
      <Row
        gutter={24}
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          // marginBottom: isMobile && "100px",
        }}
      >
        <Col lg={6} xs={24}>
          <Form.Item
            name="name"
            label={t("form.name")}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              placeholder={t("form.name")}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </Col>

        <Col lg={6} xs={24}>
          <Form.Item
            name="surname"
            label={t("form.surname")}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              placeholder={t("form.surname")}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </Col>
        <Col lg={6} xs={24}>
          <Form.Item
            name="phone"
            label={t("form.phone")}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              placeholder={t("form.phone")}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </Col>
        <Col lg={6} xs={24}>
          <Form.Item
            name="email"
            label={t("form.email")}
            rules={[
              {
                required: true,
                type: "email",
              },
            ]}
          >
            <Input
              placeholder={t("form.email")}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={t("appraisal.descriptionRequestAppraisal")}
            name="description"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea
              style={{ width: "100%", minHeight: 80 }}
              placeholder={t("appraisal.characteristicsRequestAppraisal")}
            />
          </Form.Item>
        </Col>

        {isMobile && (
          <Card
            className="cardButtonStyle"
            style={{
              left: 0,
              marginBottom: -2,
              backgroundColor: "#FAFEFE",
            }}
          >
            <Button
              id="gb-mortgage-buy-situation-mobile"
              className="button-primary-gibobs-inmo"
              type="primary"
              style={{
                fontSize: 16,
                width: "100%",
                height: 48,
                fontWeight: 600,
              }}
              htmlType="submit"
            >
              {t("form.send")}
            </Button>
          </Card>
        )}
      </Row>
      {!isMobile && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 60,
          }}
        >
          <Button
            id="gb-mortgage-buy-situation-mobile"
            className="button-primary-gibobs-inmo"
            type="primary"
            style={{
              fontSize: "14px",
              width: 120,
              height: 40,
              fontWeight: 600,
            }}
            htmlType="submit"
          >
            {t("form.send")}
          </Button>
        </div>
      )}
    </Form>
  );

  return (
    <Layout style={{ marginBottom: "100px" }}>
      {!isMobile && (
        <Card
          className="CardStyleRealeState"
          style={{
            marginLeft: isMobile ? "16px" : "40px",
            width: isMobile ? "calc(100% - 32px)" : "calc(100% - 80px)",
            marginTop: !isMobile && 40,
          }}
        >
          {pageNumber === 1 && (
            <>
              <div
                className="title-principal-appraisal title-appraisal"
                style={{ marginBottom: 8 }}
              >
                {t("init.propertyAppraisal")}
              </div>
              <div
                className="description-principal-appraisal"
                style={{ marginBottom: 32 }}
              >
                {t("appraisal.propertyAppraisalDescription")}
              </div>
              {initialPage}
            </>
          )}
          {pageNumber === 2 && <>{formPage}</>}
        </Card>
      )}
      {isMobile && (
        <>
          {pageNumber === 1 && (
            <div
              style={{
                marginLeft: isMobile ? "16px" : "40px",
                width: isMobile ? "calc(100% - 32px)" : "calc(100% - 80px)",
                marginTop: !isMobile ? 40 : 16,
              }}
            >
              <Card
                className="CardStyleRealeState"
                style={{
                  marginLeft: 0,
                  marginBottom: 32,
                }}
              >
                <div
                  className="title-principal-appraisal title-appraisal"
                  style={{ marginBottom: 8 }}
                >
                  {t("init.propertyAppraisal")}
                </div>
                <div className="description-principal-appraisal">
                  {t("appraisal.propertyAppraisalDescription")}
                </div>
              </Card>
              {initialPage}
            </div>
          )}
          {pageNumber === 2 && (
            <div
              className="gb-form-inmo-scroll"
              style={{
                marginLeft: isMobile ? "4px" : "40px",
                width: isMobile ? "calc(100% - 8px)" : "calc(100% - 80px)",
              }}
            >
              {formPage}
            </div>
          )}
        </>
      )}
    </Layout>
  );
};

export default PropertyAppraisal;
