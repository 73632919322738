import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Select, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { renderComponent } from '../../../../select/typeSelect';
import axios from 'axios';
import env from '../../../../environment';
import _ from 'lodash';
import { isMobileOnly } from 'react-device-detect';
import { InputNumber } from 'afrodita';

const { Option } = Select;
const { TabPane } = Tabs;

const CalculatorFinancingData = ({
	setResult,
	valuesForm,
	family,
	fixed,
	quotes,
	houseType,
	result,
	setPrice,
	priceTouch,
	ultimateDataSimulation,
	idUltimateSimulation
}) => {
	const location = useLocation();
	const { t } = useTranslation();
	const rol = useSelector((state) => state.auth.roles);
	const [form] = Form.useForm();
	const [countries, setContries] = useState();
	const [participants, setParticipants] = useState(undefined);
	const lang = localStorage.getItem('i18nextLng');
	const [result2, setResult2] = useState(undefined);
	const [, setValuesForm2] = useState(undefined);
	const [timeoutReference, setTimeoutReference] = useState(undefined);
	const userToken = useSelector((state) => state.auth.token);
	let navigate = useNavigate()

	useEffect(() => {
		axios.get(`${env.api.url}/v1/countries`).then((response) => {
			setContries(response.data.data);
		});

		if (ultimateDataSimulation?.simulationData?.owner1) {
			changeParticipants('1');
		}
		if (ultimateDataSimulation?.simulationData?.owner2) {
			changeParticipants('2');
		}
		if (ultimateDataSimulation?.simulationData?.owner1 === undefined) {
			changeParticipants('1');
		}
	}, []);

	const changeParticipants = (value) => {
		setParticipants(value);
	};

	const participantsTabs = [];

	const renderTabs = (index) => {
		return (
			<TabPane tab={<span>{t(`onboarding.owner${index}`)}</span>} key={`${index}`}>
				{renderForm(index)}
			</TabPane>
		);
	};

	const renderForm = (data) => {
		return (
			<>

				<Col span={24}>
					<Form.Item
						name={`activity${data}`}
						label={t('form.activity')}
						rules={[
							{
								required: true
							}
						]}>
						<Select id={`activity${data}`} placeholder={t('form.select')} style={{ marginBottom: 6 }}>
							{renderComponent('activity3').options.map((situation) => {
								return (
									<Option key={situation.value} value={situation.value}>
										{t(situation.label)}
									</Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item
						name={`seniority${data}`}
						label={t('form.seniority')}
						rules={[
							{
								required: true,
								message: t('calculator.fillInInput')
							}
						]}>
						<InputNumber
							defaultValue={''}
							id={`seniority${data}`}
							gbType="number"
							className='gb-input-new-years'
							gbDecimalType="integer"
							placeholder={'0'}
							min={0}
							max={40}
						/>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item
						name={`netIncomes${data}`}
						label={t('form.netIncomesOwnerInmo')}
						rules={[
							{
								required: true,
								message: t('calculator.fillInInput')
							}
						]}>
						<InputNumber
							defaultValue={''}
							id={`netIncomes${data}`}
							gbType="currency"
							gbDecimalType="integer"
							placeholder={'0'}
							suffix={'€/' + t('viability.monthsEstimated')}
							min={0}
							max={999999}
						/>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item
						name={`loanPayment${data}`}
						label={
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								{t('calculator.haveLoansInmo')}
								<div
									className="explination-others-incomes"
									style={{
										color: '#748EA0',
										fontWeight: 400,
										fontSize: 10,
										marginTop: 10
									}}>
									{t('form.haveLoansInmo0')}
								</div>
							</div>
						}
						rules={[
							{
								required: true,
								message: t('calculator.fillInInput')
							}
						]}>
						<InputNumber
							defaultValue={''}
							id={`loanPayment${data}`}
							gbType="currency"
							gbDecimalType="integer"
							placeholder={'0'}
							suffix={'€/' + t('viability.monthsEstimated')}
							min={0}
							max={99999}
						/>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item
						style={{ marginTop: -6 }}
						name={`age${data}`}
						label={t('form.howOldAreYou')}
						rules={[
							{
								required: true,
								message: t('age.input')
							}
						]}>
						<InputNumber
							defaultValue={''}
							id={`age${data}`}
							gbType="number"
							gbDecimalType="integer"
							className='gb-input-new-years'
							placeholder={'32'}
							min={18}
							max={70}
						/>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item
						name={`cityzenship${data}`}
						label={t('form.country')}
						rules={[
							{
								required: true,
								message: t('calculator.fillInInput')
							}
						]}>
						<Select
							id={`cityzenship${data}`}
							showSearch
							placeholder={t('form.country')}
							style={{ width: '100%' }}
							defaultValue={'ES'}
							filterOption={(input, option) => {
								return (
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
									option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
								);
							}}>
							{countries &&
								countries.countries.map((paises) => {
									return (
										<Option key={paises.code} value={paises.code}>
											{lang === 'en' ? paises.name_en : paises.name_es}
										</Option>
									);
								})}
						</Select>
					</Form.Item>
				</Col>
			</>
		);
	};

	for (let i = 1; i <= parseInt(participants); i++) {
		participantsTabs.push(renderTabs(i));
	}

	const onChangeForm = (values, allValues) => {
		let owners = [];
		if (allValues.cityzenship1 === undefined) {
			allValues.cityzenship1 = 'ES';
		}
		if (allValues.cityzenship2 === undefined) {
			allValues.cityzenship2 = 'ES';
		}

		setValuesForm2(allValues);

		if (allValues.participants === '2') {
		

			owners = [
				{
					activity: allValues.activity1,
					seniority: allValues.seniority1 !== null ? allValues.seniority1 : 0,
					netIncomes: allValues.netIncomes1 !== null ? allValues.netIncomes1 : 0,
					loanPayment: allValues.loanPayment1 !== null ? allValues.loanPayment1 : 0,
					age: allValues.age1 !== null ? allValues.age1 : 0,
					cityzenship: _.isNil(allValues.cityzenship1) ? 'ES' : allValues.cityzenship1
				},
				{
					activity: !_.isNil(allValues.activity2)
						? allValues.activity2
						: location?.state?.formValues?.activity2,
					seniority: !_.isNil(allValues.seniority2)
						? allValues.seniority2
						: location?.state?.formValues?.seniority2,
					netIncomes: !_.isNil(allValues.netIncomes2)
						? allValues.netIncomes2
						: location?.state?.formValues?.netIncomes2,
					loanPayment: !_.isNil(allValues.loanPayment2)
						? allValues.loanPayment2
						: location?.state?.formValues?.loanPayment2,
					age: !_.isNil(allValues.age2) ? allValues.age2 : location?.state?.formValues?.age2,
					cityzenship: _.isNil(allValues.cityzenship2) ? 'ES' : allValues.cityzenship2
				}
			];
		} else {
			owners = [
				{
					activity: allValues.activity1,
					seniority: allValues.seniority1 !== null ? allValues.seniority1 : 0,
					netIncomes: allValues.netIncomes1 !== null ? allValues.netIncomes1 : 0,
					loanPayment: allValues.loanPayment1 !== null ? allValues.loanPayment1 : 0,
					age: allValues.age1 !== null ? allValues.age1 : 0,
					cityzenship: _.isNil(allValues.cityzenship1) ? 'ES' : allValues.cityzenship1
				}
			];
		}

		if (timeoutReference) {
			clearTimeout(timeoutReference);
		}

		setTimeoutReference(
			setTimeout(() => {
				axios
					.post(
						`${env.api.url}/mortgages/simulations/client-qualification/mortgage-viability`,
						{
							id: idUltimateSimulation
								? idUltimateSimulation
								: !_.isNil(result2)
									? result2
									: undefined,
							simulationType: 'B2C',
							savings: !_.isNil(valuesForm.savings) ? valuesForm.savings : 50000,
							propertyType: valuesForm.propertyType,
							numerousFamily: family,
							province: valuesForm.province,
							maxBudget: !_.isNil(priceTouch) && priceTouch > 10000 ? priceTouch : undefined,
							fixed: !_.isNil(values.fixed) ? values.fixed : fixed,
							quotes: !_.isNil(values.quotes) ? values.quotes : quotes,
							participants: {
								houseType: !_.isNil(valuesForm.houseType) ? valuesForm.houseType : houseType,
								participants: parseInt(allValues.participants),
								owners: owners
							}
						},
						{
							headers: { Authorization: `Bearer ${userToken}` }
						}
					)
					.then((response) => {
						setResult(response.data.data);
						setPrice(response.data.data.bonilla[1]?.maxBudget);
						if (response.data.data.simulation) {
							setResult2(response.data.data.simulation.id);
						}
						if (typeof response.data.data === 'string') {
							setPrice(undefined);
						}
					})
					.catch((error) => {
						console.error(error);
					});

				clearTimeout(timeoutReference);
			}, 500)
		);
	};

	return (
		<>
			<Form
				labelAlign="left"
				colon={false}
				requiredMark={false}
				className="gb-form-profile-headlines-mobile"
				labelCol={{
					flex: !isMobileOnly ? '50%' : '40%'
				}}
				wrapperCol={{
					flex: isMobileOnly && '60%'
				}}
				layout={'horizontal'}
				scrollToFirstError={true}
				form={form}
				name="basic"
				onValuesChange={
					isMobileOnly
						? (values, allValues) => setResult(allValues)
						: (values, allValues) => onChangeForm(values, allValues)
				}
				initialValues={{
					participants:
						ultimateDataSimulation === null
							? undefined
							: ultimateDataSimulation?.simulationData?.owner2 !== undefined
								? '2'
								: '1',
					activity1: ultimateDataSimulation?.simulationData?.owner1?.activity,
					seniority1: ultimateDataSimulation?.simulationData?.owner1?.seniority,
					netIncomes1: ultimateDataSimulation?.simulationData?.owner1?.netIncomes,
					loanPayment1: ultimateDataSimulation?.simulationData?.owner1?.loanPayment,
					age1: ultimateDataSimulation?.simulationData?.owner1?.age,
					cityzenship1: ultimateDataSimulation?.simulationData?.owner1?.cityzenship,

					activity2: ultimateDataSimulation?.simulationData?.owner2?.activity,
					seniority2: ultimateDataSimulation?.simulationData?.owner2?.seniority,
					netIncomes2: ultimateDataSimulation?.simulationData?.owner2?.netIncomes,
					loanPayment2: ultimateDataSimulation?.simulationData?.owner2?.loanPayment,
					age2: ultimateDataSimulation?.simulationData?.owner2?.age,
					cityzenship2: ultimateDataSimulation?.simulationData?.owner2?.cityzenship
				}}>
				<Row
					style={{
						marginLeft: 'auto',
						marginRight: 'auto',
						marginTop: 16
					}}>
					<Col span={24}>
						<Form.Item
							name="participants"
							label={
								rol === 'realestate'
									? t('calculator.viabilityAlone2')
									: t('calculator.viabilityAlone')
							}>
							<Select
								placeholder={t('loan.sincronizationChosse')}
								style={{ width: '100%', marginBottom: 6 }}
								id="participants"
								onChange={(value) => {
									changeParticipants(value);
								}}>
								<Option value="1">{t('calculator.formAlone')}</Option>
								<Option value="2">{t('calculator.formAccompanied')}</Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>

				{participants === '2' && (
					<Tabs defaultActiveKey="1" centered>
						{participantsTabs}
					</Tabs>
				)}
				{participants === '1' && (
					<Row gutter={24} style={{ paddingBottom: 16 }}>
						{renderForm('1')}
					</Row>
				)}
			</Form>
		</>
	);
};

export default CalculatorFinancingData;
