import React, { useState, useEffect } from 'react';
import { Modal, Select, Form, Col, Row, Input, Card, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import financingPhoto from '../../assets/promoter/financing.svg';
import DownOutlinedMobile from '../../assets/icons/DownOutlinedMobile.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationGibobs } from '../HerramientasVenta/reusableItems';
import TextArea from 'antd/lib/input/TextArea';
import { StepsProcess } from './ModalFinancingProcess';
import { useSelector } from 'react-redux';
import axios from 'axios';
import env from '../../environment';

const { Option } = Select;

export const ModalWelcomeProject = ({ visible, onCancel, width, onCreate, nextStage }) => {
	const [numberCarousel, ] = useState(1);

	const { t } = useTranslation();

	const dots = (
		<div className="container-dots">
			<div className={numberCarousel !== 1 ? 'dots-class' : 'dots-class-select'}></div>
			<div className={numberCarousel !== 2 ? 'dots-class' : 'dots-class-select'}></div>
		</div>
	);

	const data = [
		{
			title: 'promoterLoan.modalFinancingStep1',
			description: 'promoterLoan.modalFinancingStep1Description'
		},
		{
			title: 'promoterLoan.modalFinancingStep2',
			description: 'promoterLoan.modalFinancingStep2Description'
		},
		{
			title: 'promoterLoan.modalFinancingStep3',
			description: 'promoterLoan.modalFinancingStep3Description'
		},
		{
			title: 'promoterLoan.modalFinancingStep4',
			description: 'promoterLoan.modalFinancingStep4Description'
		},
		{
			title: 'promoterLoan.modalFinancingStep5',
			description: 'promoterLoan.modalFinancingStep5Description'
		}
	];

	return (
		<Card
			className="card-promoter-welcome"
			visible={visible}
			cover={
				<div style={{ overflow: 'hidden', height: 114 }}>
					<img
						src={financingPhoto}
						alt="financing"
						style={{
							height: 'auto',
							width: '100%'
						}}
					/>
				</div>
			}
			okText={t('form.next')}
			cancelText={t('form.previous')}
			width={width}
			onCancel={onCancel}
			onOk={onCreate}
			maskClosable={false}
			closable={false}
			keyboard={false}>
			<div style={{ width: '60%', margin: 'auto' }}>
				<div>
					<div className="title-primary" style={{ marginBottom: 8 }}>
						{t('promoterLoan.welcomeModalTitle')}
					</div>
					<div className="paragraph-style" style={{ marginBottom: 16 }}>
						{t('promoterLoan.descriptionWelcomeStages')}
					</div>
					<div
						className="title-primary"
						style={{ fontSize: 14, fontWeight: 500, color: '#02C3CD' }}>
						{t('promoterLoan.stepsTitleModal')}
					</div>
					{StepsProcess(data)}
					{dots}
				</div>
				<div style={{ textAlign: 'center' }}>
					<Button className="button-primari-gibobs" type="primary" onClick={() => nextStage()}>
						{t('form.next')}
					</Button>
				</div>
			</div>
		</Card>
	);
};

export const ModalTwoStages = ({ visible, width, setModalForm, previousStage }) => {
	const navigate = useNavigate();;
	const params = useParams();
	const operationId = params.operationId;
	const [form] = Form.useForm();
	const [numberCarousel, setNumberCarousel] = useState(2);
	const [constructionProporsal, setConstructionProporsal] = useState('');
	const [constructionUse, setConstructionUse] = useState('');
	const [modalContactOpen, setModalContactOpen] = useState(false);
	const userToken = useSelector((state) => state.auth.token);
	const dataPromoter = useSelector((state) => state.promoter.dataFormPromoter);

	const { t } = useTranslation();

	const openNotificationWithIcon = () => {
		notificationGibobs('error', t('m_full.errorTitle'), t('form.incompleteInformation'));
	};

	useEffect(() => {
		setConstructionProporsal(
			dataPromoter &&
				dataPromoter.constructionData &&
				dataPromoter.constructionData.constructionPurpose
				? dataPromoter.constructionData.constructionPurpose
				: ''
		);
		setConstructionUse(
			dataPromoter &&
				dataPromoter.constructionData &&
				dataPromoter.constructionData.constructionUseTypes
				? dataPromoter.constructionData.constructionUseTypes
				: ''
		);
	}, [dataPromoter]);

	const validateMessages = {
		required: '${label}' + ' ' + t('form.formRequired'),
		types: {
			email: t('form.emailValidated'),
			number: '${label} is not a valid number!'
		},
		number: {
			range: t('form.maxBudgetNewRequestValidated') + '${min}'
		}
	};

	const sendConstructionData = (values, general, type) => {
		if (values.houseType === undefined) {
			values.houseType = 'NO_FAMILY';
		}
		axios
			.post(`${env.api.url}/promoters/promoter-projects/construction-data/${operationId}`, values, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				if (response.data.success && type === undefined) {
					// notificationGibobs("success", t("promoterLoan.ModalTextSuccess"));
				}
			})
			.catch(() => {
				notificationGibobs('error', t('promoterLoan.ModalTextError'));
			});
		if (type === undefined) {
			navigate(`/promoter-loan/general-data/${operationId}`);
			setModalForm(false);
			form.resetFields();
		}
		if (type !== undefined) {
			setNumberCarousel(numberCarousel + 1);
			setModalContactOpen(true);
		}
	};

	const sendContactData = (values) => {
		axios
			.post(
				`${env.api.url}/promoters/promoter-projects/contact-data/${operationId}?origin=RENTING`,
				values,
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					notificationGibobs('success', t('promoterLoan.ModalTextSuccess'));
				}
				if (!response.data.success) {
					notificationGibobs('error', t('promoterLoan.ModalTextError'));
				}
			})
			.catch(() => {
				notificationGibobs('error', t('promoterLoan.ModalTextError'));
			});
		navigate('/');
		setModalForm(false);
		form.resetFields();
	};

	const onCreate = (values, value, type) => {
		if (value === 'general') {
			sendConstructionData(values, value, type);
		} else {
			sendContactData(values);
		}
	};

	const dots = (
		<div className="container-dots">
			<div className={numberCarousel !== 1 ? 'dots-class' : 'dots-class-select'}></div>
			<div className={numberCarousel !== 2 ? 'dots-class' : 'dots-class-select'}></div>
			{(constructionProporsal === 'RENTAL' || constructionUse === 'OTHER') && (
				<div className={numberCarousel !== 3 ? 'dots-class' : 'dots-class-select'}></div>
			)}
		</div>
	);

	const form1Verification = (values) => {
		if (constructionProporsal === 'SALE' && constructionUse === 'RESIDENTIAL') {
			onCreate(values, 'general');
		} else {
			onCreate(values, 'general', 'case2');
		}
	};

	return (
        (<Card
			className="card-promoter-welcome"
			cancelButtonProps={{
				className: 'button-secundary-gibobs',
				style: { display: numberCarousel === 1 && 'none' }
			}}
			okButtonProps={{
				className: 'button-primari-gibobs',
				style: { textAlign: 'center' }
			}}
			visible={visible}
			cover={
				<div style={{ overflow: 'hidden', height: 114 }}>
					<img
						src={financingPhoto}
						alt="financing"
						style={{
							height: 'auto',
							width: '100%'
						}}
					/>
				</div>
			}
			okText={t('form.next')}
			cancelText={t('form.previous')}
			width={width}
			maskClosable={false}
			closable={false}
			keyboard={false}>
            {(numberCarousel === 2 || numberCarousel === 3) && (
				<Form
					form={form}
					className={'gb-form-profile-headlines-mobile'}
					onFinishFailed={openNotificationWithIcon}
					scrollToFirstError={true}
					onFinish={form1Verification}
					layout="vertical"
					name="Password"
					validateMessages={validateMessages}
					initialValues={
						dataPromoter && dataPromoter.constructionData
							? { ...dataPromoter['constructionData'] }
							: {}
					}
					style={{ width: '60%', margin: 'auto' }}>
					<div className="title-primary" style={{ marginBottom: 24 }}>
						{t('promoterLoan.welcomeModalTitle')}
					</div>
					<div className="paragraph-style" style={{ marginBottom: 16 }}>
						{t('promoterLoan.descriptionWelcome')}
					</div>

					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							marginBottom: 40
						}}>
						<Form.Item
							name="constructionPurpose"
							label={t('promoterLoan.constructionProporsal')}
							rules={[
								{
									required: true
								}
							]}
							style={{ width: 400 }}>
							<Select
								suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
								defaultValue={t('form.select')}
								style={{ width: '100%' }}
								onChange={(e) => setConstructionProporsal(e)}>
								<Option value="SALE">{t('promoterLoan.saleTitle')}</Option>
								<Option value="RENTAL">{t('promoterLoan.rentalTitle')}</Option>
							</Select>
						</Form.Item>
						<Form.Item
							name="constructionUseTypes"
							label={t('promoterLoan.constructionUse')}
							rules={[
								{
									required: true
								}
							]}
							style={{ width: 400 }}>
							<Select
								suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
								defaultValue={t('form.select')}
								style={{ width: '100%' }}
								onChange={(e) => setConstructionUse(e)}>
								<Option value="RESIDENTIAL">{t('promoterLoan.residentialTitle')}</Option>
								<Option value="OTHER">{t('promoterLoan.otherTitle')}</Option>
							</Select>
						</Form.Item>
						<Form.Item
							name="houseType"
							label={t('promoterLoan.typology')}
							rules={[
								{
									required: constructionUse === 'RESIDENTIAL' ? true : false
								}
							]}
							style={{ width: 400 }}>
							<Select
								suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
								defaultValue={t('form.select')}
								style={{ width: '100%' }}
								disabled={constructionUse !== 'RESIDENTIAL' ? true : false}>
								<Option value="SINGLE_FAMILY">{t('promoterLoan.singleFamily')}</Option>
								<Option value="MULTI_FAMILY">{t('promoterLoan.multiFamily')}</Option>
							</Select>
						</Form.Item>
					</div>

					{dots}
					<div style={{ textAlign: 'center' }}>
						<Button
							className="button-secundary-gibobs"
							type="dashed"
							style={{ marginRight: 8 }}
							onClick={() => previousStage()}>
							{t('form.previous')}
						</Button>
						<Button htmlType="submit" className="button-primari-gibobs" type="primary">
							{t('viability.continue')}
						</Button>
					</div>
				</Form>
			)}
            <Modal
				className="ModalCommon"
				title={null}
				open={modalContactOpen}
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { display: numberCarousel === 1 && 'none' }
				}}
				okButtonProps={{
					className: 'button-primari-gibobs',
					style: { textAlign: 'center' }
				}}
				onCancel={() => {
					setModalContactOpen(false);
					setNumberCarousel(numberCarousel - 1);
				}}
				onOk={() => {
					form
						.validateFields()
						.then((values) => {
							onCreate(values);
						})
						.catch((info) => {
							console.log('Validate Failed:', info);
						});
				}}>
				<Form
					form={form}
					className={'gb-form-profile-headlines-mobile'}
					onFinishFailed={openNotificationWithIcon}
					scrollToFirstError={true}
					// onFinish={finalcialDataVerificated}
					layout="vertical"
					name="Password"
					validateMessages={validateMessages}
					initialValues={dataPromoter && dataPromoter.owner ? { ...dataPromoter['owner'] } : {}}>
					<div style={{ paddingTop: 40 }}>
						<div className="title-primary" style={{ marginBottom: 24 }}>
							{t('promoterLoan.contactForm')}
						</div>
						<div className="paragraph-style" style={{ marginBottom: 16 }}>
							{t('promoterLoan.workingOnTheseOptions')}
						</div>

						<Row gutter={24}>
							<Col span={12}>
								<Form.Item
									name="phone"
									label={t('form.phone')}
									rules={[
										{
											required: true
										}
									]}>
									<Input placeholder={t('form.phone')} />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item name="contactScheduleTypes" label={t('form.contactTime')}>
									<Select
										defaultValue={t('form.select')}
										style={{
											width: '100%'
										}}
										suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}>
										<Option value="MORNINGS">{t('form.morning')}</Option>
										<Option value="NOON">{t('form.afternoon')}</Option>
										<Option value="AFTERNOONS">{t('form.evening')}</Option>
									</Select>
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item name="comments" label={t('form.comments')}>
									<TextArea
										placeholder={t('form.comments')}
										style={{
											width: '100%'
										}}
									/>
								</Form.Item>
							</Col>
						</Row>

						{dots}
					</div>
				</Form>
			</Modal>
        </Card>)
    );
};
