import React, { useState } from 'react';
import {
	Layout,
	Card,
	Typography,
	Collapse,
	Form,
	Col,
	Row,
	InputNumber,
	Input,
	Select,
	Button,
	Upload,
	notification
} from 'antd';
import { useTranslation } from 'react-i18next';
import MyBreadcrumb from '../MyBreadcrumb';
import numeral from 'numeral';
import { useNavigate } from 'react-router-dom';
import buildtoSell from '../../assets/icons/buildtosell.png';

const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

const PromoterLoan = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();;
	const [saveFileDataField, setSaveFileDataField] = useState([]);
	const [fileName, setFileName] = useState('');

	const data = [
		{
			name: t('menuComponent.productsNav'),
			link: '/products-business'
		},
		{
			name: 'Prestamo promotor',
			link: '/sales-tools/promoter-loan'
		}
	];

	const onFinish = (values) => {
		values.field = saveFileDataField;
		console.log(values);
		if (values.buildStatusSell !== undefined) {
			navigate('/sales-tools/promoterLoan/promoter-loan-build-to-sell',{
				state: { data: values }
			});
		}
	};

	const saveFileData = (file, name) => {
		const documentFile = saveFileDataField;
		documentFile.push({
			file,
			fileName: name
		});
		setSaveFileDataField(documentFile);
	};

	const uploadProps = {
		multiple: true,
		beforeUpload: (file) => {
			if (file.size > 8000000) {
				notification.error({
					message: 'Error: archivo demasiado grande',
					description: 'Su documento ocupa mas de 8mb. No se pueden enviar archivos de más de 8mb'
				});

				setFileName('Error: archivo demasiado grande');

				return;
			}

			setFileName(file.name);
			return new Promise(() => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					const base64File = reader.result.split(',')[1];
					saveFileData(base64File, file.name);
				};
			});
		}
	};

	return (
		<Layout>
			<MyBreadcrumb data={data}></MyBreadcrumb>

			<div style={{ height: 'auto', marginBottom: '100px' }}>
				<Form style={{ marginTop: '14px' }} layout="vertical" onFinish={onFinish}>
					<Card className="CardStyleClientGibobs">
						<Text style={{ color: '#2F4858', fontSize: '14px', fontWeight: '600' }}>
							{t('promoterLoan.request')}
						</Text>
						<Collapse
							defaultActiveKey={['1']}
							style={{
								marginTop: 32
							}}
							expandIconPosition="end">
							<Panel key="1" header={t('stages.basic')} style={{ fontSize: '12px' }}>
								<Text style={{ color: '#124887', fontSize: '12px', fontWeight: '500' }}>
									{t('promoterLoan.promoterDetails')}
								</Text>

								{/* <Row style={{ marginBottom: "20px", marginTop: "-15px" }}></Row> */}
								<Row gutter={24} style={{ marginTop: '14px' }}>
									<Col lg={6} md={8} sm={12} xs={24}>
										<Form.Item
											label={t('promoterLoan.nameOrSocialReason')}
											name="nameOrSocial"
											rules={[
												{
													required: true
												}
											]}>
											<Input
												style={{
													borderColor: '#c0daee',
													width: '100%',
													borderRadius: '4px'
												}}
												placeholder={t('promoterLoan.nameOrSocialReason')}
											/>
										</Form.Item>
									</Col>

									<Col lg={6} md={8} sm={12} xs={24}>
										<Form.Item
											label={t('form.companyCif')}
											name="cif"
											rules={[
												{
													required: true
												}
											]}>
											<Input
												style={{
													borderColor: '#c0daee',
													width: '100%',
													borderRadius: '4px'
												}}
												placeholder={t('form.companyCif')}
											/>
										</Form.Item>
									</Col>
									<Col lg={6} md={8} sm={12} xs={24}>
										<Form.Item
											label={t('promoterLoan.registeredOffice')}
											name="registeredOffice"
											rules={[
												{
													required: true
												}
											]}>
											<Input
												style={{
													borderColor: '#c0daee',
													width: '100%',
													borderRadius: '4px'
												}}
												placeholder={t('promoterLoan.registeredOffice')}
												formatter={(value) =>
													value !== '' ? numeral(value).format('0,0 $') : null
												}
												parser={(value) => numeral(value).value()}
											/>
										</Form.Item>
									</Col>
								</Row>

								<Text style={{ color: '#124887', fontSize: '12px', fontWeight: '500' }}>
									{t('promoterLoan.contactPersonDetails')}
								</Text>
								<Row gutter={24} style={{ marginTop: '14px' }}>
									<Col lg={6} md={8} sm={12} xs={24}>
										<Form.Item
											label={t('mydocuments.name')}
											name="name"
											rules={[
												{
													required: true
												}
											]}>
											<Input
												style={{
													borderColor: '#c0daee',
													width: '100%',
													borderRadius: '4px'
												}}
												placeholder={t('mydocuments.name')}
											/>
										</Form.Item>
									</Col>

									<Col lg={6} md={8} sm={12} xs={24}>
										<Form.Item
											label={t('form.surname')}
											name="surname"
											rules={[
												{
													required: true
												}
											]}>
											<Input
												style={{
													borderColor: '#c0daee',
													width: '100%',
													borderRadius: '4px'
												}}
												placeholder={t('form.surname')}
											/>
										</Form.Item>
									</Col>
									<Col lg={6} md={8} sm={12} xs={24}>
										<Form.Item
											label={t('form.phone')}
											name="phone"
											rules={[
												{
													required: true
												}
											]}>
											<InputNumber
												style={{
													borderColor: '#c0daee',
													width: '100%',
													borderRadius: '4px'
												}}
												placeholder={t('form.phone')}
											/>
										</Form.Item>
									</Col>
									<Col lg={6} md={8} sm={12} xs={24}>
										<Form.Item
											label={t('form.email')}
											name="email"
											rules={[
												{
													required: true
												}
											]}>
											<Input
												type={'email'}
												style={{
													borderColor: '#c0daee',
													width: '100%',
													borderRadius: '4px'
												}}
												placeholder={t('form.email')}
											/>
										</Form.Item>
									</Col>
								</Row>
							</Panel>
						</Collapse>
						<Collapse
							defaultActiveKey={['1']}
							style={{
								marginBottom: '8px',
								marginTop: 16
							}}
							expandIconPosition="end">
							<Panel
								key="1"
								header={t('promoterLoan.constructionObjective')}
								style={{ fontSize: '12px' }}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										flexWrap: 'wrap',
										justifyContent: 'center'
									}}>
									<Card style={{ width: '311px', backgroundColor: '#EFFCFC' }}>
										<div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
											<img src={buildtoSell} alt="Build To Sell" height={30} width={30} />
											<Text
												style={{ marginTop: 14, color: '#748EA0', fontSize: 14, marginBottom: 32 }}>
												Build to sell
											</Text>
										</div>

										<Form.Item label={t('promoterLoan.constructionStatus')} name="buildStatusSell">
											<Select placeholder={t('form.select')}>
												<Option value="construction_pending">
													{t('promoterLoan.constructionPending')}
												</Option>
												<Option value="in_construction">{t('promoterLoan.inConstruction')}</Option>
												<Option value="built">{t('promoterLoan.built')}</Option>
											</Select>
										</Form.Item>
										<Form.Item name="field">
											<Upload {...uploadProps}>
												<Button type="dashed" className="button-secundary-optionA-gibobs">
													{t('promoterLoan.addPromotionImage')}
												</Button>
												<small>{fileName}</small>
											</Upload>
										</Form.Item>
									</Card>
									{/* <Card style={{ width: '311px', backgroundColor: '#EFFCFC', marginLeft: '65px' }}>
                                        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                                            <img src={buildtoRent} alt='Build To Rent' height={30} width={30} />
                                            <Text style={{ marginTop: 14, color: '#748EA0', fontSize: 14, marginBottom: 32 }}>Build to sell</Text>
                                        </div>

                                        <Form.Item
                                            label="Estado de la construccion"
                                            name="buildStatusRent"
                                        >
                                            <Select

                                                placeholder={t("form.select")}
                                            >
                                                <Option value="constructionPending">Pendiente de la construcción</Option>
                                                <Option value="inConstruction">En construcción (WIP)</Option>
                                                <Option value="built">Ya construido</Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label="Explotación"
                                            name="operation"
                                        >
                                            <Select

                                                placeholder={t("form.select")}
                                            >
                                                <Option value="saleThird">Promoción para venta a un tercero</Option>
                                                <Option value="ownExploitation">Promoción para explotación propia</Option>

                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="field">
                                            <Upload {...uploadProps}>
                                                <Button type="dashed" className='button-secundary-optionA-gibobs' >
                                                    Añadir imagen de la promoción
                                                </Button>
                                                <small>{fileName}</small>
                                            </Upload>
                                        </Form.Item>
                                    </Card> */}
								</div>
							</Panel>
						</Collapse>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Button
								htmlType="submit"
								type="primary"
								className="button-primary-gibobs"
								style={{ marginTop: 32 }}>
								{t('viability.interest')}
							</Button>
						</div>
					</Card>
				</Form>
			</div>
		</Layout>
	);
};

export default PromoterLoan;
