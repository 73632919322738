import React, { useEffect, useState } from "react";
import { ClockCircleOutlined, BankOutlined} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import env from "../../../environment";
import { useSelector } from "react-redux";
import { trace } from "../../../components/tagmanager";
import { useParams, useLocation } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";

function BankAnalysis({ data, userMortgageId }) {

  const { t } = useTranslation();
  const userToken = useSelector((state) => state.auth.token);
  const [valuesDataForMortgage, setValuesDataForMortgage] = useState();
  const operations = useSelector((state) => state.auth.operations);
  const { operationId } = useParams();
  const nameOperations = operations !== undefined && operations.find(
    (operation) => operation.operationId === operationId
  )
  const rol = useSelector((state) => state.auth.roles);
  const location = useLocation();
  const analytics = getAnalytics();


  useEffect(() => {

    if (data.name === "WIDGET_BANK_ANALYSIS") {
      trace({
        location,
        userToken,
        rol,
        stage: 'analysis',
        operationName: (nameOperations && nameOperations.name),
        //extra: operationsData
    });
    logEvent(analytics, "screen_view", {
      screen_name: "m.component.analysis"
    })

    }else{
      trace({
        location,
        userToken,
        rol,
        stage: 'banks',
        operationName: (nameOperations && nameOperations.name),
        //extra: operationsData
    });
    logEvent(analytics, "screen_view", {
      screen_name: "m.component.banks"
    })

    }
   

  axios
  .get(
    `${env.api.url}/v1/documents/request-operation-documentation/${userMortgageId}`,
    {
      headers: { Authorization: `Bearer ${userToken}` },
    }
  )
  .then((response) => {
    setValuesDataForMortgage(response.data.data.documentsNeeded);
    
  })
  .catch((error) => {
    console.log(error)
  });

}, []);

  return (
    <div>
      {data.name === "WIDGET_BANK_ANALYSIS" && (
        <div style={{ fontSize: "12px" }}>  {valuesDataForMortgage === 0 ? "" : t("analysis.title")}
          <div><ClockCircleOutlined style={{ fontSize: "25px", marginTop: "30px", color: "#02C3CD" }} /></div>
          <div style={{ marginLeft: "40px", marginTop: "-30px", fontSize: "12px" }}>
          {valuesDataForMortgage === 0 ? t("analysis.analysis2") : t("analysis.analysis")}
        </div>
        </div>
      )}
      {data.name !== "WIDGET_BANK_ANALYSIS" && (
        <div style={{ fontSize: "12px" }}>{t("bank.title")}
          <div><BankOutlined style={{ fontSize: "25px", marginTop: "30px", color: "#02C3CD" }} /></div>
          <div style={{ marginLeft: "40px", marginTop: "-30px", fontSize: "12px" }}>
          {t("bank.bank")}
        </div>
        </div>
      )}
    </div>
  );
}

export default BankAnalysis;
