import React from "react";
import { Steps, Typography } from "antd";
import { useTranslation } from "react-i18next";
import stepGif from "../../../../assets/step.gif";
import { isMobile } from "react-device-detect";

const { Step } = Steps;
const { Text } = Typography;

const StepsNewStyle = ({ stage, dataSteps, height }) => {
  //STAGES Etapa en que se encuentra la operación y DATASTAGES es la información del titulo y descripción de cada step.
  const { t } = useTranslation();
  return (
    <Steps
      className={
        isMobile
          ? "gibobs-steps-new-style gibobs-steps-new-style-mobile"
          : "gibobs-steps-new-style"
      }
      direction="vertical"
      size="small"
      status="error"
      icon="none"
      current={stage}
      style={{
        height: height,
      }}
    >
      {dataSteps &&
        dataSteps.map((stages, index) => {
          return (
            <Step
              key={index}
              icon={
                stage === stages.numStages && (
                  <img
                    alt="loading"
                    src={stepGif}
                    width="24px"
                    height="24px"
                  ></img>
                )
              }
              status={stage === stages.numStages && "process"}
              title={t(stages.title)}
              description={
                stage <= stages.numStages && (
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      color: stage === stages.numStages ? "#748EA0" : "#C0DAEE",
                    }}
                  >
                    {t(stages.info)}
                  </Text>
                )
              }
            />
          );
        })}
    </Steps>
  );
};
export default StepsNewStyle;
