import React from "react";
import { useTranslation } from 'react-i18next';
import { Progress } from "antd";

const StepPromoterMobile = ({ steps, stage }) => {
    const { t } = useTranslation();

    return (
        <div className="progress-by-steps-promoter-mobile">
            <div className="steps-and-title">
                <div style={{ display: "flex" }}>
                    <div
                        style={{
                            color: "#02C3CD",
                            fontWeight: 600,
                            fontSize: 14,
                            lineHeight: "24px",
                        }}
                    >
                        {stage === 1 && t('promoter.generalData')}
                        {stage === 2 && t('promoter.economicData')}
                        {stage === 3 && t('promoter.capitalStructure')}
                    </div>
                </div>
                <Progress
                    className="steps-mobile"
                    percent={(stage * 100) / steps}
                    steps={steps}
                    showInfo={false}
                    strokeColor={"#02C3CD"}
                    trailColor={"#C0F0F3"}
                    strokeWidth={4}
                />
            </div>
        </div>
    );
};

export default StepPromoterMobile;
