import React, { useState } from "react";
import { Layout, Card, Typography, Collapse, Form, Col, Row, InputNumber, Input, Select, Switch, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import env from "../../environment";
import MyBreadcrumb from "../MyBreadcrumb";
import { Button } from 'antd';
import bajoRasante from '../../assets/icons/bajo_rasante.png'
import sobreRasante from '../../assets/icons/sobre_rasante.png'

import numeral from 'numeral'
import { isMobile } from "react-device-detect";
import { ProvinceSelector } from "afrodita";

const { Text } = Typography
const { Panel } = Collapse;
const { Option } = Select;


const PromoterLoanBuildToSell = () => {
    const navigate = useNavigate();;
    const location = useLocation();
    const promoterLoanData =
        location.state !== null && location.state !== undefined
            ? location.state.data
            : undefined;
    const [housingTotal, setHousingTotal] = useState(0);
    const [garagesTotal, setGaragesTotal] = useState(0);
    const [storageRoomTotal, setStorageRoomTotal] = useState(0);
    const [commercialTotal, setCommercialTotal] = useState(0);
    const [housingChecked, setHousingChecked] = useState(true);
    const [garagesChecked, setGaragesChecked] = useState(true);
    const [storageRoomChecked, setStorageRoomChecked] = useState(true);
    const [commercialChecked, setCommercialChecked] = useState(true);
    const [housingIncomesTotalPuntal, setHousingIncomesTotalPuntal] = useState(0);
    const [garagesIncomesTotalPuntal, setGaragesIncomesTotalPuntal] = useState(0);
    const [storageRoomIncomesTotalPuntal, setStorageRoomIncomesTotalPuntal] = useState(0);
    const [commercialIncomesTotalPuntal, setCommercialIncomesTotalPuntal] = useState(0);
    const [housingRest, setHousingRest] = useState(0);
    const [garagesRest, setGaragesRest] = useState(0);
    const [storageRoomRest, setStorageRoomRest] = useState(0);
    const [commercialRest, setCommercialRest] = useState(0);

    const { t } = useTranslation();
    const userToken = useSelector((state) => state.auth.token);

    const data = [
        {
            name: t("menuComponent.productsNav"),
            link: "/products-business",
        },
        {
            name: "Prestamo promotor",
            link: "/sales-tools/promoter-loan",
        },
        {
            name: "Build to Sell",
            link: "/sales-tools/promoterLoan/promoter-loan-build-to-sell",
        },
    ];

    const constuctionDetails = [{ name: 'Viviendas', title: 'housing', disabledOption: housingChecked }, { name: 'Garajes', title: 'garages', disabledOption: garagesChecked }, { name: 'Trasteros', title: 'storageRoom', disabledOption: storageRoomChecked }, { name: 'Comercial', title: 'commercial', disabledOption: commercialChecked }]

    const onchangeRest = (e, data) => {
        if (data === "housing") {
            setHousingRest(housingRest + parseInt(e))
        } else if (data === "garages") {
            setGaragesRest(garagesRest + parseInt(e))
        } else if (data === "storageRoom") {
            setStorageRoomRest(storageRoomRest + parseInt(e))
        } else if (data === "commercial") {
            setCommercialRest(commercialRest + parseInt(e))
        }
    }

    const onchangeTotal = (e, data) => {
        if (data === "housing") {
            setHousingTotal(parseInt(e))
        } else if (data === "garages") {
            setGaragesTotal(parseInt(e))
        } else if (data === "storageRoom") {
            setStorageRoomTotal(parseInt(e))
        } else if (data === "commercial") {
            setCommercialTotal(parseInt(e))
        }
    }
    const onchangeRestaTotal = (data) => {
        if (data === "housing") {
            return numeral(housingTotal - housingRest).format('0,0')
        } else if (data === "garages") {
            return numeral(garagesTotal - garagesRest).format('0,0')
        } else if (data === "storageRoom") {
            return numeral(storageRoomTotal - storageRoomRest).format('0,0')
        } else if (data === "commercial") {
            return numeral(commercialTotal - commercialRest).format('0,0')
        }
    }
    const onchangeChecked = (e, data) => {
        if (data === "housing") {
            setHousingChecked(e)
        } else if (data === "garages") {
            setGaragesChecked(e)
        } else if (data === "storageRoom") {
            setStorageRoomChecked(e)
        } else if (data === "commercial") {
            setCommercialChecked(e)
        }
    }
    // const onchangeIncomeChecked = (e, data) => {
    //     if (data === "housing") {
    //         setHousingIncomesChecked(e)
    //     } else if (data === "garages") {
    //         setGaragesIncomesChecked(e)
    //     } else if (data === "storageRoom") {
    //         setStorageRoomIncomesChecked(e)
    //     } else if (data === "commercial") {
    //         setCommercialIncomesChecked(e)
    //     }
    // }
    const modalSuccess = () => {
        Modal.success({
            className: "ModalCommonConfirmPaid",
            title: t("promoterLoan.ModalTitleSuccess"),
            icon: null,
            okText: t("form.ok"),
            // okText: "Subir documentación",
            okType: "primary",
            content: (
                <div>
                    <div
                        style={{
                            display: "flex",
                            marginTop: "24px",
                            flexFlow: "wrap",
                        }}
                    >
                        <Text>{t("promoterLoan.ModalTextSuccess")}</Text>
                    </div>
                </div>
            ),
            onOk() {
                navigate("/home");
            },
        });
    };
    const modalError = () => {
        Modal.error({
            className: "ModalCommonConfirmPaid",
            title: t("promoterLoan.ModalTitleError"),
            icon: null,
            okText: t("form.ok"),
            // okText: "Subir documentación",
            okType: "primary",
            content: (
                <div>
                    <div
                        style={{
                            display: "flex",
                            marginTop: "24px",
                            flexFlow: "wrap",
                        }}
                    >
                        <Text>{t("promoterLoan.ModalTextError")}</Text>
                    </div>
                </div>
            ),
            onOk() {
                // navigate("/home");
            },
        });
    };

    const onFinish = (values) => {

        var buildingDetails = new Object();
        if (housingChecked) {
            buildingDetails.housing = {
                "total": housingTotal,
                "prereserved": Number(values.housingpreserved),
                "reserved": Number(values.housingreserved),
                "notSold": values.housingtotal - values.housingreserved - values.housingpreserved,
            };
        }
        if (garagesChecked) {
            buildingDetails.garages = {
                "total": garagesTotal,
                "prereserved": Number(values.garagespreserved),
                "reserved": Number(values.garagesreserved),
                "notSold": values.garagestotal - values.garagesreserved - values.garagespreserved,
            };
        }
        if (storageRoomChecked) {
            buildingDetails.storageRooms = {
                "total": storageRoomTotal,
                "prereserved": Number(values.storageRoompreserved),
                "reserved": Number(values.storageRoomreserved),
                "notSold": values.storageRoomtotal - values.storageRoomreserved - values.storageRoompreserved,
            }
        }
        if (commercialChecked) {
            buildingDetails.commercial = {
                "total": commercialTotal,
                "prereserved": Number(values.commercialpreserved),
                "reserved": Number(values.commercialreserved),
                "notSold": values.commercialtotal - values.commercialreserved - values.commercialpreserved,
            }
        }

        var businessPlan = new Object();
        if (housingChecked) {
            businessPlan.housingActualIncomes = values.housingtotalIncome
        }
        if (garagesChecked) {
            businessPlan.garagesActualIncomes = values.garagestotalIncome
        }
        if (storageRoomChecked) {
            businessPlan.storageRoomsActualIncomes = values.storageRoomtotalIncome
        }
        if (commercialChecked) {
            businessPlan.commercialActualIncomes = values.commercialtotalIncome
        }
        var building = new Object();
        if(promoterLoanData && promoterLoanData.field){
            building.type = "sell"
            building.status = promoterLoanData.buildStatusSell
            building.field = promoterLoanData.field[0]
        }else{
            building.type = "sell"
            building.status = promoterLoanData.buildStatusSell
        }


        const dataEmail =
        {
            "promoter": {
                "name": promoterLoanData.nameOrSocial,
                "cif": (promoterLoanData.cif).toString(),
                "address": promoterLoanData.registeredOffice,

            },
            "contact": {
                "name": promoterLoanData.name,
                "surname": promoterLoanData.surname,
                "phone": promoterLoanData.phone,
                "email": promoterLoanData.email
            },
            "building": {
               ...building 
            },
            "promotion": {
                "location": values.location,
                "province": values.province,
                "residenceType": values.residenceType,
                "constructionType": values.typeConstruction
            },
            "measurements": {
                "plotArea": Number(values.plotArea),
                "occupancyArea": Number(values.occupancyArea),
                "maximumBuildableArea": Number(values.maximumBuildableArea),
                "builtResidential": Number(values.builtResidential),
                "builtCommercial": Number(values.builtCommercial),
                "builtOthers": Number(values.builtOthers),
                "builtBelowGrade": Number(values.builtBelowGrade)
            },
            "buildingDetails": { ...buildingDetails },
            "businessPlan": {
                "totalCost": values.totalCost,
                "totalIncomes": housingIncomesTotalPuntal + garagesIncomesTotalPuntal + storageRoomIncomesTotalPuntal + commercialIncomesTotalPuntal,
                ...businessPlan,
            }
        }
        axios
            .post(`${env.api.url}/v1/promoter-loan/create`, dataEmail, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            .then((response) => {
                if (response.data.success) {
                    modalSuccess()
                    console.log(dataEmail)
                    // message.success("Sus datos se actualizaron Exitosamente");
                } else {
                    modalError()
                    // message.error("Algo fue mal y no se pudo guardar");
                }
            })


        console.log(dataEmail)
    }



    const steps = [
        {
            title: '',
            content: <>
                <Form
                    style={{ marginTop: '14px' }}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Collapse
                        style={{
                            marginBottom: 16,
                        }}
                        defaultActiveKey={['1']}
                        expandIconPosition="end"
                    >
                        <Panel
                            header={t("promoterLoan.promotionData")}
                            key='1'
                            style={{ fontSize: "12px" }}
                        >
                            <Row gutter={24} style={{ marginTop: '14px' }}>
                                <Col lg={6} md={8} sm={12} xs={24}>
                                    <Form.Item
                                        label={t("promoterLoan.location")}
                                        name="location"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input
                                            style={{
                                                borderColor: "#c0daee",
                                                width: "100%",
                                                borderRadius: "4px",
                                            }}

                                            placeholder={t("promoterLoan.location")}
                                            formatter={(value) =>
                                                value !== "" ? numeral(value).format("0,0 $") : null
                                            }
                                            parser={(value) => numeral(value).value()}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col lg={6} md={8} sm={12} xs={24}>
                                    <Form.Item
                                        label={t("form.provinces")}
                                        name="province"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <ProvinceSelector
                                            placeholder={t("form.provinces")}
                                            style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col lg={6} md={8} sm={12} xs={24}>
                                    <Form.Item
                                        label={t("promoterLoan.residenceType")}
                                        name="residenceType"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder={t("form.select")}
                                        >
                                            <Option value="firstResidence">{t("promoterLoan.firstResidence")}</Option>
                                            <Option value="secondResidence">{t("form.second")}</Option>
                                            <Option value="mixed">{t("promoterLoan.mixedResidence")}</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={6} md={8} sm={12} xs={24}>
                                    <Form.Item
                                        label={t("promoterLoan.typeConstruction")}
                                        name="typeConstruction"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder={t("form.select")}
                                        >
                                            <Option value="multiFamily">{t("promoterLoan.multiFamily")}</Option>
                                            <Option value="multiFamiliCommercial">{t("promoterLoan.multiFamilyCommercial")}</Option>
                                            <Option value="singleFamily">{t("promoterLoan.singleFamily")}</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                    <Collapse
                        expandIconPosition="end"
                        defaultActiveKey={['2']}
                    >
                        <Panel
                            key='2'
                            header={t("promoterLoan.constructionDetail")}
                            style={{ fontSize: "12px" }}
                        >
                            {constuctionDetails && constuctionDetails.map((data, index) => {
                                return <Row key={index} gutter={24} style={{ justifyContent: 'center' }}>
                                    <Col lg={4} md={8} sm={12} xs={24} style={{ alignSelf: index === 0 ? 'center' : null, marginTop: '20px' }}>
                                        <Switch defaultChecked className="SwitchColorPromoter" onChange={(value) => onchangeChecked(value, data.title)} />
                                        <Text style={{ color: '#2F4858', fontSize: 14, marginLeft: 8, fontWeight: 500 }}>
                                            {data.name}
                                        </Text>
                                    </Col>

                                    <Col lg={3} md={8} sm={12} xs={24} style={{ marginTop: '20px' }} >
                                        <Form.Item
                                            label={index === 0 ? <Text style={{ fontSize: '14px' }}>{t("promoterLoan.numberTotal")}</Text> : null}
                                            name={`${data.title}total`}
                                        >
                                            <Input
                                                disabled={!data.disabledOption}
                                                onBlur={(e) => onchangeTotal(e.target.value, data.title)}
                                                style={{ width: '100%' }}
                                                placeholder={t("promoterLoan.numberTotal")}
                                            >
                                            </Input>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={3} md={8} sm={12} xs={24} style={{ marginTop: '20px' }}>
                                        <Form.Item
                                            label={index === 0 ? <Text style={{ fontSize: '14px' }}>{t("promoterLoan.preserved")}</Text> : null}
                                            name={`${data.title}preserved`}
                                        >
                                            <Input
                                                disabled={!data.disabledOption}
                                                onBlur={(e) => onchangeRest(e.target.value, data.title)}
                                                style={{ width: '100%' }}
                                                placeholder={t("promoterLoan.preserved")}
                                            >
                                            </Input>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={3} md={8} sm={12} xs={24} style={{ marginTop: '20px' }}>
                                        <Form.Item
                                            label={index === 0 ? <Text style={{ fontSize: '14px' }}>{t("promoterLoan.reserved")}</Text> : null}
                                            name={`${data.title}reserved`}
                                        >
                                            <Input
                                                disabled={!data.disabledOption}
                                                onBlur={(e) => onchangeRest(e.target.value, data.title)}
                                                style={{ width: '100%' }}
                                                placeholder={t("promoterLoan.reserved")}
                                            >
                                            </Input>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={3} md={8} sm={12} xs={24} style={{ marginTop: '20px' }}>
                                        {index === 0 ? <label for="html" style={{ fontSize: "14px" }}>{t("promoterLoan.notSold")}</label> : null}

                                        <input value={onchangeRestaTotal(data.title)} disabled={true} style={{ border: "1px solid #F1F7F8", height: '32px', width: "100%", backgroundColor: "#F1F7F8", color: "#C0DAEE", borderRadius: "4px", marginTop: index === 0 ? '10px' : 0, textAlign: 'center' }}></input>

                                    </Col>
                                </Row>
                            })}
                        </Panel>
                    </Collapse>
                    <Collapse
                        defaultActiveKey={['4']}
                        style={{
                            marginBottom: "8px",
                            marginTop: 16,
                        }}
                        expandIconPosition="end"
                    >
                        <Panel
                            header={t("promoterLoan.surfaceDetail")}
                            style={{ fontSize: "12px" }}
                            key='4'
                        >
                            <div style={{ marginTop: 16 }}>
                                <Text style={{ color: "#124887", fontSize: '12px', fontWeight: '500' }}>
                                    {t("promoterLoan.surfaceMeasurements")}
                                </Text>
                            </div>

                            <Row gutter={24} style={{ marginTop: '24px' }}>
                                <Col lg={6} md={8} sm={12} xs={24}>
                                    <Form.Item
                                        label={t("promoterLoan.plotArea")}
                                        name="plotArea"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input
                                            style={{
                                                borderColor: "#c0daee",
                                                width: isMobile ? "100%" : "80%",
                                                borderRadius: "4px",
                                            }}

                                            placeholder={t("promoterLoan.plotArea")}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col lg={6} md={8} sm={12} xs={24}>
                                    <Form.Item
                                        label={t("promoterLoan.occupancyArea")}
                                        name="occupancyArea"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input
                                            style={{
                                                borderColor: "#c0daee",
                                                width: isMobile ? "100%" : "80%",
                                                borderRadius: "4px",
                                            }}

                                            placeholder={t("promoterLoan.occupancyArea")}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={6} md={8} sm={12} xs={24}>
                                    <Form.Item
                                        label={t("promoterLoan.maximumBuildableArea")}
                                        name="maximumBuildableArea"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input
                                            style={{
                                                borderColor: "#c0daee",
                                                width: isMobile ? "100%" : "80%",
                                                borderRadius: "4px",
                                            }}

                                            placeholder={t("promoterLoan.maximumBuildableArea")}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>


                            <Row gutter={24} style={{ marginTop: '24px' }}>
                                <Col lg={18}>
                                    <Row gutter={24} style={{ marginBottom: '16px', marginLeft: '8px' }}>
                                        <div>
                                            <img src={sobreRasante} alt='Sobre Rasante' height={24} width={24} />
                                            <Text style={{ color: "#124887", fontSize: '12px', fontWeight: '500', marginLeft: 18 }}>
                                                {t("promoterLoan.builtSurface")}
                                            </Text>
                                        </div>
                                    </Row>
                                    <Row gutter={24}>


                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <Form.Item
                                                label={t("promoterLoan.builtResidential")}
                                                name="builtResidential"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    style={{
                                                        borderColor: "#c0daee",
                                                        width: isMobile ? "100%" : "80%",
                                                        borderRadius: "4px",
                                                    }}

                                                    placeholder={t("promoterLoan.builtResidential")}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col lg={8} md={8} sm={12} xs={24}>

                                            <Form.Item
                                                label={t("promoterLoan.builtCommercial")}
                                                name="builtCommercial"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    style={{
                                                        borderColor: "#c0daee",
                                                        width: isMobile ? "100%" : "80%",
                                                        borderRadius: "4px",
                                                    }}

                                                    placeholder={t("promoterLoan.builtCommercial")}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <Form.Item
                                                label={t("promoterLoan.builtOthers")}
                                                name="builtOthers"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    style={{
                                                        borderColor: "#c0daee",
                                                        width: isMobile ? "100%" : "80%",
                                                        borderRadius: "4px",
                                                    }}

                                                    placeholder={t("promoterLoan.builtOthers")}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6}>
                                    <Row gutter={24}>

                                        <div style={{ marginBottom: '16px' }}>
                                            <img src={bajoRasante} alt='Bajo Rasante' height={24} width={24} />
                                            <Text style={{ color: "#124887", fontSize: '12px', fontWeight: '500', marginLeft: 18 }}>
                                                {t("promoterLoan.builtSurfaceBelow")}
                                            </Text>
                                        </div>

                                        <Col lg={24} md={8} sm={12} xs={24}>
                                            <Form.Item
                                                label={t("promoterLoan.builtSurfaceBelow")}
                                                name="builtBelowGrade"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    style={{
                                                        borderColor: "#c0daee",
                                                        width: isMobile ? "100%" : "80%",
                                                        borderRadius: "4px",
                                                    }}

                                                    placeholder={t("promoterLoan.builtBelowGrade")}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>



                        </Panel>
                    </Collapse>
                    <Collapse
                        defaultActiveKey={['5']}
                        expandIconPosition="end"
                    >
                        <Panel
                            key='5'
                            header="Datos principales del business plan"
                            style={{ fontSize: "12px" }}
                        >
                            <Row gutter={24}>
                                <Col lg={10} md={24} sm={24} xs={24}>
                                    <div style={{ marginTop: 16, marginBottom: 16 }}>
                                        <Text style={{ color: "#124887", fontSize: '12px', fontWeight: '500' }}>
                                            {t("promoterLoan.costs")}
                                        </Text>
                                    </div>
                                    <Form.Item
                                        label={t("promoterLoan.totalCost")}
                                        name="totalCost"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                        step={10000}
                                            style={{
                                                borderColor: "#c0daee",
                                                width: "80%",
                                                borderRadius: "4px",
                                            }}
                                            placeholder={t("promoterLoan.totalCost")}
                                            formatter={(value) =>
                                                value !== "" ? numeral(value).format("0,0 $") : null
                                            }
                                            parser={(value) => numeral(value).value()}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={10} md={24} sm={24} xs={24}>
                                    <div style={{ marginTop: 16, marginBottom: 16 }}>
                                        <Text style={{ color: "#124887", fontSize: '12px', fontWeight: '500' }}>
                                            {t("balance.incomesTitle")}
                                        </Text>
                                    </div>
                                    {/* {detailData && detailData.map(data => {
                                        return (
                                            <Row span={24}>
                                                <Col lg={8} md={8} xs={24} style={{ alignSelf: 'center' }}>
                                                    <Text>{data.name}</Text>
                                                </Col>
                                                <Col lg={12} md={12} xs={24} style={{ alignSelf: 'center', marginTop: '20px' }}>
                                                    <Form.Item
                                                        label=""
                                                        name={`${data.title}totalIncome`}

                                                    >
                                                        {`${data.title}Checked` ? <Input style={{ width: '100%' }} placeholder={t("newRequest.incomeTotal")} onBlur={(e) => setSuma(suma + parseInt(e.target.value))} /> : null}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        )
                                    })} */}

                                    <Row span={10}>
                                        {housingChecked ? <><Col lg={8} md={8} xs={24} style={{ alignSelf: 'center' }}>
                                            <Text>Viviendas</Text>
                                        </Col>
                                            <Col lg={10} md={12} xs={24} style={{ alignSelf: 'center', marginTop: '20px' }}>
                                                <Form.Item
                                                    label=""
                                                    name='housingtotalIncome'

                                                >
                                                    <InputNumber
                                                        step={10000}
                                                        style={{
                                                            borderColor: "#c0daee",
                                                            width: "100%",
                                                            borderRadius: "4px",
                                                        }}
                                                        formatter={(value) =>
                                                            numeral(value).format("0,0[.]00 $")
                                                        }
                                                        parser={(value) => numeral(value).value()}
                                                        placeholder={t("newRequest.incomeTotal")}
                                                        onBlur={(e) => setHousingIncomesTotalPuntal(numeral(e.target.value).value())}
                                                    />
                                                </Form.Item> </Col>
                                        </> : null}
                                        {garagesChecked ? <><Col lg={8} md={8} xs={24} style={{ alignSelf: 'center' }}>
                                            <Text>Garages</Text>
                                        </Col>
                                            <Col lg={10} md={12} xs={24} style={{ alignSelf: 'center', marginTop: '20px' }}>
                                                <Form.Item
                                                    label=""
                                                    name='garagestotalIncome'

                                                >
                                                    <InputNumber
                                                        step={10000}
                                                        style={{
                                                            borderColor: "#c0daee",
                                                            width: "100%",
                                                            borderRadius: "4px",
                                                        }}
                                                        formatter={(value) =>
                                                            numeral(value).format("0,0[.]00 $")
                                                        }
                                                        parser={(value) => numeral(value).value()}
                                                        placeholder={t("newRequest.incomeTotal")}
                                                        onBlur={(e) => setGaragesIncomesTotalPuntal(numeral(e.target.value).value())}
                                                    />
                                                </Form.Item> </Col>
                                        </> : null}
                                        {storageRoomChecked ? <><Col lg={8} md={8} xs={24} style={{ alignSelf: 'center' }}>
                                            <Text>Trasteros</Text>
                                        </Col>
                                            <Col lg={10} md={12} xs={24} style={{ alignSelf: 'center', marginTop: '20px' }}>
                                                <Form.Item
                                                    label=""
                                                    name="storageRoomtotalIncome"

                                                >
                                                    <InputNumber
                                                        step={10000}
                                                        style={{
                                                            borderColor: "#c0daee",
                                                            width: "100%",
                                                            borderRadius: "4px",
                                                        }}
                                                        formatter={(value) =>
                                                            numeral(value).format("0,0[.]00 $")
                                                        }
                                                        parser={(value) => numeral(value).value()}
                                                        placeholder={t("newRequest.incomeTotal")}
                                                        onBlur={(e) => setStorageRoomIncomesTotalPuntal(numeral(e.target.value).value())}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </> : null}
                                        {commercialChecked ? <><Col lg={8} md={8} xs={24} style={{ alignSelf: 'center' }}>
                                            <Text>Comercial</Text>
                                        </Col>
                                            <Col lg={10} md={12} xs={24} style={{ alignSelf: 'center', marginTop: '20px' }}>
                                                <Form.Item
                                                    label=""
                                                    name="commercialtotalIncome"

                                                >
                                                    <InputNumber
                                                        step={10000}
                                                        style={{
                                                            borderColor: "#c0daee",
                                                            width: "100%",
                                                            borderRadius: "4px",
                                                        }}
                                                        formatter={(value) =>
                                                            numeral(value).format("0,0[.]00 $")
                                                        }
                                                        parser={(value) => numeral(value).value()}
                                                        placeholder={t("newRequest.incomeTotal")}
                                                        onBlur={(e) => setCommercialIncomesTotalPuntal(numeral(e.target.value).value())}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </> : null}

                                    </Row>
                                    <Row span={12}>
                                        <Col lg={8} md={8} xs={24} style={{ alignSelf: 'center' }}>
                                            <Text>{data.name}</Text>
                                        </Col>
                                        <Col lg={10} md={12} xs={24} style={{ alignSelf: 'center', marginTop: '20px' }}>

                                        </Col>
                                    </Row>

                                    {/* <Row span={24}>
                                        <Col lg={8} md={8} xs={24} style={{ alignSelf: 'center' }}>
                                            <Text>{data.name}</Text>
                                        </Col>
                                        <Col lg={12} md={12} xs={24} style={{ alignSelf: 'center', marginTop: '20px' }}>
                                            <Form.Item
                                                label=""
                                                name={`${data.title}totalIncome`}

                                            >
                                                {housingChecked ? <Input style={{ width: '100%' }} placeholder={t("newRequest.incomeTotal")} onBlur={(e) => setSuma(suma + parseInt(e.target.value))} /> : null}
                                            </Form.Item>
                                        </Col>
                                    </Row> */}

                                    <Row span={12}>
                                        <Col lg={8} md={8} xs={24} style={{ alignSelf: 'center' }}>

                                        </Col>
                                        <Col lg={10} md={12} xs={24} style={{ alignSelf: 'center', marginTop: '20px' }}>
                                            <label for="html">{t("promoterLoan.totalCostWithoutIVA")}</label><br></br>
                                            <input value={numeral(housingIncomesTotalPuntal + garagesIncomesTotalPuntal + storageRoomIncomesTotalPuntal + commercialIncomesTotalPuntal).format('0,0 $')} disabled={true} style={{ border: "1px solid #F1F7F8", height: '32px', width: "100%", backgroundColor: "#F1F7F8", color: "#C0DAEE", borderRadius: "4px", marginTop: '10px', textAlign: 'center' }}></input>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button htmlType="submit" type="primary" className='button-primari-gibobs' style={{ marginTop: 32 }}>{t("form.send")}</Button>
                    </div>
                </Form>
            </>
            ,
        },
        {
            title: '',
            content: 'Second-content',
        },
        {
            title: '',
            content: 'Last-content',
        },
    ];
    return (
        <Layout>
            <MyBreadcrumb data={data}></MyBreadcrumb>

            <div style={{ height: "auto", marginBottom: "100px" }}>

                <Card className='CardStyleClientGibobs' style={{ marginTop: '14px' }}>
                    <Text style={{ color: '#2F4858', fontSize: '14px', fontWeight: '600' }}>
                        Solicitud de prestamo promotor
                    </Text>
                    {/* <Steps current={current} style={{ margin: 'auto', width: '60%', marginTop: 32, marginBottom: 32 }} className='stepsLoanPromoter'>
                        {steps.map(item => (
                            <Step key={item.title} title={item.title} />
                        ))}
                    </Steps> */}
                    <div className="steps-content">{steps[0].content}</div>


                </Card>
            </div>
        </Layout>
    );
};

export default PromoterLoanBuildToSell;