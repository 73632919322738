import React, { useEffect, useState } from 'react';
import { Row, Button, Card, Skeleton, Col, Typography, Form, Slider, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	EditOutlined,
	EuroCircleOutlined,
	InfoCircleOutlined,
	WarningOutlined
} from '@ant-design/icons';

import { useSelector } from 'react-redux';
import axios from 'axios';
import env from '../../../../environment';
import numeral from 'numeral';
import _ from 'lodash';
import ModalWithoutButtons from '../../../Financiero/Loans/Modals/ModalWithoutButtons';
import MortgageAmountModal from './../Modals/MortgageAmountModal';
import calculatorImage from '../../../../assets/calculators/calculatorImage.png';
import { InputNumber } from 'afrodita';

const { Text } = Typography;

const CalculatorsFinancingMobile = ({ locationTmpText, createMortgage }) => {
	const location = useLocation();
	const { t } = useTranslation();
	const rol = useSelector((state) => state.auth.roles);
	const navigate = useNavigate();;
	const [loading, setLoading] = useState(true);
	const [form] = Form.useForm();
	const [term, setTerm] = useState(false);
	const [amountHp, setAmountHp] = useState(false);
	const [rate, setRate] = useState(false);
	const [finish, setFinish] = useState(false);
	const dataProfile = useSelector((state) => state.data.dataProfile);
	const [result, setResult] = useState(undefined);
	const userToken = useSelector((state) => state.auth.token);
	const [fixed, setFixed] = useState(1.9);
	const [quotes, setQuotes] = useState(360);
	const [price, setPrice] = useState(10000);
	const [, setSavings] = useState(55000);
	const [, setProvince] = useState(dataProfile && dataProfile.province);
	const [showFirstModal, setShowFirstModal] = useState(true);
	const [houseType, setHouseType] = useState('main');
	const [resultValuesForm, setResultValuesForm] = useState(undefined);
	const [priceTouch, setPriceTouch] = useState(location.state ? location.state.price : undefined);
	const [timeoutReference, setTimeoutReference] = useState(undefined);
	const dataSummary = useSelector((state) => state.data.dataSummary);
	const [, setDataUltimateSimulation] = useState(undefined);
	const [idUltimateSimulation, setIdUltimateSimulation] = useState(undefined);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, [1500]);

		if (!_.isNil(location.state)) {
			setResult(location.state.result);
			setResultValuesForm(location.state);

			setShowFirstModal(false);
		}
		getUltimateSimulation();
	}, []);

	useEffect(() => {
		if (
			result &&
			result?.bonilla &&
			result?.bonilla[1]?.maxBudget !== price &&
			priceTouch === undefined
		) {
			setPrice(result?.bonilla[1]?.maxBudget);
		} else if (priceTouch !== undefined) {
			setPrice(priceTouch);
		}
		if (typeof result === 'string') {
			setPrice(undefined);
		}
	}, [result && result?.bonilla && result?.bonilla[1]?.maxBudget]);

	const getUltimateSimulation = () => {
		axios
			.get(
				`${env.api.url}/mortgages/simulations/get-last-simulation?simulationMortgageType=ROOF_CALCULATION`,
				{
					headers: {
						Authorization: `Bearer ${userToken}`
					}
				}
			)
			.then((response) => {
				setDataUltimateSimulation(response.data.data);
				if (response.data.data.id) {
					setShowFirstModal(false);
					setIdUltimateSimulation(response.data.data.id);
					firtsCallSimulation(response.data.data);
				}
			});
	};

	const firtsCallSimulation = (allValues) => {
		const objValues = {
			id: allValues.id,
			savings: allValues.simulationData.savings,
			propertyType: allValues.simulationData.propertyType,
			simulationType: 'B2C',
			numerousFamily: false,
			province: allValues.simulationData.province,
			maxBudget: allValues.simulationData.maxBudget,
			fixed: allValues.simulationData.fixed,
			quotes: allValues.simulationData.quotes,
			participants: {
				houseType: allValues.simulationData.houseType,
				participants: allValues.simulationData.owner2 ? 2 : 1,
				owners: [allValues.simulationData.owner1, allValues.simulationData.owner2]
			}
		};
		if (_.isNil(allValues.simulationData.owner2)) {
			objValues.participants.participants = 1;
			objValues.participants.owners = [allValues.simulationData.owner1];
		} else {
			objValues.participants.participants = 2;
			objValues.participants.owners = [
				allValues.simulationData.owner1,
				allValues.simulationData.owner2
			];
		}
		axios
			.post(
				`${env.api.url}/mortgages/simulations/client-qualification/mortgage-viability`,
				objValues,
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				setResult(response.data.data);
				if (typeof response.data.data === 'string') {
					setPrice(undefined);
				} else if (response.data.data.simulation.simulationData.maxBudget) {
					setPrice(response.data.data.simulation.simulationData.maxBudget);
				} else {
					setPrice(response.data.data?.bonilla[1]?.maxBudget);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const sendFinish = () => {
		setFinish(false);
		createMortgage();
	};

	const onChangeFixed = (value) => {
		setFixed(value);
		const tmp = form.getFieldsValue();
		const tmpValues = {
			fixed: value,
			quotes: undefined
		};
		onChangeForm(tmpValues, tmp, price);
	};

	const onChangeTerm = (value) => {
		setQuotes(value * 12);
		const tmp = form.getFieldsValue();
		const tmpValues = {
			fixed: undefined,
			quotes: value * 12
		};
		onChangeForm(tmpValues, tmp, price);
	};

	const onChangeForm = (values, allValues, price, familyTmp) => {
		if (allValues !== undefined) {
			setSavings(allValues.savings);
			setProvince(allValues.province);
		}

		if (timeoutReference) {
			clearTimeout(timeoutReference);
		}

		setTimeoutReference(
			setTimeout(() => {
				if (result !== undefined && price >= 10000) {
					axios
						.post(
							`${env.api.url}/mortgages/simulations/client-qualification/mortgage-viability`,
							{
								id: idUltimateSimulation
									? idUltimateSimulation
									: !_.isNil(result)
									? result.simulation.id
									: undefined,
								simulationType: rol === 'realestate' ? 'B2B' : 'B2C',
								savings: result.simulation.simulationData.savings,
								propertyType: result.simulation.simulationData.propertyType,
								numerousFamily:
									familyTmp === undefined
										? _.isNil(result.simulation.simulationData.numerousFamily)
											? false
											: result.simulation.simulationData.numerousFamily
										: familyTmp,
								province: result.simulation.simulationData.province,
								maxBudget: price !== 10000 ? price : result.simulation.simulationData.maxBudget,
								fixed:
									values?.fixed !== undefined && fixed !== values?.fixed
										? values?.fixed
										: result.simulation.simulationData.fixed,
								quotes: quotes !== 360 ? quotes : result.simulation.simulationData.quotes,

								participants: {
									houseType: !_.isNil(result.simulation.simulationData.houseType)
										? result.simulation.simulationData.houseType
										: houseType,
									participants:
										result.simulation.simulationData.owner1.activity1 !== undefined ? 2 : 1,
									owners:
										result.simulation.simulationData.owner1.activity1 !== undefined
											? [
													result.simulation.simulationData.owner1,
													result.simulation.simulationData.owner2
											  ]
											: [result.simulation.simulationData.owner1]
								}
							},
							{
								headers: { Authorization: `Bearer ${userToken}` }
							}
						)
						.then((response) => {
							setResult(response.data.data);
						})
						.catch((error) => {
							console.error(error);
						});
				}
				clearTimeout(timeoutReference);
			}, 500)
		);
	};

	const renderTitle = () => {
		if (result.viability.excepcionalViability === 'NOSAVINGS' && rol === 'realestate') {
			return t('onboarding.cantBuyWithoutSavingsInmo');
		} else if (result.viability.excepcionalViability === 'NOSAVINGS' && rol !== 'realestate') {
			return t('onboarding.cantBuyWithoutSavings');
		} else if (result.viability.excepcionalViability === 'DTI_OVER' && rol === 'realestate') {
			return t('onboarding.cantBuyWithoutSavingsInmo');
		} else if (result.viability.excepcionalViability === 'DTI_OVER' && rol !== 'realestate') {
			return t('calculator.warnig3');
		} else if (result.viability.excepcionalViability === 'INCOME_LEVEL' && rol === 'realestate') {
			return t('onboarding.cantBuyWithoutSavingsInmo');
		} else if (result.viability.excepcionalViability === 'INCOME_LEVEL' && rol !== 'realestate') {
			return t('calculatorFinancing.warnig5');
		} else {
			if (result.viability.excepcionalViability === 'MORTGAGE_AMOUNT') {
				return t('onboarding.ItsBetterTellTruth');
			}
		}
	};

	const renderDesc = () => {
		if (result.viability.excepcionalViability === 'NOSAVINGS' && rol === 'realestate') {
			return t('onboarding.cantBuyWithoutSavingsDescriptionInmo');
		} else if (result.viability.excepcionalViability === 'NOSAVINGS' && rol !== 'realestate') {
			return t('onboarding.cantBuyWithoutSavingsDescription');
		} else if (result.viability.excepcionalViability === 'DTI_OVER' && rol === 'realestate') {
			return t('calculator.warnig4Inmo');
		} else if (result.viability.excepcionalViability === 'DTI_OVER' && rol !== 'realestate') {
			return t('calculator.warnig4');
		} else if (result.viability.excepcionalViability === 'INCOME_LEVEL' && rol === 'realestate') {
			return t('calculator.warnig6Inmo');
		} else if (result.viability.excepcionalViability === 'INCOME_LEVEL' && rol !== 'realestate') {
			return t('calculatorFinancing.warnig6');
		} else if (
			result.viability.excepcionalViability === 'MORTGAGE_AMOUNT' &&
			rol === 'realestate'
		) {
			return t('calculator.mortgageAmountTooLowDescription');
		} else if (
			result.viability.excepcionalViability === 'MORTGAGE_AMOUNT' &&
			rol !== 'realestate'
		) {
			return t('calculator.mortgageAmountTooLowDescriptionFinancing');
		}
	};

	const renderMax = () => {
		if (result) {
			if (result.simulation) {
				const maxAge2 = result.simulation.simulationData.owner2
					? result.simulation.simulationData.owner2.age
					: 0;
				const maxAge1 = result.simulation.simulationData.owner1
					? result.simulation.simulationData.owner1.age
					: 40;

				const tmpAge = maxAge2 >= maxAge1 ? maxAge2 : maxAge1;

				const tmpAgeMin = 75 - tmpAge;

				const ageValue = tmpAgeMin <= 40 ? tmpAgeMin : 40;

				return ageValue;
			}
		}
	};

	const renderBarProgress = () => {
		
		return (
			<>
				<div
					style={{
						width: 300,
						display: 'flex',
						marginTop: 24,
						marginBottom: 10
					}}>
					<div
						style={{
							width: '85%' //`${percentOne}%`,
						}}></div>
					<Text
						style={{
							color: '#748EA0',
							fontSize: 12,
							fontWeight: 400,
							width: '50%',
							marginLeft:
								result?.bonilla[2].maxBudget === result?.bonilla[1].maxBudget ? -50 : undefined
						}}>
						{numeral(result?.bonilla[1].maxBudget).format('0,0 $')}
					</Text>
					<div
						style={{
							width: '25%' //`${percentSecond}%`,
						}}></div>
					{result?.bonilla[2].maxBudget !== result?.bonilla[1].maxBudget ? (
						<Text
							style={{
								color: '#748EA0',
								fontSize: 12,
								fontWeight: 400,
								width: '50%'
							}}>
							{numeral(result?.bonilla[2].maxBudget).format('0,0 $')}
						</Text>
					) : undefined}
					<div
						style={{
							width: '25%' //`${total}%`,
						}}></div>
				</div>

				<div style={{ width: 300, display: 'flex' }}>
					<div
						style={{
							width: '50%', //`${percentOne}%`,
							background: '#95DE64',
							height: '5px',
							borderRadius: '8px 0px 0px 8px'
						}}></div>
					<Text style={{ color: '#748EA0', marginTop: -10, marginRight: -1 }}>|</Text>

					<div
						style={{
							width: '25%', //`${percentSecond}%`,
							background:
								result?.bonilla[2].maxBudget === result?.bonilla[1].maxBudget
									? '#FF4D4F'
									: '#FAAD14',
							height: '5px'
						}}></div>
					{result?.bonilla[2].maxBudget !== result?.bonilla[1].maxBudget ? (
						<Text style={{ color: '#748EA0', marginTop: -10, marginRight: -1 }}>|</Text>
					) : undefined}
					<div
						style={{
							width: '25%', //`${total}%`,
							background: '#FF4D4F',
							height: '5px',
							borderRadius: '0px 8px 8px 0px'
						}}></div>
				</div>
			</>
		);
	};

	

	const onChangePrice = (value) => {
		setPrice(value);
		setPriceTouch(value);
		const tmp = form.getFieldsValue();

		const price = value;
		const tmpValues = {
			fixed: undefined,
			quotes: undefined
		};
		onChangeForm(tmpValues, tmp, price);
	};

	const renderResult = () => {
		let value = undefined;
		if (price > 10000) {
			value = price.toFixed('0');
		} else {
			if (result === undefined) {
				value = '';
			} else {
				value = numeral(_.get(result, 'bonilla[1].maxBudget', 0)).format('0,0');
			}
		}

		return value;
	};
	const owners = () => {
		if (result?.simulation?.simulationData?.owner2) {
			return {
				activity1: result?.simulation?.simulationData?.owner1?.activity,
				seniority1: result?.simulation?.simulationData?.owner1?.seniority,
				netIncomes1: result?.simulation?.simulationData?.owner1?.netIncomes,
				loanPayment1: result?.simulation?.simulationData?.owner1?.loanPayment,
				age1: result?.simulation?.simulationData?.owner1?.age,
				cityzenship1: result?.simulation?.simulationData?.owner1?.cityzenship,
				activity2: result?.simulation?.simulationData?.owner2?.activity,
				seniority2: result?.simulation?.simulationData?.owner2?.seniority,
				netIncomes2: result?.simulation?.simulationData?.owner2?.netIncomes,
				loanPayment2: result?.simulation?.simulationData?.owner2?.loanPayment,
				age2: result?.simulation?.simulationData?.owner2?.age,
				cityzenship2: result?.simulation?.simulationData?.owner2?.cityzenship
			};
		} else {
			return {
				activity1: result?.simulation?.simulationData?.owner1?.activity,
				seniority1: result?.simulation?.simulationData?.owner1?.seniority,
				netIncomes1: result?.simulation?.simulationData?.owner1?.netIncomes,
				loanPayment1: result?.simulation?.simulationData?.owner1?.loanPayment,
				age1: result?.simulation?.simulationData?.owner1?.age,
				cityzenship1: result?.simulation?.simulationData?.owner1?.cityzenship
			};
		}
	};

	return (<>
        <div style={{ padding: 16 }}>
            <Col span={24} style={{ textAlign: 'center' }}>
                {!loading && (
                    <div
                        style={{ display: 'flex', justifyContent: 'right' }}
                        onClick={() => {
                           
                            navigate(`/qualify/calculators/fee/datas`,{
                                state: {
                                    result: resultValuesForm ?? {
                                        formValues: {
                                            ...result?.simulation?.simulationData,
                                            ...owners()
                                        },
                                        result: {
                                            simulation: {
                                                id: idUltimateSimulation
                                            }
                                        }
                                    },
                                    price: priceTouch ? priceTouch : result?.simulation?.simulationData?.maxBudget,
                                    quotes: result?.simulation?.simulationData?.term,
                                    fixed: result?.simulation?.simulationData?.fixed,
                                    province: result?.simulation?.province,
                                    edit: true
                                }
                            });
                        }}>
                        <Text style={{ fontWeight: 500, fontSize: 14, color: '#02C3CD' }}>
                            {t('loan.editData')}
                        </Text>
                        <EditOutlined style={{ color: '#02C3CD', fontSize: 20, marginLeft: 4 }} />
                    </div>
                )}

                <Skeleton active loading={loading} paragraph={false} />
                {!loading && (
                    <>
                        {rol === 'realestate' && (
                            <div style={{ marginTop: 16 }}>
                                <Text
                                    style={{
                                        color: '#2F4858',
                                        fontWeight: 600,
                                        fontSize: 16
                                    }}>
                                    {t('qualify.titlte')}
                                </Text>
                            </div>
                        )}

                        {result &&
                            result?.mortgageCeiling &&
                            result?.mortgageCeiling.mortgageAmountLimit === 'savings' && (
                                <Card className="gb-card-background-blue-mobile" style={{ marginTop: 24 }}>
                                    <div>
                                        <EuroCircleOutlined style={{ color: '#02C3CD', fontSize: 32 }} />
                                    </div>
                                    <div style={{ marginTop: 10 }}>
                                        <Text
                                            style={{
                                                color: '#2F4858',
                                                fontSize: 14,
                                                fontWeight: 600
                                            }}>
                                            {t('qualify.warnignLimitClient')}
                                        </Text>
                                    </div>
                                    <div style={{ marginTop: 8 }}>
                                        <Text
                                            style={{
                                                color: '#748EA0',
                                                fontSize: 14,
                                                fontWeight: 400
                                            }}>
                                            {t('qualify.warnignLimit2Client')}
                                        </Text>
                                    </div>
                                </Card>
                            )}

                        {result &&
                            result?.mortgageCeiling &&
                            result?.mortgageCeiling.mortgageAmountLimit !== 'savings' && (
                                <Card className="gb-card-background-blue-mobile" style={{ marginTop: 24 }}>
                                    <div>
                                        <EuroCircleOutlined style={{ color: '#02C3CD', fontSize: 32 }} />
                                    </div>
                                    <div style={{ marginTop: 10 }}>
                                        <Text
                                            style={{
                                                color: '#2F4858',
                                                fontSize: 14,
                                                fontWeight: 600
                                            }}>
                                            {t('qualify.warnignLimit3Client')}
                                        </Text>
                                    </div>
                                    <div style={{ marginTop: 8 }}>
                                        <Text
                                            style={{
                                                color: '#748EA0',
                                                fontSize: 14,
                                                fontWeight: 400
                                            }}>
                                            {t('qualify.warnignLimit4Client')}
                                        </Text>
                                    </div>
                                </Card>
                            )}

                        <div style={{ marginTop: 24 }}>
                            <Text
                                style={{
                                    color: '#2F4858',
                                    fontWeight: 600,
                                    fontSize: 16
                                }}>
                                {t('calculatorFinancing.home')}
                            </Text>
                        </div>
                        <div style={{ marginTop: 24, textAlign: '-webkit-center' }}>
                            {result && result?.bonilla && renderBarProgress()}
                            {result && result.bonilla === undefined && (
                                <>
                                    <Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>{'0 €'}</Text>
                                    <div
                                        style={{
                                            width: '100%', //`${percentOne}%`,
                                            background: '#FF4D4F',
                                            height: '5px',
                                            borderRadius: '8px 0px 0px 8px'
                                        }}></div>
                                </>
                            )}
                        </div>

                        {result?.bonilla !== undefined && (
                            <Row
                                style={{
                                    marginTop: 30,
                                    justifyContent: 'center'
                                }}>
                                <Col span={10} style={{ display: 'flex' }}>
                                    <Text
                                        style={{
                                            color: '#52C41A',
                                            fontSize: 16,
                                            fontWeight: 600
                                        }}>
                                        {' '}
                                        {result !== undefined && result?.bonilla
                                            ? t('qualify.until') +
                                              ' ' +
                                              numeral(result?.bonilla[1]?.maxBudget).format('0,0 $')
                                            : t('qualify.until') + ' ' + '-'}
                                    </Text>
                                </Col>
                                <Col span={14} style={{ textAlign: 'left' }}>
                                    <Text
                                        style={{
                                            color: '#748EA0',
                                            fontSize: 16,
                                            fontWeight: 400
                                        }}>
                                        {rol === 'realestate'
                                            ? t('qualify.bankNoProblem')
                                            : t('qualify.bankNoProblemClient')}
                                    </Text>
                                </Col>
                            </Row>
                        )}
                        {result?.bonilla !== undefined &&
                            result?.bonilla[1]?.maxBudget !== result?.bonilla[2]?.maxBudget && (
                                <Row
                                    style={{
                                        marginTop: 30,
                                        justifyContent: 'center'
                                    }}>
                                    <Col span={10} style={{ display: 'flex' }}>
                                        <Text
                                            style={{
                                                color: '#FAAD14',
                                                fontSize: 16,
                                                fontWeight: 600,
                                                textAlign: 'left'
                                            }}>
                                            {result !== undefined && result?.bonilla
                                                ? t('qualify.until') +
                                                  ' ' +
                                                  numeral(result?.bonilla[2].maxBudget).format('0,0 $')
                                                : t('qualify.until') + ' ' + '-'}
                                        </Text>
                                    </Col>
                                    <Col span={14} style={{ textAlign: 'left' }}>
                                        <Text
                                            style={{
                                                color: '#748EA0',
                                                fontSize: 16,
                                                fontWeight: 400
                                            }}>
                                            {rol === 'realestate'
                                                ? t('qualify.bankProblem')
                                                : t('qualify.bankProblemClient')}
                                        </Text>
                                    </Col>
                                </Row>
                            )}
                    </>
                )}
            </Col>
        </div>
        <div style={{ background: '#F1F7F8', padding: 16, marginBottom: 150 }}>
            <div style={{ textAlign: 'center' }}>
                <Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>
                    {t('financing.mobileQuote')}
                </Text>
            </div>
            {!loading && (
                <Row style={{ width: '100%', marginTop: 24 }}>
                    <Col span={12} style={{ textAlign: 'left', marginTop: 10 }}>
                        <Text
                            style={{
                                color: '#2F4858',
                                fontSize: 14,
                                fontWeight: 400
                            }}>
                            {t('qualify.price')}
                        </Text>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <InputNumber
                            onChange={(value) => onChangePrice(value)}
                            defaultValue={renderResult()}
                            placeholder={renderResult()}
                            suffix={'€'}
                            min={10000}
                            style={{
                                border: '1px solid #02C3CD',
                                borderRadius: 4,
                                height: 40
                            }}
                        />
                    </Col>
                </Row>
            )}

            {!loading && (
                <Row style={{ width: '100%', marginTop: 32 }}>
                    <Col span={12} style={{ marginTop: 16, textAlign: 'left' }}>
                        <Text
                            style={{
                                color: '#2F4858',
                                fontSize: 14,
                                fontWeight: 400
                            }}>
                            {t('loan.rate')}
                        </Text>
                        <InfoCircleOutlined
                            onClick={() => setRate(true)}
                            style={{ color: '#02C3CD', marginLeft: 8 }}
                        />
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <InputNumber
                            gbType='number'
                            onChange={(value) => onChangeFixed(value)}
                            className='gb-input-new-percent'
                            defaultValue={
                                result === undefined ? undefined : result?.simulation?.simulationData?.fixed
                            }
                            value={result === undefined ? undefined : result?.simulation?.simulationData?.fixed}
                            placeholder="1.31"
                            style={{
                                border: '1px solid #02C3CD',
                                borderRadius: 4,
                                height: 40
                            }}
                        />
                        {result && !_.isNil(result.quote) && (
                            <div style={{ marginTop: 8, textAlign: 'left' }}>
                                <Text
                                    style={{
                                        color: '#016B71',
                                        fontSize: 12,
                                        fontWeight: 400
                                    }}>
                                    {t('fee.calculatorEuribor') +
                                        ' ' +
                                        numeral(result.quote.rates.euribor).format('0,0.00 %')}
                                </Text>
                            </div>
                        )}
                    </Col>
                </Row>
            )}

            <Skeleton active loading={loading} paragraph={false} />

            {!loading && (
                <Row style={{ width: '100%', marginTop: 32 }}>
                    <Col span={6} style={{ marginTop: 0, textAlign: 'left' }}>
                        <Text
                            style={{
                                color: '#2F4858',
                                fontSize: 14,
                                fontWeight: 400
                            }}>
                            {t('loan.term')}
                        </Text>
                        <InfoCircleOutlined
                            onClick={() => setTerm(true)}
                            style={{ color: '#02C3CD', marginLeft: 8 }}
                        />
                    </Col>
                    <Col span={18} style={{ textAlign: 'right' }}>
                        <Slider
                            onChange={(value) => onChangeTerm(value)}
                            defaultValue={30}
                            min={5}
                            max={renderMax()}
                            tooltip={{
                                formatter: (value) => `${value} ${t('form.years')}`
                            }}
                        />
                        <div style={{ fontSize: 12, color: '#748EA0' }}>
                            {quotes && quotes / 12} {t('viability.years')}
                        </div>
                    </Col>
                </Row>
            )}
            <Row style={{ width: '100%', marginTop: 42 }}>
                <Col span={24} style={{ marginTop: 0, textAlign: '-webkit-center' }}>
                    <Skeleton active loading={loading} paragraph={false} />

                    {!loading && (
                        <>
                            <Row>
                                <Col span={12} style={{ textAlign: 'left' }}>
                                    <Text
                                        style={{
                                            color: '#748EA0',
                                            fontWeight: 400,
                                            fontSize: 14
                                        }}>
                                        {t('qualify.priceMortgage')}
                                    </Text>
                                    <InfoCircleOutlined
                                        onClick={() => setAmountHp(true)}
                                        style={{ color: '#02C3CD', marginLeft: 8 }}
                                    />
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <Text
                                        style={{
                                            color: '#2F4858',
                                            fontWeight: 600,
                                            fontSize: 16
                                        }}>
                                        {result && result.quote
                                            ? numeral(
                                                    result.quote.operation.mortgageAmount < 0
                                                        ? 0
                                                        : result.quote.operation.mortgageAmount
                                              ).format('0,0 $')
                                            : numeral(0).format('0,0 $')}
                                    </Text>
                                </Col>
                            </Row>

                            <Row style={{ marginTop: 18 }}>
                                <Col span={12} style={{ textAlign: 'left' }}>
                                    <Text
                                        style={{
                                            color: '#748EA0',
                                            fontWeight: 400,
                                            fontSize: 14
                                        }}>
                                        {t('qualify.quoteMortgage')}
                                    </Text>
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <Text
                                        style={{
                                            color: '#02C3CD',
                                            fontWeight: 600,
                                            fontSize: 20
                                        }}>
                                        {result && result.quote
                                            ? numeral(
                                                    result.quote.operation.types.fixed.quote < 0
                                                        ? 0
                                                        : result.quote.operation.types.fixed.quote
                                              ).format('0,0 $') +
                                              '/' +
                                              t('viability.monthsEstimated')
                                            : numeral(0).format('0,0 $') + '/' + t('viability.monthsEstimated')}
                                    </Text>
                                </Col>
                            </Row>
                        </>
                    )}
                </Col>
            </Row>

            {dataSummary.length > 0 && (
                <Card className="card-financing-second" style={{ marginTop: 40 }}>
                    <Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>
                        {t('calculatorFinancing.mortgage')}
                    </Text>
                    <div>
                        <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 500 }}>
                            {t('calculatorFinancing.mortgage1')}
                        </Text>
                        &nbsp;
                        <Text
                            style={{
                                color: '#02C3CD',
                                fontSize: 14,
                                fontWeight: 500,
                                cursor: 'pointer'
                            }}
                            onClick={() => navigate(locationTmpText)}>
                            {t('calculatorFinancing.mortgage2')}
                        </Text>
                        &nbsp;
                        <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 500 }}>
                            {t('calculatorFinancing.mortgage3')}
                        </Text>
                    </div>
                </Card>
            )}

            {/**warnign mas casos TODO */}
            {result && result?.viability && result.viability.excepcionalViability !== undefined && (
                <Card
                    className="card-second"
                    style={{
                        border: '1px solid #02C3CD',
                        borderRadius: 4,
                        marginTop: 40
                    }}>
                    <div style={{ textAlign: 'center' }}>
                        <WarningOutlined style={{ color: '#02C3CD', fontSize: 40 }} />
                    </div>

                    <div style={{ textAlign: 'center', marginTop: 8 }}>
                        <Text
                            style={{
                                color: '#2F4858',
                                fontWeight: 500,
                                fontSize: 14
                            }}>
                            {result && result.viability && renderTitle()}
                        </Text>
                    </div>
                    <div style={{ textAlign: 'center', marginTop: 8 }}>
                        <Text
                            style={{
                                color: '#748EA0',
                                fontWeight: 400,
                                fontSize: 14
                            }}>
                            {result && result.viability && renderDesc()}
                        </Text>
                    </div>
                </Card>
            )}
        </div>
        <Card className="cardButtonStyle" style={{ left: 0, marginBottom: -2, height: 80 }}>
            <Button
                id="gb-mortgage-buy-situation-mobile"
                type="primary"
                style={{
                    fontSize: 16,
                    height: 48,
                    fontWeight: 600,
                    width: '100%'
                }}
                onClick={() => {
                    if (result !== undefined && result.viability.excepcionalViability === undefined) {
                        setFinish(true);
                    }
                }}
                disabled={
                    result?.viability?.excepcionalViability !== undefined || dataSummary.length > 0
                        ? true
                        : false
                }>
                {t('calculatorFinancing.button')}
            </Button>
        </Card>
        <Modal
            className="ModalCommon-movile-24px"
            okButtonProps={{ style: { display: 'none' } }}
            open={showFirstModal}
            width={328}
            cancelButtonProps={{ style: { display: 'none' } }}
            closable={false}>
            <div style={{ paddingTop: 16, marginBottom: -20 }}>
                <div>
                    <Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
                        {t('enter.dataFinancing')}
                    </Text>
                </div>
                <div style={{ marginTop: 8 }}>
                    <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                        {t('enter.dataClientFinancing') + ' '}
                    </Text>
                    <Text
                        onClick={() => navigate('/qualify/calculators/fee/datas')}
                        style={{ color: '#02C3CD', fontSize: 14, fontWeight: 400 }}>
                        {t('typeMortgage.text2TooltipMortgage')}
                    </Text>
                </div>
            </div>
        </Modal>
        <Modal
            className="modal-promoter ModalCommon"
            okButtonProps={{
                className: 'button-primari-gibobs',
                style: { width: '94%' }
            }}
            open={finish}
            width={480}
            title={<img src={calculatorImage} alt="financing" width={'100%'} height="90px" />}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={() => setFinish(false)}
            onOk={() => sendFinish()}>
            <div style={{ marginBottom: 0, marginTop: 30 }}>
                <Text strong>{t('modal6.titleGood') + ' ' + dataProfile.name}</Text>
            </div>
            <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                {t('calculator.modalFinish')}
            </Text>
        </Modal>
        <MortgageAmountModal
            key={'amountHp'}
            visible={amountHp}
            result={result?.simulation}
            onCancel={() => setAmountHp(false)}
        />
        <ModalWithoutButtons
            key={'rate'}
            visible={rate}
            onCreate={() => setRate(false)}
            onCancel={() => setRate(false)}
            title={t('loan.rateCurrent')}
            text={t('calculator.modal1')}
        />
        <ModalWithoutButtons
            key={'term'}
            visible={term}
            onCreate={() => setTerm(false)}
            onCancel={() => setTerm(false)}
            title={t('scoring.term')}
            text={t('calculator.modal2')}
        />
    </>);
};

export default CalculatorsFinancingMobile;
