import { useEffect, useMemo, useState } from 'react';
import { Card, Typography, Button, Form, InputNumber, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { InfoCircleFilled } from '@ant-design/icons';
import numeral from 'numeral';
import axios from 'axios';
import env from '../../../../environment';
import { Line } from '@ant-design/plots';
import check from '../../../../assets/loans/check.png';
import bulp from '../../../../assets/loans/bulp.png';
import InfoBlue from '../../../../assets/loans/InfoBlue.png';
import { isMobileOnly } from 'react-device-detect';
import { InputDate } from 'afrodita';
import dayjs from 'dayjs';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');
dayjs.locale('vi');

const { Text } = Typography;

const CompareAutomatic = ({ loanId, rateType, tip, visible }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();;
	const [form] = Form.useForm();
	const [loan, setLoan] = useState(undefined);
	const userToken = useSelector((state) => state.auth.token);
	const [dataTips, setDataTips] = useState(undefined);
	const [data, setData] = useState(undefined);
	const dataProfile = useSelector((state) => state.data.dataProfile);
	const dataSummary = useSelector((state) => state.data.dataSummary);
	const dataSummaryFilter = dataSummary.filter((data) => data.subtype === 'subrogation');

	useEffect(() => {
		getLoan();
		getEuribor();
	}, []);

	useEffect(() => {
		getLoan();
		getEuribor();
	}, [rateType]);

	let config = {
		data,
		xField: 'date',
		yField: 'index',
		label: {
			position: 'middle',
			style: {
				fill: '#FFFFFF',
				opacity: 0.6
			}
		},
		xAxis: {
			tickCount: 12,
			label: {
				autoHide: true,
				autoRotate: true
			}
		},
		slider: {
			start: 0,
			end: 10
		},
		meta: {
			date: {
				alias: 'Año'
			},
			index: {
				alias: 'Euribor'
			}
		}
	};

	const getLoan = () => {
		axios
			.get(`${env.api.url}/v1/loans/get-loan/${loanId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				// setDataValues(response.data.data);
				setLoan(response.data.data.loan);
				response.data.data.loan.tips.map((tipData) => {
					if (tipData.type === tip) {
						setDataTips(tipData);
					}
				});
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const getEuribor = () => {
		axios
			.get(`${env.api.url}/v1/rates/by-month/euribor`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setData(response.data.data.rates);
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const memoChild = useMemo(() => {
		return <Line {...config} />;
	}, [config]);

	const onChangeAmount = (value) => {
		axios
			.post(
				`${env.api.url}/v1/loans/update/${loan.id}`,
				{
					amount: value,
					version: '2023.v1'
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then(() => {
				getLoan();
			})
			.catch((error) => {
				console.log(error)
			});
	};
	const onChangeDeb = (value) => {
		axios
			.post(
				`${env.api.url}/v1/loans/update/${loan.id}`,
				{
					finish: dayjs(value, 'DD-MM-YYYY').format('YYYY-MM-DD'),
					version: '2023.v1'
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then(() => {
				getLoan();
			})
			.catch((error) => {
				console.log(error)
			});
	};


	const requestAnalysis = () => {
		axios
			.post(
				`${env.api.url}/v1/loans/create-subrogation`,
				{
					loanId: loan.id
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					const operationId = response.data.data.operation.id;
					axios
						.post(
							`${env.api.url}/campaigns/runner/experiment/by-event`,
							{
								event: 'questions-mortgages',
								data: {
									operationId: operationId
								}
							},
							{
								headers: { Authorization: `Bearer ${userToken}` }
							}
						)
						.then((response) => {
							if (response.data.data.mortgageQuestions === '2022') {
								navigate(`/testA/operations/${operationId}/basic`);
							} else {
								navigate(`/operations/${operationId}/basic`);
							}
						});
				}
			})
			.catch((error) => {
				console.log(error)
			});
	};

	return (
		<>
			{(!loan?.amount || !loan?.finish) && (
				<Form
					form={form}
					layout="vertical"
					name="loan"
					initialValues={{
						debt: loan && loan.amount,
						quotePending: loan && dayjs(loan.finish).format('DD-MM-YYYY')
					}}
					>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item
								name="debt"
								label={t('loan.debt')}
								rules={[
									{
										required: true
									}
								]}
								style={{
									width: '100%'
								}}>
								<InputNumber
									defaultValue={loan && loan.amount}
									onChange={(value) => onChangeAmount(value)}
									style={{
										width: '100%'
									}}
									placeholder={'0 €'}
								/>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name="quotePending"
								label={t('loan.dateFinish')}
								rules={[
									{
										required: true
									}
								]}
								style={{
									width: '100%'
								}}>
								<InputDate
									type="date"
									onChange={(value) => onChangeDeb(value)}
									style={{
										width: '100%',
										borderRadius: '4px',
										borderColor: '#C0DAEE'
									}}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			)}

			{dataTips && dataTips.savings && dataTips.gibobs.term && (
				<div style={{ marginTop: 16 }}>
					<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 600 }}>
						{t('loan.ourAdvice').toUpperCase()}
					</Text>
				</div>
			)}
			<Row gutter={24} style={{ height: 'auto' }}>
				{rateType === 'fixed' &&
					tip === 'BETTER_FIXED_RATE' &&
					dataTips &&
					dataTips.category === 'WORSE' && (
						//* esto es comparativa ok es decir no hay mejora posible
						<Col span={24}>
							<Card className="gb-card-loan-init-blue-data-no-better" style={{ marginTop: 16 }}>
								<Row>
									<Col span={3}>
										<img src={InfoBlue} style={{ height: 24, width: 24, marginTop: 20 }}></img>
									</Col>
									<Col span={21} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 600
											}}>
											{t('loan.adviceOk') +
												' ' +
												numeral(Math.abs(dataTips.savings)).format('0,0.00 $') +
												' ' +
												t('loan.adviceOk1')}
										</Text>
									</Col>
								</Row>

								<Row>
									<Col span={3}></Col>
									<Col span={21}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 400
											}}>
											{t('loan.adviceOk2')}
										</Text>
									</Col>
								</Row>
							</Card>
						</Col>
					)}

				{rateType === 'variable' &&
					tip === 'BETTER_VARIABLE_RATE' &&
					dataTips &&
					dataTips.category === 'WORSE' && (
						//* esto es comparativa ok es decir no hay mejora posible
						<Col span={24}>
							<Card className="gb-card-loan-init-blue-data-no-better" style={{ marginTop: 16 }}>
								<Row>
									<Col span={2}>
										<InfoCircleFilled
											style={{
												fontSize: 18,
												color: '#1890FF',
												fontWeight: 600,
												marginTop: 16
											}}
										/>
									</Col>
									<Col span={22} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 600
											}}>
											{t('loan.adviceOk') +
												' ' +
												numeral(Math.abs(dataTips.savings)).format('0,0.00 $') +
												t('loan.adviceOk1')}
										</Text>

										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 400
											}}>
											{t('loan.adviceOk2')}
										</Text>
									</Col>
								</Row>
							</Card>
						</Col>
					)}

				{rateType === 'fixed' &&
					tip === 'BETTER_FIXED_RATE' &&
					dataTips &&
					dataTips.category !== 'WORSE' &&
					dataTips.savings &&
					dataTips.gibobs.quota && (
						<>
							<Col span={24}>
								<Card className="gb-card-loan-init-green-desktop" style={{ marginTop: 16 }}>
									<Row>
										<Col span={3} style={{ marginTop: 15 }}>
											<img src={check} alt="asdf" style={{ width: 24, height: 24 }}></img>
										</Col>
										<Col
											span={21}
											style={{
												display: 'flex',
												flexDirection: 'column'
											}}>
											<Text
												style={{
													color: '#2F4858',
													fontSize: 14,
													fontWeight: 600
												}}>
												{t('loan.fixedBest1') +
													' ' +
													numeral(dataTips.savings).format('0,0.00 $') +
													t('loan.fixedBest2')}
											</Text>
											<Text
												style={{
													color: '#2F4858',
													fontSize: 14,
													fontWeight: 400,
													marginTop: 16
												}}>
												{t('loan.fixedBest') +
													' ' +
													numeral(dataTips.gibobs.totalSavings).format('0,0.00 $')}
											</Text>
										</Col>
									</Row>
								</Card>
							</Col>

							<Col span={24}>
								<Card className="gb-card-loan-init-blue-data-desktop" style={{ marginTop: 16 }}>
									<Row style={{ width: '100%', justifyContent: 'center' }}>
										<Text
											style={{
												fontSize: 16,
												color: '#02C3CD',
												fontWeight: 600
											}}>
											{t('loan.fixedBestOffert')}
										</Text>
									</Row>

									<Row
										style={{
											width: '100%',
											marginTop: 8,
											textAlign: 'center'
										}}>
										<Col span={8} style={{ display: 'flex', flexDirection: 'column' }}>
											<Text
												style={{
													color: '#748EA0',
													fontSize: 12,
													fontWeight: 400
												}}>
												{'TAE'}
											</Text>
											<Text
												style={{
													color: '#02C3CD',
													fontSize: 16,
													fontWeight: 400
												}}>
												{dataTips.gibobs.tae + '%'}
											</Text>
										</Col>
										<Col span={8} style={{ display: 'flex', flexDirection: 'column' }}>
											<Text
												style={{
													color: '#748EA0',
													fontSize: 12,
													fontWeight: 400
												}}>
												{t('loan.pendingterm')}
											</Text>
											<Text
												style={{
													color: '#02C3CD',
													fontSize: 16,
													fontWeight: 400
												}}>
												{numeral(dataTips.gibobs.term / 12).format('0,0') +
													' ' +
													t('viability.years')}
											</Text>
										</Col>
										<Col span={8}>
											<div
												style={{
													float: 'right',
													display: 'flex',
													flexDirection: 'column'
												}}>
												<Text
													style={{
														color: '#748EA0',
														fontSize: 12,
														fontWeight: 400
													}}>
													{t('loan.quotaAd')}
												</Text>
												<Text
													style={{
														color: '#02C3CD',
														fontSize: 16,
														fontWeight: 400
													}}>
													{numeral(dataTips.gibobs.quota).format('0,0.00 $') +
														'/' +
														t('viability.monthsEstimated')}
												</Text>
											</div>
										</Col>
									</Row>
								</Card>
							</Col>
						</>
					)}

				{rateType === 'variable' && tip === 'CHANGE_TO_VARIABLE_RATE' && dataTips && data && (
					<>
						<Col span={24}>
							<Card className="gb-card-loan-init-green-desktop" style={{ marginTop: 16 }}>
								<Row>
									<Col span={3}>
										<img
											src={check}
											alt="asdf"
											style={{ width: 24, height: 24, marginTop: 20 }}></img>
									</Col>
									<Col span={21} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 600
											}}>
											{t('loan.fixedBest1') +
												' ' +
												numeral(dataTips.savings).format('0,0.00 $') +
												t('loan.variableBest2')}
										</Text>
									</Col>
								</Row>
								<Row>
									<Col span={3}></Col>
									<Col
										span={21}
										style={{
											display: 'flex',
											flexDirection: 'column'
										}}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 400,
												marginTop: 8,
												marginBottom: 8
											}}>
											{t('loan.fixedBest') +
												' ' +
												numeral(dataTips.gibobs.totalSavings).format('0,0.00 $')}
										</Text>
									</Col>
								</Row>
							</Card>
						</Col>
						<Col span={24}>
							<Card className="gb-card-loan-init-blue-data-desktop" style={{ marginTop: 16 }}>
								<Row style={{ width: '100%', justifyContent: 'center' }}>
									<Text
										style={{
											fontSize: 16,
											color: '#02C3CD',
											fontWeight: 600
										}}>
										{t('loan.variableBestOffert')}
									</Text>
								</Row>

								<Row
									style={{
										width: '100%',
										marginTop: 8,
										textAlign: 'center'
									}}>
									<Col span={8} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#748EA0',
												fontSize: 12,
												fontWeight: 400
											}}>
											{'TAE'}
										</Text>
										<Text
											style={{
												color: '#02C3CD',
												fontSize: 16,
												fontWeight: 400
											}}>
											{'Eur+' + dataTips.gibobs.tae + '%'}
										</Text>
									</Col>
									<Col
										span={8}
										style={{
											display: 'flex',
											flexDirection: 'column',
											textAlign: 'center'
										}}>
										<Text
											style={{
												color: '#748EA0',
												fontSize: 12,
												fontWeight: 400
											}}>
											{t('loan.pendingterm')}
										</Text>
										<Text
											style={{
												color: '#02C3CD',
												fontSize: 16,
												fontWeight: 400
											}}>
											{numeral(dataTips.gibobs.term / 12).format('0,0') +
												' ' +
												t('viability.years')}
										</Text>
									</Col>
									<Col span={8}>
										<div
											style={{
												float: 'right',
												display: 'flex',
												flexDirection: 'column'
											}}>
											<Text
												style={{
													color: '#748EA0',
													fontSize: 12,
													fontWeight: 400
												}}>
												{t('loan.quotaAd')}
											</Text>
											<Text
												style={{
													color: '#02C3CD',
													fontSize: 16,
													fontWeight: 400
												}}>
												{numeral(dataTips.gibobs.quota).format('0,0.00 $') +
													'/' +
													t('viability.monthsEstimated')}
											</Text>
										</div>
									</Col>
								</Row>
							</Card>
						</Col>
						<Col span={24}>
							<Card className="gcardMovile" style={{ marginTop: 24 }}>
								<Row gutter={24}>
									<Col span={24}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 600
											}}>
											{t('loan.variableWarning')}
										</Text>
										<div
											style={{
												marginTop: 16,
												display: 'flex',
												flexDirection: 'column'
											}}>
											<Text
												style={{
													color: '#748EA0',
													fontSize: 14,
													fontWeight: 400
												}}>
												{t('loan.variableWarning1')}
											</Text>
											<Text
												style={{
													color: '#748EA0',
													fontSize: 14,
													fontWeight: 400
												}}>
												{t('loan.variableWarning2')}
											</Text>
										</div>
									</Col>
									<Col span={24} style={{ marginTop: 20 }}>
										<div style={{ height: 150 }}>{memoChild}</div>
									</Col>
								</Row>
							</Card>
						</Col>
					</>
				)}

				{rateType === 'fixed' && dataProfile &&
					tip === 'CHANGE_TO_FIXED_RATE' &&
					dataTips &&
					dataTips.category !== 'WORSE' && (
						<>
							<Col span={24}>
								<Card className="gb-card-loan-init-green-desktop" style={{ marginTop: 24 }}>
									<Row>
										<Col span={3}>
											<img
												src={check}
												alt="asdf"
												style={{ width: 24, height: 24, marginTop: 8 }}></img>
										</Col>
										<Col span={21} style={{ display: 'flex', flexDirection: 'column' }}>
											<Text
												style={{
													color: '#2F4858',
													fontSize: 14,
													fontWeight: 600
												}}>
												{t('loan.tip1Variable') +
													' ' +
													dataProfile.name +
													': ' +
													t('loan.tip2Variable') +
													numeral(dataTips.savings).format('0,0.00 $') +
													t('loan.varChangeToFixBetter')}
											</Text>

											<Text
												style={{
													color: '#2F4858',
													fontSize: 14,
													fontWeight: 400,
													marginTop: 8
												}}>
												{t('loan.fixedBest') +
													' ' +
													numeral(dataTips.gibobs.totalSavings).format('0,0.00 $')}
											</Text>
										</Col>
									</Row>
								</Card>
							</Col>
							<Col span={24}>
								<Card className="gb-card-loan-init-blue-data-desktop" style={{ marginTop: 16 }}>
									<Row style={{ width: '100%', justifyContent: 'center' }}>
										<Text
											style={{
												fontSize: 16,
												color: '#02C3CD',
												fontWeight: 600
											}}>
											{t('loan.fixedBestOffert')}
										</Text>
									</Row>

									<Row
										style={{
											width: '100%',
											marginTop: 20,
											textAlign: 'center'
										}}>
										<Col span={8} style={{ display: 'flex', flexDirection: 'column' }}>
											<Text
												style={{
													color: '#748EA0',
													fontSize: 12,
													fontWeight: 400
												}}>
												{'TAE'}
											</Text>
											<Text
												style={{
													color: '#02C3CD',
													fontSize: 16,
													fontWeight: 400
												}}>
												{dataTips.gibobs.tae + '%'}
											</Text>
										</Col>
										<Col span={8} style={{ display: 'flex', flexDirection: 'column' }}>
											<Text
												style={{
													color: '#748EA0',
													fontSize: 12,
													fontWeight: 400
												}}>
												{t('loan.pendingterm')}
											</Text>
											<Text
												style={{
													color: '#02C3CD',
													fontSize: 16,
													fontWeight: 400
												}}>
												{numeral(dataTips.gibobs.term / 12).format('0,0') +
													' ' +
													t('viability.years')}
											</Text>
										</Col>
										<Col span={8}>
											<div
												style={{
													float: 'right',
													display: 'flex',
													flexDirection: 'column'
												}}>
												<Text
													style={{
														color: '#748EA0',
														fontSize: 12,
														fontWeight: 400
													}}>
													{t('loan.quotaAd')}
												</Text>
												<Text
													style={{
														color: '#02C3CD',
														fontSize: 16,
														fontWeight: 400
													}}>
													{numeral(dataTips.gibobs.quota).format('0,0.00 $') +
														'/' +
														t('viability.monthsEstimated')}
												</Text>
											</div>
										</Col>
									</Row>
								</Card>
							</Col>
						</>
					)}

				{rateType === 'variable' &&
					tip === 'BETTER_VARIABLE_RATE' &&
					dataTips &&
					dataTips.category !== 'WORSE' &&
					dataTips.savings !== undefined && (
						<>
							<Col span={24}>
								<Card className="gb-card-loan-init-green-desktop" style={{ marginTop: 16 }}>
									<Row>
										<Col span={3}>
											<img
												src={check}
												alt="asdf"
												style={{ width: 24, height: 24, marginTop: 20 }}></img>
										</Col>
										<Col span={21} style={{ display: 'flex', flexDirection: 'column' }}>
											<Text
												style={{
													color: '#2F4858',
													fontSize: 14,
													fontWeight: 600
												}}>
												{t('loan.fixedBest1') +
													' ' +
													numeral(dataTips.savings).format('0,0.00 $') +
													t('loan.variableTipsChangeManualVar')}
											</Text>

											<Text
												style={{
													color: '#2F4858',
													fontSize: 14,
													fontWeight: 400,
													marginTop: 12,
													marginBottom: 5
												}}>
												{t('loan.fixedBest') +
													' ' +
													numeral(dataTips.gibobs.totalSavings).format('0,0.00 $')}
											</Text>
										</Col>
									</Row>
								</Card>
							</Col>
							<Col span={24}>
								<Card className="gb-card-loan-init-blue-data-desktop" style={{ marginTop: 16 }}>
									<Row style={{ width: '100%', justifyContent: 'center' }}>
										<Text
											style={{
												fontSize: 16,
												color: '#02C3CD',
												fontWeight: 600
											}}>
											{t('loan.variableBestOffert')}
										</Text>
									</Row>

									<Row
										style={{
											width: '100%',
											marginTop: 8,
											textAlign: 'center'
										}}>
										<Col span={8} style={{ display: 'flex', flexDirection: 'column' }}>
											<Text
												style={{
													color: '#748EA0',
													fontSize: 12,
													fontWeight: 400
												}}>
												{'TAE'}
											</Text>
											<Text
												style={{
													color: '#02C3CD',
													fontSize: 16,
													fontWeight: 400
												}}>
												{'Eur + ' + dataTips.gibobs.tae}
											</Text>
										</Col>
										<Col span={8} style={{ display: 'flex', flexDirection: 'column' }}>
											<Text
												style={{
													color: '#748EA0',
													fontSize: 12,
													fontWeight: 400
												}}>
												{t('loan.pendingterm')}
											</Text>
											<Text
												style={{
													color: '#02C3CD',
													fontSize: 16,
													fontWeight: 400
												}}>
												{numeral(dataTips.gibobs.term / 12).format('0,0') +
													' ' +
													t('viability.years')}
											</Text>
										</Col>
										<Col span={8}>
											<div
												style={{
													float: 'right',
													display: 'flex',
													flexDirection: 'column'
												}}>
												<Text
													style={{
														color: '#748EA0',
														fontSize: 12,
														fontWeight: 400
													}}>
													{t('loan.quotaAd')}
												</Text>
												<Text
													style={{
														color: '#02C3CD',
														fontSize: 16,
														fontWeight: 400
													}}>
													{numeral(dataTips.gibobs.quota).format('0,0.00 $') +
														'/' +
														t('viability.monthsEstimated')}
												</Text>
											</div>
										</Col>
									</Row>
								</Card>
							</Col>
						</>
					)}

				{rateType === 'noBetter' && tip === 'CHANGE_TO_WORSE_FIXED_RATE' && dataTips && (
					<>
						<Col span={24}>
							<Card className="gb-card-loan-init-blue-data-no-better" style={{ marginTop: 16 }}>
								<Row>
									<Col span={4} style={{ marginTop: 15 }}>
										<img src={bulp} alt="asdf" style={{ width: 24, height: 24 }}></img>
									</Col>
									<Col span={20} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 400
											}}>
											{t('loan.comparativeNoBetter') +
												' ' +
												numeral(dataTips.savings).format('0,0.00 $') +
												'/' +
												t('viability.monthsEstimated')}
										</Text>

										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 600,
												marginTop: 8
											}}>
											{t('loan.comparativeNoBetter2') +
												' ' +
												numeral(dataTips.gibobs.totalSavings).format('0,0.00 $') +
												t('loan.messageVariableQuote3')}
										</Text>
									</Col>
								</Row>
							</Card>
						</Col>
						<Col span={24}>
							<Card className="gb-card-loan-init-blue-data" style={{ marginTop: 16 }}>
								<Row style={{ width: '100%', justifyContent: 'center' }}>
									<Text
										style={{
											fontSize: 16,
											color: '#02C3CD',
											fontWeight: 600
										}}>
										{t('loan.variableBestOffert')}
									</Text>
								</Row>

								<Row
									style={{
										width: '100%',
										marginTop: 16,
										textAlign: 'center'
									}}>
									<Col span={7} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#748EA0',
												fontSize: 12,
												fontWeight: 400
											}}>
											{'TAE'}
										</Text>
										<Text
											style={{
												color: '#02C3CD',
												fontSize: 16,
												fontWeight: 400
											}}>
											{dataTips.gibobs.tae + '%'}
										</Text>
									</Col>
									<Col span={9} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#748EA0',
												fontSize: 12,
												fontWeight: 400
											}}>
											{t('loan.pendingterm')}
										</Text>
										<Text
											style={{
												color: '#02C3CD',
												fontSize: 16,
												fontWeight: 400
											}}>
											{numeral(dataTips.gibobs.term / 12).format('0,0') / 12 +
												' ' +
												t('viability.years')}
										</Text>
									</Col>
									<Col span={6}>
										<div
											style={{
												float: 'right',
												display: 'flex',
												flexDirection: 'column'
											}}>
											<Text
												style={{
													color: '#748EA0',
													fontSize: 12,
													fontWeight: 400
												}}>
												{t('loan.quotaAd')}
											</Text>
											<Text
												style={{
													color: '#02C3CD',
													fontSize: 16,
													fontWeight: 400
												}}>
												{numeral(dataTips.gibobs.quota).format('0,0.00 $') +
													'/' +
													t('viability.monthsEstimated')}
											</Text>
										</div>
									</Col>
								</Row>
								<Row
									style={{
										display: 'flex',
										flexDirection: 'column',
										textAlign: 'center',
										marginTop: 16
									}}>
									<Text
										style={{
											color: '#748EA0',
											fontSize: 14,
											fontWeight: 400
										}}>
										{t('viability.totalSavings')}
									</Text>
									<Text
										style={{
											color: '#02C3CD',
											fontSize: 20,
											fontWeight: 600
										}}>
										{numeral(dataTips.gibobs.totalSavings).format('0,0.00 $')}
									</Text>
								</Row>
							</Card>
						</Col>
					</>
				)}

				{rateType === 'fixed' && tip === 'CHANGE_TO_WORSE_FIXED_RATE' && dataTips && (
					<>
						<Col span={24}>
							<Card className="gb-card-loan-init-green-desktop" style={{ marginTop: 16 }}>
								<Row>
									<Col span={3}>
										<img
											src={check}
											alt="asdf"
											style={{ width: 24, height: 24, marginTop: 16 }}></img>
									</Col>
									<Col span={21} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 600
											}}>
											{t('loan.varChangeFixedWorse') +
												' ' +
												numeral(dataTips.gibobs.quota).format('0,0.00 $') +
												t('loan.messageVariableQuote3')}
										</Text>
									</Col>
								</Row>
							</Card>
						</Col>
						<Col span={24}>
							<Card className="gb-card-loan-init-blue-data-desktop" style={{ marginTop: 16 }}>
								<Row style={{ width: '100%', justifyContent: 'center' }}>
									<Text
										style={{
											fontSize: 16,
											color: '#02C3CD',
											fontWeight: 600
										}}>
										{t('loan.fixedBestOffert')}
									</Text>
								</Row>

								<Row
									style={{
										width: '100%',
										marginTop: 14,
										textAlign: 'center'
									}}>
									<Col span={8} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#748EA0',
												fontSize: 12,
												fontWeight: 400
											}}>
											{'TAE'}
										</Text>
										<Text
											style={{
												color: '#02C3CD',
												fontSize: 16,
												fontWeight: 400
											}}>
											{dataTips.gibobs.tae + '%'}
										</Text>
									</Col>
									<Col span={8} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#748EA0',
												fontSize: 12,
												fontWeight: 400
											}}>
											{t('loan.pendingterm')}
										</Text>
										<Text
											style={{
												color: '#02C3CD',
												fontSize: 16,
												fontWeight: 400
											}}>
											{numeral(dataTips.gibobs.term / 12).format('0,0') +
												' ' +
												t('viability.years')}
										</Text>
									</Col>
									<Col span={8}>
										<div
											style={{
												float: 'right',
												display: 'flex',
												flexDirection: 'column'
											}}>
											<Text
												style={{
													color: '#748EA0',
													fontSize: 12,
													fontWeight: 400
												}}>
												{t('loan.quotaAd')}
											</Text>
											<Text
												style={{
													color: '#02C3CD',
													fontSize: 16,
													fontWeight: 400
												}}>
												{numeral(dataTips.gibobs.quota).format('0,0.00 $') +
													'/' +
													t('viability.monthsEstimated')}
											</Text>
										</div>
									</Col>
								</Row>
							</Card>
						</Col>
					</>
				)}
			</Row>
			{dataSummaryFilter.length !== 0 && (
				<div
					style={{
						marginTop: 24,
						color: '#2F4858',
						fontSize: 14,
						textAlign: isMobileOnly && 'center',
						fontWeight: 500
					}}>
					{t('loan.operationCurrently')}
				</div>
			)}
			<div style={{ marginTop: 24, textAlign: 'center' }}>
				<Button
					className="button-primari-gibobs"
					disabled={dataSummaryFilter.length === 0 ? false : true}
					type="primary"
					style={{ width: 264, height: 40 }}
					onClick={() => requestAnalysis()}>
					{t('loan.buttonAdvive')}
				</Button>
			</div>
			{dataSummaryFilter.length === 0 && isMobileOnly && (
				<div
					style={{
						marginTop: 7,
						color: '#02C3CD',
						fontSize: 14,
						fontWeight: 400,
						cursor: 'pointer',
						textAlign: 'center'
					}}
					onClick={() => visible()}>
					{t('offers.cancel')}
				</div>
			)}

			<div style={{ marginTop: 40, marginBottom: 100 }}>
				<Text style={{ fontSize: 10, color: '#748EA0', fontWeight: 400 }}>
					{t('viability.explanationCalculationTae')}
				</Text>
			</div>
		</>
	);
};

export default CompareAutomatic;
