import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import MenuBlack from '../../assets/icons/menu.png';
import profileAzul from '../../assets/icons/riseAzul.png';
import profileBlack from '../../assets/icons/rise.png';
import ProductosParticular from '../../assets/icons/productsBlack.png';
import MenuAzul from '../../assets/icons/menuAzul.png';
import ProductosAzulParticular from '../../assets/icons/productsAzul.png';
import Inicio from '../../assets/icons/Inicio.svg';
import chevron_right from '../../assets/icons/chevron_right.svg';
import InicioAzul from '../../assets/icons/InicioAzul.svg';
import MenuItem from 'antd/lib/menu/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { collapseMenu } from '../../store/data/actions';
import AmigobsWhite from '../../assets/icons/AmigobsWhite.png';
import AmigobsBlue from '../../assets/icons/AmigobsBlue.png';
import { Typography } from 'antd';
import { changeRol } from '../../store/auth/actions';
import calculator from '../../assets/icons/calculator.png';
import calculatorBlue from '../../assets/icons/calculatorBlue.png';
import homeBlue from '../../assets/icons/homeBlue.png';
import home from '../../assets/icons/home.png';

import axios from 'axios';
import env from '../../environment';

const { Text } = Typography;

const MenuComponent = ({ selectedMenuItem, onCollapsedTouch, rolUser }) => {
	const { t } = useTranslation();
	const userOperations = useSelector((state) => state.auth.operations);
	const dispatch = useDispatch();
	const collapsed = useSelector((state) => state.data.collapseMenu);
	const allRoles = useSelector((state) => state.auth.allRoles);


	const operations = useSelector((state) => state.realestate.operations);
	const [, setMyLoan] = useState(undefined);
	const userToken = useSelector((state) => state.auth.token);

	useEffect(async () => {
		const response2 = await axios.get(`${env.api.url}/v1/loans/get-loans-list`, {
			headers: { Authorization: `Bearer ${userToken}` }
		});

		if (
			response2.data &&
			response2.data.data &&
			response2.data.data.loans &&
			response2.data.data.loans.length > 0
		) {
			const response = await axios.get(
				`${env.api.url}/v1/loans/get-loan/${response2.data.data.loans[0].id}`,
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			);
			setMyLoan(response.data.data);
		}
	}, []);

	const toggleCollapsed = () => {
		dispatch(collapseMenu(!collapsed));
	};

	const changePar = () => {
		if (rolUser === 'friend') {
			dispatch(changeRol('client'));
		} else if (rolUser === 'client' && allRoles[0] !== 'r') {
			dispatch(changeRol('friend'));
		} else if (rolUser === 'client' && allRoles[0] === 'r') {
			dispatch(changeRol('realestate'));
		}

		if (isMobileOnly) {
			toggleCollapsed();
			changeParClickMenu();
		} else if (isMobileOnly && !collapsed) {
			toggleCollapsed() && onCollapsedTouch();
		} else {
			changeParClickMenu();
		}
	};

	const changeParClickMenu = () => {
		if (rolUser === 'friend') {
			dispatch(changeRol('client'));
		} else {
			return undefined;
		}
	};

	let menuWidth;

	if (!isMobileOnly) {
		menuWidth = collapsed ? 56 : 254;
	}

	return (
		<Menu
			selectedKeys={[selectedMenuItem]}
			mode="inline"
			inlineCollapsed={collapsed}
			style={{
				minHeight: '100%',
				backgroundColor: '#F1F7F8',
				position: 'absolute',
				zIndex: '3',
				top: isMobileOnly ? -80 : 0,
				// overflowY: rolUser !== "realestate" && !collapsed ? "scroll" : null,
				minHeight: rolUser !== 'realestate' ? 'calc(100% - 114px)' : '100%',
				// maxHeight: rolUser !== "realestate" ? "calc(100% - 114px)" : null,
				overflowX: rolUser !== 'realestate' ? 'none' : null,
				width: menuWidth
			}}
			className="menuLateral">
			{!isMobileOnly && (
				<div
					className="g-ant-menu-item"
					id="gb-menu-item-open-closed"
					style={{
						height: '56px',
						paddingTop: '8px',
						// marginTop: "5px",
						backgroundColor: '#2F4858'
					}}>
					<img
						// src={collapsed !== false ? chevron_right : chevron_left}
						src={chevron_right}
						style={{
							height: '40px',
							marginRight: '15px',
							marginLeft: collapsed !== false ? '6px' : '10px',
							marginTop: 0,
							transition: 'transform .2s ease-out',
							transform: collapsed !== false ? 'rotate(0deg)' : 'rotate(180deg)'
						}}
						alt="menu"
						onClick={(event) => {
							toggleCollapsed();
							onCollapsedTouch();
							event.stopPropagation();
						}}></img>
				</div>
			)}
			<MenuItem
				id="gb-menu-item-begin"
				onClickCapture={
					isMobileOnly
						? () => {
							toggleCollapsed();
							changeParClickMenu();
						}
						: !collapsed
							? () => {
								toggleCollapsed();
								onCollapsedTouch();
								changeParClickMenu();
							}
							: () => {
								changeParClickMenu();
							}
				}
				key={rolUser !== 'realestate' ? '/dashboard-client' : '/dashboard-business'}
				icon={
					<img
						src={
							selectedMenuItem === '/dashboard-client' || selectedMenuItem === '/dashboard-business'
								? InicioAzul
								: Inicio
						}
						style={{
							height: '20px',
							width: '20px',
							marginRight: '8px',
						}}
						alt="inicio"
					/>
				}
				className="g-ant-menu-item"
				style={{
					height: '56px',
					paddingTop: collapsed && !isMobileOnly && '8px',
					marginTop: '0px',
					marginBottom: 0,
					paddingLeft: isMobile && 16
				}}>
				<NavLink
					to={rolUser !== 'realestate' ? '/dashboard-client' : '/dashboard-business'}
					style={{ marginLeft: '10px' }}>
					<Text
						style={{
							marginLeft: 5,
							color:
								selectedMenuItem === '/dashboard-client' ||
									selectedMenuItem === '/dashboard-business'
									? '#02C3CD'
									: '#748EA0'
						}}>
						{t('menuComponent.initNav')}
					</Text>
				</NavLink>
			</MenuItem>

			{rolUser === 'realestate' && (
				<MenuItem
					onClickCapture={
						isMobileOnly
							? () => {
								toggleCollapsed();
								changeParClickMenu();
							}
							: !collapsed
								? () => {
									toggleCollapsed();
									onCollapsedTouch();
								}
								: () => {
									changeParClickMenu();
								}
					}
					key="menuComponent.newRequest"
					icon={
						<img
							src={selectedMenuItem === 'menuComponent.newRequest' ? homeBlue : home}
							style={{
								height: '24px',
								width: '24px',
								marginRight: '15px',
							}}
							alt="financiero"
						/>
					}
					style={{
						height: '56px',
						paddingTop: collapsed && !isMobileOnly && '8px',
						marginTop: '0px',
						marginBottom: 0,
						paddingLeft: isMobile && 16
					}}>
					<NavLink to="/sales-tools/new-requests" style={{ marginLeft: '10px' }}>
						<Text
							style={{
								position: 'relative',
								top: -2,
								left: -4,
								color: selectedMenuItem === 'menuComponent.newRequest' ? '#02C3CD' : '#748EA0'
							}}>
							{t('menuComponent.salesTools')}
						</Text>
					</NavLink>
				</MenuItem>
			)}

			{rolUser !== 'realestate' && (
				<MenuItem
					id="gb-menu-item-products"
					onClickCapture={
						isMobileOnly
							? () => {
								toggleCollapsed();
								changeParClickMenu();
							}
							: !collapsed
								? () => {
									toggleCollapsed();
									onCollapsedTouch();
									changeParClickMenu();
								}
								: () => {
									changeParClickMenu();
								}
					}
					key={'menuComponent.productsNav'}
					icon={
						<img
							src={selectedMenuItem === 'menuComponent.productsNav' ? homeBlue : home}
							style={{
								height: '24px',
								width: '24px',
								marginRight: '10px',
								marginLeft: "-4px",
							}}
							alt="simuladores"
						/>
					}
					style={{
						height: '56px',
						paddingTop: collapsed && !isMobileOnly && '8px',
						marginTop: '0px',
						marginBottom: 0,
						paddingLeft: isMobile && 16
					}}>
					<NavLink
						to={
							userOperations && userOperations.length > 0 ? '/products-under-contract' : '/products'
						}
						style={{ marginLeft: '10px' }}>
						<Text
							style={{
								position: 'relative',
								top: 0,
								color: selectedMenuItem === 'menuComponent.productsNav' ? '#02C3CD' : '#748EA0'
							}}>
							{t('menuComponent.productsNav')}
						</Text>
					</NavLink>
				</MenuItem>
			)}

			{rolUser === 'realestate' && (
				<MenuItem
					onClickCapture={
						isMobileOnly
							? () => {
								toggleCollapsed();
								changeParClickMenu();
							}
							: !collapsed
								? () => {
									toggleCollapsed();
									onCollapsedTouch();
								}
								: () => {
									changeParClickMenu();
								}
					}
					key={'menuComponent.productsBusiness'}
					icon={
						<img
							src={
								selectedMenuItem === 'menuComponent.productsBusiness'
									? ProductosAzulParticular
									: ProductosParticular
							}
							style={{
								height: '24px',
								width: '24px',
								marginRight: '4px',
								marginLeft: '-2px'
							}}
							alt="productsBusiness"
						/>
					}
					style={{
						height: '56px',
						paddingTop: collapsed && !isMobileOnly && '8px',
						marginTop: '0px',
						marginBottom: 0,
						paddingLeft: isMobile && 16
					}}>
					<NavLink to="/products-business" style={{ marginLeft: '14px' }}>
						<Text
							style={{
								color: selectedMenuItem === 'menuComponent.productsBusiness' ? '#02C3CD' : '#748EA0'
							}}>
							{t('menuComponent.productsBusiness')}
						</Text>
					</NavLink>
				</MenuItem>
			)}


			<MenuItem
				id="gb-menu-item-resource"
				onClickCapture={
					isMobileOnly
						? () => {
							toggleCollapsed();
							changeParClickMenu();
						}
						: !collapsed
							? () => {
								toggleCollapsed();
								onCollapsedTouch();
								changeParClickMenu();
							}
							: () => {
								changeParClickMenu();
							}
				}
				key={'menuComponent.resource'}
				icon={
					rolUser !== 'realestate' ? (
						<img
							src={selectedMenuItem === 'menuComponent.resource' ? calculatorBlue : calculator}
							style={{
								height: '24px',
								width: '24px',
								marginRight: '8px',
								marginLeft: '-4px'
							}}
							alt="simuladores"
						/>
					) : (
						<img
							src={selectedMenuItem === 'menuComponent.resource' ? MenuAzul : MenuBlack}
							style={{
								height: '20px',
								width: '20px',
								marginRight: '10px',
							}}
							alt="simuladores"
						/>
					)
				}
				style={{
					height: '56px',
					paddingTop: collapsed && !isMobileOnly && '8px',
					marginTop: '0px',
					marginBottom: 0,
					paddingLeft: isMobile && 16
				}}>
				<NavLink
					to={rolUser === 'realestate' ? '/documents' : '/calculators'}
					style={{ marginLeft: '12px' }}>
					<Text
						style={{
							position: 'relative',
							top: 0,
							color: selectedMenuItem === 'menuComponent.resource' ? '#02C3CD' : '#748EA0'
						}}>
						{rolUser !== 'realestate' ? t('menuComponent.resource') : t('frecuenceQuestions.title')}
					</Text>
				</NavLink>
			</MenuItem>

			{rolUser !== 'realestate' && (
				<MenuItem
					id="gb-menu-item-profile"
					onClickCapture={
						isMobileOnly
							? () => {
								toggleCollapsed();
								changeParClickMenu();
							}
							: !collapsed
								? () => {
									toggleCollapsed();
									onCollapsedTouch();
									changeParClickMenu();
								}
								: () => {
									changeParClickMenu();
								}
					}
					key={'menuComponent.profileNav'}
					icon={
						<img
							src={selectedMenuItem === 'menuComponent.profileNav' ? profileAzul : profileBlack}
							style={{
								height: '14px',
								width: '18px',
								marginRight: '12px'
								// marginLeft: "-2px",
							}}
							alt="financiero"
						/>
					}
					style={{
						height: '56px',
						paddingTop: collapsed && !isMobileOnly && '8px',
						marginTop: '0px',
						marginBottom: 0,
						paddingLeft: isMobile && 16
					}}>
					<NavLink to="/my-financial-profile/credits/list" style={{ marginLeft: '14px' }}>
						<Text
							style={{
								position: 'relative',
								top: 0,
								left: -4,
								color: selectedMenuItem === 'menuComponent.profileNav' ? '#02C3CD' : '#748EA0'
							}}>
							{t('menuComponent.profileNav')}
						</Text>
					</NavLink>
				</MenuItem>
			)}

			{(rolUser === 'client' || rolUser === 'friend') && allRoles[0] !== 'r' && (
				<MenuItem
					id="gb-menu-item-friend"
					onClickCapture={() => changePar()}
					style={{
						position: !isMobileOnly && 'fixed',
						maxWidth:
							collapsed !== false && isMobileOnly
								? '100%'
								: collapsed === false && !isMobileOnly
									? 254
									: 56,
						height: 56,
						background:
							selectedMenuItem === '/friend' || selectedMenuItem === '/friendInfo'
								? '#FFFFFF'
								: '#02C3CD',
						borderRight: selectedMenuItem === '/friendInfo' ? '3px solid #02C3CD' : undefined,
						left: 0,
						bottom: -4,
						zIndex: 1000000000,
						paddingLeft: 24,
						paddingRight: 20
					}}
					key={operations && operations.length > 0 ? '/friend' : '/friendInfo'}
					icon={
						<img
							src={
								selectedMenuItem === '/friend' || selectedMenuItem === '/friendInfo'
									? AmigobsBlue
									: AmigobsWhite
							}
							style={{
								height: '20px',
								width: '20px',
								marginTop: collapsed && !isMobileOnly ? 14 : 0,
								marginLeft: -8
							}}
							alt="Amigobs"
						/>
					}>
					{operations && operations.length > 0 ? (
						<NavLink
							to={rolUser === 'friend' ? '/friendInfo' : '/dashboard-client'}
							style={{ marginLeft: '16px' }}>
							<Text
								style={{
									position: 'relative',
									top: !isMobileOnly ? 0 : 2,
									color:
										selectedMenuItem === '/friend' || selectedMenuItem === '/friendInfo'
											? '#02C3CD'
											: '#FFFFFF'
								}}>
								{t('header.plain')}
							</Text>
						</NavLink>
					) : (
						<NavLink
							to={rolUser === 'friend' ? '/friend' : '/dashboard-client'}
							style={{ marginLeft: '16px' }}>
							<Text
								style={{
									position: 'relative',
									top: isMobileOnly ? 0 : 2,
									color:
										selectedMenuItem === '/friend' || selectedMenuItem === '/friendInfo'
											? '#02C3CD'
											: '#FFFFFF'
								}}>
								{collapsed ? (!isMobileOnly ? undefined : t('header.plain')) : t('header.plain')}
							</Text>
						</NavLink>
					)}
				</MenuItem>
			)}
		</Menu>
	);
};

export default MenuComponent;
