import React, { useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Button, Card, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import ModalInfoFunding from '../../../Pages/Promoter-loan/CommonComponents/ModalInfoFundings';

const { Text } = Typography;

function WidgetInmo({ title, description, action, i, image, button, promoter }) {
  const { t } = useTranslation();
  const navigate = useNavigate();;
  const [modalPromoter, setModalPromoter] = useState(false)

  if (i === 0) {
    return (
      <Card
        className={isMobileOnly ? 'gibobs-widget-info-inmo-mobile' : 'gibobs-widget-info-inmo'}
        key={title}
        cover={image && <img src={image} alt="initCard" style={{ width: '100%' }} />}
        style={{
          height: isMobileOnly && (i === 1 || i === 0) && 'auto',
          marginTop: !isMobileOnly && 40,
          backgroundSize: 'cover',
          width: !isMobileOnly && 'auto',
          marginRight: !isMobileOnly && 0
        }}>
        <div className="style-colunms-left">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Text style={{ fontSize: 16, fontWeight: 600, color: '#2F4858' }}>{title}</Text>
            {isMobileOnly && title === t('b2bOperationList.newMortgage') && (
              <div
                style={{
                  // position: "absolute",
                  // top: 74,
                  // right: 24,
                  color: '#02C3CD'
                }}
                onClick={() => navigate(`${action}`)}>
                {title === t('init.MortgageSimulator') ? t('init.simulate') : t('loan.applyFor')}
                <RightOutlined style={{ color: '#02C3CD', marginLeft: 4 }} />
              </div>
            )}
          </div>
          <Text
            style={{
              fontSize: 14,
              fontWeight: 400,
              color: '#748EA0',
              marginTop: 10
            }}>
            {description}
          </Text>
        </div>

        {title === t('init.createOperations') && button && (
          <div style={{ textAlign: 'center' }}>
            <img
              src={button}
              alt="initCard"
              style={{
                width: '120px',
                marginTop: isMobileOnly ? '8px' : '24px'
              }}
              onClick={() => navigate(`${action}`)}
            />
          </div>
        )}

        {!isMobileOnly &&
          title !== t('init.createOperations') &&
          description !== t('init.descriptionDashboardTutorial') && (
            <Button
              type="primary"
              className="button-primary-gibobs-inmo button-cards-new-styles"
              style={{
                bottom: 24,
                left: 24,
                width: 'calc(100% - 48px)'
              }}
              onClick={() => navigate(action)}>
              <span style={{ marginTop: 4 }}>{t('init.simulate')}</span>
            </Button>
          )}
      </Card>
    );
  } else {
    return (
      <>
        <Card
          className={isMobileOnly ? 'gibobs-widget-info-inmo-mobile' : 'gibobs-widget-info-inmo'}
          key={title}
          cover={image && <img src={image} alt="initCard" style={{ width: '100%' }} />}
          style={{
            height: isMobileOnly && (i === 1 || i === 0) && 'auto',
            marginTop: !isMobileOnly && 40,
            width: !isMobileOnly && 'auto',
            marginRight: !isMobileOnly && 0,
            backgroundColor:
              title === t('calculator.cardTitle1') ||
                title === t('calculator.cardTitle3') ||
                title === t('calculator.cardTitle4')
                ? '#EFFCFC'
                : undefined
          }}>
          <div className="style-colunms-left">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Text className="title-cards">{title}</Text>
              {isMobileOnly &&
                (title === t('calculator.cardTitle4') || title === t('calculator.cardTitle3')) && (
                  <div
                    style={{
                      // position: "absolute",
                      // top: 74,
                      // right: 24,
                      color: '#02C3CD'
                    }}
                    onClick={() => navigate(`${action}`)}>
                    {title === t('calculator.cardTitle4') || title === t('calculator.cardTitle3')
                      ? t('init.simulate')
                      : t('loan.applyFor')}
                    <RightOutlined style={{ color: '#02C3CD', marginLeft: 4 }} />
                  </div>
                )}
            </div>
            <Text className="description-cards">{description}</Text>
          </div>

          {title === t('init.createOperations') && button && (
            <div style={{ textAlign: 'center' }}>
              <img
                src={button}
                alt="initCard"
                style={{
                  width: '120px',
                  marginTop: isMobileOnly ? '8px' : '24px'
                }}
                onClick={() => navigate(`${action}`)}
              />
            </div>
          )}

          {!isMobileOnly &&
            title !== t('init.createOperations') &&
            description !== t('init.descriptionDashboardTutorial') &&
            title !== t('promoterLoan.promoterFinancingProducts') &&
            promoter !== true && (
              <Button
                type="primary"
                className="button-primary-gibobs-inmo button-cards-new-styles"
                style={{
                  bottom: 24,
                  left: 24,
                  width: isMobileOnly ? 264 : 'calc(100% - 48px)'
                }}
                onClick={() => navigate(`${action}`)}>
                {title === t('calculator.cardTitle1') ||
                  title === t('promoterLoan.promoterFinancingProducts')
                  ? t('newRequest.getFile')
                  : t('calculator.cardButton2')}
              </Button>
            )}

          {description === t('init.descriptionDashboardTutorial') && (
            <Button
              type="dashed"
              className="button-secundary-gibobs-inmo button-cards-new-styles"
              style={{
                bottom: 24,
                left: 24,
                width: isMobileOnly ? 264 : 'calc(100% - 48px)'
              }}
              target={'_blank'}
              href="/guia_plataforma_gibobs_desktop.pdf">
              <div style={{ marginTop: 4 }}>{t('init.download')}</div>
            </Button>
          )}
          {promoter === true && (
            <Button
              type="dashed"
              className="button-secundary-gibobs-inmo button-cards-new-styles"
              style={{
                bottom: 24,
                left: 24,
                width: isMobileOnly ? 264 : 'calc(100% - 48px)'
              }}
              onClick={() => navigate(`${action}`)}>
              <div>{t('promoterLoan.myProjects')}</div>
            </Button>
          )}
          {title === t('promoterLoan.promoterFinancingProducts') && (
            <Button
              type="primary"
              className="button-primary-gibobs-inmo button-cards-new-styles"
              style={{
                bottom: 54,
                left: 24,
                width: 'calc(100% - 48px)'
              }}
              onClick={() => action()}>
              <div style={{ marginTop: 4 }}>{t('promoterLoan.newProject')}</div>
            </Button>
          )}
          {title === t('promoterLoan.promoterFinancingProducts') && (
            <div
              onClick={() => setModalPromoter(true)}
              style={{
                display: 'flex',
                justifyContent: 'center',
                bottom: 20,
                position: 'absolute',
                width: 'calc(100% - 48px)',
                cursor: 'pointer',
              }}>

              <Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 500 }}>
                {t('promoter.whatSee')}
              </Text>
            </div>
          )}
        </Card>
        <ModalInfoFunding visible={modalPromoter} setVisible={setModalPromoter} />
      </>
    );
  }
}

export default WidgetInmo;
