import React from "react";
import { Button, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Progress } from "antd";
import { NavLink } from "react-router-dom";

const { Text } = Typography;

const BalanceWidgets = ({ completed }) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "80%",
        }}
      >
        <Text
          style={{
            fontSize: "14px",
            fontWeight: "600",
            marginBottom: "16px",
          }}
        >
          {t("scoring.completedYourBalance")}
        </Text>
        <Text
          style={{
            width: "100%",
            height: "100%",
            fontSize: "12px",
            marginBottom: "20px",
            color: "#748EA0",
            lineHeight: 1.5715
          }}
        >
          {completed && completed >= 87.5
            ? t("scoring.completed")
            : t("scoring.completed2")}
        </Text>
      </div>
      <div style={{ display: "flex", alignContent: "center" }}>
        <Progress
          size="small"
          strokeColor={{
            "0%": "#80E1E6",
            "100%": "#02AFB8",
          }}
          percent={completed ? completed : 0}
        />
        <NavLink to="/my-financial-profile/balance">
          <Button
            type="primary"
            className="button-primari-gibobs"
            style={{ width: "auto", marginLeft: "24px" }}
          >
            {completed && completed >= 95
              ? t("scoring.update")
              : t("loan.complete")}
          </Button>
        </NavLink>
      </div>
    </div>
  );
};

export default BalanceWidgets;
