import React, { useEffect, useState } from "react";
import {
  Row,
  Button,
  Card,
  Skeleton,
  Col,
  Typography,
  Form,
  Select,
  Slider,
  Modal,
  InputNumber
} from "antd";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  EditOutlined,
  EuroCircleOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";

import { useSelector } from "react-redux";
import axios from "axios";
import env from "../../../environment";
import numeral from "numeral";
import _ from "lodash";

const { Text } = Typography;
const { Option } = Select;

const CalculatorsQualifyClientMobile = ({}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const rol = useSelector((state) => state.auth.roles);
  const navigate = useNavigate();;
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [term, setTerm] = useState(false);
  const [rate, setRate] = useState(false);
  const [finish, setFinish] = useState(false);
  const dataProfile = useSelector((state) => state.data.dataProfile);
  const [result, setResult] = useState(undefined);
  const userToken = useSelector((state) => state.auth.token);
  const [fixed, setFixed] = useState(1.9);
  const [quotes, setQuotes] = useState(360);
  const [price, setPrice] = useState(10000);
  const [, setSavings] = useState(55000);
  const [, setProvince] = useState(dataProfile && dataProfile.province);
  const [, setFamily] = useState(false);
  const [visibleFamily, setVisibleFamily] = useState(false);
  const [showFirstModal, setShowFirstModal] = useState(true);
  const [houseType, setHouseType] = useState("main");
  const [resultValuesForm, setResultValuesForm] = useState(undefined);
  const [priceTouch, setPriceTouch] = useState(
    location.state ? location.state.price : undefined
  );
  const [timeoutReference, setTimeoutReference] = useState(undefined);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [1500]);

    if (!_.isNil(location.state)) {
      setResult(location.state.result);
      setResultValuesForm(location.state);

      setShowFirstModal(false);
    }
  }, []);

  useEffect(() => {
    if (
      result &&
      result?.bonilla &&
      result?.bonilla[1]?.maxBudget !== price &&
      priceTouch === undefined
    ) {
      setPrice(result?.bonilla[1]?.maxBudget);
    } else if (priceTouch !== undefined) {
      setPrice(priceTouch);
    }
    if (typeof result === "string") {
      setPrice(undefined);
    }
  }, [result && result?.bonilla && result?.bonilla[1]?.maxBudget]);

  const sendFinish = () => {
    //envio a a la operacion con el test ab
    setFinish(false);
   
    navigate(`/sales-tools/mortgages`,{
      state: {
        values: { ...form.getFieldsValue() },
        fixed,
        quotes,
        result: result !== undefined ? result.simulation : undefined,
      }
    });
  };

  const onChangeFixed = (value) => {
    setFixed(value);
    const tmp = form.getFieldsValue();
    const tmpValues = {
      fixed: value,
      quotes: undefined,
    };
    onChangeForm(tmpValues, tmp, price);
  };

  const onChangeTerm = (value) => {
    setQuotes(value * 12);
    const tmp = form.getFieldsValue();
    const tmpValues = {
      fixed: undefined,
      quotes: value * 12,
    };
    onChangeForm(tmpValues, tmp, price);
  };

  const onChangeForm = (values, allValues, price, familyTmp) => {
    if (allValues !== undefined) {
      setSavings(allValues.savings);
      setProvince(allValues.province);
    }

    if (timeoutReference) {
      clearTimeout(timeoutReference);
    }

    setTimeoutReference(
      setTimeout(() => {
        if (result !== undefined && price >= 10000) {
          if (_.isNil(result.simulation.simulationData.owner2)) {
            let owners = [];
            owners = [result.simulation.simulationData.owner1];

            axios
              .post(
                `${env.api.url}/mortgages/simulations/client-qualification/mortgage-viability`,
                {
                  id: !_.isNil(result) ? result.simulation.id : undefined,
                  simulationType: rol === 'realestate' ? "B2B" : "B2C",
                  savings: result.simulation.simulationData.savings,
                  propertyType: result.simulation.simulationData.propertyType,
                  numerousFamily:
                    familyTmp === undefined
                      ? _.isNil(result.simulation.simulationData.numerousFamily)
                        ? false
                        : result.simulation.simulationData.numerousFamily
                      : familyTmp,
                  province: result.simulation.simulationData.province,
                  maxBudget:
                    price !== 10000
                      ? price
                      : result.simulation.simulationData.maxBudget,
                  fixed:
                    values?.fixed !== undefined && fixed !== values?.fixed
                      ? values?.fixed
                      : result.simulation.simulationData.fixed,
                  quotes: values.quotes ? values.quotes :
                      quotes !== 360
                        ? quotes
                        : result.simulation.simulationData.quotes,

                  participants: {
                    houseType: !_.isNil(
                      result.simulation.simulationData.houseType
                    )
                      ? result.simulation.simulationData.houseType
                      : houseType,
                    participants:
                      result.simulation.simulationData.owner1.activity1 !==
                      undefined
                        ? 2
                        : 1,
                    owners: owners,
                  },
                },
                {
                  headers: { Authorization: `Bearer ${userToken}` },
                }
              )
              .then((response) => {
                setResult(response.data.data);
              })
              .catch((error) => {
                console.error(error);
              });
          } else {
            axios
              .post(
                `${env.api.url}/mortgages/simulations/client-qualification/mortgage-viability`,
                {
                  id: !_.isNil(result) ? result.simulation.id : undefined,
                  simulationType: rol === 'realestate' ? "B2B" : "B2C",
                  savings: result.simulation.simulationData.savings,
                  propertyType: result.simulation.simulationData.propertyType,
                  numerousFamily:
                    familyTmp === undefined
                      ? _.isNil(result.simulation.simulationData.numerousFamily)
                        ? false
                        : result.simulation.simulationData.numerousFamily
                      : familyTmp,
                  province: result.simulation.simulationData.province,
                  maxBudget:
                    price !== 10000
                      ? price
                      : result.simulation.simulationData.maxBudget,
                  fixed:
                    values?.fixed !== undefined && fixed !== values?.fixed
                      ? values?.fixed
                      : result.simulation.simulationData.fixed,
                  quotes: values.quotes ? values.quotes :
                      quotes !== 360
                        ? quotes
                        : result.simulation.simulationData.quotes,

                  participants: {
                    houseType: !_.isNil(
                      result.simulation.simulationData.houseType
                    )
                      ? result.simulation.simulationData.houseType
                      : houseType,
                    participants: 2,
                    owners: [
                      result.simulation.simulationData.owner1,
                      result.simulation.simulationData.owner2,
                    ],
                  },
                },
                {
                  headers: { Authorization: `Bearer ${userToken}` },
                }
              )
              .then((response) => {
                setResult(response.data.data);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        }
        clearTimeout(timeoutReference);
      }, 500)
    );
  };

  const renderTitle = () => {
    if (
      result.viability.excepcionalViability === "NOSAVINGS" &&
      rol === "realestate"
    ) {
      return t("onboarding.cantBuyWithoutSavingsInmo");
    } else if (
      result.viability.excepcionalViability === "NOSAVINGS" &&
      rol !== "realestate"
    ) {
      return t("onboarding.cantBuyWithoutSavings");
    } else if (
      result.viability.excepcionalViability === "DTI_OVER" &&
      rol === "realestate"
    ) {
      return t("onboarding.cantBuyWithoutSavingsInmo");
    } else if (
      result.viability.excepcionalViability === "DTI_OVER" &&
      rol !== "realestate"
    ) {
      return t("calculator.warnig3");
    } else if (
      result.viability.excepcionalViability === "INCOME_LEVEL" &&
      rol === "realestate"
    ) {
      return t("onboarding.cantBuyWithoutSavingsInmo");
    } else if (
      result.viability.excepcionalViability === "INCOME_LEVEL" &&
      rol !== "realestate"
    ) {
      return t("calculator.warnig5");
    } else {
      if (result.viability.excepcionalViability === "MORTGAGE_AMOUNT") {
        return t("onboarding.ItsBetterTellTruth");
      }
    }
  };

  const renderDesc = () => {
    if (
      result.viability.excepcionalViability === "NOSAVINGS" &&
      rol === "realestate"
    ) {
      return t("onboarding.cantBuyWithoutSavingsDescriptionInmo");
    } else if (
      result.viability.excepcionalViability === "NOSAVINGS" &&
      rol !== "realestate"
    ) {
      return t("onboarding.cantBuyWithoutSavingsDescription");
    } else if (
      result.viability.excepcionalViability === "DTI_OVER" &&
      rol === "realestate"
    ) {
      return t("calculator.warnig4Inmo");
    } else if (
      result.viability.excepcionalViability === "DTI_OVER" &&
      rol !== "realestate"
    ) {
      return t("calculator.warnig4");
    } else if (
      result.viability.excepcionalViability === "INCOME_LEVEL" &&
      rol === "realestate"
    ) {
      return t("calculator.warnig6Inmo");
    } else if (
      result.viability.excepcionalViability === "INCOME_LEVEL" &&
      rol !== "realestate"
    ) {
      return t("calculator.warnig6");
    } else if (
      result.viability.excepcionalViability === "MORTGAGE_AMOUNT" &&
      rol === "realestate"
    ) {
      return t("onboarding.ItsBetterTellTruthDescription");
    } else if (
      result.viability.excepcionalViability === "MORTGAGE_AMOUNT" &&
      rol !== "realestate"
    ) {
      return t("onboarding.ItsBetterTellTruthDescription");
    }
  };

  const renderMax = () => {
    if (result) {
      if (result.simulation) {
        const maxAge2 = result.simulation.simulationData.owner2
          ? result.simulation.simulationData.owner2.age
          : 0;
        const maxAge1 = result.simulation.simulationData.owner1
          ? result.simulation.simulationData.owner1.age
          : 40;

        const tmpAge = maxAge2 >= maxAge1 ? maxAge2 : maxAge1;

        const tmpAgeMin = 75 - tmpAge;

        const ageValue = tmpAgeMin <= 40 ? tmpAgeMin : 40;

        return ageValue;
      }
    }
  };

  const renderBarProgress = () => {
    let percentOne =
      (result?.bonilla[1].maxBudget * 100) /
      (result?.bonilla[2].maxBudget + 100000);
    percentOne = parseInt(percentOne);

    let percentSecond =
      (result?.bonilla[2].maxBudget * 100) /
      (result?.bonilla[2].maxBudget + 100000);
    percentSecond = parseInt(percentSecond - percentOne);

    const tmp = percentOne + percentSecond;

    const total = 100 - parseInt(tmp);

    return (
      <>
        <div
          style={{
            width: 300,
            display: "flex",
            marginTop: 24,
            marginBottom: 10,
          }}
        >
          <div
            style={{
              width: `${percentOne}%`,
            }}
          ></div>
          <Text
            style={{
              color: "#748EA0",
              fontSize: 12,
              fontWeight: 400,
              width: "50%",
            }}
          >
            {numeral(result?.bonilla[1].maxBudget).format("0,0 $")}
          </Text>
          <div
            style={{
              width: `${percentSecond}%`,
            }}
          ></div>
          <Text
            style={{
              color: "#748EA0",
              fontSize: 12,
              fontWeight: 400,
              width: "50%",
            }}
          >
            {numeral(result?.bonilla[2].maxBudget).format("0,0 $")}
          </Text>
          <div
            style={{
              width: `${total}%`,
            }}
          ></div>
        </div>

        <div style={{ width: 300, display: "flex" }}>
          <div
            style={{
              width: `${percentOne}%`,
              background: "#95DE64",
              height: "5px",
              borderRadius: "8px 0px 0px 8px",
            }}
          ></div>
          <Text style={{ color: "#748EA0", marginTop: -10, marginRight: -1 }}>
            |
          </Text>

          <div
            style={{
              width: `${percentSecond}%`,
              background: "#FAAD14",
              height: "5px",
            }}
          ></div>
          <Text style={{ color: "#748EA0", marginTop: -10, marginRight: -1 }}>
            |
          </Text>
          <div
            style={{
              width: `${total}%`,
              background: "#FF4D4F",
              height: "5px",
              borderRadius: "0px 8px 8px 0px",
            }}
          ></div>
        </div>
      </>
    );
  };

  const familyNumber = (e) => {
    setFamily(e);
    onChangeForm(undefined, undefined, price, e);
  };

  const onChangePrice = (value) => {
    setPrice(value);
    setPriceTouch(value);
    const tmp = form.getFieldsValue();

    const price = value;
    const tmpValues = {
      fixed: undefined,
      quotes: undefined,
    };
    onChangeForm(tmpValues, tmp, price);
  };

  const renderResult = () => {
    let value = undefined;
    if (price > 10000) {
      value = price.toFixed("0");
    } else {
      value = numeral(_.get(result, "bonilla[1].maxBudget", 0)).format("0,0");
    }

    return value;
  };

  return (<>
    <div style={{ padding: 16, marginBottom: 200 }}>
      <Col span={24} style={{ textAlign: "center" }}>
        {!loading && (
          <div
            style={{ display: "flex", justifyContent: "right" }}
            onClick={() => {
             
              navigate(`/qualify/calculators/fee/datas`,{
                state: {
                  result: resultValuesForm,
                  price: priceTouch
                    ? priceTouch
                    : result?.simulation?.simulationData?.maxBudget,
                  quotes: result?.simulation?.simulationData?.term,
                  fixed: result?.simulation?.simulationData?.fixed,
                  province: result?.simulation?.province,
                  edit: true,
                }
              });
            }}
          >
            <Text style={{ fontWeight: 500, fontSize: 14, color: "#02C3CD" }}>
              {t("loan.editData")}
            </Text>
            <EditOutlined
              style={{ color: "#02C3CD", fontSize: 20, marginLeft: 4 }}
            />
          </div>
        )}

        <Skeleton active loading={loading} paragraph={false} />
        {!loading && (
          <>
            <div style={{ marginTop: 16 }}>
              <Text
                style={{
                  color: "#2F4858",
                  fontWeight: 600,
                  fontSize: 16,
                }}
              >
                {t("qualify.titlte")}
              </Text>
            </div>

            {result &&
              result?.mortgageCeiling &&
              result?.mortgageCeiling.mortgageAmountLimit === "savings" && (
                <Card
                  className="gb-card-background-blue-mobile"
                  style={{ marginTop: 24 }}
                >
                  <div>
                    <EuroCircleOutlined
                      style={{ color: "#02C3CD", fontSize: 32 }}
                    />
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <Text
                      style={{
                        color: "#2F4858",
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      {t("qualify.warnignLimit")}
                    </Text>
                  </div>
                  <div style={{ marginTop: 8 }}>
                    <Text
                      style={{
                        color: "#748EA0",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      {t("qualify.warnignLimit2")}
                    </Text>
                  </div>
                </Card>
              )}

            {result &&
              result?.mortgageCeiling &&
              result?.mortgageCeiling.mortgageAmountLimit !== "savings" && (
                <Card
                  className="gb-card-background-blue-mobile"
                  style={{ marginTop: 24 }}
                >
                  <div>
                    <EuroCircleOutlined
                      style={{ color: "#02C3CD", fontSize: 32 }}
                    />
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <Text
                      style={{
                        color: "#2F4858",
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      {t("qualify.warnignLimit3")}
                    </Text>
                  </div>
                  <div style={{ marginTop: 8 }}>
                    <Text
                      style={{
                        color: "#748EA0",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      {t("qualify.warnignLimit4")}
                    </Text>
                  </div>
                </Card>
              )}

            <div style={{ marginTop: 24 }}>
              <Text
                style={{
                  color: "#2F4858",
                  fontWeight: 600,
                  fontSize: 16,
                }}
              >
                {t("qualify.titlte2")}
              </Text>
            </div>
            <div style={{ marginTop: 24, textAlign: "-webkit-center" }}>
              {result && result?.bonilla && renderBarProgress()}
            </div>

            <Row
              style={{
                marginTop: 30,
                justifyContent: "center",
              }}
            >
              <Col span={10} style={{ display: "flex" }}>
                <Text
                  style={{
                    color: "#52C41A",
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  {result !== undefined
                    ? t("qualify.until") +
                      " " +
                      numeral(result?.bonilla[1].maxBudget).format("0,0 $")
                    : t("qualify.until") + " " + "-"}
                </Text>
              </Col>
              <Col span={14} style={{ textAlign: "left" }}>
                <Text
                  style={{
                    color: "#748EA0",
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                >
                  {t("qualify.bankNoProblem")}
                </Text>
              </Col>
            </Row>

            <Row
              style={{
                marginTop: 30,
                justifyContent: "center",
              }}
            >
              <Col span={10} style={{ display: "flex" }}>
                <Text
                  style={{
                    color: "#FAAD14",
                    fontSize: 16,
                    fontWeight: 600,
                    textAlign: "left",
                  }}
                >
                  {result !== undefined
                    ? t("qualify.until") +
                      " " +
                      numeral(result?.bonilla[2].maxBudget).format("0,0 $")
                    : t("qualify.until") + " " + "-"}
                </Text>
              </Col>
              <Col span={14} style={{ textAlign: "left" }}>
                <Text
                  style={{
                    color: "#748EA0",
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                >
                  {t("qualify.bankProblem")}
                </Text>
              </Col>
            </Row>
          </>
        )}

        {!loading && (
          <Row style={{ width: "100%", marginTop: 24 }}>
            <Col span={12} style={{ textAlign: "left", marginTop: 10 }}>
              <Text
                style={{
                  color: "#2F4858",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                {t("qualify.price")}
              </Text>
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              <InputNumber
                onChange={(value) => onChangePrice(value)}
                placeholder={renderResult()}
                addonAfter={"€"}
                min={10000}
                style={{
                  border: "1px solid #02C3CD",
                  borderRadius: 4,
                  height: 40,
                }}
              />
            </Col>
          </Row>
        )}

        {!loading && (
          <Row style={{ width: "100%", marginTop: 32 }}>
            <Col span={12} style={{ marginTop: 16, textAlign: "left" }}>
              <Text
                style={{
                  color: "#2F4858",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                {t("loan.rate")}
              </Text>
              <InfoCircleOutlined
                onClick={() => setRate(true)}
                style={{ color: "#02C3CD", marginLeft: 8 }}
              />
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              <InputNumber
                onChange={(value) => onChangeFixed(value)}
                defaultValue={
                  result === undefined
                    ? undefined
                    : result.simulation.simulationData.fixed
                }
                value={
                  result === undefined
                    ? undefined
                    : result.simulation.simulationData.fixed
                }
                placeholder="1.31"
                addonAfter={"%"}
                style={{
                  border: "1px solid #02C3CD",
                  borderRadius: 4,
                  height: 40,
                }}
              />
              {result && !_.isNil(result.quote) && (
                <div style={{ marginTop: 8, textAlign: "left" }}>
                  <Text
                    style={{
                      color: "#016B71",
                      fontSize: 12,
                      fontWeight: 400,
                    }}
                  >
                    {t("fee.calculatorEuribor") +
                      " " +
                      numeral(result.quote.rates.euribor).format("0,0.00 %")}
                  </Text>
                </div>
              )}
            </Col>
          </Row>
        )}

        <Skeleton active loading={loading} paragraph={false} />

        {!loading && (
          <Row style={{ width: "100%", marginTop: 32 }}>
            <Col span={6} style={{ marginTop: 0, textAlign: "left" }}>
              <Text
                style={{
                  color: "#2F4858",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                {t("loan.term")}
              </Text>
              <InfoCircleOutlined
                onClick={() => setTerm(true)}
                style={{ color: "#02C3CD", marginLeft: 8 }}
              />
            </Col>
            <Col span={18} style={{ textAlign: "right" }}>
              <Slider
                onChange={(value) => onChangeTerm(value)}
                defaultValue={30}
                min={5}
                max={renderMax()}
                tooltip={{
                  formatter: (value) => `${value} ${t("form.years")}`
                }}
              />
            </Col>
          </Row>
        )}
        <Row style={{ width: "100%" }}>
          <Col
            span={24}
            style={{ marginTop: 0, textAlign: "-webkit-center" }}
          >
            <Card
              className="gcardMovile-16-padding"
              style={{
                borderRadius: 8,
                marginTop: 30,
                background: "#EFFCFC",
                boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
              }}
            >
              <Skeleton active loading={loading} paragraph={false} />

              {!loading && (
                <>
                  <Row>
                    <Col span={12} style={{ textAlign: "left" }}>
                      <Text
                        style={{
                          color: "#748EA0",
                          fontWeight: 400,
                          fontSize: 14,
                        }}
                      >
                        {t("qualify.priceMortgage")}
                      </Text>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                      <Text
                        style={{
                          color: "#2F4858",
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        {result
                          ? numeral(
                              result.quote.operation.mortgageAmount < 0
                                ? 0
                                : result.quote.operation.mortgageAmount
                            ).format("0,0 $")
                          : numeral(0).format("0,0 $")}
                      </Text>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: 18 }}>
                    <Col span={12} style={{ textAlign: "left" }}>
                      <Text
                        style={{
                          color: "#748EA0",
                          fontWeight: 400,
                          fontSize: 14,
                        }}
                      >
                        {t("qualify.quoteMortgage")}
                      </Text>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                      <Text
                        style={{
                          color: "#02C3CD",
                          fontWeight: 600,
                          fontSize: 20,
                        }}
                      >
                        {result
                          ? numeral(
                              result.quote.operation.types.fixed.quote < 0
                                ? 0
                                : result.quote.operation.types.fixed.quote
                            ).format("0,0 $")
                          : numeral(0).format("0,0 $")}
                      </Text>
                    </Col>
                  </Row>
                </>
              )}
            </Card>
          </Col>
        </Row>

        {/**warnign mas casos TODO */}
        {result &&
          result?.viability &&
          result.viability.excepcionalViability !== undefined && (
            <Card
              className="card-second"
              style={{
                border: "1px solid #02C3CD",
                borderRadius: 4,
                marginTop: 40,
              }}
            >
              <div style={{ textAlign: "center" }}>
                <WarningOutlined style={{ color: "#02C3CD", fontSize: 40 }} />
              </div>

              <div style={{ textAlign: "center", marginTop: 8 }}>
                <Text
                  style={{
                    color: "#2F4858",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  {result && result.viability && renderTitle()}
                </Text>
              </div>
              <div style={{ textAlign: "center", marginTop: 8 }}>
                <Text
                  style={{
                    color: "#748EA0",
                    fontWeight: 400,
                    fontSize: 14,
                  }}
                >
                  {result && result.viability && renderDesc()}
                </Text>
              </div>
            </Card>
          )}

        {/*family === false && (
          <div style={{ marginTop: 24 }}>
            <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 500 }}>
              {t("qualify.clickToFamily")}
            </Text>
            <Text
              style={{
                color: "#02C3CD",
                fontSize: 14,
                fontWeight: 500,
                cursor: "pointer",
              }}
              onClick={() => setVisibleFamily(true)}
            >
              {t("typeMortgage.text2TooltipMortgage") + " "}
            </Text>
            <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 500 }}>
              {t("qualify.clickToFamily2")}
            </Text>
          </div>
            )*/}

        {/*family && (
          <div style={{ marginTop: 24 }}>
            <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 500 }}>
              {t("qualify.numberFamilyText") + " "}
            </Text>
            <Text
              style={{
                color: "#02C3CD",
                fontSize: 14,
                fontWeight: 500,
                cursor: "pointer",
              }}
              onClick={() => setVisibleFamily(true)}
            >
              {t("typeMortgage.text2TooltipMortgage") + " "}
            </Text>
            <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 500 }}>
              {t("qualify.numberFamilyText2")}
            </Text>
          </div>
            )*/}

        <Card
          className="cardButtonStyle"
          style={{ left: 0, marginBottom: -2, height: 80 }}
        >
          <Button
            id="gb-mortgage-buy-situation-mobile"
            type="primary"
            style={{
              fontSize: 16,
              height: 48,
              fontWeight: 600,
              width: "100%",
            }}
            onClick={() => {
              if (rol === "realestate" && result !== undefined) {
                setFinish(true);
              } else if (rol === "realestate" && result === undefined) {
                
                navigate(`/qualify/calculators/fee/datas`,{
                  state: {
                    values: { ...form.getFieldsValue() },
                    fixed,
                    quotes,
                    id:
                      result !== undefined ? result.simulation.id : undefined,
                  }
                });
              }
            }}
            disabled={
              result &&
              result.viability &&
              result.viability.excepcionalViability !== undefined
            }
          >
            {rol === "realestate"
              ? t("calculator.clientInmoButton")
              : t("calculator.bankViabilitButton")}
          </Button>
        </Card>
      </Col>
    </div>
    <Modal
      className="ModalCommon-movile-32px"
      okButtonProps={{ style: { width: "46%", height: 40, fontSize: 14 } }}
      open={visibleFamily}
      title={<Text>{t("qualify.numberFamily")}</Text>}
      width={328}
      cancelButtonProps={{
        style: { width: "46%", height: 40, fontSize: 14, color: "#02C3CD" },
      }}
      closable={true}
      onCancel={() => setVisibleFamily(false)}
      onOk={() => setVisibleFamily(false)}
    >
      <div style={{ marginTop: -32 }}>
        <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 400 }}>
          {t("certificated.selectOptionDownload")}
        </Text>

        <Select
          style={{ width: "100%", marginTop: 16, height: 40 }}
          onChange={(e) => familyNumber(e)}
        >
          <Option value={true}>{t("form.yes")}</Option>
          <Option value={false}>{t("form.no")}</Option>
        </Select>
      </div>
    </Modal>
    <Modal
      className="ModalCommon-movile-24px"
      okButtonProps={{ style: { display: "none" } }}
      open={showFirstModal}
      width={328}
      cancelButtonProps={{ style: { display: "none" } }}
      closable={false}
    >
      <div style={{ paddingTop: 16, marginBottom: -20 }}>
        <div>
          <Text style={{ color: "#2F4858", fontSize: 14, fontWeight: 600 }}>
            {t("enter.dataClientInmo")}
          </Text>
        </div>
        <div style={{ marginTop: 8 }}>
          <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 400 }}>
            {t("enter.dataClientInmo2") + " "}
          </Text>
          <Text
            onClick={() => navigate("/qualify/calculators/fee/datas")}
            style={{ color: "#02C3CD", fontSize: 14, fontWeight: 400 }}
          >
            {t("typeMortgage.text2TooltipMortgage")}
          </Text>
        </div>
      </div>
    </Modal>
    <Modal
      className="ModalMobileFooterColum"
      okButtonProps={{ style: { display: "none" } }}
      open={term}
      title={<Text>{t("scoring.term")}</Text>}
      width={480}
      height={136}
      cancelButtonProps={{ style: { display: "none" } }}
      closable={true}
      onCancel={() => setTerm(false)}
    >
      <div style={{ marginBottom: -50 }}>
        <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 400 }}>
          {t("calculator.modal2")}
        </Text>
      </div>
    </Modal>
    <Modal
      className="ModalMobileFooterColum"
      okButtonProps={{ style: { display: "none" } }}
      open={rate}
      title={<Text>{t("loan.rateCurrent")}</Text>}
      width={328}
      height={136}
      cancelButtonProps={{ style: { display: "none" } }}
      closable={true}
      onCancel={() => setRate(false)}
    >
      <div style={{ marginBottom: -45 }}>
        <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 400 }}>
          {t("calculator.modal1")}
        </Text>
      </div>
    </Modal>
    <Modal
      className="ModalMobileFooterColum"
      okButtonProps={{ className: "button-primari-gibobs" }}
      open={finish}
      width={480}
      title={<Text>{t("modal6.titleGood") + " " + dataProfile.name}</Text>}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={() => setFinish(false)}
      onOk={() => sendFinish()}
    >
      <div style={{ marginBottom: 0 }}>
        <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 400 }}>
          {t("calculator.modalFinish")}
        </Text>
      </div>
    </Modal>
  </>);
};

export default CalculatorsQualifyClientMobile;
