import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import numeral from "numeral";

const AnalysisTable = ({ data }) => {
  const { t } = useTranslation();

  const dataSource = [
    {
      key: 1,
      name: t("promoterLoan.financingTitle") + " 1",
      age: numeral(data && data.financing && data.financing.dayOneLTV).format(
        "0,0"
      ),
      address: numeral(
        data && data.financing && data.financing.finalLTV
      ).format("0,0"),
    },
    {
      key: 2,
      name: t("promoterLoan.assetValue"),
      age: numeral(
        data && data.valueOfTheAssets && data.valueOfTheAssets.dayOneLTV
      ).format("0,0"),
      address: numeral(
        data && data.valueOfTheAssets && data.valueOfTheAssets.finalLTV
      ).format("0,0"),
    },
    {
      key: 3,
      name: "LTV Financing 1",
      age:
        data &&
        data.financingLTV &&
        `${numeral(data.financingLTV.dayOneLTV).format("0%")}`,
      address:
        data &&
        data.financingLTV &&
        `${numeral(data.financingLTV.finalLTV).format("0%")}`,
    },
  ];

  const columns = [
    {
      title: (
        <div>
          <div></div>
          <div>{t("promoterLoan.analysisLTV")}</div>
        </div>
      ),
      dataIndex: "name",
      key: "name",
      width: "30%",
    },
    {
      title: (
        <div>
          <div>{t("promoterLoan.dayTitle")} 1</div>
          <div>LTV</div>
        </div>
      ),
      dataIndex: "age",
      key: "age",
      align: "right",
    },
    {
      title: (
        <div>
          <div>Final</div>
          <div>LTV</div>
        </div>
      ),
      dataIndex: "address",
      key: "address",
      align: "right",
    },
  ];

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      rowClassName={(record) => {
        const classes = ["textStyleTable"];
        if (record.key === 2) {
          classes.push("textStyleTableOfferSelect");
        }

        if (record.key === 3) {
          classes.push("textStyleTotal");
          classes.push("textStyleTotalColor");
        }
        return classes;
      }}
      className="table-style-general"
    />
  );
};

export default AnalysisTable;
