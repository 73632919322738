import React from 'react';
import { Card, Typography, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import PieQuota from '../PieQuota';
import { isMobileOnly } from 'react-device-detect';

const { Text } = Typography;

const MonthlyFeeWidget = ({ loan }) => {
	const { t } = useTranslation();

	return (
		<Card
			className="gcardMovile card-heigth-loan"
			style={{ marginTop: isMobileOnly ? 12 : 16, height: 'calc(100% - 36px)' }}>
			<Row gutter={24} style={{ justifyContent: isMobileOnly && 'center' }}>
				<Col
					xl={12}
					lg={24}
					md={24}
					style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<Text
						style={{
							color: '#748EA0',
							fontSize: 12,
							fontWeight: 400
						}}>
						{t('loan.quotaVarOptions')}
					</Text>
					<Text
						style={{
							color: '#02C3CD',
							fontSize: 16,
							fontWeight: 600
						}}>
						{numeral(parseFloat(loan.loan.quota)).format('0,0.00 $')}
					</Text>
				</Col>
				<Col
					xl={12}
					lg={24}
					md={24}
					style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<Text
						style={{
							color: '#748EA0',
							fontSize: 12,
							fontWeight: 400
						}}>
						{t('m_loan.entailmentsTitle')}
					</Text>
					<Text
						style={{
							color: '#2F4858',
							fontSize: 14,
							fontWeight: 600
						}}>
						{numeral(parseFloat(loan.loan.totalEntailments / 12)).format('0,0.00 $')}/
						{t('viability.monthsEstimated')}
					</Text>
					<Text
						style={{
							color: '#2F4858',
							fontSize: 10,
							fontWeight: 500,
							alignSelf: 'center'
						}}>
						({numeral(parseFloat(loan.loan.totalEntailments)).format('0,0.00 $')}/{t('loan.year')})
					</Text>
				</Col>
			</Row>
			<div style={{ display: 'flex', marginTop: 18, justifyContent: isMobileOnly && 'center' }}>
				<div style={{ marginRight: 1 }}>
					<Text
						style={{
							color: '#748EA0',
							fontSize: 12,
							fontWeight: 400
						}}>
						{t('loan.estimatedEquivalentQuota')}
					</Text>
				</div>
				<div>
					<Text
						style={{
							color: '#02C3CD',
							fontSize: 14,
							fontWeight: 600,
							marginLeft: 4
						}}>
						{numeral(parseFloat(loan.loan.quota + loan.loan.totalEntailments / 12)).format(
							'0,0.00 $'
						)}
					</Text>
				</div>
			</div>
			<div>
				<PieQuota
					quota={loan.loan.quota}
					entailment={Number((loan.loan.totalEntailments / 12).toFixed(2))}
				/>
			</div>
		</Card>
	);
};

export default MonthlyFeeWidget;
