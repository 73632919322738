import {
  DATAFORMPROMOTER,
  DATASCENARIOS,
  DATANEWPROJECT,
  LOADING,
  REDIRECT,
  DATALISTMYPROYECTS,
} from "./constants";

export const INITIAL_STATE = {
  dataFormPromoter: {},
  dataListMyProyects: [],
  dataScenarios: [],
  dataNewProject: {},
  loading: false,
  redirect: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DATAFORMPROMOTER:
      return { ...state, dataFormPromoter: action.payload };
    case DATASCENARIOS:
      return { ...state, dataScenarios: action.payload };
    case DATANEWPROJECT:
      return { ...state, dataNewProject: action.payload };
    case LOADING:
      return { ...state, loading: action.payload };
    case REDIRECT:
      return { ...state, redirect: action.payload };
    case DATALISTMYPROYECTS:
      return { ...state, dataListMyProyects: action.payload };
    default:
      return state;
  }
};
