import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Layout,
  Typography,
  Card,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Input,
  Collapse,
} from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getOperations } from "../../store/realestate/actions";
import {
  DoubleRightOutlined,
  InfoCircleOutlined,
  GiftOutlined,
  ExclamationCircleFilled,
  CheckOutlined,
  CloseOutlined,
  EuroCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import env from "../../environment";
import { RWebShare } from "react-web-share";
import shareIcon from "../../assets/icons/shareIcon.svg";
import editIcon from "../../assets/icons/editIcon.svg";
import { notificationGibobs } from "../../Pages/HerramientasVenta/reusableItems";

const { Content } = Layout;
const { Text } = Typography;

const FollowUpPage = () => {
  const navigate = useNavigate();;
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();


  const [form] = Form.useForm();
  const userToken = useSelector((state) => state.auth.token);
  const [showAccount, setShowAccount] = useState(false);
  const mortgageId = useSelector((state) => state.auth.mortgageId);

  const [dataProfile, setDataPersonal] = useState([]);
  const [, setAccountBank] = useState(undefined);
  const [reward, setReward] = useState(0);
  const [modalInfo, setModalInfo] = useState(false);
  const [valuesData, setValuesData] = useState([]);
  let temp = "";
  const [signed, setSigned] = useState(undefined);
  const [noSigned, setNoSigned] = useState(undefined);

  const { Panel } = Collapse;

  const [visibleCode, setVisibleCode] = useState(false);

  useEffect(() => {
    dispatch(getOperations());
    getAccountBank();
    dataPersonalOperation();
    getReward();
    getInfoFriend();
  }, []);

  const showModalBankAccount = () => {
    setShowAccount(!showAccount);
  };

  const renderMessage = (stage) => {
    if (stage === "basic") {
      temp =
        "¡Hola! ¿Aún no has contactado con el equipo de gibobs allbanks para que te consigan una hipoteca a tu medida? Además, cuando elijas tu oferta y firmes tu hipoteca, ¡nos llevaremos 50 € cada uno! No esperes más y entra en tu www.gibobs.com para continuar con tu solicitud.";
    } else if (
      stage === "full" ||
      stage === "documents" ||
      stage === "oferts"
    ) {
      temp =
        "Ey, ¿qué tal? Acabo de ver que tu solicitud de hipoteca con gibobs está en la etapa " +
        stage +
        ". ¡Ya estás cada vez más cerca! Entra en www.gibobs.com y avanza en el proceso para que podamos ganar nuestra recompensa y darnos un capricho.";
    } else if (stage === "banks" || stage === "analysis") {
      temp =
        "¡Qué poco falta para que el equipo de gibobs te consiga la mejor hipoteca del mercado y para llevarnos 50 € cada uno! He visto que están trabajando para encontrarte la que tenga las mejores condiciones para ti, así que ve preparando la mudanza a tu nueva casa. Entra en www.gibobs.com y comprueba el estado de tu operación.";
    } else {
      temp =
        "¡Enhorabuena! Acabo de ver que la solicitud de tu hipoteca con gibobs está en la recta final, ¡la mejor forma de cuidar de tu salud financiera! Cuando firmes tu hipoteca, recuerda avisar a tu analista para que puedan ingresarnos nuestros 50 €.";
    }

    return temp
  };

  const getInfoFriend = () => {
    axios
      .get(`${env.api.url}/v1/operations/list-b2b`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setValuesData(response.data.data);
        setSigned(
          response.data.data.find(
            (item) => item.status === "finish" && item.stage === "finish"
          )
        );
        setNoSigned(
          response.data.data.find(
            (item) => item.status === "finish" && item.stage !== "finish"
          )
        );

        if (response.data.data.length > 0) {
          navigate("/friendInfo");
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const getReward = () => {
    axios
      .get(`${env.api.url}/v1/friends/my-rewards`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setReward(response.data.data);
      });
  };

  const showAmigobsDetails = () => {
    setModalInfo(!modalInfo);
  };

 


  const saveDataAccountBank = (value, type) => {
    axios
      .post(
        `${env.api.url}/v1/user/set-bank-account`,
        { bankAccount: value, clientOrAgent: type, operationId: mortgageId },

        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then(() => {
        dataPersonalOperation();
      });
  };

  const getAccountBank = () => {
    axios
      .get(`${env.api.url}/v1/friends/get-bank-accounts/${mortgageId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setAccountBank(response.data.data || "");
      });
  };

  const dataPersonalOperation = () => {
    //TODO carmbiar el operations[] por el dataPersonalAccountNumber
    axios
      .get(`${env.api.url}/v1/user/profile`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setDataPersonal(response.data.data.profile);
      });
  };

  const showModalLinkCode = () => {
    setVisibleCode(!visibleCode);
  };

  const saveDataCodeLink = (values) => {
    axios
      .post(
        `${env.api.url}/v1/friends/customize-name`,
        { friendName: values },

        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then(() => {
        dataPersonalOperation();
      });
  };


  const renderButtonSend = (item) => {
   

    if (item.status === "finish") {
      
    } else if (item.status === undefined) {
      let phone = item.phone;

      if (phone && !phone.startsWith("+") && !phone.startsWith("0")) {
        phone = `+34${phone}`;
      }
      if (phone) {
        phone = phone.replace("+", "%2B");
      }

      return (
        <Button
          className="button-primari-gibobs"
          type="primary"
          style={{ width: "90%", height: 40 }}
          href={
            "https://api.whatsapp.com/send?phone=" +
            phone +
            "&text=¡Hola! Te animo a que confíes en gibobs allbanks para que te ayuden a conseguir la hipoteca con las mejores condiciones del mercado. Además, cuando te decidas por la mejor oferta y firmes tu hipoteca, ¡nos darán 50 € a cada uno! Entra aquí " +
            item.link +
            " y da tu consentimiento para que puedan a empezar a estudiar tu operación."
          }
          target="_blank"
        >
          {t("amigobs.friendSend")}
        </Button>
      );
    } else if (item.stage === "basic") {
      let phone = item.phone;

      if (phone && !phone.startsWith("+") && !phone.startsWith("0")) {
        phone = `+34${phone}`;
      }
      if (phone) {
        phone = phone.replace("+", "%2B");
      }
      return (
        <Button
          className="button-primari-gibobs"
          type="primary"
          style={{ width: "90%", height: 40 }}
          href={
            "https://api.whatsapp.com/send?phone=" +
            phone +
            "&text=¡Hola! ¿Aún no has contactado con el equipo de gibobs allbanks para que te consigan una hipoteca a tu medida? Además, cuando elijas tu oferta y firmes tu hipoteca, ¡nos llevaremos 50 € cada uno! No esperes más y entra en tu www.gibobs.com para continuar con tu solicitud."
          }
          target="_blank"
        >
          <span style={{ marginTop: 3 }}>{t("amigobs.friendSend")}</span>
        </Button>
      );
    } else if (
      item.stage === "full" ||
      item.stage === "documents" ||
      item.stage === "oferts"
    ) {
      let phone = item.phone;

      if (phone && !phone.startsWith("+") && !phone.startsWith("0")) {
        phone = `+34${phone}`;
      }
      if (phone) {
        phone = phone.replace("+", "%2B");
      }
      let nameStage = "";
      if (item.stage === "full") {
        nameStage = "Perfil de titulares";
      } else if (item.stage === "documents") {
        nameStage = "Documentación";
      } else if (item.stage === "oferts") {
        nameStage = "Selección de oferta";
      }
      return (
        <Button
          className="button-primari-gibobs"
          type="primary"
          style={{ width: "90%", height: 40 }}
          href={
            "https://api.whatsapp.com/send?phone=" +
            phone +
            "&text=Ey, ¿qué tal? Acabo de ver que tu solicitud de hipoteca con gibobs está en la etapa " +
            nameStage +
            ". ¡Ya estás cada vez más cerca! Entra en www.gibobs.com y avanza en el proceso para que podamos ganar nuestra recompensa y darnos un capricho."
          }
          target="_blank"
        >
          {" "}
          <span style={{ marginTop: 3 }}>{t("amigobs.friendSend")}</span>
        </Button>
      );
    } else if (item.stage === "analysis" || item.stage === "banks") {
      let phone = item.phone;

      if (phone && !phone.startsWith("+") && !phone.startsWith("0")) {
        phone = `+34${phone}`;
      }
      if (phone) {
        phone = phone.replace("+", "%2B");
      }
      return (
        <Button
          className="button-primari-gibobs"
          type="primary"
          style={{ width: "90%", height: 40 }}
          href={
            "https://api.whatsapp.com/send?phone=" +
            phone +
            "&text=	¡Qué poco falta para que el equipo de gibobs te consiga la mejor hipoteca del mercado y para llevarnos 50 € cada uno! He visto que están trabajando para encontrarte la que tenga las mejores condiciones para ti, así que ve preparando la mudanza a tu nueva casa. Entra en www.gibobs.com y comprueba el estado de tu operación."
          }
          target="_blank"
        >
          <span style={{ marginTop: 3 }}>{t("amigobs.friendSend")}</span>
        </Button>
      );
    } else if (item.stage === "appraisal" || item.stage === "formalization") {
      let phone = item.phone;

      if (phone && !phone.startsWith("+") && !phone.startsWith("0")) {
        phone = `+34${phone}`;
      }
      if (phone) {
        phone = phone.replace("+", "%2B");
      }
      return (
        <Button
          className="button-primari-gibobs"
          type="primary"
          style={{ width: "90%", height: 40 }}
          href={
            "https://api.whatsapp.com/send?phone=" +
            phone +
            "&text=¡Enhorabuena! Acabo de ver que la solicitud de tu hipoteca con gibobs está en la recta final, ¡la mejor forma de cuidar de tu salud financiera! Cuando firmes tu hipoteca, recuerda avisar a tu analista para que puedan ingresarnos nuestros 50 €."
          }
          target="_blank"
        >
          <span style={{ marginTop: 3 }}>{t("amigobs.friendSend")}</span>
        </Button>
      );
    }
  };

  const copyToClipBoard = () => {
    var aux = document.createElement("input");
    aux.setAttribute(
      "value",
      `https://www.gibobs.com/plan-amigobs/?friend=${dataProfile.friendName}`
    );
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    notificationGibobs("success", t("form.codeCopiedSuccessfully"));
  };

  return (
    (<Content
      style={{ marginLeft: 40, marginTop: 3, marginRight: 10 }}
    >
      {location.pathname === "/friend" &&
        dataProfile &&
        valuesData &&
        valuesData.length === 0 && (
          <Card className="gb-card" style={{ marginBottom: 150 }}>
            <div className="gb-backgroud-friend-mobile">
              <Content>
                <div style={{ width: "100%", marginTop: -5 }}>
                  <Text
                    style={{
                      color: "#2F4858",
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    {t("header.plain")}
                  </Text>
                  <a onClick={() => showAmigobsDetails()}>
                    <InfoCircleOutlined
                      style={{
                        color: "#02C3CD",
                        fontSize: 20,
                        marginLeft: 10
                      }}
                    />
                  </a>
                </div>
                {dataProfile && (
                  <Card
                    className="card-second"
                    style={{
                      background: "#EFFCFC",
                      borderRadius: 4,
                      border: "1px solid #C0F0F3",
                      marginTop: 16,
                    }}
                  >
                    <Row gutter={16}>
                      <Col lg={20} xs={20}>
                        <Text
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: "#2F4858",
                          }}
                        >
                          {t("amigobs.code")}
                        </Text>
                        &nbsp;
                        <Text
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#748EA0",
                          }}
                        >
                          {dataProfile.friendName}
                        </Text>
                      </Col>
                      <Col
                        lg={4}
                        xs={4}
                        style={{ width: "100%", textAlign: "right" }}
                      >
                        <a onClick={() => showModalLinkCode()}>
                          {" "}
                          <Text
                            style={{
                              fontSize: 14,
                              fontWeight: 500,
                              color: "#02C3CD",
                            }}
                          >
                            {t("form.edit")}
                          </Text>
                        </a>
                      </Col>
                    </Row>
                  </Card>
                )}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <GiftOutlined
                    style={{
                      color: "#02C3CD",
                      fontSize: 60,
                      marginTop: 70,
                      marginBottom: 21,
                    }}
                  />
                  <div style={{ width: "100%" }}>
                    <Text
                      style={{
                        color: "#02C3CD",
                        fontSize: 16,
                        fontWeight: 400,
                      }}
                    >
                      {t("plainFriend.recommend1")}{" "}
                    </Text>
                    <Text
                      style={{
                        color: "#02C3CD",
                        fontSize: 16,
                        fontWeight: 600,
                        marginBottom: 24,
                      }}
                    >
                      {t("plainFriend.recommend2")}{" "}
                    </Text>
                  </div>

                  <Text
                    style={{
                      color: "#748EA0",
                      fontSize: 14,
                      fontWeight: 400,
                      width: "50%",
                      alignSelf: "center",
                      marginBottom: 40,
                      marginTop: 24,
                    }}
                  >
                    {t("m_amigobs.description")}{" "}
                  </Text>
                  <Text
                    style={{
                      color: "#02C3CD",
                      fontSize: 16,
                      fontWeight: 600,
                      width: "100%",
                      alignSelf: "center",
                      marginBottom: 24,
                    }}
                  >
                    {t("amigobs.invitedShareText")}{" "}
                  </Text>

                  <div
                    style={{
                      display: "flex",
                      marginBottom: 130,
                      alignSelf: "center",
                    }}
                  >
                    <Button
                      className="button-primari-gibobs"
                      type="dashed"
                      style={{
                        marginLeft: 12,
                        width: 120,
                        height: 32,
                        padding: 0,
                      }}
                      onClick={() => copyToClipBoard()}
                    >
                      {t("init.copyLink")}
                    </Button>

                    <RWebShare
                      data={{
                        text: t("amigobs.share1"),
                        url: dataProfile.friendLink,
                        title: t("amigobs.shareLink"),
                      }}
                      onClick={() => console.log("shared successfully!")}
                    >
                      <Button
                        className="button-primari-gibobs"
                        type="primary"
                        style={{
                          padding: 0,
                          marginLeft: 12,
                          width: 32,
                          height: 32,
                        }}
                        icon={
                          <img
                            src={shareIcon}
                            alt="shareIcon"
                            style={{ marginTop: -2 }}
                          />
                        }
                      ></Button>
                    </RWebShare>
                  </div>
                </div>
              </Content>
            </div>
          </Card>
        )}
      <Modal
        className="ModalCommonSmallNoButtons"
        open={modalInfo}
        title={
          <Text style={{ color: "#2F4858", fontSize: 16, fontWeight: 600 }}>
            {t("plainFriend.moreInfo")}{" "}
          </Text>
        }
        width={800}
        destroyOnClose={true}
        onCancel={() => {
          setModalInfo(false);
        }}
      >
        <div>
          <div style={{ marginTop: 16, width: "100%" }}>
            <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 400 }}>
              {t("plainFriend.moreInfo1")}{" "}
            </Text>
          </div>
          <div style={{ marginTop: 8, width: "100%" }}>
            <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 400 }}>
              {t("plainFriend.moreInfo2")}{" "}
            </Text>
          </div>

          <div style={{ marginTop: 16, width: "100%" }}>
            <Text style={{ color: "#2F4858", fontSize: 14, fontWeight: 600 }}>
              {t("plainFriend.moreInfo3")}{" "}
            </Text>
          </div>

          <div style={{ marginTop: 16, width: "100%" }}>
            <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 400 }}>
              {t("plainFriend.moreInfo4")}{" "}
            </Text>
          </div>

          <div style={{ marginTop: 8, width: "100%", marginBottom: -40 }}>
            <Text
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: "#748EA0",
                width: "100%",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t("plainFriend.moreInfo5"),
                }}
              />
            </Text>
          </div>
        </div>
      </Modal>
      {location.pathname === "/friendInfo" && (
        <Card className="gcard">
          <div
            className="gb-backgroud-friend-mobile"
            style={{ marginBottom: 40 }}
          >
            <div style={{ width: "100%" }}>
              <Text
                style={{ color: "#2F4858", fontSize: 16, fontWeight: 600 }}
              >
                {t("header.plain")}{" "}
              </Text>
              <a onClick={() => showAmigobsDetails()}>
                {" "}
                <InfoCircleOutlined
                  style={{ color: "#02C3CD", fontSize: 20, float: "right" }}
                />
              </a>
            </div>
            {(dataProfile && dataProfile.bankAccount === undefined) ||
              (dataProfile.bankAccount === null && (
                <Card
                  className="card-second"
                  style={{
                    background: "#FEF0D3",
                    borderRadius: 4,
                    border: "1px solid #FBC14F",
                    marginTop: 16,
                  }}
                >
                  <Row gutter={16}>
                    <ExclamationCircleFilled
                      style={{
                        color: "#FAAD14",
                        fontSize: 20,
                        marginLeft: 10,
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#2F4858",
                        marginLeft: 14,
                      }}
                    >
                      {t("amigobs.descBank")}&nbsp;
                    </Text>
                    <a onClick={() => showModalBankAccount()}>
                      {" "}
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: "#FAAD14",
                        }}
                      >
                        {t("amigobs.descBank2")}
                      </Text>
                    </a>
                  </Row>
                </Card>
              ))}

            {dataProfile && (
              <Card
                className="card-second"
                style={{
                  background: "#EFFCFC",
                  borderRadius: 4,
                  border: "1px solid #C0F0F3",
                  marginTop: 16,
                }}
              >
                <Row gutter={24}>
                  <Col lg={7} xs={7} style={{ alignSelf: "center" }}>
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: "#2F4858",
                      }}
                    >
                      {t("amigobs.code")}
                    </Text>
                    &nbsp;
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#748EA0",
                      }}
                    >
                      {dataProfile.friendName}
                    </Text>
                    <a onClick={() => showModalLinkCode()}>
                      {" "}
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          color: "#02C3CD",
                        }}
                      >
                        <img src={editIcon} alt="editIcon" />
                      </Text>
                    </a>
                  </Col>
                  <Col
                    lg={17}
                    xs={17}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "right",
                    }}
                  >
                    <Text
                      style={{
                        color: "#02C3CD",
                        fontSize: 14,
                        fontWeight: 600,
                        marginRight: 12,
                      }}
                    >
                      {t("amigobs.invitedShareText")}
                    </Text>
                    <div
                      id="invited-share-text"
                      style={{
                        color: "#2F4858",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      {dataProfile.friendLink}
                    </div>
                    <Button
                      className="button-primari-gibobsNew"
                      type="dashed"
                      style={{
                        marginLeft: 12,
                        width: 120,
                        height: 32,
                        padding: 0,
                      }}
                      onClick={() => copyToClipBoard()}
                    >
                      {t("init.copyLink")}
                    </Button>
                    {dataProfile && (
                      <RWebShare
                        data={{
                          text: t("amigobs.share1"),
                          url: dataProfile.friendLink,
                          title: t("amigobs.shareLink"),
                        }}
                        onClick={() => console.log("shared successfully!")}
                      >
                        <Button
                          className="button-primari-gibobs"
                          type="primary"
                          style={{
                            padding: 0,
                            marginLeft: 12,
                            width: 32,
                            height: 32,
                          }}
                          icon={
                            <img
                              src={shareIcon}
                              alt="shareIcon"
                              style={{ marginTop: -2 }}
                            />
                          }
                        ></Button>
                      </RWebShare>
                    )}
                  </Col>
                </Row>
              </Card>
            )}

            <Row style={{ marginTop: 40 }}>
              <Col lg={24} xs={24}>
                <EuroCircleOutlined
                  style={{ fontSize: 18, color: "#02C3CD", fontWeight: 600 }}
                />{" "}
                <Text
                  style={{ color: "#2F4858", fontSize: 16, fontWeight: 600 }}
                >
                  {t("amigobs.Congratulations")}{" "}
                </Text>
                <Text
                  style={{ color: "#02C3CD", fontSize: 16, fontWeight: 600 }}
                >
                  {reward.paid + "€"}{" "}
                </Text>
                <Text
                  style={{
                    color: "#2F4858",
                    fontSize: 14,
                    fontWeight: 400,
                    marginLeft: 16,
                  }}
                >
                  {t("amigobs.receive")}
                </Text>{" "}
                <Text
                  style={{ color: "#02C3CD", fontSize: 14, fontWeight: 400 }}
                >
                  {reward.pending + "€"}{" "}
                </Text>
                <Text
                  style={{
                    color: "#2F4858",
                    fontSize: 14,
                    fontWeight: 400,
                    marginLeft: 16,
                  }}
                >
                  {t("amigobs.received")}
                </Text>{" "}
                <Text
                  style={{ color: "#02C3CD", fontSize: 14, fontWeight: 400 }}
                >
                  {reward.paid + "€"}{" "}
                </Text>
              </Col>
            </Row>
            <div style={{ marginTop: 32 }}>
              <Text
                style={{ color: "#2F4858", fontSize: 12, fontWeight: 400 }}
              >
                {t("amigobs.mortgagesCurrents").toLocaleUpperCase()}{" "}
              </Text>
            </div>

            <Row style={{ marginTop: -20 }}>
              {valuesData.map((item, index) => {
                if (
                  item.status === "pending_declarative" ||
                  item.status === "active"
                ) {
                  return (
                    <Col Col lg={6} md={6} xs={24} style={{ marginTop: 15 }}>
                      <Collapse
                        className="gb-accordion-amigobs"
                        accordion
                        expandIconPosition={"end"}
                        style={{
                          borderColor: "white",
                          backgroundColor: "white",
                        }}
                      >
                        <Panel
                          header={
                            <Row>
                              <DoubleRightOutlined
                                style={{
                                  color: "#52C41A",
                                  fontSize: 20,
                                  marginTop: 4,
                                  marginLeft: 5,
                                }}
                              />
                              <div
                                style={{
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: "#2F4858",
                                  marginLeft: 13,
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  width: 250,
                                }}
                              >
                                {item.client}
                              </div>
                            </Row>
                          }
                          key={index}
                          style={{
                            background: "#FFFFFF",
                            borderRadius: 4,
                            boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
                            marginTop: 16,
                            marginRight: 24,
                          }}
                        >
                          {renderMessage(item.stage)}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 12,
                                fontWeight: 400,
                                color: "#748EA0",
                              }}
                            >
                              {t("b2bOperationList.stage")}
                            </Text>
                            <Text
                              style={{
                                color: "#2F4858",
                                fontSize: 16,
                                fontWeight: 600,
                              }}
                            >
                              {t("stages." + item.stage)}
                            </Text>

                            <Text
                              style={{
                                fontSize: 12,
                                fontWeight: 400,
                                color: "#748EA0",
                                marginTop: 8,
                              }}
                            >
                              {t("amigobs.IdOperation")}
                            </Text>
                            <Text
                              style={{
                                color: "#2F4858",
                                fontSize: 16,
                                fontWeight: 600,
                              }}
                            >
                              {item.name}
                            </Text>

                            <Text
                              style={{
                                fontSize: 12,
                                fontWeight: 400,
                                color: "#748EA0",
                                marginTop: 8,
                              }}
                            >
                              {t("details.create")}
                            </Text>
                            <Text
                              style={{
                                color: "#2F4858",
                                fontSize: 16,
                                fontWeight: 600,
                              }}
                            >
                              {moment(item.createAt).format("DD-MM-YYYY")}
                            </Text>
                          </div>

                          <Row
                            gutter={[24, 24]}
                            style={{
                              marginTop: 32,
                              justifyContent: "center",
                            }}
                          >
                            {renderButtonSend(item)}
                          </Row>
                        </Panel>
                      </Collapse>
                    </Col>
                  );
                }
              })}
            </Row>

            {signed !== undefined && (
              <div style={{ marginTop: 32 }}>
                <Text
                  style={{ color: "#2F4858", fontSize: 12, fontWeight: 400 }}
                >
                  {t("amigobs.mortgagesSigned").toLocaleUpperCase()}{" "}
                </Text>
              </div>
            )}

            <Row style={{ marginTop: -20 }}>
              {valuesData.map((item, index) => {
                if (item.status === "finish" && item.stage === "finish") {
                  return (
                    <Col Col lg={6} md={6} xs={24} style={{ marginTop: 15 }}>
                      <Collapse
                        className="gb-accordion-amigobs"
                        accordion
                        expandIconPosition={"end"}
                        style={{
                          borderColor: "white",
                          backgroundColor: "white",
                        }}
                      >
                        <Panel
                          header={
                            <Row gutter={16}>
                              <CheckOutlined
                                style={{
                                  color: "#52C41A",
                                  fontSize: 20,
                                  marginTop: 4,
                                  marginLeft: 15,
                                }}
                              />
                              <Text
                                style={{
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: "#2F4858",
                                  marginLeft: 13,
                                }}
                              >
                                {item.client}
                              </Text>
                            </Row>
                          }
                          key={index}
                          style={{
                            background: "#FFFFFF",
                            borderRadius: 4,
                            boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
                            marginTop: 16,
                            marginRight: 24,
                          }}
                        >
                          {renderMessage(item.stage)}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 12,
                                fontWeight: 400,
                                color: "#748EA0",
                              }}
                            >
                              {t("b2bOperationList.stage")}
                            </Text>
                            <Text
                              style={{
                                color: "#2F4858",
                                fontSize: 16,
                                fontWeight: 600,
                              }}
                            >
                              {t("stages." + item.stage)}
                            </Text>

                            <Text
                              style={{
                                fontSize: 12,
                                fontWeight: 400,
                                color: "#748EA0",
                                marginTop: 8,
                              }}
                            >
                              {t("amigobs.IdOperation")}
                            </Text>
                            <Text
                              style={{
                                color: "#2F4858",
                                fontSize: 16,
                                fontWeight: 600,
                              }}
                            >
                              {item.name}
                            </Text>

                            <Text
                              style={{
                                fontSize: 12,
                                fontWeight: 400,
                                color: "#748EA0",
                                marginTop: 8,
                              }}
                            >
                              {t("details.create")}
                            </Text>
                            <Text
                              style={{
                                color: "#2F4858",
                                fontSize: 16,
                                fontWeight: 600,
                              }}
                            >
                              {moment(item.createAt).format("DD-MM-YYYY")}
                            </Text>

                            <Text
                              style={{
                                fontSize: 12,
                                fontWeight: 400,
                                color: "#748EA0",
                                marginTop: 8,
                              }}
                            >
                              {t("m_amigobs.closeDate")}
                            </Text>
                            <Text
                              style={{
                                color: "#2F4858",
                                fontSize: 16,
                                fontWeight: 600,
                              }}
                            >
                              {moment(item.closeDate).format("DD-MM-YYYY")}
                            </Text>
                          </div>
                          <div style={{ marginTop: 32 }}>
                            {item.stage === "finish" &&
                              item.status === "finish" &&
                              reward.paid === 0 && (
                                <Text
                                  style={{
                                    color: "#1890FF",
                                    fontSize: 14,
                                    fontWeight: 600,
                                  }}
                                >
                                  {t("operationStatus.pendingPay")}
                                </Text>
                              )}

                            {item.stage === "finish" &&
                              item.status === "finish" &&
                              reward.paid !== 0 && (
                                <Text
                                  style={{
                                    color: "#52C41A",
                                    fontSize: 14,
                                    fontWeight: 600,
                                  }}
                                >
                                  {t(
                                    "operationStatus." + item.status + "Pay"
                                  )}
                                </Text>
                              )}
                          </div>
                        </Panel>
                      </Collapse>
                    </Col>
                  );
                }
              })}
            </Row>

            {noSigned !== undefined && (
              <div style={{ marginTop: 32 }}>
                <Text
                  style={{ color: "#2F4858", fontSize: 12, fontWeight: 400 }}
                >
                  {t("amigobs.mortgagesNoSigned").toLocaleUpperCase()}{" "}
                </Text>
              </div>
            )}
            <Row style={{ marginTop: -20 }}>
              {valuesData.map((item, index) => {
                if (item.status === "finish" && item.stage !== "finish") {
                  return (
                    <Col Col lg={6} md={6} xs={24} style={{ marginTop: 15 }}>
                      <Collapse
                        className="gb-accordion-amigobs"
                        accordion
                        expandIconPosition={"end"}
                        style={{
                          borderColor: "white",
                          backgroundColor: "white",
                        }}
                      >
                        <Panel
                          header={
                            <Row gutter={16}>
                              <CloseOutlined
                                style={{
                                  color: "#FF4D4F",
                                  fontSize: 20,
                                  marginTop: 4,
                                  marginLeft: 15,
                                }}
                              />
                              <Text
                                style={{
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: "#2F4858",
                                  marginLeft: 13,
                                }}
                              >
                                {item.client}
                              </Text>
                            </Row>
                          }
                          key={index}
                          style={{
                            background: "#FFFFFF",
                            borderRadius: 4,
                            boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
                            marginTop: 16,
                            marginRight: 24,
                          }}
                        >
                          {renderMessage(item.stage)}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 12,
                                fontWeight: 400,
                                color: "#748EA0",
                              }}
                            >
                              {t("b2bOperationList.stage")}
                            </Text>
                            <Text
                              style={{
                                color: "#2F4858",
                                fontSize: 16,
                                fontWeight: 600,
                              }}
                            >
                              {t("stages." + item.stage)}
                            </Text>

                            <Text
                              style={{
                                fontSize: 12,
                                fontWeight: 400,
                                color: "#748EA0",
                                marginTop: 8,
                              }}
                            >
                              {t("amigobs.IdOperation")}
                            </Text>
                            <Text
                              style={{
                                color: "#2F4858",
                                fontSize: 16,
                                fontWeight: 600,
                              }}
                            >
                              {item.name}
                            </Text>

                            <Text
                              style={{
                                fontSize: 12,
                                fontWeight: 400,
                                color: "#748EA0",
                                marginTop: 8,
                              }}
                            >
                              {t("details.create")}
                            </Text>
                            <Text
                              style={{
                                color: "#2F4858",
                                fontSize: 16,
                                fontWeight: 600,
                              }}
                            >
                              {moment(item.createAt).format("DD-MM-YYYY")}
                            </Text>

                            <Text
                              style={{
                                fontSize: 12,
                                fontWeight: 400,
                                color: "#748EA0",
                                marginTop: 8,
                              }}
                            >
                              {t("m_amigobs.closeDate")}
                            </Text>
                            <Text
                              style={{
                                color: "#2F4858",
                                fontSize: 16,
                                fontWeight: 600,
                              }}
                            >
                              {moment(item.closeDate).format("DD-MM-YYYY")}
                            </Text>
                          </div>

                          <div style={{ marginTop: 32 }}>
                            {item.stage !== "finish" &&
                              item.status === "finish" && (
                                <Text
                                  style={{
                                    color: "#FF4D4F",
                                    fontSize: 14,
                                    fontWeight: 600,
                                  }}
                                >
                                  {t("amigobs.mortgagesNoSigned2")}
                                </Text>
                              )}
                          </div>
                        </Panel>
                      </Collapse>
                    </Col>
                  );
                }
              })}
            </Row>
          </div>
        </Card>
      )}
      <Modal
        className="modal-common-mobile"
        cancelButtonProps={{
          style: { height: 40, width: "100%" },
          className: "button-secundary-gibobs",
        }}
        width={400}
        okButtonProps={{
          style: { height: 40, width: "100%" },
          className: "button-primari-gibobs",
        }}
        open={showAccount}
        title={
          <Text style={{ fontSize: 16, color: "#2F4858", fontWeight: 600 }}>
            {t("m_common.addIBAN")}
          </Text>
        }
        okText={t("offers.accept")}
        cancelText={t("mydocuments.deleteCancel")}
        destroyOnClose={true}
        onCancel={() => {
          setShowAccount(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              saveDataAccountBank(values.IBAN, "agent");
              setShowAccount(false);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Text
          style={{
            fontSize: 14,
            color: "#748EA0",
            fontWeight: 400,
            marginTop: -30,
          }}
        >
          {t("m_common.addIBANMessage")}
        </Text>
        <Form form={form} layout="vertical">
          <Form.Item name="IBAN" label={""} style={{ marginTop: 24 }}>
            <Input
              style={{ width: "98%" }}
              placeholder={t("ES00 0000 0000 0000 0000")}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        className="modal-common-mobile"
        cancelButtonProps={{
          style: { height: 40, width: "100%" },
          className: "button-secundary-gibobs",
        }}
        width={400}
        okButtonProps={{
          style: { height: 40, width: "100%" },
          className: "button-primari-gibobs",
        }}
        open={visibleCode}
        title={
          <Text style={{ fontSize: 16, color: "#2F4858", fontWeight: 600 }}>
            {t("amigobs.code")}
          </Text>
        }
        okText={t("offers.accept")}
        cancelText={t("mydocuments.deleteCancel")}
        destroyOnClose={true}
        onCancel={() => {
          setVisibleCode(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              saveDataCodeLink(values.code);
              setVisibleCode(false);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Text
          style={{
            fontSize: 14,
            color: "#748EA0",
            fontWeight: 400,
            marginTop: -30,
          }}
        >
          {t("amigobs.codeDesc")}
        </Text>
        <Form form={form} layout="vertical">
          <Form.Item
            name="code"
            label={"https://www.gibobs.com/plan-amigobs/"}
            style={{ marginTop: 24 }}
            rules={[
              {
                validator: (e, value) => {
                  return new Promise((resolve, reject) => {
                    let match = [];
                    if (value.match(/^[\-a-zA-Z0-9]+$/g)) {
                      match = value.match(/^[\-a-zA-Z0-9]+$/g);
                    }
                    if (match === null) {
                      reject("Formato inválido");
                    }
                    if (!value.match(/^[\-a-zA-Z0-9]+$/g)) {
                      reject("Formato inválido");
                    } else {
                      resolve();
                    }
                  });
                },
              },
            ]}
          >
            <Input
              style={{ width: "98%" }}
              placeholder={dataProfile.friendName}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Content>)
  );
};

export default FollowUpPage;
