import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import numeral from "numeral";

const ResourcesaTable = ({ data }) => {
  const { t } = useTranslation();

  const dataSource = [
    {
      key: 1,
      name: t("promoterLoan.ownFunds"),
      euroData: numeral(data && data.equity && data.equity.value).format("0,0"),
      moneyPerSquareMeter: numeral(
        data && data.equity && data.equity.moneyPerSquareMeter
      ).format("0,0"),
      costes: numeral(data && data.equity && data.equity.percentage).format(
        "0%"
      ),
      ingresos: 23.5,
      style: { backgroundColor: "red" },
    },
    {
      key: 2,
      name: t("promoterLoan.projectFinancingTable"),
      euroData: numeral(
        data && data.financings && data.financings.value
      ).format("0,0"),
      moneyPerSquareMeter: numeral(
        data && data.financings && data.financings.moneyPerSquareMeter
      ).format("0,0"),
      costes: numeral(
        data && data.financings && data.financings.percentage
      ).format("0%"),
      ingresos: 52.6,
      children: [
        {
          key: 11,
          name: t("Principal"),
          euroData: numeral(
            data && data.financings && data.financings.principal.value
          ).format("0,0"),
          moneyPerSquareMeter: numeral(
            data &&
              data.financings &&
              data.financings.principal.moneyPerSquareMeter
          ).format("0,0"),
          costes: numeral(
            data &&
              data.financings &&
              data.financings.principal &&
              data.financings.principal.percentage
          ).format("0%"),
          ingresos: 43.8,
        },
        {
          key: 12,
          name: t("promoterLoan.financingCosts"),
          euroData: numeral(
            data &&
              data.financings &&
              data.financings.servicesCosts &&
              data.financings.servicesCosts.value
          ).format("0,0"),
          moneyPerSquareMeter: numeral(
            data &&
              data.financings &&
              data.financings.servicesCosts &&
              data.financings.servicesCosts.moneyPerSquareMeter
          ).format("0,0"),
          costes: numeral(
            data &&
              data.financings &&
              data.financings.servicesCosts &&
              data.financings.servicesCosts.percentage
          ).format("0%"),
          ingresos: 8.9,
        },
      ],
    },
    {
      key: 3,
      name: "",
      euroData: "",
      moneyPerSquareMeter: "",
      costes: "",
      ingresos: "",
    },
    {
      key: 4,
      name: "",
      euroData: "",
      moneyPerSquareMeter: "",
      costes: "",
      ingresos: "",
    },
    {
      key: 5,
      name: "",
      euroData: "",
      moneyPerSquareMeter: "",
      costes: "",
      ingresos: "",
    },
    {
      key: 6,
      name: t("balance.totalTitle"),
      euroData: numeral(data && data.total && data.total.value).format("0,0"),
      moneyPerSquareMeter: numeral(
        data && data.total && data.total.moneyPerSquareMeter
      ).format("0,0"),
      costes: numeral(data && data.total && data.total.percentage).format("0%"),
      ingresos: 88.6,
    },
  ];

  const columns = [
    {
      title: t("promoterLoan.sources"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: "€",
      dataIndex: "euroData",
      key: "euroData",
      align: "right",
    },
    {
      title: "%",
      dataIndex: "costes",
      key: "costes",
      align: "right",
    },
    {
      title: "€/m²",
      dataIndex: "moneyPerSquareMeter",
      key: "moneyPerSquareMeter",
      align: "right",
    },
  ];

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      rowClassName={(record) => {
        const classes = ["textStyleTable"];
        if (record.key === 1 || record.key === 9 || record.key === 11) {
          classes.push("textStyleTableOfferSelect");
        }

        if (record.key === 6 || record.key === 7 || record.key === 8) {
          classes.push("textStyleTotal");
        }
        if (record.key === 6) {
          classes.push("textStyleTotal");
          classes.push("textStyleTotalColor");
        }
        return classes;
      }}
      className="table-style-general"
      style={{ width: "50%" }}
    />
  );
};

export default ResourcesaTable;
