import React, { useEffect, useState } from 'react';
import { Button, Form, Select, Row, Col, DatePicker, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import numeral from 'numeral';
import axios from 'axios';
import env from '../../../environment';
import infoIcon from '../../../assets/loans/infoIcon.svg';
import moment from 'moment';
import DownOutlinedMobile from '../../../assets/icons/DownOutlinedMobile.svg';
import { isMobileOnly } from 'react-device-detect';
import { InputNumber } from 'afrodita';

const { Option } = Select;

const CreateManualForm = ({
	setModalHousePrice,
	setModalLinks,
	setModalQuota,
	setModalTypeOfInterest,
	setModalTypeOfInterestVariable,
	setModalDebtAndPrincipalOutstanding,
	setModalDateFinishCorrect,
	setValueCuota,
	setTypeLoan,
	setSpreadValue,
	typeLoan,
	spreadValue,
	valueCuota,
	setButton,
	form,
	euriborValue,
	setEuriborValue,
	setDateEuribor,
	loan
}) => {
	const { t } = useTranslation();
	let { bankId } = useParams();
	const location = useLocation();
	const userToken = useSelector((state) => state.auth.token);
	const [euriborList, setEuriborList] = useState([]);
	const [housePrice, setHousePrice] = useState(0);
	const [initialAmount, setInitialAmount] = useState(0);
	const [amountPayable, setAmountPayable] = useState(0);
	const [diff, setDiff] = useState(0);
	const [signed, setSigned] = useState();
	const [finish, setFinish] = useState();
	const [remainingMonths, setRemainingMonths] = useState(0);
	const [reviewPeriod, setReviewPeriod] = useState(0);
	let today = moment(new Date());
	const [nameBanks, setNameBanks] = useState(null);


	const euriborData = () => {
		axios
			.get(`${env.api.url}/v1/loans/calculate-list-euribor`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				setEuriborList(response.data.data.euriborList);
			});
	};

	useEffect(() => {
		if (euriborValue !== undefined) {
			const i = euriborList && euriborList.filter((data) => data.value === euriborValue);
			if (i.length !== 0) {
				setDateEuribor(
					moment(i && i[0] && i[0].date)
						.add(1, 'months')
						.format('MM/DD/YYYY')
				);
			} else {
				setDateEuribor(null);
			}
		}
	}, [euriborValue]);

	useEffect(() => {
		euriborData();
		if (bankId === 'undefined'){
		axios
			.get(`${env.api.url}/v1/banks/get-banks`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				setNameBanks(response.data.data);
			});
		}
	}, []);

	useEffect(() => {
		if (loan !== undefined) {
			setHousePrice(loan && loan.initialHousePrice ? loan.initialHousePrice : 0);
			setInitialAmount(loan && loan.initialAmount ? loan.initialAmount : 0);
			setSigned(loan && loan.signed && moment(loan.signed));
			setFinish(loan && loan.finish && moment(loan.finish));
			setReviewPeriod(loan?.reviewPeriod);
			setAmountPayable(loan?.amount);
		}
	}, [loan]);

	useEffect(() => {
		setDiff(today.diff(signed, 'month'));
	}, [signed]);

	useEffect(() => {
		if (signed && finish) {
			form.setFieldsValue({
				term: finish.diff(signed, 'month') - diff
			});
			setRemainingMonths(finish.diff(signed, 'month') - diff);
		}
	}, [diff]);
	useEffect(() => {
		if (loan?.rateType === 'variable' && typeLoan === 'fixed') {
			form.setFieldsValue({
				rate: ''
			});
		}
	}, [typeLoan]);

	useEffect(() => {
		if (finish && signed && finish.diff(signed, 'month') > 480) {
			setModalDateFinishCorrect(true);
		}
		if (remainingMonths > 480) {
			setModalDateFinishCorrect(true);
		}
		if (signed) {
			form.setFieldsValue({
				term: remainingMonths
			});
		}
	}, [remainingMonths, finish, signed]);

	useEffect(() => {
		if (signed && finish) {
			form.setFieldsValue({
				term: finish.diff(signed, 'month') - diff
			});
			setRemainingMonths(finish.diff(signed, 'month') - diff);
		}
	}, [finish]);

	useEffect(() => {
		if (reviewPeriod && signed && euriborList) {
			const euribor = euriborList.filter(
				(data) =>
					moment(data.date).format('M') ===
					moment(signed)
						.add(reviewPeriod === 'annual' ? 11 : 5, 'M')
						.format('M')
			)?.[0]?.value;
			form.setFieldsValue({
				index: euribor
			});
			setEuriborValue(euribor);
		}
	}, [reviewPeriod, signed, euriborList]);

	const buttonCheck = (
		<Button
			className="button-primari-gibobs"
			type="primary"
			disabled={valueCuota === 0 ? true : false}
			style={{
				width: isMobileOnly ? '100%' : 264,
				marginTop: !isMobileOnly && 16
			}}
			htmlType="submit">
			{t('loan.checkButton')}
		</Button>
	);

	const quotaSection = (
		<Row className="wrapper-quota">
			{numeral(valueCuota).format('0,0.00 $')}
			<div style={{ display: 'flex' }}>
				<Button
					className="button-primari-gibobs"
					type="primary"
					disabled={valueCuota === 0 ? true : false}
					style={{ width: 70, marginLeft: 32 }}
					htmlType="submit"
					onClick={() => setButton('Yes')}>
					{t('form.yes')}
				</Button>
				<Button
					className="button-secundary-gibobs"
					type="dashed"
					disabled={valueCuota === 0 ? true : false}
					style={{ width: 50, marginLeft: 8 }}
					htmlType="submit"
					onClick={() => setButton('No')}>
					{t('form.no')}
				</Button>
			</div>
		</Row>
	);

	return (
		<div style={{ width: '100%', marginBottom: isMobileOnly && 300 }}>
			<Row style={{ justifyContent: 'center' }} gutter={24}>
				{bankId === 'undefined' && (
					<Col xs={24} md={12} sm={24} lg={8} xl={6} xxl={6}>
						<Form.Item
							name="bankId"
							label={t('loan.whichIsYourBank')}
							rules={[
								{
									required: true
								}
							]}>
							<Select
						style={{ width: '100%' }}
						showSearch
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
						placeholder={t('form.select')}>
						{nameBanks
							? nameBanks.map((nameBanks) => {
									return (
										<Option key={nameBanks.id} value={nameBanks.id}>
											{nameBanks.name}
										</Option>
									);
							  })
							: null}
					</Select>
						</Form.Item>
					</Col>
				)}
				<Col xs={24} md={12} sm={24} lg={8} xl={6} xxl={6}>
					<Form.Item name="initialHousePrice" label={t('loan.homeCost')}>
						<InputNumber
							defaultValue={''}
							id={'initialHousePrice'}
							gbType="currency"
							placeholder={0}
							suffix={'€'}
							min={10000}
							max={99999000}
							onChange={(e) => setHousePrice(e)}
							onBlur={(e) =>
								initialAmount > 0 &&
								Number((e.target.value || '').replace(/\./g, '').replace(',', '.')) > 0 &&
								Number((e.target.value || '').replace(/\./g, '').replace(',', '.')) * 0.8 <
									Number(initialAmount) &&
								setModalHousePrice(
									(Number(initialAmount) * 100) /
										Number((e.target.value || '').replace(/\./g, '').replace(',', '.'))
								)
							}
							style={{
								borderColor:
									housePrice === 0 && location?.state?.input === 'initialHousePrice'
										? '#FF4D4F'
										: '#C0DAEE'
							}}
						/>
					</Form.Item>
				</Col>
				<Col xs={24} md={12} sm={24} lg={8} xl={6} xxl={6}>
					<Form.Item
						name="initialAmount"
						label={t('loan.moneyRequestBank')}
						dependencies={['initialHousePrice']}>
						<InputNumber
							defaultValue={''}
							id={'initialAmount'}
							gbType="currency"
							placeholder={'0'}
							suffix={'€'}
							min={10000}
							max={99999000}
							onBlur={(e) => {
								const value = Number((e.target.value || '').replace(/\./g, '').replace(',', '.'));
								housePrice > 0 &&
									value > 0 &&
									Number(housePrice) * 0.8 < value &&
									setModalHousePrice(
										(Number((e.target.value || '').replace(/\./g, '').replace(',', '.')) * 100) /
											Number(housePrice)
									);

								amountPayable > 0 &&
									value > 0 &&
									value < Number(amountPayable) &&
									setModalDebtAndPrincipalOutstanding(true);
							}}
							style={{
								borderColor:
									initialAmount === 0 && location?.state?.input === 'initialAmount' && '#FF4D4F'
							}}
						/>
					</Form.Item>
				</Col>
				<Col xs={24} md={12} sm={24} lg={8} xl={6} xxl={6}>
					<Form.Item
						name="amount"
						label={t('loan.haveLeftToPay')}
						rules={[
							{
								required: true
							}
						]}
						style={{
							width: '100%'
						}}>
						<InputNumber
							defaultValue={''}
							id={'amount'}
							gbType="currency"
							placeholder={'0'}
							suffix={'€'}
							min={10000}
							max={99999000}
							onChange={(e) => {
								setValueCuota(undefined);
								setAmountPayable(e);
							}}
							onBlur={(e) =>
								initialAmount > 0 &&
								// housePrice > 0 &&
								Number(initialAmount) <
									Number((e.target.value || '').replace(/\./g, '').replace(',', '.')) &&
								setModalDebtAndPrincipalOutstanding(true)
							}
						/>
					</Form.Item>
				</Col>
			</Row>
			<div className="title-loan-form" style={{ marginTop: 24 }}>
				{t('loan.conditionsDidYouSign')}
			</div>
			<div
				style={{
					display: 'flex',
					columnGap: 10,
					padding: 8,
					justifyContent: 'center',
					marginTop: 16,
					marginBottom: 8
				}}>
				<div
					className={typeLoan === 'fixed' ? 'tab-selected' : 'tab-not-selected'}
					onClick={() => setTypeLoan('fixed')}>
					{t('loanType.fixed')}
				</div>
				<div
					className={typeLoan === 'variable' ? 'tab-selected' : 'tab-not-selected'}
					onClick={() => setTypeLoan('variable')}>
					{t('loanType.variable')}
				</div>
			</div>

			<Row style={{ justifyContent: 'center' }} gutter={24}>
				<Col xs={24} md={12} sm={24} lg={8} xl={6} xxl={6}>
					<Form.Item
						name="signed"
						label={t('onboarding.singDateQuestion')}
						rules={[
							{
								required: true,
								validator: (e, value) =>
									today.diff(value, 'days') >= 0
										? Promise.resolve()
										: Promise.reject(
												new Error('La fecha de firma no puede ser mayor al día de hoy')
										  )
							}
						]}
						style={{
							width: '100%'
						}}>
						<DatePicker
							className="date-picker-loan"
							onChange={(e) => {
								setSigned(e);
								setValueCuota(undefined);
							}}
							format={'DD/MM/YYYY'}
						/>
					</Form.Item>
				</Col>
				{typeLoan === 'fixed' && (
					<Col xs={24} md={12} sm={24} lg={8} xl={6} xxl={6}>
						<Form.Item
							name="rate"
							label={
								<>
									{t('loan.tinTitle')}{' '}
									<img
										src={infoIcon}
										alt="infoIcon"
										height={14}
										style={{ marginLeft: 5, cursor: 'pointer' }}
										onClick={() => setModalTypeOfInterest(true)}
									/>
								</>
							}
							rules={[
								{
									required: true,
									message: t('loan.tinTitle') + ' ' + t('form.formRequired')
								}
							]}
							style={{
								width: '100%'
							}}>
							<InputNumber
								defaultValue={''}
								id={'rate'}
								gbType="currency"
								placeholder={'0'}
								suffix={'%'}
								min={0}
								max={15}
								onChange={() => setValueCuota(undefined)}
							/>
						</Form.Item>
					</Col>
				)}
				{typeLoan === 'variable' && (
					<Col xs={24} md={12} sm={24} lg={8} xl={6} xxl={6}>
						<Form.Item
							name="reviewPeriod"
							label={t('loan.mortgageReviewed')}
							rules={[
								{
									required: true
								}
							]}
							style={{
								width: '100%'
							}}
							id="select">
							<Select
								placeholder={t('form.selected')}
								style={{ width: '100%' }}
								suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
								onChange={(e) => {
									setValueCuota(undefined);
									setReviewPeriod(e);
								}}
								getPopupContainer={(triggerNode) => triggerNode.parentElement}>
								<Option value="biannual">{t('loan.biannual')}</Option>
								<Option value="annual">{t('loan.annual')}</Option>
							</Select>
						</Form.Item>
					</Col>
				)}
				{typeLoan === 'variable' && (
					<Col xs={24} md={12} sm={24} lg={8} xl={6} xxl={6}>
						<Form.Item
							label={
								<>
									<div
										style={{
											color: '#ff4d4f',
											fontSize: 11,
											marginRight: 4,
											lineHeight: 1
										}}>
										*
									</div>
									{t('loan.whatIsYourInterestRate')}{' '}
									<img
										src={infoIcon}
										alt="infoIcon"
										height={14}
										style={{ marginLeft: 5, cursor: 'pointer' }}
										onClick={() => setModalTypeOfInterestVariable(true)}
									/>
								</>
							}
							style={{
								marginBottom: 0
							}}>
							<Form.Item
								name="index"
								style={{
									display: 'inline-block',
									width: 'calc(60%)'
								}}
								rules={[
									{
										required: true,
										message: t('loan.whatIsYourInterestRate') + ' ' + t('form.formRequired')
									}
								]}>
								<Select
									placeholder={t('form.selected')}
									onChange={(e) => {
										setEuriborValue(Number(e));
										setValueCuota(undefined);
									}}
									suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
									getPopupContainer={(triggerNode) => triggerNode.parentElement}>
									{euriborList &&
										euriborList.map((data) => {
											return (
												<Option value={data.value} key={data.value}>
													{numeral(data.value).format('0,0.00')} % (
													{moment(data.date).format('MMM YY')})
												</Option>
											);
										})}
								</Select>
							</Form.Item>
							<Form.Item
								name="spread"
								rules={[
									{
										required: true,
										message: t('loan.whatIsYourInterestRate') + ' ' + t('form.formRequired')
									}
								]}
								style={{
									display: 'inline-block',
									width: 'calc(38%)',
									marginLeft: '2%'
								}}>
								<InputNumber
									defaultValue={''}
									id={'amount'}
									gbType="currency"
									placeholder={'Diferencial'}
									suffix={'%'}
									min={0}
									max={15}
									onChange={(e) => {
										setSpreadValue(Number(e));
										setValueCuota(undefined);
									}}
								/>
							</Form.Item>
						</Form.Item>
						<div className="interest-actual" style={{ marginBottom: 16 }}>
							{t('loan.currentTotalInterest')} ={' '}
							{numeral(euriborValue + spreadValue).format('0,0.00')}%
						</div>
					</Col>
				)}
				<Col xs={24} md={12} sm={24} lg={8} xl={6} xxl={6}>
					<Form.Item
						name="totalEntailments"
						label={
							<>
								{t('loan.doYouHaveAnyLinks')}{' '}
								<img
									src={infoIcon}
									alt="infoIcon"
									height={14}
									style={{ marginLeft: 5, cursor: 'pointer' }}
									onClick={() => setModalLinks(true)}
								/>
							</>
						}
						style={{
							width: '100%'
						}}>
						<InputNumber
							defaultValue={''}
							id={'amount'}
							gbType="currency"
							placeholder={'0'}
							suffix={t('common.euroAtYear')}
							min={0}
							max={10000}
							onChange={() => setValueCuota(undefined)}
						/>
					</Form.Item>
				</Col>
			</Row>

			<div
				className="title-loan-form"
				style={{
					marginBottom: 16,
					marginTop: typeLoan === 'variable' ? 0 : 24
				}}>
				{t('loan.finishPayMortgage')}
			</div>
			<Row style={{ justifyContent: 'center' }} gutter={24}>
				<Col xs={12} md={6} sm={12} lg={4} xl={3} xxl={3}>
					<Form.Item
						name="finish"
						label={t('loan.endDate')}
						rules={[
							{
								required: true,
								validator: (e, value) => {
									if (signed && value) {
										if (signed.diff(value, 'month') < 0) {
											return Promise.resolve();
										} else {
											Promise.reject(
												new Error('La fecha de finalización debe ser mayor al día de firma')
											);
										}
									}
								}
							}
						]}
						style={{
							width: '100%'
						}}>
						<DatePicker
							className="date-picker-loan"
							onChange={(e) => {
								setFinish(e);
								setValueCuota(undefined);
							}}
							format={'DD/MM/YYYY'}
						/>
					</Form.Item>
				</Col>
				<Col xs={12} md={6} sm={12} lg={4} xl={3} xxl={3}>
					<Form.Item
						name="term"
						label={t('loan.remainingMonths')}
						rules={[
							{
								required: true
							}
						]}
						style={{
							width: '100%'
						}}>
						<InputNumber
							className="input-months"
							gbType='number'
							disabled={signed === undefined && true}
							onChange={(e) => {
								setRemainingMonths(Number(e));
								setValueCuota(undefined);
							}}
							addonBefore={
								<div
									className="icons-style"
									onClick={() => {
										setRemainingMonths(remainingMonths - 1);
										setValueCuota(undefined);
									}}>
									-
								</div>
							}
							addonAfter={
								<div
									className="icons-style"
									onClick={() => {
										setRemainingMonths(remainingMonths + 1);
										setValueCuota(undefined);
									}}>
									+
								</div>
							}
							style={{ height: 40, textAlignLast: 'center' }}
						/>
					</Form.Item>
				</Col>
			</Row>

			{!isMobileOnly && (
				<div className="title-loan-form" style={{ marginBottom: 16, marginTop: 24 }}>
					{t('loan.isYourQuota')}
					<img
						src={infoIcon}
						alt="infoIcon"
						height={14}
						style={{ marginLeft: 5, cursor: 'pointer' }}
						onClick={() => setModalQuota(true)}
					/>
				</div>
			)}
			{valueCuota === undefined &&
				(!isMobileOnly ? (
					<Row style={{ justifyContent: 'center', width: '100%' }}>{buttonCheck}</Row>
				) : (
					<Card className="cardButtonStyle" style={{ left: 0, marginBottom: -2 }}>
						<div className="title-loan-form" style={{ marginBottom: 16 }}>
							{t('loan.isYourQuota')}
							<img
								src={infoIcon}
								alt="infoIcon"
								height={14}
								style={{ marginLeft: 5, cursor: 'pointer' }}
								onClick={() => setModalQuota(true)}
							/>
						</div>
						{buttonCheck}
					</Card>
				))}
			{valueCuota !== undefined &&
				(!isMobileOnly ? (
					<>{quotaSection}</>
				) : (
					<Card className="cardButtonStyle" style={{ left: 0, marginBottom: -2 }}>
						<div className="title-loan-form" style={{ marginBottom: 16 }}>
							{t('loan.isYourQuota')}
							<img
								src={infoIcon}
								alt="infoIcon"
								height={14}
								style={{ marginLeft: 5, cursor: 'pointer' }}
								onClick={() => setModalQuota(true)}
							/>
						</div>
						{quotaSection}
					</Card>
				))}
		</div>
	);
};

export default CreateManualForm;
