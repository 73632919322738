import { SIMULATIONID, AMORTIZATIONCALCULATORDATA, PREVIOUSPAGE } from './constants';

export const INITIAL_STATE = {
	simulationId: undefined,
	amortizationCalculatorData: undefined,
	previousPage: undefined
};
export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SIMULATIONID:
			return { ...state, simulationId: action.payload };
		case AMORTIZATIONCALCULATORDATA:
			return { ...state, amortizationCalculatorData: action.payload };
		case PREVIOUSPAGE:
			return { ...state, previousPage: action.payload };

		default:
			return state;
	}
};
