import React, { useState, useEffect } from "react";
import {
  Layout,
  Card,
  Form,
  Input,
  Radio,
  Button,
  Spin,
  InputNumber,
} from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MyBreadcrumb from "../MyBreadcrumb";
import mortgageSimulator from "../../assets/Products/mortgageSimulator.png";
import gibobsLogo from "../../assets/Products/gibobsIcon.png";
import plusIcon from "../../assets/icons/plusIcon.svg";
import minusIcon from "../../assets/icons/minusIcon.svg";
import equalIcon from "../../assets/icons/equalIcon.svg";
import { LoadingOutlined } from "@ant-design/icons";

import numeral from "numeral";
import { isMobile } from "react-device-detect";
import env from "../../environment";
import axios from "axios";
import { ProvinceSelector } from "afrodita";


const MortgageSimulatorPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();;
  const userToken = useSelector((state) => state.auth.token);
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

  const [pageNumber, setPageNumber] = useState(1);
  const [radioValuePropertyType, setRadioValuePropertyType] = useState();
  const [radioValueRateType, setRadioValueRateType] = useState();
  const [loading, setLoading] = useState(false);
  const [mortgageCalculation, setMortgageCalculation] = useState([
    {
      type: "variable",
      gibobsRate: 0,
      gibobsQuote: 0,
      marketRate: 0,
      marketQuote: 0,
      propertyAmount: 0,
      mortgageAmount: 0,
      operationAmount: 0,
      taxes: 0,
      expenses: 0,
      clientSavings: 0,
      gibobsMonthlySavings: 0,
      gibobsTotallySavings: 0,
      ltv: 0,
      term: 0,
    },
  ]);
  const [valueInitial, setValueInitial] = useState({
    maxBudget: 200000,
    term: 30,
    province: "Madrid",
    propertyType: "secondHand",
    savings: 70000,
    rateTypes: "variable",
  });

  useEffect(() => {
    // valueInitial.term = valueInitial.term * 12;
    getMortgageCalculation({ ...valueInitial, term: valueInitial.term * 12 });
  }, []);

  const data = [
    {
      name: t("menuComponent.salesTools"),
      link: "/sales-tools/new-requests",
    },
    {
      name: t("init.MortgageSimulator"),
      link: undefined,
    },
  ];

  const getMortgageCalculation = (values) => {
    setLoading(true);
    axios
      .post(`${env.api.url}/v1/scoring/simulate`, values, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setMortgageCalculation(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const styleData = (title, value, color) => {
    return (
      <div style={{ textAlign: "center", width: isMobile && color && "100%" }}>
        <div
          style={{
            color: color ? "#02C3CD" : "#748EA0",
            fontSize: isMobile && !color ? 12 : 14,
            fontWeight: color ? 600 : 400,
            lineHeight: "20px",
            marginBottom: !isMobile && 8,
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: color ? "#02C3CD" : "#2F4858",
            fontSize: isMobile && !color ? 16 : 20,
            fontWeight: 700,
            lineHeight: "24px",
          }}
        >
          {numeral(value).format("0,0 $")}
        </div>
      </div>
    );
  };
  const onFinish = (values) => {
    values.maxBudget = numeral(values.maxBudget).value();
    values.savings = numeral(values.savings).value();
    setValueInitial(values);
    getMortgageCalculation({ ...values, term: values.term * 12 });
    setPageNumber(2);
  };
  const onChangeProperty = (e) => {
    setRadioValuePropertyType(e.target.value);
    !isMobile && changesValue(e.target.value, "propertyType");
  };
  const onChangeRate = (e) => {
    setRadioValueRateType(e.target.value);
    !isMobile && changesValue(e.target.value, "rateTypes");
  };

  const changesValue = (value, name) => {
    let objValuesModificated = valueInitial;
    objValuesModificated[name] = value;
    setValueInitial(objValuesModificated);
    getMortgageCalculation({
      ...objValuesModificated,
      term: objValuesModificated.term * 12,
    });
    isMobile && setPageNumber(2);
  };

  const formSimulador = (
    <Form
      className={
        isMobile
          ? "gb-form-profile-headlines-mobile"
          : "gb-form-common-new-style gb-form-common-new-style-32"
      }
      onFinish={isMobile && onFinish}
      name="nest-messages"
      layout="vertical"
      initialValues={valueInitial}
    >
      <Form.Item
        name="maxBudget"
        label={t("form.propertyPrice")}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <InputNumber
          formatter={(value) => numeral(value).format("0,0 $")}
          parser={(value) => numeral(value).value()}
          style={{
            width: "100%",
          }}
          defaultValue={valueInitial && valueInitial.maxBudget}
          placeholder={t("form.propertyPrice")}
          onBlur={(e) =>
            !isMobile &&
            changesValue(numeral(e.target.value).value(), "maxBudget")
          }
        />
      </Form.Item>

      <Form.Item label={t("viability.provinceViability")} name="province">
        <ProvinceSelector
           placeholder={t("viability.provinceViability")}
           style={{ width: "100%" }}
           onChange={(e) => !isMobile && changesValue(e, "province")} />
      </Form.Item>
      <Form.Item label={t("budget.typeHome")} name="propertyType">
        <Radio.Group onChange={onChangeProperty}>
          <Radio value="newConstruction">
            {radioValuePropertyType === "newConstruction" ? (
              <div style={{ color: "#2F4858", fontSize: 14 }}>
                {t("form.newBuild")}
              </div>
            ) : (
              t("form.newBuild")
            )}
          </Radio>
          <Radio value="secondHand">
            {radioValuePropertyType === "secondHand" ? (
              <div style={{ color: "#2F4858", fontSize: 14 }}>
                {t("form.secondHand")}
              </div>
            ) : (
              t("form.secondHand")
            )}
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="savings"
        label={t("form.savingsContributed")}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <InputNumber
          formatter={(value) => numeral(value).format("0,0 $")}
          parser={(value) => numeral(value).value()}
          style={{
            width: "100%",
          }}
          defaultValue={valueInitial && valueInitial.savings}
          placeholder={t("form.Savings")}
          onBlur={(e) =>
            !isMobile &&
            changesValue(numeral(e.target.value).value(), "savings")
          }
        />
       
      </Form.Item>
      <Form.Item label={t("form.typeMortgage")} name="rateTypes">
        <Radio.Group onChange={onChangeRate}>
          <Radio value="fixed">
            {radioValueRateType === "fixed" ? (
              <div style={{ color: "#2F4858", fontWeight: 400 }}>
                {t("form.fixedSpread")}
              </div>
            ) : (
              t("form.fixedSpread")
            )}
          </Radio>
          <Radio value="variable">
            {radioValueRateType === "variable" ? (
              <div style={{ color: "#2F4858", fontWeight: 400 }}>
                {t("form.variableSpread")}
              </div>
            ) : (
              t("form.variableSpread")
            )}
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="term"
        label={t("form.termYears")}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input
          style={{ width: "100%" }}
          placeholder={t("form.termYears")}
          onBlur={(e) => !isMobile && changesValue(e.target.value, "term")}
        />
      </Form.Item>
      {isMobile && (
        <Card
          className="cardButtonStyle"
          style={{
            left: 0,
            marginBottom: -2,
            backgroundColor: "#FAFEFE",
          }}
        >
          <Button
            id="gb-mortgage-buy-situation-mobile"
            className="button-primary-gibobs-inmo"
            type="primary"
            style={{
              fontSize: 16,
              width: "100%",
              height: 48,
              fontWeight: 600,
            }}
            htmlType="submit"
          >
            {t("budget.pushBudget")}
          </Button>
        </Card>
      )}
    </Form>
  );

  const simulatorData = (mortgageCalculation, index) => {
    return (
      <div key={index}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 24,
          }}
        >
          <div
            style={{
              display: "flex",
              marginBottom: 8,
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#FFFFFF",
                padding: isMobile ? 16 : 24,
                borderRadius: 4,
                width: isMobile ? "calc(50% - 24px)" : "auto",
                color: "#2F4858",
                marginRight: 8,
                textAlign: "center",
                boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
              }}
            >
              <img src={gibobsLogo} alt="gibobsLogo" height={50} />
              <div
                style={{
                  fontSize: 12,
                  fontWeight: "normal",
                  marginTop: 16,
                  marginBottom: isMobile ? 8 : 16,
                }}
              >
                {t("viability.ourBestOffer")}
              </div>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#748EA0",
                  marginBottom: isMobile ? 4 : 16,
                }}
              >
                {mortgageCalculation.type === "variable" &&
                  t("loan.euribor") + " + "}
                {(
                  mortgageCalculation && mortgageCalculation.gibobsRate * 100
                ).toFixed(2) + " %"}
              </div>
              <div style={{ fontSize: 20, fontWeight: 600, color: "#02C3CD" }}>
                {numeral(
                  mortgageCalculation && mortgageCalculation.gibobsQuote
                ).format("0,0 $") +
                  "/" +
                  t("viability.monthsEstimated")}
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#FFFFFF",
                padding: isMobile ? 16 : 24,
                borderRadius: 4,
                width: isMobile ? "calc(50% - 16px)" : "auto",
                color: "#2F4858",
                textAlign: "center",
                boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
              }}
            >
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  width: 114,
                  margin: "auto",
                }}
              >
                {t("viability.averageMarketTAE")}
              </div>
              <div
                style={{
                  fontSize: 12,
                  fontWeight: "normal",
                  marginTop: 16,
                  marginBottom: isMobile ? 8 : 16,
                  color: "#FFFFFF",
                }}
              >
                .
              </div>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#748EA0",
                  marginBottom: isMobile ? 4 : 16,
                }}
              >
                {mortgageCalculation.type === "variable" &&
                  t("loan.euribor") + " + "}
                {(
                  mortgageCalculation && mortgageCalculation.marketRate * 100
                ).toFixed(2) + " %"}
              </div>
              <div style={{ fontSize: 20, fontWeight: 600 }}>
                {numeral(
                  mortgageCalculation && mortgageCalculation.marketQuote
                ).format("0,0 $") +
                  "/" +
                  t("viability.monthsEstimated")}
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#FFFFFF",
              padding: isMobile ? 16 : 24,
              borderRadius: 4,
              width: isMobile
                ? "calc(100% - 28px)"
                : mortgageCalculation &&
                  mortgageCalculation.term === 0 &&
                  !isMobile
                ? 332
                : "auto",
              alignSelf: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: isMobile && "center",
              boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
            }}
          >
            <div
              style={{
                fontSize: 16,
                fontWeight: 600,
                marginRight: 12,
                color: "#2F4858",
              }}
            >
              {t("form.totalSavings")}{" "}
              {mortgageCalculation && mortgageCalculation.term / 12}{" "}
              {t("viability.years")}
            </div>
            <div style={{ fontSize: 20, fontWeight: 600, color: "#02C3CD" }}>
              {numeral(
                mortgageCalculation && mortgageCalculation.gibobsTotallySavings
              ).format("0,0 $")}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: isMobile ? 24 : 52,
            marginLeft: isMobile && 16,
            marginRight: isMobile && 16,
            flexWrap: isMobile && "wrap",
            rowGap: isMobile && 16,
          }}
        >
          {styleData(
            t("viability.budgetViability"),
            mortgageCalculation && mortgageCalculation.propertyAmount
          )}
          <div
            style={{
              height: 24,
              width: 24,
              textAlign: "center",
            }}
          >
            <img src={plusIcon} alt="plusIcon" height={16} />
          </div>

          {styleData(
            t("viability.taxes"),
            mortgageCalculation && mortgageCalculation.taxes
          )}
          <div
            style={{
              height: 24,
              width: 24,
              textAlign: "center",
            }}
          >
            <img src={plusIcon} alt="plusIcon" height={16} />
          </div>
          {styleData(
            t("balance.expensesTitle"),
            mortgageCalculation && mortgageCalculation.expenses
          )}
          <div
            style={{
              width: isMobile && "100%",
              textAlign: isMobile && "center",
            }}
          >
            <img src={equalIcon} alt="equalIcon" height={8} />
          </div>
          {styleData(
            t("form.totalCostPurchase"),
            mortgageCalculation && mortgageCalculation.operationAmount,
            true
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: isMobile ? 24 : 52,
            flexWrap: isMobile && "wrap",
            rowGap: isMobile && 16,
            marginTop: isMobile ? 16 : 68,
            marginLeft: isMobile ? 48 : 66,
            marginRight: isMobile ? 48 : 66,
          }}
        >
          {styleData(
            t("form.totalCost"),
            mortgageCalculation && mortgageCalculation.operationAmount
          )}
          <div
            style={{
              height: 24,
              width: 24,
              textAlign: "center",
            }}
          >
            <img src={minusIcon} alt="minusIcon" height={2} />
          </div>
          {styleData(
            t("form.Savings"),
            mortgageCalculation && mortgageCalculation.clientSavings
          )}
          <div
            style={{
              width: isMobile && "100%",
              textAlign: isMobile && "center",
            }}
          >
            <img src={equalIcon} alt="equalIcon" height={8} />
          </div>
          {styleData(
            t("form.amountYourMortgage"),
            mortgageCalculation && mortgageCalculation.mortgageAmount,
            true
          )}
        </div>
        {!isMobile && (
          <div
            style={{
              display: "flex",
              marginTop: 66,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                fontSize: 14,
                fontWeight: 600,
                color: "#748EA0",
                marginRight: 40,
              }}
            >
              {t("form.interestedCustomer")}
            </div>
            <Button
              className="button-primary-gibobs-inmo"
              type="primary"
              onClick={() => navigate("/sales-tools/mortgages")}
            >
              {t("form.startRequest")}
            </Button>
          </div>
        )}
        {isMobile && (
          <Card
            className="cardButtonStyle"
            style={{
              left: 0,
              marginBottom: -2,
              backgroundColor: "#FAFEFE",
            }}
          >
            <Button
              id="gb-mortgage-buy-situation-mobile"
              className="button-secundary-gibobs-inmo"
              type="dashed"
              style={{
                fontSize: 16,
                width: "49%",
                height: 48,
                fontWeight: 600,
              }}
              onClick={() => setPageNumber(1)}
            >
              {t("viability.repeat")}
            </Button>
            <Button
              id="gb-mortgage-buy-situation-mobile"
              className="button-primary-gibobs-inmo"
              type="primary"
              style={{
                fontSize: 16,
                width: "49%",
                height: 48,
                fontWeight: 600,
                marginLeft: 6,
              }}
              onClick={() => navigate("/sales-tools/mortgages")}
            >
              {t("form.startRequest")}
            </Button>
          </Card>
        )}
      </div>
    );
  };

  return (
    <>
      {!isMobile && (
        <Layout style={{ marginBottom: "100px" }}>
          <div style={{ display: "flex" }}>
            <MyBreadcrumb data={data} />
            <div
              style={{ display: "flex", alignItems: "center", marginRight: 40 }}
            >
              <div style={{ color: "#748EA0", fontSize: 12, fontWeight: 500 }}>
                {t("form.AnalyzeViability")}
              </div>
              <div
                style={{
                  color: "#02C3CD",
                  fontSize: 12,
                  fontWeight: 500,
                  marginLeft: 4,
                }}
              >
                gibobs.com
              </div>
            </div>
          </div>
          <Card style={{ width: "calc(100% - 80px)", marginLeft: 40 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Card
                cover={<img alt={"mortgageImage"} src={mortgageSimulator} />}
                style={{
                  backgroundColor: "#EFFCFC",
                  width: "28%",
                  border: "1px solid #C0F0F3",
                }}
              >
                <div
                  style={{
                    color: "#02C3CD",
                    fontWeight: 600,
                    fontSize: 16,
                    marginBottom: 40,
                  }}
                >
                  {t("init.MortgageSimulator")}
                </div>
                {formSimulador}
              </Card>
              <div style={{ width: "70%" }}>
                <div
                  style={{
                    textAlign: "center",
                    color: "#02C3CD",
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  {t("loan.quoteMothMobile")}
                </div>
                {loading ? (
                  <Spin indicator={antIcon} className="spin" />
                ) : (
                  mortgageCalculation.map((data, index) =>
                    simulatorData(data, index)
                  )
                )}
              </div>
            </div>
          </Card>
          <div style={{ padding: 32, marginLeft: 40, marginRight: 40 }}>
            <div
              style={{
                color: "#748EA0",
                fontSize: 10,
                fontWeight: 400,
                marginBottom: 8,
              }}
            >
              {t("viability.explanationCalculationTae")}
            </div>
            <div
              style={{
                color: "#748EA0",
                fontSize: 10,
                fontWeight: 400,
              }}
            >
              {t("viability.explanationSavings")}
            </div>
          </div>
        </Layout>
      )}
      {isMobile && (
        <div>
          {pageNumber === 1 && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: isMobile && "16px",
                  alignItems: "center",
                  marginBottom: !isMobile && "40px",
                }}
              >
                <div className="typography-titles">
                  {t("init.MortgageSimulator")}
                </div>
                <div className="typography-blue-text">
                  {t("form.propertyDetails")}
                </div>
              </div>
              <div style={{ margin: 16, height: "100%", marginBottom: 250 }}>
                {formSimulador}
              </div>
            </div>
          )}
          {pageNumber === 2 && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: isMobile && "16px",
                  alignItems: "center",
                  marginBottom: !isMobile && "40px",
                  paddingBottom: 0,
                }}
              >
                <div className="typography-titles">
                  {t("init.MortgageSimulator")}
                </div>
                <div className="typography-blue-text">
                  {t("viability.averageMarketPayment")}
                </div>
              </div>
              <div>
                {loading ? (
                  <Spin indicator={antIcon} className="spin" />
                ) : (
                  mortgageCalculation.map((data, index) =>
                    simulatorData(data, index)
                  )
                )}
              </div>
              <div style={{ margin: 16, marginTop: 24, marginBottom: 180 }}>
                <div
                  style={{
                    color: "#748EA0",
                    fontSize: 10,
                    fontWeight: 400,
                    marginBottom: 8,
                  }}
                >
                  {t("viability.explanationCalculationTae")}
                </div>
                <div
                  style={{
                    color: "#748EA0",
                    fontSize: 10,
                    fontWeight: 400,
                  }}
                >
                  {t("viability.explanationSavings")}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default MortgageSimulatorPage;
