import React, { useEffect, useState } from "react";
import { Col, Row, Card, Button, Spin, Alert } from "antd";
import { useTranslation } from "react-i18next";
import HelpButton from "../HelpButton";
import DifferentScenarioCards from "./differentScenarioCards";
import { MinusOutlined, PauseOutlined } from "@ant-design/icons";
import ProjectData from "./ProjectData";
import asprima from "../../../assets/icons/asprima.svg";
import infoSecurity from "../../../assets/icons/infoSecurity.svg";
import bankSecurity from "../../../assets/icons/bankSecurity.svg";
import LayoutPromoter from "../LayoutPromoter";
import { isMobileOnly } from "react-device-detect";
import ModalFinancingProcess from "../ModalFinancingProcess";
import numeral from "numeral";
import ModalSaveProject from "./ModalSaveProject";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getFinancingScenarios,
  getListMyProyects,
  getPromoterData,
} from "../../../store/promoter/actions";
import { LoadingOutlined } from "@ant-design/icons";
import ModalShareUs from "../CommonComponents/ModalShareUs";
import { InfoCircleOutlined } from "@ant-design/icons";


const SimulationSummary = () => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const operationId = params.operationId;
  const operationStages = params.stage;
  const colSpan = { xs: 24, lg: 16 };
  const colSpan1 = { xs: 24, lg: 8 };
  const [modalDescription, setModalDescription] = useState(false);
  const [modalSaveProject, setModalSaveProject] = useState(false);
  const [modalShareUs, setModalShareUs] = useState(false);
  const [loading, setLoading] = useState(false);
  const scenariosData = useSelector((state) => state.promoter.dataScenarios);
  const dataPromoter = useSelector((state) => state.promoter.dataFormPromoter);
  const proyects = useSelector((state) => state.promoter.dataListMyProyects);
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

  const addingNumber = (title, number) => {
    return (
      <div style={{ textAlign: "center" }}>
        <div
          className="paragraph-style"
          style={{
            fontWeight: title === "promoterLoan.amountFinancing" && 600,
            fontSize: title === "promoterLoan.amountFinancing" && 16,
          }}
        >
          {t(title)}
        </div>
        <div
          style={{
            color: number < 0 ? "#BF3A3B" : "#2F4858",
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          {numeral(number).format("0,0 $")}
        </div>
      </div>
    );
  };

  useEffect(() => {
    // if (scenariosData && scenariosData.length === 0) {
    dispatch(getFinancingScenarios(operationId));
    // }
  }, []);

  useEffect(() => {
    if (
      proyects &&
      proyects.length !== 0 &&
      (Object.values(dataPromoter).length === 0 ||
        Object.values(dataPromoter).length === 1)
    ) {
      const projectArray = proyects.filter((data) => data.id === operationId);
      dispatch(
        getPromoterData(
          projectArray && projectArray[0] && projectArray[0].operationDataClient
        )
      );
    }
  }, [proyects]);

  const findScenario = (name, type, option) => {
    const obj =
      scenariosData && scenariosData.find((data) => data.loanType === name);
    return obj && obj.scenarioData[type][option];
  };

  const scenarioFavorite = (name, type) => {
    const obj =
      scenariosData && scenariosData.find((data) => data.loanType === name);
    return obj && obj[type];
  };
  const nameStages = {
    simulation: t("m_viability.simulationTitle"),
    analysis: t("document.analystics"),
    documentation: t("document.documentation"),
    oferts: t("m_offers.title"),
    formalization: t("formalisation.formalisation"),
    finish: t("stages.closed"),
    SALE: t("promoterLoan.saleTitle"),
    RESIDENTIAL: t("promoterLoan.residentialTitle"),
    OTHER: t("promoterLoan.otherTitle"),
    SINGLE_FAMILY: t("promoterLoan.singleFamily"),
    MULTI_FAMILY: t("promoterLoan.multiFamily"),
    undefined: "-",
  };

  const checkViability = () => {
    let allScenariosZero = true;
    scenariosData.forEach(object => {
      const { loanType, scenarioData } = object;
      if (
        (loanType === 'LOAN_PROMOTER_BANK' ||
          loanType === 'ALTERNATIVE_LOAN_PROMOTER_BANK' ||
          loanType === 'BRIDGE_LOAN' ||
          loanType === 'ALTERNATIVE_CAPITAL_INCREASE') &&
        scenarioData.generalData.viability !== 0
      ) {
        allScenariosZero = false;
      }
    });
    return allScenariosZero
  }

  return (
    <>
      <LayoutPromoter
        setModalDescription={(e) => setModalDescription(e)}
        name={
          dataPromoter &&
            dataPromoter.projectData &&
            dataPromoter.projectData.projectName
            ? dataPromoter.projectData.projectName
            : "Proyecto 1"
        }
        stages={nameStages[operationStages]}
      />

      <div
        style={{
          margin: "0px 40px 100px",
          marginBottom: 100,
        }}
      >
        <Row gutter={24}>
          <Col {...colSpan1}>
            {(Object.values(dataPromoter).length === 0 ||
              Object.values(dataPromoter).length === 1) && (
                <Spin indicator={antIcon} className="spin" />
              )}
            {Object.values(dataPromoter).length > 1 && (
              <Card className="card-data-project" id="container-data">
                <div
                  className="title-primary"
                  style={{ marginBottom: 8, textAlign: "center" }}
                >
                  {t("promoterLoan.yourProjectData")}
                </div>

                <div className="paragraph-style" style={{ marginBottom: 16 }}>
                  {t("promoterLoan.yourProjectDataDescription")}
                </div>
                <ProjectData
                  operationId={operationId}
                  setLoading={(e) => setLoading(e)}
                />
              </Card>
            )}
          </Col>

          <Col {...colSpan}>
            <Card style={{ border: "1px solid #C0F0F3", borderRadius: 4 }}>
              {loading && <Spin indicator={antIcon} className="spin" />}
              {!loading && (
                <>
                  <div
                    className="title-primary"
                    style={{ marginBottom: 8, textAlign: "center" }}
                  >
                    {t("promoterLoan.yourSimulationResults")}
                  </div>
                  <DifferentScenarioCards
                    title={"promoterLoan.case0title"}
                    type={"OWN_FUNDING"}
                    number={0}
                    equity={
                      scenariosData &&
                      findScenario(
                        "OWN_FUNDING",
                        "generalData",
                        "equityMultiple"
                      )
                    }
                    margin={
                      scenariosData &&
                      findScenario(
                        "OWN_FUNDING",
                        "generalData",
                        "marginOnsales"
                      )
                    }
                    // viability={"3,3"}
                    tooltipText={"promoterLoan.case0Tooltip"}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 8,
                    }}
                  >
                    <div className="title-primary">
                      {t("promoterLoan.financingOptions")}
                    </div>
                    <HelpButton
                      simulation={true}
                      primary={true}
                      origin="SIMULATION_RESULTS"
                    />
                  </div>
                  {dataPromoter?.capitalStructure?.financingRequired < 0 && (
                    <div className="update-scenarios-styles">
                      <InfoCircleOutlined
                        style={{
                          color: "#FF4D4F",
                          marginRight: 10,
                          fontSize: 20,
                        }}
                      />
                      <div className="text-detail-small">
                        {t("promoterLoan.exceptionalCaseFinancing")}
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "34px 50px 0px",
                    }}
                  >
                    {addingNumber(
                      "promoterLoan.totalCosts",
                      Number(
                        dataPromoter?.economicData?.projectCosts?.totalCosts
                      )
                    )}
                    <MinusOutlined style={{ color: "#02C3CD" }} />
                    {addingNumber(
                      "promoterLoan.ownFunds",
                      Number(dataPromoter?.capitalStructure?.equity)
                    )}
                    <PauseOutlined rotate={90} style={{ color: "#02C3CD" }} />
                    {addingNumber(
                      "promoterLoan.amountFinancing",
                      Number(dataPromoter?.capitalStructure?.financingRequired)
                    )}
                  </div>
                  {checkViability() && (
                      <Alert
                        className='viabilityZero'
                        style={{ marginTop: 24, borderRadius: 4, borderColor: '#FAAD14' }}
                        description={t('promoter.viabilityZero')}
                        type="warning"
                        showIcon
                      />
                  )}
                  {dataPromoter?.capitalStructure?.financingRequired > 0 && (
                    <>
                      <DifferentScenarioCards
                        title={"promoterLoan.case1title"}
                        type={"LOAN_PROMOTER_BANK"}
                        number={1}
                        equity={
                          scenariosData &&
                          findScenario(
                            "LOAN_PROMOTER_BANK",
                            "generalData",
                            "equityMultiple"
                          )
                        }
                        margin={
                          scenariosData &&
                          findScenario(
                            "LOAN_PROMOTER_BANK",
                            "generalData",
                            "marginOnsales"
                          )
                        }
                        viability={
                          scenariosData &&
                          findScenario(
                            "LOAN_PROMOTER_BANK",
                            "generalData",
                            "viability"
                          )
                        }
                        tooltipText={"promoterLoan.case1Tooltip"}
                        favorite={scenarioFavorite(
                          "LOAN_PROMOTER_BANK",
                          "favorite"
                        )}
                        id={scenarioFavorite("LOAN_PROMOTER_BANK", "id")}
                      />
                      <DifferentScenarioCards
                        type={"ALTERNATIVE_LOAN_PROMOTER_LOAN"}
                        title={"promoterLoan.case2title"}
                        number={2}
                        equity={
                          scenariosData &&
                          findScenario(
                            "ALTERNATIVE_LOAN_PROMOTER_LOAN",
                            "generalData",
                            "equityMultiple"
                          )
                        }
                        margin={
                          scenariosData &&
                          findScenario(
                            "ALTERNATIVE_LOAN_PROMOTER_LOAN",
                            "generalData",
                            "marginOnsales"
                          )
                        }
                        viability={findScenario(
                          "ALTERNATIVE_LOAN_PROMOTER_LOAN",
                          "generalData",
                          "viability"
                        )}
                        tooltipText={"promoterLoan.case2Tooltip"}
                        favorite={scenarioFavorite(
                          "ALTERNATIVE_LOAN_PROMOTER_LOAN",
                          "favorite"
                        )}
                        id={scenarioFavorite(
                          "ALTERNATIVE_LOAN_PROMOTER_LOAN",
                          "id"
                        )}
                      />
                      <DifferentScenarioCards
                        type={"BRIDGE_LOAN"}
                        title={"promoterLoan.case3title"}
                        number={3}
                        equity={
                          scenariosData &&
                          findScenario(
                            "BRIDGE_LOAN",
                            "generalData",
                            "equityMultiple"
                          )
                        }
                        margin={
                          scenariosData &&
                          findScenario(
                            "BRIDGE_LOAN",
                            "generalData",
                            "marginOnsales"
                          )
                        }
                        viability={findScenario(
                          "BRIDGE_LOAN",
                          "generalData",
                          "viability"
                        )}
                        tooltipText={"promoterLoan.case3Tooltip"}
                        favorite={scenarioFavorite("BRIDGE_LOAN", "favorite")}
                        id={scenarioFavorite("BRIDGE_LOAN", "id")}
                      />
                      <DifferentScenarioCards
                        type={"ALTERNATIVE_CAPITAL_INCREASE"}
                        title={"promoterLoan.case4title"}
                        number={4}
                        equity={
                          scenariosData &&
                          findScenario(
                            "ALTERNATIVE_CAPITAL_INCREASE",
                            "generalData",
                            "equityMultiple"
                          )
                        }
                        margin={
                          scenariosData &&
                          findScenario(
                            "ALTERNATIVE_CAPITAL_INCREASE",
                            "generalData",
                            "marginOnsales"
                          )
                        }
                        viability={
                          scenariosData &&
                          findScenario(
                            "ALTERNATIVE_CAPITAL_INCREASE",
                            "generalData",
                            "viability"
                          )
                        }
                        tooltipText={"promoterLoan.case4Tooltip"}
                        favorite={scenarioFavorite(
                          "ALTERNATIVE_CAPITAL_INCREASE",
                          "favorite"
                        )}
                        id={scenarioFavorite(
                          "ALTERNATIVE_CAPITAL_INCREASE",
                          "id"
                        )}
                      />

                      <Card
                        style={{
                          backgroundColor: "#F1F7F8",
                          boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
                          borderRadius: 4,
                        }}
                      >
                        <Row gutter={24}>
                          <Col lg={16} xs={16}>
                            <div
                              className="title-primary"
                              style={{ marginBottom: 8 }}
                            >
                              {t("promoterLoan.linesFinancing")}
                            </div>
                            <div
                              className="paragraph-style"
                              style={{ marginBottom: 16 }}
                            >
                              {t("promoterLoan.linesFinancingDescription")}
                            </div>
                          </Col>
                          <Col
                            lg={8}
                            xs={8}
                            style={{ alignSelf: "center", textAlign: "right" }}
                          >
                            <HelpButton
                              simulation={true}
                              origin="TWO_LINES_OF_FINANCING"
                            />
                          </Col>
                        </Row>
                      </Card>
                      <div style={{ textAlign: "center", marginTop: 40 }}>
                        <Button
                          type={"primary"}
                          className={"button-primari-gibobs"}
                          onClick={() => setModalSaveProject(true)}
                        >
                          {t("promoterLoan.saveProject")}
                        </Button>
                      </div>
                    </>
                  )}
                </>
              )}
            </Card>
            <div
              className="paragraph-style"
              style={{ marginTop: 16, fontSize: 12 }}
            >
              {t("promoterLoan.resultsDescription")}
            </div>
            <div
              style={{
                marginTop: 24,
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                rowGap: 24,
              }}
            >
              <img src={infoSecurity} alt="infoSecurity" />
              <img src={bankSecurity} alt="bankSecurity" />
              <img src={asprima} alt="asprima" />
            </div>
          </Col>
        </Row>
        <ModalFinancingProcess
          key={modalDescription}
          visible={modalDescription}
          onCreate={() => setModalDescription(false)}
          onCancel={() => setModalDescription(false)}
          width={!isMobileOnly && 638}
        />
        <ModalSaveProject
          key={modalSaveProject}
          visible={modalSaveProject}
          save={true}
          onCreate={(e) => {
            setModalSaveProject(false);
            setModalShareUs(true);
            dispatch(getListMyProyects());
            dispatch(
              getPromoterData({
                ...dataPromoter,
                projectData: {
                  ...e,
                },
              })
            );
          }}
          onCancel={() => setModalSaveProject(false)}
          width={!isMobileOnly && 690}
          initialValuesData={
            dataPromoter && dataPromoter.projectData
              ? { ...dataPromoter["projectData"] }
              : {
                province:
                  dataPromoter.generalData &&
                  dataPromoter.generalData.province &&
                  dataPromoter.generalData.province,
              }
          }
        />
        <ModalShareUs
          key={modalShareUs}
          visible={modalShareUs}
          onCreate={() => {
            setModalShareUs(false);
          }}
          onCancel={() => {
            setModalShareUs(false);
          }}
          width={!isMobileOnly && 640}
          save={true}
        />
      </div>
    </>
  );
};

export default SimulationSummary;
