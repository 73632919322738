import React from "react";
import { Col, Row, Card } from "antd";
import { useTranslation } from "react-i18next";
import LayoutPromoter from "../LayoutPromoter";
import construction from "../../../assets/promoter/construction.png";


const DocumentatioPromoter = () => {
  const { t } = useTranslation();
  const colSpan = { xs: 24, lg: 24 };

  return (
    <>
      <div
        style={{
          margin: "40px 40px 24px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <LayoutPromoter />
        <div
          style={{
            background: "#F1F7F8",
            borderRadius: 4,
            display: "flex",
            padding: "14px 24px",
          }}
        >
          <div
            style={{
              color: "#2F4858",
              fontSize: 14,
              fontWeight: 600,
              marginRight: 75,
            }}
          >
            Proyecto 01
          </div>
          <div
            style={{
              color: "#2F4858",
              fontSize: 14,
              fontWeight: 400,
            }}
          >
            Estado: en análisis
          </div>
        </div>
      </div>
      <div
        style={{
          margin: "0px 40px 100px",
          marginBottom: 100,
        }}
      >
        <Row gutter={24}>
          <Col {...colSpan}>
            <Card style={{ border: "1px solid #C0F0F3", borderRadius: 4 }}>
              <div
                className="title-primary"
                style={{ marginBottom: 8, textAlign: "center" }}
              >
                {t("Esta pantalla se encuentra en construcción")}
              </div>
              <div style={{ textAlign: "center" }}>
                <img src={construction} alt="construction" />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DocumentatioPromoter;
