import {
  ERROR,
  EXPIRES_AT,
  LOADING,
  PROFILE,
  AUTHENTICATED,
  ROLES,
  TOKEN,
  MORTGAGEID,
  OPERATIONS,
  TABSPAGE,
  STAGES,
  RELOADINIT,
  CALLACT,
  REFRESHSTAGES,
  REFRESHDATA,
  ALLROLES
} from "./constants";

const profileString = localStorage.getItem("profile") || "{}";
let profile = {};

try {
  JSON.parse(profileString);
} catch(ex) {}

let isAuthenticated = false;
const expiresAt = Number(localStorage.getItem("expiresAt") || 0);

if (new Date().getTime() < expiresAt) {
  isAuthenticated = true;
}

export const INITIAL_STATE = {
  profile,
  loading: false,
  token: localStorage.getItem("access_token"),
  expiresAt,
  isAuthenticated,
  roles: localStorage.getItem("roles"),
  stages: "",
  mortgageId: "",
  reloadInit: false,
  callAct: true,
  refreshStages: false,
  refreshData: false,
  allRoles: localStorage.getItem("allRoles") || []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROFILE:
      return { ...state, profile: action.payload };
    case LOADING:
      return { ...state, loading: action.payload };
    case TOKEN:
      return { ...state, token: action.payload };
    case EXPIRES_AT:
      return { ...state, expiresAt: action.payload };
    case ERROR:
      return { ...state, error: action.payload };
    case AUTHENTICATED:
      return { ...state, isAuthenticated: action.payload };
    case ROLES:
      return { ...state, roles: action.payload };
    case MORTGAGEID:
      return { ...state, mortgageId: action.payload };
    case OPERATIONS:
      return { ...state, operations: action.payload };
    case TABSPAGE:
      return { ...state, tabspage: action.payload };
    case STAGES:
      return { ...state, stages: action.payload };
    case RELOADINIT:
      return { ...state, reloadInit: action.payload };
    case CALLACT:
      return { ...state, callAct: action.payload };
    case REFRESHSTAGES:
      return { ...state, refreshStages: action.payload };
    case REFRESHDATA:
      return { ...state, refreshData: action.payload };
    case ALLROLES:
      return { ...state, allRoles: action.payload };
    default:
      return state;
  }
};
