import React from "react";
import { Card, Typography, Button, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";
import { createNewProject } from "../../store/promoter/actions";
import { useSelector, useDispatch } from "react-redux";

const { Text } = Typography;

function ProductsComponent({
  title,
  titleText,
  parrafo,
  image,
  contactReason,
  index,
}) {
  const navigate = useNavigate();;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.promoter.loading);

  return (
    <Card
      className="gb-card"
      style={{
        width: "100%",
        height: isMobileOnly ? "auto" : "100%",
        borderRadius: 4,
        marginBottom: isMobileOnly ? 16 : undefined,
      }}
      cover={<img alt={title} src={image} width={"100%"} />}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Text
            style={{
              fontSize: "16px",
              color: "#2F4858",
              fontWeight: 700,
            }}
          >
            {t(titleText)}
          </Text>
          {index !== "3" && (
            <Tooltip title={parrafo}>
              <InfoCircleOutlined style={{ color: "#02C3CD", marginLeft: 6 }} />
            </Tooltip>
          )}
        </div>
        {!isMobile && (
          <Text
            style={{
              height: "auto",
              fontSize: 14,
              marginTop: 8,
              color: "#748EA0",
              marginBottom: 50,
            }}
          >
            {parrafo}
          </Text>
        )}
        {isMobileOnly && (
          <Button
            type="primary"
            className="button-secundary-gibobs-inmo"
            style={{ marginTop: 24 }}
            onClick={
              title !== t("promoterLoan.promoterFinancingProducts")
                ? () =>
                    navigate(
                      `/products-business/request-information/${contactReason}`
                    )
                : undefined
              // : () => dispatch(createNewProject())
            }
          >
            {title === t("promoterLoan.promoterFinancingProducts")
              ? t("init.simulate")
              : t("productsComponent.askInformation")}
          </Button>
        )}
      </div>
      {!isMobileOnly && (
        <>
          <Button
            type="primary"
            className="button-secundary-gibobs-inmo"
            loading={loading}
            style={{
              // float: "bottom",
              width: "calc(100% - 64px)",
              height: 40,
              fontSize: 14,
              paddingLeft: "10px",
              position: "absolute",
              bottom: 24,
            }}
            onClick={
              title !== t("promoterLoan.promoterFinancingProducts")
                ? () =>
                    navigate(
                      `/products-business/request-information/${contactReason}`
                    )
                : () => dispatch(createNewProject())
            }
          >
            {title === t("promoterLoan.promoterFinancingProducts")
              ? t("init.simulate")
              : t("productsComponent.askInformation")}
          </Button>
        </>
      )}
    </Card>
  );
}

export default ProductsComponent;
