import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { UserOutlined, MailOutlined, DownOutlined } from "@ant-design/icons";
import { Avatar, Button, Modal, Typography, message } from "antd";
import { isMobile } from "react-device-detect";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import env from "../../../../environment";

const { Text } = Typography;

const AgentInformation = ({ name, phone, email, image, formalization }) => {
  const { t } = useTranslation();

  const [showModalAvatar, setShowModalAvatar] = useState(false);
  const params = useParams();
  const userToken = useSelector((state) => state.auth.token);


  const sendEmail = () => {
    axios
      .get(`${env.api.url}/v1/hadmin/sales-agents/contact-agent-b2b/${params.operationId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then(() => {
        message.success("Tu mensaje fue enviado");
      });
}

return (<>
  {!isMobile && (
    <div style={{ textAlign: "center" }}>
      <div
        style={{
          fontSize: 14,
          fontWeight: 600,
          color: "#2F4858",
          marginBottom: 8,
        }}
      >
        {t("details.commercial")}
      </div>

      {image !== undefined && image !== null ? (
        <img src={image} alt="imageAgent" height={84} />
      ) : (
        <Avatar size={84} icon={<UserOutlined />} />
      )}

      <div
        style={{
          fontSize: 12,
          fontWeight: 500,
          color: "#2F4858",
          lineHeight: "16px",
          marginTop: 12,
        }}
      >
        {name}
      </div>
      <div
        style={{
          fontSize: 18,
          fontWeight: 700,
          color: "#02C3CD",
          lineHeight: "24px",
        }}
      >
        {phone}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 12,
          justifyContent: "center",
        }}
      >
        <div
          style={{
            fontSize: 12,
            fontWeight: 400,
            color: "#748EA0",
            marginRight: 12,
          }}
        >
          {email}
        </div>
        <Button
          onClick={() => sendEmail()}
          type="primary"
          icon={<MailOutlined style={{ fontSize: 12 }} />}
          style={{ height: 24, width: 24 }}
        />
      </div>
    </div>
  )}
  {isMobile && !showModalAvatar && (
    <div
      style={{
        position: "fixed",
        bottom: formalization ? 90 : 10,
        right: 16,
      }}
      onClick={() => setShowModalAvatar(!showModalAvatar)}
    >
      {image !== undefined && image !== null ? (
        <img
          src={image}
          alt="imageAgent"
          height={64}
          style={{ width: 64, height: 64, borderRadius: "50%" }}
        />
      ) : (
        <Avatar size={64} icon={<UserOutlined />} />
      )}
    </div>
  )}
  <Modal
    className="modal-movile-hidden-data-basic modal-contact-me-inmo"
    open={showModalAvatar}
    closable={false}
    style={{ zIndex: 10000, width: 328 }}
    title={
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text style={{ color: "#2F4858", fontSize: 24, fontWeight: 600 }}>
          {t("typeMortgage.modalContactText1")}
        </Text>
        {image !== undefined && image !== null ? (
          <img
            src={image}
            alt="imageAgent"
            height={64}
            style={{ width: 64, height: 64, borderRadius: "50%" }}
          />
        ) : (
          <Avatar size={64} icon={<UserOutlined />} />
        )}
      </div>
    }
    width={"100%"}
  >
    <div style={{ color: "#2F4858", fontSize: 14, fontWeight: 400 }}>
      {t("typeMortgage.modalContactText2")} {name},{" "}
      {t("details.commercial").toLowerCase()}.
    </div>
    <div
      style={{
        color: "#2F4858",
        fontSize: 14,
        fontWeight: 400,
        marginTop: 24,
      }}
    >
      {t("form.contactMeModal")}
    </div>
    <div
      style={{
        marginTop: 24,
        display: "flex",
        alignItems: "center",
        justifyContent: "right",
      }}
    >
      <Button
        onClick={() => sendEmail()}
        type="primary"
        icon={<MailOutlined style={{ fontSize: 20, marginTop: 3 }} />}
        style={{ height: 32, width: 32 }}
      />

      <div style={{ marginLeft: 16 }}>
        <div
          style={{
            color: "#02C3CD",
            fontSize: 20,
            fontWeight: 600,
            textAlign: "right",
            lineHeight: "24px",
          }}
        >
          {phone}
        </div>
        <div
          style={{
            color: "#748EA0",
            fontSize: 12,
            fontWeight: 400,
            textAlign: "right",
            lineHeight: "16px",
          }}
        >
          {email}
        </div>
      </div>
    </div>

    <Button
      onClick={() => setShowModalAvatar(!showModalAvatar)}
      style={{
        background: "#EFFCFC",
        position: "fixed",
        bottom: 10,
        right: 16,
        width: 64,
        height: 64,
        borderRadius: 40,
        border: "none",
      }}
    >
      <DownOutlined style={{ color: "#02C3CD", fontSize: 20 }} />
    </Button>
  </Modal>
</>);
};

export default AgentInformation;
