import React from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Col, Row, Button } from "antd";
import CarpetaBlanca from "../../../assets/icons/CarpetaBlanca.svg";
import CarpetaAzul from "../../../assets/icons/CarpetaAzul.svg";
import moment from "moment";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

function DocumentsWidget({ data }) {
  const { t } = useTranslation();

  return (
    <div>
      <div
        style={{
          height: "120px",
          display: "flex",
          justifyContent: "space-between",
          padding: "0 20px",
        }}
      >
        <div id="dad">
          <img src={CarpetaAzul} alt="Carpeta Azul" height="90px" />
          <div
            className="card-title"
            style={{ fontSize: "9px", color: "white", marginTop: "-65px" }}
          >
            TITULAR 1
          </div>
          <PlusCircleOutlined
            style={{ color: "white", fontSize: "30px", marginTop: "7px" }}
          />
        </div>
        <div id="dad1">
          <img src={CarpetaBlanca} alt="Carpeta Blanca" height="90px" />
          <div
            className="card-title"
            style={{ fontSize: "9px", color: "#B3B3B3", marginTop: "-65px" }}
          >
            TITULAR 2
          </div>
        </div>

        <div id="dad1">
          <img src={CarpetaBlanca} alt="Carpeta Blanca" height="90px" />
          <div
            className="card-title"
            style={{ fontSize: "9px", color: "#B3B3B3", marginTop: "-65px" }}
          >
            AVALISTA
          </div>
        </div>
      </div>
      <Button
        style={{
          backgroundColor: "#2A6977",
          color: "#FFFFFF",
          padding: "0 20px",
          height: "30px",
          marginBottom: "20px",
          marginLeft: "70%",
        }}
        shape="round"
      >
        {t("init.buttonSeAll")}
      </Button>

      <div className="barraStyle" style={{ height: "150px" }}>
        {data.data.documents &&
          data.data.documents.map((data) => {
            const dataSuccess =
              data.name === false ? (
                <CloseOutlined style={{ color: "red" }} />
              ) : (
                <CheckOutlined />
              );
            const dateUpload = moment(data.updatedAt).format("L");
            return (
              <>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "#B3B3B3",
                    textAlign: "left",
                    marginBottom: "5px",
                  }}
                  key={data.id}
                >
                  <Col
                    span={12}
                    style={{
                      fontSize: "11px",
                      marginTop: "5px",
                    }}
                  >
                    {data.name}
                  </Col>
                  <Col span={4}>{dateUpload} </Col>
                  <Col span={2}>{dataSuccess}</Col>
                </Row>
              </>
            );
          })}
      </div>
    </div>
  );
}

export default DocumentsWidget;
