import React, { useEffect, useState } from 'react';
import {
	Row,
	Button,
	Card,
	Skeleton,
	Col,
	Typography,
	Form,
	Select,
	Slider,
	Modal,
	InputNumber,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
	ArrowLeftOutlined,
	EuroCircleOutlined,
	InfoCircleOutlined,
	WarningOutlined
} from '@ant-design/icons';

import { useSelector } from 'react-redux';
import { renderComponent } from '../../../select/typeSelect';
import calculatorQuality from '../../../assets/calculators/calculatorQuality.png';
import axios from 'axios';
import env from '../../../environment';
import numeral from 'numeral';
import _ from 'lodash';
import CalculatorDataClientQualify from './CalculatorDataClientQualify';
import { isMobileOnly } from 'react-device-detect';
import CalculatorsQualifyClientMobile from './CalculatorsQualifyClientMobile';
import calculatorImage from '../../../assets/calculators/calculatorImage.png';

const { Text } = Typography;
const { Option } = Select;

const CalculatorsQualifyClient = ({}) => {
	const { t } = useTranslation();
	const rol = useSelector((state) => state.auth.roles);
	const navigate = useNavigate();;
	const [loading, setLoading] = useState(true);
	const [form] = Form.useForm();
	const [term, setTerm] = useState(false);
	const [rate, setRate] = useState(false);
	const [finish, setFinish] = useState(false);
	const dataProfile = useSelector((state) => state.data.dataProfile);
	const [result, setResult] = useState(undefined);
	const userToken = useSelector((state) => state.auth.token);
	const [fixed, setFixed] = useState(undefined);
	const [quotes, setQuotes] = useState(360);
	const [price, setPrice] = useState(10000);
	const [savings, setSavings] = useState(0);
	const [province, setProvince] = useState('Valencia');
	const [family, setFamily] = useState(false);
	const [houseType, setHouseType] = useState(undefined);
	const [visibleFamily, setVisibleFamily] = useState(false);
	const [priceTouch, setPriceTouch] = useState(undefined);
	const [euribor, setEuribor] = useState(undefined);
	const [fixedTmp, setFixedTmp] = useState(undefined);
	const [variableTmp, setVariableTmmp] = useState(undefined);
	const [euriborTmp, setEuriborTmp] = useState(undefined);
	const [timeoutReference, setTimeoutReference] = useState(undefined);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, [1500]);
		navigate('/calculators/qualify/client');
		getEuribor();
		getFixAndVariable();
	}, []);

	useEffect(() => {
		if (variableTmp && euriborTmp && fixedTmp) {
			let varMasEur = variableTmp + euriborTmp;

			let total = varMasEur <= fixedTmp ? varMasEur : fixedTmp;

			setFixed(total);
		}
	}, [variableTmp && euriborTmp && fixedTmp]);

	const getFixAndVariable = () => {
		axios
			.get(`${env.api.url}/v1/options/get-option/rate-gibobs-variable-tae`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				setVariableTmmp(response.data);
			});

		axios
			.get(`${env.api.url}/v1/options/get-option/rate-gibobs-fixed-tae`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				setFixedTmp(response.data);
			});

		axios
			.get(`${env.api.url}/v1/options/get-option/euribor`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				setEuriborTmp(response.data);
			});
	};

	useEffect(() => {
		if (
			result &&
			result?.bonilla &&
			result?.bonilla[1]?.maxBudget !== price &&
			priceTouch === undefined
		) {
			setPrice(price);
		} else if (priceTouch !== undefined) {
			setPrice(priceTouch);
		}
		if (typeof result === 'string') {
			setPrice(undefined);
		}
	}, [price]);

	const getEuribor = () => {
		axios
			.get(`${env.api.url}/v1/rates/by-month/euribor`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setEuribor(response.data.data.rates);
			})
			.catch((error) => {
                console.log(error)
            });
	};

	const sendFinish = () => {
		//envio a a la operacion con el test ab

		setFinish(false);
		
		navigate(`/sales-tools/mortgages`,{
            state: {
				values: { ...form.getFieldsValue() },
				fixed,
				quotes,
				result: result !== undefined ? result.simulation : undefined
			}
        });
	};

	const onChangeFixed = (value) => {
		setFixed(value);
		const tmp = form.getFieldsValue();
		const tmpValues = {
			fixed: value,
			quotes: undefined
		};

		onChangeForm(tmpValues, tmp, price);
	};

	const onChangeHouseType = (value) => {
		setHouseType(value);
	};

	const onChangePrice = (value) => {
		setPrice(value);
		setPriceTouch(value);
		const tmp = form.getFieldsValue();

		const price = value;
		const tmpValues = {
			fixed: undefined,
			quotes: undefined
		};
		onChangeForm(tmpValues, tmp, price);
	};

	const onChangeTerm = (value) => {
		setQuotes(value * 12);
		const tmp = form.getFieldsValue();
		const tmpValues = {
			fixed: undefined,
			quotes: value * 12
		};
		onChangeForm(tmpValues, tmp, price);
	};

	const onChangeForm = (values, allValues, price, familyTmp) => {
		if (allValues !== undefined) {
			setSavings(allValues.savings);
			setProvince(allValues.province);
		}

		if (_.isNil(price)) {
			price = 10000;
		}

		let houseTypeTmp = allValues.houseType;

		if (timeoutReference) {
			clearTimeout(timeoutReference);
		}

		setTimeoutReference(
			setTimeout(() => {
				if (result !== undefined && price >= 10000) {
					if (_.isNil(result.simulation.simulationData.owner2)) {
						let owners = [];
						owners = [result.simulation.simulationData.owner1];

						axios
							.post(
								`${env.api.url}/mortgages/simulations/client-qualification/mortgage-viability`,
								{
									id: !_.isNil(result) ? result.simulation.id : undefined,
									simulationType: rol === 'realestate' ? "B2B" : "B2C",
									savings: !_.isNil(allValues?.savings)
										? allValues.savings
										: result.simulation.simulationData.savings,
									propertyType:
										allValues?.propertyType !== undefined
											? allValues.propertyType
											: result.simulation.simulationData.propertyType,
									numerousFamily:
										familyTmp === undefined
											? _.isNil(result.simulation.simulationData.numerousFamily)
												? false
												: result.simulation.simulationData.numerousFamily
											: familyTmp,
									province:
										allValues?.province !== undefined
											? allValues.province
											: result.simulation.simulationData.province,
									maxBudget: price > 10000 ? price : undefined,
									fixed: fixed !== 1.9 ? fixed : result.simulation.simulationData.fixed,
									quotes: quotes !== 360 ? quotes : result.simulation.simulationData.quotes,

									participants: {
										houseType: !_.isNil(houseTypeTmp)
											? houseTypeTmp
											: result.simulation.simulationData.houseType,
										participants: 1,
										owners: owners
									}
								},
								{
									headers: { Authorization: `Bearer ${userToken}` }
								}
							)
							.then((response) => {
								setResult(response.data.data);
								if (typeof response.data.data === 'string') {
									setPrice(undefined);
								} else {
									setPrice(response.data.data?.bonilla[1]?.maxBudget);
								}
							})
							.catch((error) => {
								console.error(error);
							});
					} else {
						axios
							.post(
								`${env.api.url}/mortgages/simulations/client-qualification/mortgage-viability`,
								{
									id: !_.isNil(result) ? result.simulation.id : undefined,
									simulationType: rol === 'realestate' ? "B2B" : "B2C",
									savings: !_.isNil(allValues?.savings)
										? allValues.savings
										: result.simulation.simulationData.savings,
									propertyType:
										allValues?.propertyType !== undefined
											? allValues.propertyType
											: result.simulation.simulationData.propertyType,
									numerousFamily:
										familyTmp === undefined
											? _.isNil(result.simulation.simulationData.numerousFamily)
												? false
												: result.simulation.simulationData.numerousFamily
											: familyTmp,
									province:
										allValues?.province !== undefined
											? allValues.province
											: result.simulation.simulationData.province,
									maxBudget: price > 10000 ? price : undefined,
									fixed: fixed !== 1.9 ? fixed : result.simulation.simulationData.fixed,
									quotes: quotes !== 360 ? quotes : result.simulation.simulationData.quotes,

									participants: {
										houseType: !_.isNil(houseTypeTmp)
											? houseTypeTmp
											: result.simulation.simulationData.houseType,
										participants: 2,
										owners: [
											result.simulation.simulationData.owner1,
											result.simulation.simulationData.owner2
										]
									}
								},
								{
									headers: { Authorization: `Bearer ${userToken}` }
								}
							)
							.then((response) => {
								setResult(response.data.data);
								if (typeof response.data.data === 'string') {
									setPrice(undefined);
								} else {
									setPrice(response.data.data?.bonilla[1]?.maxBudget);
								}
							})
							.catch((error) => {
								console.error(error);
							});
					}
				}
				clearTimeout(timeoutReference);
			}, 500)
		);
	};

	const renderTitle = () => {
		if (result.viability.excepcionalViability === 'NOSAVINGS' && rol === 'realestate') {
			return t('onboarding.cantBuyWithoutSavingsInmo');
		} else if (result.viability.excepcionalViability === 'NOSAVINGS' && rol !== 'realestate') {
			return t('onboarding.cantBuyWithoutSavings');
		} else if (result.viability.excepcionalViability === 'DTI_OVER' && rol === 'realestate') {
			return t('onboarding.cantBuyWithoutSavingsInmo');
		} else if (result.viability.excepcionalViability === 'DTI_OVER' && rol !== 'realestate') {
			return t('calculator.warnig3');
		} else if (result.viability.excepcionalViability === 'INCOME_LEVEL' && rol === 'realestate') {
			return t('onboarding.cantBuyWithoutSavingsInmo');
		} else if (result.viability.excepcionalViability === 'INCOME_LEVEL' && rol !== 'realestate') {
			return t('calculator.warnig5');
		} else {
			if (result.viability.excepcionalViability === 'MORTGAGE_AMOUNT') {
				return t('calculator.mortgageAmountTooLowTitle');
			}
		}
	};

	const renderDesc = () => {
		if (result.viability.excepcionalViability === 'NOSAVINGS' && rol === 'realestate') {
			return t('onboarding.cantBuyWithoutSavingsDescriptionInmo');
		} else if (result.viability.excepcionalViability === 'NOSAVINGS' && rol !== 'realestate') {
			return t('onboarding.cantBuyWithoutSavingsDescription');
		} else if (result.viability.excepcionalViability === 'DTI_OVER' && rol === 'realestate') {
			return t('calculator.warnig4Inmo');
		} else if (result.viability.excepcionalViability === 'DTI_OVER' && rol !== 'realestate') {
			return t('calculator.warnig4');
		} else if (result.viability.excepcionalViability === 'INCOME_LEVEL' && rol === 'realestate') {
			return t('calculator.warnig6Inmo');
		} else if (result.viability.excepcionalViability === 'INCOME_LEVEL' && rol !== 'realestate') {
			return t('calculator.warnig6');
		} else if (
			result.viability.excepcionalViability === 'MORTGAGE_AMOUNT' &&
			rol === 'realestate'
		) {
			return t('calculator.mortgageAmountTooLowDescription');
		} else if (
			result.viability.excepcionalViability === 'MORTGAGE_AMOUNT' &&
			rol !== 'realestate'
		) {
			return t('calculator.mortgageAmountTooLowDescription');
		}
	};

	const renderSliderFix = () => {
		let varMasEur = variableTmp + euriborTmp;

		let total = varMasEur <= fixedTmp ? varMasEur : fixedTmp;
		return total;
	};

	const renderBarProgress = () => {
		



		return (
			<>
				<div
					style={{
						width: 300,
						display: 'flex',
						marginTop: 24,
						marginBottom: 10
					}}>
					<div
						style={{
							width: '85%' //`${percentOne}%`,
						}}></div>
					<Text
						style={{
							color: '#748EA0',
							fontSize: 12,
							fontWeight: 400,
							width: '50%',
							marginLeft:
								result?.bonilla[2].maxBudget === result?.bonilla[1].maxBudget ? -50 : undefined
						}}>
						{numeral(result?.bonilla[1].maxBudget).format('0,0 $')}
					</Text>
					<div
						style={{
							width: '25%' //`${percentSecond}%`,
						}}></div>
					{result?.bonilla[2].maxBudget !== result?.bonilla[1].maxBudget ? (
						<Text
							style={{
								color: '#748EA0',
								fontSize: 12,
								fontWeight: 400,
								width: '50%'
							}}>
							{numeral(result?.bonilla[2].maxBudget).format('0,0 $')}
						</Text>
					) : undefined}
					<div
						style={{
							width: '25%' //`${total}%`,
						}}></div>
				</div>

				<div style={{ width: 300, display: 'flex' }}>
					<div
						style={{
							width: '50%', //`${percentOne}%`,
							background: '#95DE64',
							height: '5px',
							borderRadius: '8px 0px 0px 8px'
						}}></div>
					<Text style={{ color: '#748EA0', marginTop: -10, marginRight: -1 }}>|</Text>

					<div
						style={{
							width: '25%', //`${percentSecond}%`,
							background:
								result?.bonilla[2].maxBudget === result?.bonilla[1].maxBudget
									? '#FF4D4F'
									: '#FAAD14',
							height: '5px'
						}}></div>
					{result?.bonilla[2].maxBudget !== result?.bonilla[1].maxBudget ? (
						<Text style={{ color: '#748EA0', marginTop: -10, marginRight: -1 }}>|</Text>
					) : undefined}
					<div
						style={{
							width: '25%', //`${total}%`,
							background: '#FF4D4F',
							height: '5px',
							borderRadius: '0px 8px 8px 0px'
						}}></div>
				</div>
			</>
		);
	};

	const renderResult = () => {
		let value = undefined;
		if (price > 10000) {
			value = price.toFixed('0');
		} else {
			value = numeral(_.get(result, 'bonilla[1].maxBudget', 0)).format('0,0');
		}

		return value;
	};

	const familyNumber = (e) => {
		setFamily(e);
		onChangeForm(undefined, undefined, price, e);
	};

	const renderMax = () => {
		if (result) {
			if (result.simulation) {
				const maxAge2 = result.simulation.simulationData.owner2
					? result.simulation.simulationData.owner2.age
					: 0;
				const maxAge1 = result.simulation.simulationData.owner1
					? result.simulation.simulationData.owner1.age
					: 0;

				const tmpAge = maxAge2 >= maxAge1 ? maxAge2 : maxAge1;

				const tmpAgeMin = 75 - tmpAge;

				const ageValue = tmpAgeMin <= 40 ? tmpAgeMin : 40;
				if (ageValue === 0) {
					return 5;
				}
				return ageValue;
			}
		}
	};

	return (<>
        {isMobileOnly && <CalculatorsQualifyClientMobile></CalculatorsQualifyClientMobile>}
        {!isMobileOnly && (
            <div style={{ padding: 40 }}>
                <Row style={{ width: '100%' }}>
                    <Col span={24}>
                        <a onClick={() => navigate('/sales-tools/new-requests')}>
                            <ArrowLeftOutlined style={{ color: '#2F4858', fontSize: 20 }} />
                        </a>
                        <Text
                            style={{
                                color: '#2F4858',
                                fontSize: 16,
                                fontWeight: 600,
                                marginLeft: 16
                            }}>
                            {rol === 'realestate' ? t('calculator.cardTitle3') : t('fee.calculator')}
                        </Text>
                    </Col>
                </Row>

                <Card className="gb-card" style={{ marginTop: 24, marginBottom: 100 }}>
                    <Row gutter={[24, 24]}>
                        <Col span={10}>
                            <Card
                                className="gb-card-24px"
                                cover={<img src={calculatorQuality} alt="asdf"></img>}
                                style={{
                                    border: '1px solid #C0F0F3',
                                    borderRadius: '0px 0px 4px 4px',
                                    background: '#EFFCFC'
                                }}>
                                <Skeleton active loading={loading} paragraph={false} />

                                {!loading && (
                                    <Text
                                        style={{
                                            color: '#02C3CD',
                                            fontSize: 16,
                                            fontWeight: 600
                                        }}>
                                        {t('fee.calculatorTop')}
                                    </Text>
                                )}
                                {!loading && (
                                    <div>
                                        <Form
                                            className="gb-form-profile-headlines-mobile"
                                            scrollToFirstError={true}
                                            form={form}
                                            name="basic"
                                            onValuesChange={(values, allValues) => onChangeForm(values, allValues)}
                                            initialValues={{
                                                province: dataProfile && dataProfile.province
                                            }}>
                                            <Row
                                                gutter={24}
                                                style={{
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto',
                                                    marginTop: 16
                                                }}>
                                                <Row style={{ width: '100%' }}>
                                                    <Col span={12} style={{ marginTop: 6 }}>
                                                        <Text
                                                            style={{
                                                                color: '#2F4858',
                                                                fontSize: 14,
                                                                fontWeight: 400
                                                            }}>
                                                            {t('form.Savings')}
                                                        </Text>
                                                    </Col>
                                                    <Col span={12} style={{ textAlign: 'right' }}>
                                                        <Form.Item name="savings">
                                                            <InputNumber
                                                                addonAfter={'€'}
                                                                placeholder={0}
                                                                step={100}
                                                                min={0}
                                                                style={{
                                                                    height: 40,
                                                                    width: '100%'
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row style={{ width: '100%' }}>
                                                    <Col span={12} style={{ marginTop: 6 }}>
                                                        <Text
                                                            style={{
                                                                color: '#2F4858',
                                                                fontSize: 14,
                                                                fontWeight: 400
                                                            }}>
                                                            {t('viability.provinceViability')}
                                                        </Text>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item name="province">
                                                            <Select
                                                                placeholder={t('form.selected')}
                                                                style={{ width: '100%' }}
                                                                showSearch>
                                                                {renderComponent('province').options.map((province) => {
                                                                    return (
                                                                        <Option key={province.value} value={province.value}>
                                                                            {province.label}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row style={{ width: '100%' }}>
                                                    <Col span={12} style={{ marginTop: 6 }}>
                                                        <Text
                                                            style={{
                                                                color: '#2F4858',
                                                                fontSize: 14,
                                                                fontWeight: 400
                                                            }}>
                                                            {t('form.propertyType')}
                                                        </Text>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            name="propertyType"
                                                            rules={[
                                                                {
                                                                    required: true
                                                                }
                                                            ]}>
                                                            <Select
                                                                placeholder={t('form.propertyType')}
                                                                style={{ width: '100%' }}>
                                                                <Option value="newConstruction">{t('form.newBuild')}</Option>
                                                                <Option value="secondHand">{t('form.secondHand')}</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row style={{ width: '100%' }}>
                                                    <Col span={12}>
                                                        <Text
                                                            style={{
                                                                color: '#2F4858',
                                                                fontSize: 14,
                                                                fontWeight: 400
                                                            }}>
                                                            {t('viability.expectedUse')}
                                                        </Text>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item name="houseType">
                                                            <Select
                                                                onChange={(e) => onChangeHouseType(e)}
                                                                placeholder={t('loan.sincronizationChosse')}
                                                                style={{ width: '100%', height: 40 }}>
                                                                <Option value="main">{t('form.main')}</Option>
                                                                <Option value="second">{t('form.second')}</Option>
                                                                <Option value="investor">{t('form.investor')}</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Row>

                                            <div
                                                style={{
                                                    background: '#FFFFFF',
                                                    borderRadius: 4,
                                                    paddingLeft: 16,
                                                    paddingTop: 16,
                                                    paddingRight: 16
                                                }}>
                                                <CalculatorDataClientQualify
                                                    setResult={setResult}
                                                    valuesForm={form.getFieldsValue()}
                                                    price={price}
                                                    savings={savings}
                                                    province={province}
                                                    family={family}
                                                    fixed={fixed}
                                                    quotes={quotes}
                                                    houseType={houseType}
                                                    result={result}
                                                    setPrice={setPrice}
                                                    priceTouch={priceTouch}></CalculatorDataClientQualify>
                                            </div>
                                        </Form>
                                    </div>
                                )}
                            </Card>
                        </Col>
                        <Col span={14} style={{ textAlign: 'center' }}>
                            <Skeleton active loading={loading} paragraph={false} />
                            {!loading && (
                                <>
                                    <div>
                                        <Text
                                            style={{
                                                color: '#2F4858',
                                                fontWeight: 600,
                                                fontSize: 20
                                            }}>
                                            {t('qualify.titlte')}
                                        </Text>
                                    </div>

                                    {result &&
                                        result?.mortgageCeiling &&
                                        result?.mortgageCeiling.mortgageAmountLimit === 'savings' && (
                                            <Card className="gb-card-background-blue" style={{ marginTop: 24 }}>
                                                <div>
                                                    <EuroCircleOutlined style={{ color: '#02C3CD', fontSize: 32 }} />
                                                </div>
                                                <div style={{ marginTop: 10 }}>
                                                    <Text
                                                        style={{
                                                            color: '#2F4858',
                                                            fontSize: 16,
                                                            fontWeight: 600
                                                        }}>
                                                        {t('qualify.warnignLimit')}
                                                    </Text>
                                                </div>
                                                <div style={{ marginTop: 8 }}>
                                                    <Text
                                                        style={{
                                                            color: '#748EA0',
                                                            fontSize: 16,
                                                            fontWeight: 400
                                                        }}>
                                                        {t('qualify.warnignLimit2')}
                                                    </Text>
                                                </div>
                                            </Card>
                                        )}

                                    {result &&
                                        result?.mortgageCeiling &&
                                        result?.mortgageCeiling?.mortgageAmountLimit !== 'savings' && (
                                            <Card className="gb-card-background-blue" style={{ marginTop: 24 }}>
                                                <div>
                                                    <EuroCircleOutlined style={{ color: '#02C3CD', fontSize: 32 }} />
                                                </div>
                                                <div style={{ marginTop: 10 }}>
                                                    <Text
                                                        style={{
                                                            color: '#2F4858',
                                                            fontSize: 16,
                                                            fontWeight: 600
                                                        }}>
                                                        {t('qualify.warnignLimit3')}
                                                    </Text>
                                                </div>
                                                <div style={{ marginTop: 8 }}>
                                                    <Text
                                                        style={{
                                                            color: '#748EA0',
                                                            fontSize: 16,
                                                            fontWeight: 400
                                                        }}>
                                                        {t('qualify.warnignLimit4')}
                                                    </Text>
                                                </div>
                                            </Card>
                                        )}

                                    <div style={{ marginTop: 40 }}>
                                        <Text
                                            style={{
                                                color: '#2F4858',
                                                fontWeight: 600,
                                                fontSize: 16
                                            }}>
                                            {t('qualify.titlte2')}
                                        </Text>
                                    </div>
                                    <div style={{ marginTop: 24, textAlign: '-webkit-center' }}>
                                        {result && result?.bonilla && result?.bonilla && renderBarProgress()}
                                    </div>

                                    <div
                                        style={{
                                            marginTop: 30,
                                            justifyContent: 'center',
                                            display: 'grid'
                                        }}>
                                        <div style={{ display: 'flex' }}>
                                            <Text
                                                style={{
                                                    color: '#52C41A',
                                                    fontSize: 16,
                                                    fontWeight: 600
                                                }}>
                                                {result && result?.bonilla !== undefined
                                                    ? t('qualify.until') +
                                                      ' ' +
                                                      numeral(result?.bonilla[1].maxBudget).format('0,0 $')
                                                    : t('qualify.until') + ' ' + '-€'}
                                            </Text>
                                            <Text
                                                style={{
                                                    color: '#748EA0',
                                                    fontSize: 16,
                                                    fontWeight: 400,
                                                    marginLeft: 16
                                                }}>
                                                {t('qualify.bankNoProblem')}
                                            </Text>
                                        </div>

                                        <div style={{ display: 'flex' }}>
                                            <Text
                                                style={{
                                                    color: '#FAAD14',
                                                    fontSize: 16,
                                                    fontWeight: 600
                                                }}>
                                                {result && result?.bonilla !== undefined
                                                    ? t('qualify.until') +
                                                      ' ' +
                                                      numeral(result?.bonilla[2].maxBudget).format('0,0 $')
                                                    : t('qualify.until') + ' ' + '-€'}
                                            </Text>
                                            <Text
                                                style={{
                                                    color: '#748EA0',
                                                    fontSize: 16,
                                                    fontWeight: 400,
                                                    marginLeft: 16
                                                }}>
                                                {t('qualify.bankProblem')}
                                            </Text>
                                        </div>
                                    </div>
                                </>
                            )}

                            {!loading && (
                                <Row style={{ width: '100%', marginTop: 24 }}>
                                    <Col span={12} style={{ marginTop: 10 }}>
                                        <Text
                                            style={{
                                                color: '#2F4858',
                                                fontSize: 14,
                                                fontWeight: 400,
                                                marginLeft: 50
                                            }}>
                                            {t('qualify.price')}
                                        </Text>
                                    </Col>
                                    <Col span={12} style={{ textAlign: 'left' }}>
                                        <InputNumber
                                            //value={result && renderResult()}
                                            onChange={(value) => onChangePrice(value)}
                                            min={10000}
                                            placeholder={renderResult()}
                                            addonAfter={'€'}
                                            style={{
                                                width: 160,
                                                border: '1px solid #02C3CD',
                                                borderRadius: 4
                                            }}
                                        />
                                    </Col>
                                </Row>
                            )}

                            {!loading && (
                                <Row style={{ width: '100%', marginTop: 32 }}>
                                    <Col span={12} style={{ marginTop: 16 }}>
                                        <Text
                                            style={{
                                                color: '#2F4858',
                                                fontSize: 14,
                                                fontWeight: 400
                                            }}>
                                            {t('loan.rate')}
                                        </Text>
                                        <InfoCircleOutlined
                                            onClick={() => setRate(true)}
                                            style={{ color: '#02C3CD', marginLeft: 8 }}
                                        />

                                        {euribor && (
                                            <div style={{ marginTop: 8, marginLeft: 58 }}>
                                                <Text
                                                    style={{
                                                        color: '#016B71',
                                                        fontSize: 12,
                                                        fontWeight: 400
                                                    }}>
                                                    {t('fee.calculatorEuribor') +
                                                        ' ' +
                                                        euribor[euribor.length - 1].index.toFixed(2) +
                                                        '%'}
                                                </Text>
                                            </div>
                                        )}
                                    </Col>
                                    <Col
                                        span={12}
                                        style={{
                                            textAlign: 'right',
                                            marginTop: 12,
                                            display: 'flex'
                                        }}>
                                        {fixedTmp && variableTmp && euriborTmp && (
                                            <>
                                                <Slider
                                                    onChange={(value) => onChangeFixed(value)}
                                                    step={0.01}
                                                    defaultValue={renderSliderFix()}
                                                    min={0}
                                                    max={10}
                                                    style={{ width: 250 }}
                                                />
                                                <Text
                                                    style={{
                                                        color: '#016B71',
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        marginLeft: 10
                                                    }}>
                                                    {fixed !== 1.9 ? fixed + '%' : renderSliderFix() + '%'}
                                                </Text>
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            )}

                            <Skeleton active loading={loading} paragraph={false} />

                            {!loading && (
                                <Row style={{ width: '100%', marginTop: 32 }}>
                                    <Col span={12} style={{ marginTop: 0 }}>
                                        <Text
                                            style={{
                                                color: '#2F4858',
                                                fontSize: 14,
                                                fontWeight: 400
                                            }}>
                                            {t('loan.term')}
                                        </Text>
                                        <InfoCircleOutlined
                                            onClick={() => setTerm(true)}
                                            style={{ color: '#02C3CD', marginLeft: 8 }}
                                        />
                                    </Col>
                                    <Col span={12} style={{ textAlign: 'right', display: 'flex' }}>
                                        <Slider
                                            onChange={(value) => onChangeTerm(value)}
                                            defaultValue={30}
                                            min={5}
                                            max={renderMax()}
                                            style={{ width: 250 }}
                                            tooltip={{
                                                formatter: (value) => `${value} ${t('form.years')}`
                                            }}
                                        />
                                        <Text
                                            style={{
                                                color: '#016B71',
                                                fontSize: 12,
                                                fontWeight: 400,
                                                marginLeft: 10
                                            }}>
                                            {quotes !== 360
                                                ? quotes / 12 + ' ' + t('form.years')
                                                : result !== undefined
                                                ? renderMax() + ' ' + t('form.years')
                                                : quotes / 12 + ' ' + t('form.years')}
                                        </Text>
                                    </Col>
                                </Row>
                            )}
                            <Row style={{ width: '100%' }}>
                                <Col span={24} style={{ marginTop: 0, textAlign: '-webkit-center' }}>
                                    <Card
                                        style={{
                                            borderRadius: 8,
                                            marginTop: 40,
                                            background: '#EFFCFC',
                                            boxShadow: '0px 0px 8px rgba(47, 72, 88, 0.1)',
                                            width: 600
                                        }}>
                                        <Skeleton active loading={loading} paragraph={false} />

                                        {!loading && (
                                            <>
                                                <Row>
                                                    <Col span={12} style={{ textAlign: 'left' }}>
                                                        <Text
                                                            style={{
                                                                color: '#748EA0',
                                                                fontWeight: 400,
                                                                fontSize: 16
                                                            }}>
                                                            {t('qualify.priceMortgage')}
                                                        </Text>
                                                    </Col>
                                                    <Col span={12} style={{ textAlign: 'right' }}>
                                                        <Text
                                                            style={{
                                                                color: '#2F4858',
                                                                fontWeight: 600,
                                                                fontSize: 16
                                                            }}>
                                                            {result && result.quote
                                                                ? numeral(
                                                                        result.quote.operation.mortgageAmount < 0
                                                                            ? 0
                                                                            : result.quote.operation.mortgageAmount
                                                                  ).format('0,0 $')
                                                                : '- €'}
                                                        </Text>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col span={12} style={{ textAlign: 'left' }}>
                                                        <Text
                                                            style={{
                                                                color: '#748EA0',
                                                                fontWeight: 400,
                                                                fontSize: 16
                                                            }}>
                                                            {t('qualify.quoteMortgage')}
                                                        </Text>
                                                    </Col>
                                                    <Col span={12} style={{ textAlign: 'right' }}>
                                                        <Text
                                                            style={{
                                                                color: '#02C3CD',
                                                                fontWeight: 600,
                                                                fontSize: 20
                                                            }}>
                                                            {result && result.quote
                                                                ? numeral(
                                                                        result.quote.operation.types.fixed.quote < 0
                                                                            ? 0
                                                                            : result.quote.operation.types.fixed.quote
                                                                  ).format('0,0 $')
                                                                : '- €'}
                                                        </Text>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    </Card>
                                </Col>
                            </Row>

                            {/**warnign mas casos TODO */}
                            {result &&
                                result.viability &&
                                result.viability.excepcionalViability !== undefined && (
                                    <Card
                                        className="card-second"
                                        style={{
                                            border: '1px solid #02C3CD',
                                            borderRadius: 4,
                                            marginTop: 40
                                        }}>
                                        <div style={{ textAlign: 'center' }}>
                                            <WarningOutlined style={{ color: '#02C3CD', fontSize: 40 }} />
                                        </div>

                                        <div style={{ textAlign: 'center', marginTop: 8 }}>
                                            <Text
                                                style={{
                                                    color: '#2F4858',
                                                    fontWeight: 500,
                                                    fontSize: 14
                                                }}>
                                                {result && result.viability && renderTitle()}
                                            </Text>
                                        </div>
                                        <div style={{ textAlign: 'center', marginTop: 8 }}>
                                            <Text
                                                style={{
                                                    color: '#748EA0',
                                                    fontWeight: 400,
                                                    fontSize: 14
                                                }}>
                                                {result && result.viability && renderDesc()}
                                            </Text>
                                        </div>
                                    </Card>
                                )}

                            {/*family === false && (
              <div style={{ marginTop: 24 }}>
                <Text
                  style={{
                    color: "#748EA0",
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                >
                  {t("qualify.clickToFamily")}
                </Text>
                <Text
                  style={{
                    color: "#02C3CD",
                    fontSize: 16,
                    fontWeight: 400,
                    cursor: "pointer",
                  }}
                  onClick={() => setVisibleFamily(true)}
                >
                  {t("typeMortgage.text2TooltipMortgage") + " "}
                </Text>
                <Text
                  style={{
                    color: "#748EA0",
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                >
                  {t("qualify.clickToFamily2")}
                </Text>
              </div>
                )*/}
                            {/*family && (
              <div style={{ marginTop: 24 }}>
                <Text
                  style={{
                    color: "#748EA0",
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                >
                  {t("qualify.numberFamilyText") + " "}
                </Text>
                <Text
                  style={{
                    color: "#02C3CD",
                    fontSize: 16,
                    fontWeight: 400,
                    cursor: "pointer",
                  }}
                  onClick={() => setVisibleFamily(true)}
                >
                  {t("typeMortgage.text2TooltipMortgage") + " "}
                </Text>
                <Text
                  style={{
                    color: "#748EA0",
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                >
                  {t("qualify.numberFamilyText2")}
                </Text>
              </div>
                )*/}

                            {result && result.viability && (
                                <Button
                                    id="gb-mortgage-buy-situation-mobile"
                                    className="button-primary-gibobs-inmo"
                                    type="primary"
                                    style={{
                                        fontSize: 14,
                                        height: 40,
                                        fontWeight: 600,
                                        marginTop: 40
                                    }}
                                    onClick={() => {
                                        if (
                                            rol === 'realestate' &&
                                            result !== undefined &&
                                            result.viability.excepcionalViability === undefined
                                        ) {
                                            setFinish(true);
                                        }
                                    }}
                                    disabled={
                                        result &&
                                        result.viability &&
                                        result.viability.excepcionalViability !== undefined
                                            ? true
                                            : false
                                    }>
                                    {rol === 'realestate'
                                        ? t('calculator.clientInmoButton')
                                        : t('calculator.bankViabilitButton')}
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Card>
            </div>
        )}
        <Modal
            className="ModalCommon"
            okButtonProps={{ style: { width: '46%', height: 40, fontSize: 14 } }}
            open={visibleFamily}
            title={<Text>{t('qualify.numberFamily')}</Text>}
            width={480}
            cancelButtonProps={{
                style: { width: '46%', height: 40, fontSize: 14, color: '#02C3CD' }
            }}
            closable={true}
            onCancel={() => setVisibleFamily(false)}
            onOk={() => setVisibleFamily(false)}
            okText={t('form.save')}>
            <div style={{ marginTop: -32 }}>
                <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                    {t('certificated.selectOptionDownload')}
                </Text>

                <Select
                    style={{ width: '100%', marginTop: 16, height: 40 }}
                    onChange={(e) => familyNumber(e)}>
                    <Option value={true}>{t('form.yes')}</Option>
                    <Option value={false}>{t('form.no')}</Option>
                </Select>
            </div>
        </Modal>
        <Modal
            className="ModalMobileFooterColum"
            okButtonProps={{ style: { display: 'none' } }}
            open={term}
            title={<Text>{t('scoring.term')}</Text>}
            width={480}
            height={126}
            cancelButtonProps={{ style: { display: 'none' } }}
            closable={true}
            onCancel={() => setTerm(false)}>
            <div style={{ marginBottom: -70 }}>
                <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                    {t('calculator.modal2')}
                </Text>
            </div>
        </Modal>
        <Modal
            className="ModalMobileFooterColum"
            okButtonProps={{ style: { display: 'none' } }}
            open={rate}
            title={<Text>{t('loan.rateCurrent')}</Text>}
            width={480}
            height={136}
            cancelButtonProps={{ style: { display: 'none' } }}
            closable={true}
            onCancel={() => setRate(false)}>
            <div style={{ marginBottom: -24 }}>
                <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                    {t('calculator.modal1')}
                </Text>
            </div>
        </Modal>
        <Modal
            className="modal-promoter ModalCommon"
            okButtonProps={{
                className: 'button-primari-gibobs',
                style: { width: '94%' }
            }}
            open={finish}
            width={480}
            title={<img src={calculatorImage} alt="financing" width={'100%'} height="90px" />}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={() => setFinish(false)}
            onOk={() => sendFinish()}>
            <div style={{ marginBottom: 0, marginTop: 30 }}>
                <Text strong>{t('modal6.titleGood') + ' ' + dataProfile.name}</Text>
            </div>
            <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                {t('calculator.modalFinish')}
            </Text>
        </Modal>
    </>);
};

export default CalculatorsQualifyClient;
