import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Layout, Col } from "antd";
import { useTranslation } from "react-i18next";
import OperationPage from "../Operaciones/OperationsPage";
import MyBreadcrumb from "../MyBreadcrumb";
import { isMobile } from "react-device-detect";
import axios from "axios";
import env from "../../environment";
import { useSelector } from "react-redux";

const { Content } = Layout;

const DetailOperationPage = ({ }) => {
  const { t } = useTranslation();
  const { operationId } = useParams();
  const userToken = useSelector((state) => state.auth.token);
  const [, setLoading] = useState(true);
  const rol = useSelector((state) => state.auth.roles);

  //let data = undefined

  const [data, setData] = useState([]);
  const [statusOperation, setStatusOperation] = useState();
  const [snooze, setSnooze] = useState(null);

  useEffect(() => {
    getMortgageData();
  }, []);
  useEffect(() => {
    if (statusOperation === "postpone") {
      handleSnoozeApi();
    }
  }, [statusOperation]);

  const getMortgageData = async () => {
    setLoading(true);

    const response = await axios.get(
      `${env.api.url}/v1/operations/${operationId}`,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );

    setData([
      {
        name: t("menuComponent.salesTools"),
        link: "/sales-tools/new-requests",
      },
      {
        name: t("menuComponent.followUp"),
        link: "/sales-tools/follow-up",
      },
      {
        name: response.data.data.name,
        link: "/sales-tools/follow-up/detail-operation",
      },
    ]);

    setStatusOperation(response.data.data.operationStatus);

    setLoading(false);
  };

  const handleSnoozeApi = async () => {
    await axios
      .get(`${env.api.url}/v1/mortgages/reminder/${operationId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setSnooze(response.data.data[0]);
      });
  };

  return (
    <Layout style={{ marginBottom: isMobile ? "20px" : 100 }}>
      {/* {loading && (
        <div
          style={{
            textAlign: "center",
            height: "100vh",
            backgroundColor: "white",
            marginTop: 300,
          }}
        >
          <Spin indicator={antIcon} className="spin" />
        </div>
      )} */}
      {/* {!loading && ( */}
      <>
        {rol !== "realestate" && <MyBreadcrumb data={data}></MyBreadcrumb>}

        <Content
          style={{
            margin: rol === "realestate" ? "20px 0" : 20,
            alignContent: "center",
          }}
        >
          <Col flex="1">
            <OperationPage idIMMO={operationId} snooze={snooze}></OperationPage>
          </Col>
        </Content>
      </>
      {/* )} */}
    </Layout>
  );
};

export default DetailOperationPage;
