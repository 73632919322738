import React from "react";
import { Layout, Row, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";

const LayoutPromoter = ({ setModalDescription, name, stages }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const styleTextTab = {
    fontSize: 14,
    fontWeight: 600,
    color: "white",
    backgroundColor: "#02C3CD",
    borderRadius: "4px",
    border: "none",
    height: 30,
    textAlign: "center",
  };

  return (
    <div
      style={{
        margin: "40px 40px 24px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Layout>
        <Row gutter={[24, 24]} style={{ marginLeft: 0, marginRight: 0 }}>
          <div
            className="newSubmenusTabs"
            style={{ height: 48, padding: 8, margin: 0 }}
          >
            <div
              style={{
                background: "#EFFCFC",
                borderRadius: 5,
                borderBottom: "none",
                borderBottomColor: "white",
              }}
              selectedKeys={[`${location.pathname}${location.search}`]}
              mode="horizontal"
              className="menu-tools"
            >
              {/* <NavLink
                className="buttonOperations"
                to="/promoter-loan/analysis"
              > */}
              <Button
                className="buttonOperations"
                style={
                  location.pathname.startsWith("/promoter-loan/analysis/")
                    ? styleTextTab
                    : undefined
                }
              >
                {t("promoterLoan.summary")}
              </Button>
              {/* </NavLink> */}

              {/* <NavLink to="/promoter-loan/documentation">
                <Button
                  className="buttonOperations"
                  style={
                    location.pathname === "/promoter-loan/documentation"
                      ? styleTextTab
                      : undefined
                  }
                >
                  {t("stages.documents")}
                </Button>
              </NavLink> */}
            </div>
          </div>
        </Row>
      </Layout>
      <div
        style={{
          background: "#F1F7F8",
          borderRadius: 4,
          display: "flex",
          padding: "14px 24px",
        }}
      >
        <div
          style={{
            color: "#2F4858",
            fontSize: 14,
            fontWeight: 600,
            marginRight: 75,
          }}
        >
          {name}
        </div>
        <div
          style={{
            color: "#2F4858",
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          {t("b2bOperationList.status")}: {stages}
          <InfoCircleOutlined
            style={{ color: "#02C3CD", marginLeft: 10 }}
            onClick={() => setModalDescription(true)}
          />
        </div>
      </div>
    </div>
  );
};

export default LayoutPromoter;
