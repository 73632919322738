import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import './App.less';
import i18n from './i18n';
import { Provider } from 'react-redux';
import { store } from './store';
import numeral from 'numeral';
import { ConfigProvider, theme } from 'antd';
/*import es from 'antd/es/locale/es_ES';
import cat from 'antd/es/locale/ca_ES';
import en from 'antd/es/locale/en_GB';*/
import es_ES from 'antd/lib/locale/es_ES';
import ca_ES from 'antd/lib/locale/ca_ES';
import en_GB from 'antd/lib/locale/en_GB';
import 'moment/locale/es';
import 'moment/locale/ca';
import 'moment/locale/en-gb';
import './Firebase';
import { AnalyticsProvider } from 'use-analytics';
import Analytics from 'analytics';
import mixpanelPlugin from '@analytics/mixpanel';
import amplitudePlugin from '@analytics/amplitude';
import { BrowserRouter } from 'react-router-dom';
//import * as Sentry from '@sentry/react';
//import { env } from './environment';

let locale = es_ES;
const locales = {
	es: es_ES,
	cat: ca_ES,
	en: en_GB
};

i18n.on('languageChanged', function (lang) {
	locale = locales[lang];
});

numeral.register('locale', 'es', {
	delimiters: {
		thousands: '.',
		decimal: ','
	},
	abbreviations: {
		thousand: 'k',
		million: 'm',
		billion: 'b',
		trillion: 't'
	},
	ordinalIgnore: function (number) {
		return number === 1 ? 'er' : 'ème';
	},
	currency: {
		symbol: '€'
	}
});

numeral.locale('es');

const analytics = Analytics({
	app: 'gibobs-app',
	plugins: [
		amplitudePlugin({
			apiKey: '4755261e84f8f3b48362c6f998329342',
			options: {
				trackingOptions: {
					ip_address: true
				}
			}
		}),
		mixpanelPlugin({
			token: '94455f12961ba9e9c43f6b22cdf591bd'
		})
	]
});

/* Sentry.init({
	dsn: 'https://7a0e3a6e45274d5795a503fe821668af@o440063.ingest.sentry.io/4505239171497984',
	integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
	// Performance Monitoring
	tracesSampleRate: env === 'production' ? 0.1 : 1.0, // Capture 100% of the transactions, reduce in production!
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
}); */

ReactDOM.render(
	<React.StrictMode>
		<ConfigProvider 
			locale={locale}
			theme={{
				algorithm: theme.defaultAlgorithm,
				token: { colorPrimary: '#02C3CD', colorBgLayout: '#FAFEFE' }
			}}
		>
			<AnalyticsProvider instance={analytics}>
				<Provider store={store}>
					<Suspense fallback="loading">
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</Suspense>
				</Provider>
			</AnalyticsProvider>
		</ConfigProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
