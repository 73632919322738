import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import {
    useNavigate,
    useParams,
} from "react-router-dom";
import {
    Layout,
    Card,
    Typography,
    Col,
    Row,
    Switch,
} from "antd";
import axios from "axios";
import env from "../../../../environment";
import { useSelector } from "react-redux";
import moment from "moment";
import { ArrowLeftOutlined } from "@ant-design/icons";


const { Text } = Typography;

const LoanPageDetailsConfiguration = ({  }) => {

    const { t } = useTranslation()
    const navigate = useNavigate();;
    const userToken = useSelector((state) => state.auth.token);
    let { loanId } = useParams();
    const [loan, setLoan] = useState(undefined);
    const [onOff, setOnOff] = useState(true);





    useEffect(() => {
        getLoan()
    }, [])



    const getLoan = () => {
        axios
            .get(`${env.api.url}/v1/loans/get-loan/${loanId}`, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            .then((response) => {
                // setDataValues(response.data.data);
                setLoan(response.data.data)


                // setDiffe(today.diff(dateSigned, "days"));
            })
            .catch((error) => { 
                console.log(error)
            });

    };




    const backToPage = () => {
        navigate(`/my-financial-profile/credits/all-detail-loan/${loanId}`)
    }

    const onChangeCron = () => {
        setOnOff(!onOff)
    }

    return (
        <Layout style={{ padding: 16, height: "150vh" }}>

            {loan &&
                <>
                    <div style={{ display: "flex", width: "100%" }}>
                        <a onClick={() => backToPage()}><ArrowLeftOutlined style={{ color: "#2F4858", fontSize: 20 }} /></a>
                        <Text style={{ color: "#2F4858", fontSize: "16px", fontWeight: 600, position: "relative", left: 16, top: -3 }}>{t("m_profile.title")} </Text>

                    </div>

                    <Card className="gcardMovile" style={{ marginTop: 16 }}>
                        <Row>
                            <Col span={20} style={{ display: "flex", flexDirection: "column",marginTop: 24 }}>
                                <Text style={{ color: "#748EA0", fontSize: "12px", fontWeight: 400 }}>{t("loan.ultimateSinc")} </Text>
                                <Text style={{ color: "#2F4858", fontSize: "16px", fontWeight: 600 }}>{moment(
                                    loan.quote
                                        .nextDate
                                ).format("L")} </Text>

                            </Col>

                            <Col span={4} style={{ display: "flex", marginTop: 15 }}>
                                <Switch defaultChecked onChange={onChangeCron} size="small" />
                                {onOff &&
                                    <Text style={{ color: "#2F4858", fontSize: "14px", fontWeight: 400, marginLeft: 10, marginTop: -3 }}>{"ON"} </Text>
                                }
                                {!onOff &&
                                    <Text style={{ color: "#2F4858", fontSize: "14px", fontWeight: 400, marginLeft: 10, marginTop: -3 }}>{"OFF"} </Text>
                                }


                            </Col>
                        </Row>

                    </Card>

                    <Text style={{ color: "#2F4858", fontSize: "12px", fontWeight: 600, marginTop: 24 }}>{t("loan.entityBank").toLocaleUpperCase()} </Text>


                    <Card className="gcardMovile" style={{ marginTop: 16 }}>
                    {(loan.loan.bank !== undefined && loan.loan.bank !== null) &&

                        <div>
                            <img
                                src={
                                    "https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/banks/" +
                                    `${loan.loan.bank.bankCode}` +
                                    ".png"
                                }
                                alt="Oferta"
                                style={{ height: "32px" }}
                            />


                        </div>
                    }
                    </Card>


                </>
            }

        </Layout>
    );
};

export default LoanPageDetailsConfiguration;
