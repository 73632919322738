import {
	DATAFORMPROMOTER,
	DATASCENARIOS,
	DATANEWPROJECT,
	LOADING,
	REDIRECT,
	DATALISTMYPROYECTS,
	SCENARIOSDATA
} from './constants';
import axios from 'axios';
import env from '../../environment';
import { store } from '..';

export const getPromoterData = (data) => async (dispatch) => {
	dispatch({
		type: DATAFORMPROMOTER,
		payload: data
	});
};
export const getScenariosData = (data) => async (dispatch) => {
	dispatch({
		type: DATASCENARIOS,
		payload: data
	});
};
export const changeRedirect = (data) => async (dispatch) => {
	dispatch({
		type: REDIRECT,
		payload: data
	});
};
export const getListMyProyects = () => async (dispatch) => {
	axios
		.get(`${env.api.url}/promoters/promoter-projects/listMyProjects`, {
			headers: {
				Authorization: `Bearer ${store.getState().auth.token}`
			}
		})
		.then((response) => {
			const array = response.data.data.sort(function (a, b) {
				if (a.createdAt < b.createdAt) {
					return 1;
				}
				if (a.createdAt > b.createdAt) {
					return -1;
				}
				// a must be equal to b
				return 0;
			});
			dispatch({
				type: DATALISTMYPROYECTS,
				payload: array
			});
		});
};
export const getFinancingScenarios = (operationId) => async (dispatch) => {
	axios
		.get(`${env.api.url}/promoters/financing-scenarios/by-operation/${operationId}`, {
			headers: {
				Authorization: `Bearer ${store.getState().auth.token}`
			}
		})
		.then((response) => {
			dispatch({
				type: DATASCENARIOS,
				payload: response.data.data
			});
		});
};

export const getPdf = (number, operationId, setLoading) => async (dispatch) => {
	setLoading(true);
	axios({
		url: `${env.api.url}/v1/promoter/generate-pdf/${operationId}?scenario${number}=true&scenarioOwner=CLIENT`,
		method: 'GET',
		headers: { Authorization: `Bearer ${store.getState().auth.token}` },
		responseType: 'blob',
		onDownloadProgress: (progressEvent) => {
			var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
			if (percentCompleted === 100) {
				setLoading(false);
			}
		}
	}).then((response) => {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `PromotorGibobs-Escenario${number}.pdf`);
		document.body.appendChild(link);
		link.click();
	});
};

export const patchFavoriteScenario = (scenarioId, operationId) => async (dispatch) => {
	axios
		.patch(
			`${env.api.url}/promoters/financing-scenarios/patch/${scenarioId}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${store.getState().auth.token}`
				}
			}
		)
		.then((response) => {
			dispatch(getFinancingScenarios(operationId));
		});
};

export const createNewProject = () => async (dispatch) => {
	dispatch({
		type: LOADING,
		payload: true
	});

	axios
		.post(
			`${env.api.url}/v1/accounts/get-by-email`,
			{},
			{
				headers: { Authorization: `Bearer ${store.getState().auth.token}` }
			}
		)
		.then((response) => {
			if (response.data.success) {
				axios
					.post(
						`${env.api.url}/promoters/promoter-projects/new-project`,
						{
							accountId: response.data.data.account.id
						},
						{
							headers: {
								Authorization: `Bearer ${store.getState().auth.token}`
							}
						}
					)
					.then((response) => {
						dispatch({
							type: DATANEWPROJECT,
							payload: response.data.data.data
						});
						dispatch({
							type: DATAFORMPROMOTER,
							payload: response.data.data.data.operationDataClient
						});
						dispatch({
							type: REDIRECT,
							payload: true
						});
						dispatch({
							type: LOADING,
							payload: false
						});
						dispatch(getListMyProyects());
					});
			}
		});
};
