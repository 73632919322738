import { useEffect, useState } from 'react';
import { Form, Select, Col, Row, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { notificationGibobs } from '../../HerramientasVenta/reusableItems';
import HelpButtonMobile from './HelpButtonMobile';
import StepPromoterMobile from './StepPromoterMobile';
import { useDispatch } from 'react-redux';
import { getPromoterData } from '../../../store/promoter/actions';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { ReduxStore } from './welcomePageMobile';
import axios from 'axios';
import env from '../../../environment';
import { renderSelectOptions } from '../ProvincesAndMunicipe';
import { InputNumber, ProvinceSelector } from 'afrodita';
import LoaderLogo from '../../../components/LoaderLogo';
const DownOutlinedMobile = require('../../../assets/icons/DownOutlinedMobile.svg');

const { Option } = Select;

export const GeneralDataFormMobile = (form) => {
	const { t } = useTranslation();
	const dataPromoter = useSelector<ReduxStore, any>((state) => state.promoter.dataFormPromoter);
	const [valueWorkStarted, setValueWorkStarted] = useState('no');
	const [preSaleUnit, setPreSaleUnit] = useState(dataPromoter &&
		dataPromoter.generalData &&
		dataPromoter.generalData.preSalesUnits !== undefined
		? Number(Number(dataPromoter.generalData.preSalesUnits).toFixed(2))
		: 0);
	const [saleUnit, setSaleUnit] = useState(dataPromoter && dataPromoter.generalData && dataPromoter.generalData.salesUnits !== undefined
		? Number(dataPromoter.generalData.salesUnits)
		: 0);
	const dispatch = useDispatch();

	useEffect(() => {
		// if (saleUnit !== 0 && saleUnit !== '') {
		if (saleUnit !== 0) {
			form.setFieldsValue({
				percentageOfPreSales: Number((preSaleUnit * 100) / saleUnit).toFixed(2)
			});
		}
	}, [preSaleUnit, saleUnit]);

	

	return (
		<Row>
			<Col span={24}>
				<Form.Item
					name="buildability"
					label={t('promoterLoan.Buildability')}
					rules={[
						{
							required: true,
							validator: (e, value) =>
								value !== '0' && value !== '' && value !== undefined
									? Promise.resolve()
									: Promise.reject(
											new Error(t('promoterLoan.Buildability') + ' ' + t('form.formRequired'))
									  )
						}
					]}>
					<InputNumber
						style={{ borderRadius: 8, height: 40 }}
						gbType="m2"
						suffix="m²"
						placeholder="0.000"
						onChange={(e) => {
							const dat1 =
								Object.keys(dataPromoter).length !== 0 &&
								dataPromoter.generalData &&
								dataPromoter.generalData.buildability;
							Object.keys(dataPromoter).length !== 0 &&
								dispatch(
									getPromoterData({
										...dataPromoter,
										generalData: {
											...dataPromoter['generalData'],
											buildability: Number(e)
										}
									})
								);
						}}
					/>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					name="landInProperty"
					label={t('promoterLoan.solarProperty')}
					rules={[
						{
							required: true
						}
					]}>
					<Select
						suffixIcon={<img src={DownOutlinedMobile.default} alt="DownOutlinedMobile" />}
						defaultValue={t('form.select')}
						style={{ width: '100%' }}>
						<Option value={true}>{t('form.yes')}</Option>
						<Option value={false}>{t('form.no')}</Option>
					</Select>
				</Form.Item>
			</Col>
			<Col span={12} style={{ paddingRight: 4 }}>
				<Form.Item
					name="worksInitiated"
					label={t('promoterLoan.workStarted')}
					rules={[
						{
							required: true
						}
					]}>
					<Select
						suffixIcon={<img src={DownOutlinedMobile.default} alt="DownOutlinedMobile" />}
						defaultValue={t('form.select')}
						style={{ width: '100%' }}
						onChange={(e) => setValueWorkStarted(e)}>
						<Option value={true}>{t('form.yes')}</Option>
						<Option value={false}>{t('form.no')}</Option>
					</Select>
				</Form.Item>
			</Col>
			<Col span={12} style={{ paddingLeft: 4 }}>
				<Form.Item name="percentageOfWorkStarted" label={t('promoterLoan.percentageWorkStarted')}>
					<InputNumber
						style={{ borderRadius: 8, height: 40 }}
						gbType="number"
						suffix={'%'}
						disabled={valueWorkStarted ? false : true}
						placeholder={t('0')}
					/>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					name="buildingPermission"
					label={t('promoterLoan.buildingLicense')}
					rules={[
						{
							required: true
						}
					]}>
					<Select
						suffixIcon={<img src={DownOutlinedMobile.default} alt="DownOutlinedMobile" />}
						defaultValue={t('form.select')}
						style={{ width: '100%' }}>
						<Option value={true}>{t('promoterLoan.haveLicense')}</Option>
						<Option value={false}>{t('promoterLoan.DoNotHaveLicense')}</Option>
					</Select>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					name="salesUnits"
					label={t('promoterLoan.salesUnits')}
					rules={[
						{
							required: true
						}
					]}>
					<InputNumber
						style={{ borderRadius: 8, height: 40 }}
						gbType="number"
						suffix=""
						gbDecimalType="integer"
						placeholder={t('0')}
						onChange={(e) => {
							setSaleUnit(Number(e));
							dispatch(
								getPromoterData({
									...dataPromoter,
									generalData: {
										...dataPromoter['generalData'],
										salesUnits: Number(e)
									}
								})
							);
						}}
					/>
				</Form.Item>
			</Col>

			<Col span={12} style={{ paddingRight: 4 }}>
				<Form.Item
					name="preSalesUnits"
					label={t('promoterLoan.presalesUnits')}
					rules={[
						{
							required: true,
							message: t('promoterLoan.presalesUnits') + ' ' + t('form.formRequired')
						},
						{
							validator: (_, value) => {
								if (saleUnit === 0) {
									return value < dataPromoter.generalData.salesUnits + 1
										? Promise.resolve()
										: Promise.reject(
												new Error(
													t('promoterLoan.presalesUnits') +
														' ' +
														t('promoterLoan.cannotBeGreater') +
														saleUnit
												)
										  );
								}
								if (saleUnit !== 0) {
									return value < saleUnit + 1
										? Promise.resolve()
										: Promise.reject(
												new Error(
													t('promoterLoan.presalesUnits') +
														' ' +
														t('promoterLoan.cannotBeGreater') +
														saleUnit
												)
										  );
								}
							}
						}
					]}>
					<InputNumber
						style={{ borderRadius: 8, height: 40 }}
						gbType="number"
						suffix=""
						// defaultValue={''}
						gbDecimalType="integer"
						max={saleUnit}
						onChange={(e) => {
							setPreSaleUnit(Number(Number(e).toFixed(2)));
							dispatch(
								getPromoterData({
									...dataPromoter,
									generalData: {
										...dataPromoter['generalData'],
										preSalesUnits: Number(e)
									}
								})
							);
						}}
						placeholder={t('0')}
					/>
				</Form.Item>
			</Col>
			<Col span={12} style={{ paddingLeft: 4 }}>
				<Form.Item name="percentageOfPreSales" label={t('promoterLoan.presalePercentage')}>
					<InputNumber
						style={{ borderRadius: 8, height: 40 }}
						gbType="number"
						suffix={'%'}
						value={preSaleUnit}
						disabled={true}
						placeholder={t('0')}
					/>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					label={t('form.provinces')}
					name="province"
					rules={[
						{
							required: true
						}
					]}>
						<ProvinceSelector
							location={true}
							defaultValue={t('form.provinces')}
							style={{ width: '100%' }} />
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					label={t('promoterLoan.promotionPopulation')}
					name="promotionPopulation"
					rules={[
						{
							required: true
						}
					]}>
					<Select
						suffixIcon={<img src={DownOutlinedMobile.default} alt="DownOutlinedMobile" />}
						defaultValue={t('form.select')}
						style={{ width: '100%' }}>
						<Option value="LESS_THAN_10_THOUSAND_INHABITANTS">
							{t('promoterLoan.under10Select')}
						</Option>
						<Option value="BETWEEN_10_THOUSAND_AND_50_THOUSAND_INHABITANTS">
							{t('promoterLoan.between10and50Select')}
						</Option>
						<Option value="MORE_THAN_50_THOUSAND_INHABITANTS">
							{t('promoterLoan.greaterThan50Select')}
						</Option>
					</Select>
				</Form.Item>
			</Col>
		</Row>
	);
};

const GeneralData = () => {
	const params = useParams<{ operationId: string }>();
	const operationId = params.operationId;
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const userToken = useSelector<ReduxStore, string>((state) => state.auth.token);
	const dataPromoter = useSelector<ReduxStore, any>((state) => state.promoter.dataFormPromoter);
	const [loading, setLoading] = useState(false);
	const location = useLocation();

	

	const openNotificationWithIcon = () => {
		notificationGibobs('error', t('m_full.errorTitle'), t('form.incompleteInformation'));
	};

	const validateMessages = {
		required: '${label}' + ' ' + t('form.formRequired'),
		types: {
			email: t('form.emailValidated'),
			number: '${label} is not a valid number!'
		},
		number: {
			range: t('form.maxBudgetNewRequestValidated') + '${min}'
		}
	};
	const sendGeneralData = (values) => {
		setLoading(true);
		axios
			.post(
				`${env.api.url}/promoters/promoter-projects/general-data/${operationId}`,
				{
					province: values.province,
					salesUnits: Number(values.salesUnits),
					buildability: Number(values.buildability),
					preSalesUnits: Number(values.preSalesUnits),
					landInProperty: values.landInProperty,
					worksInitiated: values.worksInitiated,
					buildingPermission: values.buildingPermission,
					promotionPopulation: values.promotionPopulation,
					percentageOfPreSales: Number(values.percentageOfPreSales),
					percentageOfWorkStarted: Number(values.percentageOfWorkStarted)
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					if (location?.state === undefined) {
					setLoading(false);
					dispatch(getPromoterData({ ...dataPromoter, generalData: values }));
					navigate(`/promoter-loan/financial-data/${operationId}`);
					}
					if (location?.state?.edit) {
						dispatch(getPromoterData(response.data.data.data ));
						setLoading(false);
						navigate(`/promoter-loan/financial-data/${operationId}`);


					}
				
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const generalDataVerificated = (values) => {
		if (values.percentageOfWorkStarted === undefined || values.percentageOfWorkStarted === null) {
			values.percentageOfWorkStarted = Number(0);
		} else {
			values.percentageOfWorkStarted = Number(values.percentageOfWorkStarted);
		}
		sendGeneralData({
			...values,
			buildability: Number(values.buildability),
			preSalesUnits: Number(values.preSalesUnits),
			salesUnits: Number(values.salesUnits),
			province: values.province
		});
	};

	
		return (
			<div style={{ padding: 16 }}>
				<StepPromoterMobile stage={1} steps={3} />
				<div
					style={{
						marginTop: 24,
						marginBottom: 24,
						fontSize: 14,
						color: '#748EA0',
						fontWeight: 400
					}}>
					{t('promoterLoan.GeneralProjectDescription')}
				</div>
				<Form
					form={form}
					className="form-promoter-mobile"
					style={{ minHeight: '115vh' }}
					onFinishFailed={openNotificationWithIcon}
					scrollToFirstError={true}
					onFinish={generalDataVerificated}
					layout="vertical"
					name="GeneralData"
					validateMessages={validateMessages}
					initialValues={dataPromoter ? {preSalesUnits: location?.state?.edit ? dataPromoter.generalData : 0, ...dataPromoter.generalData} : {}}>
					{GeneralDataFormMobile(form)}
					<div
						style={{
							position: 'fixed',
							width: '100%',
							height: 80,
							bottom: 0,
							left: 0,
							boxShadow: '0px -2px 8px 0px rgba(47, 72, 88, 0.10)',
							background: '#fff',
							zIndex: 999,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							padding: 16
						}}>
						<Button
							htmlType="submit"
							type="primary"
							style={{
								width: '100%',
								height: 48,
								borderRadius: 8
							}}
							loading={loading}>
							{t('form.next')}
						</Button>
					</div>
				</Form>
				<HelpButtonMobile origin={'IN_FORM_THREE_STEPS'} simulation={undefined} />
			</div>
		);
	
   
};

export default GeneralData;
