import React from "react";

import { NavLink } from "react-router-dom";
import { Breadcrumb, Typography, Layout } from "antd";

const { Text } = Typography;

const MyBreadcrumb = ({ data }) => {
  return (
    <Layout>
      <Breadcrumb
        className="breadCrumb"
      >
        {data &&
          data.map((item, index) => (
            <Breadcrumb.Item key={item.link} to={item.link}>
              {item.link !== undefined ?
                <NavLink to={item.link}>
                  <Text
                    style={{
                      color: data.length === index + 1 ? "#02C3CD" : "#748EA0",
                      fontSize: "12px",
                      fontWeight: data.length === index + 1 ? "600" : "normal",
                    }}
                  >
                    {item.name}
                  </Text>
                </NavLink>

                : <Text
                  style={{
                    color: data.length === index + 1 ? "#02C3CD" : "#748EA0",
                    fontSize: "12px",
                    fontWeight: data.length === index + 1 ? "600" : "normal",
                  }}
                >
                  {item.name}
                </Text>}

            </Breadcrumb.Item>
          ))}
      </Breadcrumb>
    </Layout>
  );
};

export default MyBreadcrumb;
