import React from "react";
import { isMobileOnly } from "react-device-detect";
import WarningIcon from "../../../assets/onboarding/warningIcon.svg";
import { useParams, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

const ComplicatedViability = ({
  title,
  description,
  subrogation,
  setPages,
  ajust,
  mortgage,
  capital,
}) => {
  const { t } = useTranslation();
  const { operationId } = useParams();

  
  return (
    <div
      style={{
        border: "1px solid #02C3CD",
        borderRadius: 4,
        padding: 24,
        margin: "32px auto",
        textAlign: "center",
        backgroundColor: "#FFFF",
        width: ajust || isMobileOnly ? "100%" : "60%",
      }}
    >
      <img src={WarningIcon} alt="WarningIcon" />
      <div className="offer-style" style={{ color: "#2F4858", marginTop: 8 }}>
        {t(title)}
      </div>
      <div
        style={{
          fontSize: 16,
          fontWeight: 400,
          color: "#748EA0",
          marginTop: 8,
        }}
        dangerouslySetInnerHTML={{
          __html: t(description, {
            capital: capital,
          }),
        }}
      ></div>
      {subrogation === undefined && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Button
            type="primary"
            className="button-primari-gibobs"
            style={{
              width: 220,
              marginTop: 24,
            }}
            onClick={() => setPages(2)}
          >
            {t("loan.editData")}
          </Button>
          {/* <div
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: "red",
              textAlign: "right",
              marginTop: 12,
            }}
            onClick={() => closeOperation()}
          >
            {t("onboarding.finalizeRequest")}
          </div> */}
        </div>
      )}
      {ajust && mortgage && (
        <NavLink to={`/operations/${operationId}/basic`}>
          <Button
            type="primary"
            className="button-primari-gibobs"
            style={{
              fontSize: 16,
              fontWeight: 500,
              width: "auto",
              height: 48,
              marginTop: 24,
            }}
          >
            {t("viability.repeatAnalysis")}
          </Button>
        </NavLink>
      )}
    </div>
  );
};

export default ComplicatedViability;
