import React, { useState, useEffect } from 'react';
import { Route, useLocation, useNavigate, useParams, Routes } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DocumentsPage from '../Pages/documents/page';
import ProductsPage from '../Pages/Products/ProductsPage';
import ProductsPageInmo from '../Pages/ProductsImo/ProductsPageInmo';
import DashBoardPage from '../Pages/DashBoardPage';
import MenuComponent from '../components/menu/menuComponent';
import OperationsCustomer from '../Pages/Operaciones/OperationsCustomer';
import BalancePage from '../Pages/Financiero/BalancePage';
import ScoreGibobsPage from '../Pages/Financiero/Scoring/ScoreGibobsPage';
import PersonalSpace from '../Pages/PersonalSpace';
import './navigation.css';
import FrequentQuestions from '../Pages/frequentQuestions';
import ContactoPage from '../Pages/ContactoPage';
import HeaderComponent from './header/headerComponent';
import NotificationsPage from '../Pages/notificationsPage';
import Login from '../Pages/auth/login';
import { dataProfile, dataSummary } from '../store/data/actions';
import { getProfile } from '../store/auth/actions';
import { getOperations } from '../store/realestate/actions';
import Home from '../Pages/Home';
import SolarValueHome from '../Pages/Products/SolarValueHome';
import NewRequestPage from '../Pages/HerramientasVenta/NewRequest';
import FollowUpPage from '../Pages/HerramientasVenta/FollowUp';
import NewResquestWidget from '../Pages/HerramientasVenta/NewRequestWidget';
import MortgageRequest from '../Pages/HerramientasVenta/mortgageRequest';
import DetailOperationPage from '../Pages/HerramientasVenta/DetailOperationPage';
import HeaderComponentMovile from './header/headerComponentMovil';
import queryString from 'query-string';
import TagManager from './tagmanager';
import env from '../environment';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

import {
	BrowserView,
	MobileView,
	TabletView,
	isMobile,
	isTablet,
	isMobileOnly
} from 'react-device-detect';
import PromoterLoan from '../Pages/ProductsImo/promoterLoan';
import PromoterLoanBuildToSell from '../Pages/ProductsImo/PromoterLoanBuildToSell';
import SubrogationRequest from '../Pages/HerramientasVenta/SubrogationRequest';
import EcostateBudget from '../Pages/HerramientasVenta/EcostateBudget';
import LoaderLogo from './LoaderLogo';
import FinancieroPage from '../Pages/Financiero/FinancieroPage';
import OperationsContratacion from '../Pages/Products/operationsContratacion';
import { useTranslation } from 'react-i18next';
import { LikeOutlined } from '@ant-design/icons';
import googlePlay from '../assets/init/googlePlay.png';
import appleStore from '../assets/init/appleStore.png';
import CertificatedBuySellRequest from '../Pages/HerramientasVenta/CertificatedBuySell';
import CertificatedBuyRentDocumentation from '../Pages/HerramientasVenta/CertificatedBuyRentDocumentation';
import { Layout, Typography, Modal, Rate, message, Alert, Col, Card, Row } from 'antd';

import OperationCertificate from '../Pages/Certificates/OperationCertificated';
import VerifyDni from '../Pages/Certificates/VerifyDni';
import VerifyDniDesktop from '../Pages/Certificates/VerifyDniDesktop';

import CancelMortgage from '../Pages/Operaciones/MortagageAplication/CancelMortgage';
import OffersTable from '../Pages/Operaciones/Offers/OffersTable';
import RequestInformation from '../Pages/ProductsImo/RequestInformation';
import MortgageSimulatorPage from '../Pages/HerramientasVenta/MortgageSimulationPage';
import PropertyAppraisal from '../Pages/HerramientasVenta/PropertyAppraisal/PropertyAppraisal';
import LoansNav from './Routes/LoansNav';
import OfferDocuments from './widgets/Different-Widgets/offerDocuments';
//import imageAmigobsDesktop from '../assets/banners/imageAmigobsDesktop.png';
//import { changeRol } from '../store/auth/actions';
//import iconAmigobs from '../assets/banners/iconAmigobs.png';
import i18n from '../i18n';
import PromoterLoanNav from './Routes/PromoterLoanNav';
import CalculatorsMobileAndDesktop from '../Pages/calculators/quota/CalculatorsMobileAndDesktop';
import CalculatorsFeeMobileAndDesktop from '../Pages/calculators/quota/CalculatorsFeeMobileAndDesktop';
import CalculatorsFeeDatasMobileAndDesktop from '../Pages/calculators/quota/CalculatorsFeeDatasMobileAndDesktop';
import CalculatorsFeeViabilityMobileAndDesktop from '../Pages/calculators/quota/CalculatorsFeeViabilityMobileAndDesktop';
import CalculatorsQualifyClient from '../Pages/calculators/qualify/CalculatorsQualifyClient';
import CalculatorQualifyMobileData from '../Pages/calculators/qualify/CalculatorQualifyMobileData';
import OnboardingNav from '../Pages/onboarding/OnboardingNav';
import OperationStage from '../Pages/onboarding/OperationStages';
import Distributor from '../Pages/CoreDistributor/Distributor';
import Devtools from '../Pages/DevTools';
import WelcomeSelection from '../Pages/onboarding/Pages/welcomeSelection';
import CalculatorAmortization from '../Pages/calculators/amortization/CalculatorAmortization';
import CalculatorSubrogation from '../Pages/calculators/subrogation/CalculatorSubrogation';
import CalculatorFinancing from '../Pages/calculators/quotaB2C/financing/CalculatorFinancing';
import AutomaticDocumentation from '../Pages/Operaciones/MortagageAplication/DocumetationNew/AutomaticDocumentation/AutomaticDocumentation';
import { useAnalytics } from 'use-analytics';

const { Text } = Typography;
const { Content, Sider } = Layout;

const Navigation = () => {
	const dispatch = useDispatch();
	const analytics = useAnalytics();
	const navigate = useNavigate();
	const location = useLocation();
	const [loading, setLoading] = useState(true);
	const userToken = useSelector((state) => state.auth.token);
	const [collapsed, setCollapsed] = useState(!isMobileOnly);
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const changeStages = useSelector((state) => state.auth.refreshStages);
	const rol = useSelector((state) => state.auth.roles);
	const query = queryString.parse(location.search);
	let { stage } = useParams();
	const [showModalNotification, setShowModalNotification] = useState(false);
	const [alertClosed, setAlertClosed] = useState(false);
	const [score, setScore] = useState(0);
	const [npsAll, setNpsAll] = useState([]);
	const [, setRandomNumber] = useState();
	const { t } = useTranslation();

	useEffect(() => {
		// send page view on route change
		analytics.page();
	}, [analytics, location]);

	useEffect(async () => {
		await getNotificationNPS();
		setRandomNumber(Math.floor(Math.random() * 2));
	}, []);

	useEffect(() => {
		if (userToken && userToken.length > 1) {
			if (rol !== 'realestate') {
				dispatch(dataSummary(userToken));
				dispatch(dataProfile(userToken));
				setTimeout(() => {
					setLoading(false);
				}, 2000);
			} else if (rol === 'realestate') {
				dispatch(getOperations());
				dispatch(dataProfile(userToken));
				setTimeout(() => {
					setLoading(false);
				}, 2000);
			} else if (rol === 'client') {
				dispatch(dataSummary(userToken));
				dispatch(dataProfile(userToken));
				setTimeout(() => {
					setLoading(false);
				}, 2000);
			}
		}
	}, [userToken, changeStages]);

	const getNotificationNPS = async () => {
		const response = await axios.get(`${env.api.url}/v1/nps/list`, {
			headers: { Authorization: `Bearer ${userToken}` }
		});
		setNpsAll(response.data.data);

		return response.data.data;
	};

	const isSecureUrl = () => {
		let notsecured = ['/login', '/verify/operation/owner'];

		return notsecured.findIndex((value) => new RegExp(`^${value}`).test(location.pathname)) === -1;
	};

	if (isSecureUrl() && !isAuthenticated) {
		if (query.token) {
			let customRole;

			if (query.redirect === '/friend') {
				customRole = 'friend';
			}

			const decodedToken = jwt_decode(query.token);

			if (decodedToken.managementCode !== 'gibobs') {
				axios
					.post(
						`https://prometeo.gibobs.com/webhook/9d4bbb62-fda3-463b-8046-7baa00e618d5`,
						{ managementCodePlatform: 'gibobs', jwt: query.token },
						{ headers: { Authorization: `Bearer ${userToken}` } }
					)
					.then(() => {
						if (i18n.language === 'cat') {
							document.location = `https://www.gibobs.com/ca/landing-amigobs-ca/?action=logout_plataforma`;
						} else if (i18n.language === 'en') {
							document.location = `https://www.gibobs.com/en/landing-amigobs-en/?action=logout_plataforma`;
						} else {
							document.location = `https://www.gibobs.com/landing-amigobs/?action=logout_plataforma`;
						}
					});
			} else {
				dispatch(
					getProfile({
						token: query.token,
						navigate,
						redirect: query.redirect,
						customRole,
						id: query.operationId
					})
				);
			}
		} else {
			if (env.app.login === 'gibobs') {
				if (i18n.language === 'cat') {
					document.location = `https://www.gibobs.com/ca/landing-amigobs-ca/?action=logout_plataforma`;
				} else if (i18n.language === 'en') {
					document.location = `https://www.gibobs.com/en/landing-amigobs-en/?action=logout_plataforma`;
				} else {
					document.location = `https://www.gibobs.com/landing-amigobs/?action=logout_plataforma`;
				}
			} else {
				navigate('/login');
			}
		}
	} else if (query.operationId && query.redirect === 'operation-agent-b2b') {
		navigate(`/sales-tools/follow-up/detail-operation/${query.operationId}/basic`);
	} else if (query.redirect === 'loans') {
		navigate(`/my-financial-profile/credits/list`);
	} else if (query.redirect === 'create-loan') {
		navigate(`/my-financial-profile/credits`);
	} else if (query.redirect === 'last-promotor-operation') {
		navigate(`/promoter-loan/step-form/${query.operationId}`);
	} else if (!isSecureUrl() && isAuthenticated) {
		//navigate("/home");
		navigate('/distributor');

		return <></>;
	}

	const handleClickNotification = () => {
		setShowModalNotification(!showModalNotification);
	};

	const handleCloseModalNotification = () => {
		setShowModalNotification(!showModalNotification);
	};

	const handleChangeRate = (value) => {
		setScore(value);
	};

	const handleClickSendModalNotification = () => {
		axios
			.post(
				`${env.api.url}/v1/nps/register-by-operation`,
				{
					operationId: npsAll && npsAll[0].operationId,
					vote: score,
					type:
						npsAll && npsAll[0].type === 'mortgage.nps.firstcall'
							? 'mortgage.nps.firstcall'
							: 'mortgage.nps.signed'
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then(() => {
				setShowModalNotification(!showModalNotification);
				getNotificationNPS();
				message.success('Gracias por tu respuesta');
			});
	};

	/*const handleClick = () => {
		dispatch(changeRol('friend'));

		navigate('/friend');
	};*/

	let routesApp = <Routes />;

	if (!isAuthenticated) {
		return (
			<>
				<Layout style={{ height: '100vh' }}>
					<Content style={{ marginTop: 5, minWidth: '70vh' }}>
						<Routes>
							<Route path="/login" element={<Login />} />
						</Routes>
						<Routes>
							{isMobile ? (
								<Route path="/verify/operation/owner/:base64Id" element={<VerifyDni />} />
							) : (
								<Route path="/verify/operation/owner/:base64Id" element={<VerifyDniDesktop />} />
							)}
						</Routes>
					</Content>
				</Layout>
			</>
		);
	} else {
		const commonRoutes = [
			<Route key="/documents" path="/documents" element={<DocumentsPage />} />,
			<Route key="/documents/:folderId" path="/documents/:folderId" element={<DocumentsPage />} />,
			<Route key="/calculators" path="/calculators" element={<CalculatorsMobileAndDesktop />} />,

			<Route
				key="/calculators/fee/datas"
				path="/calculators/fee/datas"
				element={<CalculatorsFeeDatasMobileAndDesktop />}
			/>,
			<Route
				key="/calculators/fee/viability"
				path="/calculators/fee/viability"
				element={<CalculatorsFeeViabilityMobileAndDesktop />}
			/>,
			<Route
				key="/qualify/calculators/fee/datas"
				path="/qualify/calculators/fee/datas"
				element={<CalculatorQualifyMobileData />}
			/>,
			<Route
				key="/calculators/fee"
				path="/calculators/fee"
				element={<CalculatorsFeeMobileAndDesktop />}
			/>,

			<Route key="/my-account" path="/my-account" element={<PersonalSpace />} />,
			<Route key="/dev-tools" path="/dev-tools" element={<Devtools />} />,
			<Route key="/products" path="/products" element={<ProductsPage />} />,
			<Route key="/help" path="/help" element={<FrequentQuestions />} />,
			<Route key="/contact" path="/contact" element={<ContactoPage />} />,
			<Route key="/notifications" path="/notifications" element={<NotificationsPage />} />,
			<Route key="/home" path="/home" element={<Home />} />,

			<Route key="/distributor" path="/distributor" element={<Distributor></Distributor>} />,

			<Route
				key={rol === 'friend' ? '/friend*' : '/dashboard-client'}
				path={rol === 'friend' ? '/friend*' : '/dashboard-client'}
				element={<DashBoardPage />}
			/>,

			<Route
				key={rol === 'friend' ? '/friendInfo' : '/dashboard-client'}
				path={rol === 'friend' ? '/friendInfo' : '/dashboard-client'}
				element={<DashBoardPage />}
			/>,

			<Route
				key={rol === 'realestate' ? '/dashboard-business' : '/dashboard-client'}
				path={rol === 'realestate' ? '/dashboard-business' : '/dashboard-client'}
				element={<DashBoardPage />}
			/>
		];

		if (rol !== 'realestate') {
			routesApp = (
				<Routes>
					<Route
						key="/calculators/amortization"
						path="/calculators/amortization"
						element={<CalculatorAmortization />}
					/>
					<Route
						key="/calculators/financing"
						path="/calculators/financing"
						element={<CalculatorFinancing />}
					/>

					<Route
						key="/calculators/subrogation"
						path="/calculators/subrogation"
						element={<CalculatorSubrogation />}
					/>
					<Route
						path="/sales-tools/new-requests/solar-value-home/result"
						element={<SolarValueHome dataBudget={true} type={location.state} />}
					/>

					<Route
						path="/sales-tools/new-requests/solar-value-home"
						element={<SolarValueHome dataBudget={false} type={location.state && location.state} />}
					/>
					<Route path="/testA*" element={<OnboardingNav />} />

					{/**Prestamos */}

					<Route path="/my-financial-profile/credits*" element={<LoansNav />} />

					{/**loan finish */}

					<Route path="/my-financial-profile/balance" element={<BalancePage />} />
					<Route path="/my-financial-profile/score-gibobs" element={<ScoreGibobsPage />} />
					<Route path="/my-financial-profile" element={<FinancieroPage />} />
					<Route path="/operations/clients-operations" element={<OperationsCustomer />} />

					<Route
						path="/operations/certificate/:operationId/:stage"
						element={<OperationCertificate />}
					/>

					<Route path="/cancel/operations/:operationId" element={<CancelMortgage />} />

					<Route path="/operations/:operationId/:stage" element={<OperationStage />} />
					<Route path="/welcomeSelection" element={<WelcomeSelection />} />
					<Route
						path="/compare/:operationId/:stage"
						element={<OffersTable offers={location?.state?.stage} />}
					/>
					<Route path="/documents-offert/:operationId/:stage" element={<OfferDocuments />} />

					<Route path="/operations-closed" element={<OperationsContratacion />} />

					<Route path="/products-under-contract" element={<OperationsCustomer />} />
					<Route
						path="/:operationId/:owner/automatic-documentation"
						element={<AutomaticDocumentation />}
					/>

					{commonRoutes}
					{/* FIXME: FIXIT */}
					{!location.pathname.startsWith('/opera') && !location.pathname.startsWith('/friend') && (
						<Route key="/" path="/" element={<Home />} />
					)}
				</Routes>
			);
		}
		if (rol === 'realestate') {
			routesApp = (
				<Routes>
					<Route path="/promoter-loan*" element={<PromoterLoanNav />} />
					<Route
						path="/sales-tools/follow-up/detail-operation/:operationId/:stage"
						element={<DetailOperationPage />}
					/>
					<Route
						path="/sales-tools/promoterLoan/promoter-loan-build-to-sell"
						element={<PromoterLoanBuildToSell />}
					/>
					<Route path="/sales-tools/promoter-loan" element={<PromoterLoan />} />
					<Route
						path="/sales-tools/follow-up"
						element={
							<FollowUpPage
								modal={location.state && location.state[0]}
								tabSelected={location.state && location.state[1]}
							/>
						}
					/>
					<Route path="/sales-tools/new-requests/pre-certified" element={<NewResquestWidget />} />
					<Route
						path="/sales-tools/new-requests/solar-value-home/result"
						element={<SolarValueHome dataBudget={true} />}
					/>
					<Route
						path="/sales-tools/new-requests/solar-value-home"
						element={<SolarValueHome dataBudget={false} />}
					/>
					<Route
						path="/sales-tools/mortgagesGreen"
						element={<MortgageRequest ecoBudget={true} />}
					/>
					<Route path="/sales-tools/mortgages" element={<MortgageRequest />} />
					<Route path="/sales-tools/subrogations" element={<SubrogationRequest />} />
					<Route
						path="/sales-tools/certificated-buy-sell/:id/:stage"
						element={<CertificatedBuyRentDocumentation stage={stage} />}
					/>
					<Route
						path="/sales-tools/certificated-rent/:id/:stage"
						element={<CertificatedBuyRentDocumentation stage={stage} />}
					/>
					<Route
						path="/sales-tools/certificated-buy-sell"
						element={<CertificatedBuySellRequest />}
					/>
					<Route path="/sales-tools/certificated-rent" element={<CertificatedBuySellRequest />} />
					<Route path="/sales-tools/mortgage-calculator" element={<MortgageSimulatorPage />} />
					<Route path="/sales-tools/property-appraisal" element={<PropertyAppraisal />} />
					<Route path="/sales-tools/new-requests/budgetEco" element={<EcostateBudget />} />
					<Route path="/sales-tools/new-requests" element={<NewRequestPage />} />
					<Route
						path="/products-business/request-information/:contactReason"
						element={<RequestInformation />}
					/>
					<Route path="/products-business" element={<ProductsPageInmo />} />
					<Route
						key="/calculators/fee"
						path="/calculators/fee"
						element={<CalculatorsFeeMobileAndDesktop />}
					/>
					,
					<Route
						key="/calculators/qualify/client"
						path="/calculators/qualify/client"
						element={<CalculatorsQualifyClient />}
					/>
					,{commonRoutes}
					{location.pathname === '/' && <Route key="/" path="/" element={<Home />} />}
				</Routes>
			);
		}
	}

	if (loading) {
		return (
			<div
				style={{
					textAlign: 'center',
					height: '100vh',
					backgroundColor: 'white'
				}}>
				{/* <img
          src={loadingLogo}
          alt="Loading..."
          style={{ width: 300, top: "50%", marginTop: "15vh" }}
        /> */}
				<LoaderLogo />
			</div>
		);
	}

	const onCollapseData = () => {
		setCollapsed(!collapsed);
	};

	const searchItem = (location) => {
		if (
			location === '/my-financial-profile/score-gibobs' ||
			location === '/my-financial-profile/balance' ||
			location === '/my-financial-profile/credits' ||
			location === '/my-financial-profile/credits/list' ||
			location.includes('my-financial-profile/credits/form')
		) {
			return 'menuComponent.profileNav';
		} else if (
			location === '/products' ||
			location === 'operations-closed' ||
			location === '/products-under-contract' ||
			location.includes('/operations')
		) {
			return 'menuComponent.productsNav';
		} else if (
			(location.includes('/documents') && !location.includes('/sales-tools')) ||
			location.includes('/calculators')
		) {
			return 'menuComponent.resource';
		} else if (location.includes('/sales-tools/')) {
			return 'menuComponent.newRequest';
		} else if (location.includes('/products-business') || location.includes('promoter-loan')) {
			return 'menuComponent.productsBusiness';
		} else {
			return location;
		}
	};

	let npsComponent;

	let linksApps;

	//let bannerAmigobs;

	if (
		npsAll &&
		npsAll.length > 0 &&
		npsAll[0].type === 'mortgage.nps.firstcall' &&
		showModalNotification === false &&
		!isMobile
	) {
		npsComponent = (
			<Alert
				//onClick={() => handleClickNotification(npsAll[0].type)}
				type="warning"
				closable
				onClose={() => setAlertClosed(true)}
				style={{
					background: '#FEF0D3',
					width: 'calc(100% - 80px)',
					position: 'relative',
					top: 24,
					height: 56,
					left: 40,
					cursor: 'pointer',
					zIndex: 10,
					marginBottom: 24
				}}
				message={
					<div onClick={() => handleClickNotification(npsAll[0].type)}>
						<LikeOutlined style={{ fontSize: 20, color: '#FAAD14', marginRight: 10 }} />{' '}
						<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
							{
								'Queremos conocer tu experiencia y nos gustaría que valorases la atención recibida por parte de tu analista.'
							}
							&nbsp;
							<Text style={{ color: '#FAAD14', fontSize: 14, fontWeight: 400 }}>{'Valorar'}</Text>
						</Text>
					</div>
				}></Alert>
		);
	} else if (
		npsAll &&
		npsAll.length > 0 &&
		npsAll[0].type === 'mortgage.nps.firstcall' &&
		showModalNotification === false &&
		isMobile
	) {
		npsComponent = (
			<>
				<Alert
					//onClick={() => handleClickNotification(npsAll[0].type)}
					type="warning"
					closable
					onClose={() => setAlertClosed(true)}
					style={{
						background: '#FEF0D3',
						width: '94%',
						position: 'relative',
						top: 24,
						height: 100,
						left: 15,
						cursor: 'pointer',
						zIndex: 10,
						marginBottom: location.pathname === '/friend' ? 25 : 30
					}}
					message={
						<div onClick={() => handleClickNotification(npsAll[0].type)}>
							<LikeOutlined style={{ fontSize: 20, color: '#FAAD14', marginRight: 10 }} />{' '}
							<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
								{
									'Queremos conocer tu experiencia y nos gustaría que valorases la atención recibida por parte de tu analista.'
								}
								&nbsp;
								<Text style={{ color: '#FAAD14', fontSize: 14, fontWeight: 400 }}>{'Valorar'}</Text>
							</Text>
						</div>
					}></Alert>
			</>
		);
	} else if (
		npsAll &&
		npsAll.length > 0 &&
		npsAll[0].type === 'mortgage.nps.signed' &&
		showModalNotification === false &&
		!isMobile
	) {
		npsComponent = (
			<Alert
				type="warning"
				closable
				style={{
					background: '#FEF0D3',
					width: '97%',
					position: 'relative',
					top: 24,
					height: 56,
					left: 40,
					cursor: 'pointer',
					zIndex: 10,
					marginBottom: 10
				}}
				message={
					<div onClick={() => handleClickNotification(npsAll[0].type)}>
						<LikeOutlined style={{ fontSize: 16, color: '#FAAD14', marginRight: 10 }} />{' '}
						<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
							{'Valora el proceso completo con nosotros en la firma de tu hipoteca.'}
							&nbsp;
							<Text style={{ color: '#FAAD14', fontSize: 14, fontWeight: 400 }}>{'Valorar'}</Text>
						</Text>
					</div>
				}></Alert>
		);
	} else if (
		npsAll &&
		npsAll.length > 0 &&
		npsAll[0].type === 'mortgage.nps.signed' &&
		showModalNotification === false &&
		isMobile
	) {
		npsComponent = (
			<Alert
				type="warning"
				closable
				style={{
					background: '#FEF0D3',
					width: '93%',
					position: 'relative',
					top: location.pathname === '/dashboard-client' ? 34 : 24,
					height: 60,
					left: 15,
					cursor: 'pointer',
					zIndex: 10,
					marginBottom: 30
				}}
				message={
					<div onClick={() => handleClickNotification(npsAll[0].type)}>
						<LikeOutlined style={{ fontSize: 16, color: '#FAAD14', marginRight: 10 }} />{' '}
						<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
							{'Valora el proceso completo con nosotros en la firma de tu hipoteca.'}
							&nbsp;
							<Text style={{ color: '#FAAD14', fontSize: 14, fontWeight: 400 }}>{'Valorar'}</Text>
						</Text>
					</div>
				}></Alert>
		);
	}

	linksApps = (
		<Card
			className="init-card-apps-desktop"
			style={{
				position: 'relative',
				bottom: 0,
				right: 0,
				top: -60,
				zIndex: 1000,
				marginBottom: 40
			}}>
			<Row>
				<Col lg={18} md={18} sm={18} xs={24}>
					<Text
						style={{
							fontSize: 20,
							fontWeight: 700,
							color: '#FAFEFE',
							fontFamily: 'Inter',
							position: 'relative',
							top: -5
						}}>
						{t('init.downloadApp')}
					</Text>
					<Text
						style={{
							fontSize: 16,
							fontWeight: 600,
							color: '#EFFCFC',
							fontFamily: 'Inter',
							position: 'relative',
							top: -5,
							left: 24
						}}>
						{t('init.downloadAppDesc')}
					</Text>
				</Col>

				<Col
					lg={6}
					md={6}
					sm={8}
					xs={24}
					style={{ display: 'flex', marginTop: -8, justifyContent: 'right' }}>
					<a
						style={{ width: 120, height: 20, marginRight: 9 }}
						target="_blank"
						href={'https://play.google.com/store/apps/details?id=com.gibobs.mobile'}>
						<img
							src={googlePlay}
							alt="asdf"
							style={{ width: 120, height: 40, marginRight: 9 }}></img>
					</a>
					<a
						style={{ width: 120, heigh: 20 }}
						target="_blank"
						href={'https://apps.apple.com/es/app/gibobs/id1582157732'}>
						<img src={appleStore} alt="asdf" style={{ width: 120, heigh: 20 }}></img>
					</a>
				</Col>
			</Row>
		</Card>
	);

	/*bannerAmigobs = (
		<Card
			className="init-card-banner-amigobs-desktop"
			style={{
				position: 'relative',
				bottom: 0,
				right: 0,
				top: -60,
				zIndex: 1000,
				marginBottom: 40,
				width: 'calc(100% - 80px)'
			}}>
			<Row>
				<Col
					lg={16}
					md={16}
					sm={16}
					xs={16}
					xl={16}
					xxl={16}
					style={{
						display: 'flex',
						background: 'linear-gradient(90deg, #0088C6 0%, #00B298 100%)',
						padding: 24,
						width: '100%',
						marginTop: -3
					}}>
					<div style={{ width: '85%' }}>
						<img
							src={iconAmigobs}
							alt="asdf"
							style={{ width: 24, height: 24, marginTop: -10 }}></img>

						<Text
							style={{
								color: '#FAFEFE',
								fontSize: 20,
								fontWeight: 600,
								marginLeft: 12
							}}>
							{t('header.plain')}
						</Text>

						<Text
							style={{
								color: '#FAFEFE',
								fontSize: 16,
								fontWeight: 600,
								marginTop: 3,
								marginLeft: 12
							}}>
							{t('amigobs.bannerDes')}
						</Text>
					</div>

					<div style={{ width: '15%', textAlign: 'right' }}>
						<Button
							style={{
								width: 96,
								height: 32,
								fontSize: 16,
								color: '#02C3CD',
								fontWeight: 600,
								borderRadius: 4,
								border: 'none'
							}}
							onClick={() => handleClick()}>
							{t('header.inviteFriend')}
						</Button>
					</div>
				</Col>

				<Col lg={8} md={8} sm={8} xs={8} xl={8} xxl={8}>
					<img src={imageAmigobsDesktop} alt="asdf" style={{ width: '100%' }}></img>
				</Col>
			</Row>
		</Card>
	);*/

	return (
		<>
			<TagManager />
			{isMobileOnly && !isTablet && (
				<MobileView style={{ width: '100%' }}>
					<HeaderComponentMovile
						routesApp={routesApp}
						npsComponent={rol !== 'realestate' && npsComponent}
						selectedMenuItem={searchItem(location.pathname)}
						alertClosed={alertClosed}
					/>
				</MobileView>
			)}
			{isTablet && !isMobileOnly && (
				<TabletView>
					<HeaderComponent />
					<Layout>
						<Sider
							trigger={null}
							width={240}
							collapsedWidth={56}
							collapsible
							collapsed={!collapsed}
							style={{
								backgroundColor: '#ffffff',
								position: 'relative',
								zIndex: '1',
								minHeight: '100%',
								marginTop: '0.5px'
							}}>
							<MenuComponent
								selectedMenuItem={searchItem(location.pathname)}
								style={{ minHeight: '100%' }}
								onCollapsedTouch={onCollapseData}
								rolUser={rol}
								collapsed={collapsed}
							/>
						</Sider>
						<Content
							style={{
								marginTop: '1px',
								height: '100vh',
								overflowY: 'scroll',
								position: 'relative'
								// left: -10,
							}}>
							<div>
								<div>
									{/*profileDateGet && profileDateGet.hasPassword === false && (
                                    <AlertPassword></AlertPassword>
                                )*/}
									{rol !== 'realestate' ? npsComponent : undefined}
								</div>
								{routesApp}

								{rol !== 'realestate' &&
								(location.pathname.startsWith('/dashboard-client') ||
									location.pathname.startsWith('/products') ||
									location.pathname.startsWith('/products-under-contract') ||
									location.pathname.startsWith('/friend') ||
									location.pathname.startsWith('/friendInfo') ||
									location.pathname.startsWith('/calculators'))
									? // randomNumber === 0
									  // 	? bannerAmigobs
									  // 	:
									  linksApps
									: undefined}
							</div>
						</Content>
					</Layout>
				</TabletView>
			)}
			{!isMobileOnly && !isTablet && (
				<BrowserView>
					<HeaderComponent />
					<Layout>
						<Sider
							trigger={null}
							width={240}
							collapsedWidth={56}
							collapsible
							collapsed={!collapsed}
							style={{
								backgroundColor: '#ffffff',
								position: 'relative',
								zIndex: '1',
								minHeight: '100%',
								marginTop: '0.5px'
							}}>
							<MenuComponent
								selectedMenuItem={searchItem(location.pathname)}
								style={{ minHeight: '100%' }}
								onCollapsedTouch={onCollapseData}
								rolUser={rol}
								collapsed={collapsed}
							/>
						</Sider>

						<Content
							style={{
								marginTop: '1px',
								height: '100vh',
								overflowY: 'scroll',
								position: 'relative',
								marginLeft: collapsed ? 15 : -5
							}}>
							<div>
								{/*profileDateGet && profileDateGet.hasPassword === false && (
                                <AlertPassword></AlertPassword>
                            )*/}
								{rol !== 'realestate' ? npsComponent : undefined}
								<div
									style={{
										minHeight:
											rol !== 'realestate' &&
											(location.pathname.startsWith('/products') ||
												location.pathname.startsWith('/products-under-contract') ||
												location.pathname.startsWith('/friend') ||
												location.pathname.startsWith('/friendInfo') ||
												location.pathname.startsWith('/calculators'))
												? '91vh'
												: location.pathname.startsWith('/dashboard-client') && '88vh'
									}}>
									{routesApp}
								</div>

								{rol !== 'realestate' &&
								(location.pathname.startsWith('/dashboard-client') ||
									location.pathname.startsWith('/products') ||
									location.pathname.startsWith('/products-under-contract') ||
									location.pathname.startsWith('/friend') ||
									location.pathname.startsWith('/friendInfo') ||
									location.pathname.startsWith('/calculators'))
									? // randomNumber === 0
									  // 	? bannerAmigobs
									  // 	:
									  linksApps
									: undefined}
							</div>
						</Content>
					</Layout>
				</BrowserView>
			)}
			<Modal
				className="ModalCommon"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { width: '96px' }
				}}
				style={{ marginTop: 100 }}
				okButtonProps={{
					className: 'button-primari-gibobs',
					style: { width: '74px', padding: 0 }
				}}
				width={400}
				open={showModalNotification}
				title={'Valora a tu analista'} //t("modal.notificationTittle")}
				okText={'Enviar'}
				cancelText={'Cancelar'}
				// okText={t("form.send")}
				//cancelText={t("offers.cancel")}
				destroyOnClose={true}
				onCancel={() => {
					handleCloseModalNotification();
				}}
				onOk={() => {
					handleClickSendModalNotification();
				}}>
				{npsAll && npsAll.length > 0 && npsAll[0].type === 'mortgage.nps.firstcall' && (
					<Text
						style={{
							fontWeight: 400,
							fontSize: 12,
							color: '#2F4858',
							position: 'relative',
							top: -16
						}}>
						{
							'En gibobs creemos en la mejora constante, por ello nos gustaría que nos ayudases, valorando del 1 al 10 la atención recibida por parte de tu analista:'
						}
					</Text>
				)}
				{npsAll && npsAll.length > 0 && npsAll[0].type === 'mortgage.nps.signed' && (
					<Text
						style={{
							fontWeight: 400,
							fontSize: 12,
							color: '#2F4858',
							position: 'relative',
							top: -16
						}}>
						{'Ahora que ya tienes tu hipoteca firmada, valora el proceso como ha sido para ti.'}
					</Text>
				)}

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						marginTop: 20,
						marginBottom: 15
					}}>
					<Text style={{ fontWeight: 600, fontSize: 14, color: '#2F4858' }}>
						&nbsp;1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10{' '}
					</Text>

					<Rate count={10} onChange={(value) => handleChangeRate(value)} />
				</div>
			</Modal>
		</>
	);
};

export default Navigation;
