import React from 'react';
import { Typography, Upload, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const ModalEditImage = ({ visible, onCreate, onCancel, fileName, uploadProps }) => {
	const { t } = useTranslation();

	return (
        (<Modal
			className="gb-modal-40-padding"
			width={480}
			open={visible}
			onCancel={onCancel}
			onOk={onCreate}
			okText={
				<Text style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 14 }}>
					{t('scoring.update')}
				</Text>
			}
			cancelText={
				<Text style={{ color: '#02C3CD', fontWeight: 600, fontSize: 14 }}>
					{t('offers.cancel')}
				</Text>
			}
			cancelButtonProps={{ style: { width: '44%', height: 40 } }}
			okButtonProps={{ style: { width: '44%', height: 40 } }}
			title={
				<Text
					style={{
						fontSize: 16,
						fontWeight: 600,
						color: '#2F4858'
					}}>
					{t('modalLoan.editImage')}
				</Text>
			}>
            <div
				style={{
					display: 'flex',
					flexDirection: 'column',
					marginBottom: -4,
					marginTop: 18
				}}>
				<Text
					style={{
						fontSize: 14,
						fontWeight: 400,
						color: '#748EA0'
					}}>
					{t('loan.modalDescEditName')}
				</Text>

				{fileName === undefined && (
					<div
						style={{
							textAlign: 'center',
							marginTop: 24,
							width: '100%',
							fontSize: '14px',
							fontWeight: 500,
							color: '#02C3CD'
						}}>
						<Upload {...uploadProps} style={{ display: 'flex' }}>
							<a>{t('loan.modalEditImageAttach')}</a>
						</Upload>
					</div>
				)}
				{fileName !== undefined && (
					<div
						style={{
							fontSize: '14px',
							fontWeight: 500,
							color: '#02C3CD',
							textAlign: 'center',
							marginTop: 16,
							width: '100%'
						}}>
						{fileName}
					</div>
				)}
			</div>
        </Modal>)
    );
};

export default ModalEditImage;
