import React, { useState, useEffect } from 'react';
import {
	Typography,
	Steps,
	Radio,
	Row,
	Col,
	Card,
	Upload,
	Button,
	notification,
	Modal
} from 'antd';

import { useSelector } from 'react-redux';
import axios from 'axios';
import env from '../../../environment';
import { useTranslation } from 'react-i18next';
import { refreshData } from '../../../store/auth/actions';
import {
	CheckOutlined,
	DownloadOutlined,
	FileOutlined,
	FilePdfOutlined,
	PlusOutlined
} from '@ant-design/icons';
import _ from 'lodash';
import { showPay } from '../../../store/data/actions';
import { useDispatch } from 'react-redux';
import CheckOutComponent from '../../ProductsImo/CheckOutComponent';
import DocumentationNew from '../MortagageAplication/DocumetationNew/DocumentationNew';
import leftArrowIcon from '../../../assets/icons/leftArrowIcon.svg';
import moment from 'moment';

const { Text } = Typography;
const { Step } = Steps;

const AppraisalMobile = ({ dataMortgage, operationId }) => {
	const [existAppraisal, setExistAppraisal] = useState(false);
	const [valuesData, setValuesData] = useState([]);
	const userToken = useSelector((state) => state.auth.token);
	const [valueRadio, setValueRadio] = useState(undefined);
	const [mortgageData, setMortgageData] = useState();
	const [isClientPay, setIsClientPay] = useState(false);
	const { t } = useTranslation();
	const [, setCheckBoxData] = useState(
		dataMortgage !== undefined &&
			dataMortgage.mortgage &&
			dataMortgage.mortgage.signedDocumentation === true
			? true
			: false
	);
	const [bankOffer, setBankOffer] = useState();
	const [saveFileDataField, setSaveFileDataField] = useState(null);
	const [typeFile, setTypeFile] = useState();
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [appraisal, setAppraisal] = useState(undefined);
	const dispatch = useDispatch();
	const showPayBoolean = useSelector((state) => state.data.showPay);

	const [dataClient, setDataClient] = useState('');
	const [pageNumber, setPageNumber] = useState(2);
	const [mortgageOperation, setMortgageOperation] = useState(undefined);

	useEffect(() => {
		if (operationId) {
			getAppraisal();
			//setValueRadio("own");
			getMortgageData();
			getClientPay();
			getBankOffer();
			getTokenPay();
		}
	}, []);

	useEffect(() => {
		getClientPay();
	}, [showPayBoolean]);

	useEffect(() => {
		setExistAppraisal(valuesData.some((value) => value.type === 'appraisal'));
	}, [valuesData]);

	const getTokenPay = () => {
		axios
			.post(
				`${env.api.url}/v1/payments/appraisal/checkout`,
				{
					operationId: operationId
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				setDataClient(response.data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const getMortgageData = () => {
		axios
			.get(`${env.api.url}/v1/mortgages/data/get/${operationId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setMortgageOperation(response.data.data.operation);
				setMortgageData(response.data.data.mortgage);
				if (response.data.data.mortgage.reportAppraisal !== undefined) {
					setValueRadio(response.data.data.mortgage.reportAppraisal);
				}
			});
	};
	const getBankOffer = () => {
		axios
			.get(`${env.api.url}/v1/mortgages/selected-bank-offer/${operationId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setBankOffer(response.data.data.selectedOffer);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getClientPay = () => {
		axios
			.post(
				`${env.api.url}/v1/payments/operation/status`,
				{
					operationId: operationId
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				setIsClientPay(response.data.data.completed);
			});
	};

	const getAppraisal = () => {
		axios
			.get(`${env.api.url}/v1/documents/get-operation-documents/${operationId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setValuesData(response.data.data);
				setExistAppraisal(response.data.data.some((value) => value.type === 'appraisal'));
				setAppraisal(response.data.data.find((value) => value.type === 'appraisal'));
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleUploadFile = (file) => {
		axios
			.put(
				`${env.api.url}/api/mortgages/attachment`,
				{
					mortgageId: operationId,
					file: file[1].file,
					fileName: file[1].fileName,
					type: typeFile,
					owner: 'owner1'
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then(() => {
				getAppraisal();
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const saveFileData = (file, name) => {
		const documentFile = [saveFileDataField];
		documentFile.push({
			file,
			fileName: name
		});
		setSaveFileDataField(documentFile);
		handleUploadFile(documentFile);
	};
	const uploadProps = {
		beforeUpload: (file) => {
			return new Promise(() => {
				const reader = new FileReader();
				reader.readAsDataURL(file);

				if (file.size < 50000000) {
					reader.onload = () => {
						const base64File = reader.result.split(',')[1];
						saveFileData(base64File, file.name);
					};
				} else {
					notification.error({
						message: 'Problema al subir archivo',
						description:
							'Documento demasiado pesado, debe subir documentos que no superen los 50MB por seguridad.'
					});
				}
			});
		}
	};
	const handleClickPlus = (typeFile) => {
		setTypeFile(typeFile);
	};

	const deleteDocument = () => {
		if (!_.isNil(appraisal.page)) {
		} else {
			axios
				.get(`${env.api.url}/v1/documents/delete-document/${appraisal.id}`, {
					headers: { Authorization: `Bearer ${userToken}` }
				})
				.then(() => {
					setShowModalDelete(false);
					getAppraisal();
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	const downloadApprasal = () => {
		axios
			.get(`${env.api.url}/v1/documents/get-document-url/${appraisal.id}?token=${userToken}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				if (response.data.success) {
					window.open(response.data.data.url, '_blank'); //to open new page
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const saveDataOnClick = (participant, key, value) => {
		let valueRadio = {
			key: 'operation',
			subkey: 'firstClickToAppraisal',
			value: moment().format('YYYY-MM-DD')
		};

		if (
			mortgageData.reportAppraisal !== undefined &&
			mortgageOperation.firstClickToAppraisal !== undefined
		) {
			valueRadio = {
				key: 'operation',
				subkey: 'ultimateClickToAppraisal',
				value: moment().format('YYYY-MM-DD')
			};
		}

		axios
			.post(
				`${env.api.url}/v1/mortgages/data/multiple/update/${operationId}`,
				{
					values: [
						{
							key: participant,
							subkey: key,
							value: value
						},
						{
							key: valueRadio.key,
							subkey: valueRadio.subkey,
							value: valueRadio.value
						}
					]
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				getMortgageData();
				refreshData();
				setCheckBoxData(response.data.data.mortgage.signedDocumentation);
			});
	};

	const renderSelect = () => {
		return (
			<div style={{ marginTop: 24 }}>
				{_.isNil(mortgageData?.appraiser) && (
					<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
						{t('m_appraisal.sectionTitle').toUpperCase()}
					</Text>
				)}

				{!isClientPay && (
					<Row style={{ marginTop: 24, display: 'flex', width: '100%' }}>
						<Col span={24} style={{ textAlign: 'left', width: '100%' }}>
							<Radio
								value={'own'}
								checked={mortgageData && mortgageData.reportAppraisal === 'own' ? true : false}
								onChange={(e) => {
									saveDataOnClick('mortgage', 'reportAppraisal', e.target.value);
									setValueRadio(e.target.value);
								}}>
								{' '}
								<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 400 }}>
									{t('appraisal.miAppraisal')}
								</Text>
							</Radio>
						</Col>
					</Row>
				)}

				{valueRadio !== 'h1' &&
					!_.isNil(valueRadio) &&
					existAppraisal === false &&
					mortgageData &&
					mortgageData.reportAppraisal !== 'bank' && (
						<div style={{ marginTop: 24 }}>
							<Upload {...uploadProps}>
								<Button
									onClick={() => handleClickPlus('appraisal')}
									style={{
										width: 104,
										height: 104,
										backgroundColor: 'white',
										borderRadius: '4px'
									}}>
									<div
										style={{
											marginBottom: '20px',
											marginTop: '10px',
											marginBottom: 5
										}}>
										<PlusOutlined style={{ color: '#02C3CD', fontSize: 20 }} />
									</div>
									<div
										style={{
											fontSize: '12px',
											whiteSpace: 'break-spaces',
											color: '#2F4858',
											fontWeight: 400
										}}>
										{t('document.attach')}
									</div>
								</Button>
							</Upload>
						</div>
					)}
				{existAppraisal && appraisal && appraisal.type === 'appraisal' && (
					<div style={{ marginTop: 24 }}>
						<Button
							className="gibobs-document-button"
							style={{
								width: 104,
								height: 104,
								backgroundColor: 'white',
								borderRadius: '4px',
								borderColor: !_.isNil(appraisal.page) ? '#52C41A' : undefined
							}}
							onClick={() =>
								!_.isNil(appraisal.page) ? downloadApprasal() : setShowModalDelete(true)
							}>
							{!_.isNil(appraisal.page) && (
								<div
									style={{
										width: '100%',
										textAlign: 'right',
										marginTop: -4,
										marginLeft: 5
									}}>
									<CheckOutlined
										style={{
											color: '#52C41A'
										}}
									/>
								</div>
							)}
							<div
								style={{
									marginBottom: '10px',
									marginTop: '5px',
									marginLeft: '-5px'
								}}>
								<FilePdfOutlined style={{ fontSize: '20px', color: '#748EA0' }} />
							</div>

							<Text
								style={{
									color: '#748EA0',
									fontSize: 12,
									fontWeight: 400
								}}>
								{t('stages.appraisal')}
							</Text>
						</Button>
					</div>
				)}

				{!existAppraisal && (
					<Row style={{ marginTop: !_.isNil(mortgageData?.appraiser) ? 20 : 28 }}>
						{valueRadio === 'h1' && !_.isNil(mortgageData?.appraiser) && (
							<>
								<div style={{ marginTop: 16 }}>
									<Text
										style={{
											fontSize: 14,
											fontWeight: 400,
											color: '#748EA0'
										}}>
										{isClientPay === false
											? t('appraisal.availableAppraisal')
											: t('m_appraisal.paymentDone')}
									</Text>
								</div>
								<div style={{ marginTop: 16 }}>
									<div style={{ display: 'flex' }}>
										<Text
											style={{
												color: '#2F4858',
												fontWeight: 400,
												fontSize: 14
											}}>
											{t('appraisal.appraiser')}
										</Text>
										&nbsp;
										<Text
											style={{
												color: '#02C3CD',
												fontWeight: 400,
												fontSize: 14
											}}>
											{mortgageData?.appraiser}
										</Text>
									</div>
									<div style={{ display: 'flex', marginBottom: 20 }}>
										<Text
											strong
											style={{
												color: '#2F4858',
												fontWeight: 600,
												fontSize: 14
											}}>
											{t('appraisal.price')}
										</Text>
										&nbsp;
										<Text
											style={{
												color: '#02C3CD',
												fontWeight: 600,
												fontSize: 14
											}}>
											{mortgageData?.appraisalAmount + '€'}
										</Text>
									</div>
								</div>
							</>
						)}
						{isClientPay === false && (
							<Col span={24}>
								<Radio
									value={'h1'}
									checked={mortgageData && mortgageData.reportAppraisal === 'h1' ? true : false}
									onChange={(e) => {
										saveDataOnClick('mortgage', 'reportAppraisal', e.target.value);
										setValueRadio(e.target.value);
									}}>
									<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 400 }}>
										{t('appraisal.gibobs')}
									</Text>
								</Radio>
							</Col>
						)}
						{valueRadio === 'h1' && _.isNil(mortgageData?.appraiser) && (
							<div style={{ marginTop: 16 }}>
								<Text
									style={{
										fontSize: 14,
										fontWeight: 400,
										color: '#748EA0'
									}}>
									{t('appraisal.infoGibobs')}
								</Text>
							</div>
						)}
					</Row>
				)}
			</div>
		);
	};

	const renderDescOption2 = () => {
		if (appraisal && mortgageData?.reportAppraisal !== 'h1') {
			if (!_.isNil(appraisal.page)) {
				return (
					<Text
						style={{
							fontSize: 14,
							fontWeight: 400,
							color: '#748EA0'
						}}>
						{t('m_appraisal.ownPendingDescription')}
					</Text>
				);
			} else {
				return undefined;
			}
		} else {
			if (appraisal && mortgageData?.reportAppraisal === 'h1') {
				return (
					<Text
						style={{
							fontSize: 14,
							fontWeight: 400,
							color: '#748EA0'
						}}>
						{t('appraisal.availableFile')}
					</Text>
				);
			}
		}
	};

	const payOk = () => {
		dispatch(showPay(true));
	};

	return (
		<div style={{ padding: showPayBoolean ? undefined : 16 }}>
			{pageNumber == 1 && (
				<div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							marginTop: 16
						}}>
						<div style={{ marginRight: 16, cursor: 'pointer' }} onClick={() => setPageNumber(2)}>
							<img src={leftArrowIcon} alt="leftArrowIcon" height={14} />
						</div>
						<Text style={{ fontSize: 16, fontWeight: 600, color: '#2F4858' }}>
							{t('document.filePendding')}
						</Text>
					</div>

					<DocumentationNew userMortgageId={operationId} stage={'appraisal'} />
				</div>
			)}
			{!showPayBoolean && pageNumber === 2 && (
				<div>
					<Steps
						direction="vertical"
						size="small"
						status="error"
						current={appraisal && !_.isNil(appraisal.page) ? 1 : 0}
						className="StepsMobileAppraisal">
						<Step
							style={{ color: '#b3b3b3' }}
							title={
								<Text
									style={{
										fontSize: 16,
										fontWeight: 400,
										color: '#02C3CD'
									}}>
									{t('appraisal.infoAppraisal')}
								</Text>
							}
							description={renderSelect()}></Step>
						<Step
							style={{ color: '#b3b3b3' }}
							title={
								<Text
									style={{
										fontSize: 16,
										fontWeight: 400,
										color: appraisal && !_.isNil(appraisal.page) ? '#02C3CD' : '#C0DAEE'
									}}>
									{t('appraisal.bank')}
								</Text>
							}
							description={renderDescOption2()}></Step>
					</Steps>
				</div>
			)}
			{!showPayBoolean && (
				<div style={{ marginTop: 40 }}>
					<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858' }}>
						{t('offers.previousDocument')}
					</Text>
				</div>
			)}
			{!showPayBoolean && (
				<Card className="gcardMovile-12-padding" style={{ marginTop: 12 }}>
					<Row>
						<Col span={2}>
							<FileOutlined style={{ color: '#02C3CD', fontSize: 20 }} />
						</Col>
						<Col span={20}>
							<Text style={{ fontSize: 14, fontWeight: 400, color: '#2F4858' }}>
								{t('offers.selected')}
							</Text>
						</Col>
						<Col span={2}>
							<a
								href={`${env.api.url}/v1/banks/generate-pdf-bank/${bankOffer}?token=${userToken}`}
								target={'_blank'}>
								<DownloadOutlined style={{ color: '#2F4858', fontSize: 20 }} />
							</a>
						</Col>
					</Row>
				</Card>
			)}
			{valueRadio === 'h1' &&
				!showPayBoolean &&
				!isClientPay &&
				!_.isNil(mortgageData?.appraisalAmount) && (
					<Card
						className="cardButtonStyle"
						style={{
							left: 0,
							marginBottom: -2,
							backgroundColor: '#FAFEFE',
							zIndex: 10000
						}}>
						<Button
							id="gb-mortgage-buy-situation-mobile"
							className="button-primari-gibobs"
							type="primary"
							onClick={() => payOk()}
							style={{
								fontSize: 16,
								width: '100%',
								height: 48,
								fontWeight: 600
							}}
							htmlType="submit">
							{t('appraisal.pay')}
						</Button>
					</Card>
				)}
			{showPayBoolean && dataMortgage && (
				<div style={{ marginBottom: 200 }}>
					<CheckOutComponent
						amount={dataMortgage?.mortgage?.appraisalAmount}
						showPayBoolean={showPayBoolean}
						mortgageId={operationId}
						clientSecretToken={dataClient?.paymentSeat?.session?.client_secret}
						dataClient={dataClient}
					/>
				</div>
			)}
			<Modal
				className="modal-documents-movile"
				width={328}
				open={showModalDelete}
				closable={true}
				onCancel={() => {
					setShowModalDelete(false);
				}}
				okButtonProps={{
					className: 'button-primari-gibobs',
					style: { width: '48%' }
				}}
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { width: '48%' }
				}}
				onOk={() => deleteDocument()}>
				<div style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
					<Text style={{ fontWeight: 600, color: '#2F4858', fontSize: 16 }}>
						{t('delete.fileAppraisal')}
					</Text>
				</div>
				<div style={{ marginTop: 24 }}>
					<Text style={{ fontWeight: 400, color: '#2F4858', fontSize: 14 }}>
						{t('delete.fileAppraisal2')}
					</Text>
				</div>
			</Modal>
		</div>
	);
};

export default AppraisalMobile;
