import React, { useEffect, useState } from "react";
import { Select, Form, Button, Modal, Input, Row, Col } from "antd";
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { notificationGibobs } from '../../HerramientasVenta/reusableItems';
import axios from "axios";
import env from '../../../environment';
const financingPhoto = require("../../../assets/promoter/imageTablet.png");
const DownOutlinedMobile = require("../../../assets/icons/DownOutlinedMobile.svg");

const { Option } = Select;

export type ReduxStore = {
  auth: StoreAuth;
  promoter: StorePromoter;
};

export type StoreAuth = {
  token: string;
};

export type StorePromoter = {
  dataFormPromoter: any;
  dataScenarios: any;
  dataListMyProyects: any;
}

const WelcomePageMobile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{ operationId: string }>();
  const operationId = params.operationId;
  const [form] = Form.useForm();
  const [constructionProporsal, setConstructionProporsal] = useState('');
  const [constructionUse, setConstructionUse] = useState('');
  const [modalContactOpen, setModalContactOpen] = useState(false);
  const userToken = useSelector<ReduxStore, string>((state) => state.auth.token);
  const dataPromoter = useSelector<ReduxStore, any>((state) => state.promoter.dataFormPromoter);

  const openNotificationWithIcon = () => {
    notificationGibobs('error', t('m_full.errorTitle'), t('form.incompleteInformation'));
  };

  useEffect(() => {
    setConstructionProporsal(
      dataPromoter &&
        dataPromoter.constructionData &&
        dataPromoter.constructionData.constructionPurpose
        ? dataPromoter.constructionData.constructionPurpose
        : ''
    );
    setConstructionUse(
      dataPromoter &&
        dataPromoter.constructionData &&
        dataPromoter.constructionData.constructionUseTypes
        ? dataPromoter.constructionData.constructionUseTypes
        : ''
    );
  }, [dataPromoter]);

  const validateMessages = {
    required: '${label}' + ' ' + t('form.formRequired'),
    types: {
      email: t('form.emailValidated'),
      number: '${label} is not a valid number!'
    },
    number: {
      range: t('form.maxBudgetNewRequestValidated') + '${min}'
    }
  };

  const sendConstructionData = (values: any, general: string, type: string) => {
    if (values.houseType === undefined) {
      values.houseType = 'NO_FAMILY';
    }
    axios
      .post(`${env.api.url}/promoters/promoter-projects/construction-data/${operationId}`, values, {
        headers: { Authorization: `Bearer ${userToken}` }
      })
      .then((response) => {
        if (type === undefined) {
          navigate(`/promoter-loan/general-data/${operationId}`);
          form.resetFields();
        }
        if (type !== undefined) {
          setModalContactOpen(true);
        }
      })
      .catch((error) => {
        notificationGibobs('error', t('promoterLoan.ModalTextError'));
      });
    if (type === undefined) {
      navigate(`/promoter-loan/general-data/${operationId}`);
      form.resetFields();
    }
    if (type !== undefined) {
      setModalContactOpen(true);
    }
  };

  const sendContactData = (values: any) => {
    axios
      .post(
        `${env.api.url}/promoters/promoter-projects/contact-data/${operationId}?origin=RENTING`,
        values,
        {
          headers: { Authorization: `Bearer ${userToken}` }
        }
      )
      .then((response) => {
        if (response.data.success) {
          notificationGibobs('success', t('promoterLoan.ModalTextSuccess'));
        }
        if (!response.data.success) {
          notificationGibobs('error', t('promoterLoan.ModalTextError'));
        }
      })
      .catch((error) => {
        notificationGibobs('error', t('promoterLoan.ModalTextError'));
      });
    navigate('/');
    form.resetFields();
  };

  const onCreate = (values: any, value?: string, type?: string) => {
    if (value === 'general') {
      sendConstructionData(values, value, type);
    } else {
      sendContactData(values);
    }
  };

  const formVerification = (values: any) => {
    if (constructionProporsal === 'SALE' && constructionUse === 'RESIDENTIAL') {
      onCreate(values, 'general');
    } else {
      onCreate(values, 'general', 'case2');
    }
  };

  return (
    <>
      <img
        src={financingPhoto}
        alt="financing"
        style={{
          height: 'auto',
          width: '100%',
        }} />
      <div style={{ padding: 16 }}>
        <div style={{ marginTop: 8, fontSize: 16, fontWeight: 600, color: '#2F4858' }}>
          {t('promoterLoan.welcomeModalTitle')}
        </div>
        <div style={{ marginTop: 8, fontSize: 14, fontWeight: 400, color: '#748EA0' }}>
          {t('promoter.projectFinanceScenarios')}<br />
          {t('promoter.startDataProvide')}
        </div>
        <Form 
          form={form}
          className="form-promoter-mobile"
          style={{ minHeight: '50vh' }}
          onFinishFailed={openNotificationWithIcon}
          scrollToFirstError={true}
          onFinish={formVerification}
          layout="vertical"
          name="Password"
          validateMessages={validateMessages}
          initialValues={
            dataPromoter && dataPromoter.constructionData
              ? { ...dataPromoter['constructionData'] }
              : {}
          }>
          <Form.Item
            name="constructionPurpose"
            label={t('promoterLoan.constructionProporsal')}
            rules={[
              {
                required: true
              }
            ]}>
            <Select
              suffixIcon={<img src={DownOutlinedMobile.default} alt="DownOutlinedMobile" />}
              defaultValue={t('form.select')}
              style={{ width: '100%' }}
              onChange={(e) => setConstructionProporsal(e)}>
              <Option value="SALE">{t('promoterLoan.saleTitle')}</Option>
              <Option value="RENTAL">{t('promoterLoan.rentalTitle')}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="constructionUseTypes"
            label={t('promoterLoan.constructionUse')}
            rules={[
              {
                required: true
              }
            ]}>
            <Select
              suffixIcon={<img src={DownOutlinedMobile.default} alt="DownOutlinedMobile" />}
              defaultValue={t('form.select')}
              style={{ width: '100%' }}
              onChange={(e) => setConstructionUse(e)}>
              <Option value="RESIDENTIAL">{t('promoterLoan.residentialTitle')}</Option>
              <Option value="OTHER">{t('promoterLoan.otherTitle')}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="houseType"
            label={t('promoterLoan.typology')}
            rules={[
              {
                required: constructionUse === 'RESIDENTIAL' ? true : false
              }
            ]}>
            <Select
              suffixIcon={<img src={DownOutlinedMobile.default} alt="DownOutlinedMobile" />}
              defaultValue={t('form.select')}
              style={{ width: '100%' }}
              disabled={constructionUse !== 'RESIDENTIAL' ? true : false}>
              <Option value="SINGLE_FAMILY">{t('promoterLoan.singleFamily')}</Option>
              <Option value="MULTI_FAMILY">{t('promoterLoan.multiFamily')}</Option>
            </Select>
          </Form.Item>
          <div style={{
            position: 'fixed',
            width: '100%',
            height: 80,
            bottom: 0,
            left: 0,
            boxShadow: '0px -2px 8px 0px rgba(47, 72, 88, 0.10)',
            background: '#fff',
            zIndex: 999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 16
          }}>
            <Button
              htmlType="submit"
              type="primary"
              style={{
                width: '100%',
                height: 48,
                borderRadius: 8
              }}>
              {t('init.continueButton')}
            </Button>
          </div>
        </Form>
      </div>
      <Modal
        className="ModalCommon"
        title={null}
        visible={modalContactOpen}
        footer={null}
        onCancel={() => setModalContactOpen(false) }>
        <Form
          form={form}
          className='form-promoter-mobile'
          style={{ marginBottom: 10 }}
          onFinishFailed={openNotificationWithIcon}
          scrollToFirstError={true}
          layout="vertical"
          name="Password"
          validateMessages={validateMessages}
          initialValues={dataPromoter && dataPromoter.owner ? { ...dataPromoter['owner'] } : {}}>
          <div style={{ paddingTop: 40 }}>
            <div className="title-primary" style={{ marginBottom: 8 }}>
              {t('promoterLoan.contactForm')}
            </div>
            <div className="paragraph-style" style={{ marginBottom: 16 }}>
              {t('promoterLoan.workingOnTheseOptions')}
            </div>
            <Form.Item
              name="phone"
              label={t('form.phone')}
              rules={[
                {
                  required: true
                }
              ]}>
              <Input placeholder={t('form.phone')} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name="contactScheduleTypes" label={t('form.contactTime')}>
              <Select
                defaultValue={t('form.select')}
                style={{ width: '100%'}}
                suffixIcon={<img src={DownOutlinedMobile.default} alt="DownOutlinedMobile" />}>
                <Option value="MORNINGS">{t('form.morning')}</Option>
                <Option value="NOON">{t('form.afternoon')}</Option>
                <Option value="AFTERNOONS">{t('form.evening')}</Option>
              </Select>
            </Form.Item>
            <Form.Item name="comments" label={t('form.comments')}>
              <TextArea
                placeholder={t('form.comments')}
                style={{
                  width: '100%'
                }}
              />
            </Form.Item>
          </div>
          <Row gutter={8}>
            <Col span={12}>
              <Button 
                className='button-secundary-gibobs' 
                style={{ width: '100%', borderRadius: 8 }}
                onClick={() => setModalContactOpen(false)}>
                {t('form.previous')}
              </Button>
            </Col>
            <Col span={12}>
              <Button 
                className='button-primari-gibobs'
                style={{ width: '100%', borderRadius: 8 }}
                onClick={() => {
                  form
                    .validateFields()
                    .then((values) => {
                      onCreate(values);
                    })
                    .catch((info) => {
                      console.log('Validate Failed:', info);
                    });
                }}>
                {t('form.send')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default WelcomePageMobile;
