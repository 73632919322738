import React from "react";
import { Routes, Route } from "react-router-dom";
import BasicStage from "./Pages/BasicStage";

export default () => (
  <Routes>
    <Route
      path="/operations/:operationId/:stage"
      element={ <BasicStage />}
    />
  </Routes>
);
