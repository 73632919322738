import React, { useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { ModalTwoStages, ModalWelcomeProject } from "./ModalWelcomeProject";
import { useNavigate } from "react-router-dom";


const WelcomePage = ({  }) => {
  const navigate = useNavigate();;
  const [modalDescription, setModalDescription] = useState(true);
  const [modalForm, setModalForm] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  return (
    <>
      <div style={{ margin: 40 }}>
        {pageNumber === 1 && (
          <ModalWelcomeProject
            key={modalDescription}
            visible={modalDescription}
            onCreate={() => {
              setModalDescription(false);
              setModalForm(true);
            }}
            onCancel={() => {
              setModalDescription(false);
              navigate("/");
            }}
            width={!isMobileOnly && 640}
            nextStage={() => setPageNumber(2)}
          />
        )}
        {pageNumber === 2 && (
          <ModalTwoStages
            key={modalForm}
            visible={modalForm}
            onCreate={() => setModalForm(false)}
            previousStage={() => setPageNumber(1)}
            onCancel={() => {
              setModalForm(false);
              setModalDescription(true);
            }}
            width={!isMobileOnly && 480}
            setModalForm={(e) => setModalForm(e)}
          />
        )}
      </div>
    </>
  );
};

export default WelcomePage;
