import React from 'react';
import { Typography, Card, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import initCard from '../../assets/loans/mortgageImage.png';
import initCard2 from '../../assets/loans/subrogationImage.png';

import numeral from 'numeral';


const { Text } = Typography;

function WidgetsCardMobileDataMortgage({
	dataMortgage1,
	handleClickContinueHp,
	styleConstOperations
}) {
	const { t } = useTranslation();

	const colSpan = {
		xxl: 6,
		xl: 6,
		lg: 8,
		md: 12,
		sm: 24,
		xs: dataMortgage1.length === 1 ? 24 : 20
	};

	return (
		<div style={{ display: 'flex', overflowX: 'scroll' }}>
			{dataMortgage1.map((dataMortgage) => {
				return (
					dataMortgage.type !== 'certificate' && ( // widget con datos HP
						<Col {...colSpan} style={{ marginRight: -15 }}>
							<Card
								className="init-card-mobile"
								onClick={() => handleClickContinueHp(dataMortgage)}
								cover={
									<>
										<img
											src={dataMortgage.subtype === 'subrogation' ? initCard2 : initCard}
											alt="initCard"
											style={{ width: '100%', height: 'auto' }}
										/>
									</>
								}>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<div style={{ display: 'flex', width: '100%' }}>
										<Text
											style={{
												fontSize: 16,
												fontWeight: 600,
												marginTop: 8,
												color: '#2F4858'
											}}>
											{t(`initCardMortgage.${dataMortgage.subtype}`)}
										</Text>
									</div>

									<div style={{ display: 'flex' }}>
										{(dataMortgage.subtype === 'green-mortgage' ||
											dataMortgage.subtype === 'mortgage') && (
											<Text
												style={{
													fontSize: 16,
													fontWeight: 600,
													color: '#1890FF',
											
												}}>
												{numeral(dataMortgage.amount).format('0,0 $')}
											</Text>
										)}

										{(dataMortgage.subtype === 'subrogation' ||
											dataMortgage.subtype === 'green-subrogation') && (
											<Text
												style={{
													fontSize: 16,
													fontWeight: 600,
													color: '#1890FF',
													
												}}>
												{dataMortgage !== undefined
													? numeral(dataMortgage.amount).format('0,0 $')
													: numeral('0').format('0,0 $')}
											</Text>
										)}
									</div>
									<div
										style={{
											display: 'flex',
											marginTop: 20,
											flexDirection: 'column',
											marginBottom: 70
										}}>
										<div className="calculators-widget-subtitle">
											{t('init.' + dataMortgage.stage + 'DescriptionWidget')}
										</div>
									</div>

									<Button
										onClick={() => handleClickContinueHp(dataMortgage)}
										type="primary"
										className="button-primari-gibobsNew"
										style={styleConstOperations}>
										{t('init.pickUpWhereILeftOff')}
									</Button>
								</div>
							</Card>
						</Col>
					)
				);
			})}
		</div>
	);
}

export default WidgetsCardMobileDataMortgage;
