import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Form, Select, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { renderComponent } from '../../../select/typeSelect';
import axios from 'axios';
import env from '../../../environment';
import _ from 'lodash';
import { InputNumber } from 'afrodita';
import { isMobile } from 'react-device-detect';

const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const CalculatorQuoteComponent = ({
	setResult,
	price,
	savings,
	province,
	formValues,
	setPrice,
	fixed,
	quotes,
	idSimulation
}) => {
	const location = useLocation();
	const { t } = useTranslation();
	const rol = useSelector((state) => state.auth.roles);
	const navigate = useNavigate();;
	const [form] = Form.useForm();
	const [countries, setContries] = useState();
	const [, setHouseType] = useState(undefined);
	const [participants, setParticipants] = useState(
		formValues && formValues.activity !== undefined ? '1' : '2'
	);
	const lang = localStorage.getItem('i18nextLng');
	const userToken = useSelector((state) => state.auth.token);
	const [resultTmp, setResultTmp] = useState(undefined);
	const [participants2, setParticipants2] = useState(undefined);
	const [timeoutReference, setTimeoutReference] = useState(undefined);

	useEffect(() => {
		axios.get(`${env.api.url}/v1/countries`).then((response) => {
			setContries(response.data.data);
		});
		if (rol === 'realestate') {
			getData(location.state.result.id);
		} else {
			getData(idSimulation);
			setParticipants(formValues && formValues.activity2 === undefined ? '1' : '2');
		}
	}, []);

	const getData = (id) => {
		axios
			.get(`${env.api.url}/mortgages/simulations/get-simulation/${id}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setResultTmp(response.data.data);
				setResult(response.data.data);
				setParticipants(response.data.data.simulationData.owner2 !== undefined ? '2' : '1');
				if (response.data.data.simulationData.owner2) {
					setParticipants2(response.data.data.simulationData.owner2);
				}
				setPrice(response.data.data.simulationData.maxBudget);

				
				navigate(`/calculators/fee`,{
					state: {
						result: response.data.data,
						formValues: form.isFieldsTouched() ? form.getFieldsValue() : location.state.formValues
					}
				});
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const participantsTabs = [];

	const renderTabs = (index) => {
		return (
			<TabPane tab={<span>{t(`onboarding.owner${index}`)}</span>} key={`${index}`}>
				{renderForm(index)}
			</TabPane>
		);
	};

	const renderForm = (data) => {
		return (
			<>
				<Row style={{ width: '100%' }}>
					<Col span={24}>
						<Form.Item
							name={`activity${data}`}
							style={{ width: '100%' }}
							label={t('form.activity')}>
							<Select id={`activity${data}`} placeholder={t('form.activity')}>
								{renderComponent('activity').options.map((situation) => {
									return (
										<Option key={situation.value} value={situation.value}>
											{t(situation.label)}
										</Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item name={`seniority${data}`} label={t('form.seniority')}>
							<InputNumber
								defaultValue={''}
								className='gb-input-new-years'
								max={40}
								gbType='number'
								id={`seniority${data}`}
								placeholder={t('0')}
								style={{
									width: '100%'
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							name={`netIncomes${data}`}
							label={
								rol === 'realestate' ? t('form.netIncomesOwner') : t('form.netIncomesOwnerInmo')
							}>
							<InputNumber
								defaultValue={''}
								id={`netIncomes${data}`}
								step={100}
								gbDecimalType="integer"
								gbType="currency"
								suffix={'€/' + t('viability.monthsEstimated')}
								placeholder={t('0')}
								style={{
									borderColor: '#c0daee',
									width: '100%',
									borderRadius: '4px'
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							name={`loanPayment${data}`}
							label={
								<div>
									<div>
										{rol === 'realestate'
											? t('onboarding.haveOtherLoans')
											: t('calculator.haveLoansInmo')}
									</div>
									<div className="explination-others-incomes" style={{ marginTop: 1 }}>
										<Text style={{ color: '#748EA0', fontSize: 10, fontWeight: 400 }}>
											{t('form.haveLoansInmo0')}
										</Text>
									</div>
								</div>
							}>
							<InputNumber
								id={`loanPayment${data}`}
								defaultValue={''}
								suffix={'€/' + t('viability.monthsEstimated')}
								step={100}
								gbDecimalType="integer"
								gbType="currency"
								placeholder={t('0')}
								style={{
									borderColor: '#c0daee',
									width: '100%',
									borderRadius: '4px'
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item name={`age${data}`} label={t('calculator.age')}>
							<InputNumber
								id={`age${data}`}
								defaultValue={''}
								className='gb-input-new-years'
								step={1}
								min={18}
								max={70}
								gbType='number'
								placeholder={t('calculator.age')}
								style={{
									borderColor: '#c0daee',
									width: '100%',
									borderRadius: '4px'
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item name={`cityzenship${data}`} label={t('form.country')}>
							<Select
								id="country"
								showSearch
								placeholder={t('form.country')}
								style={{ width: '100%' }}
								filterOption={(input, option) => {
									return (
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
										option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
									);
								}}>
								{countries &&
									countries.countries.map((paises) => {
										return (
											<Option key={paises.code} value={paises.code}>
												{lang === 'en' ? paises.name_en : paises.name_es}
											</Option>
										);
									})}
							</Select>
						</Form.Item>
					</Col>
				</Row>
			</>
		);
	};

	for (let i = 1; i <= parseInt(participants); i++) {
		participantsTabs.push(renderTabs(i));
	}

	const onChangeForm = (values, allValues) => {
		let owners = [];
		let housetypetmp = undefined;

		if (allValues.participants === '2') {
			housetypetmp = values.houseType;
			owners = [
				{
					activity: !_.isNil(allValues.activity1)
						? allValues.activity1
						: resultTmp.simulationData['owner1'].activity1,
					seniority: !_.isNil(allValues.seniority1)
						? allValues.seniority1
						: resultTmp.simulationData['owner1'].seniority1,
					netIncomes: !_.isNil(allValues.netIncomes1)
						? allValues.netIncomes1
						: resultTmp.simulationData['owner1'].netIncomes1,
					loanPayment: !_.isNil(allValues.loanPayment1)
						? allValues.loanPayment1
						: resultTmp.simulationData['owner1'].loanPayment1,
					age: !_.isNil(allValues.age1) ? allValues.age1 : resultTmp.simulationData['owner1'].age1,
					cityzenship: !_.isNil(allValues.cityzenship1)
						? allValues.cityzenship1
						: resultTmp.simulationData['owner1'].cityzenship1
				},
				{
					activity: !_.isNil(allValues.activity2)
						? allValues.activity2
						: _.isNil(resultTmp?.simulationData['owner2']?.activity)
						? participants2?.activity
						: resultTmp?.simulationData['owner2']?.activity,
					seniority: !_.isNil(allValues.seniority2)
						? allValues.seniority2
						: _.isNil(resultTmp?.simulationData['owner2']?.seniority)
						? participants2?.seniority
						: resultTmp?.simulationData['owner2']?.seniority,
					netIncomes: !_.isNil(allValues.netIncomes2)
						? allValues.netIncomes2
						: _.isNil(resultTmp?.simulationData['owner2']?.netIncomes)
						? participants2?.netIncomes
						: resultTmp?.simulationData['owner2']?.netIncomes,
					loanPayment: !_.isNil(allValues.loanPayment2)
						? allValues.loanPayment2
						: _.isNil(resultTmp?.simulationData['owner2']?.loanPayment)
						? participants2?.loanPayment
						: resultTmp?.simulationData['owner2']?.loanPayment,
					age: !_.isNil(allValues.age2)
						? allValues.age2
						: _.isNil(resultTmp?.simulationData['owner2']?.age)
						? participants2?.age
						: resultTmp?.simulationData['owner2']?.age,
					cityzenship: !_.isNil(allValues.cityzenship2)
						? allValues.cityzenship2
						: _.isNil(resultTmp?.simulationData['owner2']?.cityzenship)
						? participants2?.cityzenship
						: resultTmp?.simulationData['owner2']?.cityzenship
				}
			];
		} else {
			housetypetmp = values.houseType;
			owners = [
				{
					activity: !_.isNil(allValues.activity1)
						? allValues.activity1
						: resultTmp.simulationData['owner1'].activity1,
					seniority: !_.isNil(allValues.seniority1)
						? allValues.seniority1
						: resultTmp.simulationData['owner1'].seniority1,
					netIncomes: !_.isNil(allValues.netIncomes1)
						? allValues.netIncomes1
						: resultTmp.simulationData['owner1'].netIncomes1,
					loanPayment: !_.isNil(allValues.loanPayment1)
						? allValues.loanPayment1
						: resultTmp.simulationData['owner1'].loanPayment1,
					age: !_.isNil(allValues.age1) ? allValues.age1 : resultTmp.simulationData['owner1'].age1,
					cityzenship: !_.isNil(allValues.cityzenship1)
						? allValues.cityzenship1
						: resultTmp.simulationData['owner1'].cityzenship1
				}
			];
		}

		if (timeoutReference) {
			clearTimeout(timeoutReference);
		}

		setTimeoutReference(
			setTimeout(() => {
				axios
					.post(
						`${env.api.url}/mortgages/simulations/quota-and-viability/mortgage-viability`,
						{
							id:
								rol !== 'realestate'
									? idSimulation
									: !_.isNil(location.state)
									? location.state.result.id
									: undefined,
							maxBudget: price,
							simulationType: rol === 'realestate' ? 'B2B' : 'B2C',
							savings: savings,
							propertyType:
								rol !== 'realestate'
									? resultTmp?.simulationData?.propertyType
									: allValues.propertyType !== undefined
									? allValues.propertyType
									: location.state.result.simulationData.propertyType,
							province:
								province !== undefined ? province : location.state.result.simulationData.province,
							fixed: values.fixed !== undefined ? values.fixed : fixed,
							quotes: values.quotes !== undefined ? values.quotes : quotes,

							participants:
								owners[0].cityzenship !== undefined
									? {
											houseType:
												rol !== 'realestate'
													? resultTmp?.simulationData?.houseType
													: housetypetmp !== undefined
													? housetypetmp
													: location?.state?.result?.simulationData?.houseType,

											participants: parseInt(allValues.participants),
											owners: owners
									  }
									: undefined
						},
						{
							headers: { Authorization: `Bearer ${userToken}` }
						}
					)
					.then((response) => {
						getData(response.data.data.simulation.id);
					})
					.catch((error) => {
						console.log(error)
					});
				clearTimeout(timeoutReference);
			}, 500)
		);
	};

	return (
		<>
			<Form
				labelAlign="left"
				colon={false}
				className="gb-form-profile-headlines-mobile"
				labelCol={{
					flex: !isMobile ? '150px' : '50%'
				}}
				labelWrap
				
				layout="horizontal"
				scrollToFirstError={true}
				form={form}
				name="basic"
				onValuesChange={(values, allValues) => onChangeForm(values, allValues)}
				initialValues={{
					price:
						resultTmp?.simulationData !== undefined ? resultTmp?.simulationData?.maxBudget : 180000,
					savings:
						resultTmp?.simulationData !== undefined ? resultTmp?.simulationData?.savings : 55000,
					propertyType:
						resultTmp?.simulationData !== undefined
							? resultTmp?.simulationData?.propertyType
							: 'secondHand',
					activity: formValues.activity !== undefined ? formValues.activity : formValues.activity1,
					age: formValues.age !== undefined ? formValues.age : formValues.age1,
					cityzenship:
						formValues.cityzenship !== undefined ? formValues.cityzenship : formValues.cityzenship1,
					houseType:
						formValues.houseType !== undefined ? formValues.houseType : formValues.houseType1,
					loanPayment:
						formValues.loanPayment !== undefined ? formValues.loanPayment : formValues.loanPayment1,
					netIncomes:
						formValues.netIncomes !== undefined ? formValues.netIncomes : formValues.netIncomes1,
					participants:
						formValues.participants !== undefined
							? formValues.participants
							: formValues.participants1,
					seniority:
						formValues.seniority !== undefined ? formValues.seniority : formValues.seniority1,
					...formValues
				}}>
				<Row style={{ width: '100%', marginTop: 16 }}>
					<Col span={24}>
						<Form.Item name="houseType" label={t('viability.expectedUse')}>
							<Select
								placeholder={t('loan.sincronizationChosse')}
								style={{ width: '100%', height: 40 }}
								onChange={(value) => {
									setHouseType(value);
								}}>
								<Option value="main">{t('form.main')}</Option>
								<Option value="second">{t('form.second')}</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							name="participants"
							label={
								rol === 'realestate'
									? t('calculator.viabilityAlone2')
									: t('calculator.viabilityAlone')
							}>
							<Select
								placeholder={t('loan.sincronizationChosse')}
								style={{ width: '100%', height: 40 }}
								onChange={(value) => {
									setParticipants(value);
								}}>
								<Option value="1">{t('calculator.formAlone')}</Option>
								<Option value="2">{t('calculator.formAccompanied')}</Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>

				{participants === '2' && (
					<Tabs defaultActiveKey="1" centered>
						{participantsTabs}
					</Tabs>
				)}
				{participants === '1' && (
					<Row gutter={24} style={{ padding: 12, marginTop: -16 }}>
						{renderForm('1')}
					</Row>
				)}
			</Form>
		</>
	);
};

export default CalculatorQuoteComponent;
