import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import {
  LikeOutlined,
  BulbOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
const { Text } = Typography;

function DetailsScoring({ viable, titulo, name }) {
  const { t } = useTranslation();
  let iconsViable;
  let textAdvices;

  const typeText = {
    calm: {
      assetDiversification: t("scoring.calmDescriptionAssetDiversification"),
      assetStability: t("scoring.calmDescriptionAssetStability"),
      debtAveragePrice: t("scoring.calmDescriptionDebtAveragePrice"),
      incomeReleased: t("scoring.calmDescriptionIncomeReleased"),
      liquidity: t("scoring.calmDescriptionLiquidity"),
      ltv: t("scoring.calmDescriptionLtv"),
      stressTest: t("scoring.calmDescriptionStressTest"),
      yield: t("scoring.calmDescriptionYield"),
    },
    issue: {
      assetDiversification: t("scoring.issueDescriptionAssetDiversification"),
      assetStability: t("scoring.issueDescriptionAssetStability"),
      debtAveragePrice: t("scoring.issueDescriptionDebtAveragePrice"),
      incomeReleased: t("scoring.issueDescriptionIncomeReleased"),
      liquidity: t("scoring.issueDescriptionLiquidity"),
      ltv: t("scoring.issueDescriptionLtv"),
      stressTest: t("scoring.issueDescriptionStressTest"),
      yield: t("scoring.issueDescriptionYield"),
    },
    noData: {
      assetDiversification: t("scoring.noDataDescriptionAssetDiversification"),
      assetStability: t("scoring.noDataDescriptionAssetStability"),
      debtAveragePrice: t("scoring.noDataDescriptionDebtAveragePrice"),
      incomeReleased: t("scoring.noDataDescriptionIncomeReleased"),
      liquidity: t("scoring.noDataDescriptionLiquidity"),
      ltv: t("scoring.noDataDescriptionLtv"),
      stressTest: t("scoring.noDataDescriptionStressTest"),
      yield: t("scoring.noDataDescriptionYield"),
    },
  };

  if (viable && viable.status === "calm") {
    iconsViable = (
      <LikeOutlined style={{ marginRight: "9px", color: "#52C41A" }} />
    );
    textAdvices = typeText.calm[name];
  } else if (viable && viable.status === "issue") {
    iconsViable = (
      <BulbOutlined style={{ marginRight: "9px", color: "#FAAD14" }} />
    );
    textAdvices = typeText.issue[name];
  } else {
    iconsViable = (
      <QuestionOutlined style={{ marginRight: "9px", color: "#02C3CD" }} />
    );
    textAdvices = typeText.noData[name];
  }

  return (

    <div style={{ marginRight: "30px", marginLeft: "30px" }}>
      <Text
        style={{
          fontSize: "14px",
          marginBottom: "40px",
          fontWeight: "500",
          color: "#2F4858",
        }}
      >
        {iconsViable}

        {t(titulo)}
      </Text>
      <div
        style={{
          width: "260px",
          height: "auto",
          marginBottom: "40px",
          marginTop: "16px",
        }}
      >
        <Text style={{
          color: "#02AFB8",
          fontSize: 12,
          lineHeight: 1.5715
        }}>
          {textAdvices}
        </Text>
      </div>
    </div>

  );
}

export default DetailsScoring;
