import React from "react";
import { Typography } from "antd";
import { EuroCircleOutlined } from "@ant-design/icons";
import PieClosedSavings from "./graficas/pieClosedSavings";
import numeral from "numeral";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const SavingCapacity = ({ yearSavings, monthSavings, freeIncome }) => {
  const { t } = useTranslation();
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ marginBottom: "16px" }}>
          <Text
            strong
            style={{
              color: "#2F4858",
              fontSize: "14px",
            }}
          >
            {t("balance.savingCapacityTitle")}
          </Text>
        </div>
        <div
          style={{
            marginBottom: "16px",
            width: "200px",
            marginRight: "20px",
          }}
        >
          <Text
            style={{
              color: "#748EA0",
              fontSize: "12px",
            }}
          >
            {t("balance.savingCapacityDescription")}
          </Text>
        </div>
      </div>

      <div style={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}>
        <PieClosedSavings freeIncome={freeIncome} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >

          <div
            style={{
              display: "flex",
              marginTop: "8px",
            }}
          >
            <EuroCircleOutlined
              style={{
                color: "#2F4858",
                fontSize: "28px",
                marginRight: "8px",
                alignSelf: "center",
              }}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex" }}>

                <Text
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    color: "#748EA0",
                    marginTop: "20px",
                  }}
                >
                  {t("balance.youSavings")}
                </Text>
              </div>
              <div style={{ display: "flex" }}>
                <Text
                  strong
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#2F4858",
                    marginRight: "4px",
                  }}
                >
                  {numeral(monthSavings).format("0,0 $")}
                </Text>
                <Text
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#748EA0",
                    alignSelf: "center",
                  }}
                >
                  {t("balance.savingsToMonth")}
                </Text>
              </div>
              <div style={{ display: "flex" }}>
                <Text
                  strong
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#2F4858",
                    marginRight: "4px",
                  }}
                >
                  {numeral(yearSavings).format("0,0 $")}
                </Text>
                <Text
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#748EA0",
                    alignSelf: "center",
                  }}
                >
                  {t("balance.savingsToYear")}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavingCapacity;
