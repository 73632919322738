import React from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import greenMortgageIcon from "../../../../assets/icons/greenMortgageIcon.svg";
import activeIcon from "../../../../assets/icons/activeIcon.svg";
import lockIcon from "../../../../assets/icons/lockIcon.svg";
import mortgageIcon from "../../../../assets/icons/mortgageIcon.svg";
import subrogationIcon from "../../../../assets/icons/SubrogacionMobile.svg";
import greenSubrogationIcon from "../../../../assets/icons/SubrrogacionVerde.svg";
import blueRightArrowIcon from "../../../../assets/icons/blueRigthArrowIcon.svg";

const BreadcrumbsInmo = ({ name, status, operationType, eco, bol, type }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();;
  const titleTypeOperationName = {
    mortgage: "product.mortgage",
    undefined: "product.mortgage",
    subrogation: "operationTypes.subrogation",
    "green-mortgage": "operationTypes.green-mortgage",
    "green-subrogation": "operationTypes.green-subrogation",
    basic: "Stages.basic",
    full: "menuComponent.full",
    documents: "document.documentation",
    analysis: "document.analystics",
    banks: "document.bank",
    oferts: "offers.offert",
    appraisal: "appraisal.appraisal",
    formalization: "formalization.formalization",
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: isMobile ? 16 : "4px 40px 24px 40px",
        paddingBottom: 0,
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          onClick={() => navigate("/sales-tools/follow-up", [bol, type])}
        >
          <div
            style={{
              height: 24,
              width: 24,
              textAlign: "center",
            }}
          >
            <img
              src={blueRightArrowIcon}
              alt="blueRightArrowIcon"
              height={12}
            />
          </div>
        </div>
        <div
          style={{
            color: "#02C3CD",
            fontSize: 16,
            fontWeight: 600,
            marginLeft: 16,
          }}
        >
          {name}
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {!isMobile && !eco && (
          <div
            style={{
              color: "#02C3CD",
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            {t(titleTypeOperationName[operationType])}
            {/* {t("m_operationSubtypes.certificateRent")} */}
          </div>
        )}

        {!eco &&
          (operationType === "mortgage" ? (
            <div
              style={{
                height: 24,
                width: 24,
                textAlign: "center",
                marginLeft: 8,
              }}
            >
              <img src={mortgageIcon} alt="mortgageIcon" height={18} />
            </div>
          ) : operationType === "subrogation" ? (
            <div
              style={{
                height: 24,
                width: 24,
                textAlign: "center",
                marginLeft: 8,
              }}
            >
              <img src={subrogationIcon} alt="subrogationIcon" height={18} />
            </div>
          ) : operationType === "green-mortgage" ? (
            <div
              style={{
                height: 24,
                width: 24,
                textAlign: "center",
                marginLeft: 8,
              }}
            >
              <img
                src={greenMortgageIcon}
                alt="greenMortgageIcon"
                height={18}
              />
            </div>
          ) : operationType === "green-subrogation" ? (
            <div
              style={{
                height: 24,
                width: 24,
                textAlign: "center",
                marginLeft: 8,
              }}
            >
              <img
                src={greenSubrogationIcon}
                alt="greenSubrogationIcon"
                height={18}
              />
            </div>
          ) : (
            <div
              style={{
                height: 24,
                width: 24,
                textAlign: "center",
                marginLeft: 8,
              }}
            >
              <img src={mortgageIcon} alt="mortgageIcon" height={18} />
            </div>
            // <FileOutlined
            //   style={{
            //     color: "#02C3CD",
            //     fontSize: 18,
            //     fontWeight: 600,
            //     marginRight: 16,
            //   }}
            // />
          ))}

        {!eco &&
          (status === "finish" ? (
            <div
              style={{
                height: 24,
                width: 24,
                textAlign: "center",
                marginLeft: 8,
              }}
            >
              <img src={lockIcon} alt="lockIcon" height={18} />
            </div>
          ) : (
            <div
              style={{
                height: 24,
                width: 24,
                textAlign: "center",
                marginLeft: 8,
              }}
            >
              <img src={activeIcon} alt="activeIcon" height={18} />
            </div>
          ))}
      </div>
    </div>
  );
};
export default BreadcrumbsInmo;
