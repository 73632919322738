import React from 'react';
import { Carousel } from 'antd';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import notificationEs from '../../../../assets/loans/Notificacion_esp.png';
import notificationEn from '../../../../assets/loans/Notificacion_eng.png';
import notificationCat from '../../../../assets/loans/Notificacion_cat.png';
import calculatorEs from '../../../../assets/loans/CalcularCuota_esp.png';
import calculatorEn from '../../../../assets/loans/CalcularCuota_eng.png';
import calculatorCat from '../../../../assets/loans/CalcularCuota_cat.png';
import resultEs from '../../../../assets/loans/Resultado_esp.png';
import resultEn from '../../../../assets/loans/Resultado_eng.png';
import resultCat from '../../../../assets/loans/Resultado_cat.png';
import capitalEs from '../../../../assets/loans/Capital_esp.png';
import capitalEn from '../../../../assets/loans/Capital_eng.png';
import capitalCat from '../../../../assets/loans/Capital_cat.png';
import quotaEs from '../../../../assets/loans/Cuota_esp.png';
import quotaEn from '../../../../assets/loans/Cuota_eng.png';
import quotaCat from '../../../../assets/loans/Cuota_cat.png';
import termEs from '../../../../assets/loans/Plazo_esp.png';
import termEn from '../../../../assets/loans/Plazo_eng.png';
import termCat from '../../../../assets/loans/Plazo_cat.png';
import propertyEs from '../../../../assets/loans/Propiedad_esp.png';
import propertyEn from '../../../../assets/loans/Propiedad_eng.png';
import propertyCat from '../../../../assets/loans/Propiedad_cat.png';
import { isMobileOnly } from 'react-device-detect';

const SliderDashboard = () => {
	const { t } = useTranslation();
	const cardContent = (title, image) => {
		return (
			<div className="wrapper-slider" style={{ margin: isMobileOnly && 10 }}>
				<div className="title-card-slider">{t(title)}</div>
				<img src={image} alt={`${image}`} width={220} />
			</div>
		);
	};

	const arrayData = [
		{
			title: 'loan.improveYourMortgage',
			image:
				i18n.language === 'es' || i18n.language === 'es_ES'
					? notificationEs
					: i18n.language === 'en' || i18n.language === 'en_EN'
					? notificationEn
					: notificationCat
		},
		{
			title: 'loan.paymentAccordingToEuribor',
			image:
				i18n.language === 'es' || i18n.language === 'es_ES'
					? calculatorEs
					: i18n.language === 'en' || i18n.language === 'en_EN'
					? calculatorEn
					: calculatorCat
		},
		{
			title: 'loan.interestedInAmortization',
			image: i18n.language === 'es' || i18n.language === 'es_ES' ? resultEs : i18n.language === 'en' || i18n.language === 'en_EN' ? resultEn : resultCat
		},
		{
			title: 'loan.howMuchHaveRepaidToBank',
			image: i18n.language === 'es'|| i18n.language === 'es_ES' ? capitalEs : i18n.language === 'en' || i18n.language === 'en_EN' ? capitalEn : capitalCat
		},
		{
			title: 'loan.quotaWithBonds',
			image: i18n.language === 'es'|| i18n.language === 'es_ES' ? quotaEs : i18n.language === 'en' || i18n.language === 'en_EN'? quotaEn : quotaCat
		},
		{
			title: 'loan.quotasDoYouLeft',
			image: i18n.language === 'es'|| i18n.language === 'es_ES' ? termEs : i18n.language === 'en' || i18n.language === 'en_EN'? termEn : termCat
		},
		{
			title: 'loan.percentagePaid',
			image: i18n.language === 'es'|| i18n.language === 'es_ES' ? propertyEs : i18n.language === 'en' || i18n.language === 'en_EN' ? propertyEn : propertyCat
		}
	];
	return (
		<div
			style={{
				height: 'auto',
				padding: isMobileOnly && 16,
				overflow: 0
			}}>
			<div className="title-slider">{t('loan.findInThisSection')}</div>
			{!isMobileOnly && (
				<div class="container-slider">
					<div class="faders">
						<div class="left"></div>
						<div class="right"></div>
					</div>
					<div className="items-slider">
						{arrayData &&
							arrayData.map((data, ) => {
								return cardContent(data.title, data.image);
							})}
					</div>
				</div>
			)}

			{isMobileOnly && (
				<div>
					<Carousel autoplay className="carousel-property" dots={true}>
						{arrayData &&
							arrayData.map((data, ) => {
								return <div>{cardContent(data.title, data.image)}</div>;
							})}
					</Carousel>
				</div>
			)}
		</div>
	);
};

export default SliderDashboard;
