import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import euro from '../../../../assets/loans/euro.svg';
import chebron from '../../../../assets/loans/chebron.png';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useNavigate } from 'react-router-dom';
import numeral from 'numeral';
import { isMobile } from 'react-device-detect';

const AmortizationCalculatorWidget = ({ loan }) => {
	const { t } = useTranslation();
	const analytics = getAnalytics();
	const navigate = useNavigate();;

	return (
		<>
			<Card
				className="gcardMovile card-heigth-loan"
				style={{ marginTop: isMobile ? 14 : 18, height: 'calc(100% - 36px)' }}>
				<div
					style={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between'
					}}>
					<img src={euro} alt="euro" height={46} />
					<div
						style={{
							color: '#748EA0',
							fontSize: 14,
							fontWeight: 600,
							marginTop: 16
						}}>
						{t('loan.descriptionAmortizationWidget')}
					</div>
					<div
						style={{
							marginTop: 16,
							display: 'flex',
							cursor: 'pointer',
							alignItems: 'center',
							justifyContent: 'right'
						}}>
						<div
							style={{
								fontSize: 14,
								color: '#02C3CD',
								fontWeight: 500
							}}
							onClick={() => {
								logEvent(analytics, 'screen_view', {
									screen_name: 'loanDetail.amortizationCalculator'
								});
								const values = {
									pendingCapital: loan.loan.amount,
									pendingQuotes: numeral(loan.loan.term).format('0,0'),
									interestRateType: loan.loan.rateType === 'variable' ? 'VARIABLE' : 'FIXED',
									actualTin: loan.loan.rateType === 'variable' ? loan.loan.spread : loan.loan.rate,
									id: loan.loan.id
								};


								
								navigate(`/calculators/amortization`,{
									state: { valuesCalculator: values }
								});
							}}>
							{t('loan.linkAmortizationWidget')}
						</div>
						<img
							src={chebron}
							alt="asdf"
							style={{
								width: 16,
								height: 16,
								marginTop: 0,
								marginLeft: 6
							}}></img>
					</div>
				</div>
			</Card>
		</>
	);
};

export default AmortizationCalculatorWidget;
