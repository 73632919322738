import React, { useRef, useEffect, useState } from "react";
import { Chart } from "@antv/g2";
import { useTranslation } from "react-i18next";

function ProgressBars({
  basic,
  full,
  documents,
  analysis,
  banks,
  offers,
  appraisal,
  formalization,
}) {
  const { t } = useTranslation();
  const [chart, setChart] = useState();
  const chartRef = useRef();
  const data = [
    { type: t("stages.formalization"), value: formalization, cat: "all" },
    { type: t("appraisal.appraisal"), value: appraisal, cat: "all" },
    { type: t("document.analystics"), value: analysis, cat: "all" },
    { type: t("offers.offert"), value: offers, cat: "all" },
    { type: t("stages.banks"), value: banks, cat: "all" },
    { type: t("stages.documents"), value: documents, cat: "all" },
    { type: t("stages.profile"), value: full, cat: "all" },
    { type: t("Stages.basic"), value: basic, cat: "Datos Basicos" },
  ];

  let maxValue = Math.max(
    basic,
    full,
    documents,
    analysis,
    banks,
    offers,
    appraisal,
    formalization
  );
  if (maxValue === 1) {
    maxValue *= 3;
  }
  if (maxValue > 1) {
    maxValue *= 1;
  }

  useEffect(() => {
    const chart = new Chart({
      container: chartRef.current,
      autoFit: true,
      padding: [20, 0, 50, 100],
      autoPadding: true,
    });
    chart.data(data);
    chart.scale({
      value: {
        max: maxValue,
        min: 0,
        alias: "Número de operaciones",
      },
    });
    chart.scale({
      value: {
        max: maxValue,
        min: 0,
        alias: "Número de operaciones",
      },
    });
    chart.axis("type", {
      tickLine: null,
      line: null,
      label: {
        autoHide: false,
      },
    });

    chart.legend(false);
    chart.coordinate("rect").transpose();
    chart.interval().position("type*value").color("type", ["#7DD353"]).size(10);

    setChart(chart);

    chart.render();
  }, []);

  useEffect(() => {
    if (chart) {
      chart.data(data);
      chart.scale({
        value: {
          max: maxValue + 1,
          min: 0,
          alias: "Número de operaciones",
        },
      });
      chart.render(true);
    }
  }, [
    basic,
    full,
    documents,
    analysis,
    banks,
    offers,
    appraisal,
    formalization,
    chart,
  ]);

  return (
    <div
      ref={chartRef}
      style={{
        height: "180px",
        width: "500px",
      }}
      id="realestate-dashboard-operations"
    ></div>
  );
}

export default ProgressBars;
