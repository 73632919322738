import React from "react";
import { Layout } from "antd";
import { isMobile } from "react-device-detect";
import CertificateMobile from "./CertificateMobile.js";
import Certificate from "./Certificate";

const OperationCertificated = () => {
  if (isMobile) {
    return <CertificateMobile />;
  } else {
    return (
      <Layout>
        <Certificate />
      </Layout>
    );
  }
};

export default OperationCertificated;
