import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import axios from "axios";
import env from "./environment";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    call: axios
      .get(`${env.api.url}/v1/languages/app`, {})
      .then((response) => {
        console.log(response);
        i18n.addResourceBundle("cat", "translation", response.data.data.cat);
        i18n.addResourceBundle("es", "translation", response.data.data.es);
        i18n.addResourceBundle("en", "translation", response.data.data.en);
      })
      .catch((error) => {}),

    fallbackLng: "es",
    wait: false,
    debug: true,
    saveMissing: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

i18n.on("missingKey", (lngs, namespace, key, res) => {
  console.log("miss", lngs, namespace, key, res);
});

export default i18n;
