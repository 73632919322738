import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import numeral from "numeral";

const SalesTable = ({ data }) => {
  const { t } = useTranslation();
  const nameType = {
    MULTI_FAMILY: t("promoterLoan.multiFamily"),
    SINGLE_FAMILY: t("promoterLoan.singleFamily"),
  };
  const dataSource = [
    {
      key: 1,
      name: t("promoterLoan.incomePerUnit"),
      age: nameType[data && data.single && data.single.tipology],
      address: numeral(data && data.single && data.single.unit).format("0,0"),
      costes: numeral(data && data.single && data.single.squareMeter).format(
        "0,0"
      ),
      ingresos: numeral(
        data && data.single && data.single.moneyPerSquareMeter
      ).format("0,0"),
      sell: numeral(data && data.single && data.single.price).format("0,0"),
    },
    {
      key: 2,
      name: t("balance.totalTitle"),
      age: nameType[data && data.total && data.total.tipology],
      address: numeral(data && data.total && data.total.unit).format("0,0"),
      costes: numeral(data && data.total && data.total.squareMeter).format(
        "0,0"
      ),
      ingresos: numeral(
        data && data.total && data.total.moneyPerSquareMeter
      ).format("0,0"),
      sell: numeral(data && data.total && data.total.price).format("0,0"),
    },
    {
      key: 3,
      name: "",
      age: "",
      address: "",
      costes: "",
      ingresos: t("promoterLoan.unitsReserved"),
      sell: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            {data &&
              data.preSoldUnits &&
              numeral(data.preSoldUnits.unit).format("0,0.0")}
          </div>
          <div>
            {data &&
              data.preSoldUnits &&
              `${numeral(data.preSoldUnits.percentageUnit).format("0,0.0")}%`}
          </div>
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: t("balance.incomesTitle"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("promoterLoan.typology"),
      dataIndex: "age",
      key: "age",
      align: "right",
    },
    {
      title: t("promoterLoan.units"),
      dataIndex: "address",
      key: "address",
      align: "right",
    },
    {
      title: "m²",
      dataIndex: "costes",
      key: "address",
      align: "right",
    },
    {
      title: "€/m²",
      dataIndex: "ingresos",
      key: "address",
      align: "right",
    },
    {
      title: t("promoterLoan.salesTitle") + " (€)",
      dataIndex: "sell",
      key: "address",
      align: "right",
    },
  ];

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      rowClassName={(record) => {
        const classes = ["textStyleTable"];
        if (record.key === 2) {
          classes.push("textStyleTableOfferSelect");
        }

        if (record.key === 2) {
          classes.push("textStyleTotal");
        }
        return classes;
      }}
      className="table-style-general"
    />
  );
};

export default SalesTable;
