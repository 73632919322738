import { notification, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

const notificationStyle = {
	warning: { backgroundColor: '#D4EAFF', border: '1px solid #52ACFF' },
	success: { backgroundColor: '#DFF4D4', border: '1px solid #52C41A' },
	error: { backgroundColor: '#FFDEDE', border: '1px solid #FF4D4F' }
};

export const commonNotifications = (
	type: 'success' | 'error' | 'warning',
	title: string,
	description: string
) => {
	notification[type]({
		message: (
			<div
				style={{
					fontSize: '16px',
					color: type === 'success' ? '#3E9314' : type === 'warning' ? '#1890FF' : '#FF4D4F',
					fontWeight: 600
				}}>
				{title}
			</div>
		),
		description: <div style={{ fontSize: '12px', color: '#2F4858' }}>{description}</div>,
		placement: 'topRight',
		duration: type === 'warning' ? 2 : 4,
		icon: type === 'warning' && (
			<div
				className="wrapper-icons-render-cards"
				style={{
					marginTop: -4
				}}>
				<Spin indicator={antIcon} className="spin" />
			</div>
		),
		style: {
			...notificationStyle[type],
			borderRadius: 4,
			width: 480,
		}
	});
};
