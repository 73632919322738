import { Modal } from 'antd';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';


type Props = {
	visible: any;
	onCancel: any;
	onCreate: any;
	errorModal: any;
	
};

const ErrorAfterbanksModal = ({ visible, onCancel, onCreate, errorModal }: Props) => {
	const { t } = useTranslation();
	return (
		<Modal
			className={isMobileOnly ? 'ModalCommonSmall' : 'ModalCommon'}
			open={visible}
			title=""
			okText={t('certificated.buttonFinish')}
			cancelText={t('modalThanks.titleKOButton')}
			width={600}
			onCancel={() => onCancel()}
			onOk={() => onCreate()}
			okButtonProps={{
				type: 'dashed',
				className: 'button-secundary-gibobs button-error-afterbanks'
			}}
			cancelButtonProps={{
				className: 'button-primari-gibobs',
				type: 'primary',
				style: { width: '96%', height: 40 }
			}}
			closable={false}
			maskClosable={false}>
			<div style={{ textAlign: 'center' }}>
				<img
					src={errorModal}
					alt="ErrorIconAfterbanks"
					height={60}
					style={{ marginTop: 60 }}
				/>
				<div className="title-error-afterbanks">{t('doc.hasBeenErrorModalTitle')}</div>
				<div className="sub-title-error-afterbanks">{t('doc.hasBeenErrorModalDescription')}</div>
			</div>
		</Modal>
	);
};

export default ErrorAfterbanksModal;
