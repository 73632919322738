import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Layout, Card, Typography, Button, Col, Row } from 'antd';
import {
	FileOutlined,
	ExclamationOutlined,
	CheckOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import LayoutProducts from '../Products/LayoutProducts';
import { useSelector, useDispatch } from 'react-redux';
import numeral from 'numeral';
import Mortgage from '../../assets/Products/mortgage.png';
import MortgageGreen from '../../assets/Products/mortgageGreen.png';
import Subrogation from '../../assets/Products/subrogation.png';
import SubrogationGreen from '../../assets/Products/subrogationGreen.png';
import imageCertificate from '../../assets/Products/image-certificate.png';
import { isMobile, isMobileOnly } from 'react-device-detect';
import _ from 'lodash';
import CardLinkAmigobs from '../../components/widgets/CardLinksApps/CardLinkAmigobs';
import { dataSummary } from '../../store/data/actions';
import home_blue from '../../assets/init/home_blue.png';
import swap_blue from '../../assets/init/swap_blue.png';
import home_green from '../../assets/init/home_green.png';
import swap_green from '../../assets/init/swap_green.png';
import axios from 'axios';
import env from '../../environment';

const { Text } = Typography;

const OperationPage = () => {
	const { t } = useTranslation();
	const colSpan = { xxl: 6, xl: 6, lg: 8, md: 12, sm: 24, xs: 24 };

	let userOperations = useSelector((state) => state.auth.operations);
	userOperations = _.filter(
		userOperations,
		(d) => d.type === 'mortgage' || d.type === 'certificate'
	);
	let certificates2 =  _.filter(userOperations, (d) => d.type === 'certificate');
	const dispatch = useDispatch();
	const userToken = useSelector((state) => state.auth.token);
	const [dataOwnersDocs, setDataOwnersDocs] = useState(undefined);
	let navigate = useNavigate();

	useEffect(() => {
		dispatch(dataSummary(userToken));
		getDocumentsOwners(certificates2);
	}, []);

	const nameStages = (e) => {
		if (e === 'basic') {
			return t('stages.basic');
		} else if (e === 'full') {
			return t('stages.profile');
		} else if (e === 'documents') {
			return t('document.documentation');
		} else if (e === 'banks') {
			return t('document.bank');
		} else if (e === 'oferts') {
			return t('offers.offert');
		} else if (e === 'appraisal') {
			return t('stages.appraisal');
		} else if (e === 'formalization') {
			return t('stages.formalization');
		} else if (e === 'analysis') {
			return t('stages.analysisStep');
		}
	};

	const descriptionStages = (e) => {
		if (e === 'basic') {
			return t('m_temporalLine.basic');
		} else if (e === 'full') {
			return t('m_temporalLine.full');
		} else if (e === 'documents') {
			return t('m_temporalLine.documents');
		} else if (e === 'banks') {
			return t('m_temporalLine.banks');
		} else if (e === 'oferts') {
			return t('m_temporalLine.oferts');
		} else if (e === 'appraisal') {
			return t('m_temporalLine.appraisal');
		} else if (e === 'formalization') {
			return t('m_temporalLine.formalization');
		} else if (e === 'analysis') {
			return t('m_temporalLine.analysis');
		}
	};

	const typeMortgage = (operationType, houseState) => {
		if (
			operationType === 'mortgage' &&
			(houseState === 'have_decide' || houseState === 'searching')
		) {
			return Mortgage;
		} else if (operationType === 'green-mortgage') {
			return MortgageGreen;
		} else if (operationType === 'green-subrogation') {
			return SubrogationGreen;
		} else {
			return Subrogation;
		}
	};

	const getDocumentsOwners = (certificates) => {
		const requests = [];
		certificates.map((certificate) => {
			requests.push(
				axios.get(`${env.api.url}/v1/documents/pending/by-operation/${certificate.operationId}`, {
					headers: { Authorization: `Bearer ${userToken}` }
				})
			);
		});

		Promise.all(requests).then((values) => {
			if (values) {
				setDataOwnersDocs(values);
			}
		});
	};

	const renderParticipants = (certificate) => {
		let numberParticipants = certificate.owners.length;
		let array = [];
		const docs = _.filter(dataOwnersDocs, function (d) {
			return d.data.data.operationId === certificate.operationId;
		});

		const ownerTmp = docs[0].data.data;
		if (certificate.operationId === ownerTmp.operationId) {
			for (let i = 1; i <= numberParticipants; i++) {
				array.push(
					<div>
						<Text
							style={{
								color: '#2F4858',
								fontSize: 14,
								fontWeight: 400
							}}>
							{ownerTmp[`owner` + i] && ownerTmp[`owner` + i].length === 0 ? (
								<CheckOutlined style={{ color: '#52C41A' }} />
							) : (
								<ExclamationOutlined style={{ color: '#FAAD14' }} />
							)}
							&nbsp; {certificate[`owners`][i - 1].name}
						</Text>
					</div>
				);
			}
		}

		return array;
	};

	return (
		<Layout>
			<LayoutProducts />

			{isMobileOnly && <CardLinkAmigobs></CardLinkAmigobs>}
			<Row
				gutter={24}
				className="operationsClientsCard"
				style={{
					marginLeft: 0,
					marginRight: 0,
					marginBottom: isMobile ? 100 : undefined
				}}>
				{userOperations &&
					userOperations.map((infoMortgage) => {
						if (infoMortgage.type === 'mortgage') {
							return (
								<Col {...colSpan} style={{ height: 'auto', marginBottom: '20px' }}>
									<Card
										key={infoMortgage.id}
										style={{
											width: '100%',
											height: '100%',
											marginBottom: '20px',
											boxShadow: '0px 0px 8px rgba(47, 72, 88, 0.1)',
											borderRadius: '4px'
										}}
										cover={
											<img
												src={typeMortgage(
													infoMortgage?.type,
													infoMortgage?.houseState,
													infoMortgage?.type
												)}
												alt="mortgage"
												style={{
													width: '100%',
													height: 'auto',
													borderTopLeftRadius: '4px',
													borderTopRightRadius: '4px'
												}}
											/>
										}>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												height: '100%'
											}}>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-between'
												}}>
												<Text
													style={{
														color: '#2F4858',
														fontSize: '16px',
														fontWeight: '700',
														marginBottom: '10px'
													}}>
													{infoMortgage?.subtype === 'mortgage'
														? t('createOperations.youMortgage')
														: infoMortgage?.subtype === 'green-mortgage'
														? t('createOperations.youGreenMortgage')
														: infoMortgage?.subtype === 'subrogation'
														? t('createOperations.youSubrogation')
														: infoMortgage?.subtype === 'green-subrogation' &&
														  t('createOperations.youGreenSubrogation')}
												</Text>
												{infoMortgage?.subtype === 'mortgage' && (
													<img
														src={home_blue}
														alt="asdf"
														style={{
															position: 'absolute',
															right: 40,
															color: '#02C3CD',
															width: 32,
															height: 32
														}}
													/>
												)}

												{infoMortgage?.subtype === 'green-mortgage' && (
													<img
														src={home_green}
														alt="asdf"
														style={{
															width: 32,
															height: 32
														}}
													/>
												)}
												{infoMortgage?.subtype === 'subrogation' && (
													<img
														src={swap_blue}
														alt="asdf"
														style={{
															width: 32,
															height: 32
														}}
													/>
												)}
												{infoMortgage?.subtype === 'green-subrogation' && (
													<img
														src={swap_green}
														alt="asdf"
														style={{
															width: 32,
															height: 32
														}}
													/>
												)}
											</div>
											<Text
												style={{
													color: '#02C3CD',
													fontSize: '12px',
													fontWeight: '500'
												}}>
												{infoMortgage?.name}
											</Text>
											<Text
												style={{
													color: '#748EA0',
													fontSize: '12px',
													marginTop: '8px'
												}}>
												{infoMortgage?.owners?.[0]?.name ? infoMortgage?.owners?.[0]?.name : '-'}
											</Text>
											<div
												style={{
													marginTop: '16px'
												}}>
												<Text
													style={{
														color: '#2F4858',
														fontSize: '12px',
														fontWeight: '500'
													}}>
													{infoMortgage?.houseState === 'searching'
														? t('createOperations.searching')
														: t('init.requestedDeb')}
												</Text>
												<Text
													style={{
														color: '#02C3CD',
														fontSize: '12px',
														marginLeft: '4px',
														fontWeight: '500'
													}}>
													{infoMortgage?.houseState !== 'searching' &&
														infoMortgage?.houseState !== 'subrogation' &&
														numeral(infoMortgage?.amount).format('0,0 $')}
													{infoMortgage?.houseState === 'searching' &&
														infoMortgage?.viabilityProperty &&
														numeral(infoMortgage?.viabilityProperty).format('0,0 $')}
													{infoMortgage?.houseState === 'searching' &&
														!infoMortgage?.viabilityProperty &&
														'-'}
													{infoMortgage?.houseState === 'subrogation' &&
														numeral(infoMortgage?.amount).format('0,0 $')}
												</Text>
											</div>
											<Text
												style={{
													color: '#02C3CD',
													fontSize: '14px',
													marginTop: '16px',
													fontWeight: '600'
												}}>
												{nameStages(infoMortgage?.stage)}
											</Text>
											<Text
												style={{
													color: '#748EA0',
													fontSize: '12px',
													marginTop: '8px',
													fontWeight: '400',
													width: 'auto',
													marginBottom: '40px'
												}}>
												{descriptionStages(infoMortgage?.stage)}
											</Text>

											<Button
												onClick={() =>
													navigate(`/distributor`, {
														state: {
															dataOperation: infoMortgage
														}
													})
												}
												type="primary"
												className="button-primari-gibobs"
												style={{
													position: 'absolute',
													bottom: 32,
													width: 'calc(100% - 64px)'
												}}>
												{t('viability.myInterest')}
											</Button>
										</div>
									</Card>
								</Col>
							);
						} else {
							return (
								<Col {...colSpan} style={{ height: 'auto', marginBottom: '20px' }}>
									<Card
										key={infoMortgage.id}
										style={{
											width: '100%',
											height: '100%',
											marginBottom: '20px',
											boxShadow: '0px 0px 8px rgba(47, 72, 88, 0.1)',
											borderRadius: '4px',
											minHeight: '400px'
										}}
										cover={
											<img
												src={imageCertificate}
												alt="mortgage"
												style={{
													width: '100%',
													height: 'auto',
													borderTopLeftRadius: '4px',
													borderTopRightRadius: '4px'
												}}
											/>
										}>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												height: 'auto'
											}}>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-between'
												}}>
												<Text
													style={{
														color: '#2F4858',
														fontSize: '16px',
														fontWeight: '700',
														marginBottom: '10px'
													}}>
													{t('certificated.titlteDashboard')}
												</Text>
												<FileOutlined
													style={{
														fontSize: '24px',
														color: '#02C3CD'
													}}
												/>
											</div>
											<Text
												style={{
													color: '#02C3CD',
													fontSize: '14px',
													fontWeight: '500',
													marginBottom: 24
												}}>
												{infoMortgage.subname}
											</Text>

											{dataOwnersDocs && renderParticipants(infoMortgage)}

											{infoMortgage && infoMortgage.stage !== 'analysis' && (
												<Card
													className="gb-card-products"
													style={{
														background: '#FEF0D3',
														width: '100%',
														borderRadius: 4,
														border: '1px solid #FBC14F',
														// position: "relative", top: 16, bottom: isMobile ? 24 : 0
														marginBottom: 40,
														marginTop: 24
													}}>
													<Text
														style={{
															color: '#2F4858',
															fontSize: 12,
															fontWeight: 400
														}}>
														{t('certificated.cardInitDashboard')}
													</Text>
												</Card>
											)}
											<Button
												type="primary"
												className="button-primari-gibobs"
												style={{
													position: 'absolute',
													bottom: 32,
													width: 'calc(100% - 64px)'
												}}>
												<NavLink
													to={{
														pathname: `/operations/certificate/${infoMortgage.operationId}/${infoMortgage.stage}`
													}}>
													{t('viability.myInterest')}
												</NavLink>
											</Button>
										</div>
									</Card>
								</Col>
							);
						}
					})}
			</Row>
		</Layout>
	);
};

export default OperationPage;
