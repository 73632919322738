import React, { useEffect } from "react";
import { Spin } from "antd";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getPromoterData } from "../../store/promoter/actions";
import { LoadingOutlined } from "@ant-design/icons";

const LoadingComponent = ({ component }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const operationId = params.operationId;
  const dataPromoter = useSelector((state) => state.promoter.dataFormPromoter);
  const dataList = useSelector((state) => state.promoter.dataListMyProyects);
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

  useEffect(async () => {
    if (dataList !== 0) {
      const operation = dataList.filter((data) => data.id === operationId);
      const operationData =
        operation.length !== 0 && operation[0].operationDataClient;
      dispatch(getPromoterData(operationData));
    }
  }, [dataList]);

  return (
    <div>
      {Object.keys(dataPromoter).length !== 0 && component}
      {Object.keys(dataPromoter).length === 0 && (
        <Spin indicator={antIcon} className="spin" />
      )}
    </div>
  );
};

export default LoadingComponent;
