import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import env from '../../environment';
import { dataSummary } from '../../store/data/actions';
import ModalFriend from './ModalFriend';
import moment from 'moment';
import { isNil } from 'lodash';

const Distributor = ({ }) => {
	const varSesionStorage = sessionStorage.getItem('testA/B');
	const navigate = useNavigate();
	const userToken = useSelector((state) => state.auth.token);

	const operation = useSelector((state) => state.data.dataSummary[0]);
	const dispatch = useDispatch();
	const location = useLocation();

	const dataOperation =
		location && !isNil(location?.state)  ? location.state.dataOperation : undefined;

	const rol = useSelector((state) => state.auth.roles);

	let idMortgage =
		rol === 'realestate' ? '' : operation !== undefined ? operation.operationId : undefined;
	let stageId = rol === 'realestate' ? '' : operation !== undefined ? operation.stage : undefined;
	let typeMortgage =
		rol === 'realestate' ? '' : operation !== undefined ? operation.type : undefined;
	let subTypeMortgage =
		rol === 'realestate' ? '' : operation !== undefined ? operation.subtype : undefined;


	const [modalVisibility, setModalVisibility] = useState(false);
	const modalDate = localStorage.getItem('modalDate');
	const todayDate = moment().format('YYYY-MM-DD');
	const closeModal = () => {
		localStorage.setItem('modalDate', moment().format('YYYY-MM-DD'));
		setModalVisibility(false);
	}
	useEffect(() => {
		if (typeof operation === 'string') {
			dispatch(dataSummary(userToken));
		}
		if (rol === 'realestate') {
			navigate('/dashboard-business');
		} else {
			if ((operation === undefined || (operation.stage !== 'basic' && operation.stage !== 'documents')) && (modalDate === null || modalDate < todayDate)) {
				setModalVisibility(true)
			} else {
				if (typeof operation === 'string') {
					dispatch(dataSummary(userToken));
				} else {
					if (dataOperation !== undefined) {
						idMortgage = dataOperation.operationId;
						stageId = dataOperation.stage;
						typeMortgage = dataOperation.type;
						subTypeMortgage = dataOperation.subtype;
					}

						if (
							varSesionStorage === null &&
							operation &&
							typeMortgage !== 'certificate' &&
							stageId === 'basic'
						) {
							//llmar back para que me diga donde ir
							axios
								.post(
									`${env.api.url}/campaigns/runner/experiment/by-event`,
									{
										event: 'questions-mortgages',
										data: {
											operationId: idMortgage
										}
									},
									{
										headers: { Authorization: `Bearer ${userToken}` }
									}
								)
								.then((response) => {
									if (
										response.data.data.mortgageQuestions === '2022' &&
										subTypeMortgage !== 'green-subrogation' &&
										subTypeMortgage !== 'green-mortgage'
									) {
										navigate(`/testA/operations/${idMortgage}/${stageId}`);
									}
								});
						} else {
							if (operation !== undefined) {
								navigate(`/operations/${idMortgage}/${stageId}`);
							} else {
								navigate('/dashboard-client');
								sessionStorage.setItem("modalSub", false); // no tiene operacion no sale modal
							}
						}
				}
			}
		}
	}, [typeof operation === 'string', operation, modalVisibility]);

	if ((operation?.stage === undefined  ||
		 (operation.stage !== 'basic' && operation.stage !== 'documents'))
	 && (modalDate === null || modalDate < todayDate) && rol !== 'realestate') {
		return (
			<>
				<ModalFriend
					visible={modalVisibility}
					onCancel={closeModal}
				/>
			</>
		)
	}else {
		return false
	}
};

export default Distributor;
