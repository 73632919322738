import React, { useEffect, useState } from "react";
import { Col, Row, Card, Typography, Button, Spin } from "antd";
import Layout from "antd/lib/layout/layout";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { isMobile, isMobileOnly } from "react-device-detect";
import promoterImage from "../../assets/promoter/ImageP.png";
import projects from "../../assets/promoter/projects.svg";
import videoPromoter from "../../assets/promoter/VideoJess.png";
import { FileOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  changeRedirect,
  createNewProject,
  getListMyProyects,
} from "../../store/promoter/actions";
import { LoadingOutlined } from "@ant-design/icons";
import ModalInfoFunding from "./CommonComponents/ModalInfoFundings";

const { Text } = Typography;
const DashboardPromoter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();;
  const dispatch = useDispatch();
  const operationsClient = useSelector((state) => state.realestate.operations);
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
  const loading = useSelector((state) => state.promoter.loading);
  const dataNewProject = useSelector((state) => state.promoter.dataNewProject);
  const dataRedirect = useSelector((state) => state.promoter.redirect);
  const dataList = useSelector((state) => state.promoter.dataListMyProyects);
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    if (dataRedirect === true) {
      navigate(`/promoter-loan/step-form/${dataNewProject.id}`);
      dispatch(changeRedirect(!dataRedirect));
    }
  }, [dataRedirect]);

  useEffect(() => {
    if (dataList.length === 0) {
      dispatch(getListMyProyects());
    }
  }, []);



  const operationsCounter = (status) => {
    return (
      dataList &&
      dataList.filter(
        (data) => data.operationStatus === status && data.status !== "finish"
      ).length
    );
  };
  const operationsFinish = (status) => {
    return dataList && dataList.filter((data) => data.status === status).length;
  };
  const stagesCount = (title, quantity, color) => {
    return (
      <div
        style={{
          border: `1px solid ${color}`,
          borderLeft: `16px solid ${color}`,
          display: "flex",
          padding: "7px 0px",
          justifyContent: "center",
          marginBottom: 4,
          alignItems: "center",
        }}
      >
        <div className="paragraph-project-data">{title}:</div>
        <div
          className="title-project-data"
          style={{ marginLeft: 6, fontWeight: 600 }}
        >
          {quantity}
        </div>
      </div>
    );
  };

  const infoData = [
    {
      title: t("promoterLoan.promoterFinancingProducts"),
      description:!isMobileOnly ? t("promoterLoan.startProjectDescription") : '',
      action: () => dispatch(createNewProject()),
      image: promoterImage,
      titleButton: t('promoterLoan.newProject'),
      create: true,
    },
    {
      title: (
        <div>
          <img
            src={projects}
            alt="projects"
            height={18}
            style={{ marginRight: 16 }}
          />
          {t("promoterLoan.yourProjects")}
        </div>
      ),
      description: (
        <>
          <div style={{ marginBottom: 24 }}>
            {t("promoterLoan.summaryDescription")}
          </div>
          {stagesCount(
            t("promoter.projectsInProgress"),
            operationsCounter("active"),
            "#41D2DA"
          )}
          {stagesCount(
            t("promoter.standByProjects"),
            operationsCounter("postpone"),
            "#FBC14F"
          )}
          {stagesCount(
            t("promoter.projectsClosed"),
            operationsFinish("finish"),
            "#748EA0"
          )}
        </>
      ),
      action: "/promoter-loan/products-under-contract",
      titleButton: t("promoterLoan.myProjects"),
    },
    operationsClient.length === 0 && {
      title: (
        <div>
          <FileOutlined
            style={{ color: "#02C3CD", fontSize: 18, marginRight: 16 }}
          />
          {t("init.use")}
        </div>
      ),
      description: t("promoterLoan.usersGuideDescription"),
      promoter: true,
      titleButton: t("init.download"),
    },
  ];

  const colSpan = { xxl: 6, xl: 6, lg: 8, md: 12, sm: 24, xs: 24 };
  const styleConst = {
    margin: "auto",
    position: "absolute",
    bottom: 30,
    left: 0,
    right: 0,
    width: "calc(100% - 90px)",
    height: 40,
  };

  return (
    <Layout style={{ marginBottom: isMobile && 120 }}>
      <Row
        gutter={24}
        style={{
          rowGap: 24,
          marginTop: 40,
          width: isMobile && "calc(100% - 32px)",
          height: "auto",
          marginLeft: isMobile ? "16px" : "40px",
        }}
      >
        {infoData.map((data, index) => {
          return (
            <Col {...colSpan} key={index}>
              <Card
                className="init-card-promoter"
                cover={
                  <>
                    {data.image !== undefined && (
                      <img
                        src={data.image}
                        alt="initCard"
                        style={{ width: "100%", height: "auto" }}
                      />
                    )}
                  </>
                }
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text className="title-init-card">{data.title}</Text>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      marginBottom: 24,
                    }}
                  >
                    <Text className="description-product">
                      {data.description}
                    </Text>
                    <div
                      style={{ display: "flex", justifyContent: "center" }}
                    ></div>
                  </div>
                </div>
              </Card>
              {data.promoter && !data.create && (
                <>
                  <Button
                    type="dashed"
                    className="button-secundary-gibobs-inmo button-cards-new-styles"
                    style={styleConst}
                    target={"_blank"}
                    href="https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/guia_financiacion_promotor.pdf"
                  >
                    <div style={{ marginTop: 4 }}>{data.titleButton}</div>
                  </Button>
                </>
              )}
              {!data.promoter && !data.create && (
                <Button
                  onClick={() => navigate(`${data.action}`)}
                  type={index === 0 ? "primary" : "dashed"}
                  className={
                    index === 0
                      ? "button-primari-gibobsNew"
                      : "button-secundary-gibobs"
                  }
                  style={styleConst}
                >
                  {data.titleButton}
                </Button>
              )}
              {!data.promoter && data.create && !loading && (
                <div style= {{ marginTop: isMobileOnly && 16 }}>
                  <Button
                    onClick={() => dispatch(createNewProject())}
                    type={index === 0 ? "primary" : "dashed"}
                    className={
                      index === 0
                        ? "button-primari-gibobsNew"
                        : "button-secundary-gibobs"
                    }
                    style={
                      index === 0
                        ? {
                          margin: 'auto',
                          position: "absolute",
                          bottom: 54,
                          left: 0,
                          right: 0,
                          width: "calc(100% - 90px)",
                          height: 40,
                        }
                        : styleConst
                    }
                  >
                    {data.titleButton}
                  </Button>
                  <div 
                    onClick={() => setShowInfo(true)}
                    style={{ 
                      display: "flex", 
                      justifyContent: "center", 
                      bottom: 20, 
                      position: "absolute", 
                      width: "calc(100% - 48px)",
                      cursor: 'pointer' }}>
                    <Text style={{ color: "#02C3CD", fontSize: 14, fontWeight: 500 }}>
                      {t("promoter.whatSee")}
                    </Text>
                  </div>
                </div>
              )}
              {!data.promoter && data.create && loading && (
                <Spin indicator={antIcon} className="spin" style={styleConst} />
              )}
            </Col>
          );
        })}
        <Col {...colSpan}>
          <Card
            className="init-card-promoter"
            // style={{ background: "#C0F0F3" }}
            cover={
              <video
                controls
                height="100%"
                type="video/mp4"
                poster={videoPromoter}
                src="https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/videos/financiacion-promotor.mp4"
              ></video>
            }
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text
                style={{
                  fontSize: 16,
                  color: "#2F4858",
                  fontWeight: 600,
                }}
              >
                {t("promoterLoan.discoverGibobsAllbank")}
              </Text>

              <Text
                style={{
                  fontSize: 14,
                  color: "#2F4858",
                  fontWeight: 400,
                }}
              >
                {t("promoterLoan.whatMakesDifferents")}
              </Text>
            </div>
          </Card>
        </Col>

      </Row>
      <ModalInfoFunding visible={showInfo} setVisible={setShowInfo} />
    </Layout>
  );
};

export default DashboardPromoter;
