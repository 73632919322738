import React from 'react';
import { Form, Modal} from 'antd';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import numeral from 'numeral';

const SavingsCalculation = ({ visible, onCancel, onCreate }) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	

	const detailOptions = (name, quantity, condition, total) => {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					marginBottom: 12,
					columnGap: 8
				}}>
				{name && (
					<div
						className="amounts-name-mortgage"
						style={{ 
							fontSize: condition === 'total' ? 16 : 14,
							fontWeight: condition === 'total' ? 600 : 400 
						}}>
						{t(name)}
					</div>
				)}

				<div
					className="amounts-name-mortgage amounts-quantity"
					style={{
						fontWeight: condition === 'total' && 400,
						fontSize: condition === 'total' && isMobileOnly ? 14 : 16,
						// color: condition === '+' ? '#FF4D4F' : condition === '-' && '#52C41A'
						color: condition === 'market' && '#2F4858'
					}}>
					{quantity}
				</div>
				{total && (
					<div
						className="amounts-name-mortgage amounts-quantity"
						style={{
							fontWeight: condition === 'total' && 600,
							fontSize: 16,
							// color: condition === '+' ? '#FF4D4F' : condition === '-' && '#52C41A'
							color: condition === 'total' && '#52C41A'
						}}>
						{total}
					</div>
				)}
			</div>
		);
	};

	return (
        (<Modal
			centered
			zIndex={1200}
			className="ModalCommon modal-onboarding-amount"
			cancelButtonProps={{
				className: 'button-secundary-gibobs',
				style: { display: 'none' }
			}}
			okButtonProps={{
				className: 'button-primari-gibobs',
				style: { display: 'none' }
			}}
			open={visible}
			title={
				visible?.title === 'viabilityNew.typeFixed'
				    ? t('viabilityNew.fixedSavings')
					: visible?.title === 'viabilityNew.typeMixed'
					    ? t('viabilityNew.mixedSavings')
			            : t('viabilityNew.variableSavings') 
			}
			okText={t('viability.continue')}
			cancelText={t('mydocuments.deleteCancel')}
			width={480}
			onCancel={() => {
				onCancel();
				form.resetFields();
			}}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						onCreate(values);
						form.resetFields();
					})
					.catch((error) => {
						console.log(error)
					});
			}}>
            <div
				className="amounts-name-mortgage"
				style={{ color: '#748EA0', marginBottom: 18, fontSize: 14, marginTop: -30 }}
				dangerouslySetInnerHTML={{
					__html: t('viabilityNew.comparedAverage', {
						type: visible?.title === 'viabilityNew.typeFixed'
						    ? t('viabilityNew.fixed')
							: visible?.title === 'viabilityNew.typeMixed'
							    ? t('viabilityNew.mixed')
								: t('form.variable').toLowerCase(),
						type2: visible?.title === 'viabilityNew.typeFixed'
						    ? t('onboarding.fixedMortgage')
						    : visible?.title === 'viabilityNew.typeMixed'
							    ? t('viabilityNew.mixed2')
							    : t('form.variable').toLowerCase(),
						gibobsTae: numeral(visible?.tae*100).format('0,0.00') ,
						tae: numeral(visible?.marketRate * 100).format('0,0.00')
					})
				}}
			/>
            {/* <div
        className="amounts-name-mortgage"
        style={{ color: "#748EA0", marginBottom: 18 }}
      >
        {t("onboarding.comparedAverage")}{" "}
        {numeral(visible && visible.fixed).format("0,0.00")} % TAE*
      </div> */}
            {detailOptions(
				t('viabilityNew.marketShare'),
				numeral(visible?.marketCost).format('0,0 $'), //TODO:cambiar dato
				'market'
			)}
            {detailOptions(
				t('viabilityNew.costGibobs'),
				numeral(visible?.gibobsCost).format('0,0 $'), //TODO: cambiar dato
				'gibobs'
			)}
			<div style={{ border: '1px solid #C0DAEE', marginBottom: 12 }}></div>
			{detailOptions(
				t('viabilityNew.totalDifferenceGibobs'),
				undefined,
				'total',
				`${numeral(visible && visible.savings).format('0,0 $')}`
			)}
           
        </Modal>)
    );
};

export default SavingsCalculation;
