export const renderTitle = (rol, result) => {
	if (
		result?.simulationData?.resultViabilityMortage?.excepcionalViability === 'NOSAVINGS' &&
		rol === 'realestate'
	) {
		return 'onboarding.cantBuyWithoutSavingsInmo';
	} else if (
		result?.simulationData?.resultViabilityMortage?.excepcionalViability === 'NOSAVINGS' &&
		rol !== 'realestate'
	) {
		return 'onboarding.cantBuyWithoutSavings';
	} else if (
		result?.simulationData?.resultViabilityMortage?.excepcionalViability === 'DTI_OVER' &&
		rol === 'realestate'
	) {
		return 'onboarding.cantBuyWithoutSavingsInmo';
	} else if (
		result?.simulationData?.resultViabilityMortage?.excepcionalViability === 'DTI_OVER' &&
		rol !== 'realestate'
	) {
		return 'calculator.warnig3';
	} else if (
		result?.simulationData?.resultViabilityMortage?.excepcionalViability === 'INCOME_LEVEL' &&
		rol === 'realestate'
	) {
		return 'onboarding.cantBuyWithoutSavingsInmo';
	} else if (
		result?.simulationData?.resultViabilityMortage?.excepcionalViability === 'INCOME_LEVEL' &&
		rol !== 'realestate'
	) {
		return 'calculator.warnig5';
	} else {
		if (
			result?.simulationData?.resultViabilityMortage?.excepcionalViability === 'MORTGAGE_AMOUNT'
		) {
			return 'onboarding.ItsBetterTellTruth';
		}
	}
};

export const renderDesc = (rol, result) => {
	if (
		result?.simulationData?.resultViabilityMortage?.excepcionalViability === 'NOSAVINGS' &&
		rol === 'realestate'
	) {
		return 'onboarding.cantBuyWithoutSavingsDescriptionInmo';
	} else if (
		result?.simulationData?.resultViabilityMortage?.excepcionalViability === 'NOSAVINGS' &&
		rol !== 'realestate'
	) {
		return 'onboarding.cantBuyWithoutSavingsDescription';
	} else if (
		result?.simulationData?.resultViabilityMortage?.excepcionalViability === 'DTI_OVER' &&
		rol === 'realestate'
	) {
		return 'calculator.warnig4Inmo';
	} else if (
		result?.simulationData?.resultViabilityMortage?.excepcionalViability === 'DTI_OVER' &&
		rol !== 'realestate'
	) {
		return 'calculator.warnig4';
	} else if (
		result?.simulationData?.resultViabilityMortage?.excepcionalViability === 'INCOME_LEVEL' &&
		rol === 'realestate'
	) {
		return 'calculator.warnig6Inmo';
	} else if (
		result?.simulationData?.resultViabilityMortage?.excepcionalViability === 'INCOME_LEVEL' &&
		rol !== 'realestate'
	) {
		return 'calculator.warnig6';
	} else if (
		result?.simulationData?.resultViabilityMortage?.excepcionalViability === 'MORTGAGE_AMOUNT' &&
		rol === 'realestate'
	) {
		return 'onboarding.ItsBetterTellTruthDescription';
	} else if (
		result?.simulationData?.resultViabilityMortage?.excepcionalViability === 'MORTGAGE_AMOUNT' &&
		rol !== 'realestate'
	) {
		return 'onboarding.ItsBetterTellTruthDescription';
	}
};

export const renderLabelButton = (rol, result) => {
	if (result) {
		if (rol === 'realestate' && result?.simulationData?.resultViabilityMortage === undefined) {
			return 'calculator.clientInmoButton2';
		} else if (
			rol === 'realestate' &&
			result?.simulationData?.resultViabilityMortage !== undefined
		) {
			return 'calculator.clientInmoButton';
		} else if (
			rol !== 'realestate' &&
			result?.simulationData?.resultViabilityMortage === undefined
		) {
			return 'calculator.bankViabilityQuoteButton';
		} else {
			return 'calculator.iWannaMyMortgageButton';
		}
	} else {
		return 'calculator.clientInmoButton2';
	}
};
