import React from 'react';
import { Button, Card, Layout, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import RequestComponent from './requestComponent';
import LayoutTools from './LayountTools';
import { isMobile, isMobileOnly } from 'react-device-detect';
import Products from '../../assets/icons/certificateInmo.svg';
import Eco from '../../assets/icons/ecoBuilding.svg';
import mortgageIcon from '../../assets/icons/mortgageIcon.svg';
import subrogationIcon from '../../assets/icons/SubrogacionMobile.svg';
import FileOutlinedIcon from '../../assets/icons/FileOutlinedIcon.svg';
import checkIcon from '../../assets/icons/checkIcon.svg';
import calculatortmp from '../../assets/calculators/calculatortmp.png';
import calculatortmp2 from '../../assets/calculators/calculatortmp2.png';
import { NavLink } from 'react-router-dom';
import { EuroCircleOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

const NewRequestPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();;

	const typeRequest = [
		{
			title: 'createMortgageComponent.mortgageTitle',
			parrafo: 'init.descriptionMortgageSalesTools',
			icon: (
				<img
					src={mortgageIcon}
					alt="mortgageIcon"
					height={isMobile ? 18 : 21}
					style={{ marginBottom: isMobile ? 4 : 0 }}
				/>
			),
			url: '/sales-tools/mortgages',
			button: 'b2bOperationList.newMortgage'
		},
		{
			title: 'operationTypes.subrogation',
			parrafo: 'createMortgageComponent.descriptionSubrogationSalesTools',
			icon: (
				<img
					src={subrogationIcon}
					alt="subrogationIcon"
					height={isMobile ? 16 : 19}
					style={{ marginBottom: isMobile ? 4 : 0 }}
				/>
			),
			url: '/sales-tools/subrogations',
			button: 'newRequests.newSubrogation'
		},
		/*{
      title: "init.MortgageSimulator",
      parrafo: "init.MortgageSimulatorDescription",
      icon: (
        <img
          src={euroIcon}
          alt="euroIcon"
          height={isMobile ? 20 : 23}
          style={{ marginBottom: isMobile ? 4 : 0 }}
        />
      ),
      url: "/sales-tools/mortgage-calculator",
      button: "budget.pushBudget",
    },*/
		/*{
      title: "createMortgageComponent.preCertified",
      parrafo: "createMortgageComponent.descriptionPreCertifiedSalesTools",
      icon: (
        <img
          src={FileOutlinedIcon}
          alt="FileOutlinedIcon"
          height={isMobile ? 20 : 23}
          style={{ marginBottom: isMobile ? 4 : 0 }}
        />
      ),
      url: "/sales-tools/new-requests/pre-certified",
      button: "newRequests.newPrecertified",
    },*/

		{
			title: 'm_operationSubtypes.certificateBuy',
			parrafo: 'createMortgageComponent.descriptionCertificateSalesTools',
			icon: (
				<img
					alt="Certificate"
					src={Products}
					style={{
						height: isMobile ? 18 : 21,
						color: '#02C3CD',
						marginBottom: isMobile ? 4 : 0
					}}
				/>
			),
			url: '/sales-tools/certificated-buy-sell',
			button: 'newRequests.newCertificate'
		},
		{
			title: 'm_operationSubtypes.certificateRent',
			parrafo: 'createMortgageComponent.descriptionCertificateRentTools',
			icon: (
				<img
					src={FileOutlinedIcon}
					alt="FileOutlinedIcon"
					height={isMobile ? 20 : 23}
					style={{ marginBottom: isMobile ? 4 : 0 }}
				/>
			),
			url: '/sales-tools/certificated-rent',
			button: 'newRequests.newCertificate'
		},

		/*{
			title: 'createMortgageComponent.mortgageGreenTitle',
			parrafo: 'init.descriptionGreenMortgageSalesTools',
			icon: (
				<img
					src={greenMortgageIcon}
					alt="greenMortgageIcon"
					height={isMobile ? 18 : 21}
					style={{ marginBottom: isMobile ? 4 : 0 }}
				/>
			),
			url: '/sales-tools/mortgagesGreen',
			button: 'b2bOperationList.newMortgage',
			eco: true
		},*/
		{
			title: 'budget.budgetTitle',
			parrafo: 'form.descriptionSolarValueSalesRealEstate',
			icon: (
				<img
					alt="BudgetEco"
					src={Eco}
					style={{
						width: isMobile ? 20 : 21,
						color: '#02C3CD',
						marginBottom: isMobile ? 4 : 0
					}}
				/>
			),
			url: '/sales-tools/new-requests/budgetEco',
			// profile &&
			// (profile.email === "inmobiliaria@gibobs.com" ||
			//   profile.email === "israel.tena@gibobs.com")
			//   ? "/sales-tools/new-requests/budgetEco"
			//   : "/sales-tools/new-requests/solar-value-home",
			button: 'newRequests.newbudget',
			eco: true
		},
		{
			title: 'init.propertyAppraisal',
			parrafo: 'init.propertyAppraisalDescription',
			icon: (
				<img
					src={checkIcon}
					alt="checkIcon"
					height={isMobile ? 20 : 23}
					style={{ marginBottom: isMobile ? 4 : 0 }}
				/>
			),
			url: '/sales-tools/property-appraisal',
			button: 'init.propertyAppraisalButton'
		}
	];

	
	const showOptions = () => {
		
		
			return typeRequest.map((data, index) => {
				if (index <= 3) {
					return (
						<RequestComponent
							key={index}
							title={data.title}
							parrafo={data.parrafo}
							icon={data.icon}
							url={data.url}
							button={data.button}
							eco={data.eco}
						/>
					);
				}
			});
		
	};

	const calculatorsShow = () => {
		return (
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					rowGap: isMobile ? 8 : 16,
					columnGap: isMobile ? 8 : 16
				}}>
				<Card
					cover={<img src={calculatortmp} alt="asdf"></img>}
					className={
						!isMobile ? 'gibobs-widget-products-inmo' : 'gibobs-widget-products-inmo-mobile'
					}
					style={{ background: '#EFFCFC' }}>
					<div>
						<Text style={{ color: '#2F4858', fontWeight: 600, fontSize: 16 }}>
							{t('calculator.cardTitle1')}
						</Text>
					</div>
					<div>
						<Text style={{ color: '#748EA0', fontWeight: 400, fontSize: 14 }}>
							{t('calculator.cardTitle2')}
						</Text>
					</div>

					<NavLink
						to={'/calculators/fee'}
						style={{
							position: 'absolute',
							bottom: 72,
							left: 20
						}}>
						<Button
							className={'button-primary-gibobs-inmo'}
							type="primary"
							style={{
								height: 40,
								width: 260,
								position: 'absolute',
								border: 'none'
							}}>
							{t('newRequest.getFile')}
						</Button>
					</NavLink>
				</Card>

				<Card
					cover={<img src={calculatortmp2} alt="asdf"></img>}
					className={
						!isMobile ? 'gibobs-widget-products-inmo' : 'gibobs-widget-products-inmo-mobile'
					}
					style={{ background: '#EFFCFC' }}>
					<div>
						<Text style={{ color: '#2F4858', fontWeight: 600, fontSize: 16 }}>
							{t('calculator.cardTitle3')}
						</Text>
					</div>
					<div>
						<Text style={{ color: '#748EA0', fontWeight: 400, fontSize: 14 }}>
							{t('calculator.cardTitle5')}
						</Text>
					</div>

					<NavLink
						to={'/calculators/qualify/client'}
						style={{
							position: 'absolute',
							bottom: 72,
							left: 20
						}}>
						<Button
							className={'button-primary-gibobs-inmo'}
							type="primary"
							style={{
								height: 40,
								width: 260,
								position: 'absolute',
								border: 'none'
							}}>
							{t('calculator.cardButton2')}
						</Button>
					</NavLink>
				</Card>
			</div>
		);
	};

	const calculatorshowMobile = () => {
		return (
			<>
				<Card
					onClick={() => navigate('/calculators/fee')}
					className="gb-card-background-blue-mobile"
					style={{ width: 'calc(100% - 16px)' }}>
					<div>
						<EuroCircleOutlined style={{ color: '#02AFB8', fontSize: 20 }} />
						<Text
							style={{
								color: '#02AFB8',
								fontSize: 16,
								fontWeight: 400,
								marginLeft: 16
							}}>
							{t('calculator.cardTitle1')}
						</Text>
					</div>
				</Card>

				<Card
					onClick={() => navigate('/calculators/qualify/client')}
					className="gb-card-background-blue-mobile"
					style={{ width: 'calc(100% - 16px)' }}>
					<div>
						<UserOutlined style={{ color: '#02AFB8', fontSize: 20 }} />
						<Text
							style={{
								color: '#02AFB8',
								fontSize: 16,
								fontWeight: 400,
								marginLeft: 16
							}}>
							{t('calculator.cardTitle2')}
						</Text>
					</div>
				</Card>
			</>
		);
	};

	return (
		<Layout style={{ marginBottom: '140px' }}>
			<LayoutTools />
			<div style={{ marginLeft: isMobile ? '16px' : '40px' }}>
				<Text style={{ fontSize: '12px', color: '#2F4858', fontWeight: 600 }}>
					{t('products.titleInmo').toUpperCase()}
				</Text>
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						rowGap: isMobile ? 8 : 16,
						columnGap: isMobile ? 8 : 16,
						marginTop: 16
					}}>
					{isMobileOnly ? calculatorshowMobile() : calculatorsShow()}
				</div>

				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						rowGap: isMobile ? 8 : 16,
						columnGap: isMobile ? 8 : 16,
						marginTop: 16
					}}>
					{showOptions()}
				</div>
			</div>

			<div style={{ marginLeft: isMobile ? '16px' : '40px', marginTop: 24 }}>
				<Text style={{ fontSize: '12px', color: '#2F4858', fontWeight: 600 }}>
					{t('create.ProductEcoB2b').toUpperCase()}
				</Text>
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						rowGap: isMobile ? 8 : 16,
						columnGap: isMobile ? 8 : 16,
						marginTop: 16
					}}>
					{typeRequest.map((data, index) => {
						if (index > 3 && index < 5) {
							return (
								<RequestComponent
									key={index}
									title={data.title}
									parrafo={data.parrafo}
									icon={data.icon}
									url={data.url}
									index={index}
									button={data.button}
									eco={data.eco}
								/>
							);
						}
					})}
				</div>
			</div>

			<div style={{ marginLeft: isMobile ? '16px' : '40px', marginTop: 24 }}>
				<Text style={{ fontSize: '12px', color: '#2F4858', fontWeight: 600 }}>
					{t('createMortgageComponent.salesTools').toUpperCase()}
				</Text>
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						rowGap: isMobile ? 8 : 16,
						columnGap: isMobile ? 8 : 16,
						marginTop: 16
					}}>
					{typeRequest.map((data, index) => {
						if (index >= 5) {
							return (
								<RequestComponent
									key={index}
									title={data.title}
									parrafo={data.parrafo}
									icon={data.icon}
									url={data.url}
									index={index}
									button={data.button}
									eco={data.eco}
								/>
							);
						}
					})}
				</div>
			</div>
		</Layout>
	);
};

export default NewRequestPage;
