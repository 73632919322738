import React, { useState, useEffect } from "react";
import {
  Typography,
  Modal,
  message,
  Tabs,
  Row,
  Col,
  Badge,
  Upload,
  Tooltip,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  PlusOutlined,
  MailOutlined,
  DownloadOutlined,
  UserOutlined,
  PhoneOutlined,
  CheckOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import axios from "axios";
import env from "../../../../environment";
import _ from "lodash";
import { isMobile } from "react-device-detect";
import documentsRequired from "../../../../assets/icons/documentsRequired.svg";
import infoBlackIcon from "../../../../assets/icons/infoBlackIcon.svg";

const { TabPane } = Tabs;
const { Text } = Typography;
const { confirm } = Modal;

const DocumentMobileComponent = ({ userMortgageId }) => {
  const { t } = useTranslation();
  const [saveFileDataField, setSaveFileDataField] = useState(null);
  const userToken = useSelector((state) => state.auth.token);
  const [valuesData, setValuesData] = useState([]);
  const [valuesDataForCertificate, setValuesDataForCertificate] = useState([]);

  const [clickTab, setClickTab] = useState("owner1");
  const [detailsCertificate, setDetailsCertificate] = useState([]);
  let currentOwner = undefined;
  const [fileGroup, setFileGroup] = useState([]);
  const [tags, setTags] = useState([]);
  const [, setOwner] = useState("");


  let countShowMessage = 0;
  const dataProfile = useSelector((state) => state.data.dataProfile);


  useEffect(() => {
    axios
      .get(`${env.api.url}/v1/operations/${userMortgageId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setDetailsCertificate(response.data.data);
      });

    getDocuments();
    getGroupsDoc();
  }, []);

  useEffect(() => {
    getDocuments();
    getGroupsDoc();
  }, [userMortgageId]);

  const handleClickPlus = (idowner, file) => {
    setFileGroup(file);
    let array = [idowner];
    if (idowner === "buy") {
      setTags(array);
      setOwner("owner1");
    } else {
      setOwner(idowner);
    }
  };

  const saveFileData = (file, name) => {
    const documentFile = [saveFileDataField];
    documentFile.push({
      file,
      fileName: name,
    });
    setSaveFileDataField(documentFile);
    handleUploadFile(documentFile);
  };

  const handleUploadFile = (file) => {
    axios
      .put(
        `${env.api.url}/v1/documents/operation/attachment`,
        {
          operationId: userMortgageId,
          file: file[1].file,
          fileName: file[1].fileName,
          owner: clickTab,
          type: fileGroup.name,
          tags: tags,
          page: "t",
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then(() => {
        getDocuments();
        getGroupsDoc();

        if (countShowMessage === 0) {
          message.success(t("document.message"));
          countShowMessage = 1;
        }
      })
      .catch((error) => {
        console.log(error)
      });
    countShowMessage = 0;
  };

  const getGroupsDoc = () => {
    axios
      .get(
        `${env.api.url}/v1/documents/request-operation-documentation/${userMortgageId}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        response.data.data.documents = _.orderBy(
          response.data.data.documents,
          [
            (d) => {
              return d.participants[clickTab];
            },
          ],
          ["desc"]
        );

        setValuesDataForCertificate(response.data.data);
        _.find(response.data.data.documents, function (d) {
          return d.name === "bankreader";
        });
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const uploadProps = {
    beforeUpload: (file) => {
      return new Promise(() => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64File = reader.result.split(",")[1];
          saveFileData(base64File, file.name);
        };
      });
    },
  };

  const handleFileDownload = (documentId) => {
    axios
      .get(
        `${env.api.url}/v1/documents/get-document-url/${documentId}?token=${userToken}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        if (response.data.success) {
          window.open(response.data.data.url, "_blank"); //to open new page
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const dataValueInfoUpload = () => {
    /*axios
          .get(
            `${env.api.url}/v1/documents/get-operation-documents/${userMortgageId}`,
            {
              headers: { Authorization: `Bearer ${userToken}` },
            }
          )
          .then((response) => {
            const unsorted = response.data.data;
    
            const sorted = unsorted
              .sort(
                (a, b) =>
                  new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
              )
              .reverse();
    
            setValuesData(sorted);
    
          })
          .catch((error) => {});*/
  };

  const deleteDocument = (documentId) => {
    axios
      .get(`${env.api.url}/v1/documents/delete-document/${documentId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then(() => {
        dataValueInfoUpload();
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const modalDocument = (documentId) => {
    confirm({
      className: "ModalCommonConfirm",
      title: "Borrar documento",
      icon: null,
      content: "¿Seguro que deseas borrar este documento?",
      okText: "Borrar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteDocument(documentId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const getDocuments = async () => {
    let owner = currentOwner === undefined ? clickTab : currentOwner;

    const response = await axios.get(
      `${env.api.url}/v1/documents/get-operation-documents/${userMortgageId}`,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );

    const unsorted = response.data.data;

    const sorted = unsorted
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )
      .reverse();

    let temp = undefined;

    temp = sorted.filter((item) => item.owner === owner);
    setValuesData(temp);
  };

  const renderDocumentInformation = (index, type) => {
    const data = valuesData.filter((item) => {
      let documentFlag = false;

      if (item.owner === `${type}${index}`) {
        documentFlag = true;
      } else if (type === "owner" && index === 1 && !item.owner) {
        documentFlag = true;
      }

      return documentFlag;
    });

    const filesVerified = data.map((item, i) => {
      if (!item.name.includes(`.${item.extension}`)) {
        return (
          <div
            key={i}
            style={{
              display: "flex",
              // marginTop: 22,
              justifyContent: "space-between",
              padding: "10px 0",
              borderBottom: "1px solid #F1F7F8",
              alignItems: isMobile && "center",
              backgroundColor: isMobile && "#FFFFFF",
            }}
          >
            <div style={{ display: "flex" }}>
              <CheckOutlined style={{ color: "#52C41A", marginTop: 3 }} />
              <div
                style={{
                  marginLeft: 16,
                  width: isMobile ? "calc(100% - 100px)" : "100%",
                }}
              >
                <Text
                  style={{
                    color: "#2F4858",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  {item.name}
                </Text>
              </div>
            </div>
            {item.userId === dataProfile?.id && (
              <div>
                <DownloadOutlined
                  onClick={() => handleFileDownload(item.id)}
                  style={{ color: "#2F4858", fontSize: 15 }}
                />
              </div>
            )}
          </div>
        );
      }
    });

    const files = data.map((item, i) => {
      if (item.name.includes(`.${item.extension}`)) {
        return (
          <div
            key={i}
            style={{
              // marginTop: 22,
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 0",
              borderBottom: "1px solid #F1F7F8",
              alignItems: isMobile && "center",
              backgroundColor: isMobile && "#FFFFFF",
            }}
          >
            <div style={{ display: "flex", width: isMobile && "80%" }}>
              <CheckOutlined style={{ color: "#02C3CD" }} />
              <div
                style={{
                  marginLeft: 16,
                  width: isMobile ? "calc(100% - 30px)" : "100%",
                }}
              >
                <Text
                  style={{
                    color: "#2F4858",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  {item.name}
                </Text>
              </div>
            </div>
            {item.userId === detailsCertificate.userId && (
              <div>
                <DownloadOutlined
                  onClick={() => handleFileDownload(item.id)}
                  style={{ color: "#2F4858", fontSize: 15 }}
                />
                <DeleteOutlined
                  onClick={() => modalDocument(item.id)}
                  style={{ color: "#FF4D4F", fontSize: 15, marginLeft: 13 }}
                />
              </div>
            )}
          </div>
        );
      }
    });

    const filesDoc =
      valuesDataForCertificate.documents &&
      valuesDataForCertificate.documents.map((item, i) => {
        if (item.completed[clickTab] === false) {
          if (item.participants[clickTab] === "required") {
            return (
              <div
                key={i}
                style={{
                  display: "flex",
                  // marginTop: 22,
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px 0",
                  borderBottom: "1px solid #F1F7F8",
                  alignItems: isMobile && "center",
                  backgroundColor: isMobile && "#FFFFFF",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "90%",
                  }}
                >
                  <Tooltip placement="top" title={t("document.required")}>
                    <img
                      src={documentsRequired}
                      alt="exclamationIcons"
                      style={{ height: 15 }}
                    />
                  </Tooltip>
                  <div
                    style={{
                      width: isMobile && 280,
                      marginLeft: 16,
                    }}
                  >
                    <Text
                      style={{
                        color: "#2F4858",
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      {t(`documentTypes.${item.name}`, item.title)}
                    </Text>
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Upload multiple={true} {...uploadProps}>
                    <PlusOutlined
                      onClick={() => handleClickPlus(clickTab, item)}
                      style={{
                        color: "#02C3CD",
                        fontSize: 15,
                      }}
                    />
                  </Upload>
                </div>
              </div>
            );
          } else if (item.participants[clickTab] === "optional") {
            return (
              <div
                key={i}
                style={{
                  display: "flex",
                  // marginTop: 22,
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px 0",
                  borderBottom: "1px solid #F1F7F8",
                  alignItems: isMobile && "center",
                  backgroundColor: isMobile && "#FFFFFF",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Tooltip placement="top" title={t("document.optional")}>
                    <img
                      src={infoBlackIcon}
                      alt="exclamationIcons"
                      style={{ height: 15 }}
                    />
                  </Tooltip>
                  <div
                    style={{
                      width: isMobile && 280,
                      marginLeft: 16,
                    }}
                  >
                    <Text
                      style={{
                        color: "#2F4858",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      {item.title}
                    </Text>
                  </div>
                </div>

                <div style={{ textAlign: "center" }}>
                  <Upload multiple={true} {...uploadProps}>
                    <PlusOutlined
                      onClick={() => handleClickPlus(clickTab, item)}
                      style={{
                        color: "#02C3CD",
                        fontSize: 15,
                      }}
                    />
                  </Upload>
                </div>
              </div>
            );
          }
        }
      });
    return (
      <div style={{ marginTop: 12 }}>
        {filesVerified}
        {files}
        {filesDoc}
      </div>
    );
  };

  const notification = () => {
    return (
      <div style={{ float: "right", marginTop: "1px", marginRight: "-5px" }}>
        <Badge
          size="small"
          dot={true}
          style={{
            float: "right",
            width: "4px",
            height: "6px",
            marginLeft: "4px",
          }}
        ></Badge>
      </div>
    );
  };

  const handleSetClickTab = (values) => {
    currentOwner = values;
    getDocuments();
    setClickTab(currentOwner);
  };

  const renderTabs = (index, type) => {
    let requireFlag = false;
    if (valuesDataForCertificate.documents) {
      valuesDataForCertificate.documents.forEach((data) => {
        if (data.completed[`${type}${index}`] === false) {
          if (data.participants[`${type}${index}`] === "required") {
            requireFlag = true;
          }
        }
      });
    }

    if (
      detailsCertificate.operationData[`owner${index}`] !== undefined &&
      detailsCertificate.operationData[`owner${index}`].name
    ) {
      return (
        <TabPane
          tab={
            <div style={{ fontSize: 16 }}>
              {requireFlag ? notification() : null}

              {t(`mydocuments.${type}`) + index}
            </div>
          }
          key={`${type}${index}`}
        >
          <Text
            style={{
              marginTop: 24,
              color: "#2F4858",
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            {t("stages.documents").toUpperCase()}&nbsp;
            <Text
              style={{
                marginTop: 24,
                color: "#02C3CD",
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              {t("certificate.of").toUpperCase() +
                " " +
                detailsCertificate.operationData &&
                detailsCertificate.operationData[`owner${index}`] &&
                detailsCertificate.operationData[`owner${index}`].name &&
                detailsCertificate.operationData[
                  `owner${index}`
                ].name.toUpperCase()}
            </Text>
          </Text>

          {renderDocumentInformation(index, type)}
        </TabPane>
      );
    }
  };

  const renderAllDataParticipants = (index, type) => {
    if (detailsCertificate.operationData["owner" + index] !== undefined) {
      return (
        <Col lg={12} xs={24} style={{ marginBottom: 30 }}>
          <Text
            style={{
              fontSize: 14,
              fontWeight: 600,
              color: "#02C3CD",
              marginTop: 16,
            }}
          >
            {t(`mydocuments.${type}`) + index}
          </Text>
          <div style={{ display: "flex", marginTop: 16 }}>
            <UserOutlined
              style={{ color: "#02C3CD", fontSize: 12, marginRight: 7 }}
            />
            <Text style={{ fontSize: 12, fontWeight: 500, color: "#2F4858" }}>
              {"Silvia varela"}
            </Text>
          </div>
          <div style={{ display: "flex", marginTop: 8 }}>
            <MailOutlined
              style={{
                color: "#02C3CD",
                fontSize: 12,
                marginRight: 7,
                marginTop: 4,
              }}
            />
            <Text style={{ fontSize: 12, fontWeight: 500, color: "#2F4858" }}>
              {detailsCertificate &&
                detailsCertificate.operationData &&
                detailsCertificate.operationData["owner" + index].email}
            </Text>
          </div>
          <div style={{ display: "flex", marginTop: 8 }}>
            <PhoneOutlined
              style={{
                color: "#02C3CD",
                fontSize: 12,
                marginRight: 7,
                marginTop: 4,
              }}
            />
            <Text style={{ fontSize: 12, fontWeight: 500, color: "#2F4858" }}>
              {detailsCertificate &&
                detailsCertificate.operationData &&
                detailsCertificate.operationData["owner" + index].phone}
            </Text>
          </div>
        </Col>
      );
    }
  };

  let numParticipants = 0;
  if (detailsCertificate && detailsCertificate.operationData) {
    if (detailsCertificate.type === "mortgage") {
      numParticipants = parseInt(
        detailsCertificate &&
          detailsCertificate.operationData &&
          detailsCertificate.operationData.mortgage &&
          detailsCertificate.operationData.mortgage.participants
      );
    } else {
      numParticipants =
        detailsCertificate &&
        detailsCertificate.operationData &&
        detailsCertificate.operationData.operation &&
        detailsCertificate.operationData.operation.owners &&
        detailsCertificate.operationData.operation.owners;
    }
  }

  const documentTabs = [];
  const allDataParticipants = [];

  const documentsViewAutomatic = [];

  for (let i = 1; i <= numParticipants; i++) {
    if (detailsCertificate.operationData[`owner${i}`] !== undefined) {
      documentsViewAutomatic.push(
        <TabPane
          tab={<span>{t(`mydocuments.owner`) + i}</span>}
          key={`owner${i}`}
        >
          <Text
            style={{
              marginTop: 24,
              color: "#2F4858",
              fontSize: 12,
              fontWeight: 400,
            }}
          >
            {t("stages.documents").toUpperCase()}&nbsp;
            <Text
              style={{
                marginTop: 24,
                color: "#02C3CD",
                fontSize: 12,
                fontWeight: 400,
              }}
            >
              {t("certificate.of").toUpperCase() +
                " " +
                detailsCertificate.operationData &&
                detailsCertificate.operationData[`owner${i}`] &&
                detailsCertificate.operationData[`owner${i}`].name &&
                detailsCertificate.operationData[
                  `owner${i}`
                ].name.toUpperCase()}
            </Text>
          </Text>

          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div style={{ display: "flex", marginTop: 22, width: "100%" }}>
              <div style={{ width: 20, height: 20, marginRight: 12 }}>
                <CheckOutlined style={{ color: "#52C41A" }} />
              </div>
              <div xs={22}>
                <Text
                  style={{ color: "#2F4858", fontSize: 14, fontWeight: 400 }}
                >
                  {t("certificate.docOficialZip")}
                </Text>
              </div>
            </div>

              <div style={{ display: "flex", marginTop: 22, width: "100%" }}>
                <div style={{ width: 20, height: 20, marginRight: 12 }}>
                  <CheckOutlined style={{ color: "#52C41A" }} />
                </div>
                <div>
                  <Text
                    style={{ color: "#2F4858", fontSize: 14, fontWeight: 400 }}
                  >
                    {t("certificate.downloadBank3")}
                  </Text>
                </div>
              </div>
            
          </div>
        </TabPane>
      );
    }
  }

  

  for (let i = 1; i <= numParticipants; i++) {
    documentTabs.push(renderTabs(i, "owner"));
  }

  for (let i = 1; i <= numParticipants; i++) {
    allDataParticipants.push(renderAllDataParticipants(i, "owner"));
  }

  return (
    <dev>
      <Row>
        <Col lg={24} xs={24}>
          <Tabs
            type="card"
            style={{
              color: "#2F4858",
              fontStyle: "normal",
              fontWeight: "normal",
              // marginTop: 24,
              marginBottom: 50,
            }}
            onChange={(values) => handleSetClickTab(values)}
          >
            {documentTabs}
          </Tabs>
        </Col>
      </Row>
    </dev>
  );
};

export default DocumentMobileComponent;
