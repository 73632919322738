import React from "react";
import { Form, Modal, Typography } from "antd";
import { useTranslation } from "react-i18next";

const { Text } = Typography;
function ScoringModal({ visible, onCancel, onCreate }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  return (
    (<Modal
      className="ScoringModal"
      cancelButtonProps={{ className: "button-secundary-gibobs" }}
      okButtonProps={{
        className: "button-primari-gibobs",
        id: "gb-scoring-button-modal",
      }}
      open={visible}
      title={t("scoring.modaltitle1")}
      okText="Entendido"
      cancelText="Cancelar"
      width={674}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
            form.resetFields();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Text
        id="gb-scoring-description-modal"
        style={{ fontSize: "12px", height: "102px", width: "600px" }}
      >
        {t("scoring.modal")}
        <br></br>
        {t("scoring.modal1")}
        <br></br>
        {t("scoring.modal2")}
        <br></br>
        {t("scoring.modal3")}
      </Text>
    </Modal>)
  );
}

export default ScoringModal;
