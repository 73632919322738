import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";
const { Text } = Typography;

const LayoutPromoterMobile = ({ setModalDescription, name, stages }) => {
    const { t } = useTranslation();

    return (
        <div style={{ 
            margin: 0, 
            display: "flex", 
            justifyContent: "space-between", 
            background: '#F1F7F8', 
            padding: '16px 24px' 
        }}>
            <Text style={{ fontSize: 12, fontWeight: 600, color: '#2F4858'}}>
                {name}
            </Text>
            <div style={{ display: 'flex', alignItems: 'center'}}>
                <Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858'}}>
                    {t("b2bOperationList.status")}: {stages}
                </Text>
                <InfoCircleOutlined
                    style={{ color: "#02C3CD", marginLeft: 4, fontSize: 20 }}
                    onClick={() => setModalDescription(true)}
                />
            </div>
        </div>
    );
};

export default LayoutPromoterMobile;
