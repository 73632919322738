import {
  VIABILITYDATASUBROGATION,
  VIABILITYDATA,
  OPERATIONIDVIABILITY,
} from "./constants";
import axios from "axios";
import env from "../../environment";
import moment from "moment";

export const setDataViabilityForMortgage =
  (operationId, userToken) => async (dispatch) => {
    axios
      .post(`${env.api.url}/mortgages/conventional-viability-mortgage/get-viability/${operationId}`,{}, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        dispatch({
          type: VIABILITYDATA,
          payload: response.data.data,
        });
        dispatch({
          type: OPERATIONIDVIABILITY,
          payload: operationId,
        });
      });
  };

export const setDataViabilityForSubrogation =
  (outstandingCapital, signDate, terms, monthlyQuote, operationId, userToken) =>
  async (dispatch) => {
    axios
      .post(
        `${env.api.url}/mortgages/conventional-viability-subrogation/calculate-viability/${operationId}`,
        {
					overrides: {
						endDate: undefined,
						tin: undefined
					},
					needExceptionalViability: true,
					needCualification: true
				},
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        dispatch({
          type: VIABILITYDATASUBROGATION,
          payload: response.data.data,
        });
        dispatch({
          type: OPERATIONIDVIABILITY,
          payload: operationId,
        });
      });
  };

export const setDataViabilityForSubrogationOnboarding =
  (outstandingCapital, pendingTerm, tin, spread, operationId, userToken) =>
  async (dispatch) => {
   

    axios
      .post(
        `${env.api.url}/mortgages/conventional-viability-subrogation/calculate-viability/${operationId}`,
        {
					overrides: {
						endDate: moment(pendingTerm).format("YYYY-MM-DD"),
						tin: tin !== undefined ?  tin : spread
					},
					needExceptionalViability: true,
					needCualification: true
				},
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        dispatch({
          type: VIABILITYDATASUBROGATION,
          payload: response.data.data,
        });
        dispatch({
          type: OPERATIONIDVIABILITY,
          payload: operationId,
        });
      });
  };
