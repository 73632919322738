import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Radio, Space, Select, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { renderComponent } from '../../../../select/typeSelect';
import { isMobile, isMobileOnly } from 'react-device-detect';
import DownOutlinedMobile from '../../../../assets/icons/DownOutlinedMobile.svg';
import axios from 'axios';
import env from '../../../../environment';
import MortgageTermForm4 from './MortgageTermForm4';
import { InputNumber } from 'afrodita';

const { Option } = Select;
const { TabPane } = Tabs;

const DetailHoldersForm5 = ({ edit, form, subrogation }) => {
	const { t } = useTranslation();
	const lang = localStorage.getItem('i18nextLng');
	const [countries, setContries] = useState();
	const [participants, setParticipants] = useState(undefined);

	useEffect(() => {
		axios.get(`${env.api.url}/v1/countries`).then((response) => {
			setContries(response.data.data);
		});
		if (edit === undefined) {
			form.resetFields();
		}
	}, []);

	const onChange = (value) => {
		setParticipants(value);
		//localStorage.setItem('participants', value);
	};

	const ownerList = [];
	for (let i = 1; i <= form.getFieldValue('participants'); i++) {
		ownerList.push(i);
	}
	const colSpan = {
		xxl: edit === undefined ? 24 : 6,
		xl: edit === undefined ? 24 : 6,
		lg: edit === undefined ? 24 : 8,
		md: edit === undefined ? 24 : 12,
		sm: 24,
		xs: 24
	};
	const participantsItems = (data, owner) => {
		return (
			<div style={{ width: ownerList.length === 1 && !isMobileOnly ? "50%" : '100%' }}>
				{(edit === undefined || isMobile) && (
					<div className="applicant-style" style={{ display: 'flex', justifyContent: 'center' }}>
						{t(`onboarding.owner${data}`)}
					</div>
				)}

				<Col {...colSpan}>
					<Form.Item
						name={`activity${data}`}
						label={t('form.activity')}
						rules={[
							{
								required: true
							}
						]}
						style={{ width: '100%' }}>
						<Select
							id={`activity${data}`}
							placeholder={t('form.activity')}
							suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}>
							{renderComponent('activity').options.map((situation) => {
								return (
									<Option key={situation.value} value={situation.value}>
										{t(situation.label)}
									</Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>

				{subrogation !== true && (
					<>
						<Col {...colSpan}>
							<Form.Item
								name={`seniority${data}`}
								label={t('form.seniority')}
								rules={[
									{
										required: true,
										validator: (e, value) =>
											value < 41
												? Promise.resolve()
												: Promise.reject(
														new Error(t('form.seniority') + ' No puede ser mayor de 40')
												  )
									}
								]}>
								<InputNumber
									suffix={t('viability.years')}
									defaultValue={''}
									id={`seniority${data}`}
									gbType='number'
									className='gb-input-new-years'
									max={40}
									maxLength="2"
									placeholder={t('0')}
								/>
							</Form.Item>
						</Col>
						<Col {...colSpan}>
							<Form.Item
								name={`netIncomes${data}`}
								label={t('form.netIncomesOwner')}
								rules={[
									{
										required: true,
										validator: (e, value) =>
											value >= 0
												? Promise.resolve()
												: Promise.reject(new Error(t('netIncomes.inputValidator')))
									}
								]}>
								<InputNumber
									id={`netIncomes${data}`}
									gbType="number"
									className="gb-input-new-netincomes"
									placeholder={t('1000')}
									defaultValue={''}
								/>
							</Form.Item>
						</Col>
						<Col {...colSpan}>
							<Form.Item
								name={`loanPayment${data}`}
								label={t('onboarding.haveOtherLoans')}
								style={{ width: '100%' }}>
								<InputNumber
									id={`loanPayment${data}`}
									gbType="number"
									className="gb-input-new-netincomes"
									placeholder={t('0')}
									defaultValue={0}
								/>
							</Form.Item>
						</Col>
					</>
				)}
				{edit !== undefined && subrogation !== true && owner === 0 && (
					<MortgageTermForm4 edit={true} />
				)}
				{edit !== undefined && subrogation !== true && owner === 0 && (
					<Col {...colSpan}>
						<Form.Item
							name="cityzenship"
							label={t('form.country')}
							rules={[
								{
									required: true
								}
							]}>
							<Select
								id="cityzenship"
								suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
								showSearch
								placeholder={t('form.country')}
								style={{ width: '100%' }}
								filterOption={(input, option) => {
									return (
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
										option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
									);
								}}>
								{countries &&
									countries.countries.map((paises) => {
										return (
											<Option key={paises.code} value={paises.code}>
												{lang === 'en' ? paises.name_en : paises.name_es}
											</Option>
										);
									})}
							</Select>
						</Form.Item>
					</Col>
				)}
			</div>
		);
	};

	const participantsItemsEdit = (data, owner) => {
		return (
			<>
				{(edit === undefined || isMobile) && (
					<div className="applicant-style">{t(`onboarding.owner${data}`)}</div>
				)}
				<Col {...colSpan}>
					<Form.Item
						name={`activity${data}`}
						label={t('form.activity')}
						rules={[
							{
								required: true
							}
						]}
						style={{ width: '100%' }}>
						<Select
							id={`activity${data}`}
							placeholder={t('form.activity')}
							suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}>
							{renderComponent('activity').options.map((situation) => {
								return (
									<Option key={situation.value} value={situation.value}>
										{t(situation.label)}
									</Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>

				{subrogation !== true && (
					<>
						<Col {...colSpan}>
							<Form.Item
								name={`seniority${data}`}
								label={t('form.seniority')}
								rules={[
									{
										required: true,
										validator: (e, value) =>
											value < 41
												? Promise.resolve()
												: Promise.reject(
														new Error(t('form.seniority') + ' No puede ser mayor de 40')
												  )
									}
								]}>
								<InputNumber
									className='gb-input-new-years'
									defaultValue={''}
									id={`seniority${data}`}
									gbType="number"
									max={40}
									maxLength="2"
									placeholder={t('0')}
								/>
							</Form.Item>
						</Col>
						<Col {...colSpan}>
							<Form.Item
								name={`netIncomes${data}`}
								label={t('form.netIncomesOwner')}
								rules={[
									{
										required: true,
										validator: (e, value) =>
											value >= 0
												? Promise.resolve()
												: Promise.reject(new Error(t('netIncomes.inputValidator')))
									}
								]}>
								<InputNumber
									id={`netIncomes${data}`}
									suffix={t('€/mes')}
									gbType="currency"
									placeholder={t('form.netIncomesOwner')}
									defaultValue={''}
								/>
							</Form.Item>
						</Col>
						<Col {...colSpan}>
							<Form.Item 
								name={`loanPayment${data}`} 
								label={t('onboarding.haveOtherLoans')}>
								<InputNumber
									id={`loanPayment${data}`}
									suffix={t('€/mes')}
									gbType="currency"
									placeholder={t('onboarding.haveOtherLoans')}
									defaultValue={0}
								/>
							</Form.Item>
						</Col>
					</>
				)}
				{edit !== undefined && subrogation !== true && owner === 0 && (
					<MortgageTermForm4 edit={true} />
				)}
				{edit !== undefined && subrogation !== true && owner === 0 && (
					<Col {...colSpan}>
						<Form.Item
							name="cityzenship"
							label={t('form.country')}
							rules={[
								{
									required: true
								}
							]}>
							<Select
								id="cityzenship"
								suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
								showSearch
								placeholder={t('form.country')}
								style={{ width: '100%' }}
								filterOption={(input, option) => {
									return (
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
										option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
									);
								}}>
								{countries &&
									countries.countries.map((paises) => {
										return (
											<Option key={paises.code} value={paises.code}>
												{lang === 'en' ? paises.name_en : paises.name_es}
											</Option>
										);
									})}
							</Select>
						</Form.Item>
					</Col>
				)}
			</>
		);
	};
	return (
		// <div style={{ marginBottom: isMobileOnly && 150, width: "100%" }}>
		<>
			{edit !== undefined && (
				<Col {...colSpan}>
					<Form.Item name="participants" label={t('onboarding.ownersQuestion')}>
						<Select onChange={(e) => setParticipants(e)} id="participants">
							<Option value={1}>{t('onboarding.buyAlone')}</Option>
							<Option value={2}>{t('onboarding.buyWithSomeone')}</Option>
						</Select>
					</Form.Item>
				</Col>
			)}
			<Col span={24}>
				{edit === undefined && (
					<Form.Item name="participants" style={{ textAlignLast: 'center' }}>
						<Radio.Group onChange={(e) => onChange(e.target.value)} value={participants}>
							<Space direction={'horizontal'}>
								<Radio
									value={1}
									defaultChecked={
										parseInt(localStorage.getItem('participants')) === 1 ? true : false
									}
									checked={parseInt(localStorage.getItem('participants')) === 1 ? true : false}>
									{subrogation ? t('onboarding.buyAlone@') : t('onboarding.buyAlone')}
								</Radio>

								<Radio
									value={2}
									defaultChecked={
										parseInt(localStorage.getItem('participants')) === 2 ? true : false
									}
									checked={parseInt(localStorage.getItem('participants')) === 2 ? true : false}>
									{subrogation ? t('onboarding.buyWithSomeone2') : t('onboarding.buyWithSomeone')}
								</Radio>
							</Space>
						</Radio.Group>
					</Form.Item>
				)}

				{edit !== undefined && !isMobileOnly && (
					<Col span={24}>
						<Tabs defaultActiveKey="1" centered>
							{form.getFieldValue('participants') !== undefined &&
								ownerList.map((data, i) => {
									return (
										<TabPane tab={t(`onboarding.owner${data}`)} key={data}>
											<Row gutter={24}>{participantsItemsEdit(data, i)}</Row>
										</TabPane>
									);
								})}
						</Tabs>
					</Col>
				)}

				<Row gutter={40} style={{ width: '100%' }}>
					{form.getFieldValue('participants') !== undefined &&
						ownerList.map((data, i) => {
							return (
								<Col span={ownerList.length === 1 ? 24 : 12} style={{ display: 'flex', justifyContent: 'center' }}>
									{edit === undefined && !isMobileOnly && participantsItems(data, i)}
								</Col>
							);
						})}
				</Row>
			</Col>

			{form.getFieldValue('participants') !== undefined &&
				ownerList.map((data, i) => {
					if (isMobileOnly) {
						return participantsItems(data, i);
					}
				})}
		</>
	);
};

export default DetailHoldersForm5;
