import React from "react";
import { Layout } from "antd";
import { useTranslation } from "react-i18next";
import MyBreadcrumb from "../MyBreadcrumb";

const FinancieroPage = () => {
  const { t } = useTranslation();
  const data = [
    {
      name: t("menuComponent.profileNav"),
      link: "/my-financial-profile",
    },
  ];

  return (
    <Layout>
      <MyBreadcrumb data={data} />
      MI PERFIL FINANCIERO
    </Layout>
  );
};

export default FinancieroPage;
