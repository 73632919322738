export const DATASUMMARY = "DATA-DATASUMMARY";
export const DATAPROFILE = "DATA-DATAPROFILE";
export const SHOWPAY = "DATA-SHOWPAY";
export const PAYEND = "DATA-PAYEND";
export const GETASSET = "DATA-GETASSET";
export const CREATEASSET = "DATA-CREATEASSET";
export const RENAMEASSET = "DATA-RENAMEASSET";
export const DELETEASSET = "DATA-DELETEASSET";
export const MORTGAGEGREEN = "DATA-MORTGAGEGREEN";
export const SUBROGATIONGREEN = "DATA-SUBROGATIONGREEN";
export const RENAMESEAT = "DATA-RENAMESEAT";
export const DELETESEAT = "DATA-DELETESEAT";
export const UPDATESEAT = "DATA-UPDATESEAT";
export const CREATESEAT = "DATA-CREATESEAT";
export const GETSEATS = "DATA-GETSEATS";
export const ECOBUDGET = "DATA-ECOBUDGET";
export const MAXBUDGETGREEN = "DATA-MAXBUDGETGREEN";
export const GETSCORING = "DATA-GETSCORING";
export const COLLAPSEMENU = "DATA-COLLAPSEMENU";
export const MODELIST = "DATA-MODELIST";
export const RENAMENAMESEAT = "RENAMENAMESEAT";
export const CHANGEPAGE = "CHANGEPAGE";
export const SIMULATELTV = "SIMULATELTV";

