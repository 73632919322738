import {
  ERROR,
  TOKEN,
  LOADING,
  PROFILE,
  AUTHENTICATED,
  ROLES,
  EXPIRES_AT,
  MORTGAGEID,
  OPERATIONS,
  TABSPAGE,
  STAGES,
  RELOADINIT,
  CALLACT,
  REFRESHSTAGES,
  REFRESHDATA,
  ALLROLES,
} from "./constants";
import {
  DATASUMMARY,
  DATAPROFILE,
  ECOBUDGET,
  MORTGAGEGREEN,
  SUBROGATIONGREEN,
  MAXBUDGETGREEN,
} from "../data/constants";
import axios from "axios";
import env from "../../environment";
import i18n from "../../i18n";

export const login =
  ({ username, password, navigate }) =>
  async (dispatch) => {
    dispatch({
      type: LOADING,
      payload: true,
    });

    axios
      .post(`${env.api.url}/v1/auth/login`, { username, password })
      .then((response) => {
        if (response.data.success) {
          const profile = response.data.data.profile;
          const token = response.data.data.token;
          const expiresAt = response.data.data.expiresAt;
          const roles = response.data.data.profile.roles[0];
          const allRoles = response.data.data.profile.roles;

          sessionStorage.setItem("linksApps", true);
          localStorage.setItem("access_token", token);
          localStorage.setItem("expiresAt", expiresAt);
          localStorage.setItem("roles", roles);
          localStorage.setItem("allRoles", allRoles);
          localStorage.setItem(
            "profile",
            JSON.stringify(response.data.data.profile)
          );
          sessionStorage.setItem("modalSub", true);

         // sessionStorage.setItem("testA/B", false)

          dispatch({
            type: PROFILE,
            payload: profile,
          });

          dispatch({
            type: LOADING,
            payload: false,
          });

          dispatch({
            type: AUTHENTICATED,
            payload: true,
          });

          dispatch({
            type: ROLES,
            payload: roles,
          });

          dispatch({
            type: TOKEN,
            payload: token,
          });

          dispatch({
            type: EXPIRES_AT,
            payload: expiresAt,
          });
          dispatch({
            type: ALLROLES,
            payload: allRoles,
          });

          navigate("/distributor");
        }
      })
      .catch((error) => {
        dispatch({
          type: ERROR,
          payload: error,
        });
        dispatch({
          type: LOADING,
          payload: false,
        });
      });

    return "LOGIN";
  };

export const getProfile =
  ({ token, navigate, redirect = "/home", customRole, id }) =>
  async (dispatch) => {
    dispatch({
      type: LOADING,
      payload: true,
    });

    axios
      .get(`${env.api.url}/v1/auth/get-profile`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.data.success) {
          const profile = response.data.data;
          const expiresAt = response.data.data.expiresAt;
          const allRoles = response.data.data.roles;
          let roles = response.data.data.roles[0];

          if (customRole) {
            roles = customRole;
          }

          localStorage.setItem("access_token", token);
          localStorage.setItem("roles", roles);
          localStorage.setItem("allRoles", allRoles);
          localStorage.setItem("expiresAt", expiresAt * 1000);
          localStorage.setItem("profile", JSON.stringify(response.data.data));
          sessionStorage.setItem("linksApps", true);
          sessionStorage.setItem("modalSub", true);


          dispatch({
            type: PROFILE,
            payload: profile,
          });

          dispatch({
            type: LOADING,
            payload: false,
          });

          dispatch({
            type: AUTHENTICATED,
            payload: true,
          });

          dispatch({
            type: ROLES,
            payload: roles,
          });

          dispatch({
            type: TOKEN,
            payload: token,
          });

          dispatch({
            type: EXPIRES_AT,
            payload: expiresAt,
          });
          
          dispatch({
            type: ALLROLES,
            payload: allRoles,
          });
          
          if (redirect === "operation-agent-b2b") {
            navigate(
              `/sales-tools/follow-up/detail-operation/${id}/basic`
            );
          } else if (redirect === "loans") {
            navigate(`/my-financial-profile/credits/list`);
          }else if (redirect === 'create-loan') {
            navigate(`/my-financial-profile/credits`);
          }else if ( redirect === "last-promotor-operation") {
            navigate(`/promoter-loan/step-form/${id}`);
          }
        
        } else {
          dispatch({
            type: LOADING,
            payload: false,
          });

          const env = process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV;

          if (env === "production") {
            document.location = "https://www.gibobs.com/login";
          } else {
            navigate("/login");
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: ERROR,
          payload: error,
        });
        dispatch({
          type: LOADING,
          payload: false,
        });

        const env = process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV;
        if (env === "production") {
          document.location = "https://www.gibobs.com/login";
        } else {
          navigate("/login");
        }
      });

    return "LOGIN";
  };

export const logout =
  ({ navigate }) =>
  async (dispatch) => {
    dispatch({
      type: LOADING,
      payload: true,
    });

    localStorage.removeItem("access_token");
    localStorage.removeItem("expiresAt");
    localStorage.removeItem("roles");
    localStorage.removeItem("profile");
    sessionStorage.removeItem("linksApps");
    localStorage.removeItem("allRoles");
    sessionStorage.removeItem("testA/B");
    sessionStorage.removeItem("modalSub");




    dispatch({
      type: PROFILE,
      payload: {},
    });

    dispatch({
      type: LOADING,
      payload: false,
    });

    dispatch({
      type: AUTHENTICATED,
      payload: false,
    });

    dispatch({
      type: ROLES,
      payload: undefined,
    });

    dispatch({
      type: TOKEN,
      payload: undefined,
    });

    dispatch({
      type: EXPIRES_AT,
      payload: undefined,
    });
    dispatch({
      type: EXPIRES_AT,
      payload: undefined,
    });
    dispatch({
      type: DATAPROFILE,
      payload: [],
    });
    dispatch({
      type: DATASUMMARY,
      payload: [],
    });
    dispatch({
      type: OPERATIONS,
      payload: [],
    });
    dispatch({
      type: MORTGAGEID,
      payload: "",
    });
    dispatch({
      type: STAGES,
      payload: "",
    });
    dispatch({
      type: ECOBUDGET,
      payload: 0,
    });
    dispatch({
      type: MAXBUDGETGREEN,
      payload: 0,
    });
    dispatch({
      type: MORTGAGEGREEN,
      payload: false,
    });
    dispatch({
      type: SUBROGATIONGREEN,
      payload: false,
    });

    if (env.app.login === "gibobs") {
      if (i18n.language === "cat") {
        window.location.href = `https://www.gibobs.com/ca/landing-amigobs-ca/`;
      } else if (i18n.language === "en") {
        window.location.href = `https://www.gibobs.com/en/landing-amigobs-en/`;
      } else {
        window.location.href =
          "https://www.gibobs.com/landing-amigobs/?action=logout_plataforma";
      }
    } else {
      window.location.href = "/landing-amigobs/?action=logout_plataforma";
    }

    return "LOGOUT";
  };

export const mortgageId = (name) => {
  return {
    type: MORTGAGEID,
    payload: name,
  };
};

export const operationsClients = (name) => {
  return {
    type: OPERATIONS,
    payload: name,
  };
};

export const tabspage = (name) => {
  return {
    type: TABSPAGE,
    payload: name,
  };
};

export const stages = (name) => {
  return {
    type: STAGES,
    payload: name,
  };
};

export const reloadInit = (boo) => {
  return {
    type: RELOADINIT,
    payload: boo,
  };
};

export const callAct = (change) => {
  return {
    type: CALLACT,
    payload: change,
  };
};
export const refreshStages = (change) => {
  return {
    type: REFRESHSTAGES,
    payload: change,
  };
};
export const refreshData = (change) => {
  return {
    type: REFRESHDATA,
    payload: change,
  };
};
export const changeRol = (change) => {
  localStorage.setItem("roles", change);
  return {
    type: ROLES,
    payload: change,
  };
};
