import '../../../../components/widgets/Different-Widgets/document.css';
import env from '../../../../environment';
import tgss from '../../../../assets/onboarding/tgss.png';
import aeat from '../../../../assets/onboarding/aeat.png';
import upIcon from '../../../../assets/icons/upIcon.svg';
import tgssDisabled from '../../../../assets/onboarding/tgssDisabled.png';
import aeatDisabled from '../../../../assets/onboarding/aeatDisabled.png';
import tesoro from '../../../../assets/onboarding/tesoro.png';
import agencia from '../../../../assets/onboarding/agencia.png';
import plus from '../../../../assets/icons/plusIcon.svg';
import { DocumentBoxesComponent } from 'mortgage-documents';
import banco from "../../../../assets/documents/banco.png"

const DocumentationNew = ({ userMortgageId, stage }) => {
	return (
		<DocumentBoxesComponent
			userMortgageId={userMortgageId}
			platform="gibobs"
			stage={stage}
			env={env}
			images={{
				tgss,
				aeat,
				upIcon,
				tgssDisabled,
				aeatDisabled,
				tesoro,
				agencia,
				plus,
				banco
			}}
		/>
	);
};

export default DocumentationNew;
