import React, { useEffect, useState } from 'react';
import { Form, Select, Col, Row, Card, Button, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import DownOutlinedMobile from '../../assets/icons/DownOutlinedMobile.svg';
import { notificationGibobs } from '../HerramientasVenta/reusableItems';
import HelpButton from './HelpButton';
import StepPromoter from './StepPromoter';
import { useSelector, useDispatch } from 'react-redux';
import { getPromoterData, getScenariosData } from '../../store/promoter/actions';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import env from '../../environment';
import { InputNumber } from 'afrodita';

const { Option } = Select;

export const FormCapitalStructure = (lg, form) => {
	const { t } = useTranslation();
	const colSpan = { xs: 24, lg: lg };
	const [typeOfUnit, setTypeOfUnit] = useState('TOTAL_EUROS');
	const [financingRequired, setFinancingRequired] = useState(0);
	const [financingBuild, setFinancingBuild] = useState(0);
	const [financingUnit, setFinancingUnit] = useState(0);
	const dataPromoter = useSelector((state) => state.promoter.dataFormPromoter);
	const [totalCosts, setTotalCosts] = useState(0);
	const [unit, setUnit] = useState(0);
	const [m2t, setM2t] = useState(0);

	useEffect(async () => {
		setUnit(
			dataPromoter && dataPromoter.generalData
				? Number(dataPromoter.generalData.salesUnits)
				: 0
		);
		setM2t(
			dataPromoter && dataPromoter.generalData
				? Number(dataPromoter.generalData.buildability)
				: 0
		);
		setTotalCosts(
			dataPromoter  && dataPromoter.totalCosts
				? Number(dataPromoter.totalCosts)
				: dataPromoter.economicData && dataPromoter.economicData.projectCosts.totalCosts
				? Number(dataPromoter.economicData.projectCosts.totalCosts)
				: 0
		);

		setFinancingRequired(
			dataPromoter && dataPromoter.capitalStructure && dataPromoter.capitalStructure.equity
				? Number(dataPromoter.capitalStructure.equity)
				: 0
		);
		setTypeOfUnit(
			dataPromoter  && dataPromoter.typeOfUnit ? dataPromoter.typeOfUnit : 'TOTAL_EUROS'
		);
	}, [dataPromoter]);

	useEffect(async () => {
		form.setFieldsValue({
			financingRequired: totalCosts - financingRequired,
			financingBuild: (totalCosts / m2t - financingBuild).toFixed(2),
			financingUnit: (totalCosts / unit - financingUnit).toFixed(2)
		});
	}, [totalCosts, financingRequired, financingBuild, financingUnit]);
	useEffect(async () => {
		form.setFieldsValue({
			ownFundsBuild: Number(
				Number(
					dataPromoter && dataPromoter.capitalStructure && dataPromoter.capitalStructure.equity
						? dataPromoter.capitalStructure.equity / m2t
						: 0
				).toFixed(2)
			)
		});
	}, [m2t]);
	useEffect(async () => {
		form.setFieldsValue({
			ownFundsUnit: Number(
				Number(
					dataPromoter && dataPromoter.capitalStructure && dataPromoter.capitalStructure.equity
						? dataPromoter.capitalStructure.equity / unit
						: 0
				).toFixed(2)
			)
		});
	}, [unit]);

	const calculateWithEuro = (calculate, value) => {
		setFinancingRequired(value);
		setFinancingBuild(value / m2t);
		setFinancingUnit(value / unit);

		form.setFieldsValue({
			[calculate[0]]: (value / m2t).toFixed(2),
			[calculate[1]]: (value / unit).toFixed(2)
		});
	};

	const calculateWithBuild = (calculate, value) => {
		const num = Number(value) * m2t;
		setFinancingRequired(num);
		setFinancingBuild(value);
		setFinancingUnit(num / unit);

		form.setFieldsValue({
			[calculate[0]]: num.toFixed(2),
			[calculate[1]]: (num / unit).toFixed(2)
		});
	};
	const calculateWithUnit = (calculate, value) => {
		const num = Number(value) * unit;
		setFinancingRequired(num);
		setFinancingBuild(num / m2t);
		setFinancingUnit(value);

		form.setFieldsValue({
			[calculate[0]]: num.toFixed(2),
			[calculate[1]]: (num / m2t).toFixed(2)
		});
	};

	const inputForm = (name, label, placeholder, rule, calculate) => {
		const colSpan1 = {
			xs: 24,
			lg: name === 'financingRequired' ? 12 : rule !== 'TOTAL_EUROS' ? 6 : 12
		};
		return (
			<Col {...colSpan1} style={{ paddingRight: 4, paddingLeft: 4 }}>
				<Form.Item
					name={name}
					label={t(label)}
					rules={[
						{
							required: typeOfUnit === rule ? true : false
						}
					]}>
					<InputNumber
						defaultValue={''}
						disabled={typeOfUnit !== rule ? true : false}
						placeholder={placeholder}
						style={{
							width: '100%'
						}}
						onChange={(e) => {
							if (e !== '') {
								rule === 'TOTAL_EUROS'
									? calculateWithEuro(calculate, Number(e), name)
									: rule === 'EUROS_BUILDABILITY'
									? calculateWithBuild(calculate, Number(e), name)
									: rule === 'EUROS_UNIT' && calculateWithUnit(calculate, Number(e), name);
							}
						}}
					/>
				</Form.Item>
			</Col>
		);
	};

	const onChange = (e) => {
		setTypeOfUnit(e.target.value);
	};

	return (
		<>
			<Row gutter={120}>
				<Col span={24}>
					<Radio.Group
						className={lg === 12 ? 'radio-gibobs-promoter' : 'radio-gibobs-promoter-b'}
						onChange={onChange}
						value={typeOfUnit}>
						<Radio value={'TOTAL_EUROS'}>
							{typeOfUnit === 'TOTAL_EUROS' ? (
								<div style={{ color: '#2F4858' }}>{t('€ total')}</div>
							) : (
								t('€ total')
							)}
						</Radio>
						<Radio value={'EUROS_BUILDABILITY'}>
							{typeOfUnit === 'EUROS_BUILDABILITY' ? (
								<div style={{ color: '#2F4858' }}>{t('€/m²t')}</div>
							) : (
								t('€/m²t')
							)}
						</Radio>
						<Radio value={'EUROS_UNIT'}>
							{typeOfUnit === 'EUROS_UNIT' ? (
								<div style={{ color: '#2F4858' }}>{t('€/ud.')}</div>
							) : (
								t('€/ud.')
							)}
						</Radio>
					</Radio.Group>
				</Col>
				<Col {...colSpan}>
					<Row gutter={24} style={{ marginRight: -4, marginLeft: -4 }}>
						{inputForm('equity', 'promoterLoan.ownFunds', '€', 'TOTAL_EUROS', [
							'ownFundsBuild',
							'ownFundsUnit'
						])}
						{inputForm('ownFundsBuild', '€/m²t', '0', 'EUROS_BUILDABILITY', [
							'equity',
							'ownFundsUnit'
						])}
						{inputForm('ownFundsUnit', '€/ud.', '0', 'EUROS_UNIT', ['equity', 'ownFundsBuild'])}
					</Row>
				</Col>
				<Col {...colSpan}>
					<Row gutter={24} style={{ marginRight: -4, marginLeft: -4 }}>
						{inputForm('financingRequired', 'promoterLoan.financingRequired', '000.0000')}
						{inputForm('financingBuild', '€/m²t', '0')}
						{inputForm('financingUnit', '€/ud.', '0')}
					</Row>
				</Col>
				<Col {...colSpan}>
					<Form.Item
						name="financingPeriod"
						label={t('promoterLoan.financingTerm')}
						rules={[
							{
								required: true
							}
						]}>
						<Select
							suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
							defaultValue={t('form.select')}
							style={{ width: '100%' }}>
							<Option value={6}>6 {t('viability.months')}</Option>
							<Option value={12}>12 {t('viability.months')}</Option>
							<Option value={18}>18 {t('viability.months')}</Option>
							<Option value={24}>24 {t('viability.months')}</Option>
							<Option value={30}>30 {t('viability.months')}</Option>
							<Option value={36}>36 {t('viability.months')}</Option>
						</Select>
					</Form.Item>
				</Col>
			</Row>
		</>
	);
};

const CapitalStructureForm = () => {
	const { t } = useTranslation();
	const params = useParams();
	const operationId = params.operationId;
	const [form] = Form.useForm();
	const navigate = useNavigate();;
	const dispatch = useDispatch();
	const dataPromoter = useSelector((state) => state.promoter.dataFormPromoter);
	const userToken = useSelector((state) => state.auth.token);
	const [loading, setLoading] = useState(false);

	const openNotificationWithIcon = () => {
		notificationGibobs('error', t('m_full.errorTitle'), t('form.incompleteInformation'));
	};

	const validateMessages = {
		required: '${label}' + ' ' + t('form.formRequired'),
		types: {
			email: t('form.emailValidated'),
			number: '${label} is not a valid number!'
		},
		number: {
			range: t('form.maxBudgetNewRequestValidated') + '${min}'
		}
	};

	const createScenarios = (values) => {
		axios
			.post(
				`${env.api.url}/promoters/simulate-scenarios/create/${operationId}`,
				{},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					dispatch(getScenariosData(response.data.data));
					dispatch(getPromoterData(values));
					setLoading(false);
					navigate(`/promoter-loan/analysis/${operationId}`);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const capitalStructureDataVerificated = (values) => {
		setLoading(true);
		const obj = {
			equity: Number(values.equity),
			financingRequired: Number(values.financingRequired),
			financingPeriod: Number(values.financingPeriod)
		};

		axios
			.post(`${env.api.url}/promoters/promoter-projects/capital-structure/${operationId}`, obj, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				if (response.data.success) {
					setLoading(false);
					createScenarios(response.data.data.data);
				}
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			});
	};

	return (
		<>
			<Card
				style={{
					margin: 40,
					padding: !isMobileOnly && '0px 10%',
					marginBottom: 100
				}}>
				<StepPromoter
					data={[
						{
							name: 'promoterLoan.generalData'
						},
						{
							name: 'viability.economicData'
						},
						{
							name: 'promoterLoan.capitalStructure'
						}
					]}
					status={2}
					width={'60%'}
					promoter={true}
				/>
				<div className="title-primary" style={{ marginBottom: 8, marginTop: 40 }}>
					{t('promoterLoan.capitalStructure')}
				</div>
				<div className="paragraph-style" style={{ marginBottom: 24 }}>
					{t('promoterLoan.capitalStructureDescription')}
				</div>
				<Form
					className={'gb-form-profile-headlines-mobile'}
					onFinishFailed={openNotificationWithIcon}
					scrollToFirstError={true}
					onFinish={capitalStructureDataVerificated}
					layout="vertical"
					name="Password"
					validateMessages={validateMessages}
					initialValues={dataPromoter ? dataPromoter.capitalStructure : {}}
					form={form}>
					{FormCapitalStructure(12, form)}
					<div style={{ textAlign: 'center' }}>
						<Button
							type="dashed"
							className="button-secundary-gibobs"
							style={{
								margin: 'auto',
								marginTop: 40,
								width: 120
							}}
							onClick={() => navigate(`/promoter-loan/financial-data/${operationId}`)}>
							{t('form.previous')}
						</Button>
						<Button
							type="primary"
							className="button-primari-gibobs"
							style={{
								margin: 'auto',
								marginTop: 40,
								marginLeft: 8,
								width: 120
							}}
							htmlType="submit"
							loading={loading}>
							{t('init.simulate')}
						</Button>
					</div>
				</Form>
				<HelpButton origin={'IN_FORM_THREE_STEPS'} />
			</Card>
		</>
	);
};

export default CapitalStructureForm;
