import React from 'react';
import { Typography, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const ModalDeleteMortgage = ({ visible, onCreate, onCancel, dataProfileDetails }) => {
	const { t } = useTranslation();

	return (
        (<Modal
			className="gb-modal-40-padding"
			width={480}
			open={visible}
			onCancel={onCancel}
			onOk={onCreate}
			okText={
				<Text style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 14 }}>{t('loan.delete')}</Text>
			}
			cancelText={
				<Text style={{ color: '#02C3CD', fontWeight: 600, fontSize: 14 }}>
					{t('offers.cancel')}
				</Text>
			}
			cancelButtonProps={{ style: { width: '43%', height: 40 } }}
			okButtonProps={{
				style: {
					width: '43%',
					height: 40,
					backgroundColor: '#FF4D4F',
					borderColor: '#FF4D4F'
				}
			}}
			title={
				<Text
					style={{
						fontSize: 16,
						fontWeight: 600,
						color: '#2F4858',
						marginTop: 24,
						marginBottom: 8
					}}>
					{t('loan.eliminateMortgage')}
				</Text>
			}>
            <div
				style={{
					display: 'flex',
					flexDirection: 'column',
					marginBottom: -5,
					marginTop: 20
				}}>
				<Text
					style={{
						fontSize: 14,
						fontWeight: 400,
						color: '#748EA0'
					}}>
					{dataProfileDetails.name + ' ' + t('loan.deleteLoan3')}
				</Text>
			</div>
        </Modal>)
    );
};

export default ModalDeleteMortgage;
