import React from "react";
import { Layout, Card, Row, Col, Typography } from "antd";

import { useTranslation } from "react-i18next";
import { isMobileOnly } from "react-device-detect";
import Mortgage from "../../assets/Products/mortgage.png";
import Subrogation from "../../assets/Products/subrogation.png";
import CreateProduct from "./CreateProduct";
import LayoutProducts from "./LayoutProducts";
import { useSelector } from "react-redux";
import CardLinkAmigobs from "../../components/widgets/CardLinksApps/CardLinkAmigobs";

const { Text } = Typography;

const ProductsPage = () => {
  const rol = useSelector((state) => state.auth.roles);

  const { t } = useTranslation();

  const colSpan = { xxl: 6, xl: 6, lg: 8, md: 12, sm: 24, xs: 24 };
  const styleConst = {
    margin: "0 auto",
    position: "relative",
    top: -73,
    width: "calc(100% - 64px)",
    height: 40,
    fontWeight: 600,
  };

  return (
    <Layout>
      {/* <MyBreadcrumb data={data}></MyBreadcrumb> */}
      <LayoutProducts />

      {isMobileOnly && rol !== "realestate" && <CardLinkAmigobs></CardLinkAmigobs>}
      <div className="create-products" style={{ marginBottom: isMobileOnly ? 0 : 300 }}>
        {/* <HipotecasComponent title={"createMortgageComponent.mortgageTitle"} />*/}
        {/* <HipotecasComponent
          title={"createMortgageComponent.mortgageGreenTitle"}
       />*/}
        <Row
          gutter={24}
          className="operationsClientsCard"
          style={{ marginLeft: 0, marginRight: 0 }}
        >
          <Col {...colSpan} style={{ marginBottom: "20px" }}>
            <Card
              className="product-card"
              cover={
                <img
                  src={Mortgage}
                  alt="mortgage"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                  }}
                />
              }
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text className="title-productos">
                  {t("createMortgageComponent.mortgageTitle")}
                </Text>
                <Text className="sub-title-productos">
                  {t("createMortgageComponent.bestConditionMortgage")}
                </Text>
                <Text className="description-product">
                  {t("productsComponent.descriptionMortagageProducts")}
                </Text>
              </div>
            </Card>
            <CreateProduct
              type={"mortgage"}
              houseState={"have_decide"}
              style={styleConst}
            />
          </Col>
          {/*
          <Col {...colSpan} style={{ marginBottom: "20px" }}>
            <Card
              className="product-card"
              cover={
                <>
                  <div
                    style={{
                      width: 24,
                      height: 24,
                      background: "#72D00A",
                      position: "absolute",
                      top: 12,
                      right: 12,
                      borderRadius: 3,
                      textAlign: "center",
                    }}
                  >
                    <img
                      className="rotateImgEco"
                      src={eco}
                      alt="eco"
                      style={{
                        width: "12px",
                        height: "12px",
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px",
                      }}
                    ></img>
                  </div>

                  <img
                    src={MortgageGreen}
                    alt="mortgage"
                    style={{ width: "100%", height: "auto" }}
                  />
                </>
              }
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text className="title-productos">
                  {t("createMortgageComponent.mortgageGreenTitle")}
                </Text>
                <Text className="sub-title-productos">
                  {t("createMortgageComponent.bestConditionMortgageGreen")}
                </Text>
                <Text className="description-product">
                  {t("productsComponent.descriptionSubrogationProducts2")}
                </Text>
              </div>
            </Card>
            <CreateProduct
              type={"green-mortgage"}
              houseState={"have_decide"}
              style={styleConst}
            />
            </Col>*/}
          <Col {...colSpan} style={{ marginBottom: "20px" }}>
            <Card
              className="product-card"
              cover={
                <img
                  src={Subrogation}
                  alt="mortgage"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                  }}
                />
              }
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text className="title-productos">
                  {t("operationTypes.subrogation")}
                </Text>
                <Text className="sub-title-productos">
                  {t("createMortgageComponent.helpMortgage")}
                </Text>
                <Text className="description-product">
                  {t("productsComponent.descriptionSubrogation")}
                </Text>
              </div>
            </Card>
            <CreateProduct
              type={"subrogation"}
              houseState={"subrogation"}
              style={styleConst}
            />
          </Col>
          {/*
          <Col {...colSpan} style={{ marginBottom: "20px" }}>
            <Card
              className="product-card"
              cover={
                <>
                  <div
                    style={{
                      width: 24,
                      height: 24,
                      background: "#72D00A",
                      position: "absolute",
                      top: 12,
                      right: 12,
                      borderRadius: 3,
                      textAlign: "center",
                    }}
                  >
                    <img
                      className="rotateImgEco"
                      src={eco}
                      alt="eco"
                      style={{
                        width: "12px",
                        height: "12px",
                        // marginLeft: 3,
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px",
                      }}
                    ></img>
                  </div>

                  <img
                    src={SubrogationGreen}
                    alt="mortgage"
                    style={{ width: "100%", height: "auto" }}
                  />
                </>
              }
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text className="title-productos">
                  {t("operationTypes.green-subrogation")}
                </Text>
                <Text className="sub-title-productos">
                  {t("createMortgageComponent.helpMortgage")}
                </Text>
                <Text className="description-product">
                  {t("productsComponent.descriptionGreenSubrogation")}
                </Text>
              </div>
            </Card>
            <CreateProduct
              type={"green-subrogation"}
              houseState={"subrogation"}
              style={styleConst}
            />
            </Col>*/}
        </Row>
      </div>
    </Layout>
  );
};

export default ProductsPage;
