import React from "react";
import { Layout, Modal, Typography } from "antd";
import { EuroCircleOutlined, DownloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import backgroundImage from "../../../assets/promoter/modalInfoImage.png"
import iconCalculator from "../../../assets/promoter/IconCalculator.png"

const { Text } = Typography;

const textStyle = {
    fontSize: 14,
    fontWeight: 400,
    color: '#2F4858',
}

function ModalInfoFunding({ visible, setVisible }) {
    const { t } = useTranslation();

    return (
        <Modal
            closable={true}
            centered
            className="ModalInfoFunding"
            open={visible}
            footer={null}
            title={null}
            width={664}
            onCancel={() => setVisible(false)}
        >
            <img src={backgroundImage} width="100%" />
            <Layout style={{ padding: 40 }}>
                <Text style={{ fontSize: 16, fontWeight: 600, color: '#2F4858' }}>
                    {t('promoter.promoterFinancing')}
                </Text>
                <Text style={{ ...textStyle, marginTop: 16 }}>
                    {t('promoter.developerFundsPro')}
                </Text>
                <Text style={{ ...textStyle, marginTop: 16 }}>
                    {t('promoter.capabilitiesCheck')}
                </Text>
                <div style={{ display: 'flex', alignItems: 'flex-start', gap: 16, marginTop: 16 }}>
                    <img src={iconCalculator} width={24}  height={24}/>
                    <Text style={{ ...textStyle }}>{t('promoter.financeScenariosPlay')}</Text>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-start', gap: 16, marginTop: 16 }}>
                    <EuroCircleOutlined style={{ fontSize: 24, color:'#2F4858' }} />
                    <Text style={textStyle}>{t('promoter.projectFitFinance')}</Text>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-start', gap: 16, marginTop: 16 }}>
                    <DownloadOutlined style={{ fontSize: 24 , color:'#2F4858' }} />
                    <Text style={textStyle}>{t('promoter.ecoFinScenarioDocs')}</Text>
                </div>
            </Layout>
        </Modal>
    );
}

export default ModalInfoFunding;
