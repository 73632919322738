import { useEffect, useState } from 'react';
import { Card, Col, Divider, Row, Typography, Switch, Spin } from 'antd';
import { ClockCircleOutlined, CloseCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { ReduxStore } from '../../DocumentationCore';
import { Offer, Request } from '../../Utils/types';
import axios from 'axios';
import env from '../../../../../environment';
import moment from 'moment';
import OffersCard from '../../Components/OffersCard';
import OffersTable from '../../Components/OffersTable';
const { Text } = Typography;

interface Props {
	userMortgageId: string;
	stages: string;
	bankOffers: Offer[];
	getBankOffers: () => void;
	setShowAppraisal: (value: boolean) => void;
}

const BankToOffersSection = ({
	userMortgageId,
	stages,
	bankOffers,
	getBankOffers,
	setShowAppraisal
}: Props) => {
	const { t } = useTranslation();
	const [banksRequest, setBanksRequest] = useState<Request[]>(undefined);
	const [filteredBanks, setFilteredBanks] = useState<Request[]>(undefined);
	const [sortedOffers, setSortedOffers] = useState<Offer[]>(undefined);
	const [tableMode, setTableMode] = useState<boolean>(false);
	const [offerIdWithMaxHeight, setOfferIdWithMaxHeight] = useState<string | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(true);
	const userToken = useSelector<ReduxStore, string>((state) => state.auth.token);
	const [showExpiry, setShowExpiry] = useState<boolean>(null);
	const [allDenied, setAllDenied] = useState<boolean>(false);

	useEffect(() => {
		getBankRequest();
		getBankOffers();
	}, []);

	useEffect(() => {
		axios
			.post(
				`${env.api.url}/campaigns/runner/experiment/by-event`,
				{
					event: 'offers-expiration',
					data: {
						operationId: userMortgageId
					}
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.data.offersExpiration === 'WITH_OFFERS_EXPIRATION') {
					setShowExpiry(true);
				} else {
					setShowExpiry(false);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	useEffect(() => {
		filterBanks();
		setSortedOffers(bankOffers?.sort(compareByDate));
	}, [banksRequest, bankOffers]);

	useEffect(() => {
		let maxRows = 0;
		let idWithMaxRows = null;
		bankOffers?.forEach((offer) => {
			const rows = offer?.data?.entailments[0]?.stages.length || 0;
			if (rows > maxRows) {
				maxRows = rows;
				idWithMaxRows = offer.id;
			}
		});
		setOfferIdWithMaxHeight(idWithMaxRows);
	}, [bankOffers]);

	const getBankRequest = () => {
		axios
			.get(`${env.api.url}/v1/banks/get-operation-bank-requests/${userMortgageId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setBanksRequest(response.data.data);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const compareByDate = (a: Offer, b: Offer) => {
		const dateA = moment(a.createdAt);
		const dateB = moment(b.createdAt);
		return dateB.diff(dateA);
	};

	const filterBanks = () => {
		if (banksRequest && bankOffers) {
			banksRequest.sort((a: Request, b: Request) => {
				if (a.status === 'deny' && b.status !== 'deny') return 1;
				if (a.status !== 'deny' && b.status === 'deny') return -1;
				return 0;
			});
			const uniqueBankIds = new Set();
			const bankOffersSet = new Set(bankOffers?.map((offer: Offer) => offer.bankCode));
			const uniqueBanks = banksRequest.filter((request: Request) => {
				if (
					request.status !== 'approved' &&
					!uniqueBankIds.has(request.bankCode) &&
					!bankOffersSet.has(request.bankCode)
				) {
					uniqueBankIds.add(request.bankCode);
					return true;
				}
				return false;
			});

			const denyBank = banksRequest.filter((request: Request) => {
				if (request.status === 'deny') {
					return request;
				}
			});
			if (denyBank.length === banksRequest.length) {
				setAllDenied(true);
			}

			setFilteredBanks(uniqueBanks);
		}
	};

	return (
		<>
			{stages !== 'documents' && stages !== 'analysis' && (
				<Row>
					<Col xs={24} md={24} sm={24} lg={14} xl={12} xxl={12}>
						<Text style={{ fontSize: 14, fontWeight: 400, color: '#2F4858' }}>
							{t('oferts.like')}
						</Text>
					</Col>
					<Col
						xs={24}
						md={24}
						sm={24}
						lg={10}
						xl={12}
						xxl={12}
						style={{ textAlign: isMobile ? 'center' : 'right' }}>
						<Switch
							onClick={() => setTableMode(!tableMode)}
							checked={tableMode}
							style={{ backgroundColor: tableMode ? '#02C3CD' : '#C0DAEE' }}
							size="small"
						/>
						<Text style={{ marginLeft: 12, color: tableMode ? '#2F4858' : '#C0DAEE' }}>
							{t('oferts.table')}
						</Text>
					</Col>
				</Row>
			)}

			{stages === 'documents' && (
				<>
					<Row>
						<Col span={24}>
							<Text style={{ fontSize: 14, fontWeight: 400, color: '#2F4858' }}>
								{t('documents.offerAlert')}
							</Text>
						</Col>
					</Row>
					<Row>
						<Col xs={24} md={12} sm={12} lg={12} xl={8} xxl={8} style={{ marginBottom: isMobile ? 6 : 16 }}>
							<Card style={{ borderRadius: 8, marginTop: 16, textAlign: 'center' }}>
								<Text style={{ color: '#E5B937', fontSize: 14, fontWeight: 500 }}>
									{t('documents.needDocuments')}
								</Text>
								<div>
									<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
										{t('documents.receiveOffers') + ' '}
									</Text>
									<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 600 }}>
										{t('documents.uploadEssentials')}
									</Text>
									<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
										{t('documents.findPersonalizedDeals')}
									</Text>
								</div>
							</Card>
						</Col>
					</Row>
				</>
			)}

			{stages === 'analysis' && (
				<>
					<Row>
						<Col span={24}>
							<Text style={{ fontSize: 14, fontWeight: 400, color: '#2F4858' }}>
								{t('documents.offers')}
							</Text>
						</Col>
					</Row>
					<Row>
						<Col xs={24} md={12} sm={12} lg={12} xl={8} xxl={8} style={{ marginBottom: isMobile ? 6 : 16 }}>
							<Card style={{ borderRadius: 8, marginTop: 16, textAlign: 'center' }}>
								<Text style={{ color: '#0088C6', fontSize: 14, fontWeight: 500 }}>
									{t('perfil.analytics')}
								</Text>
								<div>
									<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
										{t('perfil.offerCard') + ' '}
									</Text>
									<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 600 }}>
										{t('perfil.offerCard2')}
									</Text>
								</div>
							</Card>
						</Col>
					</Row>
				</>
			)}

			{!tableMode ? (
				<Spin spinning={loading}>
					<Row gutter={14} style={{ marginTop: 16 }}>
						{sortedOffers &&
							sortedOffers.map((offer: Offer, index: number) => {
								return (
									<Col
										xs={24}
										md={12}
										sm={12}
										lg={12}
										xl={8}
										xxl={8}
										style={{ marginBottom: isMobile ? 6 : 16 }}
										key={index}>
										<OffersCard
											offer={offer}
											bankOffers={bankOffers}
											getBankOffers={getBankOffers}
											isFavorite={offer.isFavorite}
											offerIdWithMaxHeight={offerIdWithMaxHeight}
											setLoading={setLoading}
											showExpiry={showExpiry}
											setShowAppraisal={setShowAppraisal}
										/>
									</Col>
								);
							})}
					</Row>
					<div>
						{allDenied && stages !== 'documents' && stages !== 'analysis' && (
							<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
								{t('deny.allOffers')}
							</Text>
						)}
					</div>
					<Row gutter={14} style={{ marginTop: 16 }}>
						{filteredBanks &&
							filteredBanks.map((request: Request, index: number) => {
								return (
									<Col
										xs={24}
										md={12}
										sm={12}
										lg={12}
										xl={8}
										xxl={8}
										style={{ marginBottom: isMobile ? 6 : 16 }}
										key={index}>
										<Card className="gb-card-24px" style={{ height: '100%' }}>
											<Row>
												<Col span={12}>
													<img
														src={
															'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/banks/' +
															`${request.bankCode}` +
															'.png'
														}
														alt={request.bankName}
														style={{ height: '32px', width: 'auto' }}
													/>
												</Col>
												<Col span={12} style={{ textAlign: 'right' }}>
													{request.status === 'deny' && (
														<CloseCircleFilled
															style={{ color: '#FF4D4F', fontSize: 28, marginTop: 5 }}
														/>
													)}
													{request.status !== 'deny' && (
														<ClockCircleOutlined
															style={{ color: '#0088C6', fontSize: 28, marginTop: 5 }}
														/>
													)}
												</Col>
												<Divider style={{ color: '#F1F7F8', height: 2 }}></Divider>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														textAlign: 'center',
														width: '100%'
													}}>
													{request.status !== 'deny' && (
														<Text style={{ color: '#0088C6', fontWeight: 500, fontSize: 14 }}>
															{t('perfil.analytics')}
														</Text>
													)}
													{request.status === 'deny' && (
														<Text style={{ color: '#FF4D4F', fontWeight: 400, fontSize: 14 }}>
															{t('bank.noSelect')}
														</Text>
													)}
													{request.status !== 'deny' && (
														<Text style={{ color: '#2F4858', fontWeight: 400, fontSize: 12 }}>
															{t('bank.noSelect1')}
														</Text>
													)}
													{request.status === 'deny' && (
														<Text style={{ color: '#2F4858', fontWeight: 400, fontSize: 12 }}>
															{t('bank.noSelect2')}
														</Text>
													)}
												</div>
											</Row>
										</Card>
									</Col>
								);
							})}
					</Row>
				</Spin>
			) : (
				<Spin spinning={loading}>
					<OffersTable
						bankOffers={bankOffers && bankOffers}
						getBankOffers={getBankOffers}
						loading={loading}
						setLoading={setLoading}
						setShowAppraisal={setShowAppraisal}
					/>
				</Spin>
			)}
		</>
	);
};
export default BankToOffersSection;
