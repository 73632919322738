import React, { useEffect, useState } from "react";
import { Typography, Card, Row, Col } from "antd";
import "./document.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import blueRigthArrowIcon from "../../../assets/icons/blueRigthArrowIcon.svg";
import PersonalAnalyst from "./personalAnalyst";
import Stages from "./stagesMortgagesWidget";
import axios from "axios";
import env from "../../../environment";
import DocumentationNew from "../../../Pages/Operaciones/MortagageAplication/DocumetationNew/DocumentationNew";

const { Text } = Typography;

const OfferDocuments = ({ }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();;
  const { operationId } = useParams();
  const userToken = useSelector((state) => state.auth.token);
  const params = useParams()

  const [nameOperations, setNameOperations] = useState(undefined);

  useEffect(() => {
    axios
      .get(`${env.api.url}/v1/operations/${operationId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setNameOperations(response.data.data);
      })
      .catch((error) => {
        console.log(error)
      });
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 24,
          marginLeft: 40,
          marginTop: 24,
        }}
      >
        <div
          style={{ marginRight: 16, cursor: "pointer" }}
          onClick={() => navigate(`/operations/${operationId}/${params.stage}`)}
        >
          <img src={blueRigthArrowIcon} alt="leftArrowIcon" height={14} />
        </div>
        <Text style={{ fontSize: 16, fontWeight: 600, color: "#02C3CD" }}>
          {params.stage === "oferts" ? t("oferts.selectionTable") : t("appraisal.appraisal")}
        </Text>
      </div>
      <Row gutter={24} style={{ margin: "24px 40px", marginBottom: 100 }}>
        <Col span={19} style={{ paddingLeft: 0 }}>
          <Card className="gb-card">
            <div style={{ color: "#2F4858", fontSize: 16, fontWeight: 600 }}>
              {t("document.filePendding")}
            </div>
            <DocumentationNew userMortgageId={operationId} stage={params.stage} />

          </Card>
        </Col>
        <Col span={5} style={{ paddingRight: 0 }}>
          <Card className="gb-card" style={{ marginBottom: 24 }}>
            <Text className="title-card">{t("document.personalAnalyst")}</Text>
            {nameOperations && (
              <PersonalAnalyst analyst={nameOperations}></PersonalAnalyst>
            )}
          </Card>
          <Card className="gb-card">
            <Text className="title-card">{t("init.mortgage")}</Text>
            <Stages />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default OfferDocuments;
