import React from 'react';
import { Card, Typography, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import moment from 'moment';

const { Text } = Typography;

const CurrentConditionsWidget = ({ loan }) => {
	const { t } = useTranslation();
	return (
		<Card className="gcardMovile card-heigth-loan" style={{ height: '100%' }}>
			<Row
				gutter={6}
				style={{
					marginTop: 0,
					marginBottom: 0,
					display: 'flex',
					height: '100%',
					alignContent: 'center',
					alignItems: 'center'
				}}>
				<Col
					span={loan.loan.rateType === 'variable' ? 7 : 12}
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: loan.loan.rateType !== 'variable' && 'center'
					}}>
					<Text
						style={{
							color: '#748EA0',
							fontSize: 12,
							fontWeight: 400
						}}>
						{t('b2bOperationList.type')}
					</Text>
					<Text
						style={{
							color: '#2F4858',
							fontSize: '15.5px',
							fontWeight: 600
						}}>
						{t(`loanType.${loan.loan.rateType}`)}
					</Text>
				</Col>
				<Col
					span={loan.loan.rateType === 'variable' ? 9 : 12}
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: loan.loan.rateType !== 'variable' && 'center'
					}}>
					<Text
						style={{
							color: '#748EA0',
							fontSize: 12,
							fontWeight: 400
						}}>
						{t('loan.rate')}
					</Text>
					<Text
						style={{
							color: '#2F4858',
							fontSize: 16,
							fontWeight: 600
						}}>
						{loan.loan.rateType === 'variable' &&
							numeral(loan.loan.spread).format('0.00') + ' %' + '+Eur'}
						{loan.loan.rateType === 'fixed' && loan.loan.rate + ' %'}
					</Text>
				</Col>
				{loan.loan.rateType === 'variable' && (
					<Col span={8} style={{ marginRight: -2 }}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column'
							}}>
							<Text
								style={{
									color: '#748EA0',
									fontSize: 12,
									fontWeight: 400
								}}>
								{t('loan.revisionDate')}
							</Text>
							<Text
								style={{
									color: '#2F4858',
									fontSize: 16,
									fontWeight: 600
								}}>
								{loan.loan.lastReview ? moment(loan.loan.lastReview).format('MM/YYYY') : '-'}
							</Text>
						</div>
					</Col>
				)}
			</Row>
		</Card>
	);
};

export default CurrentConditionsWidget;
