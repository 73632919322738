import { useState, useEffect } from 'react';
import { Card, Col, Divider, Row, Typography, Modal, Button } from 'antd';
import { HeartOutlined, HeartFilled, UpOutlined, DownOutlined, DownloadOutlined, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../DocumentationCore';
import { CSSTransition } from 'react-transition-group';
import { Offer } from '../Utils/types';
import { selectImagebank, renderType, capitalizeFirstLetter, getLinkages, renderAmortization, renderRevision, showHeart, hasLinkages } from '../Utils/commonFunctions';
import axios from 'axios';
import env from '../../../../environment';
import numeral from 'numeral';
import moment from 'moment';
const { Text } = Typography;
const iconApproved = require('../../../../assets/onboarding/icon_approved.png');

interface Props {
    offer: Offer,
    bankOffers: Offer[],
    getBankOffers: () => void,
    isFavorite: boolean,
    offerIdWithMaxHeight: string,
    setLoading: (value: boolean) => void,
    showExpiry: boolean,
    setShowAppraisal: (value: boolean) => void
}

const OffersCard = ({ offer, bankOffers, getBankOffers, isFavorite, offerIdWithMaxHeight, setLoading, showExpiry, setShowAppraisal }: Props) => {
    const { t } = useTranslation();
    const userToken = useSelector<ReduxStore, string>((state) => state.auth.token);
    const [showMore, setShowMore] = useState<boolean>(false);
    const [showModalConf, setShowModalConf] = useState<boolean>(false);
    const [showModalChange, setShowModalChange] = useState<boolean>(false);
    const [showModalNoChange, setShowModalNoChange] = useState<boolean>(false);
    const [minHeight, setMinHeight] = useState<number>(0);
    const creationDate = moment(offer?.createdAt);
    const today = moment();
    const minutesUntilExpiration = creationDate.add(21, 'days').diff(today, 'minutes');
    const expiryDays = Math.floor(minutesUntilExpiration / (24 * 60));
    const remainingMinutes = minutesUntilExpiration % (24 * 60);
    const expiryHours = Math.floor(remainingMinutes / 60);
    const expiryMinutes = remainingMinutes % 60;
    const expired = showExpiry && minutesUntilExpiration <= 0;
    const favoriteOffers = bankOffers?.filter((offer: Offer) => offer.isFavorite === true);

    useEffect(() => {
        const maxHeightRow = document.querySelector('.minHeight');
        setMinHeight(maxHeightRow.clientHeight)
    }, []);

    const handleToggleFavorite = (id: string) => {
        if (!offer.isFavorite) {
            setLoading(true);
            axios
                .post(`${env.api.url}/banks/bank-offer/toggle-favorite`, {
                    bankOfferId: id,
                    isFromHadmin: false
                },{
                    headers: { Authorization: `Bearer ${userToken}` }
                })
                .then(() => {
                    getBankOffers();
                    setShowAppraisal(true);
                    setLoading(false);
                })
                .catch((error) => console.log(error));
            setShowModalChange(false);
            setShowModalConf(false);
        }
    };

    const handleClickHeartIcon = () => {
        if (offer.bankRequest.type !== 'API') {
            showModalManual();
        } else {
            showModalApi();
        }
    };

    const handleConfirmManual = () => {
        handleToggleFavorite(offer?.id)
        if (favoriteOffers?.some((fav: Offer) => fav.bankCode === offer.bankCode)) {
            const favoriteOffer = (favoriteOffers.find((fav: Offer) => fav.bankCode === offer.bankCode && fav.id !== offer.id));
            handleToggleFavorite(favoriteOffer?.id);
        }
    };

    const showModalManual = () => {
        if (offer.isFavorite !== true) {
            if (favoriteOffers?.some((fav: Offer) => fav.bankCode === offer.bankCode)) {
                setShowModalChange(true);
            } else {
                handleToggleFavorite(offer?.id);
            }
        } else {
            handleToggleFavorite(offer?.id);
        }
    };

    const showModalApi = () => {
        if (!offer.isFavorite) {
            setShowModalConf(true);
        } else {
            setShowModalNoChange(true);
        }
    };

    const handleFileDownload = () => {
        axios
            .get(`${env.api.url}/v1/banks/generate-pdf-bank/${offer.id}?token=${userToken}`, {
                responseType: 'blob'
            })
            .then((response) => {
                if (response) {
                    const url = window.URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `ofertaBanco.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);

                }
            })
            .catch((error) => { console.log(error) });
    };

    const calculateColorExpiry = (background: boolean) => {
        if (minutesUntilExpiration > 21600) {
            return background ? '#ECF7FC' : '#0088C6';
        } else if (minutesUntilExpiration >= 11520 && minutesUntilExpiration <= 21600) {
            return background ? '#FEF0D3' : '#FAAD14';
        } else {
            return background ? '#FFDEDE' : '#BF3A3B';
        }
    }

    return (
        <>
            <Card className="gb-card-24px">
                {showExpiry && (
                    <div
                        style={{
                            width: '100%',
                            border: '1px solid',
                            borderColor: calculateColorExpiry(false),
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            backgroundColor: calculateColorExpiry(true),
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            minHeight: 45,
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '4px 24px',
                        }}>
                        {!expired
                            ? (
                                <>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <ClockCircleOutlined style={{ fontSize: isMobile ? 18 : 20, color: '#2F4858', marginRight: 5 }} />
                                        <Text style={{ fontSize: 14, fontWeight: 500, color: '#2F4858', marginTop: 2 }}>
                                            {t('offers.expiry')}&nbsp;
                                        </Text>
                                    </div>
                                    <Text style={{ fontSize: 14, fontWeight: 500, color: '#2F4858', marginTop: 2 }}>
                                        {expiryDays > 0 && (
                                            <>
                                                <span style={{ fontSize: 16, fontWeight: 600 }}>{String(expiryDays).padStart(2, '0')}</span>&nbsp;
                                                {expiryDays > 0 ? t('offers.days') : t('offer.day')}&nbsp;
                                            </>
                                        )}
                                        {expiryHours > 0 && (
                                            <>
                                                <span style={{ fontSize: 16, fontWeight: 600 }}>{String(expiryHours).padStart(2, '0')}</span>&nbsp;
                                                {expiryHours > 1 ? t('offers.hours') : t('offers.hour')}&nbsp;
                                            </>
                                        )}
                                        <span style={{ fontSize: 16, fontWeight: 600 }}>{String(expiryMinutes).padStart(2, '0')}</span>&nbsp;
                                        {expiryMinutes > 1 ? t('offers.minutes') : t('offers.minute')}&nbsp;
                                    </Text>
                                </>
                            )
                            : (
                                <>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <CloseCircleOutlined style={{ fontSize: isMobile ? 18 : 20, color: '#2F4858', marginRight: 5 }} />
                                        <Text style={{ fontSize: 14, fontWeight: 400, color: '#2F4858', marginTop: 2 }}>
                                            <b>{t('offers.expired')}</b>&nbsp;
                                        </Text>
                                    </div>
                                    <Text style={{ fontSize: 14, fontWeight: 400, color: '#2F4858', marginTop: 2 }}>
                                        {t('offers.consultWithAnalyst')}
                                    </Text>
                                </>
                            )
                        }

                    </div>
                )}
                <div style={{ paddingTop: showExpiry && 55 }}>
                    <Row>
                        <Col span={12}>
                            {selectImagebank(offer, false)}
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            {!isFavorite ?
                                (
                                    <>
                                        {showHeart(favoriteOffers, offer) && (
                                            <HeartOutlined
                                                style={{ fontSize: 32, color: '#C0DAEE' }}
                                                onClick={handleClickHeartIcon} />
                                        )}
                                    </>
                                ) :
                                (
                                    <HeartFilled
                                        style={{ fontSize: 32, color: '#FF7A7B' }}
                                        onClick={handleClickHeartIcon} />
                                )
                            }
                        </Col>
                    </Row>
                    <Divider style={{ color: '#F1F7F8', height: 2, marginBottom: 8 }}></Divider>
                    <Row justify={'space-between'}>
                        <Col>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={iconApproved} alt='icono' />
                                <Text style={{ fontSize: 14, fontWeight: 500, color: '#05C69A' }}>
                                    {t('offers.preApproved')}
                                </Text>
                            </div>
                        </Col>
                        <Col>
                            <Text style={{ fontSize: 14, fontWeight: 500, color: '#2F4858' }}>
                                {t('offers.amount', {
                                    amount: numeral(Number(offer?.amount)).format('0,0, $'),
                                    years: offer?.term / 12
                                })}
                            </Text>
                        </Col>
                    </Row>
                    <Divider style={{ color: '#F1F7F8', height: 2, margin: 8 }}></Divider>
                    <Row>
                        <Col span={14}>
                            <Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0' }}>
                                {t('offers.type')}
                            </Text>
                            <br />
                            <Text style={{ fontSize: 18, fontWeight: 600, color: '#2F4858' }}>
                                {offer.ratio !== 'Seleccione'
                                    ? offer.ratio !== 'MIXED'
                                        ? renderType(offer, t)
                                        : t('offers.mixed', {
                                            years: (Number(offer?.data?.entailments[0]?.stages[0].to) - Number(offer?.data?.entailments[0]?.stages[0].from)) / 12
                                        })

                                    : ''}
                            </Text>
                        </Col>
                        <Col span={10} style={{ textAlign: 'right' }}>
                            <Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0' }}>
                                {t('offers.taeOneYear')}*
                            </Text>
                            <br />
                            <Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
                                {offer.data.entailments[0].tae > "0"
                                    ? numeral(Number(offer.data.entailments[0].tae)).format('0,0.00') + '%'
                                    : '-'}
                            </Text>
                        </Col>
                    </Row>
                    <Divider style={{ color: '#F1F7F8', height: 2, margin: 8 }}></Divider>
                    <Row
                        className={offerIdWithMaxHeight === offer.id && 'minHeight'}
                        style={{ minHeight: !isMobile && minHeight, display: 'block', width: '100%' }}
                    >
                        {offer && offer?.data?.entailments[0]?.stages.map((stage, index) => {
                            const years = ((Number(stage.to) - Number(stage.from)) / 12) % 1 === 0
                                ? Math.floor((Number(stage.to) - Number(stage.from)) / 12)
                                : ((Number(stage.to) - Number(stage.from)) / 12).toFixed(1)
                            return (
                                <div key={index}>
                                    {index === 0 ? (
                                        <Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', }}>
                                            {offer?.ratio === 'FIXED' ? (
                                                t('offers.always')
                                            ) : (
                                                Number(stage.to) < 12
                                                    ? Number(stage.to) + ' ' + t('offers.firstMonths')
                                                    : years + ' ' + t('offers.firstYears')
                                            )}
                                        </Text>
                                    ) : index === offer?.data?.entailments[0]?.stages.length - 1 ? (
                                        <Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0' }}>
                                            {t('viability.UltimateYears')}
                                        </Text>
                                    ) : (
                                        <Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0' }}>
                                            {t('offers.sections', {
                                                year1: Math.round(Number(stage.from) / 12) + 1,
                                                year2: Number(stage.to) / 12
                                            })}
                                        </Text>
                                    )
                                    }
                                    <Row key={index}>
                                        <Col span={14}>
                                            <Text style={{ fontSize: 18, fontWeight: 600, color: '#2F4858' }}>
                                                {stage.quote > '10' ? numeral(Number(stage.quote)).format('0,0.00') : '-'} {t('common.euroAtMonth')}
                                            </Text>
                                            {index !== 0 && (
                                                <div style={{ marginTop: -8 }}>
                                                    <Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0' }}>
                                                        {t('offers.estimated')}
                                                    </Text>
                                                </div>
                                            )}
                                        </Col>
                                        <Col span={10} style={{ textAlign: 'right', marginTop: 3 }}>
                                            <Text style={{ fontSize: 14, fontWeight: 400, color: '#2F4858' }}>
                                                {stage.rateType !== 'FIXED' && (<span>Eur + </span>)}
                                                <span style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
                                                    {numeral(Number(stage.tin)).format('0,0.00')} %
                                                </span> TIN
                                            </Text>
                                        </Col>
                                    </Row>
                                </div>
                            );
                        })}
                    </Row>
                    <Divider style={{ color: '#F1F7F8', height: 2, margin: 8 }}></Divider>
                    <Row style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0' }}>
                            {t('appraisal.nameTableEntailment')}
                        </Text>
                        <div style={{ marginTop: 4 }}>
                            {getLinkages(offer, t, false)}
                        </div>
                    </Row>
                    <CSSTransition
                        in={showMore}
                        timeout={300}
                        classNames="showMoreSlide"
                        unmountOnExit
                    >
                        <div style={{ zIndex: 0 }}>
                            <Divider style={{ color: '#F1F7F8', height: 2, margin: 8 }}></Divider>
                            <Row justify={'space-between'}>
                                <Col><Text style={{ fontWeight: 400, color: '#748EA0' }}>{t('appraisal.nameTableReview')}</Text></Col>
                                <Col><Text style={{ fontWeight: 600, color: '#748EA0', textAlign: 'right' }}>
                                    {renderRevision(offer.revision, t)}
                                </Text></Col>
                            </Row>
                            <Row justify={'space-between'}>
                                <Col><Text style={{ fontWeight: 400, color: '#748EA0' }}>{t('appraisal.nameTableTotalAmortization')}</Text></Col>
                                <Col><Text style={{ fontWeight: 600, color: '#748EA0', textAlign: 'right' }}>
                                    {renderAmortization(offer.totalFee, t)}
                                </Text></Col>
                            </Row>
                            <Row justify={'space-between'}>
                                <Col><Text style={{ fontWeight: 400, color: '#748EA0' }}>{t('appraisal.nameTablePartialAmortization')}</Text></Col>
                                <Col><Text style={{ fontWeight: 600, color: '#748EA0', textAlign: 'right' }}>
                                    {renderAmortization(offer.partialFee, t)}
                                </Text></Col>
                            </Row>
                            <Row justify={'space-between'}>
                                <Col><Text style={{ fontWeight: 400, color: '#748EA0' }}>{t('appraisal.nameTableOpeningCommission')}</Text></Col>
                                <Col><Text style={{ fontWeight: 600, color: '#748EA0', textAlign: 'right' }}>
                                    {Number(offer?.openFee)}€
                                </Text></Col>
                            </Row>
                            <Divider style={{ color: '#F1F7F8', height: 2, margin: 8 }}></Divider>
                            <Row justify="center">
                                <Text style={{ color: '#02C3CD', cursor: 'pointer' }} onClick={handleFileDownload}>
                                    <b>{t('init.download')}.pdf</b>
                                    <DownloadOutlined style={{ marginLeft: 8 }} />
                                </Text>
                            </Row>
                            <Divider style={{ color: '#F1F7F8', height: 2, margin: 8 }}></Divider>
                        </div>
                    </CSSTransition>
                    <div
                        onClick={() => setShowMore(!showMore)}
                        style={{ marginTop: 8, textAlign: 'center', zIndex: 99, width: '100%', backgroundColor: '#fff' }}>
                        <Text style={{ color: '#748EA0', cursor: 'pointer' }}>
                            {showMore ? t('offers.seeLess') : t('promoterLoan.seeMore')}
                            {showMore
                                ? <UpOutlined style={{ marginLeft: 4, fontSize: 12 }} />
                                : <DownOutlined style={{ marginLeft: 4, fontSize: 12 }} />
                            }
                        </Text>
                    </div>
                </div>
            </Card>
            <Modal
                title={t('bank.confirm')}
                className='ModalConfirmOffer'
                open={showModalChange}
                onOk={handleConfirmManual}
                onCancel={() => setShowModalChange(false)}>
                <Text style={{ color: '#748EA0' }}>
                    {t('bank.markedFavorite')} <b>{offer?.bankName}</b> {offer?.bankName.endsWith('.') ? '' : '.'} {t('bank.changeOffer')}
                </Text>
            </Modal>
            <Modal
                title={t('bank.confirm2')}
                className='ModalConfirmOffer'
                open={showModalConf}
                onOk={() => handleToggleFavorite(offer.id)}
                onCancel={() => setShowModalConf(false)}>
                <Text style={{ color: '#748EA0' }}>
                    {t('offer.marked')}
                    <b>{t('offers.typeAndBank', { type: renderType(offer, t), bank: capitalizeFirstLetter(offer.bankCode) })}</b>&nbsp;
                    {hasLinkages(offer) ? t('offer.withLinkages') : t('offer.withoutLinkages')}.&nbsp;
                    {t('bank.choose')} <b>{offer?.bankName}</b>{offer?.bankName.endsWith('.') ? '' : '.'}<br />
                    {t('bank.sure')}
                </Text>
            </Modal>
            <Modal
                title={t('offer.noChange')}
                className='ModalConfirmOffer'
                open={showModalNoChange}
                onOk={() => setShowModalNoChange(false)}
                onCancel={() => setShowModalNoChange(false)}
                footer={[
                    <Button
                        key="ok"
                        type="primary"
                        style={{ width: '100%' }}
                        onClick={() => setShowModalNoChange(false)}
                    >
                        {t('offers.accept')}
                    </Button>,
                ]}>
                <Text style={{ color: '#748EA0' }}>
                    {t('offer.noChangeDescription')}
                </Text>
            </Modal>
        </>
    );
};
export default OffersCard;
