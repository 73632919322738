import React from 'react';
import { Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';

const DifferenceInQuota = ({ visible, onCancel, onCreate }) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const detailOptions = (name, quantity, color, condition) => {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: name !== '' && 'space-between',
					marginBottom: 12,
					columnGap: 8
				}}>
				<div
					className="amounts-name-mortgage"
					style={{
						fontWeight: condition === 'total' && 600,
						fontSize: name === 'Diferencia total' && 16
					}}>
					{t(name)}
				</div>

				<div
					className="amounts-name-mortgage amounts-quantity"
					style={{
						fontWeight: condition === 'total' && 600,
						fontSize: name === 'Diferencia total' && 16,
						color: color
					}}>
					{quantity}
				</div>
			</div>
		);
	};

	return (
        (<Modal
			centered
			zIndex={1200}
			className="ModalCommon ModalCommonSmallDelete modal-onboarding-amount"
			cancelButtonProps={{
				className: 'button-secundary-gibobs',
				style: { display: 'none' }
			}}
			okButtonProps={{
				className: 'button-primari-gibobs',
				style: { display: 'none' }
			}}
			open={visible}
			title={
				visible?.title === 'onboarding.fixedSpread'
					? t('onboarding.fixedCalculationSavings')
					: t('onboarding.variableCalculationSavings')
			}
			okText={t('viability.continue')}
			cancelText={t('mydocuments.deleteCancel')}
			width={480}
			onCancel={() => {
				onCancel();
				form.resetFields();
			}}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						onCreate(values);
						form.resetFields();
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}>
            {detailOptions(
				'onboarding.quotaGibobs',
				numeral(visible && visible.quote).format('0,0.00 $') + '/' + t('viability.monthsEstimated'),
				'#02C3CD'
			)}
            {detailOptions(
				'onboarding.estimatedCurrentQuota',
				numeral(visible && visible.currentQuote).format('0,0.00 $') +
					'/' +
					t('viability.monthsEstimated'),
				'#02C3CD'
			)}
            <div style={{ border: '1px solid #748EA0', marginBottom: 12 }}></div>
            {detailOptions(
				'onboarding.savePerMonths',
				numeral(Math.abs(visible.quote - visible.currentQuote)).format('0,0.00 $'),
				visible.quote - visible.currentQuote < 0 ? '#52C41A' : '#FF4D4F',
				'total'
			)}
        </Modal>)
    );
};

export default DifferenceInQuota;
