import LoanPageDetailsNew from './LoansDesktop/LoanPageDetailsNew';

const LoanSwitchMobileOrDesktopDetails = ({}) => {
	return (
		<>
			<LoanPageDetailsNew />
		</>
	);
};

export default LoanSwitchMobileOrDesktopDetails;
