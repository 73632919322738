import React, { useState } from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import WaterWaveRename from "./WaterWaveRename";
import ScoringModal from "./ScoringModal";
import { isMobile } from "react-device-detect";
const { Text } = Typography;

const ScoreGibobsWidgets = ({ scoring, dateScoringFlag }) => {
  const { t } = useTranslation();
  const [taskToEdit, setTaskToEdit] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const hideAddTaskModal = () => {
    setShowModal(false);
    setTaskToEdit(null);
  };
  const enviarNuevoMensaje = () => {
    setShowModal(false);
  };
  const showSendMessagesModal = () => {
    setShowModal(true);
  };

  let typeCategory;

  if (scoring >= 75 && scoring < 100) {
    typeCategory = t("scoring.oak");
  } else if (scoring >= 50 && scoring < 75) {
    typeCategory = t("scoring.youngOak");
  } else if (scoring >= 25 && scoring < 50) {
    typeCategory = t("scoring.sprout");
  } else if (scoring >= 1 && scoring < 25) {
    typeCategory = t("scoring.seed");
  } else {
    typeCategory = t("scoring.noData");
  }

  const typeText = {
    Roble: t("scoring.descriptionOakCategory"),
    "Roble jóven": t("scoring.descriptionYoungOakCategory"),
    Retoño: t("scoring.descriptionSproutCategory"),
    Semilla: t("scoring.descriptionSeedCategory"),
    "Sin Datos": t("scoring.descriptionNoDataCategory"),
  };

  let waterScoring;

  if (dateScoringFlag) {
    waterScoring = (
      <WaterWaveRename scoring={scoring} typeCategory={typeCategory} />
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: isMobile ? "column" : null}}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginRight: isMobile ? 0 : "50px",
          textAlign: isMobile ? "center" : "left",
        }}
      >
        <Text
          style={{
            fontSize: "14px",
            fontWeight: "600",
            marginBottom: "16px",
          }}
        >
          {t("scoring.financialHealthIndex")}
        </Text>

        {isMobile && (
          <Text
            style={{
              width: "100%",
              height: "auto",
              fontSize: "12px",
              marginBottom: "24px",
              color: "#2F4858",
              alignText: "center",
            }}
          >
            {t("balance.versionMobile")}
          </Text>
        )}

        {!isMobile && (
          <Text
            style={{
              width: "100%",
              height: "auto",
              fontSize: "12px",
              marginBottom: "12px",
              color: "#748EA0",
              lineHeight: 1.5715
            }}
          >
            {typeText[typeCategory]}
          </Text>
        )}

        {!isMobile && (
          <Text
            id="gb-scoring-text-modal"
            style={{
              fontSize: "10px",
              fontWeight: "normal",
              color: " #02C3CD",
              cursor: "pointer",
            }}
            onClick={showSendMessagesModal}
          >
            {t("scoring.calculateScoring")}
          </Text>
        )}
      </div>
      {waterScoring}
      <ScoringModal
        key={taskToEdit}
        visible={showModal || taskToEdit}
        onCreate={enviarNuevoMensaje}
        onCancel={hideAddTaskModal}
        width={800}
        taskToEdit={taskToEdit}
      />
         
    </div>
    
  );
};

export default ScoreGibobsWidgets;
