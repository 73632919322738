import React from "react";
import { Liquid } from "@ant-design/plots";

function DemoLiquid({ percent }) {
  const config = {
    percent: percent,
    statistic: {
      content: {
        formatter({ }) {
          return "";
        },
      },
    },
    shape: function (x, y, width, height) {
      width = 91;
      height = 91;
      return [
        ["M", x, y - height / 2],
        ["L", x + width / 2, y],
        ["L", x + width / 2.5, y],
        ["L", x + width / 2.5, y + height / 2],
        ["L", x - width / 2.5, y + height / 2],
        ["L", x - width / 2.5, y],
        ["L", x - width / 2, y],
        ["Z"],
      ];
    },
    color: "#02C3CD",
    outline: {
      border: 2,
      distance: 2,
    },
    wave: {
      length: 20,
    },
  };

  return <Liquid {...config} />;
}

export default DemoLiquid;
