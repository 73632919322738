import React, { useState, useEffect } from "react";
import {
  Layout,
  Tabs,
  Card,
  Typography,
  Button,
  Progress,
  Row,
  Col,
} from "antd";
import { useTranslation } from "react-i18next";
import TablePassive from "./Balance/tablePassive";
import TableActive from "./Balance/tableActive";
import TableIncomesComponent from "./Balance/tableIncomesComponent";
import TableExpensesComponent from "./Balance/tableExpensesComponent";
import PieClosedActivos from "./Balance/graficas/PiedClosedActivos";
import PieClosedPassive from "./Balance/graficas/PieClosedPassives";
import { PieChartOutlined, EditOutlined } from "@ant-design/icons";
import LoanToValueCard from "./Balance/LoanToValueCard";
import SavingCapacity from "./Balance/savingCapacity";
import StressLevel from "./Balance/stressLevel";
import PieActivesPassives from "./Balance/graficas/pieActivesPassives";
import ImageExample from "../../assets/pantalla_balance.png";
import { getAsset, getSeats } from "../../store/data/actions";
import { useSelector, useDispatch } from "react-redux";
import padlockIcon from "../../assets/icons/padlock.svg";
import env from "../../environment";
import axios from "axios";
import { isMobile } from "react-device-detect";
import numeral from "numeral";
import BalanceDesktop from "../../assets/BalanceDesktop.png";
import PieClosedIncome from "./Balance/graficas/pieClosedIncome";
import PieClosedExpenses from "./Balance/graficas/PieClosedExpenses";
import LayoutOperations from "./LayoutOperations";


const { Text } = Typography;
const BalancePage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const userToken = useSelector((state) => state.auth.token);
  const assetList = useSelector((state) => state.data.assetList);
  const seatList = useSelector((state) => state.data.getSeats);
  const [changeScreen, setChangeScreen] = useState("initial");
  const [dataScoring, setDataScoring] = useState();


  function callback(key) {
    console.log(key);
  }

  useEffect(() => {
    dispatch(getAsset(userToken));
  }, []);

  useEffect(() => {
    dispatch(getSeats(userToken, 2021));
  }, []);

  const dataScoringCall = () => {
    axios
      .get(`${env.api.url}/v1/scoring/get-scoring`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        // console.log(response.data.data.kpis);
        setDataScoring(response.data.data.kpis);
        if (response.data.data.completed === 0) {
          setChangeScreen("initial");
        } else if (response.data.data.completed > 0) {
          setChangeScreen("balance");
        }
      });
  };

  useEffect(() => {
    dataScoringCall();
  }, []);

  const sumaAssets = (categorys, types) => {
    let total = 0;

    assetList &&
      assetList.forEach((type) => {
        if (type.category === categorys && type.type === types) {
          total += parseInt(type.amount);
        }
      });
    return total;
  };

  const sumaSeats = (categorys, types) => {
    let total = 0;
    seatList &&
      seatList.forEach((type) => {
        if (type.category === categorys && type.type === types) {
          total += Object.values(type.amounts).reduce((a, b) => a + b);
        }
      });
    return total;
  };

  return (
    <Layout>
      {/* <MyBreadcrumb data={data} /> */}
      <LayoutOperations />


      {isMobile ? (
        <Card
          className="CardStyleClientGibobs"
        // style={{ width: "calc(100% - 32px)" }}
        >
          <div style={{ marginBottom: "16px", textAlign: "center" }}>
            <Text
              strong
              style={{
                color: "#2F4858",
                fontSize: "14px",
              }}
            >
              {t("balance.balanceTitle")}
            </Text>
          </div>
          <div style={{ marginBottom: "24px", textAlign: "center" }}>
            <Text
              style={{
                color: "#2F4858",
                fontSize: "12px",
              }}
            >
              {t("balance.versionMobile")}
            </Text>
          </div>
          <img
            src={BalanceDesktop}
            alt="Balance Mobile"
            height="100%"
            width="100%"
          />
        </Card>
      ) : (
        <div style={{ height: "auto", marginBottom: "100px" }}>
          {changeScreen === "initial" ? (
            <>
              <Card
                style={{
                  margin: "auto",
                  width: "calc(100% - 80px)",
                  alignItems: "center",
                  boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
                }}
              >
                <div style={{ marginBottom: "16px", textAlign: "center" }}>
                  <Text
                    strong
                    style={{
                      color: "#2F4858",
                      fontSize: "14px",
                    }}
                  >
                    {t("balance.balanceTitle")}
                  </Text>
                </div>
                <div style={{ marginBottom: "40px", textAlign: "center" }}>
                  <Text
                    style={{
                      color: "#748EA0",
                      fontSize: "12px",
                    }}
                  >
                    {t("balance.descriptionBalanceInit")}
                  </Text>
                </div>
                <div
                  style={{
                    marginBottom: "32px",
                    marginTop: "40px",
                    textAlign: "center",
                  }}
                >
                  <Button
                    id="gb-balance-button-initial"
                    type="primary"
                    className="button-primari-gibobs"
                    style={{ fontSize: "14px" }}
                    onClick={() => setChangeScreen("second")}
                  >
                    {t("loan.complete")}
                  </Button>
                </div>
              </Card>
              <Card
                style={{
                  margin: "auto",
                  width: "calc(100% - 80px)",
                  alignItems: "center",
                  boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
                  marginTop: "40px",
                }}
              >
                <div style={{ marginBottom: "16px" }}>
                  <Text
                    strong
                    style={{
                      color: "#2F4858",
                      fontSize: "14px",
                    }}
                  >
                    {t("balance.exampleBalance")}
                  </Text>
                </div>
                <div style={{ marginBottom: "40px" }}>
                  <Text
                    style={{
                      color: "#748EA0",
                      fontSize: "12px",
                    }}
                  >
                    {t("balance.descriptionBalance")}
                  </Text>
                </div>
                <img
                  src={ImageExample}
                  alt="ExampleImage"
                  height="calc(100% - 64px)"
                  width="100%"
                />
              </Card>
            </>
          ) : null}
          {changeScreen === "second" ? (
            <Card
              style={{
                margin: "auto",
                width: "calc(100% - 80px)",
                alignItems: "center",
                boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ marginBottom: "16px" }}>
                    <Text
                      strong
                      style={{
                        color: "#2F4858",
                        fontSize: "14px",
                      }}
                    >
                      {t("balance.balanceTitle")}
                    </Text>
                  </div>
                  <div style={{ marginBottom: "40px" }}>
                    <Text
                      style={{
                        color: "#748EA0",
                        fontSize: "12px",
                      }}
                    >
                      {t("balance.balanceDescriptionPage")}
                    </Text>
                  </div>
                </div>
                <Button
                  type="primary"
                  className="button-primari-gibobs"
                  style={{ fontSize: "14px" }}
                  onClick={() => {
                    setChangeScreen("balance");
                    dataScoringCall();
                  }}
                >
                  {t("form.save")}
                </Button>
              </div>
              <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab={t("balance.activeAndPassiveTitle")} key="1">
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "48%", marginRight: "32px" }}>
                      <TableActive />
                    </div>
                    <div style={{ width: "48%" }}>
                      <TablePassive />
                    </div>
                  </div>
                </TabPane>
                <TabPane tab={t("balance.incomeAndExpensesTitle")} key="2">
                  <div className="TableIncomes-Expenses">
                    <TableIncomesComponent />
                  </div>
                  <div className="TableIncomes-Expenses">
                    <TableExpensesComponent />
                  </div>
                </TabPane>
              </Tabs>
            </Card>
          ) : null}
          {changeScreen === "balance" ? (
            <div>
              <Card className="gcard4" key={"rde"}>
                <img
                  width={16}
                  alt="padlockIcon"
                  src={padlockIcon}
                  style={{ position: "relative" }}
                />
                <Text
                  style={{
                    fontSize: "12px",
                    color: "#748EA0",
                    marginLeft: 14,
                  }}
                  className="title-label"
                >
                  {t("balance.lock")}
                </Text>
              </Card>
              <Card
                style={{
                  margin: "auto",
                  width: "calc(100% - 80px)",
                  alignItems: "center",
                  boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
                }}
              >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      textAlign: "center",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        onClick={() => {
                          setChangeScreen("second");
                        }}
                      >
                        <Text
                          strong
                          style={{
                            color: "#2F4858",
                            fontSize: "14px",
                            textAlign: "center",
                          }}
                        >
                          {t("balance.activeTitle")}
                        </Text>
                        <EditOutlined
                          onClick={() => setChangeScreen("second")}
                          style={{
                            color: "#02C3CD",
                            fontSize: "14px",
                            position: "relative",
                            top: 80,
                            right: 24,
                            pointer: "cursor",
                          }}
                        />
                        <PieClosedActivos
                          realestate={sumaAssets("realestate", "assets")}
                          vehicles={sumaAssets("vehicles", "assets")}
                          cash={sumaAssets("cash", "assets")}
                          other={sumaAssets("other", "assets")}
                        />
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        onClick={() => {
                          setChangeScreen("second");
                        }}
                      >
                        <Text
                          strong
                          style={{
                            color: "#2F4858",
                            fontSize: "14px",
                          }}
                        >
                          {t("balance.passiveTitle")}
                        </Text>
                        <EditOutlined
                          onClick={() => setChangeScreen("second")}
                          style={{
                            color: "#02C3CD",
                            fontSize: "14px",
                            position: "relative",
                            top: 80,
                            right: 24,
                            pointer: "cursor",
                          }}
                        />
                        <PieClosedPassive
                          realestate={sumaAssets("realestate", "liability")}
                          vehicles={sumaAssets("vehicles", "liability")}
                          other={sumaAssets("other", "liability")}
                        />
                      </div>
                    </div>

                    <div style={{ display: "flex", marginRight: "100px" }}>
                      <div>
                        <Text
                          strong
                          style={{
                            color: "#2F4858",
                            fontSize: "14px",
                          }}
                        >
                          {t("balance.valueTotal")}
                        </Text>
                        <PieActivesPassives
                          realestate={
                            sumaAssets("realestate", "assets") -
                            sumaAssets("realestate", "liability")
                          }
                          vehicles={
                            sumaAssets("vehicles", "assets") -
                            sumaAssets("vehicles", "liability")
                          }
                          other={
                            sumaAssets("other", "assets") +
                            sumaAssets("cash", "assets") -
                            sumaAssets("other", "liability")
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      textAlign: "center",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        onClick={() => {
                          setChangeScreen("second");
                        }}
                      >
                        <Text
                          strong
                          style={{
                            color: "#2F4858",
                            fontSize: "14px",
                          }}
                        >
                          {t("balance.incomesTitle")}
                        </Text>
                        <EditOutlined
                          onClick={() => setChangeScreen("second")}
                          style={{
                            color: "#02C3CD",
                            fontSize: "14px",
                            position: "relative",
                            top: 80,
                            right: 24,
                            pointer: "cursor",
                          }}
                        />
                        <PieClosedIncome
                          payrolls={sumaSeats("payrolls", "income")}
                          rents={sumaSeats("rents", "income")}
                          other={sumaSeats("other", "income")}
                        />
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        onClick={() => {
                          setChangeScreen("second");
                        }}
                      >
                        <Text
                          strong
                          style={{
                            color: "#2F4858",
                            fontSize: "14px",
                          }}
                        >
                          {t("balance.expensesTitle")}
                        </Text>
                        <EditOutlined
                          onClick={() => setChangeScreen("second")}
                          style={{
                            color: "#02C3CD",
                            fontSize: "14px",
                            position: "relative",
                            top: 80,
                            right: 24,
                            pointer: "cursor",
                          }}
                        />
                        <PieClosedExpenses
                          loans={sumaSeats("loans", "expense")}
                          rents={sumaSeats("rents", "expense")}
                          supplies={sumaSeats("supplies", "expense")}
                          insurances={sumaSeats("insurances", "expense")}
                          education={sumaSeats("education", "expense")}
                          vehicles={sumaSeats("vehicles", "expense")}
                          market={sumaSeats("market", "expense")}
                          leisure={sumaSeats("leisure", "expense")}
                          purchases={sumaSeats("purchases", "expense")}
                          other={sumaSeats("other", "expense")}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>

              <Row gutter={40}>
                <div
                  style={{
                    display: "flex",
                    marginLeft: "40px",
                    marginRight: "40px",
                    width: "100%",
                  }}
                >
                  <Col lg={10} xs={24}>
                    <Card
                      style={{
                        marginTop: "40px",
                        marginRight: "40px",
                        width: "100%",
                        alignItems: "center",
                        border: "1px solid #C0F0F3",
                      }}
                    >
                      <LoanToValueCard
                        title={t("balance.loanToValueTitle")}
                        text={t("balance.descriptionLoanToValue")}
                        percentAssets={
                          dataScoring && dataScoring.ltvRealestate.amount
                        }
                        percentGlobal={
                          dataScoring && dataScoring.ltvGlobal.amount
                        }
                      />
                    </Card>
                  </Col>
                  <Col lg={14} xs={24}>
                    <Card
                      style={{
                        marginTop: "40px",
                        width: "100%",
                        alignItems: "center",
                        border: "1px solid #C0F0F3",
                        height: "auto",
                      }}
                    >
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={{ marginBottom: "16px" }}>
                            <Text
                              strong
                              style={{
                                color: "#2F4858",
                                fontSize: "14px",
                              }}
                            >
                              {t("balance.debtAnalysis")}
                            </Text>
                          </div>
                          <div
                            style={{
                              marginBottom: "16px",
                              width: "250px",
                              marginRight: "40px",
                            }}
                          >
                            <Text
                              style={{
                                color: "#748EA0",
                                fontSize: "12px",
                              }}
                            >
                              {t("balance.debtAnalysisDescription")}
                            </Text>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            marginTop: "20px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginRight: "40px",
                            }}
                          >
                            <Text
                              style={{
                                width: "146px",
                                fontSize: "12px",
                                color: "#748EA0",
                                marginTop: "20px",
                                textAlign: "left",
                              }}
                            >
                              {t("balance.averageDebt")}
                            </Text>
                            <div
                              style={{
                                display: "flex",
                                marginTop: "8px",
                                alignItems: "center",
                              }}
                            >
                              <PieChartOutlined
                                style={{
                                  color: "#02C3CD",
                                  fontSize: "24px",
                                  marginRight: "8px",
                                }}
                              />
                              <Text
                                strong
                                style={{
                                  fontSize: "24px",
                                  fontWeight: "500",
                                  color: "#2F4858",
                                }}
                              >
                                {dataScoring &&
                                  numeral(dataScoring.debs.amount).format(
                                    "0.000 %"
                                  )}
                              </Text>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "223px",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: "12px",
                                textAlign: "center",
                                color: "#748EA0",
                                marginTop: "20px",
                              }}
                            >
                              {t("balance.shortTerm")}
                            </Text>
                            <Text
                              style={{
                                fontSize: "12px",
                                color: "#2F4858",
                                marginTop: "8px",
                              }}
                            >
                              {t("balance.TreasuryVSShortDebt")}
                            </Text>
                            <div style={{ display: "flex" }}>
                              <Progress
                                className="progressAnalitic"
                                percent={
                                  dataScoring &&
                                  (
                                    dataScoring.shortDebtTreasury.amount * 100
                                  ).toFixed(2)
                                }
                                strokeColor={{
                                  "0%": "#80E1E6",
                                  "100%": "#02AFB8",
                                }}
                                trailColor={"#F1F7F8"}
                                strokeWidth={8}
                              />

                              <div
                                style={{
                                  marginLeft: "-25px",
                                  color: "#2F4858",
                                  fontSize: "14px",
                                  fontWeight: 500,
                                }}
                              >
                                {dataScoring &&
                                  (
                                    dataScoring.shortDebtTreasury.amount * 100
                                  ).toFixed(2)}
                                %
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </div>
              </Row>

              <Row gutter={40}>
                <div
                  style={{
                    display: "flex",
                    marginLeft: "40px",
                    marginRight: "40px",
                    width: "100%",
                  }}
                >
                  <Col lg={10} xs={24}>
                    <Card
                      style={{
                        marginTop: "40px",
                        marginRight: "40px",
                        width: "100%",
                        alignItems: "center",
                        border: "1px solid #C0F0F3",
                      }}
                    >
                      <LoanToValueCard
                        title={t("scoring.yield")}
                        text={t("balance.descriptionYield")}
                        percentGlobal={
                          dataScoring && dataScoring.yieldAmount.amount
                        }
                        percentAssets={
                          dataScoring && dataScoring.yieldRealestate.amount
                        }
                      />
                    </Card>
                  </Col>
                  <Col lg={10} xs={24}>
                    <Card
                      style={{
                        marginTop: "40px",
                        width: "100%",
                        alignItems: "center",
                        border: "1px solid #C0F0F3",
                      }}
                    >
                      <StressLevel
                        stressTestNormal={
                          dataScoring &&
                          Math.floor(dataScoring.stressTest.amount / 12)
                        }
                        stressTestSellRealestate={
                          dataScoring &&
                          Math.floor(
                            dataScoring.stressTestRealestate.amount / 12
                          )
                        }
                        stressTestNormalMonth={
                          dataScoring &&
                          Math.round(dataScoring.stressTest.amount) % 12
                        }
                        stressTestSellRealestateMonth={
                          dataScoring &&
                          Math.round(dataScoring.stressTestRealestate.amount) %
                          12
                        }
                      />
                    </Card>
                  </Col>
                </div>
              </Row>

              <Row>
                <Col lg={15} xs={24}>
                  <Card
                    style={{
                      margin: "40px",
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid #C0F0F3",
                    }}
                  >
                    <SavingCapacity
                      yearSavings={
                        dataScoring && dataScoring.yearSavings.amount
                      }
                      monthSavings={
                        dataScoring && dataScoring.monthSaving.amount
                      }
                      freeIncome={dataScoring && dataScoring.freeIncome.amount}
                    />
                  </Card>
                </Col>
              </Row>
            </div>
          ) : null}
        </div>
      )}
    </Layout>
  );
};

export default BalancePage;
