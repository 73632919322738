import DocumentsWidget from './Different-Widgets/documentsWidget';
import StagesMortgagesWidget from './Different-Widgets/stagesMortgagesWidget';
import WidgetVacio from './Different-Widgets/WidgetVacio';
import BalanceWidget from './Different-Widgets/balanceWidget';
import Documentation from './Different-Widgets/document';
import PersonalAnalyst from './Different-Widgets/personalAnalyst';
import BankAnalysis from './Different-Widgets/banckAnalysis';
import Oferts from '../../Pages/Operaciones/Offers/OffersTable';
import WidgetsDashboard from './WidgetsDashboard';
import DataBasicINMO from '../../Pages/HerramientasVenta/DataBasicINMO';
import DocumentationINMMO from '../../Pages/HerramientasVenta/DocumentationINMMO';
import WidgetsDashboardRealState from './DashBoard-Realestate/WidgetsDashboardRealState';
import WidgetsRealestateSmall from './DashBoard-Realestate/WidgetsRealestateSmall';
import WidgetsDashboardSmall from './WidgetsDashboardSmall';
import WidgetTopStartups from './WidgetTopStartups';

export const types = {
	OPERATION_STAGES: StagesMortgagesWidget,
	OPERATION_DOCUMENTS: DocumentsWidget,
	OPERATION_VACIO: WidgetVacio,
	OPERATION_BALANCE: BalanceWidget,
	WIDGET_DOCUMENTS: Documentation,
	PERSONAL_ANALYST: PersonalAnalyst,
	WIDGET_TOP_STARTUPS: WidgetTopStartups,
	WIDGET_BANK_ANALYSIS: BankAnalysis,
	WIDGET_OFERTS: Oferts,
	WIDGET_BANK_ANALYSISB: BankAnalysis,
	WIDGET_DASHBOARDGENERAL: WidgetsDashboard,
	WIDGET_DASHBOARDGENERALSMALL: WidgetsDashboardSmall,
	WIDGET_DATA_BASIC: DataBasicINMO,
	WIDGET_HP: DocumentationINMMO,
	WIDGET_DOCUMENTS_INMO: Documentation,
	WIDGET_DASHBOARDGENERAL_REALSTATE: WidgetsDashboardRealState,
	WIDGET_DASHBOARDGENERAL_REALSTATE_SMALL: WidgetsRealestateSmall
};
