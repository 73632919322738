import React, { useState, useEffect } from 'react';
import { Tabs, Slider, Row, Col, Card, Button, Skeleton, Spin, Typography, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import { isMobile, isMobileOnly } from 'react-device-detect';
import IconFixed from '../../../../assets/viability/IconFixed.png';
import IconMixed from '../../../../assets/viability/IconMixed.png';
import IconSpread from '../../../../assets/viability/IconSpread.png';

import editIcon from '../../../../assets/onboarding/editIcon.svg';
import { InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import CalculationMortgageAmount from '../Modals/CalculationMortgageAmount';
import SavingsCalculation from '../Modals/SavingsCalculation';
import ComplicatedViability from '../../Components/ComplicatedViability';
import EditData from './EditData';
import { titlePageMobile } from '../../../HerramientasVenta/reusableItems';
import Viability from './Viability';
import { useParams } from 'react-router-dom';
import { setDataViabilityForMortgage } from '../../../../store/viability/actions';
import { useSelector, useDispatch } from 'react-redux';
import env from '../../../../environment';
import axios from 'axios';
import i18n from 'i18next';
import { VIABILITYDATA } from '../../../../store/viability/constants';
import { stages } from '../../../../store/auth/actions';
import { useNavigate, useLocation } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { trace } from '../../../../components/tagmanager';
import _ from 'lodash';
import ReviewsCustomer from '../../../../components/widgets/ReviewsCustomer';
import KnowMore from '../Modals/KnowMore';
import AnalystIntroModal from '../Modals/AnalystIntroModal'

const { Text } = Typography;

const ResultAndViabilityGeneral = ({ dataOperation, refreshData }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { operationId } = useParams();
	const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
	const [visibleModalCalculationAmount, setVisibleModalCalculationAmount] = useState(false);
	const [visibleModalSavings, setVisibleModalSavings] = useState(false);
	const [visibleModalKnowMore, setVisibleModalKnowMore] = useState(false);
	const [loading, setLoading] = useState(false);
	const [, setRecalculateViability] = useState(false);
	const [changePages, setChangesPages] = useState(1);
	const viabilityData = useSelector((state) => state.viability.viabilityData);
	const operationIdViability = useSelector((state) => state.viability.operationIdViability);
	const userToken = useSelector((state) => state.auth.token);
	const analytics = getAnalytics();
	const location = useLocation();
	const rol = useSelector((state) => state.auth.roles);
	const [years, setYears] = useState(40);
	const [, setQualityFer] = useState(undefined);
    const collapsed = useSelector((state) => state.data.collapseMenu);

    useEffect(() => {
        if (
            (viabilityData === undefined && operationIdViability === undefined) ||
            (operationIdViability !== undefined && operationIdViability !== operationId)
        ) {
            dispatch(setDataViabilityForMortgage(operationId, userToken));
        }
    }, []);

    useEffect(() => {
        if (viabilityData) {
            trace({
                location,
                userToken,
                rol,
                stage: 'basic_02_OK',
                operationName: operationId,
                viability: _.get(viabilityData, 'viability'),
                //amountMortgage: nameOperations && nameOperations.operationData && nameOperations.operationData.mortgage.maxBudget,
                maxBudget: _.get(viabilityData, 'operation.property'),
                amountMortgage: _.get(viabilityData, 'operation.mortgageAmount')
                //incomes: nameOperations && nameOperations.owners[0].netIncomes
            });

			axios
				.get(`${env.api.url}/operations/operation/operationData/${operationId}/client`, {
					headers: { Authorization: `Bearer ${userToken}` }
				})
				.then((response) => {
					setQualityFer(response.data.data.operation.qualityferViableResult);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [viabilityData]);

    const DocumentsResidentMortgageEs =
        'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/docu_bienvenida_hipoteca_residentes_es.pdf';
    const DocumentsResidentMortgageEn =
        'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/docu_bienvenida_hipoteca_residentes_en.pdf';
    const DocumentsResidentMortgageCat =
        'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/docu_bienvenida_hipoteca_residentes_cat.pdf';

    let documentToDownload;

    if (i18n.language === 'es' || i18n.language === 'es-ES') {
        documentToDownload = DocumentsResidentMortgageEs;
    } else if (i18n.language === 'en') documentToDownload = DocumentsResidentMortgageEn;
    else {
        documentToDownload = DocumentsResidentMortgageCat;
    }

    const renderQuotas = (title, quote, fixed, years) => {
        if (title === 'viabilityNew.typeFixed') {
            return (
                <Row style={{ marginTop: 10, marginBottom: 65 }}>
                    <Col span={12} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                            {t('cuota.cuota')}
                        </Text>
                        <Text style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>
                            {'(' + numeral(fixed).format('0,0.00') + '% TIN)'}
                        </Text>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                            {quote < 0
                                ? 0 + '€'
                                : numeral(quote).format('0,0 $') + '/' + t('viability.monthsEstimated')}
                        </Text>
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row style={{ marginTop: 10 }}>
                    <Col span={12} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                            {title === 'viabilityNew.typeSpread'
                                ? t('onboarding.quoteFirstYear')
                                : t('onboarding.quoteMoreYears', { years: (years + 1) / 12 })}
                        </Text>
                        <Text style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>
                            {'(' + numeral(fixed).format('0,0.00') + '% TIN)'}
                        </Text>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                            {quote < 0
                                ? 0 + '€'
                                : numeral(quote).format('0,0 $') + '/' + t('viability.monthsEstimated')}
                        </Text>
                    </Col>
                </Row>
            );
        }
    };

    const renderQuotasMixed = (title, quote, fixed) => {
        return (
            <Row style={{ marginTop: 10 }}>
                <Col span={16} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                        {title === "viabilityNew.typeMixed" ? t('onboarding.quoteRestYearEstimated') : t('onboarding.quoteRestYear')}
                    </Text>
                    <Text style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>
                        {'(Eur + ' + numeral(fixed).format('0,0.00') + '% TIN)'}
                    </Text>
                </Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                    <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                        {quote < 0
                            ? 0 + '€'
                            : numeral(quote).format('0,0 $') + '/' + t('viability.monthsEstimated')}
                    </Text>
                </Col>
            </Row>
        );
    };

    const typeOfQuote = (
        title,
        icon,
        quote,
        savings,
        tae,
        fixed,
        monthSavings,
        quotes,
        marketRate,
        years,
        marketCost,
        gibobsCost
    ) => {
        return (
            <Col
                span={8}
                style={{ marginTop: 25, minWidth: 328 }}
                onClick={() => setVisibleModalKnowMore(true)}>
                {viabilityData !== undefined ? (
                    <div className="viability-quote">
                        <div style={{ display: 'flex' }}>
                            <img src={icon} alt="IconFixed" style={{ width: 32, height: 32 }}></img>
                            <div
                                className="title"
                                style={{
                                    marginBottom: 16,
                                    minHeight: isMobileOnly && 32,
                                    marginLeft: 8,
                                    marginTop: isMobileOnly ? 4 : 8,
                                    fontSize: isMobileOnly && 20
                                }}>
                                {t(title)}{' '}
                            </div>
                        </div>
                        <Divider
                            type="horizontal"
                            style={{
                                borderColor:
                                    title === 'viabilityNew.typeFixed'
                                        ? '#05C69A'
                                        : title === 'viabilityNew.typeMixed'
                                            ? '#F5CD57'
                                            : '#0088C6',
                                marginTop: 0
                            }}></Divider>

                        <div style={{ marginTop: -16 }}>
                            <Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>
                                {t('viabilityNew.Tae') + ' ' + numeral(tae * 100).format('0,0.00') + '%'}
                            </Text>
                        </div>

                        {/**si hubiera mas tramos habria que poner aqui un map del renderQuotes */}

                        {renderQuotas(title, quote, fixed, years)}

                        {title === 'viabilityNew.typeMixed' &&
                            viabilityData.operation.types.mixedTin.sections &&
                            renderQuotasMixed(
                                title,
                                viabilityData.operation.types.mixedTin.sections[1].monthlyPayment,
                                viabilityData.operation.types.mixedTin.sections[1].tin * 100
                            )}
                        {title === 'viabilityNew.typeSpread' &&
                            viabilityData.operation.types.variableTin.sections &&
                            renderQuotasMixed(
                                title,
                                viabilityData.operation.types.variableTin.sections[1].monthlyPayment,
                                viabilityData.operation.types.variableTin.sections[1].tin * 100
                            )}

                        <Divider
                            type="horizontal"
                            style={{
                                borderColor: '#C0DAEE',
                                marginTop: 20
                            }}></Divider>

                        <Row
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginBottom: title === 'viabilityNew.typeFixed' ? 5 : undefined
                            }}>
                            <div>
                                <Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 400 }}>
                                    {t('onboarding.withGibobsYouSave')}{' '}
                                </Text>
                            </div>

                            <div style={{ textAlign: 'right' }}>
                                <Text style={{ color: '#2F4858', fontSize: 20, fontWeight: 600 }}>
                                    {savings < 0 ? 0 + '€' : numeral(savings).format('0,0 $')}
                                </Text>
                                <InfoCircleOutlined
                                    style={{ color: '#2F4858', fontSize: 24, marginLeft: 6 }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setVisibleModalSavings({
                                            title,
                                            quote,
                                            savings,
                                            fixed,
                                            monthSavings,
                                            quotes,
                                            marketRate,
                                            marketCost,
                                            gibobsCost,
                                            tae
                                        });
                                        logEvent(analytics, 'screen_view', {
                                            screen_name: 'onboarding2022.h_tooltipSavings'
                                        });
                                    }}
                                />
                            </div>
                        </Row>
                    </div>
                ) : (
                    <div className="viability-quote">
                        <Skeleton active />
                        <div className="savings">
                            <Skeleton active />
                        </div>
                    </div>
                )}
            </Col>
        );
    };

    const viabialityData = (type, name, savingsByRest, section, value) => {
        return (
            viabilityData &&
            viabilityData.operation &&
            viabilityData.operation.types &&
            viabilityData.operation.types[type] &&
            viabilityData.operation.types[type][name] &&
            viabilityData.operation.types[type][name][savingsByRest] &&
            viabilityData.operation.types[type][name][savingsByRest][section] &&
            viabilityData.operation.types[type][name][savingsByRest][section][value]
        );
    };

    const viabialityDataMixed = (type, name, savingsByRest, section, value) => {
        return (
            viabilityData &&
            viabilityData.operation &&
            viabilityData.operation.types &&
            viabilityData.operation.types[type] &&
            viabilityData.operation.types[type][name] &&
            viabilityData.operation.types[type][name][savingsByRest][0] &&
            viabilityData.operation.types[type][name][savingsByRest][0][section] &&
            viabilityData.operation.types[type][name][savingsByRest][0][section][value]
        );
    };

    const disbledButton = () => {
        return viabilityData && viabilityData && viabilityData.excepcionalViability === 'NOSAVINGS'
            ? true
            : viabilityData && viabilityData.excepcionalViability === 'INCOME_LEVEL'
                ? true
                : viabilityData && viabilityData.excepcionalViability === 'MORTGAGE_AMOUNT'
                    ? true
                    : false;
    };

	const calculateViability = (value) => {
		dispatch({
			type: VIABILITYDATA,
			payload: undefined
		});
		setYears(value);
		axios
			.post(
				`${env.api.url}/mortgages/conventional-viability-mortgage/calculate-viability/${operationId}`,
				{
					overrides: {
						quotes: value * 12
					}
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					refreshData();
					dispatch({
						type: VIABILITYDATA,
						payload: response.data.data
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const changesStage = (owner, key, value) => {
		setLoading(true);
		axios
			.post(
				`${env.api.url}/v1/mortgages/data/update/${operationId}`,
				{ key, participant: owner, value },
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					axios
						.post(
							`${env.api.url}/v1/mortgages/check-full-declaratives`,
							{ operationId },
							{
								headers: { Authorization: `Bearer ${userToken}` }
							}
						)
						.then((response) => {
							if (response.data.success) {
								axios
									.get(`${env.api.url}/v1/auto-things/AutoStagesGenerator/${operationId}`, {
										headers: {
											Authorization: `Bearer ${userToken}`
										}
									})
									.then((response) => {
										if (response.data.success) {
											dispatch(stages(response.data.data.currentStage));
											navigate(`/operations/${operationId}/${response.data.data.currentStage}`);
											setLoading(false);
										}
									});
							}
						});
				}
			});
	};

    return (<>
        {changePages === 2 && (
            <EditData
                changePage={titlePageMobile((e) => setChangesPages(e), 1, t('loan.editData'))}
                type="mortgage"
                dataOperation={dataOperation}
                refreshData={() => refreshData()}
                setPages={(e) => setChangesPages(e)}
                setRecalculateViability={(e) => setRecalculateViability(e)}
                style={{ marginTop: 16 }}
            />
        )}
        {changePages === 1 &&
            (!loading ? (
                <div
                    style={{
                        padding: isMobileOnly ? 16 : 40,
                        paddingTop: 24,
                        marginBottom: 300,
                        overflowX: 'hidden',
                        marginTop: isMobileOnly && 16
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'center',
                            marginBottom: 26
                        }}>
                        {/* <img src={Gibobs} alt="Gibobs" height={50} /> */}
                        {viabilityData &&
                            viabilityData.excepcionalViability !== 'NOSAVINGS' &&
                            viabilityData.excepcionalViability !== 'INCOME_LEVEL' &&
                            viabilityData.excepcionalViability !== 'MORTGAGE_AMOUNT' && (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                    <div
                                        style={{
                                            color: '#02C3CD',
                                            fontSize: 14,
                                            fontWeight: 500,
                                            lineHeight: '20px',
                                            marginRight: 4,
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => setChangesPages(2)}>
                                        {t('loan.editData')}
                                    </div>
                                    <img src={editIcon} alt="Gibobs" height={16} />
                                </div>
                            )}
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: !isMobileOnly ? 'center' : undefined,
                            flexDirection: isMobileOnly ? 'column' : undefined
                        }}>
                        {!isMobileOnly && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                    className="offer-style"
                                    style={{ fontWeight: 600, fontSize: 14, color: '#2F4858' }}>
                                    {t('onboarding.mortgageAmount')}
                                </div>
                                <div
                                    className="offer-style"
                                    style={{ fontWeight: 600, fontSize: 16, color: '#2F4858', marginLeft: 16 }}>
                                    {viabilityData !== undefined ? (
                                        numeral(
                                            viabilityData?.operation?.mortgageAmount === 0
                                                ? Number(
                                                    viabilityData?.operation?.taxes +
                                                    dataOperation?.mortgage?.maxBudget -
                                                    viabilityData?.operation?.savings
                                                ) + 2000
                                                : viabilityData?.operation?.mortgageAmount
                                        ).format('0,0.00 $')
                                    ) : (
                                        <Skeleton.Input active />
                                    )}
                                </div>
                                <div style={{ marginTop: 0, marginLeft: 3 }}>
                                    <InfoCircleOutlined
                                        style={{ color: '#02C3CD', fontSize: 20, marginLeft: 6 }}
                                        onClick={() => {
                                            setVisibleModalCalculationAmount(true);
                                            logEvent(analytics, 'screen_view', {
                                                screen_name: 'onboarding2022.h_tooltipAmount'
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        {isMobileOnly && (
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24, width: '100%' }}>
                                <div
                                    className="offer-style"
                                    style={{ fontWeight: 500, fontSize: 14, color: '#2F4858' }}>
                                    {t('onboarding.mortgageAmount')}
                                </div>

                                <div style={{ display: 'flex' }}>
                                    <div
                                        className="offer-style"
                                        style={{ fontWeight: 600, fontSize: 16, color: '#2F4858' }}>
                                        {viabilityData !== undefined ? (
                                            numeral(
                                                viabilityData?.operation?.mortgageAmount === 0
                                                    ? Number(
                                                        viabilityData?.operation?.taxes +
                                                        dataOperation?.mortgage?.maxBudget -
                                                        viabilityData?.operation?.savings
                                                    ) + 2000
                                                    : viabilityData?.operation?.mortgageAmount
                                            ).format('0,0.00 $')
                                        ) : (
                                            <Skeleton.Input active />
                                        )}
                                    </div>
                                    <InfoCircleOutlined
                                        style={{ color: '#02C3CD', marginTop: 0, marginLeft: 5, fontSize: 20 }}
                                        onClick={() => {
                                            setVisibleModalCalculationAmount(true);
                                            logEvent(analytics, 'screen_view', {
                                                screen_name: 'onboarding2022.h_tooltipAmount'
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        {!isMobileOnly && (
                            <Divider
                                type="vertical"
                                style={{ background: '#2F4858', height: 24, marginLeft: 20 }}
                            />
                        )}

                        {viabilityData && (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginLeft: isMobileOnly ? 15 : 10,
                                        marginRight: isMobileOnly && 15,
                                        marginTop: isMobileOnly && -5,
                                        justifyContent: isMobileOnly && 'space-between',
                                        width: isMobileOnly && '100%'
                                    }}>
                                    <div
                                        style={{
                                            fontWeight: 500,
                                            fontSize: 14,
                                            lineHeight: '16px',
                                            color: '#2F4858'
                                        }}>
                                        {t('appraisal.nameTableTerm')}
                                    </div>

                                    <Slider
                                        defaultValue={
                                            viabilityData &&
                                            viabilityData.operation &&
                                            viabilityData.operation.quotes / 12
                                        }
                                        getTooltipPopupContainer={(node) => node.parentNode}
                                        style={{ width: isMobileOnly ? '85%' : 320 }}
                                        trackStyle={{ backgroundColor: '#02C3CD' }}
                                        onAfterChange={(e) => calculateViability(e)}
                                        max={
                                            dataOperation && dataOperation.owner1 && dataOperation.owner1.age < 35
                                                ? 40
                                                : 75 -
                                                    Number(
                                                        dataOperation && dataOperation.owner1 && dataOperation.owner1.age
                                                    ) >
                                                    5
                                                    ? 75 -
                                                    Number(
                                                        dataOperation && dataOperation.owner1 && dataOperation.owner1.age
                                                    )
                                                    : 5
                                        }
                                        min={5}
                                        tooltip={{
                                            formatter: (value) => `${value} ${t('form.years')}`,
                                            open: isMobileOnly ? false : true
                                        }} />
                                </div>
                                {!isMobileOnly && (
                                    <div style={{ textAlign: 'right' }}>
                                        Max:&nbsp;
                                        {dataOperation && dataOperation.owner1 && dataOperation.owner1.age < 35
                                            ? 40
                                            : 75 -
                                                Number(
                                                    dataOperation && dataOperation.owner1 && dataOperation.owner1.age
                                                ) >
                                                5
                                                ? 75 -
                                                Number(dataOperation && dataOperation.owner1 && dataOperation.owner1.age)
                                                : 5}{' '}
                                        años
                                    </div>
                                )}
                                {isMobileOnly && (
                                    <div style={{ textAlign: 'right', width: '100%', marginRight: 15, marginTop: -5 }}>
                                        {'Max: ' + years + ' años'}
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                    {/* START CONDICIONES DE VIABIALIDAD COMPLICADA */}
                    {viabilityData && viabilityData.excepcionalViability === 'NOSAVINGS' && (
                        <ComplicatedViability
                            title={'onboarding.cantBuyWithoutSavings'}
                            description={'onboarding.cantBuyWithoutSavingsDescription'}
                            reason={'Pre-scoring'}
                            subReason={'Ahorros insufientes - cierre automático'}
                            descriptionToClose={'Ahorros insufientes'}
                            setPages={(e) => setChangesPages(e)}
                        />
                    )}
                    {viabilityData && viabilityData.excepcionalViability === 'INCOME_LEVEL' && (
                        <ComplicatedViability
                            title={'onboarding.lowIncomeLevel'}
                            description={'onboarding.lowIncomeLevelDescription'}
                            reason={'Pre-scoring'}
                            subReason={'Ingresos bajos - cierre automático'}
                            descriptionToClose={'Ingresos bajos'}
                            setPages={(e) => setChangesPages(e)}
                        />
                    )}
                    {viabilityData && viabilityData.excepcionalViability === 'MORTGAGE_AMOUNT' && (
                        <ComplicatedViability
                            title={'onboarding.ItsBetterTellTruth'}
                            description={'onboarding.ItsBetterTellTruthDescription'}
                            reason={'Pre-scoring'}
                            subReason={'Importe de hipoteca'}
                            descriptionToClose={'Importe de hipoteca bajo'}
                            setPages={(e) => setChangesPages(e)}
                            capital={dataOperation?.maxBudget}
                        />
                    )}
                    {/* FINISH CONDICIONES DE VIABIALIDAD COMPLICADA */}
                    <Tabs
                        className={
                            isMobileOnly ? 'tabs-onboarding-mortgage-mobile' : 'tabs-onboarding-mortgage'
                        }
                        defaultActiveKey="1"
                        centered
                        style={{
                            marginBottom: 200,
                            width: !isMobileOnly && '100%',
                            margin: 'auto',
                            marginTop: 20,
                            alignItems: 'center'
                        }}>
                        <Tabs.TabPane tab={t('loan.quoteDetails').toLocaleUpperCase()} key="1">
                            <Row gutter={24} style={{ margin: '0px -8px 32px' }}>
                                <Col xl={8} xxl={8} lg={12} md={12} sm={12} xs={24}>
                                    {typeOfQuote(
                                        'viabilityNew.typeFixed',
                                        IconFixed,
                                        viabilityData &&
                                        viabilityData.operation &&
                                        viabilityData.operation.types.fixedTin.quote,
                                        viabilityData &&
                                        viabilityData.operation &&
                                        viabilityData.operation.types.fixedTin.savings.totalSaving,
                                        viabilityData &&
                                        viabilityData.operation &&
                                        viabilityData.operation.types.fixedTin.tae,
                                        viabilityData &&
                                        viabilityData.operation &&
                                        viabilityData.operation.types.fixedTin.spread * 100,
                                        viabialityData('fixed', 'monthSavings'),
                                        viabilityData && viabilityData.operation && viabilityData.operation.quotes,
                                        viabilityData?.rates?.marketFixedRate,
                                        undefined,
                                        viabilityData?.operation?.types?.fixedTin?.market?.mortgageAmount,
                                        viabilityData?.operation?.types?.fixedTin?.gibobsTotalMortageAmountTae
                                    )}
                                </Col>
                                <Col xl={8} xxl={8} lg={12} md={12} sm={12} xs={24}>
                                    {typeOfQuote(
                                        'viabilityNew.typeMixed',
                                        IconMixed,
                                        viabilityData &&
                                        viabilityData.operation &&
                                        viabilityData.operation.types.mixedTin.sections[0].monthlyPayment,
                                        viabilityData &&
                                        viabilityData.operation &&
                                        viabilityData.operation.types.mixedTin.savings.totalSaving,
                                        viabilityData &&
                                        viabilityData.operation &&
                                        viabilityData.operation.types.mixedTin.tae,

                                        viabilityData &&
                                        viabilityData.operation &&
                                        viabilityData.operation.types.mixedTin.sections[0].tin * 100,
                                        viabialityDataMixed('mixedTin', 'monthSavings'),
                                        viabilityData && viabilityData.operation && viabilityData.operation.quotes,
                                        viabilityData?.rates?.marketMixedRate,
                                        viabilityData &&
                                        viabilityData.operation &&
                                        viabilityData.operation.types.mixedTin.sections[0].to,

                                        viabilityData?.operation?.types?.mixedTin?.market?.mortgageAmount,
                                        viabilityData?.operation?.types?.mixedTin?.gibobsTotalMortageAmountTae
                                    )}
                                </Col>
                                <Col xl={8} xxl={8} lg={12} md={12} sm={12} xs={24}>
                                    {typeOfQuote(
                                        'viabilityNew.typeSpread',
                                        IconSpread,
                                        viabilityData &&
                                        viabilityData.operation &&
                                        viabilityData.operation.types.variableTin.sections[0].monthlyPayment,
                                        viabilityData &&
                                        viabilityData.operation &&
                                        viabilityData.operation.types.variableTin.savings.totalSaving,
                                        viabilityData &&
                                        viabilityData.operation &&
                                        viabilityData.operation.types.variableTin.tae,
                                        viabilityData &&
                                        viabilityData.operation &&
                                        viabilityData.operation.types.variableTin.sections[0].tin * 100,
                                        viabialityData('variableTin', 'monthSavings'),
                                        viabilityData && viabilityData.operation && viabilityData.operation.quotes,
                                        viabilityData?.rates?.marketVariableRate,
                                        viabilityData &&
                                        viabilityData.operation &&
                                        viabilityData.operation.types.variableTin.sections[0].to,
                                        viabilityData?.operation?.types?.variableTin?.market?.mortgageAmount,
                                        viabilityData?.operation?.types?.variableTin?.gibobsTotalMortageAmountTae
                                    )}
                                </Col>
                            </Row>
                        </Tabs.TabPane>

                        {viabilityData && (
                            <Tabs.TabPane
                                tab={
                                    <div style={{ fontWeight: 400, fontSize: 16 }}>
                                        {t('topViability.viability').toLocaleUpperCase()}{' '}
                                        <RightOutlined style={{ color: '#02C3CD', fontSize: 12 }} />
                                    </div>
                                }
                                key="2">
                                <Viability
                                    viabilityData={viabilityData && viabilityData}
                                    viabilityDocument={`${env.api.url}/v1/banks/generate-pdf-viabilidad-casaElegida/${operationId}/${i18n.language}?token=${userToken}`}
                                    welcomeDocument={documentToDownload}
                                />
                               <div
										style={{
											background: '#EFFCFC',
											borderRadius: 4,
											padding: 24,
											margin: '32px 0px'
										}}>
										<div
											style={{
												fontSize: 14,
												fontWeight: 400,
												color: '#748EA0'
											}}
											onClick={() => setChangesPages(2)}
											dangerouslySetInnerHTML={{
                                                __html: t('onboarding.cityzenshipDescription')
                                            }}>
											
										</div>
									</div>
                            </Tabs.TabPane>
                        )}
                    </Tabs>
                    {isMobileOnly && (
                        <>
                            {viabilityData && !disbledButton() && 
                                <AnalystIntroModal operationId={operationId} changesStage={changesStage} />
                            }
                            <Card
                                className="card-button-style-onboading"
                                style={{
                                    left: 0,
                                    marginBottom: -2,
                                    backgroundColor: '#FAFEFE'
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        width: '100%'
                                    }}>
                                    <div style={{ marginBottom: 16, textAlign: 'center' }}>
                                        <Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
                                            {t('viability.textNewOnboarding')}
                                        </Text>
                                    </div>
                                    {viabilityData &&
                                        <Button
                                            id="gb-mortgage-buy-situation-mobile"
                                            className="button-primary-gibobs-inmo"
                                            type="primary"
                                            style={{
                                                fontSize: '14px',
                                                width: '100%',
                                                height: 48,
                                                fontWeight: 600,
                                                fontSize: 16,
                                                zIndex: 1200
                                            }}
                                            disabled={disbledButton()}
                                            htmlType="submit"
                                            onClick={() => changesStage('process', 'basicCompleted', true)}>
                                            {t('viability.continueFree')}
                                        </Button>
                                    }
                                </div>
                            </Card>
                        </>
                    )}
                    {!isMobileOnly && (
                        <Card
                            className="card-button-style-onboading"
                            style={{
                                left: collapsed ? 56 : 254,
                                marginBottom: -2,
                                backgroundColor: '#FAFEFE',
                                zIndex: 1000,
                                height: 112,
                                borderRadius: 'none !important',
                                width: collapsed ? 'calc(100% - 56px)' : 'calc(100% - 254px)',
                                transition: 'width 0.3s ease',
                                transition: 'left 0.3s ease'
                            }}>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        gap: 32
                                    }}>
                                    <Text style={{ fontSize: 16, fontWeight: 600, color: '#2F4858' }}>
                                        {t('viability.textNewOnboarding')}
                                    </Text>
                                    {viabilityData &&
                                        <div>
                                            <Button
                                                id="gb-mortgage-buy-situation-mobile"
                                                className="button-primary-gibobs-inmo"
                                                type="primary"
                                                style={{
                                                    fontSize: '14px',
                                                    height: 40,
                                                    fontWeight: 600
                                                }}
                                                disabled={disbledButton()}
                                                htmlType="submit"
                                                onClick={() => changesStage('process', 'basicCompleted', true)}>
                                                {t('viability.continueFree')}
                                            </Button>
                                        </div>
                                    }
                                </div>
                                {viabialityData && !disbledButton() &&
                                    <AnalystIntroModal operationId={operationId} changesStage={changesStage} />
                                }
                            </div>
                        </Card>
                    )}
                    <div
                        className={
                            isMobile ? 'reviews-onboarding-styles-mobile' : 'reviews-onboarding-styles'
                        }>
                        <div className="title-reviews-onboarding-styles">
                            {t('onboarding.confidenceCustomer')}
                        </div>
                        <div style={{ width: isMobile ? '100%' : '40%' }}>
                            <ReviewsCustomer />
                        </div>
                    </div>
                    <div className="description-viability">{t('viability.explanationCalculationTae')}</div>
                    <div className="description-viability">
                        {t('viability.explanationSavings')}
                    </div>
                    {/* MODALES */}
                    <CalculationMortgageAmount
                        key={'visibleModalCalculationAmount'}
                        visible={visibleModalCalculationAmount}
                        onCreate={() => setVisibleModalCalculationAmount(false)}
                        onCancel={() => setVisibleModalCalculationAmount(false)}
                        width={800}
                        viabilityData={viabilityData}
                        dataOperation={dataOperation}
                    />
                    <SavingsCalculation
                        key={'visibleModalSavings'}
                        visible={visibleModalSavings}
                        onCreate={() => setVisibleModalSavings(false)}
                        onCancel={() => setVisibleModalSavings(false)}
                        width={800}
                    />
                  {disbledButton() === false && (
							<KnowMore
								key={'visibleModalKnowMore'}
								visible={visibleModalKnowMore}
								onOk={() => changesStage('process', 'basicCompleted', true)}
								onCancel={() => setVisibleModalKnowMore(false)}
							/>
						)}
                </div>
            ) : (
                <Spin indicator={antIcon} className="spin" />
            ))}
    </>);
};

export default ResultAndViabilityGeneral;
