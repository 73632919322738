import React from 'react';
import { Col, Form, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { InputNumber } from 'afrodita';

const HavePendingForm4 = ({ edit }) => {
	const { t } = useTranslation();
	const colSpan = {
		xxl: edit === undefined ? 24 : 6,
		xl: edit === undefined ? 24 : 6,
		lg: edit === undefined ? 24 : 8,
		md: edit === undefined ? 24 : 12,
		sm: 24,
		xs: 24
	};

	return (
		<>
			<Col {...colSpan}>
				<Form.Item
					name="amount"
					label={
						edit !== undefined ? t('form.pendingCapital') : t('onboarding.amountCurrentMortgage')
					}
					rules={[
						{
							required: true
						}
					]}>
					<InputNumber id="amount" gbType="currency" placeholder={t('0')} defaultValue={''} suffix={'€'} />
				</Form.Item>
			</Col>
			<Col {...colSpan}>
				<Form.Item
					name="dateEndMortgage"
					label={
						edit !== undefined ? t('onboarding.completionDate') : t('onboarding.currentMortgageEnd')
					}
					rules={[
						{
							required: true
						}
					]}>
					<DatePicker
						id="dateEndMortgage"
						format={'MM/YYYY'}
						picker="month"
						style={{
							width: '100%',
							border: '1px solid #C0DAEE',
							borderRadius: 4,
							height: 40
						}}
					/>
				</Form.Item>
			</Col>
		</>
	);
};

export default HavePendingForm4;
