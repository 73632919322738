import { createStore, combineReducers, applyMiddleware } from "redux";
import auth from "./auth/reducer";
import realestate from "./realestate/reducer";
import documents from "./documents/reducer";
import data from "./data/reducer";
import viability from "./viability/reducer";
import promoter from "./promoter/reducer";
import reduxThunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import calculators from "./calculators/reducer";


const reducers = combineReducers({
  auth,
  realestate,
  documents,
  data,
  viability,
  promoter,
  calculators
});

export const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(reduxThunk))
);
