import React, { useState } from "react";
import { Layout, Typography, Row, Col, Card, Button, Collapse } from "antd";
import { useTranslation } from "react-i18next";
import MyBreadcrumb from "../../src/Pages/MyBreadcrumb";
import { FilePdfOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const { Text } = Typography;

const FrequentQuestions = () => {
  const { t } = useTranslation();
  const [valueKey, setValueKey] = useState("0");

  const onClickPanel = (key) => {
    if (key === valueKey) {
      setValueKey(undefined);
    } else {
      setValueKey(key);
    }
  };

  const questions1 = [
    {
      id: "0",
      question: t("frecuenceQuestions.whoIs"),
      answer: t("frecuenceQuestions.whoIsAnswer"),
    },
    {
      id: "1",
      question: t("frecuenceQuestions.model"),
      answer: t("frecuenceQuestions.modelAnswere"),
    },
    {
      id: "2",
      question: t("frecuenceQuestions.service"),
      answer: t("frecuenceQuestions.serviceAnswer"),
    },
    {
      id: "3",
      question: t("frecuenceQuestions.banks"),
      answer: t("frecuenceQuestions.banksAnswer"),
    },
  ];
  const questions2 = [
    {
      id: "4",
      question: t("frecuenceQuestions.productsQuestion"),
      answer: t("frecuenceQuestions.productAnsw"),
    },
    {
      id: "5",
      question: t("frecuenceQuestions.interes"),
      answer: t("frecuenceQuestions.interesAnsw"),
    },
    {
      id: "6",
      question: t("frecuenceQuestions.time"),
      answer: t("frecuenceQuestions.timeAnsw"),
    },
    {
      id: "7",
      question: t("frecuenceQuestions.offers"),
      answer: t("frecuenceQuestions.offersAnsw"),
    },
    {
      id: "8",
      question: t("frecuenceQuestions.scoring"),
      answer: t("frecuenceQuestions.scoringAnsw"),
    },
    {
      id: "9",
      question: t("frecuenceQuestions.getScoring"),
      answer: t("frecuenceQuestions.getScoringAnw"),
    },
  ];

  const questions3 = [
    {
      id: "10",
      question: t("frecuenceQuestions.problems"),
      answer: t("frecuenceQuestions.problemsAnsw"),
    },
    {
      id: "11",
      question: t("frecuenceQuestions.email"),
      answer: t("frecuenceQuestions.emailAnsw"),
    },
    {
      id: "12",
      question: t("frecuenceQuestions.code"),
      answer: t("frecuenceQuestions.codeAnsw"),
    },
    {
      id: "13",
      question: t("frecuenceQuestions.changeEmail"),
      answer: t("frecuenceQuestions.changeEmailAnsw"),
    },
    {
      id: "14",
      question: t("frecuenceQuestions.passw"),
      answer: t("frecuenceQuestions.passwAnsw"),
    },
    {
      id: "15",
      question: t("frecuenceQuestions.dataPersonal"),
      answer: t("frecuenceQuestions.dataPersonalAnsw"),
    },
    {
      id: "16",
      question: t("frecuenceQuestions.cancel"),
      answer: t("frecuenceQuestions.cancelAnsw"),
    },
    {
      id: "17",
      question: t("frecuenceQuestions.cancelError"),
      answer: t("frecuenceQuestions.cancelErrorAnsw"),
    },
  ];

  const data = [
    {
      name: t("menuComponent.initNav"),
      link: "/dashboard-client",
    },
    {
      name: t("menuComponent.help"),
      link: "/help",
    },
  ];

  return (
    <Layout style={{paddingRight: 40}} >
      <Row gutter={24}>
        <Col lg={24} xs={24}>
          <MyBreadcrumb data={data}></MyBreadcrumb>

          <Card
            className="box"
            style={{
              width: "calc(100% - 40px)",
              height: "auto",
              marginBottom: "140px",
              marginLeft: "40px",
            }}
            key={"re"}
          >
            <Text
              strong
              style={{
                fontSize: "14px",
                color: "#4D4D4D",
                display: "flex",
                marginBottom: "16px",
                marginLeft: "5px",
              }}
            >
              {t("frecuenceQuestions.title")}
            </Text>
            <div style={{ display: "flex", alignItems: "center", marginBottom: 48}}>
              <FilePdfOutlined style={{ fontSize: 20, color: "#748EA0" }} />
              <Button
                target={"_blank"}
                href="https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/contrato-Intermediacion.pdf"
                style={{
                  float: "left",
                  alignItems: "center",
                  fontSize: "12px",
                  color: "#748EA0",
                  display: "flex",
                  border: "none",
                  background: "none",
                }}
              >
                {t("frecuenceQuestions.fileContract")}
              </Button>
            </div>
            <Text strong style={{ fontWeight: 600, fontSize: 14 }}>
              {t("frecuenceQuestions.gibobs")}
            </Text>

            {questions1.map((text) => {
              return (
                <Collapse
                  style={{
                    backgroundColor: "#FAFEFE",
                    marginBottom: "8px",
                    marginTop: 16,
                  }}
                  expandIconPosition="end"
                  activeKey={valueKey}
                  onChange={() => {
                    onClickPanel(text.id);
                  }}
                >
                  <Panel
                    header={text.question}
                    key={text.id}
                    style={{ fontSize: "12px" }}
                  >
                    <Text style={{ color: "#748EA0" }}>
                      <span
                        dangerouslySetInnerHTML={{ __html: text.answer }}
                      ></span>
                    </Text>
                  </Panel>
                </Collapse>
              );
            })}
            <div style={{ marginTop: 16 }}>
              {" "}
              <Text strong style={{ fontWeight: 600, fontSize: 14 }}>
                {t("frecuenceQuestions.products")}
              </Text>{" "}
            </div>

            {questions2.map((text) => {
              return (
                <Collapse
                  style={{
                    backgroundColor: "#FAFEFE",
                    marginBottom: "8px",
                    marginTop: 16,
                  }}
                  expandIconPosition="end"
                  activeKey={valueKey}
                  onChange={() => {
                    onClickPanel(text.id);
                  }}
                >
                  <Panel
                    header={text.question}
                    key={text.id}
                    style={{ fontSize: "12px" }}
                  >
                    <Text style={{ color: "#748EA0" }}>{text.answer}</Text>
                  </Panel>
                </Collapse>
              );
            })}
            <div style={{ marginTop: 16 }}>
              {" "}
              <Text strong style={{ fontWeight: 600, fontSize: 14 }}>
                {t("frecuenceQuestions.account")}
              </Text>{" "}
            </div>

            {questions3.map((text) => {
              return (
                <Collapse
                  style={{
                    backgroundColor: "#FAFEFE",
                    marginBottom: "8px",
                    marginTop: 16,
                  }}
                  expandIconPosition="end"
                  activeKey={valueKey}
                  onChange={() => {
                    onClickPanel(text.id);
                  }}
                >
                  <Panel
                    header={text.question}
                    key={text.id}
                    style={{ fontSize: "12px" }}
                  >
                    <Text style={{ color: "#748EA0" }}>{text.answer}</Text>
                  </Panel>
                </Collapse>
              );
            })}
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};

export default FrequentQuestions;
