import React, { useEffect, useState } from 'react';
import { Col, Form, Radio, Select, Modal, Typography, Row, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import DownOutlinedMobile from '../../../../assets/icons/DownOutlinedMobile.svg';
import exclamationIcon from '../../../../assets/onboarding/exclamationIcon.svg';
import { InputNumber } from 'afrodita';
import { isMobileOnly } from 'react-device-detect';

const { Option } = Select;
const { Text } = Typography;

const CurrentConditionsForm3 = ({ edit, interestData, form, dataOperation }) => {
	const { t } = useTranslation();
	const [interest, setInterest] = useState(interestData !== undefined ? interestData : "variable");
	const [visibleModalExplination, setVisibleModalExplination] = useState(false);
	const [visibleModalExplinationVariable, setVisibleModalExplinationVariable] = useState(false);

	const colSpan = {
		xxl: edit === undefined ? 24 : 6,
		xl: edit === undefined ? 24 : 6,
		lg: edit === undefined ? 24 : 8,
		md: edit === undefined ? 24 : 12,
		sm: 24,
		xs: 24
	};

	useEffect(() => {
		if (interestData === undefined ) {
			setInterest("variable")
		}else {
			setInterest(interestData)
		}

	},[])

	useEffect(() => {
		if (interest) {
			form.setFieldsValue({
				interest: interest
			});
		}

	},[interest])


	const renderLabel = () => {
		return (
			<div style={{ display: 'flex' }}>
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#2f4858'}}>{t('loan.costAnnual')}</Text>
				<div>
					<img
						src={exclamationIcon}
						alt="exclamationIcon"
						style={{ marginLeft: 4, marginTop: 0, width: 24 }}
						onClick={() => setVisibleModalExplination(true)}
					/>
				</div>
			</div>
		);
	};
	//initialTypeVariable "fixed"
	//finalTypetVariable "variable"

	return (<>
        {edit === undefined && (
            <Col span={24}>
                <Form.Item
                    name="interest"
                    //label={t('onboarding.whatCurrentMortgageLike')}
                    rules={[
                        {
                            required: true
                        }
                    ]}>
                    <Radio.Group
                        style={{
                            width: '100%',
                            textAlign: isMobileOnly ? 'center' : undefined
                        }}
                        onChange={(e) => setInterest(e.target.value)}>
                        <Radio value="fixed">
                            <div style={{ minWidth: isMobileOnly ? undefined : 80 }}>{t('loanType.fixed')}</div>
                        </Radio>

                        <Radio value="mixed">
                            <div style={{ minWidth: isMobileOnly ? undefined : 80 }}>
                                {t('loan.mixedOnboarding')}
                            </div>
                        </Radio>

                        <Radio value="variable">
                            <div style={{ minWidth: isMobileOnly ? undefined : 80 }}>{t('form.variable')}</div>
                        </Radio>

                        {interest === 'fixed' && (
                            <Form.Item
                                name={interest === 'fixed' ? 'haveATin' : undefined}
                                label={t('form.tinOnboarding')}
                                rules={[
                                    {
                                        validator: (e, value) => {
                                            return new Promise((resolve, reject) => {
                                                if (value <= 10 && value >= 0.1) {
                                                    resolve();
                                                } else {
                                                    reject(
                                                        <div style={{ width: 180 }}>{t('form.errorTinSubrogation')}</div>
                                                    );
                                                }
                                            });
                                        },
                                        required: true
                                    }
                                ]}
                                style={{ marginTop: 24 }}>
                                <InputNumber
                                     gbType='number'
                                     className='gb-input-new-percent'
                                    id="haveATin"
                                    placeholder={t('0')}
                                    defaultValue={''}
                                />
                            </Form.Item>
                        )}

                        {/**seria poner los input del mixed */}

                        {interest === 'mixed' && (
                            <div>
                                <Row
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: 20
                                    }}>
                                    <Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 500 }}>
                                        {t('viability.firtsYears')}
                                    </Text>

                                    <Form.Item
                                        name={'initialTermVariable'}
                                        label={edit ? t("loan.termYears") : t('loan.term')}
                                        rules={[
                                            {
                                                required: true
                                            }
                                        ]}
                                        style={{ width: 100 }}>
                                        <InputNumber
                                            gbType='number'
                                            className='gb-input-new-years'
                                            id="initialTermVariable"
                                            placeholder={t('0')}
                                            defaultValue={''}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name={'initialInterestVariable'}
                                        label={t('TIN')}
                                        rules={[
                                            {
                                                validator: (e, value) => {
                                                    return new Promise((resolve, reject) => {
                                                        if (value <= 10 && value >= 0.1) {
                                                            resolve();
                                                        } else {
                                                            reject(
                                                                <div style={{ width: 180 }}>{t('form.errorTinSubrogation')}</div>
                                                            );
                                                        }
                                                    });
                                                },
                                                required: true
                                            }
                                        ]}
                                        style={{ width: 100 }}>
                                        <InputNumber
                                            gbType='number'
                                            className='gb-input-new-percent'
                                            id="initialInterestVariable"
                                            placeholder={t('0')}
                                            defaultValue={''}
                                        />
                                    </Form.Item>

                                    {/**no se ve en front */}
                                    <Form.Item hidden={true} name={'initialTypeVariable'}>
                                        <Input value={'fixed'} defaultValue={'fixed'} />
                                    </Form.Item>
                                </Row>

                                <Row
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                    <Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 500 }}>
                                        {t('viability.UltimateYears')}
                                    </Text>

                                    <Form.Item
                                        name={'finalInterestVariable'}
                                        label={
                                            <div style={{ display: 'flex', marginTop: 5 }}>
                                                {t('loan.diferential')}
                                                <div>
                                                    <img
                                                        src={exclamationIcon}
                                                        alt="exclamationIcon"
                                                        style={{ marginLeft: 4, width: 24, marginTop: -4 }}
                                                        onClick={() => setVisibleModalExplinationVariable(true)}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        rules={[
                                            {
                                                validator: (e, value) => {
                                                    return new Promise((resolve, reject) => {
                                                        if (value <= 10 && value >= 0.1) {
                                                            resolve();
                                                        } else {
                                                            reject(
                                                                <div style={{ width: 180 }}>{t('form.errorTinSubrogation')}</div>
                                                            );
                                                        }
                                                    });
                                                },

                                                required: true
                                            }
                                        ]}
                                        style={{ width: 100 }}>
                                        <InputNumber
                                             gbType='number'
                                             className='gb-input-new-percent'
                                            id="finalInterestVariable"
                                            placeholder={t('0')}
                                            defaultValue={''}
                                        />
                                    </Form.Item>

                                    <Form.Item hidden={true} name={'finalTypeVariable'}>
                                        <Input value={'variable'} defaultValue={'variable'} />
                                    </Form.Item>
                                </Row>
                            </div>
                        )}

                        {interest === 'variable' && (
                            <Form.Item
                                name={interest === 'variable' ? 'haveATin' : undefined}
                                label={
                                    <div style={{ display: 'flex', marginTop: 4}}>
                                        {t('form.spreadOnboarding')}{' '}
                                        <div>
                                            <img
                                                src={exclamationIcon}
                                                alt="exclamationIcon"
                                                style={{ marginLeft: 4, width: 24, marginTop: -4 }}
                                                onClick={() => setVisibleModalExplinationVariable(true)}
                                            />
                                        </div>
                                    </div>
                                }
                                rules={[
                                    {
                                        validator: (e, value) => {
                                            return new Promise((resolve, reject) => {
                                                if (value <= 10 && value >= 0.1) {
                                                    resolve();
                                                } else {
                                                    reject(
                                                        <div style={{ width: 180 }}>{t('form.errorTinSubrogation')}</div>
                                                    );
                                                }
                                            });
                                        },
                                        required: true
                                    }
                                ]}
                                style={{ marginTop: 30 }}>
                                <InputNumber
                                    gbType='number'
                                    className='gb-input-new-percent'
                                    id="haveATin"
                                    placeholder={t('0')}
                                    defaultValue={''}
                                />
                            </Form.Item>
                        )}
                    </Radio.Group>
                </Form.Item>
            </Col>
        )}
        {edit !== undefined && (
            <>
                <Col {...colSpan}>
                    <Form.Item
                        name="interest"
                        label={t('suborgation.typeInterest')}
                        rules={[
                            {
                                required: true
                            }
                        ]}>
                        <Select
                            placeholder={t('form.activity')}
                            suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
                            onChange={(e) => setInterest(e)}>
                            <Option value="fixed">{t('viability.fixedSpread')}</Option>
                            <Option value="mixed">{t('loan.mixedOnboarding')}</Option>
                            <Option value="variable">{t('viability.variableSpread')}</Option>
                        </Select>
                    </Form.Item>
                </Col>
                {interest !== 'mixed' && (
                    <Col {...colSpan}>
                        <Form.Item
                            name={'haveATin'}
                            label={interest === 'fixed' ? t('appraisal.nameTableTin') : t('loan.diferential')}
                            rules={[
                                {
                                    validator: (e, value) => {
                                        return new Promise((resolve, reject) => {
                                            if (value <= 10 && value >= 0.1) {
                                                resolve();
                                            } else {
                                                reject(t('form.errorTinSubrogation'));
                                            }
                                        });
                                    }
                                }
                            ]}>
                            <InputNumber  gbType='number'
                                    className='gb-input-new-percent' id="haveATin" placeholder={t('0')} />
                        </Form.Item>
                    </Col>
                )}

                {interest === 'mixed' && (
                    <>
                        <Form.Item
                            name={'initialTermVariable'}
                            label={edit ? t("loan.termYears") : t('loan.term')}
                            rules={[
                                {
                                    required: true
                                }
                            ]}
                            style={{ width: 100, marginLeft: 14 }}>
                            <InputNumber
                                gbType='number'
                                className='gb-input-new-years'
                                id="initialTermVariable"
                                placeholder={t('0')}
                                defaultValue={''}
                            />
                        </Form.Item>

                        <Form.Item
                            name={'initialInterestVariable'}
                            label={t('TIN')}
                            rules={[
                                {
                                    validator: (e, value) => {
                                        return new Promise((resolve, reject) => {
                                            if (value <= 10 && value >= 0.1) {
                                                resolve();
                                            } else {
                                                reject(<div style={{ width: 180 }}>{t('form.errorTinSubrogation')}</div>);
                                            }
                                        });
                                    },
                                    required: true
                                }
                            ]}
                            style={{ width: 100, marginLeft: 14 }}>
                            <InputNumber
                                 gbType='number'
                                 className='gb-input-new-percent'
                                id="initialInterestVariable"
                                placeholder={t('0')}
                                defaultValue={''}
                            />
                        </Form.Item>

                        {/**no se ve en front */}

                        <Form.Item
                            name={'haveATin'}
                            hidden={true}
                            >
                            <InputNumber  gbType='number'
                                    className='gb-input-new-percent' id="haveATin" placeholder={t('0')}
                            value={dataOperation && dataOperation?.subrogation?.initialInterestVariable}
                            />
                        </Form.Item>

                        {/**no se ve en front */}
                        <Form.Item hidden={true} name={'initialTypeVariable'}>
                            <Input value={'fixed'} defaultValue={'fixed'} />
                        </Form.Item>

                        <Form.Item
                            name={'finalInterestVariable'}
                            label={
                                <div style={{ display: 'flex', marginTop: 7 }}>
                                    {t('loan.diferential')}
                                    <div>
                                        <img
                                            src={exclamationIcon}
                                            alt="exclamationIcon"
                                            style={{ marginLeft: 4, marginTop: -3, width: 24 }}
                                            onClick={() => setVisibleModalExplinationVariable(true)}
                                        />
                                    </div>
                                </div>
                            }
                            rules={[
                                {
                                    validator: (e, value) => {
                                        return new Promise((resolve, reject) => {
                                            if (value <= 10 && value >= 0.1) {
                                                resolve();
                                            } else {
                                                reject(<div style={{ width: 180 }}>{t('form.errorTinSubrogation')}</div>);
                                            }
                                        });
                                    },

                                    required: true
                                }
                            ]}
                            style={{ width: 100, marginLeft: 14  }}>
                            <InputNumber
                                 gbType='number'
                                 className='gb-input-new-percent'
                                id="finalInterestVariable"
                                placeholder={t('0')}
                                defaultValue={''}
                            />
                        </Form.Item>

                        <Form.Item hidden={true} name={'finalTypeVariable'}>
                            <Input value={'variable'} defaultValue={'variable'} />
                        </Form.Item>
                    </>
                )}
            </>
        )}
        <Col {...colSpan}>
            <Form.Item
                name="costOtherProducts"
                label={edit !== undefined ? t('onboarding.totalCostLinked') : renderLabel()}
                rules={[
                    {
                        required: true
                    }
                ]}
                style={{ marginTop: edit !== undefined ? 0 : -24 }}>
                <InputNumber
                    id="costOtherProducts"
                    max={99000000}
                    gbType='number'
                    className='gb-input-new-years-euro'
                    maxLength="15"
                    placeholder={t('0')}
                    defaultValue={0}
                />
            </Form.Item>
        </Col>
        <Modal
            centered
            title={t('onboarding.linkedProducts')}
            className="modal-onboarding-stepper"
            width={500}
            cancelButtonProps={{
                className: 'button-secundary-gibobs',
                style: { display: 'none' }
            }}
            okButtonProps={{
                className: 'button-primari-gibobs',
                style: { display: 'none' }
            }}
            cancelText={t('form.back')}
            open={visibleModalExplination}
            onCancel={() => setVisibleModalExplination(!visibleModalExplination)}
            destroyOnClose={true}
            closable={true}>
            {t('onboarding.linkedProductsDescription')}
        </Modal>
        <Modal
            centered
            title={t('form.variable')}
            className="modal-onboarding-stepper"
            width={480}
            cancelButtonProps={{
                className: 'button-secundary-gibobs',
                style: { display: 'none' }
            }}
            okButtonProps={{
                className: 'button-primari-gibobs',
                style: { display: 'none' }
            }}
            cancelText={t('form.back')}
            open={visibleModalExplinationVariable}
            onCancel={() => setVisibleModalExplinationVariable(!visibleModalExplinationVariable)}
            destroyOnClose={true}
            closable={true}>
            {t('onboarding.varibaleModal')}
        </Modal>
    </>);
};

export default CurrentConditionsForm3;
