import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import numeral from "numeral";
import moment from "moment";
import { isMobile } from "react-device-detect";

const { Text } = Typography;

const DataInformationEco = ({ valuesData, dataMortgage }) => {
  const { t } = useTranslation();

  const spaceInfo = (title, info) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "12px 0",
          marginLeft: isMobile && 16,
          borderBottom: "1px solid #F1F7F8",
          width: isMobile && "calc(100% - 32px)",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 16,
            fontWeight: 400,
            color: "#2F4858",
          }}
        >
          {t(title)}
        </Text>
        <Text style={{ fontSize: 14, fontWeight: 600, color: "#748EA0" }}>
          {info}
        </Text>
      </div>
    );
  };

  const typeHomeName = {
    individual: t("budget.individual"),
    adosado: t("budget.townhouse"),
    pareado: t("budget.couplet"),
    casaRustica: t("budget.rusticHouse"),
    piso: t("budget.house"),
  };

  return (
    <div style={{ margin: !isMobile && 8 }}>
      <div
        style={{
          margin: isMobile && "16px 16px",
          marginBottom: 12,
          marginTop: isMobile && 8,
        }}
      >
        <Text
          style={{
            fontSize: 12,
            fontWeight: 600,
            color: "#2F4858",
          }}
        >
          {t("certificate.seccionData").toUpperCase()}
        </Text>
      </div>
      <div style={{ backgroundColor: "#ffffff" }}>
        {spaceInfo(
          "certificated.dateApplication",
          moment(valuesData.createdAt).format("L")
        )}
        {spaceInfo(
          "budget.typeHome",
          typeHomeName[dataMortgage["solar-value"].ecoHouseType]
        )}
        {spaceInfo("form.totalFloor", dataMortgage["solar-value"].floorSurface)}
        {spaceInfo("budget.Nfloor", dataMortgage["solar-value"].floorNumber)}
        {spaceInfo("budget.room", dataMortgage["solar-value"].rooms)}
        {spaceInfo("form.provinces", dataMortgage["solar-value"].province)}
        {spaceInfo("form.population", dataMortgage["solar-value"].city)}
        {spaceInfo("m_register.postalCode", dataMortgage["solar-value"].zip)}
        {spaceInfo("m_register.direction", dataMortgage["solar-value"].address)}
        {spaceInfo(
          "form.approximatePrice",
          numeral(dataMortgage["solar-value"].maxBudget).format("0,0$")
        )}
      </div>
    </div>
  );
};
export default DataInformationEco;
