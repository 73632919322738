import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Collapse, Tabs } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";
import ViabilityComponentInmo from "./Components/vibilityComponentInmo";
import DataInformation from "./dataInformation";
import DataInformationEco from "./dataInformationEco";
import { isMobile } from "react-device-detect";

const { Text } = Typography;
const { Panel } = Collapse;
const { TabPane } = Tabs;

const CardInformationInfo = ({
  stages,
  valuesData,
  ownerList,
  dataMortgage,
  viabilityData,
  userMortgageId,
  eco,
  dataOwner,
  status,
  pospone,
}) => {
  const { t } = useTranslation();
  const [tabsValue, setTabsValue] = useState("1");

  const componenstName = {
    basic: "Stages.basic",
    full: "menuComponent.full",
    documents: "document.documentation",
    analysis: "document.analystics",
    banks: "document.bank",
    oferts: "offers.offert",
    appraisal: "appraisal.appraisal",
    formalization: "formalization.formalization",
  };

  const onChangeTabs = (tabs) => {
    setTabsValue(tabs);
  };

  return (
    <div>
      <div style={{ marginBottom: 24 }}>
        <Text style={{ fontSize: 16, fontWeight: 600 }}>
          {t(componenstName[stages])}
        </Text>
      </div>

      <Collapse
        style={{ backgroundColor: "white" }}
        expandIconPosition={"end"}
        defaultActiveKey={
          eco
            ? 1
            : status === "finish"
            ? 2
            : pospone
            ? 2
            : stages === "basic"
            ? 1
            : stages === "full"
            ? 1
            : 2
        }
      >
        <Panel
          header={
            <div style={{ display: "flex", alignItems: "center" }}>
              <UnorderedListOutlined
                style={{ color: "#02C3CD", fontSize: 16, marginRight: 16 }}
              />
              <div
                style={{
                  fontSize: 16,
                  color: "#2F4858",
                  fontWeight: 400,
                }}
              >
                {t("certificate.infoOperation")}
              </div>
            </div>
          }
          key="1"
        >
          <Tabs
            defaultActiveKey="1"
            style={{
              padding: isMobile ? "0 16px" : "8px",
              marginTop: !isMobile && -8,
              paddingTop: !isMobile && 0,
            }}
            className="tabs-inmo"
            onChange={onChangeTabs}
          >
            <TabPane
              tab={
                tabsValue === "1" ? (
                  <div style={{ fontWeight: 600 }}>
                    {t("form.informationData")}
                  </div>
                ) : (
                  t("form.informationData")
                )
              }
              key="1"
            >
              {!eco && (
                <DataInformation
                  stages={stages}
                  valuesData={valuesData}
                  ownerList={ownerList}
                  dataMortgage={dataMortgage}
                  viabilityData={viabilityData}
                />
              )}
              {eco && (
                <DataInformationEco
                  stages={stages}
                  valuesData={valuesData}
                  ownerList={ownerList}
                  dataMortgage={dataMortgage}
                  viabilityData={viabilityData}
                />
              )}

              {!eco && (
                <Collapse
                  style={{ marginLeft: -16 }}
                  expandIconPosition={"end"}
                  defaultActiveKey={["1"]}
                  ghost
                >
                  <Panel
                    header={
                      <div
                        style={{
                          fontSize: 16,
                          color: "#2F4858",
                          fontWeight: 400,
                        }}
                      >
                        {t("form.description")}
                      </div>
                    }
                    key="1"
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#748EA0",
                      }}
                    >
                      {valuesData.operation.description}
                    </Text>
                  </Panel>
                  <Panel
                    header={
                      <div
                        style={{
                          fontSize: 16,
                          color: "#2F4858",
                          fontWeight: 400,
                        }}
                      >
                        {t("details.otherData")}
                      </div>
                    }
                    key="2"
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#748EA0",
                      }}
                    >
                      {valuesData.operation.otherData}
                    </Text>
                  </Panel>
                </Collapse>
              )}
            </TabPane>
            {!eco &&
              dataMortgage.mortgage &&
              dataMortgage.mortgage.operationType &&
              dataMortgage.mortgage.operationType !== "subrogation" &&
              dataMortgage.mortgage &&
              dataMortgage.mortgage.houseState !== "subrogation" && (
                <TabPane
                  tab={
                    tabsValue === "2" ? (
                      <div style={{ fontWeight: 600 }}>
                        {t("m_viability.viabilityTitle")}
                      </div>
                    ) : (
                      t("m_viability.viabilityTitle")
                    )
                  }
                  key="2"
                >
                  <ViabilityComponentInmo
                    viabilityless={true}
                    viabilityData={viabilityData && viabilityData}
                    dataMortgage={dataMortgage.mortgage}
                    userMortgageId={userMortgageId}
                    dataOwner={dataOwner}
                  />
                </TabPane>
              )}
          </Tabs>
        </Panel>
      </Collapse>
    </div>
  );
};
export default CardInformationInfo;
