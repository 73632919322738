import React, { useState, useEffect } from "react";
import {
  Typography,
  Tabs,
  Upload,
  message,
  Modal,
  Card,
  Badge,
  Divider,
  Row, 
  Col
} from "antd";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  CheckOutlined,
  PlusOutlined,
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import govermentIconMobile from '../../assets/icons/govermentIconMobile.svg';
import spainBankMobile from '../../assets/icons/spainBankMobile.svg';
import { useParams} from "react-router-dom";
import axios from "axios";
import env from "../../environment";
import _ from "lodash";
import syncIcon from "../../assets/icons/syncIcon.svg";

const { Text } = Typography;
const { TabPane } = Tabs;
const { confirm } = Modal;

const DocumentsCertificate = ({ downloadOptionsRadio, setLoading , setValuesDataForCertificate, valuesDataForCertificate}) => {
  let countShowMessage = 0;

  const { t } = useTranslation();
  const param = useParams();
  const { operationId } = useParams();
  const userToken = useSelector((state) => state.auth.token);
  const activeNbric = true;
  const [clickTab, setClickTab] = useState("owner1");
  const [detailsCertificate, setDetailsCertificate] = useState([]);
  const [nameOperations, setNameOperations] = useState(undefined);

  const [valuesData, setValuesData] = useState([]);
  const [saveFileDataField, setSaveFileDataField] = useState(null);
  const [tags, setTags] = useState([]);
  const [, setOwner] = useState("");
  const [, setDocumentsByOwner] = useState([]);
  const [, setAccount] = useState(null);
  const [, setFileBanks3Moths] = useState(undefined); //upload manual
  const [, setFileBanks3MothsGroups] = useState(undefined); //upload manual
  const [, setFileAfterbanks] = useState(undefined); //upload automatic
  const [, setHiddenAutorze] = useState(false);
  const [, setOwnerRefresh] = useState("");
  const [, setVisibleButtonParticipants] =
    useState(undefined);
  const [, setVisible] = useState(activeNbric); // true para activar
  const [fileGroup, setFileGroup] = useState([]);

  const uploadProps = {
    beforeUpload: (file) => {
      return new Promise(() => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64File = reader.result.split(",")[1];
          saveFileData(base64File, file.name);
        };
      });
    },
  };

  useEffect(() => {
    getGroupsDoc();
    getDocuments();
    getAutomaticDocumentation(true);
   
    getDetailsCertificate("");
    //esto lo hago para siempre sea manual, cuando se habilite nbric hay que quitarlo
    // let owner = "owner" + indexOwner;
    // saveData(owner, "manualDoc", true, false);

    ///////////////////////////
  }, []);

  useEffect(() => {
    getGroupsDoc();
    getDocuments();
  }, [clickTab]);


  const modalDocument = (documentId) => {
    confirm({
      className: "ModalCommonConfirm",
      title: "Borrar documento",
      icon: null,
      content: "¿Seguro que deseas borrar este documento?",
      okText: "Borrar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteDocument(documentId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const notification = () => {
    return (
      <div style={{ float: "right", marginTop: "1px", marginRight: "-5px" }}>
        <Badge
          size="small"
          dot={true}
          style={{
            float: "right",
            width: "4px",
            height: "6px",
            marginLeft: "4px",
          }}
        ></Badge>
      </div>
    );
  };

  const deleteDocument = (documentId) => {
    axios
      .get(`${env.api.url}/v1/documents/delete-document/${documentId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then(() => {
        getDocuments();
        getGroupsDoc();
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const getDetailsCertificate = async () => {
    const response = await axios.get(
      `${env.api.url}/v1/operations/${operationId}`,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );

    setDetailsCertificate(response.data.data);
    setNameOperations(response.data.data)
    setOwnerRefresh(response.data.data.operationData.certificate.currentOwner);
    /* if (response.data.data.operationData[currentOwner].manualDoc) {
             setVisible(false)
         }*/
  };

  const getGroupsDoc = () => {
    axios
      .get(
        `${env.api.url}/v1/documents/request-operation-documentation/${operationId}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        response.data.data.documents = _.orderBy(
          response.data.data.documents,
          [
            (d) => {
              return d.participants[clickTab];
            },
            (d) => d.description,
          ],
          ["desc", "asc"]
        );

        response.data.data.documents.find((item) => item.name !== "bankreader");

        setValuesDataForCertificate(response.data.data);
        setFileBanks3MothsGroups(
          response.data.data.documents.find(
            (item) => item.name === "bankreader"
          )
        );
        // setFileBanks3Moths(response.data.data.documents.find((item) => item.name === "bankreader"))
        setVisibleButtonParticipants(
          response.data.data.documents.find(
            (item) =>
              item.completed[clickTab] === false &&
              item.participants[clickTab] === "required"
          )
        );
      })
      .catch((error) => {
        console.log(error)
      });
  };
  const getDocuments = async () => {
    const response = await axios.get(
      `${env.api.url}/v1/documents/get-operation-documents/${operationId}`,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );

    const unsorted = response.data.data;

    const sorted = unsorted
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )
      .reverse();

    let temp = undefined;

    if (nameOperations && nameOperations.stage === "analysis") {
      temp = sorted.filter((item) => item.owner === clickTab);
      setValuesData(temp);
    } else {
      temp = sorted.filter(
        (item) => item.owner === clickTab
      );
      setValuesData(temp);
    }

    let documentsTemp = response.data.data.filter(
      (item) =>
        item.type !== "nif" &&
        item.owner === clickTab &&
        item.type !== "bankreader"
    );

    setDocumentsByOwner(documentsTemp);

    let tempFileAfterbanks = [];
    tempFileAfterbanks = response.data.data.filter(
      (item) =>
        item.tags.includes("automatic") &&
        item.type === "bankreader" &&
        item.owner === clickTab
    );

    if (tempFileAfterbanks.length === 0) {
      tempFileAfterbanks = undefined;
    }
    setFileAfterbanks(tempFileAfterbanks);

    let tempFileBanks3Moths = [];
    tempFileBanks3Moths = response.data.data.filter(
      (item) =>
        item.tags.length === 0 &&
        item.type === "bankreader" &&
        item.owner === clickTab
    );
    if (tempFileBanks3Moths.length === 0) {
      tempFileBanks3Moths = undefined;
    }
    setFileBanks3Moths(tempFileBanks3Moths);

    ///////////////////////////////////////////////
    //descargar un zip con todos los documentos

    if (documentsTemp.length > 0) {
      await axios
        .all(
          documentsTemp.map((l) =>
            axios.get(`${env.api.url}/v1/documents/get-document-url/${l.id}`, {
              headers: { Authorization: `Bearer ${userToken}` },
            })
          )
        )
        .then((res) => {
          const merged = [].concat(res);
          setAccount(merged);
        });
    }
  };

  const saveFileData = (file, name) => {
   // setLoading(true)
    const documentFile = [saveFileDataField];
    documentFile.push({
      file,
      fileName: name,
    });
    setSaveFileDataField(documentFile);
    handleUploadFile(documentFile);
  };

  const handleClickPlus = (idowner, file) => {
    setFileGroup(file);
    let array = [idowner];
    if (idowner === "buy") {
      setTags(array);
      setOwner("owner1");
    } else {
      setOwner(idowner);
    }
  };
  const getAutomaticDocumentation = (nbric) => {
    axios
      .get(
        `${env.api.url}/v1/operations/${operationId}/automatic-documentation/check/${clickTab}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        if (response.data.data.valid) {
          setHiddenAutorze(false);
        }
        /* if (nameOperations.operationData[currentOwner].manualDoc !== undefined) {
                     setVisible(false)
                 }*/
        if (
          nameOperations.operationData[clickTab].manualDoc === undefined &&
          nbric
        ) {
          setVisible(response.data.data.valid);
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const handleFileDownload = (documentId, item) => {
    axios
      .get(
        `${env.api.url}/v1/documents/get-document-url/${documentId}?token=${userToken}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        if (response.data.success) {
          const link = document.createElement("a");
          link.href = response.data.data.url;
          link.setAttribute("download", `${item.name}`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const handleUploadFile = (file) => {
    axios
      .put(
        `${env.api.url}/v1/documents/operation/attachment`,
        {
          operationId: operationId,
          file: file[1].file,
          fileName: file[1].fileName,
          owner: clickTab,
          type: fileGroup.name,
          tags: tags,
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then(() => {

        getDocuments();
        getGroupsDoc();
        getDetailsCertificate()
        setLoading(false)
        if (countShowMessage === 0) {
          message.success(t("document.message"));
          countShowMessage = 1;
        }
      })
      .catch((error) => {
        console.log(error)
      });
    countShowMessage = 0;
  };

  const styleDocuments = (type, title, i, item) => {
    return (
      <div
        key={i}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 0",
          borderBottom: "1px solid #F1F7F8",
          alignItems: isMobile && "center",
          backgroundColor: isMobile && "#FFFFFF",
        }}
      >
        <div style={{ display: "flex", width: isMobile && "70%" }}>
          {type === "required" ? (
            <ExclamationCircleFilled
              style={{ color: "#2F4858", fontSize: 20, alignSelf: "center" }}
            />
          ) : type === "optional" ? (
            <ExclamationCircleOutlined
              style={{ color: "#2F4858", fontSize: 20, alignSelf: "center" }}
            />
          ) : type === "getThem" ? (
            <PlusOutlined
              style={{ color: "#02C3CD", fontSize: 18, alignSelf: "center" }}
            />
          ) : type === "downloading" ? (
            <img src={syncIcon} alt="syncIcon" />
          ) : (
            <CheckOutlined
              style={{ color: "#52C41A", fontSize: 18, alignSelf: "center" }}
            />
          )}

          <div
            style={{
              marginLeft: 16,
              width: "100%",
            }}
          >
            <Text
              style={{
                color: type === "getThem" ? "#02C3CD" : "#2F4858",
                fontSize: 14,
                fontWeight: type === "required" ? 600 : 400,
              }}
            >
              {item.type === undefined && type !== "downloading" &&
                !_.isEmpty(item) &&
                t(`documentTypes.${item.name}`)}

               {item.type !== undefined && type === "downloading" && 
                !_.isEmpty(item) &&
                t(item.name)}

              {item.type !== undefined && type !== "downloading" && 
                !_.isEmpty(item) &&
                t(`documentTypes.${item.type}`)}

              {_.isEmpty(item) && t(`${title}`)}

            </Text>
          </div>
        </div>
        {(type === "optional" || type === "required") && (
          <div
            className="PlusButton"
            style={{
              width: "14px",
              height: "14px",
              cursor: "pointer",
            }}
          >
            <Upload multiple={true} {...uploadProps}>
              <a
                onClick={() => handleClickPlus(clickTab, item)}
                style={{ border: "none" }}
              >
                <PlusOutlined style={{ color: "#02C3CD", fontSize: 15 }} />
              </a>
            </Upload>
          </div>
        )}
        {type === "validated" && (
          <div style={{ display: "flex" }}>
            <div
              className="PlusButton"
              style={{
                width: "14px",
                height: "14px",
                cursor: "pointer",
              }}
            >
              {item.tags[0] !== "automatic" && (
              //item.userId === detailsCertificate.userId && (
                <div>
                  <DeleteOutlined
                    onClick={() => modalDocument(item.id)}
                    style={{ color: "#FF4D4F", fontSize: 15 }}
                  />
                </div>
              )}
            </div>
            <div
              onClick={() => handleFileDownload(item.id, item)}
              style={{
                marginLeft: 18,
                cursor: "pointer",
              }}
            >
              <DownloadOutlined style={{ color: "#2F4858" }} />
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderDocumentInformation = (index, type) => {
    const data = valuesData.filter((item) => {
      let documentFlag = false;

      if (item.owner === `${type}${index}`) {
        documentFlag = true;
      } else if (type === "owner" && index === 1 && !item.owner) {
        documentFlag = true;
      }

      return documentFlag;
    });

    const filesValidated = data.map((item, i) => {
      if (item.type !== null && item.page !== null && item.owner) {
        return (
          //documentacion validada
          styleDocuments("validated", item.name, i, item)
        );
      }
    });
    const files = data.map((item, i) => {
      if (item.type === null || item.page === null) {
        return (
          //documentacion sin etiquetar
          styleDocuments("downloading", item.name, i, item)
        );
      }
    });

    const filesDoc =
      valuesDataForCertificate?.documents &&
      valuesDataForCertificate?.documents.map((item, i) => {
        if (item.completed[`${type}${index}`] === false) {
          if (item.participants[`${type}${index}`] === "required") {
            return (
              //documentacion requerida
              styleDocuments("required", item.title, i, item)
            );
          } else if (
            detailsCertificate &&
            detailsCertificate.operationData &&
            item.participants[`${type}${index}`] === "optional"
          ) {
            if (item.completed[`${type}${index}`] === false) {
              return (
                //documentacion opcional
                styleDocuments("optional", item.title, i, item)
              );
            }
          }
        }
      });
    return (
      <div>
        {valuesDataForCertificate &&
          valuesDataForCertificate.documentsNeeded -
            valuesDataForCertificate.documentsCompleted !==
            0 && (
            <div
              onClick={() => downloadOptionsRadio()}
              style={{ marginTop: 12, cursor: "pointer" }}
            >
              {styleDocuments(
                "getThem",
                t("myDocuments.getThemHere"),
                "getThem1",
                {}
              )}
            </div>
          )}
        {filesDoc}
        {valuesDataForCertificate &&
          valuesDataForCertificate.documentsNeeded -
            valuesDataForCertificate.documentsCompleted !==
            0 &&
          styleDocuments("optional", "Otro documento", "otroDucuemnto1", {})}
        {files}
        {filesValidated}
      </div>
    );
  };

  const saveData = (participant, key, value) => {
    axios
      .post(
        `${env.api.url}/v1/mortgages/data/update/${operationId}`,
        { key, participant, value },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      
  };

  const handleClickTab = (values) => {
    setClickTab(values)
    saveData("certificate", "currentOwner", values);
  }

  
  const renderTabs = (index, type) => {
    let requireFlag = false;

    if (valuesDataForCertificate && valuesDataForCertificate.documents) {
      valuesDataForCertificate.documents.forEach((data) => {
        if (data.completed[`${type}${index}`] === false) {
          if (data.participants[`${type}${index}`] === "required") {
            requireFlag = true;
          }
        }
      });
    }

    return (
      <TabPane
        tab={
          <span>
            {requireFlag ? notification() : null}

            {t(`mydocuments.participant`) + index}
          </span>
        }
        key={`${type}${index}`}
      >
        <Text
          style={{
            marginTop: 24,
            color: "#2F4858",
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          {t("stages.documents").toUpperCase()}&nbsp;

          {nameOperations &&
          <Text
            style={{
              marginTop: 24,
              color: "#02C3CD",
              fontSize: 12,
              fontWeight: 400,
            }}
          >
            {t("certificate.of").toUpperCase() +
              " " +
              nameOperations.operationData[`owner${index}`].name.toUpperCase()}
          </Text>
      }
        </Text>

        {renderDocumentInformation(index, type)}
      </TabPane>
    );
  };
  const documentTabs = [];

  if (
    detailsCertificate &&
    detailsCertificate.operationData &&
    detailsCertificate.operationData.operation
  ) {

    for (let i = 1; i <= detailsCertificate.operationData.operation.owners; i++) {
      documentTabs.push(renderTabs(i, "owner"));
    }

  }

  return (
    <>
      <Tabs
        type="card"
        style={{
          color: "#2F4858",
          fontStyle: "normal",
          fontWeight: "normal",
          marginTop: 24,
        }}
         onChange={(values) => handleClickTab(values)
          }
      >
        {documentTabs}
      </Tabs>
      <Row style={{ marginTop: 20, justifyContent: isMobile ? 'center' : 'space-between' }}>
        {/* LEYENDA */}
        <Col>
        {valuesDataForCertificate &&
          param.stage === "documents" && (
            <Card
              className="card-legend"
              style={{
                borderColor: "#037FB8",
                borderRadius: 4,
                background: "#ECF7FC",
                marginTop: 12,
                width: isMobile ? '95%' : 496,
                margin: isMobile && '0 auto'
              }}
            >
              {!isMobile && <Row style={{fontSize: 12, fontWeight: 500}}>Explicación:</Row>}
              <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: isMobile ? 6 : 0 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ExclamationCircleFilled
                    style={{
                      color: "#2F4858",
                      marginRight: isMobile ? 10 : 4,
                      fontSize: isMobile ? 16 : 14,
                    }}
                  />
                  <Text style={{ color: "#2F4858", fontSize: 12, fontWeight: 600, marginTop: 1 }}>
                    {t("certificate.legend3")}{" "}
                  </Text>
                { !isMobile && <Divider type="vertical" style={{ borderColor: "#B8DEEF", height: 12 }}/> }
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ExclamationCircleOutlined
                    style={{
                      color: "#2F4858",
                      marginRight: isMobile ? 10 : 4,
                      fontSize: isMobile ? 16 : 14,
                    }}
                  />
                  <Text style={{ color: "#2F4858", fontSize: 12, fontWeight: 400, marginTop: 1 }}>
                    {t("certificate.legend2")}{" "}
                  </Text>
                  { !isMobile && <Divider type="vertical" style={{ borderColor: "#B8DEEF", height: 12 }}/> }
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CheckOutlined
                    style={{
                      color: "#52C41A",
                      marginRight: isMobile ? 10 : 4,
                      fontSize: isMobile ? 16 : 14,
                    }}
                  />{" "}
                  <Text style={{ color: "#2F4858", fontSize: 12, fontWeight: 400 }}>
                    {t("certificate.legend1")}{" "}
                  </Text>
                </div>
              </div>
            </Card>
          )}
        </Col>
        {/* PIE CONFIANZA */}
        <Col>
        {valuesDataForCertificate &&
          param.stage === 'documents' && (
            <div style={{ width: 250, marginTop: isMobile && 15 }}>
              <div className="our-information-styles" style={{ fontSize: 12, justifyContent: isMobile ? 'center' : 'flex-start' }}>
                {t('doc.ourSecureInformation')}
              </div>
              <div style={{ marginTop: 13, display: 'flex', justifyContent: isMobile ? 'center' : 'space-between' }}>
                <img src={govermentIconMobile} alt="asdfadsf" style={{ height: 50 }}></img>
                <img src={spainBankMobile} alt="asdfadsf" style={{ height: 50, marginLeft: isMobile && 20 }}></img>
              </div>
            </div>
          )}
        </Col>
      </Row>
      {/* BOTON CONFIRMAR */}
      {/* {detailsCertificate &&
        !isMobile &&
        (account || valuesData) &&
        valuesDataForCertificate &&
        (fileAfterbanks || fileBanks3Moths) &&
        param.stage === "documents" && (
          <div style={{ width: '100%', textAlign: 'center'}}>
            <Button
              className="button-primari-gibobs"
              type="primary"
              style={{
                fontSize: "14px",
                width: "auto",
                height: 40,
                fontWeight: 600,
                marginTop: 34,
              }}
              onClick={() => goToAnalysis()}
              disabled={ valuesDataForCertificate.documentsCompleted !== valuesDataForCertificate.documentsNeeded }
            >
              {t("certificatedModal.documentationTitle")}
            </Button>
          </div>
        )} */}
    </>
  );
};

export default DocumentsCertificate;
