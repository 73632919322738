import { useTranslation } from 'react-i18next';
import { Typography, Input, Modal, Upload } from 'antd';
import { useSelector } from 'react-redux';

const { Text } = Typography;

const ModalEdit = ({
	visibleDelete,
	visibleEditImage,
	visibleEditName,
	setVisibleDelete,
	setVisibleEditName,
	handleModalEditName,
	setVisibleEditImage,
	handleModalEditImage,
	handleModalDelete,
	onChangeName,
	fileName,
	uploadProps
}) => {
	const { t } = useTranslation();
	const dataProfileDetails = useSelector((state) => state.data.dataProfile);
	return (
		<>
			<Modal
				className="gb-modal-40-padding"
				destroyOnClose={true}
				width={480}
				open={visibleDelete}
				onCancel={() => {
					setVisibleDelete(!visibleDelete);
				}}
				onOk={() => handleModalDelete()}
				okText={
					<Text style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 14 }}>
						{t('loan.delete')}
					</Text>
				}
				cancelText={
					<Text style={{ color: '#02C3CD', fontWeight: 600, fontSize: 14 }}>
						{t('offers.cancel')}
					</Text>
				}
				cancelButtonProps={{ style: { width: '43%', height: 40 } }}
				okButtonProps={{
					style: {
						width: '43%',
						height: 40,
						backgroundColor: '#FF4D4F',
						borderColor: '#FF4D4F'
					}
				}}
				title={
					<Text
						style={{
							fontSize: 16,
							fontWeight: 600,
							color: '#2F4858',
							marginTop: 24,
							marginBottom: 8
						}}>
						{t('loan.eliminateMortgage')}
					</Text>
				}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: -5,
						marginTop: 20
					}}>
					<Text
						style={{
							fontSize: 14,
							fontWeight: 400,
							color: '#748EA0'
						}}>
						{dataProfileDetails.name + ' ' + t('loan.deleteLoan3')}
					</Text>
				</div>
			</Modal>

			<Modal
				destroyOnClose={true}
				className="gb-modal-40-padding"
				width={480}
				open={visibleEditName}
				onCancel={() => {
					setVisibleEditName(!visibleEditName);
				}}
				onOk={() => handleModalEditName()}
				okText={
					<Text style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 14 }}>
						{t('viability.confirmButton')}
					</Text>
				}
				cancelText={
					<Text style={{ color: '#02C3CD', fontWeight: 600, fontSize: 14 }}>
						{t('offers.cancel')}
					</Text>
				}
				cancelButtonProps={{ style: { width: '44%', height: 40 } }}
				okButtonProps={{ style: { width: '44%', height: 40 } }}
				title={
					<Text
						style={{
							fontSize: 16,
							fontWeight: 600,
							color: '#2F4858'
						}}>
						{t('loan.modalTitlteEditName')}
					</Text>
				}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: 0,
						marginTop: 22
					}}>
					<Text
						style={{
							fontSize: 14,
							fontWeight: 400,
							color: '#748EA0'
						}}>
						{t('loan.editLoanNameDesktop')}
					</Text>

					<Input
						style={{ height: 40, marginTop: 24, width: '100%' }}
						placeholder={'Prestamo 1'}
						onChange={(value) => onChangeName(value)}></Input>
				</div>
			</Modal>

			<Modal
				className="gb-modal-40-padding"
				destroyOnClose={true}
				width={480}
				open={visibleEditImage}
				onCancel={() => {
					setVisibleEditImage(!visibleEditImage);
				}}
				onOk={() => handleModalEditImage()}
				okText={
					<Text style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 14 }}>
						{t('scoring.update')}
					</Text>
				}
				cancelText={
					<Text style={{ color: '#02C3CD', fontWeight: 600, fontSize: 14 }}>
						{t('offers.cancel')}
					</Text>
				}
				cancelButtonProps={{ style: { width: '44%', height: 40 } }}
				okButtonProps={{ style: { width: '44%', height: 40 } }}
				title={
					<Text
						style={{
							fontSize: 16,
							fontWeight: 600,
							color: '#2F4858'
						}}>
						{t('modalLoan.editImage')}
					</Text>
				}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: -4,
						marginTop: 18
					}}>
					<Text
						style={{
							fontSize: 14,
							fontWeight: 400,
							color: '#748EA0'
						}}>
						{t('loan.modalDescEditName')}
					</Text>

					{fileName === undefined && (
						<div
							style={{
								textAlign: 'center',
								marginTop: 24,
								width: '100%',
								fontSize: '14px',
								fontWeight: 500,
								color: '#02C3CD'
							}}>
							<Upload {...uploadProps} style={{ display: 'flex' }}>
								<a>{t('loan.modalEditImageAttach')}</a>
							</Upload>
						</div>
					)}
					{fileName !== undefined && (
						<div
							style={{
								fontSize: '14px',
								fontWeight: 500,
								color: '#02C3CD',
								textAlign: 'center',
								marginTop: 16,
								width: '100%'
							}}>
							{fileName}
						</div>
					)}
				</div>
			</Modal>
		</>
	);
};

export default ModalEdit;
