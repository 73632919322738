import React, { useState } from "react";
import { Col, Progress, Typography, Tooltip, Row, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {  useDispatch } from "react-redux";
import { DownloadOutlined, InfoCircleOutlined } from "@ant-design/icons";
import numeral from "numeral";
import { getPdf } from "../../../../store/promoter/actions";

const { Text } = Typography;

type Props ={
  title: string;
  number: number;
  equity: number;
  margin: number;
  viability: string;
  tooltipText: string;
}

const DifferentScenarioCardsMobile = ({ title, number, equity, margin, viability, tooltipText }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams<{ operationId: string, type: any }>();
  const operationId = params.operationId;
  const [loading, setLoading] = useState(false);

  const sectionScenario = (title: string, data: string,) => {
    return (
      <Col span={number === 0 ? 12 : 8}
        style={{
          backgroundColor: "#FFFFF",
          borderRight: title === 'promoterLoan.equityMultiple' && '1px solid rgba(192, 218, 238, 0.5)',
          borderLeft: title === 'm_viability.viabilityTitle' && '1px solid rgba(192, 218, 238, 0.5)',
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          padding: 8,
        }}
      >
        <div style={{ textAlign: "center", lineHeight: 1 }}>
          <div style={{ minHeight: title !== "m_viability.viabilityTitle" && number !== 0 && 30 }}>
            <Text style={{ fontSize: 10, fontWeight: 400, color: '#748EA0' }}>
              {t(title)}
            </Text>
          </div>
          {title === "m_viability.viabilityTitle" ? (
            <div>
              <Progress
                type="dashboard"
                strokeColor={parseInt(viability) < 3 ? "#FAAD14" : "#52C41A"}
                trailColor={"#F1F7F8"}
                percent={((viability ? parseInt(viability) : null) * 100) / 5}
                format={() => (
                  <div>
                    <Text
                      strong
                      style={{
                        fontSize: 14,
                        color: "#2F4858",
                        marginBottom: -13,
                        display: "grid",
                      }}
                    >
                      {viability ? numeral(viability).format("0.0") : 0}
                    </Text>
                    <Text style={{ fontSize: "10px", color: "#2F4858" }}>
                      {t('promoter.ofFive')}
                    </Text>
                  </div>
                )}
                width={54}
                className="EstimatedViabilityProgress"
                style={{
                  textAlign: "center",
                  marginTop: 6,
                  marginBottom: 0,
                  marginRight: 0,
                }}
              />
            </div>
          ) : (
            <div style={{ marginTop: 8 }}>
              <Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
                {data}
              </Text>
            </div>
          )}
        </div>
      </Col>
    );
  };

  return (
    <div style={{
      borderRadius: 4,
      boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
      marginTop:  number === 0 ? '8px' : number === 1 ? "24px" : "16px",
    }}
    >
      <div style={{
        width: '100%',
        boxShadow: '0px 2px 2px 0px rgba(47, 72, 88, 0.08)',
        backgroundColor: number === 0 ? "#FFFFF" : "#EFFCFC",
        padding: "16px 8px",
        display: "flex",
        justifyContent: "space-between"
      }}
      >
        <div style={{ display: 'flex', alignItems: 'center', fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
          {number + '. ' + t(title)}
          <Tooltip
            placement="bottom"
            title={
              <div style={{ fontSize: 12, fontWeight: 400 }}>
                {t(tooltipText)}
              </div>
            }
          >
            <div style={{ width: 30, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <InfoCircleOutlined 
                style={{ color: "#02C3CD", marginLeft: 4, fontSize: 20 }} 
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </Tooltip>
        </div>
        <div>
          {number !== 0 && (
            <>
              {!loading ? (
                <DownloadOutlined
                  style={{ color: "#02C3CD", fontSize: 24, marginLeft: 12 }}
                  onClick={() =>
                    dispatch(getPdf(number, operationId, (e) => setLoading(e)))
                  }
                />
              ) : (
                <Spin style={{ color: "#02C3CD", marginLeft: 12, fontSize: 15 }} />
              )}
            </>
          )}
        </div>
      </div>
      <Row style={{ width: "100%", padding: "8px 0px 16px 0px" }}>
        {sectionScenario("promoterLoan.equityMultiple", numeral(equity).format("0,0.00"))}
        {sectionScenario("promoterLoan.marginOnSales", numeral(margin).format("0.00 %"))}
        {viability !== undefined && sectionScenario("m_viability.viabilityTitle", viability)}
      </Row>
    </div>
  );
};

export default DifferentScenarioCardsMobile;
