// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
	apiKey: 'AIzaSyD8GsoIdQwZy2SnElrbNRBdfJWUFoljPjA',
	authDomain: 'gibobs-a478a.firebaseapp.com',
	databaseURL: 'https://gibobs-a478a-default-rtdb.europe-west1.firebasedatabase.app',
	projectId: 'gibobs-a478a',
	storageBucket: 'gibobs-a478a.appspot.com',
	messagingSenderId: '295989968442',
	appId: '1:295989968442:web:a365f2976bf53430007f03',
	measurementId: 'G-DC9QGHBXFD'
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

//export const FirebaseAnalytics = analytics;
