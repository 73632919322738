import { Offer, TranslationFunction } from './types';
import { Tooltip, Typography } from 'antd';
import {
	HeartOutlined,
	CarOutlined,
	FileDoneOutlined,
	LockOutlined,
	HomeOutlined,
	ContainerOutlined,
	EuroOutlined,
	CreditCardOutlined
} from '@ant-design/icons';
const { Text } = Typography;
const accidents = require('../../../../assets/icons/accidents.png');
const deep = require('../../../../assets/icons/deep.png');
const energetic = require('../../../../assets/icons/energetic.png');
const paymentsSecure = require('../../../../assets/icons/paymentsSecure.png');

//Funciones para Card y Tabla de ofertas
export const selectImagebank = (offer: Offer, table: boolean) => (
	<img
		src={
			'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/banks/' +
			`${offer.bankCode}` +
			'.png'
		}
		alt={offer.bankName}
		style={{ height: table ? 28 : 32, width: 'auto' }}
	/>
);

export const selectLogoBank = (offer: Offer) => (
	<img
		src={
			'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/banks/' +
			`${offer.bankCode}` +
			'_iso.png'
		}
		alt={offer.bankName}
		style={{ height: 24 }}
	/>
);

export const capitalizeFirstLetter = (type: string) => {
	return type.charAt(0).toUpperCase() + type.slice(1);
};

export const renderType = (offer: Offer, t: TranslationFunction) => {
	if (offer.ratio === 'FIXED') {
		return capitalizeFirstLetter(t('viabilityNew.fixed'));
	} else if (offer.ratio === 'MIXED') {
		return capitalizeFirstLetter(t('loan.mixedOnboarding'));
	} else {
		return capitalizeFirstLetter(t('cards.variable'));
	}
};

export const getLinkages = (offer: Offer, t: TranslationFunction, table: boolean) => {
	const field = [];
	if (offer.data !== undefined) {
		const [entailment] = offer.data.entailments;
		if (entailment?.linkages !== undefined) {
			if (entailment.linkages.insecurance.isChecked !== false) {
				field.push(
					<Tooltip
						placement="bottom"
						title={
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div>{t('loan.entailments_insecurance')}</div>
								<div>
									{entailment.linkages.insecurance.amount +
										'%' +
										' - ' +
										entailment.linkages.insecurance.price +
										'€'}
								</div>
							</div>
						}>
						<HeartOutlined className="iconOffersTable" />{' '}
					</Tooltip>
				);
			}
			if (entailment.linkages.car.isChecked !== false) {
				field.push(
					<Tooltip
						placement="bottom"
						title={
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div>{t('loan.entailments_car')}</div>
								<div>
									{entailment.linkages.car.amount +
										'%' +
										' - ' +
										entailment.linkages.car.price +
										'€'}
								</div>
							</div>
						}>
						<CarOutlined className="iconOffersTable" />{' '}
					</Tooltip>
				);
			}
			if (entailment.linkages.payroll.isChecked !== false) {
				field.push(
					<Tooltip
						placement="bottom"
						title={
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div>{t('loan.entailments_payroll')}</div>
								<div>
									{entailment.linkages.payroll.amount +
										'%' +
										' - ' +
										entailment.linkages.payroll.price +
										'€'}
								</div>
							</div>
						}>
						<FileDoneOutlined className="iconOffersTable" />{' '}
					</Tooltip>
				);
			}
			if (entailment.linkages.unemployment.isChecked !== false) {
				field.push(
					<Tooltip
						placement="bottom"
						title={
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div>{t('loan.entailments_unemployment')}</div>
								<div>
									{entailment.linkages.unemployment.amount +
										'%' +
										' - ' +
										entailment.linkages.unemployment.price +
										'€'}
								</div>
							</div>
						}>
						<LockOutlined className="iconOffersTable" />{' '}
					</Tooltip>
				);
			}
			if (entailment.linkages.home.isChecked !== false) {
				field.push(
					<Tooltip
						placement="bottom"
						title={
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div>{t('loan.entailments_home')}</div>
								<div>
									{entailment.linkages.home.amount +
										'%' +
										' - ' +
										entailment.linkages.home.price +
										'€'}
								</div>
							</div>
						}>
						<HomeOutlined className="iconOffersTable" />{' '}
					</Tooltip>
				);
			}
			if (entailment.linkages.payments.isChecked !== false) {
				field.push(
					<Tooltip
						placement="bottom"
						title={
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div>{t('loan.entailments_bills')}</div>
								<div>
									{entailment.linkages.payments.amount +
										'%' +
										' - ' +
										entailment.linkages.payments.price +
										'€'}
								</div>
							</div>
						}>
						<ContainerOutlined className="iconOffersTable" />{' '}
					</Tooltip>
				);
			}
			if (entailment.linkages.pensionPlan.isChecked !== false) {
				field.push(
					<Tooltip
						placement="bottom"
						title={
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div>{t('loan.entailments_pensionPlan')}</div>
								<div>
									{entailment.linkages.pensionPlan.amount +
										'%' +
										' - ' +
										entailment.linkages.pensionPlan.price +
										'€'}
								</div>
							</div>
						}>
						<EuroOutlined className="iconOffersTable" />{' '}
					</Tooltip>
				);
			}
			if (entailment.linkages.cards.isChecked !== false) {
				field.push(
					<Tooltip
						placement="bottom"
						title={
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div>{t('loan.entailments_cards')}</div>
								<div>
									{entailment.linkages.cards.amount +
										'%' +
										' - ' +
										entailment.linkages.cards.price +
										'€'}
								</div>
							</div>
						}>
						<CreditCardOutlined className="iconOffersTable" />{' '}
					</Tooltip>
				);
			}
			if (entailment.linkages.accidents.isChecked !== false) {
				field.push(
					<Tooltip
						placement="bottom"
						title={
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div>{t('loan.entailments_accidentInsurance')}</div>
								<div>
									{entailment.linkages.accidents.amount +
										'%' +
										' - ' +
										entailment.linkages.accidents.price +
										'€'}
								</div>
							</div>
						}>
						<img width={16} height={16} src={accidents} alt="" />{' '}
					</Tooltip>
				);
			}
			if (entailment.linkages.investmentFunds.isChecked !== false) {
				field.push(
					<Tooltip
						placement="bottom"
						title={
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div>{t('loan.entailments_investmentFund')}</div>
								<div>
									{entailment.linkages.investmentFunds.amount +
										'%' +
										' - ' +
										entailment.linkages.investmentFunds.price +
										'€'}
								</div>
							</div>
						}>
						<img width={16} height={16} src={deep} alt="" />{' '}
					</Tooltip>
				);
			}
			if (entailment.linkages.energyCertification.isChecked !== false) {
				field.push(
					<Tooltip
						placement="bottom"
						title={
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div>{t('loan.entailments_energyRating')}</div>
								<div>
									{entailment.linkages.energyCertification.amount +
										'%' +
										' - ' +
										entailment.linkages.energyCertification.price +
										'€'}
								</div>
							</div>
						}>
						<img width={16} height={16} src={energetic} alt="" />{' '}
					</Tooltip>
				);
			}
			if (entailment.linkages.protectedPayments.isChecked !== false) {
				field.push(
					<Tooltip
						placement="bottom"
						title={
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div>{t('icon.viability2')}</div>
								<div>
									{entailment.linkages.protectedPayments.amount +
										'%' +
										' - ' +
										entailment.linkages.protectedPayments.price +
										'€'}
								</div>
							</div>
						}>
						<img width={18} height={18} src={paymentsSecure} alt="" />{' '}
					</Tooltip>
				);
			}
		}
		if (!field.length) {
			if (table) {
				return (
					<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858', display: 'block' }}>
						-
					</Text>
				);
			} else {
				return (
					<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858', display: 'block' }}>
						{t('offers.linkages')}
					</Text>
				);
			}
		}
	}
	return field;
};

export const renderAmortization = (revision: string, t: TranslationFunction) => {
	if (revision === 'LAW') {
		return t('offer.law');
	} else if (revision === 'ZERO') {
		return '0%';
	} else {
		return t('loan.noBankAvatar');
	}
};

export const renderRevision = (revision: string, t: TranslationFunction) => {
	if (revision === 'BIANNUAL') {
		return t('offer.biannual');
	} else {
		return t('loan.annual');
	}
};

export const showHeart = (favoriteOffers: Offer[], offer: Offer) => {
	return (
		!favoriteOffers.some((fav: Offer) => fav.bankCode === offer.bankCode) ||
		!favoriteOffers.some(
			(fav: Offer) =>
				fav.bankCode === offer.bankCode && offer.bankRequest.type === 'API' && fav.isFavorite
		)
	);
};

export const hasLinkages = (offer: Offer) => {
	const linkages = offer?.data?.entailments[0]?.linkages;
	if (!linkages) {
		return false;
	}
	for (const key in linkages) {
		if (linkages.hasOwnProperty(key) && linkages[key].isChecked) {
			return true;
		}
	}
	return false;
};
