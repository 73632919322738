import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Upload, Space, Tooltip, Dropdown, Menu, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import {
	CloudDownloadOutlined,
	EditOutlined,
	DeleteOutlined,
	FolderOutlined,
	FileOutlined,
	PlusOutlined,
	MoreOutlined
} from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import { uploadFile } from '../../store/documents/actions';
import env from '../../environment';
import axios from 'axios';
import moment from 'moment';

const DocumentItem = ({
	document = {},
	addItemType = undefined,
	onModal,
	onModalRename,
	onModalDelete,
	folderId,
	virtual = false
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();;
	const dispatch = useDispatch();
	const token = useSelector((state) => state.auth.token);
	const [deleteFolderEmtpy, setDeleteFolderEmtpy] = useState(undefined);
	let content = <div></div>;
	const modeList = useSelector((state) => state.data.modeList);

	useEffect(() => {
		if (document.id !== undefined) {
			axios
				.get(`${env.api.url}/v1/documents/is-empty/${document.id}`, {
					headers: { Authorization: `Bearer ${token}` }
				})
				.then((response) => {
					setDeleteFolderEmtpy(response.data.data);
				});
		}
	}, [document.id]);

	const onClick = (type) => {
		if (onModal) {
			onModal();
		} else if (type === 'folder') {
			navigate(`/documents/${document.id}`);
		}
	};

	const send = () => {
		if (document.document) {
			axios
				.get(`${env.api.url}/v1/documents/my-documents/download/${document.id}`, {
					headers: { Authorization: `Bearer ${token}` }
				})
				.then((response) => {
					if (response.data.success) {
						const url = response.data.data.url;
						window.open(url);
					}
				});
		} else {
			navigate(`/documents/${document.id}`);
		}
	};

	const rename = () => {
		onModalRename(document.id);
	};

	const deleteAction = async () => {
		await new Promise((resolve) => {
			axios
				.get(`${env.api.url}/v1/documents/is-empty/${document.id}`, {
					headers: { Authorization: `Bearer ${token}` }
				})
				.then((response) => {
					setDeleteFolderEmtpy(response.data.data);
					resolve();
				});
		});
		onModalDelete(document.id, deleteFolderEmtpy);
	};

	const type = document.document
		? 'file'
		: addItemType
		? `${addItemType} add-${addItemType}`
		: 'folder';
	let typeElement;

	if (type === 'folder') {
		typeElement = <FolderOutlined className="icon" />;
	} else if (addItemType) {
		typeElement = <PlusOutlined className="add icon" />;
	} else {
		typeElement = <FileOutlined className="icon" />;
	}

	let documentName;
	let documentDate;
	let numberFile = 0;
	let numberFolder = 0;

	if (addItemType) {
		documentName =
			addItemType === 'folder' ? t('mydocuments.createFolder') : t('mydocuments.createFile');
	} else {
		documentName = t(`mydocuments.${document.name}`, document.name);
		documentDate = t(`mydocuments.${document.createdAt}`, document.createdAt);
		numberFile = document.files;
		numberFolder = document.folders;
	}

	const handleUploadFile = (file, name) => {
		dispatch(uploadFile(name, file, folderId));
	};

	const uploadProps = {
		beforeUpload: (file) => {
			return new Promise(() => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					const base64File = reader.result.split(',')[1];
					handleUploadFile(base64File, file.name);
				};
			});
		}
	};

	const overlay =
		!addItemType && type !== 'folder' ? (
			<div className="overlay">
				<Space align="center" size="middle">
					<Tooltip title={t('mydocuments.downloadFile')}>
						<CloudDownloadOutlined className="mini-button" onClick={send} />
					</Tooltip>
					{!virtual && (
						<>
							<Tooltip title={t('mydocuments.renameFile')}>
								<EditOutlined className="mini-button" onClick={rename} />
							</Tooltip>
							<Tooltip title={t('mydocuments.renameFile')}>
								<DeleteOutlined className="mini-button" onClick={deleteAction} />
							</Tooltip>
						</>
					)}
				</Space>
			</div>
		) : undefined;
	const overable = !addItemType && type !== 'folder' ? 'overable' : 'clickable';

	let menu;

	if ((type === 'folder' || type === 'file') && !virtual) {
		const submenu = (
			<Menu>
				<Menu.Item icon={<EditOutlined />} onClick={rename}>
					{t('mydocuments.renameFolder')}
				</Menu.Item>
				<Menu.Item icon={<DeleteOutlined />} onClick={deleteAction} danger>
					{t('mydocuments.deleteFolder')}
				</Menu.Item>
			</Menu>
		);

		menu = (
			<Dropdown overlay={submenu}>
				{modeList ? (
					<MoreOutlined className="document-menu" />
				) : (
					<MoreOutlined className={`document-menu ${type} ${overable}`} />
				)}
			</Dropdown>
		);
	}

	if (modeList === false) {
		content = (
			<div className={`document-item ${type} ${overable}`}>
				{type === 'folder' && menu}

				<div className="document-content" onClick={() => onClick(type)}>
					{overlay}
					{typeElement}
					<br />
					<span className="title">{documentName}</span>
				</div>
			</div>
		);
	}

	if (modeList) {
		content = (
			<div style={{ marginTop: 10, marginLeft: documentName !== 'Crear carpeta' ? -8 : -15 }}>
				<div
					style={{
						borderTop: '1px solid #F1F7F8',
						marginBottom: 10,
						overflow: 'hidden',
						position: 'relative',
						width: type !== 'folder' ? 530 : 1000,
						height: 1,
						marginBottom: -20
					}}></div>
				<Col
					span={6}
					style={{
						marginLeft: type !== 'folder' && documentName !== 'Crear carpeta' ? -17 : -8,
						marginTop: -50
					}}>
					<div className={`document-item ${type} ${overable}`}>
						<div
							className="document-content"
							onClick={() => onClick(type)}
							style={{ marginLeft: type !== 'folder' ? -10 : undefined, marginTop: 12 }}>
							<div style={{ display: 'flex', width: 300, alignItems: 'center' }}>
								{overlay}
								{typeElement}
								{documentName.length > 25 ? (
									<Tooltip title={documentName}>
										<div style={{ fontSize: 12, color: '#2f4858', marginLeft: 5 }}>
											{documentName.substring(0, 25) + '...'}
										</div>
									</Tooltip>
								) : (
									<div style={{ fontSize: 12, color: '#2f4858', marginLeft: 5 }}>
										{documentName}
									</div>
								)}
							</div>
						</div>
					</div>
				</Col>

				<Col span={6} style={{ marginLeft: -8 }}>
					<div className={`document-item ${type} ${overable}`} style={{ width: '100%' }}>
						{documentName !== 'Crear carpeta' && (
							<span style={{ position: 'relative', top: -20, left: 195 }} className="title">
								{moment(documentDate).format('L')}
							</span>
						)}
						{documentName !== 'Crear carpeta' && type === 'folder' && (
							<span style={{ position: 'relative', left: 320, top: -50, width: '100%' }}>
								{numberFile}
							</span>
						)}

						{documentName !== 'Crear carpeta' && type === 'folder' && (
							<span style={{ position: 'relative', left: 520, top: -50, width: '100%' }}>
								{numberFolder}
							</span>
						)}

						{
							<div
								style={{
									position: 'relative',
									left: type === 'file' ? -170 : 280,
									width: '100%',
									top: type === 'file' ? -50 : -70
								}}>
								{menu}
							</div>
						}
					</div>
				</Col>
			</div>
		);
	}

	return addItemType === 'file' ? (
		<Upload multiple={true} {...uploadProps}>
			{content}
		</Upload>
	) : (
		content
	);
};

export default DocumentItem;
