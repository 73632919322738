import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import CapitalStructureForm from "../../Pages/Promoter-loan/CapitalStructureForm";
import EconomicStudy from "../../Pages/Promoter-loan/EconomicStudy/EconomyStudy";
import FinancialDataForm from "../../Pages/Promoter-loan/FinancialDataForm";
import GeneralDataForm from "../../Pages/Promoter-loan/GeneralDataForm";
import MyProductsPromoter from "../../Pages/Promoter-loan/Products/MyProductsPromoter";
import DocumentatioPromoter from "../../Pages/Promoter-loan/SimulationSummary/DocumentationPromoter";
import SimulationSummary from "../../Pages/Promoter-loan/SimulationSummary/SimulationSummary";
import WelcomePage from "../../Pages/Promoter-loan/welcomePage";
import { getListMyProyects } from "../../store/promoter/actions";
import DashboardPromoter from "../../Pages/Promoter-loan/DashboardPromoter";
import { useSelector, useDispatch } from "react-redux";
import { isMobile } from "react-device-detect";
import LoadingComponent from "../../Pages/Promoter-loan/LoadingComponent";
import WelcomePageMobile from "../../Pages/Promoter-loan/Promoter-mobile/welcomePageMobile";
import GeneralDataFormMobile from "../../Pages/Promoter-loan/Promoter-mobile/GeneralDataFormMobile";
import FinancialDataFormMobile from "../../Pages/Promoter-loan/Promoter-mobile/FinancialDataFormMobile";
import CapitalStructureFormMobile from "../../Pages/Promoter-loan/Promoter-mobile/CapitalStructureFormMobile";
import SimulationSummaryMobile from "../../Pages/Promoter-loan/Promoter-mobile/SimmulationSummary/SimulationSummaryMobile";

export default () => {
  const dispatch = useDispatch();
  const dataList = useSelector((state) => state.promoter.dataListMyProyects);
  useEffect(() => {
    if (dataList.length === 0) {
      dispatch(getListMyProyects());
    }
  }, []);

  const linksDesktop = [
    <Route
      path="/step-form/:operationId"
      element={ <LoadingComponent component={<WelcomePage />} />}
    />,
    <Route
      path="/general-data/:operationId"
      element={ <LoadingComponent component={<GeneralDataForm />} />}
    />,
    <Route
      path="/financial-data/:operationId"
      element={ <LoadingComponent component={<FinancialDataForm />} />}
    />,
    <Route
      path="/capital-structure/:operationId"
      element={ <LoadingComponent component={<CapitalStructureForm />} />}
    />,
    <Route
      path="/documentation/:operationId"
      element={ <DocumentatioPromoter />}
    />,
    <Route
      path="/economic-study/:operationId/:type"
      element={ <EconomicStudy />}
    />,
    <Route
      path="/:stage/:operationId"
      element={ <SimulationSummary />}
    />,
  ];


  const linksMobile = [
    <Route
      path="/step-form/:operationId"
      element={ <LoadingComponent component={<WelcomePageMobile />} />}
    />,
    <Route
      path="/general-data/:operationId"
      element={ <LoadingComponent component={<GeneralDataFormMobile />} />}
    />,
    <Route
      path="/financial-data/:operationId"
      element={ <LoadingComponent component={<FinancialDataFormMobile />} />}
    />,
    <Route
      path="/capital-structure/:operationId"
      element={ <LoadingComponent component={<CapitalStructureFormMobile />} />}
    />,
    <Route
      path="/documentation/:operationId"
      element={ <DocumentatioPromoter />}
    />,
    <Route
      path="/economic-study/:operationId/:type"
      element={ <EconomicStudy />}
    />,
    <Route
      path="/:stage/:operationId"
      element={ <SimulationSummaryMobile />}
    />,
  ]
  return (
    <Routes>
      <Route path="/dashboard-business" element={<DashboardPromoter />} />
      <Route
        path="/products-under-contract"
        element={ <MyProductsPromoter />}
      />
      {!isMobile ? linksDesktop : linksMobile}
    </Routes>
  );
};
