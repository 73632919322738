import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import {
    useNavigate,
    useParams,
} from "react-router-dom";
import {
    Layout,
    Card,
    Typography,
    Button,
    Col,
    Row,
    message,
} from "antd";
import axios from "axios";
import env from "../../../../environment";
import { useSelector } from "react-redux";
import { ArrowLeftOutlined, LineChartOutlined } from "@ant-design/icons";
import numeral from "numeral";


const { Text } = Typography;

const LoanPageDetailsConfiguration = ({  }) => {

    const { t } = useTranslation()
    const navigate = useNavigate();;
    const userToken = useSelector((state) => state.auth.token);
    let { loanId } = useParams();
    const [loan, setLoan] = useState(undefined);


    useEffect(() => {
        getLoan()
    }, [])



    const getLoan = () => {
        axios
            .get(`${env.api.url}/v1/loans/get-loan/${loanId}`, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            .then((response) => {
                // setDataValues(response.data.data);
                setLoan(response.data.data)

              
            })
            .catch((error) => { 
                console.log(error)
            });

    };




    const backToPage = () => {
        navigate(`/my-financial-profile/credits/all-detail-loan/${loanId}`)
    }



    const handleClickSendEmail = () => {
        axios
            .get(`${env.api.url}/v1/clients/contact-client-generic`, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            .then(() => {
                message.success("Tu solicitud se ha registrado correctamente");
            });

    }

    return (
        <Layout style={{ padding: 16, height: "150vh" }}>

            {loan &&
                <>
                    <div style={{ display: "flex", width: "100%" }}>
                        <a onClick={() => backToPage()}><ArrowLeftOutlined style={{ color: "#2F4858", fontSize: 20 }} /></a>
                        <Text style={{ color: "#2F4858", fontSize: "16px", fontWeight: 600, position: "relative", left: 16, top: -3 }}>{t("loan.compareInteres")} </Text>
                    </div>

                    {loan.loan.rateType === "variable" ?
                        <div style={{ display: "flex", marginTop: 24, alignSelf: "center" }}>
                            <LineChartOutlined style={{ color: "#02C3CD", fontSize: 20 }} />
                            <Text style={{ color: "#2F4858", fontSize: "16px", fontWeight: 600, position: "relative", left: 12, top: -3 }}>{t("loan.compareInteresDesc")} </Text>

                        </div>
                        :
                        <div style={{ display: "flex", marginTop: 24, alignSelf: "center" }}>
                            <LineChartOutlined style={{ color: "#02C3CD", fontSize: 20 }} />
                            <Text style={{ color: "#2F4858", fontSize: "16px", fontWeight: 600, position: "relative", left: 12, top: -3 }}>{t("loan.compareFixed1")} </Text>

                        </div>
                    }

                    <Row gutter={[8, 0]} style={{ marginTop: 16 }}>
                        <Col span={12}>
                            <Card className="gb-card-compare1" >
                                <Text style={{ color: "#2F4858", fontSize: "16px", fontWeight: 600 }}>{t("loan.yourInteres")} </Text>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Text style={{ color: "#748EA0", fontSize: "12px", fontWeight: 400, marginTop: 16 }}>{t("loan.diferentialmoreEurrrribor")} </Text>
                                    <Text style={{ color: "#2F4858", fontSize: "16px", fontWeight: 400 }}>{
                                        Number.parseInt(loan.quote.spread.toFixed(2)) + Number.parseInt(loan.quote.index.toFixed(2)) + "%"

                                    } </Text>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Text style={{ color: "#748EA0", fontSize: "12px", fontWeight: 400, marginTop: 8 }}>{t("loan.pendingterm")} </Text>
                                    <Text style={{ color: "#2F4858", fontSize: "16px", fontWeight: 400 }}>{(loan.quote.term / 12).toFixed(0) + " "+ t("form.years").toLowerCase()} </Text>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Text style={{ color: "#748EA0", fontSize: "12px", fontWeight: 400, marginTop: 8 }}>{t("viability.quote")} </Text>
                                    <Text style={{ color: "#2F4858", fontSize: "16px", fontWeight: 400 }}>{loan.quote.nextQuote + "€/" + t("viability.monthsEstimated")} </Text>
                                </div>

                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card className="gb-card-compare2">
                                <Text style={{ color: "#02C3CD", fontSize: "16px", fontWeight: 600 }}>{t("viability.ourBestOffer")} </Text>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Text style={{ color: "#748EA0", fontSize: "12px", fontWeight: 400, marginTop: 16 }}>{t("TIN")} </Text>
                                    <Text style={{ color: "#02C3CD", fontSize: "16px", fontWeight: 400 }}>{loan.market.gibobs + "%"} </Text>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Text style={{ color: "#748EA0", fontSize: "12px", fontWeight: 400, marginTop: 8 }}>{t("loan.pendingterm")} </Text>
                                    <Text style={{ color: "#02C3CD", fontSize: "16px", fontWeight: 400 }}>{(loan.quote.term / 12).toFixed(0) + " "+ t("form.years").toLowerCase()} </Text>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Text style={{ color: "#748EA0", fontSize: "12px", fontWeight: 400, marginTop: 8 }}>{t("viability.quote")} </Text>
                                    <Text style={{ color: "#02C3CD", fontSize: "16px", fontWeight: 400 }}>{numeral(loan.comparison.fixedQuote).format("0,0 $") + "/" + t("viability.monthsEstimated")} </Text>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Text style={{ color: "#748EA0", fontSize: "14px", fontWeight: 400, marginTop: 16 }}>{t("viability.totalSavings")} </Text>
                                    <Text style={{ color: "#02C3CD", fontSize: "20px", fontWeight: 400 }}>{numeral(loan.comparison.totalFixedSavings).format("0,0 $")} </Text>
                                </div>

                            </Card>
                        </Col>

                    </Row>

                    {loan.loan.rateType === "variable" ?
                        <div style={{ display: "flex", marginTop: 24, alignSelf: "center" }}>
                            <LineChartOutlined style={{ color: "#02C3CD", fontSize: 20 }} />
                            <Text style={{ color: "#2F4858", fontSize: "16px", fontWeight: 600, position: "relative", left: 12, top: -3 }}>{t("loan.compareInteresDesc2")} </Text>

                        </div>
                        :
                        <div style={{ display: "flex", marginTop: 24, alignSelf: "center" }}>
                            <LineChartOutlined style={{ color: "#02C3CD", fontSize: 20 }} />
                            <Text style={{ color: "#2F4858", fontSize: "16px", fontWeight: 600, position: "relative", left: 12, top: -3 }}>{t("loan.compareFixed2")} </Text>

                        </div>
                    }

                    <Row gutter={[8, 0]} style={{ marginTop: 16 }}>
                        <Col span={12}>
                            <Card className="gb-card-compare1" >
                                <Text style={{ color: "#2F4858", fontSize: "16px", fontWeight: 600 }}>{t("loan.yourInteres")} </Text>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Text style={{ color: "#748EA0", fontSize: "12px", fontWeight: 400, marginTop: 16 }}>{t("loan.diferentialmoreEurrrribor")} </Text>
                                    <Text style={{ color: "#2F4858", fontSize: "16px", fontWeight: 400 }}>{
                                        Number.parseInt(loan.quote.spread.toFixed(2)) + Number.parseInt(loan.quote.index.toFixed(2)) + "%"

                                    } </Text>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Text style={{ color: "#748EA0", fontSize: "12px", fontWeight: 400, marginTop: 8 }}>{t("loan.pendingterm")} </Text>
                                    <Text style={{ color: "#2F4858", fontSize: "16px", fontWeight: 400 }}>{(loan.quote.term / 12).toFixed(0)+  " "+ t("form.years").toLowerCase()} </Text>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Text style={{ color: "#748EA0", fontSize: "12px", fontWeight: 400, marginTop: 8 }}>{t("viability.quote")} </Text>
                                    <Text style={{ color: "#2F4858", fontSize: "16px", fontWeight: 400 }}>{loan.quote.nextQuote + "€/" + t("viability.monthsEstimated")} </Text>
                                </div>

                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card className="gb-card-compare2">
                                <Text style={{ color: "#02C3CD", fontSize: "16px", fontWeight: 600 }}>{t("viability.ourBestOffer")} </Text>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Text style={{ color: "#748EA0", fontSize: "12px", fontWeight: 400, marginTop: 16 }}>{t("TIN")} </Text>
                                    <Text style={{ color: "#02C3CD", fontSize: "16px", fontWeight: 400 }}>{loan.loan.rateType === "variable" ? (loan.rates.gibobs.variable).toFixed(0) + " %" : (loan.rates.gibobs.fixed).toFixed(0)+ " %"} </Text>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Text style={{ color: "#748EA0", fontSize: "12px", fontWeight: 400, marginTop: 8 }}>{t("loan.pendingterm")} </Text>
                                    <Text style={{ color: "#02C3CD", fontSize: "16px", fontWeight: 400 }}>{(loan.quote.term / 12).toFixed(0)+ " "+ t("form.years").toLowerCase()} </Text>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Text style={{ color: "#748EA0", fontSize: "12px", fontWeight: 400, marginTop: 8 }}>{t("viability.quote")} </Text>
                                    <Text style={{ color: "#02C3CD", fontSize: "16px", fontWeight: 400 }}>{numeral(loan.comparison.variableQuote).format("0,0 $") + "/" + t("viability.monthsEstimated")} </Text>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Text style={{ color: "#748EA0", fontSize: "14px", fontWeight: 400, marginTop: 16 }}>{t("viability.totalSavings")} </Text>
                                    <Text style={{ color: "#02C3CD", fontSize: "20px", fontWeight: 400 }}>{numeral(loan.comparison.totalVariableSavings).format("0,0 $")} </Text>
                                </div>

                            </Card>
                        </Col>

                    </Row>
                    <div style={{ marginTop: 32 }}>
                        <Text style={{ color: "#748EA0", fontSize: "10px", fontWeight: 400 }}>{t("")} </Text>
                    </div>


                    <Card className="cardButtonStyle" style={{ left: 0, textAlign: "center" }}>
                        <Button
                            className="button-primari-gibobs"
                            type="primary"
                            onClick={() => handleClickSendEmail()}
                            style={{width: "100%", height: 48}}
                        >
                            {t("form.wantCalled")}
                        </Button>
                    </Card>




                </>
            }

        </Layout>
    );
};

export default LoanPageDetailsConfiguration;
