import React from "react";
import { NavLink } from "react-router-dom";
import { Breadcrumb, Layout, Typography } from "antd";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const NotificationsPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Breadcrumb style={{ margin: "24px 40px" }}>
        <Breadcrumb.Item>
          <NavLink to="/dashboard-particular">
            <Text style={{ color: "#748EA0", fontSize: "12px" }}>
              {t("menuComponent.initNav")}
            </Text>
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Text strong style={{ color: "#02C3CD", fontSize: "12px" }}>
            {t("menuComponent.notifications")}
          </Text>
        </Breadcrumb.Item>
      </Breadcrumb>
      NOTIFICACIONES
    </Layout>
  );
};

export default NotificationsPage;
