import React, { useEffect, useState } from 'react';
import { Row, Button, Card, Col, Typography, Progress } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { ArrowLeftOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import ViabilityComponent from '../../Operaciones/viabilityAndSimulation/viabilityComponent';

const { Text } = Typography;

const CalculatorsFeeViabilityMobileAndDesktop = ({}) => {
	const location = useLocation();
	const { t } = useTranslation();
	const navigate = useNavigate();;
	const [textViability, setTextViability] = useState(undefined);
	const [textViabilitytitle, setTextViabilitytitle] = useState(undefined);
	const [showData, setShowData] = useState(true);

	useEffect(() => {
		if (location && location.state && location.state.result !== undefined) {
			if (location.state.result.simulationData.resultViabilityMortage.viability < 2) {
				setTextViability(t('viability.viabilityLessThan2'));
			} else if (
				location.state.result.simulationData.resultViabilityMortage.viability < 3 &&
				location.state.result.simulationData.resultViabilityMortage.viability >= 2
			) {
				setTextViability(t('viability.viabilityBetween2and3'));
			} else if (
				location.state.result.simulationData.resultViabilityMortage.viability < 4 &&
				location.state.result.simulationData.resultViabilityMortage.viability >= 3
			) {
				setTextViability(t('viability.viabilityBetween3and4'));
			} else {
				setTextViability(t('viability.viabilityBetween4and5'));
			}

			if (location.state.result.simulationData.resultViabilityMortage.viability < 2) {
				setTextViabilitytitle(t('viability.viabilityLessThan2title'));
			} else if (
				location.state.result.simulationData.resultViabilityMortage.viability < 3 &&
				location.state.result.simulationData.resultViabilityMortage.viability >= 2
			) {
				setTextViabilitytitle(t('viability.viabilityBetween2and3title'));
			} else if (
				location.state.result.simulationData.resultViabilityMortage.viability < 4 &&
				location.state.result.simulationData.resultViabilityMortage.viability >= 3
			) {
				setTextViabilitytitle(t('viability.viabilityBetween3and4title'));
			} else {
				setTextViabilitytitle(t('viability.viabilityBetween4and5title'));
			}
		}
	}, []);

	const data = [
		{
			titulo: 'viability.financingCeilingTitle',
			viable: location?.state?.result?.simulationData?.resultViabilityMortage?.penalties
				? location?.state?.result?.simulationData?.resultViabilityMortage?.penalties[
						'financingCeiling'
				  ]
				: null
		},
		{
			titulo: 'viability.indebtednessTitle',
			viable: location?.state?.result?.simulationData?.resultViabilityMortage?.penalties
				? location?.state?.result?.simulationData?.resultViabilityMortage?.penalties['indebtedness']
				: null
		},
		{
			titulo: 'viability.seniorityTitle',
			viable: location?.state?.result?.simulationData?.resultViabilityMortage?.penalties
				? location?.state?.result?.simulationData?.resultViabilityMortage?.penalties['seniority']
				: null
		},
		{
			titulo: 'viability.incomesTitle',
			viable: location?.state?.result?.simulationData?.resultViabilityMortage?.penalties
				? location?.state?.result?.simulationData?.resultViabilityMortage?.penalties['incomes']
				: null
		},
		{
			titulo: 'viability.noresidentTitle',
			viable: location?.state?.result?.simulationData?.resultViabilityMortage?.penalties
				? location?.state?.result?.simulationData?.resultViabilityMortage?.penalties['noresident']
				: null
		}
	];

	const renderBack = () => {
		
		navigate( `/calculators/fee`,{
			state: {
				result: location.state.result,
				formValues: location.state.formValues
			}
		});
	};

	if (isMobileOnly && textViabilitytitle && textViability) {
		return (
			<>
				<div style={{ padding: 16 }}>
					<Row style={{ width: '100%' }}>
						<Col span={24}>
							<a onClick={() => renderBack()}>
								<ArrowLeftOutlined style={{ color: '#2F4858', fontSize: 20 }} />
							</a>
							<Text
								style={{
									color: '#2F4858',
									fontSize: 16,
									fontWeight: 600,
									marginLeft: 16
								}}>
								{t('onboarding.viabilityOnBanks')}
							</Text>
						</Col>
					</Row>

					<div
						style={{
							textAlign: 'center',
							marginTop: 16,
							display: 'flex',
							flexDirection: 'column'
						}}>
						<Progress
							type="circle"
							strokeColor={'#52C41A'}
							trailColor={'#F1F7F8'}
							percent={
								((location && location.state && location.state.result.simulationData
									? location.state.result.simulationData.resultViabilityMortage.viability
									: 0) *
									100) /
								5
							}
							width={80}
							format={() => (
								<div>
									<Text
										strong
										style={{
											fontSize: '32px',
											color: '#2F4858',
											marginBottom: '-4px',
											display: 'grid',
											marginTop: 10
										}}>
										{location && location.state && location.state.result.simulationData
											? location.state.result.simulationData.resultViabilityMortage.viability.toFixed(
													1
											  )
											: 0}
									</Text>
									<Text
										style={{
											fontSize: '14px',
											color: '#2F4858',
											marginTop: -16
										}}>{`de 5`}</Text>
								</div>
							)}
						/>
						<Text
							style={{
								color: '#52C41A',
								fontSize: 16,
								fontWeight: 600,
								marginTop: 16
							}}>
							{textViabilitytitle}&nbsp;
						</Text>
					</div>

					<div style={{ marginTop: 26, marginBottom: 100 }}>
						<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>
							{t('viability.whyDoIHaveThisViability')}
						</Text>
						<div
							style={{ textAlign: 'right', marginTop: -20 }}
							onClick={() => setShowData(!showData)}>
							{!showData && <DownOutlined style={{ color: '#748EA0', fontSize: 16 }} />}
							{showData && <UpOutlined style={{ color: '#748EA0', fontSize: 16 }} />}
						</div>

						{showData && (
							<Row style={{ marginTop: 24, marginBottom: 200 }}>
								{data.map((values, index) => {
									return (
										<Col span={24} style={{ marginTop: index !== 0 ? 24 : undefined }}>
											<ViabilityComponent
												dataMortgage={location.state.result.simulationData}
												key={values.titulo}
												titulo={values.titulo}
												viable={values.viable}
												viabilityData={location.state.result.simulationData.resultViabilityMortage}
												ltv={location.state.result.simulationData.resultQuoteMortage.ltv}
												calculator={true}
											/>
										</Col>
									);
								})}
							</Row>
						)}
						<Card
							className="cardButtonStyle"
							style={{
								left: 0,
								backgroundColor: '#FAFEFE'
							}}>
							<Button
								id="gb-mortgage-buy-situation-mobile"
								className="button-primary-gibobs-inmo"
								type="primary"
								style={{
									fontSize: 16,
									width: '100%',
									height: 48,
									fontWeight: 600
								}}
								onClick={() => {
									
									navigate(`/calculators/fee/datas`,{
										state: {
											values: location,
											houseType: location.state.result.simulationData.houseType,
											fixed: location.state.result.simulationData.fixed,
											quotes: location.state.result.simulationData.quotes,
											maxBudget: location.state.result.simulationData.maxBudget,
											savings: location.state.result.simulationData.savings,
											province: location.state.result.simulationData.province,
											propertyType: location.state.result.simulationData.propertyType,
											participants:
												location.state.result.simulationData['owner2'] !== undefined ? '2' : '1',
											id: location !== undefined ? location.state.result.id : undefined
										}
									});
								}}>
								{t('loan.editData')}
							</Button>
						</Card>
					</div>
				</div>
			</>
		);
	} else {
		return (
			<>
				{textViabilitytitle && textViability && (
					<div style={{ padding: 40 }}>
						<Row style={{ width: '100%' }}>
							<Col span={24}>
								<a onClick={() => renderBack()}>
									<ArrowLeftOutlined style={{ color: '#2F4858', fontSize: 20 }} />
								</a>
								<Text
									style={{
										color: '#2F4858',
										fontSize: 16,
										fontWeight: 600,
										marginLeft: 16
									}}>
									{t('onboarding.viabilityOnBanks')}
								</Text>
							</Col>
						</Row>

						<Card className="gb-card" style={{ marginTop: 30, textAlign: 'center' }}>
							<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>
								{t('topViability.viability')}
							</Text>
							<div style={{ marginTop: 32 }}>
								<Progress
									type="circle"
									percent={
										((location && location.state && location.state.result.simulationData
											? location.state.result.simulationData.resultViabilityMortage.viability
											: 0) *
											100) /
										5
									}
									width={120}
									strokeColor={'#52C41A'}
									trailColor={'#F1F7F8'}
									format={() => (
										<div>
											<Text
												strong
												style={{
													fontSize: '32px',
													color: '#2F4858',
													marginBottom: '-4px',
													display: 'grid',
													marginTop: 10
												}}>
												{location && location.state && location.state.result.simulationData
													? location.state.result.simulationData.resultViabilityMortage.viability.toFixed(
															1
													  )
													: 0}
											</Text>
											<Text
												style={{
													fontSize: '14px',
													color: '#2F4858',
													marginTop: -16
												}}>{`de 5`}</Text>
										</div>
									)}
								/>
							</div>

							<div
								style={{
									width: '100%',
									textAlign: '-webkit-center',
									marginTop: 32
								}}>
								<div style={{ maxWidth: 922 }}>
									<Text
										style={{
											color: '#52C41A',
											fontSize: 16,
											fontWeight: 600
										}}>
										{textViabilitytitle}&nbsp;
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 400
											}}>
											{textViability}
										</Text>
									</Text>
								</div>
							</div>

							<div style={{ marginTop: 32 }}>
								<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>
									{t('viability.whyDoIHaveThisViability')}
								</Text>
							</div>

							<hr color="#02C3CD" size={1} border={'1px solid #02C3CD'} style={{ width: 300 }}></hr>

							<Row gutter={[24, 24]} style={{ marginTop: 24 }}>
								{data.map((values) => {
									return (
										<Col xs={12} md={8} sm={8} lg={8} xl={8} xxl={6} style={{ textAlign: 'left' }}>
											<ViabilityComponent
												dataMortgage={location.state.result.simulationData}
												key={values.titulo}
												titulo={values.titulo}
												viable={values.viable}
												viabilityData={location.state.result.simulationData.resultViabilityMortage}
												ltv={location.state.result.simulationData.resultQuoteMortage.ltv}
												calculator={true}
											/>
										</Col>
									);
								})}
							</Row>
						</Card>

						<div style={{ marginTop: 26, marginBottom: 100 }}></div>
					</div>
				)}
			</>
		);
	}
};

export default CalculatorsFeeViabilityMobileAndDesktop;
