import { useState, useEffect } from 'react';
import { Card, Collapse, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Offer, Document } from '../Utils/types';
import axios from 'axios';
import env from '../../../../environment';
import DocumentationSection from './Sections/DocumentationSection';
import BankToOffersSection from './Sections/BankToOffersSection';
import { ReduxStore } from '../DocumentationCore';
import AppraisalDesktop from '../../appraisal/AppraisalDesktop';
import { isMobileOnly } from 'react-device-detect';
import AppraisalMobile from '../../appraisal/AppraisalMobile';
import FormalizationMobile from '../../Formalization/FormalizationMobile';
import FormalizationDesktop from '../../Formalization/FormalizationDesktop';
const { Text } = Typography;
const { Panel } = Collapse;

const styleCollapseDoc = {
	borderColor: 'white',
	backgroundColor: 'white',
	marginTop: 16,
	borderRadius: 8,
	boxShadow: '0px 0px 8px rgba(47, 72, 88, 0.1)'
};

const styleCollapseBanks = {
	marginTop: 16,
	borderRadius: 8,
	borderColor: '#ECF7FC',

};

const styleCollapseAppraisal = {
	marginTop: 16,
	borderRadius: 8,
	borderColor: '#B8DEEF'
};
const styleHeaderCollapse = {
	color: '#2F4858',
	fontSize: '14px',
	fontWeight: 600
};

interface Props {
	userMortgageId: string,
	stages: string,
	showAppraisal: boolean,
	setShowAppraisal: (value: boolean) => void
}

const DocumentationAccordions = ({ userMortgageId, stages, showAppraisal, setShowAppraisal }: Props) => {
	const { t } = useTranslation();
	const userToken = useSelector<ReduxStore, string>((state) => state.auth.token);
	const [numberDocuments, setNumberDocuments] = useState<number>(0);
	const [bankOffers, setBankOffers] = useState<Offer[]>(undefined);
	const [numberOffers, setNumberOffers] = useState<number>(0);
	const [documents, setDocuments] = useState<Document[]>([]);
	const [participants, setParticipants] = useState<any>([]);
	const numParticipants: number = parseInt(participants?.mortgage?.participants) || 0;
	const [dataMortgage, setDataMortgage] = useState<any>(undefined);
	const [openCollapse, setOpenCollapse] = useState<Record<string, boolean>>({
		1: stages === 'documents' ? true : false,
		2: stages === 'analysis' ? true : false,
		3: (stages === 'banks' || stages === 'oferts') ? true : false,
		4: (stages === 'appraisal' || stages === 'formalization') ? true : false,
	});

	const ownerList = [];
	for (let i = 1; i <= numParticipants; i++) {
		ownerList.push(`owner${i}`);
	}
	const documentsRequired = [];

	useEffect(() => {
		if (userMortgageId) {
			getDocuments();
			getParticipants();
			refreshData();
			getBankOffers();
		}
	}, []);

	useEffect(() => {
		if (ownerList.length > 0) {
			for (let i = 0; i < documents.length; i++) {
				for (let j = 0; j < ownerList.length; j++) {
					if (documents[i].participants[ownerList[j]] === 'optional' && documents[i].completed[ownerList[j]] === false) {
						documentsRequired.push(documents[i]);
					}
				}
			}
			setNumberDocuments(documentsRequired?.length);
		}
	}, [documents]);

	useEffect(() => {
		setOpenCollapse(() => ({
			1: stages === 'documents',
			2: stages === 'analysis',
			3: (stages === 'banks' || stages === 'oferts'),
			4: (stages === 'appraisal' || stages === 'formalization')
		}));
	}, [stages]);

	const getDocuments = () => {
		axios
			.get(`${env.api.url}/v1/documents/request-operation-documentation/${userMortgageId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setDocuments(response.data.data.documents);
			})
			.catch((error) => { console.log(error) });
	};

	const getParticipants = () => {
		axios
			.get(`${env.api.url}/v1/mortgages/data/get/${userMortgageId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setParticipants(response.data.data);
			})
			.catch((error) => { console.log(error) });
	};

	const getBankOffers = () => {
		axios
			.get(`${env.api.url}/banks/bank-offer/find-by-operation/${userMortgageId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setBankOffers(response.data.data);
				setNumberOffers(response.data.data.length)
			})
			.catch((error) => { console.log(error) });
	};

	const refreshData = () => {
		axios
			.get(`${env.api.url}/v1/operations/${userMortgageId}`, {
				headers: { Authorization: `Bearer ${userToken}` },
			})
			.then((response) => {
				setDataMortgage(response.data.data.operationData);
			});
	};

	const handleChangeCollapse = (key: string) => {
		setOpenCollapse((prevOpenCollapse) => {
			const updatedOpenCollapse = { ...prevOpenCollapse };
			updatedOpenCollapse[key] = !prevOpenCollapse[key];
			Object.keys(updatedOpenCollapse).forEach((k) => {
				if (k !== key) {
					updatedOpenCollapse[k] = false;
				}
			});
			return updatedOpenCollapse;
		});
	};

	return (
		<div style={{ marginTop: 24 }}>
			<Collapse
				accordion
				defaultActiveKey={stages === 'documents' ? [1] : undefined}
				activeKey={openCollapse[1] ? ['1'] : undefined}
				onChange={() => handleChangeCollapse('1')}
				expandIconPosition={'end'}
				style={styleCollapseDoc}>
				<Panel
					header={
						<div style={{ alignItems: 'center' }}>
							<Text style={styleHeaderCollapse}>{t('doc.priorityUpload')}</Text>
						</div>
					}
					key="1">
					<DocumentationSection
						userMortgageId={userMortgageId}
						type="priority"
						stages={stages}
						participants={participants}
						setDocuments={setDocuments} />
				</Panel>
			</Collapse>

			<Collapse
				accordion
				defaultActiveKey={stages !== 'analysis' ? undefined : ['1']}
				activeKey={openCollapse[2] ? ['1'] : undefined}
				onChange={() => handleChangeCollapse('2')}
				expandIconPosition={'end'}
				style={styleCollapseDoc}>
				<Panel
					header={
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
							<Text style={styleHeaderCollapse}>{t('doc.secundaryUpload')}</Text>
							{stages !== 'documents' && numberDocuments > 0 && (
								<Text style={{ fontSize: 12, fontWeight: 600, textAlign: 'right' }}>
									{!isMobileOnly
										? <div>{numberDocuments} {numberDocuments === 1 ? t('doc.pendingDoc') : t('doc.pendingDocs')}</div>
										: <div>{numberDocuments} {numberDocuments === 1 ? t('doc.pendingDocBrev') : t('doc.pendingDocsBrev')}</div>
									}
								</Text>
							)}
						</div>
					}
					key="1">
					<DocumentationSection
						userMortgageId={userMortgageId}
						type="aditional"
						stages={stages}
						participants={participants}
						setDocuments={setDocuments} />
				</Panel>
			</Collapse>


			<Collapse
				className="gb-collapse-oferts"
				accordion
				defaultActiveKey={(stages === 'appraisal' || stages === 'formalization' || stages === 'documents' || stages === 'analysis') ? undefined : ['1']}
				activeKey={openCollapse[3] ? ['1'] : undefined}
				onChange={() => handleChangeCollapse('3')}
				expandIconPosition={'end'}
				style={styleCollapseBanks}>
				<Panel
					header={
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
							<Text style={styleHeaderCollapse}>{t('m_offers.title')}</Text>
							{numberOffers >= 0 && (
								<Text style={{ fontSize: 12, fontWeight: 600 }}>
									{numberOffers} {numberOffers === 1 ? t('doc.offer') : t('doc.offers')}
								</Text>
							)}
						</div>
					}
					key="1">
					<BankToOffersSection
						userMortgageId={userMortgageId}
						stages={stages}
						bankOffers={bankOffers}
						getBankOffers={getBankOffers}
						setShowAppraisal={setShowAppraisal}
					/>
				</Panel>
			</Collapse>

			{(showAppraisal || stages === 'appraisal' || stages === 'formalization') && dataMortgage && (
				<Collapse
					className="gb-collapse-appraisal"
					accordion
					defaultActiveKey={(stages === 'appraisal' || stages === 'formalization') ? ['1'] : undefined}
					activeKey={openCollapse[4] ? ['1'] : undefined}
					onChange={() => handleChangeCollapse('4')}
					expandIconPosition={'end'}
					style={styleCollapseAppraisal}>
					<Panel
						header={
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
								<Text style={styleHeaderCollapse}>{t('finish.steps')}</Text>

							</div>
						}
						key="1">
						{showAppraisal || stages === 'appraisal' ?
							<Text style={{ fontWeight: 400, fontSize: 14, color: "#2F4858" }}>{t('finihsSteps.appraisal')}</Text>
							:
							<Text style={{ fontWeight: 400, fontSize: 14, color: "#2F4858" }}>{t('textFormalization.Finish')}</Text>
						}
						<Card className='gb-card-appraisal' style={{ marginTop: 16 }}>
							{!isMobileOnly && (stages === 'appraisal' || showAppraisal)
								? <AppraisalDesktop dataMortgage={dataMortgage} operationId={userMortgageId} />
								: (stages === 'appraisal' || showAppraisal) && isMobileOnly && <AppraisalMobile dataMortgage={dataMortgage} operationId={userMortgageId}></AppraisalMobile>
							}

							{!isMobileOnly && stages === 'formalization'
								? <FormalizationDesktop dataMortgage={dataMortgage} userMortgageId={userMortgageId} stages={stages} />
								: stages === 'formalization' && isMobileOnly && <FormalizationMobile dataMortgage={dataMortgage} userMortgageId={userMortgageId} stages={stages} />
							}

						</Card>
					</Panel>
				</Collapse>
			)}
		</div>
	);
};
export default DocumentationAccordions;
