import React, { useState, useEffect } from 'react';
import { Card, Typography, Col, Row, Drawer } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';
import env from '../../../../environment';
import numeral from 'numeral';
import check from '../../../../assets/loans/check.png';
import bulp from '../../../../assets/loans/bulp.png';
import checkBlack from '../../../../assets/loans/checkBlack.png';
import LoanComparativePageNodataDesktop from './LoanComparativePageNoDataDesktop';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { isMobileOnly } from 'react-device-detect';

const { Text } = Typography;

const TipsAutomatic = ({ loan }) => {
	const { t } = useTranslation();
	const [visible, setVisible] = useState(false);
	const [rateType, setRateType] = useState(undefined);
	const [tip, setTip] = useState(undefined);
	const dataProfile = useSelector((state) => state.data.dataProfile);
	const analytics = getAnalytics();
	const [loanTmp, setLoanTmp] = useState(loan);
	const userToken = useSelector((state) => state.auth.token);
	const dataSummary = useSelector((state) => state.data.dataSummary);
	const dataSummaryFilter = dataSummary.filter((data) => data.subtype === 'subrogation');
	useEffect(() => {
		getLoan();
	}, [visible]);

	useEffect(() => {
		loan?.tips.map((tip, i) => {
			if (i === 0 && dataSummaryFilter.length === 0) {
				if (tip.type === 'CHANGE_TO_WORSE_FIXED_RATE' && loan.rateType === 'variable') {
					handleClickCompare('noBetter', 'CHANGE_TO_WORSE_FIXED_RATE');
				} else if (tip.type === 'BETTER_VARIABLE_RATE' && loan.rateType === 'variable') {
					handleClickCompare('variable', 'BETTER_VARIABLE_RATE');
				} else if (tip.type === 'CHANGE_TO_FIXED_RATE' && loan.rateType === 'variable') {
					handleClickCompare('fixed', 'CHANGE_TO_FIXED_RATE');
				} else if (tip.type === 'BETTER_FIXED_RATE' && loan.rateType === 'fixed') {
					handleClickCompare('fixed', 'BETTER_FIXED_RATE');
				} else if (tip.type === 'CHANGE_TO_VARIABLE_RATE' && loan.rateType === 'fixed') {
					handleClickCompare('variable', 'CHANGE_TO_VARIABLE_RATE');
				} else if (tip.type === 'WORSE_VARIABLE_RATE' || tip.type === 'WORSE_FIXED_RATE') {
					return false;
				} else {
					return false;
				}
			}
		});
	}, []);

	const getLoan = () => {
		axios
			.get(`${env.api.url}/v1/loans/get-loan/${loan.id}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				// setDataValues(response.data.data);
				setLoanTmp(response.data.data.loan);
			})
			.catch((error) => {
                console.log(error)
            });
	};

	const handleClickCompare = (rateType, tip) => {
		setTip(tip);
		setRateType(rateType);
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
	};

	return (<>
        {loan &&
            loan.tips.map((tip, i) => {
                if (i === 0) {
                    if (tip.type === 'CHANGE_TO_WORSE_FIXED_RATE' && loan.rateType === 'variable') {
                        return (
                            <Col xs={24} md={24} sm={12} lg={12} xl={12} xxl={12} style={{ marginBottom: 24 }}>
                                <Card
                                    className="gb-card-loan-init-blue-data-no-better"
                                    style={{ height: '100%', display: 'grid' }}
                                    onClickCapture={() =>
                                        logEvent(analytics, 'screen_view', {
                                            screen_name:
                                                tip.type === 'CHANGE_TO_FIXED_RATE'
                                                    ? 'loanTip.variableToFixed'
                                                    : 'stabilityVariableToFixed'
                                        })
                                    }>
                                    <div className="card-tips-loan">
                                        <Row>
                                            <Col span={1} style={{ marginTop: 15 }}>
                                                <img src={bulp} alt="asdf" style={{ width: 24, height: 24 }}></img>
                                            </Col>
                                            <Col span={22} style={{ marginLeft: 12 }}>
                                                <div
                                                    style={{
                                                        color: '#2F4858',
                                                        fontSize: 14,
                                                        fontWeight: 600
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('m_loan.tipStabilityVariableToFixedTitle', {
                                                            name: dataProfile.name,
                                                            savings: numeral(tip.savings).format('0,0.00')
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} style={{ marginTop: 10, textAlign: 'right' }}>
                                                <Text
                                                    style={{
                                                        color: '#126CBF',
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() =>
                                                        handleClickCompare('noBetter', 'CHANGE_TO_WORSE_FIXED_RATE')
                                                    }>
                                                    {t('typeMortgageB.moreInfo')}
                                                </Text>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        );
                    } else if (tip.type === 'BETTER_VARIABLE_RATE' && loan.rateType === 'variable') {
                        return (
                            <Col xs={24} md={24} sm={12} lg={12} xl={12} xxl={12} style={{ marginBottom: 24 }}>
                                <Card
                                    className="gb-card-loan-init-green"
                                    onClickCapture={() =>
                                        logEvent(analytics, 'screen_view', {
                                            screen_name: 'loanTip.variableToVariable'
                                        })
                                    }
                                    style={{ height: '100%', display: 'grid' }}>
                                    <div className="card-tips-loan">
                                        <Row>
                                            <Col span={1}>
                                                <img src={check} alt="asdf" style={{ width: 24, height: 24 }}></img>
                                            </Col>
                                            <Col span={22} style={{ marginLeft: 12 }}>
                                                <div
                                                    style={{
                                                        color: '#2F4858',
                                                        fontSize: 14,
                                                        fontWeight: 600
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('m_loan.tipVariableToVariableTitle', {
                                                            name: dataProfile.name,
                                                            savings: numeral(tip.savings).format('0,0.00')
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} style={{ marginTop: 12, textAlign: 'right' }}>
                                                <Text
                                                    style={{
                                                        color: '#3E9314',
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => handleClickCompare('variable', 'BETTER_VARIABLE_RATE')}>
                                                    {t('typeMortgageB.moreInfo')}
                                                </Text>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        );
                    } else if (tip.type === 'CHANGE_TO_FIXED_RATE' && loan.rateType === 'variable') {
                        return (
                            <Col xs={24} md={24} sm={12} lg={12} xl={12} xxl={12} style={{ marginBottom: 24 }}>
                                <Card
                                    className="gb-card-loan-init-green"
                                    onClickCapture={() =>
                                        logEvent(analytics, 'screen_view', {
                                            screen_name: 'loanTip.variableToFixed'
                                        })
                                    }
                                    style={{ height: '100%', display: 'grid' }}>
                                    <div className="card-tips-loan">
                                        <Row>
                                            <Col span={1} style={{ marginTop: 12 }}>
                                                <img src={check} alt="asdf" style={{ width: 24, height: 24 }}></img>
                                            </Col>
                                            <Col span={22} style={{ marginLeft: 12 }}>
                                                <div
                                                    style={{
                                                        color: '#2F4858',
                                                        fontSize: 14,
                                                        fontWeight: 600
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('m_loan.tipVariableToFixedTitle', {
                                                            name: dataProfile.name,
                                                            savings: numeral(tip.savings).format('0,0.00')
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} style={{ marginTop: 10, textAlign: 'right' }}>
                                                <Text
                                                    style={{
                                                        color: '#3E9314',
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => handleClickCompare('fixed', 'CHANGE_TO_FIXED_RATE')}>
                                                    {t('typeMortgageB.moreInfo')}
                                                </Text>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        );
                    } else if (tip.type === 'WORSE_VARIABLE_RATE' || tip.type === 'WORSE_FIXED_RATE') {
                        return (
                            <Col xs={24} md={24} sm={12} lg={12} xl={12} xxl={12} style={{ marginBottom: 24 }}>
                                <Card
                                    className="gcardMovile"
                                    style={{
                                        border: '1px solid #C0DAEE',
                                        background: '#F1F7F8',
                                        marginTop: 16,
                                        borderRadius: 4,
                                        height: '100%',
                                        display: 'grid'
                                    }}>
                                    <div className="card-tips-loan">
                                        <Row>
                                            <Col span={1}>
                                                <img src={checkBlack} alt="asf" style={{ width: 24, height: 24 }} />
                                            </Col>
                                            <Col span={22} style={{ marginLeft: 12 }}>
                                                <Text
                                                    style={{
                                                        color: '#2F4858',
                                                        fontSize: 14,
                                                        fontWeight: 600
                                                    }}>
                                                    {dataProfile &&
                                                        dataProfile.name + ', ' + t('loan.detailsLoanOwnersAdvi')}
                                                </Text>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: 10, marginLeft: 12 }}>
                                            <Col span={1}></Col>
                                            <Col span={22}>
                                                <Text
                                                    style={{
                                                        color: '#2F4858',
                                                        fontSize: 14,
                                                        fontWeight: 400
                                                    }}>
                                                    {t('loan.detailsLoanOwnersAdvi1')}
                                                </Text>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        );
                    } else if (tip.type === 'BETTER_FIXED_RATE' && loan.rateType === 'fixed') {
                        return (
                            <Col xs={24} md={24} sm={12} lg={12} xl={12} xxl={12} style={{ marginBottom: 24 }}>
                                <Card
                                    className="gb-card-loan-init-green"
                                    onClickCapture={() =>
                                        logEvent(analytics, 'screen_view', {
                                            screen_name: 'loanTip.fixedToFixed'
                                        })
                                    }
                                    style={{ height: '100%', display: 'grid' }}>
                                    <div className="card-tips-loan">
                                        <Row>
                                            <Col span={1}>
                                                <img src={check} alt="asdf" style={{ width: 24, height: 24 }}></img>
                                            </Col>
                                            <Col span={22} style={{ marginLeft: 12 }}>
                                                <div
                                                    style={{
                                                        color: '#2F4858',
                                                        fontSize: 14,
                                                        fontWeight: 600
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('m_loan.tipFixedToFixedTitle', {
                                                            name: dataProfile.name,
                                                            savings: numeral(Math.abs(tip.savings)).format('0,0.00')
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} style={{ marginTop: 10, textAlign: 'right' }}>
                                                <Text
                                                    style={{
                                                        color: '#3E9314',
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => handleClickCompare('fixed', 'BETTER_FIXED_RATE')}>
                                                    {t('typeMortgageB.moreInfo')}
                                                </Text>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        );
                    } else if (tip.type === 'CHANGE_TO_VARIABLE_RATE' && loan.rateType === 'fixed') {
                        return (
                            <Col xs={24} md={24} sm={12} lg={12} xl={12} xxl={12} style={{ marginBottom: 24 }}>
                                <Card
                                    className="gb-card-loan-init-green"
                                    onClickCapture={() =>
                                        logEvent(analytics, 'screen_view', {
                                            screen_name: 'loanTip.fixedToVariable'
                                        })
                                    }
                                    style={{ height: '100%', display: 'grid' }}>
                                    <div className="card-tips-loan">
                                        <Row>
                                            <Col span={1}>
                                                <img src={check} alt="asdf" style={{ width: 24, height: 24 }}></img>
                                            </Col>
                                            <Col span={22} style={{ marginLeft: 12 }}>
                                                <div
                                                    style={{
                                                        color: '#2F4858',
                                                        fontSize: 14,
                                                        fontWeight: 600
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('m_loan.tipFixedToVariableTitle', {
                                                            name: dataProfile.name,
                                                            savings: numeral(tip.savings).format('0,0.00')
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} style={{ marginTop: 10, textAlign: 'right' }}>
                                                <Text
                                                    style={{
                                                        color: '#3E9314',
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() =>
                                                        handleClickCompare('variable', 'CHANGE_TO_VARIABLE_RATE')
                                                    }>
                                                    {t('typeMortgageB.moreInfo')}
                                                </Text>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        );
                    } else {
                        return false;
                    }
                }
            })}
        {tip && rateType && (
            <Drawer
                title={
                    <Text rootStyle={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>
                        {t('loan.initLoanComparative') + t(`loanType.${rateType}`)}
                    </Text>
                }
                placement="right"
                onClose={onClose}
                open={visible}
                rootClassName="gb-drawer-32px"
                width={isMobileOnly ? 380 : 700}>
                <LoanComparativePageNodataDesktop
                    loanIdProp={loanTmp.id}
                    rateTypeProp={rateType}
                    tipProp={tip}
                    open={() => setVisible(false)}></LoanComparativePageNodataDesktop>
            </Drawer>
        )}
    </>);
};

export default TipsAutomatic;
