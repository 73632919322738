import React, { useEffect, useState } from 'react';
import { Row, Button, Card, Skeleton, Col, Typography, Form, Select, Slider, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
	ArrowLeftOutlined,
	EuroCircleOutlined,
	InfoCircleOutlined,
	WarningOutlined
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { renderComponent } from '../../../../select/typeSelect';
import calculatorQuality from '../../../../assets/calculators/calculatorQuality.png';
import axios from 'axios';
import env from '../../../../environment';
import numeral from 'numeral';
import _ from 'lodash';
import CalculatorFinancingData from './CalculatorFinancingData';
import { isMobileOnly } from 'react-device-detect';
import CalculatorsFinancingMobile from './CalculatorsFinancingMobile';
import calculatorImage from '../../../../assets/calculators/calculatorImage.png';
import { InputNumber } from 'afrodita';
import MortgageAmountModal from '../Modals/MortgageAmountModal';
import ModalWithoutButtons from '../../../Financiero/Loans/Modals/ModalWithoutButtons';

const { Text } = Typography;
const { Option } = Select;

const CalculatorFinancing = ({}) => {
	const { t } = useTranslation();
	const rol = useSelector((state) => state.auth.roles);
	const navigate = useNavigate();;
	const [loading, setLoading] = useState(true);
	const [form] = Form.useForm();
	const [term, setTerm] = useState(false);
	const [amountHp, setAmountHp] = useState(false);
	const [rate, setRate] = useState(false);
	const [finish, setFinish] = useState(false);
	const dataProfile = useSelector((state) => state.data.dataProfile);
	const [result, setResult] = useState(undefined);
	const userToken = useSelector((state) => state.auth.token);
	const [fixed, setFixed] = useState(undefined);
	const [quotes, setQuotes] = useState(40 * 12);
	const [price, setPrice] = useState(10000);
	const [savings, setSavings] = useState(0);
	const [province, setProvince] = useState('Valencia');
	const [family, setFamily] = useState(false);
	const [houseType, setHouseType] = useState(undefined);
	const [priceTouch, setPriceTouch] = useState(undefined);
	const [euribor, setEuribor] = useState(undefined);
	const [fixedTmp, setFixedTmp] = useState(undefined);
	const [variableTmp, setVariableTmmp] = useState(undefined);
	const [euriborTmp, setEuriborTmp] = useState(undefined);
	const [timeoutReference, setTimeoutReference] = useState(undefined);
	const dataSummary = useSelector((state) => state.data.dataSummary);
	const [locationTmpText, setLocationTmpText] = useState(undefined);
	const [dataUltimateSimulation, setDataUltimateSimulation] = useState(undefined);
	const [idUltimateSimulation, setIdUltimateSimulation] = useState(undefined);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, [1500]);
		getEuribor();
		getFixAndVariable();

		if (dataSummary.length > 0) {
			getText();
		}
		getUltimateSimulation();
	}, []);

	useEffect(() => {
		if (variableTmp && euriborTmp && fixedTmp) {
			let varMasEur = variableTmp + euriborTmp;

			let total = varMasEur <= fixedTmp ? varMasEur : fixedTmp;

			setFixed(total);
		}
	}, [variableTmp && euriborTmp && fixedTmp]);

	const getUltimateSimulation = () => {
		axios
			.get(
				`${env.api.url}/mortgages/simulations/get-last-simulation?simulationMortgageType=ROOF_CALCULATION`,
				{
					headers: {
						Authorization: `Bearer ${userToken}`
					}
				}
			)
			.then((response) => {
				setDataUltimateSimulation(response.data.data);
				if (response.data?.data?.id) {
					setIdUltimateSimulation(response.data.data.id);
					setFixed(response.data.data.simulationData.fixed);
					firtsCallSimulation(response.data.data);
				}
			});
	};

	const firtsCallSimulation = (allValues) => {
		const objValues = {
			id: allValues.id,
			savings: allValues.simulationData.savings,
			propertyType: allValues.simulationData.propertyType,
			simulationType: 'B2C',
			numerousFamily: false,
			province: allValues.simulationData.province,
			maxBudget: allValues.simulationData.maxBudget,
			fixed: allValues.simulationData.fixed,
			quotes: allValues.simulationData.quotes,
			participants: {
				houseType: allValues.simulationData.houseType,
				participants: allValues.simulationData.owner2 ? 2 : 1,
				owners: [allValues.simulationData.owner1, allValues.simulationData.owner2]
			}
		};
		if (_.isNil(allValues.simulationData.owner2)) {
			objValues.participants.participants = 1;
			objValues.participants.owners = [allValues.simulationData.owner1];
		} else {
			objValues.participants.participants = 2;
			objValues.participants.owners = [
				allValues.simulationData.owner1,
				allValues.simulationData.owner2
			];
		}
		axios
			.post(
				`${env.api.url}/mortgages/simulations/client-qualification/mortgage-viability`,
				objValues,
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				console.log(response);
				setResult(response.data.data);
				if (typeof response.data.data === 'string') {
					setPrice(undefined);
				} else if (response.data.data.simulation.simulationData.maxBudget) {
					setPrice(response.data.data.simulation.simulationData.maxBudget);
				} else {
					setPrice(response.data.data?.bonilla[1]?.maxBudget);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const getText = () => {
		axios
			.post(
				`${env.api.url}/campaigns/runner/experiment/by-event`,
				{
					event: 'questions-mortgages',
					data: {
						operationId: dataSummary[0].operationId
					}
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {

                if (response.data.data.mortgageQuestions === '2022') {
                    if (dataSummary[0].stage === 'basic') {
                        setLocationTmpText(
                            `/testA/operations/${dataSummary[0].operationId}/${dataSummary[0].stage}`
                        );
                    }else {
                        setLocationTmpText(
                            `/operations/${dataSummary[0].operationId}/${dataSummary[0].stage}`
                        );
                    }
					
				} else {
                    if (dataSummary[0].stage === 'basic') {
                        setLocationTmpText(
                            `/testA/operations/${dataSummary[0].operationId}/${dataSummary[0].stage}`
                        );
                    }else {
                        setLocationTmpText(
                            `/operations/${dataSummary[0].operationId}/${dataSummary[0].stage}`
                        );
                    }
				}


			
			});
	};

	const getFixAndVariable = () => {
		axios
			.get(`${env.api.url}/v1/options/get-option/rate-gibobs-variable-tae`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				setVariableTmmp(response.data);
			});

		axios
			.get(`${env.api.url}/v1/options/get-option/rate-gibobs-fixed-tae`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				setFixedTmp(response.data);
			});

		axios
			.get(`${env.api.url}/v1/options/get-option/euribor`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				setEuriborTmp(response.data);
			});
	};

	const getEuribor = () => {
		axios
			.get(`${env.api.url}/v1/rates/by-month/euribor`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setEuribor(response.data.data.rates);
			})
			.catch((error) => {
                console.log(error)
            });
	};

	const sendFinish = () => {
		//envio a a la operacion con el test ab

		setFinish(false);
		createMortgage();
	};

	const createMortgage = () => {
		axios
			.post(
				`${env.api.url}/mortgages/simulations/client-qualification/create-operation-mortgage/${
					idUltimateSimulation !== undefined ? idUltimateSimulation : result?.simulation?.id
				}`,
				{},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					const id = response.data.data.operationId;
					axios
						.post(
							`${env.api.url}/campaigns/runner/experiment/by-event`,
							{
								event: 'questions-mortgages',
								data: {
									operationId: id
								}
							},
							{
								headers: { Authorization: `Bearer ${userToken}` }
							}
						)
						.then((response) => {
							if (response.data.data.mortgageQuestions === '2022') {
								navigate(`/testA/operations/${id}/basic`);
							} else {
								navigate(`/operations/${id}/basic`);
							}
						});
				}
			});
	};

	const onChangeFixed = (value) => {
		setFixed(value);
		const tmp = form.getFieldsValue();
		const tmpValues = {
			fixed: value,
			quotes: undefined
		};
		let priceTMP = undefined;
		if (priceTouch) {
			priceTMP = priceTouch;
		} else {
			priceTMP = price;
		}

		onChangeForm(tmpValues, tmp, priceTMP);
	};

	const onChangeHouseType = (value) => {
		setHouseType(value);
	};

	const onChangePrice = (value) => {
		setPriceTouch(value);
		const tmp = form.getFieldsValue();

		const price = value;
		const tmpValues = {
			fixed: undefined,
			quotes: undefined
		};
		onChangeForm(tmpValues, tmp, price);
	};

	const onChangeTerm = (value) => {
		setQuotes(value * 12);
		const tmp = form.getFieldsValue();
		const tmpValues = {
			fixed: undefined,
			quotes: value * 12
		};
		let priceTMP = undefined;

		if (priceTouch) {
			priceTMP = priceTouch;
		} else {
			priceTMP = price;
		}
		onChangeForm(tmpValues, tmp, priceTMP);
	};

	const onChangeForm = (values, allValues, price) => {
		if (allValues !== undefined) {
			setSavings(allValues.savings);
			setProvince(allValues.province);
		}

		if (_.isNil(price)) {
			price = 10000;
		}

		let houseTypeTmp = allValues.houseType;

		if (timeoutReference) {
			clearTimeout(timeoutReference);
		}

		setTimeoutReference(
			setTimeout(() => {
				if (result !== undefined && price >= 10000) {
					let owners = [];
					owners = [result.simulation.simulationData.owner1];

					const objValues = {
						id: idUltimateSimulation
							? idUltimateSimulation
							: !_.isNil(result)
							? result.simulation.id
							: undefined,
						simulationType: 'B2C',
						savings: !_.isNil(allValues?.savings)
							? allValues.savings
							: result.simulation.simulationData.savings,
						propertyType:
							allValues?.propertyType !== undefined
								? allValues.propertyType
								: result.simulation.simulationData.propertyType,
						numerousFamily: false,
						province:
							allValues?.province !== undefined
								? allValues.province
								: result.simulation.simulationData.province,
						maxBudget: price > 10000 ? price : priceTouch,
						fixed: fixed !== 1.9 ? fixed : result.simulation.simulationData.fixed,
						quotes: quotes !== 360 ? quotes : result.simulation.simulationData.quotes,

						participants: {
							houseType: !_.isNil(houseTypeTmp)
								? houseTypeTmp
								: result.simulation.simulationData.houseType,
							participants: 2,
							owners: [
								result.simulation.simulationData.owner1,
								result.simulation.simulationData.owner2
							]
						}
					};
					if (_.isNil(result.simulation.simulationData.owner2)) {
						objValues.participants.participants = 1;
						objValues.participants.owners = owners;
					} else {
						objValues.participants.participants = 2;
						objValues.participants.owners = [
							result.simulation.simulationData.owner1,
							result.simulation.simulationData.owner2
						];
					}
					axios
						.post(
							`${env.api.url}/mortgages/simulations/client-qualification/mortgage-viability`,
							objValues,
							{
								headers: { Authorization: `Bearer ${userToken}` }
							}
						)
						.then((response) => {
							console.log(response);
							setResult(response.data.data);
							if (typeof response.data.data === 'string') {
								setPrice(undefined);
							} else {
								setPrice(response.data.data?.bonilla[1]?.maxBudget);
							}
						})
						.catch((error) => {
							console.error(error);
						});
				}
				clearTimeout(timeoutReference);
			}, 500)
		);
	};

	const renderTitle = () => {
		if (result.viability.excepcionalViability === 'NOSAVINGS' && rol === 'realestate') {
			return t('onboarding.cantBuyWithoutSavingsInmo');
		} else if (result.viability.excepcionalViability === 'NOSAVINGS' && rol !== 'realestate') {
			return t('onboarding.cantBuyWithoutSavings');
		} else if (result.viability.excepcionalViability === 'DTI_OVER' && rol === 'realestate') {
			return t('onboarding.cantBuyWithoutSavingsInmo');
		} else if (result.viability.excepcionalViability === 'DTI_OVER' && rol !== 'realestate') {
			return t('calculator.warnig3');
		} else if (result.viability.excepcionalViability === 'INCOME_LEVEL' && rol === 'realestate') {
			return t('onboarding.cantBuyWithoutSavingsInmo');
		} else if (result.viability.excepcionalViability === 'INCOME_LEVEL' && rol !== 'realestate') {
			return t('calculatorFinancing.warnig5');
		} else {
			if (result.viability.excepcionalViability === 'MORTGAGE_AMOUNT') {
				return t('calculator.mortgageAmountTooLowTitle');
			}
		}
	};

	const renderDesc = () => {
		if (result.viability.excepcionalViability === 'NOSAVINGS' && rol === 'realestate') {
			return t('onboarding.cantBuyWithoutSavingsDescriptionInmo');
		} else if (result.viability.excepcionalViability === 'NOSAVINGS' && rol !== 'realestate') {
			return t('onboarding.cantBuyWithoutSavingsDescription');
		} else if (result.viability.excepcionalViability === 'DTI_OVER' && rol === 'realestate') {
			return t('calculator.warnig4Inmo');
		} else if (result.viability.excepcionalViability === 'DTI_OVER' && rol !== 'realestate') {
			return t('calculator.warnig4');
		} else if (result.viability.excepcionalViability === 'INCOME_LEVEL' && rol === 'realestate') {
			return t('calculator.warnig6Inmo');
		} else if (result.viability.excepcionalViability === 'INCOME_LEVEL' && rol !== 'realestate') {
			return t('calculatorFinancing.warnig6');
		} else if (
			result.viability.excepcionalViability === 'MORTGAGE_AMOUNT' &&
			rol === 'realestate'
		) {
			return t('calculator.mortgageAmountTooLowDescriptionFinancing');
		} else if (
			result.viability.excepcionalViability === 'MORTGAGE_AMOUNT' &&
			rol !== 'realestate'
		) {
			return t('calculator.mortgageAmountTooLowDescriptionFinancing');
		}
	};

	const renderSliderFix = () => {
		let varMasEur = variableTmp + euriborTmp;

		let total = varMasEur <= fixedTmp ? varMasEur : fixedTmp;
		return total;
	};

	const renderBarProgress = () => {
	

		return (
			<>
				<div
					style={{
						width: 300,
						display: 'flex',
						marginTop: 24,
						marginBottom: 10
					}}>
					<div
						style={{
							width: '85%' //`${percentOne}%`,
						}}></div>
					<Text
						style={{
							color: '#748EA0',
							fontSize: 12,
							fontWeight: 400,
							width: '50%',
							marginLeft:
								result?.bonilla[2].maxBudget === result?.bonilla[1].maxBudget ? -50 : undefined
						}}>
						{numeral(result?.bonilla[1].maxBudget).format('0,0 $')}
					</Text>
					<div
						style={{
							width: '25%' //`${percentSecond}%`,
						}}></div>
					{result?.bonilla[2].maxBudget !== result?.bonilla[1].maxBudget ? (
						<Text
							style={{
								color: '#748EA0',
								fontSize: 12,
								fontWeight: 400,
								width: '50%'
							}}>
							{numeral(result?.bonilla[2].maxBudget).format('0,0 $')}
						</Text>
					) : undefined}
					<div
						style={{
							width: '25%' //`${total}%`,
						}}></div>
				</div>

				<div style={{ width: 300, display: 'flex' }}>
					<div
						style={{
							width: '50%', //`${percentOne}%`,
							background: '#95DE64',
							height: '5px',
							borderRadius: '8px 0px 0px 8px'
						}}></div>
					<Text style={{ color: '#748EA0', marginTop: -10, marginRight: -1 }}>|</Text>

					<div
						style={{
							width: '25%', //`${percentSecond}%`,
							background:
								result?.bonilla[2].maxBudget === result?.bonilla[1].maxBudget
									? '#FF4D4F'
									: '#FAAD14',
							height: '5px'
						}}></div>
					{result?.bonilla[2].maxBudget !== result?.bonilla[1].maxBudget ? (
						<Text style={{ color: '#748EA0', marginTop: -10, marginRight: -1 }}>|</Text>
					) : undefined}
					<div
						style={{
							width: '25%', //`${total}%`,
							background: '#FF4D4F',
							height: '5px',
							borderRadius: '0px 8px 8px 0px'
						}}></div>
				</div>
			</>
		);
	};

	const renderResult = () => {
		let value = undefined;
		if (price > 10000) {
			value = price.toFixed('0');
		} else {
			if (result === undefined) {
				value = '';
			} else {
				value = numeral(_.get(result, 'simulationData.maxBudget', 0)).format('0,0');
			}
		}

		return value;
	};

	const renderMax = () => {
		if (result) {
			if (result.simulation) {
				const maxAge2 = result.simulation.simulationData.owner2
					? result.simulation.simulationData.owner2.age
					: 0;
				const maxAge1 = result.simulation.simulationData.owner1
					? result.simulation.simulationData.owner1.age
					: 0;

				const tmpAge = maxAge2 >= maxAge1 ? maxAge2 : maxAge1;

				const tmpAgeMin = 75 - tmpAge;

				const ageValue = tmpAgeMin <= 40 ? tmpAgeMin : 40;
				if (ageValue === 0) {
					return 5;
				}

				return ageValue;
			}
		}
	};

	return (<>
        {isMobileOnly && (
            <CalculatorsFinancingMobile
                locationTmpText={locationTmpText}
                idUltimateSimulation={idUltimateSimulation}
                createMortgage={createMortgage}></CalculatorsFinancingMobile>
        )}
        {!isMobileOnly && (
            <div style={{ padding: 40 }}>
                <Row style={{ width: '100%' }}>
                    <Col span={24}>
                        <a onClick={() => navigate('/calculators')}>
                            <ArrowLeftOutlined style={{ color: '#2F4858', fontSize: 20 }} />
                        </a>
                        <Text
                            style={{
                                color: '#2F4858',
                                fontSize: 16,
                                fontWeight: 600,
                                marginLeft: 16
                            }}>
                            {t('calculatorFinancing.title')}
                        </Text>
                    </Col>
                </Row>

                <Card className="gb-card" style={{ marginTop: 24, marginBottom: 100 }}>
                    <Row gutter={[24, 24]}>
                        <Col span={10}>
                            <Card
                                className="gb-card-24px"
                                cover={<img src={calculatorQuality} alt="asdf"></img>}
                                style={{
                                    border: '1px solid #C0F0F3',
                                    borderRadius: '0px 0px 4px 4px',
                                    background: '#EFFCFC'
                                }}>
                                <Skeleton active loading={loading} paragraph={false} />

                                {!loading && (
                                    <Text
                                        style={{
                                            color: '#02C3CD',
                                            fontSize: 16,
                                            fontWeight: 600
                                        }}>
                                        {t('fee.calculatorTop')}
                                    </Text>
                                )}
                                {!loading && (
                                    <div>
                                        <Form
                                            labelAlign="left"
                                            colon={false}
                                            className="gb-form-profile-headlines-mobile"
                                            labelCol={{
                                                flex: !isMobileOnly ? '50%' : '40%'
                                            }}
                                            wrapperCol={{
                                                flex: isMobileOnly && '60%'
                                            }}
                                            layout={'horizontal'}
                                            scrollToFirstError={true}
                                            form={form}
                                            name="basic"
                                            onValuesChange={(values, allValues) => onChangeForm(values, allValues)}
                                            initialValues={{
                                                province:
                                                    dataUltimateSimulation?.simulationData?.province !== undefined
                                                        ? dataUltimateSimulation?.simulationData?.province
                                                        : dataProfile && dataProfile.province,
                                                savings: dataUltimateSimulation?.simulationData?.savings,
                                                propertyType: dataUltimateSimulation?.simulationData?.propertyType,
                                                houseType: dataUltimateSimulation?.simulationData?.houseType
                                            }}>
                                            <Row
                                                style={{
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto',
                                                    marginTop: 16
                                                }}>
                                                <Col span={24}>
                                                    <Form.Item name="savings" label={t('form.Savings')}>
                                                        <InputNumber
                                                            defaultValue={''}
                                                            id={'savings'}
                                                            gbType="currency"
                                                            gbDecimalType="integer"
                                                            placeholder={'0'}
                                                            suffix={'€'}
                                                            min={0}
                                                            max={99000000}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item name="province" label={t('viability.provinceViability')}>
                                                        <Select
                                                            placeholder={t('form.selected')}
                                                            style={{ width: '100%' }}
                                                            id={'province'}
                                                            showSearch>
                                                            {renderComponent('province').options.map((province) => {
                                                                return (
                                                                    <Option key={province.value} value={province.value}>
                                                                        {province.label}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item name="propertyType" label={t('form.propertyType')}>
                                                        <Select
                                                            placeholder={t('form.select')}
                                                            id={'propertyType'}
                                                            style={{ width: '100%' }}>
                                                            <Option value="newConstruction">{t('form.newBuild')}</Option>
                                                            <Option value="secondHand">{t('form.secondHand')}</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item name="houseType" label={t('viability.expectedUse')}>
                                                        <Select
                                                            onChange={(e) => onChangeHouseType(e)}
                                                            placeholder={t('form.select')}
                                                            id={'houseType'}
                                                            style={{ width: '100%', height: 40 }}>
                                                            <Option value="main">{t('form.main')}</Option>
                                                            <Option value="second">{t('form.second')}</Option>
                                                            <Option value="investor">{t('form.investor')}</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                {/*<Col span={24}>
                                                    <Form.Item name="numerousFamily" label={t('¿Eres familia numerosa?')}>
                                                        <Select
                                                            placeholder={t('form.selected')}
                                                            id={'numerousFamily'}
                                                            style={{ width: '100%', height: 40 }}>
                                                            <Option value={true}>{t('form.yes')}</Option>
                                                            <Option value={false}>{t('form.no')}</Option>
                                                        </Select>
                                                    </Form.Item>
                                                        </Col>*/}
                                            </Row>

                                            <div
                                                style={{
                                                    background: '#FFFFFF',
                                                    borderRadius: 4,
                                                    paddingLeft: 16,
                                                    paddingTop: 16,
                                                    paddingRight: 16
                                                }}>
                                                <CalculatorFinancingData
                                                    ultimateDataSimulation={dataUltimateSimulation}
                                                    idUltimateSimulation={idUltimateSimulation}
                                                    setResult={setResult}
                                                    valuesForm={form.getFieldsValue()}
                                                    price={price}
                                                    savings={savings}
                                                    province={province}
                                                    family={family}
                                                    fixed={fixed}
                                                    quotes={quotes}
                                                    houseType={houseType}
                                                    result={result}
                                                    setPrice={setPrice}
                                                    priceTouch={priceTouch}></CalculatorFinancingData>
                                            </div>
                                        </Form>
                                    </div>
                                )}
                            </Card>
                        </Col>
                        <Col span={14} style={{ textAlign: 'center' }}>
                            <Skeleton active loading={loading} paragraph={false} />
                            {!loading && (
                                <>
                                    {rol === 'realestate' && (
                                        <div>
                                            <Text
                                                style={{
                                                    color: '#2F4858',
                                                    fontWeight: 600,
                                                    fontSize: 20
                                                }}>
                                                {t('qualify.titlte')}
                                            </Text>
                                        </div>
                                    )}

                                    {result &&
                                        result?.mortgageCeiling &&
                                        result?.mortgageCeiling.mortgageAmountLimit === 'savings' && (
                                            <Card className="gb-card-background-blue" style={{ marginTop: 24 }}>
                                                <div>
                                                    <EuroCircleOutlined style={{ color: '#02C3CD', fontSize: 32 }} />
                                                </div>
                                                <div style={{ marginTop: 10 }}>
                                                    <Text
                                                        style={{
                                                            color: '#2F4858',
                                                            fontSize: 16,
                                                            fontWeight: 600
                                                        }}>
                                                        {t('qualify.warnignLimitFinancing')}
                                                    </Text>
                                                </div>
                                                <div style={{ marginTop: 8 }}>
                                                    <Text
                                                        style={{
                                                            color: '#748EA0',
                                                            fontSize: 16,
                                                            fontWeight: 400
                                                        }}>
                                                        {t('qualify.warnignLimit2')}
                                                    </Text>
                                                </div>
                                            </Card>
                                        )}

                                    {result &&
                                        result?.mortgageCeiling &&
                                        result?.mortgageCeiling?.mortgageAmountLimit !== 'savings' && (
                                            <Card className="gb-card-background-blue" style={{ marginTop: 24 }}>
                                                <div>
                                                    <EuroCircleOutlined style={{ color: '#02C3CD', fontSize: 32 }} />
                                                </div>
                                                <div style={{ marginTop: 10 }}>
                                                    <Text
                                                        style={{
                                                            color: '#2F4858',
                                                            fontSize: 16,
                                                            fontWeight: 600
                                                        }}>
                                                        {t('qualify.warnignLimit3')}
                                                    </Text>
                                                </div>
                                                <div style={{ marginTop: 8 }}>
                                                    <Text
                                                        style={{
                                                            color: '#748EA0',
                                                            fontSize: 16,
                                                            fontWeight: 400
                                                        }}>
                                                        {t('qualify.warnignLimit4')}
                                                    </Text>
                                                </div>
                                            </Card>
                                        )}

                                    <div
                                        style={{
                                            marginTop:
                                                rol === 'realestate'
                                                    ? 40
                                                    : result?.mortgageCeiling?.mortgageAmountLimit
                                                    ? 24
                                                    : 0
                                        }}>
                                        <Text
                                            style={{
                                                color: '#2F4858',
                                                fontWeight: 600,
                                                fontSize: 16
                                            }}>
                                            {rol === 'realestate'
                                                ? t('qualify.titlte2')
                                                : t('calculatorFinancing.home')}
                                        </Text>
                                    </div>
                                    <div style={{ marginTop: 24, textAlign: '-webkit-center' }}>
                                        {result && result?.bonilla && result?.bonilla && renderBarProgress()}
                                        {result && result.bonilla === undefined && (
                                            <>
                                                <Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>
                                                    {'0 €'}
                                                </Text>
                                                <div
                                                    style={{
                                                        width: 300, //`${percentOne}%`,
                                                        background: '#FF4D4F',
                                                        height: '5px',
                                                        borderRadius: '8px 0px 0px 8px'
                                                    }}></div>
                                            </>
                                        )}
                                    </div>

                                    {result?.bonilla !== undefined && (
                                        <div
                                            style={{
                                                marginTop: 30,
                                                justifyContent: 'center',
                                                display: 'grid'
                                            }}>
                                            <div style={{ display: 'flex' }}>
                                                <Text
                                                    style={{
                                                        color: '#52C41A',
                                                        fontSize: 16,
                                                        fontWeight: 600
                                                    }}>
                                                    {result && result?.bonilla !== undefined
                                                        ? t('qualify.until') +
                                                          ' ' +
                                                          numeral(result?.bonilla[1].maxBudget).format('0,0 $')
                                                        : t('qualify.until') + ' ' + '-€'}
                                                </Text>
                                                <Text
                                                    style={{
                                                        color: '#748EA0',
                                                        fontSize: 16,
                                                        fontWeight: 400,
                                                        marginLeft: 16
                                                    }}>
                                                    {rol === 'realestate'
                                                        ? t('qualify.bankNoProblem')
                                                        : t('qualify.bankNoProblemClient')}
                                                </Text>
                                            </div>

                                            {result?.bonilla[1]?.maxBudget !== result?.bonilla[2]?.maxBudget && (
                                                <div style={{ display: 'flex' }}>
                                                    <Text
                                                        style={{
                                                            color: '#FAAD14',
                                                            fontSize: 16,
                                                            fontWeight: 600
                                                        }}>
                                                        {result && result?.bonilla !== undefined
                                                            ? t('qualify.until') +
                                                              ' ' +
                                                              numeral(result?.bonilla[2].maxBudget).format('0,0 $')
                                                            : t('qualify.until') + ' ' + '-€'}
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            color: '#748EA0',
                                                            fontSize: 16,
                                                            fontWeight: 400,
                                                            marginLeft: 16
                                                        }}>
                                                        {rol === 'realestate'
                                                            ? t('qualify.bankProblem')
                                                            : t('qualify.bankProblemClient')}
                                                    </Text>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </>
                            )}

                            <Card
                                style={{
                                    borderRadius: 4,
                                    background: '#F1F7F8',
                                    marginTop: 40,
                                    textAlign: '-webkit-center'
                                }}>
                                <div style={{ width: 328 }}>
                                    <Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>
                                        {t('financing.mobileQuote')}
                                    </Text>

                                    {!loading && (
                                        <Row style={{ width: '100%', marginTop: 24 }}>
                                            <Col span={12} style={{ marginTop: 10, textAlign: 'left' }}>
                                                <Text
                                                    style={{
                                                        color: '#2F4858',
                                                        fontSize: 14,
                                                        fontWeight: 400
                                                    }}>
                                                    {t('qualify.price')}
                                                </Text>
                                            </Col>
                                            <Col span={12} style={{ textAlign: 'right' }}>
                                                <InputNumber
                                                    defaultValue={renderResult()}
                                                    onChange={(value) => onChangePrice(value)}
                                                    placeholder={renderResult()}
                                                    gbType="currency"
                                                    suffix={'€'}
                                                    min={10000}
                                                />
                                            </Col>
                                        </Row>
                                    )}

                                    {!loading && (
                                        <Row style={{ width: '100%', marginTop: 32 }}>
                                            <Col span={9} style={{ marginTop: 16, textAlign: 'left' }}>
                                                <Text
                                                    style={{
                                                        color: '#2F4858',
                                                        fontSize: 14,
                                                        fontWeight: 400
                                                    }}>
                                                    {t('loan.rate')}
                                                </Text>
                                                <InfoCircleOutlined
                                                    onClick={() => setRate(true)}
                                                    style={{ color: '#02C3CD', marginLeft: 8 }}
                                                />

                                                {euribor && (
                                                    <div style={{ marginTop: 8 }}>
                                                        <Text
                                                            style={{
                                                                color: '#016B71',
                                                                fontSize: 12,
                                                                fontWeight: 400
                                                            }}>
                                                            {t('fee.calculatorEuribor') +
                                                                ' ' +
                                                                euribor[euribor.length - 1].index.toFixed(2) +
                                                                '%'}
                                                        </Text>
                                                    </div>
                                                )}
                                            </Col>
                                            <Col
                                                span={15}
                                                style={{
                                                    textAlign: 'right',
                                                    marginTop: 12,
                                                    display: 'flex'
                                                }}>
                                                {fixedTmp && variableTmp && euriborTmp && (
                                                    <>
                                                        <Slider
                                                            onChange={(value) => onChangeFixed(value)}
                                                            step={0.01}
                                                            defaultValue={renderSliderFix()}
                                                            min={0}
                                                            max={10}
                                                            style={{ width: 180, marginLeft: -30 }}
                                                        />
                                                        <Text
                                                            style={{
                                                                color: '#016B71',
                                                                fontSize: 12,
                                                                fontWeight: 400,
                                                                marginTop: 5,
                                                                marginLeft: 12
                                                            }}>
                                                            {fixed !== 1.9 ? fixed + '%' : renderSliderFix() + '%'}
                                                        </Text>
                                                    </>
                                                )}
                                            </Col>
                                        </Row>
                                    )}

                                    <Skeleton active loading={loading} paragraph={false} />

                                    {!loading && (
                                        <Row style={{ width: '100%', marginTop: 45 }}>
                                            <Col span={8} style={{ marginTop: 0, textAlign: 'left' }}>
                                                <Text
                                                    style={{
                                                        color: '#2F4858',
                                                        fontSize: 14,
                                                        fontWeight: 400
                                                    }}>
                                                    {t('loan.term')}
                                                </Text>
                                                <InfoCircleOutlined
                                                    onClick={() => setTerm(true)}
                                                    style={{ color: '#02C3CD', marginLeft: 8 }}
                                                />
                                            </Col>
                                            <Col span={16} style={{ textAlign: 'right', display: 'flex' }}>
                                                <Slider
                                                    onChange={(value) => onChangeTerm(value)}
                                                    min={renderMax() === undefined ? 40 : 5}
                                                    defaultValue={renderMax()}
                                                    max={renderMax()}
                                                    style={{ width: 180, marginLeft: -20 }}
                                                    tooltip={{
                                                        formatter: (value) => `${value} ${t('form.years')}`
                                                    }}
                                                />
                                                <Text
                                                    style={{
                                                        color: '#016B71',
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        marginTop: 5,
                                                        marginLeft: 5
                                                    }}>
                                                    {quotes !== 40 * 12
                                                        ? quotes / 12 + ' ' + t('form.years')
                                                        : result !== undefined
                                                        ? renderMax() + ' ' + t('form.years')
                                                        : quotes / 12 + ' ' + t('form.years')}
                                                </Text>
                                            </Col>
                                        </Row>
                                    )}
                                    <div style={{ width: '100%', marginTop: 40 }}>
                                        <Skeleton active loading={loading} paragraph={false} />

                                        {!loading && (
                                            <>
                                                <Row>
                                                    <Col span={12} style={{ textAlign: 'left' }}>
                                                        <Text
                                                            style={{
                                                                color: '#748EA0',
                                                                fontWeight: 400,
                                                                fontSize: 14
                                                            }}>
                                                            {t('qualify.priceMortgage')}
                                                        </Text>
                                                        <InfoCircleOutlined
                                                            onClick={() => setAmountHp(true)}
                                                            style={{ color: '#02C3CD', marginLeft: 8 }}
                                                        />
                                                    </Col>
                                                    <Col span={12} style={{ textAlign: 'right' }}>
                                                        <Text
                                                            style={{
                                                                color: '#2F4858',
                                                                fontWeight: 600,
                                                                fontSize: 16
                                                            }}>
                                                            {result && result.quote
                                                                ? numeral(
                                                                        result.quote.operation.mortgageAmount < 0
                                                                            ? 0
                                                                            : result.quote.operation.mortgageAmount
                                                                  ).format('0,0 $')
                                                                : '- €'}
                                                        </Text>
                                                    </Col>
                                                </Row>

                                                <Row style={{ marginTop: 20 }}>
                                                    <Col span={12} style={{ textAlign: 'left' }}>
                                                        <Text
                                                            style={{
                                                                color: '#748EA0',
                                                                fontWeight: 400,
                                                                fontSize: 14
                                                            }}>
                                                            {t('calculatorFinancing.result')}
                                                        </Text>
                                                    </Col>
                                                    <Col span={12} style={{ textAlign: 'right' }}>
                                                        <Text
                                                            style={{
                                                                color: '#02C3CD',
                                                                fontWeight: 600,
                                                                fontSize: 20
                                                            }}>
                                                            {result && result.quote
                                                                ? numeral(
                                                                        result.quote.operation.types.fixed.quote < 0
                                                                            ? 0
                                                                            : result.quote.operation.types.fixed.quote
                                                                  ).format('0,0 $') +
                                                                  '/' +
                                                                  t('viability.monthsEstimated')
                                                                : '-' + t('common.euroAtMonth')}
                                                        </Text>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    </div>
                                </div>

                                {/**warnign mas casos TODO */}
                                {result &&
                                    result.viability &&
                                    result.viability.excepcionalViability !== undefined && (
                                        <Card
                                            className="card-second"
                                            style={{
                                                border: '1px solid #02C3CD',
                                                borderRadius: 4,
                                                marginTop: 40
                                            }}>
                                            <div style={{ textAlign: 'center' }}>
                                                <WarningOutlined style={{ color: '#02C3CD', fontSize: 40 }} />
                                            </div>

                                            <div style={{ textAlign: 'center', marginTop: 8 }}>
                                                <Text
                                                    style={{
                                                        color: '#2F4858',
                                                        fontWeight: 500,
                                                        fontSize: 16
                                                    }}>
                                                    {result && result.viability && renderTitle()}
                                                </Text>
                                            </div>
                                            <div style={{ textAlign: 'center', marginTop: 8 }}>
                                                <Text
                                                    style={{
                                                        color: '#748EA0',
                                                        fontWeight: 400,
                                                        fontSize: 16
                                                    }}>
                                                    {result && result.viability && renderDesc()}
                                                </Text>
                                            </div>
                                        </Card>
                                    )}

                                {dataSummary.length > 0 && (
                                    <Card className="card-financing-second" style={{ marginTop: 40 }}>
                                        <Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>
                                            {t('calculatorFinancing.mortgage')}
                                        </Text>
                                        <div>
                                            <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 500 }}>
                                                {t('calculatorFinancing.mortgage1')}
                                            </Text>
                                            &nbsp;
                                            <Text
                                                style={{
                                                    color: '#02C3CD',
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => navigate(locationTmpText)}>
                                                {t('calculatorFinancing.mortgage2')}
                                            </Text>
                                            &nbsp;
                                            <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 500 }}>
                                                {t('calculatorFinancing.mortgage3')}
                                            </Text>
                                        </div>
                                    </Card>
                                )}

                                {result && result.viability && (
                                    <Button
                                        id="gb-mortgage-buy-situation-mobile"
                                        className="button-primary-gibobs-inmo"
                                        type="primary"
                                        style={{
                                            fontSize: 14,
                                            height: 40,
                                            fontWeight: 600,
                                            marginTop: 40
                                        }}
                                        onClick={() => {
                                            if (
                                                result !== undefined &&
                                                result.viability.excepcionalViability === undefined
                                            ) {
                                                setFinish(true);
                                            }
                                        }}
                                        disabled={
                                            result?.viability?.excepcionalViability !== undefined ||
                                            dataSummary.length > 0
                                                ? true
                                                : false
                                        }>
                                        {t('calculatorFinancing.button')}
                                    </Button>
                                )}
                            </Card>
                        </Col>
                    </Row>
                </Card>
            </div>
        )}
        <Modal
            className="modal-promoter ModalCommon"
            okButtonProps={{
                className: 'button-primari-gibobs',
                style: { width: '94%' }
            }}
            open={finish}
            width={480}
            title={<img src={calculatorImage} alt="financing" width={'100%'} height="90px" />}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={() => setFinish(false)}
            onOk={() => sendFinish()}>
            <div style={{ marginBottom: 0, marginTop: 30 }}>
                <Text strong>{t('modal6.titleGood') + ' ' + dataProfile.name}</Text>
            </div>
            <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                {t('calculator.modalFinish')}
            </Text>
        </Modal>
        <MortgageAmountModal
            key={'amountHp'}
            visible={amountHp}
            result={result?.simulation}
            onCancel={() => setAmountHp(false)}
        />
        <ModalWithoutButtons
            key={'rate'}
            visible={rate}
            onCreate={() => setRate(false)}
            onCancel={() => setRate(false)}
            title={t('loan.rateCurrent')}
            text={t('calculator.modal1')}
        />
        <ModalWithoutButtons
            key={'term'}
            visible={term}
            onCreate={() => setTerm(false)}
            onCancel={() => setTerm(false)}
            title={t('scoring.term')}
            text={t('calculator.modal2')}
        />
    </>);
};

export default CalculatorFinancing;
