import React from "react";
import { Pie } from "@ant-design/charts";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import numeral from "numeral";

const { Text } = Typography;

const PieActivesPassives = ({ realestate, vehicles, other }) => {
  const { t } = useTranslation();
  var data = [
    {
      type: `${t("balance.realeStateTitle")} ${realestate}`,
      value: realestate,
    },
    {
      type: t("balance.vehiclesTitle"),
      value: vehicles,
    },
    {
      type: t("form.others"),
      value: other,
    },
  ];
  var config = {
    legend: false,
    appendPadding: 10,
    data: data,
    angleField: "value",
    colorField: "type",
    color: ["#41D2DA", "#FFC069", "#FF7875", "#95DE64"],
    radius: 0.7,
    label: {
      type: "inner",
      offset: "-30%",
      content: function content() {
        // var percent = _ref.percent;
        return "";
      },
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [{ type: "element-active" }],
  };
  return (
    <div style={{ marginTop: "-20px" }}>
      <Pie
        hasLegend="false"
        height={180}
        width={200}
        {...config}
        valueFormat={(val) => <span>{numeral(val).format("0,0 $")}</span>}
        colors={["#41D2DA", "#FFC069", "#FF7875", "#95DE64"]}
      />{" "}
      <div
        style={{
          marginTop: "-10px",
        }}
      >
        <Text
          strong
          style={{
            marginRight: "10px",
            fontSize: "12px",
          }}
        >
          {t("balance.totalTitle")}
        </Text>
        <Text
          style={{
            color: "#02C3CD",
            fontSize: "12px",
          }}
        >
          {numeral(realestate + vehicles + other).format("0,00 $")}
        </Text>
      </div>
    </div>
  );
};

export default PieActivesPassives;
