import React from "react";
import { WaterWave } from "ant-design-pro/lib/Charts";

function WaterWaveRename({ scoring, typeCategory, styleInit }) {
  return (
    <div style={{ textAlign: "center" }}>
      <WaterWave
        color={"#02C3CD"}
        height={styleInit ? 160 : 161}
        title={
          <div
            style={{
              color: "#748EA0",
              fontSize: "14px",
              fontWeight: "500",
              marginBottom: "8px",
            }}
          >
            {typeCategory}
          </div>
        }
        percent={scoring}
      />
    </div>
  );
}

export default WaterWaveRename;
