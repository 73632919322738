import React from "react";
import { Typography } from "antd";
import { Pie } from "ant-design-pro/lib/Charts";
import "ant-design-pro/dist/ant-design-pro.css";
import { useTranslation } from "react-i18next";
import numeral from "numeral";

const { Text } = Typography;

const PieClosedActivos = ({ realestate, vehicles, cash, other }) => {
  const { t } = useTranslation();
  const salesPieData = [
    {
      x: t("balance.realeStateTitle"),
      y: realestate,
    },
    {
      x: t("balance.vehiclesTitle"),
      y: vehicles,
    },
    {
      x: t("balance.treasury"),
      y: cash,
    },
    {
      x: t("form.others"),
      y: other,
    },
  ];
  return (
    <>
      <Pie
        title="Activos"
        colors={["#41D2DA", "#FFC069", "#FF7875", "#95DE64"]}
        hasLegend={false}
        data={salesPieData}
        className="PieClosedActive"
        valueFormat={(val) => <span>{numeral(val).format("0,00 $")}</span>}
        height={140}
      />
      <div style={{ textAlign: "center" }}>
        <Text
          strong
          style={{
            marginRight: "10px",
            fontSize: "12px",
          }}
        >
          {t("balance.totalTitle")}
        </Text>
        <Text
          style={{
            color: "#02C3CD",
            fontSize: "12px",
          }}
        >
          {numeral(realestate + vehicles + cash + other).format("0,00 $")}
        </Text>
      </div>
    </>
  );
};

export default PieClosedActivos;
