import { Route, Routes } from 'react-router-dom';
import LoanSwitchMobileOrDesktopForm from '../../Pages/Financiero/Loans/LoanSwitchMobileOrDesktopForm';
import LoanSwitchMobileOrDesktop from '../../Pages/Financiero/Loans/LoanSwitchMobileOrDesktop';
import LoanSwitchMobileOrDesktopListLoan from '../../Pages/Financiero/Loans/LoanSwitchMobileOrDesktopListLoan';
import LoanSwitchMobileOrDesktopDetails from '../../Pages/Financiero/Loans/LoanSwitchMobileOrDesktopDetails';
import LoanPageDetailsMobileConfiguration from '../../Pages/Financiero/Loans/LoansMobile/LoanPageDetailsMobileConfiguration';
import LoanPageDetailsMobileInformation from '../../Pages/Financiero/Loans/LoansMobile/LoanPageDetailsMobileInformation';
import LoanComparativeSwitchMobileOrDesktop from '../../Pages/Financiero/Loans/LoanComparativeSwitchMobileOrDesktop';
import LoanAmortizeMobileManual from '../../Pages/Financiero/Loans/LoansMobile/LoanAmortizeMobileManual';
import LoanValueHomeMobileManual from '../../Pages/Financiero/Loans/LoansMobile/LoanValueHomeMobileManual';

export default () => (
	<Routes>
		<Route
			path="/form/:bankId"
			element={ <LoanSwitchMobileOrDesktopForm />}
		/>
		<Route
			path="/edit/form/:loanId"
			element={<LoanSwitchMobileOrDesktopForm />}
		/>
		<Route
			path="/edit/all-info-checked/:loanId"
			element={ <LoanSwitchMobileOrDesktopForm />}
		/>
		<Route
			path="/list"
			element={ <LoanSwitchMobileOrDesktopListLoan />}
		/>
		<Route
			path="/loan-type/comparative/:loanId/:rateType"
			element={ <LoanComparativeSwitchMobileOrDesktop />}
		/>

		<Route
			path="/all-detail-loan/:loanId"
			element={ <LoanSwitchMobileOrDesktopDetails />}
		/>
		<Route
			path="/:loanId/configuration"
			element={ <LoanPageDetailsMobileConfiguration />}
		/>
		<Route
			path="/:loanId/information"
			element={ <LoanPageDetailsMobileInformation />}
		/>
		<Route
			path="/amortize/form/:loanId"
			element={<LoanAmortizeMobileManual />}
		/>
		<Route
			path="/value-home/form/:loanId"
			element={<LoanValueHomeMobileManual />}
		/>
		<Route
			path="/add/loan"
			element={<LoanSwitchMobileOrDesktop />}
		/>
		<Route
			path="/"
			element={ <LoanSwitchMobileOrDesktop checkForm={true} />}
		/>
		
	</Routes>
);
