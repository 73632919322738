import React, { useState, useEffect } from 'react';
import { Col, Dropdown, Menu, Row, Card, Typography, Button } from 'antd';
import loanInit from '../../../assets/loans/initLoan.png';
import EditIcon from '../../../assets/loans/EditIcon.svg';
import TipsList from './LoansDesktop/TipsList';
import { useTranslation } from 'react-i18next';
import { BankOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import env from '../../../environment';
import numeral from 'numeral';
import updateLoan from '../../../assets/loans/updateLoan.png';

const { Text } = Typography;
const LoanCard = ({
	loan,
	handleShowOptions,
	handleClickCard,
	editLoanImage,
	editLoanName,
	deleteLoan,
	colSpan
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();;
	const [, setLoanList] = useState([]);
	const userToken = useSelector((state) => state.auth.token);


	useEffect(() => {
		getLoansList();
	}, []);

	const getLoansList = () => {
		axios
			.get(`${env.api.url}/v1/loans/get-loans-list`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				if (response.data.data.loans.length === 0) {
					navigate('/my-financial-profile/credits');
				} else {
					setLoanList(response.data.data.loans.reverse());
				}
			})
			
	};

	const menuComponent = (
		<Menu>
			<Menu.Item>
				<a onClick={(e) => editLoanImage(e)}>{t('modalLoan.editImage')}</a>
			</Menu.Item>
			<Menu.Item>
				<a onClick={(e) => editLoanName(e)}>{t('modalLoan.editName')}</a>
			</Menu.Item>
			<Menu.Item>
				<a onClick={(e) => deleteLoan(e)}>{t('loan.eliminateMortgage')}</a>
			</Menu.Item>
		</Menu>
	);

	return (
		<Col {...colSpan}>
			<Card
				className="init-card init-card-24padding"
				style={{ minHeight: 400 }}
				cover={
					<>
						<div style={{ overflow: 'hidden', width: 'auto' }}>
							{loan?.version !== "2023.v1"  ? (
								<img
									src={updateLoan}
									alt="initCard"
									style={{
										width: '100%',
										maxHeight: 'auto'
									}}
								/>
							) : (
								<img
									src={loan.image !== undefined ? loan.image.link : loanInit}
									alt="initCard"
									style={{
										width: '100%',
										height: 'auto'
									}}
								/>
							)}
						</div>
					</>
				}>
				<Row style={{ marginTop: -20 }}>
					<Col span={4} style={{ marginTop: 30 }}>
						{loan.bank !== undefined && loan.bank !== null ? (
							<div>
								<img
									src={
										'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/banks/' +
										`${loan.bank.bankCode + '_iso.png'}`
									}
									alt="banco"
									style={{ height: '32px' }}
								/>
							</div>
						) : (
							<BankOutlined style={{ fontSize: 32, color: '#02C3CD' }} />
						)}
					</Col>
					<Col span={20}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginTop: 24
							}}>
							<Dropdown
								overlay={menuComponent}
								trigger={['click']}
								onClick={(e) => handleShowOptions(e, loan.id)}
								className="dropdown-loan">
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center'
									}}>
									{loan.name === undefined ||
										(loan.name === null && (
											<>
												<div className="text-limit">
													<Text className="title-page">
														{t('loan.typeMortgageTitle')}&nbsp;
														{t('loanType.' + loan.rateType)}
													</Text>
												</div>
												<img
													src={EditIcon}
													alt="EditIcon"
													style={{
														height: 24,
														width: 24,
														marginLeft: 8,
														cursor: 'pointer'
													}}
												/>
											</>
										))}

									{loan.name !== undefined && loan.name !== null && (
										<>
											<Text className="title-page">{loan.name}</Text>
											<img
												src={EditIcon}
												alt="EditIcon"
												style={{
													height: 24,
													width: 24,
													marginLeft: 8,
													cursor: 'pointer'
												}}
											/>
										</>
									)}
								</div>
							</Dropdown>

							<Text
								style={{
									color: '#02C3CD',
									fontSize: 14,
									fontWeight: 500
								}}>
								{numeral(loan.quota).format('0,0 $') + ' ' + t('balance.savingsToMonth')}
							</Text>
						</div>
					</Col>
				</Row>

				<div style={{ marginBottom: 60 }}>
					<TipsList loan={loan}></TipsList>
				</div>

				<Button
					className="button-primari-gibobs"
					type="primary"
					onClick={() => {
						if (loan?.version !== "2023.v1" ) {
							navigate(`/my-financial-profile/credits/edit/form/${loan.id}`);
						} else {
							handleClickCard(loan);
						}
					}}
					style={{
						position: 'absolute',
						bottom: 24,
						width: 'calc(100% - 48px)',
						borderRadius: 8
					}}>
					{loan?.version !== "2023.v1" ? t('scoring.update') : t('init.goToDetail')}
				</Button>
			</Card>
		</Col>
	);
};

export default LoanCard;
