import { useTranslation } from 'react-i18next';
import topStartups from '../../assets/onboarding/top_startup_24.svg';

const WidgetTopStartups = () => {
	const { t } = useTranslation();
	return (
		<div
			style={{
				textAlign: 'center',
				color: '#2F4858',
				fontSize: 14,
				marginTop: 17
			}}>
			<p>
				<span style={{ fontWeight: 600 }}>{t('advantages.gibobs2')}</span>
				&nbsp;
				<span style={{ fontWeight: 400 }}>{t('advantages.gibobs3')}</span>
			</p>
			<img src={topStartups} alt="Top Startups" width={100} height={81} />
		</div>
	);
};

export default WidgetTopStartups;
