import React, { useState } from "react";
import Agregar from "../../../assets/icons/Agregar.svg";
import { Popover, Menu } from "antd";
import axios from "axios";
import env from "../../../environment";
import { useSelector, useDispatch } from "react-redux";
import { reloadInit } from "../../../store/auth/actions";

function WidgetVacio() {
  const userToken = useSelector((state) => state.auth.token);
  const [hidden, setHidden] = useState(false);
  const dispatch = useDispatch();
  const reloadWidgets = useSelector((state) => state.auth.reloadInit);

  const selectWidget = (
    <div style={{ width: "100px", textAlign: "center", display: "contents" }}>
      {hidden && (
        <Menu>
          <Menu.Item onClick={() => handleNewWidget("OPERATION_STAGES")}>
            SOLICITUD DE HIPOTECA
          </Menu.Item>
          <Menu.Item onClick={() => handleNewWidget("OPERATION_DOCUMENTS")}>
            MIS DOCUMENTOS
          </Menu.Item>
          <Menu.Item onClick={() => handleNewWidget("BALANCE")}>
            MI BALANCE
          </Menu.Item>
          <Menu.Item onClick={() => handleNewWidget("WIDGET_DOCUMENTS")}>
            MI DOCUMENTACION
          </Menu.Item>
          <Menu.Item onClick={() => handleNewWidget("PERSONAL_ANALYST")}>
            MI ANALISTA PERSONAL
          </Menu.Item>
          <Menu.Item onClick={() => handleNewWidget("WIDGET_BANK_ANALYSIS")}>
            BANCO
          </Menu.Item>
        </Menu>
      )}
    </div>
  );

  const handleNewWidget = (type) => {
    const home = "home"; //dasboardId de inicio
    axios
      .post(
        `${env.api.url}/v1/dashboard/widgets/add`,
        { dashboardType: home, widgetType: type },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        if (response.data.success) {
          dispatch(reloadInit(!reloadWidgets));
        }
      })
      .catch((error) => {
        console.log(error)
      });

    setHidden(false);
  };

  return (
    <div>
      <Popover
        content={selectWidget}
        style={{ textAlign: "center" }}
        placement="bottom"
        trigger="click"
        visible={hidden}
        onVisibleChange={() => setHidden(!hidden)}
      >
        <img
          src={Agregar}
          alt="agregar"
          height={30}
          style={{ marginTop: "20px" }}
        />
      </Popover>
    </div>
  );
}

export default WidgetVacio;
