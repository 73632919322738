import React, { useState, useEffect } from "react";
import { Card, Col, Row, Spin, Collapse } from "antd";
import { useTranslation } from "react-i18next";
import env from "../../../environment";
import { useSelector } from "react-redux";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import BreadcrumbsInmo from "./Components/BreadcrumbsInmo";
import DataInformationEco from "./dataInformationEco";
import AgentInformation from "./Components/AgentInformation";
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import UnorderedListOutlinedIcon from "../../../assets/icons/UnorderedListOutlinedIcon.svg";

const { Panel } = Collapse;

function EcoFollowUp({ stages, dataMortgage, userMortgageId }) {
  const { t } = useTranslation();
  const navigate = useNavigate();;

  const [valuesData, setValuesData] = useState([]);
  const [viabilityData, ] = useState();
  const [nameOperation, setNameOperation] = useState();
  const [infoAgents, setInfoAgents] = useState();

  const userToken = useSelector((state) => state.auth.token);
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

  useEffect(() => {
    dataValueInfoUpload();
    axios
      .get(`${env.api.url}/v1/operations/${userMortgageId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setNameOperation(response.data.data.subname);
      });
    getAgentsOpertions();
  }, []);

  const dataValueInfoUpload = () => {
    axios
      .get(`${env.api.url}/v1/operations/b2b-resume/${userMortgageId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setValuesData(response.data.data);
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const ownerList = [];

  if (
    dataMortgage &&
    dataMortgage.mortgage &&
    dataMortgage.mortgage.participants
  ) {
    for (let i = 1; i <= Number(dataMortgage.mortgage.participants); i++) {
      ownerList.push(`owner${i}`);
    }

    for (let i = 0; i < Number(dataMortgage.mortgage.guarantors); i++) {
      ownerList.push(`guarantor${i + 1}`);
    }
  }
  const getAgentsOpertions = () => {
    axios
      .get(`${env.api.url}/v1/mortgages/agents/${userMortgageId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setInfoAgents(response.data.data);
      });
  };

  return (
    <>
      {valuesData &&
      valuesData.operation &&
      dataMortgage &&
      dataMortgage["solar-value"] ? (
        <>
          {!isMobile && (
            <>
              <BreadcrumbsInmo
                name={nameOperation}
                eco={true}
                type={"svh"}
                bol={false}
              />
              <Row
                gutter={24}
                style={{ width: "calc(100% - 64px)", marginLeft: 32 }}
              >
                <Col xs={24} lg={18}>
                  <Card
                    className="gibobs-card-styles"
                    style={{
                      // width: "calc(100% - 80px)",
                      // marginLeft: 40,
                      marginTop: 24,
                    }}
                  >
                    <Collapse
                      style={{
                        backgroundColor: "white",
                      }}
                      expandIconPosition={"end"}
                      defaultActiveKey={1}
                    >
                      <Panel
                        header={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={UnorderedListOutlinedIcon}
                              alt="UnorderedListOutlinedIcon"
                              height={12}
                              style={{ marginRight: 16 }}
                            />
                            <div
                              style={{
                                fontSize: 16,
                                color: "#2F4858",
                                fontWeight: 400,
                              }}
                            >
                              {t("certificate.infoOperation")}
                            </div>
                          </div>
                        }
                        key="1"
                      >
                        <DataInformationEco
                          stages={stages}
                          valuesData={valuesData}
                          ownerList={ownerList}
                          dataMortgage={dataMortgage}
                          viabilityData={viabilityData}
                        />
                      </Panel>
                    </Collapse>
                  </Card>
                </Col>
                <Col xs={6} lg={6}>
                  <Card
                    className="gibobs-card-styles"
                    style={{
                      // width: "calc(100% - 80px)",
                      // marginLeft: 40,
                      marginTop: 24,
                    }}
                  >
                    <AgentInformation
                      image={
                        infoAgents &&
                        infoAgents.agent &&
                        infoAgents.agent.avatar &&
                        infoAgents.agent.avatar.link &&
                        infoAgents.agent.avatar.link
                      }
                      name={
                        infoAgents && infoAgents.agent && infoAgents.agent.name
                      }
                      phone={
                        infoAgents && infoAgents.agent && infoAgents.agent.phone
                      }
                      email={
                        infoAgents && infoAgents.agent && infoAgents.agent.email
                      }
                    />
                  </Card>
                </Col>
              </Row>
            </>
          )}
          {isMobile && (
            <div style={{ marginBottom: 100 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "16px 16px 24px 16px",
                }}
              >
                <ArrowLeftOutlined
                  style={{ fontSize: 16, color: "#2F4858" }}
                  onClick={() =>
                    navigate("/sales-tools/follow-up", [false, "svh"])
                  }
                />
                <div
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#2f4858",
                    marginLeft: 16,
                  }}
                >
                  {t("form.budgetInformation")}
                </div>
              </div>
              <DataInformationEco
                stages={stages}
                valuesData={valuesData}
                ownerList={ownerList}
                dataMortgage={dataMortgage}
                viabilityData={viabilityData}
              />
              <AgentInformation
                name={"Nombre agente"}
                phone={123456789}
                email={"amilagente@gibobs.com"}
              />
            </div>
          )}
        </>
      ) : (
        <Spin indicator={antIcon} className="spin" />
      )}
    </>
  );
}

export default EcoFollowUp;
