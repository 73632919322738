import React, { useState, useEffect } from 'react';
import {
	Button,
	Typography,
	Modal,
	Form,
	Input,
	Select,
	message,
	notification,
	Card,
	Row,
	Col,
	Spin
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import { changeRol } from '../../store/auth/actions';
import axios from 'axios';
import env from '../../environment';
import initCard from '../../assets/loans/mortgageImage.png';
import { LoadingOutlined } from '@ant-design/icons';
import numeral from 'numeral';
import TablePlainFriend from '../widgets/TablePlainFriend';
import { dataSummary } from '../../store/data/actions';
import _ from 'lodash';
import PlainFriendMobile from '../../Pages/PlainFriend/PlainFriendMobile.js';
import WidgetsCardMobileDataMortgage from './WidgetCardMobileData';
import { getAnalytics, logEvent } from 'firebase/analytics';
import DashBoardDataLoanMobile from './DashBoardLoan/DashBoardDataLoanMobile';
import initLoan from '../../assets/loans/initLoan.png';
import CardLinkAmigobs from './CardLinksApps/CardLinkAmigobs';
import initCard2 from '../../assets/loans/subrogationImage.png';
import amigobsImage from '../../assets/loans/banner-amigobs-500.png';
import LoanCard from '../../Pages/Financiero/Loans/LoanCard';
import ModalEditImage from './ModalDashboard/ModalEditImage';
import ModalEditName from './ModalDashboard/ModalEditName';
import ModalDeleteMortgage from './ModalDashboard/ModalDeleteMortgage';
import CalculatorsWidget from './CalculatorsWidget';
import womenInformation from '../../assets/loans/womenInformation.svg';
import initMortgage from '../../assets/loans/loanInit.png';
import ReviewsCustomer from './ReviewsCustomer';
import savings from '../../assets/init/savings.png';

const { Text } = Typography;
const { Option } = Select;

function WidgetsDashboard() {
	const [modalFriendShow, setModalFriendShow] = useState(false);
	const rolUser = useSelector((state) => state.auth.roles);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const userToken = useSelector((state) => state.auth.token);
	const navigate = useNavigate();
	const [dataPersonal, setDataPersonal] = useState(undefined);
	const [myLoansList, setMyLoansList] = useState(undefined);
	let dataMortgage1 = useSelector((state) => state.data.dataSummary);
	dataMortgage1 = _.filter(dataMortgage1, (d) => d.type === 'mortgage' || d.type === 'certificate');

	const dataMortgageTmp = _.filter(dataMortgage1, (d) => d.type === 'mortgage');
	const dataProfileDetails = useSelector((state) => state.data.dataProfile);
	const analytics = getAnalytics();

	const [loanId, setLoanId] = useState(undefined);
	const [visibleEditImage, setVisibleEditImage] = useState(false);
	const [visibleDelete, setVisibleDelete] = useState(false);
	const [visibleEditName, setVisibleEditName] = useState(false);
	const [nameBank, setNameBank] = useState(undefined);
	const [fileName, setFileName] = useState(undefined);
	const [saveFileDataField, setSaveFileDataField] = useState([]);
	const [loading, setLoading] = useState(false);
	const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

	useEffect(async () => {
		dispatch(dataSummary(userToken));
		getDataPersonalOperation();
		const response2 = await axios.get(`${env.api.url}/v1/loans/get-loans-list`, {
			headers: { Authorization: `Bearer ${userToken}` }
		});
		setMyLoansList(response2.data.data);

		logEvent(analytics, 'screen_view', {
			screen_name: 'tabBar.home'
		});
	}, []);

	const getDataPersonalOperation = () => {
		axios
			.get(`${env.api.url}/v1/user/profile`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setDataPersonal(response.data.data.profile);
			});
	};

	const modalFriend = () => {
		setModalFriendShow(!modalFriendShow);
	};

	const sendToAnalytics = () => {
		logEvent(analytics, 'screen_view', {
			screen_name: 'cancel.mortgage'
		});
	};

	const sendinfoProfile = (values) => {
		axios
			.post(
				`${env.api.url}/v1/friends/invite`,
				{
					name: values.name,
					surname: values.surname,
					email: values.email,
					phone: values.phone,
					language: values.languaje
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					dispatch(changeRol('friend'));
					navigate('/friendInfo');
					notification.success(
						'Muchas gracias. Hemos enviado un email a tu amigo para invitarle a gibobs. A continuación puedes ver el listado de invitaciones que has cursado'
					);
				}
				if (response.data.errorCode === 'FRIEND_EXISTS') {
					message.error(
						'Tu amigo ya está registrado en gibobs; no puede formar parte del Plan Amigo'
					);
				}
				if (response.data.error) {
					message.error('Ha habido un error. No ha sido posible enviar la invitación a tu amigo');
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleClickHistory = () => {
		dispatch(changeRol('friend'));
		navigate('/friendInfo');
	};

	const handleClickProduct = () => {
		setLoading(true);
		navigate('/welcomeSelection');
	};

	const handleClickCard = (loan) => {
		navigate(`/my-financial-profile/credits/all-detail-loan/${loan.id}`);
	};

	const onChangeName = (e) => {
		setNameBank(e.target.value);
	};
	const saveFileData = (file, name) => {
		const documentFile = saveFileDataField;
		documentFile.push({
			file,
			fileName: name
		});
		setSaveFileDataField(documentFile);
	};

	const uploadProps = {
		multiple: true,
		beforeUpload: (file, e) => {
			if (file.size > 8000000) {
				notification.error({
					message: 'Error: archivo demasiado grande',
					description: 'Su documento ocupa mas de 8mb. No se pueden enviar archivos de más de 8mb'
				});

				setFileName('Error: archivo demasiado grande');

				return;
			}

			setFileName(file.name);

			// setFileName([...fileName, file.name]);
			return new Promise(() => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					const base64File = reader.result.split(',')[1];
					saveFileData(base64File, file.name, e);
				};
			});
		}
	};

	const editLoanImage = () => {
		setVisibleEditImage(!visibleEditImage);
	};

	const deleteLoan = () => {
		setVisibleDelete(!visibleDelete);
	};

	const editLoanName = () => {
		setVisibleEditName(!visibleEditName);
	};

	const handleClickContinueHp = (dataMortgage) => {
		navigate('/distributor', {
			state: {
				dataOperation: dataMortgage
			}
		});
	};

	const handleShowOptions = (e, id) => {
		setLoanId(id);
		e.stopPropagation();
	};

	const handleModalDelete = () => {
		axios
			.get(`${env.api.url}/v1/loans/delete-loan/${loanId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then(() => {
				getLoansList();
				navigate('/dashboard-client');
				setVisibleDelete(!visibleDelete);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getLoansList = () => {
		axios
			.get(`${env.api.url}/v1/loans/get-loans-list`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				if (response.data.data.loans.length === 0) {
					navigate('/my-financial-profile/credits');
				} else {
					setMyLoansList(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleModalEditName = () => {
		axios
			.post(
				`${env.api.url}/v1/loans/update-details/${loanId}`,
				{
					name: nameBank
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then(() => {
				getLoansList();
				setVisibleEditName(!visibleEditName);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleModalEditImage = () => {
		axios
			.post(
				`${env.api.url}/v1/loans/update-details/${loanId}`,
				{
					image: saveFileDataField[0].file
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then(() => {
				getLoansList();
				setVisibleEditImage(!visibleEditImage);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const colSpan = { xxl: 6, xl: 6, lg: 8, md: 12, sm: 24, xs: 24 };

	const styleConst = {
		margin: 'auto',
		position: 'absolute',
		bottom: 24,
		left: 0,
		right: 0,
		width: 'calc(100% - 72px)',
		height: 40,
		fontWeight: 600,
		borderRadius: 8
	};

	const styleConstOperations = {
		margin: 'auto',
		position: 'absolute',
		bottom: 24,
		left: 0,
		right: 0,
		width: 'calc(100% - 48px)',
		height: 40,
		fontWeight: 600,
		borderRadius: 8
	};
	const textName = {
		'green-subrogation': t('initCardMortgage.green-subrogation'),
		subrogation: t('initCardMortgage.subrogation'),
		'green-mortgage': t('initCardMortgage.green-mortgage'),
		mortgage: t('initCardMortgage.mortgage')
	};

	if (loading) {
		return <Spin indicator={antIcon} className="spin" />;
	}

	return (
		<div className="card-init-client" style={{ marginBottom: isMobileOnly ? -200 : 100 }}>
			{rolUser && rolUser === 'friend' && isMobileOnly && (
				<div style={{ width: '100%', marginBottom: 400 }}>
					<PlainFriendMobile></PlainFriendMobile>
				</div>
			)}
			{rolUser && rolUser === 'friend' && !isMobileOnly && (
				<div style={{ width: '100%', marginTop: 32 }}>
					<TablePlainFriend></TablePlainFriend>
				</div>
			)}
			{rolUser && rolUser !== 'friend' && (
				<Row
					style={{
						marginLeft: isMobileOnly ? undefined : 30,
						marginBottom: isMobileOnly ? 300 : undefined
					}}
					gutter={[0, 25]}>
					<Col
						span={isMobileOnly ? 20 : 24}
						style={{ textAlign: 'center', marginTop: isMobile ? 14 : 0 }}>
						<div
							style={{
								display: 'flex',
								width: isMobileOnly ? '90%' : '50%',
								alignItems: 'center',
								margin: 'auto'
							}}>
							<img src={womenInformation} alt="women-information" />
							<div
								className="title-initial-information"
								dangerouslySetInnerHTML={{
									__html: t('init.messageTheBestMortgage')
								}}
								style={{
									fontSize: isMobileOnly && 16,
									lineHeight: isMobileOnly && '20px',
									textAlign: isMobileOnly && 'left',
									marginLeft: isMobileOnly && 16
								}}
							/>
						</div>
					</Col>
					{dataMortgageTmp.length === 0 && (
						<Col {...colSpan}>
							<Card
								className="init-card init-card-24padding"
								cover={
									<>
										<div style={{ overflow: 'hidden', width: 'auto', marginBottom: -6 }}>
											<img
												src={initMortgage}
												alt="initCard"
												style={{ width: '100%', height: 'auto' }}
											/>
										</div>
									</>
								}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: isMobileOnly && 35
									}}>
									<Text className="calculators-widget-title">{t('init.newMortages')}</Text>
									<div
										className="calculators-widget-subtitle"
										style={{ marginTop: 8, marginBottom: isMobileOnly && 25 }}>
										{t('init.mortgageWidgetDescription')}
									</div>
									<Button
										onClick={() => handleClickProduct()}
										type="primary"
										className="button-primari-gibobsNew"
										style={{ ...styleConstOperations }}>
										{t('init.goingToTheBestMortgage')}
									</Button>
								</div>
							</Card>
						</Col>
					)}

					<Col {...colSpan} style={{ height: 'auto' }}>
						<CalculatorsWidget />
					</Col>

					{dataPersonal && (
						<Col {...colSpan} style={{ height: 'auto' }}>
							<Card
								className="init-card init-card-24padding"
								cover={
									<div style={{ overflow: 'hidden', width: 'auto', marginBottom: -6 }}>
										<img
											src={amigobsImage}
											alt="amigobsImage"
											style={{ width: '100%', height: 'auto' }}
										/>
									</div>
								}
								style={{ background: '#EFFCFC' }}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: 60
									}}>
									<Text className="title-init-card" style={{ fontSize: 16 }}></Text>
									<Text className="description-product" style={{ fontSize: 14 }}>
										{t('init.amigobsDesriptionNew')}
									</Text>
								</div>
							</Card>
							<Button
								type="dashed"
								className="button-secundary-gibobs"
								onClick={() => handleClickHistory()}
								style={{ ...styleConst, backgroundColor: 'transparent' }}>
								{t('init.inviteMyFriendButton')}
							</Button>
						</Col>
					)}
					{/* <Col {...colSpan}>
						<Card
							className="init-card init-card-24padding"
							cover={
								<>
									<div style={{ overflow: 'hidden', width: 'auto', marginBottom: -6 }}>
										<img src={savings} alt="savings" style={{ width: '100%', height: 'auto' }} />
									</div>
								</>
							}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: isMobileOnly ? 55 : dataMortgageTmp.length !== 0 ? 64 : undefined
								}}>
								<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>
									{t('init.savingsTitle')}
								</Text>
								<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
									{t('init.savingsText')}
								</Text>
							</div>

							<Button
								onClick={() => sendToAnalytics()}
								href="https://cancelaciones.gibobs.com/"
								target="_blank"
								type="primary"
								className="button-primari-gibobsNew"
								style={{ ...styleConstOperations }}>
								{<span style={{ marginTop: 4 }}>{t('loan.linkCapitalWidgetMoreInfo')}</span>}
							</Button>
						</Card>
					</Col> */}

					{dataMortgage1 !== undefined &&
						!isMobileOnly &&
						dataMortgage1.map((dataMortgage) => {
							return (
								// widget con datos HP
								dataMortgage.type !== 'certificate' && (
									<Col {...colSpan} key={dataMortgage.id}>
										<Card
											style={{ cursor: 'pointer' }}
											className="init-card init-card-24padding"
											onClick={() => handleClickContinueHp(dataMortgage)}
											cover={
												<>
													<div style={{ overflow: 'hidden', width: 'auto' }}>
														<img
															src={dataMortgage.subtype === 'subrogation' ? initCard2 : initCard}
															alt="initCard"
															style={{ width: '100%', height: 'auto' }}
														/>
													</div>
												</>
											}>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'space-between'
												}}>
												<div>
													<Text className="calculators-widget-title">
														{textName[dataMortgage.subtype]}
													</Text>
													<div style={{ display: 'flex' }}>
														<Text
															style={{
																fontSize: 16,
																fontWeight: 600,
																color: '#02C3CD',
																marginRight:
																	((dataMortgage.subtype === 'mortgage' ||
																		dataMortgage.subtype === 'green-mortgage') &&
																		dataMortgage.province) ||
																	(dataMortgage.subtype === 'green-subrogation' &&
																		dataMortgage &&
																		dataMortgage.province)
																		? 6
																		: 0,
																lineHeight: '20px'
															}}>
															{(dataMortgage.subtype === 'mortgage' ||
																dataMortgage.subtype === 'green-mortgage') &&
															dataMortgage.province
																? dataMortgage.province + ' -'
																: dataMortgage.subtype === 'green-subrogation' &&
																  dataMortgage.province &&
																  dataMortgage.province + ' -'}
														</Text>
														<Text
															style={{
																fontSize: 16,
																fontWeight: 600,
																color: '#02C3CD',
																lineHeight: '20px'
															}}>
															{dataMortgage.subtype === 'mortgage' ||
															dataMortgage.subtype === 'green-mortgage'
																? numeral(dataMortgage.amount).format('0,0 $')
																: dataMortgage.amount
																? numeral(dataMortgage.amount).format('0,0 $')
																: numeral(0).format('0,0 $')}
														</Text>
													</div>
												</div>
											</div>

											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													marginTop: 24,
													marginBottom: 70
												}}>
												<div className="calculators-widget-subtitle">
													{t('init.' + dataMortgage.stage + 'DescriptionWidget')}
												</div>
											</div>

											<Button
												onClick={() => handleClickContinueHp(dataMortgage)}
												type="primary"
												className="button-primari-gibobsNew"
												style={styleConstOperations}>
												{t('init.pickUpWhereILeftOff')}
											</Button>
										</Card>
									</Col>
								)
							);
						})}

					{dataMortgage1 !== undefined && isMobileOnly && (
						<WidgetsCardMobileDataMortgage
							dataMortgage1={dataMortgage1}
							handleClickContinueHp={handleClickContinueHp}
							styleConstOperations={styleConstOperations}
							colSpan={colSpan}></WidgetsCardMobileDataMortgage>
					)}

					{myLoansList && myLoansList.loans.length === 0 && !isMobileOnly && (
						<Col {...colSpan} style={{ height: isMobileOnly ? 430 : undefined }}>
							<Card
								className="init-card init-card-24padding"
								cover={
									<div style={{ overflow: 'hidden', width: 'auto', marginBottom: -6 }}>
										<img src={initLoan} alt="initCard" style={{ width: '100%', height: 'auto' }} />
									</div>
								}>
								<Row>
									<Col span={24}>
										<Text
											style={{
												fontSize: 16,
												color: '#2F4858',
												fontWeight: 600
											}}>
											{t('loan.timeToRelax')}
										</Text>
									</Col>
								</Row>
								<div
									style={{
										marginTop: 8,
										marginBottom: 60,
										color: '#748EA0',
										fontSize: 14,
										fontWeight: 400,
										fontFamily: 'Inter'
									}}
									dangerouslySetInnerHTML={{
										__html: t('init.timeToRelaxDescription2')
									}}
								/>
							</Card>
							<Button
								onClick={() => {
									navigate('/my-financial-profile/credits');
									logEvent(analytics, 'select_content', {
										content_type: 'widget.go.section.mortgages',
										content_id: 'widget.mortgages.button'
									});
								}}
								type="primary"
								className="button-primari-gibobsNew"
								style={{ ...styleConst }}>
								{t('init.reduceMyQuote')}
							</Button>
						</Col>
					)}

					{myLoansList && myLoansList.loans.length === 0 && isMobileOnly && (
						<Col {...colSpan}>
							<Card
								className="init-card-mobile"
								cover={
									<div style={{ overflow: 'hidden', width: 'auto', height: 120, marginBottom: -6 }}>
										<img src={initLoan} alt="initCard" style={{ width: '100%', height: 'auto' }} />
									</div>
								}>
								<Row>
									<Col span={19}>
										<Text
											style={{
												fontSize: 16,
												color: '#2F4858',
												fontWeight: 600
											}}>
											{t('loan.timeToRelax')}
										</Text>
									</Col>
								</Row>
								<div
									style={{
										marginTop: 8,
										color: '#748EA0',
										fontSize: 14,
										fontWeight: 400,
										marginBottom: 80
									}}
									dangerouslySetInnerHTML={{
										__html: t('init.timeToRelaxDescription2')
									}}
								/>
							</Card>
							<Button
								onClick={() => {
									navigate('my-financial-profile/credits');
									logEvent(analytics, 'select_content', {
										content_type: 'widget.go.section.mortgages',
										content_id: 'widget.mortgages.button'
									});
								}}
								type="primary"
								className="button-primari-gibobsNew"
								style={{ ...styleConst }}>
								{t('init.reduceMyQuote')}
							</Button>
						</Col>
					)}

					{myLoansList && myLoansList.loans.length > 0 && isMobileOnly && (
						<>
							<Text
								style={{
									fontSize: 12,
									color: '#2F4858',
									fontWeight: 600,
									marginLeft: 14
								}}>
								{t('init.myLoans').toUpperCase()}
							</Text>
							<DashBoardDataLoanMobile myLoansList={myLoansList}></DashBoardDataLoanMobile>
						</>
					)}

					{myLoansList &&
						myLoansList.loans.length > 0 &&
						!isMobileOnly &&
						myLoansList.loans.map((loan) => {
							return (
								<LoanCard
									key={loan.id}
									loan={loan}
									handleShowOptions={(e, id) => handleShowOptions(e, id)}
									handleClickCard={(e) => handleClickCard(e)}
									editLoanImage={(e) => editLoanImage(e)}
									editLoanName={(e) => editLoanName(e)}
									deleteLoan={(e) => deleteLoan(e)}
									colSpan={colSpan}
								/>
							);
						})}

					{isMobileOnly && (
						<div
							style={{
								margin: 'auto',
								marginBottom: isMobileOnly ? 150 : undefined,
								width: '100%'
							}}>
							<ReviewsCustomer dashboardMobile={true} />
						</div>
					)}
				</Row>
			)}
			<div
				style={{
					margin: '40px auto 0px',
					paddingLeft: 40,
					marginBottom: isMobileOnly ? 150 : undefined
				}}>
				<ReviewsCustomer dashboard={true} />
			</div>
			{/*isMobileOnly && <CardLinksApp></CardLinksApp>*/}
			{isMobileOnly && <CardLinkAmigobs></CardLinkAmigobs>}
			<Modal
				className="ModalCommonSmall"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { width: '96px' }
				}}
				okButtonProps={{
					className: 'button-primari-gibobs',
					style: { width: '96px', padding: 0 }
				}}
				open={modalFriendShow}
				title={t('Invitación plan amigo')}
				okText={t('form.send')}
				cancelText={t('mydocuments.deleteCancel')}
				destroyOnClose={true}
				onCancel={() => {
					modalFriend(false);
				}}
				onOk={() => {
					form
						.validateFields()
						.then((values) => {
							form.resetFields();
							modalFriend(false);
							sendinfoProfile(values);
						})
						.catch((info) => {
							console.log('Validate Failed:', info);
						});
				}}>
				<Form form={form} layout="vertical">
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<div style={{ display: 'flex' }}>
							<Form.Item
								name="name"
								label={t('form.name')}
								rules={[{ required: true }]}
								style={{ marginRight: 32, width: 220 }}>
								<Input style={{ width: '100%' }} placeholder={t('form.name')} />
							</Form.Item>
							<Form.Item
								name="surname"
								label={t('form.surname')}
								rules={[{ required: true }]}
								style={{ width: 220 }}>
								<Input style={{ width: '100%' }} placeholder={t('form.surname')} />
							</Form.Item>
						</div>

						<div style={{ display: 'flex' }}>
							<Form.Item
								name="email"
								label={t('form.email')}
								rules={[{ required: true }]}
								style={{ marginRight: 32, width: 220 }}>
								<Input style={{ width: '100%' }} placeholder={t('form.email')} />
							</Form.Item>
							<Form.Item
								name="phone"
								label={t('form.phone')}
								rules={[{ required: true }]}
								style={{ width: 220 }}>
								<Input style={{ width: '100%' }} placeholder={t('form.phone')} />
							</Form.Item>
						</div>

						<Form.Item
							name="languaje"
							label={t('form.languajeComunication')}
							rules={[{ required: true }]}
							style={{ width: 220 }}>
							<Select placeholder={t('form.select')}>
								<Option value="es">{t('form.es')}</Option>
								<Option value="en">{t('form.en')}</Option>
								<Option value="cat">{t('form.cat')}</Option>
							</Select>
						</Form.Item>
					</div>
				</Form>
			</Modal>
			<ModalDeleteMortgage
				key={visibleDelete}
				visible={visibleDelete}
				onCreate={() => handleModalDelete()}
				onCancel={() => setVisibleDelete(!visibleDelete)}
				dataProfileDetails={dataProfileDetails}
			/>
			<ModalEditName
				key={visibleEditName}
				visible={visibleEditName}
				onCreate={() => handleModalEditName()}
				onCancel={() => setVisibleEditName(!visibleEditName)}
				onChangeName={(e) => onChangeName(e)}
			/>
			<ModalEditImage
				key={visibleEditImage}
				visible={visibleEditImage}
				onCreate={() => handleModalEditImage()}
				onCancel={() => setVisibleEditImage(!visibleEditImage)}
				fileName={fileName}
				uploadProps={uploadProps}
			/>
		</div>
	);
}

export default WidgetsDashboard;

{
	/* {!isMobileOnly && dataPersonal && dataPersonal.metadata.dashboard_b2c === undefined && (
						<Col {...colSpan}>
							<Card
								className="init-card-video-jorge" 
								cover={
									<video
										controls
										width="100%"
										type="video/mp4"
										onPlay={() => sendViewVideo()}
										poster={videoJorgeDesktop}
										src="https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/videos/gibobs-corporativo-take-01.mp4"></video>
								}>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Text
										style={{
											fontSize: 16,
											color: '#FFFFFF',
											fontWeight: 600
										}}>
										{t('init.wellcome')}
									</Text>

									<Text
										style={{
											fontSize: 14,
											color: '#FFFFFF',
											fontWeight: 400
										}}>
										{t('init.wellcomeDesc')}
									</Text>
								</div>
							</Card>
						</Col>
					)} */
}

{
	/* {isMobileOnly && dataPersonal && dataPersonal.metadata.dashboard_b2c === undefined && (
						<Col {...colSpan}>
							<Card
								className="init-card-video-jorge"
								cover={
									<video
										controls
										width="100%"
										type="video/mp4"
										onPlay={() => sendViewVideo()}
										poster={videoJorge}
										src="https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/videos/gibobs-corporativo-take-01.mp4"></video>
								}>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Text
										style={{
											fontSize: 16,
											color: '#FFFFFF',
											fontWeight: 600
										}}>
										{t('init.wellcome')}
									</Text>

									<Text
										style={{
											fontSize: 14,
											color: '#FFFFFF',
											fontWeight: 400
										}}>
										{t('init.wellcomeDesc')}
									</Text>
								</div>
							</Card>
						</Col>
					)} */
}
{
	/* <Col {...colSpan} style={{ height: isMobileOnly ? 430 : undefined }}>
						<Card
							className="init-card init-card-24padding"
							cover={
								<img
									src={tutorialImage}
									alt="tutorialImage"
									onClick={() => sendViewVideo('tutorial')}
								/>
							}>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<Text className="title-init-card" style={{ fontSize: 16 }}>
									{t('init.tutorialTitle')}
								</Text>

								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										flexDirection: 'column',
										marginBottom: 50
									}}>
									<Text className="description-product" style={{ fontSize: 14 }}>
										{t('init.descriptionDashboardTutorial')}
									</Text>
								</div>
							</div>
						</Card>
						<Modal
							className="modal-help-gibobs"
							style={{ marginTop: 100 }}
							width={isMobileOnly ? 328 : 480}
							visible={modalShowTutorial}
							okText={'Enviar'}
							cancelText={'Cancelar'}
							destroyOnClose={true}
							onCancel={() => {
								setModalShowTutorial(false);
							}}
							onOk={() => {
								setModalShowTutorial(false);
							}}>
							<iframe
								width={isMobileOnly ? '328' : '480'}
								height="330"
								src={'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/tutorials/tutorial.mp4'}
								title="YouTube video player"
								frameborder="0"
								allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
						</Modal>
					</Col> */
}

{
	/* {isMobileOnly &&
						dataPersonal &&
						dataPersonal.metadata &&
						dataPersonal.metadata.dashboard_b2c !== undefined && (
							<Col {...colSpan}>
								<Card
									className="init-card-video-jorge"
									cover={
										<video
											controls
											width="100%"
											type="video/mp4"
											onPlay={() => sendViewVideo()}
											poster={videoJorge}
											src="https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/videos/gibobs-corporativo-take-01.mp4"></video>
									}>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												fontSize: 16,
												color: '#FFFFFF',
												fontWeight: 600
											}}>
											{t('init.wellcome')}
										</Text>

										<Text
											style={{
												fontSize: 14,
												color: '#FFFFFF',
												fontWeight: 400
											}}>
											{t('init.wellcomeDesc')}
										</Text>
									</div>
								</Card>
							</Col>
						)} */
}

{
	/* {!isMobileOnly &&
						dataPersonal &&
						dataPersonal.metadata &&
						dataPersonal.metadata.dashboard_b2c !== undefined && (
							<Col {...colSpan}>
								<Card
									className="init-card-video-jorge"
									cover={
										<video
											controls
											width="100%"
											type="video/mp4"
											onPlay={() => sendViewVideo()}
											poster={videoJorgeDesktop}
											src="https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/videos/gibobs-corporativo-take-01.mp4"></video>
									}>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												fontSize: 16,
												color: '#FFFFFF',
												fontWeight: 600
											}}>
											{t('init.wellcome')}
										</Text>

										<Text
											style={{
												fontSize: 14,
												color: '#FFFFFF',
												fontWeight: 400
											}}>
											{t('init.wellcomeDesc')}
										</Text>
									</div>
								</Card>
							</Col>
						)} */
}
