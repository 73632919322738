import React from 'react';
import {  Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';

const KnowMore = ({ visible, onCancel, onOk }) => {
	const { t } = useTranslation();

	return (
        (<Modal
			centered
			zIndex={1200}
			className="ModalKnowMore"
			cancelButtonProps={{
				className: 'button-secundary-gibobs',
				style: { 
                    width: isMobileOnly ? 120 : 204,
                    height: isMobileOnly ? 32 : 40,
                    borderRadius: 8 
                }
			}}
			okButtonProps={{
				className: 'button-primari-gibobs',
				style: { 
                    width: isMobileOnly ? 120 : 204,
                    height: isMobileOnly ? 32 : 40,
                    borderRadius: 8,
                    marginLeft: isMobileOnly && 16
                }
			}}
			open={visible}
			title={t('viability.knowMore')}
			okText={t('viability.continue')}
			cancelText={t('viability.back')}
			width={ isMobileOnly ? 328 : 480}
			onCancel={onCancel}
			onOk={onOk}
        >
            <p>Continúa para conocer los detalles de tu oferta personalizada.</p>
        </Modal>)
    );
};

export default KnowMore;
