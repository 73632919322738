import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Layout,
  Card,
  Typography,
  Button,
  Col,
  Row,
  Menu,
  Dropdown,
  Modal,
  Alert,
  Input,
  Upload,
  notification,
} from "antd";
import LayoutOperations from "../../LayoutOperations";
import axios from "axios";
import env from "../../../../environment";
import { useSelector } from "react-redux";
import numeral from "numeral";
import loanInit from "../../../../assets/init/loanInit.png";
import chebronDown from "../../../../assets/loans/chebronDown.png";
import bankIcon from "../../../../assets/loans/bankIcon.png";
import { getAnalytics, logEvent } from "firebase/analytics";
import CardLinkAmigobs from "../../../../components/widgets/CardLinksApps/CardLinkAmigobs";
import TipsList from "../LoansDesktop/TipsList";
import updateLoan from '../../../../assets/loans/updateLoan.png';

const { Text } = Typography;

const LoanPageDetailsMobile = ({  }) => {
  const [loanList, setLoanList] = useState([]);
  const userToken = useSelector((state) => state.auth.token);
  const navigate = useNavigate();;
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [loanId, setLoanId] = useState(undefined);

  const { t } = useTranslation();
  const dataProfileDetails = useSelector((state) => state.data.dataProfile);
  const [visibleEditName, setVisibleEditName] = useState(false);
  const [nameBank, setNameBank] = useState(undefined);
  const [fileName, setFileName] = useState(undefined);
  const [saveFileDataField, setSaveFileDataField] = useState([]);
  const [visibleEditImage, setVisibleEditImage] = useState(false);
  const [renderNoti, setRenderNoti] = useState(undefined);
  const analytics = getAnalytics();


  useEffect(() => {
    getLoansList();
  }, []);

  const getLoansList = () => {
    axios
      .get(`${env.api.url}/v1/loans/get-loans-list`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        if (response.data.data.loans.length === 0) {
          navigate("/my-financial-profile/credits");
        } else {
          setLoanList(response.data.data.loans.reverse());
          /*response.data.data.map((loan) => {
                        getLoan(loan.id)

                    })*/
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const handleAddLoan = () => {
    navigate("/my-financial-profile/credits/add/loan");
  };

  const handleClickCard = (loan) => {
    if (loan.status === "PENDING_USER") {
      navigate(
        `/my-financial-profile/credits/edit/all-info-checked/${loan.id}`
      );
    } else {
      navigate(`/my-financial-profile/credits/all-detail-loan/${loan.id}`);
    }
  };

  const uploadProps = {
    multiple: true,
    beforeUpload: (file, e) => {
      if (file.size > 8000000) {
        notification.error({
          message: "Error: archivo demasiado grande",
          description:
            "Su documento ocupa mas de 8mb. No se pueden enviar archivos de más de 8mb",
        });

        setFileName("Error: archivo demasiado grande");

        return;
      }

      setFileName(file.name);

      // setFileName([...fileName, file.name]);
      return new Promise(() => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64File = reader.result.split(",")[1];
          saveFileData(base64File, file.name, e);
        };
      });
    },
  };

  const menuComponent = (
    <Menu>
      <Menu.Item>
        <a onClick={(e) => editLoanImage(e)}>{t("modalLoan.editImage")}</a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={(e) => editLoanName(e)}>{t("modalLoan.editName")}</a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={(e) => deleteLoan(e)}>{t("loan.eliminateMortgage")}</a>
      </Menu.Item>
    </Menu>
  );

  const saveFileData = (file, name) => {
    const documentFile = saveFileDataField;
    documentFile.push({
      file,
      fileName: name,
    });
    setSaveFileDataField(documentFile);
  };

  const editLoanImage = () => {
    setVisibleEditImage(!visibleEditImage);
  };

  const deleteLoan = () => {
    setVisibleDelete(!visibleDelete);
  };

  const renderNotification = () => {
    let tecxtNotification = undefined;

    if (renderNoti === "delete") {
      tecxtNotification = t("m_loan.deletedLoan");
    } else if (renderNoti === "image") {
      tecxtNotification = t("loan.changeImageEdit");
    } else if (renderNoti === "name") {
      tecxtNotification = t("loan.changeNameEdit");
    } else {
      return undefined;
    }

    return (
      <Alert
        message={
          <Text
            style={{
              color: "#3E9314",
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            {t("m_common.completedOperation")}
          </Text>
        }
        description={
          <Text
            style={{
              color: "#2F4858",
              fontSize: 14,
              fontWeight: 400,
            }}
          >
            {tecxtNotification}
          </Text>
        }
        type="success"
        closable
        showIcon
        style={{
          borderRadius: 4,
          borderColor: "#52C41A",
          backgroundColor: "#DFF4D4",
          position: "absolute",
          zIndex: 1000,
          top: 70,
          width: 355,
        }}
      />
    );
  };

  const handleModalDelete = () => {
    axios
      .get(`${env.api.url}/v1/loans/delete-loan/${loanId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then(() => {
        getLoansList();
        navigate("/my-financial-profile/credits/list");
        setVisibleDelete(!visibleDelete);
        setRenderNoti("delete");
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const handleModalEditName = () => {
    axios
      .post(
        `${env.api.url}/v1/loans/update-details/${loanId}`,
        {
          name: nameBank,
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then(() => {
        getLoansList();
        navigate("/my-financial-profile/credits/list");
        setVisibleEditName(!visibleEditName);
        setRenderNoti("name");
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const handleModalEditImage = () => {
    axios
      .post(
        `${env.api.url}/v1/loans/update-details/${loanId}`,
        {
          image: saveFileDataField[0].file,
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then(() => {
        getLoansList();
        navigate("/my-financial-profile/credits/list");
        setVisibleEditImage(!visibleEditImage);
        setFileName(undefined);
        setRenderNoti("image");
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const editLoanName = () => {
    setVisibleEditName(!visibleEditName);
  };

  const onChangeName = (e) => {
    setNameBank(e.target.value);
  };

  const handleShowOptions = (e, id) => {
    setLoanId(id);
    e.stopPropagation();

    logEvent(analytics, "screen_view", {
      screen_name: "loan.editionModal",
    });
  };

  return (
    (<Layout style={{ marginBottom: 240 }}>
      <LayoutOperations />
      <div style={{ padding: 16, marginTop: -45 }}>
        {renderNotification()}
        {loanList &&
          loanList.map((loan) => {
            return (
              <Card
                className="gcardMovile"
                style={{ marginTop: 16 }}
                cover={
                  loan?.version !== "2023.v1" ? 
                  <>
                  <img
                    src={
                      updateLoan
                    }
                    alt="initCard"
                    style={{
                      width: "100%",
                      height: loan.image !== undefined ? "84px" : "auto",
                      objectFit: "cover",
                      objectPosition: "20% 0%",
                    }}
                  />
                </>:
                  <>
                    <img
                      src={
                        loan.image !== undefined ? loan.image.link : loanInit
                      }
                      alt="initCard"
                      style={{
                        width: "100%",
                        height: loan.image !== undefined ? "84px" : "auto",
                        objectFit: "cover",
                        objectPosition: "20% 0%",
                      }}
                    />
                  </>
                }
              >
                <Row style={{ marginTop: -40 }}>
                  <Col span={4} style={{ marginTop: 30 }}>
                    {loan.bank !== undefined && loan.bank !== null ? (
                      <div>
                        <img
                          src={
                            "https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/banks/" +
                            `${loan.bank.bankCode + "_iso.png"}`
                          }
                          alt="banco"
                          style={{ height: "32px" }}
                        />
                      </div>
                    ) : (
                      <img
                        src={bankIcon}
                        style={{ height: 32, width: 32 }}
                      ></img>
                    )}
                  </Col>
                  <Col span={20}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 24,
                      }}
                    >
                      <Dropdown
                        overlay={menuComponent}
                        trigger={["click"]}
                        onClick={(e) => handleShowOptions(e, loan.id)}
                      >
                        <div>
                          {loan.name === undefined ||
                            (loan.name === null && (
                              <Text className="title-page">
                                {t("loan.typeMortgageTitle")}&nbsp;
                                {t("loanType." + loan.rateType)}
                                <img
                                  src={chebronDown}
                                  style={{
                                    height: 24,
                                    width: 24,
                                    marginLeft: 6,
                                  }}
                                ></img>
                              </Text>
                            ))}
                          {loan.name !== undefined && loan.name !== null && (
                            <Text className="title-page">
                              {loan.name}
                              <img
                                src={chebronDown}
                                style={{ height: 24, width: 24, marginLeft: 6 }}
                              ></img>
                            </Text>
                          )}
                        </div>
                      </Dropdown>

                      <Text
                        style={{
                          color: "#02C3CD",
                          fontSize: 14,
                          fontWeight: 500,
                        }}
                      >
                        {numeral(loan.quota).format("0,0 $") +
                          " " +
                          t("balance.savingsToMonth")}
                      </Text>
                    </div>
                  </Col>
                </Row>

                <div style={{ marginBottom: 16 }}>
                  <TipsList loan={loan}></TipsList>
                </div>

                <div>
                  <Button
                    className="button-primari-gibobs"
                    type="primary"
                    onClick={() =>{
                      if (loan?.version !== "2023.v1") {
                        navigate(`/my-financial-profile/credits/edit/form/${loan.id}`);
                      }else{
                        handleClickCard(loan)
                      }
                    } }
                    style={{
                      width: "100%",
                    }}
                  >
                   {loan?.version !== "2023.v1" ? t('scoring.update') : t('viability.myInterest')}
                  </Button>
                </div>
              </Card>
            );
          })}

        <Card
          className="cardButtonStyle"
          style={{ left: 0, textAlign: "center" }}
        >
          <Button
            className="button-primari-gibobs"
            type="primary"
            onClick={() => handleAddLoan()}
            style={{ width: "100%", height: 48 }}
          >
            {t("loan.addMortgage")}
          </Button>
        </Card>
      </div>
      <CardLinkAmigobs></CardLinkAmigobs>
      <Modal
        className="modal-common-mobile"
        width={328}
        open={visibleDelete}
        onCancel={() => {
          setVisibleDelete(!visibleDelete);
        }}
        onOk={() => handleModalDelete()}
        okText={
          <Text style={{ color: "#FFFFFF", fontWeight: 600, fontSize: 14 }}>
            {t("loan.delete")}
          </Text>
        }
        cancelText={
          <Text style={{ color: "#02C3CD", fontWeight: 600, fontSize: 14 }}>
            {t("offers.cancel")}
          </Text>
        }
        cancelButtonProps={{ style: { width: "50%", height: 40 } }}
        okButtonProps={{
          style: {
            width: "50%",
            height: 40,
            backgroundColor: "#FF4D4F",
            borderColor: "#FF4D4F",
          },
        }}
        title={
          <Text
            style={{
              fontSize: 16,
              fontWeight: 600,
              color: "#2F4858",
              marginTop: 24,
              marginBottom: 24,
            }}
          >
            {t("loan.eliminateMortgage")}
          </Text>
        }
      >
        <div
          style={{ display: "flex", flexDirection: "column", marginBottom: 10 }}
        >
          <Text
            style={{
              fontSize: 14,
              fontWeight: 400,
              color: "#748EA0",
            }}
          >
            {dataProfileDetails.name + " " + t("loan.modalDelete")}
          </Text>
          <Text
            style={{
              fontSize: 14,
              fontWeight: 400,
              color: "#748EA0",
            }}
          >
            {t("loan.modalDelete1")}
          </Text>
        </div>
      </Modal>
      <Modal
        className="modal-common-mobile"
        width={328}
        open={visibleEditName}
        onCancel={() => {
          setVisibleEditName(!visibleEditName);
        }}
        onOk={() => handleModalEditName()}
        okText={
          <Text style={{ color: "#FFFFFF", fontWeight: 600, fontSize: 14 }}>
            {t("viability.confirmButton")}
          </Text>
        }
        cancelText={
          <Text style={{ color: "#02C3CD", fontWeight: 600, fontSize: 14 }}>
            {t("offers.cancel")}
          </Text>
        }
        cancelButtonProps={{ style: { width: "50%", height: 40 } }}
        okButtonProps={{ style: { width: "50%", height: 40 } }}
        title={
          <Text
            style={{
              fontSize: 16,
              fontWeight: 600,
              color: "#2F4858",
              marginTop: 24,
              marginBottom: 24,
            }}
          >
            {t("loan.modalTitlteEditName")}
          </Text>
        }
      >
        <div
          style={{ display: "flex", flexDirection: "column", marginBottom: 10 }}
        >
          <Text
            style={{
              fontSize: 14,
              fontWeight: 400,
              color: "#748EA0",
            }}
          >
            {t("loan.editLoanNameDesktop")}
          </Text>

          <Input
            style={{ height: 40, marginTop: 16, width: "97%" }}
            placeholder={"Prestamo 1"}
            onChange={(value) => onChangeName(value)}
          ></Input>
        </div>
      </Modal>
      <Modal
        className="modal-common-mobile"
        width={328}
        open={visibleEditImage}
        onCancel={() => {
          setVisibleEditImage(!visibleEditImage);
        }}
        onOk={() => handleModalEditImage()}
        okText={
          <Text style={{ color: "#FFFFFF", fontWeight: 600, fontSize: 14 }}>
            {t("scoring.update")}
          </Text>
        }
        cancelText={
          <Text style={{ color: "#02C3CD", fontWeight: 600, fontSize: 14 }}>
            {t("offers.cancel")}
          </Text>
        }
        cancelButtonProps={{ style: { width: "50%", height: 40 } }}
        okButtonProps={{ style: { width: "50%", height: 40 } }}
        title={
          <Text
            style={{
              fontSize: 16,
              fontWeight: 600,
              color: "#2F4858",
              marginTop: 24,
              marginBottom: 24,
            }}
          >
            {t("modalLoan.editImage")}
          </Text>
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: 10,
            marginTop: -14,
          }}
        >
          <Text
            style={{
              fontSize: 14,
              fontWeight: 400,
              color: "#748EA0",
            }}
          >
            {t("loan.modalDescEditName")}
          </Text>

          {fileName === undefined && (
            <div
              style={{
                textAlign: "center",
                marginTop: 16,
                width: "100%",
                fontSize: "14px",
                fontWeight: 500,
                color: "#02C3CD",
              }}
            >
              <Upload {...uploadProps} style={{ display: "flex" }}>
                <a>{t("loan.modalEditImageAttach")}</a>
              </Upload>
            </div>
          )}
          {fileName !== undefined && (
            <div
              style={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#02C3CD",
                textAlign: "center",
                marginTop: 16,
                width: "100%",
              }}
            >
              {fileName}
            </div>
          )}
        </div>
      </Modal>
    </Layout>)
  );
};

export default LoanPageDetailsMobile;
