import React from "react";
import { Typography, Progress, Collapse } from "antd";
import ViabilityComponent from "../../../Operaciones/viabilityAndSimulation/viabilityComponent";
import env from "../../../../environment";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import FileOutlinedIcon from "../../../../assets/icons/FileOutlinedIcon.svg";
import VerticalAlignBottomOutlinedIcon from "../../../../assets/icons/VerticalAlignBottomOutlinedIcon.svg";

const { Text } = Typography;
const { Panel } = Collapse;

function ViabilityComponentInmo({
  viabilityData,
  dataMortgage,
  userMortgageId,
  dataOwner,
}) {
  const { t } = useTranslation();
  const userToken = useSelector((state) => state.auth.token);

  const data = [
    {
      titulo: "viability.financingCeilingTitle",
      viable:
        viabilityData && viabilityData.penalties
          ? viabilityData.penalties["financingCeiling"]
          : null,
    },
    {
      titulo: "viability.indebtednessTitle",
      viable:
        viabilityData && viabilityData.penalties
          ? viabilityData.penalties["indebtedness"]
          : null,
    },
    {
      titulo: "viability.seniorityTitle",
      viable:
        viabilityData && viabilityData.penalties
          ? viabilityData.penalties["seniority"]
          : null,
    },
    {
      titulo: "viability.incomesTitle",
      viable:
        viabilityData && viabilityData.penalties
          ? viabilityData.penalties["incomes"]
          : null,
    },
    {
      titulo: "viability.noresidentTitle",
      viable:
        viabilityData && viabilityData.penalties
          ? viabilityData.penalties["noresident"]
          : null,
    },
  ];

  let textViability;

  if (viabilityData && viabilityData.viability < 2) {
    textViability = t("viability.viabilityLessThan2");
  } else if (
    viabilityData &&
    viabilityData.viability < 3 &&
    viabilityData.viability >= 2
  ) {
    textViability = t("viability.viabilityBetween2and3");
  } else if (
    viabilityData &&
    viabilityData.viability < 4 &&
    viabilityData.viability >= 3
  ) {
    textViability = t("viability.viabilityBetween3and4");
  } else {
    textViability = t("viability.viabilityBetween4and5");
  }
  let textViabilitytitle;

  if (viabilityData && viabilityData.viability < 2) {
    textViabilitytitle = t("viability.viabilityLessThan2title");
  } else if (
    viabilityData &&
    viabilityData.viability < 3 &&
    viabilityData.viability >= 2
  ) {
    textViabilitytitle = t("viability.viabilityBetween2and3title");
  } else if (
    viabilityData &&
    viabilityData.viability < 4 &&
    viabilityData.viability >= 3
  ) {
    textViabilitytitle = t("viability.viabilityBetween3and4title");
  } else {
    textViabilitytitle = t("viability.viabilityBetween4and5title");
  }
  let documentViability;
  if (dataMortgage && dataOwner && viabilityData) {
    if (dataMortgage.type === "subrogation") {
      documentViability = `${env.api.url}/v1/banks/generate-pdf-viabilidad-subrogacion/${userMortgageId}/${i18n.language}?token=${userToken}`;
    } else if (dataMortgage.houseState === "have_decide") {
      documentViability = `${env.api.url}/v1/banks/generate-pdf-viabilidad-casaElegida/${userMortgageId}/${i18n.language}?token=${userToken}`;
    } else if (
      dataMortgage.houseState === "searching" &&
      viabilityData &&
      viabilityData.operation &&
      viabilityData.operation.mortgageAmountLimit === "savings"
    ) {
      documentViability = `${env.api.url}/v1/banks/generate-pdf-viabilidad-limite-ahorro/${userMortgageId}/${i18n.language}?token=${userToken}`;
    } else if (
      dataMortgage.houseState === "searching" &&
      viabilityData &&
      viabilityData.operation &&
      viabilityData.operation.mortgageAmountLimit === "fixed"
    ) {
      documentViability = `${env.api.url}/v1/banks/generate-pdf-viabilidad-limite-endeudamiento/${userMortgageId}/${i18n.language}?token=${userToken}`;
    }
  }

  return (
    <div>
      <div style={{ display: "flex", flexDirection: isMobile && "column" }}>
        <Progress
          type="dashboard"
          strokeColor={"#52C41A"}
          trailColor={"#F1F7F8"}
          percent={((viabilityData ? viabilityData.viability : null) * 100) / 5}
          format={() => (
            <div>
              <Text
                strong
                style={{
                  fontSize: "32px",
                  color: "#2F4858",
                  marginBottom: "4px",
                  display: "grid",
                }}
              >
                {viabilityData
                  ? numeral(viabilityData.viability).format("0.0")
                  : null}
              </Text>
              <Text
                style={{ fontSize: "14px", color: "#2F4858" }}
              >{`de 5`}</Text>
            </div>
          )}
          width={132}
          className="EstimatedViabilityProgress"
          style={{
            textAlign: "center",
            marginRight: 0,
          }}
        />
        <div
          className="EstimatedViabilityParagraphB"
          style={{
            marginLeft: isMobile ? 0 : 40,
            width: isMobile ? "auto" : 776,
            textAlign: !isMobile && "left",
          }}
        >
          <div>
            <Text
              style={{
                fontSize: "14px",
                height: "72px",
                color:
                  viabilityData && viabilityData.viability < 2
                    ? "#FAAD14"
                    : "#52C41A",
                fontWeight: "600",
              }}
            >
              {textViabilitytitle}
            </Text>
          </div>
          <div>
            <Text
              style={{
                fontSize: "14px",
                height: "72px",
                fontWeight: "400",
              }}
            >
              {textViability}
            </Text>
          </div>
        </div>
      </div>

      <Collapse
        expandIconPosition={"end"}
        defaultActiveKey={["1"]}
        ghost
        style={{
          marginTop: 32,
          marginLeft: -16,
          marginRight: -16,
          width: isMobile && "auto",
          backgroundColor: "white"
        }}
      >
        {viabilityData && (
          <Panel
            header={
              <div style={{ fontSize: 16, fontWeight: 600 }}>
                {t("viability.feasibilityIndicators")}
              </div>
            }
            key="1"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            {data.map((values) => {
              return (
                <ViabilityComponent
                  dataMortgage={dataMortgage}
                  key={values.titulo}
                  titulo={values.titulo}
                  viable={values.viable}
                  viabilityData={viabilityData}
                  inmo={true}
                  mortgageInmo={!dataMortgage.houseState ? true : false}
                />
              );
            })}
            <a
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#ffffff",
                padding: "12px 0",
                width: "100%",
                marginTop: !isMobile && -16,
              }}
              href={documentViability}
              target={"_blank"}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    height: 24,
                    width: 24,
                    textAlign: "center",
                    marginRight: 8,
                  }}
                >
                  <img
                    src={FileOutlinedIcon}
                    alt="FileOutlinedIcon"
                    height={20}
                  />
                </div>
                <div>
                  <Text
                    style={{ fontSize: 16, fontWeight: 400, color: "#2F4858" }}
                  >
                    {t("viability.feasibilityAnalysisButton")}
                  </Text>
                </div>
              </div>
              <div
                style={{
                  height: 24,
                  width: 24,
                  textAlign: "center",
                }}
              >
                <img
                  src={VerticalAlignBottomOutlinedIcon}
                  alt="VerticalAlignBottomOutlinedIcon"
                  height={15}
                />
              </div>
            </a>
          </Panel>
        )}
      </Collapse>
    </div>
  );
}

export default ViabilityComponentInmo;
