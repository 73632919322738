import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Pie } from "ant-design-pro/lib/Charts";
import "ant-design-pro/dist/ant-design-pro.css";
import numeral from "numeral";

const { Text } = Typography;

function PieClosedPassive({ realestate, vehicles, other }) {
  const { t } = useTranslation();
  const salesPieData = [
    {
      x: t("balance.realeStateTitle"),
      y: realestate,
    },
    {
      x: t("balance.vehiclesTitle"),
      y: vehicles,
    },
    {
      x: t("form.others"),
      y: other,
    },
  ];
  return (
    <>
      <Pie
        title="Activos"
        colors={["#41D2DA", "#FFC069", "#FF7875"]}
        hasLegend={false}
        data={salesPieData}
        valueFormat={(val) => <span>{numeral(val).format("0,0 $")}</span>}
        height={140}
        className="PieClosedActive"
      />
      <Text
        strong
        style={{
          marginRight: "10px",
          fontSize: "12px",
        }}
      >
        {t("balance.totalTitle")}
      </Text>
      <Text
        style={{
          color: "#02C3CD",
          fontSize: "12px",
        }}
      >
        {numeral(realestate + vehicles + other).format("0,00 $")}
      </Text>
    </>
  );
}

export default PieClosedPassive;
