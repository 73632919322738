import {
  DATASUMMARY,
  DATAPROFILE,
  SHOWPAY,
  PAYEND,
  CREATEASSET,
  GETASSET,
  RENAMEASSET,
  DELETEASSET,
  ECOBUDGET,
  MORTGAGEGREEN,
  SUBROGATIONGREEN,
  RENAMESEAT,
  DELETESEAT,
  UPDATESEAT,
  CREATESEAT,
  GETSEATS,
  MAXBUDGETGREEN,
  GETSCORING,
  COLLAPSEMENU,
  MODELIST,
  RENAMENAMESEAT,
  CHANGEPAGE,
  SIMULATELTV
} from "./constants";
import axios from "axios";
import env from "../../environment";
import { store } from "..";
import { mortgageId, operationsClients } from "../auth/actions";
import _ from "lodash";

export const overwriteSummary = (operationId, stage) => async (dispatch) => {
  const summary = store.getState().data.dataSummary;

  for (let i = 0; i < summary.length; i++) {
    if (summary[i].mortgageId === operationId) {
      summary[i].stage = stage;
    }
  }

  console.log("log juan", operationId, summary);

  dispatch({
    type: DATASUMMARY,
    payload: summary,
  });
};

export const dataSummary = (token) => async (dispatch) => {
  axios
    .get(`${env.api.url}/operations/operation/summary`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      dispatch({
        type: DATASUMMARY,
        payload: response.data.data,
      });
      dispatch(
        mortgageId(
          response.data.data[0] ? response.data.data[0].mortgageId : null
        )
      );

      dispatch(operationsClients(response.data.data));
    });
};

export const dataProfile = (token) => async (dispatch) => {
  axios
    .get(`${env.api.url}/v1/user/profile`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      dispatch({
        type: DATAPROFILE,
        payload: _.get(response, "data.data.profile", []),
      });
    });
};

export const createAsset = (token, values) => async (dispatch) => {
  axios
    .post(`${env.api.url}/v1/accounting/create-asset`, values, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      if (response.data.success) {
        dispatch(getAsset(store.getState().auth.token));
        dispatch({
          type: CREATEASSET,
          payload: response.data.data.profile,
        });
      }
    });
};

export const renameAsset = (token, values, id) => async (dispatch) => {
  axios
    .post(`${env.api.url}/v1/accounting/update-asset/${id}`, values, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      if (response.data.success) {
        dispatch(getAsset(store.getState().auth.token));
        dispatch({
          type: RENAMEASSET,
          payload: response.data.data.profile,
        });
      }
    });
};

export const deleteAsset = (token, id) => async (dispatch) => {
  axios
    .get(`${env.api.url}/v1/accounting/delete-asset/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      if (response.data.success) {
        dispatch(getAsset(store.getState().auth.token));
        dispatch({
          type: DELETEASSET,
          payload: response.data.data,
        });
      }
    });
};

export const getAsset = (token) => async (dispatch) => {
  axios
    .get(`${env.api.url}/v1/accounting/get-assets`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: GETASSET,
          payload: response.data.data,
        });
      }
    });
};

export const getSeats = (token, year) => async (dispatch) => {
  axios
    .get(`${env.api.url}/v1/accounting/get-seats/${year}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: GETSEATS,
          payload: response.data.data,
        });
      }
    });
};

export const createSeat = (token, values) => async (dispatch) => {
  axios
    .post(`${env.api.url}/v1/accounting/create-seat`, values, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      if (response.data.success) {
        dispatch(getSeats(store.getState().auth.token, 2021));
        dispatch({
          type: CREATESEAT,
          payload: response.data.data,
        });
      }
    });
};

export const renameSeat = (token, values, seatId) => async (dispatch) => {
  axios
    .post(`${env.api.url}/v1/accounting/update-seat/${seatId}`, values, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      if (response.data.success) {
        dispatch(getSeats(store.getState().auth.token, 2021));
        dispatch({
          type: RENAMESEAT,
          payload: response.data.data,
        });
      }
    });
};
export const renameNameSeat = (token, values, seatId) => async (dispatch) => {
  axios
    .post(`${env.api.url}/v1/accounting/rename-seat/${seatId}`, values, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      if (response.data.success) {
        dispatch(getSeats(store.getState().auth.token, 2021));
        dispatch({
          type: RENAMENAMESEAT,
          payload: response.data.data,
        });
      }
    });
};

export const deleteSeat = (token, seatId) => async (dispatch) => {
  axios
    .get(`${env.api.url}/v1/accounting/delete-seat/${seatId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      if (response.data.success) {
        dispatch(getSeats(store.getState().auth.token, 2021));
        dispatch({
          type: DELETESEAT,
          payload: response.data.data,
        });
      }
    });
};
export const getScoring = (token, mortgageId) => async (dispatch) => {
  axios
    .get(`${env.api.url}/v1/accounting/delete-seat/${mortgageId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: GETSCORING,
          payload: response.data.data,
        });
      }
    });
};

export const updateSeat = (token, values, seatId) => async (dispatch) => {
  axios
    .post(`${env.api.url}/v1/accounting/update-seat/${seatId}`, values, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      if (response.data.success) {
        dispatch(getSeats(store.getState().auth.token));
        dispatch({
          type: UPDATESEAT,
          payload: response.data.data,
        });
      }
    });
};

export const showPay = (booPay) => async (dispatch) => {
  dispatch({
    type: SHOWPAY,
    payload: booPay,
  });
};

export const payEnd = (pay) => async (dispatch) => {
  dispatch({
    type: PAYEND,
    payload: pay,
  });
};
export const ecoBudget = (eco) => async (dispatch) => {
  dispatch({
    type: ECOBUDGET,
    payload: eco,
  });
};
export const mortgageGreen = (boolen) => async (dispatch) => {
  dispatch({
    type: MORTGAGEGREEN,
    payload: boolen,
  });
};
export const subrogationGreen = (boolen) => async (dispatch) => {
  dispatch({
    type: SUBROGATIONGREEN,
    payload: boolen,
  });
};
export const maxbudgetGreen = (maxbudget) => async (dispatch) => {
  dispatch({
    type: MAXBUDGETGREEN,
    payload: maxbudget,
  });
};
export const collapseMenu = (menu) => async (dispatch) => {
  dispatch({
    type: COLLAPSEMENU,
    payload: menu,
  });
};

export const changeModelist = (modeList) => async (dispatch) => {
  dispatch({
    type: MODELIST,
    payload: modeList,
  });
};

export const moveScroolToTop = (changePage) => async (dispatch) => {
  dispatch({
    type: CHANGEPAGE,
    payload: changePage,
  });
};

export const simulateLtv = (simulateLtv) => async (dispatch) => {
  dispatch({
    type: SIMULATELTV,
    payload: simulateLtv,
  });
};

