import React, { useState } from 'react';
import {
	Select,
	Modal,
	Row,
	Col,
	Form,
	Input,
	Button,
	notification,
	Avatar,
	Typography,
	Divider
} from 'antd';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/lib/input/TextArea';
import { useSelector } from 'react-redux';
import axios from 'axios';
import env from '../../../environment';
import { notificationGibobs } from '../../HerramientasVenta/reusableItems';
import { useParams } from 'react-router-dom';
import { ReduxStore } from './welcomePageMobile';
import { MailOutlined, WhatsAppOutlined } from '@ant-design/icons';
const help = require('../../../assets/icons/help.png');
const DownOutlinedMobile = require('../../../assets/icons/DownOutlinedMobile.svg');

const { Option } = Select;
const { Text } = Typography;

const HelpButtonMobile = ({ simulation, origin }) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const params = useParams<{ operationId: string }>();
	const operationId = params.operationId;
	const [showModalHelp, setShowModalHelp] = useState(false);
	const userToken = useSelector<ReduxStore, string>((state) => state.auth.token);
	const dataPromoter = useSelector<ReduxStore, any>((state) => state.promoter.dataFormPromoter);

	const sendContactData = (values: any) => {
		for (const propName in values) {
			if (values[propName] === undefined) {
				delete values[propName];
			}
		}
		axios
			.post(
				`${env.api.url}/promoters/promoter-projects/contact-data/${operationId}?origin=${origin}`,
				values,
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					notification.success({
						className: 'notification-success-promoter',
						message: t('promoter.titleSuccess'),
						description: t('promoter.descriptionSuccess'),
						placement: 'top',
						duration: 4,
						style: { padding: 16 }
					});
				}
				if (!response.data.success) {
					notificationGibobs('error', t('promoterLoan.ModalTextError'));
				}
			})
			.catch((error) => {
				notificationGibobs('error', t('promoterLoan.ModalTextError'));
			});
		setShowModalHelp(!showModalHelp);
	};

	const validateMessages = {
		required: '${label}' + ' ' + t('form.formRequired'),
		types: {
			email: t('form.emailValidated'),
			number: '${label} is not a valid number!'
		},
		number: {
			range: t('form.maxBudgetNewRequestValidated') + '${min}'
		}
	};

	// if (simulation) {
	// 	//cara del analista TODO
	// 	return (
	// 		<>
	// 			<div
	// 				style={{
	// 					position: 'fixed',
	// 					bottom: 96,
	// 					right: 16,
	// 					cursor: 'pointer'
	// 				}}
	// 				onClick={() => setShowModalHelp(!showModalHelp)}>
	// 				<Avatar style={{ width: 64, height: 64 }}></Avatar>
	// 			</div>
	// 			<Modal
	// 				className="gb-modal-32-padding help-button-modal-mobile"
	// 				open={showModalHelp}
	// 				footer={null}
    //                 width={328}
	// 				title={
	// 					<div style={{ background: '#ECF7FC', paddingTop: 32, paddingLeft: 32, paddingRight: 32, borderBottom: '1px solid var(--secondary-l-2, #B8DEEF)' }}>
	// 						<Row>
	// 							<Col span={6}>
	// 								<Avatar style={{ width: 56, height: 56 }}></Avatar>
	// 							</Col>
	// 							<Col span={18} style={{ display: 'flex', flexDirection: 'column' }}>
	// 								<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>
	// 									Rodrigo Alonso
	// 								</Text>
	// 								<Text style={{ color: '#748EA0', fontSize: 12, fontWeight: 500 }}>
	// 									Tu Analista
	// 								</Text>
	// 							</Col>
	// 						</Row>
	// 						<Row style={{ marginTop: 10 }}>
	// 							<Col span={16}>
	// 								<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 500 }}>
	// 									91 223 75 26
	// 								</Text>
	// 							</Col>
	// 							<Col span={8} style={{ display: 'flex', justifyContent: 'right' }}>
	// 								<img
	// 									src="/images/promoter/whatsapp.png"
	// 									alt="whatsapp"
	// 									style={{ width: 24, height: 24 }}></img>
	// 							</Col>
	// 						</Row>
	// 						<Row style={{ marginTop: 4, marginBottom: 16 }}>
	// 							<Col span={16}>
	// 								<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 500 }}>
	// 									rodrigo.alonso@gibobs.com
	// 								</Text>
	// 							</Col>
	// 							<Col span={8} style={{ display: 'flex', justifyContent: 'right' }}>
	// 								<img
	// 									src="/images/promoter/mail.png"
	// 									alt="mail"
	// 									style={{ width: 24, height: 24 }}></img>
	// 							</Col>
	// 						</Row>
							
	// 					</div>
	// 				}
	// 				onCancel={() => setShowModalHelp(false)}>
	// 				<Form
	// 					form={form}
	// 					className="form-promoter-mobile"
	// 					style={{ marginBottom: 10 }}
	// 					scrollToFirstError={true}
	// 					layout="vertical"
	// 					name="Password"
	// 					validateMessages={validateMessages}
	// 					initialValues={dataPromoter && dataPromoter.owner ? { ...dataPromoter['owner'] } : {}}>
	// 					<div >
							
	// 						<div className="paragraph-style-mobile-second" style={{ marginBottom: 16 }}>
	// 							{t('promoter.modalAnalyst')}
	// 						</div>
	// 						<Form.Item
	// 							name="phone"
	// 							label={t('form.phone')}
	// 							rules={[
	// 								{
	// 									required: true
	// 								}
	// 							]}>
	// 							<Input placeholder={t('form.phone')} style={{ width: '100%' }} />
	// 						</Form.Item>
	// 						<Form.Item name="contactScheduleTypes" label={t('form.contactTime')}>
	// 							<Select
	// 								defaultValue={t('form.select')}
	// 								style={{ width: '100%' }}
	// 								suffixIcon={<img src={DownOutlinedMobile.default} alt="DownOutlinedMobile" />}>
	// 								<Option value="MORNINGS">{t('form.morning')}</Option>
	// 								<Option value="NOON">{t('form.afternoon')}</Option>
	// 								<Option value="AFTERNOONS">{t('form.evening')}</Option>
	// 							</Select>
	// 						</Form.Item>
	// 						<Form.Item name="comments" label={t('form.comments')}>
	// 							<TextArea
	// 								placeholder={t('form.comments')}
	// 								style={{
	// 									width: '100%'
	// 								}}
	// 							/>
	// 						</Form.Item>
	// 					</div>
	// 					<Row gutter={8}>
	// 						<Col span={12}>
	// 							<Button
	// 								className="button-secundary-gibobs"
	// 								style={{ width: '100%', borderRadius: 8 }}
	// 								onClick={() => setShowModalHelp(false)}>
	// 								{t('offers.cancel')}
	// 							</Button>
	// 						</Col>
	// 						<Col span={12}>
	// 							<Button
	// 								className="button-primari-gibobs"
	// 								style={{ width: '100%', borderRadius: 8 }}
	// 								onClick={() => {
	// 									form
	// 										.validateFields()
	// 										.then((values) => {
	// 											sendContactData(values);
	// 										})
	// 										.catch((info) => {
	// 											console.log('Validate Failed:', info);
	// 										});
	// 								}}>
	// 								{t('form.send')}
	// 							</Button>
	// 						</Col>
	// 					</Row>
	// 				</Form>
	// 			</Modal>
	// 		</>
	// 	);
	// } else {
		return (
			<>
				<div
					style={{
						position: 'fixed',
						bottom: 96,
						right: 16,
						cursor: 'pointer'
					}}
					onClick={() => setShowModalHelp(!showModalHelp)}>
					<img style={{ width: 64, height: 64 }} alt="" src={help}></img>
				</div>
				<Modal
					className="gb-modal-32-padding help-button-modal-mobile"
					title={null}
					open={showModalHelp}
					footer={null}
					onCancel={() => setShowModalHelp(false)}>
					<Form
						form={form}
						className="form-promoter-mobile"
						style={{ marginBottom: 10 }}
						scrollToFirstError={true}
						layout="vertical"
						name="Password"
						validateMessages={validateMessages}
						initialValues={dataPromoter && dataPromoter.owner ? { ...dataPromoter['owner'] } : {}}>
						<div style={{ paddingTop: 32 }}>
							<div className="title-primary" style={{ marginBottom: 8 }}>
								{simulation ? t('promoterLoan.contactForm') : t('button.initHelp')}
							</div>
							<div className="paragraph-style" style={{ marginBottom: 16 }}>
								{t('promoterLoan.analystContact')}
							</div>
							<Form.Item
								name="phone"
								label={t('form.phone')}
								rules={[
									{
										required: true
									}
								]}>
								<Input placeholder={t('form.phone')} style={{ width: '100%' }} />
							</Form.Item>
							<Form.Item name="contactScheduleTypes" label={t('form.contactTime')}>
								<Select
									defaultValue={t('form.select')}
									style={{ width: '100%' }}
									suffixIcon={<img src={DownOutlinedMobile.default} alt="DownOutlinedMobile" />}>
									<Option value="MORNINGS">{t('form.morning')}</Option>
									<Option value="NOON">{t('form.afternoon')}</Option>
									<Option value="AFTERNOONS">{t('form.evening')}</Option>
								</Select>
							</Form.Item>
							<Form.Item name="comments" label={t('form.comments')}>
								<TextArea
									placeholder={t('form.comments')}
									style={{
										width: '100%'
									}}
								/>
							</Form.Item>
						</div>
						<Row gutter={8}>
							<Col span={12}>
								<Button
									className="button-secundary-gibobs"
									style={{ width: '100%', borderRadius: 8 }}
									onClick={() => setShowModalHelp(false)}>
									{t('offers.cancel')}
								</Button>
							</Col>
							<Col span={12}>
								<Button
									className="button-primari-gibobs"
									style={{ width: '100%', borderRadius: 8 }}
									onClick={() => {
										form
											.validateFields()
											.then((values) => {
												sendContactData(values);
											})
											.catch((info) => {
												console.log('Validate Failed:', info);
											});
									}}>
									{t('form.send')}
								</Button>
							</Col>
						</Row>
					</Form>
				</Modal>
			</>
		);
	// }
};

export default HelpButtonMobile;
