import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import axios from 'axios';
import env from '../../environment';
import moment from 'moment';
import { getAnalytics, logEvent } from 'firebase/analytics';


export default ({ type, bankId, subType, bank, setLoanId, loanId, nameOwner }) => {
	const params = useParams();
	const operations = useSelector((state) => state.auth.operations);
	const nameOperations =
		operations !== undefined &&
		operations.find((operation) => operation.operationId === params.operationId);
	const userToken = useSelector((state) => state.auth.token);
	const [afterbanksData, setAfterbanksData] = useState(undefined);
	let myHtml = '';
	let currentOwner =
		type === 'mortgage' ? nameOwner : nameOperations && nameOperations.currentOwner;
	const [filterLoan, setFilterLoan] = useState(undefined);

	const simulationId = useSelector((state) => state.calculators.simulationId)

	let mapBank = [
		{ name: 'caixa', id: 'a021t00000KCqSYAA1' },
		{ name: 'bankinter', id: 'a021t00000Jm4QOAAZ' },
		{ name: 'bbva', id: 'a021t0000083gxcAAA' },
		{ name: 'caixa', id: 'a026N000000whFmQAI' },
		{ name: 'eurocajarural', id: 'a026N000000whKrQAI' },
		{ name: 'ibercaja', id: 'a021t000009Oj00AAC' },
		{ name: 'kutxabank', id: 'a026N000000whLpQAI' },
		{ name: 'liberbank', id: 'a026N000000whLuQAI' },
		{ name: 'pichincha', id: 'a026N000000whCTQAY' },
		{ name: 'sabadell', id: 'a021t000009OgpJAAS' },
		{ name: 'santander', id: 'a026N000000whCdQAI' },
		{ name: 'deutschebank', id: 'a026N000000whKNQAY' },
		{ name: 'ingdirect', id: 'a021t0000083gxXAAQ' }
	];

	const analytics = getAnalytics();


	useEffect(() => {
		if (type === 'loan') {
			const bankCodeTmp = _.find(mapBank, function (b) {
				return b.id === bank.id;
			});

			setFilterLoan(bankCodeTmp.name);
		} else {
			setFilterLoan('ALL');
		}

		if (type === 'loan') {
			if (subType === 'change') {
				changeManualToAutomatic();
			} else {
				generateIdAfterBanksLoan();
			}
		} else {
			if (nameOperations && nameOperations.type === 'certificate') {
				getDetailsCertificate();
			} else {
				generateIdAfterBanks();
			}
		}
	}, []);

	const getDetailsCertificate = async () => {
		const response = await axios.get(`${env.api.url}/v1/operations/${params.operationId}`, {
			headers: { Authorization: `Bearer ${userToken}` }
		});
		currentOwner = response.data.data.operationData.certificate.currentOwner;
		generateIdAfterBanks();
	};

	const changeManualToAutomatic = () => {
		axios
			.post(
				`${env.api.url}/v1/loans/convert-creation-type`,
				{
					loanId: loanId
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				setAfterbanksData(response.data.data);
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const generateIdAfterBanks = () => {
		axios
			.post(
				`${env.api.url}/v1/provider-reference/generate`,
				{
					provider: 'AFTERBANKS',
					refId: params.operationId,
					refType: 'OPERATION',
					refPath: 'operationData.' + currentOwner + '.bankReaderId'
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				setAfterbanksData(response.data.data);
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const generateIdAfterBanksLoan = () => {
		logEvent(analytics, 'screen_view', {
			screen_name: 'click.auto.form'
		});

		axios
			.post(
				`${env.api.url}/v1/loans/create-automatic`,
				{
					bankId: bankId,
					simulationId: simulationId,
					version: "2023.v1"
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				setAfterbanksData(response.data.data);
				setLoanId(response.data.data.loan.id);
			})
			.catch((error) => {
				console.log(error)
			});
	};

	if (afterbanksData !== undefined) {
		myHtml = `
    <!DOCTYPE html>
    <html lang="es">
       <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Arcopay Widget</title>
       </head>
       <body>
          <div id='arcopayContainer'></div>
          <script type='text/javascript'>
             let parameters = {
               "id": "${type === 'loan' ? afterbanksData.loan.id : afterbanksData.externalId}",
               "action": "read",
               "countryCode": "ALL",
               "banksShown": "ALL",
               "banksShownV3": "${filterLoan}",
               "defaultBank": "${filterLoan}",
               "autoStart": 0,
               "defaultLanguage": "ES",
               "showSandbox": 0,
               "showSplashScreen": 0,
               "showCheckTerms": 0,
               "URLredirectAfterOK": "",
               "URLredirectAfterKO": "",
               "dailyFrequency": 4,
               "validUntil": "${moment().add(1, 'month').format('DD-MM-YYYY')}",
               "paymentType": "normal",
               "amount": 0.01,
               "currency": "EUR",
               "sourceIBAN": "",
               "sourceCreditorName": "",
               "destinationIBAN": "ES4400490001532110022225",
               "destinationCreditorName": "Cruz Roja",
               "paymentDescription": "",
               "firstQuotaDay": "",
               "frequency": "",
               "numberOfQuotas": ""
             };
          </script>
          <script>
             function receiveFromFrame(ev) {
                 if (ev.data != ''&& ev.data.code != undefined && ev.data.message != undefined) {
                     let codigo = ev.data.code;
                     let mensaje = ev.data.message;
                     let widgetContainer = document.getElementById('arcopayContainer');
             
                     switch (codigo) {
                         case 200:
                             if (parameters.action == 'read') {
                                 document.getElementById('frameResponse').innerHTML = 'Lectura finalizada correctamente.';
                             } else {
                                 document.getElementById('frameResponse').innerHTML = 'Pago finalizado correctamente.';
                             }
                             break;
             case 9999: // Cambio en el tamaño del widget
                             widgetContainer.style.height = mensaje + 'px';
                             window.scrollTo(0, 0);
                             document.getElementById('iframeArcopay').contentWindow.postMessage({"action": "changeIframeHeight"}, 'https://www.afterbanks.com/');
                             break;
             
                         default:
                             document.getElementById('frameResponse').innerHTML = codigo - mensaje;
                             break;
                     }
                 }
             }
             window.addEventListener('message', receiveFromFrame, false);
          </script>
          <script type='text/javascript' src="https://static.afterbanks.com/appmain/PSD2ExternalForm/js/external.js"></script>
          
       </body>
    </html>

`;
	}

	return (
		<iframe
			width={'100%'}
			height={'500px'}
			srcdoc={myHtml}
			style={{ marginTop: 50, border: 'none' }}></iframe>
	);
};
