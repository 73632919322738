import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import numeral from "numeral";

const UsesTable = ({ data }) => {
  const { t } = useTranslation();

  const dataSource = [
    {
      key: 1,
      name: t("promoterLoan.landPurchase"),
      euroData: numeral(
        data && data.assetAcquisition && data.assetAcquisition.value
      ).format("0,0"),
      moneyPerSquareMeter: numeral(
        data &&
          data.assetAcquisition &&
          data.assetAcquisition.moneyPerSquareMeter
      ).format("0,0"),
      costes: numeral(
        data && data.assetAcquisition && data.assetAcquisition.percentage
      ).format("0.0%"),
      ingresos: 23.5,
      style: { backgroundColor: "red" },
    },
    {
      key: 2,
      name: t("promoterLoan.totalConstruction"),
      euroData: numeral(
        data && data.totalConstructionCosts && data.totalConstructionCosts.value
      ).format("0,0"),
      moneyPerSquareMeter: numeral(
        data &&
          data.totalConstructionCosts &&
          data.totalConstructionCosts.moneyPerSquareMeter
      ).format("0,0"),
      costes: numeral(
        data &&
          data.totalConstructionCosts &&
          data.totalConstructionCosts.percentage
      ).format("0.0%"),
      ingresos: 52.6,
      children: [
        {
          key: 11,
          name: t("promoterLoan.commercialCosts"),
          euroData: numeral(
            data &&
              data.totalConstructionCosts &&
              data.totalConstructionCosts.hardCosts &&
              data.totalConstructionCosts.hardCosts.value
          ).format("0,0"),
          moneyPerSquareMeter: numeral(
            data &&
              data.totalConstructionCosts &&
              data.totalConstructionCosts.hardCosts &&
              data.totalConstructionCosts.hardCosts.moneyPerSquareMeter
          ).format("0,0"),
          costes: numeral(
            data &&
              data.totalConstructionCosts &&
              data.totalConstructionCosts.hardCosts &&
              data.totalConstructionCosts.hardCosts.percentage
          ).format("0.0%"),
          ingresos: 43.8,
        },
        {
          key: 12,
          name: t("promoterLoan.associatedCosts"),
          euroData: numeral(
            data &&
              data.totalConstructionCosts &&
              data.totalConstructionCosts.softCosts &&
              data.totalConstructionCosts.softCosts.value
          ).format("0,0"),
          moneyPerSquareMeter: numeral(
            data &&
              data.totalConstructionCosts &&
              data.totalConstructionCosts.softCosts &&
              data.totalConstructionCosts.softCosts.moneyPerSquareMeter
          ).format("0,0"),
          costes: numeral(
            data &&
              data.totalConstructionCosts &&
              data.totalConstructionCosts.softCosts &&
              data.totalConstructionCosts.softCosts.percentage
          ).format("0.0%"),
          ingresos: 8.9,
        },
      ],
    },
    {
      key: 3,
      name: t("promoterLoan.commercialCosts"),
      euroData: numeral(data && data.mktCosts && data.mktCosts.value).format(
        "0,0"
      ),
      moneyPerSquareMeter: numeral(
        data && data.mktCosts && data.mktCosts.moneyPerSquareMeter
      ).format("0,0"),
      costes: numeral(data && data.mktCosts && data.mktCosts.percentage).format(
        "0.0%"
      ),
      ingresos: 3.2,
    },
    {
      key: 4,
      name: t("promoterLoan.managementCosts"),
      euroData: numeral(
        data && data.otherCosts && data.otherCosts.value
      ).format("0,0"),
      moneyPerSquareMeter: numeral(
        data && data.otherCosts && data.otherCosts.moneyPerSquareMeter
      ).format("0,0"),
      costes: numeral(
        data && data.otherCosts && data.otherCosts.percentage
      ).format("0.0%"),
      ingresos: 3.0,
    },
    {
      key: 5,
      name: t("promoterLoan.financingServices"),
      euroData: numeral(
        data && data.servicesCosts && data.servicesCosts.value
      ).format("0,0"),
      moneyPerSquareMeter: numeral(
        data && data.servicesCosts && data.servicesCosts.moneyPerSquareMeter
      ).format("0,0"),
      costes: numeral(
        data && data.servicesCosts && data.servicesCosts.percentage
      ).format("0.0%"),
      ingresos: 6.4,
    },
    {
      key: 6,
      name: t("balance.totalTitle"),
      euroData: numeral(data && data.total && data.total.value).format("0,0"),
      moneyPerSquareMeter: numeral(
        data && data.total && data.total.moneyPerSquareMeter
      ).format("0,0"),
      costes: numeral(data && data.total && data.total.percentage).format(
        "0.0%"
      ),
      ingresos: 88.6,
    },
  ];

  const columns = [
    {
      title: t("promoterLoan.Uses"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: "€",
      dataIndex: "euroData",
      key: "euroData",
      align: "right",
    },
    {
      title: "%",
      dataIndex: "costes",
      key: "costes",
      align: "right",
    },
    {
      title: "€/m²",
      dataIndex: "moneyPerSquareMeter",
      key: "moneyPerSquareMeter",
      align: "right",
    },
  ];

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      rowClassName={(record) => {
        const classes = ["textStyleTable"];
        if (record.key === 1 || record.key === 9 || record.key === 11) {
          classes.push("textStyleTableOfferSelect");
        }

        if (record.key === 6 || record.key === 7 || record.key === 8) {
          classes.push("textStyleTotal");
        }
        if (record.key === 6) {
          classes.push("textStyleTotal");
          classes.push("textStyleTotalColor");
        }
        return classes;
      }}
      className="table-style-general"
      style={{ width: "50.0%" }}
    />
  );
};

export default UsesTable;
