import React from "react";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import blueRightArrowIcon from "../../../../assets/icons/blueRigthArrowIcon.svg";

const BreadcrumbsParticular = ({  video, returnPage, certificated }) => {
  const navigate = useNavigate();;
  const { t } = useTranslation();


  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: isMobile
          ? 16
          : certificated
          ? "9px 0px 24px 0px"
          : "9px 40px 24px 40px",
        paddingBottom: isMobileOnly ? 16 : 24,
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => navigate(returnPage)}
        >
          <div
            style={{
              height: 24,
              width: 24,
              textAlign: "center",
            }}
          >
            <img
              src={blueRightArrowIcon}
              alt="blueRightArrowIcon"
              height={12}
            />
          </div>
        </div>
        <div
          style={{
            color: "#02C3CD",
            fontSize: 16,
            fontWeight: 600,
            marginLeft: 16,
          }}
        >
          {t('offers.products')}
        </div>
      </div>
      
    </div>
  );
};
export default BreadcrumbsParticular;
