import {
  DOCUMENTS,
  PARENTID,
  BREADCRUMB,
  LOADING,
  FOLDER,
  VIRTUAL
} from "./constants";


export const INITIAL_STATE = {
  documents: [],
  loading: false,
  breadcrumb: [],
  folder: {},
  virtual: false
};


export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DOCUMENTS:
      return { ...state, documents: action.payload };
    case LOADING:
      return { ...state, loading: action.payload };
    case PARENTID:
      return { ...state, parentId: action.payload };
    case BREADCRUMB:
      return { ...state, breadcrumb: action.payload };
    case FOLDER:
      return { ...state, folder: action.payload };
    case VIRTUAL:
      return { ...state, virtual: action.payload };
    default:
      return state;
  }
};