import React, { useEffect, useState } from 'react';
import { Form, Row, Card, Button } from 'antd';
import { isMobileOnly } from 'react-device-detect';
import TargetsForm1 from '../mortageForms/TargetsForm1';
import TaxCalculationForm2 from '../mortageForms/TaxCalculationForm2';
import RequiredFinancingForm3 from '../mortageForms/RequiredFinancingForm3';
import MortgageTermForm4 from '../mortageForms/MortgageTermForm4';
import DetailHoldersForm5 from '../mortageForms/DetailHoldersForm5';
import { notificationGibobs } from '../../../HerramientasVenta/reusableItems';
import { useTranslation } from 'react-i18next';
import TargetsSubrogationForm1 from '../subrogationForms/TargetsSubrogationForm1';
import CurrentMortgageForm2 from '../subrogationForms/CurrentMortgageForm2';
import HavePendingForm4 from '../subrogationForms/HavePendingForm4';
import CurrentConditionsForm3 from '../subrogationForms/CurrentConditionsForm3';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import env from '../../../../environment';
import { useParams } from 'react-router-dom';
import {
	setDataViabilityForMortgage,
	setDataViabilityForSubrogationOnboarding
} from '../../../../store/viability/actions';
import { moveScroolToTop } from '../../../../store/data/actions';
import AdditionalGuarantee from '../Modals/AdditionalGuarantee';
import { getAnalytics, logEvent } from 'firebase/analytics';

import dayjs from 'dayjs';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import _ from 'lodash';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');
dayjs.locale('vi');

const EditData = ({
	changePage,
	type,
	dataOperation,
	refreshData,
	setPages,
	setRecalculateViability
}) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { operationId } = useParams();
	const [, setLoading] = useState(false);
	const userToken = useSelector((state) => state.auth.token);
	const moveTop = useSelector((state) => state.data.changePage);
	const [visibleModalGuarantee, setVisibleModalGuarantee] = useState(false);
	const analytics = getAnalytics();

	useEffect(() => {
		refreshData();
	}, [dataOperation.subrogation && dataOperation.subrogation.amount === undefined]);

	useEffect(() => {
		logEvent(analytics, 'screen_view', {
			screen_name: 'onboarding2022.edit'
		});
	}, []);

	const validateMessages = {
		required: '${label}' + ' ' + t('form.formRequired'),
		types: {
			email: t('form.emailValidated'),
			number: '${label} is not a valid number!'
		},
		number: {
			range: t('form.maxBudgetNewRequestValidated') + '${min}'
		}
	};
	const openNotificationWithIcon = () => {
		notificationGibobs('error', t('m_full.errorTitle'), t('form.incompleteInformation'));
	};
	const onFinishFormRecalculate = (values) => {
		logEvent(analytics, 'screen_view', {
			screen_name: 'onboarding2022.onedit'
		});
		const obj = {};
		for (let clave in values) {
			if (clave !== 'dateEndMortgage') {
				if (!isNaN(values[clave])) {
					values[clave] = Number(values[clave]);
				}
			}
			if (clave === 'dateEndMortgage') {
				values.dateEndMortgage = dayjs(dayjs(values.dateEndMortgage).format('YYYY/MM/15'));
			}
			if (clave === 'dateSign') {
				values.dateSign = dayjs(dayjs(values.dateSign).format('YYYY/MM/15'));
			}
		}

		const mortgage = {
			houseType: values.houseType,
			maxBudget: values.maxBudget,
			propertyType: values.propertyType,
			province: values.province,
			purchaseForecast: values.purchaseForecast,
			savings: values.savings,
			participants: values.participants
		};
		Object.assign(obj, mortgage);
		const owner1 = {
			owner1: {
				age: values.age,
				activity: values.activity1,
				seniority: values.seniority1,
				netIncomes: values.netIncomes1,
				loanPayment: values.loanPayment1,
				cityzenship: values.cityzenship
			}
		};
		Object.assign(obj, owner1);
		let owner2 = undefined;
		if (type === 'subrogation') {
			owner2 = {
				owner2: {
					activity:
						values.activity2 === undefined && values.participants === 2
							? dataOperation.owner2.activity
							: values.activity2
				}
			};
		} else {
			owner2 = {
				owner2: {
					activity:
						values.activity2 === undefined && values.participants === 2
							? dataOperation.owner2.activity
							: values.activity2,
					seniority:
						values?.seniority2 === undefined && values.participants === 2
							? dataOperation.owner2.seniority
							: values?.seniority2,
					netIncomes:
						values?.netIncomes2 === undefined && values.participants === 2
							? dataOperation.owner2.netIncomes
							: values.netIncomes2,
					loanPayment:
						values?.loanPayment2 === undefined && values.participants === 2
							? dataOperation?.owner2 !== undefined
								? dataOperation?.owner2?.loanPayment
								: 0
							: values?.loanPayment2
				}
			};
		}
		if (values.activity2 !== undefined || values.participants === 2) {
			Object.assign(obj, owner2);
		}
		if (type === 'subrogation') {
			Object.assign(obj, values);
		}
		axios
			.post(`${env.api.url}/mortgages/${type}/update/${operationId}`, obj, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setLoading(true);
				if (response.data.success) {
					axios
						.post(
							`${env.api.url}/mortgages/pre-viability/checks`,
							{
								operationId: operationId
							},
							{
								headers: { Authorization: `Bearer ${userToken}` }
							}
						)
						.then((response) => {
							if (response.data.data.additionalGuarantee) {
								setVisibleModalGuarantee(response.data.data.additionalGuarantee);
								setLoading(false);
								refreshData();
								setPages(1);
								setRecalculateViability(true);
								dispatch(moveScroolToTop(!moveTop));
							} else {
								sendAdditionalGuarantee(false); // by default not question
								refreshData();
								setPages(1);
								setRecalculateViability(true);
								dispatch(moveScroolToTop(!moveTop));
								window.location.reload();
							}
						});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const sendAdditionalGuarantee = (values) => {
		axios
			.post(
				`${env.api.url}/mortgages/mortgage/step5extra/${operationId}`,
				{ canHavePaidOwnedHousesOrCanHaveGuarantors: values },
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				setLoading(true);
				if (response.data.success) {
					if (type !== 'subrogation') {
						dispatch(setDataViabilityForMortgage(operationId, userToken));
					}
					if (type === 'subrogation') {
						dispatch(
							setDataViabilityForSubrogationOnboarding(
								dataOperation && dataOperation.subrogation.amount,
								dataOperation && dataOperation.subrogation.dateEndMortgage,
								dataOperation &&
									dataOperation.subrogation.interest &&
									dataOperation.subrogation.interest === 'fixed'
									? dataOperation.subrogation.haveATin
									: undefined,
								dataOperation &&
									dataOperation.subrogation.interest &&
									dataOperation.subrogation.interest === 'variable'
									? dataOperation.subrogation.haveATin
									: undefined,
								operationId,
								userToken
							)
						);
					}

					setVisibleModalGuarantee(false);
					//setPages(stage + 1);
					axios
						.post(
							`${env.api.url}/v1/mortgages/data/update/${operationId}`,
							{
								key: 'basicDeclarativesCompleted',
								participant: 'process',
								value: true
							},
							{
								headers: { Authorization: `Bearer ${userToken}` }
							}
						)
						.then((response) => {
							console.log(response);
						});
				}
				refreshData();
				setPages(1);
				setRecalculateViability(true);
				dispatch(moveScroolToTop(!moveTop));
			})
			.catch((error) => {
				console.log(error);
			});
	};

	if (type !== 'mortgage' && !_.isNil(dataOperation.subrogation.amount)) {
		return (
			<div
				style={{
					padding: !isMobileOnly && 40,
					marginBottom: isMobileOnly ? 220 : 150
				}}>
				<div style={{ padding: isMobileOnly && '16px 16px 0px', marginBottom: 40 }}>
					{changePage}
				</div>
				{/* <div
          className="logo-onboarding"
          style={{
            marginBottom: !isMobileOnly && 40,
          }}
        >
          {" "}
          <img src={Gibobs} alt="Gibobs" />
        </div> */}
				<Form
					className={'gb-form-profile-headlines-mobile gb-form-separate-24'}
					onFinishFailed={openNotificationWithIcon}
					scrollToFirstError={true}
					form={form}
					name="nest-messages"
					onFinish={onFinishFormRecalculate}
					layout="vertical"
					validateMessages={validateMessages}
					initialValues={
						dataOperation === undefined
							? { interest: 'fixed' }
							: type === 'mortgage' &&
							  dataOperation &&
							  dataOperation.mortgage &&
							  dataOperation.owner1 &&
							  dataOperation.owner2
							? {
									...dataOperation['mortgage'],
									activity1: dataOperation.owner1.activity,
									seniority1: dataOperation.owner1.seniority,
									netIncomes1: dataOperation.owner1.netIncomes,
									loanPayment1: dataOperation.owner1.loanPayment,
									activity2: dataOperation.owner2.activity,
									seniority2: dataOperation.owner2.seniority,
									netIncomes2: dataOperation.owner2.netIncomes,
									loanPayment2: dataOperation.owner2.loanPayment,
									age: dataOperation.owner1.age,
									cityzenship: dataOperation.owner1.cityzenship,
									participants: dataOperation?.mortgage?.participants
							  }
							: type === 'mortgage' &&
							  dataOperation &&
							  dataOperation.mortgage &&
							  dataOperation.owner1
							? {
									...dataOperation['mortgage'],
									activity1: dataOperation.owner1.activity,
									seniority1: dataOperation.owner1.seniority,
									netIncomes1: dataOperation.owner1.netIncomes,
									loanPayment1: dataOperation.owner1.loanPayment,
									age: dataOperation.owner1.age,
									cityzenship: dataOperation.owner1.cityzenship,
									participants: dataOperation?.mortgage?.participants
							  }
							: type === 'subrogation' &&
							  dataOperation.subrogation &&
							  dataOperation.owner1 &&
							  dataOperation.owner2 === undefined
							? {
									...dataOperation['subrogation'],
									activity1: dataOperation.owner1.activity,
									dateSign: dataOperation.subrogation.dateSign
										? dayjs(dataOperation.subrogation.dateSign)
										: undefined,
									dateEndMortgage: dayjs(dataOperation.subrogation.dateEndMortgage),
									amount: dataOperation.subrogation.amount,
									haveATin: dataOperation?.subrogation?.haveATin,
									participants: dataOperation?.mortgage?.participants
							  }
							: {
									...dataOperation['subrogation'],
									activity1: dataOperation.owner1.activity,
									activity2: dataOperation.owner2.activity,
									participants: dataOperation?.mortgage?.participants,
									haveATin: dataOperation?.subrogation?.haveATin,
									dateSign: dataOperation.subrogation.dateSign
										? dayjs(dataOperation.subrogation.dateSign)
										: undefined,
									dateEndMortgage: dayjs(dataOperation.subrogation.dateEndMortgage)
							  }
					}>
					{type === 'mortgage' && (
						<Row
							gutter={isMobileOnly ? 32 : 24}
							style={{
								marginLeft: 'auto',
								marginRight: 'auto'
							}}>
							<TargetsForm1 edit={true} />
							<TaxCalculationForm2 edit={true} />
							<RequiredFinancingForm3 edit={true} />
							<MortgageTermForm4 edit={true} />
							<DetailHoldersForm5
								edit={true}
								participant={dataOperation?.mortgage?.participants}
								form={form}
							/>
						</Row>
					)}
					{type === 'subrogation' && (
						<Row
							gutter={isMobileOnly ? 32 : 24}
							style={{
								marginLeft: 'auto',
								marginRight: 'auto'
							}}>
							<TargetsSubrogationForm1 edit={true} />
							<CurrentMortgageForm2 edit={true} dataOperation={dataOperation} />
							<CurrentConditionsForm3
								edit={true}
								interestData={
									dataOperation && dataOperation.subrogation && dataOperation.subrogation.interest
								}
								form={form}
								dataOperation={dataOperation}
							/>
							<HavePendingForm4 edit={true} />
							<DetailHoldersForm5
								edit={true}
								subrogation={true}
								participant={dataOperation?.mortgage?.participants}
								form={form}
							/>
						</Row>
					)}
					{isMobileOnly && (
						<Card
							className="card-button-style-onboading"
							style={{
								left: 0,
								marginBottom: -2,
								backgroundColor: '#FAFEFE'
							}}>
							<Button
								id="gb-mortgage-buy-situation-mobile"
								className="button-primary-gibobs-inmo"
								type="primary"
								style={{
									fontSize: '14px',
									width: '100%',
									height: 48,
									fontWeight: 600
								}}
								// loading={loading}
								htmlType="submit">
								{t('viability.continue')}
							</Button>
						</Card>
					)}
					{!isMobileOnly && (
						<div style={{ textAlign: 'center', marginTop: 40 }}>
							<Button
								id="gb-mortgage-buy-situation-mobile"
								className="button-primary-gibobs-inmo"
								type="primary"
								style={{
									fontSize: '14px',
									width: 248,
									height: 48,
									fontWeight: 600
								}}
								// loading={loading}
								htmlType="submit">
								{t('onboarding.recalculate')}
							</Button>
						</div>
					)}
				</Form>
			</div>
		);
	} else {
		if (type === 'mortgage') {
			return (
				<div
					style={{
						padding: !isMobileOnly && 40,
						marginBottom: isMobileOnly ? 220 : 150
					}}>
					<div
						style={{
							padding: isMobileOnly && '16px 16px 0px',
							marginBottom: 40
						}}>
						{changePage}
					</div>
					{/* <div
            className="logo-onboarding"
            style={{
              marginBottom: !isMobileOnly && 40,
            }}
          >
            {" "}
            <img src={Gibobs} alt="Gibobs" />
          </div> */}
					<Form
						className={'gb-form-profile-headlines-mobile gb-form-separate-24'}
						onFinishFailed={openNotificationWithIcon}
						scrollToFirstError={true}
						form={form}
						name="nest-messages"
						onFinish={onFinishFormRecalculate}
						layout="vertical"
						validateMessages={validateMessages}
						initialValues={
							dataOperation === undefined
								? { interest: 'fixed' }
								: type === 'mortgage' &&
								  dataOperation &&
								  dataOperation.mortgage &&
								  dataOperation.owner1 &&
								  dataOperation.owner2
								? {
										...dataOperation['mortgage'],
										activity1: dataOperation.owner1.activity,
										seniority1: dataOperation.owner1.seniority,
										netIncomes1: dataOperation.owner1.netIncomes,
										loanPayment1: dataOperation.owner1.loanPayment,
										activity2: dataOperation.owner2.activity,
										seniority2: dataOperation.owner2.seniority,
										netIncomes2: dataOperation.owner2.netIncomes,
										loanPayment2: dataOperation.owner2.loanPayment,
										age: dataOperation.owner1.age,
										cityzenship: dataOperation.owner1.cityzenship,
										participants: dataOperation?.mortgage?.participants
								  }
								: type === 'mortgage' &&
								  dataOperation &&
								  dataOperation.mortgage &&
								  dataOperation.owner1 &&
								  dataOperation.owner2 === undefined
								? {
										...dataOperation['mortgage'],
										activity1: dataOperation.owner1.activity,
										seniority1: dataOperation.owner1.seniority,
										netIncomes1: dataOperation.owner1.netIncomes,
										loanPayment1: dataOperation.owner1.loanPayment,
										age: dataOperation.owner1.age,
										cityzenship: dataOperation.owner1.cityzenship,
										participants: dataOperation?.mortgage?.participants
								  }
								: type === 'subrogation' &&
								  dataOperation.subrogation && {
										...dataOperation['subrogation'],
										dateEndMortgage: dayjs(dataOperation.subrogation.dateEndMortgage),
										amount: dataOperation.subrogation.amount,
										haveATin: dataOperation?.subrogation?.haveATin,
										dateSign: dataOperation?.subrogation?.dateSign
											? dayjs(dataOperation.subrogation.dateSign)
											: undefined
								  }
						}>
						{type === 'mortgage' && (
							<Row
								gutter={isMobileOnly ? 32 : 24}
								style={{
									marginLeft: 'auto',
									marginRight: 'auto'
								}}>
								<TargetsForm1 edit={true} />
								<TaxCalculationForm2 edit={true} />
								<RequiredFinancingForm3 edit={true} />
								<DetailHoldersForm5
									edit={true}
									participant={dataOperation?.mortgage?.participants}
									form={form}
								/>
								{/* <MortgageTermForm4 edit={true} /> */}
							</Row>
						)}
						{type === 'subrogation' && (
							<Row
								gutter={isMobileOnly ? 32 : 24}
								style={{
									marginLeft: 'auto',
									marginRight: 'auto'
								}}>
								<TargetsSubrogationForm1 edit={true} />
								<CurrentMortgageForm2 edit={true} />
								<CurrentConditionsForm3
									edit={true}
									interestData={
										dataOperation && dataOperation.subrogation && dataOperation.subrogation.interest
									}
									form={form}
									dataOperation={dataOperation}
								/>
								<HavePendingForm4 edit={true} />
							</Row>
						)}
						{isMobileOnly && (
							<Card
								className="card-button-style-onboading"
								style={{
									left: 0,
									marginBottom: -2,
									backgroundColor: '#FAFEFE'
								}}>
								<Button
									id="gb-mortgage-buy-situation-mobile"
									className="button-primary-gibobs-inmo"
									type="primary"
									style={{
										fontSize: '14px',
										width: '100%',
										height: 48,
										fontWeight: 600
									}}
									// loading={loading}
									htmlType="submit">
									{t('onboarding.recalculate')}
								</Button>
							</Card>
						)}
						{!isMobileOnly && (
							<div style={{ textAlign: 'center' }}>
								<Button
									id="gb-mortgage-buy-situation-mobile"
									className="button-primary-gibobs-inmo"
									type="primary"
									style={{
										fontSize: '14px',
										width: 248,
										height: 48,
										fontWeight: 600
									}}
									// loading={loading}
									htmlType="submit">
									{t('onboarding.recalculate')}
								</Button>
							</div>
						)}
					</Form>

					<AdditionalGuarantee
						key={'visibleModalGuarantee'}
						visible={visibleModalGuarantee}
						onCreate={(e) => sendAdditionalGuarantee(e)}
						onCancel={() => setVisibleModalGuarantee(false)}
						width={800}
					/>
				</div>
			);
		}
	}
};

export default EditData;
