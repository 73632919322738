import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import env from "../../../../environment";
import axios from "axios";
import StepsHorizontal from "./stepsHorizontal";
import { Button, Form, message, Card } from "antd";
import { refreshData } from "../../../../store/auth/actions";
import moment from "moment";
import { isMobile } from "react-device-detect";
import { getAnalytics, logEvent } from "firebase/analytics";
import { InputDate } from 'afrodita';

const FormalizationComponent = ({
  userMortgageId,
  dataMortgage,
  valuesData,
}) => {
  const { t } = useTranslation();
  const userToken = useSelector((state) => state.auth.token);
  const analytics = getAnalytics();

  useEffect(() => {

    logEvent(analytics, "screen_view", {
      screen_name: "m.component.formalization"
    })
  }, [])

  const updateData = (owner, key, value) => {
    axios
      .post(
        `${env.api.url}/v1/mortgages/data/update/${userMortgageId}`,
        { key, participant: owner, value },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        if (response.data.success) {
          refreshData();
        } else {
          message.error("Algo fue mal y no se pudo guardar");
        }
      });
  };

  return (
    <>
      <StepsHorizontal
        data={[
          {
            name: t("details.fein"),
            date:
              valuesData &&
              valuesData.appraisalStatus &&
              valuesData.appraisalStatus.feinAvailableDate,
          },
          {
            name: "Fecha estimada",
            date:
              dataMortgage &&
              dataMortgage.mortgage &&
              dataMortgage.mortgage.estimatedSignDate,
          },
        ]}
        status={
          dataMortgage &&
          dataMortgage.mortgage &&
          dataMortgage.mortgage.digitalContractLink
            ? 2
            : dataMortgage &&
              dataMortgage.mortgage &&
              dataMortgage.mortgage.estimatedSignDate
            ? 1
            : 0
        }
        width={isMobile ? "auto" : 300}
        formalization={true}
      />
      <Form layout="vertical">
        <Form.Item
          name="estimatedSignDate"
          label={t("formalization.dateEstimated")}
          style={{ marginTop: 32 }}
        >
          <InputDate
            type="date"
            style={{
              width: isMobile ? "100%" : "280px",
              height: "40px",
              borderRadius: "4px",
              borderColor: "#C0DAEE",
              fontSize: "12px",
              float: "left",
            }}
            onChange={(e) => {
              updateData(
                "mortgage",
                "signDate",
                moment(e, ["YYYY-MM-DD", "DD-MM-YYYY"]).format("YYYY-MM-DD")
              );
            }}
            disabled={
              dataMortgage &&
              dataMortgage.mortgage &&
              dataMortgage.mortgage.signDate
            }
          />
        </Form.Item>
      </Form>
      {isMobile && (
        <Card
          className="cardButtonStyle"
          style={{
            left: 0,
            marginBottom: -2,
            backgroundColor: "#FAFEFE",
          }}
        >
          <Button
            id="gb-mortgage-buy-situation-mobile"
            className="button-primary-gibobs-inmo"
            type="primary"
            style={{
              fontSize: 16,
              width: "100%",
              height: 48,
              fontWeight: 600,
            }}
            htmlType="submit"
          >
            {t("form.customerPaid")}
          </Button>
        </Card>
      )}
      {!isMobile && (
        <Button
          className="button-primary-gibobs-inmo"
          type="primary"
          style={{ width: 200, height: 40 }}
        >
          {t("form.customerPaid")}
        </Button>
      )}
    </>
  );
};

export default FormalizationComponent;
