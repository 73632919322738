import React from "react";
import { Typography } from "antd";
import ProgressBars from "./progressBars";
import PieClosed from "./PieClosed";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

const { Text } = Typography;

function WidgetsDashboardRealState({ data, title }) {
  const { t } = useTranslation();

  let graph;

  if (!isMobile) {
    if (title === "init.operationsBySteps") {
      graph = (
        <ProgressBars
          basic={data.data.basic}
          full={data.data.full}
          documents={data.data.documents}
          analysis={data.data.analysis}
          banks={data.data.banks}
          offers={data.data.offers}
          appraisal={data.data.appraisal}
          formalization={data.data.formalization}
          quantity={data.data.quantity}
        />
      );
    } else {
      graph = (
        <PieClosed
          gibobs={data.data.gibobs}
          others={data.data.others}
          banks={data.data.banks}
        />
      );
    }
  }

  return (
    <div>
      <div style={{ marginBottom: "20px", textAlign: "center" }}>
        <Text className="box-subtext">{t(title)}</Text>
      </div>
      {graph}
    </div>
  );
}

export default WidgetsDashboardRealState;
