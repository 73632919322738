import React, { useState, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
	Layout,
	Card,
	Typography,
	Button,
	Col,
	Row,
	Dropdown,
	Menu,
	message,
	Alert,
	notification
} from 'antd';
import LayoutOperations from '../../LayoutOperations';
import axios from 'axios';
import env from '../../../../environment';
import { BankOutlined } from '@ant-design/icons';
import numeral from 'numeral';
import loanInit from '../../../../assets/loans/loanInit.png';
import addLoanImage from '../../../../assets/loans/addLoan.png';
import { getAnalytics, logEvent } from 'firebase/analytics';
import EditIcon from '../../../../assets/loans/EditIcon.svg';
import TipsList from './TipsList';
import ModalEdit from './ModalEdit';
import { getAmortizationData, getPreviousPage } from '../../../../store/calculators/actions';
import { useSelector, useDispatch } from 'react-redux';
import updateLoan from '../../../../assets/loans/updateLoan.png';

const { Text } = Typography;

const LoanPageDetailsPC = ({ }) => {
	const [loanList, setLoanList] = useState([]);
	const userToken = useSelector((state) => state.auth.token);
	const navigate = useNavigate();;
	const { t } = useTranslation();
	const [visibleEditImage, setVisibleEditImage] = useState(false);
	const [visibleDelete, setVisibleDelete] = useState(false);
	const [visibleEditName, setVisibleEditName] = useState(false);
	const [loanId, setLoanId] = useState(undefined);
	const [nameBank, setNameBank] = useState(undefined);
	const [fileName, setFileName] = useState(undefined);
	const [saveFileDataField, setSaveFileDataField] = useState([]);
	const analytics = getAnalytics();
	const refModal = useRef(null);
	const dispatch = useDispatch();

	useEffect(() => {
		getLoansList();
	}, []);

	const getLoansList = () => {
		axios
			.get(`${env.api.url}/v1/loans/get-loans-list`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				if (response.data.data.loans.length === 0) {
					navigate('/my-financial-profile/credits');
				} else {
					setLoanList(response.data.data.loans.reverse());
				}
			})
			.catch((error) => {
				console.log(error)
			});
	};

	
	const handleModalDelete = () => {
		axios
			.get(`${env.api.url}/v1/loans/delete-loan/${loanId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then(() => {
				getLoansList();
				navigate('/my-financial-profile/credits/list');
				setVisibleDelete(!visibleDelete);
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const handleClickCard = (loan) => {
		if (loan.status === 'PENDING_USER') {
			navigate(`/my-financial-profile/credits/edit/all-info-checked/${loan.id}`);
		} else {
			navigate(`/my-financial-profile/credits/all-detail-loan/${loan.id}`);
		}
	};

	const handleModalEditImage = () => {
		axios
			.post(
				`${env.api.url}/v1/loans/update-details/${loanId}`,
				{
					image: saveFileDataField[0].file
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then(() => {
				getLoansList();
				navigate('/my-financial-profile/credits/list');
				setVisibleEditImage(!visibleEditImage);
				setFileName(undefined);
				message.config({
					icon: 'none',
					prefixCls: (
						<Alert
							message="Success Tips"
							description="Detailed description and advice about successful copywriting."
							type="success"
							showIcon
						/>
					)
				});
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const menuComponent = (
		<Menu>
			<Menu.Item>
				<a onClick={(e) => editLoanImage(e)}>{t('modalLoan.editImage')}</a>
			</Menu.Item>
			<Menu.Item>
				<a onClick={(e) => editLoanName(e)}>{t('modalLoan.editName')}</a>
			</Menu.Item>
			<Menu.Item>
				<a onClick={(e) => deleteLoan(e)}>{t('loan.eliminateMortgage')}</a>
			</Menu.Item>
		</Menu>
	);
	const editLoanImage = () => {
		setVisibleEditImage(!visibleEditImage);
	};

	const deleteLoan = () => {
		setVisibleDelete(!visibleDelete);
		refModal.current.focus();
	};

	const editLoanName = () => {
		setVisibleEditName(!visibleEditName);
	};

	const handleShowOptions = (e, id) => {
		setLoanId(id);
		e.stopPropagation();

		logEvent(analytics, 'screen_view', {
			screen_name: 'loan.editionModal'
		});
	};
	const handleModalEditName = () => {
		axios
			.post(
				`${env.api.url}/v1/loans/update-details/${loanId}`,
				{
					name: nameBank
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then(() => {
				getLoansList();
				navigate('/my-financial-profile/credits/list');
				setVisibleEditName(!visibleEditName);
				message.config({
					icon: 'none',
					prefixCls: (
						<Alert
							message="Success Tips"
							description="Detailed description and advice about successful copywriting."
							type="success"
							showIcon
						/>
					)
				});
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const onChangeName = (e) => {
		setNameBank(e.target.value);
	};
	const saveFileData = (file, name) => {
		const documentFile = saveFileDataField;
		documentFile.push({
			file,
			fileName: name
		});
		setSaveFileDataField(documentFile);
	};

	const uploadProps = {
		multiple: true,
		beforeUpload: (file, e) => {
			if (file.size > 8000000) {
				notification.error({
					message: 'Error: archivo demasiado grande',
					description: 'Su documento ocupa mas de 8mb. No se pueden enviar archivos de más de 8mb'
				});

				setFileName('Error: archivo demasiado grande');

				return;
			}

			setFileName(file.name);

			// setFileName([...fileName, file.name]);
			return new Promise(() => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					const base64File = reader.result.split(',')[1];
					saveFileData(base64File, file.name, e);
				};
			});
		}
	};

	return (
		<Layout style={{ height: 'auto', marginBottom: 200 }}>
			<LayoutOperations />
			<Row style={{ paddingRight: 40, paddingLeft: 40, height: '100%' }} gutter={[24, 24]}>
				{loanList &&
					loanList.map((loan) => {
						return (
							<>
								<Col xs={24} md={24} sm={8} lg={8} xl={6} xxl={6}>
									<Card
										className="gb-card"
										cover={
											<>
												<div style={{ overflow: 'hidden', width: 'auto', height: 120 }}>
													{loan?.version !== "2023.v1" ? (
														<img
															src={updateLoan}
															alt="initCard"
															style={{
																width: '100%',
																height: '100%'
															}}
														/>
													) : (
														<img
															src={loan.image !== undefined ? loan.image.link : loanInit}
															alt="initCard"
															style={{
																width: '100%',
																height: '100%'
															}}
														/>
													)}
												</div>
											</>
										}
										style={{ height: '100%', display: 'grid', borderRadius: 4 }}>
										<Row style={{ marginTop: -40 }}>
											<Col span={4} style={{ marginTop: 30 }}>
												{loan.bank !== undefined && loan.bank !== null ? (
													<div>
														<img
															src={
																'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/banks/' +
																`${loan.bank.bankCode + '_iso.png'}`
															}
															alt="banco"
															style={{ height: '32px' }}
														/>
													</div>
												) : (
													<BankOutlined style={{ fontSize: 32, color: '#02C3CD' }} />
												)}
											</Col>
											<Col span={20}>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														marginTop: 24
													}}>
													<Dropdown
														overlay={menuComponent}
														trigger={['click']}
														onClick={(e) => handleShowOptions(e, loan.id)}
														className="dropdown-loan">
														<div
															style={{
																display: 'flex',
																justifyContent: 'space-between',
																alignItems: 'center'
															}}>
															{loan.name === undefined ||
																(loan.name === null && (
																	<>
																		<div className="text-limit">
																			<Text className="title-page">
																				{t('loan.typeMortgageTitle')}&nbsp;
																				{t('loanType.' + loan.rateType)}
																			</Text>
																		</div>
																		<img
																			src={EditIcon}
																			alt="EditIcon"
																			style={{
																				height: 24,
																				width: 24,
																				marginLeft: 8,
																				cursor: 'pointer'
																			}}
																		/>
																	</>
																))}

															{loan.name !== undefined && loan.name !== null && (
																<>
																	<Text className="title-page">{loan.name}</Text>
																	<img
																		src={EditIcon}
																		alt="EditIcon"
																		style={{
																			height: 24,
																			width: 24,
																			marginLeft: 8,
																			cursor: 'pointer'
																		}}
																	/>
																</>
															)}
														</div>
													</Dropdown>

													<Text
														style={{
															color: '#02C3CD',
															fontSize: 14,
															fontWeight: 500
														}}>
														{numeral(loan.quota).format('0,0 $') +
															' ' +
															t('balance.savingsToMonth')}
													</Text>
												</div>
											</Col>
										</Row>

										<div style={{ marginBottom: 60 }}>
											<TipsList loan={loan}></TipsList>
										</div>

										<Button
											className="button-primari-gibobs"
											type="primary"
											onClick={() => {
												if (loan?.version !== "2023.v1" ) {
													navigate(`/my-financial-profile/credits/edit/form/${loan.id}`);
												} else {
													handleClickCard(loan);
												}
											}}
											style={{
												position: 'absolute',
												bottom: 32,
												width: 'calc(100% - 64px)'
											}}>
											{loan?.version !== "2023.v1"  ? t('scoring.update') : t('viability.myInterest')}
										</Button>
									</Card>
								</Col>
							</>
						);
					})}
				<Col xs={24} md={24} sm={8} lg={8} xl={6} xxl={6}>
					<Card className="gb-card" style={{ height: '100%', display: 'grid', borderRadius: 4 }}>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<Text className="title-page">{t('loan.addMortgage')}</Text>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
								{t('loan.titleAddNewMortgage')}
							</Text>
							<img
								onClick={() => {
									navigate('/my-financial-profile/credits/add/loan');
									dispatch(getPreviousPage('new-loan'));
									dispatch(getAmortizationData(undefined));
								}}
								src={addLoanImage}
								alt="button"
								style={{
									marginTop: 80,
									width: 120,
									height: 120,
									alignSelf: 'center',
									cursor: 'pointer',
									marginBottom: 47
								}}></img>
						</div>
					</Card>
				</Col>
			</Row>

			<div ref={refModal} style={{ zIndex: 1000 }} className="miPutoDiv">
				<ModalEdit
					visibleDelete={visibleDelete}
					visibleEditImage={visibleEditImage}
					visibleEditName={visibleEditName}
					setVisibleDelete={setVisibleDelete}
					handleModalDelete={handleModalDelete}
					setVisibleEditName={setVisibleEditName}
					handleModalEditName={handleModalEditName}
					setVisibleEditImage={setVisibleEditImage}
					handleModalEditImage={handleModalEditImage}
					onChangeName={onChangeName}
					fileName={fileName}
					uploadProps={uploadProps}></ModalEdit>
			</div>
		</Layout>
	);
};

export default LoanPageDetailsPC;
