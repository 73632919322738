import React, { useEffect, useState } from 'react';
import { Layout, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import env from '../../environment';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { stages } from '../../store/auth/actions';
import { isMobile } from 'react-device-detect';
import LayoutProducts from '../Products/LayoutProducts';
import OperationsPage from '../../Pages/Operaciones/OperationsPage';
import DocumentatitonCore from '../Operaciones/Documentation/DocumentationCore';

const OperationStage = ({ idIMMO }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();;
	let { operationId, stage } = useParams();
	const userMortgageId = idIMMO !== undefined ? idIMMO : operationId;
	const userToken = useSelector((state) => state.auth.token);
	const operations = useSelector((state) => state.auth.operations);
	const rol = useSelector((state) => state.auth.roles);
	const [operationsData, setOperationsData] = useState(undefined);
	const [dataMortgage, setDataMortgage] = useState();
	const [dataAllMortgage, setDataAllMortgage] = useState([]);
	const refreshDataChange = useSelector((state) => state.auth.refreshData);
	const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

	useEffect(() => {
		window.scrollTo(0, 1);

		axios
			.get(`${env.api.url}/v1/operations/${userMortgageId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setOperationsData(response.data.data);
			})
			.catch((error) => {
				console.log(error);
			});

		let currentoperation = null;
		currentoperation =
			operations && operations.find((operation) => operation.operationId === operationId);

		refreshData();

		if (currentoperation) {
			if (operations !== undefined && operations.length > 0) {
				axios
					.get(`${env.api.url}/v1/auto-things/AutoStagesGenerator/${operationId}`, {
						headers: {
							Authorization: `Bearer ${userToken}`
						}
					})
					.then((response) => {
						if (response.data.success) {
							dispatch(stages(response.data.data.currentStage));
							navigate(
								`/operations/${currentoperation.operationId}/${response.data.data.currentStage}`
							);
						}
					});
			}
			//setOperationsData(currentoperation);
		}
	}, [operationId, operations]);

	const refreshData = () => {
		axios
			.get(`${env.api.url}/v1/operations/${userMortgageId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setDataAllMortgage(response.data.data);
				setDataMortgage(response.data.data.operationData);
			});
	};

	useEffect(() => {
		refreshData();
	}, []);

	useEffect(() => {
		refreshData();
		dispatch(stages(operationsData?.stage));
	}, [userMortgageId, userToken, refreshDataChange]);

	let layoutProducts;
	if (!isMobile && operationsData) {
		layoutProducts = <LayoutProducts name={operationsData && operationsData.name} />;
	} else if (operationsData && isMobile) {
		layoutProducts = <LayoutProducts name={operationsData && operationsData.name} />;
	}

	const commonVariables = {
		stages: stage,
		analyst: operationsData?.agent,
		dataMortgage: dataMortgage,
		userMortgageId: userMortgageId
	};

	const renderComponent = () => {
		
		
			return (
				<DocumentatitonCore
					name={operationsData?.name}
					valuesDataAgent={dataAllMortgage}
					{...commonVariables}
				/>
			);
		
	};

	if (dataMortgage) {
		if (rol !== 'realestate') {
			return (
				<Layout>
					{stage !== 'basic' && layoutProducts}
					{renderComponent()}
				</Layout>
			);
		}
		if (rol === 'realestate') {
			return <OperationsPage />;
		}
	} else {
		return <Spin indicator={antIcon} className="spin" />;
	}
};

export default OperationStage;
