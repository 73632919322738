import React from "react";
import { Typography, Progress } from "antd";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

function LoanToValueCard({ title, percentAssets, percentGlobal, text }) {
  const { t } = useTranslation();
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginRight: "20px",
        }}
      >
        <div style={{ marginBottom: "16px" }}>
          <Text
            strong
            style={{
              color: "#2F4858",
              fontSize: "14px",
            }}
          >
            {title}
          </Text>
        </div>
        <div
          style={{
            marginBottom: "16px",
            width: "200px",
            marginRight: "20px",
          }}
        >
          <Text
            style={{
              color: "#748EA0",
              fontSize: "12px",
            }}
          >
            {text}
          </Text>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: "20px",
          }}
        >
          <Progress
            className="progressLoanToValue"
            width={100}
            type="circle"
            percent={(percentGlobal * 100).toFixed(2)}
            strokeColor={
              title !== "Rendimiento (yield)" ? "#FF4D4F" : "#73D13D"
            }
            trailColor={"#F1F7F8"}
            strokeWidth={8}
          />
          <Text
            style={{
              fontSize: "12px",
              fontWeight: "500",
              textAlign: "center",
              color: "#2F4858",
              marginTop: "20px",
            }}
          >
            {t("balance.global")}
          </Text>
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <Progress
            width={100}
            className="progressLoanToValue"
            type="circle"
            percent={(percentAssets * 100).toFixed(2)}
            strokeColor={
              title !== "Rendimiento (yield)" ? "#FF7875" : "#D3F261"
            }
            trailColor={"#F1F7F8"}
            strokeWidth={8}
          />
          <Text
            style={{
              fontSize: "12px",
              fontWeight: "500",
              textAlign: "center",
              color: "#2F4858",
              marginTop: "20px",
            }}
          >
            {t("balance.realeStateTitle")}
          </Text>
        </div>
      </div>
    </div>
  );
}

export default LoanToValueCard;
