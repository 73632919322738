import React, { useEffect, useState } from "react";
import { Card, Tabs, Progress, Typography, Spin } from "antd";
import { useTranslation } from "react-i18next";
import DifferentScenarioCards from "../SimulationSummary/differentScenarioCards";
import ResultSummary from "./resultSummary";
import SalesTable from "./SalesTable";
import AnalysisTable from "./AnalysisTable";
import numeral from "numeral";
import ResourcesaTable from "./ResourcesTable";
import UsesTable from "./UsesTable";
import HelpButton from "../HelpButton";
import { ArrowLeftOutlined, PauseOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  HeartOutlined,
  DownloadOutlined,
  ArrowUpOutlined,
  HeartFilled,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  getFinancingScenarios,
  getPdf,
  getPromoterData,
  patchFavoriteScenario,
} from "../../../store/promoter/actions";

const { TabPane } = Tabs;
const { Text } = Typography;

const EconomicStudy = () => {
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();;
  const dispatch = useDispatch();
  const [numberTab, setNumberTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [scenarioData, setScenarioData] = useState([]);
  const typeParams = params.type;
  const operationId = params.operationId;
  const scenariosData = useSelector((state) => state.promoter.dataScenarios);
  const dataPromoter = useSelector((state) => state.promoter.dataScenarios);
  const dataList = useSelector((state) => state.promoter.dataListMyProyects);
  const costs = useSelector((state) => state.promoter.dataFormPromoter);

  useEffect(async () => {
    if (dataList !== 0 && Object.keys(dataPromoter).length === 0) {
      const operation = dataList.filter((data) => data.id === operationId);
      const operationData =
        operation.length !== 0 && operation[0].operationDataClient;
      dispatch(getPromoterData(operationData));
    }
  }, [dataList]);

  useEffect(() => {
    if (scenariosData && scenariosData.length === 0) {
      dispatch(getFinancingScenarios(operationId));
    }
  }, []);

  useEffect(() => {
    setScenarioData(scenariosData.find((data) => data.loanType === typeParams));
  }, [scenariosData]);

  const textViability = (title, description, arrow) => {
    return (
      <div style={{ width: "30%", marginBottom: 24 }}>
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: 16 }}
        >
          {arrow === "OPTIMAL" ? (
            <ArrowUpOutlined style={{ color: "#52C41A", marginRight: 12 }} />
          ) : arrow === "NEUTRAL" ? (
            <PauseOutlined
              style={{
                transform: "rotate(90deg)",
                color: "#FAAD14",
                marginRight: 12,
              }}
            />
          ) : (
            arrow === "NEED_TO_IMPROVE" && (
              <ArrowUpOutlined
                style={{
                  color: "#FF4D4F",
                  marginRight: 12,
                  transform: "rotate(180deg)",
                }}
              />
            )
          )}
          <div className="sub-title-promoter-gibobs">{t(title)}</div>
        </div>
        <div className="paragraph-project-data">{t(description)}</div>
      </div>
    );
  };
  const onChange = (e) => {
    setNumberTab(e);
  };

  const nameCase = {
    OWN_FUNDING: "promoterLoan.case0title",
    LOAN_PROMOTER_BANK: "promoterLoan.case1title",
    ALTERNATIVE_LOAN_PROMOTER_LOAN: "promoterLoan.case2title",
    BRIDGE_LOAN: "promoterLoan.case3title",
    ALTERNATIVE_CAPITAL_INCREASE: "promoterLoan.case4title",
  };
  const numberCase = {
    OWN_FUNDING: 0,
    LOAN_PROMOTER_BANK: 1,
    ALTERNATIVE_LOAN_PROMOTER_LOAN: 2,
    BRIDGE_LOAN: 3,
    ALTERNATIVE_CAPITAL_INCREASE: 4,
  };
  let textViabilitytitle;

  if (
    scenarioData &&
    scenarioData.scenarioData &&
    scenarioData.scenarioData.viability &&
    scenarioData.scenarioData.viability.viability < 2
  ) {
    textViabilitytitle = t("viability.viabilityLessThan2title");
  } else if (
    scenarioData &&
    scenarioData.scenarioData &&
    scenarioData.scenarioData.viability &&
    scenarioData.scenarioData.viability.viability < 3 &&
    scenarioData.scenarioData.viability.viability >= 2
  ) {
    textViabilitytitle = t("viability.viabilityBetween2and3title");
  } else if (
    scenarioData &&
    scenarioData.scenarioData &&
    scenarioData.scenarioData.viability &&
    scenarioData.scenarioData.viability.viability < 4 &&
    scenarioData.scenarioData.viability.viability >= 3
  ) {
    textViabilitytitle = t("viability.viabilityBetween3and4title");
  } else {
    textViabilitytitle = t("viability.viabilityBetween4and5title");
  }

  const descriptionViability = {
    "promoterLoan.landPurchase": {
      NEED_TO_IMPROVE: t("promoterLoan.landPurchaseDescriptionNeedImprove"),
      NEUTRAL: t("promoterLoan.landPurchaseDescriptionNeutral"),
      OPTIMAL: t("promoterLoan.landPurchaseDEscription"),
    },
    "promoterLoan.inhabitantsPopulation": {
      NEED_TO_IMPROVE: t(
        "promoterLoan.inhabitantsPopulationDescriptionNeedImprove"
      ),
      NEUTRAL: t("promoterLoan.inhabitantsPopulationDescriptionNeutral"),
      OPTIMAL: t("promoterLoan.inhabitantsPopulationDescription"),
    },
    "promoterLoan.ratioCosts": {
      NEED_TO_IMPROVE: t("promoterLoan.ratioCostsDescriptionNeedImprove"),
      NEUTRAL: t("promoterLoan.ratioCostsDescriptionNeutral"),
      OPTIMAL:
        t("promoterLoan.ratioCostsDescription") +
        " " +
        (typeParams === "OWN_FUNDING"
          ? numeral(
              costs?.capitalStructure &&
                (Number(costs.capitalStructure.equity) +
                  Number(costs.capitalStructure.financingRequired)) /
                  (Number(costs.capitalStructure.equity) +
                    Number(costs.capitalStructure.financingRequired))
            ).format("0,0")
          : numeral(
              costs?.capitalStructure &&
                Number(costs.capitalStructure.equity) /
                  (Number(costs.capitalStructure.equity) +
                    Number(costs.capitalStructure.financingRequired))
            ).format("0.00 %")) +
        " " +
        t("promoterLoan.ratioCostsDescription1"),
    },
    "promoterLoan.ratioLandCosts": {
      NEED_TO_IMPROVE:
        t("promoterLoan.ratioLandCostsDescriptionNeedImprove") +
        ` (${
          scenarioData &&
          scenarioData.scenarioData &&
          scenarioData.scenarioData.viability &&
          scenarioData.scenarioData.viability.points &&
          numeral(scenarioData.scenarioData.viability.points.landCT).format(
            "0.00 %"
          )
        }) ` +
        t("promoterLoan.ratioLandCostsDescriptionNeedImprove1"),
      NEUTRAL: t("promoterLoan.ratioLandCostsDescriptionNeutral"),
      OPTIMAL: t("promoterLoan.ratioLandCostsDescription"),
    },
    LTC: {
      NEED_TO_IMPROVE:
        t("promoterLoan.ltcDescriptionNeedImprove") +
        ` (${
          costs?.capitalStructure &&
          numeral(
            (Number(costs.capitalStructure.equity) +
              Number(costs.capitalStructure.financingRequired) -
              Number(costs.capitalStructure.equity)) /
              (Number(costs.capitalStructure.equity) +
                Number(costs.capitalStructure.financingRequired))
          ).format("0.00 %")
        }) ` +
        t("promoterLoan.ltcDescriptionNeedImprove1"),
      NEUTRAL:
        t("promoterLoan.ltcDescriptionNeutral") +
        ` (${
          costs?.capitalStructure &&
          numeral(
            (Number(costs.capitalStructure.equity) +
              Number(costs.capitalStructure.financingRequired) -
              Number(costs.capitalStructure.equity)) /
              (Number(costs.capitalStructure.equity) +
                Number(costs.capitalStructure.financingRequired))
          ).format("0.00 %")
        }) ` +
        t("promoterLoan.ltcDescriptionNeutral1"),
      OPTIMAL:
        t("promoterLoan.ltcDescription") +
        ` (${
          costs?.capitalStructure &&
          numeral(
            (Number(costs.capitalStructure.equity) +
              Number(costs.capitalStructure.financingRequired) -
              Number(costs.capitalStructure.equity)) /
              (Number(costs.capitalStructure.equity) +
                Number(costs.capitalStructure.financingRequired))
          ).format("0.00 %")
        }) ` +
        t("promoterLoan.ltcDescription"),
    },
    "promoterLoan.marginTitle": {
      NEED_TO_IMPROVE: t("promoterLoan.marginDescriptionNeedImprove"),
      NEUTRAL: t("promoterLoan.marginDescriptionNeutral"),
      OPTIMAL: t("promoterLoan.marginDescription"),
    },
    "promoterLoan.presaleLevel": {
      NEED_TO_IMPROVE: t("promoterLoan.presaleLevelDescriptionNeedImprove"),
      NEUTRAL: t("promoterLoan.presaleLevelDescriptionNeutral"),
      OPTIMAL:
        t("promoterLoan.presaleLevelDescription") +
        ` (${
          scenarioData &&
          scenarioData.scenarioData &&
          scenarioData.scenarioData.economicStudy &&
          scenarioData.scenarioData.economicStudy.sales &&
          numeral(
            scenarioData.scenarioData.economicStudy.sales.preSoldUnits
              .percentageUnit / 100
          ).format("0.00 %")
        }) ` +
        t("promoterLoan.presaleLevelDescription1"),
    },
    "promoterLoan.amountFinancingTitle": {
      NEED_TO_IMPROVE: t("promoterLoan.amountFinancingDescriptionNeedImprove"),
      NEUTRAL: t("promoterLoan.amountFinancingDescriptionNeutral"),
      OPTIMAL: t("promoterLoan.amountFinancingDescription"),
    },
  };

  const paramImprovements = (type) => {
    return (
      scenarioData &&
      scenarioData.scenarioData &&
      scenarioData.scenarioData.viability &&
      scenarioData.scenarioData.viability.improvements &&
      scenarioData.scenarioData.viability.improvements[type]
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          margin: "40px 40px 0px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 16,
            color: "#2F4858",
            fontWeight: 600,
          }}
        >
          <ArrowLeftOutlined
            style={{ marginRight: 16 }}
            onClick={() =>
              navigate(`/promoter-loan/analysis/${operationId}`)
            }
          />
          <div>
            {t("promoterLoan.scenarioTitle")} {numberCase[typeParams]}.{" "}
            {t(`${nameCase[typeParams]}`)}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: 18 }}>
            {numberCase[typeParams] !== 0 && (
              <>
                {scenarioData && scenarioData.favorite && (
                  <HeartFilled
                    onClick={() =>
                      dispatch(
                        patchFavoriteScenario(scenarioData.id, operationId)
                      )
                    }
                    style={{
                      color: "#02C3CD",
                      fontSize: 15,
                    }}
                  />
                )}
                {scenarioData && !scenarioData.favorite && (
                  <HeartOutlined
                    onClick={() =>
                      dispatch(
                        patchFavoriteScenario(
                          scenarioData && scenarioData.id,
                          operationId
                        )
                      )
                    }
                    style={{
                      color: "#02C3CD",
                      fontSize: 15,
                    }}
                  />
                )}
              </>
            )}
            {numberCase[typeParams] !== 0 && (
              <>
                {!loading ? (
                  <DownloadOutlined
                    onClick={() =>
                      dispatch(
                        getPdf(numberCase[typeParams], operationId, (e) =>
                          setLoading(e)
                        )
                      )
                    }
                    style={{ color: "#02C3CD", marginLeft: 12, fontSize: 18 }}
                  />
                ) : (
                  <Spin
                    style={{ color: "#02C3CD", marginLeft: 12, fontSize: 18 }}
                  />
                )}
              </>
            )}
          </div>

          <HelpButton
            simulation={true}
            primary={true}
            origin="SIMULATION_RESULTS"
          />
        </div>
      </div>
      <Card
        style={{
          margin: 40,
          marginBottom: 100,
          marginTop: 24,
        }}
      >
        <div className="paragraph-style" style={{ marginBottom: 24 }}>
          {t(`promoterLoan.detailScenarioDescription${numberCase[typeParams]}`)}
        </div>

        <div className="title-primary" style={{ marginBottom: 24 }}>
          {t("promoterLoan.generalDetails")}
        </div>
        <DifferentScenarioCards
          number={5}
          equity={
            scenarioData &&
            scenarioData.scenarioData &&
            scenarioData.scenarioData.generalData &&
            scenarioData.scenarioData.generalData.equityMultiple
          }
          margin={
            scenarioData &&
            scenarioData.scenarioData &&
            scenarioData.scenarioData.generalData &&
            scenarioData.scenarioData.generalData.marginOnsales
          }
          viability={
            scenarioData &&
            scenarioData.scenarioData &&
            scenarioData.scenarioData.generalData &&
            scenarioData.scenarioData.generalData.viability
          }
          setNumberTab={(e) => setNumberTab(e)}
        />
        <Tabs
          defaultActiveKey="1"
          centered
          onChange={onChange}
          activeKey={numberTab}
        >
          <TabPane tab={t("promoterLoan.economicStudy")} key="1">
            <div
              className="paragraph-style"
              style={{ marginBottom: 32, marginTop: 32 }}
            >
              {t("promoterLoan.economicDataDescription3")}
            </div>
            <div
              className="title-primary"
              style={{ marginBottom: 24, fontSize: 20, marginTop: 40 }}
            >
              {t("promoterLoan.sourcesUses")}
            </div>
            <div style={{ display: "flex" }}>
              <ResourcesaTable
                data={
                  scenarioData &&
                  scenarioData.scenarioData &&
                  scenarioData.scenarioData.economicStudy &&
                  scenarioData.scenarioData.economicStudy.sourcesAndUses.sources
                }
              />{" "}
              <UsesTable
                data={
                  scenarioData &&
                  scenarioData.scenarioData &&
                  scenarioData.scenarioData.economicStudy &&
                  scenarioData.scenarioData.economicStudy.sourcesAndUses.uses
                }
              />
            </div>
            <div
              className="title-primary"
              style={{ marginBottom: 24, fontSize: 20, marginTop: 40 }}
            >
              {t("promoterLoan.resultSummary")}
            </div>
            <ResultSummary
              data={
                scenarioData &&
                scenarioData.scenarioData &&
                scenarioData.scenarioData.economicStudy &&
                scenarioData.scenarioData.economicStudy.summaryResult
              }
            />
            <div
              className="title-primary"
              style={{ marginBottom: 24, fontSize: 20, marginTop: 40 }}
            >
              {t("promoterLoan.salesTitle")}
            </div>
            <SalesTable
              data={
                scenarioData &&
                scenarioData.scenarioData &&
                scenarioData.scenarioData.economicStudy &&
                scenarioData.scenarioData.economicStudy.sales
              }
            />
            <div
              className="title-primary"
              style={{ marginBottom: 24, fontSize: 20, marginTop: 40 }}
            >
              {t("promoterLoan.analysisLTV")}
            </div>
            <AnalysisTable
              data={
                scenarioData &&
                scenarioData.scenarioData &&
                scenarioData.scenarioData.economicStudy &&
                scenarioData.scenarioData.economicStudy.ltvAnalysis
              }
            />
          </TabPane>
          <TabPane tab={t("m_viability.viabilityTitle")} key="2">
            <Progress
              type="dashboard"
              strokeColor={
                parseInt(
                  scenarioData &&
                    scenarioData.scenarioData &&
                    scenarioData.scenarioData.viability &&
                    Number(scenarioData.scenarioData.viability.viability)
                ) < 3
                  ? "#FAAD14"
                  : "#52C41A"
              }
              trailColor={"#F1F7F8"}
              percent={
                (parseInt(
                  scenarioData &&
                    scenarioData.scenarioData &&
                    scenarioData.scenarioData.viability &&
                    Number(scenarioData.scenarioData.viability.viability)
                ) *
                  100) /
                5
              }
              format={() => (
                <div>
                  <Text
                    strong
                    style={{
                      fontSize: 32,
                      lineHeight: "40px",
                      color: "#2F4858",
                      marginBottom: -8,
                      display: "grid",
                    }}
                  >
                    {numeral(
                      scenarioData &&
                        scenarioData.scenarioData &&
                        scenarioData.scenarioData.viability &&
                        Number(scenarioData.scenarioData.viability.viability)
                    ).format("0.0")}
                  </Text>
                  <Text
                    style={{
                      fontSize: "14px",
                      color: "#2F4858",
                    }}
                  >{`de 5`}</Text>
                </div>
              )}
              width={120}
              className="EstimatedViabilityProgress"
              style={{
                textAlign: "center",
                marginTop: 6,
                marginBottom: 24,
                marginRight: 0,
                textAlign: "center",
                width: "100%",
              }}
            />
            <div
              className="sub-title-promoter-gibobs"
              style={{
                marginBottom: 32,
                color:
                  scenarioData &&
                  scenarioData.scenarioData &&
                  scenarioData.scenarioData.viability &&
                  scenarioData.scenarioData.viability.viability < 2
                    ? "#FAAD14"
                    : "#52C41A",
                textAlign: "center",
              }}
            >
              {textViabilitytitle}
            </div>
            <div className="title-primary" style={{ marginBottom: 24 }}>
              {t("viability.feasibilityIndicators")}
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", columnGap: "5%" }}>
              {textViability(
                "promoterLoan.landPurchase",
                descriptionViability["promoterLoan.landPurchase"][
                  paramImprovements("landBuyed")
                ],
                paramImprovements("landBuyed")
              )}
              {textViability(
                "promoterLoan.inhabitantsPopulation",
                descriptionViability["promoterLoan.inhabitantsPopulation"][
                  paramImprovements("populationType")
                ],
                paramImprovements("populationType")
              )}
              {textViability(
                "promoterLoan.ratioCosts",
                descriptionViability["promoterLoan.ratioCosts"][
                  paramImprovements("equityCosts")
                ],
                paramImprovements("equityCosts")
              )}
              {textViability(
                "promoterLoan.ratioLandCosts",
                descriptionViability["promoterLoan.ratioLandCosts"][
                  paramImprovements("landCT")
                ],
                paramImprovements("landCT")
              )}
              {textViability(
                "LTC",
                descriptionViability["LTC"][paramImprovements("ltc")],
                paramImprovements("ltc")
              )}
              {textViability(
                "promoterLoan.marginTitle",
                descriptionViability["promoterLoan.marginTitle"][
                  paramImprovements("marginWithoutFinancing")
                ],
                paramImprovements("marginWithoutFinancing")
              )}
              {textViability(
                "promoterLoan.presaleLevel",
                descriptionViability["promoterLoan.presaleLevel"][
                  paramImprovements("presale")
                ],
                paramImprovements("presale")
              )}
              {textViability(
                "promoterLoan.amountFinancingTitle",
                descriptionViability["promoterLoan.amountFinancingTitle"][
                  paramImprovements("amountToFinance")
                ],
                paramImprovements("amountToFinance")
              )}
            </div>
          </TabPane>
        </Tabs>
      </Card>
    </>
  );
};

export default EconomicStudy;
