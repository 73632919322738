import React from "react";
import { Layout, Row, Button } from "antd";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";

const LayoutTools = ({ folderId }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const styleTextTab = {
    fontSize: 14,
    fontWeight: 600,
    color: "white",
    backgroundColor: "#02C3CD",
    borderRadius: "4px",
    border: "none",
    height: 30,
    textAlign: "center",
  };

  return (
    <Layout>
      <Row gutter={[24, 24]} style={{ marginLeft: 0, marginRight: 0 }}>
        <div
          className="newSubmenusTabs"
          style={{ height: 48, padding: 8, margin: isMobile && 16 }}
        >
          <div
            style={{
              background: "#EFFCFC",
              borderRadius: 5,
              borderBottom: "none",
              borderBottomColor: "white",
            }}
            selectedKeys={[`${location.pathname}${location.search}`]}
            mode="horizontal"
            className="menu-tools"
          >
            {/* <Menu.Item
              style={{ border: "none", margin: "0 4px" }}
              key="/sales-tools/new-requests"
            > */}
            <NavLink
              className="buttonOperations"
              to="/sales-tools/new-requests"
            >
              <Button
                className="buttonOperations"
                style={
                  location.pathname === "/sales-tools/new-requests" ||
                  (folderId !== undefined &&
                    folderId.map((data) => data === location.pathname))
                    ? styleTextTab
                    : undefined
                }
              >
                {t("form.newRequests")}
              </Button>
            </NavLink>
            {/* </Menu.Item> */}
            {/* <Menu.Item
              style={{ border: "none", margin: "0 4px" }}
              key="/sales-tools/follow-up"
            > */}
            <NavLink to="/sales-tools/follow-up">
              <Button
                className="buttonOperations"
                style={
                  location.pathname === "/sales-tools/follow-up"
                    ? styleTextTab
                    : undefined
                }
              >
                {t("menuComponent.followUp")}
              </Button>
            </NavLink>
            {/* </Menu.Item> */}
          </div>
        </div>
      </Row>
    </Layout>
  );
};

export default LayoutTools;
