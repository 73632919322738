import { useEffect, useState } from 'react';
import { Form, Select, Col, Row, Card, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import DownOutlinedMobile from '../../assets/icons/DownOutlinedMobile.svg';
import { isMobileOnly } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationGibobs } from '../HerramientasVenta/reusableItems';
import HelpButton from './HelpButton';
import StepPromoter from './StepPromoter';
import { useDispatch } from 'react-redux';
import { getPromoterData } from '../../store/promoter/actions';
import { useSelector } from 'react-redux';
import axios from 'axios';
import env from '../../environment';
import { InputNumber, ProvinceSelector } from 'afrodita';

const { Option } = Select;

export const GeneralDataForm = (colSpan, colSpanSpecial, form) => {
	const { t } = useTranslation();
	const [valueWorkStarted, setValueWorkStarted] = useState('no');
	const [preSaleUnit, setPreSaleUnit] = useState(0);
	const [saleUnit, setSaleUnit] = useState(0);
	const dispatch = useDispatch();
	const dataPromoter = useSelector((state) => state.promoter.dataFormPromoter);

	useEffect(async () => {
		if (saleUnit !== 0 && saleUnit !== '') {
			form.setFieldsValue({
				percentageOfPreSales: Number((preSaleUnit * 100) / saleUnit).toFixed(2)
			});
		}
	}, [preSaleUnit, saleUnit]);

	useEffect(async () => {
		setPreSaleUnit(
			dataPromoter &&
				dataPromoter.generalData &&
				dataPromoter.generalData.preSalesUnits !== undefined
				? Number(dataPromoter.generalData.preSalesUnits).toFixed(2)
				: 0
		);
		setSaleUnit(
			dataPromoter  &&
				dataPromoter.generalData &&
				dataPromoter.generalData.salesUnits !== undefined
				? Number(dataPromoter.generalData.salesUnits)
				: 0
		);
	}, [dataPromoter]);

	return (
		<Row gutter={120}>
			<Col {...colSpan}>
				<Form.Item
					name="buildability"
					label={t('promoterLoan.Buildability')}
					rules={[
						{
							required: true,
							validator: (e, value) =>
								value !== '0' && value !== '' && value !== undefined
									? Promise.resolve()
									: Promise.reject(
											new Error(t('promoterLoan.Buildability') + ' ' + t('form.formRequired'))
									  )
						}
					]}>
					<InputNumber
						suffix="m²"
						placeholder="0"
						onBlur={(e) => {
							
							Object.keys(dataPromoter).length !== 0 &&
								dispatch(
									getPromoterData({
										...dataPromoter,
										generalData: {
											...dataPromoter['generalData'],
											buildability: Number(e.target.value)
										}
									})
								);
						}}
					/>
				</Form.Item>
			</Col>

			<Col {...colSpan}>
				<Form.Item
					name="landInProperty"
					label={t('promoterLoan.solarProperty')}
					rules={[
						{
							required: true
						}
					]}>
					<Select
						suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
						defaultValue={t('form.select')}
						style={{ width: '100%' }}>
						<Option value={true}>{t('form.yes')}</Option>
						<Option value={false}>{t('form.no')}</Option>
					</Select>
				</Form.Item>
			</Col>
			<Col {...colSpanSpecial} style={{ paddingRight: 4 }}>
				<Form.Item
					name="worksInitiated"
					label={t('promoterLoan.workStarted')}
					rules={[
						{
							required: true
						}
					]}>
					<Select
						suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
						defaultValue={t('form.select')}
						style={{ width: '100%' }}
						onChange={(e) => setValueWorkStarted(e)}>
						<Option value={true}>{t('form.yes')}</Option>
						<Option value={false}>{t('form.no')}</Option>
					</Select>
				</Form.Item>
			</Col>
			<Col {...colSpanSpecial} style={{ paddingLeft: 4 }}>
				<Form.Item name="percentageOfWorkStarted" label={t('promoterLoan.percentageWorkStarted')}>
					<InputNumber
						suffix={'%'}
						disabled={valueWorkStarted === true ? false : true}
						placeholder={t('0')}
						style={{
							width: '100%'
						}}
					/>
				</Form.Item>
			</Col>
			<Col {...colSpan}>
				<Form.Item
					name="buildingPermission"
					label={t('promoterLoan.buildingLicense')}
					rules={[
						{
							required: true
						}
					]}>
					<Select
						suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
						defaultValue={t('form.select')}
						style={{ width: '100%' }}>
						<Option value={true}>{t('promoterLoan.haveLicense')}</Option>
						<Option value={false}>{t('promoterLoan.DoNotHaveLicense')}</Option>
					</Select>
				</Form.Item>
			</Col>
			<Col {...colSpan}>
				<Form.Item
					name="salesUnits"
					label={t('promoterLoan.salesUnits')}
					rules={[
						{
							required: true
						}
					]}>
					<InputNumber
						gbDecimalType="integer"
						placeholder={t('0')}
						style={{
							width: '100%'
						}}
						onBlur={(e) => {
							setSaleUnit(Number(e.target.value));
							dispatch(
								getPromoterData({
									...dataPromoter,
									generalData: {
										...dataPromoter['generalData'],
										salesUnits: Number(e.target.value)
									}
								})
							);
						}}
					/>
				</Form.Item>
			</Col>
			<Col {...colSpanSpecial} style={{ paddingRight: 4 }}>
				<Form.Item
					name="preSalesUnits"
					label={t('promoterLoan.presalesUnits')}
					rules={[
						{
							required: true,
							validator: (e, value) =>
								value < saleUnit + 1
									? Promise.resolve()
									: Promise.reject(
											new Error(
												t('promoterLoan.presalesUnits') + ' ' + 'No puede ser mayor de' + saleUnit
											)
									  )
						}
					]}>
					<InputNumber
						gbDecimalType="integer"
						max={saleUnit}
						onBlur={(e) => {
							setPreSaleUnit(Number(e.target.value).toFixed(2));
							dispatch(
								getPromoterData({
									...dataPromoter,
									generalData: {
										...dataPromoter['generalData'],
										preSalesUnits: Number(e.target.value)
									}
								})
							);
						}}
						placeholder={t('0')}
						style={{
							width: '100%'
						}}
					/>
				</Form.Item>
			</Col>
			<Col {...colSpanSpecial} style={{ paddingLeft: 4 }}>
				<Form.Item name="percentageOfPreSales" label={t('promoterLoan.presalePercentage')}>
					<InputNumber
						suffix={'%'}
						value={preSaleUnit}
						disabled={true}
						placeholder={t('0')}
						style={{
							width: '100%'
						}}
					/>
				</Form.Item>
			</Col>
			<Col {...colSpan}>
				<Form.Item
					label={t('form.provinces')}
					name="province"
					rules={[
						{
							required: true
						}
					]}>
						<ProvinceSelector
							location={true}
							placeholder={t('form.provinces')}
							style={{ width: '100%' }} />
				</Form.Item>
			</Col>
			<Col {...colSpan}>
				<Form.Item
					label={t('promoterLoan.promotionPopulation')}
					name="promotionPopulation"
					rules={[
						{
							required: true
						}
					]}>
					<Select
						suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
						defaultValue={t('form.select')}
						style={{ width: '100%' }}>
						<Option value="LESS_THAN_10_THOUSAND_INHABITANTS">
							{t('promoterLoan.under10Select')}
						</Option>
						<Option value="BETWEEN_10_THOUSAND_AND_50_THOUSAND_INHABITANTS">
							{t('promoterLoan.between10and50Select')}
						</Option>
						<Option value="MORE_THAN_50_THOUSAND_INHABITANTS">
							{t('promoterLoan.greaterThan50Select')}
						</Option>
					</Select>
				</Form.Item>
			</Col>
		</Row>
	);
};

const GeneralData = () => {
	const params = useParams();
	const operationId = params.operationId;
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const navigate = useNavigate();;
	const dataPromoter = useSelector((state) => state.promoter.dataFormPromoter);
	const userToken = useSelector((state) => state.auth.token);
	const [loading, setLoading] = useState(false);

	const openNotificationWithIcon = () => {
		notificationGibobs('error', t('m_full.errorTitle'), t('form.incompleteInformation'));
	};

	const validateMessages = {
		required: '${label}' + ' ' + t('form.formRequired'),
		types: {
			email: t('form.emailValidated'),
			number: '${label} is not a valid number!'
		},
		number: {
			range: t('form.maxBudgetNewRequestValidated') + '${min}'
		}
	};
	const sendGeneralData = (values) => {
		setLoading(true);
		axios
			.post(
				`${env.api.url}/promoters/promoter-projects/general-data/${operationId}`,
				{
					province: values.province,
					salesUnits: Number(values.salesUnits),
					buildability: Number(values.buildability),
					preSalesUnits: Number(values.preSalesUnits),
					landInProperty: values.landInProperty,
					worksInitiated: values.worksInitiated,
					buildingPermission: values.buildingPermission,
					promotionPopulation: values.promotionPopulation,
					percentageOfPreSales: Number(values.percentageOfPreSales),
					percentageOfWorkStarted: Number(values.percentageOfWorkStarted)
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					setLoading(false);
					dispatch(getPromoterData({ ...dataPromoter, generalData: values }));
					navigate(`/promoter-loan/financial-data/${operationId}`);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const generalDataVerificated = (values) => {
		if (values.percentageOfWorkStarted === undefined || values.percentageOfWorkStarted === null) {
			values.percentageOfWorkStarted = Number(0);
		} else {
			values.percentageOfWorkStarted = Number(values.percentageOfWorkStarted);
		}
		sendGeneralData({
			...values,
			buildability: Number(values.buildability),
			preSalesUnits: Number(values.preSalesUnits),
			salesUnits: Number(values.salesUnits),
			province: values.province
		});
	};

	return (
		<>
			<Card
				style={{
					margin: 40,
					padding: !isMobileOnly && '0px 10%',
					marginBottom: 100
				}}>
				<StepPromoter
					data={[
						{
							name: 'promoterLoan.generalData'
						},
						{
							name: 'viability.economicData'
						},
						{
							name: 'promoterLoan.capitalStructure'
						}
					]}
					status={0}
					width={'60%'}
					promoter={true}
				/>
				<div className="title-primary" style={{ marginBottom: 8, marginTop: 40 }}>
					{t('promoterLoan.GeneralProjectData')}
				</div>
				<div className="paragraph-style" style={{ marginBottom: 24 }}>
					{t('promoterLoan.GeneralProjectDescription')}
				</div>
				<Form
					form={form}
					className={'gb-form-profile-headlines-mobile'}
					onFinishFailed={openNotificationWithIcon}
					scrollToFirstError={true}
					onFinish={generalDataVerificated}
					layout="vertical"
					name="GeneralData"
					validateMessages={validateMessages}
					initialValues={dataPromoter ? dataPromoter.generalData : {}}>
					{GeneralDataForm({ xs: 24, lg: 12 }, { xs: 24, lg: 6 }, form)}
					<div style={{ textAlign: 'center' }}>
						<Button
							type="primary"
							className="button-primari-gibobs"
							style={{
								margin: 'auto',
								marginTop: 40,
								width: 120
							}}
							htmlType="submit"
							loading={loading}>
							{t('form.next')}
						</Button>
					</div>
				</Form>
				<HelpButton origin={'IN_FORM_THREE_STEPS'} />
			</Card>
		</>
	);
};

export default GeneralData;
