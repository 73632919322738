import React, { useEffect, useState } from 'react';
import {  Row, Button, Card, Skeleton, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LayoutMeans from '../../LayoutMeans';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import CardLinkAmigobs from '../../../components/widgets/CardLinksApps/CardLinkAmigobs';
import euro from '../../../assets/loans/euro.png';
import roof from '../../../assets/loans/roof.svg';
import amortization from '../../../assets/loans/amortization.svg';
const { Text } = Typography;

const CalculatorsMobileAndDesktop = ({}) => {
	const { t } = useTranslation();
	const rol = useSelector((state) => state.auth.roles);
	const navigate = useNavigate();;
	const [loading, setLoading] = useState(true);

	

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, [1500]);
	}, []);

	if (isMobileOnly) {
		return (
			<>
				{isMobileOnly && rol !== 'realestate' && <CardLinkAmigobs></CardLinkAmigobs>}
				<LayoutMeans  rol={ rol } />
				<div style={{ padding: isMobileOnly ? '0px 16px' : '0px 40px' }}>
					<Card
						onClick={() => navigate('/calculators/fee')}
						className="gcardMovile-16-padding"
						style={{ borderRadius: 4 }}>
						<Skeleton active loading={loading} paragraph={false} />
						{!loading && (
							<Row gutter={12} style={{ alignItems: 'center' }}>
								<Col span={3} style={{ textAlign: 'center' }}>
									<img src={euro} style={{ width: 24, height: 24 }}></img>
								</Col>
								<Col span={21} style={{ display: 'flex', flexDirection: 'column' }}>
									<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
										{t('fee.calculator')}
									</Text>
									<Text style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>
										{t('fee.calculator2')}
									</Text>
								</Col>
							</Row>
						)}
					</Card>
					<Card
						className="gcardMovile-16-padding"
						onClick={() => navigate('/calculators/financing')}
						style={{ marginTop: 8, borderRadius: 4 }}>
						<Skeleton active loading={loading} paragraph={false} />
						{!loading && (
							<Row gutter={12} style={{ alignItems: 'center' }}>
								<Col span={3} style={{ textAlign: 'center' }}>
									<img src={roof} style={{ width: 24, height: 24 }}></img>
								</Col>
								<Col span={21} style={{ display: 'flex', flexDirection: 'column' }}>
									<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
										{t('fee.calculatorTop')}
									</Text>
									<Text style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>
										{t('fee.calculatorTop2')}
									</Text>
								</Col>
							</Row>
						)}
					</Card>
					{/*<Card className="gcardMovile-16-padding" style={{ marginTop: 8, minHeight: 88, borderRadius:8 }}>
						<Skeleton active loading={loading} paragraph={false} />
						{!loading && (
							<Row gutter={12}  style={{ alignItems: 'center' }}>
								<Col span={3} style={{ textAlign: 'center' }}>
									<SwapOutlined style={{ color: '#FFC069', fontSize: 20}} />
								</Col>
								<Col span={21} style={{ display: 'flex', flexDirection: 'column' }}>
									<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
										{t('fee.calculatorSubrogation')}
									</Text>
									<Text style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>
										{t('fee.calculatorSubrogation2')}
									</Text>
								</Col>
							</Row>
						)}
            </Card>*/}
					<Card
						className="gcardMovile-16-padding"
						onClick={() => navigate(`/calculators/amortization`,{
							state: {
								valuesDefaultMenu: {
									pendingCapital: 140000,
									pendingQuotes: 336,
									interestRateType: 'FIXED',
									actualTin: 2.5,
									amountToBeAmortized: 10000
								}
							}
						})}
						style={{ marginTop: 8, borderRadius: 4 }}>
						<Skeleton active loading={loading} paragraph={false} />
						{!loading && (
							<Row gutter={12} style={{ alignItems: 'center' }}>
								<Col span={3} style={{ textAlign: 'center' }}>
									<img src={amortization} style={{ width: 24, height: 24 }}></img>
								</Col>
								<Col span={21} style={{ display: 'flex', flexDirection: 'column' }}>
									<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
										{t('fee.calculatorAmortization')}
									</Text>
									<Text style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>
										{t('fee.calculatorAmortization2')}
									</Text>
								</Col>
							</Row>
						)}
					</Card>
				</div>
			</>
		);
	} else {
		return (
			<>
				<LayoutMeans rol={ rol }></LayoutMeans>
				<Row gutter={[24, 24]} style={{ padding: 40, marginTop: -30, marginBottom: 420 }}>
					<Col xs={24} md={24} sm={12} lg={8} xl={6} xxl={6}>
						<Card className="gcardMovile" style={{ minHeight: 216 }}>
							<Skeleton active loading={loading} paragraph={false} />

							{!loading && (
								<Row gutter={12}>
									<Col span={4} style={{ alignSelf: 'center' }}>
										<img src={euro} style={{ width: 30, height: 30 }}></img>
									</Col>
									<Col span={20} style={{ alignSelf: 'center' }}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 600
											}}>
											{t('fee.calculator')}
										</Text>
									</Col>
									<Col span={24} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#748EA0',
												fontSize: 12,
												fontWeight: 400,
												marginTop: 8
											}}>
											{t('fee.calculator2')}
										</Text>
									</Col>
									<Button
										onClick={() => navigate('/calculators/fee')}
										type="primary"
										className="button-primari-gibobsNew"
										style={{
											marginTop: 28,
											height: 40,
											position: 'absolute',
											bottom: 24,
											width: 'calc(100% - 35px)'
										}}>
										{t('init.continueButton')}
									</Button>
								</Row>
							)}
						</Card>
					</Col>
					<Col xs={24} md={24} sm={12} lg={8} xl={6} xxl={6}>
						<Card className="gcardMovile" style={{ minHeight: 216 }}>
							<Skeleton active loading={loading} paragraph={false} />
							{!loading && (
								<Row gutter={12}>
									<Col span={4} style={{ alignSelf: 'center' }}>
										<img src={roof} style={{ width: 30, height: 30 }}></img>
									</Col>
									<Col span={20} style={{ alignSelf: 'center' }}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 600
											}}>
											{t('calculator.roofCalculatorTitle')}
										</Text>
									</Col>
									<Col span={24} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#748EA0',
												fontSize: 12,
												fontWeight: 400,
												marginTop: 8
											}}>
											{t('fee.calculatorTop2')}
										</Text>
									</Col>
									<Button
										onClick={() => navigate('/calculators/financing')}
										type="primary"
										className="button-primari-gibobsNew"
										style={{
											marginTop: 28,
											height: 40,
											position: 'absolute',
											bottom: 24,
											width: 'calc(100% - 35px)'
										}}>
										{t('init.continueButton')}
									</Button>
								</Row>
							)}
						</Card>
					</Col>
					{/*<Col xs={24} md={24} sm={12} lg={8} xl={6} xxl={6}>
						<Card className="gcardMovile" style={{ minHeight: 216 }}>
							<Skeleton active loading={loading} paragraph={false} />
							{!loading && (
								<Row gutter={12}>
									<Col span={4} style={{ alignSelf: 'center' }}>
										<SwapOutlined style={{ color: '#FFC069', fontSize: 20 }} />
									</Col>
									<Col span={20}  style={{ alignSelf: 'center' }}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 600
											}}>
											{t('fee.calculatorSubrogation')}
										</Text>
									</Col>
									<Col span={24} style={{ display: 'flex', flexDirection: 'column' }}>
									<Text
											style={{
												color: '#748EA0',
												fontSize: 12,
												fontWeight: 400,
												marginTop: 8
											}}>
											{t('fee.calculatorSubrogation2')}
										</Text>
									</Col>
									<Button
										onClick={() => navigate('/calculators/fee')}
										type="primary"
										className="button-primari-gibobsNew"
										style={{
											marginTop: 28,
											height: 40,
											position: 'absolute',
											bottom: 24,
											width: 'calc(100% - 35px)'
										}}>
										{t('init.continueButton')}
									</Button>
								</Row>
							)}
                  </Card>
					</Col>*/}
					<Col xs={24} md={24} sm={12} lg={8} xl={6} xxl={6}>
						<Card className="gcardMovile" style={{ minHeight: 216 }}>
							<Skeleton active loading={loading} paragraph={false} />
							{!loading && (
								<Row gutter={12}>
									<Col span={4} style={{ alignSelf: 'center' }}>
										<img src={amortization} style={{ width: 30, height: 30 }}></img>
									</Col>
									<Col span={20} style={{ alignSelf: 'center' }}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 600
											}}>
											{t('fee.calculatorAmortization')}
										</Text>
									</Col>
									<Col span={24} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#748EA0',
												fontSize: 12,
												fontWeight: 400,
												marginTop: 8
											}}>
											{t('fee.calculatorAmortization2')}
										</Text>
									</Col>
									<Button
										onClick={() => navigate(`/calculators/amortization`,{
											state: {
												valuesDefaultMenu: {
													pendingCapital: 140000,
													pendingQuotes: 336,
													interestRateType: 'FIXED',
													actualTin: 2.5,
													amountToBeAmortized: 10000
												}
											}
										})}
										type="primary"
										className="button-primari-gibobsNew"
										style={{
											marginTop: 28,
											height: 40,
											position: 'absolute',
											bottom: 24,
											width: 'calc(100% - 35px)'
										}}>
										{t('init.continueButton')}
									</Button>
								</Row>
							)}
						</Card>
					</Col>
				</Row>
			</>
		);
	}
};

export default CalculatorsMobileAndDesktop;
