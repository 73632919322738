import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import env from '../../../../environment';
import CompareAutomatic from './CompareAutomatic';
import CompareManual from './CompareManual';

const LoanComparativePageNodataDesktop = ({ loanIdProp, rateTypeProp, tipProp, visible }) => {
	const [loan, setLoan] = useState(undefined);
	const userToken = useSelector((state) => state.auth.token);
	const [, setDataTips] = useState(undefined);
	const [, setData] = useState(undefined);

	const { loanId } = useParams();

	useEffect(() => {
		getLoan();
		getEuribor();
	}, []);

	useEffect(() => {
		getLoan();
	}, [rateTypeProp, tipProp]);

	const getLoan = () => {
		if (loanIdProp) {
			axios
				.get(`${env.api.url}/v1/loans/get-loan/${loanIdProp}`, {
					headers: { Authorization: `Bearer ${userToken}` }
				})
				.then((response) => {
					// setDataValues(response.data.data);
					setLoan(response.data.data.loan);
					response.data.data.loan.tips.map((tipData) => {
						if (tipData.type === tipProp) {
							setDataTips(tipData);
						}
					});
				})
				.catch((error) => {
					console.log(error)
				});
		} else {
			axios
				.get(`${env.api.url}/v1/loans/get-loan/${loanId}`, {
					headers: { Authorization: `Bearer ${userToken}` }
				})
				.then((response) => {
					// setDataValues(response.data.data);
					setLoan(response.data.data.loan);
					response.data.data.loan.tips.map((tipData) => {
						if (tipData.type === tipProp) {
							setDataTips(tipData);
						}
					});
				})
				.catch((error) => {
					console.log(error)
				});
		}
	};

	const getEuribor = () => {
		axios
			.get(`${env.api.url}/v1/rates/by-month/euribor`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setData(response.data.data.rates);
			})
			.catch((error) => {
				console.log(error)
			});
	};

	if (loan) {
		return (
			<Layout style={{ background: '#FFFFFF' }}>
				{loan.creationType === 'AUTOMATIC' && (
					<CompareAutomatic
						loanId={loanId}
						rateTypeProp={rateTypeProp}
						tipProp={tipProp}
						visible={visible}
					/>
				)}
				{loan.creationType === 'MANUAL' && <CompareManual />}
			</Layout>
		);
	} else {
		return false;
	}
};

export default LoanComparativePageNodataDesktop;
