import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Collapse, Tabs } from "antd";
import ViabilityComponentInmo from "./Components/vibilityComponentInmo";
import DataInformation from "./dataInformation";
import DataInformationEco from "./dataInformationEco";
import StepsNewStyle from "./Components/StepsNewStyle";
import { stagesNameMobile, titlePageMobile } from "../reusableItems";
import BreadcrumbsInmo from "./Components/BreadcrumbsInmo";
import DocumentMobileComponent from "./Components/DocumentComponent";
import BanksComponent from "./Components/BanksComponent";
import FormalizationComponent from "./Components/FormalizationComponent";
import StepsHorizontal from "./Components/stepsHorizontal";
import AgentInformation from "./Components/AgentInformation";
import rightArrowIcon from "../../../assets/icons/rightArrowIcon.svg";
import infoBlueIcon from "../../../assets/icons/infoBlueIcon.svg";
import FileOutlinedIcon from "../../../assets/icons/FileOutlinedIcon.svg";
import UnorderedListOutlinedIcon from "../../../assets/icons/UnorderedListOutlinedIcon.svg";

const { Text } = Typography;
const { Panel } = Collapse;
const { TabPane } = Tabs;

const CardInformationMobile = ({
  stages,
  valuesData,
  ownerList,
  dataMortgage,
  viabilityData,
  dataSteps,
  eco,
  stage,
  nameHP,
  userMortgageId,
  pospone,
  close,
  dataOwner,
  infoAgents,
  formalization,
}) => {
  const { t } = useTranslation();
  const [pages, setPages] = useState(
    stages === "basic" ? 2 : stages === "full" ? 2 : 1
  );
  const [tabsValue, setTabsValue] = useState("1");
  const onChangeTabs = (tabs) => {
    setTabsValue(tabs);
  };
  const componenstName = {
    basic: "Stages.basic",
    full: "menuComponent.full",
    documents: "document.documentation",
    analysis: "document.analystics",
    banks: "document.bank",
    oferts: "offers.offert",
    appraisal: "appraisal.appraisal",
    formalization: "formalization.formalization",
  };

  return (
    <>
      {pages === 1 && (
        <div>
          <BreadcrumbsInmo
            name={nameHP}
            status={stages}
            operationType={
              dataMortgage && dataMortgage.operation
                ? dataMortgage.operation.subtype
                : dataMortgage.mortgage &&
                  dataMortgage &&
                  dataMortgage.mortgage.operationType &&
                  dataMortgage.mortgage.operationType
            }
            type={"mortgage"}
            bol={false}
          />
          {stagesNameMobile(
            t(componenstName[stages]),
            setPages,
            3,
            <div
              style={{
                height: 24,
                width: 24,
                textAlign: "center",
              }}
            >
              <img src={infoBlueIcon} alt="infoBlueIcon" height={18} />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#ffffff",
              padding: "12px 16px",
              width: "100%",
            }}
            onClick={() => setPages(2)}
          >
            <div style={{ display: "flex" }}>
              <div
                style={{
                  height: 24,
                  width: 24,
                  textAlign: "center",
                  marginRight: 16,
                }}
              >
                <img
                  src={UnorderedListOutlinedIcon}
                  alt="UnorderedListOutlinedIcon"
                  height={12}
                />
              </div>
              <Text style={{ fontSize: 16, fontWeight: 400, color: "#2F4858" }}>
                {t("certificate.infoOperation")}
              </Text>
            </div>
            <div
              style={{
                height: 24,
                width: 24,
                textAlign: "center",
              }}
            >
              <img src={rightArrowIcon} alt="rightArrowIcon" height={18} />
            </div>
          </div>
          {(stages === "analysis" || stages === "banks") && !pospone && !close && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#ffffff",
                padding: "12px 16px",
                width: "100%",
              }}
              onClick={() => setPages(4)}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    height: 24,
                    width: 24,
                    textAlign: "center",
                    marginRight: 16,
                  }}
                >
                  <img
                    src={FileOutlinedIcon}
                    alt="FileOutlinedIcon"
                    height={18}
                  />
                </div>
                <Text
                  style={{ fontSize: 16, fontWeight: 400, color: "#2F4858" }}
                >
                  {t("document.filePendding")}
                </Text>
              </div>
              <div
                style={{
                  height: 24,
                  width: 24,
                  textAlign: "center",
                }}
              >
                <img src={rightArrowIcon} alt="rightArrowIcon" height={18} />
              </div>
            </div>
          )}
          {stages === "documents" && !pospone && !close && (
            <div style={{ margin: 16 }}>
              <DocumentMobileComponent userMortgageId={userMortgageId} />
            </div>
          )}
          {(stages === "banks" || stages === "oferts") && !pospone && !close && (
            <div style={{ margin: 16, marginTop: 24 }}>
              <BanksComponent
                userMortgageId={userMortgageId}
                stages={stages}
                width={"auto"}
              />
            </div>
          )}
          {stages === "appraisal" && !pospone && !close && (
            <div style={{ marginTop: 32, marginLeft: 16 }}>
              <StepsHorizontal
                data={[
                  {
                    name: t("operationStatus.finishPay"),
                    date:
                      dataMortgage &&
                      dataMortgage.mortgage &&
                      dataMortgage.mortgage.paidAppraisalDate
                        ? dataMortgage.mortgage.paidAppraisalDate
                        : valuesData &&
                          valuesData.appraisalStatus &&
                          valuesData.appraisalStatus.appraisalPaidDate
                        ? valuesData.appraisalStatus.appraisalPaidDate
                        : null,
                  },
                  {
                    name: t("appraisal.stepsAvailable"),
                    date:
                      valuesData &&
                      valuesData.appraisalStatus &&
                      valuesData.appraisalStatus.appraisalAvailableDate,
                  },
                  {
                    name: t("appraisal.stepsApproved"),
                    // date: infoBanks.createdAt && infoBanks.responseDate,
                  },
                ]}
                width={"100%"}
                status={
                  dataMortgage &&
                  dataMortgage.mortgage &&
                  dataMortgage.mortgage.bankAppraisalApproval &&
                  dataMortgage.mortgage.bankAppraisalApproval === true
                    ? 3
                    : dataMortgage &&
                      dataMortgage.mortgage &&
                      dataMortgage.mortgage.reportAppraisal !== undefined
                    ? 2
                    : dataMortgage &&
                      dataMortgage.mortgage &&
                      dataMortgage.mortgage.appraisalPaid === true
                    ? 1
                    : 0
                }
              />
            </div>
          )}
          {stages === "formalization" && !pospone && !close && (
            <div style={{ margin: 16 }}>
              <FormalizationComponent dataMortgage={dataMortgage} />
            </div>
          )}
          <div style={{ margin: 16 }}>
            {pospone}
            {close}
          </div>
          <AgentInformation
            image={
              infoAgents &&
              infoAgents.agent &&
              infoAgents.agent.avatar &&
              infoAgents.agent.avatar.link &&
              infoAgents.agent.avatar.link
            }
            name={infoAgents && infoAgents.agent && infoAgents.agent.name}
            phone={infoAgents && infoAgents.agent && infoAgents.agent.phone}
            email={infoAgents && infoAgents.agent && infoAgents.agent.email}
            formalization={formalization}
          />
        </div>
      )}
      {pages === 2 && (
        <div style={{ margin: 16, marginBottom: 70 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {titlePageMobile(
              setPages,
              1,
              t("certificate.infoOperation"),
              (stages === "basic" || stages === "full") && (
                <div
                  style={{
                    fontSize: 12,
                    color: "#02C3CD",
                    marginLeft: 8,
                    fontWeight: 600,
                  }}
                >
                  {nameHP}
                </div>
              )
            )}
          </div>
          <Tabs
            defaultActiveKey="1"
            centered
            className="tabs-inmo"
            onChange={onChangeTabs}
          >
            <TabPane
              tab={
                tabsValue === "1" ? (
                  <div style={{ fontWeight: 600 }}>
                    {t("form.informationData")}
                  </div>
                ) : (
                  t("form.informationData")
                )
              }
              key="1"
            >
              {!eco && (
                <DataInformation
                  stages={stages}
                  valuesData={valuesData}
                  ownerList={ownerList}
                  dataMortgage={dataMortgage}
                  viabilityData={viabilityData}
                />
              )}
              {eco && (
                <DataInformationEco
                  stages={stages}
                  valuesData={valuesData}
                  ownerList={ownerList}
                  dataMortgage={dataMortgage}
                  viabilityData={viabilityData}
                />
              )}

              {!eco && (
                <Collapse
                  style={{
                    marginLeft: -16,
                    marginRight: -16,
                    backgroundColor: "#FFFFFF",
                  }}
                  expandIconPosition={"end"}
                  defaultActiveKey={["1"]}
                  ghost
                >
                  <Panel
                    header={
                      <div
                        style={{
                          fontSize: 16,
                          color: "#2F4858",
                          fontWeight: 400,
                        }}
                      >
                        {t("form.description")}
                      </div>
                    }
                    key="1"
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#748EA0",
                      }}
                    >
                      {valuesData.operation.description}
                    </Text>
                  </Panel>
                  <Panel
                    header={
                      <div
                        style={{
                          fontSize: 16,
                          color: "#2F4858",
                          fontWeight: 400,
                        }}
                      >
                        {t("details.otherData")}
                      </div>
                    }
                    key="2"
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#748EA0",
                      }}
                    >
                      {valuesData.operation.otherData}
                    </Text>
                  </Panel>
                </Collapse>
              )}
            </TabPane>
            {!eco &&
              dataMortgage &&
              dataMortgage.mortgage &&
              dataMortgage.mortgage.operationType &&
              dataMortgage.mortgage.operationType !== "subrogation" && (
                <TabPane
                  tab={
                    tabsValue === "2" ? (
                      <div style={{ fontWeight: 600 }}>
                        {t("m_viability.viabilityTitle")}
                      </div>
                    ) : (
                      t("m_viability.viabilityTitle")
                    )
                  }
                  key="2"
                >
                  <ViabilityComponentInmo
                    viabilityless={true}
                    viabilityData={viabilityData && viabilityData}
                    dataMortgage={dataMortgage.mortgage}
                    userMortgageId={userMortgageId}
                    dataOwner={dataOwner}
                  />
                </TabPane>
              )}
          </Tabs>
        </div>
      )}

      {pages === 3 && (
        <div style={{ margin: 16, marginBottom: 50 }}>
          {titlePageMobile(setPages, 1, t("document.modatTitlteSteper"))}
          <StepsNewStyle stage={stage} dataSteps={dataSteps} height={"auto"} />
        </div>
      )}
      {pages === 4 && (
        <div style={{ margin: 16, marginBottom: 50 }}>
          {titlePageMobile(setPages, 1, t("document.filePendding"))}
          <DocumentMobileComponent userMortgageId={userMortgageId} />
        </div>
      )}
    </>
  );
};
export default CardInformationMobile;
