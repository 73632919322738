import React, { useState } from "react";
import {
  Layout,
  Typography,
  Card,
  Button,
  Row,
  Avatar,
  Segmented,
  Col,
} from "antd";

import sergio from "../assets/sergio.jpg";
import {
  AliwangwangOutlined,
  CodeSandboxOutlined,
  SettingOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

const Devtools = () => {

  const [showImage, setshowImage] = useState(false);

  const saveTextAB = (value) => {
    if (value === "1") {
      sessionStorage.setItem("testA/B", false);
    } else if (value === "2") {
      sessionStorage.setItem("testA/B", true);
    } else {
      sessionStorage.removeItem("testA/B");
    }
  };

  const showImageClick = () => {
    setshowImage(true);
  };

  return (
    <Layout style={{ padding: 40 }}>
      <Card className="gb-card">
        <Text style={{ fontSize: 16, fontWeight: 600 }}>
          Configuración para los test A/B
        </Text>

        <Row gutter={40}>
          <Col span={12}>
            {!showImage && (
              <Button onClick={() => showImageClick()}>
                Click to surprise!
              </Button>
            )}
            {showImage && (
              <div style={{ textAlignLast: "center", marginTop: 32 }}>
                <Text strong>
                  Surprise surprise motherfucker the king is back!!
                </Text>
                <img src={sergio} alt="sergio" style={{ width: "100%" }}></img>
              </div>
            )}
          </Col>
          <Col span={12} style={{ marginTop: 200 }}>
            <Segmented
              onChange={(e) => saveTextAB(e)}
              options={[
                {
                  label: (
                    <div style={{ padding: 4 }}>
                      <Avatar
                        style={{ backgroundColor: "#87d068" }}
                        icon={<SettingOutlined />}
                      ></Avatar>
                      <div>Configuración antigua</div>
                    </div>
                  ),
                  value: "1",
                },
                {
                  label: (
                    <div style={{ padding: 4 }}>
                      <Avatar
                        style={{ backgroundColor: "#87d068" }}
                        icon={<AliwangwangOutlined />}
                      ></Avatar>
                      <div>Ir a text A/B</div>
                    </div>
                  ),
                  value: "2",
                },
                {
                  label: (
                    <div style={{ padding: 4 }}>
                      <Avatar
                        style={{ backgroundColor: "#87d068" }}
                        icon={<CodeSandboxOutlined />}
                      />
                      <div>Configuración random</div>
                    </div>
                  ),
                  value: "3",
                },
              ]}
            />
          </Col>
        </Row>
      </Card>
    </Layout>
  );
};

export default Devtools;
