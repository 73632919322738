import React from 'react';
import { Typography, Modal, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const ModalEditName = ({ visible, onCreate, onCancel, onChangeName }) => {
	const { t } = useTranslation();

	return (
        (<Modal
			className="gb-modal-40-padding"
			width={480}
			open={visible}
			onCancel={onCancel}
			onOk={onCreate}
			okText={
				<Text style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 14 }}>
					{t('viability.confirmButton')}
				</Text>
			}
			cancelText={
				<Text style={{ color: '#02C3CD', fontWeight: 600, fontSize: 14 }}>
					{t('offers.cancel')}
				</Text>
			}
			cancelButtonProps={{ style: { width: '44%', height: 40 } }}
			okButtonProps={{ style: { width: '44%', height: 40 } }}
			title={
				<Text
					style={{
						fontSize: 16,
						fontWeight: 600,
						color: '#2F4858'
					}}>
					{t('loan.modalTitlteEditName')}
				</Text>
			}>
            <div
				style={{
					display: 'flex',
					flexDirection: 'column',
					marginBottom: 0,
					marginTop: 22
				}}>
				<Text
					style={{
						fontSize: 14,
						fontWeight: 400,
						color: '#748EA0'
					}}>
					{t('loan.editLoanNameDesktop')}
				</Text>

				<Input
					style={{ height: 40, marginTop: 24, width: '100%' }}
					placeholder={'Prestamo 1'}
					onChange={(value) => onChangeName(value)}></Input>
			</div>
        </Modal>)
    );
};

export default ModalEditName;
