import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

function WidgetsDashboardRealStateSmall({ data, title }) {
  const { t } = useTranslation();
  return (
    <div
      className="style-colunms-left"
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Text
        className="box-subtext"
        style={{
          // marginLeft: 12,
          fontSize: 15,
        }}
      >
        {t(title).slice(0, 2) + t(title).slice(10)}
      </Text>
      <Text className="box-text">{data.data.quantity}</Text>
    </div>
  );
}

export default WidgetsDashboardRealStateSmall;
