import React, { useState, useEffect } from 'react';
import { Layout, Card, Typography, Button, Form, Modal, Avatar, Row, Col, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import axios from 'axios';
import env from '../../../../environment';
import LayoutOperations from '../../LayoutOperations';
import AfterBanksComponent from '../../../../components/AfterBanks/Afterbanks';
import Plus from '../../../../assets/loans/plus.png';
import BankIcon from '../../../../assets/loans/bankIcon.png';
import chebronBlack from '../../../../assets/loans/chebronBlack.png';
import SliderDashboard from '../LoansDesktop/SliderDashboard';
import ModalDataCollection from '../Modals/ModalDataCollection';
import ModalButtonsCommon from '../Modals/ModalButtonsCommon';
import { getAnalytics, logEvent } from 'firebase/analytics';

const { Text } = Typography;

const LoanPageNoDataMobile = ({}) => {
	const location = useLocation();
	const { t } = useTranslation();
	const navigate = useNavigate();;
	const [nameBanks, setNameBanks] = useState(null);
	const userToken = useSelector((state) => state.auth.token);
	const [form] = Form.useForm();
	const [afterbanks, setAfterBanks] = useState(false);
	const [bankId, setBankId] = useState(undefined);
	const [modalAutomaticOrManual, setModalAutomaticOrManual] = useState(0);
	const [errorDataModal, setErrorDataModal] = useState(false);
	const [loanList, setLoanList] = useState([]);

	const [loanId, setLoanId] = useState(undefined);
	const analytics = getAnalytics();

	useEffect(() => {
		axios
			.get(`${env.api.url}/v1/banks/search?bankreader=true&status=ongoing`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				const otherBank = {
					name: 'Otros',
					id: undefined,
					bankCode: undefined
				};
				response.data.data.banks.unshift(otherBank);
				setNameBanks(response.data.data.banks);
			});
		getLoansList();

		logEvent(analytics, 'screen_view', {
			screen_name: 'loan_screen-add-view'
		});
	}, []);

	const getLoansList = () => {
		axios
			.get(`${env.api.url}/v1/loans/get-loans-list`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				
					setLoanList(response.data.data.loans.reverse());
				
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const handleAddLoan = () => {
		navigate('/my-financial-profile/credits/add/loan');
	};

	var myVar = undefined;
	let values = 0;
	useEffect(() => {
		if (loanId) {
			myVar = setInterval(() => {
				axios
					.get(`${env.api.url}/v1/loans/get-loan/${loanId}`, {
						headers: { Authorization: `Bearer ${userToken}` }
					})
					.then((response) => {
						if (response.data.success) {
							values = values + 1;
							if (response.data.data.loan.status === 'EMPTY' && values === 4) {
								myStopFunction();
								setAfterBanks(false);
								setErrorDataModal(true);
								values = 0;
							}
							if (response.data.data.loan.status === 'PENDING_USER') {
								myStopFunction();
								handleafterBanks();
								values = 0;
							}
						}
					})
					.catch(() => {
						myStopFunction();
						handleafterBanks();
						values = 0;
					});
			}, 15000);
		}
	}, [loanId]);

	const myStopFunction = () => {
		clearInterval(myVar);
	};

	

	const handleafterBanksCancel = () => {
		setBankId(undefined)
		setAfterBanks(false);
	};

	const handleafterBanks = () => {
		setAfterBanks(false);
		navigate(`/my-financial-profile/credits/edit/all-info-checked/${loanId}`);
	};

	const onSearch = (e) => {
		if (e.target.value === '') {
			axios
				.get(`${env.api.url}/v1/banks/search?bankreader=true&status=ongoing`, {
					headers: {
						Authorization: `Bearer ${userToken}`
					}
				})
				.then((response) => {
					const otherBank = {
						name: 'Otros',
						id: undefined,
						bankCode: undefined
					};
					response.data.data.banks.unshift(otherBank);
					setNameBanks(response.data.data.banks);
				});
		} else {
			axios
				.get(`${env.api.url}/v1/banks/search?bankreader=true&status=ongoing&q=${e.target.value}`, {
					headers: {
						Authorization: `Bearer ${userToken}`
					}
				})
				.then((response) => {
					const otherBank = {
						name: 'Otros',
						id: undefined,
						bankCode: undefined
					};
					response.data.data.banks.unshift(otherBank);
					setNameBanks(response.data.data.banks);
				});
		}
	};

	const handleClickFormData = (bank) => {
		if (bank !== undefined) {
			setBankId(bank);
			if (bank.bankreaderMortgage) {
				setModalAutomaticOrManual(!modalAutomaticOrManual);
			} else {
				handleCreateManual(bank.id);
			}
		} else {
			handleCreateManual(undefined);
		}
	};

	const handleCreateAutomatic = () => {
		setAfterBanks(!afterbanks);
		setModalAutomaticOrManual(!modalAutomaticOrManual);
	};

	const handleCreateManual = (id) => {
		if (bankId !== undefined) {
			navigate(`/my-financial-profile/credits/form/${bankId.id}`);
			setModalAutomaticOrManual(!modalAutomaticOrManual);
		} else if (id) {
			navigate(`/my-financial-profile/credits/form/${id}`);
		} else {
			navigate(`/my-financial-profile/credits/form/${bankId}`);
		}
	};

	if (location.pathname === '/my-financial-profile/credits') {
		return (
			<Layout
				className="gb-loans-page-initial"
				style={{
					marginBottom: 100,
					height: '100vh'
				}}>
				<LayoutOperations />

				{location.pathname === '/my-financial-profile/credits' ? (
					<>
						<div
							style={{
								alignSelf: 'center',
								display: 'flex',
								flexDirection: 'column'
							}}>
							<div style={{ marginTop: 0, textAlign: 'center', width: 296 }}>
								<Text style={{ fontSize: 16, color: '#2F4858', fontWeight: 600 }}>
									{t('loan.emptyListMortgageTitle')}
								</Text>
							</div>
							<div style={{ marginTop: 16, textAlign: 'center', width: 296 }}>
								<Text style={{ fontSize: 14, color: '#748EA0', fontWeight: 400 }}>
									{t('loan.noDataDescriptionMortgage')}
								</Text>
							</div>
						</div>
						<SliderDashboard />
						<Card className="cardButtonStyle" style={{ left: 0, marginBottom: -2, zIndex: 5 }}>
							<Button
								className="button-primari-gibobs"
								type="primary"
								onClick={() => handleAddLoan()}
								style={{ width: '100%', height: 48 }}>
								{t('init.myMortgageButtonAdd')}
							</Button>
						</Card>
					</>
				) : null}
			</Layout>
		);
	} else {
		return (
			<Layout style={{ padding: 16, marginBottom: 80 }}>
				{location.pathname === '/my-financial-profile/credits/add/loan' ? (
					<div style={{ width: '100%', marginBottom: 30 }}>
						{loanList && loanList.length === 0 && (
							<a onClick={() => navigate('/my-financial-profile/credits')}>
								<img src={chebronBlack} style={{ height: 24, width: 24 }} />
							</a>
						)}
						{loanList && loanList.length > 0 && (
							<a onClick={() => navigate('/my-financial-profile/credits/list')}>
								<img src={chebronBlack} style={{ height: 24, width: 24 }} />
							</a>
						)}
						<Text className="title-page" style={{ marginLeft: 16 }}>
							{t('loan.chooseBank')}
						</Text>

						<div style={{ marginTop: 24 }}>
							<Form form={form} layout="vertical" className="gb-form-profile-headlines-mobile">
								<Form.Item label={''} name="haveABank">
									<Input
										className="gb-search-input"
										onChange={onSearch}
										placeholder={t('loan.chooseBankForm')}></Input>
									<div
										style={{
											width: 15,
											height: 15,
											position: 'relative',
											top: -28,
											right: 10,
											float: 'right'
										}}>
										<SearchOutlined style={{ color: '#748EA0', fontSize: 16 }} />
									</div>
								</Form.Item>
							</Form>
						</div>

						<Row style={{ marginTop: -24, display: 'flex', textAlign: 'center' }}>
							{nameBanks ? (
								nameBanks.map((nameBank) => {
									return (
										<Col span={6} style={{ marginTop: 24 }}>
											{nameBank.name !== 'Otros' ? (
												<>
													<div onClick={() => handleClickFormData(nameBank)}>
														<Avatar
															size={64}
															style={{
																boxShadow: '0px 0px 8px rgba(47, 72, 88, 0.1)',
																marginBottom: 4,
																background: 24
															}}>
															<img
																src={`https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/banks/${
																	nameBank.bankCode + '_iso.png'
																}`}
																style={{
																	width: 32
																}}
															/>
														</Avatar>
													</div>
													<Text
														style={{
															color: '#2F4858',
															fontSize: 12,
															fontWeight: 400
														}}>
														{nameBank.name}
													</Text>
												</>
											) : (
												<>
													<div
														onClick={() => handleClickFormData(undefined)}
														style={{ marginTop: 0 }}>
														<Avatar
															size={64}
															style={{
																boxShadow: '0px 0px 8px rgba(47, 72, 88, 0.1)',
																marginBottom: 4,
																background: 24
															}}>
															<img
																src={BankIcon}
																style={{
																	width: 32
																}}
															/>
														</Avatar>
													</div>
													<Text
														style={{
															color: '#2F4858',
															fontSize: 12,
															fontWeight: 400
														}}>
														{nameBank.name}
													</Text>
												</>
											)}
										</Col>
									);
								})
							) : (
								<Col
									span={24}
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										marginTop: 24
									}}>
									<div onClick={() => handleClickFormData(undefined)}>
										<Avatar size={84} src={Plus} style={{ marginBottom: 4 }} />
									</div>
									<Text
										style={{
											color: '#2F4858',
											fontSize: 12,
											fontWeight: 400
										}}>
										{t('loan.noBankAvatar')}
									</Text>

									<div style={{ padding: 32, marginTop: 10 }}>
										<div>
											<Text
												style={{
													color: '#748EA0',
													fontSize: 14,
													fontWeight: 400
												}}>
												{t('loan.chooseBankSearch1')}
											</Text>
										</div>

										<div style={{ marginTop: 24 }}>
											<Text
												style={{
													color: '#748EA0',
													fontSize: 14,
													fontWeight: 400
												}}>
												{t('loan.chooseBankSearch2')}
											</Text>
										</div>
									</div>
								</Col>
							)}
						</Row>
					</div>
				) : null}

				<Modal
					className="modalMobileAfterBanks"
					open={afterbanks}
					onCancel={() => handleafterBanksCancel(false)}
					onOk={() => handleafterBanks()}
					//okButtonProps={{ disabled: fileAfterbanks ? false : true }}
					cancelButtonProps={{
						style: { border: 'none', contentVisibility: 'hidden' }
					}}
					destroyOnClose={true}
					closable={true}
					footer={null}>
					<AfterBanksComponent
						type="loan"
						bankId={bankId && bankId.id}
						bank={bankId}
						setLoanId={setLoanId}></AfterBanksComponent>
				</Modal>
				<ModalButtonsCommon
					key={'errorDataModal'}
					visible={errorDataModal}
					onCreate={() => handleCreateManual(bankId)}
					onCancel={() => setErrorDataModal(false)}
					okText={'offers.accept'}
					cancelText={'offers.cancel'}
					title={'loan.IncompleteDataModalTitle'}
					text={t('loan.IncompleteDataModalDescription')}
				/>
				<ModalDataCollection
					key={'modalAutomaticOrManual'}
					visible={modalAutomaticOrManual}
					onCreate={() => setModalAutomaticOrManual(!modalAutomaticOrManual)}
					onCancel={() => setModalAutomaticOrManual(!modalAutomaticOrManual)}
					width={800}
					handleCreateAutomatic={() => handleCreateAutomatic()}
					handleCreateManual={() => handleCreateManual()}
				/>
			</Layout>
		);
	}
};

export default LoanPageNoDataMobile;
