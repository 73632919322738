import React, { useState } from "react";
import { Form, Button, Collapse } from "antd";
import { useTranslation } from "react-i18next";
import { isMobileOnly } from "react-device-detect";
import { notificationGibobs } from "../../HerramientasVenta/reusableItems";
import ModalDescriptionFinancial from "../ModalDescriptionFinancial";
import { InfoCircleOutlined } from "@ant-design/icons";
import { GeneralDataForm } from "../GeneralDataForm";
import { FormFinancialData } from "../FinancialDataForm";
import { FormCapitalStructure } from "../CapitalStructureForm";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import env from "../../../environment";
import {
  getPromoterData,
  getScenariosData,
} from "../../../store/promoter/actions";

const { Panel } = Collapse;

const ProjectData = ({ operationId, setLoading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modalDescription, setModalDescription] = useState(false);
  const [form] = Form.useForm();
  const dataPromoter = useSelector((state) => state.promoter.dataFormPromoter);
  const userToken = useSelector((state) => state.auth.token);

  const openNotificationWithIcon = () => {
    notificationGibobs(
      "error",
      t("m_full.errorTitle"),
      t("form.incompleteInformation")
    );
  };

  const validateMessages = {
    required: "${label}" + " " + t("form.formRequired"),
    types: {
      email: t("form.emailValidated"),
      number: "${label} is not a valid number!",
    },
    number: {
      range: t("form.maxBudgetNewRequestValidated") + "${min}",
    },
  };
  const sendNewSimulation = (values) => {
    setLoading(true);
    const objSimulation = {
      constructionData: dataPromoter && dataPromoter.constructionData,
      generalData:
        values.buildability === undefined
          ? dataPromoter && dataPromoter.generalData
          : {
              province: values.province,
              salesUnits: Number(values.salesUnits),
              buildability: Number(values.buildability),
              preSalesUnits: Number(values.preSalesUnits),
              landInProperty: values.landInProperty,
              worksInitiated: values.worksInitiated,
              buildingPermission: values.buildingPermission,
              promotionPopulation: values.promotionPopulation,
              percentageOfPreSales: Number(values.percentageOfPreSales),
              percentageOfWorkStarted: Number(values.percentageOfWorkStarted),
            },
      economicData:
        values.incomes === undefined
          ? dataPromoter && dataPromoter.economicData
          : {
              economicDataUnits: "TOTAL_EUROS",
              projectIncomes: {
                incomes: Number(values.incomes),
              },
              projectCosts: {
                landCosts: Number(values.landCosts),
                businessCosts: Number(values.businessCosts),
                constructionsCosts: Number(values.constructionsCosts),
                managementCosts: Number(values.managementCosts),
                associatedCosts: Number(values.associatedCosts),
                totalCosts: Number(values.totalCosts),
              },
            },
      capitalStructure:
        values.equity === undefined
          ? dataPromoter && {
              equity: Number(dataPromoter.capitalStructure.equity),
              financingRequired: Number(
                dataPromoter.totalCosts - dataPromoter.capitalStructure.equity
              ),
              financingPeriod: Number(
                dataPromoter.capitalStructure.financingPeriod
              ),
            }
          : {
              equity: Number(values.equity),
              financingRequired: Number(values.financingRequired),
              financingPeriod: Number(values.financingPeriod),
            },
    };
    axios
      .post(
        `${env.api.url}/promoters/simulate-scenarios/update/${operationId}?scenarioOwner=CLIENT`,
        objSimulation,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          dispatch(getScenariosData(response.data.data));
          dispatch(getPromoterData({ ...objSimulation }));
        }
      });
  };
  const nameStages = {
    simulation: t("m_viability.simulationTitle"),
    analysis: t("document.analystics"),
    documentation: t("document.documentation"),
    oferts: t("m_offers.title"),
    formalization: t("formalisation.formalisation"),
    finish: t("stages.closed"),
    SALE: t("promoterLoan.saleTitle"),
    RESIDENTIAL: t("promoterLoan.residentialTitle"),
    OTHER: t("promoterLoan.otherTitle"),
    SINGLE_FAMILY: t("promoterLoan.singleFamily"),
    MULTI_FAMILY: t("promoterLoan.multiFamily"),
    undefined: "-",
  };
  return (
    <>
      <div className="title-project-data">
        {t("promoterLoan.purposeConstruction")}
      </div>
      <div className="paragraph-project-data" style={{ marginBottom: 16 }}>
        {
          nameStages[
            dataPromoter &&
              dataPromoter.constructionData &&
              dataPromoter.constructionData.constructionPurpose
          ]
        }{" "}
        /{" "}
        {
          nameStages[
            dataPromoter &&
              dataPromoter.constructionData &&
              dataPromoter.constructionData.constructionUseTypes
          ]
        }{" "}
        -{" "}
        {
          nameStages[
            dataPromoter &&
              dataPromoter.constructionData &&
              dataPromoter.constructionData.houseType
          ]
        }
      </div>
      <Form
        className={"gb-form-profile-headlines-mobile"}
        onFinishFailed={openNotificationWithIcon}
        scrollToFirstError={true}
        onFinish={sendNewSimulation}
        layout="vertical"
        name="Password"
        validateMessages={validateMessages}
        initialValues={
          dataPromoter !== undefined
            ? {
                ...dataPromoter["generalData"],
                ...dataPromoter["capitalStructure"],
              }
            : {}
        }
        form={form}
      >
        <Collapse
          defaultActiveKey={["4"]}
          ghost
          expandIconPosition={"end"}
          className="collapse-gibobs-promoter"
        >
          <Panel
            header={
              <div className="title-project-data">
                1. {t("promoterLoan.generalData")}
              </div>
            }
            key="1"
          >
            {GeneralDataForm({ xs: 24, lg: 24 }, { xs: 24, lg: 12 }, form)}
          </Panel>
          <Panel
            header={
              <div className="title-project-data">
                2. {t("viability.economicData")}{" "}
                <InfoCircleOutlined
                  style={{ color: "#02C3CD", marginLeft: 10 }}
                  onClick={(event) => {
                    setModalDescription(true);
                    event.stopPropagation();
                  }}
                />
              </div>
            }
            key="2"
          >
            {FormFinancialData(24, form, 24)}
          </Panel>
          <Panel
            header={
              <div className="title-project-data">
                3. {t("promoterLoan.capitalStructure")}
              </div>
            }
            key="3"
          >
            {FormCapitalStructure(24, form)}
          </Panel>
        </Collapse>
        <Form.Item shouldUpdate>
          {() => (
            <Button
              type="primary"
              className="button-primari-gibobs"
              style={{
                margin: "auto",
                marginTop: 40,
                width: "100%",
              }}
              htmlType="submit"
              disabled={form.isFieldsTouched(true)}
            >
              {t("viability.repeatAnalysis")}
            </Button>
          )}
        </Form.Item>
      </Form>
      <ModalDescriptionFinancial
        key={modalDescription}
        visible={modalDescription}
        onCreate={() => setModalDescription(false)}
        onCancel={() => setModalDescription(false)}
        width={!isMobileOnly && 880}
      />
    </>
  );
};

export default ProjectData;
