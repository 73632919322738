import { isMobileOnly } from 'react-device-detect';
import LoanPageNoDataDesktop from './LoansDesktop/LoanPageNoDataDesktop';
import LoanPageNoDataMobile from './LoansMobile/LoanPageNoDataMobile';

const LoanswitchPCorMobile = ({ addLoan }) => {
	return (
		<>
			{isMobileOnly ? (
				<LoanPageNoDataMobile addLoan={addLoan} />
			) : (
				<LoanPageNoDataDesktop addLoan={addLoan} />
			)}
		</>
	);
};

export default LoanswitchPCorMobile;
