import React, { useState, useEffect } from 'react';
import {
	Layout,
	Card,
	Typography,
	Form,
	InputNumber,
	Select,
	Col,
	Row,
	Modal,
	Alert,
	Button
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import env from '../../../../environment';
import { isNull } from 'lodash';
import AfterBanksComponent from '../../../../components/AfterBanks/Afterbanks';
import LoaderLogo from '../../../../components/LoaderLogo';
import Check from '../../../../assets/loans/check.png';
import BulpBlue from '../../../../assets/loans/bulpBlue.png';
import bankIcon from '../../../../assets/loans/bankIcon.png';
import InfoYellow from '../../../../assets/loans/InfoYellow.png';
import checkBlack from '../../../../assets/loans/checkBlack.png';
import left from '../../../../assets/loans/left.png';
import TipsManual from './TipsManual';
import TipsAutomatic from './TipsAutomatic';
import { getAnalytics, logEvent } from 'firebase/analytics';
import TermWidget from '../Widgets/TermWidget';
import PropertyWidget from '../Widgets/PropertyWidget';
import MonthlyFeeWidget from '../Widgets/MonthlyFeeWidget';
import EuriborCalculatorWidget from '../Widgets/EuriborCalculatorWidget';
import ProjectClientWidget from '../Widgets/ProjectClientWidget';
import CurrentConditionsWidget from '../Widgets/CurrentConditionsWidget';
import CapitalWidget from '../Widgets/CapitalWidget';
import AmortizationCalculatorWidget from '../Widgets/AmortizationCalculatorWidget';
import { isMobileOnly } from 'react-device-detect';
import infoIcon from '../../../../assets/loans/infoIcon.svg';
import ModalAmortizationExplination from '../Modals/ModalAmortizationExplination';
import ModalButtonsCommon from '../Modals/ModalButtonsCommon';
import { InputDate } from 'afrodita';

import dayjs from 'dayjs';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');
dayjs.locale('vi');

const { Text } = Typography;
const { Option } = Select;

const LoanPageDetailsNew = ({}) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const navigate = useNavigate();;
	const userToken = useSelector((state) => state.auth.token);
	let { loanId } = useParams();
	const [loan, setLoan] = useState(undefined);
	const [, setDays] = useState(0);
	const [diffe, setDiffe] = useState(0);
	const [, setTypeCurrentClient] = useState(undefined);
	const [, setTypeCurrentGibobs] = useState(undefined);
	const [modalAmortizationCalculator, setModalAmortizationCalculator] = useState(false);
	const dataProfile = useSelector((state) => state.data.dataProfile);
	const [bindings, setBindings] = useState(false);
	const [modalAutomatic, setModalAutomatic] = useState(false);
	const [afterbanks, setAfterBanks] = useState(false);
	const [bankId, setBankId] = useState(undefined);
	const [loading, setLoading] = useState(true);
	const location = useLocation();
	const analytics = getAnalytics();
	const [entailmentsDefault, setEntailmentsDefault] = useState(false);
	const [revisionDate, setRevisionDate] = useState(false);
	const [amortizeVisible, setAmortizeVisible] = useState(false);
	const [, setDataAmortize] = useState(0);
	const [amountInitial, setAmountInitial] = useState(false);
	const [percent, setPercent] = useState(undefined);
	const [valueHomeModal, setValueHomeModal] = useState(false);
	const [errorDataModal, setErrorDataModal] = useState(false);
	const [amount, setAmount] = useState(undefined);
	const [reviewPeriod, setReviewPeriod] = useState(undefined);
	const [initialHousePrice, setInitialHousePrice] = useState(undefined);

	const notificationShow =
		location && !isNull(location.state) ? location.state.notificationShow : undefined;

	let bindingsOptionsDefault = [
		{
			entailments: [
				{
					type: 'insecurance',
					amount: 0
				},
				{
					type: 'home',
					amount: 0
				},
				{
					type: 'pensionPlan',
					amount: 0
				},
				{
					type: 'cards',
					amount: 0
				},
				{
					type: 'car',
					amount: 0
				},
				{
					type: 'unemployment',
					amount: 0
				},
				{
					type: 'other',
					amount: 0
				}
			]
		}
	];

	useEffect(() => {
		getLoan();
		getAmortization();

		logEvent(analytics, 'screen_view', {
			screen_name: 'loanDetail.main'
		});
	}, []);

	useEffect(() => {
		getLoan();
		getAmortization();
	}, [bindings, loanId]);

	useEffect(() => {
		getLoan();
	}, [amount, reviewPeriod, initialHousePrice]);

	const getAmortization = () => {
		axios
			.post(
				`${env.api.url}/v1/loans/amortization-calculator`,
				{
					loanId: loanId
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				setDataAmortize(response.data.data);
				getLoan();
			})
			.catch((error) => {
                console.log(error)
            });
	};

	const getLoan = () => {
		axios
			.get(`${env.api.url}/v1/loans/get-loan/${loanId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				// setDataValues(response.data.data);
				if (response.data.data.loan.entailments.length === 0) {
					response.data.data.loan.entailments = bindingsOptionsDefault[0].entailments;
					setEntailmentsDefault(true);
				} else {
					setEntailmentsDefault(false);
				}
				if (
					response.data.data.loan.initialHousePrice !== undefined ||
					response.data.data.loan.initialHousePrice !== null
				) {
					const temp =
						response.data.data.loan.initialHousePrice - response.data.data.loan.initialAmount;

					const temp2 = response.data.data.loan.initialAmount - response.data.data.loan.amount;

					const percentAmount = response.data.data.loan.initialHousePrice
						? ((temp + temp2) * 100) / response.data.data.loan.initialHousePrice
						: undefined;
					setPercent(Math.round(percentAmount));
				}

				let dateSigned = dayjs(response.data.data.loan.signed);
				let today = dayjs(new Date());

				setDiffe(today.diff(dateSigned, 'days'));

				if (response.data.data.loan.status === 'EMPTY') {
					setTimeout(() => {
						getLoan();
					}, 15000);
				}

				if (response.data.data.loan.status !== 'EMPTY') {
					setLoading(false);
				}

				setLoan(response.data.data);
				setAmount(response.data.data.loan.amount);
				setInitialHousePrice(response.data.data.loan.initialHousePrice);
				setReviewPeriod(response.data.data.loan.reviewPeriod);

				setBankId(response.data.data.loan.bankId);

				if (response.data.data.loan.rateType === 'fixed') {
					setTypeCurrentClient(response.data.data.loan.rate);
					setTypeCurrentGibobs(response.data.data.loan.rate);
				} else {
					// setTypeCurrentClient(response.data.data.loan.rate + response.data.data.quote.index.toFixed(2)) euribor firma
					// setTypeCurrentGibobs(response.data.data.loan.rate ) + euriobor de este mes
				}

				let date1 = dayjs();
				let date2 = dayjs(response.data.data.quote.nextReview);

				setDays(date2.diff(date1, 'month'));
			})
			.catch((error) => {
                console.log(error)
            });
	};

	const backToPage = () => {
		navigate('/my-financial-profile/credits/list');
	};

	const addBindings = () => {
		setBindings(true);
		navigate(`/my-financial-profile/credits/all-detail-loan/${loanId}/binding`);
		logEvent(analytics, 'screen_view', {
			screen_name: 'loanDetail.entailments'
		});
	};

	const handleChangeRevisionDate = (values) => {
		axios
			.post(
				`${env.api.url}/v1/loans/update/${loanId}`,
				{
					lastReview: dayjs(values.lastRevison, 'DD-MM-YYYY').format('YYYY-MM-DD HH:mm'),
					reviewPeriod: values.reviewPeriod,
					version: '2023.v1'
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then(() => {
				setRevisionDate(false);
				getLoan();
			})
			.catch((error) => {
                console.log(error)
            });
	};

	const handleChangeAmortize = (values) => {
		axios
			.post(
				`${env.api.url}/v1/loans/update/${loanId}`,
				{
					finish: dayjs(values.finish, 'DD-MM-YYYY').format('YYYY-MM-DD'),
					amount: values.debt,
					version: '2023.v1'
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then(() => {
				setAmortizeVisible(false);
				getLoan();
			})
			.catch((error) => {
                console.log(error)
            });
	};

	const handleChangeInitialAmount = (values) => {
		axios
			.post(
				`${env.api.url}/v1/loans/update/${loanId}`,
				{
					initialHousePrice: values.initialHousePrice,
					amount: values.debt,
					version: '2023.v1'
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then(() => {
				setAmountInitial(false);
				getLoan();
			})
			.catch((error) => {
                console.log(error)
            });
	};

	const handleCreateAutomatic = () => {
		setAfterBanks(!afterbanks);
		setModalAutomatic(!modalAutomatic);
	};

	const handleafterBanksCancel = () => {
		setAfterBanks(false);
	};

	const handleafterBanks = () => {
		setAfterBanks(false);
	};
	const colSpan = { xxl: 6, xl: 6, lg: 8, md: 12, sm: 24, xs: 24 };
	const colSpanNotification = {
		xxl: 12,
		xl: 12,
		lg: 12,
		md: 12,
		sm: 24,
		xs: 24
	};
	
	const renderNotification = () => {
		let tecxtNotification = undefined;

		if (notificationShow === 'name') {
			tecxtNotification = t('loan.editMessageDescCompleted');
		} else if (notificationShow === 'amount') {
			tecxtNotification = t('loan.amountNotificationChange');
		} else if (notificationShow === 'binding') {
			tecxtNotification = t('loan.entailmentsChangeNotification');
		} else if (notificationShow === 'revision') {
			tecxtNotification = t('loan.editMessageDescCompleted');
		} else {
			return undefined;
		}
		return (
			<Alert
				message={
					<Text
						style={{
							color: '#2F4858',
							fontSize: 16,
							fontWeight: 600
						}}>
						{t('m_common.completedOperation')}
					</Text>
				}
				description={
					<Text
						style={{
							color: '#2F4858',
							fontSize: 14,
							fontWeight: 400
						}}>
						{tecxtNotification}
					</Text>
				}
				type="success"
				closable
				icon={<img src={Check} alt="sadf" style={{ width: 20, height: 20, marginTop: 3 }}></img>}
				className="alert-green"
				showIcon
				style={{
					borderRadius: 4,
					borderColor: '#52C41A',
					backgroundColor: '#DFF4D4',
					position: 'absolute',
					zIndex: 1000,
					top: 16,
					left: '35%'
				}}
			/>
		);
	};

	if (loading) {
		return (
			<div
				style={{
					textAlign: 'center',
					height: '100vh',
					backgroundColor: 'white'
				}}>
				<LoaderLogo />
				<Text style={{ position: 'relative', top: 600 }}>{t('loan.spinnerAfterban') + '...'}</Text>
			</div>
		);
	} else {
		return (<>
            <Layout
                style={{
                    marginBottom: 100,
                    padding: !isMobileOnly ? '24px 40px 0px' : '16px'
                }}>
                {renderNotification()}
                {loan &&
                    loan.loan.creationType === 'MANUAL' &&
                    // loan.loan.rateType === "variable" &&
                    loan.loan.bank !== undefined &&
                    loan.loan.bank.bankreaderMortgage && (
                        <Card
                            className="card-second"
                            style={{
                                background: '#FEF0D3',
                                width: '100%',
                                height: !isMobileOnly && 56,
                                border: '1px solid #FBC14F',
                                borderRadius: 4,
                                marginBottom: 24
                            }}>
                            <Row>
                                <Col span={16} style={{ display: 'flex' }}>
                                    <img src={InfoYellow} style={{ width: 24, height: 24 }}></img>

                                    <Text
                                        style={{
                                            color: '#2F4858',
                                            fontSize: 14,
                                            fontWeight: 400,
                                            marginLeft: 14
                                        }}>
                                        {t('loan.notificationVarAfter')}
                                    </Text>
                                </Col>
                                <Col span={8}>
                                    <div style={{ float: 'right' }} onClick={() => setAfterBanks(!afterbanks)}>
                                        <Text
                                            style={{
                                                color: '#BC820F',
                                                fontSize: 12,
                                                fontWeight: 500
                                            }}>
                                            {t('loan.getLoan')}
                                        </Text>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    )}

                {loan && bindings === false && (
                    <>
                        <div style={{ width: '100%', height: 'auto', marginBottom: 30 }}>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                <div>
                                    <a onClick={() => backToPage()}>
                                        <img src={left} style={{ width: 24, height: 24, marginTop: -2 }}></img>
                                    </a>
                                    <Text
                                        style={{
                                            color: '#02C3CD',
                                            fontSize: 16,
                                            fontWeight: 600,
                                            marginLeft: 12
                                        }}>
                                        {t('init.myLoan')}
                                    </Text>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'right'
                                    }}>
                                    <Button
                                        type="primary"
                                        className="button-primari-gibobs"
                                        onClick={() =>
                                            navigate(`/my-financial-profile/credits/edit/form/${loanId}`)
                                        }>
                                        {t('loan.editData')}
                                    </Button>
                                </div>

                                {/* {loan.loan.lastReview !== undefined &&
                loan.loan.lastReview !== null &&
                loan.loan.creationType !== "MANUAL" && (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "right",
                      marginTop: -20,
                      marginLeft: -10,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        color: "#748EA0",
                        fontWeight: 400,
                      }}
                    >
                      {t("m_loan.lastRevisionDate")}
                    </Text>
                    <Text
                      style={{
                        fontSize: 16,
                        color: "#2F4858",
                        fontWeight: 600,
                        marginLeft: 8,
                        marginTop: -3,
                      }}
                    >
                      {moment(loan.loan.lastReview).format("DD-MM-YYYY")}
                    </Text>
                  </div>
                )} */}
                            </div>

                            {loan && (
                                <Row gutter={24} style={{ height: '100%', marginTop: 32 }}>
                                    <Col {...colSpan} style={{ marginBottom: 24 }}>
                                        <ProjectClientWidget
                                            loan={loan}
                                            dataProfile={dataProfile}
                                            bankIcon={bankIcon}
                                            entailmentsDefault={entailmentsDefault}
                                            BulpBlue={BulpBlue}
                                            addBindings={addBindings}
                                        />
                                    </Col>
                                    <Col {...colSpan} style={{ marginBottom: 24 }}>
                                        <CurrentConditionsWidget loan={loan} />
                                    </Col>
                                    {loan.loan.tips.length > 1 &&
                                        loan.loan.tips[0].category !== 'WORSE' &&
                                        loan.loan.tips[1].category !== 'WORSE' && (
                                            <Col
                                                xs={0}
                                                md={0}
                                                sm={0}
                                                lg={0}
                                                xl={12}
                                                xxl={12}
                                                style={{ marginBottom: 24 }}></Col>
                                        )}
                                    {loan &&
                                        (loan.loan.rateType === 'fixed' || loan.loan.rateType === 'variable') &&
                                        loan.loan.creationType === 'AUTOMATIC' &&
                                        loan.loan.tips.length > 0 &&
                                        (loan.loan.tips[0].category !== 'WORSE' ||
                                            (loan.loan.tips[1] !== undefined
                                                ? loan.loan.tips[1].category !== 'WORSE'
                                                : undefined)) && <TipsAutomatic loan={loan.loan}></TipsAutomatic>}

                                    {loan &&
                                        (loan.loan.rateType === 'fixed' || loan.loan.rateType === 'variable') &&
                                        loan.loan.creationType === 'MANUAL' &&
                                        loan.loan.tips.length > 0 &&
                                        (loan.loan.tips[0].category !== 'WORSE' ||
                                            (loan.loan.tips[1] !== undefined
                                                ? loan.loan.tips[1].category !== 'WORSE'
                                                : undefined)) && <TipsManual loan={loan.loan}></TipsManual>}

                                    {loan &&
                                        (loan.loan.tips.length === 0 || loan.loan.tips[0].category === 'WORSE') && (
                                            //  ||
                                            // (loan.loan.tips[1] !== undefined
                                            //   ? loan.loan.tips[1].category === "WORSE"
                                            //   : undefined)) && (
                                            (<Col {...colSpanNotification} style={{ marginBottom: 24 }}>
                                                <Card
                                                    className="gcardMovile"
                                                    style={{
                                                        border: '1px solid #C0DAEE',
                                                        background: '#F1F7F8',
                                                        borderRadius: 4,
                                                        height: '100%'
                                                    }}>
                                                    <Row gutter={24}>
                                                        <Col xs={2} md={2} sm={2} lg={2} xl={2} xxl={2}>
                                                            <img src={checkBlack} style={{ height: 24, width: 24 }}></img>
                                                        </Col>
                                                        <Col xs={22} md={22} sm={22} lg={22} xl={22} xxl={22}>
                                                            <div
                                                                style={{
                                                                    color: '#2F4858',
                                                                    fontSize: 14,
                                                                    fontWeight: 600
                                                                }}>
                                                                {diffe && diffe < 365
                                                                    ? t('loan.detailsLoanOwnersTitleAdvi')
                                                                    : dataProfile &&
                                                                      dataProfile.name + ', ' + t('loan.detailsLoanOwnersAdvi')}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: '#2F4858',
                                                                    fontSize: 14,
                                                                    fontWeight: 400,
                                                                    marginTop: 10
                                                                }}>
                                                                {diffe && diffe < 365
                                                                    ? t('loan.detailsLoanOwners2Advi')
                                                                    : t('loan.detailsLoanOwnersAdvi1')}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>)
                                        )}

                                    <Col {...colSpan} style={{ marginBottom: 24 }}>
                                        <div>
                                            <Text
                                                style={{
                                                    color: '#2F4858',
                                                    fontSize: 12,
                                                    fontWeight: 600
                                                }}>
                                                {t('loan.capital').toUpperCase()}
                                            </Text>
                                        </div>
                                        <CapitalWidget loan={loan} />
                                    </Col>

                                    <Col {...colSpan} style={{ marginBottom: 24 }}>
                                        <div>
                                            <Text
                                                style={{
                                                    color: '#2F4858',
                                                    fontSize: 12,
                                                    fontWeight: 600
                                                }}>
                                                {t('loan.quoteMothMobile').toUpperCase()}
                                            </Text>
                                        </div>
                                        <MonthlyFeeWidget loan={loan} />
                                    </Col>

                                    <Col {...colSpan} style={{ marginBottom: 24 }}>
                                        <div>
                                            <Text
                                                style={{
                                                    color: '#2F4858',
                                                    fontSize: 12,
                                                    fontWeight: 600
                                                }}>
                                                {t('loan.term').toUpperCase()}
                                            </Text>
                                        </div>
                                        <TermWidget
                                            pendingTerm={loan && loan.loan && loan.loan.term}
                                            endDate={loan && loan.loan && loan.loan.finish}
                                            dateSigned={loan && loan.loan && loan.loan.signed}
                                        />
                                    </Col>
                                    <Col {...colSpan} style={{ marginBottom: 24 }}>
                                        <div>
                                            <Text
                                                style={{
                                                    color: '#2F4858',
                                                    fontSize: 12,
                                                    fontWeight: 600
                                                }}>
                                                {t('loan.property').toUpperCase()}
                                            </Text>
                                        </div>
                                        <PropertyWidget
                                            percent={percent}
                                            valueHomeModal={valueHomeModal}
                                            setValueHomeModal={(e) => setValueHomeModal(e)}
                                        />
                                    </Col>
                                    <Col {...colSpan} style={{ marginBottom: 24 }}>
                                        <div>
                                            <Text
                                                style={{
                                                    color: '#2F4858',
                                                    fontSize: 12,
                                                    fontWeight: 600
                                                }}>
                                                {t('loan.titleEuriborWidget').toUpperCase()}
                                            </Text>
                                        </div>
                                        <EuriborCalculatorWidget loan={loan} />
                                    </Col>
                                    <Col {...colSpan} style={{ marginBottom: 24 }}>
                                        <div style={{ display: 'flex' }}>
                                            <div
                                                style={{
                                                    color: '#2F4858',
                                                    fontSize: 12,
                                                    fontWeight: 600
                                                }}>
                                                {t('loan.variableCalculartor').toUpperCase()}
                                            </div>
                                            <img
                                                src={infoIcon}
                                                alt="infoIcon"
                                                height={14}
                                                style={{ marginLeft: 5, cursor: 'pointer' }}
                                                onClick={() => setModalAmortizationCalculator(true)}
                                            />
                                        </div>
                                        <AmortizationCalculatorWidget loan={loan}></AmortizationCalculatorWidget>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </>
                )}

                <Modal
                    className="ModalDesktopFooterColum"
                    okButtonProps={{
                        className: 'button-primari-gibobs',
                        style: { width: 264, alignSelf: 'center', height: 40 }
                    }}
                    okText={
                        <Text style={{ fontSize: 14, fontWeight: 500, color: 'white' }}>
                            {t('loan.modalAutomaticButtonSecond')}
                        </Text>
                    }
                    cancelText={
                        <Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 500 }}>
                            {t('loan.modalManualButtonSecond')}
                        </Text>
                    }
                    open={modalAutomatic}
                    title={<Text>{t('loan.modalAutomaticTitle')}</Text>}
                    width={432}
                    cancelButtonProps={{ style: { border: 'none' } }}
                    closable={true}
                    onOk={() => handleCreateAutomatic()}
                    onCancel={() => navigate(`/my-financial-profile/credits/edit/form/${loanId}`)}>
                    <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                        {t('loan.modalAutomaticDec')}
                    </Text>
                </Modal>

                <Modal
                    className="modalMobileAfterBanks"
                    open={afterbanks}
                    onCancel={() => handleafterBanksCancel(false)}
                    onOk={() => handleafterBanks()}
                    //okButtonProps={{ disabled: fileAfterbanks ? false : true }}
                    cancelButtonProps={{
                        style: { border: 'none', contentVisibility: 'hidden' }
                    }}
                    destroyOnClose={true}
                    closable={true}>
                    <AfterBanksComponent
                        type="loan"
                        subType="change"
                        loanId={loanId}
                        bank={loan && loan.loan.bank}
                        successRedirect={() => handleafterBanks()}></AfterBanksComponent>
                </Modal>
                {/* <ModalButtonsCommon
        key={"errorDataModal"}
        visible={errorDataModal}
        onCreate={() => setErrorDataModal(false)}
        onCancel={() => setErrorDataModal(false)}
        okText={"offers.cancel"}
        cancelText={"offers.accept"}
        title={"loan.tinTitle"}
        text={t("loan.IncompleteDataModalDescription")}
      /> */}
                <Modal
                    className="ModalCommon"
                    width={480}
                    open={revisionDate}
                    onCancel={() => {
                        setRevisionDate(!revisionDate);
                    }}
                    onOk={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                handleChangeRevisionDate(values);
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            });
                    }}
                    okText={
                        <Text style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 14 }}>
                            {t('viability.confirmButton')}
                        </Text>
                    }
                    cancelText={
                        <Text style={{ color: '#02C3CD', fontWeight: 600, fontSize: 14 }}>
                            {t('offers.cancel')}
                        </Text>
                    }
                    cancelButtonProps={{ style: { width: '46%', height: 40 } }}
                    okButtonProps={{ style: { width: '46%', height: 40 } }}
                    title={
                        <Text
                            style={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: '#2F4858'
                            }}>
                            {t('loan.dateRevisionDesktopEnterBack')}
                        </Text>
                    }>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: -30,
                            marginBottom: -24
                        }}>
                        <Text
                            style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: '#748EA0'
                            }}>
                            {t('loan.dateRevisionDesktopEnterBackDesc')}
                        </Text>

                        <Form
                            form={form}
                            layout="vertical"
                            name="dateRevison"
                            style={{ marginTop: 16 }}
                            className="gb-form-common-new-style">
                            <Form.Item
                                name="lastRevison"
                                label={t('loan.ultimateDateRevision')}
                                style={{
                                    width: '100%'
                                }}
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}>
                                <InputDate
                                    type="date"
                                    style={{
                                        width: '100%',
                                        height: '40px',
                                        borderRadius: '4px',
                                        borderColor: '#C0DAEE'
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="reviewPeriod"
                                label={t('loan.dateRevision')}
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}
                                style={{
                                    width: '100%',
                                    marginTop: -20
                                }}>
                                <Select placeholder={t('form.selected')} style={{ width: '100%' }}>
                                    <Option value="biannual">{t('loan.biannual')}</Option>
                                    <Option value="annual">{t('loan.annual')}</Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>

                <Modal
                    className="ModalCommon"
                    width={480}
                    open={amortizeVisible}
                    onCancel={() => {
                        setAmortizeVisible(!amortizeVisible);
                    }}
                    onOk={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                handleChangeAmortize(values);
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            });
                    }}
                    okText={
                        <Text style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 14 }}>
                            {t('viability.confirmButton')}
                        </Text>
                    }
                    cancelText={
                        <Text style={{ color: '#02C3CD', fontWeight: 600, fontSize: 14 }}>
                            {t('offers.cancel')}
                        </Text>
                    }
                    cancelButtonProps={{ style: { width: '46%', height: 40 } }}
                    okButtonProps={{ style: { width: '46%', height: 40 } }}
                    title={
                        <Text
                            style={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: '#2F4858'
                            }}>
                            {t('loan.enterDataDetp')}
                        </Text>
                    }>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: -30,
                            marginBottom: -24
                        }}>
                        <Text
                            style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: '#748EA0'
                            }}>
                            {t('loan.enterDataDetpDesc')}
                        </Text>

                        <Form
                            form={form}
                            layout="vertical"
                            name="amortizeVisible"
                            style={{ marginTop: 16 }}
                            className="gb-form-common-new-style">
                            <Form.Item
                                name="debt"
                                label={t('loan.debt')}
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}
                                style={{
                                    width: '100%'
                                }}>
                                <InputNumber
                                    style={{
                                        width: '100%'
                                    }}
                                    placeholder={'0 €'}
                                />
                            </Form.Item>

                            <Form.Item
                                name="finish"
                                label={t('loan.dateFinish')}
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}
                                style={{
                                    width: '100%',
                                    marginTop: -20
                                }}>
                                <InputDate
                                    type="date"
                                    style={{
                                        width: '100%',
                                        borderRadius: '4px',
                                        borderColor: '#C0DAEE'
                                    }}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>

                <Modal
                    className="ModalCommon"
                    width={480}
                    open={amountInitial}
                    onCancel={() => {
                        setAmountInitial(!amountInitial);
                    }}
                    onOk={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                handleChangeInitialAmount(values);
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            });
                    }}
                    okText={
                        <Text style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 14 }}>
                            {t('viability.confirmButton')}
                        </Text>
                    }
                    cancelText={
                        <Text style={{ color: '#02C3CD', fontWeight: 600, fontSize: 14 }}>
                            {t('offers.cancel')}
                        </Text>
                    }
                    cancelButtonProps={{ style: { width: '46%', height: 40 } }}
                    okButtonProps={{ style: { width: '46%', height: 40 } }}
                    title={
                        <Text
                            style={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: '#2F4858'
                            }}>
                            {t('loan.enterDataDetp')}
                        </Text>
                    }>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: -30,
                            marginBottom: -24
                        }}>
                        <Text
                            style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: '#748EA0'
                            }}>
                            {t('loan.enterDataDetpDesc')}
                        </Text>

                        <Form
                            form={form}
                            layout="vertical"
                            name="amortizeVisible"
                            style={{ marginTop: 16 }}
                            className="gb-form-common-new-style">
                            <Form.Item
                                name="debt"
                                label={t('loan.debt')}
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}
                                style={{
                                    width: '100%'
                                }}>
                                <InputNumber
                                    style={{
                                        width: '100%'
                                    }}
                                    placeholder={'0 €'}
                                />
                            </Form.Item>

                            <Form.Item
                                name="initialHousePrice"
                                label={t('loan.originalAmountHomeMobile')}
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}
                                style={{
                                    width: '100%',
                                    marginTop: -20
                                }}>
                                <InputNumber
                                    style={{ height: 40, width: '97%' }}
                                    placeholder={'00000 €'}></InputNumber>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>

                <Modal
                    className="ModalCommon"
                    width={480}
                    open={valueHomeModal}
                    onCancel={() => {
                        setValueHomeModal(!valueHomeModal);
                    }}
                    onOk={() => setValueHomeModal(!valueHomeModal)}
                    okText={
                        <Text style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 14 }}>
                            {t('offers.accept')}
                        </Text>
                    }
                    cancelText={
                        <Text style={{ color: '#02C3CD', fontWeight: 600, fontSize: 14 }}>
                            {t('offers.cancel')}
                        </Text>
                    }
                    cancelButtonProps={{ style: { width: '46%', height: 40 } }}
                    okButtonProps={{ style: { width: '46%', height: 40 } }}
                    title={
                        <Text
                            style={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: '#2F4858',
                                marginTop: 24,
                                marginBottom: 24
                            }}>
                            {t('loan.valueHomeTitle')}
                        </Text>
                    }>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: -30
                        }}>
                        <Text
                            style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: '#748EA0'
                            }}>
                            {t('loan.valueHomeDesc')}
                        </Text>
                    </div>
                </Modal>
                <ModalAmortizationExplination
                    key={'modalAmortizationCalculator'}
                    visible={modalAmortizationCalculator}
                    onCreate={() => setModalAmortizationCalculator(false)}
                    onCancel={() => setModalAmortizationCalculator(false)}
                    title={'loan.amortizationTitleModal'}
                    text={t('loan.amortizationDescriptionModal')}
                />
                <ModalButtonsCommon
                    key={'errorDataModal'}
                    visible={errorDataModal}
                    onCreate={() => setErrorDataModal(bankId)}
                    onCancel={() => setErrorDataModal(false)}
                    okText={'offers.accept'}
                    cancelText={'offers.cancel'}
                    title={'loan.IncompleteDataModalTitle'}
                    text={t('loan.IncompleteDataModalDescription')}
                />
            </Layout>
        </>);
	}
};

export default LoanPageDetailsNew;
