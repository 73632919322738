import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

const Home = () => {
	const navigate = useNavigate();
	const roles = useSelector((state) => state.auth.roles);
	const dataSummary = useSelector((state) => state.data.dataSummary);
	const allRoles = useSelector((state) => state.auth.allRoles);
	const operation = useSelector((state) => state.data.dataSummary[0]);
	const location = useLocation();

	useEffect(() => {
		if (dataSummary.length > 0) {
			if (operation.type === 'certificate') {
				navigate(`/operations/certificate/${operation.operationId}/${operation.stage}`);
			} else {
				navigate(`/distributor`);
			}
		} else {
			if (allRoles.includes('php')) {
				navigate('/promoter-loan/products-under-contract');
			}
			if (roles === 'realestate' && dataSummary.length === 0 && !allRoles.includes('php')) {
				navigate('/dashboard-business');
			}
			if (roles !== 'realestate' && dataSummary.length === 0) {
				navigate('/dashboard-client');
			}
		}
	}, [dataSummary]);

	useEffect(() => {
		if (roles !== 'realestate' && dataSummary.length > 0) {
			if (operation.type === 'certificate') {
				navigate(`/operations/certificate/${operation.operationId}/${operation.stage}`);
			} else {
				if (roles !== 'realestate' && dataSummary.length >= 2 && location.state === undefined) {
					navigate(`/products-under-contract`);
				} else {
					navigate(`/distributor`);
				}
			}
		} else {
			if (allRoles.includes('php')) {
				navigate('/promoter-loan/products-under-contract');
			}
			if (roles === 'realestate' && dataSummary.length === 0 && !allRoles.includes('php')) {
				navigate('/dashboard-business');
			}
			if (roles !== 'realestate' && dataSummary.length === 0) {
				navigate('/distributor');
			}
		}
	}, []);

	return <></>;
};

export default Home;
