import React from "react";
import { Pie } from "ant-design-pro/lib/Charts";
import "ant-design-pro/dist/ant-design-pro.css";
import { useTranslation } from "react-i18next";

function PieClosedSavings({ freeIncome }) {
  const { t } = useTranslation();
  const salesPieData = [
    {
      x: t("balance.incomeReleased"),
      y: freeIncome * 100,
    },
    {
      x: t("balance.dedicatedToExpenses"),
      y: 100 - freeIncome * 100,
    },
  ];
  return (
    <Pie
      className="PieSaving"
      title="Activos"
      colors={["#41D2DA", "#748EA0"]}
      hasLegend="true"
      data={salesPieData}
      valueFormat={0}
      height={140}
      style={{
        display: "grid",
        gridTemplateColumns: "0.5fr 5fr",
        width: "350px",
        marginRight: "40px",
      }}
    />
  );
}

export default PieClosedSavings;
