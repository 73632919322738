import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Layout, Row, Card, Form, Input, Button, Typography, Checkbox, Alert, Spin } from "antd";
import gibobsLogo from "../../assets/icons/Gibobs.svg";
import axios from "axios";
import env from "../../environment";

const { Content } = Layout;
const { Text } = Typography;

export default () => {
    const params = useParams()
    const [pagePin, setPagePin] = useState(undefined)
    const [pinValidated, setPinValidated] = useState(undefined)
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState("");
    const [errorPin, setErrorPin] = useState(false);
    const [errorDni, setErrorDni] = useState(false);
    const [terms, setTemrs] = useState(false);
    const [docs, setDocs] = useState(false);
    const [form] = Form.useForm();
    const [errorChecks, setErrorChecks] = useState(false);
    const parseJson = Buffer.from(params.base64Id, "base64").toString()
    const ownerData = JSON.parse(parseJson)



    const onFinish = async (values) => {
        if (terms === false || docs === false) {

            setErrorChecks(true)

        } else {

            form
                .validateFields()
                .then(() => {

                    axios
                        .post(
                            `${env.api.url}/v1/operation/verify/owner`,
                            {
                                operationId: ownerData.operationId,
                                owner: ownerData.owner,
                                email: ownerData.email,
                                nif: values.dni,
                                checkService: terms,
                                checkCertificate: docs

                            },

                        )
                        .then((response) => {
                            if (response.data.data.verified) {
                                setToken(response.data.data.token)
                                setLoading(true)
                                setPagePin(true)
                                setErrorChecks(false)
                                setErrorDni(false)


                                getPinOTP(response.data.data.token)

                            } else {
                                setErrorDni(true)
                            }


                        })
                        .catch(() => {
                            setErrorDni(true)

                        });
                })
                .catch((info) => {
                    console.log("Validate Failed:", info);
                });
        }
    };

    const getPinOTP = (token) => {

        axios
            .post(
                `${env.api.url}/v1/documents/automatic/request-owner`, {},
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then(() => {
                setLoading(false)
            })


    }

    const onChangeTermin = (e) => {
        setTemrs(e.target.checked)

    }
    const onChangeDocs = (e) => {
        setDocs(e.target.checked)
    }







    const pinRequests = (values) => {
        setLoading(true)

        axios
            .post(
                `${env.api.url}/v1/documents/nbric/confirm/otp-owner`,
                {
                    otp: values.pin
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then((response) => {
                if (response.data.success) {
                    setLoading(false)
                    setPinValidated(true)
                    setPagePin(false)
                    setErrorChecks(false)
                    setErrorDni(false)


                } else {
                    setErrorPin(true)
                    setLoading(false)

                }


            })
            .catch(() => {
                setErrorPin(true)
                setLoading(false)


            });

    }

    return (
        <>

            {loading ?
                <div
                    style={{
                        height: "100vh",
                        backgroundColor: "white",
                        width: "100%"
                    }}
                >
                    <div style={{ display: "flex", flexDirection: "column", position: "relative", top: "50%" }}>
                        <Spin size="large" />
                        <Text style={{ fontSize: 14, fontWeight: 400, color: "#4D4D4D", marginTop: 16, textAlign: "center" }}> Procesando tu solicitud...</Text>

                    </div>
                </div>

                :

                <Layout style={{ padding: 24 }}>
                    <Card className="movilCardHeaderImg"
                        style={{ height: "92px", width: "110%", marginTop: -26, marginLeft: -26, textAlign: "center" }}>
                        <img
                            src={gibobsLogo}
                            style={{ height: "60px", marginTop: -10 }}
                            alt="Gibobs all banks"
                        ></img>
                    </Card>
                    <Card className="gcard" style={{ marginTop: 40 }}>
                        {errorDni &&

                            <div style={{ width: "100%", textAlign: "-webkit-center", marginTop: -60 }}>

                                <Alert
                                    message={
                                        <div style={{ marginLeft: 8, textAlign: "left" }}><Text style={{ color: "#2F4858", fontSize: 14, fontWeight: 400 }}>El DNI introducido no se corresponde con ninguna operación</Text></div>}
                                    type="error"
                                    showIcon
                                    closable
                                    style={{ height: 56, width: 500, zIndex: 1000, background: "#FFDEDE", border: "1px solid #FF7A7B", borderRadius: 4, marginBottom: -30 }}


                                />
                            </div>

                        }

                        {errorChecks &&

                            <div style={{ width: "100%", textAlign: "-webkit-center", marginTop: -60 }}>

                                <Alert
                                    message={
                                        <div style={{ marginLeft: 8, textAlign: "left" }}><Text style={{ color: "#2F4858", fontSize: 14, fontWeight: 400 }}>Debes aceptar los terminos y condiciones</Text></div>}
                                    type="error"
                                    showIcon
                                    closable
                                    style={{ height: 56, width: 400, zIndex: 1000, background: "#FFDEDE", border: "1px solid #FF7A7B", borderRadius: 4, marginBottom: -30 }}


                                />
                            </div>

                        }


                        {errorPin &&

                            <div style={{ width: "100%", textAlign: "-webkit-center", marginTop: -60 }}>

                                <Alert
                                    message={
                                        <div style={{ marginLeft: 8, textAlign: "left" }}><Text style={{ color: "#2F4858", fontSize: 14, fontWeight: 400 }}>El PIN introducido es erróneo</Text></div>}
                                    type="error"
                                    showIcon
                                    closable
                                    style={{ height: 56, width: 300, zIndex: 1000, background: "#FFDEDE", border: "1px solid #FF7A7B", borderRadius: 4, marginBottom: -30 }}


                                />
                            </div>
                        }

                        <Content style={{ marginTop: "10%" }}>


                            <Row style={{ height: "100%" }}>

                                <div style={{ display: "flex", flexDirection: "column" }}>



                                    {!pagePin && !pinValidated &&
                                        <div style={{ marginTop: -160, display: "flex", flexDirection: "column" }}>
                                            <Text style={{ color: "#2F4858", fontSize: 16, fontWeight: 600 }}>Verifica tu identidad</Text>
                                            <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 400, marginTop: 8, width: "100%" }}>Por favor, introduce tu DNI para que podamos verificar tu identidad y comenzar con el proceso de descarga automática de documentos.</Text>

                                            <Form
                                                name="login"
                                                onFinish={onFinish}
                                            >

                                                <Form.Item
                                                    label={"DNI"}
                                                    name="dni"
                                                    style={{ marginTop: 24 }}
                                                    rules={[{ required: true }]}

                                                >
                                                    <Input
                                                        placeholder="1234567X"
                                                    />
                                                </Form.Item>

                                                <Form.Item

                                                    name="termins"
                                                    style={{ marginTop: 16 }}
                                                >
                                                    <Checkbox onChange={onChangeTermin} value="true"><Text style={{ fontSize: 12, fontWeight: 400, color: "#748EA0" }}>Acepto los términos y condiciones del servicio</Text></Checkbox>
                                                </Form.Item>

                                                <Form.Item
                                                    name="documents"
                                                    style={{ marginTop: -8 }}
                                                >
                                                    <Checkbox onChange={onChangeDocs} value="true"><Text style={{ fontSize: 12, fontWeight: 400, color: "#748EA0" }}>Acepto la consulta de mis documentos oficiales en sedes electrónicas</Text></Checkbox>
                                                </Form.Item>


                                                <Form.Item>
                                                    <Button
                                                        htmlType="submit"
                                                        id="gb-mortgage-modality-standard"
                                                        className="button-primari-gibobs"
                                                        type="primary"
                                                        style={{ fontSize: "14px", width: 100, height: 40, fontWeight: 600 }}
                                                    // onClick={() => authorize()}
                                                    >
                                                        Continuar
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        </div>
                                    }



                                    {/*Esta pantalla del pin debe ser la sigiente al dni*/}

                                    {pagePin &&
                                        <div style={{ marginTop: -160, display: "flex", flexDirection: "column" }}>
                                            <Text style={{ color: "#2F4858", fontSize: 16, fontWeight: 600, width: "80%" }}>Descarga automática de documentación oficial</Text>
                                            <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 400, marginTop: 8, width: "100%" }}>Por favor, introduce el PIN que has recibido en tu móvil para que podamos descargar tus documentos sobre IRPF e Informe de Vida Laboral.</Text>

                                            <Form
                                                name="login"
                                                onFinish={pinRequests}
                                            >

                                                <Form.Item
                                                    label={"PIN recibido"}
                                                    name="pin"
                                                    style={{ marginTop: 24 }}
                                                    rules={[{ required: true }]}

                                                >
                                                    <Input
                                                        placeholder="1234"
                                                    />
                                                </Form.Item>




                                                <Form.Item>
                                                    <Button
                                                        htmlType="submit"
                                                        id="gb-mortgage-modality-standard"
                                                        className="button-primari-gibobs"
                                                        type="primary"
                                                        style={{ fontSize: "14px", width: 100, height: 40, fontWeight: 600 }}
                                                    // onClick={() => authorize()}
                                                    >
                                                        Continuar
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        </div>
                                    }
                                    {pinValidated &&
                                        <div style={{ marginTop: -160, display: "flex", flexDirection: "column" }}>



                                            <Text style={{ color: "#2F4858", fontSize: 16, fontWeight: 600, width: "80%" }}>¡Muchas gracias por confiar en Gibobs allbanks!</Text>
                                            <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 400, marginTop: 8, width: "100%" }}>Tus documentos se están descargando correctamente y el primer participante podrá visualizarlos dentro de poco.</Text>

                                            {/*<Card className="cardButtonStyle" style={{ left: 0, marginBottom: - 2 }}>
                                    <Button
                                        htmlType="submit"
                                        id="gb-mortgage-modality-standard"
                                        className="button-primari-gibobs"
                                        type="primary"
                                        style={{ fontSize: "14px", width: "100%", height: 40, fontWeight: 600 }}
                                    // onClick={() => authorize()}
                                    >
                                        Continuar
                                    </Button>
                                </Card>*/}
                                        </div>
                                    }

                                </div>
                            </Row>

                        </Content>
                    </Card>



                </Layout>
            }
        </>
    );
};
