import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Col,
  Row,
  Avatar,
  Upload,
  Image,
  Spin,
  Typography,
  Modal,
  Card,
  message,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import env from "../environment";
import ChangePassword from "./Operaciones/changePassword";
import AvatarGenerico from "../assets/AvatarGenerico.jpg";
import { callAct } from "../store/auth/actions";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { dataProfile } from "../store/data/actions";
import employmentSituation from "../assets/icons/employmentSituation.svg";
import rightArrowIcon from "../assets/icons/rightArrowIcon.svg";
import lockBlueIcon from "../assets/icons/lockBlueIcon.svg";
import userIconInmo from "../assets/icons/userIconInmo.svg";
import { LoadingOutlined } from "@ant-design/icons";
import {
  notificationGibobs,
  titlePageMobile,
} from "./HerramientasVenta/reusableItems";
import { ProvinceSelector } from "afrodita";

const { Text } = Typography;

const CompanySpace = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();;

  const changeCallAct = useSelector((state) => state.auth.callAct);
  const userToken = useSelector((state) => state.auth.token);
  const dataProfileDetails = useSelector((state) => state.data.dataProfile);
  const [saveFileDataField, setSaveFileDataField] = useState(
    dataProfileDetails && dataProfileDetails.avatar
      ? dataProfileDetails.avatar.data
      : null
  );
  const [taskToEdit, setTaskToEdit] = useState(null);
  const [pages, setPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
  const [qr, setQR] = useState(undefined);
  const [visibleQR, setVisibleQR] = useState(false);
  const [visibleCode, setVisibleCode] = useState(false);

  const hideAddTaskModal = () => {
    setShowModal(false);
    setTaskToEdit(null);
  };

  useEffect(() => {
    dispatch(callAct(true));
    createQR();
  }, []);

  const changePassword = (values) => {
    axios
      .post(`${env.api.url}/v1/auth/change-password`, values, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        if (response.data.errorCode === "INVALID_PASSWORD_FORMAT") {
          message.error(t("paswd.format"));
        }else if (response.data.errorCode ===  "SESSION_TOKEN_EXPIRED") {
          message.error("paswd.session");
        }else {
          notificationGibobs("success", t("m_updatePassword.successMessage"));

        }
      
      });

    if (!isMobile) {
      setShowModal(false);
    }
    if (isMobile) {
      setPages(1);
    }
  };

  const eliminateFieldInvalidate = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === "") {
        return (obj[propName] = null);
      } else if (
        dataProfileDetails.birthdate !== obj.birthdate &&
        obj[propName] === "Invalid date"
      ) {
        return (obj[propName] = null);
      } else if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === "Invalid date"
      ) {
        delete obj[propName];
      }
    }
  };

  const onFinish = (values) => {
    eliminateFieldInvalidate(values);
    axios
      .post(`${env.api.url}/v1/user/update-profile`, values, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        if (response.data.success) {
          notificationGibobs("success", t("m_profile.accountSuccessMessage"));
          dispatch(dataProfile(userToken));
          navigate("/dashboard-business");
        } else {
          notificationGibobs("error", t("m_profile.accountErrorMessage"));
        }
      });
  };

  const uploadProps = {
    beforeUpload: (file) => {
      return new Promise(() => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64File = reader.result.split(",")[1];
          axios
            .post(
              `${env.api.url}/v1/user/change-avatar`,
              { avatar: base64File },
              {
                headers: {
                  Authorization: `Bearer ${userToken}`,
                },
              }
            )
            .then((response) => {
              if (response.data.success) {
                notificationGibobs("success", t("myAccount.updatedImage"));
                dispatch(callAct(!changeCallAct));
                dispatch(dataProfile(userToken));
                setLoading(false);
              } else {
                notificationGibobs("error", t("myAccount.notUpdatedImage"));
                dispatch(callAct(!changeCallAct));
                setLoading(false);
              }
            });
          setSaveFileDataField(base64File);
        };
      });
    },
  };
  const showSendMessagesModal = () => {
    setShowModal(true);
  };

  const createQR = () => {
    axios
      .get(`${env.api.url}/v1/user/generate/invite-qr`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setQR(response.data.data);
      });
  };

  const downloadQR = () => {
    let source = qr.qrcode;

    var a = document.createElement("a");

    a.download = "QR-Register";
    a.target = "_blank";
    a.href = source;
    a.click();

    if (isMobile) {
      setPages(1);
    }
    if (!isMobile) {
      setVisibleQR(!visibleQR);
    }
  };

  const personalInformation = (
    <Row gutter={24}>
      <Col xs={24} lg={8}>
        <Form.Item
          name="name"
          label={t("form.name")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder={t("form.name")}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      </Col>
      <Col xs={24} lg={8}>
        <Form.Item
          name="surname"
          label={t("form.surname")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder={t("form.surname")}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      </Col>
      <Col xs={24} lg={8}>
        <Form.Item
          name="phone"
          label={t("form.phone")}
          rules={[
            {
              required: true,
              message: "Please input your phone number!",
            },
          ]}
        >
          <Input
            placeholder={t("form.phone")}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      </Col>
      <Col xs={24} lg={8}>
        <Form.Item
          name="email"
          label={t("form.email")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            disabled={true}
            placeholder={t("form.email")}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );

  const companyInformation = (
    <Row gutter={24}>
      <Col xs={24} lg={8}>
        <Form.Item
          name="companyName"
          label={t("form.companyName")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder={t("form.companyName")}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      </Col>
      <Col xs={24} lg={8}>
        <Form.Item
          name="companyCif"
          label={t("form.companyCif")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder={t("form.companyCif")}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      </Col>
      <Col xs={24} lg={8}>
        <Form.Item
          name="street"
          label={t("form.street")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder={t("form.street")}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      </Col>
      <Col xs={24} lg={8}>
        <Form.Item
          name="zip"
          label={t("m_register.postalCode")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder={t("m_register.postalCode")}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      </Col>
      <Col xs={24} lg={8}>
        <Form.Item label={t("form.population")} name="population">
          <Input
            showSearch
            placeholder={t("form.population")}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Col>
      <Col xs={24} lg={8}>
        <Form.Item label={t("form.provinces")} name="province">
          <ProvinceSelector 
             placeholder={t("form.provinces")}
             style={{ width: "100%" }} />
        </Form.Item>
      </Col>
    </Row>
  );

  const labelTitle = (title, icon1, icon2, function1) => {
    return (
      <div
        style={{
          backgroundColor: "#FFFFFF",
          padding: "12px 16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #F1F7F8",
        }}
        onClick={function1}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: 16, color: "#02C3CD", fontSize: 20 }}>
            {icon1}
          </div>
          <div style={{ fontSize: 16, fontWeight: 400 }}>{t(title)}</div>
        </div>
        <div style={{ color: "#748EA0", fontSize: 16 }}>{icon2}</div>
      </div>
    );
  };
  const copyToClipBoard = () => {
    var range = document.createRange();
    range.selectNode(document.getElementById("inmo-code"));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    notificationGibobs("success", t("form.codeCopiedSuccessfully"));
    if (!isMobile) {
      setVisibleCode(!visibleCode);
    }
    if (isMobile) {
      setPages(1);
    }
  };

  const openNotificationWithIcon = () => {
    notificationGibobs(
      "error",
      t("m_full.errorTitle"),
      t("form.incompleteInformation")
    );
  };

  const validateMessages = {
    required: "${label}" + " " + t("form.formRequired"),
    types: {
      email: t("form.emailValidated"),
      number: "${label} is not a valid number!",
    },
    number: {
      range: t("form.maxBudgetNewRequestValidated") + "${min}",
    },
  };

  return (<>
    {isMobile && (
      <div>
        {pages === 1 && (
          <>
            <div style={{ margin: 16 }}>
              {titlePageMobile(
                navigate,
                "/dashboard-client",
                t("m_profile.account")
              )}
              <div
                style={{
                  display: "flex",
                  flexFlow: "column",
                  alignItems: "center",
                }}
              >
                <Upload {...uploadProps}>
                  <Avatar
                    size={{
                      xs: 120,
                      lg: 120,
                      xl: 114,
                      xxl: 130,
                    }}
                    icon={<UserOutlined />}
                    src={
                      loading ? (
                        <Spin indicator={antIcon} className="spin" />
                      ) : saveFileDataField !== null ? (
                        <Image
                          preview={false}
                          style={{ cursor: "pointer" }}
                          src={`data:image/png;base64,${saveFileDataField}`}
                          height={"100%"}
                          width={"100%"}
                        />
                      ) : (
                        <Image
                          src={AvatarGenerico}
                          preview={false}
                          style={{ cursor: "pointer" }}
                        />
                      )
                    }
                  />
                </Upload>
              </div>
              {/* <Form
                className={"gb-form-profile-headlines-mobile"}
                onFinishFailed={openNotificationWithIcon}
                scrollToFirstError={true}
                layout="vertical"
                style={{ marginTop: 32 }}
              >
                <Form.Item
                  name="priorityProfile"
                  label={t("form.priorityProfile")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    suffixIcon={
                      <img
                        src={DownOutlinedMobile}
                        alt="DownOutlinedMobile"
                      />
                    }
                    disabled
                    showSearch
                    placeholder={t("form.priorityProfile")}
                    style={{ width: "100%" }}
                  >
                    {renderComponent("province").options.map((province) => {
                      return (
                        <Option key={province.value} value={province.value}>
                          {province.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Form> */}
            </div>
            <div style={{ marginTop: 24 }}>
              {labelTitle(
                "form.changePassword",
                <div
                  style={{
                    height: 24,
                    width: 24,
                    textAlign: "center",
                  }}
                >
                  <img
                    src={lockBlueIcon}
                    alt="infoBlueIcon"
                    height={20}
                    style={{ marginBottom: 10 }}
                  />
                </div>,
                <img src={rightArrowIcon} alt="rightArrowIcon" height={12} />,
                () => setPages(2)
              )}
              {labelTitle(
                "m_products.basicPersonalDataTitle",
                <div
                  style={{
                    height: 24,
                    width: 24,
                    textAlign: "center",
                  }}
                >
                  <img
                    src={userIconInmo}
                    alt="infoBlueIcon"
                    height={19}
                    style={{ marginBottom: 10 }}
                  />
                </div>,
                <img src={rightArrowIcon} alt="rightArrowIcon" height={12} />,
                () => setPages(3)
              )}
              {labelTitle(
                "m_register.companyInfo",
                <div
                  style={{
                    height: 24,
                    width: 24,
                    textAlign: "center",
                  }}
                >
                  <img
                    src={employmentSituation}
                    alt="employment"
                    height={18}
                    style={{ marginBottom: 9 }}
                  />
                </div>,
                <img src={rightArrowIcon} alt="rightArrowIcon" height={12} />,
                () => setPages(4)
              )}
            </div>

            <Card
              className="cardButtonStyle"
              style={{
                left: 0,
                marginBottom: -2,
                backgroundColor: "#FAFEFE",
              }}
            >
              <Button
                id="gb-mortgage-buy-situation-mobile"
                className="button-secundary-gibobs"
                type="dashed"
                style={{
                  fontSize: 16,
                  width: "48%",
                  height: 48,
                  fontWeight: 600,
                  marginRight: "10px",
                }}
                onClick={() => setPages(5)}
              >
                {t("form.QRClient")}
              </Button>
              <Button
                id="gb-mortgage-buy-situation-mobile"
                className="button-secundary-gibobs"
                type="dashed"
                style={{
                  fontSize: 16,
                  width: "48%",
                  height: 48,
                  fontWeight: 600,
                }}
                onClick={() => setPages(6)}
                loading={loading}
              >
                {t("form.agentCode")}
              </Button>
            </Card>
          </>
        )}

        {pages === 2 && (
          <div style={{ margin: 16 }}>
            {titlePageMobile(setPages, 1, t("form.changePassword"))}
            <Form
              className={"gb-form-profile-headlines-mobile"}
              onFinishFailed={openNotificationWithIcon}
              scrollToFirstError={true}
              onFinish={changePassword}
              layout="vertical"
              name="Password"
              initialValues={{
                confirmPassword: "",
                newPassword: "",
                confirmNewPassword: "",
              }}
            >
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name="confirmPassword"
                    label={t("m_profile.actualPassword")}
                  >
                    <Input.Password
                      className="InputPassWord"
                      type="password"
                      placeholder={t("m_profile.actualPassword")}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="newPassword"
                    label={t("m_profile.newPassword")}
                  >
                    <Input.Password
                      className="InputPassWord"
                      type="password"
                      placeholder={t("m_profile.newPassword")}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="confirmNewPassword"
                    label={t("m_profile.confirmNewPassword")}
                  >
                    <Input.Password
                      className="InputPassWord"
                      type="password"
                      placeholder={t("m_profile.confirmNewPassword")}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Card
                className="cardButtonStyle"
                style={{
                  left: 0,
                  marginBottom: -2,
                  backgroundColor: "#FAFEFE",
                }}
              >
                <Button
                  id="gb-mortgage-buy-situation-mobile"
                  className="button-primari-gibobs"
                  type="primary"
                  style={{
                    fontSize: 16,
                    width: "100%",
                    height: 48,
                    fontWeight: 600,
                  }}
                  htmlType="submit"
                >
                  {t("form.save")}
                </Button>
              </Card>
            </Form>
          </div>
        )}
        {pages === 3 && (
          <div style={{ margin: 16 }}>
            {titlePageMobile(
              setPages,
              1,
              t("m_products.basicPersonalDataTitle")
            )}
            <Form
              className={"gb-form-profile-headlines-mobile"}
              name="nest-messages"
              onFinish={onFinish}
              layout="vertical"
              initialValues={dataProfileDetails ? dataProfileDetails : {}}
              style={{
                marginTop: "24px",
              }}
            >
              {personalInformation}
              <Card
                className="cardButtonStyle"
                style={{
                  left: 0,
                  marginBottom: -2,
                  backgroundColor: "#FAFEFE",
                }}
              >
                <Button
                  id="gb-mortgage-buy-situation-mobile"
                  className="button-primari-gibobs"
                  type="primary"
                  style={{
                    fontSize: 16,
                    width: "100%",
                    height: 48,
                    fontWeight: 600,
                  }}
                  htmlType="submit"
                  loading={loading}
                >
                  {t("form.save")}
                </Button>
              </Card>
            </Form>
          </div>
        )}
        {pages === 4 && (
          <div style={{ margin: 16 }}>
            {titlePageMobile(setPages, 1, t("m_register.companyInfo"))}
            <Form
              className={"gb-form-profile-headlines-mobile"}
              onFinishFailed={openNotificationWithIcon}
              scrollToFirstError={true}
              name="nest-messages"
              onFinish={onFinish}
              layout="vertical"
              initialValues={dataProfileDetails ? dataProfileDetails : {}}
              style={{
                marginBottom: "150px",
                marginTop: "24px",
              }}
            >
              {companyInformation}
              <Card
                className="cardButtonStyle"
                style={{
                  left: 0,
                  marginBottom: -2,
                  backgroundColor: "#FAFEFE",
                }}
              >
                <Button
                  id="gb-mortgage-buy-situation-mobile"
                  className="button-primari-gibobs"
                  type="primary"
                  style={{
                    fontSize: 16,
                    width: "100%",
                    height: 48,
                    fontWeight: 600,
                  }}
                  loading={loading}
                  htmlType="submit"
                >
                  {t("form.save")}
                </Button>
              </Card>
            </Form>
          </div>
        )}
        {pages === 5 && (
          <>
            <div style={{ margin: "16px 16px 170px" }}>
              {/* {titlePageMobile(setPages, 1, t("m_register.companyInfo"))} */}
              <Card className="card-inmo-account">
                <div className="title-cards">
                  {t("form.QRButtonSecundary")}
                </div>
                <div className="description-cards">
                  {t("form.QRDescription")}
                </div>
              </Card>
              <div
                style={{
                  fontWeight: 400,
                  marginTop: 32,
                  textAlign: "center",
                }}
              >
                {qr && (
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      marginTop: 40,
                      marginBottom: 16,
                    }}
                  >
                    <img
                      src={qr.qrcode}
                      alt="QR"
                      style={{ width: 120, height: 120 }}
                    />
                  </div>
                )}
              </div>
              <div
                className="typography-blue-text"
                style={{ textAlign: "center", marginBottom: 100 }}
              >
                <Text
                  style={{ color: "#02C3CD", fontSize: 14, fontWeight: 600 }}
                >
                  {qr && qr.url}
                </Text>
              </div>
            </div>
            <Card
              className="cardButtonStyle"
              style={{
                left: 0,
                marginBottom: -2,
                backgroundColor: "#FAFEFE",
              }}
            >
              <Button
                id="gb-mortgage-buy-situation-mobile"
                className="button-primari-gibobs"
                type="primary"
                style={{
                  fontSize: 16,
                  width: "100%",
                  height: 48,
                  fontWeight: 600,
                }}
                onClick={() => downloadQR()}
                loading={loading}
              >
                {t("init.download")}
              </Button>
            </Card>
          </>
        )}
        {pages === 6 && (
          <div style={{ margin: 16 }}>
            {/* {titlePageMobile(setPages, 1, t("m_register.companyInfo"))} */}
            <Card className="card-inmo-account">
              <div className="title-cards">{t("form.agentCode")}</div>
              <div className="description-cards">
                {t("form.CodeDescription")}
              </div>
            </Card>
            <div
              className="title-cards"
              style={{ fontWeight: 400, marginTop: 32, textAlign: "center" }}
            >
              {t("form.personalCodeInmo").toUpperCase()}
            </div>
            <div
              id="inmo-code"
              className="typography-blue-text"
              style={{ textAlign: "center", fontWeight: 600 }}
            >
              {dataProfileDetails && dataProfileDetails.friendName}
            </div>
            <Card
              className="cardButtonStyle"
              style={{
                left: 0,
                marginBottom: -2,
                backgroundColor: "#FAFEFE",
              }}
            >
              <Button
                id="gb-mortgage-buy-situation-mobile"
                className="button-primari-gibobs"
                type="primary"
                style={{
                  fontSize: 16,
                  width: "100%",
                  height: 48,
                  fontWeight: 600,
                }}
                onClick={() => copyToClipBoard()}
                loading={loading}
              >
                {t("form.copyCode")}
              </Button>
            </Card>
          </div>
        )}
      </div>
    )}
    {!isMobile && (
      <div style={{ margin: "40px 20px", marginBottom: 50 }}>
        <Card className="CardStyleRealeState" style={{ marginBottom: 50 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="title-cards">{t("form.personalAccount")}</div>
            <div>
              <Button
                id="gb-mortgage-buy-situation-mobile"
                className="button-secundary-gibobs-inmo"
                type="dashed"
                onClick={() => setVisibleQR(true)}
                style={{ marginLeft: 8, height: 32 }}
              >
                {t("form.QRButtonSecundary")}
              </Button>
              <Button
                id="gb-mortgage-buy-situation-mobile"
                className="button-secundary-gibobs-inmo"
                type="dashed"
                style={{ marginLeft: 8, height: 32 }}
                onClick={() => setVisibleCode(true)}
              >
                {t("form.agentCode")}
              </Button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexFlow: "column",
              alignItems: "center",
            }}
          >
            <Upload {...uploadProps}>
              <Avatar
                size={{
                  xs: 80,
                  lg: 80,
                  xl: 114,
                  xxl: 130,
                }}
                icon={<UserOutlined />}
                src={
                  loading ? (
                    <Spin indicator={antIcon} className="spin" />
                  ) : saveFileDataField !== null ? (
                    <Image
                      preview={false}
                      style={{ cursor: "pointer" }}
                      src={`data:image/png;base64,${saveFileDataField}`}
                    />
                  ) : (
                    <Image
                      src={AvatarGenerico}
                      preview={false}
                      style={{ cursor: "pointer" }}
                    />
                  )
                }
              />
            </Upload>

            <Button
              type="dashed"
              className="button-secundary-gibobs-inmo"
              style={{
                padding: "0 3px",
                marginTop: "30px",
                marginBottom: "60px",
                height: "24px",
                width: isMobile ? "100%" : "auto",
                fontSize: "12px",
              }}
              onClick={showSendMessagesModal}
            >
              {t("form.changePassword")}
            </Button>
          </div>
          <Form
            className={"gb-form-profile-headlines-mobile"}
            onFinishFailed={openNotificationWithIcon}
            scrollToFirstError={true}
            name="nest-messages"
            onFinish={onFinish}
            layout="vertical"
            initialValues={dataProfileDetails ? dataProfileDetails : {}}
            style={{
              marginTop: "24px",
            }}
            validateMessages={validateMessages}
          >
            <div
              style={{
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 600,
                marginBottom: "24px",
                marginTop: "8px",
                color: "#2f4858",
              }}
            >
              {t("m_products.basicPersonalDataTitle").toUpperCase()}
            </div>
            {personalInformation}
            <div
              style={{
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 600,
                marginBottom: "24px",
                marginTop: "40px",
                color: "#2f4858",
              }}
            >
              {t("m_register.companyInfo").toUpperCase()}
            </div>
            {companyInformation}
            {/* <Row gutter={24}>
              <Col xs={24} lg={8}>
                <Form.Item
                  name="priorityProfile"
                  label={t("form.priorityProfile")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    suffixIcon={
                      <img
                        src={DownOutlinedMobile}
                        alt="DownOutlinedMobile"
                      />
                    }
                    disabled
                    showSearch
                    placeholder={t("form.priorityProfile")}
                    style={{ width: "100%" }}
                  >
                    {renderComponent("province").options.map((province) => {
                      return (
                        <Option key={province.value} value={province.value}>
                          {province.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row> */}
            <div style={{ textAlign: "center", marginTop: 60 }}>
              <Button
                id="gb-mortgage-buy-situation-mobile"
                className="button-primary-gibobs-inmo"
                type="primary"
                style={{
                  fontSize: "14px",
                  width: "96px",
                  height: 40,
                  fontWeight: 600,
                }}
                htmlType="submit"
                loading={loading}
              >
                {t("form.save")}
              </Button>
            </div>

            <ChangePassword
              key={taskToEdit}
              visible={showModal || taskToEdit}
              onCreate={changePassword}
              onCancel={hideAddTaskModal}
              width={800}
              taskToEdit={taskToEdit}
            />
          </Form>
        </Card>
      </div>
    )}
    <Modal
      className="ModalCommon"
      okText={t("init.download")}
      cancelText={t("offers.cancel")}
      open={visibleQR}
      title={t("form.QRButtonSecundary")}
      width={554}
      onOk={() => downloadQR()}
      onCancel={() => setVisibleQR(!visibleQR)}
      cancelButtonProps={{
        className: "button-secundary-gibobs-inmo",
        style: { width: "45%", height: 40 },
      }}
      okButtonProps={{
        className: "button-primary-gibobs-inmo",
        style: { width: "45%", height: 40 },
      }}
    >
      <div className="description-cards" style={{ marginTop: -22 }}>
        {t("form.QRDescription")}
      </div>
      {qr && (
        <div style={{ textAlign: "center", width: "100%" }}>
          <img
            src={qr.qrcode}
            alt="QR"
            style={{ width: 146, height: 146 }}
          ></img>
        </div>
      )}
      <div className="typography-blue-text" style={{ textAlign: "center" }}>
        <Text style={{ color: "#02C3CD", fontSize: 12, fontWeight: 600 }}>
          {qr && qr.url}
        </Text>
      </div>
    </Modal>
    <Modal
      className="ModalCommon"
      okText={t("form.copyButton")}
      cancelText={t("offers.cancel")}
      open={visibleCode}
      title={t("form.agentCode")}
      width={380}
      onOk={() => copyToClipBoard()}
      onCancel={() => setVisibleCode(!visibleCode)}
      cancelButtonProps={{
        className: "button-secundary-gibobs-inmo",
        style: { width: "45%", height: 40 },
      }}
      okButtonProps={{
        className: "button-primary-gibobs-inmo",
        style: { width: "45%", height: 40 },
      }}
    >
      <div className="description-cards" style={{ marginTop: -22 }}>
        {t("form.CodeDescription")}
      </div>
      <div style={{ display: "flex", marginTop: 16 }}>
        <div
          className="title-cards"
          style={{ fontWeight: 400, fontSize: 14, marginRight: 8 }}
        >
          {t("form.personalCodeInmo").toUpperCase()}
        </div>
        <div
          id="inmo-code"
          className="typography-blue-text"
          style={{ fontSize: 14 }}
        >
          {dataProfileDetails && dataProfileDetails.friendName}
        </div>
      </div>
    </Modal>
  </>);
};

export default CompanySpace;
