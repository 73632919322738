import React, { useState, useEffect } from 'react';
import { Col, Form, Radio, Space, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import DownOutlinedMobile from '../../../../assets/icons/DownOutlinedMobile.svg';
import axios from 'axios';
import env from '../../../../environment';
import { useSelector } from 'react-redux';
import numeral from 'numeral';
import { InputNumber } from 'afrodita';

const { Option } = Select;

const RequiredFinancingForm3 = ({ edit, dataOperation }) => {
	const { t } = useTranslation();
	const [savings, setSavings] = useState('-');
	const [houseType, setHouseType] = useState(
		dataOperation && dataOperation.mortgage && dataOperation.mortgage.houseType
			? dataOperation.mortgage.houseType
			: ''
	);

	const userToken = useSelector((state) => state.auth.token);
	const colSpan = {
		xxl: edit === undefined ? 24 : 6,
		xl: edit === undefined ? 24 : 6,
		lg: edit === undefined ? 24 : 8,
		md: edit === undefined ? 24 : 12,
		sm: 24,
		xs: 24
	};
	const getOperationData = () => {
		const obj = {
			maxBudget: dataOperation && dataOperation.mortgage && dataOperation.mortgage.maxBudget,
			province: dataOperation && dataOperation.mortgage && dataOperation.mortgage.province,
			age:
				dataOperation && dataOperation.owner1 && dataOperation.owner1.age
					? dataOperation.owner1.age
					: 30,
			houseType: houseType,
			propertyType: dataOperation && dataOperation.mortgage && dataOperation.mortgage.propertyType
		};
		axios
			.post(`${env.api.url}/mortgages/calculations/desirable-savings`, obj, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setSavings(response.data.data);
			})
			.catch((error) => {
				console.log(error)
			});
	};
	useEffect(() => {
		if (houseType !== '' && houseType !== 'investor') {
			getOperationData();
		}
	}, [houseType]);
	return (
		<>
			{edit !== undefined && (
				<Col {...colSpan}>
					<Form.Item name="houseType" label={t('onboarding.useToTheHome')}>
						<Select
							id="houseType"
							suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
							placeholder={t('form.select')}
							style={{ width: '100%' }}
							// onChange={(e) => setHouseType(e.target.value)}
						>
							<Option value="main">{t('onboarding.primaryResidence')}</Option>
							<Option value="second">{t('onboarding.secondHome')}</Option>
							<Option value="investor">{t('onboarding.buyToInvest')}</Option>
						</Select>
					</Form.Item>
				</Col>
			)}
			{edit === undefined && (
				<Col span={24}>
					<Form.Item name="houseType" label={t('onboarding.useToTheHome')} 
					rules={[
						{
						  required: true,
						},
					  ]}
					
					>
						<Radio.Group id="houseType" onChange={(e) => setHouseType(e.target.value)}>
							<Space direction="vertical">
								<Radio value="main">{t('onboarding.primaryResidence')}</Radio>
								<Radio value="second">{t('onboarding.secondHome')}</Radio>
								<Radio value="investor">{t('onboarding.buyToInvest')}</Radio>
							</Space>
						</Radio.Group>
					</Form.Item>
				</Col>
			)}
			<Col {...colSpan}>
				<Form.Item
					name="savings"
					label={t('onboarding.savingAvailable')}
					validateTrigger="onBlur"
					rules={[
						{
							required: true,
							validator: (e, value) =>
								value > 0
									? Promise.resolve()
									: Promise.reject(
											new Error(
												t('onboarding.savingAvailable') +
													' ' +
													t('onboarding.savingAvailableValidator')
											)
									  )
						}
					]}>
					<InputNumber
						defaultValue={''}
						id={'savings'}
						gbType="currency"
						max={99000000}
						maxLength="15"
						placeholder={t('0')}
					/>
				</Form.Item>
				{houseType === 'main' && (
					<div className="explination-savings explination-others-incomes">
						{t('onboarding.shouldHaveSavings')} {numeral(savings).format('0,0 $')}
					</div>
				)}
				{houseType === 'second' && (
					<div className="explination-savings explination-others-incomes">
						{t('onboarding.shouldHaveSavings')} {numeral(savings).format('0,0 $')}
					</div>
				)}
			</Col>
		</>
	);
};

export default RequiredFinancingForm3;
