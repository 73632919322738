import React, { useState, useEffect } from 'react';
import { Layout, Card, Typography, Button, Form, Input, Col, Row, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import {
	ArrowLeftOutlined,
	BankOutlined,
	SearchOutlined
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import env from '../../../../environment';
import LayoutOperations from '../../LayoutOperations';
import AfterBanksComponent from '../../../../components/AfterBanks/Afterbanks';
import _ from 'lodash';
import SliderDashboard from './SliderDashboard';
import ModalDataCollection from '../Modals/ModalDataCollection';
import { getAnalytics, logEvent } from 'firebase/analytics';
import ModalButtonsCommon from '../Modals/ModalButtonsCommon';
import { getAmortizationData, getPreviousPage } from '../../../../store/calculators/actions';

const { Text } = Typography;


const LoanPageNoDataDesktop = ({ }) => {
	const analytics = getAnalytics();
	const location = useLocation();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();;
	const [nameBanks, setNameBanks] = useState(null);
	const userToken = useSelector((state) => state.auth.token);
	const [form] = Form.useForm();
	const [afterbanks, setAfterBanks] = useState(false);
	const [bankId, setBankId] = useState(undefined);
	const [modalAutomaticOrManual, setModalAutomaticOrManual] = useState(false);
	const [bankSelect, setBankSelect] = useState([]);
	const [errorDataModal, setErrorDataModal] = useState(false);
	const [loanList, setLoanList] = useState([]);
	const [modalDataNoInfo, setModalDataNoInfo] = useState(false);

	var myVar = undefined;
	const [loanId, setLoanId] = useState(undefined);

	useEffect(() => {
		axios
			.get(`${env.api.url}/v1/banks/search?bankreader=true&status=ongoing`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				const otherBank = {
					name: 'Otros',
					id: undefined,
					bankCode: undefined
				};
				response.data.data.banks.push(otherBank);
				setNameBanks(response.data.data.banks);
			});

		getLoansList();
		logEvent(analytics, 'screen_view', {
			screen_name: 'loan_screen-add-view'
		});
	}, []);

	const getLoansList = () => {
		axios
			.get(`${env.api.url}/v1/loans/get-loans-list`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setLoanList(response.data.data.loans.reverse());
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const handleAddLoan = () => {
		navigate('/my-financial-profile/credits/add/loan');
		dispatch(getPreviousPage('new-loan'));
		dispatch(getAmortizationData(undefined));
	};

	let values = 0;
	useEffect(() => {
		if (loanId) {
			myVar = setInterval(() => {
				axios
					.get(`${env.api.url}/v1/loans/get-loan/${loanId}`, {
						headers: { Authorization: `Bearer ${userToken}` }
					})
					.then((response) => {
						if (response.data.success) {
							values = values + 1;
							if (response.data.data.loan.status === 'EMPTY' && values === 4) {
								myStopFunction();
								setAfterBanks(false);
								setErrorDataModal(true);
								values = 0;
							}
							if (response.data.data.loan.status === 'PENDING_USER') {
								myStopFunction();
								handleafterBanks();
								values = 0;
							}
							if (response.data.data.loan.status === 'DRAFT' && values === 4) {
								myStopFunction();
								setAfterBanks(false);
								setModalDataNoInfo(true);
								values = 0;
							}
						}
					})
					.catch(() => {
						myStopFunction();
						handleafterBanks();
						values = 0;
					});
			}, 15000);
		}
	}, [loanId]);

	const myStopFunction = () => {
		clearInterval(myVar);
	};


	const handleafterBanksCancel = () => {
		setAfterBanks(false);
		setBankId(undefined)
	};

	const handleafterBanks = () => {
		setAfterBanks(false);
		
		navigate(`/my-financial-profile/credits/edit/all-info-checked/${loanId}`);
	};

	const handleCreateManual = (bank) => {
		if (bankId !== undefined) {
			navigate(`/my-financial-profile/credits/form/${bankId.id}`);
			setModalAutomaticOrManual(!modalAutomaticOrManual);
		} else {
			if (bank === undefined) {
				navigate(`/my-financial-profile/credits/form/${bank}`);
			} else {
				navigate(`/my-financial-profile/credits/form/${bank.id}`);
			}
		}
		logEvent(analytics, 'select_content', {
			content_type: 'modal.select.create.mortgage',
			content_id: 'create.mortgage.manual'
		});
	};

	const onSearch = (e) => {
		if (e.target.value === '') {
			axios
				.get(`${env.api.url}/v1/banks/search?bankreader=true&status=ongoing`, {
					headers: {
						Authorization: `Bearer ${userToken}`
					}
				})
				.then((response) => {
					const otherBank = {
						name: 'Otros',
						id: undefined,
						bankCode: undefined
					};
					response.data.data.banks.push(otherBank);
					setNameBanks(response.data.data.banks);
				});
		} else {
			axios
				.get(`${env.api.url}/v1/banks/search?bankreader=true&status=ongoing&q=${e.target.value}`, {
					headers: {
						Authorization: `Bearer ${userToken}`
					}
				})
				.then((response) => {
					const otherBank = {
						name: 'Otros',
						id: undefined,
						bankCode: undefined
					};
					response.data.data.banks.push(otherBank);
					setNameBanks(response.data.data.banks);
				});
		}
	};

	const handleClickFormData = (bank) => {
		if (bank.id !== undefined) {
			if (bank.bankreaderMortgage) {
				setModalAutomaticOrManual(!modalAutomaticOrManual);
			} else {
				handleCreateManual(bank);
			}
		} else {
			handleCreateManual(undefined);
		}
	};

	const renderPaint = (id) => {
		return _.indexOf(bankSelect, id) !== -1;
	};

	const selectCard = (bank) => {
		setBankId(bank);

		bankSelect.shift();
		if (bank !== undefined) {
			bankSelect.push(bank.id);
		}
		setBankSelect(_.clone(bankSelect));

		if (bank.name === 'Otros') {
			handleCreateManual();
		} else {
			handleClickFormData(bank);
		}

		return false;
	};

	const handleCreateAutomatic = () => {
		setAfterBanks(!afterbanks);
		setModalAutomaticOrManual(!modalAutomaticOrManual);
		logEvent(analytics, 'select_content', {
			content_type: 'modal.select.create.mortgage',
			content_id: 'create.mortgage.automatic'
		});
	};

	if (location.pathname === '/my-financial-profile/credits') {
		return (
			<>
				<LayoutOperations />

				<Layout
					style={{
						paddingLeft: 40,
						paddingRight: 40,
						marginBottom: 100
					}}>
					<Card className="gb-card-loans-page-initial">
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<Text className="title-cards">{t('loan.emptyListMortgageTitle')}</Text>
							<Text
								style={{
									color: '#748EA0',
									fontSize: 14,
									fontWeight: 400,
									marginTop: 16
								}}>
								{t('loan.noDataDescriptionMortgage')}
							</Text>
						</div>
						<div style={{ marginTop: 32, textAlign: 'center' }}>
							<Button
								className="button-primari-gibobs"
								type="primary"
								style={{ width: 264 }}
								onClick={() => handleAddLoan()}>
								{t('init.myMortgageButtonAdd')}
							</Button>
						</div>
					</Card>
					<SliderDashboard />
				</Layout>
			</>
		);
	} else {
		return (
            (<Layout
				style={{
					paddingLeft: 40,
					height: 'auto',
					marginBottom: 200,
					paddingRight: 40,
					paddingTop: 24
				}}>
                <div style={{ width: '100%', marginBottom: 30 }}>
					{loanList && loanList.length > 0 && (
						<a onClick={() => navigate('/my-financial-profile/credits/list')}>
							<ArrowLeftOutlined style={{ color: '#2F4858', fontWeight: 600, fontSize: 16 }} />
						</a>
					)}
					{loanList && loanList.length === 0 && (
						<a onClick={() => navigate('/my-financial-profile/credits')}>
							<ArrowLeftOutlined style={{ color: '#2F4858', fontWeight: 600, fontSize: 16 }} />
						</a>
					)}
					<Text className="title-page" style={{ marginLeft: 16 }}>
						{t('loan.chooseBank')}
					</Text>
				</div>
                <Card className="gb-card">
					<div style={{ marginBottom: -16 }}>
						<Form form={form} layout="vertical" style={{ marginLeft: -15 }}>
							<Form.Item label={''} name="haveABank">
								<Input
									className="gb-search-input"
									onChange={onSearch}
									placeholder={t('loan.chooseBankForm')}
									style={{ height: 32, width: 394 }}></Input>
								<div
									style={{
										width: 15,
										height: 15,
										position: 'relative',
										top: 6,
										right: -380,
										float: 'left'
									}}>
									<SearchOutlined style={{ color: '#748EA0', fontSize: 16 }} />
								</div>
							</Form.Item>
						</Form>
					</div>

					<Row gutter={[16, 0]} style={{ display: 'flex', textAlign: 'center' }}>
						{nameBanks ? (
							nameBanks.map((nameBank) => {
								return (
									<Col
										xs={24}
										md={24}
										sm={6}
										lg={6}
										xl={6}
										xxl={4}
										style={{ marginTop: 16 }}
										onClick={(e) => selectCard(nameBank, e)}>
										{nameBank.name !== 'Otros' ? (
											<>
												<Card
													className="gb-card"
													style={{
														cursor: 'pointer',
														border: renderPaint(nameBank.id) ? '1px solid #02C3CD' : undefined,
														borderRadius: 4
													}}
													// onClick={(e) => handleClickFormData()}
												>
													<img
														src={`https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/banks/${
															nameBank.bankCode + '.png'
														}`}
														style={{
															width: 'auto',
															height: 32
														}}
													/>
												</Card>
												<Text
													style={{
														color: '#2F4858',
														fontSize: 12,
														fontWeight: 400
													}}>
													{nameBank.name}
												</Text>
											</>
										) : (
											<>
												<Card
													className="gb-card"
													style={{
														cursor: 'pointer',
														border: renderPaint(undefined) ? '1px solid #02C3CD' : undefined
													}}
													// onClick={(e) => handleCreateManual()}
												>
													<BankOutlined style={{ color: '#02C3CD', fontSize: 30 }} />
												</Card>
												<Text
													style={{
														color: '#2F4858',
														fontSize: 12,
														fontWeight: 400
													}}>
													{nameBank.name}
												</Text>
											</>
										)}
									</Col>
								);
							})
						) : (
							<Col
								span={24}
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									marginTop: 24
								}}>
								<div onClick={(e) => selectCard(undefined, e)}></div>
								<Text
									style={{
										color: '#2F4858',
										fontSize: 12,
										fontWeight: 400
									}}>
									{t('loan.noBankAvatar')}
								</Text>

								<div style={{ padding: 32, marginTop: 10 }}>
									<div>
										<Text
											style={{
												color: '#748EA0',
												fontSize: 14,
												fontWeight: 400
											}}>
											{t('loan.chooseBankSearch1')}
										</Text>
									</div>

									<div style={{ marginTop: 24 }}>
										<Text
											style={{
												color: '#748EA0',
												fontSize: 14,
												fontWeight: 400
											}}>
											{t('loan.chooseBankSearch2')}
										</Text>
									</div>
								</div>
							</Col>
						)}
					</Row>
				</Card>
                <Modal
					className="modalMobileAfterBanks"
					open={afterbanks}
					onCancel={() => handleafterBanksCancel(false)}
					onOk={() => handleafterBanks()}
					//okButtonProps={{ disabled: fileAfterbanks ? false : true }}
					cancelButtonProps={{
						style: { border: 'none', contentVisibility: 'hidden' }
					}}
					destroyOnClose={true}
					closable={true}
					footer={null}>
					<AfterBanksComponent
						type="loan"
						bankId={bankId && bankId.id}
						bank={bankId}
						setLoanId={setLoanId}
						afterbanks={(e) => setAfterBanks(e)}></AfterBanksComponent>
				</Modal>
                <ModalButtonsCommon
					key={'errorDataModal'}
					visible={errorDataModal}
					onCreate={() => handleCreateManual(bankId)}
					onCancel={() => setErrorDataModal(false)}
					okText={'offers.accept'}
					cancelText={'offers.cancel'}
					title={'loan.IncompleteDataModalTitle'}
					text={t('loan.IncompleteDataModalDescription')}
				/>
                <ModalDataCollection
					key={'modalAutomaticOrManual'}
					visible={modalAutomaticOrManual}
					onCreate={() => setModalAutomaticOrManual(!modalAutomaticOrManual)}
					onCancel={() => setModalAutomaticOrManual(!modalAutomaticOrManual)}
					width={800}
					handleCreateAutomatic={() => handleCreateAutomatic()}
					handleCreateManual={() => handleCreateManual()}
				/>
                <Modal
					className="ModalDesktopFooterColum"
					okButtonProps={{
						className: 'button-primari-gibobs',
						style: { width: '100%', alignSelf: 'center', height: 40 }
					}}
					okText={
						<Text style={{ fontSize: 14, fontWeight: 500, color: 'white' }}>
							{t('loan.manualRegister')}
						</Text>
					}
					cancelText={
						<Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 500 }}>
							{t('loan.goToLoans')}
						</Text>
					}
					open={modalDataNoInfo}
					title={<Text>{t('loan.draftModalTitle')}</Text>}
					width={432}
					cancelButtonProps={{ style: { border: 'none', marginBottom: -20, marginTop: 16 } }}
					closable={true}
					onOk={() => navigate(`/my-financial-profile/credits/form/${bankId}`)}
					onCancel={() => {
						navigate('/my-financial-profile/credits/list');
						setModalDataNoInfo(false);
					}}>
					<div>
						<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
							{t('loan.draftModalText1')}
						</Text>
					</div>
					<div style={{ marginTop: 40 }}>
						<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
							{t('loan.draftModalText2')}
						</Text>
					</div>
				</Modal>
            </Layout>)
        );
	}
};

export default LoanPageNoDataDesktop;
