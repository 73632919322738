import { Col, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { InputNumber } from 'afrodita';

const MortgageTermForm4 = ({ edit }) => {
	const { t } = useTranslation();
	const colSpan = {
		xxl: edit === undefined ? 24 : 6,
		xl: edit === undefined ? 24 : 6,
		lg: edit === undefined ? 24 : 8,
		md: edit === undefined ? 24 : 12,
		sm: 24,
		xs: 24
	};
	return (
		<>
			<Col {...colSpan}>
				<Form.Item
					name="age"
					label={t('onboarding.howOldAreYou')}
					validateTrigger="onBlur"
					rules={[
						{
							required: true,
							validator: (e, value) =>
								value >= 18 && value <= 74
									? Promise.resolve()
									: Promise.reject(new Error(t('age.input')))
						}
					]}>
					<InputNumber
						defaultValue={''}
						id={'age'}
						gbType='number'
						className='gb-input-new-years'
						min={18}
						maxLength="2"
						placeholder={t('0')}
					/>
				</Form.Item>
			</Col>
		</>
	);
};

export default MortgageTermForm4;
