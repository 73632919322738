import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { EuroCircleOutlined } from '@ant-design/icons';

function ModalDescriptionFinancial({ visible, onCancel, onCreate, width, mobile }) {
	const { t } = useTranslation();

	const titleSection = (title, color) => {
		return (
			<>
				<div style={{ display: 'flex', marginBottom: 4, alignItems: 'center', marginTop: 4 }}>
					<EuroCircleOutlined style={{ color: color }} />
					<div className="title-primary" style={{ marginLeft: 10 }}>
						{t(title)}
					</div>
				</div>
			</>
		);
	};
	const descriptionData = (title, description) => {
		return (
			<div style={{ width: mobile ? '100%' : '30%', marginTop: mobile ? -10 : undefined }}>
				<div className={mobile ? "sub-title-promoter-gibobs-mobile" : "sub-title-promoter-gibobs"} style={{ marginBottom: 6 }}>
					{t(title)}
				</div>
				<div className="paragraph-style" style={{ marginBottom: 24, fontSize: 12 }}>
					{t(description)}
				</div>
			</div>
		);
	};
	return mobile ? (
		<Modal
			className="gb-modal-16-padding modal-description-mobile-promoter"
			cancelButtonProps={{
				className: 'button-secundary-gibobs',
				style: { display: 'none' }
			}}
			okButtonProps={{
				className: 'button-primari-gibobs',
				style: { display: 'none' }
			}}
			centered
			open={visible}
			title={t('promoter.modalTitlte')}

			width={width}
			onCancel={() => {
				onCancel();
			}}
			onOk={() => {
				onCreate();
			}}>
			<div className="paragraph-style-mobile">
				{t('promoterLoan.allDataTaxes')}
			</div>
			<div style={{ marginTop: 8 }}>
				{titleSection('promoterLoan.projectIncome', '#52C41A')}
			</div>
			<div style={{ marginTop: 18 }}>
				{descriptionData('balance.incomesTitle', 'promoterLoan.incomesDescription')}
			</div>
			<div style={{ marginTop: -14 }}>
				{titleSection('promoterLoan.projectCosts', '#FF4D4F')}
			</div>
			<div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '5%', marginTop: 20, marginBottom: -50 }}>
				{descriptionData('promoterLoan.landCosts', 'promoterLoan.landDescription')}
				{descriptionData('promoterLoan.constuctionCosts', 'promoterLoan.constructionDescription')}
				{descriptionData('promoterLoan.associatedCosts', 'promoterLoan.associatedDescription')}
				{descriptionData('promoterLoan.commercialCosts', 'promoterLoan.commercialDescription')}
				{descriptionData('promoterLoan.managementCosts', 'promoterLoan.managementDescription')}
			</div>

		</Modal>
	) : (
		<Modal
			className="ModalCommon modal-promoter-footer"
			cancelButtonProps={{
				className: 'button-secundary-gibobs',
				style: { width: '120px' }
			}}
			okButtonProps={{
				className: 'button-primari-gibobs',
				style: { width: '120px' }
			}}
			visible={visible}
			title={t('promoterLoan.descriptionEconomicData')}
			okText={t('form.ok')}
			cancelText={t('offers.cancel')}
			width={width}
			onCancel={() => {
				onCancel();
			}}
			onOk={() => {
				onCreate();
			}}>
			{titleSection('promoterLoan.projectIncome', '#52C41A')}
			{descriptionData('balance.incomesTitle', 'promoterLoan.incomesDescription')}
			{titleSection('promoterLoan.projectCosts', '#FF4D4F')}
			<div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '5%' }}>
				{descriptionData('promoterLoan.landCosts', 'promoterLoan.landDescription')}
				{descriptionData('promoterLoan.constuctionCosts', 'promoterLoan.constructionDescription')}
				{descriptionData('promoterLoan.associatedCosts', 'promoterLoan.associatedDescription')}
				{descriptionData('promoterLoan.commercialCosts', 'promoterLoan.commercialDescription')}
				{descriptionData('promoterLoan.managementCosts', 'promoterLoan.managementDescription')}
			</div>
			<div className="paragraph-style" style={{ marginBottom: 24 }}>
				{t('promoterLoan.allDataTaxes')}
			</div>
		</Modal>
	);
}

export default ModalDescriptionFinancial;
