import React, { useState, useEffect } from "react";
import { Typography, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import numeral from "numeral";
import EditableTableIncome from "./tableEditableIncome";
import ModalIngresos from "./modalIngresos";
import {
  createSeat,
  deleteSeat,
  getSeats,
  renameNameSeat,
  renameSeat,
} from "../../../store/data/actions";

const { confirm } = Modal;
const { Text } = Typography;

function TableExpensesComponent() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const seatsList = useSelector((state) => state.data.getSeats);
  const userToken = useSelector((state) => state.auth.token);
  const [valueTotalEnero, setValueTotalEnero] = useState(0);
  const [valueTotalFebrero, setValueTotalFebrero] = useState(0);
  const [valueTotalMarzo, setValueTotalMarzo] = useState(0);
  const [valueTotalAbril, setValueTotalAbril] = useState(0);
  const [valueTotalMayo, setValueTotalMayo] = useState(0);
  const [valueTotalJunio, setValueTotalJunio] = useState(0);
  const [valueTotalJulio, setValueTotalJulio] = useState(0);
  const [valueTotalAgosto, setValueTotalAgosto] = useState(0);
  const [valueTotalSeptiembre, setValueTotalSeptiembre] = useState(0);
  const [valueTotalOctubre, setValueTotalOctubre] = useState(0);
  const [valueTotalNoviembre, setValueTotalNoviembre] = useState(0);
  const [valueTotalDiciembre, setValueTotalDiciembre] = useState(0);
  const [valueTotalMes, setValueTotalMes] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);

  const [dataEdit, setDataEdit] = useState();

  const hideAddTaskModal = () => {
    setShowAddModal(false);
    setDataEdit(null);
  };
  const [typeActive, setTypeActive] = useState([
    {
      name: "balance.loansTitle",
      category: "loans",
      dataSource: [],
    },
    {
      name: "balance.rentalsTitle",
      category: "rents",
      dataSource: [],
    },
    {
      name: "balance.suppliesTitle",
      category: "supplies",
      dataSource: [],
    },
    {
      name: "balance.insuranceTitle",
      category: "insurances",
      dataSource: [],
    },
    {
      name: "balance.educationTitle",
      category: "education",
      dataSource: [],
    },
    {
      name: "balance.vehiclesTitle",
      category: "vehicles",
      dataSource: [],
    },

    {
      name: "balance.shoppingTitle",
      category: "market",
      dataSource: [],
    },
    {
      name: "balance.leisure",
      category: "leisure",
      dataSource: [],
    },
    {
      name: "form.others",
      category: "other",
      dataSource: [],
    },
  ]);
  useEffect(() => {
    let totalEnero = 0;
    let totalFebrero = 0;
    let totalMarzo = 0;
    let totalAbril = 0;
    let totalMayo = 0;
    let totalJunio = 0;
    let totalJulio = 0;
    let totalAgosto = 0;
    let totalSeptiembre = 0;
    let totalOctubre = 0;
    let totalNoviembre = 0;
    let totalDiciembre = 0;
    let totalMes = 0;

    typeActive.forEach((type) => {
      type.dataSource.forEach((dataSource) => {
        totalEnero += dataSource.valueEnero;
        totalFebrero += dataSource.valueFebrero;
        totalMarzo += dataSource.valueMarzo;
        totalAbril += dataSource.valueAbril;
        totalMayo += dataSource.valueMayo;
        totalJunio += dataSource.valueJunio;
        totalAgosto += dataSource.valueAgosto;
        totalSeptiembre += dataSource.valueSeptiembre;
        totalOctubre += dataSource.valueOctubre;
        totalNoviembre += dataSource.valueNoviembre;
        totalDiciembre += dataSource.valueDiciembre;
        totalJulio += dataSource.valueJulio;
      });
    });

    totalMes =
      totalEnero +
      totalFebrero +
      totalMarzo +
      totalMayo +
      totalAbril +
      totalJunio +
      totalJulio +
      totalAgosto +
      totalSeptiembre +
      totalOctubre +
      totalNoviembre +
      totalDiciembre;

    setValueTotalEnero(totalEnero);
    setValueTotalFebrero(totalFebrero);
    setValueTotalMarzo(totalMarzo);
    setValueTotalAbril(totalAbril);
    setValueTotalMayo(totalMayo);
    setValueTotalJunio(totalJunio);
    setValueTotalJulio(totalJulio);
    setValueTotalAgosto(totalAgosto);
    setValueTotalSeptiembre(totalSeptiembre);
    setValueTotalOctubre(totalOctubre);
    setValueTotalNoviembre(totalNoviembre);
    setValueTotalDiciembre(totalDiciembre);
    setValueTotalMes(totalMes);
  }, [typeActive]);

  useEffect(() => {
    if (seatsList.length > 0) {
      let newTypeActive = [...typeActive].map((type) => {
        return {
          name: type.name,
          icon: type.icon,
          category: type.category,
          dataSource: [],
        };
      });
      seatsList.forEach((item) => {
        const dataToAdd = {
          name: item.name,
          value: item.amount,
          id: item.id,
          porcentaje: item.payable,
          valueEnero: item.amounts.january,
          valueFebrero: item.amounts.february,
          valueMarzo: item.amounts.march,
          valueAbril: item.amounts.april,
          valueMayo: item.amounts.may,
          valueJunio: item.amounts.june,
          valueJulio: item.amounts.july,
          valueAgosto: item.amounts.august,
          valueSeptiembre: item.amounts.september,
          valueOctubre: item.amounts.october,
          valueDiciembre: item.amounts.december,
          valueNoviembre: item.amounts.november,
        };
        if (item.type === "expense") {
          newTypeActive
            .find((data) => {
              return data.category === item.category;
            })
            .dataSource.push(dataToAdd);
        }
      });
      setTypeActive(newTypeActive);
    }
  }, [seatsList]);
  useEffect(() => {
    dispatch(getSeats(userToken, 2021));
  }, []);

  const taskEditHandler = (indexType, id, property) => {
    let newDataSource = [...typeActive][indexType].dataSource.find(
      (item) => item.id === id
    );

    setDataEdit({ ...newDataSource, property });
  };

  const title = {
    0: "balance.realeStateTitle",
    1: "balance.furniture",
    2: "balance.treasury",
    3: "form.others",
  };

  const showDeleteConfirm = (i, id) => {
    confirm({
      className: "ModalCommonConfirm",
      title: `Borrar activos de ${t(title[i])}`,
      icon: null,
      content: "¿Seguro que deseas borrar este elemento?",
      okText: "Borrar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        handleDelete(i, id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const textMonth = {
    valueEnero: "january",
    valueFebrero: "february",
    valueMarzo: 'march',
    valueAbril: 'april',
    valueMayo: 'may',
    valueJunio: 'june',
    valueJulio: 'july',
    valueAgosto: 'august',
    valueSeptiembre: 'september',
    valueOctubre:'octuber',
    valueNoviembre:'november',
    valueDiciembre:'december'
  };

  const handleDelete = (indexType, id) => {
    dispatch(deleteSeat(userToken, id));
  };

  const handleAdd = (values) => {
    if (dataEdit) {
      if (!values.name) {
        dispatch(
          renameSeat(
            userToken,
            {
              // name: values.name,
              month: textMonth[values.month],
              amount: values.active,
              year: 2021,
            },
            dataEdit.id
          )
        );
      } else {
        dispatch(
          renameNameSeat(
            userToken,
            {
              name: values.name,
              year: 2021,
            },
            dataEdit.id
          )
        );
      }
    } else {
      let objResponse = {
        year: 2021,
        category: typeActive[showAddModal].category,
        type: "expense",
        name: values.name,
        amounts: {
          january: values.month.includes("valueEnero") ? values.active : 0,
          february: values.month.includes("valueFebrero") ? values.active : 0,
          march: values.month.includes("valueMarzo") ? values.active : 0,
          april: values.month.includes("valueAbril") ? values.active : 0,
          may: values.month.includes("valueMayo") ? values.active : 0,
          june: values.month.includes("valueJunio") ? values.active : 0,
          july: values.month.includes("valueJulio") ? values.active : 0,
          august: values.month.includes("valueAgosto") ? values.active : 0,
          september: values.month.includes("valueSeptiembre")
            ? values.active
            : 0,
          october: values.month.includes("valueOctubre") ? values.active : 0,
          november: values.month.includes("valueNoviembre") ? values.active : 0,
          december: values.month.includes("valueDiciembre") ? values.active : 0,
        },
      };
      dispatch(createSeat(userToken, objResponse));
    }

    setShowAddModal(false);
    setDataEdit(null);
  };
  const typeTitle = [
    {
      title: valueTotalEnero,
    },
    {
      title: valueTotalFebrero,
    },
    {
      title: valueTotalMarzo,
    },
    {
      title: valueTotalAbril,
    },
    {
      title: valueTotalMayo,
    },
    {
      title: valueTotalJunio,
    },
    {
      title: valueTotalJulio,
    },
    {
      title: valueTotalAgosto,
    },
    {
      title: valueTotalSeptiembre,
    },
    {
      title: valueTotalOctubre,
    },
    {
      title: valueTotalNoviembre,
    },
    {
      title: valueTotalDiciembre,
    },
    {
      title: valueTotalMes,
    },
  ];
  const meses = [
    "Enero",
    "Febre",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septie",
    "Octubre",
    "Noviem",
    "Diciem",
    "     ",
    "Total",
  ];
  return (
    <>
      <div style={{ marginTop: "40px" }}>
        <Text style={{ color: "#2F4858", fontSize: "14px", fontWeight: "500" }}>
          {t("balance.expensesTitle")}
        </Text>
      </div>

      <div
        style={{
          backgroundColor: "#41D2DA",
          borderRadius: "4px 4px 0px 0px",
          height: "44px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "16px",
          width: "1430px",
          marginTop: "16px",
        }}
      >
        

        <Text
          style={{
            width: "110px",
            fontSize: "12px",
            color: "#FFFFFF",
            // marginRight: "16px",
            fontWeight: "600",
          }}
        >
          {t("balance.categoryActivePassive")}
        </Text>
   
        <Text
          style={{
            width: "155px",
            // marginRight: "10px",
            fontSize: "12px",
            color: "#FFFFFF",
            fontWeight: "600",
          }}
        >
          {t("form.name")}
        </Text>
        {meses
          ? meses.map((data) => (
            data === "Total" ? <div style={{ backgroundColor: "#41D2DA", height: "44px", position: "absolute", right: "12px", width: '120px', marginRight: "16px", borderRadius: "4px 4px 0px 0px", paddingTop: "10px", paddingLeft: "25px" }}>
              <Text
                style={{
                  fontSize: "12px",
                  color: "#FFFFFF",
                  // marginRight: "16px",
                  fontWeight: "600",
                }}
              >
                {data}
              </Text>
            </div> :
              <Text
                style={{
                  width: data === "     " ? "100px" : "50px",
                  fontSize: "12px",
                  color: "#FFFFFF",
                  // marginRight: "16px",
                  fontWeight: "600",
                  position: data === "Total" ? "absolute" : null,
                  right: data === "Total" ? "10px" : null,
                  background: data === "Total" ? "#02AFB8;" : null,
                }}
              >
                {data}
              </Text>
          ))
          : null}
      </div>
      {typeActive
        ? typeActive.map((data, i) => {
            return (
              <>
                <EditableTableIncome
                  key={data.name}
                  name={t(data.name)}
                  icon={data.icon}
                  dataToSource={data.dataSource}
                  handleDelete={(id) => handleDelete(i, id)}
                  showAddModal={() => setShowAddModal(i)}
                  taskEditHandler={(id, property) => taskEditHandler(i, id, property)}
                  showDeleteConfirm={(id) => showDeleteConfirm(i, id)}
                  index={i}
                />
                {i !== 8 ? (
                  <div
                    style={{
                      marginTop: "-1px",
                      border: "1px solid #F1F7F8",
                      width: "1360px",
                    }}
                  />
                ) : null}
              </>
            );
          })
        : null}

      <div
        style={{
          backgroundColor: "#41D2DA",
          borderRadius: "0px 0px 4px 4px",
          height: "44px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px",
          width: "1430px",
          marginBottom: "12px",
        }}
      >
        <Text
          style={{
            fontSize: "12px",
            color: "#FFFFFF",
            marginRight: "16px",
            fontWeight: "600",
            width: "290px",
          }}
        >
          {t("balance.totalTitle")}
        </Text>

        {typeTitle
          ? typeTitle.map((data, index) => (
            index === typeTitle.length - 1 ? <div style={{ backgroundColor: "#41D2DA", height: "44px", position: "absolute", right: "12px", width: '120px', marginRight: "16px", borderRadius: "4px 4px 0px 0px", paddingTop: "10px", paddingLeft: "16px" }}>
            <Text
              style={{
                fontSize: "16px",
                color: "#FFFFFF",
                // marginRight: "16px",
                fontWeight: "600",
              }}
            >
              {numeral(data.title).format("0,0 $")}
            </Text>
            </div>:
            <Text
            style={{
              fontSize: "16px",
              color: "#FFFFFF",
              // marginRight: "16px",
              fontWeight: "600",
              width: index === typeTitle.length - 2 ? "80px" : "80px",
              color: index === typeTitle.length - 2 ? "#02AFB8" : "#FFFFFF",
            }}
          >
            {numeral(data.title).format("0,0 $")}
          </Text>

          ))
          : null}

        <ModalIngresos
          key={dataEdit}
          visible={showAddModal || dataEdit || showAddModal === 0}
          onCreate={(event) => handleAdd(event)}
          onCancel={hideAddTaskModal}
          width={800}
          taskToEdit={dataEdit}
          showAddModal={showAddModal}
        />
      </div>
    </>
  );
}

export default TableExpensesComponent;
