import React, { useState, useEffect } from 'react';
import {
	Card,
	Layout,
	Form,
	Row,
	Col,
	Select,
	Input,
	InputNumber,
	Button,
	Modal,
	message,
	Checkbox,
	Typography
} from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import numeral from 'numeral';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import env from '../../environment';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { InputDate, ProvinceSelector } from 'afrodita';
import { notificationGibobs } from './reusableItems';
import DownOutlinedMobile from '../../assets/icons/DownOutlinedMobile.svg';

const { Option } = Select;
const { Text } = Typography;

function MortgageRequest({ ecoBudget }) {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const [property, setProperty] = useState('secondHand');
	const [pageNumber, setPageNumber] = useState(1);
	const userToken = useSelector((state) => state.auth.token);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [bodyRecuestPermision, setBodyRecuestPermision] = useState(undefined);
	const [values, setValues] = useState(['solar', 'aerothermal', 'enclosure']);
	const [ecoHouseValue, setEcoHouseValue] = useState();
	const [valuesForm1, setValuesForm1] = useState();
	const [valuesForm2, setValuesForm2] = useState();
	const [loading, setLoading] = useState(false);

	const [, forceUpdate] = useState({});
	const location = useLocation();

	useEffect(() => {
		forceUpdate({});
	}, []);

	const handleCancel = () => {
		setIsModalVisible(false);
		navigate('/sales-tools/mortgages');
	};

	const handleOk = () => {
		axios
			.post(
				`${env.api.url}/api/clients/request-permission`,
				{
					...bodyRecuestPermision,
					managementCode: 'gibobs',
					simulationId:
						location.state === undefined || location.state === null
							? undefined
							: location.state.result.id
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then(() => {
				navigate('/sales-tools/follow-up');
			})
			.catch(() => {
				message.error(
					'Hay un problema al insertar su solicitud, pongase en contacto con el departamento de Atención al Cliente.'
				);
			});

		setIsModalVisible(false);
	};

	function onChange(checkedValues) {
		console.log('checked = ', checkedValues);
	}

	const onFinishForm1 = (values) => {
		setPageNumber(2);
		setValuesForm1(values);
	};
	const onFinishForm2 = (values) => {
		if (ecoBudget) {
			setPageNumber(3);
			setValuesForm2(values);
		}
		if (!ecoBudget) {
			const newValues = { ...values, ...valuesForm1 };
			onFinish(newValues);
		}
	};
	const onFinishForm3 = (values) => {
		const newValues = { ...values, ...valuesForm2, ...valuesForm1 };
		onFinish(newValues);
	};

	const onFinish = (values) => {
		setLoading(true);

		values.housingDelivery = values.housingDelivery;

		var objNuevo = new Object();

		if (values.maxBudget !== undefined) {
			objNuevo.maxBudget = values.maxBudget;
		}
		if (values.housingDelivery !== undefined) {
			objNuevo.housingDelivery = values.housingDelivery;
		}
		if (values.description !== undefined) {
			objNuevo.description = values.description;
		}
		if (values.interestedGreenMortgage) {
			objNuevo.address = values.address;
			objNuevo.ecoHouseType = values.ecoHouseType;
			objNuevo.floorNumber = values.floorNumber;
			objNuevo.floorSurface = values.floorSurface;
			objNuevo.rooms = values.rooms;
			objNuevo.ecoProjects = values.ecoProjects;
		}
		if (values.description !== undefined) {
			objNuevo.description = values.description;
		}
		if (values.propertyType === 'newConstruction') {
			objNuevo.housingDelivery = values.housingDelivery;
			objNuevo.promotion = values.promotion;
		}

		objNuevo.email = values.email;
		objNuevo.language = values.language;
		objNuevo.mobilePhone = values.mobilePhone;
		objNuevo.name = values.name;
		objNuevo.resident = values.resident;
		objNuevo.surname = values.surname;
		objNuevo.chooseProperty = values.chooseProperty;
		objNuevo.houseType = values.houseType;
		objNuevo.interestedGreenMortgage = false;
		objNuevo.propertyType = values.propertyType;
		objNuevo.province = values.province;

		if (ecoBudget) {
			objNuevo.address = values.address;
			objNuevo.rooms = values.rooms;
			objNuevo.floorNumber = values.floorNumber;
			objNuevo.floorSurface = values.floorSurface;
			objNuevo.ecoHouseType = values.ecoHouseType;
			objNuevo.ecoProjects = values.ecoHouseType === 'piso' ? ['enclosure'] : values.ecoProjects;
			objNuevo.interestedGreenMortgage = true;
		}
		axios
			.post(
				`${env.api.url}/v1/clients/is-recent-client`,
				{ email: valuesForm1.email },
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((responde) => {
				if (responde.data.data === true) {
					setIsModalVisible(true);
					setBodyRecuestPermision(objNuevo);
					setLoading(false);
				}
				if (responde.data.data === false) {
					axios
						.post(
							`${env.api.url}/api/clients/request-permission`,
							{
								...objNuevo,
								managementCode: 'gibobs',
								simulationId:
									location.state === undefined || location.state === null
										? undefined
										: location.state.result.id
							},
							{
								headers: { Authorization: `Bearer ${userToken}` }
							}
						)
						.then(() => {
							Modal.success({
								title: t('form.applyFor'),
								content: (
									<div style={{ marginLeft: -30 }}>
										<p>{t('form.text1')}</p>
										<p>{t('form.text2')}</p>
										<p>{t('form.text3')}</p>
									</div>
								),
								onOk: () => {
									navigate('/sales-tools/follow-up');
								},
								style: { padding: 32 },
								className: 'modal-apply-for-inmo',
								icon: <CheckCircleOutlined />
							});
							setLoading(false);
						})
						.catch(() => {
							message.error(
								'Hay un problema al insertar su solicitud, pongase en contacto con el departamento de Atención al Cliente.'
							);
						});
				}
			});
	};

	const optionsWithDisabled = [
		{
			label: t('form.solarPanels'),
			value: 'solar',
			disabled: ecoHouseValue !== 'piso' ? false : true
		},
		{
			value: 'aerothermal',
			label: t('form.aerothermy'),
			disabled: ecoHouseValue !== 'piso' ? false : true
		},
		{
			value: 'enclosure',
			label: t('form.closed')
		}
	];

	const validateMessages = {
		required: '${label}' + ' ' + t('form.formRequired'),
		types: {
			email: t('form.emailValidated'),
			number: '${label} is not a valid number!'
		},
		number: {
			range: t('form.maxBudgetNewRequestValidated') + '${min}'
		}
	};

	const openNotificationWithIcon = () => {
		notificationGibobs('error', t('m_full.errorTitle'), t('form.incompleteInformation'));
	};

	const formMortgage = (
		<>
			{pageNumber === 1 && (
				<Form
					className={isMobile ? 'gb-form-profile-headlines-mobile' : 'gb-form-common-new-style'}
					onFinishFailed={openNotificationWithIcon}
					scrollToFirstError={true}
					form={form}
					name="nest-messages"
					onFinish={onFinishForm1}
					layout="vertical"
					validateMessages={validateMessages}
					initialValues={{ resident: 'yes', language: 'es' }}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							padding: isMobile && '16px',
							alignItems: 'center',
							marginBottom: !isMobile ? '40px' : '8px'
						}}>
						<div className="typography-titles">
							{ecoBudget ? t('operationTypes.green-mortgage') : t('product.mortgage')}
						</div>
						<div className="typography-blue-text">{t('m_products.basicPersonalDataTitle')}</div>
					</div>
					<Row
						gutter={24}
						style={{
							marginLeft: 'auto',
							marginRight: 'auto'
							// marginBottom: isMobile && "50px",
						}}>
						<Col lg={6} xs={24}>
							<Form.Item
								name="name"
								label={t('form.name')}
								rules={[
									{
										required: true
									}
								]}>
								<Input
									placeholder={t('form.name')}
									style={{
										width: '100%'
									}}
								/>
							</Form.Item>
						</Col>

						<Col lg={6} xs={24}>
							<Form.Item
								name="surname"
								label={t('form.surname')}
								rules={[
									{
										required: true
									}
								]}>
								<Input
									placeholder={t('form.surname')}
									style={{
										width: '100%'
									}}
								/>
							</Form.Item>
						</Col>
						<Col lg={6} xs={24}>
							<Form.Item
								name="email"
								label={t('form.email')}
								rules={[
									{
										required: true,
										type: 'email'
									}
								]}>
								<Input
									placeholder={t('form.email')}
									style={{
										width: '100%'
									}}
								/>
							</Form.Item>
						</Col>

						<Col lg={6} xs={24}>
							<Form.Item
								name="mobilePhone"
								label={t('form.phone')}
								rules={[
									{
										required: true
									}
								]}>
								<Input
									placeholder={t('form.phone')}
									style={{
										width: '100%'
									}}
								/>
							</Form.Item>
						</Col>

						<Col lg={6} xs={24}>
							<Form.Item
								name="resident"
								label={t('form.residentSpain')}
								rules={[
									{
										required: true
									}
								]}>
								<Select
									suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
									placeholder={t('form.residentSpain')}
									style={{
										width: '100%'
									}}>
									<Option key="yes">{t('form.yes')}</Option>
									<Option key="no">{t('form.no')}</Option>
								</Select>
							</Form.Item>
						</Col>

						<Col lg={6} xs={24}>
							<Form.Item
								name="language"
								label={t('form.language')}
								rules={[
									{
										required: true
									}
								]}>
								<Select
									suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
									placeholder={t('form.language')}
									style={{
										width: '100%'
									}}>
									<Option key="es">{t('form.es')}</Option>
									<Option key="en">{t('form.en')}</Option>
									{/* <Option key="cat">{t("form.cat")}</Option> */}
								</Select>
							</Form.Item>
						</Col>
						{isMobile && (
							<Card
								className="cardButtonStyle"
								style={{
									left: 0,
									marginBottom: -2,
									backgroundColor: '#FAFEFE'
								}}>
								<Button
									id="gb-mortgage-buy-situation-mobile"
									className="button-primary-gibobs-inmo"
									type="primary"
									style={{
										fontSize: 16,
										width: '100%',
										height: 48,
										fontWeight: 600
									}}
									htmlType="submit"
									loading={loading}>
									{t('form.next')}
								</Button>
							</Card>
						)}
					</Row>
					{!isMobile && (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								marginTop: 60
							}}>
							<Button
								id="gb-mortgage-buy-situation-mobile"
								className="button-primary-gibobs-inmo"
								type="primary"
								style={{
									fontSize: '14px',
									width: 120,
									height: 40,
									fontWeight: 600
								}}
								htmlType="submit">
								{t('form.next')}
							</Button>
						</div>
					)}
				</Form>
			)}

			{pageNumber === 2 && (
				<Form
					onFinishFailed={openNotificationWithIcon}
					scrollToFirstError={true}
					className={isMobile ? 'gb-form-profile-headlines-mobile' : 'gb-form-common-new-style'}
					form={form}
					name="nest-messages"
					onFinish={onFinishForm2}
					layout="vertical"
					validateMessages={validateMessages}
					initialValues={{
						province:
							location && location.state
								? location.state.result.simulationData.province
								: undefined,
						houseType:
							location && location.state
								? location.state.result.simulationData.houseType
								: undefined,
						maxBudget:
							location && location.state
								? parseInt(location.state.result.simulationData.maxBudget.toFixed(0))
								: undefined,
						propertyType:
							location && location.state
								? location.state.result.simulationData.propertyType
								: undefined
					}}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							padding: isMobile && '16px',
							alignItems: 'center',
							marginBottom: !isMobile ? '40px' : '8px'
						}}>
						<div className="typography-titles">
							{ecoBudget ? t('operationTypes.green-mortgage') : t('product.mortgage')}
						</div>
						<div className="typography-blue-text">{t('form.propertyData')}</div>
					</div>
					<Row
						gutter={24}
						style={{
							marginLeft: 'auto',
							marginRight: 'auto'
							// marginBottom: isMobile && "50px",
						}}>
						<Col lg={6} xs={24}>
							<Form.Item
								label={t('form.province')}
								name="province"
								rules={[
									{
										required: true
									}
								]}>
								<ProvinceSelector placeholder={t('form.province')} style={{ width: '100%' }} />
							</Form.Item>
						</Col>

						<Col lg={6} xs={24}>
							<Form.Item
								label={t('form.houseType')}
								name="houseType"
								rules={[
									{
										required: true
									}
								]}>
								<Select
									suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
									placeholder={t('form.houseType')}
									style={{ width: '100%' }}
									rules={[
										{
											required: true
										}
									]}>
									<Option value="main">{t('form.main')}</Option>
									<Option value="second">{t('form.second')}</Option>
									<Option value="investor">{t('form.investor')}</Option>
									<Option value="both">{t('form.both')}</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col lg={6} xs={24}>
							<Form.Item
								label={t('form.chooseProperty')}
								name="chooseProperty"
								rules={[
									{
										required: true
									}
								]}>
								<Select
									suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
									style={{ width: '100%' }}
									placeholder={t('form.chooseProperty')}>
									<Option value="yes">{t('form.yes')}</Option>
									<Option value="no">{t('form.no')}</Option>
								</Select>
							</Form.Item>
						</Col>

						<Col lg={6} xs={24}>
							<Form.Item
								label={t('form.maxBudgetNewRequest')}
								name="maxBudget"
								rules={[
									{
										type: 'number',
										min: 10000,
										max: 2500000000000000
									}
								]}>
								<InputNumber
									step={100}
									style={{
										borderColor: '#c0daee',
										width: '100%',
										borderRadius: '4px'
									}}
									placeholder={t('form.maxBudgetNewRequest')}
									defaultValue=""
									formatter={(value) => numeral(value).format('0,0 $')}
									parser={(value) => numeral(value).value()}
								/>
							</Form.Item>
						</Col>

						<Col lg={6} xs={24}>
							<Form.Item
								label={t('form.propertyType')}
								name="propertyType"
								rules={[
									{
										required: true
									}
								]}>
								<Select
									suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
									placeholder={t('form.propertyType')}
									style={{ width: '100%' }}
									onChange={(value) => setProperty(value)}>
									<Option value="newConstruction">{t('form.newBuild')}</Option>
									<Option value="secondHand">{t('form.secondHand')}</Option>
								</Select>
							</Form.Item>
						</Col>
						{/* {property === "newConstruction" ? (
              <Col lg={6} xs={24}>
                <Form.Item
                  label={t("promoterLoan.addPromotionImage")}
                  // name="image"
                >
                  <Button
                    className="button-secundary-gibobs-inmo"
                    type="dashed"
                    style={{ width: "100%", height: 40 }}
                    disabled={true}
                  >
                    {t("document.attach")}
                  </Button>
                </Form.Item>
              </Col>
            ) : null} */}

						{property === 'newConstruction' ? (
							<Col lg={6} xs={24}>
								<Form.Item
									name="promotion"
									label={t('form.promotion')}
									rules={[
										{
											required: true
										}
									]}>
									<Input
										placeholder={t('form.promotion')}
										style={{
											width: '100%'
										}}
									/>
								</Form.Item>
							</Col>
						) : null}

						{property === 'newConstruction' ? (
							<Col lg={6} xs={24}>
								<Form.Item
									label={t('form.housingDelivery')}
									name="housingDelivery"
									rules={[
										{
											required: true
										}
									]}>
									<InputDate
										type="date"
										defaultValue={''}
										style={{
											width: '100%'
										}}
									/>
								</Form.Item>
							</Col>
						) : null}

						{/* {ecoBudget ? (
              <Col lg={6} xs={24}>
                <Form.Item
                  name="ecoBudget"
                  label={t("form.ecoBudget")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    placeholder={t("form.ecoBudget")}
                    style={{
                      width: "100%",
                      height: "32px",
                      borderRadius: "4px",
                      borderColor: "#C0DAEE",
                      fontSize: "12px",
                    }}
                  />
                </Form.Item>
              </Col>
            ) : null} */}

						<Col span={24}>
							<Form.Item label={t('form.descriptionInmo')} name="description">
								<TextArea
									style={{ width: '100%', minHeight: 40 }}
									placeholder={t('form.writeYourComments')}
								/>
							</Form.Item>
						</Col>

						{isMobile && (
							<Card
								className="cardButtonStyle"
								style={{
									left: 0,
									marginBottom: -2,
									backgroundColor: '#FAFEFE'
								}}>
								<Button
									id="gb-mortgage-buy-situation-mobile"
									className="button-secundary-gibobs-inmo"
									type="dashed"
									style={{
										fontSize: 16,
										width: '48%',
										height: 48,
										fontWeight: 600,
										marginRight: '10px'
									}}
									onClick={() => setPageNumber(1)}>
									{t('form.previous')}
								</Button>
								<Button
									id="gb-mortgage-buy-situation-mobile"
									className="button-primary-gibobs-inmo"
									type="primary"
									style={{
										fontSize: 16,
										width: '48%',
										height: 48,
										fontWeight: 600
									}}
									htmlType="submit"
									loading={loading}
									disabled={
										form.getFieldValue().length > 12 ||
										!!form.getFieldsError().filter(({ errors }) => errors.length).length
									}>
									{t('form.next')}
								</Button>
							</Card>
						)}
					</Row>
					{!isMobile && (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								marginTop: 60
							}}>
							<Button
								id="gb-mortgage-buy-situation-mobile"
								className="button-secundary-gibobs-inmo"
								type="dashed"
								style={{
									fontSize: '14px',
									width: 120,
									height: 40,
									fontWeight: 600,
									marginRight: '10px'
								}}
								onClick={() => setPageNumber(1)}>
								{t('form.previous')}
							</Button>
							<Button
								id="gb-mortgage-buy-situation-mobile"
								className="button-primary-gibobs-inmo"
								type="primary"
								style={{
									fontSize: '14px',
									width: 120,
									height: 40,
									fontWeight: 600
								}}
								loading={loading}
								htmlType="submit"
								disabled={
									form.getFieldValue().length > 12 ||
									!!form.getFieldsError().filter(({ errors }) => errors.length).length
								}>
								{t('form.next')}
							</Button>
						</div>
					)}
				</Form>
			)}

			{ecoBudget && pageNumber === 3 && (
				<Form
					className={isMobile ? 'gb-form-profile-headlines-mobile' : 'gb-form-common-new-style'}
					onFinishFailed={openNotificationWithIcon}
					scrollToFirstError={true}
					form={form}
					name="nest-messages"
					onFinish={onFinishForm3}
					layout="vertical"
					initialValues={{
						ecoProjects: values
					}}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							padding: isMobile && '16px',
							alignItems: 'center',
							marginBottom: !isMobile ? '40px' : '8px'
						}}>
						<div className="typography-titles">
							{ecoBudget ? t('operationTypes.green-mortgage') : t('product.mortgage')}
						</div>
						<div className="typography-blue-text">
							{isMobile ? t('form.ecostateTitle') : t('m_products.ecostateTitle')}
						</div>
					</div>
					<Row
						gutter={24}
						style={{
							marginLeft: 'auto',
							marginRight: 'auto'
							// marginBottom: isMobile && "150px",
						}}>
						<Col lg={24} xs={24}>
							<Form.Item
								label={
									isMobile ? (
										<div style={{ fontWeight: 600, fontSize: 12, marginBottom: 4 }}>
											{t('form.labelProyectEco').toUpperCase()}
										</div>
									) : (
										t('form.labelProyectEco')
									)
								}
								name="ecoProjects"
								rules={[
									{
										required: true
									}
								]}>
								<Checkbox.Group
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: isMobile && 'column',
										flexWrap: !isMobile && 'wrap',
										justifyContent: !isMobile && 'space-between',
										rowGap: isMobile && 12,
										marginBottom: isMobile && 4,
										backgroundColor: '#FFFFFF'
									}}
									options={optionsWithDisabled}
									onChange={onChange}
									defaultValue={['solar']}
								/>
							</Form.Item>
						</Col>
						<Col lg={6} xs={24}>
							<Form.Item
								label={t('form.totalFloor')}
								name="floorSurface"
								rules={[
									{
										type: 'number',
										min: 0,
										required: true
									}
								]}>
								<InputNumber
									step={100}
									style={{
										width: '100%'
									}}
									placeholder={t('m2')}
									defaultValue=""
								/>
							</Form.Item>
						</Col>

						<Col lg={6} xs={24}>
							<Form.Item
								name="ecoHouseType"
								label={t('budget.typeHome')}
								rules={[
									{
										required: true
									}
								]}>
								<Select
									suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
									style={{ width: '100%' }}
									placeholder={t('budget.typeHome')}
									onChange={(value) => {
										setValues(['enclosure']);
										setEcoHouseValue(value);
									}}>
									<Option value="individual">{t('budget.individual')}</Option>
									<Option value="adosado">{t('budget.townhouse')}</Option>
									<Option value="pareado">{t('budget.couplet')}</Option>
									<Option value="casaRustica">{t('budget.rusticHouse')}</Option>
									<Option value="piso"> {t('budget.house')}</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col lg={6} xs={24}>
							<Form.Item
								name="floorNumber"
								label={t('budget.Nfloor')}
								rules={[
									{
										required: true
									}
								]}>
								<InputNumber
									style={{
										width: '100%'
									}}
									defaultValue={''}
									placeholder={'0'}
								/>
							</Form.Item>
						</Col>
						<Col lg={6} xs={24}>
							<Form.Item
								name="rooms"
								label={t('budget.room')}
								rules={[
									{
										required: true
									}
								]}>
								<InputNumber
									style={{
										width: '100%'
									}}
									defaultValue={''}
									placeholder={'0'}
								/>
							</Form.Item>
						</Col>
						<Col lg={6} xs={24}>
							<Form.Item
								name="address"
								label={t('budget.direction')}
								rules={[
									{
										required: true
									}
								]}>
								<Input
									style={{
										width: '100%'
									}}
									defaultValue={''}
									placeholder={'calle sin numero...'}
								/>
							</Form.Item>
						</Col>
					</Row>

					{isMobile && (
						<Card className="cardButtonStyle" style={{ backgroundColor: '#FAFEFE' }}>
							<Button
								id="gb-mortgage-buy-situation-mobile"
								className="button-secundary-gibobs-inmo"
								type="dashed"
								style={{
									fontSize: 16,
									width: '48%',
									height: 48,
									fontWeight: 600,
									marginRight: '10px'
								}}
								onClick={() => setPageNumber(2)}>
								{t('form.previous')}
							</Button>
							<Button
								id="gb-mortgage-buy-situation-mobile"
								className="button-primary-gibobs-inmo"
								type="primary"
								style={{
									fontSize: 16,
									width: '48%',
									height: 48,
									fontWeight: 600
								}}
								loading={loading}
								htmlType="submit"
								disabled={
									form.getFieldValue().length > 12 ||
									!!form.getFieldsError().filter(({ errors }) => errors.length).length
								}>
								{t('form.next')}
							</Button>
						</Card>
					)}
					{!isMobile && (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								marginTop: 60
							}}>
							<Button
								id="gb-mortgage-buy-situation-mobile"
								className="button-secundary-gibobs-inmo"
								type="dashed"
								style={{
									fontSize: '14px',
									width: 120,
									height: 40,
									fontWeight: 600,
									marginRight: '10px'
								}}
								onClick={() => setPageNumber(2)}>
								{t('form.previous')}
							</Button>
							<Button
								id="gb-mortgage-buy-situation-mobile"
								className="button-primary-gibobs-inmo"
								type="primary"
								style={{
									fontSize: '14px',
									width: 120,
									height: 40,
									fontWeight: 600
								}}
								loading={loading}
								htmlType="submit"
								disabled={
									form.getFieldValue().length > 12 ||
									!!form.getFieldsError().filter(({ errors }) => errors.length).length
								}>
								{t('form.next')}
							</Button>
						</div>
					)}
				</Form>
			)}
		</>
	);

	return (
		<Layout>
			{isModalVisible && (
				<Modal
					className={isMobile ? 'modal-mobile-basic' : 'modal-basic'}
					cancelButtonProps={{
						className: 'button-secundary-gibobs-inmo',
						style: { width: isMobile ? 128 : 136, height: 40 }
					}}
					okButtonProps={{
						className: 'button-primary-gibobs-inmo',
						style: { width: isMobile ? 128 : 136, height: 40 }
					}}
					open={isModalVisible}
					title={t('form.existCliente')}
					okText={t('form.yes')}
					cancelText={t('form.no')}
					width={isMobile ? 328 : 360}
					onCancel={handleCancel}
					onOk={handleOk}>
					{t('form.exist')}
				</Modal>
			)}
			{!isMobile && (
				<Card
					className="CardStyleRealeState"
					style={{
						marginLeft: isMobile ? '16px' : '40px',
						width: isMobile ? 'calc(100% - 32px)' : 'calc(100% - 80px)',
						marginTop: !isMobile && 40
					}}>
					{formMortgage}

					<div style={{ marginTop: 24 }}>
						<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
							{t('inmo.footerText')}
						</Text>
					</div>
				</Card>
			)}
			{isMobile && (
				<>
					{' '}
					<div className="gb-form-inmo-scroll">
						{formMortgage}
						<div style={{ marginTop: 24, marginLeft: 16 }}>
							<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
								{t('inmo.footerText')}
							</Text>
						</div>
					</div>
				</>
			)}
		</Layout>
	);
}

export default MortgageRequest;
