import React from "react";
import { Button, Typography, Modal } from "antd";
import { NavLink } from "react-router-dom";
import Iframe from "react-iframe";

import { useTranslation } from "react-i18next";
import {
  isMobile,
} from "react-device-detect";
const { Text } = Typography;

function WidgetsDashboardSmall({ data, name }) {
  const { t } = useTranslation();


  const handleShown = () => {
    Modal.success({
      content: (
        <Iframe
          width="100%"
          height="500px"
          src={
            "https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/tutorials/tutorial.mp4"
          }
        />
      ),
      width: "100%",
      bodyStyle: { height: "500px" },
    });
  };

  return (
    <>
      {name && name === t("init.getYourBestMortagage") ? (
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
              }}
            >
              <Text
                strong
                style={{ fontSize: "14px", color: "#2F4858", float: "left" }}
              >
                {name}
              </Text>
            </div>

            <div style={{ height: "128px", margin: "auto", marginTop: "30px" }}>
              {data.data.icon}
            </div>
          </div>

          <NavLink to={data.data.url}>
            <Button
              type="primary"
              className="button-primari-gibobs"
              style={{
                fontSize: "14px",
                position: "absolute",
                bottom: "32px",
                right: isMobile ? 130 : "100px",
              }}
            >
              {t("init.simulate")}
            </Button>
          </NavLink>
        </div>
      ) : (
        <div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
              }}
            >
              <Text
                strong
                style={{ fontSize: "14px", color: "#2F4858", float: "left" }}
              >
                {name}
              </Text>
              {name && name !== t("init.getYourBestMortagage") ? (
                <div
                  style={{
                    marginTop: "16px",
                    width: "230px",
                    height: "calc(100% - 32px)",
                    marginLeft:  isMobile ? 30 : undefined
                  }}
                >
                  <Text
                    style={{
                      fontSize: "12px",
                      color: "#748EA0",
                     
                    }}
                  >
                    {t(data.data.text)}
                  </Text>
                </div>
              ) : null}
            </div>
          </div>

          {name && name === t("init.tutorial") ? (
            <div>
              <Button
                onClick={() => handleShown()}
                type="primary"
                className="button-primari-gibobs"
                style={{
                  fontSize: "14px",
                  position: "absolute",
                  bottom: "32px",
                  right:  isMobile ? 120 : "100px",
                }}
              >
                {t("init.consult")}
              </Button>
            </div>
          ) : null}
          {name && name !== t("init.tutorial") ? (
            <NavLink to={data.data.url}>
              <Button
                type="primary"
                className="button-primari-gibobs"
                style={{
                  fontSize: "14px",
                  position: "absolute",
                  bottom: "32px",
                  right: "100px",
                }}
              >
                {t("init.consult")}
              </Button>
            </NavLink>
          ) : null}
        </div>
      )}
    </>
  );
}

export default WidgetsDashboardSmall;
