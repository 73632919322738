import {
  DATASUMMARY,
  DATAPROFILE,
  SHOWPAY,
  PAYEND,
  CREATEASSET,
  GETASSET,
  RENAMEASSET,
  DELETEASSET,
  ECOBUDGET,
  MORTGAGEGREEN,
  SUBROGATIONGREEN,
  RENAMESEAT,
  DELETESEAT,
  UPDATESEAT,
  CREATESEAT,
  GETSEATS,
  MAXBUDGETGREEN,
  GETSCORING,
  COLLAPSEMENU,
  MODELIST,
  RENAMENAMESEAT,
  CHANGEPAGE,
  SIMULATELTV
} from "./constants";

export const INITIAL_STATE = {
  dataSummary: [],
  dataProfile: [],
  showPay: false,
  payEnd: false,
  assetList: [],
  ecoBudget: 0,
  mortgageGreen: false,
  subrogationGreen: false,
  getSeats: [],
  maxbudgetGreen: 0,
  getScoring: [],
  collapseMenu: false,
  modeList: false,
  changePage: true,
  SIMULATELTV: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DATASUMMARY:
      return { ...state, dataSummary: action.payload };
    case DATAPROFILE:
      return { ...state, dataProfile: action.payload };
    case SHOWPAY:
      return { ...state, showPay: action.payload };
    case PAYEND:
      return { ...state, payEnd: action.payload };
    case CREATEASSET:
      return { ...state, createAsset: action.payload };
    case GETASSET:
      return { ...state, assetList: action.payload };
    case RENAMEASSET:
      return { ...state, renameAsset: action.payload };
    case DELETEASSET:
      return { ...state, deleteAsset: action.payload };
    case ECOBUDGET:
      return { ...state, ecoBudget: action.payload };
    case MORTGAGEGREEN:
      return { ...state, mortgageGreen: action.payload };
    case SUBROGATIONGREEN:
      return { ...state, subrogationGreen: action.payload };
    case RENAMESEAT:
      return { ...state, renameSeat: action.payload };
    case RENAMENAMESEAT:
      return { ...state, renameNameSeat: action.payload };
    case CREATESEAT:
      return { ...state, createSeat: action.payload };
    case GETSEATS:
      return { ...state, getSeats: action.payload };
    case DELETESEAT:
      return { ...state, deleteSeat: action.payload };
    case UPDATESEAT:
      return { ...state, updateSeat: action.payload };
    case MAXBUDGETGREEN:
      return { ...state, maxbudgetGreen: action.payload };
    case GETSCORING:
      return { ...state, getScoring: action.payload };
    case COLLAPSEMENU:
      return { ...state, collapseMenu: action.payload };
    case MODELIST:
      return { ...state, modeList: action.payload };
    case CHANGEPAGE:
      return { ...state, changePage: action.payload };
    case SIMULATELTV:
        return { ...state, simulateLtv: action.payload };
    default:
      return state;
  }
};
