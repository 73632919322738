import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Form, Select, Tabs, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { renderComponent } from '../../../select/typeSelect';
import axios from 'axios';
import env from '../../../environment';
import { isMobileOnly } from 'react-device-detect';
import _ from 'lodash';

const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const CalculatorDataClientQualify = ({
	setResult,
	valuesForm,
	family,
	fixed,
	quotes,
	houseType,
	result,
	setPrice,
	priceTouch
}) => {
	const location = useLocation();
	const { t } = useTranslation();
	const rol = useSelector((state) => state.auth.roles);
	const navigate = useNavigate();;
	const [form] = Form.useForm();
	const [countries, setContries] = useState();
	const [participants, setParticipants] = useState(undefined);
	const lang = localStorage.getItem('i18nextLng');
	const [result2, setResult2] = useState(undefined);
	const [valuesForm2, setValuesForm2] = useState(undefined);
	const [timeoutReference, setTimeoutReference] = useState(undefined);

	const userToken = useSelector((state) => state.auth.token);

	useEffect(() => {
		axios.get(`${env.api.url}/v1/countries`).then((response) => {
			setContries(response.data.data);
		});
		if (result2) {
			// getData(result2)
		}
	}, []);

	

	const changeParticipants = (value) => {
		setParticipants(value);
	};

	const participantsTabs = [];

	const renderTabs = (index) => {
		return (
			<TabPane tab={<span>{t(`onboarding.owner${index}`)}</span>} key={`${index}`}>
				{renderForm(index)}
			</TabPane>
		);
	};

	const renderForm = (data) => {
		return (
			<>
				<Row style={{ width: '100%' }}>
					<Col span={12} style={{ marginTop: 6 }}>
						<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
							{t('form.activity')}
						</Text>
					</Col>
					<Col span={12}>
						<Form.Item
							rules={[
								{
									required: true
								}
							]}
							name={`activity${data}`}
							style={{ width: '100%' }}>
							<Select id={`activity${data}`} placeholder={t('form.activity')}>
								{renderComponent('activity2').options.map((situation) => {
									return (
										<Option key={situation.value} value={situation.value}>
											{t(situation.label)}
										</Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row style={{ width: '100%' }}>
					<Col span={12} style={{ marginTop: 6 }}>
						<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
							{t('form.seniority')}
						</Text>
					</Col>
					<Col span={12}>
						<Form.Item
							rules={[
								{
									required: true,
									message: t('calculator.fillInInput')
								}
							]}
							name={`seniority${data}`}>
							<InputNumber
								className='gb-input-new-years'
								max={40}
								min={0}
								step={1}
								id={`seniority${data}`}
								placeholder={t('0')}
								style={{
									width: '100%'
								}}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Row style={{ width: '100%' }}>
					<Col span={12} style={{ marginTop: 6 }}>
						<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
							{t('form.netIncomesOwnerInmo')}
						</Text>
					</Col>
					<Col span={12}>
						<Form.Item
							rules={[
								{
									required: true,
									message: t('calculator.fillInInput')
								}
							]}
							name={`netIncomes${data}`}>
							<InputNumber
								id={`netIncomes${data}`}
								addonAfter={'€/' + t('viability.monthsEstimated')}
								step={100}
								placeholder={t('1000')}
								min={0}
								max={99999}
								style={{
									borderColor: '#c0daee',
									width: '100%',
									borderRadius: '4px'
								}}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Row style={{ width: '100%' }}>
					<Col span={12} style={{ marginTop: 6 }}>
						<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
							{t('calculator.haveLoansInmo')}
						</Text>
						<div
							className="explination-others-incomes"
							style={{
								color: '#748EA0',
								fontWeight: 400,
								fontSize: 10,
								marginTop: 10
							}}>
							{t('form.haveLoansInmo0')}
						</div>
					</Col>
					<Col span={12}>
						<Form.Item
							rules={[
								{
									required: true,
									message: t('calculator.fillInInput')
								}
							]}
							name={`loanPayment${data}`}>
							<InputNumber
								id={`loanPayment${data}`}
								addonAfter={'€/' + t('viability.monthsEstimated')}
								step={100}
								placeholder={t('0')}
								style={{
									borderColor: '#c0daee',
									width: '100%',
									borderRadius: '4px'
								}}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Row style={{ width: '100%' }}>
					<Col span={12} style={{ marginTop: 6 }}>
						<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
							{t('form.howOldAreYou')}
						</Text>
					</Col>
					<Col span={12}>
						<Form.Item
							rules={[
								{
									required: true,
									message: t('calculator.fillInInput')
								}
							]}
							name={`age${data}`}>
							<InputNumber
								id={`age${data}`}
								className='gb-input-new-years'
								step={1}
								min={18}
								max={74}
								placeholder={t('32')}
								style={{
									borderColor: '#c0daee',
									width: '100%',
									borderRadius: '4px'
								}}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Row style={{ width: '100%' }}>
					<Col span={12} style={{ marginTop: 6 }}>
						<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
							{t('form.country')}
						</Text>
					</Col>
					<Col span={12}>
						<Form.Item
							rules={[
								{
									required: true
								}
							]}
							name={`cityzenship${data}`}>
							<Select
								id="country"
								showSearch
								placeholder={t('form.country')}
								style={{ width: '100%' }}
								defaultValue={'ES'}
								filterOption={(input, option) => {
									return (
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
										option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
									);
								}}>
								{countries &&
									countries.countries.map((paises) => {
										return (
											<Option key={paises.code} value={paises.code}>
												{lang === 'en' ? paises.name_en : paises.name_es}
											</Option>
										);
									})}
							</Select>
						</Form.Item>
					</Col>
				</Row>
			</>
		);
	};

	for (let i = 1; i <= parseInt(participants); i++) {
		participantsTabs.push(renderTabs(i));
	}

	const onChangeForm = (values, allValues) => {
		let owners = [];
		if (allValues.cityzenship1 === undefined) {
			allValues.cityzenship1 = 'ES';
		}
		if (allValues.cityzenship2 === undefined) {
			allValues.cityzenship2 = 'ES';
		}

		setValuesForm2(allValues);

		if (allValues.participants === '2') {
			
			navigate(`/calculators/qualify/client`,{
				state: {
					result: typeof result !== 'string' ? result : location.state,
					formValues:
						form.isFieldTouched('seniority2') ||
						form.isFieldTouched('age2') ||
						form.isFieldTouched('loanPayment2') ||
						form.isFieldTouched('netIncomes2') ||
						form.isFieldTouched('activity2') ||
						form.isFieldTouched('cityzenship2')
							? allValues
							: _.isNil(location.state)
							? allValues
							: location.state.formValues
				}
			});

			owners = [
				{
					activity: allValues.activity1,
					seniority: allValues.seniority1 !== null ? allValues.seniority1 : 0,
					netIncomes: allValues.netIncomes1 !== null ? allValues.netIncomes1 : 0,
					loanPayment: allValues.loanPayment1 !== null ? allValues.loanPayment1 : 0,
					age: allValues.age1 !== null ? allValues.age1 : 0,
					cityzenship: _.isNil(allValues.cityzenship1) ? 'ES' : allValues.cityzenship1
				},
				{
					activity: !_.isNil(allValues.activity2)
						? allValues.activity2
						: location?.state?.formValues?.activity2,
					seniority: !_.isNil(allValues.seniority2)
						? allValues.seniority2
						: location?.state?.formValues?.seniority2,
					netIncomes: !_.isNil(allValues.netIncomes2)
						? allValues.netIncomes2
						: location?.state?.formValues?.netIncomes2,
					loanPayment: !_.isNil(allValues.loanPayment2)
						? allValues.loanPayment2
						: location?.state?.formValues?.loanPayment2,
					age: !_.isNil(allValues.age2) ? allValues.age2 : location?.state?.formValues?.age2,
					cityzenship: _.isNil(allValues.cityzenship2) ? 'ES' : allValues.cityzenship2
				}
			];
		} else {
			owners = [
				{
					activity: allValues.activity1,
					seniority: allValues.seniority1 !== null ? allValues.seniority1 : 0,
					netIncomes: allValues.netIncomes1 !== null ? allValues.netIncomes1 : 0,
					loanPayment: allValues.loanPayment1 !== null ? allValues.loanPayment1 : 0,
					age: allValues.age1 !== null ? allValues.age1 : 0,
					cityzenship: _.isNil(allValues.cityzenship1) ? 'ES' : allValues.cityzenship1
				}
			];
		}

		if (timeoutReference) {
			clearTimeout(timeoutReference);
		}

		setTimeoutReference(
			setTimeout(() => {
				axios
					.post(
						`${env.api.url}/mortgages/simulations/client-qualification/mortgage-viability`,
						{
							id: !_.isNil(result2) ? result2 : undefined,
							simulationType: rol === 'realestate' ? "B2B" : "B2C",
							savings: !_.isNil(valuesForm.savings) ? valuesForm.savings : 50000,
							propertyType: valuesForm.propertyType,
							numerousFamily: family,
							province: valuesForm.province,
							maxBudget: !_.isNil(priceTouch) && priceTouch > 10000 ? priceTouch : undefined,
							fixed: !_.isNil(values.fixed) ? values.fixed : fixed,
							quotes: !_.isNil(values.quotes) ? values.quotes : quotes,
							participants: {
								houseType: !_.isNil(valuesForm.houseType) ? valuesForm.houseType : houseType,
								participants: parseInt(allValues.participants),
								owners: owners
							}
						},
						{
							headers: { Authorization: `Bearer ${userToken}` }
						}
					)
					.then((response) => {
						setResult(response.data.data);
						setPrice(response.data.data.bonilla[1]?.maxBudget);
						if (response.data.data.simulation) {
							setResult2(response.data.data.simulation.id);
						}
						if (typeof response.data.data === 'string') {
							setPrice(undefined);
						}
					})
					.catch((error) => {
						console.error(error);
					});

				clearTimeout(timeoutReference);
			}, 500)
		);
	};

	return (
		<>
			<Form
				className="gb-form-profile-headlines-mobile"
				scrollToFirstError={true}
				form={form}
				name="basic"
				onValuesChange={
					isMobileOnly
						? (values, allValues) => setResult(allValues)
						: (values, allValues) => onChangeForm(values, allValues)
				}
				initialValues={{ ...valuesForm2 }}>
				<Row style={{ width: '100%' }}>
					<Col span={12} style={{ marginTop: 6 }}>
						<div style={{ width: 150 }}>
							<Text
								style={{
									color: '#2F4858',
									fontSize: 14,
									fontWeight: 400
								}}>
								{rol === 'realestate'
									? t('calculator.viabilityAlone2')
									: t('calculator.viabilityAlone')}
							</Text>
						</div>
					</Col>
					<Col span={12}>
						<Form.Item
							rules={[
								{
									required: true
								}
							]}
							name="participants">
							<Select
								placeholder={t('loan.sincronizationChosse')}
								style={{ width: '100%', height: 40 }}
								onChange={(value) => {
									changeParticipants(value);
								}}>
								<Option value="1">{t('calculator.formAlone')}</Option>
								<Option value="2">{t('calculator.formAccompanied')}</Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>

				{participants === '2' && (
					<Tabs defaultActiveKey="1" centered>
						{participantsTabs}
					</Tabs>
				)}
				{participants === '1' && (
					<Row gutter={24} style={{ paddingLeft: 16, paddingTop: 16, paddingRight: 16 }}>
						{renderForm('1')}
					</Row>
				)}
			</Form>
		</>
	);
};

export default CalculatorDataClientQualify;
