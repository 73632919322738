import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import LoanComparativePageNoDataMobile from './LoansMobile/LoanComparativePageNoDataMobile';
import LoanComparativePageNoDataDesktop from './LoansDesktop/LoanComparativePageNoDataDesktop';

const LoanComparativeSwitchPCorMobile = () => {
	return (
		<>{isMobileOnly ? <LoanComparativePageNoDataMobile /> : <LoanComparativePageNoDataDesktop />}</>
	);
};

export default LoanComparativeSwitchPCorMobile;
