import { useState, useEffect } from 'react';
import { Progress } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';

type Props = {
	env: any;
	steps: any;
	stage: any;
	platform: string;
};

export type ReduxStore = {
	auth: StoreAuth;
};

export type StoreAuth = {
	token: string;
	roles: string;
	operations: any;
};

const ProgressBySteps = ({ steps, stage, env , platform}: Props) => {
	const { t } = useTranslation();
	const userToken = useSelector<ReduxStore, string>((state) => state.auth.token);
	const [colorNif, setColorNif] = useState( platform === "gibobs" ? '#748EA0' : '#006DFF');
	const [colorOfficialDocuments, setColorOfficialDocuments] = useState(platform === "gibobs" ? '#748EA0' : '#006DFF');
	const { operationId, owner } = useParams<any>();

	useEffect(() => {
		getDocuments();
	}, [stage]);

	useEffect(() => {
		if ( platform === "gibobs") {
			setColorNif('#748EA0');
			setColorOfficialDocuments('#748EA0');
		}else {
			setColorNif('#006DFF');
			setColorOfficialDocuments('#006DFF');
		}
	
		
	}, [owner]);

	const getDocuments = async () => {
		const response = await axios.get(
			`${env.api.url}/v1/documents/get-operation-documents/${operationId}`,
			{
				headers: { Authorization: `Bearer ${userToken}` }
			}
		);
		let nifData = [];
		nifData = response.data.data.filter((item: any) => item.type === 'nif' && item.owner === owner);
		if (nifData.length !== 0) {
			setColorNif('#52C41A');
		}
		let officialDocuments = [];
		officialDocuments = response.data.data.filter(
			(item: any) =>
				(item.type === 'laboralLife' ||
					item.type === 'contributionBase' ||
					item.type === 'paymentsAEAT' ||
					item.type === 'tgss') &&
				item.owner === owner
		);
		if (officialDocuments.length !== 0) {
			setColorOfficialDocuments('#52C41A');
		}
	};

	const nameStages:any = {
		1: t('doc.differentStagesAutomatic'),
		2: t('doc.differentStages2Automatic'),
		3: t('doc.differentStages3Automatic')
	};

	return (
		<div className="Progress-by-steps" style={{  marginTop: !isMobileOnly && platform === 'gibobs' && -35 }}>
			<div className="steps-and-title">
				<div style={{ display: 'flex' }}>
					<div className="steps-and-title-text">
						{t('doc.stagesDocumentation')} {stage}:
					</div>
					<div
						className="steps-and-title-text"
						style={{
							marginLeft: 4
						}}>
						{nameStages[stage]}
					</div>
				</div>
				<Progress
					className={'steps-mobile'}
					percent={(stage * 100) / steps}
					steps={steps}
					showInfo={false}
					strokeColor={[
						stage !== 1 ? colorNif : platform !== "gibobs" ? '#006DFF' : '#02C3CD',
						stage !== 2 ? colorOfficialDocuments :  platform !== "gibobs" ? '#006DFF' : '#02C3CD',
						platform !== "gibobs" ? '#006DFF' : '#02C3CD'
					]}
					trailColor={ platform === "gibobs" ? '#C0F0F3' : "white"}
					strokeWidth={4}
				/>
			</div>
		</div>
	);
};

export default ProgressBySteps;
