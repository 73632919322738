import React from "react";
import { Pie } from "ant-design-pro/lib/Charts";
import "ant-design-pro/dist/ant-design-pro.css";
import { useTranslation } from "react-i18next";

function PieClosed({ gibobs, banks, others }) {
  const { t } = useTranslation();
  const salesPieData = [
    {
      x: t("plainFriend.signed"),
      y: gibobs,
    },
    {
      x: t("form.signedBank"),
      y: banks,
    },
    {
      x: t("form.others"),
      y: others,
    },
  ];
  return (
    <Pie
      colors={["#02C3CD", "#2F4858", "#748EA0"]}
      hasLegend="true"
      data={salesPieData}
      valueFormat={(val) => <span>{val}</span>}
      height={180}
      style={{
        display: "grid",
        gridTemplateColumns: "0.5fr 5fr",
        width: "500px",
        margin: "20px auto 0px",
      }}
    />
  );
}

export default PieClosed;
