import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import env from '../../../../environment';
import axios from 'axios';
import StepsHorizontal from './stepsHorizontal';
import { LoadingOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { Spin } from 'antd';

const BanksComponent = ({ userMortgageId, stages, width }) => {
	const { t } = useTranslation();
	const [operationBanksRequest, setOperationBanksRequest] = useState([]);
	const [misBanks, setMisBanks] = useState([]);
	const userToken = useSelector((state) => state.auth.token);
	const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

	useEffect(() => {
		dataBanks();
		getAccounts();
	}, []);

	const dataBanks = () => {
		axios
			.get(`${env.api.url}/v1/banks/get-operation-bank-requests/${userMortgageId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setOperationBanksRequest(response.data.data);
			});
	};

	const getAccounts = async () => {
		const response = await axios.get(`${env.api.url}/v1/banks/get-banks`, {
			headers: { Authorization: `Bearer ${userToken}` }
		});
		const banks = response.data.data;
		await getBanksClient(banks);
	};

	const getBanksClient = async (banks) => {
		axios
			.get(`${env.api.url}/v1/mortgages/data/get/${userMortgageId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				const ownBanks = response.data.data.mortgage.ownBanks;
				const clientBanks = ownBanks
					? ownBanks.map((item) => banks.find((bItem) => bItem.id === item).bankCode)
					: [];
				setMisBanks(clientBanks);
			});
	};

	const selectImagebank = (bankCode) => {
		return (
			<div style={{ width: 'auto', height: 32 }}>
				<img
					src={
						'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/banks/' +
						`${bankCode}` +
						'.png'
					}
					alt={bankCode}
					style={{ height: '100%', width: '100%' }}
				/>
			</div>
		);
	};
	return (
		<>
			{operationBanksRequest ? (
				<div>
					{misBanks.length !== 0 && stages !== 'oferts' && (
						<div
							style={{
								display: 'flex',
								textAlign: 'center',
								marginBottom: 40,
								alignItems: 'center',
								flexDirection: isMobile ? 'column' : 'row'
							}}>
							<div
								className="typography-titles"
								style={{
									marginBottom: isMobile && 16,
									fontSize: 14,
									marginRight: 24
								}}>
								{t('form.blockedBanks')}
							</div>
							{misBanks.length !== 0 && stages !== 'oferts' && (
								<div
									style={{
										display: 'flex',
										flexWrap: 'wrap',
										rowGap: 16,
										columnGap: 16,
										justifyContent: isMobile && 'center'
									}}>
									{misBanks &&
										misBanks.map((banks) => {
											return selectImagebank(banks);
										})}
								</div>
							)}
						</div>
					)}

					<div
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							marginBottom: 40,
							columnGap: !isMobile && 64,
							rowGap: isMobile ? 24 : 32
						}}>
						{operationBanksRequest &&
							operationBanksRequest.map((infoBanks, i) => {
								return (
									<div>
										<div
											className="typography-titles"
											style={{
												marginBottom: 16,
												fontSize: 14,
												textAlign: 'center',
												marginLeft: -25
											}}>
											{t('appraisal.entity')} {i + 1}
										</div>
										<StepsHorizontal
											data={[
												{
													name: t('banks.send'),
													date: infoBanks.createdAt && infoBanks.createdAt
												},
												{
													name: t('banks.assigned'),
													date: infoBanks.createdAt && infoBanks.assignDate
												},
												{
													name: t('banks.approved'),
													date: infoBanks.createdAt && infoBanks.responseDate
												}
											]}
											status={
												infoBanks.status === 'send'
													? 1
													: infoBanks.status === 'approved'
													? 3
													: infoBanks.status === 'assigned'
													? 2
													: infoBanks.status === 'deny'
													? 3
													: 0
											}
											width={width}
											denied={infoBanks.status === 'deny' && infoBanks.status === 'deny'}
											approved={infoBanks.status === 'approved' && infoBanks.status === 'approved'}
											index={i}
										/>
									</div>
								);
							})}
					</div>
				</div>
			) : (
				<Spin indicator={antIcon} className="spin" />
			)}
		</>
	);
};

export default BanksComponent;
