import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { createHash } from 'crypto-browserify';

const trace = ({
	location,
	userToken,
	rol,
	stage,
	operationName,
	operationSubtype,
	modality,
	extra,
	viability,
	amountMortgage,
	incomes,
	maxBudget
}) => {
	const decoded = jwt_decode(userToken);

	const data = {
		userId: decoded.id,
		hashEmail: createHash('sha256')
			.update(decoded.email || '')
			.digest('hex'),
		hashPhone: createHash('sha256')
			.update(decoded.phone || '')
			.digest('hex'),
		userType: rol
	};

	const path = location.pathname;

	if (path.indexOf('/documents') !== -1) {
		data.process = 'documents';
	} else if (path.indexOf('/login') !== -1) {
		data.process = 'login';
	} else if (path.indexOf('/my-account') !== -1) {
		data.process = 'account';
	} else if (path.indexOf('/products') !== -1) {
		data.process = 'products';
	} else if (path.indexOf('/help') !== -1) {
		data.process = 'help';
	} else if (path.indexOf('/contact') !== -1) {
		data.process = 'contact';
	} else if (path.indexOf('/solar-value-home') !== -1) {
		data.process = 'solar-value-home';
	} else if (path.indexOf('/my-financial-profile') !== -1) {
		data.process = 'finance';
	} else if (path.indexOf('/clients-operations') !== -1) {
		data.process = 'clients-operations';
	} else if (path.indexOf('/sales-tools/follow-up') !== -1) {
		data.process = 'follow-up';
	} else if (path.indexOf('/sales-tools/follow-up') !== -1) {
		data.process = 'follow-up';
	} else if (path.indexOf('/sales-tools/new-requests/pre-certified') !== -1) {
		data.process = 'sales-precertified';
	} else if (path.indexOf('/sales-tools/new-requests/solar-value-home') !== -1) {
		data.process = 'solar-value-homes';
	} else if (path.indexOf('/sales-tools/mortgagesGreen') !== -1) {
		data.process = 'mortgage-green';
	} else if (path.indexOf('/sales-tools/mortgages') !== -1) {
		data.process = 'mortgages';
	} else if (path.indexOf('/products-business') !== -1) {
		data.process = 'products-business';
	} else if (path.indexOf('/operations/') !== -1) {
		data.process = 'operation';
	}

	const eventStage = {
		basic: 'FSH_01_basic_01_data_form',
		basic_02_OK: 'FSH_01_basic_02_simulation_ok',
		basic_02_fail: 'FSH_01_basic_02_simulation_fail',
		full_01_service_mode_selection: 'FSH_02_full_01_service_mode_selection',
		full_02_card_payment: 'FSH_02_full_02_card_payment',
		full_03_holders_form: 'FSH_02_full_03_holders_form',
		documents: 'FSH_03_documents',
		analysis: 'FSH_04_analysis',
		banks: 'FSH_05_banks',
		oferts: 'FSH_06_offers',
		appraisal: 'FSH_07_appraisal',
		formalization: 'FSH_08_formalization'
	};

	if (stage) {
		data.event = eventStage[stage];
	}

	if (operationName) {
		data.operationName = operationName;
	}

	data.operationType = 'mortgage';

	if (operationSubtype) {
		data.operationSubtype = operationSubtype;
	}
	if (modality) {
		data.modality = modality;
	}

	if (extra) {
		data.extra = extra;
	}

	if (viability !== undefined) {
		data.viability = viability;
	}
	if (amountMortgage) {
		data.amountMortgage = amountMortgage;
	}

	if (incomes) {
		data.incomes = incomes;
	}

	if (maxBudget) {
		data.maxBudget = maxBudget;
	}

	window.dataLayer.push(data);
};

const purchase = ({ userToken, purchase }) => {
	const decoded = jwt_decode(userToken);

	const data = {
		event: 'purchase_confirmation',
		quant_order_id: createHash('sha256').update(decoded.email).digest('hex'),
		purchase_revenue: purchase
	};

	window.dataLayer.push(data);
};

const optimize = () => {
	const data = {
		event: 'optimize.activate'
	};

	window.dataLayer.push(data);
};

const TagManager = () => {
	const location = useLocation();
	const userToken = useSelector((state) => state.auth.token);
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const rol = useSelector((state) => state.auth.roles);

	useEffect(() => {
		if (isAuthenticated) {
			trace({ location, userToken, rol });

			const decoded = jwt_decode(userToken);

			const analyticsTrace = {
				event: 'pageview',
				userId: decoded.id,
				hashEmail: createHash('sha256')
					.update(decoded.email || '')
					.digest('hex'),
				hashPhone: createHash('sha256')
					.update(decoded.phone || '')
					.digest('hex'),
				userType: rol
			};

			if (decoded.email === 'juan.benavides@gibobs.com') {
				console.log('tagmanager', analyticsTrace);
			}

			window.dataLayer.push(analyticsTrace);
		}
	}, [location, isAuthenticated, userToken, rol]);

	return <></>;
};

export default TagManager;

export { trace, purchase, optimize };
