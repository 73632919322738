import { Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useParams } from 'react-router-dom';

const { Step } = Steps;

function StagesMortgages({ data }) {
	const { t } = useTranslation();
	let { stage } = useParams();

	const stages = {
		simulate: 0,
		documents: 1,
		oferts: 2,
		appraisal: 3,
		formalization: 4
	};

	const renderStages = () => {
		if (stage === 'banks' || stage === 'oferts') {
			return stages['oferts'];
		} else {
			if (stage === undefined) {
				return stages[data.data.stage];
			} else {
				if (stage === 'analysis') {
					return stages['documents'];
				}else {
					return stages[`${stage}`];
				}
				
			}
		}
	};

	return (
		<div
			className="barraStyle2"
			style={{
				marginTop: isMobile ? '16px' : '32px',
				marginLeft: isMobileOnly ? 0 : undefined,
				textAlign: 'center',
				marginBottom: -65
			}}>
			<div
				style={{
					textAlign: 'center',
					display: 'inline-block',
					marginBottom: 16
				}}>
				<Steps
					className="steps-new-style-documents"
					direction="vertical"
					size="small"
					icon="none"
					current={renderStages()}>
					<Step title={t('step.simulationTitle')} style={{ color: '#2F4858' }} />

					<Step
						title={t('document.documentation')}
						description={(stage === 'documents' ||  stage === 'analysis') && t('document.documentationDescr')}
					/>

					<Step
						title={t('step.ofertsCustomer')}
						description={stage === 'oferts' && t('onboarding.selectOffer')}
					/>
					<Step
						title={t('stages.appraisal')}
						description={stage === 'appraisal' && t('onboarding.processAppraisal')}
					/>
					<Step
						title={t('stages.formalization')}
						description={stage === 'formalization' && t('onboarding.checkTheDocuments')}
					/>
				</Steps>
			</div>
		</div>
	);
}

export default StagesMortgages;
