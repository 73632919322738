import React from "react";
import { Form, Modal, Input, Col, Row } from "antd";
import { useTranslation } from "react-i18next";

const ContactoPage = ({ visible, onCancel, onCreate }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  return (
    (<Modal
      className="ModalCommon"
      width={360}
      cancelButtonProps={{
        className: "button-secundary-gibobs",
        style: { width: 136 },
      }}
      okButtonProps={{
        className: "button-primari-gibobs",
        style: { width: 136 },
      }}
      open={visible}
      title={t("form.changePassword")}
      okText={t("scoring.update")}
      cancelText={t("offers.cancel")}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
            form.resetFields();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="Password"
        initialValues={{
          confirmPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="confirmPassword"
              label={t("m_profile.actualPassword")}
            >
              <Input.Password
                className="InputPassWord"
                type="password"
                placeholder={t("m_profile.actualPassword")}
                style={{ width: "280px" }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="newPassword" label={t("m_profile.newPassword")}>
              <Input.Password
                className="InputPassWord"
                type="password"
                placeholder={t("m_profile.newPassword")}
                style={{ width: "280px" }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="confirmNewPassword"
              label={t("m_profile.confirmNewPassword")}
            >
              <Input.Password
                className="InputPassWord"
                type="password"
                placeholder={t("m_profile.confirmNewPassword")}
                style={{ width: "280px" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>)
  );
};

export default ContactoPage;
