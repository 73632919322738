import React, { useState, useEffect } from "react";
import { Typography, Card, Row, Col, Spin } from "antd";
import { useTranslation } from "react-i18next";
import env from "../../../../environment";
import { useSelector } from "react-redux";
import axios from "axios";
import StepsNewStyle from "./StepsNewStyle";
import CardInformationInfo from "../CardInformationInfo";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import CardInformationMobile from "../CardInformationMobile";
import DocumentMobileComponent from "./DocumentComponent";
import BanksComponent from "./BanksComponent";
import BreadcrumbsInmo from "./BreadcrumbsInmo";
import StepsHorizontal from "./stepsHorizontal";
import FormalizationComponent from "./FormalizationComponent";
import moment from "moment";
import AgentInformation from "./AgentInformation";
import { LoadingOutlined } from "@ant-design/icons";

const { Text } = Typography;

function FollowUpOperations({
  stages,
  dataMortgage,
  userMortgageId,
  nameHP,
  status,
  snooze,
  statusOperation,
}) {
  const { t } = useTranslation();
  let { type } = useParams();

  const [valuesData, setValuesData] = useState([]);
  const [viabilityData, setViabilityData] = useState();
  const [infoAgents, setInfoAgents] = useState();
  const userToken = useSelector((state) => state.auth.token);
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;


  useEffect(() => {
    dataValueInfoUpload();
    getAgentsOpertions();
  }, []);

  const dataValueInfoUpload = () => {
    axios
      .get(`${env.api.url}/v1/operations/b2b-resume/${userMortgageId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setValuesData(response.data.data);
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const getViabilityData = () => {
    axios
      .post(`${env.api.url}/mortgages/conventional-viability-mortgage/get-viability/${userMortgageId}`,{}, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setViabilityData(response.data.data);
      });
  };

  const getAgentsOpertions = () => {
    axios
      .get(`${env.api.url}/v1/mortgages/agents/${userMortgageId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setInfoAgents(response.data.data);
      });
  };

  useEffect(() => {
    getViabilityData();
  }, []);

  const dataSteps = [
    { title: "Stages.basic", info: "details.info", numStages: 0 },
    { title: "stages.profile", info: "details.fullMobile", numStages: 1 },
    {
      title: "document.documentation",
      info: "details.documentsMobile",
      numStages: 2,
    },
    { title: "stages.analysisStep", info: "details.analysis", numStages: 3 },
    { title: "document.bank", info: "details.banks", numStages: 4 },
    { title: "offers.offert", info: "m_temporalLine.oferts", numStages: 5 },
    {
      title: "stages.appraisal",
      info: "details.appraisalMobile",
      numStages: 6,
    },
    {
      title: "stages.formalization",
      info: "m_temporalLine.formalization",
      numStages: 7,
    },
  ];

  const stagesName = {
    basic: 0,
    full: 1,
    documents: 2,
    analysis: 3,
    banks: 4,
    oferts: 5,
    appraisal: 6,
    formalization: 7,
    finish: 8,
  };

  const ownerList = [];

  if (
    dataMortgage &&
    dataMortgage.mortgage &&
    dataMortgage.mortgage.participants
  ) {
    for (let i = 1; i <= Number(dataMortgage.mortgage.participants); i++) {
      ownerList.push(`owner${i}`);
    }

    for (let i = 0; i < Number(dataMortgage.mortgage.guarantors); i++) {
      ownerList.push(`guarantor${i + 1}`);
    }
  }

  const postponeNotification = (
    <div
      style={{
        background: "#FEF0D3",
        borderRadius: "4px",
        padding: "24px",
        marginTop: isMobile ? 16 : 32,
      }}
    >
      <div
        style={{
          color: "#FAAD14",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: 600,
        }}
      >
        {t("form.postponedUntil")}{" "}
        {snooze && snooze.date && moment(snooze.date).format("DD/MM/YYYY")}
      </div>
      <div
        style={{
          color: "#2F4858",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "normal",
          marginTop: "16px",
        }}
      >
        {snooze && snooze.reason}
      </div>
      {snooze && snooze.reason === "Está buscando vivienda" && (
        <div
          style={{
            color: "#2F4858",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 400,
            marginTop: "8px",
          }}
        >
          {snooze && snooze.body}
        </div>
      )}
    </div>
  );

  const finishNotification = (
    <div
      style={{
        background: "#EFFCFc",
        borderRadius: "4px",
        padding: "24px",
        marginTop: isMobile ? 16 : 32,
      }}
    >
      <div
        style={{
          color: "#02C3CD",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: 600,
          marginBottom: 16,
        }}
      >
        {t("docuInmo.close")}
      </div>
      <div
        className="typography-titles"
        style={{
          fontWeight: "400",
          marginBottom: 8,
        }}
      >
        {t("b2bOperationList.reason")}
      </div>
      <div
        className="typography-titles"
        style={{
          fontWeight: "400",
          marginBottom: 16,
          fontSize: 14,
        }}
      >
        {valuesData && valuesData.close && valuesData.close.reason !== null
          ? valuesData.close.reason
          : "-"}
      </div>
      <div
        className="typography-titles"
        style={{
          fontWeight: "400",
          marginBottom: 8,
        }}
      >
        {t("b2bOperationList.subreason")}
      </div>
      <div
        style={{
          fontWeight: "400",
          marginBottom: 16,
          fontSize: 14,
        }}
      >
        {valuesData && valuesData.close && valuesData.close.subreason !== null
          ? valuesData.close.subreason
          : "-"}
      </div>
      <div
        className="typography-titles"
        style={{
          fontWeight: "400",
          marginTop: 16,
        }}
      >
        {t("m_closeOperation.commentsTitle")}
      </div>
      <p
        style={{
          fontWeight: "400",
          marginBottom: 16,
          fontSize: 14,
        }}
        dangerouslySetInnerHTML={{
          __html:
            valuesData && valuesData.close && valuesData.close.description,
        }}
      ></p>
    </div>
  );

  return (
    <>
      {valuesData && valuesData.operation ? (
        <>
          {!isMobile && (
            <>
              <BreadcrumbsInmo
                name={nameHP}
                status={status}
                operationType={
                  dataMortgage && dataMortgage.operation
                    ? dataMortgage.operation.subtype
                    : dataMortgage.mortgage &&
                      dataMortgage &&
                      dataMortgage.mortgage.operationType &&
                      dataMortgage.mortgage.operationType
                }
                type={"mortgage"}
                bol={false}
              />
              <Row
                gutter={24}
                style={{
                  width: "calc(100% - 56px)",
                  marginLeft: 28,
                }}
                // style={{
                //   display: "flex",
                //   margin: "0 40px",
                //   rowGap: 24,
                //   marginTop: 20,
                // }}
              >
                {valuesData && valuesData.operation && (
                  <Col xs={24} lg={18} style={{ marginTop: 24 }}>
                    <Card
                      className="gibobs-card-styles"
                      style={{ height: "100%" }}
                    >
                      <CardInformationInfo
                        stages={stages}
                        valuesData={valuesData}
                        ownerList={ownerList}
                        dataMortgage={dataMortgage}
                        viabilityData={viabilityData}
                        userMortgageId={userMortgageId}
                        dataOwner={dataMortgage.owner1}
                        status={status}
                        pospone={statusOperation === "postpone"}
                      />
                      {statusOperation !== "postpone" && status !== "finish" && (
                        <>
                          {(stages === "banks" || stages === "oferts") && (
                            <div style={{ margin: 16, marginTop: 24 }}>
                              <BanksComponent
                                userMortgageId={userMortgageId}
                                stages={stages}
                              />
                            </div>
                          )}
                          {(stages === "documents" ||
                            stages === "banks" ||
                            stages === "analysis") && (
                            <div style={{ marginTop: 32 }}>
                              <DocumentMobileComponent
                                userMortgageId={userMortgageId}
                                stage={stages}
                              />
                            </div>
                          )}
                          {stages === "appraisal" && (
                            <div style={{ marginTop: 32 }}>
                              <StepsHorizontal
                                data={[
                                  {
                                    name: t("operationStatus.finishPay"),
                                    date:
                                      dataMortgage &&
                                      dataMortgage.mortgage &&
                                      dataMortgage.mortgage.paidAppraisalDate
                                        ? dataMortgage.mortgage
                                            .paidAppraisalDate
                                        : valuesData &&
                                          valuesData.appraisalStatus &&
                                          valuesData.appraisalStatus
                                            .appraisalPaidDate
                                        ? valuesData.appraisalStatus
                                            .appraisalPaidDate
                                        : null,
                                  },
                                  {
                                    name: t("appraisal.stepsAvailable"),
                                    date:
                                      valuesData &&
                                      valuesData.appraisalStatus &&
                                      valuesData.appraisalStatus
                                        .appraisalAvailableDate,
                                  },
                                  {
                                    name: t("appraisal.stepsApproved"),
                                    // date: infoBanks.createdAt && infoBanks.responseDate,
                                  },
                                ]}
                                width={320}
                                status={
                                  dataMortgage &&
                                  dataMortgage.mortgage &&
                                  dataMortgage.mortgage.bankAppraisalApproval &&
                                  dataMortgage.mortgage
                                    .bankAppraisalApproval === true
                                    ? 3
                                    : dataMortgage &&
                                      dataMortgage.mortgage &&
                                      dataMortgage.mortgage.reportAppraisal !==
                                        undefined
                                    ? 2
                                    : dataMortgage &&
                                      dataMortgage.mortgage &&
                                      dataMortgage.mortgage.appraisalPaid ===
                                        true
                                    ? 1
                                    : 0
                                }
                              />
                            </div>
                          )}
                          {stages === "formalization" && (
                            <div style={{ marginTop: 32 }}>
                              <FormalizationComponent
                                dataMortgage={dataMortgage}
                                valuesData={valuesData}
                              />
                            </div>
                          )}
                        </>
                      )}

                      {statusOperation === "postpone" && postponeNotification}
                      {status === "finish" && finishNotification}
                    </Card>
                  </Col>
                )}
                <Col xs={10} lg={6} style={{ marginTop: 24 }}>
                  <Card
                    className="gibobs-card-styles"
                    style={{ height: 268, marginBottom: 24 }}
                  >
                    <AgentInformation
                      image={
                        infoAgents &&
                        infoAgents.agent &&
                        infoAgents.agent.avatar &&
                        infoAgents.agent.avatar.link &&
                        infoAgents.agent.avatar.link
                      }
                      name={
                        infoAgents && infoAgents.agent && infoAgents.agent.name
                      }
                      phone={
                        infoAgents && infoAgents.agent && infoAgents.agent.phone
                      }
                      email={
                        infoAgents && infoAgents.agent && infoAgents.agent.email
                      }
                    />
                  </Card>
                  <Card className="gibobs-card-styles" style={{ height: 598 }}>
                    <div style={{ marginBottom: 32, textAlign: "center" }}>
                      <Text style={{ fontSize: 16, fontWeight: 600 }}>
                        {t("document.modatTitlteSteper")}
                      </Text>
                    </div>

                    <StepsNewStyle
                      stage={stagesName[stages]}
                      dataSteps={dataSteps}
                      height={460}
                    />
                  </Card>
                </Col>
              </Row>
            </>
          )}

          {isMobile && (
            <CardInformationMobile
              stages={stages}
              valuesData={valuesData}
              ownerList={ownerList}
              dataMortgage={dataMortgage}
              viabilityData={viabilityData}
              dataSteps={dataSteps}
              stage={stagesName[stages]}
              height={460}
              nameHP={nameHP}
              eco={type === "eco" ? true : false}
              userMortgageId={userMortgageId}
              pospone={statusOperation === "postpone" && postponeNotification}
              close={status === "finish" && finishNotification}
              dataOwner={dataMortgage.owner1}
              infoAgents={infoAgents && infoAgents}
              formalization={stages === "formalization" ? true : false}
            />
          )}
        </>
      ) : (
        <Spin indicator={antIcon} className="spin" />
      )}
    </>
  );
}

export default FollowUpOperations;
