import React, { useEffect } from "react";
import { Progress, Typography, Collapse } from "antd";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import ViabilityComponent from "../../../Operaciones/viabilityAndSimulation/viabilityComponent";
import documentsIcon from "../../../../assets/onboarding/documentsIcon.svg";
import documentsIconDesk from "../../../../assets/onboarding/documentsIconDesk.svg";
import downloadIcon from "../../../../assets/onboarding/downloadIcon.svg";
import { isMobileOnly } from "react-device-detect";
import { getAnalytics, logEvent } from 'firebase/analytics';

const { Text } = Typography;
const { Panel } = Collapse;

const Viability = ({ viabilityData, viabilityDocument, welcomeDocument }) => {
  const { t } = useTranslation();
  const analytics = getAnalytics();

  useEffect(()=> {
    logEvent(analytics, 'screen_view', {
      screen_name: "onboarding2022.viabilitytab"
    });
  },[])

  const data = [
    {
      titulo: "viability.financingCeilingTitle",
      viable:
        viabilityData && viabilityData.penalties
          ? viabilityData.penalties["financingCeiling"]
          : null,
    },
    {
      titulo: "viability.indebtednessTitle",
      viable:
        viabilityData && viabilityData.penalties
          ? viabilityData.penalties["indebtedness"]
          : null,
    },
    {
      titulo: "viability.seniorityTitle",
      viable:
        viabilityData && viabilityData.penalties
          ? viabilityData.penalties["seniority"]
          : null,
    },
    {
      titulo: "viability.incomesTitle",
      viable:
        viabilityData && viabilityData.penalties
          ? viabilityData.penalties["incomes"]
          : null,
    },
    {
      titulo: "viability.noresidentTitle",
      viable:
        viabilityData && viabilityData.penalties
          ? viabilityData.penalties["noresident"]
          : null,
    },
  ];

 // let textViability;

  if (viabilityData && viabilityData.viability < 2) {
    //textViability = t("viability.viabilityLessThan2");
  } else if (
    viabilityData &&
    viabilityData.viability < 3 &&
    viabilityData.viability >= 2
  ) {
    //textViability = t("viability.viabilityBetween2and3");
  } else if (
    viabilityData &&
    viabilityData.viability < 4 &&
    viabilityData.viability >= 3
  ) {
   // textViability = t("viability.viabilityBetween3and4");
  } else {
    //textViability = t("viability.viabilityBetween4and5");
  }
  let textViabilitytitle;

  if (viabilityData && viabilityData.viability < 2) {
    textViabilitytitle = t("viability.viabilityLessThan2title");
  } else if (
    viabilityData &&
    viabilityData.viability < 3 &&
    viabilityData.viability >= 2
  ) {
    textViabilitytitle = t("viability.viabilityBetween2and3title");
  } else if (
    viabilityData &&
    viabilityData.viability < 4 &&
    viabilityData.viability >= 3
  ) {
    textViabilitytitle = t("viability.viabilityBetween3and4title");
  } else {
    textViabilitytitle = t("viability.viabilityBetween4and5title");
  }

  const penaltiesViability = () => {
    return data.map((values) => {
      return (
        <ViabilityComponent
          dataMortgage={{ houseState: "have_decide" }}
          key={values.titulo}
          titulo={values.titulo}
          viable={values.viable}
          viabilityData={viabilityData}
          optionsModal={!isMobileOnly && true}
          calculator={false}

        />
      );
    });
  };

  const downloadDocumentsMobile = (title, download) => {
    return (
      <div>
        <a
          href={download}
          target={"_blank"}
          style={{
            marginBottom: 8,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={documentsIcon} alt="documentsIcon" />
            <Text style={{ fontSize: 16, fontWeight: 400, marginLeft: 16 }}>
              {t(title)}
            </Text>
          </div>
          <img src={downloadIcon} alt="downloadIcon" />
        </a>
      </div>
    );
  };
  const downloadDocumentsDesktop = (title, download) => {
    return (
      <div
        style={{
          marginBottom: 8,
          display: "flex",
          alignItems: "center",
        }}
        href={`${download}`}
        target={"_blank"}
      >
        <a href={download} target={"_blank"}>
          <img src={documentsIconDesk} alt="documentsIcon" />
          <Text
            style={{
              fontSize: 12,
              fontWeight: 400,
              marginLeft: 8,
              color: "#748EA0",
            }}
          >
            {t(title)}
          </Text>
        </a>
      </div>
    );
  };

  return (
    <>
      <div
        className={
          isMobileOnly
            ? "Estimated-Viability-onboarding"
            : "EstimatedViabilityB"
        }
      >
        <Progress
          type="dashboard"
          strokeColor={"#52C41A"}
          trailColor={"#F1F7F8"}
          percent={(viabilityData && viabilityData.viability * 100) / 5}
          format={() => (
            <div>
              <Text
                strong
                style={{
                  fontSize: "32px",
                  color: "#2F4858",
                  marginBottom: "4px",
                  display: "grid",
                }}
              >
                {viabilityData
                  ? numeral(viabilityData.viability).format("0.0")
                  : null}
              </Text>
              <Text
                style={{ fontSize: "14px", color: "#2F4858" }}
              >{`de 5`}</Text>
            </div>
          )}
          width={120}
          className="EstimatedViabilityProgress"
          style={{
            textAlign: "center",
            marginRight: 0,
          }}
        />
        <div className="EstimatedViabilityParagraphB">
          <Text
            style={{
              fontSize: "14px",
              height: "72px",
              color:
                viabilityData && viabilityData.viability < 2
                  ? "#FAAD14"
                  : "#52C41A",
              fontWeight: "600",
            }}
          >
            {textViabilitytitle}{" "}
          </Text>
        </div>

        {!isMobileOnly && (
          <div
            style={{
              display: "flex",
              columnGap: 16,
              marginTop: 26,
              alignSelf: "center",
            }}
          >
            {downloadDocumentsDesktop(
              "viability.feasibilityAnalysis",
              viabilityDocument
            )}
            {downloadDocumentsDesktop(
              "onboarding.welcomeDocument",
              welcomeDocument
            )}
          </div>
        )}

        {!isMobileOnly && (
          <div
            style={{
              display: "flex",
              marginTop: 34,
              alignSelf: "center",
              borderBottom: "1px solid #02C3CD",
            }}
          >
            <div style={{ fontSize: "16px", fontWeight: "600" }}>
              {t("viability.whyDoIHaveThisViability")}
            </div>
          </div>
        )}

        {isMobileOnly && (
          <>
            <Collapse
              className={isMobileOnly && "collapse-viability-onboarding"}
              defaultActiveKey={"2"}
              expandIconPosition="end"
              ghost
            >
              <Panel header={t("viability.whyDoIHaveThisViability")} key="1">
                {penaltiesViability()}
              </Panel>
            </Collapse>
            {downloadDocumentsMobile(
              "viability.feasibilityAnalysis",
              viabilityDocument
            )}
            {downloadDocumentsMobile(
              "onboarding.welcomeDocument",
              welcomeDocument
            )}
          </>
        )}
      </div>
      {!isMobileOnly && (
        <div
          style={{
            display: "flex",
            marginTop: "-5px",
            justifyContent: "center",
            flexFlow: "wrap",
          }}
        >
          {penaltiesViability()}
        </div>
      )}
    </>
  );
};

export default Viability;
