import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  Layout,
  message,
  Tabs,
  Row,
  Col,
  Badge,
  Upload,
  Collapse,
  Tooltip,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  PlusOutlined,
  MailOutlined,
  DownloadOutlined,
  UserOutlined,
  PhoneOutlined,
  CheckOutlined,
  ExclamationOutlined,
  ExclamationCircleOutlined,
  UnorderedListOutlined,
  FileOutlined,
  RightOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import axios from "axios";
import env from "../../environment";
import _ from "lodash";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import moment from "moment";
import numeral from "numeral";
import shoppingCar from "../../assets/icons/productsAzul.png";
import StepsNewStyle from "./FollowUpOperations/Components/StepsNewStyle";
import { useNavigate } from "react-router-dom";
import DocumentMobileComponent from "./FollowUpOperations/Components/DocumentComponent";
import AgentInformation from "./FollowUpOperations/Components/AgentInformation";
import FileOutlinedIcon from "../../assets/icons/FileOutlinedIcon.svg";
import activeIcon from "../../assets/icons/activeIcon.svg";
import lockIcon from "../../assets/icons/lockIcon.svg";
import infoBlueIcon from "../../assets/icons/infoBlueIcon.svg";

const { TabPane } = Tabs;
const { Text } = Typography;
const { Panel } = Collapse;

const CertificatedBuyRentDocumentation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();;
  const [saveFileDataField, setSaveFileDataField] = useState(null);
  const userToken = useSelector((state) => state.auth.token);
  const [valuesData, setValuesData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [valuesDataForCertificate, setValuesDataForCertificate] = useState([]);
  const [clickTab, setClickTab] = useState("owner1");
  const param = useParams();
  const [detailsCertificate, setDetailsCertificate] = useState([]);
  let currentOwner = undefined;
  const [fileGroup, setFileGroup] = useState([]);
  const [tags, setTags] = useState([]);
  const [, setOwner] = useState("");
  const [infoAgents, setInfoAgents] = useState();
  let countShowMessage = 0;
  const stagesName = {
    documents: t("frecuenceQuestions.title"),
    analysis: t("stages.analysisStep"),
    finish: t("stages.closed"),
  };
  useEffect(() => {
    axios
      .get(`${env.api.url}/v1/operations/${param.id}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setDetailsCertificate(response.data.data);
      });
    axios
      .get(`${env.api.url}/v1/mortgages/agents/${param.id}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setInfoAgents(response.data.data);
      });
    getDocuments();
    getGroupsDoc();
  }, []);

  useEffect(() => {
    if (detailsCertificate && detailsCertificate.operationData) {
      setClickTab(detailsCertificate.operationData.certificate.currentOwner);
    }
  }, [detailsCertificate, currentOwner]);

  const handleClickPlus = (idowner, file) => {
    setFileGroup(file);
    let array = [idowner];
    if (idowner === "buy") {
      setTags(array);
      setOwner("owner1");
    } else {
      setOwner(idowner);
    }
  };

  const saveFileData = (file, name) => {
    const documentFile = [saveFileDataField];
    documentFile.push({
      file,
      fileName: name,
    });
    setSaveFileDataField(documentFile);
    handleUploadFile(documentFile);
  };

  const handleUploadFile = (file) => {
    axios
      .put(
        `${env.api.url}/v1/documents/operation/attachment`,
        {
          operationId: param.id,
          file: file[1].file,
          fileName: file[1].fileName,
          owner: clickTab,
          type: fileGroup.name,
          tags: tags,
          page: "t",
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then(() => {
        getDocuments();
        getGroupsDoc();

        if (countShowMessage === 0) {
          message.success(t("document.message"));
          countShowMessage = 1;
        }
      })
      .catch((error) => {
        console.log(error)
      });
    countShowMessage = 0;
  };

  const getGroupsDoc = () => {
    axios
      .get(
        `${env.api.url}/v1/documents/request-operation-documentation/${param.id}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        response.data.data.documents = _.orderBy(
          response.data.data.documents,
          [
            (d) => {
              return d.participants[clickTab];
            },
          ],
          ["desc"]
        );

        setValuesDataForCertificate(response.data.data);
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const uploadProps = {
    beforeUpload: (file) => {
      return new Promise(() => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64File = reader.result.split(",")[1];
          saveFileData(base64File, file.name);
        };
      });
    },
  };

  const handleFileDownload = (id) => {
    axios
      .get(`${env.api.url}/v1/documents/get-operation-documents/${id}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        if (response.data.success) {
          const file = response.data.data.find(
            (item) => item.type === "certificate"
          );
          if (file) {
            axios
              .get(`${env.api.url}/v1/certificates/generate-pdf/${param.id}`, {
                headers: { Authorization: `Bearer ${userToken}` },
              })
              .then((response) => {
                window.open(response.data.data.url, "_blank"); //to open new page
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };


  const getDocuments = async () => {
    let owner = currentOwner === undefined ? clickTab : currentOwner;

    const response = await axios.get(
      `${env.api.url}/v1/documents/get-operation-documents/${param.id}`,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );

    const unsorted = response.data.data;

    const sorted = unsorted
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )
      .reverse();

    let temp = undefined;

    temp = sorted.filter((item) => item.owner === owner);
    setValuesData(temp);
  };

  const renderDocumentInformation = (index, type) => {
    const data = valuesData.filter((item) => {
      let documentFlag = false;

      if (item.owner === `${type}${index}`) {
        documentFlag = true;
      } else if (type === "owner" && index === 1 && !item.owner) {
        documentFlag = true;
      }

      return documentFlag;
    });

    const files = data.map((item) => {
      if (item.type !== null && item.page !== null && item.owner === clickTab) {
        return (
          <Row
            style={{
              display: "flex",
              marginTop: 22,
              marginLeft: -10,
              width: "100%",
            }}
          >
            <Col xs={24} style={{ display: "flex" }}>
              <CheckOutlined style={{ color: "#52C41A", marginTop: 3 }} />
              <Text
                style={{
                  color: "#2F4858",
                  fontSize: 14,
                  fontWeight: 400,
                  marginLeft: 16,
                }}
              >
                {" "}
                {item.name.substr(0, 25)}
              </Text>
            </Col>

            {/*<Col xs={3} style={{ display: "flex", width: "100%" }}>
                            <div onClick={() => handleFileDownload(item.id, item)} >
                                <DownloadOutlined style={{ marginRight: 22, color: "#2F4858" }} />
                            </div>
                            <div hidden={item.type === "nif"}
                                onClick={() => {
                                    modalDocument(item.id);
                                }}>
                                <DeleteOutlined style={{ color: "#FF4D4F" }} />
                            </div>
                        </Col>*/}
          </Row>
        );
      }
    });

    const filesDoc =
      valuesDataForCertificate.documents &&
      valuesDataForCertificate.documents.map((item) => {
        if (item.completed[clickTab] === false) {
          if (item.participants[clickTab] === "required") {
            return (
              <Row
                style={{
                  display: "flex",
                  marginTop: 22,
                  marginLeft: -10,
                  width: "100%",
                }}
              >
                <Col xs={21}>
                  <Tooltip placement="top" title={t("document.required")}>
                    {" "}
                    <ExclamationOutlined style={{ color: "#2F4858" }} />
                  </Tooltip>
                  <Text
                    style={{
                      color: "#2F4858",
                      fontSize: 14,
                      fontWeight: 600,
                      marginLeft: 16,
                    }}
                  >
                    {" "}
                    {item.title}
                  </Text>
                </Col>

                <Col xs={3} style={{ width: "100%" }}>
                  <div
                    className="PlusButton"
                    style={{
                      width: "14px",
                      height: "14px",
                      float: "right",
                      marginRight: -10,
                    }}
                  >
                    <Upload multiple={true} {...uploadProps}>
                      <a
                        onClick={() => handleClickPlus(clickTab, item)}
                        style={{ border: "none" }}
                      >
                        <PlusOutlined
                          style={{ color: "#02C3CD", fontSize: 15 }}
                        />
                      </a>
                    </Upload>
                  </div>
                </Col>
              </Row>
            );
          } else if (
            detailsCertificate &&
            detailsCertificate.stage === "documents" &&
            item.participants[clickTab] === "optional"
          ) {
            return (
              //documentacion opcional

              <Row
                style={{
                  display: "flex",
                  marginTop: 22,
                  marginLeft: -10,
                  width: "100%",
                }}
              >
                <Col xs={21}>
                  <Tooltip placement="top" title={t("document.optional")}>
                    {" "}
                    <ExclamationCircleOutlined style={{ color: "#2F4858" }} />
                  </Tooltip>
                  <Text
                    style={{
                      color: "#2F4858",
                      fontSize: 14,
                      fontWeight: 400,
                      marginLeft: 16,
                    }}
                  >
                    {" "}
                    {item.title}
                  </Text>
                </Col>

                <Col xs={3} style={{ width: "100%" }}>
                  <div
                    className="PlusButton"
                    style={{
                      width: "14px",
                      height: "14px",
                      float: "right",
                      marginRight: -10,
                    }}
                  >
                    <Upload multiple={true} {...uploadProps}>
                      <a
                        onClick={() => handleClickPlus(clickTab, item)}
                        style={{ border: "none" }}
                      >
                        <PlusOutlined
                          style={{ color: "#02C3CD", fontSize: 15 }}
                        />
                      </a>
                    </Upload>
                  </div>
                </Col>
              </Row>
            );
          }
        }
      });
    return (
      <div style={{ marginLeft: "15px" }}>
        {files}
        {filesDoc}
      </div>
    );
  };

  const notification = () => {
    return (
      <div style={{ float: "right", marginTop: "1px", marginRight: "-5px" }}>
        <Badge
          size="small"
          dot={true}
          style={{
            float: "right",
            width: "4px",
            height: "6px",
            marginLeft: "4px",
          }}
        ></Badge>
      </div>
    );
  };

 

  const renderTabs = (index, type) => {
    let requireFlag = false;
    if (valuesDataForCertificate.documents) {
      valuesDataForCertificate.documents.forEach((data) => {
        if (data.completed[`${type}${index}`] === false) {
          if (data.participants[`${type}${index}`] === "required") {
            requireFlag = true;
          }
        }
      });
    }

    return (
      <TabPane
        tab={
          <span>
            {requireFlag ? notification() : null}

            {t(`mydocuments.${type}`) + " " + index}
          </span>
        }
        key={`${type}${index}`}
      >
        <Text
          style={{
            marginTop: 24,
            color: "#2F4858",
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          {t("stages.documents").toUpperCase()}&nbsp;
          <Text
            style={{
              marginTop: 24,
              color: "#02C3CD",
              fontSize: 12,
              fontWeight: 400,
            }}
          >
            {t("certificate.of").toUpperCase() +
              " " +
              detailsCertificate.operationData[
                `owner${index}`
              ].name.toUpperCase()}
          </Text>
        </Text>

        {renderDocumentInformation(index, type)}
      </TabPane>
    );
  };

  const renderAllDataParticipants = (index, type) => {
    return (
      <Col lg={12} xs={24} style={{ marginBottom: 30 }}>
        <Text
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: "#02C3CD",
            marginTop: 16,
          }}
        >
          {t(`mydocuments.${type}`) + index}
        </Text>
        <div style={{ display: "flex", marginTop: 16 }}>
          <UserOutlined
            style={{ color: "#02C3CD", fontSize: 12, marginRight: 7 }}
          />
          <Text style={{ fontSize: 12, fontWeight: 500, color: "#2F4858" }}>
            {"Silvia varela"}
          </Text>
        </div>
        <div style={{ display: "flex", marginTop: 8 }}>
          <MailOutlined
            style={{
              color: "#02C3CD",
              fontSize: 12,
              marginRight: 7,
              marginTop: 4,
            }}
          />
          <Text style={{ fontSize: 12, fontWeight: 500, color: "#2F4858" }}>
            {detailsCertificate &&
              detailsCertificate.operationData &&
              detailsCertificate.operationData["owner" + index].email}
          </Text>
        </div>
        <div style={{ display: "flex", marginTop: 8 }}>
          <PhoneOutlined
            style={{
              color: "#02C3CD",
              fontSize: 12,
              marginRight: 7,
              marginTop: 4,
            }}
          />
          <Text style={{ fontSize: 12, fontWeight: 500, color: "#2F4858" }}>
            {detailsCertificate &&
              detailsCertificate.operationData &&
              detailsCertificate.operationData["owner" + index].phone}
          </Text>
        </div>
      </Col>
    );
  };

  const numParticipants = parseInt(
    detailsCertificate &&
      detailsCertificate.operationData &&
      detailsCertificate.operationData.operation.owners
  );

  const documentTabs = [];
  const allDataParticipants = [];

  const documentsViewAutomatic = [];

  for (let i = 1; i <= numParticipants; i++) {
    documentsViewAutomatic.push(
      <TabPane
        tab={<span>{t(`mydocuments.owner`) + i}</span>}
        key={`owner${i}`}
      >
        <Text
          style={{
            marginTop: 24,
            color: "#2F4858",
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          {t("stages.documents").toUpperCase()}&nbsp;
          <Text
            style={{
              marginTop: 24,
              color: "#02C3CD",
              fontSize: 12,
              fontWeight: 400,
            }}
          >
            {t("certificate.of").toUpperCase() +
              " " +
              detailsCertificate.operationData[`owner${i}`].name.toUpperCase()}
          </Text>
        </Text>

        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <Row style={{ display: "flex", marginTop: 22, width: "100%" }}>
            <Col xs={2}>
              <CheckOutlined style={{ color: "#52C41A" }} />
            </Col>
            <Col xs={22}>
              <Text style={{ color: "#2F4858", fontSize: 14, fontWeight: 400 }}>
                {" "}
                {t("certificate.docOficialZip")}
              </Text>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: 22, width: "100%" }}>
            <Col xs={2}>
              <CheckOutlined style={{ color: "#52C41A" }} />
            </Col>
            <Col xs={22}>
              <Text style={{ color: "#2F4858", fontSize: 14, fontWeight: 400 }}>
                {" "}
                {t("certificate.downloadBank3")}
              </Text>
            </Col>
          </Row>
        </div>
      </TabPane>
    );
  }

  const downloadCertificate = (
    <div
      style={{
        backgroundColor: "#ffffff",
        padding: "10px 16px",
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div>
        <FileOutlined style={{ color: "#02C3CD", fontSize: 16 }} />
        <Text
          style={{
            color: "#2F4858",
            fontSize: 16,
            fontWeight: 400,
            marginLeft: 12,
          }}
        >
          {t("certificate.certificate")}
        </Text>
      </div>
      {detailsCertificate && detailsCertificate.operationData &&
      <DownloadOutlined
        style={{ color: "#02C3CD" }}
        onClick={() =>
          handleFileDownload(detailsCertificate.id, detailsCertificate)
        }
      />
      }
    </div>
  );

  for (let i = 1; i <= numParticipants; i++) {
    documentTabs.push(renderTabs(i, "owner"));
  }

  for (let i = 1; i <= numParticipants; i++) {
    allDataParticipants.push(renderAllDataParticipants(i, "owner"));
  }

  const renderDataOwner = () => {
    let result = [];

    if (detailsCertificate && detailsCertificate.operationData) {
      for (let index = 0; index < numParticipants; index++) {
        result.push(
          <>
            <Row
              style={{
                marginTop: isMobile ? 24 : 32,
                marginLeft: isMobile && 16,
                marginBottom: 12,
              }}
            >
              <Text style={{ color: "#2F4858", fontSize: 12, fontWeight: 600 }}>
                {t("certificate.dataOwnerList").toLocaleUpperCase() +
                  " " +
                  t(`form.owner${index + 1}`).toLocaleUpperCase()}{" "}
              </Text>
            </Row>
            {sectionInfoCertificated(
              "b2bOperationList.customer",
              detailsCertificate &&
                detailsCertificate.operationData[`owner${index + 1}`].name +
                  " " +
                  detailsCertificate.operationData[`owner${index + 1}`]
                    .surname1 +
                  " " +
                  detailsCertificate.operationData[`owner${index + 1}`].surname2
            )}
            {sectionInfoCertificated(
              "form.email",
              detailsCertificate &&
                detailsCertificate.operationData[`owner${index + 1}`].email
            )}
            {sectionInfoCertificated(
              "form.phone",
              detailsCertificate &&
                detailsCertificate.operationData[`owner${index + 1}`].phone
            )}
          </>
        );
      }
      return result;
    }
  };

  const sectionInfoCertificated = (title, info) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: isMobile ? "12px 16px" : "12px 0",
          borderBottom: "1px solid #F1F7F8",
          alignItems: isMobile && "center",
          backgroundColor: isMobile && "#FFFFFF",
        }}
      >
        <div>
          <Text style={{ color: "#2F4858", fontSize: 16, fontWeight: 400 }}>
            {t(title)}
          </Text>
        </div>
        <div
          style={{
            width: title === "form.email" && isMobile && 260,
            textAlign: "right",
          }}
        >
          <Text
            style={{
              color: "#748EA0",
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            {info}
          </Text>
        </div>
      </div>
    );
  };

  const renderInfoCertificated = (detailsCertificate) => {
    if (detailsCertificate.subtype === "certificate-buy") {
      return (
        <div style={{ margin: !isMobile && 8 }}>
          <Row
            style={{
              marginTop: isMobile && 24,
              marginLeft: isMobile && 16,
              marginBottom: 12,
            }}
          >
            <Text style={{ color: "#2F4858", fontSize: 12, fontWeight: 600 }}>
              {t("certificate.seccionData").toUpperCase()}{" "}
            </Text>
          </Row>
          {sectionInfoCertificated(
            "mydocuments.createDate",
            detailsCertificate &&
              moment(detailsCertificate.createdAt).format("DD-MM-YYYY")
          )}
          {sectionInfoCertificated(
            "certificate.buyAmounInmo",
            detailsCertificate &&
              detailsCertificate.operationData &&
              numeral(
                detailsCertificate.operationData.certificate.maxBuySellHouse
              ).format("0,0 $")
          )}
          {sectionInfoCertificated(
            "form.provinces",
            detailsCertificate &&
              detailsCertificate.operationData &&
              detailsCertificate.operationData.certificate.province
          )}
          {sectionInfoCertificated(
            "certificate.houseTypeInmo",
            t(
              "certificate." +
                detailsCertificate.operationData.certificate.typeHome
            )
          )}
          {sectionInfoCertificated(
            "m_products.basicPurposeTitle",
            t("form." + detailsCertificate.operationData.certificate.goBuy)
          )}
          {sectionInfoCertificated(
            "certificate.moneyOfAllOwner",
            detailsCertificate &&
              detailsCertificate.operationData &&
              numeral(
                detailsCertificate.operationData.certificate.totalSavings
              ).format("0,0 $")
          )}
          {renderDataOwner()}
        </div>
      );
    } else {
      return (
        <div style={{ margin: !isMobile && 8 }}>
          <Row
            style={{
              marginTop: isMobile && 24,
              marginLeft: isMobile && 16,
              marginBottom: 12,
            }}
          >
            <Text style={{ color: "#2F4858", fontSize: 12, fontWeight: 600 }}>
              {t("certificate.seccionData").toUpperCase()}{" "}
            </Text>
          </Row>
          {sectionInfoCertificated(
            "mydocuments.createDate",
            detailsCertificate &&
              moment(detailsCertificate.createdAt).format("DD-MM-YYYY")
          )}
          {sectionInfoCertificated(
            "certificate.rentValue",
            detailsCertificate &&
              detailsCertificate.operationData &&
              numeral(
                detailsCertificate.operationData.certificate.maxBudget
              ).format("0,0 $")
          )}
          {renderDataOwner()}
        </div>
      );
    }
  };

  const dataSteps = [
    {
      title: "stages.documents",
      info: "productsComponent.stepDoc",
      numStages: 0,
    },
    {
      title: "stages.analysisStep",
      info: "m_temporalLine.analysis",
      numStages: 1,
    },
  ];

  const stagesAndOperationsTitle = () => {
    return (
      <div
        gutter={24}
        style={{
          margin: isMobile ? 16 : "24px 0px 24px 40px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <ArrowLeftOutlined
            style={{ marginRight: 16, fontSize: 16, color: "#02C3CD" }}
            onClick={() =>
              navigate("/sales-tools/follow-up", [false, "certificated"])
            }
          />
          <Text style={{ color: "#02C3CD", fontWeight: 600, fontSize: 16 }}>
            {detailsCertificate &&
              detailsCertificate.subname &&
              detailsCertificate.subname}
          </Text>
        </div>
        <div>
          {detailsCertificate &&
            detailsCertificate.subtype === "certificate-rent" &&
            detailsCertificate.stage !== "finish" && (
              <div
                style={{
                  float: "right",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{ color: "#02C3CD", fontSize: 16, fontWeight: 400 }}
                >
                  {!isMobile && t("m_operationSubtypes.certificateRent")}
                </Text>
                <div
                  style={{
                    height: 24,
                    width: 24,
                    textAlign: "center",
                    marginLeft: 8,
                  }}
                >
                  <img
                    src={FileOutlinedIcon}
                    alt="FileOutlinedIcon"
                    height={20}
                  />
                </div>
                <div
                  style={{
                    height: 24,
                    width: 24,
                    textAlign: "center",
                    marginLeft: 8,
                  }}
                >
                  <img src={activeIcon} alt="activeIcon" height={15} />
                </div>
              </div>
            )}
          {detailsCertificate &&
            detailsCertificate.subtype !== "certificate-rent" &&
            detailsCertificate.stage !== "finish" && (
              <div
                style={{
                  float: "right",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{ color: "#02C3CD", fontSize: 16, fontWeight: 400 }}
                >
                  {!isMobile && t("m_operationSubtypes.certificateBuy")}
                </Text>
                <div
                  style={{
                    height: 24,
                    width: 24,
                    textAlign: "center",
                    marginLeft: 8,
                  }}
                >
                  <img src={shoppingCar} alt="shoppingCar" height={20} />
                </div>
                <div
                  style={{
                    height: 24,
                    width: 24,
                    textAlign: "center",
                    marginLeft: 8,
                  }}
                >
                  <img src={activeIcon} alt="activeIcon" height={15} />
                </div>
              </div>
            )}

          {detailsCertificate &&
            detailsCertificate.subtype === "certificate-rent" &&
            detailsCertificate.stage === "finish" && (
              <div style={{ float: "right", display: "flex" }}>
                <Text
                  style={{ color: "#02C3CD", fontSize: 16, fontWeight: 400 }}
                >
                  {!isMobile && t("m_operationSubtypes.certificateRent")}
                </Text>
                <div
                  style={{
                    height: 24,
                    width: 24,
                    textAlign: "center",
                    marginLeft: 8,
                  }}
                >
                  <img
                    src={FileOutlinedIcon}
                    alt="FileOutlinedIcon"
                    height={20}
                  />
                </div>
                <div
                  style={{
                    height: 24,
                    width: 24,
                    textAlign: "center",
                    marginLeft: 8,
                  }}
                >
                  <img src={lockIcon} alt="lockIcon" height={20} />
                </div>
              </div>
            )}

          {detailsCertificate &&
            detailsCertificate.subtype !== "certificate-rent" &&
            detailsCertificate.stage === "finish" && (
              <div style={{ float: "right" }}>
                <Text
                  style={{ color: "#02C3CD", fontSize: 16, fontWeight: 400 }}
                >
                  {!isMobile && t("m_operationSubtypes.certificateBuy")}
                </Text>
                <img
                  src={shoppingCar}
                  alt="shoppingCar"
                  height={20}
                  style={{ color: "#02C3CD", marginLeft: 8, marginBottom: 2 }}
                />
                <div
                  style={{
                    height: 24,
                    width: 24,
                    textAlign: "center",
                    marginLeft: 8,
                  }}
                >
                  <img src={lockIcon} alt="lockIcon" height={20} />
                </div>
              </div>
            )}
        </div>
      </div>
    );
  };

  return (
    <>
      {!isMobile && (
        <Layout
          style={{
            width: isMobile ? "calc(100% - 16px)" : "calc(100% - 40px)",
            marginBottom: 100,
          }}
        >
          {stagesAndOperationsTitle()}
          <Row
            gutter={24}
            style={{
              marginLeft: !isMobile && 28,
            }}
          >
            <Col lg={18} xs={24}>
              <Card
                className="CardStyleRealeState"
                style={{
                  marginLeft: isMobile ? "16px" : 0,
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text
                    style={{ fontSize: 16, fontWeight: 600, color: "#2F4858" }}
                  >
                    {detailsCertificate &&
                    detailsCertificate.stage === "analysis"
                      ? t("stages.analysis")
                      : detailsCertificate.stage === "finish"
                      ? t("certitificate.finishStep")
                      : t("stages.documents")}
                  </Text>
                  <Collapse
                    style={{
                      backgroundColor: "white",
                      marginBottom: "8px",
                      marginTop: 24,
                    }}
                    expandIconPosition="end"
                  >
                    <Panel
                      header={
                        <div style={{ alignItems: "center" }}>
                          <UnorderedListOutlined
                            style={{
                              color: "#02C3CD",
                              fontSize: 16,
                              marginRight: 16,
                            }}
                          />
                          <Text
                            style={{
                              color: "#2F4858",
                              fontSize: "16px",
                              fontWeight: 400,
                            }}
                          >
                            {t("certificate.infoOperation")}
                          </Text>
                        </div>
                      }
                      key="1"
                      style={{ fontSize: "12px" }}
                    >
                      {renderInfoCertificated(detailsCertificate)}
                    </Panel>
                  </Collapse>
                </div>

                {detailsCertificate &&
                  detailsCertificate.stage === "analysis" &&
                  null}

                {detailsCertificate &&
                  detailsCertificate.stage === "documents" && (
                    // <Tabs
                    //   type="card"
                    //   style={{
                    //     color: "#2F4858",
                    //     fontStyle: "normal",
                    //     fontWeight: "normal",
                    //     marginTop: 24,
                    //     marginBottom: 24,
                    //   }}
                    //   onChange={(values) => handleSetClickTab(values)}
                    // >
                    //   {detailsCertificate &&
                    //   detailsCertificate.operationData &&
                    //   detailsCertificate.operationData[clickTab]
                    //     .automaticDocOTPCompleted === true
                    //     ? documentsViewAutomatic
                    //     : documentTabs}
                    // </Tabs>
                    <div style={{ marginTop: 24 }}>
                      <DocumentMobileComponent userMortgageId={param.id} />
                    </div>
                  )}
                {detailsCertificate &&
                  detailsCertificate.stage === "finish" &&
                  downloadCertificate}
              </Card>
            </Col>

            <Col lg={6} xs={24}>
              <Card
                className="CardStyleRealeState"
                style={{ alignContent: "center", marginLeft: 0 }}
              >
                <AgentInformation
                  image={
                    infoAgents &&
                    infoAgents.agent &&
                    infoAgents.agent.avatar &&
                    infoAgents.agent.avatar.link &&
                    infoAgents.agent.avatar.link
                  }
                  name={infoAgents && infoAgents.agent && infoAgents.agent.name}
                  phone={
                    infoAgents && infoAgents.agent && infoAgents.agent.phone
                  }
                  email={
                    infoAgents && infoAgents.agent && infoAgents.agent.email
                  }
                />
              </Card>
              {detailsCertificate && detailsCertificate.stage !== "finish" && (
                <Card
                  className="CardStyleRealeState"
                  style={{
                    alignContent: "center",
                    marginLeft: 0,
                    marginTop: 24,
                  }}
                >
                  <StepsNewStyle
                    stage={detailsCertificate.stage === "documents" ? 0 : 1}
                    dataSteps={dataSteps}
                    height={"auto"}
                  />
                  {/* <div>
                    <div style={{ textAlign: "center" }}>
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: "#2F4858",
                        }}
                      >
                        {t("document.modatTitlteSteper")}
                      </Text>
                    </div>

                    <Steps
                      direction="vertical"
                      size="small"
                      status="error"
                      icon="none"
                      className="StepsSimulationBasicCertificate"
                      style={{
                        marginLeft: isMobile ? 50 : 0,
                        marginTop: isMobile ? "40px" : "28px",
                      }}
                    >
                      {detailsCertificate &&
                      detailsCertificate.stage === "documents" ? (
                        <>
                          <Step
                            icon={
                              <img
                                alt="loading"
                                src={stepGif}
                                width="34px"
                                height="34px"
                                style={{ marginTop: -10, marginLeft: -10 }}
                              ></img>
                            }
                            status={"process"}
                            description={
                              <Text
                                style={{
                                  color: "#748EA0",
                                  fontSize: 14,
                                  fontWeight: 400,
                                  position: "relative",
                                  left: 45,
                                  top: -30,
                                }}
                              >
                                {t("m_temporalLine.documents")}
                              </Text>
                            }
                            title={
                              <Text
                                style={{
                                  color: "#02C3CD",
                                  fontSize: 16,
                                  fontWeight: 400,
                                }}
                              >
                                {t("stages.documents")}
                              </Text>
                            }
                          />

                          <Step
                            title={
                              <Text
                                style={{
                                  color: "#C0DAEE",
                                  fontSize: 16,
                                  fontWeight: 400,
                                }}
                              >
                                {t("stages.analysis")}
                              </Text>
                            }
                            description={
                              <Text
                                style={{
                                  color: "#C0DAEE",
                                  fontSize: 14,
                                  fontWeight: 400,
                                  position: "relative",
                                  left: 45,
                                  top: -30,
                                }}
                              >
                                {t("m_temporalLine.analysis")}
                              </Text>
                            }
                          />
                        </>
                      ) : (
                        <>
                          <Step
                            status={"finish"}
                            title={
                              <Text
                                style={{
                                  color: "#2F4858",
                                  fontSize: 16,
                                  fontWeight: 400,
                                  position: "relative",
                                  top: 4,
                                }}
                              >
                                {t("stages.documents")}
                              </Text>
                            }
                          />

                          <Step
                            icon={
                              <img
                                alt="loading"
                                src={stepGif}
                                width="34px"
                                height="34px"
                                style={{
                                  marginTop: -10,
                                  marginLeft: -10,
                                  position: "relative",
                                  right: -5,
                                }}
                              ></img>
                            }
                            status={"process"}
                            description={
                              <Text
                                style={{
                                  color: "#748EA0",
                                  fontSize: 14,
                                  fontWeight: 400,
                                  position: "relative",
                                  left: 40,
                                  top: -30,
                                }}
                              >
                                {t("m_temporalLine.analysis")}
                              </Text>
                            }
                            title={
                              <Text
                                style={{
                                  color: "#02C3CD",
                                  fontSize: 16,
                                  fontWeight: 400,
                                }}
                              >
                                {t("stages.analysis")}{" "}
                              </Text>
                            }
                          />
                        </>
                      )}
                    </Steps>
                  </div> */}
                </Card>
              )}
            </Col>
          </Row>
        </Layout>
      )}
      {isMobile && (
        <>
          {pageNumber === 1 && (
            <div>
              {stagesAndOperationsTitle()}
              <div
                style={{
                  margin: 16,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{ fontSize: 16, fontWeight: 600, color: "#2F4858" }}
                >
                  {stagesName[detailsCertificate.stage]}
                </Text>
                <div
                  style={{
                    height: 24,
                    width: 24,
                    textAlign: "center",
                    marginLeft: 8,
                  }}
                >
                  <img
                    src={infoBlueIcon}
                    alt="infoBlueIcon"
                    height={20}
                    onClick={() => setPageNumber(3)}
                  />
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#ffffff",
                  padding: "10px 16px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => setPageNumber(2)}
              >
                <div>
                  <UnorderedListOutlined
                    style={{ color: "#02C3CD", fontSize: 16 }}
                  />
                  <Text
                    style={{
                      color: "#2F4858",
                      fontSize: 16,
                      fontWeight: 400,
                      marginLeft: 12,
                    }}
                  >
                    {t("certificate.infoOperation")}
                  </Text>
                </div>
                <RightOutlined style={{ color: "#748EA0" }} />
              </div>
              {detailsCertificate &&
                detailsCertificate.stage === "analysis" &&
                null}
              {detailsCertificate && detailsCertificate.stage === "documents" && (
                <div style={{ margin: 16, marginBottom: 100 }}>
                  <DocumentMobileComponent userMortgageId={param.id} />
                </div>
              )}
              {detailsCertificate &&
                detailsCertificate.stage === "finish" &&
                downloadCertificate}

              <AgentInformation
                name={"Nombre agente"}
                phone={123456789}
                email={"amilagente@gibobs.com"}
              />
            </div>
          )}
          {pageNumber === 2 && (
            <>
              <div
                style={{ margin: 16, display: "flex", alignItems: "center" }}
              >
                <ArrowLeftOutlined
                  style={{ fontSize: 16, color: "#2F4858" }}
                  onClick={() => setPageNumber(1)}
                />
                <div
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#2f4858",
                    marginLeft: 16,
                  }}
                >
                  Informacion del certificado
                </div>
              </div>
              <div
                style={{
                  // margin: 16,
                  marginBottom: 100,
                }}
              >
                {renderInfoCertificated(detailsCertificate)}{" "}
              </div>
            </>
          )}
          {pageNumber === 3 && (
            <>
              <div
                style={{ margin: 16, display: "flex", alignItems: "center" }}
              >
                <ArrowLeftOutlined
                  style={{ fontSize: 16, color: "#2F4858" }}
                  onClick={() => setPageNumber(1)}
                />
                <div
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#2f4858",
                    marginLeft: 16,
                  }}
                >
                  {t("document.modatTitlteSteper")}
                </div>
              </div>
              <div style={{ margin: 16 }}>
                <StepsNewStyle
                  stage={detailsCertificate.stage === "documents" ? 0 : 1}
                  dataSteps={dataSteps}
                  height={detailsCertificate.stage === "documents" ? 260 : 152}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CertificatedBuyRentDocumentation;
