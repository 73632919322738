import React from 'react';
import { Pie } from '@ant-design/charts';
import { useTranslation } from 'react-i18next';

const PieQuota = ({ quota, entailment }) => {
	const { t } = useTranslation();

	var data = [
		{
			type: t('loan.quotaAd'),
			value: quota
		},
		{
			type: t('appraisal.nameTableEntailment'),
			value: entailment
		}
	];

	var config = {
		legend: false,
		appendPadding: 10,
		data: data,
		angleField: 'value',
		colorField: 'type',
		color: ['#41D2DA', '#FFC069', '#FF7875'],
		radius: 0.9,
		label: {
			type: 'inner',
			offset: '-30%',
			content: function content() {
				return '';
			},
			style: {
				fontSize: 14,
				textAlign: 'center'
			}
		}
	};
	return (
		<div className="pie-wrapper-loan">
			<Pie
				hasLegend={true}
				height={100}
				width={100}
				{...config}
				colors={['#41D2DA', '#FFC069', '#FF7875']}
				className="PieDashboard"
			/>
			<div
				style={{
					display: 'flex',
					columnGap: 12
				}}>
				<div className="wrapper-leggend-loan">
					<div className="dot-loan"></div>
					<div className="text-leggend-loan">{t('loan.quotaAd')}</div>
				</div>
				<div className="wrapper-leggend-loan">
					<div className="dot-loan" style={{ background: '#FFC069' }}></div>
					<div className="text-leggend-loan">{t('appraisal.nameTableEntailment')}</div>
				</div>
			</div>
		</div>
	);
};

export default PieQuota;
