import React from "react";
import { Layout } from "antd";
import { useTranslation } from "react-i18next";
import Budget from "./Budget";
import MyBreadcrumb from "../MyBreadcrumb";

function SolarValueHome({ dataBudget, type }) {
  const { t } = useTranslation();

  const data = [
    {
      name: t("menuComponent.salesTools"),
      link: "/sales-tools/new-requests",
    },
    {
      name: t("menuComponent.newRequest"),
      link: "/sales-tools/new-requests",
    },
    {
      name: t("menuComponent.budgetSolar"),
      link: "/sales-tools/new-requests/solar-value-home",
    },
  ];
  return (
    <Layout>
      <MyBreadcrumb data={data}></MyBreadcrumb>
      <div style={{ height: "auto", marginBottom: "100px" }}>
        <Budget title={t("budget.budgetTitle")} dataBudget={dataBudget} type={type} />
      </div>
    </Layout>
  );
}

export default SolarValueHome;
