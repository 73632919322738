import React from 'react';
import { Card, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const { Text } = Typography;

const ProjectClientWidget = ({ loan, dataProfile, bankIcon }) => {
	const { t } = useTranslation();

	return (
		<Card className="gcardMovile card-heigth-loan" style={{ height: '100%' }}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					flexWrap: 'wrap',
					justifyContent: 'space-between'
				}}>
				{loan.loan.bank !== undefined && (
					<div>
						<img
							src={
								'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/banks/' +
								`${loan.loan.bank.bankCode}` +
								'.png'
							}
							alt={loan.loan.bank.name}
							style={{ height: 24 }}
						/>
					</div>
				)}
				{loan.loan.bank === undefined && (
					<div>
						<img src={bankIcon} style={{ height: 32, width: 32 }}></img>
					</div>
				)}
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-end'
					}}>
					<Text
						style={{
							color: '#748EA0',
							fontSize: 12,
							fontWeight: 400
						}}>
						{loan && loan.loan && loan.loan.creationType === 'MANUAL'
							? t('loan.lastEdition')
							: t('loan.ultimateSinc')}
					</Text>
					<Text
						style={{
							color: '#2F4858',
							fontSize: 12,
							fontWeight: 400
						}}>
						{loan && loan.loan && loan.loan.creationType === 'MANUAL'
							? loan &&
							  loan.loan.updatedAt &&
							  moment(loan.loan.updatedAt).format('HH:mm - DD/MM/YYYY')
							: loan &&
							  loan.loan.lastSync &&
							  moment(loan.loan.lastSync).format('HH:mm - DD/MM/YYYY')}
					</Text>
				</div>
			</div>

			<div
				style={{
					marginTop: 10,
					display: 'flex',
					flexDirection: 'column',
					columnGap: 8,
					textAlign: 'right'
				}}>
				<Text
					style={{
						color: '#748EA0',
						fontSize: 12,
						fontWeight: 400
					}}>
					{t('loan.detailsLoanOwners')}
				</Text>
				<Text
					style={{
						color: '#2F4858',
						fontSize: 14,
						fontWeight: 600
					}}>
					{loan &&
					loan.loan.participants !== undefined &&
					loan.loan.participants !== null &&
					loan.loan.participants.length > 0
						? loan.loan.participants[0].name
						: dataProfile && dataProfile.name + ' ' + dataProfile.surname}
				</Text>
			</div>
		</Card>
	);
};

export default ProjectClientWidget;
