import React, { useState, useEffect } from 'react';
import { Avatar, Button, Card, Col, Layout, Modal, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import ProductsComponent from './ProductsComponent';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import env from '../../environment';
import { isMobile, isMobileOnly } from 'react-device-detect';
import creditLinesImage from '../../assets/Products/creditLines.jpg';
import businessMortgagesImage from '../../assets/Products/businessMortgages.jpg';
import promoterImageHouse from '../../assets/promoter/promoterImageHouse.png';
import solarProjectImage from '../../assets/Products/solarProject.jpg';
import LayoutProductsPromoter from '../Promoter-loan/Layouts/LayoutProductsPromoter';
import { changeRedirect } from '../../store/promoter/actions';
import { useNavigate } from 'react-router-dom';
import isra from '../../assets/promoter/isra.png';
import email from '../../assets/promoter/email.png';

import progress2 from '../../assets/promoter/progress2.png';

import { UserOutlined } from '@ant-design/icons';
import ProductsPageInmoMobile from './ProductsPageInmoMobile';
import { createNewProject } from '../../store/promoter/actions';
import ModalInfoFunding from '../Promoter-loan/CommonComponents/ModalInfoFundings';

const { Text } = Typography;

const ProductsPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();;
	const dispatch = useDispatch();
	const userToken = useSelector((state) => state.auth.token);
	const [dataPersonal, setDataPersonal] = useState(null);
	const dataNewProject = useSelector((state) => state.promoter.dataNewProject);
	const dataRedirect = useSelector((state) => state.promoter.redirect);
	const [show2, setShow2] = useState(false);
	const [showInfo, setShowInfo] = useState(false);
	const dataProfile = useSelector((state) => state.data.dataProfile);

	useEffect(() => {
		if (dataRedirect === true) {
			navigate(`/promoter-loan/step-form/${dataNewProject.id}`);
			dispatch(changeRedirect(!dataRedirect));
		}
	}, [dataRedirect]);

	const dataPersonalOperation = () => {
		axios
			.get(`${env.api.url}/v1/user/profile`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setDataPersonal(response.data.data.profile);
			});
	};
	useEffect(() => {
		dataPersonalOperation();
	}, []);

	const widgetsProducts = [
		// {
		//   title: t("productsComponent.promoterLoan"),
		//   titleText: t("productsComponent.promoterLoan"),
		//   parrafo: t("productsComponent.descriptionPromoterLoan"),
		//   image: PromoterLoanImage,
		// },
		{
			title: t('productsComponent.creditLines'),
			titleText: t('productsComponent.creditLines'),
			parrafo: t('productsComponent.descriptionCreditLines'),
			image: creditLinesImage,
			contactReason: 'credit-lines'
		},
		{
			title: t('productsComponent.businessMortgages'),
			titleText: t('productsComponent.businessMortgages'),
			parrafo: t('productsComponent.descriptionBusinessMortgages'),
			image: businessMortgagesImage,
			contactReason: 'business-mortgage'
		},
		{
			title: t('productsComponent.solarProject'),
			titleText: t('productsComponent.solarProject'),
			parrafo: t('productsComponent.descriptionSolarValue'),
			image: solarProjectImage,
			contactReason: 'industrial-solar-project'
		}
	];

	
	const changeModal2 = () => {
		setShow2(!show2);
		window.open('https://es.october.eu/introductor/gibobs/', '_blank');
	};

	if (isMobileOnly) {
		return <ProductsPageInmoMobile></ProductsPageInmoMobile>;
	} else {
		return (
			<Layout style={{ marginBottom: 100 }}>
				<div
					style={{
						height: 'auto',
						marginBottom: 40,
						marginLeft: isMobile ? 16 : undefined,
						marginTop: isMobile ? 16 : 24
					}}>
					<div style={{ marginLeft: 40, marginBottom: -20 }}>
						<LayoutProductsPromoter />
					</div>

					<div
						style={{
							marginTop: 40,
							width: 'calc(100% - 80px)',
							boxShadow: '0px 0px 8px rgba(47, 72, 88, 0.1',
							background: '#FFFFFF',
							borderRadius: 4,
							marginLeft: 40,
							height: 236
						}}>
						<div style={{ display: 'flex' }}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '50%',
									padding: '40px 80px',
									alignItems: 'flex-end',
									textAlign: 'right'
								}}>
								<div style={{ color: '#2F4858', fontWeight: 600, fontSize: 16 }}>
									{t('promoterLoan.promoterFinancingProducts')}
								</div>
								<div
									style={{
										marginTop: 8,
										display: 'inline-flex',
										color: '#748EA0',
										fontWeight: 400,
										fontSize: 14
									}}>
									{t('productsComponent.descriptionPromoterLoan2')}
								</div>
								<div style={{ display: 'flex', gap: 20 }}>
									<Button
										type="primary"
										className="button-primary-gibobs-inmo"
										style={{
											width: 130,
											height: 40,
											fontSize: 14,
											marginTop: 24
										}}
										onClick={() => dispatch(createNewProject())}>
										{t('promoterLoan.newProject')}
									</Button>
									<Button
										type="secondary"
										style={{
											width: 130,
											height: 40,
											fontSize: 14,
											marginTop: 24,
											color: '#02c3cd',
											borderColor: '#02c3cd',
											borderRadius: 4
										}}
										onClick={() => setShowInfo(true)}>
										{t('promoter.whatSee')}
									</Button>
								</div>
							</div>
							<img
								src={promoterImageHouse}
								alt="promoterImageHouse"
								width={'50%'}
								height={236}
								// style={{ objectFit: "cover" }}
							/>
						</div>
					</div>

					<Row gutter={[24, 24]} style={{ marginLeft: 30, marginRight: 30, marginTop: 40 }}>
						{widgetsProducts.map((data, index) => {
							return (
								<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
									<ProductsComponent
										key={index}
										title={data.title}
										titleText={data.titleText}
										parrafo={data.parrafo}
										dataPersonal={dataPersonal}
										index={index}
										image={data.image}
										contactReason={data.contactReason}
									/>
								</Col>
							);
						})}
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Card
								className="gb-card"
								style={{
									height: isMobile ? 'auto' : '100%',
									borderRadius: 4
								}}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center'
									}}>
									<Text style={{ fontSize: 16, fontWeight: 600, color: '#2F4858' }}>
										{t('button.initHelp')}
									</Text>
									<Text style={{ fontSize: 14, fontWeight: 400, color: '#2F4858' }}>
										{t('october.comercialTitle')}
									</Text>

									<Avatar size={120} icon={<UserOutlined />} src={isra} style={{ marginTop: 34 }} />

									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											marginTop: 32
										}}>
										<Text
											style={{
												fontSize: 12,
												fontWeight: 600,
												color: '#2F4858'
											}}>
											{'Israel Tena'}
										</Text>
										<Text
											style={{
												fontSize: 20,
												fontWeight: 600,
												color: '#02C3CD'
											}}>
											{'91 223 75 26'}
										</Text>
										<div>
											<Text
												style={{
													fontSize: 12,
													fontWeight: 500,
													color: '#748EA0'
												}}>
												{'israel.tena@gibobs.com'}
											</Text>
											<img
												src={email}
												alt="asdf"
												style={{ width: 20, height: 20, marginLeft: 12 }}></img>
										</div>
									</div>
								</div>
							</Card>
						</Col>
					</Row>
				</div>

			
				<Modal
					className={isMobileOnly ? 'ModalCommonSmall' : 'ModalCommon'}
					cancelButtonProps={{
						className: 'button-secundary-gibobs',
						style: { width: '46%' }
					}}
					okButtonProps={{
						className: 'button-primari-gibobs',
						style: { width: '46%' }
					}}
					visible={show2}
					title={t('typeMortgage.hello') + ', ' + dataProfile.name}
					okText={t('viability.myInterest')}
					cancelText={t('form.back')}
					width={480}
					onCancel={() => {
						setShow2(!show2);
					}}
					onOk={() => changeModal2()}>
					<div style={{ marginTop: -20 }}>
						<div>
							<Text
								style={{
									color: '#748EA0',
									fontSize: 14,
									fontWeight: 400
								}}>
								{t('october.modal2Desc1')}
							</Text>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
								{t('October')}
							</Text>
						</div>

						<div style={{ marginTop: 16 }}>
							<Text
								style={{
									color: '#748EA0',
									fontSize: 14,
									fontWeight: 400
								}}>
								{t('october.modal2Desc2')}
							</Text>
						</div>

						<div style={{ marginTop: 32 }}>
							<Text
								style={{
									color: '#748EA0',
									fontSize: 14,
									fontWeight: 600
								}}>
								{t('october.modal2Desc3')}
							</Text>

							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
								{t('october.modal2Desc4')}
							</Text>
							<Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 600 }}>
								{t('october.modal2Desc5')}
							</Text>
						</div>

						<div style={{ marginTop: 24, textAlign: 'center' }}>
							<img src={progress2} alt="asfd" style={{ width: 24, height: 8 }}></img>
						</div>
					</div>
				</Modal>
				<ModalInfoFunding visible={showInfo} setVisible={setShowInfo} />
			</Layout>
		);
	}
};

export default ProductsPage;
