import {
  VIABILITYDATA,
  VIABILITYDATASUBROGATION,
  OPERATIONIDVIABILITY,
} from "./constants";

export const INITIAL_STATE = {
  viabilityData: undefined,
  viabilitySubrogation: undefined,
  operationIdViability: undefined,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPERATIONIDVIABILITY:
      return { ...state, operationIdViability: action.payload };
    case VIABILITYDATA:
      return { ...state, viabilityData: action.payload };
    case VIABILITYDATASUBROGATION:
      return { ...state, viabilitySubrogation: action.payload };
    default:
      return state;
  }
};
