import { useTranslation } from 'react-i18next';
import { Typography, Button, Modal } from 'antd';
import bancoSpain from '../../../../assets/loans/bancoSpain.svg';
import PSD2 from '../../../../assets/loans/PSD2.svg';
import close from '../../../../assets/loans/close.png';
import rgpd from '../../../../assets/loans/rgpd.svg';
import { isMobileOnly } from 'react-device-detect';

const { Text } = Typography;

const ModalDataCollection = ({
	visible,
	onCreate,
	onCancel,
	handleCreateAutomatic,
	handleCreateManual
}) => {
	const { t } = useTranslation();
	const iconsDescription = (description, image, i) => {
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					marginTop: i !== 0 ? (isMobileOnly ? 12 : 25) : isMobileOnly ? 8 : 16,
					justifyContent: 'space-between'
				}}>
				<div style={{ width: '40%', textAlign: 'center' }}>
					<img
						src={image}
						alt="image"
						height={description === 'loan.auditedByDigitalSecurity' && 32}
						width={description === 'loan.auditedByDigitalSecurity' && 32}
					/>
				</div>
				<div
					className="modal-title-loan"
					style={{ width: '60%' }}
					dangerouslySetInnerHTML={{
						__html: t(description)
					}}
				/>
			</div>
		);
	};
	return (
		<Modal
			centered
			className={isMobileOnly ? 'modal-footer-loan modal-footer-loan-desktop' : 'modal-footer-loan'}
			okButtonProps={{
				style: { display: 'none' }
			}}
			open={visible}
			title={<Text>{t('loan.modalAutomaticTitle')}</Text>}
			width={500}
			cancelButtonProps={{
				style: { display: 'none' }
			}}
			closable={true}
			onOk={onCreate}
			onCancel={onCancel}>
			<div style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
				{t('loan.modalAutomaticDec')}
			</div>
			<Button
				type="dashed"
				className="button-secundary-gibobs"
				style={{ width: '48%', margin: '32px 4% 32px 0px' }}
				onClick={() => handleCreateManual()}>
				{t('loan.modalAutomaticButton2')}
			</Button>
			<Button
				type="primary"
				className="button-primari-gibobs"
				style={{ width: '48%' }}
				onClick={() => handleCreateAutomatic()}>
				{t('loan.modalAutomaticButton')}
			</Button>
			<div
				style={{
					color: '#02C3CD',
					fontSize: 14,
					fontWeight: 400,
					marginBottom: 8,
					textAlign: 'center'
				}}>
				{t('loan.whatKeysNeed')}
			</div>
			<div
				style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}
				dangerouslySetInnerHTML={{
					__html: t('loan.descriptionDataModalLoan')
				}}
			/>
			<div
				style={{
					color: '#748EA0',
					fontSize: 14,
					fontWeight: 400,
					marginTop: 8,
					fontWeight: 600
				}}>
				{t('loan.safetyOfGibobs')}
			</div>

			{iconsDescription('loan.authorizedBankSpain', bancoSpain, 0)}
			{iconsDescription('loan.compliantEuropeanDirective', PSD2)}
			{iconsDescription('loan.adaptedGeneralDataProtection', rgpd)}
			{iconsDescription('loan.auditedByDigitalSecurity', close)}
		</Modal>
	);
};

export default ModalDataCollection;
