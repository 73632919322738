import { useEffect, useMemo, useState } from 'react';
import { Card, Typography, Button, Row, Col, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { InfoCircleFilled } from '@ant-design/icons';
import numeral from 'numeral';
import axios from 'axios';
import env from '../../../../environment';
import { Line } from '@ant-design/plots';
import check from '../../../../assets/loans/check.png';
import bulp from '../../../../assets/loans/bulp.png';
import { isMobile } from 'react-device-detect';

const { Text } = Typography;

const CompareAutomatic = ({ loanId, rateTypeProp, tipProp, visible }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();;
	const [, setLoan] = useState(undefined);
	const userToken = useSelector((state) => state.auth.token);
	const tip = tipProp;
	const rateType = rateTypeProp;
	const dataSummary = useSelector((state) => state.data.dataSummary);
	const dataSummaryFilter = dataSummary.filter((data) => data.subtype === 'subrogation');
	const [dataTips, setDataTips] = useState(undefined);
	const [data, setData] = useState(undefined);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getLoan();
		getEuribor();
	}, []);

	useEffect(() => {
		getLoan();
	}, [rateTypeProp, tipProp]);

	let config = {
		data,
		xField: 'date',
		yField: 'index',
		label: {
			position: 'middle',
			style: {
				fill: '#FFFFFF',
				opacity: 0.6
			}
		},
		xAxis: {
			tickCount: 12,
			label: {
				autoHide: true,
				autoRotate: true
			}
		},
		slider: {
			start: 0,
			end: 10
		},
		meta: {
			date: {
				alias: 'Año'
			},
			index: {
				alias: 'Euribor'
			}
		}
	};

	const getLoan = () => {
		setLoading(true);
		axios
			.get(`${env.api.url}/v1/loans/get-loan/${loanId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				// setDataValues(response.data.data);
				setLoan(response.data.data.loan);
				response.data.data.loan.tips.map((tipData) => {
					if (tipData.type === tip) {
						setDataTips(tipData);
						setLoading(false);
					}
				});
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const getEuribor = () => {
		axios
			.get(`${env.api.url}/v1/rates/by-month/euribor`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setData(response.data.data.rates);
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const requestAnalysis = () => {
		axios
			.post(
				`${env.api.url}/v1/loans/create-subrogation`,
				{
					loanId: loanId
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					const operationId = response.data.data.operation.id;
					axios
						.post(
							`${env.api.url}/campaigns/runner/experiment/by-event`,
							{
								event: 'questions-mortgages',
								data: {
									operationId: operationId
								}
							},
							{
								headers: { Authorization: `Bearer ${userToken}` }
							}
						)
						.then((response) => {
							if (response.data.data.mortgageQuestions === '2022') {
								navigate(`/testA/operations/${operationId}/basic`);
							} else {
								navigate(`/operations/${operationId}/basic`);
							}
						});
				}
			})
			.catch((error) => {
				console.log(error)
			});
	};
	const buttonInterest = (
		<div>
			{dataSummaryFilter.length !== 0 && (
				<div
					style={{
						marginTop: 24,
						color: '#2F4858',
						fontSize: 14,
						textAlign: isMobile && 'center',
						fontWeight: 500
					}}>
					{t('loan.operationCurrently')}
				</div>
			)}
			<div style={{ marginTop: 24, textAlign: 'center' }}>
				<Button
					className="button-primari-gibobs"
					disabled={dataSummaryFilter.length === 0 ? false : true}
					type="primary"
					style={{ width: 264, height: 40 }}
					onClick={() => requestAnalysis()}>
					{t('loan.buttonAdvive')}
				</Button>
			</div>
			{dataSummaryFilter.length === 0 && isMobile && (
				<div
					style={{
						marginTop: 7,
						color: '#02C3CD',
						fontSize: 14,
						fontWeight: 400,
						cursor: 'pointer',
						textAlign: 'center'
					}}
					onClick={() => visible()}>
					{t('loan.anotherTimeMortgage')}
				</div>
			)}
		</div>
	);

	const memoChild = useMemo(() => {
		return <Line {...config} />;
	}, [config]);

	return (
		<div>
			<div style={{ marginTop: 0 }}>
				<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 600 }}>
					{t('loan.ourAdvice').toUpperCase()}
				</Text>
			</div>
			<Skeleton active loading={loading} paragraph={false} />
			{!loading && (
				<>
					{rateType === 'fixed' && false && (
						//* esto es comparativa ok es decir no hay mejora posible

						<Card className="gb-card-loan-init" style={{ marginTop: 16, width: 317 }}>
							<Row>
								<Col span={3}>
									<InfoCircleFilled
										style={{
											fontSize: 18,
											color: '#1890FF',
											fontWeight: 600,
											marginTop: 16
										}}
									/>
								</Col>
								<Col span={21} style={{ display: 'flex', flexDirection: 'column' }}>
									<Text
										style={{
											color: '#2F4858',
											fontSize: 14,
											fontWeight: 600
										}}>
										{t('loan.adviceOk') + '100,10€' + t('loan.adviceOk1')}
									</Text>

									<Text
										style={{
											color: '#2F4858',
											fontSize: 14,
											fontWeight: 400
										}}>
										{t('loan.adviceOk2')}
									</Text>
								</Col>
							</Row>
						</Card>
					)}

					{rateType === 'fixed' && tip === 'BETTER_FIXED_RATE' && dataTips && (
						<>
							<Card className="gb-card-loan-init-green-desktop" style={{ marginTop: 24 }}>
								<Row>
									<Col span={3} style={{ marginTop: 15 }}>
										<img src={check} alt="asdf" style={{ width: 24, height: 24 }}></img>
									</Col>
									<Col span={21} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 400
											}}>
											{t('loan.comparativeQuotaVar') +
												' ' +
												numeral(dataTips.monthTotalQuota).format('0,0.00 $') +
												'/' +
												t('viability.monthsEstimated')}
										</Text>

										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 600,
												marginTop: 8
											}}>
											{t('loan.fixedBest1') +
												' ' +
												numeral(dataTips.savings).format('0,0.00 $') +
												t('loan.comparativeQuotaSavings')}
										</Text>
									</Col>
								</Row>
							</Card>

							<Card className="gb-card-loan-init-blue-data-desktop" style={{ marginTop: 24 }}>
								<Row style={{ width: '100%', justifyContent: 'center' }}>
									<Text style={{ fontSize: 16, color: '#02C3CD', fontWeight: 600 }}>
										{t('loan.fixedBestOffert')}
									</Text>
								</Row>

								<Row style={{ width: '100%', marginTop: 16, textAlign: 'center' }}>
									<Col span={7} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#748EA0',
												fontSize: 12,
												fontWeight: 400
											}}>
											{'TAE'}
										</Text>
										<Text
											style={{
												color: '#02C3CD',
												fontSize: 16,
												fontWeight: 400
											}}>
											{dataTips.gibobs.tae + '%'}
										</Text>
									</Col>
									<Col span={9} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#748EA0',
												fontSize: 12,
												fontWeight: 400
											}}>
											{t('loan.pendingterm')}
										</Text>
										<Text
											style={{
												color: '#02C3CD',
												fontSize: 16,
												fontWeight: 400
											}}>
											{numeral(dataTips.gibobs.term / 12).format('0,0') +
												' ' +
												t('viability.years')}
										</Text>
									</Col>
									<Col span={6} style={{ minWidth: '90px' }}>
										<div
											style={{
												float: 'right',
												display: 'flex',
												flexDirection: 'column'
											}}>
											<Text
												style={{
													color: '#748EA0',
													fontSize: 12,
													fontWeight: 400
												}}>
												{t('loan.quotaAd')}
											</Text>
											<Text
												style={{
													color: '#02C3CD',
													fontSize: 16,
													fontWeight: 400
												}}>
												{numeral(dataTips.gibobs.quota).format('0,0.00 $') +
													'/' +
													t('viability.monthsEstimated')}
											</Text>
										</div>
									</Col>
								</Row>
								<Row
									style={{
										display: 'flex',
										flexDirection: 'column',
										textAlign: 'center',
										marginTop: 16
									}}>
									<Text
										style={{
											color: '#748EA0',
											fontSize: 14,
											fontWeight: 400
										}}>
										{t('viability.totalSavings')}
									</Text>
									<Text
										style={{
											color: '#02C3CD',
											fontSize: 20,
											fontWeight: 600
										}}>
										{numeral(dataTips.gibobs.totalSavings).format('0,0.00 $')}
									</Text>
								</Row>
							</Card>
							{buttonInterest}

							<div style={{ marginTop: 32 }}>
								<Text style={{ fontSize: 10, color: '#748EA0', fontWeight: 400 }}>
									{t('viability.explanationCalculationTae')}
								</Text>
							</div>
						</>
					)}

					{rateType === 'variable' &&
						tip === 'CHANGE_TO_VARIABLE_RATE' &&
						dataTips &&
						dataTips.category !== 'WORSE' &&
						data && (
							<>
								<Card
									className="gb-card-loan-init-green-desktop"
									style={{ marginTop: 24, width: 612 }}>
									<Row>
										<Col span={3} style={{ marginTop: 15 }}>
											<img src={check} alt="asdf" style={{ width: 24, height: 24 }}></img>
										</Col>
										<Col span={21} style={{ display: 'flex', flexDirection: 'column' }}>
											<Text
												style={{
													color: '#2F4858',
													fontSize: 14,
													fontWeight: 400
												}}>
												{t('loan.comparativeQuotaVar') +
													' ' +
													numeral(dataTips.monthTotalQuota).format('0,0.00 $') +
													'/' +
													t('viability.monthsEstimated')}
											</Text>

											<Text
												style={{
													color: '#2F4858',
													fontSize: 14,
													fontWeight: 600,
													marginTop: 8
												}}>
												{t('loan.fixedBest1') +
													' ' +
													numeral(dataTips.savings).format('0,0.00 $') +
													t('loan.comparativeQuotaSavings')}
											</Text>
										</Col>
									</Row>
								</Card>

								<Card
									className="gb-card-loan-init-blue-data-desktop"
									style={{ marginTop: 24, width: 612 }}>
									<Row style={{ width: '100%', justifyContent: 'center' }}>
										<Text style={{ fontSize: 16, color: '#02C3CD', fontWeight: 600 }}>
											{t('loan.variableBestOffert')}
										</Text>
									</Row>

									<Row style={{ width: '100%', marginTop: 16, textAlign: 'center' }}>
										<Col span={7} style={{ display: 'flex', flexDirection: 'column' }}>
											<Text
												style={{
													color: '#748EA0',
													fontSize: 12,
													fontWeight: 400
												}}>
												{'TAE'}
											</Text>
											<Text
												style={{
													color: '#02C3CD',
													fontSize: 16,
													fontWeight: 400
												}}>
												{'Eur+' + dataTips.gibobs.tae + '%'}
											</Text>
										</Col>
										<Col span={9} style={{ display: 'flex', flexDirection: 'column' }}>
											<Text
												style={{
													color: '#748EA0',
													fontSize: 12,
													fontWeight: 400
												}}>
												{t('loan.pendingterm')}
											</Text>
											<Text
												style={{
													color: '#02C3CD',
													fontSize: 16,
													fontWeight: 400
												}}>
												{numeral(dataTips.gibobs.term / 12).format('0,0') +
													' ' +
													t('viability.years')}
											</Text>
										</Col>
										<Col span={6} style={{ minWidth: '90px' }}>
											<div
												style={{
													float: 'right',
													display: 'flex',
													flexDirection: 'column'
												}}>
												<Text
													style={{
														color: '#748EA0',
														fontSize: 12,
														fontWeight: 400
													}}>
													{t('loan.quotaAd')}
												</Text>
												<Text
													style={{
														color: '#02C3CD',
														fontSize: 16,
														fontWeight: 400
													}}>
													{numeral(dataTips.gibobs.quota).format('0,0.00 $') +
														'/' +
														t('viability.monthsEstimated')}
												</Text>
											</div>
										</Col>
									</Row>
									<Row
										style={{
											display: 'flex',
											flexDirection: 'column',
											textAlign: 'center',
											marginTop: 16
										}}>
										<Text
											style={{
												color: '#748EA0',
												fontSize: 14,
												fontWeight: 400
											}}>
											{t('viability.totalSavings')}
										</Text>
										<Text
											style={{
												color: '#02C3CD',
												fontSize: 20,
												fontWeight: 600
											}}>
											{numeral(dataTips.gibobs.totalSavings).format('0,0.00 $')}
										</Text>
									</Row>
								</Card>
								<Card className="gcardMovile" style={{ marginTop: 24, width: 612 }}>
									<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
										{t('loan.variableWarning')}
									</Text>
									<div
										style={{
											marginTop: 16,
											display: 'flex',
											flexDirection: 'column'
										}}>
										<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
											{t('loan.variableWarning1')}
										</Text>
										<Text
											style={{
												color: '#748EA0',
												fontSize: 14,
												fontWeight: 400,
												marginTop: 24
											}}>
											{t('loan.variableWarning2')}
										</Text>
									</div>

									<div style={{ marginTop: 32 }}>{memoChild}</div>
								</Card>
								{buttonInterest}

								<div style={{ marginTop: 32 }}>
									<Text style={{ fontSize: 10, color: '#748EA0', fontWeight: 400 }}>
										{t('viability.explanationCalculationTae')}
									</Text>
								</div>
							</>
						)}

					{rateType === 'fixed' && tip === 'CHANGE_TO_FIXED_RATE' && dataTips && (
						<>
							<Card className="gb-card-loan-init-green-desktop" style={{ marginTop: 24 }}>
								<Row>
									<Col span={3} style={{ marginTop: 15 }}>
										<img src={check} alt="asdf" style={{ width: 24, height: 24 }}></img>
									</Col>
									<Col span={21} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 400
											}}>
											{t('loan.comparativeQuotaVar') +
												' ' +
												numeral(dataTips.monthTotalQuota).format('0,0.00 $') +
												'/' +
												t('viability.monthsEstimated')}
										</Text>

										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 600,
												marginTop: 8
											}}>
											{t('loan.fixedBest1') +
												' ' +
												numeral(dataTips.savings).format('0,0.00 $') +
												t('loan.comparativeQuotaSavings')}
										</Text>
									</Col>
								</Row>
							</Card>

							<Card className="gb-card-loan-init-blue-data-desktop" style={{ marginTop: 24 }}>
								<Row style={{ width: '100%', justifyContent: 'center' }}>
									<Text style={{ fontSize: 16, color: '#02C3CD', fontWeight: 600 }}>
										{t('loan.variableBestOffert')}
									</Text>
								</Row>

								<Row style={{ width: '100%', marginTop: 16, textAlign: 'center' }}>
									<Col span={7} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#748EA0',
												fontSize: 12,
												fontWeight: 400
											}}>
											{'TAE'}
										</Text>
										<Text
											style={{
												color: '#02C3CD',
												fontSize: 16,
												fontWeight: 400
											}}>
											{dataTips.gibobs.tae + '%'}
										</Text>
									</Col>
									<Col span={9} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#748EA0',
												fontSize: 12,
												fontWeight: 400
											}}>
											{t('loan.pendingterm')}
										</Text>
										<Text
											style={{
												color: '#02C3CD',
												fontSize: 16,
												fontWeight: 400
											}}>
											{numeral(dataTips.gibobs.term / 12).format('0,0') +
												' ' +
												t('viability.years')}
										</Text>
									</Col>
									<Col span={6} style={{ minWidth: '90px' }}>
										<div
											style={{
												float: 'right',
												display: 'flex',
												flexDirection: 'column'
											}}>
											<Text
												style={{
													color: '#748EA0',
													fontSize: 12,
													fontWeight: 400
												}}>
												{t('loan.quotaAd')}
											</Text>
											<Text
												style={{
													color: '#02C3CD',
													fontSize: 16,
													fontWeight: 400
												}}>
												{numeral(dataTips.gibobs.quota).format('0,0.00 $') +
													'/' +
													t('viability.monthsEstimated')}
											</Text>
										</div>
									</Col>
								</Row>
								<Row
									style={{
										display: 'flex',
										flexDirection: 'column',
										textAlign: 'center',
										marginTop: 16
									}}>
									<Text
										style={{
											color: '#748EA0',
											fontSize: 14,
											fontWeight: 400
										}}>
										{t('viability.totalSavings')}
									</Text>
									<Text
										style={{
											color: '#02C3CD',
											fontSize: 20,
											fontWeight: 600
										}}>
										{numeral(dataTips.gibobs.totalSavings).format('0,0.00 $')}
									</Text>
								</Row>
							</Card>
							{buttonInterest}

							<div style={{ marginTop: 32 }}>
								<Text style={{ fontSize: 10, color: '#748EA0', fontWeight: 400 }}>
									{t('viability.explanationCalculationTae')}
								</Text>
							</div>
						</>
					)}

					{rateType === 'variable' && tip === 'BETTER_VARIABLE_RATE' && dataTips && (
						<>
							<Card className="gb-card-loan-init-green-desktop" style={{ marginTop: 24 }}>
								<Row>
									<Col span={3} style={{ marginTop: 15 }}>
										<img src={check} alt="asdf" style={{ width: 24, height: 24 }}></img>
									</Col>
									<Col span={21} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 400
											}}>
											{t('loan.comparativeQuotaVar') +
												' ' +
												numeral(dataTips.monthTotalQuota).format('0,0.00 $') +
												'/' +
												t('viability.monthsEstimated')}
										</Text>

										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 600,
												marginTop: 8
											}}>
											{t('loan.fixedBest1') +
												' ' +
												numeral(dataTips.savings).format('0,0.00 $') +
												t('loan.comparativeQuotaSavings')}
										</Text>
									</Col>
								</Row>
							</Card>

							<Card className="gb-card-loan-init-blue-data-desktop" style={{ marginTop: 24 }}>
								<Row style={{ width: '100%', justifyContent: 'center' }}>
									<Text style={{ fontSize: 16, color: '#02C3CD', fontWeight: 600 }}>
										{t('loan.variableBestOffert')}
									</Text>
								</Row>

								<Row style={{ width: '100%', marginTop: 16, textAlign: 'center' }}>
									<Col span={7} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#748EA0',
												fontSize: 12,
												fontWeight: 400
											}}>
											{'TAE'}
										</Text>
										<Text
											style={{
												color: '#02C3CD',
												fontSize: 16,
												fontWeight: 400
											}}>
											{'Eur + ' + dataTips.gibobs.tae}
										</Text>
									</Col>
									<Col span={9} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#748EA0',
												fontSize: 12,
												fontWeight: 400
											}}>
											{t('loan.pendingterm')}
										</Text>
										<Text
											style={{
												color: '#02C3CD',
												fontSize: 16,
												fontWeight: 400
											}}>
											{numeral(dataTips.gibobs.term / 12).format('0,0') +
												' ' +
												t('viability.years')}
										</Text>
									</Col>
									<Col span={6} style={{ minWidth: '90px' }}>
										<div
											style={{
												float: 'right',
												display: 'flex',
												flexDirection: 'column'
											}}>
											<Text
												style={{
													color: '#748EA0',
													fontSize: 12,
													fontWeight: 400
												}}>
												{t('loan.quotaAd')}
											</Text>
											<Text
												style={{
													color: '#02C3CD',
													fontSize: 16,
													fontWeight: 400
												}}>
												{numeral(dataTips.gibobs.quota).format('0,0.00 $') +
													'/' +
													t('viability.monthsEstimated')}
											</Text>
										</div>
									</Col>
								</Row>
								<Row
									style={{
										display: 'flex',
										flexDirection: 'column',
										textAlign: 'center',
										marginTop: 16
									}}>
									<Text
										style={{
											color: '#748EA0',
											fontSize: 14,
											fontWeight: 400
										}}>
										{t('viability.totalSavings')}
									</Text>
									<Text
										style={{
											color: '#02C3CD',
											fontSize: 20,
											fontWeight: 600
										}}>
										{numeral(dataTips.gibobs.totalSavings).format('0,0.00 $')}
									</Text>
								</Row>
							</Card>
							{buttonInterest}
							<div style={{ marginTop: 32 }}>
								<Text style={{ fontSize: 10, color: '#748EA0', fontWeight: 400 }}>
									{t('viability.explanationCalculationTae')}
								</Text>
							</div>
						</>
					)}

					{rateType === 'noBetter' && tip === 'CHANGE_TO_WORSE_FIXED_RATE' && dataTips && (
						<>
							<Card className="gb-card-loan-init-blue-data-no-better" style={{ marginTop: 24 }}>
								<Row>
									<Col span={3} style={{ marginTop: 15 }}>
										<img src={bulp} alt="asdf" style={{ width: 24, height: 24 }}></img>
									</Col>
									<Col span={21} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 400
											}}>
											{t('loan.comparativeNoBetter') +
												' ' +
												numeral(dataTips.monthTotalQuota).format('0,0.00 $') +
												'/' +
												t('viability.monthsEstimated')}
										</Text>

										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 600,
												marginTop: 8
											}}>
											{t('loan.comparativeNoBetter2') +
												' ' +
												numeral(dataTips.savings).format('0,0.00 $') +
												t('loan.messageVariableQuote3')}
										</Text>
									</Col>
								</Row>
							</Card>

							<Card className="gb-card-loan-init-blue-data-desktop" style={{ marginTop: 24 }}>
								<Row style={{ width: '100%', justifyContent: 'center' }}>
									<Text style={{ fontSize: 16, color: '#02C3CD', fontWeight: 600 }}>
										{t('loan.variableBestOffert')}
									</Text>
								</Row>

								<Row style={{ width: '100%', marginTop: 16, textAlign: 'center' }}>
									<Col span={7} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#748EA0',
												fontSize: 12,
												fontWeight: 400
											}}>
											{'TAE'}
										</Text>
										<Text
											style={{
												color: '#02C3CD',
												fontSize: 16,
												fontWeight: 400
											}}>
											{dataTips.gibobs.tae + '%'}
										</Text>
									</Col>
									<Col span={9} style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												color: '#748EA0',
												fontSize: 12,
												fontWeight: 400
											}}>
											{t('loan.pendingterm')}
										</Text>
										<Text
											style={{
												color: '#02C3CD',
												fontSize: 16,
												fontWeight: 400
											}}>
											{numeral(dataTips.gibobs.term / 12).format('0,0') +
												' ' +
												t('viability.years')}
										</Text>
									</Col>
									<Col span={6} style={{ minWidth: '90px' }}>
										<div
											style={{
												float: 'right',
												display: 'flex',
												flexDirection: 'column'
											}}>
											<Text
												style={{
													color: '#748EA0',
													fontSize: 12,
													fontWeight: 400
												}}>
												{t('loan.quotaAd')}
											</Text>
											<Text
												style={{
													color: '#02C3CD',
													fontSize: 16,
													fontWeight: 400
												}}>
												{numeral(dataTips.gibobs.quota).format('0,0.00 $') +
													'/' +
													t('viability.monthsEstimated')}
											</Text>
										</div>
									</Col>
								</Row>
								<Row
									style={{
										display: 'flex',
										flexDirection: 'column',
										textAlign: 'center',
										marginTop: 16
									}}>
									<Text
										style={{
											color: '#748EA0',
											fontSize: 14,
											fontWeight: 400
										}}>
										{t('viability.totalSavings')}
									</Text>
									<Text
										style={{
											color: '#02C3CD',
											fontSize: 20,
											fontWeight: 600
										}}>
										{numeral(dataTips.gibobs.totalSavings).format('0,0.00 $')}
									</Text>
								</Row>
							</Card>

							{buttonInterest}

							<div style={{ marginTop: 32 }}>
								<Text style={{ fontSize: 10, color: '#748EA0', fontWeight: 400 }}>
									{t('viability.explanationCalculationTae')}
								</Text>
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
};

export default CompareAutomatic;
