import React, { useEffect, useState } from 'react';
import { Row, Button, Card, Col, Typography, Form, Select, Tabs, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { renderComponent } from '../../../select/typeSelect';
import axios from 'axios';
import env from '../../../environment';
import _ from 'lodash';
import CalculatorDataClientQualifyMobile from './CalculatorDataClientQualifyMobile';
import leftArrowIcon from '../../../assets/icons/leftArrowIcon.svg';
import { InputNumber, ProvinceSelector } from 'afrodita';

const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const CalculatorQualifyMobileData = ({}) => {
	const location = useLocation();
	const { t } = useTranslation();
	const rol = useSelector((state) => state.auth.roles);
	const navigate = useNavigate();;
	const [loading, setLoading] = useState(true);
	const [form] = Form.useForm();
	const [countries, setContries] = useState();
	const lang = localStorage.getItem('i18nextLng');
	const userToken = useSelector((state) => state.auth.token);
	const [result, setResult] = useState(undefined);
	const [price, setPrice] = useState(location.state ? location.state.price : 10000);
	const [savings, setSavings] = useState(0);
	const dataProfile = useSelector((state) => state.data.dataProfile);
	const [participants, setParticipants] = useState(undefined);
	const [province, setProvince] = useState(dataProfile && dataProfile.province);
	const [family, setFamily] = useState(false);
	const [participantsAll, setParticipantsAll] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, [1500]);
		axios.get(`${env.api.url}/v1/countries`).then((response) => {
			setContries(response.data.data);
		});

		if (result === undefined && location.state !== undefined) {
			setResult(location?.state?.result?.formValues);
		}
	}, []);

	const renderAvailable = () => {
		if (location?.state?.edit) {
			return false;
		} else {
			if (participantsAll === '1') {
				if (result && result.age1) {
					return false;
				} else {
					return true;
				}
			} else if (participantsAll === '2') {
				if (result && result.age2 && result.age1) {
					return false;
				} else {
					return true;
				}
			} else {
				return true;
			}
		}
	};

	const sendValues = (values) => {
		//enviar a back

		let owners = [];

		if (!_.isNil(result.activity2)) {
			owners = [
				{
					activity: result.activity1,
					seniority: result.seniority1,
					netIncomes: result.netIncomes1,
					loanPayment: result.loanPayment1,
					age: result.age1,
					cityzenship: _.isNil(result.cityzenship1) ? 'ES' : result.cityzenship1
				},
				{
					activity: result.activity2,
					seniority: result.seniority2,
					netIncomes: result.netIncomes2,
					loanPayment: result.loanPayment2,
					age: result.age2,
					cityzenship: _.isNil(result.cityzenship2) ? 'ES' : result.cityzenship2
				}
			];
		} else {
			owners = [
				{
					activity: result.activity1,
					seniority: result.seniority1,
					netIncomes: result.netIncomes1,
					loanPayment: result.loanPayment1,
					age: result.age1,
					cityzenship: _.isNil(result.cityzenship1) ? 'ES' : result.cityzenship1
				}
			];
		}

		axios
			.post(
				`${env.api.url}/mortgages/simulations/client-qualification/mortgage-viability`,
				{
					id:
						location && !_.isNil(location.state)
							? location.state.result.result.simulation.id
							: undefined,
					simulationType: rol === 'realestate' ? 'B2B' : 'B2C',
					maxBudget:
						price > 10000
							? price
							: location.state === undefined
							? undefined
							: location.state.price.maxBudget,
					savings: values.savings,
					propertyType: values.propertyType,
					province: values.province,
					fixed: location.state === undefined ? 1.9 : location.state.fixed,
					quotes: location.state === undefined ? 360 : location.state.quotes,
					numerousFamily: family,
					participants: {
						houseType: values.houseType,
						participants: !_.isNil(result.activity2) ? 2 : 1,
						owners: owners
					}
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				setResult(response.data.data);

			
				if (rol === 'realestate') {
					navigate(`/calculators/qualify/client`,{
						state: {
							price:
								location.state && location.state.price !== undefined
									? location.state.price
									: undefined,
							result: response.data.data,
							formValues: result,
							...form.getFieldsValue()
						}
					})
				}else {
					navigate(`/calculators/financing`,{
						state: {
							price:
								location.state && location.state.price !== undefined
									? location.state.price
									: undefined,
							result: response.data.data,
							formValues: result,
							...form.getFieldsValue()
						}
					})
				}
				
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const participantsTabs = [];
	const participantsTabsDesktop = [];

	const renderTabs = (index) => {
		return (
			<TabPane
				tab={<span>{t(`onboarding.owner${index}`)}</span>}
				key={`${index}`}
				forceRender={true}>
				{renderForm(index)}
			</TabPane>
		);
	};

	const renderTabsDesktop = (index) => {
		return (
			<TabPane
				tab={<span>{t(`onboarding.owner${index}`)}</span>}
				key={`${index}`}
				forceRender={true}>
				{renderFormDesktop(index)}
			</TabPane>
		);
	};

	const renderFormDesktop = (data) => {
		return (
			<>
				<Row gutter={[60, 10]} style={{ width: '100%' }}>
					<Col span={6}>
						<Form.Item
							rules={[
								{
									required: true
								}
							]}
							label={
								<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
									{t('form.activity')}
								</Text>
							}
							name={`activity${data}`}
							style={{ width: '100%' }}>
							<Select id={`activity${data}`} placeholder={t('form.activity')}>
								{renderComponent('activity').options.map((situation) => {
									return (
										<Option key={situation.value} value={situation.value}>
											{t(situation.label)}
										</Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>

					<Col span={6}>
						<Form.Item
							rules={[
								{
									required: true
								}
							]}
							label={
								<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
									{t('form.seniority')}
								</Text>
							}
							name={`seniority${data}`}>
							<InputNumber
								id={`seniority${data}`}
								placeholder={0}
								step={1}
								gbType="number"
								className='gb-input-new-years'
								style={{
									width: '100%'
								}}
							/>
						</Form.Item>
					</Col>

					<Col span={6}>
						<Form.Item
							rules={[
								{
									required: true
								}
							]}
							label={
								<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
									{t('form.netIncomesOwner')}
								</Text>
							}
							name={`netIncomes${data}`}>
							<InputNumber
								id={`netIncomes${data}`}
								suffix={'€/' + t('viability.monthsEstimated')}
								placeholder={0}
								max={999999}
								gbType="currency"
								style={{
									borderColor: '#c0daee',
									width: '100%',
									borderRadius: '4px'
								}}
							/>
						</Form.Item>
					</Col>

					<Col span={6}>
						<Form.Item
							label={
								<div>
									<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
										{t('onboarding.haveOtherLoans')}
									</Text>
									<div className="explination-others-incomes">
										<Text
											style={{
												color: '#748EA0',
												fontSize: 10,
												fontWeight: 400
											}}>
											{t('form.haveLoansInmo0')}
										</Text>
									</div>
								</div>
							}
							rules={[
								{
									required: true
								}
							]}
							name={`loanPayment${data}`}>
							<InputNumber
								id={`loanPayment${data}`}
								suffix={'€/' + t('viability.monthsEstimated')}
								placeholder={0}
								max={99999}
								gbType="currency"
								style={{
									borderColor: '#c0daee',
									width: '100%',
									borderRadius: '4px'
								}}
							/>
						</Form.Item>
					</Col>

					<Col span={6}>
						<Form.Item
							label={
								<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
									{t('calculator.ag')}
								</Text>
							}
							rules={[
								{
									required: true
								}
							]}
							name={`age${data}`}>
							<InputNumber
								id={`age${data}`}
								className='gb-input-new-years'
								placeholder={18}
								gbType="number"
								style={{
									borderColor: '#c0daee',
									width: '100%',
									borderRadius: '4px'
								}}
							/>
						</Form.Item>
					</Col>

					<Col span={6}>
						<Form.Item
							label={
								<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
									{t('form.country')}
								</Text>
							}
							rules={[
								{
									required: true
								}
							]}
							name={`cityzenship${data}`}>
							<Select
								id="country"
								showSearch
								placeholder={t('form.country')}
								style={{ width: '100%' }}
								filterOption={(input, option) => {
									return (
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
										option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
									);
								}}>
								{countries &&
									countries.countries.map((paises) => {
										return (
											<Option key={paises.code} value={paises.code}>
												{lang === 'en' ? paises.name_en : paises.name_es}
											</Option>
										);
									})}
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<div style={{ textAlign: 'center', width: '100%', marginTop: 24 }}>
					<Button
						id="gb-mortgage-buy-situation-mobile"
						className="button-primary-gibobs-inmo"
						type="primary"
						style={{
							fontSize: 14,
							height: 40,
							fontWeight: 600
						}}
						htmlType="submit">
						{t('calculator.buttonCalculate')}
					</Button>
				</div>
			</>
		);
	};

	const renderForm = (data) => {
		return (
			<>
				<Row style={{ width: '100%' }}>
					<Col span={12} style={{ marginTop: 6 }}>
						<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
							{t('form.activity')}
						</Text>
					</Col>
					<Col span={12}>
						<Form.Item name={`activity${data}`} style={{ width: '100%' }}>
							<Select id={`activity${data}`} placeholder={t('form.activity')}>
								{renderComponent('activity').options.map((situation) => {
									return (
										<Option key={situation.value} value={situation.value}>
											{t(situation.label)}
										</Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row style={{ width: '100%' }}>
					<Col span={12} style={{ marginTop: 6 }}>
						<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
							{t('form.seniority')}
						</Text>
					</Col>
					<Col span={12}>
						<Form.Item name={`seniority${data}`}>
							<InputNumber
								max={40}
								id={`seniority${data}`}
								placeholder={t('0')}
								className='gb-input-new-years'
								style={{
									width: '100%'
								}}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Row style={{ width: '100%' }}>
					<Col span={12} style={{ marginTop: 6 }}>
						<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
							{t('form.netIncomesOwner')}
						</Text>
					</Col>
					<Col span={12}>
						<Form.Item name={`netIncomes${data}`}>
							<InputNumber
								id={`netIncomes${data}`}
								suffix={'€/' + t('viability.monthsEstimated')}
								placeholder="0"
								gbType="currency"
								style={{
									borderColor: '#c0daee',
									width: '100%',
									borderRadius: '4px'
								}}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Row style={{ width: '100%' }}>
					<Col span={12} style={{ marginTop: 6 }}>
						<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
							{t('onboarding.haveOtherLoans')}
						</Text>
						<div className="explination-others-incomes">
							<Text style={{ color: '#748EA0', fontSize: 10, fontWeight: 400 }}>
								{t('form.haveLoansInmo0')}
							</Text>
						</div>
					</Col>
					<Col span={12}>
						<Form.Item name={`loanPayment${data}`}>
							<InputNumber
								id={`loanPayment${data}`}
								suffix={'€/' + t('viability.monthsEstimated')}
								placeholder="0"
								gbType="currency"
								style={{
									borderColor: '#c0daee',
									width: '100%',
									borderRadius: '4px'
								}}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Row style={{ width: '100%' }}>
					<Col span={12} style={{ marginTop: 6 }}>
						<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
							{t('calculator.age')}
						</Text>
					</Col>
					<Col span={12}>
						<Form.Item name={`age${data}`}>
							<InputNumber
								id={`age${data}`}
								className='gb-input-new-years'
								placeholder="18"
								gbType="number"
								style={{
									borderColor: '#c0daee',
									width: '100%',
									borderRadius: '4px'
								}}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Row style={{ width: '100%' }}>
					<Col span={12} style={{ marginTop: 6 }}>
						<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
							{t('form.country')}
						</Text>
					</Col>
					<Col span={12}>
						<Form.Item name={`cityzenship${data}`}>
							<Select
								id="country"
								showSearch
								placeholder={t('form.country')}
								style={{ width: '100%' }}
								filterOption={(input, option) => {
									return (
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
										option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
									);
								}}>
								{countries &&
									countries.countries.map((paises) => {
										return (
											<Option key={paises.code} value={paises.code}>
												{lang === 'en' ? paises.name_en : paises.name_es}
											</Option>
										);
									})}
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Form.Item>
					<Card
						className="cardButtonStyle"
						style={{
							left: 0,
							backgroundColor: '#FAFEFE'
						}}>
						<Button
							id="gb-mortgage-buy-situation-mobile"
							className="button-primary-gibobs-inmo"
							type="primary"
							style={{
								fontSize: 16,
								width: '100%',
								height: 48,
								fontWeight: 600
							}}
							htmlType="submit">
							{t('calculator.buttonCalculate')}
						</Button>
					</Card>
				</Form.Item>
			</>
		);
	};

	for (let i = 1; i <= parseInt(participants); i++) {
		participantsTabs.push(renderTabs(i));
	}

	for (let i = 1; i <= parseInt(participants); i++) {
		participantsTabsDesktop.push(renderTabsDesktop(i));
	}

	const sendValuesHistory = () => {
		if(rol === 'realestate') {
			navigate('/sales-tools/new-requests');
		} else {
			navigate(`/calculators`,{
				state: {
					result: location.state ? location.state.result.result : undefined,
					formValues: location.state ? location.state.result.formValue : undefined
				}
			});

		}
	};

	return (
		<>
			<div style={{ padding: 16 }}>
				<Row style={{ width: '100%' }}>
					<Col span={24}>
						{/**TODO */}
						<a onClick={() => sendValuesHistory()}>
							<img src={leftArrowIcon} alt="asdf" style={{ color: '#2F4858', fontSize: 20 }} />
						</a>
						<Text
							style={{
								color: '#2F4858',
								fontSize: 16,
								fontWeight: 600,
								marginLeft: 16
							}}>
							{rol === 'realestate' ? t('calculator.cardTitle3') : t('fee.calculatorTop')}
						</Text>
					</Col>
				</Row>
				<div style={{ marginBottom: 200 }}>
					<Skeleton active loading={loading} paragraph={false} />
					{!loading && (
						<Form
							className="gb-form-profile-headlines-mobile"
							scrollToFirstError={true}
							form={form}
							name="basic"
							onFinish={sendValues}
							initialValues={{
								...location?.state?.result?.result?.simulation?.simulationData,
								province:
									location?.state?.result === undefined
										? dataProfile && dataProfile?.province
										: location?.state?.result?.result?.simulation?.simulationData?.province,
								...location?.state?.result?.formValues,
								participants: location?.state?.result?.formValues?.activity2 ? '2' : '1'
							}}>
							<Row
								gutter={24}
								style={{
									marginLeft: 'auto',
									marginRight: 'auto',
									marginTop: 16
								}}>
								<Row style={{ width: '100%' }}>
									<Col span={12} style={{ marginTop: 6 }}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 400
											}}>
											{t('form.Savings')}
										</Text>
									</Col>
									<Col span={12} style={{ textAlign: 'right' }}>
										<Form.Item
											rules={[
												{
													required: true,
													message: t('calculator.warningErrorSavings')
												}
											]}
											name="savings">
											<InputNumber
												placeholder={0}
												gbType="currency"
												gbDecimalType="integer"
												suffix={'€'}
												min={0}
												max={99000000}
												style={{
													height: 40
												}}
											/>
										</Form.Item>
									</Col>
								</Row>

								<Row style={{ width: '100%' }}>
									<Col span={12} style={{ marginTop: 6 }}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 400
											}}>
											{t('viability.provinceViability')}
										</Text>
									</Col>
									<Col span={12}>
										<Form.Item
											rules={[
												{
													required: true
												}
											]}
											name="province">
											<ProvinceSelector style={{ width: '100%' }} placeholder={t('form.select')}/>
										</Form.Item>
									</Col>
								</Row>
								<Row style={{ width: '100%' }}>
									<Col span={12} style={{ marginTop: 6 }}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 400
											}}>
											{t('form.propertyType')}
										</Text>
									</Col>
									<Col span={12}>
										<Form.Item
											name="propertyType"
											rules={[
												{
													required: true
												}
											]}>
											<Select placeholder={t('form.select')} style={{ width: '100%' }}>
												<Option value="newConstruction">{t('form.newBuild')}</Option>
												<Option value="secondHand">{t('form.secondHand')}</Option>
											</Select>
										</Form.Item>
									</Col>
								</Row>

								<Row style={{ width: '100%' }}>
									<Col span={12}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 400
											}}>
											{t('viability.expectedUse')}
										</Text>
									</Col>
									<Col span={12}>
										<Form.Item
											rules={[
												{
													required: true
												}
											]}
											name="houseType">
											<Select placeholder={t('form.select')} style={{ width: '100%', height: 40 }}>
												<Option value="main">{t('form.main')}</Option>
												<Option value="second">{t('form.second')}</Option>
												<Option value="investor">{t('form.investor')}</Option>
											</Select>
										</Form.Item>
									</Col>
								</Row>
							</Row>

							<div style={{ height: 500 }}>
								<CalculatorDataClientQualifyMobile
									setResult={setResult}
									price={price}
									savings={savings}
									province={province}
									form={form}
									formValues={location?.state?.result?.formValues}
									setParticipantsAll={setParticipantsAll}></CalculatorDataClientQualifyMobile>
							</div>

							<Card className="cardButtonStyle" style={{ left: 0, marginBottom: -2, height: 80 }}>
								<Button
									id="gb-mortgage-buy-situation-mobile"
									type="primary"
									disabled={renderAvailable()}
									style={{
										fontSize: 16,
										height: 48,
										fontWeight: 600,
										width: '100%'
									}}
									htmlType="submit">
									{t('newRequest.getFile')}
								</Button>
							</Card>
						</Form>
					)}
				</div>
			</div>
		</>
	);
};

export default CalculatorQualifyMobileData;
