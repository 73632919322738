import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Slider, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import axios from 'axios';
import env from '../../../../environment';
import { useSelector } from 'react-redux';
import {  Line } from '@ant-design/plots';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { isMobileOnly } from 'react-device-detect';

const { Text } = Typography;

const EuriborCalculator = ({ visible, onCreate, onCancel, title, loan }) => {
	const { t } = useTranslation();
	const userToken = useSelector((state) => state.auth.token);
	const [valueCuota, setValueCuota] = useState(undefined);
	const [data, setData] = useState(undefined);
	const [, setEuriborList] = useState([]);
	const [euriborDataCurrent, setEuriborDataCurrent] = useState(undefined);
	const analytics = getAnalytics();

	useEffect(() => {
		getEuribor();
		euriborData();
		euriborCurrent();
		obteinQuota();
		logEvent(analytics, 'screen_view', {
			screen_name: 'loanDetail.euribor'
		});
	}, []);

	const obteinQuota = (euribor) => {
		const objValues = {
			indexType: 'euribor',
			rateType: loan?.loan?.rateType,
			amount: loan?.loan?.amount,
			term: Number(loan?.loan?.term),
			rate: loan?.loan?.spread
		};

		objValues.euribor = euribor;

		axios
			.post(`${env.api.url}/v1/loans/calculate-monthly-quote`, objValues, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				if (response.data.success) {
					if (response.data.data.quota > 0) {
						setValueCuota(response.data.data.quota);
					}
				}
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const getEuribor = () => {
		axios
			.get(`${env.api.url}/v1/rates/by-month/euribor`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setData(response.data.data.rates);
			})
			.catch((error) => {
				console.log(error)
			});
	};

	let config = {
		data,
		xField: 'date',
		yField: 'index',
		height: 200,
		label: {
			// 可手动配置 label 数据标签位置
			position: 'middle',
			// 'top', 'bottom', 'middle',
			// 配置样式
			style: {
				fill: '#FFFFFF',
				opacity: 0.6
			}
		},
		yAxis: {},
		xAxis: {
			tickCount: 5,
			label: {
				autoHide: true,
				autoRotate: true
			}
		},
		slider: {
			start: 0,
			end: 10
		},
		meta: {
			date: {
				alias: 'Año'
			},
			index: {
				alias: 'Euribor'
			}
		},
		color: '#02C3CD'
	};

	const euriborData = () => {
		axios
			.get(`${env.api.url}/v1/loans/calculate-list-euribor`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				setEuriborList(response.data.data.euriborList);
			});
	};

	const euriborCurrent = () => {
		axios
			.get(`${env.api.url}/v1/options/get-option/euribor`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				setEuriborDataCurrent(response.data);
			});
	};

	const memoChild = useMemo(() => {
		return <Line {...config} />;
	}, [config]);

	return (
        (<Modal
			className="modal-without-buttons-loans"
			width={400}
			open={visible}
			onCancel={onCancel}
			onOk={onCreate}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			title={t(title)}
			centered>
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
				<Text
					style={{
						fontSize: 14,
						fontWeight: 400,
						color: '#748EA0'
					}}>
					{t('loan.EuriborQuoteSwipe')}
				</Text>

				{loan?.loan?.amount && loan?.loan?.term && loan?.loan?.rate ? (
					<div
						style={{
							marginTop: 16,
							display: 'flex',
							width: '100%',
							justifyContent: 'space-between',
							alignItems: 'center'
						}}>
						<Text
							style={{
								fontSize: 14,
								fontWeight: 400,
								color: '#748EA0'
							}}>
							-1.0%
						</Text>
						<Slider
							min={-1}
							max={10}
							defaultValue={euriborDataCurrent}
							step={0.1}
							style={{ width: isMobileOnly ? '60%' : '70%' }}
							onAfterChange={(e) => obteinQuota(e)}
						/>
						<Text
							style={{
								fontSize: 14,
								fontWeight: 400,
								color: '#748EA0'
							}}>
							10.0%
						</Text>
					</div>
				) : (
					<div style={{ color: 'red', marginTop: 14 }}>
						Por favor complete los siguientes datos en la pantalla de "EDITAR DATOS":
						<br />
						{!loan?.loan?.amount && '- ' + t('loan.haveLeftToPay')}
						<br />
						{!loan?.loan?.term && '- ' + t('loan.remainingMonths')}
						<br />
						{!loan?.loan?.rate && '- ' + t('loan.currentTotalInterest')}
					</div>
				)}
				<div style={{ marginTop: 16, alignSelf: 'center', marginBottom: 34 }}>
					<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 500 }}>
						{t('loan.EuriborQuoteResult') + ' '}
					</Text>

					<Text style={{ color: '#02C3CD', fontSize: 16, fontWeight: 600 }}>
						{numeral(parseFloat(valueCuota !== undefined ? valueCuota : loan?.loan?.quota)).format(
							'0,0.00 $'
						)}
						{'/' + t('viability.monthsEstimated')}
					</Text>
				</div>
			</div>
            <div style={{ textAlign: 'center' }}>
				<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 500 }}>
					{t('Gráfica de evolución del Euribor') + ' '}
				</Text>
				<div style={{ marginTop: 24, marginBottom: 20 }}>{memoChild}</div>
				{/* <div
          style={{
            display: "flex",
            columnGap: 7,
            alignItems: "flex-end",
            marginTop: 16,
            justifyContent: "center",
          }}
        >
          {euriborList?.map((data) => {
            return (
              <div
                style={{
                  height: (data.value + 1) * 10,
                  width: 4,
                  background: "#02C3CD",
                }}
              ></div>
            );
          })}
        </div> */}
			</div>
        </Modal>)
    );
};

export default EuriborCalculator;
