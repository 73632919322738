import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import numeral from "numeral";

const ResultSummary = ({ data }) => {
  const { t } = useTranslation();

  const dataSource = [
    {
      key: 1,
      name: t("promoterLoan.landPurchase"),
      euroData: numeral(
        data && data.assetAcquisition && data.assetAcquisition.value
      ).format("0,0"),
      moneyPerSquareMeter: numeral(
        data &&
          data.assetAcquisition &&
          data.assetAcquisition.moneyPerSquareMeter
      ).format("0,0"),
      costes: numeral(
        data &&
          data.assetAcquisition &&
          data.assetAcquisition.percentageTotalCosts
      ).format("0.0%"),
      ingresos: numeral(
        data &&
          data.assetAcquisition &&
          data.assetAcquisition.percentageTotalIncome
      ).format("0.0%"),
      style: { backgroundColor: "red" },
    },
    {
      key: 2,
      name: t("promoterLoan.totalConstruction"),
      euroData: numeral(
        data && data.totalConstructionCosts && data.totalConstructionCosts.value
      ).format("0,0"),
      moneyPerSquareMeter: numeral(
        data &&
          data.totalConstructionCosts &&
          data.totalConstructionCosts.moneyPerSquareMeter
      ).format("0,0"),
      costes: numeral(
        data &&
          data.totalConstructionCosts &&
          data.totalConstructionCosts.percentageTotalCosts
      ).format("0.0%"),
      ingresos: numeral(
        data &&
          data.totalConstructionCosts &&
          data.totalConstructionCosts.percentageTotalIncome
      ).format("0.0%"),
      children: [
        {
          key: 11,
          name: t("promoterLoan.commercialCosts"),
          euroData: numeral(
            data &&
              data.totalConstructionCosts &&
              data.totalConstructionCosts.hardCosts &&
              data.totalConstructionCosts.hardCosts.value
          ).format("0,0"),
          moneyPerSquareMeter: numeral(
            data &&
              data.totalConstructionCosts &&
              data.totalConstructionCosts.hardCosts &&
              data.totalConstructionCosts.hardCosts.moneyPerSquareMeter
          ).format("0,0"),
          costes: numeral(
            data &&
              data.totalConstructionCosts &&
              data.totalConstructionCosts.hardCosts &&
              data.totalConstructionCosts.hardCosts.percentageTotalCosts
          ).format("0.0%"),
          ingresos: numeral(
            data &&
              data.totalConstructionCosts &&
              data.totalConstructionCosts.hardCosts &&
              data.totalConstructionCosts.hardCosts.percentageTotalIncome
          ).format("0.0%"),
        },
        {
          key: 12,
          name: t("promoterLoan.associatedCosts"),
          euroData: numeral(
            data &&
              data.totalConstructionCosts &&
              data.totalConstructionCosts.softCosts &&
              data.totalConstructionCosts.softCosts.value
          ).format("0,0"),
          moneyPerSquareMeter: numeral(
            data &&
              data.totalConstructionCosts &&
              data.totalConstructionCosts.softCosts &&
              data.totalConstructionCosts.softCosts.moneyPerSquareMeter
          ).format("0,0"),
          costes: numeral(
            data &&
              data.totalConstructionCosts &&
              data.totalConstructionCosts.softCosts &&
              data.totalConstructionCosts.softCosts.percentageTotalCosts
          ).format("0.0%"),
          ingresos: numeral(
            data &&
              data.totalConstructionCosts &&
              data.totalConstructionCosts.softCosts &&
              data.totalConstructionCosts.softCosts.percentageTotalIncome
          ).format("0.0%"),
        },
      ],
    },
    {
      key: 3,
      name: t("promoterLoan.commercialCosts"),
      euroData: numeral(data && data.mktCosts && data.mktCosts.value).format(
        "0,0"
      ),
      moneyPerSquareMeter: numeral(
        data && data.mktCosts && data.mktCosts.moneyPerSquareMeter
      ).format("0,0"),
      costes: numeral(
        data && data.mktCosts && data.mktCosts.percentageTotalCosts
      ).format("0.0%"),
      ingresos: numeral(
        data && data.mktCosts && data.mktCosts.percentageTotalIncome
      ).format("0.0%"),
    },
    {
      key: 4,
      name: t("promoterLoan.managementCosts"),
      euroData: numeral(
        data && data.otherCosts && data.otherCosts.value
      ).format("0,0"),
      moneyPerSquareMeter: numeral(
        data && data.otherCosts && data.otherCosts.moneyPerSquareMeter
      ).format("0,0"),
      costes: numeral(
        data && data.otherCosts && data.otherCosts.percentageTotalCosts
      ).format("0.0%"),
      ingresos: numeral(
        data && data.otherCosts && data.otherCosts.percentageTotalIncome
      ).format("0.0%"),
    },
    {
      key: 5,
      name: t("promoterLoan.financingServices"),
      euroData: numeral(
        data && data.servicesCosts && data.servicesCosts.value
      ).format("0,0"),
      moneyPerSquareMeter: numeral(
        data && data.servicesCosts && data.servicesCosts.moneyPerSquareMeter
      ).format("0,0"),
      costes: numeral(
        data && data.servicesCosts && data.servicesCosts.percentageTotalCosts
      ).format("0.0%"),
      ingresos: numeral(
        data && data.servicesCosts && data.servicesCosts.percentageTotalIncome
      ).format("0.0%"),
    },
    {
      key: 6,
      name: t("promoterLoan.totalPromotionalCosts"),
      euroData: numeral(
        data && data.totalBudget && data.totalBudget.value
      ).format("0,0"),
      moneyPerSquareMeter: numeral(
        data && data.totalBudget && data.totalBudget.moneyPerSquareMeter
      ).format("0,0"),
      costes: numeral(
        data && data.totalBudget && data.totalBudget.percentageTotalCosts
      ).format("0.0%"),
      ingresos: numeral(
        data && data.totalBudget && data.totalBudget.percentageTotalIncome
      ).format("0.0%"),
    },
    {
      key: 7,
      name: t("promoterLoan.totalSales"),
      euroData: numeral(
        data && data.totalSales && data.totalSales.value
      ).format("0,0"),
      moneyPerSquareMeter: numeral(
        data && data.totalSales && data.totalSales.moneyPerSquareMeter
      ).format("0,0"),
      costes: "",
      ingresos: numeral(
        data && data.totalSales && data.totalSales.percentageTotalIncome
      ).format("0.0%"),
    },
    {
      key: 8,
      name: t("promoterLoan.marginBeforeTaxes"),
      euroData: numeral(data && data.marginBai && data.marginBai.value).format(
        "0,0"
      ),
      moneyPerSquareMeter: numeral(
        data && data.marginBai && data.marginBai.moneyPerSquareMeter
      ).format("0,0"),
      costes: "",
      ingresos: numeral(
        data && data.marginBai && data.marginBai.percentageTotalIncome
      ).format("0.0%"),
    },
    {
      key: 9,
      name: t("promoterLoan.ownFunds"),
      euroData: numeral(data && data.equity && data.equity.value).format("0,0"),
      moneyPerSquareMeter: "",
      costes: numeral(
        data && data.equity && data.equity.percentageTotalCosts
      ).format("0.0%"),
      ingresos: numeral(
        data && data.equity && data.equity.percentageTotalIncome
      ).format("0.0%"),
    },
    {
      key: 10,
      name: t("promoterLoan.equityMultiple"),
      euroData:
        numeral(data && data.multipleEquity && data.multipleEquity).format(
          "0,0.00"
        ) +
        " " +
        "x",
      moneyPerSquareMeter: "",
      costes: "-",
      ingresos: "-",
    },
  ];

  const columns = [
    {
      title: t("promoterLoan.resultSummary"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: "€",
      dataIndex: "euroData",
      key: "euroData",
      align: "right",
    },
    {
      title: "€/m²",
      dataIndex: "moneyPerSquareMeter",
      key: "moneyPerSquareMeter",
      align: "right",
    },
    {
      title: t("promoterLoan.totalCosts") + " (%)",
      dataIndex: "costes",
      key: "costes",
      align: "right",
    },
    {
      title: t("newRequest.incomeTotal") + " (%)",
      dataIndex: "ingresos",
      key: "ingresos",
      align: "right",
    },
  ];

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      rowClassName={(record) => {
        const classes = ["textStyleTable"];
        if (
          record.key === 1 ||
          record.key === 3 ||
          record.key === 5 ||
          record.key === 7 ||
          record.key === 9 ||
          record.key === 11
        ) {
          classes.push("textStyleTableOfferSelect");
        }

        if (record.key === 6 || record.key === 7 || record.key === 8) {
          classes.push("textStyleTotal");
        }
        return classes;
      }}
      className="table-style-general"
    />
  );
};

export default ResultSummary;
