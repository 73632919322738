import { useState, useEffect } from 'react';
import { Table, Modal, Typography, Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import numeral from 'numeral';
import { HeartOutlined, HeartFilled, EyeOutlined, EyeInvisibleOutlined, ReloadOutlined } from '@ant-design/icons'
import type { ColumnsType } from 'antd/es/table';
import { Offer } from '../Utils/types';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../DocumentationCore';
import {
    selectImagebank, renderType, getLinkages, renderAmortization, renderRevision, showHeart, hasLinkages,
    capitalizeFirstLetter, selectLogoBank
} from '../Utils/commonFunctions'
import axios from 'axios';
import env from '../../../../environment';
const { Text } = Typography

interface Props {
    bankOffers: Offer[],
    getBankOffers: () => void,
    loading: boolean,
    setLoading: (value: boolean) => void,
    setShowAppraisal: (value: boolean) => void
};


const nameStyle = {
    fontSize: '12px',
    color: '#2F4858',
    width: 176
}

const OffersTable = ({ bankOffers, getBankOffers, loading, setLoading, setShowAppraisal }: Props) => {
    const { t } = useTranslation();
    const userToken = useSelector<ReduxStore, string>((state) => state.auth.token);
    const favoriteOffers = bankOffers?.filter((offer: Offer) => offer.isFavorite === true);
    const [showModalConf, setShowModalConf] = useState<boolean>(false);
    const [showModalChange, setShowModalChange] = useState<boolean>(false);
    const [showModalNoChange, setShowModalNoChange] = useState<boolean>(false);
    const [selectedOffer, setSelectedOffer] = useState<Offer | null>(null);
    const [columnStates, setColumnStates] = useState<any>({});
    const [phoneOrientation, setPhoneOrientation] = useState<string>('vertical');
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [columns, setColumns] = useState<ColumnsType<any>>([
        {
            title: '',
            dataIndex: 'name',
            width: 176,
            fixed: 'left',
        }
    ]);

    const offerColumns = [...columns];

    const data = [
        {
            key: '1',
            name: <div style={nameStyle}>{t('offers.type')}</div>,
            code: 'ratio',
        },
        {
            key: '2',
            name: <div style={nameStyle}>TAE</div>,
            code: 'tae'
        },
        {
            key: '3',
            name: <div style={nameStyle}>{t('appraisal.nameTableEntailment')}</div>,
            code: 'linkages'
        },
        {
            key: '4',
            name: <div style={nameStyle}>{t('appraisal.nameTableAmount')}</div>,
            code: 'amount'
        },
        {
            key: '5',
            name: <div style={nameStyle}>{t('appraisal.nameTableTerm')}</div>,
            code: 'term'
        },
        {
            key: '6',
            name: <div style={nameStyle}>TIN ({t('offer.section1')})</div>,
            code: 'tin1'
        },
        {
            key: '7',
            name: <div style={nameStyle}>{t('offers.quoteMonthOneYear')} ({t('offer.section1')})</div>,
            code: 'quote1'
        },
        {
            key: '8',
            name: <div style={nameStyle}>{t('offers.tinAllYear')}</div>,
            code: 'tinRest'
        },
        {
            key: '9',
            name: <div style={nameStyle}>{t('offers.quoteMonthOneYear')} ({t('offers.rest')})</div>,
            code: 'quoteRest'
        },
        {
            key: '10',
            name: <div style={nameStyle}>{t('appraisal.nameTableReview')}</div>,
            code: 'revision'
        },
        {
            key: '11',
            name: <div style={nameStyle}>{t('appraisal.nameTableTotalAmortization')}</div>,
            code: 'totalFee'
        },
        {
            key: '12',
            name: <div style={nameStyle}>{t('appraisal.nameTablePartialAmortization')}</div>,
            code: 'partialFee'
        },
        {
            key: '13',
            name: <div style={nameStyle}>{t('appraisal.nameTableOpeningCommission')}</div>,
            code: 'openFee'
        },
        {
            key: '14',
            name: <div style={nameStyle}>{t('offers.offersTableTitle')}</div>,
            code: 'download'
        },
        {
            key: '15',
            code: 'see'
        }
    ];

    useEffect(() => {
        const additionalColumns = offerColumns.slice(1);
        additionalColumns.sort((a, b) => {
            const idA = a.key?.toString();
            const idB = b.key?.toString();
            const isAVisible = columnStates[idA];
            const isBVisible = columnStates[idB];

            if (isAVisible && !isBVisible) return -1;
            if (!isAVisible && isBVisible) return 1;
            return 0;
        });
        const sortedColumns = [offerColumns[0], ...additionalColumns];
        if (columns.length === 1) {
            setColumns(sortedColumns);
        }
        setLoading(false);
    }, [bankOffers]);

    useEffect(() => {
        const initialColumnVisibility = {};
        bankOffers.forEach((offer) => {
            initialColumnVisibility[offer.id] = true;
        });
        setColumnStates(initialColumnVisibility);
    }, []);

    useEffect(() => {
        setColumns([
            {
                title: '',
                dataIndex: 'name',
                width: 176,
                fixed: 'left'
            }
        ])
        getBankOffers();
    }, [columnStates]);

    useEffect(() => {
        function checkOrientation() {
            if (window.matchMedia("(orientation: portrait)").matches) {
                setPhoneOrientation('portrait');
                setModalVisible(false);
            } else if (window.matchMedia("(orientation: landscape)").matches) {
                setPhoneOrientation('landscape');
                setModalVisible(true);
            }
        }
        checkOrientation();
        window.addEventListener("resize", checkOrientation);
        return () => {
            window.removeEventListener("resize", checkOrientation);
        };
    }, []);

    const handleFileDownload = (offer: Offer) => {
        axios
            .get(`${env.api.url}/v1/banks/generate-pdf-bank/${offer.id}?token=${userToken}`, {
                responseType: 'blob'
            })
            .then((response) => {
                if (response) {
                    const url = window.URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `ofertaBanco.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);

                }
            })
            .catch((error) => { console.log(error) });
    };

    const handleToggleFavorite = (id: string) => {
        const offer = bankOffers.find((offer: Offer) => offer.id === id)
        if (!offer.isFavorite) {
            setLoading(true);
            axios
                .post(`${env.api.url}/banks/bank-offer/toggle-favorite`,{
                    bankOfferId: id,
                    isFromHadmin: false
                }, {
                    headers: { Authorization: `Bearer ${userToken}` }
                })
                .then(() => {
                    setColumns([
                        {
                            title: '',
                            dataIndex: 'name',
                            width: 176,
                            fixed: 'left'
                        }
                    ]);
                    getBankOffers()
                    setShowAppraisal(true);
                    setShowModalChange(false);
                    setShowModalConf(false);
                    setLoading(false);
                })
                .catch((error) => console.log(error));
        }
    };

    const handleConfirmManual = (offer: Offer) => {
        handleToggleFavorite(offer.id)
        if (favoriteOffers?.some((fav: Offer) => fav.bankCode === offer.bankCode)) {
            const favoriteOffer = (favoriteOffers.find((fav: Offer) => fav.bankCode === offer.bankCode && fav.id !== offer.id));
            handleToggleFavorite(favoriteOffer?.id);
        }
    };

    const showModalManual = (offer: Offer) => {
        if (offer?.isFavorite !== true) {
            if (favoriteOffers?.some((fav: Offer) => fav.bankCode === offer?.bankCode)) {
                setShowModalChange(true);
            } else {
                handleToggleFavorite(offer?.id);
            }
        } else {
            handleToggleFavorite(offer?.id);
        }
    };

    const showModalApi = (offer: Offer) => {
        if (!offer?.isFavorite) {
            setShowModalConf(true);
        } else {
            setShowModalNoChange(true);
        }
    };

    const handleClickHeartIcon = (offer: Offer) => {
        setSelectedOffer(offer);
        if (offer?.bankRequest.type !== 'API') {
            showModalManual(offer);
        } else {
            showModalApi(offer);
        }
    };

    const handleToggleColumn = (offerId: string) => {
        setLoading(true);
        setColumnStates((prevColumnStates: any) => ({
            ...prevColumnStates,
            [offerId]: !prevColumnStates[offerId]
        }));
    };

    bankOffers && bankOffers.map((offer) => {
        const years = offer.term / 12;
        const isColumnVisible = columnStates[offer.id];
        offerColumns.push({
            key: offer.id,
            title: (
                <>
                    {isColumnVisible ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                            {selectImagebank(offer, true)}
                            {!offer.isFavorite ?
                                (
                                    <>
                                        {showHeart(favoriteOffers, offer) && (
                                            <HeartOutlined
                                                style={{ fontSize: 30, color: '#C0DAEE' }}
                                                onClick={() => handleClickHeartIcon(offer)} />
                                        )}
                                    </>
                                ) :
                                (
                                    <HeartFilled
                                        style={{ fontSize: 30, color: '#FF7A7B' }}
                                        onClick={() => handleClickHeartIcon(offer)} />
                                )
                            }
                        </div>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {selectLogoBank(offer)}
                        </div>
                    )}
                </>
            ),
            align: 'center',
            width: isColumnVisible ? 176 : 40,
            render: (item) => {
                const field = [];
                if (item.code === 'linkages') {
                    field.push(isColumnVisible ? getLinkages(offer, t, true) : <div>-</div>)
                } else if (item.code === 'amount') {
                    field.push(isColumnVisible
                        ? <div>{offer.amount ? numeral(Number(offer.amount)).format('0,0 $') : '-'}</div>
                        : <div>-</div>);
                } else if (item.code === 'term') {
                    field.push(isColumnVisible
                        ? <div>{years ? years + ' ' + t('viability.years') : '-'}</div>
                        : <div>-</div>);
                } else if (item.code === 'ratio') {
                    field.push(isColumnVisible
                        ? offer.ratio !== 'Seleccione'
                            ? offer.ratio !== 'MIXED'
                                ? renderType(offer, t)
                                : t('offers.mixed', {
                                    years: (Number(offer?.data?.entailments[0]?.stages[0].to) - Number(offer?.data?.entailments[0]?.stages[0].from)) / 12
                                })

                            : '-'
                        : <div>-</div>);
                } else if (item.code === 'tin1') {
                    if (offer?.data?.entailments[0]?.stages?.length > 0) {
                        const section = offer?.data?.entailments[0]?.stages[0]
                        const type = offer.ratio
                        const years = ((Number(section.to) - Number(section.from)) / 12) % 1 === 0
                            ? Math.floor((Number(section.to) - Number(section.from)) / 12)
                            : ((Number(section.to) - Number(section.from)) / 12).toFixed(1)
                        field.push(isColumnVisible
                            ? <div style={{ fontSize: type !== 'FIXED' && 12, fontWeight: type !== 'FIXED' && 600 }}>
                                {type !== 'FIXED' && (
                                    <span style={{ fontWeight: 400 }}>
                                        {Number(section.to) < 12
                                            ? Number(section.to) + ' ' + t('offers.firstMonths') + ': '
                                            : years + ' ' + t('offers.firstYears') + ': '
                                        }
                                    </span>)}
                                {section.rateType !== 'FIXED' && <span>Eur + </span>}
                                {section.tin ? numeral(Number(section.tin)).format('0,0.00') + '%' : '-'}
                            </div>
                            : <div>-</div>)
                    }
                } else if (item.code === 'tinRest') {
                    const sections = [...offer?.data?.entailments[0]?.stages]
                    sections.shift()
                    field.push(isColumnVisible
                        ? <div>
                            {sections.length ? sections.map((section: any, index: number) => (
                                <div key={index} style={{ fontSize: 12, fontWeight: 600 }}>
                                    <span style={{ fontWeight: 400 }}>{t('offers.section')} {index + 2}:</span>&nbsp;
                                    {section.rateType !== 'FIXED' && <span>Eur + </span>}
                                    {numeral(Number(section.tin)).format('0,0.00')}
                                </div>
                            )) : '-'}
                        </div>
                        : <div>-</div>)
                } else if (item.code === 'tae') {
                    field.push(isColumnVisible
                        ? <div>
                            {offer.data.entailments[0].tae > '0'
                                ? numeral(Number(offer.data.entailments[0].tae)).format('0,0.00') + '%'
                                : '-'}
                        </div>
                        : <div>-</div>);
                } else if (item.code === 'quote1') {
                    if (offer?.data?.entailments[0]?.stages?.length > 0) {
                        const section = offer?.data?.entailments[0]?.stages[0]
                        const type = offer.ratio
                        const quote = section.quote
                        const years = ((Number(section.to) - Number(section.from)) / 12) % 1 === 0
                            ? Math.floor((Number(section.to) - Number(section.from)) / 12)
                            : ((Number(section.to) - Number(section.from)) / 12).toFixed(1)
                        field.push(isColumnVisible
                            ? <div style={{ fontSize: type !== 'FIXED' && 12, fontWeight: type !== 'FIXED' && 600 }}>
                                {type !== 'FIXED' && (
                                    <span style={{ fontWeight: 400 }}>
                                        {Number(section.to) < 12
                                            ? Number(section.to) + ' ' + t('offers.firstMonths') + ': '
                                            : years + ' ' + t('offers.firstYears') + ': '
                                        }
                                    </span>)}
                                {quote ? numeral(Number(quote)).format('0,0.00 $') : '-'}</div>
                            : <div>-</div>);
                    }
                } else if (item.code === 'quoteRest') {
                    const sections = [...offer?.data?.entailments[0]?.stages]
                    sections.shift()
                    field.push(isColumnVisible
                        ? <div>
                            {sections.length ? sections.map((section: any, index: number) => (
                                <div key={index} style={{ fontSize: 12, fontWeight: 600 }}>
                                    <span style={{ fontWeight: 400 }}>{t('offers.section')} {index + 2}:</span>&nbsp;
                                    {section.quote > '10' ? numeral(Number(section.quote)).format('0,0.00 $') : '-'}
                                </div>
                            )) : '-'}
                        </div>
                        : <div>-</div>)
                } else if (item.code === 'revision') {
                    field.push(isColumnVisible
                        ? offer.revision === 'Seleccione' ? '-' : renderRevision(offer.revision, t)
                        : <div>-</div>);
                } else if (item.code === 'openFee') {
                    field.push(isColumnVisible
                        ? <div>{offer.openFee ? numeral(Number(offer.openFee)).format('0,0 $') : '-'}</div>
                        : <div>-</div>);
                } else if (item.code === 'partialFee') {
                    field.push(isColumnVisible
                        ? <div>{offer.partialFee ? renderAmortization(offer.partialFee, t) : '-'}</div>
                        : <div>-</div>);
                } else if (item.code === 'totalFee') {
                    field.push(isColumnVisible
                        ? <div>{offer.totalFee ? renderAmortization(offer.totalFee, t) : '-'}</div>
                        : <div>-</div>);
                } else if (item.code === 'download') {
                    field.push(
                        isColumnVisible
                            ? <div
                                onClick={() => handleFileDownload(offer)}
                                style={{ color: '#02C3CD', fontWeight: 600, cursor: 'pointer' }}>
                                {t('init.download')}.pdf
                            </div>
                            : (<div>-</div>)
                    );
                } else if (item.code === 'see') {
                    field.push(<div>
                        <div>
                            {!isColumnVisible ? (

                                <EyeOutlined
                                    style={{ color: '#C0DAEE', fontSize: 24, cursor: 'pointer' }}
                                    onClick={() => handleToggleColumn(offer.id)}
                                />
                            ) : (
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer'
                                }}
                                    onClick={() => handleToggleColumn(offer.id)}>
                                    <EyeInvisibleOutlined style={{ color: '#C0DAEE', fontSize: 24 }} />
                                    <Text style={{ color: '#C0DAEE', marginLeft: 3 }}>Ocultar</Text>
                                </div>
                            )}
                        </div>
                    </div>);
                }
                return field;
            }
        });
    });

    return (
        <>
            {loading &&
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                        top: '50%'
                    }}>
                    <Spin size="large" />
                </div>
            }

            {!loading &&
                <>
                    {!isMobileOnly ? (
                        <Table
                            className='offersTable'
                            columns={columns}
                            dataSource={data}
                            bordered
                            pagination={false}
                            scroll={{ x: 1000 }}
                            size='small'
                        />
                    ) : (
                        <>
                            {phoneOrientation === 'portrait' ? (
                                <div style={{ display: 'block', textAlign: 'center', marginTop: 16, width: '100%' }}>
                                    <ReloadOutlined style={{ fontSize: 32, textAlign: 'center', color: '#2F4858' }} /><br />
                                    <Text style={{ marginTop: 10, color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
                                        {t('offers.rotatePhone')} <br /> {t('offers.tableMode')}
                                    </Text>
                                </div>
                            ) : (
                                <Modal
                                    className='ModalTableOffers'
                                    open={modalVisible}
                                    closable={false}
                                    footer={null}
                                    centered
                                    width={'100%'}
                                >
                                    <Table
                                        className='offersTable'
                                        columns={columns}
                                        dataSource={data}
                                        bordered
                                        pagination={false}
                                        scroll={{ x: 'max-content', y: '70vh' }}
                                        size='small'
                                    />
                                </Modal>
                            )}
                        </>
                    )}
                </>
            }
            <Modal
                title={t('bank.confirm')}
                className='ModalConfirmOffer'
                open={showModalChange}
                onOk={() => handleConfirmManual(selectedOffer)}
                onCancel={() => setShowModalChange(false)}>
                {selectedOffer && (
                    <Text style={{ color: '#748EA0' }}>
                        {t('bank.markedFavorite')} <b>{selectedOffer?.bankName}</b> {selectedOffer?.bankName.endsWith('.') ? '' : '.'} {t('bank.changeOffer')}
                    </Text>
                )}
            </Modal>
            <Modal
                title={t('bank.confirm2')}
                className='ModalConfirmOffer'
                open={showModalConf}
                onOk={() => handleToggleFavorite(selectedOffer.id)}
                onCancel={() => setShowModalConf(false)}>
                {selectedOffer && (
                    <Text style={{ color: '#748EA0' }}>
                        {t('offer.marked')}
                        <b>{t('offers.typeAndBank', { type: renderType(selectedOffer, t), bank: capitalizeFirstLetter(selectedOffer?.bankCode) })}</b>&nbsp;
                        {hasLinkages(selectedOffer) ? t('offer.withLinkages') : t('offer.withoutLinkages')}.&nbsp;
                        {t('bank.choose')} <b>{selectedOffer?.bankName}</b>{selectedOffer?.bankName.endsWith('.') ? '' : '.'}<br />
                        {t('bank.sure')}
                    </Text>
                )}
            </Modal>
            <Modal
                title={t('offer.noChange')}
                className='ModalConfirmOffer'
                open={showModalNoChange}
                onOk={() => setShowModalNoChange(false)}
                onCancel={() => setShowModalNoChange(false)}
                footer={[
                    <Button
                        key="ok"
                        type="primary"
                        style={{ width: '100%' }}
                        onClick={() => setShowModalNoChange(false)}
                    >
                        {t('offers.accept')}
                    </Button>,
                ]}>
                {selectedOffer && (
                    <Text style={{ color: '#748EA0' }}>
                        {t('offer.noChangeDescription')}
                    </Text>
                )}
            </Modal>

        </>
    );
};

export default OffersTable;
