import React, { useState, useEffect } from "react";
import {
  Button,
  Upload,
  Typography,
  Steps,
  Checkbox,
  Modal,
  notification,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  FilePdfOutlined,
  DownloadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import axios from "axios";
import env from "../../../environment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { refreshData } from "../../../store/auth/actions";
import { trace } from "../../../components/tagmanager";
import { useParams, useLocation } from "react-router-dom";
import { InputDate } from 'afrodita';

import dayjs from 'dayjs';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import AppraisalDesktop from "../appraisal/AppraisalDesktop";

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');
dayjs.locale('vi');

const { Text } = Typography;
const { Step } = Steps;

const FormalizationDesktop = ({ dataMortgage, userMortgageId, stages }) => {
  const [valuesData, setValuesData] = useState([]);
  const { t } = useTranslation();
  const userToken = useSelector((state) => state.auth.token);
  const [saveFileDataField, setSaveFileDataField] = useState(null);
  const [typeFile, setTypeFile] = useState();
  const [fein, setfein] = useState(false);
  const [finishStep, setFinishStep] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();;
  const [, setEwallet] = useState(0);
  const showPayBoolean = useSelector((state) => state.data.showPay);
  const [, setIsClientPay] = useState(false);
  const [checkBoxData, setCheckBoxData] = useState(
    dataMortgage !== undefined &&
      dataMortgage.mortgage &&
      dataMortgage.mortgage.signedDocumentation === true
      ? true
      : false
  );
  const [mortgageData, setMortgageData] = useState();
  const [idFileFein, setIdFileFein] = useState();
  const [, setDataClient] = useState("");
  const operations = useSelector((state) => state.auth.operations);

  const { operationId } = useParams();
  const nameOperations =
    operations !== undefined &&
    operations.find((operation) => operation.mortgageId === operationId);
  const rol = useSelector((state) => state.auth.roles);
  const location = useLocation();

  let countFormalization = 0;
  const [bankOffer, setBankOffer] = useState(undefined);

  useEffect(() => {
    getEwallet();
    //setIsClientPay(dataMortgage.mortgage !== undefined ? dataMortgage.mortgage.appraisalPaid : false)
    dataValueInfoUpload();
    getClientPay();
    getMortgageData();
    getTokenPay();
    getBankOffer();

    if (stages === "formalization") {
      trace({
        location,
        userToken,
        rol,
        stage: "formalization",
        operationName: nameOperations && nameOperations.name,
        //extra: operationsData
      });
    }
  }, []);

  useEffect(() => {
    getClientPay();
  }, [showPayBoolean]);

  const getTokenPay = () => {
    axios
      .post(
        `${env.api.url}/v1/payments/appraisal/checkout`,
        {
          operationId: userMortgageId,
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        setDataClient(response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getClientPay = () => {
    axios
      .post(
        `${env.api.url}/v1/payments/operation/status`,
        {
          operationId: userMortgageId,
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        setIsClientPay(response.data.data.completed);
      });
  };

  const getEwallet = () => {
    axios
      .get(`${env.api.url}/v1/user/refundable-deposit`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setEwallet(response.data.data.amount);
      });
  };



  const dataValueInfoUpload = () => {
    axios
      .get(
        `${env.api.url}/v1/documents/get-operation-documents/${userMortgageId}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        setValuesData(response.data.data);

      

        setfein(response.data.data.some((value) => value.type === "fein"));

        if (response.data.data) {
          response.data.data.map((data) => {
            if (data.type === "fein" && data.owner === null) {
              setIdFileFein(data.id);
            }
          });
        }
      })
      .catch((error) => {
         console.log(error)
      });
  };

  const handleUploadFile = (file) => {
    axios
      .put(
        `${env.api.url}/api/mortgages/attachment`,
        {
          mortgageId: userMortgageId,
          file: file[1].file,
          fileName: file[1].fileName,
          type: typeFile,
          owner: "owner1",
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then(() => {
        dataValueInfoUpload();
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const handleClickPlus = (typeFile) => {
    setTypeFile(typeFile);
    /*if (typeFile === "signedFein") {
      saveData("mortgage", "signedDocumentation", true);
    }*/
  };

  const saveFileData = (file, name) => {
    const documentFile = [saveFileDataField];
    documentFile.push({
      file,
      fileName: name,
    });
    setSaveFileDataField(documentFile);
    handleUploadFile(documentFile);
  };

  const uploadProps = {
    beforeUpload: (file) => {
      return new Promise(() => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        if (file.size < 50000000) {
          reader.onload = () => {
            const base64File = reader.result.split(",")[1];
            saveFileData(base64File, file.name);
          };
        } else {
          notification.error({
            message: "Problema al subir archivo",
            description:
              "Documento demasiado pesado, debe subir documentos que no superen los 50MB por seguridad.",
          });
        }
      });
    },
  };

  const handleFileDownload = (documentId) => {
    axios
      .get(
        `${env.api.url}/v1/documents/get-document-url/${documentId}?token=${userToken}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        if (response.data.success) {
          window.open(response.data.data.url, "_blank"); //to open new page
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };

   
  const buttonStyles = (documentId) => {
    return (
      <div
        className="gibobs-document-button-overlay"
        style={{
          position: "absolute",
          width: 88,
          height: 88,
          top: 0,
          left: 0,
          backgroundColor: "#02C3CD",
          lineHeight: 10,
          borderRadius: "4px",
        }}
      >
       
        <Button
          type="text"
          size="small"
          onClick={() => {
            handleFileDownload(documentId);
          }}
        >
          <DownloadOutlined
            style={{
              marginTop: "10px",
              marginRight: "3px",
              color: "#ffff",
            }}
          />
        </Button>
      </div>
    );
  };



  const saveData = (participant, key, value) => {
    axios
      .post(
        `${env.api.url}/v1/mortgages/data/update/${userMortgageId}`,
        { key, participant, value },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          getMortgageData();
          refreshData();
          setCheckBoxData(response.data.data.mortgage.signedDocumentation);
        }
      });

    if (key === "signed" && value) {
      setIsModalVisible(true);
    }
    if (key === "signedDocumentation" && value === true) {
      setFinishStep(true);
    }
  };

  const getMortgageData = () => {
    axios
      .get(`${env.api.url}/v1/mortgages/data/get/${userMortgageId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setMortgageData(response.data.data.mortgage);
       
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getBankOffer = () => {
    axios
      .get(
        `${env.api.url}/v1/mortgages/selected-bank-offer/${userMortgageId}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        setBankOffer(response.data.data.bank.id);
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const handleOk = () => {
    setIsModalVisible(false);
    navigate(`/my-financial-profile/credits/form/${bankOffer}`);
  };

 
  return (
    (<div>
      {stages === "appraisal" && (
        <AppraisalDesktop
          stages={stages}
          dataMortgage={dataMortgage}
          operationId={userMortgageId}
        ></AppraisalDesktop>
      )}
      {stages === "formalization" && !showPayBoolean && (
        <div>
          {dataMortgage.mortgage &&
            dataMortgage.mortgage.digitalContractLink === undefined &&
            !fein && (
              <div>
                <div style={{ marginLeft: "15px", width: "auto" }}>
                  <Steps
                    direction="vertical"
                    size="small"
                    icon="none"
                    current={"1"}
                    className="StepsFormalization"
                    style={{ height: "200px" }}
                  >
                    <Step style={{ color: "#b3b3b3" }} />
                    <Step />
                  </Steps>
                </div>
                <div
                  style={{
                    marginLeft: "45px",
                    width: "auto",
                    marginTop: "-200px",
                  }}
                >
                  <Text strong style={{ fontSize: "14px" }}>
                    {t("formalization.condictions")}
                  </Text>
                </div>
                <div style={{ marginLeft: "45px", width: "auto" }}>
                  <Text
                    style={{
                      marginTop: "16px",
                      marginBottom: "25px",
                      fontSize: "12px",
                      float: "left",
                    }}
                  >
                    {t("formalization.documents")}
                  </Text>
                </div>
                <div
                  style={{
                    marginLeft: "65px",
                    width: "auto",
                    float: "left",
                    marginTop: "-5px",
                    marginBottom: 40
                  }}
                >
                  <Text
                    style={{
                      fontSize: "12px",
                      color: "#C0DAEE",
                      marginLeft: "-15px",
                    }}
                  >
                    {t("formalization.finish")}
                  </Text>
                </div>
              </div>
            )}

          {dataMortgage.mortgage !== undefined &&
            dataMortgage.mortgage.digitalContractLink !== undefined &&
            checkBoxData === false &&
            (dataMortgage.mortgage.signedDocumentation === false ||
              dataMortgage.mortgage.signedDocumentation === undefined) &&
            !fein && ( // !==
              (<div style={{ marginLeft: "-12px" }}>
                <div style={{ marginLeft: "13px", width: "auto" }}>
                  <Steps
                    direction="vertical"
                    size="small"
                    status="error"
                    icon="none"
                    current={0}
                    className="StepsFormalization"
                    style={{ height: "706px" }}
                  >
                    <Step style={{ color: "#b3b3b3" }} />
                    <Step />
                  </Steps>
                </div>
                <div
                  style={{
                    marginLeft: "45px",
                    width: "auto",
                    marginTop: "-706px",
                  }}
                >
                  <Text strong style={{ fontSize: "14px" }}>
                    {t("formalization.condictions")}
                  </Text>
                </div>
                <div style={{ marginLeft: "45px", width: "auto" }}>
                  <Text
                    style={{
                      marginTop: "16px",
                      marginBottom: "25px",
                      fontSize: "12px",
                      float: "left",
                    }}
                  >
                    {t("formalization.filesAvailables")}
                  </Text>
                </div>
                <div
                  style={{
                    marginBottom: "25px",
                    fontSize: "12px",
                    float: "left",
                    marginLeft: "45px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text strong>
                    {t("formalization.pdf")}
                    <Button
                      style={{
                        fontSize: "12px",
                        color: "#02C3CD",
                        border: "none",
                        background: "none",
                      }}
                      target={"_blank"}
                      href="/guiaLiberbank.pdf"
                    >
                      {t("formalization.signature")}
                    </Button>
                  </Text>
                  <Text strong>
                    {t("formalization.link")}
                    <Button
                      style={{
                        height: "12px",
                        fontSize: "12px",
                        color: "#02C3CD",
                        border: "none",
                        background: "none",
                      }}
                      target={"_blank"}
                      href={`${
                        dataMortgage.mortgage.digitalContractLink !== undefined
                          ? dataMortgage.mortgage.digitalContractLink
                          : ""
                      }`}
                    >
                      {dataMortgage.mortgage.digitalContractLink !== undefined
                        ? dataMortgage.mortgage.digitalContractLink
                        : ""}
                    </Button>
                  </Text>
                  <div style={{ marginTop: "12px" }}>
                    <Text
                      strong
                      style={{
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      {/*t("formalization.user1")*/}
                      {dataMortgage.owner1 !== undefined ? "Titular 1:" : null}
                    </Text>
                    <Text
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        marginLeft: "4px",
                      }}
                    >
                      {dataMortgage.owner1 !== undefined
                        ? dataMortgage.owner1.userBankCode
                        : null}
                    </Text>
                  </div>
                  {dataMortgage.owner2 !== undefined ? (
                    <div style={{ marginTop: "12px" }}>
                      <Text
                        strong
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        {/*t("formalization.user2")*/}
                        {dataMortgage.owner2 !== undefined
                          ? "Titular 2:"
                          : null}
                      </Text>
                      <Text
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          marginLeft: "4px",
                        }}
                      >
                        {dataMortgage.owner2 !== undefined
                          ? dataMortgage.owner2.userBankCode
                          : null}
                      </Text>
                    </div>
                  ) : (
                    <div style={{ marginTop: "12px" }}>
                      <Text
                        strong
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        {/*t("formalization.user2")*/}
                        {dataMortgage.guarantor1 !== undefined
                          ? "Avalista 1:"
                          : null}
                      </Text>
                      <Text
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          marginLeft: "4px",
                        }}
                      >
                        {dataMortgage.guarantor1 !== undefined
                          ? dataMortgage.guarantor1.userBankCode
                          : null}
                      </Text>
                    </div>
                  )}

                  {dataMortgage.owner2 !== undefined &&
                  dataMortgage.guarantor1 !== undefined ? (
                    <div style={{ marginTop: "12px" }}>
                      <Text
                        strong
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        {/*t("formalization.user2")*/}
                        {dataMortgage.guarantor1 !== undefined
                          ? "Avalista 1:"
                          : null}
                      </Text>
                      <Text
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          marginLeft: "4px",
                        }}
                      >
                        {dataMortgage.guarantor1 !== undefined
                          ? dataMortgage.guarantor1.userBankCode
                          : null}
                      </Text>
                    </div>
                  ) : (
                    <div style={{ marginTop: "12px" }}>
                      <Text
                        strong
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        {/*t("formalization.user2")*/}
                        {dataMortgage.guarantor2 !== undefined
                          ? "Avalista 2"
                          : null}
                      </Text>
                      <Text
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          marginLeft: "4px",
                        }}
                      >
                        {dataMortgage.guarantor2 !== undefined
                          ? dataMortgage.guarantor2.userBankCode
                          : null}
                      </Text>
                    </div>
                  )}
                  {dataMortgage.guarantor2 !== undefined ? (
                    <div style={{ marginTop: "12px" }}>
                      <Text
                        strong
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        {/*t("formalization.user2")*/}
                        Ava2
                        {dataMortgage.guarantor2 !== undefined
                          ? "Avalista 2"
                          : null}
                      </Text>
                      <Text
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          marginLeft: "4px",
                        }}
                      >
                        {dataMortgage.guarantor2 !== undefined
                          ? dataMortgage.guarantor2.userBankCode
                          : null}
                      </Text>
                    </div>
                  ) : (
                    <div style={{ marginTop: "12px" }}>
                      <Text
                        strong
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      ></Text>
                      <Text
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          marginLeft: "4px",
                        }}
                      ></Text>
                    </div>
                  )}

                  <div
                    style={{
                      fontSize: "12px",
                      marginTop: "20px",
                      marginBottom: "38px",
                    }}
                  >
                    <Checkbox
                      defaultChecked={
                        dataMortgage && dataMortgage.mortgage
                          ? dataMortgage.mortgage.signedDocumentation
                          : false
                      }
                      onChange={(e) =>
                        saveData(
                          "mortgage",
                          "signedDocumentation",
                          e.target.checked
                        )
                      }
                    >
                      {t("formalization.signatureFiles")}
                    </Checkbox>
                  </div>

                  <div
                    style={{
                      width: "auto",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "12px",
                        color: "#C0DAEE",
                      }}
                    >
                      {t("formalization.finish")}
                    </Text>
                  </div>
                </div>
              </div>)
            )}
        </div>
      )}
      {fein && !finishStep && !showPayBoolean && (
        <div style={{ marginLeft: "-12px"}}>
          <div style={{ marginLeft: "13px", width: "auto" }}>
            <Steps
              direction="vertical"
              size="small"
              status="error"
              icon="none"
              current={0}
              className="StepsFormalization"
              style={{ height: "650px" }}
            >
              <Step style={{ color: "#b3b3b3" }} />
              <Step />
            </Steps>
          </div>
          <div
            style={{ marginLeft: "45px", width: "auto", marginTop: "-650px" }}
          >
            <Text strong style={{ fontSize: "14px" }}>
              {t("formalization.condictions")}
            </Text>
          </div>
          <div style={{ marginLeft: "45px", width: "auto" }}>
            <Text
              style={{
                marginTop: "16px",
                marginBottom: "25px",
                fontSize: "12px",
                float: "left",
              }}
            >
              {t("formalization.filesAvailables")}
            </Text>
          </div>
          <Text
            strong
            style={{
              marginTop: "66px",
              marginBottom: "25px",
              fontSize: "12px",
              float: "left",
              marginLeft: "-700px",
            }}
          >
            {t("formalization.pdf")}
          </Text>
          <Button
            style={{
              marginTop: "60px",
              marginBottom: "25px",
              fontSize: "12px",
              float: "left",
              marginLeft: "-683px",
              color: "#02C3CD",
              border: "none",
              background: "none",
            }}
            target={"_blank"}
            href="/guia/guia.pdf"
          >
            {t("formalization.signature")}
          </Button>
          <Text
            strong
            style={{
              marginTop: "86px",
              marginBottom: "25px",
              fontSize: "12px",
              float: "left",
              marginLeft: "-700px",
            }}
          >
            {t("formalization.pdf")}
          </Text>
          <Button
            style={{
              marginTop: "80px",
              marginBottom: "25px",
              fontSize: "12px",
              float: "left",
              marginLeft: "-683px",
              color: "#02C3CD",
              border: "none",
              background: "none",
            }}
            onClick={() => {
              handleFileDownload(idFileFein);
            }}
          >
            Documento FEIN
          </Button>

          <div
            style={{
              whiteSpace: "nowrap",
              marginLeft: "-685px",
              float: "left",
              marginRight: "25px",
              marginTop: "140px",
              display: "flex",
            }}
          >
            {valuesData &&
              valuesData.map((data) => {
                if (data.type === "signedFein" && data.owner !== null) {
                  countFormalization = countFormalization + 1;

                  setFinishStep(true);
                  saveData("mortgage", "signedDocumentation", true);

                  return (
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        marginLeft: "-15px",
                        float: "left",
                        marginRight: "25px",
                      }}
                    >
                      <Button
                        className="gibobs-document-button"
                        style={{
                          width: "88px",
                          height: "88px",
                          backgroundColor: "white",
                          borderRadius: "4px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "20px",
                            marginTop: "5px",
                            marginLeft: "-5px",
                          }}
                        >
                          <FilePdfOutlined />
                        </div>
                        <div
                          className="textButton"
                          style={{
                            width: "72px",
                            height: "54px",
                            fontSize: "10px",
                            whiteSpace: "normal",
                            marginLeft: "-10px",
                            marginTop: "-20px",
                          }}
                        >
                          {buttonStyles(data.id)}
                          <div
                            style={{
                              width: "100%",
                              height: "30px",
                              overflowWrap: "break-word",
                              marginBottom: "-7px",
                            }}
                          >
                            {data.name.substr(0, 25)}...
                          </div>
                        </div>
                      </Button>
                    </div>
                  );
                }
              })}

            {countFormalization !== 2 && (
              <Upload {...uploadProps}>
                <Button
                  onClick={() => handleClickPlus("signedFein")}
                  style={{
                    width: "88px",
                    height: "88px",
                    backgroundColor: "white",
                    borderRadius: "4px",
                    marginLeft: "-15px",
                  }}
                >
                  <div style={{ marginBottom: "20px", marginTop: "10px" }}>
                    <PlusOutlined
                      style={{ color: "#02C3CD", fontSize: "30px" }}
                    />
                  </div>
                  <div
                    className="textButton"
                    style={{
                      width: "72px",
                      height: "34px",
                      fontSize: "10px",
                      whiteSpace: "break-spaces",
                      marginLeft: "-10px",
                      marginTop: "-20px",
                    }}
                  >
                    {t("document.attach")}
                  </div>
                </Button>
              </Upload>
            )}
          </div>

          <div
            style={{
              marginTop: "180px",
              marginBottom: "25px",
              fontSize: "12px",
              float: "left",
              marginLeft: "50px",
            }}
          >
            <Checkbox
              onChange={(e) =>
                saveData("mortgage", "signedDocumentation", e.target.checked)
              }
              checked={countFormalization === 2 ? true : false}
            >
              {t("formalization.signatureFiles")}
            </Checkbox>
          </div>

          <div
            style={{
              marginLeft: "-330px",
              width: "auto",
              float: "left",
              marginTop: "225px",
            }}
          >
            <Text
              style={{
                fontSize: "12px",
                color: "#C0DAEE",
                marginLeft: "-15px",
              }}
            >
              {t("formalization.finish")}
            </Text>
          </div>
        </div>
      )}
      {(dataMortgage &&
        dataMortgage.mortgage &&
        dataMortgage.mortgage.signedDocumentation) ||
      finishStep
        ? !showPayBoolean && (
            <div style={{ marginLeft: "-12px" }}>
              <div style={{ marginLeft: "13px", width: "auto" }}>
                <Steps
                  direction="vertical"
                  size="small"
                  status="finish"
                  className="StepsFormalization"
                  current={"1"}
                  style={{ height: "100px" }}
                >
                  <Step style={{ color: "#b3b3b3" }} />
                  <Step />
                </Steps>
              </div>
              <div
                style={{
                  marginLeft: "45px",
                  width: "auto",
                  marginTop: "-100px",
                }}
              >
                <Text style={{ fontSize: "12px" }}>
                  {t("formalization.condictions")}
                </Text>
              </div>
              <div
                style={{ marginLeft: "45px", width: "auto", marginTop: "30px" }}
              >
                <Text strong style={{ fontSize: "12px" }}>
                  {t("formalization.finish")}
                </Text>
              </div>
              <div style={{ width: "auto", marginTop: "30px" }}>
                <Text
                  style={{
                    marginTop: "10px",
                    marginBottom: "25px",
                    fontSize: "12px",
                    float: "left",
                    marginLeft: "45px",
                  }}
                >
                  {t("formalization.date")}
                </Text>
              </div>
              <div
                style={{ width: "auto", marginTop: "70px", marginLeft: 170 }}
              >
                <Text
                  style={{
                    marginTop: "10px",
                    marginBottom: "25px",
                    fontSize: "12px",
                    float: "left",
                    marginLeft: "-100px",
                  }}
                >
                  {t("formalization.dateEstimated")}
                </Text>
                {(mortgageData !== undefined ||
                  dataMortgage.mortgage !== undefined) && (
                  <InputDate
                    type="date"
                    value={
                      dataMortgage.mortgage !== undefined &&
                      dataMortgage.mortgage.estimatedSignDate !== undefined &&
                      dataMortgage.mortgage.estimatedSignDate !== "Invalid date"
                        ? dayjs(
                            dataMortgage.mortgage.estimatedSignDate
                          ).format("DD-MM-YYYY")
                        : ""
                    }
                    // defaultValue={null}
                    style={{
                      width: "280px",
                      height: "32px",
                      borderRadius: "4px",
                      borderColor: "#C0DAEE",
                      fontSize: "12px",
                      marginTop: "50px",
                      marginLeft: "-160px",
                    }}
                    placeholder={"Fecha"}
                    onChange={(e) => {
                      {
                        saveData(
                          "mortgage",
                          "estimatedSignDate",
                          dayjs(e, "DD-MM-YYYY").format("YYYY-MM-DD")
                        );
                      }
                    }}
                  />
                )}
              </div>

              <div
                style={{
                  marginTop: "-30px",
                  marginBottom: "25px",
                  fontSize: "12px",
                  float: "left",
                  marginLeft: "400px",
                }}
              >
                <Checkbox
                  checked={
                    dataMortgage.mortgage !== undefined &&
                    dataMortgage.mortgage.signed
                      ? true
                      : false
                  }
                  style={{ fontSize: 12 }}
                  onChange={(e) =>
                    saveData("mortgage", "signed", e.target.checked)
                  }
                >
                  {t("formalization.notary")}
                </Checkbox>
              </div>
              {isModalVisible && (
                <Modal
                  cancelButtonProps={{ className: "button-secundary-gibobs" }}
                  okButtonProps={{ className: "button-primari-gibobs" }}
                  className="ModalCommonSmall"
                  open={isModalVisible}
                  title="Muchas gracias por confiar en Gibobs"
                  okText="Aceptar"
                  cancelText="Cancelar"
                  onCancel={handleCancel}
                  onOk={handleOk}
                >
                  <p>{t("formalization.thanks")}</p>
                </Modal>
              )}
            </div>
          )
        : null}
    </div>)
  );
};

export default FormalizationDesktop;
