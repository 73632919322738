import React, { useState, useEffect } from 'react';
import { Col, Form, Radio, Space, Select, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import DownOutlinedMobile from '../../../../assets/icons/DownOutlinedMobile.svg';
import axios from 'axios';
import env from '../../../../environment';
import { InputNumber, ProvinceSelector } from 'afrodita';
import moment from 'moment';

const { Option } = Select;

const CurrentMortgageForm2 = ({ edit, dataOperation }) => {
	const { t } = useTranslation();
	const [nameBanks, setNameBanks] = useState(null);
	const userToken = useSelector((state) => state.auth.token);
	const [valueRadio, setValueRadio] = useState(
		dataOperation?.subrogation?.whenSignedMortgage !== undefined
			? dataOperation.subrogation.whenSignedMortgage
			: undefined
	);
	const [valueEditMortgage, setValueEditMortgage] = useState(dataOperation?.subrogation?.whenSignedMortgage !== undefined
		? dataOperation.subrogation.whenSignedMortgage
		: undefined);
	const currentMonthStart = moment().startOf('month');

	const colSpan = {
		xxl: edit === undefined ? 24 : 6,
		xl: edit === undefined ? 24 : 6,
		lg: edit === undefined ? 24 : 8,
		md: edit === undefined ? 24 : 12,
		sm: 24,
		xs: 24
	};

	const disabledDate = (current) => {
		const oneYearAgo = moment().subtract(1, 'year').startOf('day');
		if (!current) {
			return false;
		}
		return current.isAfter(oneYearAgo);
	};

	useEffect(() => {
		axios
			.get(`${env.api.url}/v1/banks/get-banks`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				setNameBanks(response.data.data);
			});
	}, []);


	return (
		<>
			<Col {...colSpan}>
				<Form.Item
					label={
						edit !== undefined
							? t('onboarding.bankWhereYouHaveYourMortgage')
							: t('onboarding.bankEntitiesQuestion')
					}
					name="mortgageCurrentBank"
					rules={[
						{
							required: true
						}
					]}>
					<Select
						style={{ width: '100%' }}
						showSearch
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
						placeholder={t('form.select')}>
						{nameBanks
							? nameBanks.map((nameBanks) => {
								return (
									<Option key={nameBanks.id} value={nameBanks.id}>
										{nameBanks.name}
									</Option>
								);
							})
							: null}
					</Select>
				</Form.Item>
			</Col>
			<Col {...colSpan}>
				<Form.Item
					name="maxBudget"
					label={edit !== undefined ? t('loan.homeCost') : t('loan.homeCost')}
					rules={[
						{
							required: true
						}
					]}>
					<InputNumber
						id="maxBudget"
						gbType="currency"
						suffix={"€"}
						max={99000000}
						min={50000}
						maxLength="15"
						placeholder={t('0')}
						defaultValue={''}
					/>
				</Form.Item>
			</Col>
			<Col {...colSpan}>
				<Form.Item
					label={edit !== undefined ? t('onboarding.provinceNew') : t('onboarding.provinceNew')}
					name="province"
					rules={[
						{
							required: true
						}
					]}>
					<ProvinceSelector
						style={{ width: '100%' }}
						placeholder={t('form.select')} />
				</Form.Item>
			</Col>

			{edit === undefined && (
				<Col span={24}>
					<Form.Item
						name="whenSignedMortgage"
						label={t('onboarding.singDateQuestion')}
						rules={[
							{
								required: true
							}
						]}
						style={{
							width: '100%',
							display: !isMobileOnly ? 'grid' : undefined
						}}>
						<Radio.Group
							onChange={(e) => setValueRadio(e.target.value)}
							style={{
								width: '100%',
								display: 'grid'
							}}>
							<Space direction="vertical">
								<Radio value="moreThanOneYear">
									{t('onboarding.overYearAgo1')}&nbsp;
									<strong>{t('onboarding.overYearAgo2')}</strong> {t('onboarding.overYearAgo3')}
								</Radio>

								{valueRadio === 'moreThanOneYear' && (
									<Form.Item
										name="dateSign"
										rules={[
											{
												required: true
											}
										]}>
										<DatePicker
											id="dateSign"
											format={'MM/YYYY'}
											picker="month"
											style={{
												width: '100%',
												border: '1px solid #C0DAEE',
												borderRadius: 4,
												height: 40
											}}
											disabledDate={disabledDate}
										/>
									</Form.Item>
								)}

								<Radio
									value="lessThanOneYear"
									style={{ marginTop: valueRadio === 'moreThanOneYear' ? -16 : 0 }}>
									{t('onboarding.overYearAgo1')}&nbsp;
									<strong>{t('onboarding.lessThanYearAgo4')}&nbsp;</strong>
									{t('onboarding.overYearAgo3')}
								</Radio>
							</Space>
						</Radio.Group>
					</Form.Item>
				</Col>
			)}
			{edit !== undefined && (
				<Col {...colSpan}>
					<Form.Item
						name="whenSignedMortgage"
						label={t('suborgation.initialSignatureDate')}
						rules={[
							{
								required: true
							}
						]}>
						<Select
							placeholder={t('form.activity')}
							onChange={(value) => setValueEditMortgage(value)}
							suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}>
							<Option value="moreThanOneYear">
								{t('onboarding.overYearAgo1')}&nbsp;
								{t('onboarding.overYearAgo2')}&nbsp;
								{t('onboarding.overYearAgo3')}{' '}
							</Option>
							<Option value="lessThanOneYear">
								{t('onboarding.overYearAgo1')}&nbsp;
								{t('onboarding.lessThanYearAgo4')}&nbsp;
								{t('onboarding.overYearAgo3')}
							</Option>
						</Select>
					</Form.Item>
				</Col>
			)}

			{edit !== undefined && valueEditMortgage === "moreThanOneYear" && (
				<Col {...colSpan}>
					<Form.Item
						name="dateSign"
						rules={[
							{
								required: true
							}
						]}>
						<DatePicker
							id="dateSign"
							format={'MM/YYYY'}
							picker="month"
							style={{
								width: '100%',
								border: '1px solid #C0DAEE',
								borderRadius: 4,
								height: 40,
								marginTop: 24
							}}
							disabledDate={disabledDate}
						/>
					</Form.Item>
				</Col>
			)}
		</>
	);
};

export default CurrentMortgageForm2;
