import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const ModalWithoutButtons = ({ visible, onCreate, onCancel, title, text }) => {
	const { t } = useTranslation();

	return (
		<Modal
			className="modal-without-buttons-loans"
			width={480}
			open={visible}
			onCancel={onCancel}
			onOk={onCreate}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			title={t(title)}
			centered>
			<div
				dangerouslySetInnerHTML={{
					__html: text
				}}
			/>
		</Modal>
	);
};

export default ModalWithoutButtons;
