import { useState, useEffect } from 'react';
import { Button, Typography, Row, Col, Tabs, Badge, Progress, Card, Modal, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isMobile, isMobileOnly } from 'react-device-detect';
import LoaderLogo from 'mortgage-documents/src/document-boxes/components/LoaderLogo';
import RenderCards from 'mortgage-documents/src/document-boxes/components/RenderCards';
import { useSelector } from 'react-redux';
import axios from 'axios';
import env from '../../../../../environment';
import _ from 'lodash';
import { commonNotifications } from 'mortgage-documents/src/document-boxes/CommonItems';
import { ReduxStore } from '../../DocumentationCore';
import DeleteDocumentModal from 'mortgage-documents/src/document-boxes/modals/DeleteDocumentModal';
import Afterbanks from 'mortgage-documents/src/document-boxes/AfterBanks/Afterbanks';
const { Text } = Typography;
import { Document } from '../../Utils/types';
const { TabPane } = Tabs;
const tesoro = require('../../../../../assets/onboarding/tesoro.png');
const agencia = require('../../../../../assets/onboarding/agencia.png');
const plus = require('../../../../../assets/icons/plus.png');

interface Props {
	userMortgageId: string;
	type: string;
	stages: string;
	participants: any;
	setDocuments: (value: Document[]) => void;
}

const DocumentationSection = ({
	userMortgageId,
	type,
	stages,
	participants,
	setDocuments
}: Props) => {
	let timerReference: NodeJS.Timeout;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const userToken = useSelector<ReduxStore, string>((state) => state.auth.token);
	const [availableButton, setAvailableButton] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingDocumentUpload, setLoadingDocumentUpload] = useState<boolean>(false);
	const [currentUploadingDocument, setCurrentUploadingDocument] = useState<number | undefined>(
		undefined
	);
	const [loadingCard, setLoadingCard] = useState<boolean>(false);
	const [nameOperations, setNameOperations] = useState<any>(undefined);
	const [valuesDataForMortgage, setValuesDataForMortgage] = useState<any>(undefined);
	const [clickTab, setClickTab] = useState<string | undefined>(undefined);
	const [selectedTab, setSelectedTab] = useState<string | undefined>(undefined);
	const [percentN, setPercentN] = useState<number>(0);
	const [widgetLink, setWidgetLink] = useState<any>(undefined);
	const [completedBankFlip, setCompletedBankFlip] = useState<boolean>(false);
	const [modalBankFlip, setModalBankFlip] = useState<boolean>(false);
	const [tags, setTags] = useState<any>([]);
	const [saveFileDataField, setSaveFileDataField] = useState<any>({});
	const [valuesData, setValuesData] = useState<any>([]);
	const [noTypeFile, setNoTypeFile] = useState<any>(undefined);
	const [visibleUploadModal, setVisibleUploadModal] = useState<boolean>(false);
	const [visibleAfterBanksModal, setVisibleAfterBanksModal] = useState<boolean>(false);
	const [deleteDoc, setDeleteDoc] = useState<any>(undefined);
	const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
	const numOwners: number = parseInt(participants?.mortgage?.participants) || 0;
	const numGuarantors: number = parseInt(participants?.mortgage?.guarantors) || 0;

	useEffect(() => {
		dataValueInfoUpload();
		getDataOperation();
	}, []);

	useEffect(() => {
		documentsRefresh();
	}, [participants, clickTab, selectedTab]);

	useEffect(() => {
		if (modalBankFlip && widgetLink) {
			timerReference = setInterval(() => {
				getBankFlipProcess();
			}, 2000);

			if (completedBankFlip) {
				clearInterval(timerReference);
			}
		}
	}, [widgetLink, modalBankFlip]);

	const getDataOperation = () => {
		axios
			.get(`${env.api.url}/v1/operations/${userMortgageId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => setNameOperations(response.data.data))
			.catch((error) => console.log(error));
	};

	const autoStageGenerator = () => {
		axios
			.get(`${env.api.url}/v1/auto-things/AutoStagesGenerator/${userMortgageId}`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				if (response.data.success) {
					if (stages !== 'oferts' && stages !== 'appraisal') {
						navigate(`/operations/${userMortgageId}/${response.data.data.currentStage}`);
					}
					setLoadingDocumentUpload(false);
				}
			});
	};

	const deleteDocument = (documentId: string) => {
		axios
			.get(`${env.api.url}/v1/documents/delete-document/${documentId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then(() => {
				dataValueInfoUpload();
				documentsRefresh();
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getBankFlipProcess = () => {
		axios
			.get(
				`${env.api.url}/docs/bankflip/check-provider-reference/${widgetLink.providerReferences.id}`,
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.data.data.flags.sessionCompleted) {
					setCompletedBankFlip(true);
					setModalBankFlip(false);
					clearInterval(timerReference);
					documentsRefresh();
					dataValueInfoUpload();
				}
			})
			.catch((error) => console.log(error));
	};

	const callToBankFlip = (id: string) => {
		axios
			.post(
				`${env.api.url}/docs/bankflip/create-session`,
				{
					operationId: id,
					personKey: clickTab
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				setWidgetLink(response.data.data);
				setModalBankFlip(true);
			})
			.catch((error) => console.log(error));
	};

	const documentsRefresh = () => {
		axios
			.get(`${env.api.url}/v1/documents/request-operation-documentation/${userMortgageId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				const tab = calculateTab(response.data.data);
				setValuesDataForMortgage(response.data.data);
				setDocuments(response.data.data.documents);
				setLoadingCard(false);
				const tmpButton = response.data.data.documents.find((o) => {
					const requiredDocuments = [
						'irpf',
						'irpf2',
						'laboralLife',
						'statement130',
						'statement303',
						'statement390'
					];
					return (
						requiredDocuments.includes(o.name) &&
						o.participants[tab] === 'required' &&
						!o.completed[tab]
					);
				});

				const tmpButton2 = response.data.data.documents.find((o) => {
					const requiredDocuments = [
						'irpf',
						'irpf2',
						'laboralLife',
						'statement130',
						'statement303',
						'statement390'
					];
					return (
						requiredDocuments.includes(o.name) &&
						(o.participants[tab] === 'required' || o.participants[tab] === 'optional') &&
						!o.completed[tab]
					);
				});

				if (type === 'priority') {
					setAvailableButton(tmpButton?.participants === undefined);
				} else {
					setAvailableButton(tmpButton2?.participants === undefined ? true : false);
				}

				setPercentN(
					(response.data.data.documentByParticipant[tab].completed * 100) /
						response.data.data.documentByParticipant[tab].needed
				);

				autoStageGenerator();
			})
			.catch((error) => console.log(error));
	};

	const calculateTab = (docs: any) => {
		for (let i = 1; i <= numOwners; i++) {
			if (docs?.documents) {
				const ownerKey = `owner${i}`;
				const ownerDocuments = docs.documents.filter(
					(data: any) => !data.completed[ownerKey] && data.participants[ownerKey] === 'required'
				);
				if (selectedTab === undefined) {
					if (ownerDocuments && ownerDocuments.length > 0) {
						const completed = ownerDocuments[0]?.completed;
						for (const key in completed) {
							if (completed[key] === false) {
								setClickTab(key);
								return key;
							}
						}
					} else {
						if (clickTab === undefined) {
							setClickTab(ownerKey);
							return ownerKey;
						}
					}
				} else {
					setClickTab(selectedTab);
				}
			}
		}
		return clickTab;
	};

	const isExcelFile = (fileType: string) => {
		const allowedTypes = [
			'csv',
			'xls',
			'xlsx',
			'text/csv',
			'text/xls',
			'text/xlsx',
			'text/xml',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'application/vnd.ms-excel'
		];
		return allowedTypes.includes(fileType);
	};

	const dataValueInfoUpload = () => {
		axios
			.get(`${env.api.url}/v1/documents/get-operation-documents/${userMortgageId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				const unsorted = response.data.data;
				const sorted = unsorted.sort(
					(a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
				);

				setValuesData(sorted);

				const fileWithoutType = sorted.find((item) => item.type === null);
				setNoTypeFile(fileWithoutType);
			})
			.catch((error) => console.log(error));
	};

	const modalDocument = (documentId: string) => {
		setDeleteDoc(documentId);
		setDeleteOpen(true);
	};

	const handleClickPlus = (idowner: string) => {
		const array: any = [idowner];
		setTags(array);
	};

	const handleUploadFile = (file: any, index: any) => {
		setCurrentUploadingDocument(index);
		setLoadingDocumentUpload(true);
		axios
			.put(
				`${env.api.url}/v1/documents/operation/attachment`,
				{
					operationId: userMortgageId,
					file: file[1].file,
					fileName: file[1].fileName,
					owner: clickTab,
					tags: index === 'othersDocs' ? undefined : tags,
					type: index === 'othersDocs' ? undefined : tags[0],
					page: index === 'othersDocs' ? undefined : 't'
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then(() => {
				setLoadingDocumentUpload(false);
				dataValueInfoUpload();
				documentsRefresh();
				setTimeout(() => commonNotifications('success', t('doc.notiNew'), t('doc.notiNew2')), 200);
			})
			.catch(() => {
				setLoading(false);
				setTimeout(
					() =>
						commonNotifications('error', t('document.waitUploadError'), t('document.failedUpload')),
					200
				);
			});
	};

	const saveFileData = (file: any, name: any, index: any) => {
		const documentFile: any[] = [saveFileDataField];
		documentFile.push({
			file,
			fileName: name
		});
		setSaveFileDataField(documentFile);
		handleUploadFile(documentFile, index);
	};

	const playToBeforeUpload = (file: any, data: any, index: any) => {
		if (isExcelFile(file.type)) {
			commonNotifications(
				'error',
				'Problema al subir archivo',
				'No está permitido subir archivos de tipo Excel.'
			);
			return;
		}

		handleClickPlus(data.name);

		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = () => {
			if (file.size < 50000000) {
				const base64File = reader.result?.toString().split(',')[1];
				saveFileData(base64File, file.name, index);
			} else {
				commonNotifications(
					'error',
					'Problema al subir archivo',
					'Documento demasiado pesado, debe subir documentos que no superen los 50MB por seguridad.'
				);
			}
		};
	};

	const getDocumentInfoByDownlands = () => {
		const lang = localStorage.getItem('i18nextLng');

		const languageMap = {
			es: 'es',
			en: 'en',
			cat: 'cat'
		};

		const language = languageMap[lang] || 'es';
		const url = `https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/gibobs_all_${language}.pdf`;
		window.open(url, '_blank');
	};

	const handleGetDocuments = () => {
		setVisibleUploadModal(true);
		setVisibleAfterBanksModal(false);
		dataValueInfoUpload();
		documentsRefresh();
	};

	const renderButtonDoc = () => {
		return (
			<div className={availableButton ? 'buttons-automatic-doc-disabled' : 'buttons-automatic-doc'}>
				<Row style={{ width: '100%', alignItems: 'center' }}>
					<Col
						xs={24}
						md={24}
						sm={24}
						lg={24}
						xl={18}
						xxl={18}
						style={{ display: isMobile ? 'block' : 'flex', justifyContent: 'center' }}>
						<div
							className={!availableButton ? 'title-information' : 'title-information-disabled'}
							dangerouslySetInnerHTML={{
								__html: availableButton
									? t('doc.youHaveDocumentsOfficial')
									: t('doc.newBankFlipGibobs')
							}}
						/>
						<div style={{ textAlign: isMobile ? 'center' : 'right' }}>
							<img
								src={
									availableButton
										? '/images/documents/tgssDisabled.png'
										: '/images/documents/tgss.png'
								}
								alt="tgssDisabled"
								style={{ height: isMobile ? 20 : 24, marginLeft: 5 }}></img>
							<img
								src={
									availableButton
										? '/images/documents/aeatDisabled.png'
										: '/images/documents/aeat.png'
								}
								alt="tgssDisabled"
								style={{ height: isMobile ? 20 : 24, marginLeft: 12 }}></img>
						</div>
					</Col>
					<Col
						xs={24}
						md={12}
						sm={12}
						lg={24}
						xl={6}
						xxl={6}
						style={{
							textAlign: isMobileOnly ? 'center' : 'right',
							marginTop: isMobileOnly ? 16 : undefined
						}}>
						<Button
							disabled={availableButton}
							className="button-primari-gibobs"
							onClick={() => callToBankFlip(nameOperations.id)}
							type="primary"
							style={{ height: 32 }}>
							{t('doc.newBankflipButton')}
						</Button>
					</Col>
				</Row>
			</div>
		);
	};

	const renderDocumentCounter = () => {
		const documentInfo = valuesDataForMortgage?.documentByParticipant[clickTab!];
		const completed = documentInfo?.completed;
		const needed = documentInfo?.needed;
		const participantName = participants[clickTab!]?.name;

		const percent = parseInt(
			(percentN === 100 || percentN === 0
				? percentN
				: parseFloat(percentN!.toString()).toFixed()
			).toString()
		);

		return (
			<div style={{ margin: '0 0 16px 16px', display: 'flex', alignItems: 'center' }}>
				<div style={{ width: '48px', height: '48px' }}>
					<Progress
						type="circle"
						percent={percent}
						className="progress-circle-documents"
						format={() => (
							<div style={{ marginTop: -4 }}>
								{valuesDataForMortgage && (
									<Text style={{ fontSize: 12, color: '#2F4858', fontWeight: 500 }}>
										{`${completed}/${needed}`}
									</Text>
								)}
							</div>
						)}
						width={48}
						strokeColor={'#2F4858'}
						trailColor={'#F1F7F8'}
						strokeWidth={14}
					/>
				</div>
				<div style={{ color: '#2F4858', marginLeft: 8 }}>
					{participants[clickTab!] !== undefined && (
						<Text>
							<span style={{ fontSize: 14, fontWeight: 400, color: '#2F4858' }}>
								{t('document.fileUpload')}
							</span>
							&nbsp;
							<span style={{ color: '#2F4858', fontWeight: 500, fontSize: 14 }}>
								{participantName}
							</span>
						</Text>
					)}
				</div>
			</div>
		);
	};

	const renderTabs = (index: number, participant: string) => {
		const docs = valuesDataForMortgage?.documents;
		const shouldRenderBadge =
			docs &&
			docs.some(
				(data) =>
					data.completed[`${participant}${index}`] === false &&
					data.participants[`${participant}${index}`] === 'required'
			);
		return (
			<TabPane
				className="gb-tabpane"
				tab={
					<span>
						{type === 'priority' && shouldRenderBadge && (
							<Badge
								size="small"
								color="#FF4D4F"
								dot={true}
								style={{
									float: 'right',
									marginTop: 1,
									marginRight: -5,
									width: 4,
									height: 6,
									marginLeft: 4
								}}
							/>
						)}
						<span>{t(`mydocuments.${participant}${index}`)}</span>
					</span>
				}
				key={`${participant}${index}`}>
				<Spin spinning={loadingCard}>
					{type === 'priority' && renderDocumentCounter()}
					{type === 'priority' && renderButtonDoc()}
				</Spin>
			</TabPane>
		);
	};
	const ownerTabs = Array.from({ length: numOwners }, (_, i) => renderTabs(i + 1, 'owner'));
	const guarantorTabs = Array.from({ length: numGuarantors }, (_, i) =>
		renderTabs(i + 1, 'guarantor')
	);
	const documentTabs = [...ownerTabs, ...guarantorTabs];

	if (loading) {
		return (
			<div style={{ textAlign: 'center', height: '80vh', backgroundColor: 'white' }}>
				<LoaderLogo />
				<div style={{ display: 'flex', flexDirection: 'column', position: 'relative', top: '45%' }}>
					<Text style={{ fontSize: 14, fontWeight: 400, color: '#4D4D4D' }}>
						{t('document.waitUpload') + '...'}
					</Text>
				</div>
			</div>
		);
	}

	return (
		<>
			{type === 'priority' ? (
				<Text>{t('doc.attachDocumentation')}</Text>
			) : (
				<Text>{t('doc.aditionalDocumentation')}</Text>
			)}

			{clickTab && (
				<Tabs
					type="card"
					className="tabs-styles-documents"
					activeKey={selectedTab || clickTab}
					onChange={(values) => {
						setLoadingCard(true);
						setSelectedTab(values);
					}}>
					{documentTabs}
				</Tabs>
			)}

			<div style={{ marginTop: 16 }}>
				<Row gutter={[16, 16]}>
					{valuesDataForMortgage?.documents
						? valuesDataForMortgage.documents.map((data: any, index: any) => {
								return (
									<>
										{type === 'priority' && data.participants[clickTab!] === 'required' ? (
											<Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={6}>
												<Card
													key={index}
													className="gb-card-docs-not-up"
													style={{
														height: '100%',
														minHeight: isMobile ? 88 : 96,
														boxShadow: 'none'
													}}>
													<RenderCards
														data={data}
														clickTab={clickTab}
														index={index}
														handleClickPlus={handleClickPlus}
														playToBeforeUpload={playToBeforeUpload}
														visibleAfterBanksModal={visibleAfterBanksModal}
														setVisibleAfterBanksModal={setVisibleAfterBanksModal}
														loader={currentUploadingDocument === index && loadingDocumentUpload}
														valuesData={valuesData}
														modalDocument={(e: any) => modalDocument(e)}
														dataValueInfoUpload={() => dataValueInfoUpload()}
														documentsRefresh={() => documentsRefresh()}
														noTypeFile={undefined}
														env={env}
														images={{ tesoro, agencia, plus }}
														platform={'gibobs'}
													/>
												</Card>
											</Col>
										) : null}

										{type === 'aditional' && data.participants[clickTab!] === 'optional' ? (
											<Col xs={24} md={24} sm={12} lg={8} xl={8} xxl={6}>
												<Card
													key={index}
													className="gb-card-docs-not-up"
													style={{
														height: '100%',
														minHeight: isMobile ? 88 : 96,
														boxShadow: 'none'
													}}>
													<RenderCards
														data={data}
														clickTab={clickTab}
														index={index}
														handleClickPlus={handleClickPlus}
														playToBeforeUpload={playToBeforeUpload}
														visibleAfterBanksModal={visibleAfterBanksModal}
														setVisibleAfterBanksModal={setVisibleAfterBanksModal}
														loader={currentUploadingDocument === index && loadingDocumentUpload}
														valuesData={valuesData}
														modalDocument={(e: any) => modalDocument(e)}
														dataValueInfoUpload={() => dataValueInfoUpload()}
														documentsRefresh={() => documentsRefresh()}
														noTypeFile={undefined}
														env={env}
														images={{ tesoro, agencia, plus }}
														platform={'gibobs'}
													/>
												</Card>
											</Col>
										) : null}
									</>
								);
						  })
						: null}
				</Row>
			</div>

			{type === 'priority' && !isMobile && (
				<div style={{ marginTop: 16 }}>
					<div>
						<Text style={{ fontSize: 14, fontWeight: 500, color: '#2F4858' }}>
							*<span style={{ marginLeft: 4 }}>{t('doc.rememberNew')}</span>
						</Text>
						<br />
						<div
							style={{ fontSize: 12, marginLeft: 12, color: '#2F4858' }}
							dangerouslySetInnerHTML={{ __html: t('doc.needBankTransactios') }}
						/>
					</div>
					<div style={{ marginTop: 8 }}>
						<SearchOutlined style={{ marginRight: 4, fontSize: 13, color: '#2F4858' }} />
						<Text style={{ fontSize: 14, fontWeight: 500, color: '#2F4858' }}>
							{t('header.help')}:
						</Text>
						<br />
						<Text style={{ fontSize: 12, fontWeight: 300, color: '#2F4858', marginLeft: 12 }}>
							{t('doc.manual')}&nbsp;
							<span onClick={getDocumentInfoByDownlands} style={{ cursor: 'pointer' }}>
								<strong>{t('doc.downloadHere')}</strong>
							</span>
						</Text>
					</div>
				</div>
			)}

			<Modal
				className="modalMobileAfterBanks"
				open={visibleAfterBanksModal}
				onCancel={() => setVisibleAfterBanksModal(false)}
				onOk={() => {
					handleGetDocuments();
				}}
				cancelButtonProps={{
					style: { border: 'none', contentVisibility: 'hidden' }
				}}
				destroyOnClose={true}
				closable={true}>
				<Afterbanks type={'mortgage'} nameOwner={clickTab} env={env} />
			</Modal>

			<Modal
				className="modalMobileAfterBanks"
				open={visibleAfterBanksModal}
				onCancel={() => setVisibleAfterBanksModal(false)}
				onOk={() => {
					handleGetDocuments();
				}}
				cancelButtonProps={{
					style: { border: 'none', contentVisibility: 'hidden' }
				}}
				destroyOnClose={true}
				closable={true}>
				<Afterbanks type={'mortgage'} nameOwner={clickTab} env={env} />
			</Modal>

			<DeleteDocumentModal
				key={'deleteOpen'}
				visible={deleteOpen}
				onCreate={() => {
					deleteDocument(deleteDoc);
					setDeleteOpen(false);
				}}
				onCancel={() => {
					setDeleteOpen(false);
				}}
			/>

			{widgetLink && (
				<Modal
					className="modalbankflip"
					open={modalBankFlip}
					onCancel={() => {
						setModalBankFlip(false);
						clearInterval(timerReference);
					}}
					okButtonProps={{ style: { border: 'none', contentVisibility: 'hidden' } }}
					cancelButtonProps={{ style: { border: 'none', contentVisibility: 'hidden' } }}
					destroyOnClose={true}
					closable={true}
					width={800}>
					<iframe
						width={'100%'}
						height={'800px'}
						src={widgetLink.widgetLink}
						style={{ marginTop: 0, border: 'none' }}></iframe>
				</Modal>
			)}
		</>
	);
};
export default DocumentationSection;
