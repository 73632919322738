import React from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

const ModalButtonsCommon = ({
  visible,
  onCreate,
  onCancel,
  title,
  text,
  okText,
  cancelText,
}) => {
  const { t } = useTranslation();

  return (
    (<Modal
      className="ModalCommon"
      width={480}
      open={visible}
      onCancel={onCancel}
      onOk={onCreate}
      cancelButtonProps={{
        className: "button-secundary-gibobs",
        style: { width: "46%", height: 40 },
      }}
      okButtonProps={{
        className: "button-primari-gibobs",
        style: { width: "46%", height: 40 },
      }}
      title={t(title)}
      okText={t(okText)}
      cancelText={t(cancelText)}
      centered
    >
      <div
        style={{ marginTop: -30, color: "#748EA0" }}
        dangerouslySetInnerHTML={{
          __html: t(text),
        }}
      />
    </Modal>)
  );
};

export default ModalButtonsCommon;
