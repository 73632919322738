import { Modal, Typography } from 'antd';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';

const { Text } = Typography;

type Props = {
	visible: any;
	onCreate: any;
	numParticipants: any;
	owner: any;
	
};


const ModalManyThanksDocumentation = ({ visible, onCreate, numParticipants, owner }: Props) => {
	const { t } = useTranslation();

	return (
		<Modal
			className={isMobileOnly ? 'ModalCommonSmall' : 'ModalCommon'}
			open={visible}
			maskClosable={false}
			onOk={() => {
				onCreate();
			}}
			width={isMobileOnly ? 328 : 480}
			//heigh={isMobileOnly ? undefined : 656}
			okText={
				<Text style={{ color: '#FFFFFF', fontSize: 14, fontWeight: 600 }}>
					{owner === 'owner1'
						? t('doc.modalManyThanksOk')
						: t(`doc.continueOwnerModal`, {
								owner: t(`mydocuments.owner2`)
						  })}
				</Text>
			}
			cancelButtonProps={{
				style: { display: 'none' }
			}}
			okButtonProps={{
				style: {
					width: isMobileOnly ? '100%' : '96%',
					height: 40,
					marginTop: -40
				}
			}}
			centered
			destroyOnClose={true}
			closable={false}>
			<div style={{ textAlignLast: 'center' }}>
				<CheckOutlined style={{ fontSize: 40, color: '#52C41A', marginTop: 90 }} />
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginTop: 16,
						marginBottom: 16
					}}>
					<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>
						{t('form.thanks')}
					</Text>
					<div
						style={{ color: '#748EA0', fontSize: 14, fontWeight: 400, marginTop: 8 }}
						dangerouslySetInnerHTML={{
							__html:
								owner === 'owner1'
									? t('doc.documentsReadyOneOwner')
									: t('doc.documentsReadyMoreOwner', {
											owner: t(`mydocuments.owner2`)
									  })
						}}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default ModalManyThanksDocumentation;
