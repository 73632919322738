import React, { useEffect, useState } from "react";
import { Layout, Card, Tooltip, Row } from "antd";
import { useSelector } from "react-redux";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import DetailsScoring from "../detailsScoring";
import ScoreGibobsWidgets from "./ScoreGibobsWidgets";
import BalanceWidgets from "./BalanceWidgets";
import env from "../../../environment";
import axios from "axios";
import { isMobile } from "react-device-detect";
import LayoutOperations from "../LayoutOperations";

const ScoreGibobsPage = () => {
  const { t } = useTranslation();
  const userToken = useSelector((state) => state.auth.token);
  const [dataScoring, setDataScoring] = useState();
  const [dateScoringFlag, setDataScoringFlag] = useState(false);

  const dataScoringCall = () => {
    axios
      .get(`${env.api.url}/v1/scoring/get-scoring`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setDataScoring(response.data.data);
        setDataScoringFlag(true);
      });
  };
  useEffect(() => {
    dataScoringCall();
  }, []);

  const nameType = [
    { name: "incomeReleased", title: t("scoring.incomeReleased") },
    { name: "stressTest", title: t("scoring.stressTest") },
    { name: "liquidity", title: t("scoring.liquidity") },
    { name: "assetStability", title: t("scoring.assetStability") },
    { name: "ltv", title: t("scoring.ltv") },
    { name: "debtAveragePrice", title: t("scoring.debtAveragePrice") },
    { name: "yield", title: t("scoring.yield") },
    { name: "assetDiversification", title: t("scoring.assetDiversification") },
  ];


  return (
    <Layout>
      <div style={{ height: "auto", marginBottom: "300px" }}>
        <LayoutOperations />
        {/* <MyBreadcrumb data={data} /> */}
        <div style={{ display: "flex" }}>
          {!isMobile && (
            <Card
              style={{
                marginLeft: "40px",
                boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
                width: "calc(60% - 60px)",
                height: "calc(100% - 240px)",
                alignItems: "center",
                marginBottom: "40px",
              }}
            >
              <ScoreGibobsWidgets
                scoring={dataScoring ? dataScoring.scoring : 0}
                dateScoringFlag={dateScoringFlag}
              />
            </Card>
          )}
          {!isMobile && (
            <Card
              style={{
                marginLeft: "40px",
                boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
                width: "calc(40% - 60px)",
                height: "auto",
                alignItems: "center",
                marginBottom: "40px",
              }}
            >
              <BalanceWidgets
                completed={dataScoring ? dataScoring.completed : 0}
              />
            </Card>
          )}
          {isMobile && (
            <Card
              className="CardStyleClientGibobs"
              style={{
                width: "calc(100% - 32px)",
                marginLeft: "16px",
                marginBottom: "16px",
              }}
            >
              <ScoreGibobsWidgets
                scoring={dataScoring ? dataScoring.scoring : 0}
                dateScoringFlag={dateScoringFlag}
              />
            </Card>
          )}
        </div>
        <Card
          className="CardStyleClientGibobs"
          style={{
            marginLeft: isMobile ? "16px" : "40px",
            boxShadow: 0,
            border: "1px solid #C0F0F3",
            width: isMobile ? "calc(100% - 32px)" : "calc(100% - 80px)",
            backgroundColor: "#EFFCFC",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: isMobile ? "center" : "space-between",
              marginTop: "8px",
              marginRight: "8px",
              marginLeft: "8px",
              marginBottom: "33px",
            }}
          >
            <div
              style={{
                fontFamily: 'Inter',
                marginRight: isMobile ? "9px" : 0,
                fontSize: "14px",
                fontWeight: "600",
                color: "#2F4858",
              }}
            >
              {t("scoring.advices")}
            </div>
            <Tooltip
              placement="left"
              title="Información de interés"
              color={"#2F4858"}
              overlayClassName="toolTipProperty"
            >
              <InfoCircleOutlined
                color={"#2F4858"}
                style={{ fontSize: "14px", alignSelf: "center" }}
              />
            </Tooltip>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              flexFlow: "wrap",
              justifyContent: "flex-start",
              position: "relative",
              left: -20,
            }}
          >
            {nameType
              ? nameType.map((data, index) => {
                  if (index <= 2) {
                    return (
                      <Row>
                        <DetailsScoring
                          name={data.name}
                          titulo={data.title}
                          viable={
                            dataScoring
                              ? dataScoring.advices[data.name]
                              : "noData"
                          }
                        />
                      </Row>
                    );
                  }
                  if (2 < index && index <= 5) {
                    return (
                      <Row>
                        <DetailsScoring
                          name={data.name}
                          titulo={data.title}
                          viable={
                            dataScoring
                              ? dataScoring.advices[data.name]
                              : "noData"
                          }
                        />
                      </Row>
                    );
                  }
                  if (index > 5) {
                    return (
                      <Row>
                        <DetailsScoring
                          name={data.name}
                          titulo={data.title}
                          viable={
                            dataScoring
                              ? dataScoring.advices[data.name]
                              : "noData"
                          }
                        />
                      </Row>
                    );
                  }
                })
              : null}
          </div>
        </Card>
      </div>
    </Layout>
  );
};

export default ScoreGibobsPage;
