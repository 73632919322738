import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  LoadingOutlined,
  CreditCardOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import {
  Card,
  Button,
  Form,
  Typography,
  Spin,
  Modal,
  Col,
  Row,
} from "antd";
import CardVisa from "../../assets/CardVisa.png";
import CardMasterCard from "../../assets/CardMasterCard.svg";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { showPay, payEnd } from "../../store/data/actions";
import {  isMobileOnly, isTablet } from "react-device-detect";
import { stages } from "../../store/auth/actions";
import env from "../../environment";
import { useTranslation } from "react-i18next";
import { purchase } from "../../components/tagmanager";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { trace } from "../../components/tagmanager";

const { Text } = Typography;
const stripePromise = loadStripe(`${env.stripe.public_key}`);

const CheckoutForm = ({
  clientSecretToken,
  amount,
  mortgageId,
  userMortgageId,
}) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elementsCard = useElements();
  const [, setError] = useState(null);
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
  const userToken = useSelector((state) => state.auth.token);
  const [loading, ] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();;

  const location = useLocation();
  const rol = useSelector((state) => state.auth.roles);
  const { operationId } = useParams();
  const [nameOperations, setNameOperations] = useState(undefined)


  useEffect(() => {
    axios
    .get(`${env.api.url}/v1/operations/${operationId}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    })
    .then((response) => {
      setNameOperations(response.data.data.name);

    trace({
      location,
      userToken,
      rol,
      stage: "full_02_card_payment",
      operationName: response.data.data,
      //extra: operationsData
    });
    })
  }, []);

  const saveData = (participant, key, value) => {
    axios
      .post(
        `${env.api.url}/v1/mortgages/data/update/${mortgageId}`,
        { key, participant, value },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        console.log(response);
      });
  };

  const dataMultiple = (key, owner, value, key1, owner1, value1) => {
    axios
      .post(
        `${env.api.url}/v1/mortgages/data/multiple/update/${userMortgageId}`,
        {
          values: [
            {
              key: key,
              subkey: owner,
              value: value,
            },
            {
              key: key1,
              subkey: owner1,
              value: value1,
            },
          ],
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        console.log(response)
      });
  };
  const modalSuccess = () => {
    Modal.success({
      className: "ModalCommonConfirmPaid",
      title: t("m_payment.confirmTitle1") + t("m_payment.confirmTitle2"),
      icon: null,
      okText: t("viability.continue"),
      okType: "primary",
      content: (
        <div>
          {" "}
          <Text>{t("m_payment.confirmTitle3")}</Text>
        </div>
      ),
      onOk() {
        navigate(`/operations/${userMortgageId}/full`);
        dispatch(stages("full"));
        dataMultiple(
          "payments",
          "refundableDepositPaid",
          "Si",
          "payments",
          "refundableDepositAmount",
          amount ? amount : "50"
        );
      },
    });
  };

  const handleSubmit = async () => {
    let payload = "";

    if (amount !== undefined) {
      try {
        payload = await stripe.confirmCardPayment(`${clientSecretToken}`, {
          payment_method: {
            card: elementsCard.getElement(CardExpiryElement),
          },
        });
      } catch (e) {
        console.log("holaa" + e.message);
      }
      if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        alert(`Tu pago ha fallado ${payload.error.message}`);
      } else {
        purchase({ userToken, purchase: amount });
        alert("Tu pago ha sido procesado correctamente");

        dispatch(showPay(false));
        dispatch(payEnd(true));
        saveData("mortgage", "appraisalPaid", true);
      }
    }

    if (amount === undefined) {
      payload = await stripe.confirmCardPayment(`${clientSecretToken}`, {
        payment_method: {
          card: elementsCard.getElement(CardExpiryElement),
        },
      });

      if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        alert(`Tu pago ha fallado ${payload.error.message}`);
      } else {
        modalSuccess();
      }
    }

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      alert(`Tu pago ha fallado ${payload.error.message}`);
    }
  };


  return (
    <div>
      {isMobileOnly && !isTablet && nameOperations && (
        <Row>
          <Col xs={24}>
            <Card
              className="gcard-pay"
              style={{
                margin: "16px",
                boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
                marginTop: 16,
              }}
            >
            <Text>
              {amount
                ? t("viability.textPaidmentAppraisalviability") +
                  " " +
                  amount +
                  "€"
                : t("viability.textPaidmentBasicviability")}
            </Text>

              <div style={{ display: "flex", marginTop: 4 }}>
                {" "}
                <CheckOutlined
                  style={{ color: "#52C41A", marginRight: 10, marginTop: 5 }}
                />{" "}
                <Text
                  style={{ fontSize: 14, color: "#2F4858", fontWeight: 400 }}
                >
                  {t("appraisal.securePay")}
                </Text>
              </div>
              
              <Form
                onFinish={handleSubmit}
                style={{ marginTop: "20px" }}
                layout="vertical"
              >
                <Form.Item label={t("appraisal.emailSecure")} name="email">
                  <input
                    className="styleInput"
                    style={{
                      width: "100%",
                      paddingTop: "2px",
                      fontSize: "12px",
                    }}
                    placeholder={t("appraisal.emailSecure")}
                  />
                </Form.Item>

                <Form.Item
                  label={t("appraisal.InfoSecure")}
                  style={{ marginTop: -10 }}
                >
                  <div className="styleInput" style={{ display: "flex" }}>
                    <div style={{ width: "84%" }}>
                      <CardNumberElement />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "5px",
                        marginRight: "10px",
                      }}
                    >
                      <img
                        src={CardVisa}
                        alt="CardVisa"
                        height="16px"
                        style={{ marginRight: "5px" }}
                      />
                      <img src={CardMasterCard} alt="CardVisa" height="24px" />
                    </div>
                  </div>
                  <div style={{ display: "flex", marginTop: 8 }}>
                    <div
                      className="styleInput"
                      style={{
                        width: "50%",
                        marginRight: 8,
                      }}
                    >
                      <CardExpiryElement />
                    </div>
                    <div
                      className="styleInput"
                      style={{
                        width: "50%",
                        display: "flex",
                      }}
                    >
                      <div style={{ width: "84%" }}>
                        <CardCvcElement />
                      </div>
                      <CreditCardOutlined
                        style={{ float: "right", marginTop: "2px" }}
                      />
                    </div>
                  </div>
                </Form.Item>
                <Form.Item label={t("appraisal.NameSecure")} name="name">
                  <input
                    className="styleInput"
                    style={{
                      width: "100%",
                      paddingTop: "0px",
                      fontSize: "12px",
                    }}
                    placeholder={t("appraisal.NameSecure")}
                  />
                </Form.Item>

                <Button
                  htmlType="submit"
                  className="button-primari-gibobs"
                  type="primary"
                  style={{ marginTop: "10px", width: "100%", fontSize: "14px" }}
                  disabled={!stripe}
                >
                  {loading ? (
                    <Spin indicator={antIcon} className="spin" />
                  ) : (
                    t("appraisal.pay") + " " +amount + "€"
                  )}
                </Button>
              </Form>
            </Card>
            <div
              style={{ textAlign: "center", marginTop: 20 }}
              onClick={() => navigate("/dashboard-client")}
            >
              <Text style={{ fontSize: 12, color: "#02C3CD", fontWeight: 500 }}>
                {t("typeMortgage.saveAndOut")}
              </Text>
            </div>
          </Col>
        </Row>
      )}

      {isTablet && !isMobileOnly && nameOperations &&(
        <Row>
          

          <Col lg={24} md={24} sm={24} xs={24}>
            <Card
              className="gcard-pay"
              style={{
                margin: "16px",
                boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
                marginTop: 16,
              }}
            >
             <Text>
              {amount
                ? t("viability.textPaidmentAppraisalviability") +
                  " " +
                  amount +
                  "€"
                : t("viability.textPaidmentBasicviability")}
            </Text>

              <div style={{ display: "flex", marginTop: 4 }}>
                {" "}
                <CheckOutlined
                  style={{ color: "#52C41A", marginRight: 10, marginTop: 5 }}
                />{" "}
                <Text
                  style={{ fontSize: 14, color: "#2F4858", fontWeight: 400 }}
                >
                  {t("Pago seguro")}
                </Text>
              </div>
              <div style={{ display: "flex", marginTop: 4 }}>
                <CheckOutlined
                  style={{ color: "#52C41A", marginRight: 10, marginTop: 5 }}
                />{" "}
                <Text
                  style={{ fontSize: 14, color: "#2F4858", fontWeight: 400 }}
                >
                  {t(
                    "Garantía de devolución si no te conseguimos ninguna oferta o tú consigues una mejor"
                  )}
                </Text>
              </div>

              <Form
                onFinish={handleSubmit}
                style={{ marginTop: "20px" }}
                layout="vertical"
              >
                <Form.Item label="Correo electrónico" name="email">
                  <input
                    className="styleInput"
                    style={{
                      width: "100%",
                      paddingTop: "2px",
                      fontSize: "12px",
                    }}
                    placeholder="Correo electrónico"
                  />
                </Form.Item>

                <Form.Item
                  label="Información de la tarjeta"
                  style={{ marginTop: -10 }}
                >
                  <div className="styleInput" style={{ display: "flex" }}>
                    <div style={{ width: "84%" }}>
                      <CardNumberElement />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "5px",
                        marginRight: "10px",
                      }}
                    >
                      <img
                        src={CardVisa}
                        alt="CardVisa"
                        height="16px"
                        style={{ marginRight: "5px" }}
                      />
                      <img src={CardMasterCard} alt="CardVisa" height="24px" />
                    </div>
                  </div>
                  <div style={{ display: "flex", marginTop: 8 }}>
                    <div
                      className="styleInput"
                      style={{
                        width: "50%",
                        marginRight: 8,
                      }}
                    >
                      <CardExpiryElement />
                    </div>
                    <div
                      className="styleInput"
                      style={{
                        width: "50%",
                        display: "flex",
                      }}
                    >
                      <div style={{ width: "84%" }}>
                        <CardCvcElement />
                      </div>
                      <CreditCardOutlined
                        style={{ float: "right", marginTop: "2px" }}
                      />
                    </div>
                  </div>
                </Form.Item>
                <Form.Item label="Nombre de la tarjeta" name="name">
                  <input
                    className="styleInput"
                    style={{
                      width: "100%",
                      paddingTop: "0px",
                      fontSize: "12px",
                    }}
                    placeholder="Nombre de la tarjeta"
                  />
                </Form.Item>

                <Button
                  htmlType="submit"
                  className="button-primari-gibobs"
                  type="primary"
                  style={{ marginTop: "10px", width: "100%", fontSize: "14px" }}
                  disabled={!stripe}
                >
                  {loading ? (
                    <Spin indicator={antIcon} className="spin" />
                  ) : (
                    t("Pagar 50€")
                  )}
                </Button>
              </Form>
            </Card>
            <div
              style={{ textAlign: "center", marginTop: 20 }}
              onClick={() => navigate("/dashboard-client")}
            >
              <Text style={{ fontSize: 12, color: "#02C3CD", fontWeight: 500 }}>
                {t("typeMortgage.saveAndOut")}
              </Text>
            </div>
          </Col>
        </Row>
      )}

     
      {!isMobileOnly && nameOperations && !isTablet &&(
        <Card
          style={{
            margin: isMobileOnly ? "auto" : "auto 40px",
            width: !isMobileOnly ? "calc(100% - 80px)" : "auto",
            alignItems: "center",
            boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
            color: "",
          }}
        >
          <Card
            style={{
              width: !isMobileOnly ? "400px" : "auto",
              margin: "auto",
            }}
          >
            <Text>
              {amount
                ? t("viability.textPaidmentAppraisalviability") +
                  " " +
                  amount +
                  "€"
                : t("viability.textPaidmentBasicviability")}
            </Text>

            <Form
              onFinish={handleSubmit}
              style={{ marginTop: "20px" }}
              layout="vertical"
            >
              <Form.Item label="Correo electrónico" name="email">
                <input
                  className="styleInput"
                  style={{ width: "100%", paddingTop: "2px", fontSize: "12px" }}
                  placeholder="Correo electrónico"
                />
              </Form.Item>

              <Form.Item label="Información de la tarjeta">
                <div className="styleInput" style={{ display: "flex" }}>
                  <div style={{ width: "84%" }}>
                    <CardNumberElement />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "5px",
                      marginRight: "10px",
                    }}
                  >
                    <img
                      src={CardVisa}
                      alt="CardVisa"
                      height="16px"
                      style={{ marginRight: "5px" }}
                    />
                    <img src={CardMasterCard} alt="CardVisa" height="24px" />
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    className="styleInput"
                    style={{
                      width: "50%",
                      borderTop: "0",
                    }}
                  >
                    <CardExpiryElement />
                  </div>
                  <div
                    className="styleInput"
                    style={{
                      width: "50%",
                      borderTop: "0",
                      display: "flex",
                    }}
                  >
                    <div style={{ width: "84%" }}>
                      <CardCvcElement />
                    </div>
                    <CreditCardOutlined
                      style={{ float: "right", marginTop: "2px" }}
                    />
                  </div>
                </div>
              </Form.Item>
              <Form.Item label="Nombre de la tarjeta" name="name">
                <input
                  className="styleInput"
                  style={{ width: "100%", paddingTop: "5px", fontSize: "12px" }}
                  placeholder="Nombre de la tarjeta"
                />
              </Form.Item>

              <Button
                htmlType="submit"
                className="button-primari-gibobs"
                type="primary"
                style={{ marginTop: "40px", width: "100%", fontSize: "14px" }}
                disabled={!stripe}
              >
                {loading ? (
                  <Spin indicator={antIcon} className="spin" />
                ) : (
                  t("appraisal.pay")
                )}
              </Button>
            </Form>
          </Card>
        </Card>
      )}
    </div>
  );
};

function CheckOutPrueba({
  clientSecretToken,
  amount,
  showPayBoolean,
  mortgageId,
  userMortgageId,
  dataClient,
}) {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        clientSecretToken={clientSecretToken}
        amount={amount}
        showPayBoolean={showPayBoolean}
        mortgageId={mortgageId}
        userMortgageId={userMortgageId}
        dataClient={dataClient}
      />
    </Elements>
  );
}

export default CheckOutPrueba;
