import React from "react";
import { notification } from "antd";
import { Typography } from "antd";
import leftArrowIcon from "../../assets/icons/leftArrowIcon.svg";

const { Text } = Typography;

export const notificationGibobs = (type, title, description) => {
  notification[type]({
    message: (
      <div style={{ fontSize: "16px", color: "#2F4858", fontWeight: 600 }}>
        {title}
      </div>
    ),
    description: (
      <div style={{ fontSize: "12px", color: "#2F4858" }}>{description}</div>
    ),
  });
};

export const stagesNameMobile = (title, changePage, numberPage, icon) => {
  return (
    <div
      style={{
        marginBottom: 8,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: 16,
      }}
    >
      <Text style={{ fontSize: 16, fontWeight: 600 }}>{title}</Text>
      <div onClick={() => changePage(numberPage)}>{icon}</div>
    </div>
  );
};

export const titlePageMobile = (changePage, numberPage, title, stages) => {
  return (
    <div
      style={{
        marginBottom: 8,
        display: "flex",
        alignItems: "center",
        marginBottom: 24,
      }}
    >
      <div style={{ marginRight: 16 }} onClick={() => changePage(numberPage)}>
        <img src={leftArrowIcon} alt="leftArrowIcon" height={14} />
      </div>
      <Text style={{ fontSize: 16, fontWeight: 600 }}>{title}</Text>
      {stages && stages}
    </div>
  );
};
