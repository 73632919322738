import { useEffect, useState } from 'react';
import { Form, Col, Row, Card, Button, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import { notificationGibobs } from '../HerramientasVenta/reusableItems';
import { InfoCircleOutlined } from '@ant-design/icons';
import ModalDescriptionFinancial from './ModalDescriptionFinancial';
import HelpButton from './HelpButton';
import StepPromoter from './StepPromoter';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getPromoterData } from '../../store/promoter/actions';
import axios from 'axios';
import env from '../../environment';
import { InputNumber } from 'afrodita';


export const FormFinancialData = (lg, form) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();
	const [typeOfUnit, setTypeOfUnit] = useState('TOTAL_EUROS');
	const [totalCosts, setTotalCosts] = useState(0);
	const [totalBuildCosts, setTotalBuildCosts] = useState(0);
	const [totalUnitCosts, setTotalUnitCosts] = useState(0);
	const [unit, setUnit] = useState(0);
	const [m2t, setM2t] = useState(0);
	const [incomesObj, setIncomesObj] = useState({
		// incomes: 0,
		landCosts: 0,
		constructionsCosts: 0,
		associatedCosts: 0,
		businessCosts: 0,
		managementCosts: 0
	});
	const [euroBuildObj, setEuroBuildObj] = useState({
		// euroBuildProject: 0,
		euroBuildLand: 0,
		euroBuildConstruction: 0,
		euroBuildAssociated: 0,
		euroBuildBusiness: 0,
		euroBuildManagement: 0
	});
	const [euroUnitObj, setEuroUnitObj] = useState({
		// euroUnitProject: 0,
		euroUnitLand: 0,
		euroUnitConstruction: 0,
		euroUnitAssociated: 0,
		euroUnitBusiness: 0,
		euroUnitManagement: 0
	});

	const dataPromoter = useSelector((state) => state.promoter.dataFormPromoter);
	const colSpan = { xs: 24, lg: lg };

	const sumaTotal = (obj, name, value, setObj, setTotal) => {
		const array = obj;
		array[name] = value;
		let sum = 0;
		for (let key in array) {
			if (array[key] !== '') {
				sum += array[key];
			}
		}
		setObj(array);
		setTotal(sum);
	};
	const sumaUnit = (obj, setTotal) => {
		const array = obj;
		let sum = 0;
		for (let key in array) {
			if (array[key] !== '') {
				sum += array[key];
			}
		}
		setTotal(sum);
	};

	useEffect(async () => {
		form.setFieldsValue({
			totalCosts: totalCosts && totalCosts,
			euroBuildTotal: totalBuildCosts && totalBuildCosts.toFixed(2),
			euroUnitTotal: totalUnitCosts && totalUnitCosts.toFixed(2)
		});
		dispatch(getPromoterData({ ...dataPromoter, totalCosts: totalCosts }));
	}, [totalCosts, totalBuildCosts, totalUnitCosts]);

	useEffect(async () => {
		form.setFieldsValue({
			incomes:
				dataPromoter &&
				dataPromoter.economicData &&
				dataPromoter.economicData.projectIncomes.incomes
					? Number(dataPromoter.economicData.projectIncomes.incomes)
					: ''
		});
		const incomes = {
			landCosts:
				dataPromoter &&
				dataPromoter.economicData &&
				dataPromoter.economicData.projectCosts.landCosts
					? Number(dataPromoter.economicData.projectCosts.landCosts)
					: '',
			constructionsCosts:
				dataPromoter &&
				dataPromoter.economicData &&
				dataPromoter.economicData.projectCosts.constructionsCosts
					? Number(dataPromoter.economicData.projectCosts.constructionsCosts)
					: '',
			associatedCosts:
				dataPromoter &&
				dataPromoter.economicData &&
				dataPromoter.economicData.projectCosts.associatedCosts
					? Number(dataPromoter.economicData.projectCosts.associatedCosts)
					: '',
			businessCosts:
				dataPromoter &&
				dataPromoter.economicData &&
				dataPromoter.economicData.projectCosts.businessCosts
					? Number(dataPromoter.economicData.projectCosts.businessCosts)
					: '',
			managementCosts:
				dataPromoter &&
				dataPromoter.economicData &&
				dataPromoter.economicData.projectCosts.managementCosts
					? Number(dataPromoter.economicData.projectCosts.managementCosts)
					: ''
		};
		form.setFieldsValue(incomes);
		setIncomesObj(incomes);
		sumaUnit(incomes, setTotalCosts);
	}, []);

	useEffect(async () => {
		setUnit(
			dataPromoter && dataPromoter.generalData
				? Number(dataPromoter.generalData.salesUnits)
				: ''
		);
		setM2t(
			dataPromoter && dataPromoter.generalData
				? Number(dataPromoter.generalData.buildability)
				: ''
		);
	}, [dataPromoter]);

	useEffect(async () => {
		if (dataPromoter !== undefined) {
			form.setFieldsValue({
				euroBuildProject:
					dataPromoter &&
					dataPromoter.economicData &&
					dataPromoter.economicData.projectIncomes.incomes
						? Number((Number(dataPromoter.economicData.projectIncomes.incomes) / m2t).toFixed(2))
						: ''
			});
			const euroBuild = {
				euroBuildLand:
					dataPromoter &&
					dataPromoter.economicData &&
					dataPromoter.economicData.projectCosts.landCosts
						? Number((Number(dataPromoter.economicData.projectCosts.landCosts) / m2t).toFixed(2))
						: '',
				euroBuildConstruction:
					dataPromoter &&
					dataPromoter.economicData &&
					dataPromoter.economicData.projectCosts.constructionsCosts
						? Number(
								(Number(dataPromoter.economicData.projectCosts.constructionsCosts) / m2t).toFixed(2)
						  )
						: '',
				euroBuildAssociated:
					dataPromoter &&
					dataPromoter.economicData &&
					dataPromoter.economicData.projectCosts.associatedCosts
						? Number(
								(Number(dataPromoter.economicData.projectCosts.associatedCosts) / m2t).toFixed(2)
						  )
						: '',
				euroBuildBusiness:
					dataPromoter &&
					dataPromoter.economicData &&
					dataPromoter.economicData.projectCosts.businessCosts
						? Number(
								(Number(dataPromoter.economicData.projectCosts.businessCosts) / m2t).toFixed(2)
						  )
						: '',
				euroBuildManagement:
					dataPromoter &&
					dataPromoter.economicData &&
					dataPromoter.economicData.projectCosts.managementCosts
						? Number(
								(Number(dataPromoter.economicData.projectCosts.managementCosts) / m2t).toFixed(2)
						  )
						: ''
			};
			form.setFieldsValue(euroBuild);
			sumaUnit(euroBuild, setTotalBuildCosts);
			setEuroBuildObj(euroBuild);
		}
	}, [m2t]);

	useEffect(async () => {
		if (dataPromoter !== undefined) {
			form.setFieldsValue({
				euroUnitProject:
					dataPromoter &&
					dataPromoter.economicData &&
					dataPromoter.economicData.projectIncomes.incomes
						? Number((Number(dataPromoter.economicData.projectIncomes.incomes) / unit).toFixed(2))
						: ''
			});
			const euroUnit = {
				euroUnitLand:
					dataPromoter &&
					dataPromoter.economicData &&
					dataPromoter.economicData.projectCosts.landCosts
						? Number((dataPromoter.economicData.projectCosts.landCosts / unit).toFixed(2))
						: '',
				euroUnitConstruction:
					dataPromoter &&
					dataPromoter.economicData &&
					dataPromoter.economicData.projectCosts.constructionsCosts
						? Number((dataPromoter.economicData.projectCosts.constructionsCosts / unit).toFixed(2))
						: '',
				euroUnitAssociated:
					dataPromoter &&
					dataPromoter.economicData &&
					dataPromoter.economicData.projectCosts.associatedCosts
						? Number((dataPromoter.economicData.projectCosts.associatedCosts / unit).toFixed(2))
						: '',
				euroUnitBusiness:
					dataPromoter &&
					dataPromoter.economicData &&
					dataPromoter.economicData.projectCosts.businessCosts
						? Number((dataPromoter.economicData.projectCosts.businessCosts / unit).toFixed(2))
						: '',
				euroUnitManagement:
					dataPromoter &&
					dataPromoter.economicData &&
					dataPromoter.economicData.projectCosts.managementCosts
						? Number((dataPromoter.economicData.projectCosts.managementCosts / unit).toFixed(2))
						: ''
			};
			form.setFieldsValue(euroUnit);
			sumaUnit(euroUnit, setTotalUnitCosts);
			setEuroUnitObj(euroUnit);
		}
	}, [unit]);

	const calculateWithEuro = (calculate, value, name) => {
		if (name !== 'incomes') {
			sumaTotal(incomesObj, name, Number(value), setIncomesObj, setTotalCosts);
			sumaTotal(
				euroBuildObj,
				calculate[0],
				Number(value / m2t),
				setEuroBuildObj,
				setTotalBuildCosts
			);
			sumaTotal(euroUnitObj, calculate[1], Number(value / unit), setEuroUnitObj, setTotalUnitCosts);
		}

		form.setFieldsValue({
			[calculate[0]]: (value / m2t).toFixed(2),
			[calculate[1]]: (value / unit).toFixed(2)
		});
	};

	const calculateWithBuild = (calculate, value, name) => {
		const num = Number(value) * m2t;
		if (name !== 'euroBuildProject') {
			sumaTotal(incomesObj, calculate[0], num, setIncomesObj, setTotalCosts);
			sumaTotal(euroBuildObj, name, Number(value), setEuroBuildObj, setTotalBuildCosts);
			sumaTotal(euroUnitObj, calculate[1], num / unit, setEuroUnitObj, setTotalUnitCosts);
		}

		// setTotalBuildCosts(totalBuildCosts + value);
		// setTotalUnitCosts(totalUnitCosts + num / unit);

		form.setFieldsValue({
			[calculate[0]]: num.toFixed(2),
			[calculate[1]]: (num / unit).toFixed(2)
		});
	};

	const calculateWithUnit = (calculate, value, name) => {
		const num = Number(value) * unit;
		if (name !== 'euroUnitProject') {
			sumaTotal(incomesObj, calculate[0], num, setIncomesObj, setTotalCosts);
			sumaTotal(euroBuildObj, calculate[1], num / m2t, setEuroBuildObj, setTotalBuildCosts);
			sumaTotal(euroUnitObj, name, value, setEuroUnitObj, setTotalUnitCosts);
		}

		// setTotalBuildCosts(totalBuildCosts + num / m2t);
		// setTotalUnitCosts(totalUnitCosts + value);

		form.setFieldsValue({
			[calculate[0]]: num.toFixed(2),
			[calculate[1]]: (num / m2t).toFixed(2)
		});
	};
	const mandatoryValues = [
		'incomes',
		'euroBuildProject',
		'euroUnitProject',
		'landCosts',
		'euroBuildLand',
		'euroUnitLand'
	];

	const inputForm = (name, label, placeholder, rule, calculate) => {
		const colSpan1 = {
			xs: 24,
			lg: name === 'totalCosts' ? 12 : rule !== 'TOTAL_EUROS' ? 6 : 12
		};
		return (
			<Col {...colSpan1} style={{ paddingRight: 4, paddingLeft: 4 }}>
				<Form.Item
					name={name}
					label={t(label)}
					rules={[
						{
							required: mandatoryValues.includes(name) && typeOfUnit === rule ? true : false
						}
					]}>
					<InputNumber
						defaultValue={''}
						suffix={(rule === 'TOTAL_EUROS' || name === 'totalCosts') && '€'}
						disabled={typeOfUnit !== rule ? true : false}
						placeholder={placeholder}
						style={{
							width: '100%',
							border:
								(name === 'totalCosts' || name === 'euroBuildTotal' || name === 'euroUnitTotal') &&
								'none',
							backgroundColor:
								(name === 'totalCosts' || name === 'euroBuildTotal' || name === 'euroUnitTotal') &&
								'white'
						}}
						onChange={(e) => {
							if (e !== '') {
								rule === 'TOTAL_EUROS'
									? calculateWithEuro(calculate, Number(e), name)
									: rule === 'EUROS_BUILDABILITY'
									? calculateWithBuild(calculate, Number(e), name)
									: rule === 'EUROS_UNIT' && calculateWithUnit(calculate, Number(e), name);
							}
						}}
					/>
				</Form.Item>
			</Col>
		);
	};
	const onChange = (e) => {
		setTypeOfUnit(e.target.value);
		dispatch(getPromoterData({ ...dataPromoter, typeOfUnit: e.target.value }));
	};
	return (
		<>
			<div className="paragraph-style" style={{ marginBottom: 16 }}>
				{t('promoterLoan.economicDataDescription1')}
			</div>
			<Form.Item name={'radio-options'} label={''}>
				<Radio.Group
					className={lg === 12 ? 'radio-gibobs-promoter' : 'radio-gibobs-promoter-b'}
					onChange={onChange}
					defaultValue={typeOfUnit}>
					<Radio value={'TOTAL_EUROS'}>
						{typeOfUnit === 'TOTAL_EUROS' ? (
							<div style={{ color: '#2F4858' }}>{t('€ total')}</div>
						) : (
							t('€ total')
						)}
					</Radio>
					<Radio value={'EUROS_BUILDABILITY'}>
						{typeOfUnit === 'EUROS_BUILDABILITY' ? (
							<div style={{ color: '#2F4858' }}>{t('€/m²t')}</div>
						) : (
							t('€/m²t')
						)}
					</Radio>
					<Radio value={'EUROS_UNIT'}>
						{typeOfUnit === 'EUROS_UNIT' ? (
							<div style={{ color: '#2F4858' }}>{t('€/ud.')}</div>
						) : (
							t('€/ud.')
						)}
					</Radio>
				</Radio.Group>
			</Form.Item>
			{lg === 12 && (
				<div className="paragraph-style" style={{ marginBottom: 24 }}>
					{t('promoterLoan.economicDataDescription2')}
				</div>
			)}
			<div className="sub-title-promoter-gibobs" style={{ marginBottom: 16, marginTop: 24 }}>
				{t('promoterLoan.projectIncome')}
			</div>
			<Row gutter={120}>
				<Col {...colSpan}>
					<Row gutter={24} style={{ marginRight: -4, marginLeft: -4 }}>
						{inputForm('incomes', 'balance.incomesTitle', '€', 'TOTAL_EUROS', [
							'euroBuildProject',
							'euroUnitProject'
						])}
						{inputForm('euroBuildProject', '€/m²t', '0', 'EUROS_BUILDABILITY', [
							'incomes',
							'euroUnitProject'
						])}
						{inputForm('euroUnitProject', '€/ud.', '0', 'EUROS_UNIT', [
							'incomes',
							'euroBuildProject'
						])}
					</Row>
				</Col>
			</Row>

			<div className="sub-title-promoter-gibobs" style={{ marginBottom: 16, marginTop: 24 }}>
				{t('promoterLoan.projectCosts')}
			</div>

			<Row gutter={120}>
				<Col {...colSpan}>
					<Row gutter={24} style={{ marginRight: -4, marginLeft: -4 }}>
						{inputForm('landCosts', 'promoterLoan.landCosts', '€', 'TOTAL_EUROS', [
							'euroBuildLand',
							'euroUnitLand'
						])}
						{inputForm('euroBuildLand', '€/m²t', '0', 'EUROS_BUILDABILITY', [
							'landCosts',
							'euroUnitLand'
						])}
						{inputForm('euroUnitLand', '€/ud.', '0', 'EUROS_UNIT', ['landCosts', 'euroBuildLand'])}
						{inputForm('constructionsCosts', 'promoterLoan.constuctionCosts', '€', 'TOTAL_EUROS', [
							'euroBuildConstruction',
							'euroUnitConstruction'
						])}
						{inputForm('euroBuildConstruction', '€/m²t', '0', 'EUROS_BUILDABILITY', [
							'constructionsCosts',
							'euroUnitConstruction'
						])}
						{inputForm('euroUnitConstruction', '€/ud.', '0', 'EUROS_UNIT', [
							'constructionsCosts',
							'euroBuildConstruction'
						])}
						<div
							style={{
								width: '100%',
								marginBottom: 16
							}}></div>
						{inputForm('associatedCosts', 'promoterLoan.associatedCosts', '€', 'TOTAL_EUROS', [
							'euroBuildAssociated',
							'euroUnitAssociated'
						])}
						{inputForm('euroBuildAssociated', '€/m²t', '0', 'EUROS_BUILDABILITY', [
							'associatedCosts',
							'euroUnitAssociated'
						])}
						{inputForm('euroUnitAssociated', '€/ud.', '0', 'EUROS_UNIT', [
							'associatedCosts',
							'euroBuildAssociated'
						])}
					</Row>
				</Col>
				<Col {...colSpan}>
					<Row gutter={24} style={{ marginRight: -4, marginLeft: -4 }}>
						{inputForm('businessCosts', 'promoterLoan.commercialCosts', '€', 'TOTAL_EUROS', [
							'euroBuildBusiness',
							'euroUnitBusiness'
						])}
						{inputForm('euroBuildBusiness', '€/m²t', '0', 'EUROS_BUILDABILITY', [
							'businessCosts',
							'euroUnitBusiness'
						])}
						{inputForm('euroUnitBusiness', '€/ud.', '0', 'EUROS_UNIT', [
							'businessCosts',
							'euroBuildBusiness'
						])}
						{inputForm('managementCosts', 'promoterLoan.managementCosts', '€', 'TOTAL_EUROS', [
							'euroBuildManagement',
							'euroUnitManagement'
						])}
						{inputForm('euroBuildManagement', '€/m²t', '0', 'EUROS_BUILDABILITY', [
							'managementCosts',
							'euroUnitManagement'
						])}
						{inputForm('euroUnitManagement', '€/ud.', '0', 'EUROS_UNIT', [
							'managementCosts',
							'euroBuildManagement'
						])}
						<div
							style={{
								borderBottom: '4px solid #C0DAEE',
								width: '100%',
								marginBottom: 16
							}}></div>
						{inputForm('totalCosts', 'promoterLoan.totalCosts', '€')}
						{inputForm('euroBuildTotal', '€/m²t', '0')}
						{inputForm('euroUnitTotal', '€/ud.', '0')}
					</Row>
				</Col>
			</Row>
		</>
	);
};

const FinancialDataForm = () => {
	const [form] = Form.useForm();
	const params = useParams();
	const operationId = params.operationId;
	const { t } = useTranslation();
	const navigate = useNavigate();;
	const dispatch = useDispatch();
	const [modalDescription, setModalDescription] = useState(false);
	const dataPromoter = useSelector((state) => state.promoter.dataFormPromoter);
	const userToken = useSelector((state) => state.auth.token);
	const [loading, setLoading] = useState(false);

	const openNotificationWithIcon = () => {
		notificationGibobs('error', t('m_full.errorTitle'), t('form.incompleteInformation'));
	};

	const validateMessages = {
		required: '${label}' + ' ' + t('form.formRequired'),
		types: {
			email: t('form.emailValidated'),
			number: '${label} is not a valid number!'
		},
		number: {
			range: t('form.maxBudgetNewRequestValidated') + '${min}'
		}
	};

	const sendFinancialData = (values) => {
		setLoading(true);
		axios
			.post(`${env.api.url}/promoters/promoter-projects/economic-data/${operationId}`, values, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				if (response.data.success) {
					setLoading(false);
					dispatch(getPromoterData({ ...dataPromoter, economicData: values }));
					navigate(`/promoter-loan/capital-structure/${operationId}`);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const finanlcialDataVerificated = (values) => {
		const objFinancial = {
			economicDataUnits: 'TOTAL_EUROS',
			projectIncomes: {
				incomes: Number(values.incomes)
			},
			projectCosts: {
				landCosts: Number(values.landCosts),
				businessCosts: Number(values.businessCosts),
				constructionsCosts: Number(values.constructionsCosts),
				managementCosts: Number(values.managementCosts),
				associatedCosts: Number(values.associatedCosts),
				totalCosts: Number(values.totalCosts)
			}
		};
		sendFinancialData(objFinancial);
	};
	return (
		<>
			<Card
				style={{
					margin: 40,
					padding: !isMobileOnly && '0px 10%',
					marginBottom: 100
				}}>
				<StepPromoter
					data={[
						{
							name: 'promoterLoan.generalData'
						},
						{
							name: 'viability.economicData'
						},
						{
							name: 'promoterLoan.capitalStructure'
						}
					]}
					status={1}
					width={'60%'}
					promoter={true}
				/>
				<div
					style={{
						display: 'flex',
						marginBottom: 8,
						marginTop: 40,
						alignItems: 'center'
					}}>
					<div className="title-primary">{t('promoterLoan.economicDataProject')}</div>
					<InfoCircleOutlined
						style={{ color: '#02C3CD', marginLeft: 10 }}
						onClick={() => setModalDescription(true)}
					/>
				</div>
				<div className="paragraph-style" style={{ marginBottom: 8 }}>
					{t('promoterLoan.economicDataDescription')}
				</div>

				<Form
					className={'gb-form-profile-headlines-mobile'}
					onFinishFailed={openNotificationWithIcon}
					scrollToFirstError={true}
					onFinish={finanlcialDataVerificated}
					layout="vertical"
					name="Password"
					form={form}
					validateMessages={validateMessages}
					initialValues={
						dataPromoter
							? {
									'radio-options': 'TOTAL_EUROS',
									...dataPromoter
							  }
							: { 'radio-options': 'TOTAL_EUROS' }
					}>
					{FormFinancialData(12, form, 120)}
					<div style={{ textAlign: 'center' }}>
						<Button
							type="dashed"
							className="button-secundary-gibobs"
							style={{
								margin: 'auto',
								marginTop: 40,
								width: 120
							}}
							onClick={() => navigate(`/promoter-loan/general-data/${operationId}`)}>
							{t('form.previous')}
						</Button>
						<Button
							type="primary"
							className="button-primari-gibobs"
							style={{
								margin: 'auto',
								marginTop: 40,
								marginLeft: 8,
								width: 120
							}}
							htmlType="submit"
							loading={loading}>
							{t('form.next')}
						</Button>
					</div>
				</Form>
				<ModalDescriptionFinancial
					key={modalDescription}
					visible={modalDescription}
					onCreate={() => setModalDescription(false)}
					onCancel={() => setModalDescription(false)}
					width={!isMobileOnly && 880}
				/>
				<HelpButton origin={'IN_FORM_THREE_STEPS'} />
			</Card>
		</>
	);
};

export default FinancialDataForm;
