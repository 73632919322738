import React, { useState } from "react";
import { message, Button, Card, Layout, Typography, Radio, Space } from "antd";
import env from "../../../environment";
import axios from "axios";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    useNavigate,
    useParams,
} from "react-router-dom";
import LayoutProducts from "../../Products/LayoutProducts";
import { isMobile } from "react-device-detect";

const { Text } = Typography;

function CancelMortgage({ }) {
    const { t } = useTranslation();
    const userToken = useSelector((state) => state.auth.token);
    const [valueRadio, setValueRadio] = useState(1)
    let { operationId } = useParams();
    const navigate = useNavigate();

    const onChange = (e) => {
        setValueRadio(e.target.value)
    }

    const backToBasic = () => {
        navigate(`/operations/${operationId}/basic`);
    }

    const outMortgage = () => {
        if (valueRadio === 1) {
            axios
                .get(`${env.api.url}/v1/clients/contact-client/${operationId}`, {
                    headers: { Authorization: `Bearer ${userToken}` },
                })
                .then(() => {
                    message.success(t("button.initHelpMessage"));
                    navigate(`/dashboard-client`);

                });

        } else if (valueRadio === 2) {
            navigate(`/dashboard-client`);

        } else {
            axios
                .get(`${env.api.url}/v1/mortgages/no-interest-contact/${operationId}`, {
                    headers: { Authorization: `Bearer ${userToken}` },
                })
                .then(() => {
                    navigate(`/dashboard-client`);

                });
        }
    }


    return (
        <>
            {!isMobile &&
                <>
                    <LayoutProducts />

                    <Layout style={{ padding: 40 }}>

                        <Card className="gcard" style={{ marginBottom: 160, marginTop: -40 }}>
                            <Text style={{ fontSize: 16, fontWeight: 600, color: "#2F4858" }}>{t("cancel.mortgageOut")}</Text>

                            <div style={{ display: "flex", marginTop: 8 }}>
                                <Text style={{ fontSize: 14, fontWeight: 400, color: "#748EA0" }}>{t("cancel.mortgageOutDesc")}</Text>&nbsp;
                                <Text style={{ fontSize: 14, fontWeight: 600, color: "#748EA0" }}>{t("cancel.mortgageOutDesc1")}</Text>&nbsp;
                                <Text style={{ fontSize: 14, fontWeight: 400, color: "#748EA0" }}>{t("cancel.mortgageOutDesc2")}</Text>
                            </div>
                            <div style={{ marginTop: 24 }}>
                                <Text style={{ fontSize: 14, fontWeight: 400, color: "#748EA0" }}>{t("cancel.mortgageOutDesc3")}</Text>
                            </div>

                            <div style={{ marginTop: 24 }}>
                                <Radio.Group onChange={onChange} defaultValue={valueRadio}>
                                    <Space direction="vertical">
                                        <Radio value={1}>{<Text style={{ color: valueRadio === 1 ? "#2F4858" : "#748EA0", fontSize: 14, fontWeight: 400 }}>{t("cancel.radio1")}</Text>}</Radio>
                                        <Radio value={2}><Text style={{ color: valueRadio === 2 ? "#2F4858" : "#748EA0", fontSize: 14, fontWeight: 400 }}>{t("cancel.radio2")}</Text></Radio>
                                        <Radio value={3}><Text style={{ color: valueRadio === 3 ? "#2F4858" : "#748EA0", fontSize: 14, fontWeight: 400 }}>{t("cancel.radio3")}</Text></Radio>
                                    </Space>
                                </Radio.Group>
                            </div>

                            <div style={{ display: "flex", width: "100%", placeContent: "center", marginBottom: -40 }}>
                                <div>
                                    <Button

                                        className="button-secundary-gibobs"
                                        style={{
                                            margin: "40px auto",
                                            display: "block",
                                            height: "40px",
                                            width: "96px",
                                            fontSize: "14px",
                                        }}
                                        onClick={() => backToBasic()}
                                    >
                                        {t("viability.returnBasic")}
                                    </Button>
                                </div>
                                <div style={{ marginLeft: 8 }}>

                                    <Button
                                        type="primary"
                                        className="button-primari-gibobs"
                                        style={{
                                            margin: "40px auto",
                                            display: "block",
                                            height: "40px",
                                            width: "96px",
                                            fontSize: "14px",
                                        }}
                                        onClick={() => outMortgage()}

                                    >
                                        {t("viability.saveAndGetOut")}
                                    </Button>
                                </div>

                            </div>

                        </Card>
                    </Layout>
                </>
            }


            {isMobile &&
                <>

                    <LayoutProducts />

                    <Layout style={{ padding: 16 }}>
                        <Card className="gcardMovile" style={{ marginTop: -10 }}>
                            <Text style={{ fontSize: 16, fontWeight: 600, color: "#2F4858" }}>{t("cancel.mortgageOut")}</Text>

                            <div style={{ marginTop: 8 }}>
                                <Text style={{ fontSize: 14, fontWeight: 400, color: "#748EA0" }}>{t("cancel.mortgageOutDesc")}</Text>&nbsp;
                                <Text style={{ fontSize: 14, fontWeight: 600, color: "#748EA0" }}>{t("cancel.mortgageOutDesc1")}</Text>&nbsp;
                                <Text style={{ fontSize: 14, fontWeight: 400, color: "#748EA0" }}>{t("cancel.mortgageOutDesc2")}</Text>
                            </div>
                            <div style={{ marginTop: 24 }}>
                                <Text style={{ fontSize: 14, fontWeight: 400, color: "#748EA0" }}>{t("cancel.mortgageOutDesc3")}</Text>
                            </div>

                        </Card>
                        <Text style={{ fontSize: 12, fontWeight: 600, color: "#2F4858", marginTop: 24 }}>{t("chosse.optionMobile").toLocaleUpperCase()}</Text>
                        <div style={{ marginTop: 16 }}>
                            <Radio.Group onChange={onChange} defaultValue={valueRadio}>
                                <Space direction="vertical">
                                    <Radio value={1}>{<Text style={{ color: valueRadio === 1 ? "#2F4858" : "#748EA0", fontSize: 14, fontWeight: 400 }}>{t("cancel.radio1")}</Text>}</Radio>
                                    <Radio value={2}><Text style={{ color: valueRadio === 2 ? "#2F4858" : "#748EA0", fontSize: 14, fontWeight: 400 }}>{t("cancel.radio2")}</Text></Radio>
                                    <Radio value={3}><Text style={{ color: valueRadio === 3 ? "#2F4858" : "#748EA0", fontSize: 14, fontWeight: 400 }}>{t("cancel.radio3")}</Text></Radio>
                                </Space>
                            </Radio.Group>
                        </div>

                        <Card
                            className="cardButtonStyle"
                            style={{ marginBottom: -2, display: "flex", width: "100%", marginLeft: -14, marginBottom: 3 }}
                        >
                            <Button
                                className="button-secundary-gibobs"
                                style={{
                                    fontSize: "14px",
                                    width: "48%",
                                    height: 48,
                                    fontWeight: 600,

                                }}
                                onClick={() => backToBasic()}
                            >
                                {t("viability.returnBasic")}
                            </Button>

                            <Button
                                id="gb-mortgage-buy-situation-mobile"
                                className="button-primari-gibobs"
                                type="primary"
                                style={{
                                    fontSize: "14px",
                                    width: "48%",
                                    height: 48,
                                    fontWeight: 600,
                                    marginLeft: 8
                                }}
                                onClick={() => outMortgage()}

                            >
                                {t("viability.saveAndGetOut")}
                            </Button>
                        </Card>


                    </Layout>

                </>
            }
        </>

    );
}

export default CancelMortgage;
