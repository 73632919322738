import React from 'react';
import { Modal, Button, Typography, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RWebShare } from 'react-web-share';
import shareIcon from '../../../assets/icons/shareIcon.svg';
import { notificationGibobs } from '../../HerramientasVenta/reusableItems';
import { isMobileOnly } from 'react-device-detect';

const { Text } = Typography;

function ModalShareUs({ visible, onCancel, onCreate, width, save }) {
	const { t } = useTranslation();
	const navigate = useNavigate();;

	const copyToClipBoard = () => {
		var aux = document.createElement('input');
		aux.setAttribute('value', `https://www.gibobs.com/inmobiliarias-y-promotoras/`);
		document.body.appendChild(aux);
		aux.select();
		document.execCommand('copy');
		document.body.removeChild(aux);
		notificationGibobs('success', t('form.codeCopiedSuccessfully'));
		onCreate();
		navigate('/promoter-loan/products-under-contract');
	};
	return !isMobileOnly ? (
		<Modal
			centered
			className="ModalCommon modal-promoter-footer modal-promoter-header"
			cancelButtonProps={{
				className: 'button-secundary-gibobs',
				style: { width: '120px', display: 'none' }
			}}
			okButtonProps={{
				className: 'button-primari-gibobs',
				style: { width: '120px', display: 'none' }
			}}
			visible={visible}
			title={save ? t('onboarding.proyectSaveSuccessfully') : t('promoterLoan.recommendUs')}
			okText={t('form.ok')}
			cancelText={t('offers.cancel')}
			width={width}
			onCancel={() => {
				onCancel();
			}}
			onOk={() => {
				onCreate();
			}}>
			<div className="sub-title-promoter-gibobs" style={{ marginBottom: 16, fontWeight: 400 }}>
				{t('promoterLoan.shareItDescription')}
			</div>
			<div className="paragraph-project-data" style={{ color: '#2F4858' }}>
				{t('promoterLoan.linkShare')}
			</div>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div className="paragraph-style">https://www.gibobs.com/inmobiliarias-y-promotoras/</div>
				<div
					style={{
						display: 'flex',
						// marginBottom: 130,
						alignSelf: 'center'
					}}>
					<Button
						className="button-primari-gibobs"
						type="dashed"
						style={{
							marginLeft: 12,
							width: 120,
							height: 32,
							padding: 0,
							fontSize: 12
						}}
						onClick={() => copyToClipBoard()}>
						{t('init.copyLink')}
					</Button>

					<RWebShare
						data={{
							text: t('promoterLoan.shareLink'),
							url: 'https://www.gibobs.com/inmobiliarias-y-promotoras/',
							title: t('promoterLoan.recommendUs')
						}}
						onClick={() => {
							onCreate();
						}}>
						<Button
							className="button-primari-gibobs"
							type="primary"
							style={{
								padding: 0,
								marginLeft: 12,
								width: 32,
								height: 32
							}}
							icon={<img src={shareIcon} alt="shareIcon" style={{ marginTop: -2 }} />}></Button>
					</RWebShare>
				</div>
			</div>
		</Modal>
	) : (
		<Modal
			centered
			className="ModalCommon-movile-32px modal-promoter-footer modal-promoter-header modal-promoter-share-mobile"
			cancelButtonProps={{
				className: 'button-secundary-gibobs',
				style: { width: '120px', display: 'none' }
			}}
			okButtonProps={{
				className: 'button-primari-gibobs',
				style: { width: '120px', display: 'none' }
			}}
			width={328}
			open={visible}
			title={
				save ? (
					<Text style={{ fontSize: 16, fontWeight: 600 }}>
						{t('onboarding.proyectSaveSuccessfully')}
					</Text>
				) : (
					t('promoterLoan.recommendUs')
				)
			}
			okText={t('form.ok')}
			cancelText={t('offers.cancel')}
			onCancel={() => {
				onCancel();
				navigate('/promoter-loan/products-under-contract');
			}}
			onOk={() => {
				onCreate();
				navigate('/promoter-loan/products-under-contract');
			}}>
			<div className="sub-title-promoter-gibobs" style={{ marginBottom: 16, fontWeight: 400 }}>
				{t('promoterLoan.shareItDescription')}
			</div>
			<div className="paragraph-project-data" style={{ color: '#2F4858' }}>
				{t('promoterLoan.linkShare')}
			</div>
			<div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
				<Card
					className='gb-card-10px'
					style={{
						border: '1px solid var(--neutral-l-2, #C0DAEE',
						borderRadius: 4,
						marginTop: 8
					}}>
					<div className="paragraph-style">https://www.gibobs.com/inmobiliarias-y-promotoras/</div>
				</Card>
				<div
					style={{
						display: 'flex',
						marginTop: 16,
						alignSelf: 'center'
					}}>
					<Button
						className="button-primari-gibobs"
						type="dashed"
						style={{
							marginLeft: 12,
							width: 120,
							height: 32,
							padding: 0,
							fontSize: 12
						}}
						onClick={() => copyToClipBoard()}>
						{t('init.copyLink')}
					</Button>

					<RWebShare
						data={{
							text: t('promoterLoan.shareLink'),
							url: 'https://www.gibobs.com/inmobiliarias-y-promotoras/',
							title: t('promoterLoan.recommendUs')
						}}
						onClick={() => {
							onCreate();
							navigate('/promoter-loan/products-under-contract');
						}}>
						<Button
							className="button-primari-gibobs"
							type="primary"
							style={{
								padding: 0,
								marginLeft: 12,
								width: 32,
								height: 32
							}}
							icon={<img src={shareIcon} alt="shareIcon" style={{ marginTop: -2 }} />}></Button>
					</RWebShare>
				</div>
			</div>
		</Modal>
	);
}

export default ModalShareUs;
