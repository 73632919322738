import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, Skeleton, Spin, Typography, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import { isMobileOnly } from 'react-device-detect';
import editIcon from '../../../../assets/onboarding/editIcon.svg';
import { InfoCircleOutlined, FileOutlined } from '@ant-design/icons';
import EditData from './EditData';
import { titlePageMobile } from '../../../HerramientasVenta/reusableItems';
import TotalDifference from '../Modals/TotalDifference';
import DifferenceInQuota from '../Modals/DifferenceInQuota';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setDataViabilityForSubrogationOnboarding } from '../../../../store/viability/actions';
import { VIABILITYDATASUBROGATION } from '../../../../store/viability/constants';
import ComplicatedViability from '../../Components/ComplicatedViability';
import env from '../../../../environment';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { stages } from '../../../../store/auth/actions';
import { useNavigate, useLocation } from 'react-router-dom';
import analista_v2 from '../../../../assets/onboarding/analista_v2.gif';
import analyst_withoutbg from '../../../../assets/onboarding/analyst_withoutbg.gif';
import ideaIcon from '../../../../assets/loans/bulp.png';
import rightIcon from '../../../../assets/loans/rigth.png';
import { getAnalytics, logEvent } from 'firebase/analytics';
import _ from 'lodash';
import { trace } from '../../../../components/tagmanager';
import ReviewsCustomer from '../../../../components/widgets/ReviewsCustomer';
import IconFixed from '../../../../assets/viability/IconFixed.png';
import IconMixed from '../../../../assets/viability/IconMixed.png';
import IconSpread from '../../../../assets/viability/IconSpread.png';
import KnowMore from '../Modals/KnowMore';
import complicatedVia from '../../../../assets/viability/complicatedVia.png';
import AnalystIntroModal from '../Modals/AnalystIntroModal';
import i18n from '../../../../i18n';

const { Text } = Typography;

const ResultSubrogation = ({ dataOperation, refreshData, dataOperationAll }) => {
	const { t } = useTranslation();
	const { operationId } = useParams();
	const [visibleModalCalculationAmount, setVisibleModalCalculationAmount] = useState(false);
	const [visibleModalDifference, setVisibleModalDifference] = useState(false);
	const [visibleModalKnowMore, setVisibleModalKnowMore] = useState(false);
	const [, setRecalculateViability] = useState(false);
	const [costLinkagesGibobs, setCostLinkagesGibobs] = useState(undefined);
	const userToken = useSelector((state) => state.auth.token);
	const viabilityDataSubrogation = useSelector((state) => state.viability.viabilitySubrogation);
	const [changePages, setChangesPages] = useState(1);
	const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();;
	const analytics = getAnalytics();
	const location = useLocation();
	const rol = useSelector((state) => state.auth.roles);
	const [negativeSaving, setNegativeSaving] = useState(undefined);
	const [dataModals, setDataModals] = useState(undefined);
	const collapsed = useSelector((state) => state.data.collapseMenu);

	const dispatch = useDispatch();

	useEffect(() => {
		axios
			.get(`${env.api.url}/v1/options/get-option/annual-cost-linkages-gibobs`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				setCostLinkagesGibobs(response.data);
			});
	}, []);

	useEffect(() => {
		dispatch({
			type: VIABILITYDATASUBROGATION,
			payload: undefined
		});
		if (dataOperation !== undefined) {
			dispatch(
				setDataViabilityForSubrogationOnboarding(
					dataOperation && dataOperation.subrogation.amount,
					dataOperation && dataOperation.subrogation.dateEndMortgage,
					dataOperation &&
						dataOperation.subrogation.interest &&
						dataOperation.subrogation.interest === 'fixed'
						? dataOperation.subrogation.haveATin
						: undefined,
					dataOperation &&
						dataOperation.subrogation.interest &&
						dataOperation.subrogation.interest === 'variable'
						? dataOperation.subrogation.haveATin
						: undefined,
					operationId,
					userToken
				)
			);
		}
	}, []);

	useEffect(() => {
		if (
			viabilityDataSubrogation?.fixedQuote.savings?.total < 0 &&
			viabilityDataSubrogation?.mixedQuote.savings?.total < 0 &&
			viabilityDataSubrogation?.variableQuote.savings?.total < 0
		) {
			setNegativeSaving(true);
		} else {
			setNegativeSaving(false);
		}
	}, [viabilityDataSubrogation !== undefined]);

	useEffect(() => {
		if (viabilityDataSubrogation && dataOperationAll) {
			trace({
				location,
				userToken,
				rol,
				stage: 'basic_02_OK',
				operationName: dataOperationAll.name,
				viability: _.get(viabilityDataSubrogation, 'viability'),
				//amountMortgage: nameOperations && nameOperations.operationData && nameOperations.operationData.mortgage.maxBudget,
				//maxBudget: _.get(nameOperations, 'operationData.mortgage.maxBudget'),
				amountMortgage: _.get(dataOperationAll, 'operationData.subrogation.amount')
				//incomes: nameOperations && nameOperations.owners[0].netIncomes
			});
		}
	}, [dataOperationAll, viabilityDataSubrogation]);

	const changesStage = (owner, key, value) => {
		setLoading(true);
		axios
			.post(
				`${env.api.url}/v1/mortgages/data/update/${operationId}`,
				{ key, participant: owner, value },
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					axios
						.post(
							`${env.api.url}/v1/mortgages/check-full-declaratives`,
							{ operationId: operationId },
							{
								headers: { Authorization: `Bearer ${userToken}` }
							}
						)
						.then((response) => {
							if (response.data.success) {
								axios
									.get(`${env.api.url}/v1/auto-things/AutoStagesGenerator/${operationId}`, {
										headers: {
											Authorization: `Bearer ${userToken}`
										}
									})
									.then((response) => {
										if (response.data.success) {
											dispatch(stages(response.data.data.currentStage));
											navigate(`/operations/${operationId}/${response.data.data.currentStage}`);
											setLoading(false);
										}
									});
							}
						});
				}
			});
	};

	const renderQuotas = (title, quote, tin, years) => {
		if (title === 'viabilityNew.typeFixed') {
			return (
				<Row style={{ marginTop: 10, marginBottom: 65 }}>
					<Col span={12} style={{ display: 'flex', flexDirection: 'column' }}>
						<Text style={{ color: '#748EA0', fontSize: isMobileOnly ? 14 : 12, fontWeight: 400 }}>
							{t('onboarding.newQuota')}
						</Text>
						<Text style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>
							{'(' + numeral(tin).format('0,0.00') + '% TIN)'}
						</Text>
					</Col>
					<Col span={12} style={{ textAlign: 'right' }}>
						<Text style={{ color: '#748EA0', fontSize: isMobileOnly ? 14 : 12, fontWeight: 400 }}>
							{quote < 0
								? 0 + '€'
								: numeral(quote).format('0,0 $') + '/' + t('viability.monthsEstimated')}
						</Text>
					</Col>
				</Row>
			);
		} else if (title === 'viabilityNew.typeMixed') {
			return (
				<Row style={{ marginTop: 10 }}>
					<Col span={16} style={{ display: 'flex', flexDirection: 'column' }}>
						<Text style={{ color: '#748EA0', fontSize: isMobileOnly ? 14 : 12, fontWeight: 400 }}>
							{t('onboarding.new') +
								' ' +
								t('onboarding.quoteMoreYears', { years: years }).toLowerCase()}
						</Text>
						<Text style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>
							{'(' + numeral(tin).format('0,0.00') + '% TIN)'}
						</Text>
					</Col>
					<Col span={8} style={{ textAlign: 'right' }}>
						<Text style={{ color: '#748EA0', fontSize: isMobileOnly ? 14 : 12, fontWeight: 400 }}>
							{quote < 0
								? 0 + '€'
								: numeral(quote).format('0,0 $') + '/' + t('viability.monthsEstimated')}
						</Text>
					</Col>
				</Row>
			);
		} else {
			return (
				<Row style={{ marginTop: 10 }}>
					<Col span={12} style={{ display: 'flex', flexDirection: 'column' }}>
						<Text style={{ color: '#748EA0', fontSize: isMobileOnly ? 14 : 12, fontWeight: 400 }}>
							{t('onboarding.new') + ' ' + t('onboarding.quoteFirstYear')}
						</Text>
						<Text style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>
							{'(' + numeral(tin).format('0,0.00') + '% TIN)'}
						</Text>
					</Col>
					<Col span={12} style={{ textAlign: 'right' }}>
						<Text style={{ color: '#748EA0', fontSize: isMobileOnly ? 14 : 12, fontWeight: 400 }}>
							{quote < 0
								? 0 + '€'
								: numeral(quote).format('0,0 $') + '/' + t('viability.monthsEstimated')}
						</Text>
					</Col>
				</Row>
			);
		}
	};

	const renderQuotasMixed = (title, quote, tin) => {
		return (
			<Row style={{ marginTop: 10 }}>
				<Col span={16} style={{ display: 'flex', flexDirection: 'column' }}>
					<Text style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>
						{title === "viabilityNew.typeMixed" ? t('onboarding.quoteRestYearEstimated') : t('onboarding.quoteRestYear')}
					</Text>
					<Text style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>
						{'(Eur +' + numeral(tin).format('0,0.00') + '% TIN)'}
					</Text>
				</Col>
				<Col span={8} style={{ textAlign: 'right' }}>
					<Text style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>
						{quote < 0
							? 0 + '€'
							: numeral(quote).format('0,0 $') + '/' + t('viability.monthsEstimated')}
					</Text>
				</Col>
			</Row>
		);
	};

	const typeOfQuote = (
		title,
		icon,
		quote,
		quote2,
		savings,
		tae,
		tin,
		tin2,
		same,
		years,
		savingsByRest,
		savingsBySection,
		savingsCostsLinkages
	) => {
		if (savings < 0 && same === undefined) {
			return (
				<Col
					span={!isMobileOnly ? 8 : 24}
					style={{ marginTop: 25, minWidth: !isMobileOnly ? 328 : '100%' }}>
					<div className="viability-quote">
						<div style={{ display: 'flex' }}>
							<img
								src={complicatedVia}
								alt="complicatedVia"
								style={{ width: 32, height: 32 }}></img>
							<div
								className="title"
								style={{
									marginBottom: 16,
									minHeight: isMobileOnly && 32,
									marginLeft: 8,
									marginTop: isMobileOnly ? 4 : 8,
									fontSize: isMobileOnly && 20,
									color: '#C0DAEE'
								}}>
								{t(title)}{' '}
							</div>
						</div>
						<Divider
							type="horizontal"
							style={{
								borderColor: '#C0DAEE',
								marginTop: 0
							}}></Divider>

						<div>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
								{t('viability.notViability') + ' '}
							</Text>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 500 }}>
								{t('viability.notViability2')}
							</Text>
						</div>
						{!isMobileOnly && (
							<div style={{ textAlign: 'center', height: 148, overflow: 'hidden' }}>
								<img
									src={analyst_withoutbg}
									alt="analista_v2"
									style={{ width: 225, marginTop: 8 }}></img>
							</div>
						)}
					</div>
				</Col>
			);
		} else {
			return (
				<Col
					span={!isMobileOnly ? 8 : 24}
					style={{ marginTop: 25, minWidth: !isMobileOnly ? 328 : '100%' }}
					onClick={() => setVisibleModalKnowMore(true)}>
					{viabilityDataSubrogation !== undefined ? (
						<div className="viability-quote">
							<div style={{ display: 'flex' }}>
								<img src={icon} alt="IconFixed" style={{ width: 32, height: 32 }}></img>
								<div
									className="title"
									style={{
										marginBottom: 16,
										minHeight: isMobileOnly && 32,
										marginLeft: 8,
										marginTop: isMobileOnly ? 4 : 8,
										fontSize: isMobileOnly && 20
									}}>
									{t(title)}{' '}
								</div>
							</div>
							<Divider
								type="horizontal"
								style={{
									borderColor:
										title === 'viabilityNew.typeFixed'
											? '#05C69A'
											: title === 'viabilityNew.typeMixed'
												? '#F5CD57'
												: '#0088C6',
									marginTop: 0
								}}></Divider>

							<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>
								{t('viabilityNew.Tae') + ' ' + numeral(tae).format('0,0.00') + ' ' + '%'}
							</Text>

							{/**si hubiera mas tramos habria que poner aqui un map del renderQuotes */}

							{renderQuotas(title, quote, tin, years)}

							{title === 'viabilityNew.typeMixed' &&
								viabilityDataSubrogation?.mixedQuote?.sections[1] &&
								renderQuotasMixed(title, quote2, tin2)}
							{title === 'viabilityNew.typeSpread' &&
								viabilityDataSubrogation?.variableQuote?.sections[1] &&
								renderQuotasMixed(title, quote2, tin2)}

							<Divider
								type="horizontal"
								style={{
									borderColor: '#C0DAEE',
									marginTop: 20
								}}></Divider>

							<Row
								style={{
									marginBottom: title === 'viabilityNew.typeFixed' ? 18 : 16,
									display: 'flex',
									justifyContent: 'space-between'
								}}>
								<div>
									<Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 400 }}>
										{t('onboarding.withGibobsYouSave')}{' '}
									</Text>
								</div>

								<div style={{ textAlign: 'right' }}>
									<Text style={{ color: '#2F4858', fontSize: 20, fontWeight: 600 }}>
										{savings < 0 ? 0 + '€' : numeral(savings).format('0,0 $')}
									</Text>
									<InfoCircleOutlined
										style={{ color: '#2F4858', fontSize: 24, marginLeft: 6 }}
										onClick={(e) => {
											e.stopPropagation();
											setDataModals({
												title,
												savings,
												savingsByRest,
												savingsBySection,
												costLinkagesGibobs,
												savingsCostsLinkages
											});
											setVisibleModalDifference(true);
											logEvent(analytics, 'screen_view', {
												screen_name: 'onboarding2022.h_tooltipSavings'
											});
										}}
									/>
								</div>
							</Row>
						</div>
					) : (
						<div className="viability-quote">
							<Skeleton active />
							<div className="savings">
								<Skeleton active />
							</div>
						</div>
					)}
				</Col>
			);
		}
	};

	const renderDisabled = (dataOperation, viabilityDataSubrogation) => {
		if (dataOperation?.subrogation?.amount < 50000) {
			return true;
		} else if (dataOperation?.subrogation?.whenSignedMortgage === 'lessThanOneYear') {
			return true;
		} else if (viabilityDataSubrogation?.excepcionalViability !== undefined) {
			return true;
		} else {
			return false;
		}
	};

	const downloadFile = () => {
		axios
			.get(`${env.api.url}/v1/mortgages/generate-pdf-viabilidad-subrogacion/${operationId}`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				if (isMobileOnly) {
					window.location.href = response.data.url;
				} else {
					window.open(response.data.url, '_blank');
				}
			});
	};

	const downloadDocumentsDesktop = (title, download) => {
		return (
			<div 
				href={`${download}`}
				target={'_blank'}>
				<a href={download} target={'_blank'}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<FileOutlined style={{ fontSize: 16, marginRight: 4, color: '#748EA0' }}/>
						<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0' }}>
							{t(title)}
						</Text>
					</div>
				</a>
			</div>
		);
	};

	const DocumentsResidentMortgageEs =
		'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/docu_bienvenida_hipoteca_residentes_es.pdf';
	const DocumentsResidentMortgageEn =
		'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/docu_bienvenida_hipoteca_residentes_en.pdf';
	const DocumentsResidentMortgageCat =
		'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/docu_bienvenida_hipoteca_residentes_cat.pdf';

	let documentToDownload;

	if (i18n.language === 'es' || i18n.language === 'es-ES') {
		documentToDownload = DocumentsResidentMortgageEs;
	} else if (i18n.language === 'en') {
		documentToDownload = DocumentsResidentMortgageEn;
	} else {
		documentToDownload = DocumentsResidentMortgageCat;
	}

	return (
		<>
			{changePages === 2 && (
				<EditData
					changePage={titlePageMobile((e) => setChangesPages(e), 1, t('loan.editData'))}
					type="subrogation"
					dataOperation={dataOperation}
					refreshData={() => refreshData()}
					setPages={(e) => setChangesPages(e)}
					setRecalculateViability={(e) => setRecalculateViability(e)}
					style={{ marginTop: isMobileOnly && 16 }}
				/>
			)}
			{changePages === 1 &&
				(!loading ? (
					<div
						style={{
							padding: isMobileOnly ? 16 : 40,
							paddingTop: 24,
							marginBottom: 300,
							marginTop: isMobileOnly && 16,
							overflowX: 'hidden'
						}}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'end',
								alignItems: 'center',
								marginBottom: -23
							}}>
							{/* <img src={Gibobs} alt="Gibobs" height={50} /> */}
							{/* COLOCAR LA CONDICION CON LA VIABILIDAD COMPLICADA */}
							{!viabilityDataSubrogation?.excepcionalViability &&
								dataOperation?.subrogation?.whenSignedMortgage !== 'lessThanOneYear' && (
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center'
										}}>
										<div
											style={{
												color: '#02C3CD',
												fontSize: 14,
												fontWeight: 500,
												lineHeight: '20px',
												marginRight: 4,
												cursor: 'pointer'
											}}
											onClick={() => setChangesPages(2)}>
											{t('loan.editData')}
										</div>
										<img src={editIcon} alt="Gibobs" height={16} />
									</div>
								)}
						</div>
						{/* <div> CONDICION DE VIABIABILIDAD COMPLICADA CONDICION */}
						{dataOperation &&
							dataOperation.subrogation &&
							dataOperation.subrogation.whenSignedMortgage === 'lessThanOneYear' &&
							!viabilityDataSubrogation?.excepcionalViability && (
								<ComplicatedViability
									title={'onboarding.lessThanOneYearTitle'}
									description={'onboarding.lessThanOneYearDescription'}
									setPages={(e) => setChangesPages(e)}
								//subrogation={true}
								/>
							)}
						{viabilityDataSubrogation &&
							viabilityDataSubrogation.excepcionalViability === 'LOW_SUBROGATION' && (
								<ComplicatedViability
									title={'onboarding.lowSubrogationTitle'}
									description={'onboarding.outstandingCapitalDescription'}
									capital={numeral(dataOperation?.subrogation?.amount).format('0,0')}
									setPages={(e) => setChangesPages(e)}
								// subrogation={true}
								/>
							)}
						{viabilityDataSubrogation &&
							viabilityDataSubrogation.excepcionalViability === 'NOT_IMPROVE_SUBROGATION' && (
								<ComplicatedViability
									title={'onboarding.lowSubrogationTitle'}
									description={'onboarding.notImproveSubrogationDesc2'}
									setPages={(e) => setChangesPages(e)}
								// subrogation={true}
								/>
							)}{' '}
						<div
							style={{
								marginBottom: 200,
								width: !isMobileOnly && '60%',
								margin: 'auto'
							}}>
							<div
								className="title-subrogation"
								style={{ color: '#2F4858', fontWeight: 600, marginBottom: 8 }}>
								{t('onboarding.yourResult')}
							</div>
							{negativeSaving === false && viabilityDataSubrogation && (
								<div className="sub-title-subrogation" style={{ fontSize: 14 }}>
									{t('viabilityNew.yourResultDescription')}
								</div>
							)}
						</div>
						{!isMobileOnly && negativeSaving === false && (
							<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
								<Row gutter={24} style={{ margin: '0px -8px 32px' }}>
									<>
										{/* FIXED */}
										<Col xl={8} xxl={8} lg={12} md={12} sm={12} xs={24}>
											{typeOfQuote(
												'viabilityNew.typeFixed', // title fixed
												IconFixed, // icon fixed
												viabilityDataSubrogation?.fixedQuote.quote, // quote fixed
												undefined, //quote2 fixed
												viabilityDataSubrogation?.fixedQuote.savings?.total, // savings fixed
												viabilityDataSubrogation?.fixedQuote?.tae * 100, // tae fixed
												viabilityDataSubrogation?.fixedQuote?.tin * 100, // tin fixed
												undefined, // tin2 fixed
												undefined, // same
												viabilityDataSubrogation?.mixedQuote?.sections[0]?.to, // years fixed
												viabilityDataSubrogation?.fixedQuote?.savings?.savingsByRest, // savingsByRest fixed
												viabilityDataSubrogation?.fixedQuote?.savings?.savingsBySection, // savingsBySection fixed
												viabilityDataSubrogation?.fixedQuote?.savings?.savingsCostsLinkages //savingsCostsLinkages fixed
											)}
										</Col>
										{/* MIXED */}
										<Col xl={8} xxl={8} lg={12} md={12} sm={12} xs={24}>
											{typeOfQuote(
												'viabilityNew.typeMixed', // title mixed
												IconMixed, // icon mixed
												viabilityDataSubrogation?.mixedQuote?.sections[0]?.monthlyPayment, // quote mixed
												viabilityDataSubrogation?.mixedQuote?.sections[1]?.monthlyPayment, // quote2 mixed
												viabilityDataSubrogation?.mixedQuote?.savings?.total, // savings mixed
												viabilityDataSubrogation?.mixedQuote?.tae * 100, // tae mixed
												viabilityDataSubrogation?.mixedQuote?.sections[0]?.tin * 100, // tin mixed
												viabilityDataSubrogation?.mixedQuote?.sections[1]?.tin * 100, // tin2 mixed
												undefined, // same
												(viabilityDataSubrogation?.mixedQuote?.savings?.savingsBySection[0]?.section
													.to -
													viabilityDataSubrogation?.mixedQuote?.savings?.savingsBySection[0]
														?.section.from +
													1) /
												12, // years mixed
												viabilityDataSubrogation?.mixedQuote?.savings?.savingsByRest, // savingsByRest mixed
												viabilityDataSubrogation?.mixedQuote?.savings?.savingsBySection, // savingsBySection mixed
												viabilityDataSubrogation?.mixedQuote?.savings?.savingsCostsLinkages //savingsCostsLinkages mixed
											)}
											{/* VARIABLE */}
										</Col>
										<Col xl={8} xxl={8} lg={12} md={12} sm={12} xs={24}>
											{typeOfQuote(
												'viabilityNew.typeSpread', // title variable
												IconSpread, // icon variable
												viabilityDataSubrogation?.variableQuote?.sections[0]?.monthlyPayment, // quote variable
												viabilityDataSubrogation?.variableQuote?.sections[1]?.monthlyPayment, // quote2 variable
												viabilityDataSubrogation?.variableQuote?.savings?.total, // savings variable
												viabilityDataSubrogation?.variableQuote?.tae * 100, // tae variable
												viabilityDataSubrogation?.variableQuote?.sections[0]?.tin * 100, // tin variable
												viabilityDataSubrogation?.variableQuote?.sections[1]?.tin * 100, // tin2 variable
												undefined, // same
												undefined, // years variable
												viabilityDataSubrogation?.variableQuote?.savings?.savingsByRest, // savingsByRest variable
												viabilityDataSubrogation?.variableQuote?.savings?.savingsBySection, // savingsBySection variable
												viabilityDataSubrogation?.variableQuote?.savings?.savingsCostsLinkages //savingsCostsLinkages variable
											)}
										</Col>
									</>
								</Row>
							</div>
						)}
						{/* </div>  */}
						{negativeSaving && viabilityDataSubrogation && (
							<div style={{ width: '100%', textAlign: 'center' }}>
								<div style={{ width: isMobileOnly ? 318 : 509, display: 'inline-block' }}>
									<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
										{t('onboarding.moreInfo') + ' '}
									</Text>
									<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
										{t('onboarding.moreInfo2')}
									</Text>
									<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 500 }}>
										{t('onboarding.moreInfo3')}
									</Text>
								</div>
								<div style={{ marginTop: 24 }}>
									<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 500 }}>
										{t('onboarding.moreInfo4')}
									</Text>
								</div>
								<div style={{ textAlign: 'center' }}>
									<img src={analista_v2} alt="analista_v2" style={{ width: 245 }}></img>
								</div>
							</div>
						)}
						{isMobileOnly && negativeSaving === false && (
							<>
								{typeOfQuote(
									'viabilityNew.typeFixed', // title fixed
									IconFixed, // icon fixed
									viabilityDataSubrogation?.fixedQuote.quote, // quote fixed
									undefined, //quote2 fixed
									viabilityDataSubrogation?.fixedQuote.savings?.total, // savings fixed
									viabilityDataSubrogation?.fixedQuote?.tae * 100, // tae fixed
									viabilityDataSubrogation?.fixedQuote?.tin * 100, // tin fixed
									undefined, // tin2 fixed
									undefined, // same
									viabilityDataSubrogation?.mixedQuote?.sections[0]?.to, // years fixed
									viabilityDataSubrogation?.fixedQuote?.savings?.savingsByRest, // savingsByRest fixed
									viabilityDataSubrogation?.fixedQuote?.savings?.savingsBySection, // savingsBySection fixed
									viabilityDataSubrogation?.fixedQuote?.savings?.savingsCostsLinkages //savingsCostsLinkages fixed
								)}
								{typeOfQuote(
									'viabilityNew.typeMixed', // title mixed
									IconMixed, // icon mixed
									viabilityDataSubrogation?.mixedQuote?.sections[0]?.monthlyPayment, // quote mixed
									viabilityDataSubrogation?.mixedQuote?.sections[1]?.monthlyPayment, // quote2 mixed
									viabilityDataSubrogation?.mixedQuote?.savings?.total, // savings mixed
									viabilityDataSubrogation?.mixedQuote?.tae * 100, // tae mixed
									viabilityDataSubrogation?.mixedQuote?.sections[0]?.tin * 100, // tin mixed
									viabilityDataSubrogation?.mixedQuote?.sections[1]?.tin * 100, // tin2 mixed
									undefined, // same
									(viabilityDataSubrogation?.mixedQuote?.savings?.savingsBySection[0]?.section
										.to -
										viabilityDataSubrogation?.mixedQuote?.savings?.savingsBySection[0]
											?.section.from +
										1) /
									12, // years mixed
									viabilityDataSubrogation?.mixedQuote?.savings?.savingsByRest, // savingsByRest mixed
									viabilityDataSubrogation?.mixedQuote?.savings?.savingsBySection, // savingsBySection mixed
									viabilityDataSubrogation?.mixedQuote?.savings?.savingsCostsLinkages //savingsCostsLinkages mixed
								)}
								{/* VARIABLE */}
								{typeOfQuote(
									'viabilityNew.typeSpread', // title variable
									IconSpread, // icon variable
									viabilityDataSubrogation?.variableQuote?.sections[0]?.monthlyPayment, // quote variable
									viabilityDataSubrogation?.variableQuote?.sections[1]?.monthlyPayment, // quote2 variable
									viabilityDataSubrogation?.variableQuote?.savings?.total, // savings variable
									viabilityDataSubrogation?.variableQuote?.tae * 100, // tae variable
									viabilityDataSubrogation?.variableQuote?.sections[0]?.tin * 100, // tin variable
									viabilityDataSubrogation?.variableQuote?.sections[1]?.tin * 100, // tin2 variable
									undefined, // same
									undefined, // years variable
									viabilityDataSubrogation?.variableQuote?.savings?.savingsByRest, // savingsByRest variable
									viabilityDataSubrogation?.variableQuote?.savings?.savingsBySection, // savingsBySection variable
									viabilityDataSubrogation?.variableQuote?.savings?.savingsCostsLinkages //savingsCostsLinkages variable
								)}
							</>
						)}
						{((dataOperation &&
							dataOperation.subrogation &&
							dataOperation.subrogation.whenSignedMortgage === 'lessThanOneYear' &&
							!viabilityDataSubrogation?.excepcionalViability) ||
							viabilityDataSubrogation?.excepcionalViability) && (
								<Row gutter={24}>
									<Col span={24}>
										<div
											style={{
												border: '1px solid #1890FF',
												borderRadius: 4,
												padding: 24,
												margin: '32px auto',
												textAlign: 'center',
												width: !isMobileOnly && '60%',
												background: '#D4EAFF',
												display: 'flex',
												alignItems: 'center'
											}}>
											<img src={ideaIcon} alt="ideaIcon" height={24} />
											<div
												style={{ textAlign: 'left', marginLeft: 13, cursor: 'pointer' }}
												onClick={() => navigate('/my-financial-profile/credits/add/loan')}>
												<div
													style={{
														fontSize: 14,
														fontWeight: 400,
														color: '#2F4858'
													}}>
													{t('onboarding.gibobsExplanation')}
												</div>
												<div
													style={{
														marginTop: 10,
														color: '#126CBF',
														display: 'flex',
														alignItems: 'center',
														columnGap: 4
													}}>
													<div
														style={{
															fontSize: 14,
															fontWeight: 500
														}}>
														{t('onboarding.saveData')}
													</div>
													<img src={rightIcon} alt="ideaIcon" height={16} />
												</div>
											</div>
										</div>
									</Col>
								</Row>
							)}
						{isMobileOnly && viabilityDataSubrogation && (
							<>
								{viabilityDataSubrogation && !renderDisabled(dataOperation, viabilityDataSubrogation) &&
									<AnalystIntroModal operationId={operationId} changesStage={changesStage} />
								}
								<Card
									className="card-button-style-onboading"
									style={{
										left: 0,
										marginBottom: -2,
										backgroundColor: '#FAFEFE'
									}}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											width: '100%'
										}}>
										<div style={{ marginBottom: 16, textAlign: 'center' }}>
											<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
												{t('viability.textNewOnboarding')}
											</Text>
										</div>
										<Button
											id="gb-mortgage-buy-situation-mobile"
											className="button-primary-gibobs-inmo"
											type="primary"
											style={{
												fontSize: '14px',
												width: '100%',
												height: 48,
												fontWeight: 600,
												zIndex: 1200,
												fontSize: 16
											}}
											onClick={() => changesStage('process', 'basicCompleted', true)}
											htmlType="submit"
											disabled={renderDisabled(dataOperation, viabilityDataSubrogation)}>
											{t('viability.continueFreeSub')}
										</Button>
									</div>
								</Card>
							</>
						)}
						{!isMobileOnly && viabilityDataSubrogation && (
							<Card
								className="card-button-style-onboading"
								style={{
									left: collapsed ? 56 : 254,
									marginBottom: -2,
									backgroundColor: '#FAFEFE',
									zIndex: 1000,
									height: 128,
									width: collapsed ? 'calc(100% - 56px)' : 'calc(100% - 254px)',
									transition: 'width 0.3s ease',
									transition: 'left 0.3s ease'
								}}>
								<div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											width: '100%',
											gap: 32
										}}>
										<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
											{t('viability.textNewOnboarding')}
										</Text>
										<Button
											id="gb-mortgage-buy-situation-mobile"
											className="button-primary-gibobs-inmo"
											type="primary"
											style={{
												fontSize: '14px',
												width: 248,
												height: 48,
												fontWeight: 600
											}}
											// loading={loading}
											onClick={() => changesStage('process', 'basicCompleted', true)}
											disabled={renderDisabled(dataOperation, viabilityDataSubrogation)}>
											{t('viability.continueFreeSub')}
										</Button>
									</div>
									{viabilityDataSubrogation && !renderDisabled(dataOperation, viabilityDataSubrogation) && 
										<AnalystIntroModal operationId={operationId} changesStage={changesStage} />
									}
								</div>
							</Card>
						)}
						<div style={{ display: 'flex', marginTop: 24, justifyContent: isMobileOnly ? 'space-between' : 'center', padding: 10}}>
							<div onClick={() => downloadFile()} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
								<FileOutlined style={{ fontSize: 16, marginRight: 4, color: '#748EA0' }}/>
								<Text style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>
									{t('viability.resume')}
								</Text>
							</div>
							<div style={{ marginLeft: 16 }}>
								{downloadDocumentsDesktop('onboarding.welcomeDocument', documentToDownload)}
							</div>
						</div>
						<div
							className={
								isMobileOnly ? 'reviews-onboarding-styles-mobile' : 'reviews-onboarding-styles'
							}
							style={{ marginTop: isMobileOnly && 24 }}>
							<div className="title-reviews-onboarding-styles">
								{t('onboarding.confidenceCustomer')}
							</div>
							<div style={{ width: isMobileOnly ? '100%' : '40%' }}>
								<ReviewsCustomer />
							</div>
						</div>
						<div className="description-viability">{t('viability.explanationCalculationTae')}</div>
						{/* MODALES */}
						<DifferenceInQuota
							key={'visibleModalCalculationAmount'}
							visible={visibleModalCalculationAmount}
							onCreate={() => setVisibleModalCalculationAmount(false)}
							onCancel={() => setVisibleModalCalculationAmount(false)}
							width={800}
							dataOperation={dataOperation && dataOperation.subrogation}
						/>
						{dataModals && (
							<TotalDifference
								key={'visibleModalDifference'}
								visible={visibleModalDifference}
								dataModals={dataModals}
								onCreate={() => setVisibleModalDifference(false)}
								onCancel={() => setVisibleModalDifference(false)}
								width={800}
								dataOperation={dataOperation && dataOperation.subrogation}
							/>
						)}
						{renderDisabled(dataOperation, viabilityDataSubrogation) === false && 
						<KnowMore
							key={'visibleModalKnowMore'}
							visible={visibleModalKnowMore}
							onOk={() => changesStage('process', 'basicCompleted', true)}
							onCancel={() => setVisibleModalKnowMore(false)}
						/>
						}
					</div>
				) : (
					<Spin indicator={antIcon} className="spin" />
				))}
		</>
	);
};

export default ResultSubrogation;
