import React from "react";
import { Pie } from "ant-design-pro/lib/Charts";
import { useTranslation } from "react-i18next";
import "ant-design-pro/dist/ant-design-pro.css";
import { Typography } from "antd";

import numeral from "numeral";

const { Text } = Typography;
function PieClosedExpenses({
  loans,
  supplies,
  insurances,
  education,
  vehicles,
  rents,
  market,
  leisure,
  other,
}) {
  const { t } = useTranslation();
  const salesPieData = [
    {
      x: t("balance.loansTitle"),
      y: loans,
    },
    {
      x: t("balance.rentalsTitle"),
      y: rents,
    },
    {
      x: t("balance.suppliesTitle"),
      y: supplies,
    },
    {
      x: t("balance.insuranceTitle"),
      y: insurances,
    },
    {
      x: t("balance.educationTitle"),
      y: education,
    },
    {
      x: t("balance.vehiclesTitle"),
      y: vehicles,
    },
    {
      x: t("balance.shoppingTitle"),
      y: market,
    },
    {
      x: t("balance.leisure"),
      y: leisure,
    },
    {
      x: t("form.others"),
      y: other,
    },
  ];

  return (
    <>
      <Pie
        title="Activos"
        colors={[
          "#41D2DA",
          "#FFC069",
          "#FF7875",
          "#95DE64",
          "#D3F261",
          "#FFF566",
          "#B37FEB",
          "#FF85C0",
          "#69C0FF",
        ]}
        hasLegend={false}
        className="PieClosedExpense"
        data={salesPieData}
        valueFormat={(val) => <span>{numeral(val).format("0,0 $")}</span>}
        height={140}
      />

      <Text
        strong
        style={{
          marginRight: "10px",
          fontSize: "12px",
        }}
      >
        {t("balance.totalTitle")}
      </Text>
      <Text
        style={{
          color: "#02C3CD",
          fontSize: "12px",
        }}
      >
        {numeral(
          loans +
            supplies +
            insurances +
            education +
            vehicles +
            rents +
            market +
            leisure +
            other
        ).format("0,00 $")}
      </Text>
    </>
  );
}

export default PieClosedExpenses;
