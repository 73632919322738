import React, { useState, useEffect } from 'react';
import { Layout, Card, Typography, Row, Col, Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { isMobile, isMobileOnly } from 'react-device-detect';
import LayoutProductsPromoter from '../Layouts/LayoutProductsPromoter';
import addLoanImage from '../../../assets/loans/addLoan.png';
import { useNavigate } from 'react-router-dom';
import DetailProductPromoter from './DetailProductPromoter';
import shareIcon from '../../../assets/promoter/shareIcon.svg';
import ModalShareUs from '../CommonComponents/ModalShareUs';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import env from '../../../environment';
import { LoadingOutlined } from '@ant-design/icons';
import {
	changeRedirect,
	createNewProject,
	getListMyProyects
} from '../../../store/promoter/actions';
import LoaderLogo from '../../../components/LoaderLogo';

const { Text } = Typography;

function MyProductsPromoter() {
	const navigate = useNavigate();;
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [modalDescription, setModalDescription] = useState(false);
	const [imagesArray, setImagesArray] = useState([]);
	const loading = useSelector((state) => state.promoter.loading);
	const dataNewProject = useSelector((state) => state.promoter.dataNewProject);
	const dataRedirect = useSelector((state) => state.promoter.redirect);
	const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
	const proyects = useSelector((state) => state.promoter.dataListMyProyects);
	const userToken = useSelector((state) => state.auth.token);
	const [loading2, setLoading2] = useState(true);

	
	useEffect(() => {
		if (proyects !== undefined) {
			setLoading2(false)
		}
		
	}, [proyects]);

	useEffect(() => {
		dispatch(getListMyProyects());
	}, []);

	useEffect(() => {
		if (dataRedirect === true) {
			navigate(`/promoter-loan/step-form/${dataNewProject.id}`);
			dispatch(changeRedirect(!dataRedirect));
		}
	}, [dataRedirect]);



	const getImage = async () => {
		if (proyects !== undefined) {
			await axios
				.all(
					proyects.map(
						(l) =>
							l.operationDataClient &&
							l.operationDataClient.projectData &&
							l.operationDataClient.projectData.projectImageId &&
							axios.get(
								`${env.api.url}/v1/assets/download/${l.operationDataClient.projectData.projectImageId}/promoter-project`,
								{
									headers: { Authorization: `Bearer ${userToken}` }
								}
							)
					)
				)
				.then((res) => {
					const merged = [].concat(res);
					setImagesArray(merged);
				});
		}
	};

	useEffect(() => {
		if (proyects.length !== 0) {
			getImage();
		}
	}, [proyects]);

	if (loading2) {
		return (
			<div
			  style={{
				textAlign: "center",
				height: "80vh",
				backgroundColor: "white",
			  }}
			>
			  <LoaderLogo />
	  
			</div>
		  );
	} else {
		return (
			<Layout>
				<div
					style={{
						height: 'auto',
						marginBottom: '90px',
						marginLeft: isMobile ? 16 : 40,
						marginTop: isMobile ? 16 : 40,
						width: isMobile ? 'calc(100% - 32px)' : 'calc(100% - 80px)'
					}}>
					<LayoutProductsPromoter />

					<Row gutter={24}>
						{proyects &&
							imagesArray.length !== 0 &&
							proyects.map((data, i) => {
								return (
									<Col xs={24} lg={6} style={{ marginTop: 24 }} key={data.id}>
										<DetailProductPromoter
											getDataOperations={() => dispatch(getListMyProyects())}
											operationStatus={data}
											projectData={data.operationDataClient}
											projectName={
												data.operationDataClient && data.operationDataClient.projectData
													? data.operationDataClient.projectData.projectName
													: i === 0
													? `Proyecto ${i + 1}`
													: `Proyecto ${i + 1}`
											}
											municipality={
												data.operationDataClient &&
												data.operationDataClient.projectData &&
												data.operationDataClient.projectData.town
													? data.operationDataClient.projectData.town
													: '-'
											}
											price={
												data.operationDataClient &&
												data.operationDataClient.capitalStructure &&
												data.operationDataClient.capitalStructure.financingRequired
											}
											date={data.createdAt}
											stage={data.stage ? data.stage : '-'}
											image={
												imagesArray[i] &&
												imagesArray[i].data &&
												imagesArray[i].data.data &&
												imagesArray[i].data.data.link
											}
											index={data.id}
											proyects={data.operationDataClient}
											status={data.status}
										/>
									</Col>
								);
							})}

						{!isMobileOnly && (
							<Col xs={24} lg={6} style={{ marginTop: 24 }}>
								<Card
									className="gb-card"
									style={{ height: '100%', display: 'grid', borderRadius: 4 }}>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<Text className="title-page">{t('promoterLoan.newProject')}</Text>
										<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
											{t('promoterLoan.newProjectDescription')}
										</Text>
										{!loading ? (
											<img
												onClick={() => dispatch(createNewProject())}
												src={addLoanImage}
												alt="button"
												style={{
													marginTop: 24,
													width: 120,
													height: 120,
													alignSelf: 'center',
													cursor: 'pointer'
												}}></img>
										) : (
											<Spin indicator={antIcon} className="spin" />
										)}
									</div>
								</Card>
							</Col>
						)}

						<Col xs={24} lg={6} style={{ marginTop: 24 }}>
							<Card
								className="gb-card"
								style={{
									height: '100%',
									display: 'grid',
									borderRadius: 4,
									background: '#EFFCFC'
								}}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-between',
										height: '100%'
									}}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column'
										}}>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												marginBottom: 8
											}}>
											<img
												src={shareIcon}
												alt="shareIcon"
												height={18}
												style={{ marginRight: 15 }}
											/>
											<Text className="title-page">{t('promoterLoan.recommendUs')}</Text>
										</div>
										<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
											{t('promoterLoan.shareItDescription')}
										</Text>
									</div>
									<Button
										type="dashed"
										className="button-secundary-gibobs"
										style={{ background: 'transparent', marginTop: 30 }}
										onClick={() => setModalDescription(true)}>
										{t('inmo.whatsappShare')}
									</Button>
								</div>
							</Card>
						</Col>
					</Row>
				</div>
				{isMobileOnly && (
					<Card
						className="cardButtonStyle"
						style={{
							left: 0,
							backgroundColor: '#FAFEFE'
						}}>
						<Button
							type="primary"
							className="button-primari-gibobs"
							style={{
								margin: 'auto',
								marginLeft: 8,
								width: '100%',
								height: 48
							}}
							
							onClick={() => dispatch(createNewProject())}
							loading={loading}>
							{t('promoterLoan.newProject')}
						</Button>
					</Card>
				)}
				<ModalShareUs
					key={modalDescription}
					visible={modalDescription}
					onCreate={() => {
						setModalDescription(false);
					}}
					onCancel={() => setModalDescription(false)}
					width={!isMobileOnly && 640}
				/>
			</Layout>
		);
	}
}

export default MyProductsPromoter;
