export const PROFILE = "AUTH-PROFILE";
export const TOKEN = "AUTH-TOKEN";
export const LOADING = "AUTH-LOADING";
export const EXPIRES_AT = "AUTH-EXPIRES_AT";
export const ERROR = "AUTH-ERROR";
export const AUTHENTICATED = "AUTH-AUTHENTICATED";
export const ROLES = "AUTH-ROLES";
export const MORTGAGEID = "AUTH-MORTGAGEID";
export const OPERATIONS = "AUTH-OPERATIONS";
export const TABSPAGE = "AUTH-TABSPAGE";
export const STAGES = "AUTH-STAGES";
export const RELOADINIT = "AUTH-RELOADINIT";
export const CALLACT = "AUTH-CALLACT";
export const REFRESHSTAGES = "AUTH-REFRESHSTAGES";
export const REFRESHDATA = "AUTH-REFRESHDATA";
export const ALLROLES = "AUTH-ALLROLES";
