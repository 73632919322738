import React, { useState } from "react";
import { Form, Modal, Input, Col, Row, Select, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import { isMobile } from "react-device-detect";

const { Option } = Select;

function ModalIngresos({
  visible,
  onCancel,
  onCreate,
  taskToEdit,
  incomes
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [valueMonth, setValueMonth] = useState(taskToEdit ? "puntual" : null);

  return (
    (<Modal
      className="ModalCommon"
      cancelButtonProps={{ className: "button-secundary-gibobs" }}
      okButtonProps={{ className: "button-primari-gibobs" }}
      open={visible}
      title={incomes ? `${t("loan.addAsset")} ${t("balance.incomesTitle")}` : `${t("loan.addAsset")} ${t("balance.expensesTitle")}`}
      okText={t("form.send")}
      cancelText={t("mydocuments.deleteCancel")}
      width={672}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            if (valueMonth === "mensual") {
              values.month = [
                "valueEnero",
                "valueFebrero",
                "valueMarzo",
                "valueAbril",
                "valueMayo",
                "valueJunio",
                "valueJulio",
                "valueAgosto",
                "valueSeptiembre",
                "valueOctubre",
                "valueNoviembre",
                "valueDiciembre",
              ];
            }
            onCreate(values);
            form.resetFields();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="Contacto"
        initialValues={
          taskToEdit
            ? {
              name: taskToEdit.name,
              typeIncome: "puntual",
              active: taskToEdit.valueEnero || taskToEdit.valueFebrero,
              month: taskToEdit.property,
            }
            : {}
        }
      >
        <Row gutter={24}>
          {taskToEdit && taskToEdit.property === "name" ? (
            <Col span={12}>
              <Form.Item
                name="name"
                label={t("form.name")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder={t("form.name")}
                  style={{
                    width: isMobile ? "100%" : "280px",
                  }}
                />
              </Form.Item>
            </Col>
          ) : null}
          {!taskToEdit ? (
            <Col span={12}>
              <Form.Item
                name="name"
                label={t("form.name")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder={t("form.name")}
                  style={{
                    width: isMobile ? "100%" : "280px",
                  }}
                />
              </Form.Item>
            </Col>
          ) : null}
          {taskToEdit && taskToEdit.property !== "name" ? (
            <Col span={12}>
              <Form.Item
                name="active"
                label="Importe"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  step={100}
                  placeholder="Activos"
                  defaultValue={taskToEdit ? taskToEdit.value : null}
                  formatter={(value) => numeral(value).format("0,0 $")}
                  parser={(value) => numeral(value).value()}
                  style={{
                    borderColor: "#c0daee",
                    width: isMobile ? "100%" : "280px",
                    borderRadius: "4px",
                  }}
                />
              </Form.Item>
            </Col>
          ) : null}
          {!taskToEdit ? (
            <Col span={12}>
              <Form.Item
                name="active"
                label="Importe"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  step={100}
                  placeholder="Activos"
                  defaultValue={taskToEdit ? taskToEdit.value : null}
                  formatter={(value) => numeral(value).format("0,0 $")}
                  parser={(value) => numeral(value).value()}
                  style={{
                    borderColor: "#c0daee",
                    width: isMobile ? "100%" : "280px",
                    borderRadius: "4px",
                  }}
                />
              </Form.Item>
            </Col>
          ) : null}
          {taskToEdit && taskToEdit.property !== "name" ? (
            <Col span={12}>
              <Form.Item
                name="typeIncome"
                label="Tipo de ingresos"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  onChange={(values) => setValueMonth(values)}
                  style={{
                    width: isMobile ? "100%" : "280px",
                  }}
                >
                  <Option value="puntual">Puntual</Option>
                  <Option value="mensual">Mensual</Option>
                </Select>
              </Form.Item>
            </Col>
          ) : null}
          {!taskToEdit ? (
            <Col span={12}>
              <Form.Item
                name="typeIncome"
                label="Tipo de ingresos"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  onChange={(values) => setValueMonth(values)}
                  style={{
                    width: isMobile ? "100%" : "280px",
                  }}
                >
                  <Option value="puntual">Puntual</Option>
                  <Option value="mensual">Mensual</Option>
                </Select>
              </Form.Item>
            </Col>
          ) : null}
          {valueMonth &&
            valueMonth !== "mensual" &&
            taskToEdit &&
            taskToEdit.property !== "name" && (
              <Col span={12}>
                <Form.Item
                  name="month"
                  label="Mes"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    style={{
                      width: isMobile ? "100%" : "280px",
                    }}
                  >
                    <Option value="valueEnero">Enero</Option>
                    <Option value="valueFebrero">Febrero</Option>
                    <Option value="valueMarzo">Marzo</Option>
                    <Option value="valueAbril">Abril</Option>
                    <Option value="valueMayo">Mayo</Option>
                    <Option value="valueJunio">Junio</Option>
                    <Option value="valueJulio">Julio</Option>
                    <Option value="valueAgosto">Agosto</Option>
                    <Option value="valueSeptiembre">Septiembre</Option>
                    <Option value="valueOctubre">Octubre</Option>
                    <Option value="valueNoviembre">Noviembre</Option>
                    <Option value="valueDiciembre">Diciembre</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
          {valueMonth && valueMonth !== "mensual" && !taskToEdit && (
            <Col span={12}>
              <Form.Item
                name="month"
                label="Mes"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  style={{
                    width: isMobile ? "100%" : "280px",
                  }}
                >
                  <Option value="valueEnero">Enero</Option>
                  <Option value="valueFebrero">Febrero</Option>
                  <Option value="valueMarzo">Marzo</Option>
                  <Option value="valueAbril">Abril</Option>
                  <Option value="valueMayo">Mayo</Option>
                  <Option value="valueJunio">Junio</Option>
                  <Option value="valueJulio">Julio</Option>
                  <Option value="valueAgosto">Agosto</Option>
                  <Option value="valueSeptiembre">Septiembre</Option>
                  <Option value="valueOctubre">Octubre</Option>
                  <Option value="valueNoviembre">Noviembre</Option>
                  <Option value="valueDiciembre">Diciembre</Option>
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    </Modal>)
  );
}

export default ModalIngresos;
