import React, { useState } from 'react';
import { Card, Typography, Progress, Row, Col, Modal } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import chebron from '../../../../assets/loans/chebron.png';
import bankIcon from '../../../../assets/loans/bankIcon.svg';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { isMobileOnly } from 'react-device-detect';
const { Text } = Typography;

const CapitalWidget = ({ loan }) => {
	const { t } = useTranslation();
	const analytics = getAnalytics();

	let { loanId } = useParams();
	const [modalInterestPay, setModalInterestPay] = useState(false);
	const navigate = useNavigate();;

	return (<>
        {loan.loan.initialAmount !== null && loan.loan.initialAmount !== 0 && (
            <Card
                className="gcardMovile card-heigth-loan"
                style={{ marginTop: isMobileOnly ? 12 : 16, height: 'calc(100% - 36px)' }}>
                <div
                    style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text
                            style={{
                                fontSize: 12,
                                color: '#748EA0',
                                fontWeight: 400
                            }}>
                            {t('loan.amountAmortized')}
                        </Text>

                        <Progress
                            style={{
                                width: '100%',
                                marginTop: 4
                            }}
                            strokeColor={{
                                '0%': '#80E1E6',
                                '100%': '#02AFB8'
                            }}
                            percent={numeral(
                                ((loan.loan.initialAmount - loan.loan.amount) * 100) / loan.loan.initialAmount
                            ).format('0')}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                        <Text
                            style={{
                                fontSize: 12,
                                color: '#748EA0',
                                fontWeight: 400
                            }}>
                            {t('loan.amountBankInit')}
                        </Text>
                        <Text
                            style={{
                                fontSize: 16,
                                color: '#2F4858',
                                fontWeight: 600
                            }}>
                            {numeral(parseFloat(loan.loan.initialAmount)).format('0,0 $')}
                        </Text>
                    </div>
                    <Row>
                        <Col span={12} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Text
                                style={{
                                    fontSize: 12,
                                    color: '#748EA0',
                                    fontWeight: 400
                                }}>
                                {t('loan.capitalAmortize')}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 16,
                                    color: '#02C3CD',
                                    fontWeight: 600
                                }}>
                                {numeral(parseFloat(loan.loan.initialAmount - loan.loan.amount)).format(
                                    '0,0.00 $'
                                )}
                            </Text>
                        </Col>
                        <Col span={12} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Text
                                style={{
                                    fontSize: 12,
                                    color: '#748EA0',
                                    fontWeight: 400
                                }}>
                                {t('form.pendingCapital')}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 16,
                                    color: '#2F4858',
                                    fontWeight: 600
                                }}>
                                {numeral(parseFloat(loan.loan.amount)).format('0,0.00 $')}
                            </Text>
                        </Col>
                    </Row>
                    <div
                        style={{
                            display: 'flex',
                            cursor: 'pointer',
                            alignItems: 'center',
                            justifyContent: 'right'
                        }}>
                        <div
                            onClick={() => {
                                setModalInterestPay(!modalInterestPay);

                                logEvent(analytics, 'select_content', {
                                    content_type: 'modal.interest.capital',
                                    content_id: 'click.capital.info'
                                });
                            }}
                            style={{
                                fontSize: 14,
                                color: '#02C3CD',
                                fontWeight: 500
                            }}>
                            {t('loan.calculateYourInterestPayments')}
                        </div>
                        <img
                            src={chebron}
                            alt="asdf"
                            style={{
                                width: 16,
                                height: 16,
                                marginTop: 0,
                                marginLeft: 6
                            }}></img>
                    </div>
                </div>
            </Card>
        )}
        {!loan.loan.initialAmount && (
            <Card
                className="gcardMovile card-heigth-loan"
                style={{
                    marginTop: 16,
                    height: 'calc(100% - 36px)'
                }}>
                <div
                    style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}>
                    <img src={bankIcon} alt="bankIcon" height={42} />

                    <div
                        style={{
                            fontSize: 14,
                            color: '#748EA0',
                            fontWeight: 600
                        }}>
                        {t('loan.capitalWithoutDataTitle')}
                    </div>

                    <div
                        style={{
                            marginTop: 16,
                            display: 'flex',
                            cursor: 'pointer',
                            alignItems: 'center',
                            justifyContent: 'right'
                        }}>
                        <div
                            onClick={() =>
                                navigate(
                                    `/my-financial-profile/credits/edit/form/${loanId}`,{
                                    state: { input: 'initialAmount' }
                                })
                            }
                            style={{
                                fontSize: 14,
                                color: '#02C3CD',
                                fontWeight: 500
                            }}>
                            {t('m_common.knowMore')}
                        </div>
                        <img
                            src={chebron}
                            alt="asdf"
                            style={{
                                width: 16,
                                height: 16,
                                marginTop: 0,
                                marginLeft: 6
                            }}></img>
                    </div>
                </div>
            </Card>
        )}
        <Modal
            className="ModalCommon"
            key={'modalInterestPay'}
            open={modalInterestPay}
            cancelButtonProps={{
                className: 'button-secundary-gibobs',
                style: { width: '46%', height: 40 }
            }}
            okButtonProps={{
                className: 'button-primari-gibobs',
                style: { width: '46%', height: 40 }
            }}
            title={t('loan.howMuchInterestModal')}
            width={480}
            onCancel={() => setModalInterestPay(false)}
            onOk={() => {
                setModalInterestPay(false);
                logEvent(analytics, 'select_content', {
                    content_type: 'modal.interest.capital',
                    content_id: 'confirm.capital.info'
                });
            }}
            okText={t('loan.letMeKnow')}
            cancelText={t('form.no')}
            centered>
            <div
                style={{ marginTop: -30, color: '#748EA0' }}
                dangerouslySetInnerHTML={{
                    __html: t('loan.howMuchInterestModalDescription')
                }}
            />
        </Modal>
        {/* <Modal
    className="ModalCommon"
    key={"modalInterestPay"}
    visible={modalInterestPay}
    cancelButtonProps={{
      className: "button-secundary-gibobs",
      style: { width: "46%", height: 40 },
    }}
    okButtonProps={{
      className: "button-primari-gibobs",
      style: { width: "46%", height: 40 },
    }}
    title={t("loan.IncompleteDataModalTitle")}
    width={480}
    onCancel={() => setModalInterestPay(false)}
    onOk={() => {
      setModalInterestPay(false);
    }}
    okText={t("offers.cancel")}
    cancelText={t("offers.accept")}
    centered
  >
    <div
      style={{ marginTop: -30, color: "#748EA0" }}
      dangerouslySetInnerHTML={{
        __html: t("loan.IncompleteDataModalDescription"),
      }}
    />
  </Modal> */}
        {/* <Modal
    className="ModalCommon"
    key={"modalInterestPay"}
    visible={modalInterestPay}
    cancelButtonProps={{
      className: "button-secundary-gibobs",
      style: { width: "46%", height: 40 },
    }}
    okButtonProps={{
      className: "button-primari-gibobs",
      style: { width: "46%", height: 40 },
    }}
    title={t("loan.updatedDataModalTitle")}
    width={480}
    onCancel={() => setModalInterestPay(false)}
    onOk={() => {
      setModalInterestPay(false);
    }}
    okText={t("loan.updatedDataModalButtonUpdate")}
    cancelText={t("loan.updatedDataModalButtonAnotherTime")}
    centered
  >
    <div
      style={{ marginTop: -30, color: "#748EA0" }}
      dangerouslySetInnerHTML={{
        __html: t("loan.updatedDataModalDescription"),
      }}
    />
  </Modal> */}
    </>);
};

export default CapitalWidget;
