import React, { useContext, useState, useEffect, useRef } from "react";
import { Table, Input, Button, Form, Typography, Popover } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import numeral from "numeral";

const EditableContext = React.createContext(null);
const { Text } = Typography;

const EditableRow = ({...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableTableIncome = ({
  name,
  icon,
  showAddModal,
  taskEditHandler,
  index,
  dataToSource,
  showDeleteConfirm,
}) => {
  const columns = [
    {
      title: "Nombre",
      width: "10%",
      dataIndex: "name",
      editable: true,
    },
    {
      title: "Enero",
      dataIndex: "liquidity",
      // width: 200,
    },
    {
      title: "Febrero",
      dataIndex: "valueEnero",
      width: "30%",
      editable: true,
    },
    {
      title: "Marzo",
      dataIndex: "valueFebrero",
      width: "30%",
      editable: true,
    },
    {
      title: "Abril",
      dataIndex: "valueMarzo",
      width: "30%",
      editable: true,
    },
    {
      title: "Mayo",
      dataIndex: "valueAbril",
      width: "30%",
      editable: true,
    },
    {
      title: "Abril",
      dataIndex: "valueMayo",
      width: "30%",
      editable: true,
    },
    {
      title: "Junio",
      dataIndex: "valueJunio",
      width: "30%",
      editable: true,
    },
    {
      title: "Julio",
      dataIndex: "valueJulio",
      width: "30%",
      editable: true,
    },
    {
      title: "Agosto",
      dataIndex: "valueAgosto",
      width: "30%",
      editable: true,
    },
    {
      title: "Septiembre",
      dataIndex: "valueSeptiembre",
      width: "30%",
      editable: true,
    },
    {
      title: "Octubre",
      dataIndex: "valueOctubre",
      width: "30%",
      editable: true,
    },
    {
      title: "Noviembre",
      dataIndex: "valueNoviembre",
      width: "30%",
      editable: true,
    },
    {
      title: "Diciembre",
      dataIndex: "valueDiciembre",
      width: "30%",
      editable: true,
    },
    {
      title: "Total",
      dataIndex: "valueTotal",
      width: "30%",
      editable: true,
    fixed:'right'
    },
  ];

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const dataSource = dataToSource.map((dataObj) => {
    return {
      id: dataObj.id,
      key: dataObj.id,
      name: (
        <div style={{ width: "16ch", whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
          <Popover
            overlayClassName="popOverTableEditable"
            placement="right"
            content={
              <div>
                <EditOutlined
                  style={{ color: "#02C3CD", marginRight: "10px" }}
                  onClick={() => taskEditHandler(dataObj.id, "name")}
                />
                <DeleteOutlined
                  style={{ color: "#02C3CD" }}
                  onClick={() => showDeleteConfirm(dataObj.id)}
                />
              </div>
            }
            trigger="hover"
          >
            {dataObj.name}
          </Popover>
        </div>
      ),
      valueEnero: (
        <div style={{ width: "50px", color: "#748EA0" }}>
          <Popover
            overlayClassName="popOverTableEditable"
            placement="right"
            content={
              <div>
                <EditOutlined
                  style={{ color: "#02C3CD" }}
                  onClick={() => taskEditHandler(dataObj.id, "valueEnero")}
                />
              </div>
            }
            trigger="hover"
          >
            {numeral(dataObj.valueEnero).format("0,0 $")}
          </Popover>
        </div>
      ),
      valueFebrero: (
        <div style={{ width: "50px", color: "#748EA0" }}>
          <Popover
            overlayClassName="popOverTableEditable"
            placement="right"
            content={
              <div>
                <EditOutlined
                  style={{ color: "#02C3CD" }}
                  onClick={() => taskEditHandler(dataObj.id, "valueFebrero")}
                />
              </div>
            }
            trigger="hover"
          >
            {numeral(dataObj.valueFebrero).format("0,0 $")}
          </Popover>
        </div>
      ),
      valueMarzo: (
        <div style={{ width: "50px", color: "#748EA0" }}>
          <Popover
            overlayClassName="popOverTableEditable"
            placement="right"
            content={
              <div>
                <EditOutlined
                  style={{ color: "#02C3CD" }}
                  onClick={() => taskEditHandler(dataObj.id, "valueMarzo")}
                />
              </div>
            }
            trigger="hover"
          >
            {numeral(dataObj.valueMarzo).format("0,0 $")}
          </Popover>
        </div>
      ),
      valueAbril: (
        <div style={{ width: "50px", color: "#748EA0" }}>
          <Popover
            overlayClassName="popOverTableEditable"
            placement="right"
            content={
              <div>
                <EditOutlined
                  style={{ color: "#02C3CD" }}
                  onClick={() => taskEditHandler(dataObj.id, "valueAbril")}
                />
              </div>
            }
            trigger="hover"
          >
            {numeral(dataObj.valueAbril).format("0,0 $")}
          </Popover>
        </div>
      ),
      valueMayo: (
        <div style={{ width: "50px", color: "#748EA0" }}>
          <Popover
            overlayClassName="popOverTableEditable"
            placement="right"
            content={
              <div>
                <EditOutlined
                  style={{ color: "#02C3CD" }}
                  onClick={() => taskEditHandler(dataObj.id)}
                />
              </div>
            }
            trigger="hover"
          >
            {numeral(dataObj.valueMayo).format("0,0 $")}
          </Popover>
        </div>
      ),
      valueJunio: (
        <div style={{ width: "50px", color: "#748EA0" }}>
          <Popover
            overlayClassName="popOverTableEditable"
            placement="right"
            content={
              <div>
                <EditOutlined
                  style={{ color: "#02C3CD" }}
                  onClick={() => taskEditHandler(dataObj.id)}
                />
              </div>
            }
            trigger="hover"
          >
            {numeral(dataObj.valueJunio).format("0,0 $")}
          </Popover>
        </div>
      ),
      valueJulio: (
        <div style={{ width: "50px", color: "#748EA0" }}>
          <Popover
            overlayClassName="popOverTableEditable"
            placement="right"
            content={
              <div>
                <EditOutlined
                  style={{ color: "#02C3CD" }}
                  onClick={() => taskEditHandler(dataObj.id)}
                />
              </div>
            }
            trigger="hover"
          >
            {numeral(dataObj.valueJulio).format("0,0 $")}
          </Popover>
        </div>
      ),
      valueAgosto: (
        <div style={{ width: "50px", color: "#748EA0" }}>
          <Popover
            overlayClassName="popOverTableEditable"
            placement="right"
            content={
              <div>
                <EditOutlined
                  style={{ color: "#02C3CD" }}
                  onClick={() => taskEditHandler(dataObj.id)}
                />
              </div>
            }
            trigger="hover"
          >
            {numeral(dataObj.valueAgosto).format("0,0 $")}
          </Popover>
        </div>
      ),
      valueSeptiembre: (
        <div style={{ width: "50px", color: "#748EA0" }}>
          <Popover
            overlayClassName="popOverTableEditable"
            placement="right"
            content={
              <div>
                <EditOutlined
                  style={{ color: "#02C3CD" }}
                  onClick={() => taskEditHandler(dataObj.id)}
                />
              </div>
            }
            trigger="hover"
          >
            {numeral(dataObj.valueSeptiembre).format("0,0 $")}
          </Popover>
        </div>
      ),
      valueOctubre: (
        <div style={{ width: "50px", color: "#748EA0" }}>
          <Popover
            overlayClassName="popOverTableEditable"
            placement="right"
            content={
              <div>
                <EditOutlined
                  style={{ color: "#02C3CD" }}
                  onClick={() => taskEditHandler(dataObj.id)}
                />
              </div>
            }
            trigger="hover"
          >
            {numeral(dataObj.valueOctubre).format("0,0 $")}
          </Popover>
        </div>
      ),
      valueNoviembre: (
        <div style={{ width: "50px", color: "#748EA0" }}>
          <Popover
            overlayClassName="popOverTableEditable"
            placement="right"
            content={
              <div>
                <EditOutlined
                  style={{ color: "#02C3CD" }}
                  onClick={() => taskEditHandler(dataObj.id)}
                />
              </div>
            }
            trigger="hover"
          >
            {numeral(dataObj.valueNoviembre).format("0,0 $")}
          </Popover>
        </div>
      ),
      valueDiciembre: (
        <div style={{ width: "50px", color: "#748EA0" }}>
          <Popover
            overlayClassName="popOverTableEditable"
            placement="right"
            content={
              <div>
                <EditOutlined
                  style={{ color: "#02C3CD" }}
                  onClick={() => taskEditHandler(dataObj.id)}
                />
              </div>
            }
            trigger="hover"
          >
            {numeral(dataObj.valueDiciembre).format("0,0 $")}
          </Popover>
        </div>
      ),
      valueTotal: (
        <div style={{ width: "80px", color: "#748EA0" }}>
          {numeral(
            Object.values(dataObj).reduce((a, b) => {
              if (isNaN(a)) {
                return 0;
              }
              return a + b;
            })
          ).format("0,0 $")}
        </div>
      ),
    };
  });

  columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <div
        style={{
          alignSelf: "center",
          width: "120px",
          display: "flex",
          marginLeft: "16px",
        }}
      >
        <Button
          type="primary"
          className="button-primari-gibobs"
          icon={
            <PlusOutlined style={{ display: "flex", placeContent: "center" }} />
          }
          style={{
            width: "24px",
            height: "24px",
            marginRight: "16px",
          }}
          onClick={() => showAddModal(index)}
        />
        <Text
          style={{
            color: "#2F4858",
            fontSize: "12px",
            // marginRight: "8px",
            alignSelf: "center",
            width: "80px",
          }}
        >
          {name}
        </Text>
        <div style={{ color: "#2F4858", fontSize: "16px" }}>{icon}</div>
      </div>
      {dataSource.length !== 0 ? (
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          dataSource={dataSource}
          columns={columns}
          showHeader={false}
          pagination={false}
          style={{ marginLeft: "20px" }}
          // bordered='fixed'
        />
      ) : (
        <div style={{ height: "30px", marginBottom: "10px" }}></div>
      )}
    </div>
  );
};

export default EditableTableIncome;
