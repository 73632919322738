import React, { useState, useEffect } from 'react';
import { Card, Typography, Col, Row, Drawer } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import check from '../../../../assets/loans/check.png';
import checkBlack from '../../../../assets/loans/checkBlack.png';
import { getAnalytics, logEvent } from 'firebase/analytics';
import CompareAutomatic from './CompareManual';
import { isMobileOnly } from 'react-device-detect';
import numeral from 'numeral';

const { Text } = Typography;

const TipsManual = ({ loan }) => {
	const { t } = useTranslation();
	const [visible, setVisible] = useState(false);
	const dataProfile = useSelector((state) => state.data.dataProfile);
	const analytics = getAnalytics();
	const [rateType, setRateType] = useState(undefined);
	const [tip, setTip] = useState(undefined);
	const dataSummary = useSelector((state) => state.data.dataSummary);
	const dataSummaryFilter = dataSummary.filter((data) => data.subtype === 'subrogation');

	useEffect(() => {
		loan?.tips.map((tip, i) => {
			if (i === 0 && dataSummaryFilter.length === 0) {
				if (tip.type === 'BETTER_FIXED_RATE' && loan.rateType === 'fixed') {
					handleClickCompare('fixed', 'BETTER_FIXED_RATE');
				} else if (tip.type === 'CHANGE_TO_VARIABLE_RATE' && loan.rateType === 'fixed') {
					handleClickCompare('variable', 'CHANGE_TO_VARIABLE_RATE');
				} else if (tip.type === 'BETTER_VARIABLE_RATE' && loan.rateType === 'variable') {
					handleClickCompare('variable', 'BETTER_VARIABLE_RATE');
				} else if (
					(tip.type === 'CHANGE_TO_FIXED_RATE' || tip.type === 'CHANGE_TO_WORSE_FIXED_RATE') &&
					loan.rateType === 'variable'
				) {
					handleClickCompare('fixed', tip.type);
				} else if (tip.type === 'WORSE_VARIABLE_RATE') {
					return false;
				} else {
					return false;
				}
			}
		});
	}, []);

	const handleClickCompare = (rateType, tip) => {
		setRateType(rateType);
		setTip(tip);
		setVisible(true);
	};
	const onClose = () => {
		setVisible(false);
	};
	const colSpanNotification = {
		xxl: 12,
		xl: 12,
		lg: 12,
		md: 12,
		sm: 24,
		xs: 24
	};

	return (<>
        {loan &&
            loan.tips.map((tip, ) => {
                if (tip.category !== 'WORSE') {
                    if (tip.type === 'BETTER_FIXED_RATE' && loan.rateType === 'fixed') {
                        return (
                            <Col {...colSpanNotification} style={{ marginBottom: 24 }}>
                                <Card
                                    className="gb-card-loan-init-green"
                                    onClick={() => handleClickCompare('fixed', 'BETTER_FIXED_RATE')}
                                    onClickCapture={() =>
                                        logEvent(analytics, 'screen_view', {
                                            screen_name: 'loanTip.fixedToFixed'
                                        })
                                    }
                                    style={{
                                        height: '100%',
                                        display: 'grid',
                                        cursor: 'pointer'
                                    }}>
                                    <div className="card-tips-loan">
                                        <Row>
                                            <Col span={2}>
                                                <img src={check} alt="asdf" style={{ width: 24, height: 24 }}></img>
                                            </Col>
                                            <Col span={20} style={{ marginLeft: 12 }}>
                                                <Text
                                                    style={{
                                                        color: '#2F4858',
                                                        fontSize: 14,
                                                        fontWeight: 600
                                                    }}>
                                                    {t('m_loan.tipFixedToFixedTitle', {
                                                        name: dataProfile?.name,
                                                        savings: numeral(tip.savings).format('0,0.00 $')
                                                    })}
                                                </Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} style={{ marginTop: 12, textAlign: 'right' }}>
                                                <Text
                                                    style={{
                                                        color: '#3E9314',
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => handleClickCompare('fixed', 'BETTER_FIXED_RATE')}>
                                                    {t('typeMortgageB.moreInfo')}
                                                </Text>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        );
                    } else if (tip.type === 'CHANGE_TO_VARIABLE_RATE' && loan.rateType === 'fixed') {
                        return (
                            <Col {...colSpanNotification} style={{ marginBottom: 24 }}>
                                <Card
                                    className="gb-card-loan-init-green"
                                    onClick={() => handleClickCompare('variable', 'CHANGE_TO_VARIABLE_RATE')}
                                    onClickCapture={() =>
                                        logEvent(analytics, 'screen_view', {
                                            screen_name: 'loanTip.fixedToVariable'
                                        })
                                    }
                                    style={{
                                        height: '100%',
                                        display: 'grid',
                                        cursor: 'pointer'
                                    }}>
                                    <div className="card-tips-loan">
                                        <Row>
                                            <Col span={2}>
                                                <img src={check} alt="asdf" style={{ width: 24, height: 24 }}></img>
                                            </Col>
                                            <Col span={20} style={{ marginLeft: 12 }}>
                                                <Text
                                                    style={{
                                                        color: '#2F4858',
                                                        fontSize: 14,
                                                        fontWeight: 600
                                                    }}>
                                                    {t('m_loan.tipFixedToVariableTitle', {
                                                        name: dataProfile?.name,
                                                        savings: numeral(tip.savings).format('0,0.00')
                                                    })}
                                                </Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} style={{ marginTop: 12, textAlign: 'right' }}>
                                                <Text
                                                    style={{
                                                        color: '#3E9314',
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() =>
                                                        handleClickCompare('variable', 'CHANGE_TO_VARIABLE_RATE')
                                                    }>
                                                    {t('typeMortgageB.moreInfo')}
                                                </Text>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        );
                    } else if (tip.type === 'BETTER_VARIABLE_RATE' && loan.rateType === 'variable') {
                        return (
                            <Col {...colSpanNotification} style={{ marginBottom: 24 }}>
                                <Card
                                    className="gb-card-loan-init-green"
                                    onClick={() => handleClickCompare('variable', 'BETTER_VARIABLE_RATE')}
                                    onClickCapture={() =>
                                        logEvent(analytics, 'screen_view', {
                                            screen_name: 'loanTip.variableToVariable'
                                        })
                                    }
                                    style={{
                                        height: '100%',
                                        display: 'grid',
                                        cursor: 'pointer'
                                    }}>
                                    <div className="card-tips-loan">
                                        <Row gutter={24}>
                                            <Col span={2}>
                                                <img src={check} alt="asdf" style={{ width: 24, height: 24 }}></img>
                                            </Col>
                                            <Col span={20} style={{ marginLeft: 12 }}>
                                                <Text
                                                    style={{
                                                        color: '#2F4858',
                                                        fontSize: 14,
                                                        fontWeight: 600
                                                    }}>
                                                    {dataProfile.name +
                                                        ', ' +
                                                        t('m_loan.tipVariableToVariableManualDetailTitle', {
                                                            savings: numeral(tip.savings).format('0,0.00 $')
                                                        })}
                                                </Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} style={{ marginTop: 12, textAlign: 'right' }}>
                                                <Text
                                                    style={{
                                                        color: '#3E9314',
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => handleClickCompare('variable', 'BETTER_VARIABLE_RATE')}>
                                                    {t('typeMortgageB.moreInfo')}
                                                </Text>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        );
                    } else if (
                        (tip.type === 'CHANGE_TO_FIXED_RATE' || tip.type === 'CHANGE_TO_WORSE_FIXED_RATE') &&
                        loan.rateType === 'variable'
                    ) {
                        return (
                            <Col {...colSpanNotification} style={{ marginBottom: 24 }}>
                                <Card
                                    className="gb-card-loan-init-green"
                                    onClick={() => handleClickCompare('fixed', tip.type)}
                                    onClickCapture={() =>
                                        logEvent(analytics, 'screen_view', {
                                            screen_name:
                                                tip.type === 'CHANGE_TO_FIXED_RATE'
                                                    ? 'loanTip.variableToFixed'
                                                    : 'stabilityVariableToFixed'
                                        })
                                    }
                                    style={{
                                        height: '100%',
                                        display: 'grid',
                                        cursor: 'pointer'
                                    }}>
                                    <div className="card-tips-loan">
                                        <Row gutter={24}>
                                            <Col span={2}>
                                                <img src={check} alt="asdf" style={{ width: 24, height: 24 }}></img>
                                            </Col>
                                            <Col span={20} style={{ marginLeft: 12 }}>
                                                <Text
                                                    style={{
                                                        color: '#2F4858',
                                                        fontSize: 14,
                                                        fontWeight: 600
                                                    }}>
                                                    {t('m_loan.tipVariableToFixedManualDetailTitle', {
                                                        name: dataProfile?.name,
                                                        savings: numeral(tip.savings).format('0,0.00')
                                                    })}
                                                </Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} style={{ marginTop: 12, textAlign: 'right' }}>
                                                <Text
                                                    style={{
                                                        color: '#3E9314',
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        cursor: 'pointer'
                                                    }}>
                                                    {t('typeMortgageB.moreInfo')}
                                                </Text>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        );
                    } else if (
                        tip.type === 'WORSE_VARIABLE_RATE' /*||
        tip.type === "WORSE_FIXED_RATE"*/
                    ) {
                        return (
                            <Col {...colSpanNotification} style={{ marginBottom: 24 }}>
                                <Card
                                    className="gcardMovile"
                                    style={{
                                        border: '1px solid #C0DAEE',
                                        background: '#F1F7F8',
                                        borderRadius: 4,
                                        height: '100%',
                                        display: 'grid',
                                        cursor: 'pointer'
                                    }}>
                                    <Row>
                                        <Col span={1}>
                                            <img src={checkBlack} alt="asf" style={{ width: 24, height: 24 }} />
                                        </Col>
                                        <Col span={21} style={{ marginLeft: 12 }}>
                                            <Text
                                                style={{
                                                    color: '#2F4858',
                                                    fontSize: 14,
                                                    fontWeight: 600
                                                }}>
                                                {dataProfile && dataProfile.name + ', ' + t('loan.detailsLoanOwnersAdvi')}
                                            </Text>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: 8 }}>
                                        <Col span={1}></Col>
                                        <Col span={22} style={{ marginLeft: 12 }}>
                                            <Text
                                                style={{
                                                    color: '#2F4858',
                                                    fontSize: 14,
                                                    fontWeight: 400
                                                }}>
                                                {t('loan.detailsLoanOwnersAdvi1')}
                                            </Text>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        );
                    } else {
                        return false;
                    }
                }
            })}
        <Drawer
            title={
                <Text rootStyle={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>
                    {loan?.amount || loan?.finish ? (
                        <>
                            {t('loan.initLoanComparative')} {t(`loanType.${rateType}`)}
                        </>
                    ) : (
                        t('loan.dateRevisionDesktopEnterBack')
                    )}
                </Text>
            }
            placement="right"
            onClose={onClose}
            open={visible}
            rootClassName="gb-drawer-32px"
            width={isMobileOnly ? 380 : 484}>
            <CompareAutomatic
                loanId={loan.id}
                rateType={rateType}
                tip={tip}
                open={() => setVisible(false)}></CompareAutomatic>
        </Drawer>
    </>);
};

export default TipsManual;
