import React, { useEffect, useState } from "react";
import {
  Layout,
  Typography,
  Row,
  Col,
} from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import axios from "axios";
import env from "../../../../environment";
import chebronBlack from "../../../../assets/loans/chebronBlack.png";
import CompareAutomatic from "./CompareAutomatic";
import CompareManual from "./CompareManual"

const { Text } = Typography;


const LoanComparativePageNodataMobile = ({}) => {
  const { t } = useTranslation();
  let { loanId, rateType } = useParams();
  const navigate = useNavigate();;
  const [loan, setLoan] = useState(undefined);
  const userToken = useSelector((state) => state.auth.token);
  const location = useLocation();

  const tip = location.state.detail;
  const [, setDataTips] = useState(undefined);
  const [, setData] = useState(undefined);

  useEffect(() => {
    getLoan();
    getEuribor();
  }, []);

  
  const getLoan = () => {
    axios
      .get(`${env.api.url}/v1/loans/get-loan/${loanId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        // setDataValues(response.data.data);
        setLoan(response.data.data.loan);
        response.data.data.loan.tips.map((tipData) => {
          if (tipData.type === tip) {
            setDataTips(tipData);
          }
        });
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const getEuribor = () => {
    axios
      .get(`${env.api.url}/v1/rates/by-month/euribor`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setData(response.data.data.rates);
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const backToPage = () => {
    navigate(`/my-financial-profile/credits/all-detail-loan/${loanId}`);
  };


  if(loan) {
    return (
      <Layout style={{ padding: 16, marginBottom: 150 }}>
        <Row style={{ width: "100%" }}>
          <Col span={24}>
            <a onClick={() => backToPage()}>
              <img
                src={chebronBlack}
                atl="sdf"
                style={{ width: 24, height: 24 }}
              ></img>
            </a>
  
            {loan && loan.rateType !== rateType && (
              <Text
                style={{
                  color: "#2F4858",
                  fontSize: 16,
                  fontWeight: 600,
                  marginLeft: 12,
                }}
              >
                {t("loan.comparativeTitleFixed")}{" "}
                {rateType === "fixed"
                  ? t("loanType.fixed")
                  : t("loanType.variable")}
              </Text>
            )}
            {loan && loan.rateType === rateType && (
              <Text
                style={{
                  color: "#2F4858",
                  fontSize: 16,
                  fontWeight: 600,
                  marginLeft: 12,
                }}
              >
                {t("loan.initLoanComparative")}{" "}
                {rateType === "fixed"
                  ? t("loanType.fixed")
                  : t("loanType.variable")}
              </Text>
            )}
          </Col>
        </Row>
  
        {loan.creationType === "AUTOMATIC" && (
          <CompareAutomatic></CompareAutomatic>
        )}
        {loan.creationType === "MANUAL" && (
          <CompareManual></CompareManual>
        )}       
  
       
      </Layout>
    );
  }else {
    return false
  }

  
};

export default LoanComparativePageNodataMobile;
