import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Modal } from "antd";
import {
  HomeOutlined,
  CarOutlined,
  EuroCircleOutlined,
} from "@ant-design/icons";
import EditableTable from "./tableEditable";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import ModalAdd from "./modalAdd";
import {
  createAsset,
  getAsset,
  renameAsset,
  deleteAsset,
} from "../../../store/data/actions";

const { Text } = Typography;
const { confirm } = Modal;

function TableActive() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userToken = useSelector((state) => state.auth.token);
  const assetList = useSelector((state) => state.data.assetList);
  const [valueTotal, setValueTotal] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const [dataEdit, setDataEdit] = useState();

  const hideAddTaskModal = () => {
    setShowAddModal(false);
    setDataEdit(null);
  };

  const [typeActive, setTypeActive] = useState([
    {
      name: "balance.realeStateTitle",
      category: "realestate",
      icon: <HomeOutlined />,
      dataSource: [],
    },
    {
      name: "balance.furniture",
      category: "vehicles",
      icon: <CarOutlined />,
      dataSource: [],
    },
    {
      name: "balance.treasury",
      category: "cash",
      icon: <EuroCircleOutlined />,
      dataSource: [],
    },
    {
      name: "balance.investments",
      category: "other",
      dataSource: [],
    },
  ]);

  useEffect(() => {
    let total = 0;
    typeActive.forEach((type) => {
      type.dataSource.forEach((dataSource) => {
        total += parseInt(dataSource.value);
      });
    });
    setValueTotal(total);
  }, [typeActive]);

  useEffect(() => {
    dispatch(getAsset(userToken));
  }, []);

  useEffect(() => {
    if (assetList.length > 0) {
      let newTypeActive = [...typeActive].map((type) => {
        return {
          name: type.name,
          category: type.category,
          icon: type.icon,
          dataSource: [],
        };
      });
      assetList.forEach((item) => {
        const dataToAdd = {
          name: item.name,
          value: item.amount,
          id: item.id,
          porcentaje: item.payable,
        };
        if (item.type === "assets") {
          newTypeActive
            .find((data) => {
              return data.category === item.category;
            })
            .dataSource.push(dataToAdd);
        }
      });
      setTypeActive(newTypeActive);
    }
  }, [assetList]);

  const taskEditHandler = (indexType, id) => {
    let newDataSource = [...typeActive][indexType].dataSource.find(
      (item) => item.id === id
    );

    setDataEdit({ ...newDataSource });
  };

  const handleDelete = (indexType, id) => {
    dispatch(deleteAsset(userToken, id));
  };

  const title = {
    0: "balance.realeStateTitle",
    1: "balance.furniture",
    2: "balance.treasury",
    3: "balance.investments",
  };

  const showDeleteConfirm = (i, id) => {
    confirm({
      className: "ModalCommonConfirm",
      // okButtonProps: "button-primari-delete-gibobs",
      title: `Borrar activos de ${t(title[i])}`,
      icon: null,
      content: "¿Seguro que deseas borrar este elemento?",
      okText: "Borrar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        handleDelete(i, id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleAdd = (values) => {
    if (dataEdit) {
      dispatch(
        renameAsset(
          userToken,
          {
            name: values.name,
            amount: values.active,
            payable: values.porcentaje,
          },
          dataEdit.id
        )
      );
    } else {
      let objResponse = {
        name: values.name,
        amount: values.active,
        category: t(typeActive[showAddModal].category),
        type: "assets",
        payable: values.porcentaje,
      };
      dispatch(createAsset(userToken, objResponse));
    }

    setShowAddModal(false);
    setDataEdit(null);
  };

  return (
    <>
      <div style={{ marginTop: "40px" }}>
        <Text style={{ color: "#2F4858", fontSize: "14px", fontWeight: "500" }}>
          {t("balance.activeTitle")}
        </Text>
        <Text style={{ color: "#748EA0", fontSize: "14px" }}>
          {" "}
          {t("balance.explinationActive")}
        </Text>
      </div>

      <div
        style={{
          backgroundColor: "#02AFB8",
          borderRadius: "4px 4px 0px 0px",
          height: "44px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: "16px",
          marginTop: "16px",
        }}
      >
        <Text
          style={{
            width: "45%",
            fontSize: "12px",
            color: "#FFFFFF",
            marginRight: "16px",
            fontWeight: "600",
          }}
        >
          {t("balance.categoryActivePassive")}
        </Text>
        <Text
          style={{
            width: "30%",
            fontSize: "12px",
            color: "#FFFFFF",
            marginRight: "16px",
            fontWeight: "600",
          }}
        >
          {t("form.name")}
        </Text>
        <Text
          style={{
            width: "30%",
            fontSize: "12px",
            color: "#FFFFFF",
            marginRight: "16px",
            fontWeight: "600",
          }}
        >
          {t("balance.liquidityTitle")}
        </Text>
        <Text
          style={{
            width: "40%",
            fontSize: "12px",
            color: "#FFFFFF",
            marginRight: "16px",
            fontWeight: "600",
          }}
        >
          {t("balance.currentValue")}
        </Text>
      </div>
      {typeActive
        ? typeActive.map((data, i) => {
            return (
              <>
                <EditableTable
                  key={data.name}
                  name={t(data.name)}
                  icon={data.icon}
                  dataToSource={data.dataSource}
                  handleDelete={(id) => handleDelete(i, id)}
                  showAddModal={() => setShowAddModal(i)}
                  taskEditHandler={(id) => taskEditHandler(i, id)}
                  showDeleteConfirm={(id) => showDeleteConfirm(i, id)}
                  index={i}
                />
                {i !== 3 ? (
                  <div
                    style={{
                      marginTop: "-1px",
                      border: "1px solid #F1F7F8",
                    }}
                  />
                ) : null}
              </>
            );
          })
        : null}

      <div
        style={{
          backgroundColor: "#02AFB8",
          borderRadius: "0px 0px 4px 4px",
          height: "44px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px",
        }}
      >
        <Text
          style={{
            width: "156px",
            fontSize: "12px",
            color: "#FFFFFF",
            marginRight: "16px",
            fontWeight: "600",
          }}
        >
          {t("balance.totalTitle")}
        </Text>
        <Text
          style={{
            fontSize: "16px",
            color: "#FFFFFF",
            marginRight: "75px",
            fontWeight: "600",
          }}
        >
          {valueTotal && numeral(valueTotal).format("0,0 $")}
        </Text>
        <ModalAdd
          key={dataEdit}
          visible={showAddModal || dataEdit || showAddModal === 0}
          onCreate={(event) => handleAdd(event)}
          onCancel={hideAddTaskModal}
          width={800}
          dataEdit={dataEdit}
          showAddModal={showAddModal}
        />
      </div>
    </>
  );
}

export default TableActive;
