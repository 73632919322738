import React, { useState, useEffect } from 'react';
import './document.css';
import { useSelector } from 'react-redux';
import axios from 'axios';
import env from '../../../environment';
import { isMobile } from 'react-device-detect';
import Avatar from 'antd/lib/avatar/avatar';
import { MailOutlined, UserOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import ReviewsCustomer from '../ReviewsCustomer';

const { Text } = Typography;

const PersonalAnalyst = (analyst) => {
	const { t } = useTranslation();
	const userToken = useSelector((state) => state.auth.token);

	const rol = useSelector((state) => state.auth.roles);
	const [dataValues, setDataValues] = useState();

	let mortgageId = analyst.userMortgageId;

	if (mortgageId === undefined) {
		mortgageId =
			analyst.analyst.operationId !== undefined ? analyst.analyst.operationId : analyst.analyst.id;
	}

	useEffect(() => {
		getPhoto();
	}, []);

	const getPhoto = () => {
		axios
			.get(`${env.api.url}/v1/mortgages/agents/${mortgageId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setDataValues(response.data.data);
			});
	};

	return (
		<>
			{dataValues !== undefined && !isMobile ? (
				<div className="personalInfo" style={{ color: '#988d8d', textAlign: 'center' }}>
					{/* START B2C */}
					<Text style={{color: "#748EA0", fontSize: 12, fontWeight: 500}}>{t("document.personalAnalyst")}</Text>
					{rol !== 'realestate' && (
						<span className="analyst-name-style">
							{dataValues && dataValues.analyst && dataValues.analyst.name !== null
								? dataValues.analyst.name
								: 'No Name'}
						</span>
					)}
					{rol !== 'realestate' && (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								marginTop: '16px'
							}}>
							<Avatar
								size={88}
								icon={<UserOutlined />}
								src={
									dataValues && dataValues.analyst && dataValues.analyst.avatar !== null
										? dataValues.analyst.avatar.link
										: 'https://gibobs-assets.s3.eu-central-1.amazonaws.com/assets-dev/6008ddb40164df16002f94c6b1_608a71a603b0c458502cd74a93?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIS4QMZRS5JUUI7VA%2F20210719%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20210719T064357Z&X-Amz-Expires=300&X-Amz-Signature=7cca0a6445382b1fb12bf1176fb7a3b3e205a9b852e61288ec043e42e51f25ef&X-Amz-SignedHeaders=host' //""
								}
							/>
						</div>
					)}
					{rol !== 'realestate' && (
						<div
							style={{
								marginTop: 18,
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center'
							}}>
							<div className="analyst-phone-email-style">
								{dataValues && dataValues.analyst && dataValues.analyst.phone !== null
									? dataValues.analyst.phone
									: 'No Number'}
							</div>
							<a
								href={`https://api.whatsapp.com/send?phone=%2B34${dataValues?.analyst?.phone}`}
								target="_blank">
								<Button
									type="primary"
									icon={<WhatsAppOutlined style={{ fontSize: 14, display: "block" }} />}
									style={{ height: 24, width: 24, borderRadius: 4 }}></Button>
							</a>
						</div>
					)}
					{rol !== 'realestate' && (
						<div
							style={{
								marginTop: 8,
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center'
							}}>
							<div
								className="analyst-phone-email-style"
								style={{
									fontSize: 12,
									width: '80%',
									overflowWrap: 'anywhere',
									display: 'flex'
								}}>
								{dataValues && dataValues.analyst && dataValues.analyst.email !== null
									? dataValues.analyst.email.replace('hipotecaprimero', 'gibobs')
									: 'No email'}
							</div>
							<a href={`mailto:${dataValues?.analyst?.email}`} target="_blank">
								<Button
									type="primary"
									icon={<MailOutlined style={{ fontSize: 14, display: "block" }} />}
									style={{ height: 24, width: 24, borderRadius: 4 }}></Button>
							</a>
						</div>
					)}
					{/* END B2C */}
					{/* START B2B */}
					{rol === 'realestate' && (
						<div
							style={{
								marginLeft: '40px',
								marginBottom: '10px',
								marginTop: -15
							}}>
							<Avatar
								size={60}
								icon={<UserOutlined />}
								src={
									dataValues.agent.avatar !== null
										? dataValues.agent.avatar.link
										: 'https://gibobs-assets.s3.eu-central-1.amazonaws.com/assets-dev/6008ddb40164df16002f94c6b1_608a71a603b0c458502cd74a93?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIS4QMZRS5JUUI7VA%2F20210719%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20210719T064357Z&X-Amz-Expires=300&X-Amz-Signature=7cca0a6445382b1fb12bf1176fb7a3b3e205a9b852e61288ec043e42e51f25ef&X-Amz-SignedHeaders=host' //""
								}
							/>
						</div>
					)}
					{rol === 'realestate' && (
						<span
							style={{
								marginTop: '16px',
								fontSize: '12px',
								color: '#2F4858',
								lineHeight: '100%',
								textAlign: 'center',
								display: 'flex',
								justifyContent: 'center'
							}}>
							{dataValues.agent.name !== null ? dataValues.agent.name : 'No Name'}
						</span>
					)}
					{rol === 'realestate' && (
						<div
							style={{
								color: '#02C3CD',
								marginTop: '2px',
								marginLeft: '-20px',
								textAlign: 'center'
							}}>
							<span
								style={{
									marginLeft: '25px',
									fontSize: '16px',
									color: '#02C3CD',
									fontWeight: 'bold',
									lineHeight: '100%',
									border: 'none'
								}}>
								{dataValues.agent.phone !== null ? dataValues.agent.phone : 'No Number'}
							</span>
						</div>
					)}
					{rol === 'realestate' && (
						<div
							style={{
								color: '#748EA0',
								marginLeft: '-20px',
								textAlign: 'center'
							}}>
							<span
								style={{
									marginLeft: '25px',
									fontSize: '10px',
									color: '#748EA0',
									fontWeight: 'bold',
									lineHeight: '100%',
									border: 'none'
								}}>
								{dataValues.agent.email !== null
									? dataValues.agent.email.replace('hipotecaprimero', 'gibobs')
									: 'No email'}
							</span>
						</div>
					)}
					{/* END B2B */}
				</div>
			) : null}

			{dataValues !== undefined && isMobile ? (
				<div className="personalInfo" style={{ color: '#988d8d', marginTop: -60 }}>
					{rol != 'realestate' && (
						<div
							style={{
								marginLeft: '60px',
								marginBottom: '10px',
								marginTop: 70
							}}>
							<Avatar
								size={60}
								icon={<UserOutlined />}
								src={
									dataValues && dataValues.analyst && dataValues.analyst.avatar !== null
										? dataValues.analyst.avatar.link
										: 'https://gibobs-assets.s3.eu-central-1.amazonaws.com/assets-dev/6008ddb40164df16002f94c6b1_608a71a603b0c458502cd74a93?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIS4QMZRS5JUUI7VA%2F20210719%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20210719T064357Z&X-Amz-Expires=300&X-Amz-Signature=7cca0a6445382b1fb12bf1176fb7a3b3e205a9b852e61288ec043e42e51f25ef&X-Amz-SignedHeaders=host' //""
								}
							/>
						</div>
					)}
					{rol === 'realestate' && (
						<div
							style={{
								//marginLeft: "40px",
								//marginBottom: "10px",
								// marginTop: -15,
								position: 'relative',
								left: '50%',
								top: 75
							}}>
							<Avatar
								size={60}
								icon={<UserOutlined />}
								src={
									dataValues.agent.avatar !== null
										? dataValues.agent.avatar.link
										: 'https://gibobs-assets.s3.eu-central-1.amazonaws.com/assets-dev/6008ddb40164df16002f94c6b1_608a71a603b0c458502cd74a93?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIS4QMZRS5JUUI7VA%2F20210719%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20210719T064357Z&X-Amz-Expires=300&X-Amz-Signature=7cca0a6445382b1fb12bf1176fb7a3b3e205a9b852e61288ec043e42e51f25ef&X-Amz-SignedHeaders=host' //""
								}
							/>
						</div>
					)}
					{rol !== 'realestate' && (
						<span
							style={{
								marginTop: '20px',
								fontSize: '12px',
								color: '#2F4858',
								lineHeight: '100%',
								textAlign: 'center',
								display: 'flex',
								justifyContent: 'center'
							}}>
							{dataValues && dataValues.analyst && dataValues.analyst.name !== null
								? dataValues.analyst.name
								: 'No Name'}
						</span>
					)}
					{rol === 'realestate' && (
						<span
							style={{
								marginTop: '-40px',
								fontSize: '12px',
								color: '#2F4858',
								justifyContent: 'left',
								fontWeight: 500,
								color: '#2F4858'
							}}>
							{dataValues.agent.name !== null ? dataValues.agent.name : 'No Name'}
						</span>
					)}
					{rol !== 'realestate' && (
						<div
							style={{
								color: '#02C3CD',
								marginTop: '2px',
								marginLeft: '-20px',
								textAlign: 'center'
							}}>
							<span
								style={{
									marginLeft: '25px',
									fontSize: '16px',
									color: '#02C3CD',
									fontWeight: 'bold',
									lineHeight: '100%',
									border: 'none'
								}}>
								{dataValues && dataValues.analyst && dataValues.analyst.phone !== null
									? dataValues.analyst.phone
									: 'No Number'}
							</span>
						</div>
					)}
					{rol === 'realestate' && (
						<div
							style={{
								color: '#02C3CD',
								marginTop: '2px',
								textAlign: 'left'
							}}>
							<span
								style={{
									fontSize: '18px',
									color: '#02C3CD',
									fontWeight: 'bold',
									border: 'none'
								}}>
								{dataValues.agent.phone !== null ? dataValues.agent.phone : 'No Number'}
							</span>
						</div>
					)}
					{rol !== 'realestate' && (
						<div
							style={{
								color: '#748EA0',
								marginTop: '0px',
								marginLeft: '-20px',
								textAlign: 'center'
							}}>
							<span
								style={{
									marginLeft: '15px',
									fontSize: '10px',
									color: '#748EA0',
									fontWeight: 400,
									lineHeight: '100%',
									border: 'none'
								}}>
								{dataValues && dataValues.analyst && dataValues.analyst.email !== null
									? dataValues.analyst.email.replace('hipotecaprimero', 'gibobs')
									: 'No email'}
							</span>
						</div>
					)}
					{rol === 'realestate' && (
						<div
							style={{
								color: '#748EA0',
								textAlign: 'left'
							}}>
							<span
								style={{
									fontSize: '10px',
									color: '#748EA0',
									fontWeight: 400,
									color: '#748EA0'
								}}>
								{dataValues.agent.email !== null
									? dataValues.agent.email.replace('hipotecaprimero', 'gibobs')
									: 'No email'}
							</span>
						</div>
					)}
				</div>
			) : null}

			<div className="reviews-analyst-styles">
				<div className="customer-feedback" style={{ marginBottom: 16, color: '#2F4858' }}>
					{t('onboarding.opinionsCustomers')}
				</div>
				<ReviewsCustomer />
			</div>
		</>
	);
};

export default PersonalAnalyst;
