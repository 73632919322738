import React, { useState } from "react";
import { Form, Modal, Radio, Space } from "antd";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

const AdditionalGuarantee = ({ visible, onCancel, onCreate }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(1);
  const [form] = Form.useForm();

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    (<Modal
      centered
      className="ModalCommon ModalCommonSmallDelete modal-additional-guarantee"
      cancelButtonProps={{
        className: "button-secundary-gibobs",
        style: { display: "none" },
      }}
      okButtonProps={{
        className: "button-primari-gibobs",
        style: { width: "100%", margin: '0 auto' },
      }}
      open={visible}
      title={t("onboarding.additionalWarranty")}
      okText={t("viability.continue")}
      cancelText={t("mydocuments.deleteCancel")}
      width={480}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then(() => {
            onCreate(value);
            form.resetFields();
          })
          
      }}
    >
      <Form
        className={
          isMobile
            ? "gb-form-profile-headlines-mobile"
            : "gb-form-common-new-style"
        }
        scrollToFirstError={true}
        name="nest-messages"
        layout="vertical"
      >
        <Form.Item
          name="canHavePaidOwnedHousesOrCanHaveGuarantors"
          label={t("onboarding.haveAnyGuarantees")}
        >
          <Radio.Group onChange={onChange} value={value}>
            <Space direction="vertical">
              <Radio value={true}>
                {t("onboarding.havePropertyGuarantee")}
              </Radio>
              <Radio value={false}>{t("onboarding.dontHaveEither")}</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>)
  );
};

export default AdditionalGuarantee;
