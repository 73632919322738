import React, { useState } from 'react';
import { Button, Typography, Card, Form, Layout, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import env from '../../environment';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { notificationGibobs } from './reusableItems';
import FileOutlinedIcon from '../../assets/icons/FileOutlinedIcon.svg';
import VerticalAlignBottomOutlinedIcon from '../../assets/icons/VerticalAlignBottomOutlinedIcon.svg';
import {  InputNumber } from 'afrodita';

const { Text } = Typography;

function NewResquestWidget({ }) {
	const { t } = useTranslation();
	const [getFilePress, setGetFilePress] = useState(false);
	const [form] = Form.useForm();
	const [quoteRent, setQuoteRent] = useState(0);
	const [quoteBuy, setQuoteBuy] = useState(0);
	const [maxMortgage, setMaxMortgage] = useState(0);
	const userToken = useSelector((state) => state.auth.token);
	const [valuesData, setValuesData] = useState();

	const pushGetFile = (values) => {
		values.expenses = numeral(values.expenses).value();
		values.income = numeral(values.income).value();
		setGetFilePress(true);
		calculateValues(values);
		setValuesData(values);
	};

	const openNotificationWithIcon = () => {
		notificationGibobs('error', t('m_full.errorTitle'), t('form.incompleteInformation'));
	};

	const monthlyQuote = (rate, periods, present, future = 0, type = 0) => {
		// Credits: algorithm inspired by Apache OpenOffice

		const parseNumber = function (string) {
			if (string === undefined || string === '') {
				// return error.value;
			}
			if (!isNaN(string)) {
				return parseFloat(string);
			}
			// return error.value;
		};

		future = future || 0;
		type = type || 0;

		rate = parseNumber(rate);
		periods = parseNumber(periods);
		present = parseNumber(present);
		future = parseNumber(future);
		type = parseNumber(type);

		// Return payment
		var result;
		if (rate === 0) {
			result = (present + future) / periods;
		} else {
			var term = Math.pow(1 + rate, periods);
			if (type === 1) {
				result = ((future * rate) / (term - 1) + (present * rate) / (1 - 1 / term)) / (1 + rate);
			} else {
				result = (future * rate) / (term - 1) + (present * rate) / (1 - 1 / term);
			}
		}
		return -result;
	};

	const cumulativePrincipal = (rate, periods, value, start, end, type) => {
		// Credits: algorithm inspired by Apache OpenOffice
		// Credits: Hannes Stiebitzhofer for the translations of function and variable names

		const parseNumber = function (string) {
			if (string === undefined || string === '') {
				//   return error.value;
			}
			if (!isNaN(string)) {
				return parseFloat(string);
			}

			// return error.value;
		};

		const fv = function (rate, periods, payment, value, type) {
			// Credits: algorithm inspired by Apache OpenOffice

			value = value || 0;
			type = type || 0;

			rate = parseNumber(rate);
			periods = parseNumber(periods);
			payment = parseNumber(payment);
			value = parseNumber(value);
			type = parseNumber(type);

			// Return future value
			var result;
			if (rate === 0) {
				result = value + payment * periods;
			} else {
				var term = Math.pow(1 + rate, periods);
				if (type === 1) {
					result = value * term + (payment * (1 + rate) * (term - 1)) / rate;
				} else {
					result = value * term + (payment * (term - 1)) / rate;
				}
			}
			return -result;
		};

		rate = parseNumber(rate);
		periods = parseNumber(periods);
		value = parseNumber(value);

		// Return error if either rate, periods, or value are lower than or equal to zero
		if (rate <= 0 || periods <= 0 || value <= 0) {
			// return error.num;
		}

		// Return error if start < 1, end < 1, or start > end
		if (start < 1 || end < 1 || start > end) {
			// return error.num;
		}

		// Return error if type is neither 0 nor 1
		if (type !== 0 && type !== 1) {
			// return error.num;
		}

		// Compute cumulative principal
		var payment = monthlyQuote(rate, periods, value, 0, type);
		var principal = 0;
		if (start === 1) {
			if (type === 0) {
				principal = payment + value * rate;
			} else {
				principal = payment;
			}
			start++;
		}
		for (var i = start; i <= end; i++) {
			if (type > 0) {
				principal += payment - (fv(rate, i - 2, payment, value, 1) - payment) * rate;
			} else {
				principal += payment - fv(rate, i - 1, payment, value, 0) * rate;
			}
		}

		// Return cumulative principal
		return principal;
	};

	const calculateValues = (values) => {
		const incomes = values.income;
		const expenses = values.expenses;

		const quoteRent = Math.round(incomes * 0.4 - expenses);
		const quoteBuy = Math.round(incomes * 0.35 - expenses);

		setQuoteRent(quoteRent);
		setQuoteBuy(quoteBuy);

		if (quoteRent < 0) {
			quoteRent = 0;
		}

		if (quoteBuy < 0) {
			quoteBuy = 0;
		}

		const percent = 2;
		const months = 360;
		//const scoringI = percent / 12 / 100;

		/*
    let calculationMaxBudget = Math.round(
      (quoteBuy * ((1 + scoringI) ^ (months - 1))) /
        ((1 + scoringI) ^ (months * scoringI))
    )

    const a = finance.FV(scoringI, quoteBuy, months) * months;
      */

		const line0 = quoteBuy;
		//const line1 = -cumulativePrincipal(((1 + (percent / 100))^(0.0833333333333333)) - 1, months, 3, 1, 1, 0) % 3;
		const line2 = Math.pow(1 + percent / 100, 0.0833333333333333) - 1;
		const line1 = -cumulativePrincipal(line2, months, 3, 1, 1, 0) / 3.0;

		//const line2 = (((1 + (percent / 100))^(0.0833333333333333)) - 1)

		//const a = quoteBuy % (-cumulativePrincipal((1 + scoringI)^(0.0833333333333333) - 1, months, 3, 1, 1, 0) % 3 + ((1 + scoringI)^(0.0833333333333333) - 1));

		const budget = line0 / (line1 + line2);

		setMaxMortgage(budget);


		
	};

	const pageCalculatePrecertificate = (
		<Form
			className={'gb-form-profile-headlines-mobile'}
			onFinishFailed={openNotificationWithIcon}
			scrollToFirstError={true}
			form={form}
			layout="vertical"
			onFinish={pushGetFile}
			name="solarValue"
			style={{ marginTop: 10 }}>
			<Row gutter={24}>
				<Col xs={24} lg={7}>
					<Form.Item
						name="income"
						label={t('newRequest.income')}
						rules={[
							{
								required: true
								// type: "number",
							}
						]}
						style={{
							marginTop: isMobile ? 0 : '30px',
							marginRight: isMobile ? 0 : '40px'
						}}>
						<InputNumber
							style={{
								// borderColor: "#c0daee",
								width: '100%',
								borderRadius: '4px'
							}}
							placeholder={'€/mes'}
						/>

						{/* <InputNumber
              step={100}
              style={{
                width: isMobile ? "100%" : "280px",
              }}
              formatter={(value) =>
                numeral(value)
                  .format("0 $")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              }
              defaultValue={""}
              placeholder={"€/mes"}
            /> */}
					</Form.Item>
				</Col>
				<Col xs={24} lg={7}>
					<Form.Item
						name="expenses"
						label={t('newRequest.expenses')}
						rules={[
							{
								required: true
								// type: "number",
							}
						]}
						style={{ marginTop: isMobile ? 0 : '30px' }}>
						<InputNumber
							style={{
								// borderColor: "#c0daee",
								width: '100%',
								borderRadius: '4px'
							}}
							placeholder={'€/mes'}
						/>
						{/* <InputNumber
              step={100}
              style={{
                width: isMobile ? "100%" : "280px",
              }}
              formatter={(value) =>
                numeral(value)
                  .format("0 $")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              }
              defaultValue={""}
              placeholder={"€/mes"}
            /> */}
					</Form.Item>
				</Col>
				{!isMobile && (
					<Col xs={24} lg={24} style={{ textAlign: 'center' }}>
						<Button
							className="button-primary-gibobs-inmo"
							type="primary"
							style={{
								width: isMobile ? '100%' : 96,
								marginTop: isMobile ? '8px' : '60px',
								height: 40
							}}
							htmlType="submit">
							{t('budget.pushBudget')}
						</Button>
					</Col>
				)}
			</Row>
			{isMobile && (
				<Card
					className="cardButtonStyle"
					style={{ left: 0, marginBottom: -2, backgroundColor: '#FAFEFE' }}>
					<Button
						id="gb-mortgage-buy-situation-mobile"
						className="button-primary-gibobs-inmo"
						type="primary"
						style={{
							width: '100%',
							height: 48,
							fontSize: 16
						}}
						htmlType="submit">
						{t('budget.pushBudget')}
					</Button>
				</Card>
			)}
		</Form>
	);

	const buttonGetCalculate = (
		<Button
			href={`${env.api.url}/v1/banks/generate-precertified?token=${userToken}&incomes=${
				valuesData !== undefined ? valuesData.income : 0
			}&expenses=${
				valuesData !== undefined ? valuesData.expenses : 0
			}&maxMortgage=${maxMortgage}&quoteBuy=${quoteBuy}&quoteRent=${quoteRent}`}
			target={'_blank'}
			style={{
				borderColor: '#C0DAEE',
				height: 44,
				border: isMobile && 'none',
				width: isMobile ? '100%' : 301,
				padding: isMobile && 0
			}}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					marginTop: 7,
					justifyContent: 'space-between'
				}}>
				<div style={{ display: 'flex' }}>
					<div style={{ marginRight: 10 }}>
						<img
							src={FileOutlinedIcon}
							alt="FileOutlinedIcon"
							height={20}
							style={{ marginBottom: 2 }}
						/>
					</div>
					<div style={{ alignSelf: 'center', color: '#2F4858', fontSize: 16 }}>
						{isMobile ? t('newRequest.title') : t('menuComponent.precert')}
					</div>
				</div>
				<div style={{ marginLeft: 10 }}>
					<img
						src={VerticalAlignBottomOutlinedIcon}
						alt="VerticalAlignBottomOutlinedIcon"
						height={20}
						style={{ marginBottom: 2 }}
					/>
				</div>
			</div>
		</Button>
	);

	const getPrecertificate = (
		<>
			<Row gutter={24} style={{ marginTop: isMobile ? 16 : 40 }}>
				<Col
					lg={5}
					xs={24}
					style={{
						marginTop: isMobile && 16
					}}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '100%',
							alignItems: isMobile ? 'left' : 'center'
						}}>
						<Text
							style={{
								fontSize: '12px',
								color: '#748EA0',
								alignSelf: isMobile ? 'center' : 'left'
							}}>
							{t('newRequest.mortsgage')}
						</Text>
						<Text
							strong
							style={{
								fontSize: '16px',
								color: '#02C3CD',
								textAlign: 'center'
							}}>
							{numeral(maxMortgage).format('0,0 $')}
						</Text>
					</div>
				</Col>
				<Col
					lg={5}
					xs={24}
					style={{
						marginTop: isMobile && 16
					}}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '100%',
							alignItems: 'center'
						}}>
						<Text
							style={{
								fontSize: '12px',
								color: '#748EA0',
								alignSelf: isMobile ? 'center' : 'left'
							}}>
							{t('newRequest.maxHome')}
						</Text>
						<Text
							strong
							style={{
								fontSize: '16px',
								textAlign: 'center'
							}}>
							{numeral(quoteBuy).format('0,0 $') + '/mes'}
						</Text>
					</div>
				</Col>
				<Col
					lg={6}
					xs={24}
					style={{
						marginTop: isMobile && 16
					}}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '100%',
							alignItems: 'center'
						}}>
						<Text
							style={{
								fontSize: '12px',
								color: '#748EA0',
								alignSelf: isMobile ? 'center' : 'left'
							}}>
							{t('newRequest.maxHome2')}
						</Text>

						<Text
							strong
							style={{
								fontSize: '16px',
								textAlign: 'center'
							}}>
							{numeral(quoteRent).format('0,0 $') + '/mes'}
						</Text>
					</div>
				</Col>
			</Row>
			{!isMobile && (
				<Row gutter={24} style={{ marginTop: 40 }}>
					<Col xs={24} lg={5}>
						<div
							style={{
								marginBottom: 12,
								fontSize: 12,
								fontWeight: 400,
								color: '#2F4858'
							}}>
							{t('form.generatedDocuments').toUpperCase()}
						</div>
						{buttonGetCalculate}
					</Col>
					<Col xs={24} lg={24} style={{ textAlign: 'center' }}>
						<Button
							className="button-primary-gibobs-inmo"
							type="primary"
							style={{
								width: isMobile ? '100%' : 160,
								marginTop: isMobile ? '8px' : '60px',
								height: 40
							}}
							onClick={() => setGetFilePress(false)}>
							{t('budget.return')}
						</Button>
					</Col>
				</Row>
			)}
		</>
	);

	return (
		<Layout style={{ marginBottom: '200px' }}>
			{!getFilePress && (
				<div style={{ marginTop: isMobile ? 16 : 40 }}>
					{!isMobile && (
						<Card
							className="CardStyleRealeState"
							style={{
								width: isMobile ? 'calc(100% - 32px)' : 'calc(100% - 80px)',
								marginBottom: '24px',
								marginLeft: isMobile ? '16px' : '40px'
							}}>
							<div className="title-cards">{t('newRequest.title')}</div>
							<div className="description-cards">{t('newRequest.info')}</div>
							{pageCalculatePrecertificate}
						</Card>
					)}
					{isMobile && (
						<>
							<Card
								className="CardStyleRealeState"
								style={{
									width: isMobile ? 'calc(100% - 32px)' : 'calc(100% - 80px)',
									marginLeft: isMobile ? '16px' : '40px'
								}}>
								<div className="title-cards">{t('newRequest.title')}</div>
								<div className="description-cards">{t('newRequest.info')}</div>
							</Card>
							<div
								className="gb-form-inmo-scroll"
								style={{
									width: isMobile ? 'calc(100% - 32px)' : 'calc(100% - 80px)',
									marginBottom: '24px',
									marginLeft: isMobile ? '16px' : '40px'
								}}>
								{pageCalculatePrecertificate}
							</div>
						</>
					)}
				</div>
			)}

			{getFilePress && (
				<div style={{ marginTop: isMobile ? 16 : 40 }}>
					{!isMobile && (
						<Card
							className="CardStyleRealeState"
							style={{
								width: isMobile ? 'calc(100% - 32px)' : 'calc(100% - 80px)',
								marginBottom: '24px',
								marginLeft: isMobile ? '16px' : '40px'
							}}>
							<div className="title-cards">{t('newRequest.title')}</div>
							<div className="description-cards">{t('newRequest.info')}</div>
							{getPrecertificate}
						</Card>
					)}
					{isMobile && (
						<>
							<Card
								className="CardStyleRealeState"
								style={{
									width: isMobile ? 'calc(100% - 32px)' : 'calc(100% - 80px)',
									marginBottom: '24px',
									marginLeft: isMobile ? '16px' : '40px'
								}}>
								<div className="title-cards">{t('newRequest.title')}</div>
								<div className="description-cards">{t('newRequest.info')}</div>
								{getPrecertificate}
							</Card>

							<div
								style={{
									marginBottom: 12,
									fontSize: 12,
									fontWeight: 600,
									color: '#2F4858',
									marginLeft: 16
								}}>
								{t('form.generatedDocuments').toUpperCase()}
							</div>
							<div
								style={{
									backgroundColor: '#FFFFFF',
									padding: '12px 16px'
								}}>
								{buttonGetCalculate}
							</div>

							{isMobile && (
								<Card
									className="cardButtonStyle"
									style={{
										left: 0,
										backgroundColor: '#FAFEFE',
										padding: 0
									}}>
									<Button
										id="gb-mortgage-buy-situation-mobile"
										className="button-primary-gibobs-inmo"
										type="primary"
										style={{
											width: '100%',
											height: 48,
											fontSize: 16
										}}
										onClick={() => setGetFilePress(false)}>
										{t('budget.return')}
									</Button>
								</Card>
							)}
						</>
					)}
				</div>
			)}
		</Layout>
	);
}

export default NewResquestWidget;
