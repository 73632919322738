import React from "react";
import { Layout, Row, Button } from "antd";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

const LayoutProductsPromoter = ({ }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const styleTextTab = {
    fontSize: 14,
    fontWeight: 600,
    color: "white",
    backgroundColor: "#02C3CD",
    borderRadius: "4px",
    border: "none",
    height: 30,
    textAlign: "center",
  };

  return (
    <div
      style={{
        // margin: "40px 40px 24px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Layout>
        <Row gutter={[24, 24]} style={{ marginLeft: 0, marginRight: 0 }}>
          <div
            className="newSubmenusTabs"
            style={{ height: 48, padding: 8, margin: 0 }}
          >
            <div
              style={{
                background: "#EFFCFC",
                borderRadius: 5,
                borderBottom: "none",
                borderBottomColor: "white",
              }}
              selectedKeys={[`${location.pathname}${location.search}`]}
              mode="horizontal"
              className="menu-tools"
            >
              <NavLink className="buttonOperations" to="/products-business">
                <Button
                  className="buttonOperations"
                  style={
                    location.pathname === "/products-business"
                      ? styleTextTab
                      : undefined
                  }
                >
                  {t("createOperations.NewProducts")}
                </Button>
              </NavLink>

              <NavLink to="/promoter-loan/products-under-contract">
                <Button
                  className="buttonOperations"
                  style={
                    location.pathname ===
                    "/promoter-loan/products-under-contract"
                      ? styleTextTab
                      : undefined
                  }
                >
                  {t("promoterLoan.myProjects")}
                </Button>
              </NavLink>
            </div>
          </div>
        </Row>
      </Layout>
    </div>
  );
};

export default LayoutProductsPromoter;
