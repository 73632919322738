import React, { useState, useEffect } from "react";
import { Typography, Card, Form, Col, Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import env from "../../environment";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import Ecostate from "./Ecostate";
import { notificationGibobs } from "./reusableItems";

const { Text } = Typography;

function EcostateBudget({ ecoBudget }) {
  const navigate = useNavigate();;
  const location = useLocation();
  const ecoBudgetPrice = ecoBudget ? location.state.price : null;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const userToken = useSelector((state) => state.auth.token);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [, setProfile] = useState(undefined);

  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
    getProfile();
  }, []);

  const handleCancel = () => {
    setIsModalVisible(false);
    navigate("/sales-tools/mortgages");
  };

  const handleOk = () => {
    setIsModalVisible(false);

    navigate("/sales-tools/follow-up");
  };

  const getProfile = () => {
    axios
      .get(`${env.api.url}/v1/auth/get-profile`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setProfile(response.data.data);
      });
  };

  const onFinish = (values) => {
    values.ecoBudget = ecoBudget ? ecoBudgetPrice : null;
    values.ecoProjects =
      values.ecoHouseType === "piso" ? ["enclosure"] : ["solar"];
    values.housingDelivery = moment(values.housingDelivery).format();

    if (values.email === undefined) {
      const { ...rest } = values;
      axios
        .post(
          `${env.api.url}/v1/eco/eco-mail-particular`,
          {
            ...rest,
          },
          {
            headers: { Authorization: `Bearer ${userToken}` },
          }
        )
        .then((response) => {
          if (response.data.data.sent) {
            // Modal.success({
            //   style: { marginTop: 40 },
            //   className: "ModalCommonConfirmPaid",
            //   title:
            //     t("form.requestTitlesInmo").charAt(0).toUpperCase() +
            //     t("form.requestTitlesInmo").slice(1),
            //   cancelButtonProps: { className: "button-secundary-gibobs-inmo" },
            //   okButtonProps: { className: "button-primary-gibobs-inmo" },
            //   content: (
            //     <div>
            //       <p>{t("form.requestDesInmo")}</p>
            //     </div>
            //   ),
            //   onOk: () => {
            //     navigate("/sales-tools/follow-up", [false, "svh"]);
            //   },
            // });
            notificationGibobs(
              "success",
              t("m_common.requestSuccessTitle"),
              t("form.formSuccessful")
            );
            navigate("/sales-tools/follow-up", [false, "svh"]);
          }
        });
    } else {
      axios
        .post(
          `${env.api.url}/v1/clients/is-recent-client`,
          {
            email: values.email,
          },
          {
            headers: { Authorization: `Bearer ${userToken}` },
          }
        )
        .then((responde) => {
          if (responde.data.data) {
            setIsModalVisible(true);
            Modal.success({
              style: { marginTop: 40 },
              cancelButtonProps: { className: "button-secundary-gibobs-inmo" },
              okButtonProps: { className: "button-primary-gibobs-inmo" },
              className: "ModalCommonConfirmPaid",
              title: t("form.applyFor"),
              content: (
                <div>
                  <p>{t("form.text1")}</p>
                  <p>{t("form.text2")}</p>
                  <p>{t("form.text3")}</p>
                </div>
              ),
              onOk: () => {
                navigate("/sales-tools/follow-up", [false, "svh"]);
              },
            });
          }
        });
    }
  };

  const validateMessages = {
    required: "${label}" + " " + t("form.formRequired"),
    types: {
      email: t("form.emailValidated"),
      number: "${label} is not a valid number!",
    },
    number: {
      range: t("form.maxBudgetNewRequestValidated") + "${min}",
    },
  };

  const openNotificationWithIcon = () => {
    notificationGibobs(
      "error",
      t("m_full.errorTitle"),
      t("form.incompleteInformation")
    );
  };

  const createEcoBudget = (
    <Form
      className={
        isMobile
          ? "gb-form-profile-headlines-mobile"
          : "gb-form-common-new-style"
      }
      onFinishFailed={openNotificationWithIcon}
      scrollToFirstError={true}
      form={form}
      name="nest-messages"
      onFinish={onFinish}
      layout="vertical"
      initialValues={{ ecoBudget: ecoBudgetPrice ? ecoBudgetPrice : null }}
      validateMessages={validateMessages}
    >
      <Ecostate budget={true}></Ecostate>

      {!isMobile && (
        <Col xs={24} lg={24} style={{ textAlign: "center" }}>
          <Button
            className="button-primary-gibobs-inmo"
            type="primary"
            style={{
              width: isMobile ? "100%" : 96,
              marginTop: isMobile ? "8px" : "60px",
              height: 40,
            }}
            htmlType="submit"
            disabled={
              form.getFieldValue().length > 12 ||
              !!form.getFieldsError().filter(({ errors }) => errors.length)
                .length
            }
          >
            {t("form.send")}
          </Button>
        </Col>
      )}
      {isMobile && (
        <Card
          className="cardButtonStyle"
          style={{ left: 0, marginBottom: -2, backgroundColor: "#FAFEFE" }}
        >
          <Button
            id="gb-mortgage-buy-situation-mobile"
            className="button-primary-gibobs-inmo"
            type="primary"
            style={{
              fontSize: 16,
              width: "100%",
              height: 48,
              fontWeight: 600,
            }}
            htmlType="submit"
            disabled={
              form.getFieldValue().length > 12 ||
              !!form.getFieldsError().filter(({ errors }) => errors.length)
                .length
            }
          >
            {t("form.send")}
          </Button>
        </Card>
      )}
    </Form>
  );

  return (
    (<div style={{ marginTop: isMobile ? 16 : 40 }}>
      {isModalVisible && (
        <Modal
          className={isMobile ? "modal-mobile-basic" : "modal-basic"}
          cancelButtonProps={{ className: "button-secundary-gibobs-inmo" }}
          okButtonProps={{ className: "button-primary-gibobs-inmo" }}
          open={isModalVisible}
          title={t("form.existCliente")}
          okText={t("form.yes")}
          cancelText={t("form.no")}
          width={674}
          onCancel={handleCancel}
          onOk={handleOk}
        >
          <Text style={{ fontSize: "12px", height: "102px", width: "600px" }}>
            {t("form.exist")}
          </Text>
        </Modal>
      )}
      {!isMobile && (
        <Card
          className="CardStyleRealeState"
          style={{
            marginLeft: isMobile ? "16px" : "40px",
            width: isMobile ? "calc(100% - 32px)" : "calc(100% - 80px)",
          }}
        >
          <div className="title-cards">{t("budget.budgetTitle")}</div>
          <div className="description-cards">{t("budget.budgetDesc")}</div>
          {createEcoBudget}
        </Card>
      )}
      {isMobile && (
        <>
          <Card
            className="CardStyleRealeState"
            style={{
              marginLeft: isMobile ? "16px" : "40px",
              width: isMobile ? "calc(100% - 32px)" : "calc(100% - 80px)",
            }}
          >
            <div className="title-cards">{t("budget.budgetTitle")}</div>
            <div className="description-cards">{t("budget.budgetDesc")}</div>
          </Card>
          <div
            className="gb-form-inmo-scroll"
            style={{
              width: isMobile ? "calc(100% - 32px)" : "calc(100% - 80px)",
              marginBottom: "24px",
              marginLeft: isMobile ? "16px" : "40px",
              height: "calc(100vh - 350px)",
            }}
          >
            {createEcoBudget}
          </div>
        </>
      )}
    </div>)
  );
}

export default EcostateBudget;
