import React from "react";
import { Form, Modal, Input, Col, Row, Select, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import { isMobile } from "react-device-detect";

const { Option } = Select;

function ModalAddPassive({
  visible,
  onCancel,
  onCreate,
  dataEdit,
  showAddModal,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const title = {
    0: "balance.realeStateTitle",
    1: "balance.furniture",
    2: "form.others",
  };
  return (
    (<Modal
      className="ModalCommon"
      cancelButtonProps={{ className: "button-secundary-gibobs" }}
      okButtonProps={{ className: "button-primari-gibobs" }}
      open={visible}
      title={`Añadir pasivo ${t(title[showAddModal])}`}
      okText={t("form.send")}
      cancelText={t("mydocuments.deleteCancel")}
      width={672}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
            form.resetFields();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="Contacto"
        initialValues={dataEdit ? dataEdit : null}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="name"
              label={t("form.name")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                placeholder={t("form.name")}
                style={{
                  width: "280px",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="term"
              label={t("loan.term")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                style={{
                  width: isMobile ? "100%" : "280px",
                }}
              >
                <Option value="large">{t("common.large")}</Option>
                <Option value="medium">{t("common.medium")}</Option>
                <Option value="short">{t("common.short")}</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="interest"
              label="Interes"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                placeholder="2 %"
                style={{
                  borderColor: "#c0daee",
                  width: isMobile ? "100%" : "280px",
                  borderRadius: "4px",
                }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="pending"
              label={t("form.pendingCapital")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                step={100}
                placeholder="1.000€"
                style={{
                  borderColor: "#c0daee",
                  width: isMobile ? "100%" : "280px",
                  borderRadius: "4px",
                }}
                formatter={(value) => numeral(value).format("0,0 $")}
                parser={(value) => numeral(value).value()}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>)
  );
}

export default ModalAddPassive;
