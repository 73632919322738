import React, { useState, useEffect } from 'react';
import '../Widgets.css';
import { Card, Typography, Col, Row, Modal, Button } from 'antd';
import Layout from 'antd/lib/layout/layout';
import { types as componentsType } from '../typeConstant';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isMobile, isMobileOnly } from 'react-device-detect';
import WidgetInmo from './widgetInmo';
import MortgageDesktop from '../../../assets/Products/MortgageCoverDesktop.png';
import Mortgage from '../../../assets/Products/MortgageCoverInmo.png';
import ImageP from '../../../assets/promoter/ImageP.png';
import gibobsLogo from '../../../assets/icons/gibobsIconInmo.png';
import AddButton from '../../../assets/Products/AddButton.svg';
import { FileOutlined } from '@ant-design/icons';
import _ from 'lodash';
import axios from 'axios';
import env from '../../../environment';
import { changeRedirect, createNewProject } from '../../../store/promoter/actions';
import calculatortmp from '../../../assets/calculators/calculatortmp.png';
import calculatortmp2 from '../../../assets/calculators/calculatortmp2.png';
import calculatortmpMobile from '../../../assets/calculators/calculatortmpMobile.png';
import calculatortmp2Mobile from '../../../assets/calculators/calculatortmp2Mobile.png';
import promoterImagemobile from '../../../assets/promoter/promoterImagemobile.png';
import ModalInfoFunding from '../../../Pages/Promoter-loan/CommonComponents/ModalInfoFundings';

const { Text } = Typography;

const DragAndDrop = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();;
	const dispatch = useDispatch();
	const [dataWidgets, setDataWidgets] = useState([]);
	const [visible, setVisible] = useState(false);
	const [hasViewedWelcomeModel, setHasViewedWelcomeModel] = useState(undefined);
	const operationsClient = useSelector((state) => state.realestate.operations);
	const dataProfile = useSelector((state) => state.data.dataProfile);
	const userToken = useSelector((state) => state.auth.token);
	const dataNewProject = useSelector((state) => state.promoter.dataNewProject);
	const dataRedirect = useSelector((state) => state.promoter.redirect);
	const [modalPromoter, setModalPromoter] = useState(false)

	useEffect(() => {
		if (dataRedirect === true) {
			navigate(`/promoter-loan/step-form/${dataNewProject.id}`);
			dispatch(changeRedirect(!dataRedirect));
		}
	}, [dataRedirect]);

	const searchStages = (name, stage, object) => {
		return object.filter((data) => data[stage] === name).length;
	};

	useEffect(async () => {
		if (hasViewedWelcomeModel === false) {
			setVisible(true);
		}
	}, [hasViewedWelcomeModel]);

	useEffect(async () => {
		if (dataProfile?.metadata?.dashboard_b2b) {
			setHasViewedWelcomeModel(_.get(dataProfile.metadata.dashboard_b2b, 'b2b_welcome_modal'));
		}
		if (!dataProfile?.metadata?.dashboard_b2b) {
			setHasViewedWelcomeModel(false);
		}
	}, [dataProfile]);

	const stagesFinish = operationsClient.filter((data) => data.status === 'finish');
	const pendientConfirm = operationsClient.filter((data) => data.accountKAM);

	const progressRequest = operationsClient.filter(
		(data) => data.status !== 'finish' && !data.accountKAM
	);

	const modalDashboardOk = () => {
		axios
			.post(
				`${env.api.url}/v1/user/update-metadata`,
				{
					key: 'dashboard_b2b',
					subkey: 'b2b_welcome_modal',
					value: true
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			
	};

	const handleModalCancel = () => {
		setVisible(false);
		modalDashboardOk();
	};

	const handleModalOk = () => {
		setVisible(false);
		modalDashboardOk();
	};

	

	const ownBankSign = searchStages(
		'Continúa con otro banco/prescriptor',
		'closeReason',
		stagesFinish
	);
	const ownBankSign1 = searchStages('Firmado con su banco', 'closeReason', stagesFinish);

	const gbSign = searchStages('Firmado con gibobs', 'closeReason', stagesFinish);

	const noSign = stagesFinish.filter(
		(task) =>
			task.closeReason !== 'Firmado con su banco' && task.closeReason !== 'Firmado con gibobs'
	).length;

	const widgetNuevo = [];

	useEffect(() => {
		widgetNuevo.push(
			{
				id: '123456AllOperations',
				dashboardId: '123456AllOperations',
				name: 'ALLOPERATIONS',
				type: 'WIDGET_DASHBOARDGENERAL_REALSTATE_SMALL',
				xSize: 264,
				ySize: 264,
				isActive: true,
				data: {
					title: 'total',
					color: '#2F4858',
					quantity: operationsClient.length,
					url: '/sales-tools/follow-up',
					key: undefined
				}
			},
			{
				id: '123456CourseOperations',
				dashboardId: '123456CourseOperations',
				name: 'COURSEOPERATIONS',
				type: 'WIDGET_DASHBOARDGENERAL_REALSTATE_SMALL',
				xSize: 794,
				ySize: 264,
				isActive: true,
				data: {
					color: '#52C41A',
					quantity: progressRequest.length,
					basic: searchStages('basic', 'stage', progressRequest),
					full: searchStages('full', 'stage', progressRequest),
					documents: searchStages('documents', 'stage', progressRequest),
					analysis: searchStages('analysis', 'stage', progressRequest),
					banks: searchStages('banks', 'stage', progressRequest),
					offers: searchStages('oferts', 'stage', progressRequest),
					appraisal: searchStages('appraisal', 'stage', progressRequest),
					formalization: searchStages('formalization', 'stage', progressRequest),
					url: '/sales-tools/follow-up?status=open',
					key: 'open'
				}
			},
			{
				id: '123456PendingOperations',
				dashboardId: '123456PendingOperations',
				name: 'PENDINGOPERATIONS',
				type: 'WIDGET_DASHBOARDGENERAL_REALSTATE_SMALL',
				xSize: 264,
				ySize: 264,
				isActive: true,
				data: {
					color: '#FAAD14',
					quantity: pendientConfirm.length,
					url: '/sales-tools/follow-up?status=preregister',
					key: 'preregister'
				}
			},
			{
				id: '123456FinishOperations',
				dashboardId: '123456FinishOperations',
				name: 'FINISHOPERATIONS',
				type: 'WIDGET_DASHBOARDGENERAL_REALSTATE_SMALL',
				xSize: 794,
				ySize: 264,
				isActive: true,
				data: {
					color: '#1890FF',
					url: '/sales-tools/follow-up?status=closed',
					quantity: stagesFinish.length,
					banks: ownBankSign + ownBankSign1,
					gibobs: gbSign,
					others: noSign
				}
			},
			{
				id: '123456OperationsBySteps',
				dashboardId: '123456OperationsBySteps',
				name: 'OPERATIONSBYSTEPS',
				type: 'WIDGET_DASHBOARDGENERAL_REALSTATE',
				xSize: 794,
				ySize: 264,
				isActive: true,
				data: {
					color: '#FFFFFF',
					quantity: progressRequest.length,
					basic: searchStages('basic', 'stage', progressRequest),
					full: searchStages('full', 'stage', progressRequest),
					documents: searchStages('documents', 'stage', progressRequest),
					analysis: searchStages('analysis', 'stage', progressRequest),
					banks: searchStages('banks', 'stage', progressRequest),
					offers: searchStages('oferts', 'stage', progressRequest),
					appraisal: searchStages('appraisal', 'stage', progressRequest),
					formalization: searchStages('formalization', 'stage', progressRequest),
					url: '/sales-tools/follow-up?status=open',
					key: 'open'
				}
			},
			{
				id: '123456DetailCloseOperations',
				dashboardId: '123456DetailCloseOperations',
				name: 'DETAILCLOSEDOPERATIONS',
				type: 'WIDGET_DASHBOARDGENERAL_REALSTATE',
				xSize: 794,
				ySize: 264,
				isActive: true,
				data: {
					color: '#FFFFF',
					url: '/sales-tools/follow-up?status=closed',
					quantity: stagesFinish.length,
					banks: ownBankSign + ownBankSign1,
					gibobs: gbSign,
					others: noSign
				}
			}
		);
		setDataWidgets(widgetNuevo);
	}, [operationsClient]);

	const componenstName = {
		ALLOPERATIONS: 'init.allOperations',
		PENDINGOPERATIONS: 'init.pendingOperations',
		COURSEOPERATIONS: 'init.courseOperations',
		FINISHOPERATIONS: 'init.finishOperations',
		TUTORIAL: 'init.tutorial',
		OPERATIONSBYSTEPS: 'init.operationsBySteps',
		DETAILCLOSEDOPERATIONS: 'init.detailClosedOperations'
	};

	const infoData = [
		operationsClient.length !== 0
			? {
					title: t('b2bOperationList.newMortgage'),
					description: t('init.descriptionMortgageSalesTools'),
					action: '/sales-tools/mortgages',
					image: isMobileOnly ? Mortgage : MortgageDesktop
			  }
			: {
					title: t('init.createOperations'),
					description: t('init.createOperationsDescription'),
					action: '/sales-tools/new-requests',
					button: AddButton
			  },

		{
			title: isMobileOnly ? t('calculator.cardTitle4') : t('calculator.cardTitle1'),
			description: t('calculator.cardTitle2'),
			action: '/calculators/fee',
			image: isMobileOnly ? calculatortmpMobile : calculatortmp
		},
		{
			title: t('calculator.cardTitle3'),
			description: isMobileOnly ? t('calculator.cardTitle5') : t('calculator.cardTitle5'),
			action: '/calculators/qualify/client',
			image: isMobileOnly ? calculatortmp2Mobile : calculatortmp2
		},
		operationsClient.length === 0 && {
			title: (
				<div>
					<FileOutlined style={{ color: '#02C3CD', fontSize: 18, marginRight: 16 }} />
					{t('init.use')}
				</div>
			),
			description: t('init.descriptionDashboardTutorial')
		}
	];
	const infoDataDownload = [
		!isMobile && {
			//descomentar para el nuevo dashboard
			title: t('promoterLoan.promoterFinancingProducts'),
			description: t('promoterLoan.startProjectDescription'),
			action: () => dispatch(createNewProject()),
			image: ImageP,
			promoter: true
		}
	];

	const colSpan = { xxl: 6, xl: 6, lg: 8, md: 12, sm: 24, xs: 24 };

	return (
        (<Layout
			className="gibobs-dashboard-inmo"
			style={{ marginLeft: isMobile && '20px', marginBottom: isMobile && 120 }}>
            <Row gutter={24}>
				{operationsClient.length !== 0 && (
					<Col
						{...colSpan}
						className="gibobs-dashboard-inmo-operations"
						style={{
							rowGap: isMobile && 0,
							flexWrap: 'none',
							flexDirection: 'column',
							marginTop: !isMobile && 40
						}}>
						{dataWidgets
							? dataWidgets.map((box) => {
									const ComponentsType = componentsType[box.type];
									const nameData = componenstName[box.name];
									if (box.type !== 'WIDGET_DASHBOARDGENERAL_REALSTATE' && !isMobile) {
										return (
											<Card
												className={!isMobile ? 'box-inmo' : 'box-inmo-mobile'}
												key={box.id}
												onClick={() => {
													navigate(box.data.url);
												}}
												style={{ backgroundColor: box.data.color, height: 76 }}>
												<ComponentsType data={box} title={nameData} />
											</Card>
										);
									}
							  })
							: null}
					</Col>
				)}
				{infoData.map((data, i) => {
					if (data !== false) {
						return (
							<Col {...colSpan}>
								<WidgetInmo
									title={data.title}
									description={data.description}
									action={data.action}
									i={i}
									operationsClient={data.operationsClient}
									image={data.image && data.image}
									button={data.button && data.button}
								/>
							</Col>
						);
					}
				})}
			</Row>
            {isMobile && operationsClient.length !== 0 && (
				<div
					style={{
						fontSize: '12px',
						lineHeight: '16px',
						fontWeight: 600,
						marginBottom: '12px',
						marginTop: '8px'
					}}>
					{t('init.statusOperations')}
				</div>
			)}
            {operationsClient.length !== 0 && (
				<Row
					gutter={24}
					className="gibobs-dashboard-inmo-operations"
					// style={{
					//   rowGap: isMobile && 0,
					//   flexDirection: !isMobile && "row",
					//   width: isMobile && "100%",
					// }}
				>
					{dataWidgets
						? dataWidgets.map((box) => {
								const ComponentsType = componentsType[box.type];
								const nameData = componenstName[box.name];
								if (!isMobile && box.type === 'WIDGET_DASHBOARDGENERAL_REALSTATE') {
									return (
										<Col xl={12} lg={24} md={24} sm={24} xs={24}>
											<Card
												className="box-double"
												key={box.id}
												onClick={() => {
													navigate(box.data.url);
												}}
												style={{ backgroundColor: box.data.color }}>
												<ComponentsType data={box} title={nameData} />
											</Card>
										</Col>
									);
								}
								if (box.type !== 'WIDGET_DASHBOARDGENERAL_REALSTATE' && isMobile) {
									return (
										<Col xl={12} lg={24} md={24} sm={24} xs={24}>
											<Card
												className={'box-inmo box-inmo-mobile'}
												key={box.id}
												onClick={() => {
													navigate(box.data.url);
												}}
												style={{ backgroundColor: box.data.color }}>
												<ComponentsType data={box} title={nameData} />
											</Card>
										</Col>
									);
								}
						  })
						: null}
					{infoDataDownload.map((data) => {
						if (data !== false) {
							return (
								<Col {...colSpan}>
									<WidgetInmo
										title={data.title}
										description={data.description}
										action={data.action}
										i={3}
										operationsClient={data.operationsClient}
										image={data.image && data.image}
										button={data.button && data.button}
									/>
								</Col>
							);
						}
					})}
				</Row>
			)}

			{isMobileOnly && (
				<>
					<Card
						className="gb-card-24px"
						cover={
							<img style={{ width: '100%', height: 64 }} src={promoterImagemobile} alt={'asdf'}></img>
						}>
						<div>
							<Text style={{ color: '#2F4858', fontWeight: 600, fontSize: 16 }}>
								{t('promoterLoan.promoterFinancingProducts')}
							</Text>
						</div>

						<Button
							type="primary"
							onClick={() => dispatch(createNewProject())}
							className="button-primary-gibobs-inmo"
							style={{
								height: 40,
								width: '100%',
								fontSize: 14,
								marginTop: 24
							}}>
							{t('promoterLoan.newProject')}
						</Button>
						<div
							onClick={() => setModalPromoter(true)}
							style={{
								display: 'flex',
								justifyContent: 'center',
								marginTop: 8,
								cursor: 'pointer',
							}}>
								
							<Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 500 }}>
								{t('promoter.whatSee')}
							</Text>
						</div>
					</Card>
					<ModalInfoFunding visible={modalPromoter} setVisible={setModalPromoter} />
				</>
			)}

			<Modal
				className="ModalCommon modal-footer-align"
				title={
					<div style={{ textAlign: 'center' }}>
						<img alt="gibobsLogo" src={gibobsLogo} height={60} style={{ textAlign: 'center' }} />
					</div>
				}
				footer={[
					<Button
						key="submit"
						type="primary"
						onClick={handleModalOk}
						target={'_blank'}
						href={
							isMobile
								? '/guia_plataforma_gibobs_mobile.pdf'
								: '/guia_plataforma_gibobs_desktop.pdf'
						}>
						{t('init.download')}
					</Button>
				]}
				centered
				open={visible}
				onOk={() => handleModalOk()}
				onCancel={() => handleModalCancel()}
				okText="Dercargar">
				<div
					style={{
						fontSize: 14,
						color: '#748EA0',
						fontWeight: 400,
						textAlign: 'center'
					}}>
					{t('init.welcomeB2B')}
				</div>
				<div
					style={{
						fontSize: 14,
						color: '#748EA0',
						fontWeight: 700,
						marginTop: 20,
						textAlign: 'center'
					}}>
					{t('init.innovationsTitleB2B')}
				</div>
			</Modal>
        </Layout>)
    );
};

export default DragAndDrop;
