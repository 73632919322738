import React from "react";
import { Form, Modal } from "antd";
import { useTranslation } from "react-i18next";
import numeral from "numeral";

const ModalShiftCalculation = ({
  visible,
  onCancel,
  onCreate,
  viabilityData,
  dataOperation,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

 

  const detailOptions = (name, quantity, condition) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 12,
        }}
      >
        <div
          className="amounts-name-mortgage"
          style={{ fontWeight: condition === "total" && 600 }}
        >
          {t(name)}
        </div>
        {name !==
        `${t("onboarding.amountCalculation")}/${t(
          "onboarding.priceOfHouse"
        )}` ? (
          <div
            className="amounts-name-mortgage amounts-quantity"
            style={{
              fontWeight: condition === "total" && 600,
              color:
                condition === "+" ? "#FF4D4F" : condition === "-" && "#52C41A",
            }}
          >
            {(condition === "-" || condition === "+") && condition}
            {numeral(quantity).format("0,0.00 $")}
          </div>
        ) : (
          <div
            className="amounts-name-mortgage amounts-quantity"
            style={{ fontWeight: condition == "total" && 600 }}
          >
            {numeral(quantity).format("0,0%")}
          </div>
        )}
      </div>
    );
  };

  return (
    (<Modal
      className="ModalCommon ModalCommonSmallDelete modal-onboarding-amount"
      centered
      zIndex={1200}
      cancelButtonProps={{
        className: "button-secundary-gibobs",
        style: { display: "none" },
      }}
      okButtonProps={{
        className: "button-primari-gibobs",
        style: { display: "none" },
      }}
      open={visible}
      title={t("fee.calculationOfTheChange")}
      okText={t("viability.continue")}
      cancelText={t("mydocuments.deleteCancel")}
      width={480}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
            form.resetFields();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      {detailOptions(
        "fee.fullCancellationFeeModal",
        dataOperation &&
          dataOperation.mortgage &&
          dataOperation.mortgage.maxBudget
      )}
      {detailOptions(
        "fee.inQuotas",
        viabilityData &&
          viabilityData.operation &&
          viabilityData.operation.savings,
        "-"
      )}
      {detailOptions("fee.inLinkages", 2000, "+")}
      <div style={{ border: "1px solid #748EA0", marginBottom: 12 }}></div>
      {detailOptions(
        "m_balance.realestateTotalTitle",
        viabilityData?.operation?.mortgageAmount === 0
          ? Number(
              viabilityData?.operation?.taxes +
                dataOperation?.mortgage?.maxBudget -
                viabilityData?.operation?.savings
            ) + 2000
          : viabilityData?.operation?.mortgageAmount,
        "total"
      )}
    </Modal>)
  );
};

export default ModalShiftCalculation;
