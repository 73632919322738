import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import numeral from "numeral";
import moment from "moment";
import { isMobile } from "react-device-detect";

const { Text } = Typography;

const DataInformation = ({ valuesData, ownerList, dataMortgage }) => {
  const { t } = useTranslation();

  const spaceInfo = (title, info) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "12px 16px",
          borderBottom: "1px solid #F1F7F8",
          alignItems: isMobile && "center",
          backgroundColor: isMobile && "#FFFFFF",
        }}
      >
        <Text
          style={{
            fontSize: 16,
            fontWeight: 400,
            color: "#2F4858",
          }}
        >
          {t(title)}
        </Text>
        <Text style={{ fontSize: 14, fontWeight: 600, color: "#748EA0" }}>
          {info}
        </Text>
      </div>
    );
  };

  const houseTypeConst = {
    main: "form.main",
    second: "form.second",
    investor: "form.investor",
    secondHand: "form.secondHand",
    newConstruction: "form.newBuild",
  };

  return (
    <div style={{ marginLeft: -16, marginRight: -16 }}>
      <div
        style={{
          marginTop: !isMobile && 8,
          marginBottom: 12,
          marginLeft: 16,
        }}
      >
        <Text
          style={{
            fontSize: 12,
            fontWeight: 600,
            color: "#2F4858",
          }}
        >
          {t("certificate.seccionData").toUpperCase()}
        </Text>
      </div>
      {dataMortgage.mortgage &&
        dataMortgage.mortgage.operationType &&
        dataMortgage.mortgage.operationType !== "subrogation" &&
        dataMortgage.mortgage &&
        dataMortgage.mortgage.houseState !== "subrogation" && (
          <div>
            {spaceInfo(
              "m_amigobs.createDate",
              moment(valuesData.createdAt).format("L")
            )}
            {spaceInfo("form.provinces", valuesData.operation.address)}
            {spaceInfo(
              "certificate.houseTypeInmo",
              t(houseTypeConst[valuesData.operation.propertyType])
            )}
            {spaceInfo(
              "form.titlePrice",
              numeral(
                dataMortgage.mortgage &&
                  dataMortgage.mortgage.houseState === "have_decide"
                  ? valuesData.operation.amount
                  : dataMortgage.mortgage &&
                      dataMortgage.mortgage.viabilityData &&
                      dataMortgage.mortgage.viabilityData.operation &&
                      dataMortgage.mortgage.viabilityData.operation.property
              ).format("0,0 $")
            )}
          </div>
        )}
      {((dataMortgage.operation &&
        dataMortgage.mortgage.operationType &&
        dataMortgage.mortgage.operationType === "subrogation") ||
        (dataMortgage.mortgage &&
          dataMortgage.mortgage.houseState === "subrogation")) && (
        <div>
          {spaceInfo(
            "suborgation.initialSignatureDate",
            moment(dataMortgage.subrogation.dateSign).format("L")
          )}
          {spaceInfo(
            "form.pendingCapital",
            numeral(dataMortgage.subrogation.amount).format("0,0 $")
          )}
          {spaceInfo(
            "loan.timeAmount",
            `${dataMortgage.subrogation.subrogationSigned} ${t(
              "viability.years"
            )}`
          )}
          {spaceInfo(
            "subrogation.quotaSubrogation",
            numeral(dataMortgage.subrogation.quota).format("0,0 $")
          )}
        </div>
      )}
      {ownerList &&
        dataMortgage &&
        ownerList.map((owner, index) => {
          return (
            <div key={index}>
              {dataMortgage[owner] && (
                <>
                  <div
                    style={{
                      marginBottom: 12,
                      marginTop: 32,
                      marginLeft: 16,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: 600,
                        color: "#2F4858",
                      }}
                    >
                      {t("certificate.dataOwnerList").toUpperCase() +
                        t(`form.${owner}`).toUpperCase()}
                    </Text>
                  </div>
                  {spaceInfo(
                    "b2bOperationList.customer",
                    dataMortgage[owner].name
                  )}
                  {spaceInfo("form.email", valuesData.client.email)}
                  {spaceInfo("form.phone", valuesData.client.phone)}
                </>
              )}
            </div>
          );
        })}
    </div>
  );
};
export default DataInformation;
