import React from 'react';
import { Row, Col, Typography, Progress } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import numeral from 'numeral';

const { Text } = Typography;


const ViabilityClientProgress = ({ result, resultForm, form, edit, fixed, quotes }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();;

	return (
		<Row style={{ alignItems: 'center' }}>
			{/* span a 10 si hay viabilidad si no 20*/}
			<Col span={!_.isNil(result?.simulationData?.resultViabilityMortage?.viability) ? 10 : 12}>
				<Text
					style={{
						color: '#748EA0',
						fontSize: 16,
						fontWeight: 400
					}}>
					{ t('calculator.bankViability')}
				</Text>
			</Col>
			<Col
				span={!_.isNil(result?.simulationData?.resultViabilityMortage?.viability) ? 4 : 12}
				style={{
					cursor: 'pointer',
					textAlign: !_.isNil(result?.simulationData?.resultViabilityMortage?.viability) ? undefined : 'right'
				}}>
				{_.isNil(result?.simulationData?.resultViabilityMortage?.viability) && (
					<Progress
						onClick={() => {
							
							navigate(`/calculators/fee/datas`,{
								state: {
									values: { ...form.getFieldsValue() },
									fixed,
									quotes,
									id: result !== undefined ? result?.id : undefined
								}
							});
						}}
						type="circle"
						percent={100}
						width={40}
						format={() => (
							<img
								src={edit}
								alt="asdf"
								style={{
									width: 20,
									height: 20,
									// marginTop: -5
								}}></img>
						)}
					/>
				)}
				{!_.isNil(result?.simulationData?.resultViabilityMortage?.viability) && (
					<Progress
						type="circle"
						strokeColor={'#52C41A'}
						trailColor={'#F1F7F8'}
						percent={
							((result?.simulationData?.resultViabilityMortage
								? result?.simulationData?.resultViabilityMortage.viability
								: 0) *
								100) /
							5
						}
						width={40}
						format={() => (
							<Text
								strong
								style={{
									fontSize: '12px',
									color: '#2F4858',
									marginBottom: '0px',
									display: 'grid'
								}}>
								{result?.simulationData?.resultViabilityMortage
									? numeral(result?.simulationData?.resultViabilityMortage.viability).format('0.0')
									: numeral().format('0.0')}
							</Text>
						)}
					/>
				)}
			</Col>
			{!_.isNil(result?.simulationData?.resultViabilityMortage?.viability) && (
				<Col span={10} style={{ textAlign: 'right' }}>
					<Text
						style={{
							color: '#02C3CD',
							fontSize: 14,
							fontWeight: 400
						}}>
						{t('onboarding.why')}
					</Text>
					<InfoCircleOutlined
						onClick={() => {
							
							navigate(`/calculators/fee/viability`,{
								state: { result, formValues: resultForm }
							});
						}}
						style={{ color: '#02C3CD', marginLeft: 8 }}
					/>
				</Col>
			)}
		</Row>
	);
};

export default ViabilityClientProgress;
