
import dataPhotos from '../../../../../assets/documents/dataPhotos.png';
import dataBanks from '../../../../../assets/documents/dataBanks.png';
import dataMoreBanks from '../../../../../assets/documents/dataMoreBanks.png';
import camera from '../../../../../assets/documents/camera.png'
import env from '../../../../../environment';
import errorModal from '../../../../../assets/documents/ErrorIconAfterbanks.png'
import psd2 from '../../../../../assets/documents/psd2.png'
import footerGibobs from '../../../../../assets/documents/footerGibobs.png'
import footerMobileDocs from '../../../../../assets/documents/footerMobileDocs.png'
import gifBank from '../../../../../assets/documents/gifBank.gif'
import { AutomaticDocumentationNew } from 'mortgage-documents';



const AutomaticDocumentation = ({ userMortgageId, stage }) => {
	return (
		<AutomaticDocumentationNew
			userMortgageId={userMortgageId}
			stage={stage}
			env={env}
			platform={"gibobs"}
			traceSadabdell={undefined}
			images={{
				dataPhotos,
				dataBanks,
				dataMoreBanks,
				errorModal,
				psd2,
				footerGibobs,
				footerMobileDocs,
				gifBank,
				camera
			}}
		/>
	);
};

export default AutomaticDocumentation;