import React from "react";
import { Modal, Steps, Typography } from "antd";
import { useTranslation } from "react-i18next";
const { Step } = Steps;
const { Text } = Typography;

export const StepsProcess = (data) => {
  const { t } = useTranslation();

  return (
    <Steps
      direction="vertical"
      size="small"
      current={-1}
      className={"gibobs-steps-financing-promoter"}
      style={{ marginTop: 16 }}
    >
      {data.map((data) => (
        <Step title={t(data.title)} description={t(data.description)} />
      ))}
    </Steps>
  );
};

function ModalFinancingProcessMobile({ visible, onCancel }) {
  const { t } = useTranslation();

  const data = [
    {
      title: "promoter.simulationStage",
      description: "promoterLoan.modalFinancingStep1Description",
    },
    {
      title: "promoterLoan.modalFinancingStep3",
      description: "promoterLoan.modalFinancingStep3Description",
    },
    {
      title: "promoterLoan.modalFinancingStep2",
      description: "promoterLoan.modalFinancingStep2Description",
    },
    {
      title: "promoterLoan.modalFinancingStep4",
      description: "promoter.bestSolutions",
    },
    {
      title: "stages.formalization",
      description: "promoterLoan.modalFinancingStep5Description",
    },
  ];

  return (
    <Modal
      className="modal-promoter-mobile ModalCommon"
      visible={visible}
      centered
      footer={null}
      // width={}
      onCancel={() => {
        onCancel();
      }}
    >
      <Text style={{ fontSize: 16, fontWeight: 600, color: '#2F4858' }}>
        {t("promoter.financingProccess")}
      </Text>
      {StepsProcess(data)}
    </Modal>
  );
}

export default ModalFinancingProcessMobile;
