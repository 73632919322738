import React, { useEffect, useState } from 'react';
import {
	Row,
	Button,
	Card,
	Col,
	Typography,
	Form,
	Select,
	Progress,
	Slider,
	Modal,
	Spin
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	ArrowLeftOutlined,
	InfoCircleOutlined,
	LoadingOutlined,
	MinusOutlined,
	PlusOutlined
} from '@ant-design/icons';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { renderComponent } from '../../../select/typeSelect';
import edit from '../../../assets/calculators/edit.png';
import CalculatorsFeeDesktop from './CalculatorsFeeDesktop';
import axios from 'axios';
import env from '../../../environment';
import numeral from 'numeral';
import _ from 'lodash';
import { InputNumber } from 'afrodita';
import ComplicatedViability from '../../onboarding/Components/ComplicatedViability';
import ModalWithoutButtons from '../../Financiero/Loans/Modals/ModalWithoutButtons';
import MortgageAmountModal from '../quotaB2C/Modals/MortgageAmountModal';
import { renderDesc, renderLabelButton, renderTitle } from './common/RenderCommonTexts';
import ViabilityClientProgress from './ViabilityClientProgress';
import calculatorImage from '../../../assets/calculators/calculatorImage.png';

const { Text } = Typography;
const { Option } = Select;

const CalculatorsFeeMobileAndDesktop = ({}) => {
	const location = useLocation();
	const { t } = useTranslation();
	const rol = useSelector((state) => state.auth.roles);
	const navigate = useNavigate();;
	const [loading, setLoading] = useState(true);
	const [form] = Form.useForm();
	const [term, setTerm] = useState(false);
	const [amountHp, setAmountHp] = useState(false);
	const [rate, setRate] = useState(false);
	const [pay, setPay] = useState(false);
	const [finish, setFinish] = useState(false);
	const dataProfile = useSelector((state) => state.data.dataProfile);
	const userToken = useSelector((state) => state.auth.token);
	const [fixed, setFixed] = useState(undefined);
	const [quotes, setQuotes] = useState(360);
	const [, setPrice] = useState(180000);
	const [, setSavings] = useState(55000);
	const [province, setProvince] = useState(
		dataProfile && dataProfile.province ? dataProfile.province : 'Valencia'
	);
	const [result, setResult] = useState(undefined);
	const [participants, setParticipants] = useState(undefined);
	const [resultForm, setResultForm] = useState(undefined);
	const [showFields, setShowFields] = useState(true);
	const [result2, setResult2] = useState(undefined);
	const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
	const [fixedTmp, setFixedTmp] = useState(undefined);
	const [variableTmp, setVariableTmmp] = useState(undefined);
	const [euriborTmp, setEuriborTmp] = useState(undefined);
	const [timeoutReference, setTimeoutReference] = useState(undefined);
	const [, setDataUltimateSimulation] = useState(undefined);
	const [idUltimateSimulation, setIdUltimateSimulation] = useState(undefined);
	const [, setFromValuesClient] = useState(undefined);
	const [locationTmpText, setLocationTmpText] = useState(undefined);
	const dataSummary = useSelector((state) => state.data.dataSummary);

	useEffect(() => {
		if (rol !== 'realestate') {
			getUltimateSimulation();
			if (dataSummary.filter((data) => data.subtype === 'mortgage').length > 0) {
				getText();
			}
		}
	}, []);

	useEffect(() => {
		if (variableTmp && euriborTmp && fixedTmp) {
			let varMasEur = variableTmp + euriborTmp;

			let total = varMasEur <= fixedTmp ? varMasEur : fixedTmp;

			setFixed(total);
		}
	}, [variableTmp && euriborTmp && fixedTmp]);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, [1500]);
		if (!_.isNil(location.state)) {
			getData(location?.state?.result?.id);
			setResult(location?.state?.result);
			setResultForm(location.state?.formValues);
			setParticipants(
				location?.state?.result?.simulationData['owner2']?.activity !== undefined ? '2' : '1'
			);
		}
		getFixAndVariable();

		if (_.isNil(location.state) && fixed && province && rol === 'realestate') {
			firtSimulation();
		}
	}, [fixed]);

	const getText = () => {
		axios
			.post(
				`${env.api.url}/campaigns/runner/experiment/by-event`,
				{
					event: 'questions-mortgages',
					data: {
						operationId: dataSummary[0].operationId
					}
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
                    if (response.data.data.mortgageQuestions === '2022') {
                        if (dataSummary[0].stage === 'basic') {
                            setLocationTmpText(
                                `/testA/operations/${dataSummary[0].operationId}/${dataSummary[0].stage}`
                            );
                        }else {
                            setLocationTmpText(
                                `/operations/${dataSummary[0].operationId}/${dataSummary[0].stage}`
                            );
                        }
                        
                    } else {
                        if (dataSummary[0].stage === 'basic') {
                            setLocationTmpText(
                                `/testA/operations/${dataSummary[0].operationId}/${dataSummary[0].stage}`
                            );
                        }else {
                            setLocationTmpText(
                                `/operations/${dataSummary[0].operationId}/${dataSummary[0].stage}`
                            );
                        }
                    }
			});
	};

	const getUltimateSimulation = () => {
		axios
			.get(
				`${env.api.url}/mortgages/simulations/get-last-simulation?simulationMortgageType=QUOTA_CALCULATION`,
				{
					headers: {
						Authorization: `Bearer ${userToken}`
					}
				}
			)
			.then((response) => {
				setDataUltimateSimulation(response.data.data);
				if (response?.data?.data?.id && response.data.data.simulationData.houseType) {
					setIdUltimateSimulation(response.data.data.id);
					firtsCallSimulationTheLast(response.data.data);
				} else {
					firtSimulation();
				}
			});
	};

	const firtSimulation = () => {
		axios
			.post(
				`${env.api.url}/mortgages/simulations/quota-and-viability/mortgage-viability`,
				{
					id: !_.isNil(location.state) ? location.state.result.simulation.id : result2,
					simulationType: rol === 'realestate' ? 'B2B' : 'B2C',
					maxBudget: 180000,
					savings: 55000,
					propertyType: 'secondHand',
					province: province,
					fixed: fixed ? fixed : 2.9,
					quotes: quotes,
					participants: undefined
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				getData(response.data.data.simulation.id);
			})
			.catch((error) => {
                console.log(error)
            });
	};

	const firtsCallSimulationTheLast = (allValues) => {
		const objValues = {
			id: allValues.id,
			savings: allValues.simulationData.savings,
			propertyType: allValues.simulationData.propertyType,
			simulationType: 'B2C',
			numerousFamily: false,
			province: allValues.simulationData.province,
			maxBudget: allValues.simulationData.maxBudget,
			fixed: allValues.simulationData.fixed,
			quotes: allValues.simulationData.quotes,
			participants: {
				houseType: allValues.simulationData.houseType,
				participants: allValues.simulationData.owner2 ? 2 : 1,
				owners: [allValues.simulationData.owner1, allValues.simulationData.owner2]
			}
		};
		if (_.isNil(allValues.simulationData.owner2)) {
			objValues.participants.participants = 1;
			objValues.participants.owners = [allValues.simulationData.owner1];
		} else {
			objValues.participants.participants = 2;
			objValues.participants.owners = [
				allValues.simulationData.owner1,
				allValues.simulationData.owner2
			];
		}
		axios
			.post(
				`${env.api.url}/mortgages/simulations/quota-and-viability/mortgage-viability`,
				objValues,
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				const result = response.data.data.simulation;
				let tmpValue = {};
				if (result?.simulationData.owner2) {
					tmpValue.activity2 = result?.simulationData.owner2.activity;
					tmpValue.age2 = result?.simulationData.owner2.age;
					tmpValue.cityzenship2 = result?.simulationData.owner2.cityzenship;
					tmpValue.loanPayment2 = result?.simulationData.owner2.loanPayment;
					tmpValue.netIncomes2 = result?.simulationData.owner2.netIncomes;
					tmpValue.seniority2 = result?.simulationData.owner2.seniority;
					tmpValue.houseType = result?.simulationData.houseType;
					tmpValue.propertyType = result?.simulationData.propertyType;
				}
				if (result?.simulationData.owner1) {
					tmpValue.activity1 = result?.simulationData.owner1.activity;
					tmpValue.age1 = result?.simulationData.owner1.age;
					tmpValue.cityzenship1 = result?.simulationData.owner1.cityzenship;
					tmpValue.loanPayment1 = result?.simulationData.owner1.loanPayment;
					tmpValue.netIncomes1 = result?.simulationData.owner1.netIncomes;
					tmpValue.seniority1 = result?.simulationData.owner1.seniority;
					tmpValue.houseType = result?.simulationData.houseType;
					tmpValue.propertyType = result?.simulationData.propertyType;
				}
				if (result?.simulationData.owner2) {
					tmpValue.participants = '2';
				} else {
					tmpValue.participants = '1';
				}

				setFromValuesClient(tmpValue);
				setResultForm(tmpValue);
				getData(response.data.data.simulation.id);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const getFixAndVariable = () => {
		axios
			.get(`${env.api.url}/v1/options/get-option/rate-gibobs-variable-tae`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				setVariableTmmp(response.data);
			});

		axios
			.get(`${env.api.url}/v1/options/get-option/rate-gibobs-fixed-tae`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				setFixedTmp(response.data);
			});

		axios
			.get(`${env.api.url}/v1/options/get-option/euribor`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				setEuriborTmp(response.data);
			});
	};
	const getData = (id) => {
		axios
			.get(`${env.api.url}/mortgages/simulations/get-simulation/${id}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setResult2(response.data.data.simulationData.id);
				setResult(response.data.data);
				setParticipants(response.data.data.simulationData.owner2 !== undefined ? '2' : '1');
				setLoading(false);
			})
			.catch((error) => {
                console.log(error)
            });
	};

	const sendFinish = () => {
		if (rol === 'realestate') {
			setFinish(false);
			
			navigate(`/sales-tools/mortgages`,{
                state: {
					values: { ...form.getFieldsValue() },
					fixed,
					quotes,
					result: result !== undefined ? result : undefined
				}
            });
		}
		if (rol !== 'realestate') {
			setFinish(false);
			axios
				.post(
					`${env.api.url}/mortgages/simulations/quota-and-viability/create-operation-mortgage/${idUltimateSimulation}`,
					{},
					{
						headers: {
							Authorization: `Bearer ${userToken}`
						}
					}
				)
				.then((response) => {
					if (response.data.success) {
						const id = response.data.data.operationId;
						axios
							.post(
								`${env.api.url}/campaigns/runner/experiment/by-event`,
								{
									event: 'questions-mortgages',
									data: {
										operationId: id
									}
								},
								{
									headers: { Authorization: `Bearer ${userToken}` }
								}
							)
							.then((response) => {
								if (response.data.data.mortgageQuestions === '2022') {
									navigate(`/testA/operations/${id}/basic`);
								} else {
									navigate(`/operations/${id}/basic`);
								}
							});
					}
				});
		}
	};

	const onChangeFixed = (value) => {
		setFixed(value);
		const tmp = form.getFieldsValue();
		const tmpValues = {
			fixed: value,
			quotes: undefined
		};
		onChangeForm(tmpValues, tmp);
	};

	const onChangeTerm = (value) => {
		setQuotes(value * 12);
		const tmp = form.getFieldsValue();
		const tmpValues = {
			fixed: undefined,
			quotes: value * 12
		};
		onChangeForm(tmpValues, tmp);
	};

	const onChangeForm = (values, allValues) => {
		setSavings(allValues.savings);
		setPrice(allValues.price);
		setProvince(allValues.province);

		let owners = [];

		if (participants === '2' && resultForm !== undefined) {
			owners = [
				{
					activity: resultForm.activity1,
					seniority: resultForm.seniority1 !== undefined ? resultForm.seniority1 : 0,
					netIncomes: resultForm.netIncomes1 !== undefined ? resultForm.netIncomes1 : 0,
					loanPayment: resultForm.loanPayment1 !== undefined ? resultForm.loanPayment1 : 0,
					age: resultForm.age1 !== undefined ? resultForm.age1 : 0,
					cityzenship: resultForm.cityzenship1
				},
				{
					activity: resultForm.activity2,
					seniority: resultForm.seniority2 !== undefined ? resultForm.seniority2 : 0,
					netIncomes: resultForm.netIncomes2 !== undefined ? resultForm.netIncomes2 : 0,
					loanPayment: resultForm.loanPayment2 !== undefined ? resultForm.loanPayment2 : 0,
					age: resultForm.age2 !== undefined ? resultForm.age2 : 0,
					cityzenship: resultForm.cityzenship2
				}
			];
		} else {
			if (participants === '1' && resultForm !== undefined) {
				owners = [
					{
						activity: resultForm.activity1,
						seniority: resultForm.seniority1 !== undefined ? resultForm.seniority1 : 0,
						netIncomes: resultForm.netIncomes1 !== undefined ? resultForm.netIncomes1 : 0,
						loanPayment: resultForm.loanPayment1 !== undefined ? resultForm.loanPayment1 : 0,
						age: resultForm.age1 !== undefined ? resultForm.age1 : 0,
						cityzenship: resultForm.cityzenship1
					}
				];
			}
		}
		if (timeoutReference) {
			clearTimeout(timeoutReference);
		}

		setTimeoutReference(
			setTimeout(() => {
				axios
					.post(
						`${env.api.url}/mortgages/simulations/quota-and-viability/mortgage-viability`,
						{
							id: result?.id,
							simulationType: rol === 'realestate' ? 'B2B' : 'B2C',
							maxBudget: allValues.price !== null ? allValues.price : 0,
							savings: allValues.savings !== null ? allValues.savings : 0,
							propertyType: allValues.propertyType,
							province: allValues.province !== undefined ? allValues.province : dataProfile.provice,
							fixed: values.fixed !== undefined ? values.fixed : fixed,
							quotes: values.quotes !== undefined ? values.quotes : quotes,
							participants:
								owners[0] !== undefined
									? {
											houseType:
												result?.simulationData?.houseType !== undefined
													? result?.simulationData?.houseType
													: result?.simulationData?.houseType,
											participants: parseInt(participants),
											owners: owners
									  }
									: undefined
						},
						{
							headers: { Authorization: `Bearer ${userToken}` }
						}
					)
					.then((response) => {
						getData(response.data.data.simulation.id);
					})
					.catch((error) => {
                        console.log(error)
                    });
				clearTimeout(timeoutReference);
			}, 500)
		);
	};

	const renderMax = () => {
		if (result) {
			if (result.simulationData) {
				const maxAge2 = result.simulationData.owner2 ? result.simulationData.owner2.age : 0;
				const maxAge1 = result.simulationData.owner1 ? result.simulationData.owner1.age : 40;

				const tmpAge = maxAge2 >= maxAge1 ? maxAge2 : maxAge1;

				const tmpAgeMin = 75 - tmpAge;

				const ageValue = tmpAgeMin <= 40 ? tmpAgeMin : 40;

				return ageValue;
			}
		}
	};

	const renderNumeral = (result) => {
		const amount = result.simulationData.resultQuoteMortage.operation.property;
		const impuestos = result.simulationData.resultQuoteMortage.operation.taxes + 2000;
		const intereses =
			result?.simulationData?.resultQuoteMortage?.operation.types.fixed.quote *
				result?.simulationData?.resultQuoteMortage.operation.quotes -
			result?.simulationData?.resultQuoteMortage?.operation?.mortgageAmount;
		const total = amount + impuestos + intereses;
		return numeral(total).format('0,0 $');
	};

	if (loading && isMobileOnly) {
		return <Spin indicator={antIcon} className="spin" />;
	}

	if (isMobileOnly && !loading) {
		return (<>
            <div style={{ padding: 16, marginBottom: 200 }}>
                <Row style={{ width: '100%' }}>
                    <Col span={24}>
                        <a onClick={() => rol === 'realestate' ? navigate('/sales-tools/new-requests') : navigate('/calculators')}>
                            <ArrowLeftOutlined style={{ color: '#2F4858', fontSize: 20 }} />
                        </a>
                        <Text
                            style={{
                                color: '#2F4858',
                                fontSize: 16,
                                fontWeight: 600,
                                marginLeft: 16
                            }}>
                            {t('fee.calculator')}
                        </Text>
                    </Col>
                </Row>

                <div>
                    <Form
                        labelAlign="left"
                        colon={false}
                        className="gb-form-profile-headlines-mobile"
                        labelCol={{
                            flex: '50%'
                        }}
                        labelWrap
                        wrapperCol={{
                            flex: '50%'
                        }}
                        layout="horizontal"
                        scrollToFirstError={true}
                        form={form}
                        name="basic"
                        onValuesChange={(values, allValues) => onChangeForm(values, allValues)}
                        initialValues={{
                            price:
                                result && result.simulationData !== undefined
                                    ? result.simulationData.maxBudget
                                    : 180000,
                            savings:
                                result && result.simulationData !== undefined
                                    ? result.simulationData.savings
                                    : 55000,
                            propertyType:
                                result && result.simulationData !== undefined
                                    ? result.simulationData.propertyType
                                    : 'secondHand',
                            province:
                                result && result.simulationData !== undefined
                                    ? result.simulationData.province
                                    : dataProfile && dataProfile.province,
                            rate:
                                result && result.simulationData !== undefined
                                    ? result.simulationData.fixed
                                    : fixed
                        }}>
                        <Row
                            gutter={24}
                            style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginTop: 16
                            }}>
                            <Row style={{ width: '100%' }}>
                                <Col span={24}>
                                    <Form.Item name="price" label={t('budget.price')}>
                                        <InputNumber
                                            defaultValue={''}
                                            suffix={'€'}
                                            min={0}
                                            style={{
                                                height: 40
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name="savings" label={t('fee.calculatorformSavings')}>
                                        <InputNumber
                                            defaultValue={''}
                                            suffix={'€'}
                                            min={0}
                                            style={{
                                                height: 40
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row style={{ width: '100%', marginTop: 32 }}>
                                <Col span={12}>
                                    <Text
                                        style={{
                                            color: '#2F4858',
                                            fontSize: 16,
                                            fontWeight: 400
                                        }}>
                                        {t('fee.calculatorOtherData')}
                                    </Text>
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    {showFields && (
                                        <MinusOutlined
                                            style={{ color: '#02C3CD', fontSize: 16 }}
                                            onClick={() => setShowFields(false)}
                                        />
                                    )}
                                    {!showFields && (
                                        <PlusOutlined
                                            style={{ color: '#02C3CD', fontSize: 16 }}
                                            onClick={() => setShowFields(true)}
                                        />
                                    )}
                                </Col>
                            </Row>

                            {showFields && (
                                <>
                                    <Row style={{ width: '100%', marginTop: 32 }}>
                                        <Col span={24}>
                                            <Form.Item
                                                name="propertyType"
                                                label={
                                                    rol === 'realestate'
                                                        ? t('fee.calculatorFormTypeIn')
                                                        : t('fee.calculatorFormType')
                                                }>
                                                <Select style={{ width: '100%' }}>
                                                    <Option value="newConstruction">{t('form.newBuild')}</Option>
                                                    <Option value="secondHand">{t('form.secondHand')}</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name="province" label={t('viability.provinceViability')}>
                                                <Select style={{ width: '100%' }} showSearch>
                                                    {renderComponent('province').options.map((province) => {
                                                        return (
                                                            <Option key={province.value} value={province.value}>
                                                                {province.label}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            <Row style={{ width: '100%', marginTop: 32 }}>
                                <Col span={24}>
                                    <Form.Item
                                        name="rate"
                                        label={
                                            <>
                                                {t('loan.rate')}
                                                <InfoCircleOutlined
                                                    onClick={() => setRate(true)}
                                                    style={{ color: '#02C3CD', marginLeft: 8 }}
                                                />
                                            </>
                                        }>
                                        <InputNumber
                                            defaultValue={
                                                result && result.simulationData !== undefined
                                                    ? result.simulationData.fixed
                                                    : fixed
                                            }
                                            min={0}
                                            gbType='number'
                                            className='gb-input-new-percent'
                                            style={{
                                                height: 40
                                            }}
                                            onChange={(value) => onChangeFixed(value)}
                                        />
                                        <div style={{ marginTop: 8, textAlign: 'left' }}>
                                            {result && result.quotes !== undefined && (
                                                <Text
                                                    style={{
                                                        color: '#016B71',
                                                        fontSize: 12,
                                                        fontWeight: 400
                                                    }}>
                                                    {t('fee.calculatorEuribor') +
                                                        ' ' +
                                                        numeral(result.quotes.rates.euribor).format('0,0.00 %')}
                                                </Text>
                                            )}
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row style={{ width: '100%', marginTop: 32 }}>
                                <Col span={24}>
                                    <Form.Item
                                        name="term"
                                        label={
                                            <>
                                                {t('loan.term')}
                                                <InfoCircleOutlined
                                                    onClick={() => setTerm(true)}
                                                    style={{ color: '#02C3CD', marginLeft: 8 }}
                                                />
                                            </>
                                        }>
                                        <Slider
                                            defaultValue={
                                                result && result.simulationData !== undefined
                                                    ? result.simulationData.quotes / 12
                                                    : term / 12
                                            }
                                            onChange={(value) => onChangeTerm(value)}
                                            min={5}
                                            max={renderMax()}
                                            tooltip={{
                                                formatter: (value) => `${value} ${t('form.years')}`
                                            }}
                                        />
                                        <div style={{ fontSize: 12, color: '#748EA0', textAlign: 'right' }}>
                                            {quotes && quotes / 12} {t('viability.years')}
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Row>
                    </Form>

                    <Card
                        className="card-second"
                        style={{
                            borderColor: '#02C3CD',
                            borderRadius: 16,
                            width: '100%',
                            marginBottom:
                                result && result.viability && result.viability.excepcionalViability !== undefined
                                    ? isMobileOnly
                                        ? 24
                                        : 190
                                    : 0
                        }}>
                        <div style={{ textAlign: 'center' }}>
                            <Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>
                                {t('m_viability.title')}
                            </Text>
                        </div>
                        <Card className="gb-card-24px" style={{ marginTop: 12 }}>
                            {rol === 'realestate' && (
                                <Row>
                                    {/* span a 10 si hay viabilidad sin no 20*/}
                                    <Col span={location.state === undefined ? 20 : 10} style={{ marginTop: 5 }}>
                                        <Text
                                            style={{
                                                color: '#748EA0',
                                                fontSize: 14,
                                                fontWeight: 400
                                            }}>
                                            {rol === 'realestate'
                                                ? t('calculator.cardTitleInmo1')
                                                : t('calculator.bankViability')}
                                            hg
                                        </Text>
                                    </Col>
                                    <Col span={4}>
                                        {_.isNil(location.state) && (
                                            <Progress
                                                onClick={() => {
                                                   
                                                    navigate(`/calculators/fee/datas`,{
                                                        state: {
                                                            values: { ...form.getFieldsValue() },
                                                            id: result !== undefined ? result?.id : undefined,

                                                            houseType: result?.simulationData?.houseType,
                                                            fixed: result?.simulationData?.fixed,
                                                            quotes: result?.simulationData?.quotes,
                                                            maxBudget: result?.simulationData?.maxBudget,
                                                            savings: result?.simulationData?.savings,
                                                            province: result?.simulationData?.province,
                                                            propertyType: result?.simulationData?.propertyType,
                                                            participants:
                                                                result?.simulationData['owner2'] !== undefined ? '2' : '1'
                                                        }
                                                    });
                                                }}
                                                type="circle"
                                                percent={100}
                                                width={36}
                                                format={() => (
                                                    <img
                                                        src={edit}
                                                        alt="asdf"
                                                        style={{
                                                            width: 20,
                                                            height: 20,
                                                            marginTop: -5
                                                        }}></img>
                                                )}
                                            />
                                        )}

                                        {!_.isNil(location.state) && (
                                            <Progress
                                                type="circle"
                                                strokeColor={'#52C41A'}
                                                trailColor={'#F1F7F8'}
                                                percent={
                                                    ((result?.simulationData?.resultViabilityMortage
                                                        ? result?.simulationData?.resultViabilityMortage.viability
                                                        : 0) *
                                                        100) /
                                                    5
                                                }
                                                width={36}
                                                format={() => (
                                                    <Text
                                                        strong
                                                        style={{
                                                            fontSize: '12px',
                                                            color: '#2F4858',
                                                            marginBottom: '4px',
                                                            display: 'grid'
                                                        }}>
                                                        {result?.simulationData?.resultViabilityMortage
                                                            ? numeral(
                                                                    result?.simulationData?.resultViabilityMortage.viability
                                                              ).format('0.0')
                                                            : numeral().format('0.0')}
                                                    </Text>
                                                )}
                                            />
                                        )}
                                    </Col>
                                    {!_.isNil(location.state) && (
                                        <Col span={10} style={{ textAlign: 'right' }}>
                                            <Text
                                                style={{
                                                    color: '#02C3CD',
                                                    fontSize: 14,
                                                    fontWeight: 400
                                                }}>
                                                {t('onboarding.why')}
                                            </Text>
                                            <InfoCircleOutlined
                                                onClick={() => {
                                                   
                                                    navigate(`/calculators/fee/viability`,{
                                                        state: { result, formValues: resultForm }
                                                    });
                                                }}
                                                style={{ color: '#02C3CD', marginLeft: 8 }}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            )}

                            {rol !== 'realestate' && result && (
                                <ViabilityClientProgress
                                    result={result}
                                    resultForm={resultForm}
                                    form={form}
                                    edit={edit}
                                    fixed={fixed}
                                    quotes={quotes}></ViabilityClientProgress>
                            )}
                        </Card>
                        <Card className="gb-card-24px" style={{ marginTop: 12, background: '#EFFCFC' }}>
                            <Row>
                                <Col span={14}>
                                    <Text
                                        style={{
                                            color: '#748EA0',
                                            fontSize: 14,
                                            fontWeight: 400
                                        }}>
                                        {t('viability.monthlyPayments')}
                                    </Text>
                                </Col>
                                <Col span={10} style={{ textAlign: 'right' }}>
                                    <Text
                                        style={{
                                            color: '#2F4858',
                                            fontSize: 16,
                                            fontWeight: 600
                                        }}>
                                        {result?.simulationData &&
                                            result?.simulationData?.resultQuoteMortage?.operation?.types?.fixed
                                                ?.quote &&
                                            numeral(
                                                result?.simulationData?.resultQuoteMortage?.operation?.types?.fixed?.quote
                                            ).format('0,0 $') + '/mes'}
                                    </Text>
                                </Col>
                            </Row>
                        </Card>

                        <Card className="gb-card-24px" style={{ marginTop: 12 }}>
                            <Row>
                                <Col span={12}>
                                    <Text
                                        style={{
                                            color: '#748EA0',
                                            fontSize: 14,
                                            fontWeight: 400
                                        }}>
                                        {t('onboarding.amountCalculation')}
                                    </Text>
                                </Col>
                                <Col span={2}>
                                    <InfoCircleOutlined
                                        onClick={() => setAmountHp(true)}
                                        style={{
                                            color: '#02C3CD',

                                            fontSize: 16
                                        }}
                                    />
                                </Col>

                                <Col span={10} style={{ textAlign: 'right' }}>
                                    <Text
                                        style={{
                                            color: '#2F4858',
                                            fontSize: 16,
                                            fontWeight: 600
                                        }}>
                                        {result &&
                                            result?.simulationData &&
                                            result?.simulationData?.resultQuoteMortage?.operation?.mortgageAmount !==
                                                undefined &&
                                            numeral(
                                                result?.simulationData?.resultQuoteMortage?.operation?.mortgageAmount +
                                                    2000
                                            ).format('0,0 $')}
                                    </Text>
                                </Col>
                            </Row>

                            <Row style={{ marginTop: 8 }}>
                                <Col span={16}>
                                    <Text
                                        style={{
                                            color: '#748EA0',
                                            fontSize: 14,
                                            fontWeight: 400
                                        }}>
                                        {t('onboarding.percentageFinancing')}
                                    </Text>
                                </Col>

                                <Col span={8} style={{ textAlign: 'right' }}>
                                    <Text
                                        style={{
                                            color: '#2F4858',
                                            fontSize: 16,
                                            fontWeight: 600
                                        }}>
                                        {result &&
                                            result?.simulationData !== undefined &&
                                            (
                                                (result?.simulationData?.resultQuoteMortage?.operation?.mortgageAmount *
                                                    100) /
                                                result?.simulationData?.resultQuoteMortage?.operation?.property
                                            ).toFixed('1') + '%'}
                                    </Text>
                                </Col>
                            </Row>
                        </Card>

                        <Card className="gb-card-24px" style={{ marginTop: 12 }}>
                            <Row>
                                <Col span={12}>
                                    <Text
                                        style={{
                                            color: '#748EA0',
                                            fontSize: 14,
                                            fontWeight: 400
                                        }}>
                                        {rol === 'realestate'
                                            ? t('calculator.bankViability2Inmo')
                                            : t('calculator.bankViability2')}
                                    </Text>
                                </Col>
                                <Col span={2}>
                                    <InfoCircleOutlined
                                        onClick={() => setPay(true)}
                                        style={{
                                            color: '#02C3CD',

                                            fontSize: 16
                                        }}
                                    />
                                </Col>

                                <Col span={10} style={{ textAlign: 'right' }}>
                                    <Text
                                        style={{
                                            color: '#2F4858',
                                            fontSize: 16,
                                            fontWeight: 600
                                        }}>
                                        {result && result?.simulationData !== undefined && renderNumeral(result)}
                                    </Text>
                                </Col>
                            </Row>
                        </Card>
                    </Card>

                    {result &&
                        result.simulationData &&
                        result.simulationData?.resultViabilityMortage?.excepcionalViability !== undefined && (
                            <ComplicatedViability
                                title={
                                    result?.simulationData?.resultViabilityMortage && t(renderTitle(rol, result))
                                }
                                description={
                                    result?.simulationData?.resultViabilityMortage && t(renderDesc(rol, result))
                                }
                                subrogation={true}
                                ajust={true}
                            />
                        )}

                    {dataSummary.filter((data) => data.subtype === 'mortgage').length > 0 && (
                        <Card className="card-financing-second" style={{ marginTop: 40 }}>
                            <Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>
                                {t('calculatorFinancing.mortgage')}
                            </Text>
                            <div>
                                <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 500 }}>
                                    {t('calculatorFinancing.mortgage1')}
                                </Text>
                                &nbsp;
                                <Text
                                    style={{
                                        color: '#02C3CD',
                                        fontSize: 14,
                                        fontWeight: 500,
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => navigate(locationTmpText)}>
                                    {t('calculatorFinancing.mortgage2')}
                                </Text>
                                &nbsp;
                                <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 500 }}>
                                    {t('calculatorFinancing.mortgage3')}
                                </Text>
                            </div>
                        </Card>
                    )}

                    <Card
                        className="cardButtonStyle"
                        style={{
                            left: 0,
                            backgroundColor: '#FAFEFE'
                        }}>
                        <Button
                            id="gb-mortgage-buy-situation-mobile"
                            className="button-primary-gibobs-inmo"
                            type="primary"
                            style={{
                                fontSize: 16,
                                width: '100%',
                                height: 48,
                                fontWeight: 600
                            }}
                            onClick={() => {
                                if (result) {
                                    if (result?.simulationData?.resultViabilityMortage !== undefined) {
                                        setFinish(true);
                                    } else if (result?.simulationData?.resultViabilityMortage === undefined) {
                                       
                                        navigate(`/calculators/fee/datas`,{
                                            state: {
                                                fixed,
                                                quotes,
                                                id: result !== undefined ? result.simulationData.id : undefined,

                                                values: { ...form.getFieldsValue() },
                                                houseType: result?.simulationData?.houseType,
                                                maxBudget: result?.simulationData?.maxBudget,
                                                savings: result?.simulationData?.savings,
                                                province: result?.simulationData?.province,
                                                propertyType: result?.simulationData?.propertyType,
                                                participants: result?.simulationData['owner2'] !== undefined ? '2' : '1'
                                            }
                                        });
                                    }
                                } else {
                                   
                                    navigate(`/calculators/fee/datas`,{
                                        state: {
                                            values: { ...form.getFieldsValue() },
                                            fixed,
                                            quotes,
                                            id: result !== undefined ? result.simulation.id : undefined
                                        }
                                    });
                                }
                            }}
                            disabled={
                                result?.simulationData?.resultViabilityMortage?.excepcionalViability !==
                                    undefined ||
                                dataSummary.filter((data) => data.subtype === 'mortgage').length > 0
                                    ? true
                                    : false
                            }>
                            {t(renderLabelButton(rol, result))}
                        </Button>
                    </Card>
                </div>
            </div>
            <ModalWithoutButtons
                key={'rate'}
                visible={rate}
                onCreate={() => setRate(false)}
                onCancel={() => setRate(false)}
                title={t('loan.rateCurrent')}
                text={t('calculator.modal1')}
            />
            <ModalWithoutButtons
                key={'term'}
                visible={term}
                onCreate={() => setTerm(false)}
                onCancel={() => setTerm(false)}
                title={t('scoring.term')}
                text={t('calculator.modal2')}
            />
            <MortgageAmountModal
                tmp={'amountHp'}
                visible={amountHp}
                result={result}
                onCancel={() => setAmountHp(false)}
            />
            <MortgageAmountModal
                tmp={'pay'}
                visible={pay}
                result={result}
                onCancel={() => setPay(false)}
            />
            <Modal
                className="modal-promoter ModalCommon"
                okButtonProps={{
                    className: 'button-primari-gibobs',
                    style: { width: '94%' }
                }}
                open={finish}
                width={328}
                title={<img src={calculatorImage} alt="financing" width={'100%'} height="90px" />}
                cancelButtonProps={{ style: { display: 'none' } }}
                onCancel={() => setFinish(false)}
                onOk={() => sendFinish()}>
                <div style={{ marginBottom: 0, marginTop: 30 }}>
                    {rol === 'realestate' ? (
                        <Text strong>{t('modal6.titleGood') + ' ' + dataProfile.name}</Text>
                    ) : (
                        <div
                            style={{ fontWeight: 600 }}
                            dangerouslySetInnerHTML={{
                                __html: t('calculator.modalQuotaCalculatorClient', { name: dataProfile.name })
                            }}
                        />
                    )}
                </div>
                <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                    {t('calculator.modalFinish')}
                </Text>
            </Modal>
        </>);
	} else {
		if (loading) {
			return <Spin indicator={antIcon} className="spin" />;
		} else {
			return <CalculatorsFeeDesktop></CalculatorsFeeDesktop>;
		}
	}
};

export default CalculatorsFeeMobileAndDesktop;
