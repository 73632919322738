import React from 'react';
import { useTranslation } from 'react-i18next';
import googleIcon from '../../assets/icons/googleIcon.svg';
import trustpilotIcon from '../../assets/icons/TrustpilotIcon.svg';
import starsGoogle from '../../assets/icons/stars-google.svg';
import starsTrustpilot from '../../assets/icons/stars-trustpilot.svg';

const ReviewsCustomer = ({ dashboard, dashboardMobile }) => {
	const { t } = useTranslation();

	const differentReviews = (icon, rate, reviews, link, stars) => {
		return (
			<div style={{ display: 'flex', alignItems: 'center', flexDirection: !dashboard && 'column' }}>
				<img alt={`${icon}`} src={icon} height={18} />
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						columnGap: 4,
						marginTop: !dashboard && 4,
						marginBottom: !dashboard && 1
					}}>
					<img alt={`${stars}`} src={stars} height={16} />

					<div className="rating-styles">{rate}</div>
					{dashboard && (
						<div className="customer-feedback" style={{ margin: '0px 16px' }}>
							{t('onboarding.excellentReview')}
						</div>
					)}
				</div>
				<div style={{ display: dashboardMobile && 'flex' }}>
					<a href={link} target="_blank">
						<div className="reviews-styles">{reviews}</div>
					</a>
					{dashboardMobile && (
						<div className="customer-feedback" style={{ margin: '0px 16px' }}>
							{t('onboarding.excellentReview')}
						</div>
					)}
				</div>
			</div>
		);
	};
	return (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: dashboard ? 'center' : 'space-around',
					flexWrap: 'wrap',
					columnGap: dashboard && 80,
					rowGap: 10
				}}>
				{differentReviews(
					googleIcon,
					t('reviews.numberStarsGoogle'),
					t('reviews.quantityGoogle'),
					'https://www.google.com/search?q=gibobs+all+banks&rlz=1C5CHFA_enES1002ES1002&oq=gibob[…]99i512j0i20i263i512j69i60l3.2548j0j7&sourceid=chrome&ie=UTF-8',
					starsGoogle
				)}
				{differentReviews(
					trustpilotIcon,
					t('reviews.numberStarsTrustPilot'),
					t('reviews.quantityTrustpilot'),
					'https://es.trustpilot.com/review/www.gibobs.com',
					starsTrustpilot
				)}
			</div>
		</>
	);
};

export default ReviewsCustomer;
