import React, { useState } from "react";
import { Form, Row, Col, Select, Input, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import { isMobile } from "react-device-detect";
import DownOutlinedMobile from "../../assets/icons/DownOutlinedMobile.svg";
import { ProvinceSelector } from "afrodita";

const { Option } = Select;

const Ecostate = ({ budget }) => {
  const { t } = useTranslation();

  const [valueClientMortgageGreen, ] = useState(
    budget ? true : false
  );

  return (
    <>
      {valueClientMortgageGreen && (
        <Row
          gutter={24}
          style={{
            // marginLeft: "auto",
            // marginRight: "auto",
            marginBottom: isMobile ? 50 : 10,
            marginTop: isMobile ? 24 : 40,
          }}
        >
          <Col lg={6} xs={24}>
            <Form.Item
              name="ecoHouseType"
              label={t("budget.typeHome")}
              rules={[
                {
                  required: true,
                },
              ]}
              style={{
                width: "100%",
              }}
            >
              <Select
                suffixIcon={
                  <img src={DownOutlinedMobile} alt="DownOutlinedMobile" />
                }
                style={{ width: "100%" }}
                placeholder={t("form.select")}
              >
                <Option value="individual">{t("budget.individual")}</Option>
                <Option value="adosado">{t("budget.townhouse")}</Option>
                <Option value="pareado">{t("budget.couplet")}</Option>
                <Option value="casaRustica">{t("budget.rusticHouse")}</Option>
                <Option value="piso"> {t("budget.house")}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item
              label={t("form.totalFloor")}
              name="floorSurface"
              rules={[
                {
                  type: "number",
                  min: 0,
                  required: true,
                },
              ]}
            >
              <InputNumber
                step={100}
                style={{
                  width: "100%",
                }}
                placeholder={t("m2")}
                defaultValue=""
              />
            </Form.Item>
          </Col>

          <Col lg={6} xs={24}>
            <Form.Item
              name="floorNumber"
              label={t("budget.Nfloor")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                style={{
                  width: "100%",
                }}
                defaultValue={""}
                placeholder={"0"}
              />
            </Form.Item>
          </Col>

          <Col lg={6} xs={24}>
            <Form.Item
              name="rooms"
              label={t("budget.room")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                style={{
                  width: "100%",
                }}
                defaultValue={""}
                placeholder={"0"}
              />
            </Form.Item>
          </Col>

          <Col lg={6} xs={24}>
            <Form.Item
              label={t("form.provinces")}
              name="province"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <ProvinceSelector
                 placeholder={t("form.province")}
                 style={{ width: "100%" }} />
              {/* <Select
                suffixIcon={
                  <img src={DownOutlinedMobile} alt="DownOutlinedMobile" />
                }
                showSearch
                placeholder={t("form.province")}
                style={{ width: "100%" }}
              >
                {renderComponent("province").options.map((province) => {
                  return (
                    <Option key={province.value} value={province.value}>
                      {province.label}
                    </Option>
                  );
                })}
              </Select> */}
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item label={t("form.population")} name="city">
              <Input
                showSearch
                placeholder={t("form.population")}
                style={{ width: "100%" }}
              ></Input>
            </Form.Item>
          </Col>

          <Col lg={6} xs={24}>
            <Form.Item
              name="zip"
              label={t("m_register.postalCode")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{
                  width: "100%",
                }}
                defaultValue={""}
                placeholder={"0"}
              />
            </Form.Item>
          </Col>

          <Col lg={6} xs={24}>
            <Form.Item
              label={t("form.maxBudgetNewRequest")}
              name="maxBudget"
              rules={[
                {
                  type: "number",
                  min: 10000,
                  max: 2500000000000000,
                  required: true,
                },
              ]}
            >
              <InputNumber
                step={100}
                style={{
                  width: "100%",
                }}
                placeholder={t("form.maxBudgetNewRequest")}
                defaultValue=""
                formatter={(value) => numeral(value).format("0,0 $")}
                parser={(value) => numeral(value).value()}
              />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item
              name="address"
              label={t("budget.direction")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{
                  width: "100%",
                }}
                defaultValue={""}
                placeholder={"calle sin numero..."}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Ecostate;
