import React, { useEffect, useState } from "react";
import { Col, Row, Button, Tooltip, Progress } from "antd";
import moment from "moment";
import { notification } from "antd";
import axios from "axios";
import env from "../../../environment";
import { useSelector } from "react-redux";

function DocumentsWidget() {
  const [dataWidgets, setDataWidgets] = useState([]);
  const userToken = useSelector((state) => state.auth.token);

  useEffect(() => {
    axios
      .get(
        `${env.api.url}/v1/documents/request-operation-documentation/a006N000000x6rUQAQ`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((response) => {
        setDataWidgets(response.data.data.documents);
        {
          !response.data.success &&
            notification.error({
              message: `Notification error`,
              description:
                "An error occurred while retrieving data on this page",
              placement: "topRight",
            });
        }
      })
      .catch((error) => {
        console.log(error)
      });
  }, [userToken]);

  return (
    <div>
      <div style={{ marginBottom: "30px" }}>
        <Tooltip title="circleProgress">
          <Progress
            style={{ marginLeft: "-24px" }}
            strokeColor={{
              "0%": "#CAE9F1",
              "100%": "#449CA4",
            }}
            percent={80}
            type="circle"
            trailColor={{ "0%": "#C7CCD8", "100%": "#449CA4" }}
          />
        </Tooltip>
        <div
          className="info"
          style={{
            width: "55px",
            height: "25px",
            float: "right",
            marginBottom: "30px",
          }}
        >
          <div
            className="infoIncome"
            style={{
              width: "11px",
              height: "11px",
              marginBottom: "5px",
              backgroundColor: "#C7CCD8",
            }}
          ></div>
          <div
            className="infoExpenses"
            style={{
              width: "11px",
              height: "11px",
              marginTop: "10px",
              backgroundColor: "#449CA4",
            }}
          ></div>
          <div style={{ marginTop: "-40px" }}>
            <p
              style={{
                marginLeft: "20px",
                marginTop: "5px",
                marginBottom: "-1px",
              }}
            >
              Ingresos
            </p>
            <p className="infoExpensesT" style={{ marginLeft: "20px" }}>
              Gastos
            </p>
          </div>
        </div>
      </div>
      <Button
        style={{
          backgroundColor: "#2A6977",
          color: "#FFFFFF",
          padding: "0 20px",
          height: "30px",
          marginBottom: "20px",
          marginLeft: "20%",
        }}
        shape="round"
      >
        VER TODOS
      </Button>

      <div className="barraStyle" style={{ height: "150px" }}>
        {dataWidgets
          ? dataWidgets.map((data) => {
             
                var dateUpload = 0;
                if (data.completed.owner1 !== undefined) {
                   dateUpload = moment(data.completed.owner1.updatedAt).format(
                    "L"
                  );
                }
             
              return (
                <>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      color: "#B3B3B3",
                      textAlign: "left",
                      marginBottom: "5px",
                    }}
                    key={data.id}
                  >
                    <Col
                      span={12}
                      style={{
                        fontSize: "11px",
                        marginTop: "5px",
                      }}
                    >
                      {data.title}
                    </Col>
                    <Col span={12}>{dateUpload} </Col>
                  </Row>
                </>
              );
            })
          : null}
      </div>
    </div>
  );
}

export default DocumentsWidget;
