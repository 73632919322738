import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Select,
  Input,
  Col,
  Row,
  Button,
  Upload,
  notification,
} from "antd";
import { useTranslation } from "react-i18next";
import DownOutlinedMobile from "../../../assets/icons/DownOutlinedMobile.svg";
import axios from "axios";
import env from "../../../environment";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { renderSelectOptions } from "../ProvincesAndMunicipe";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import { isMobileOnly } from 'react-device-detect';
import { ProvinceSelector } from "afrodita";

const { Option } = Select;

const LocationMarker = ({ position, title, onChange }) => {
  useMapEvents({
    click(e) {
      onChange([e.latlng.lat, e.latlng.lng]);
    },
  });

  return position === null ? null : (
    <Marker position={position}>
      <Popup>{title}</Popup>
    </Marker>
  );
};

function ModalSaveProject({
  visible,
  save,
  onCancel,
  onCreate,
  width,
  initialValuesData,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const params = useParams();
  const operationId =
    initialValuesData && initialValuesData.operationId
      ? initialValuesData.operationId
      : params.operationId;
  const [fileName, setFileName] = useState("");
  const [position, setPosition] = useState([]);
  const [town, setTown] = useState(initialValuesData && initialValuesData.town);
  const [saveFileDataField, setSaveFileDataField] = useState([]);
  const [province, setProvince] = useState(
    initialValuesData && initialValuesData.province
  );
  const userToken = useSelector((state) => state.auth.token);

  const saveFileData = (file, name) => {
    const documentFile = saveFileDataField;
    documentFile.push({
      file,
      fileName: name,
    });
    setSaveFileDataField(documentFile);
  };

  useEffect(async () => {
    if (province !== undefined) {
      const arrayProvince = renderSelectOptions("province").options.filter(
        (data) => data.value === province
      );
      arrayProvince &&
        arrayProvince[0] &&
        setPosition(arrayProvince[0].location);
    }
  }, [province]);

  useEffect(async () => {
    if (town !== undefined) {
      const arrayProvince = renderSelectOptions("town").options.filter(
        (data) => data.name === town
      );
      arrayProvince &&
        arrayProvince[0] &&
        setPosition(arrayProvince[0].location);
    }
  }, [town]);

  const uploadProps = {
    multiple: true,
    beforeUpload: (file) => {
      if (file.size > 8000000) {
        notification.error({
          message: "Error: archivo demasiado grande",
          description:
            "Su documento ocupa mas de 8mb. No se pueden enviar archivos de más de 8mb",
        });

        setFileName("Error: archivo demasiado grande");

        return;
      }

      setFileName(file.name);
      return new Promise(() => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64File = reader.result.split(",")[1];
          saveFileData(base64File, file.name);
        };
      });
    },
  };
  const saveProject = (values) => {
    const objvalues = {
      projectName: values.projectName,
      promoterName: values.promoterName,
      province: values.province,
      town: values.town,
      locationMap: { x: position && position[0], y: position && position[1] },
      projectImageId:
        saveFileDataField &&
        saveFileDataField.length !== 0 &&
        saveFileDataField[0].file,
    };
    if (objvalues.town === undefined) {
      delete objvalues.town;
    } else {
      objvalues.town = objvalues.town;
    }
    if (objvalues.promoterName === undefined) {
      delete objvalues.promoterName;
    }
    if (objvalues.projectImageId === false) {
      delete objvalues.projectImageId;
    }

    axios
      .post(
        `${env.api.url}/promoters/promoter-projects/project-data/${operationId}`,
        objvalues,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then(() => {
        onCreate(values);
        form.resetFields();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateMessages = {
    required: "${label}" + " " + t("form.formRequired"),
    types: {
      email: t("form.emailValidated"),
      number: "${label} is not a valid number!",
    },
    number: {
      range: t("form.maxBudgetNewRequestValidated") + "${min}",
    },
  };

  return (
    (<Modal
      className="ModalCommon modal-promoter-footer modal-promoter-header"
      cancelButtonProps={{
        className: "button-secundary-gibobs",
        style: { textAlign: "center", width: 120, display: isMobileOnly && 'none'},
      }}
      okButtonProps={{
        className: "button-primari-gibobs",
        style: { textAlign: "center", width: 120 },
      }}
      title={
        save === true
          ? t("promoterLoan.saveProject")
          : initialValuesData !== undefined
            ? t("promoterLoan.editProject")
            : t("promoterLoan.saveProject")
      }
      open={visible}
      okText={t("form.save")}
      cancelText={t("offers.cancel")}
      width={width}
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            saveProject(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        className={"gb-form-profile-headlines-mobile"}
        layout="vertical"
        name="Contacto"
        form={form}
        validateMessages={validateMessages}
        initialValues={initialValuesData !== undefined ? initialValuesData : {}}
      >
        <Row gutter={24}>
          <Col span={isMobileOnly ? 24 : 12}>
            <Form.Item
              name="projectName"
              label={t("promoterLoan.projectName")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder={t("promoterLoan.projectName")} />
            </Form.Item>
          </Col>
          <Col span={isMobileOnly ? 24 : 12}>
            <Form.Item
              name="promoterName"
              label={t("promoterLoan.promoterName")}
            >
              <Input placeholder={t("promoterLoan.promoterName")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="field"
              label={t("promoterLoan.projectImage")}
              style={{ width: "100%" }}
            >
              <Upload {...uploadProps} style={{ width: "100%" }} className={ isMobileOnly ? "upload-mobile" : null}>
                <Button
                  type="dashed"
                  className="button-secundary-gibobs"
                  style={{ width: isMobileOnly ? "100%" : "610px" }}
                >
                  {t("document.attach")}
                </Button>
                <small>{fileName}</small>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={24}>
            <div className="title-project-data" style={{ marginBottom: 16 }}>
              {t("promoterLoan.locationTitle")}
            </div>
          </Col>
          <Col span={isMobileOnly ? 24 : 12}>
            <Form.Item
              label={t("form.provinces")}
              name="province"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <ProvinceSelector 
                disabled={
                  initialValuesData && initialValuesData.province !== undefined
                    ? true
                    : false
                }
                placeholder={t("form.provinces")}
                style={{ width: "100%" }}
                location={true}
                onChange={(e) => setProvince(e)} />
            </Form.Item>
          </Col>
          <Col span={isMobileOnly ? 24 : 12}>
            <Form.Item label={t("promoterLoan.municipality")} name="town">
              <Select
                suffixIcon={
                  <img src={DownOutlinedMobile} alt="DownOutlinedMobile" />
                }
                showSearch
                placeholder={t("promoterLoan.municipality")}
                style={{ width: "100%" }}
                onChange={(e) => setTown(e)}
              >
                {renderSelectOptions("town")
                  .options.filter((data) => data.province === province)
                  .sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((town) => {
                    return (
                      <Option key={town.id} value={town.name}>
                        {town.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="locationMap"
              label={t("promoterLoan.indicatesLocation")}
            >
              {position && position.length !== 0 && (
                <MapContainer
                  center={position}
                  zoom={10}
                  scrollWheelZoom={false}
                  style={{ minHeight: isMobileOnly ? "136px" : "300px" }}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <LocationMarker
                    title={
                      initialValuesData && initialValuesData.projectName
                        ? initialValuesData.projectName
                        : "NOMBRE PROMOCIÓN"
                    }
                    position={position}
                    onChange={(position) => setPosition(position)}
                  />
                </MapContainer>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>)
  );
}

export default ModalSaveProject;
