import React from 'react';
import { Card, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import HomeWater from '../../Scoring/HomeWater';
import propertyIcon from '../../../../assets/loans/propertyIcon.svg';
import { useNavigate, useParams } from 'react-router-dom';
import chebron from '../../../../assets/loans/chebron.png';
import { isMobileOnly } from 'react-device-detect';

const { Text } = Typography;

const PropertyWidget = ({ percent }) => {
	const { t } = useTranslation();
	let { loanId } = useParams();
	const navigate = useNavigate();;

	return (
		<>
			<Card
				className="gcardMovile card-heigth-loan"
				style={{ marginTop: isMobileOnly ? 12 : 16, height: 'calc(100% - 36px)' }}>
				<div
					style={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: isNaN(percent) && 'space-between'
					}}>
					{isNaN(percent) && (
						<div style={{ marginBottom: 16, textAlign: 'center' }}>
							<img src={propertyIcon} alt="propertyIcon" />
						</div>
					)}
					<div>
						<Text
							style={{
								fontSize: 14,
								color: '#748EA0',
								fontWeight: 600
							}}>
							{t('loan.properTytitlte2')}
						</Text>
					</div>
					{!isNaN(percent) && (
						<div
							style={{
								height: '100%',
								display: 'flex',
								alignItems: 'center',
								alignSelf: 'center'
							}}>
							<div
								style={{
									display: 'flex',
									alignItems: 'flex-end',
									justifyContent: 'center',
									marginTop: isMobileOnly && 24
								}}>
								<Text
									style={{
										color: '#2F4858',
										fontSize: 20,
										fontWeight: 600
									}}>
									{parseInt(percent) + '%'}
								</Text>
								<div
									style={{
										width: 91,
										height: 91
									}}>
									<HomeWater percent={percent / 100} />
								</div>
							</div>
						</div>
					)}
					{isNaN(percent) && (
						<div
							style={{
								marginTop: 16,
								display: 'flex',
								cursor: 'pointer',
								alignItems: 'center',
								justifyContent: 'right'
							}}>
							<div
								onClick={() =>
									navigate(
										`/my-financial-profile/credits/edit/form/${loanId}`,{
										state: { input: 'initialHousePrice' }
									})
								}
								style={{
									fontSize: 14,
									color: '#02C3CD',
									fontWeight: 500
								}}>
								{t('m_common.knowMore')}
							</div>
							<img
								src={chebron}
								alt="asdf"
								style={{
									width: 16,
									height: 16,
									marginTop: 0,
									marginLeft: 6
								}}></img>
						</div>
					)}
				</div>
			</Card>
		</>
	);
};

export default PropertyWidget;
