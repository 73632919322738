import React from "react";
import { Layout, Button } from "antd";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { isMobileOnly } from "react-device-detect";
import BreadcrumbsParticular from "../HerramientasVenta/FollowUpOperations/Components/BreadcrumbsParticular";
import { getAnalytics, logEvent } from "firebase/analytics";

const LayoutProducts = ({ name }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const userOperations = useSelector((state) => state.auth.operations);
  const analytics = getAnalytics();

  const styleTextTab = {
    fontSize: 14,
    fontWeight: 600,
    color: "white",
    backgroundColor: "#02C3CD",
    borderRadius: "4px",
    border: "none",
    height: 32,
    textAlign: "center",
  };

  return (
    <Layout>
      {!location.pathname.includes("/operations/") && (
        <div
          style={{
            marginLeft: 0,
            marginRight: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            className="newSubmenusTabs"
            style={{ width: isMobileOnly ? "calc(100% - 10px)" : "auto" }}
          >
            <div
              style={{
                background: "#EFFCFC",
                borderRadius: 5,
                borderBottom: "none",
                borderBottomColor: "white",
                display: "flex",
                alignItems: "center",
                overflowY: "scroll",
                overflowX: "none",
                padding: "8px 0",
              }}
              className="scroolHorizontal"
              selectedKeys={[`${location.pathname}${location.search}`]}
              mode="horizontal"
            >
              <NavLink className="buttonOperations" to="/products">
                <Button
                  className="buttonOperations"
                  style={
                    location.pathname === "/products" ? styleTextTab : undefined
                  }
                  onClickCapture={() =>
                    logEvent(analytics, "screen_view", {
                      screen_name: "tabs.newProducts"
                    })
                  }
                
                >
                  {t("createOperations.NewProducts")}
                </Button>
              </NavLink>
              {userOperations && userOperations.length > 0 ? (
                <NavLink to="/products-under-contract">
                  <Button
                    className="buttonOperations"
                    style={
                      location.pathname === "/products-under-contract" ||
                      location.pathname.includes("/operations/")
                        ? styleTextTab
                        : undefined
                    }
                    onClickCapture={() =>
                      logEvent(analytics, "screen_view", {
                        screen_name: "tabs.activeProducts"
                      })
                    }
                  >
                    {t("createOperations.productsUnderContract")}
                  </Button>
                </NavLink>
              ) : // </Menu.Item>
              null}
              {/* <Menu.Item
              style={{ border: "none", margin: "0 4px" }}
              key="/operations-closed"
            > */}
              {/* <NavLink to="/operations-closed">
              <Button
                className="buttonOperations"
                style={
                  location.pathname === "/operations-closed"
                    ? styleTextTab
                    : undefined
                }
              >
                {t("init.finishOperations")}
              </Button>
            </NavLink> */}
              {/* </Menu.Item> */}
            </div>
          </div>
        </div>
      )}
      {location.pathname.includes("/operations/") && (
        <div style={{ marginTop: 14 }}>
          <BreadcrumbsParticular
            name={name}
            video={
              location.pathname.includes("/basic") ||
              location.pathname.includes("/full") ||
              location.pathname.includes("/documents")
            }
            returnPage={"/products-under-contract"}
          />
        </div>
      )}
    </Layout>
  );
};

export default LayoutProducts;
