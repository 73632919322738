import React from "react";
import { Steps } from "antd";
import moment from "moment";
import checkIcon from "../../../../assets/icons/check.svg";

const { Step } = Steps;

const StepsHorizontal = ({
  data,
  status,
  width,
  formalization,
  denied,
  approved,
  promoter,
}) => {
  return (
    <div>
      <Steps
        // size="small"
        className={"gb-steps-styles-common steps-styles-banks"}
        current={status}
        style={{ width: width, margin: promoter && "auto" }}
        direction={"horizontal"}
        responsive={false}
      >
        {data.map((infoSteps, i) => {
          return (
            <Step
              style={{ borderColor: "#52c41a" }}
              key={i}
              icon={
                i == 2 &&
                approved && (
                  <img
                    src={checkIcon}
                    alt="check"
                    height={32}
                    style={{ marginBottom: 4 }}
                  />
                )
              }
              title=""
              status={i === 2 && denied && "error"}
              description={
                <div>
                  <div
                    style={{
                      marginLeft: formalization ? -16 : -24,
                      fontSize: 12,
                      color: i == 2 && approved && "#52C41A",
                    }}
                  >
                    {i === 2 && denied ? "Denegada" : infoSteps.name}
                  </div>
                  <div
                    className="step-date"
                    style={{ marginLeft: formalization ? -16 : "-14px" }}
                  >
                    {infoSteps.date !== null &&
                      infoSteps.date !== undefined &&
                      moment(infoSteps.date).format("DD/MM/YYYY")}
                  </div>
                </div>
              }
              className={!formalization ? "steps-banks-fein" : "steps-banks"}
            />
          );
        })}
      </Steps>
    </div>
  );
};

export default StepsHorizontal;
