import React, { useEffect, useState } from "react";
import {
    useNavigate,
    useLocation,
} from "react-router-dom";
import {
    Layout,
    Typography,
    Card,
    Row,
    Col,
   
    Button,
   
    Modal,
    Form,
    Input,
    
} from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
   
    DoubleRightOutlined,
 
    InfoCircleOutlined,
   
    ArrowLeftOutlined,
    ExclamationCircleFilled,
    ShareAltOutlined,
    CheckOutlined,
    CloseOutlined,
} from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import env from "../../environment";
import { RWebShare } from "react-web-share";



const { Content } = Layout;
const { Text } = Typography;

const PlainFriendDataMobile = ({ valuesData }) => {
    const navigate = useNavigate();;
    const location = useLocation();
    const { t } = useTranslation();
    const [showAccount, setShowAccount] = useState(false)
    const [form] = Form.useForm();
    const mortgageId = useSelector((state) => state.auth.mortgageId);
    const userToken = useSelector((state) => state.auth.token);
    const [valuesMortgage, setValuesMortgage] = useState(undefined)
    const [dataPersonal, setDataPersonal] = useState([])
    const [reward, setReward] = useState(0)
    let temp = ""
    const [signed, setSigned] = useState(undefined)
    const [noSigned, setNoSigned] = useState(undefined)

    const [visibleCode, setVisibleCode] = useState(false)

    const borderTop = (
        <div
            style={{
                borderTop: "1px solid #F1F7F8",
                width: "100%",
                marginBottom: 12,
                overflow: "hidden",
            }}
        ></div>
    );


    useEffect(() => {

        getProfile()
        getReward()

        setSigned(valuesData.find((item) => item.status === "finish" && item.stage === "finish"))
        setNoSigned(valuesData.find((item) => item.status === "finish" && item.stage !== "finish"))

    }, [])

    const getProfile = () => {
        axios
            .get(`${env.api.url}/v1/user/profile`, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            .then((response) => {
                setDataPersonal(response.data.data.profile);
            });
    }


    const getReward = () => {
        axios
            .get(`${env.api.url}/v1/friends/my-rewards`, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            .then((response) => {

                setReward(response.data.data);

            });
    };

    const showAmigobsDetails = () => {
        navigate("/friendInfo/details/info")
    }

    const backToHome = () => {
        navigate("/friendInfo")
    }

    const showModalBankAccount = () => {
        setShowAccount(!showAccount)
    }

    const saveDataAccountBank = (value, type) => {
        axios
            .post(
                `${env.api.url}/v1/user/set-bank-account`,
                { bankAccount: value, clientOrAgent: type, operationId: mortgageId },

                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            
    };

    const showDetailsOperations = (item) => {
        navigate("/friendInfo/operations-details")
        setValuesMortgage(item)
    }

    const renderMessage = (stage) => {
        if (stage === "basic") {
            temp = "¡Hola! ¿Aún no has contactado con el equipo de gibobs allbanks para que te consigan una hipoteca a tu medida? Además, cuando elijas tu oferta y firmes tu hipoteca, ¡nos llevaremos 50 € cada uno! No esperes más y entra en tu www.gibobs.com para continuar con tu solicitud."

        } else if (stage === "full" || stage === "documents" || stage === "oferts") {
            temp = "Ey, ¿qué tal? Acabo de ver que tu solicitud de hipoteca con gibobs está en la etapa " +
                stage +
                ". ¡Ya estás cada vez más cerca! Entra en www.gibobs.com y avanza en el proceso para que podamos ganar nuestra recompensa y darnos un capricho."
        } else if (stage === "banks" || stage === "analysis") {
            temp = "¡Qué poco falta para que el equipo de gibobs te consiga la mejor hipoteca del mercado y para llevarnos 50 € cada uno! He visto que están trabajando para encontrarte la que tenga las mejores condiciones para ti, así que ve preparando la mudanza a tu nueva casa. Entra en www.gibobs.com y comprueba el estado de tu operación."
        } else {
            temp = "¡Enhorabuena! Acabo de ver que la solicitud de tu hipoteca con gibobs está en la recta final, ¡la mejor forma de cuidar de tu salud financiera! Cuando firmes tu hipoteca, recuerda avisar a tu analista para que puedan ingresarnos nuestros 50 €."
        }
    }


    const saveDataCodeLink = (values) => {
        axios
            .post(
                `${env.api.url}/v1/friends/customize-name`,
                { friendName: values },

                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            .then(() => {
                getProfile();
            });
    }

    const showModalLinkCode = () => {
        setVisibleCode(!visibleCode)
    }




    return (<>
        {location.pathname === "/friendInfo" && 
            <div>
                <Content style={{ padding: 16 }}>
                    <div style={{ width: "100%" }}>
                        <Text style={{ color: "#2F4858", fontSize: 16, fontWeight: 600 }}>{t("header.plain")} </Text>
                        <a onClick={() => showAmigobsDetails()}> <InfoCircleOutlined
                            style={{ color: "#02C3CD", fontSize: 20, float: "right" }}
                        /></a>
                    </div>
                    {dataPersonal && dataPersonal.bankAccount === undefined || dataPersonal.bankAccount === null &&
                        <Card className="card-second" style={{ background: "#FEF0D3", borderRadius: 4, border: "1px solid #FBC14F", marginTop: 16 }}>
                            <Row gutter={16}>
                                <Col lg={3} xs={3}>
                                    <ExclamationCircleFilled style={{ color: "#FAAD14", fontSize: 20, marginTop: 30 }} />
                                </Col>
                                <Col lg={21} xs={21}>

                                    <Text style={{ fontSize: 14, fontWeight: 400, color: "#2F4858" }}>{t("amigobs.descBank")}&nbsp;</Text>
                                    <a onClick={() => showModalBankAccount()}> <Text style={{ fontSize: 14, fontWeight: 600, color: "#FAAD14" }}>{t("amigobs.descBank2")}</Text></a>
                                </Col>

                            </Row>
                        </Card>
                    }

                    {dataPersonal &&
                        <Card className="card-second" style={{ background: "#EFFCFC", borderRadius: 4, border: "1px solid #C0F0F3", marginTop: 16 }}>
                            <Row gutter={16}>
                                <Col lg={19} xs={19}>

                                    <Text style={{ fontSize: 14, fontWeight: 500, color: "#2F4858" }}>{t("amigobs.code")}</Text>&nbsp;
                                    <Text style={{ fontSize: 14, fontWeight: 400, color: "#748EA0" }}>{dataPersonal.friendName}</Text>
                                </Col>
                                <Col lg={5} xs={5} style={{ width: "100%" }}>
                                    <a onClick={() => showModalLinkCode()}> <Text style={{ fontSize: 14, fontWeight: 500, color: "#02C3CD", marginLeft: 7 }}>{t("form.edit")}</Text></a>
                                </Col>

                            </Row>
                        </Card>
                    }

                    <div style={{ marginTop: 24, textAlign: "center" }}>
                        <Text style={{ color: "#2F4858", fontSize: 16, fontWeight: 600 }}>{t("amigobs.Congratulations")} </Text>
                        <Text style={{ color: "#02C3CD", fontSize: 16, fontWeight: 600 }}>{reward.paid + "€"} </Text>
                        <div style={{ marginTop: 4 }}>
                            <Text style={{ color: "#2F4858", fontSize: 14, fontWeight: 400 }}>{t("amigobs.receive")}</Text> <Text style={{ color: "#02C3CD", fontSize: 14, fontWeight: 400 }}>{reward.pending + "€"} </Text>
                            <Text style={{ color: "#2F4858", fontSize: 14, fontWeight: 400 }}>{t("amigobs.received")}</Text> <Text style={{ color: "#02C3CD", fontSize: 14, fontWeight: 400 }}>{reward.paid + "€"} </Text>

                        </div>


                    </div>

                    <div style={{ marginTop: 32 }}>
                        <Text style={{ color: "#2F4858", fontSize: 12, fontWeight: 400 }}>{t("amigobs.mortgagesCurrents").toLocaleUpperCase()} </Text>
                        {valuesData.map((item) => {
                            if (item.status === "pending_declarative" || item.status === "active") {


                                return (
                                    <Card className="CardViability" style={{ background: "#FFFFFF", borderRadius: 4, boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)", marginTop: 16 }}>
                                        <Row gutter={16}>
                                            <Col lg={3} xs={3}>
                                                <DoubleRightOutlined style={{ color: "#52C41A", fontSize: 20, marginTop: 4 }} />
                                            </Col>
                                            <Col lg={21} xs={21}>

                                                <Text style={{ fontSize: 16, fontWeight: 600, color: "#2F4858" }}>{item.client}</Text>
                                            </Col>

                                        </Row>
                                        {renderMessage(item.stage)}
                                        <Row gutter={16} style={{ marginTop: 18 }}>
                                            <Col lg={21} xs={21}>
                                                <a onClick={() => showDetailsOperations(item)}> <Text style={{ fontSize: 14, fontWeight: 600, color: "#02C3CD" }}>{t("plainFriend.showDetail")}</Text></a>
                                            </Col>
                                            <Col lg={3} xs={3}>
                                                <RWebShare
                                                    data={{
                                                        text: temp,
                                                        url: undefined,
                                                        title: t("amigobs.shareLink"),
                                                    }}
                                                    onClick={() => console.log("shared successfully!")}
                                                >
                                                    <Button
                                                        className="button-primari-gibobs"
                                                        type="primary"
                                                        style={{ background: "white", border: "none", marginLeft: -20 }}

                                                    // onClick={() => sharedSocialMendia()}
                                                    >
                                                        <ShareAltOutlined style={{ color: "#02C3CD", fontSize: 20 }} />
                                                    </Button>
                                                </RWebShare>







                                            </Col>

                                        </Row>
                                    </Card>
                                )
                            }



                        })}


                    </div>


                    <div style={{ marginTop: 32 }}>
                        {signed !== undefined &&
                            <Text style={{ color: "#2F4858", fontSize: 12, fontWeight: 400 }}>{t("amigobs.mortgagesSigned").toLocaleUpperCase()} </Text>
                        }

                        {valuesData.map((item) => {
                            if (item.status === "finish" && item.stage === "finish") {




                                return (
                                    <Card className="CardViability" style={{ background: "#FFFFFF", borderRadius: 4, boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)", marginTop: 16 }}>
                                        <Row gutter={16}>
                                            <Col lg={3} xs={3}>
                                                <CheckOutlined style={{ color: "#52C41A", fontSize: 20, marginTop: 4 }} />
                                            </Col>
                                            <Col lg={21} xs={21}>
                                                <Text style={{ fontSize: 16, fontWeight: 600, color: "#2F4858" }}>{item.client}</Text>
                                            </Col>

                                        </Row>
                                        <Row gutter={16} style={{ marginTop: 18 }}>
                                            <Col lg={24} xs={24}>
                                                <a onClick={() => showDetailsOperations(item)}> <Text style={{ fontSize: 14, fontWeight: 600, color: "#02C3CD" }}>{t("plainFriend.showDetail")}</Text></a>
                                            </Col>


                                        </Row>
                                    </Card>
                                )
                            }



                        })}

                    </div>

                    <div style={{ marginTop: 32 }}>
                        {noSigned !== undefined &&
                            <Text style={{ color: "#2F4858", fontSize: 12, fontWeight: 400 }}>{t("amigobs.mortgagesNoSigned").toLocaleUpperCase()} </Text>
                        }

                        {valuesData.map((item) => {

                            if (item.status === "finish" && item.stage !== "finish") {


                                return (
                                    <Card className="CardViability" style={{ background: "#FFFFFF", borderRadius: 4, boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)", marginTop: 16 }}>
                                        <Row gutter={16}>
                                            <Col lg={3} xs={3}>
                                                <CloseOutlined style={{ color: "#FF4D4F", fontSize: 20, marginTop: 4 }} />
                                            </Col>
                                            <Col lg={21} xs={21}>
                                                <Text style={{ fontSize: 16, fontWeight: 600, color: "#2F4858" }}>{item.client}</Text>
                                            </Col>

                                        </Row>
                                        <Row gutter={16} style={{ marginTop: 18 }}>
                                            <Col lg={24} xs={24}>
                                                <a onClick={() => showDetailsOperations(item)}> <Text style={{ fontSize: 14, fontWeight: 600, color: "#02C3CD" }}>{t("plainFriend.showDetail")}</Text></a>
                                            </Col>


                                        </Row>
                                    </Card>
                                )
                            }



                        })}

                    </div>

                    {dataPersonal &&
                        <div style={{ alignContent: "center" }} >
                            <RWebShare
                                data={{
                                    text:t("amigobs.share1"),
                                    url: dataPersonal.friendLink,
                                    title: t("amigobs.shareLink"),
                                }}
                               
                                onClick={() => console.log("shared successfully!")}
                            >
                                <Button
                                    className="button-primari-gibobs"
                                    type="primary"
                                    style={{
                                        width: "92%",
                                        height: 48,
                                        fontSize: "16px",
                                        position: "fixed",
                                        left: 16,
                                        bottom: 16,
                                        fontWeight: 600

                                    }}
                                // onClick={() => sharedSocialMendia()}
                                >
                                    {t("plainFriend.inviteMoreFriend")}
                                </Button>
                            </RWebShare>
                        </div>
                    }





                </Content>
            </div >

        }
        {location.pathname === "/friendInfo/operations-details" && valuesMortgage !== undefined &&
            <div >
                <Content style={{ padding: 16 }}>

                    <div style={{ display: "flex" }}>
                        <a onClick={() => backToHome()}><ArrowLeftOutlined style={{ color: "#2F4858", fontSize: 16, fontWeight: 600 }} /> </a>
                        <Text style={{ color: "#2F4858", fontSize: 16, fontWeight: 600, marginLeft: 16 }}>{t("plainFriend.detailOperationTitle")} </Text>
                    </div>


                    <Row style={{ width: "100%", display: "block", marginTop: 24 }}>
                        <Text style={{ fontSize: 16, fontWeight: 400, color: "#2F4858", marginBottom: 12 }}>{t("m_amigobs.status")}</Text>

                        {valuesMortgage.status !== "finish" &&

                            <Text style={{
                                float: "right", marginBottom: 3, color: "#52C41A", fontSize: 14, fontWeight: 600
                            }}>{t("operationStatus.open")}</Text>
                        }

                        {valuesMortgage.stage === "finish" && valuesMortgage.status === "finish" && reward.paid === 0 &&

                            <Text style={{
                                float: "right", marginBottom: 3, color: "#1890FF", fontSize: 14, fontWeight: 600
                            }}>{t("operationStatus.pendingPay")}</Text>
                        }

                        {valuesMortgage.stage === "finish" && valuesMortgage.status === "finish" && reward.paid !== 0 &&

                            <Text style={{
                                float: "right", marginBottom: 3, color: "#52C41A", fontSize: 14, fontWeight: 600
                            }}>{t("operationStatus." + valuesMortgage.status + "Pay")}</Text>
                        }

                        {valuesMortgage.stage !== "finish" && valuesMortgage.status === "finish" &&

                            <Text style={{
                                float: "right", marginBottom: 3, color: "#FF4D4F", fontSize: 14, fontWeight: 600
                            }}>{t("amigobs.mortgagesNoSigned2")}</Text>
                        }






                        {borderTop}
                    </Row>
                    <Row style={{ width: "100%", display: "block", marginTop: 24 }}>
                        <Text style={{ fontSize: 16, fontWeight: 400, color: "#2F4858", marginBottom: 12 }}>{t("m_amigobs.friend")}</Text>
                        <Text style={{ float: "right", marginBottom: 3, color: "#748EA0", fontSize: 14, fontWeight: 600 }}>{valuesMortgage.client}</Text>
                        {borderTop}
                    </Row>
                    <Row style={{ width: "100%", display: "block", marginTop: 24 }}>
                        <Text style={{ fontSize: 16, fontWeight: 400, color: "#2F4858", marginBottom: 12 }}>{"ID"}</Text>
                        <Text style={{ float: "right", marginBottom: 3, color: "#748EA0", fontSize: 14, fontWeight: 600 }}>{valuesMortgage.name}</Text>
                        {borderTop}
                    </Row>
                    <Row style={{ width: "100%", display: "block", marginTop: 24 }}>
                        <Text style={{ fontSize: 16, fontWeight: 400, color: "#2F4858", marginBottom: 12 }}>{t("details.create")}</Text>
                        <Text style={{ float: "right", marginBottom: 3, color: "#748EA0", fontSize: 14, fontWeight: 600 }}>{moment(valuesMortgage.createAt).format("DD-MM-YYYY")}</Text>
                        {borderTop}
                    </Row>
                    <Row style={{ width: "100%", display: "block", marginTop: 24 }}>
                        <Text style={{ fontSize: 16, fontWeight: 400, color: "#2F4858", marginBottom: 12 }}>{t("b2bOperationList.stage")}</Text>
                        <Text style={{ float: "right", marginBottom: 3, color: "#748EA0", fontSize: 14, fontWeight: 600 }}>{t("stages." + valuesMortgage.stage)}</Text>
                        {borderTop}
                    </Row>
                    {renderMessage(valuesMortgage.stage)}
                    <RWebShare
                        data={{
                            text: temp,
                            url: undefined,
                            title: "Animo",
                        }}
                        onClick={() => console.log("shared successfully!")}
                    >
                        <Button
                            className="button-primari-gibobs"
                            type="primary"
                            style={{
                                width: "92%",
                                height: 48,
                                fontSize: "16px",
                                fontWeight: 600,
                                position: "fixed",
                                left: 16,
                                bottom: 16
                            }}
                        //  onClick={() => sharedSocialMendia()}
                        >
                            {t("amigobs.friendSend")}
                        </Button>
                    </RWebShare>
                </Content>
            </div >

        }
        {location.pathname === "/friendInfo/details/info" &&

            <div>
                <Content style={{ padding: 16 }}>
                    <div style={{ display: "flex" }}>
                        <a onClick={() => backToHome()}><ArrowLeftOutlined style={{ color: "#2F4858", fontSize: 16, fontWeight: 600 }} /> </a>
                        <Text style={{ color: "#2F4858", fontSize: 16, fontWeight: 600, marginLeft: 16 }}>{t("plainFriend.moreInfo")} </Text>

                    </div>

                    <div style={{ marginTop: 16, width: "100%" }}>
                        <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 400 }}>{t("plainFriend.moreInfo1")} </Text>
                    </div>
                    <div style={{ marginTop: 8, width: "100%" }}>
                        <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 400 }}>{t("plainFriend.moreInfo2")} </Text>
                    </div>

                    <div style={{ marginTop: 16, width: "100%" }}>

                        <Text style={{ color: "#2F4858", fontSize: 14, fontWeight: 600 }}>{t("plainFriend.moreInfo3")} </Text>
                    </div>

                    <div style={{ marginTop: 16, width: "100%" }}>
                        <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 400 }}>{t("plainFriend.moreInfo4")} </Text>
                    </div>

                    <div style={{ marginTop: 8, width: "100%" }}>
                        <Text style={{ fontSize: 14, fontWeight: 400, color: "#748EA0", width: "100%" }}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: t("plainFriend.moreInfo5"),
                                }}
                            />
                        </Text>

                    </div>


                </Content>
            </div >

        }
        <Modal
            className="modal-common-mobile"
            cancelButtonProps={{
                style: { height: 40, width: "100%" },
                className: "button-secundary-gibobs",
            }}
            width={328}
            okButtonProps={{
                style: { height: 40, width: "100%" },
                className: "button-primari-gibobs",
            }}
            open={showAccount}
            title={<Text style={{ fontSize: 16, color: "#2F4858", fontWeight: 600 }}>{t("m_common.addIBAN")}</Text>}
            okText={t("offers.accept")}
            cancelText={t("mydocuments.deleteCancel")}
            destroyOnClose={true}
            onCancel={() => {
                setShowAccount(false);
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        saveDataAccountBank(values.IBAN, "agent");
                        setShowAccount(false);
                    })
                    .catch((info) => {
                        console.log("Validate Failed:", info);
                    });
            }}
        >
            <Text style={{ fontSize: 14, color: "#748EA0", fontWeight: 400, marginTop: -30 }}>{t("m_common.addIBANMessage")}</Text>
            <Form form={form} layout="vertical">
                <Form.Item
                    name="IBAN"
                    label={""}
                    style={{ marginTop: 24 }}
                >
                    <Input
                        style={{ width: "98%" }}
                        placeholder={t("ES00 0000 0000 0000 0000")}
                    />
                </Form.Item>
            </Form>
        </Modal>
        <Modal
            className="modal-common-mobile"
            cancelButtonProps={{
                style: { height: 40, width: "100%", fontWeight: 600 },
                className: "button-secundary-gibobs",
            }}
            width={328}
            okButtonProps={{
                style: { height: 40, width: "100%",fontWeight: 600  },
                className: "button-primari-gibobs",
            }}
            open={visibleCode}
            title={<Text style={{ fontSize: 16, color: "#2F4858", fontWeight: 600 }}>{t("amigobs.code")}</Text>}
            okText={t("offers.accept")}
            cancelText={t("mydocuments.deleteCancel")}
            destroyOnClose={true}
            onCancel={() => {
                setVisibleCode(false);
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        saveDataCodeLink(values.code);
                        setVisibleCode(false);
                    })
                    .catch((info) => {
                        console.log("Validate Failed:", info);
                    });
            }}
        >
            <Text style={{ fontSize: 14, color: "#748EA0", fontWeight: 400, marginTop: -30 }}>{t("amigobs.codeDesc")}</Text>
            <Form form={form} layout="vertical">
                <Form.Item
                    name="code"
                    label={"https://www.gibobs.com/plan-amigobs/"}
                    style={{ marginTop: 24 }}
                    rules={[
                        {
                            validator: (e, value) => {
                                return new Promise((resolve, reject) => {
                                    let match = [];
                                    if (value.match(/^[\-a-zA-Z0-9]+$/g)) {
                                        match = value.match(
                                            /^[\-a-zA-Z0-9]+$/g
                                        );
                                    }
                                    if (match === null) {
                                        reject("Formato inválido");
                                    }
                                    if (!value.match(/^[\-a-zA-Z0-9]+$/g)) {
                                        reject("Formato inválido");
                                    } else {
                                        resolve();

                                    }
                                });
                            },
                        },
                    ]}
                >
                    <Input
                        style={{ width: "98%" }}
                        placeholder={dataPersonal.friendName}

                    />
                </Form.Item>
            </Form>
        </Modal>
    </>);
};

export default PlainFriendDataMobile;
