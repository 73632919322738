import React, { useState } from "react";
import { Col, Progress, Typography, Tooltip, Row, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  RightOutlined,
  HeartOutlined,
  DownloadOutlined,
  HeartFilled,
  InfoCircleOutlined,
} from "@ant-design/icons";
import numeral from "numeral";
import { getPdf, patchFavoriteScenario } from "../../../store/promoter/actions";

const { Text } = Typography;

const DifferentScenarioCards = ({
  title,
  number,
  equity,
  margin,
  type,
  viability,
  tooltipText,
  setNumberTab,
  favorite,
  id,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();;
  const dispatch = useDispatch();
  const params = useParams();
  const operationId = params.operationId;
  const typeData = params.type;
  const dataPromoter = useSelector((state) => state.promoter.dataFormPromoter);
  const [loading, setLoading] = useState(false);
  const sectionScenario = (title, data, detail) => {
    const colSpan1 = {
      xs: 24,
      lg:
        number === 5 && typeData === "OWN_FUNDING"
          ? 12
          : number !== 0
          ? 8
          : number === 0
          ? 12
          : number !== 5 && typeData !== "OWN_FUNDING"
          ? 8
          : 12,
    };

    return (
      <Col
        {...colSpan1}
        style={{
          backgroundColor: "#FFFFF",
          borderRight:
            number === 0 && title === "promoterLoan.marginOnSales"
              ? ""
              : number === 5 &&
                title === "promoterLoan.marginOnSales" &&
                typeData === "OWN_FUNDING"
              ? ""
              : title !== "m_viability.viabilityTitle" && "4px solid #F1F7F8",
          padding: "0px 32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div
            className="paragraph-style"
            style={{ fontWeight: 600, textAlign: "center", minHeight: 40 }}
          >
            {t(title)}
          </div>
          {title === "m_viability.viabilityTitle" ? (
            <div>
              <Progress
                type="dashboard"
                strokeColor={parseInt(viability) < 3 ? "#FAAD14" : "#52C41A"}
                trailColor={"#F1F7F8"}
                percent={((viability ? parseInt(viability) : null) * 100) / 5}
                format={() => (
                  <div>
                    <Text
                      strong
                      style={{
                        fontSize: 20,
                        color: "#2F4858",
                        marginBottom: -8,
                        display: "grid",
                      }}
                    >
                      {viability ? numeral(viability).format("0.0") : 0}
                    </Text>
                    <Text
                      style={{ fontSize: "10px", color: "#2F4858" }}
                    >{`de 5`}</Text>
                  </div>
                )}
                width={75}
                className="EstimatedViabilityProgress"
                style={{
                  textAlign: "center",
                  marginTop: 6,
                  marginBottom: 0,
                  marginRight: 0,
                  textAlign: "center",
                }}
              />
              {number === 5 && (
                <div
                  className="paragraph-style"
                  style={{ color: "#02C3CD", cursor: "pointer" }}
                  onClick={() => setNumberTab("2")}
                >
                  {t("promoterLoan.seeDetails")}
                </div>
              )}
            </div>
          ) : detail ? (
            <div>
              <div>{t("promoterLoan.ownFunds")}</div>
              <Progress percent={50} />
            </div>
          ) : (
            <div
              className="title-primary"
              style={{
                padding: "25px 0px",
                fontSize: number === 5 ? 32 : 20,
                textAlign: "center",
              }}
            >
              {data}
            </div>
          )}
          <div style={{ borderRight: "4px solid #F1F7F8" }}> </div>
        </div>
      </Col>
    );
  };
  return (
    <div
      style={{
        display: "flex",
        boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
        margin: "24px 0px",
      }}
    >
      {number !== 5 ? (
        <div style={{ width: "40%" }}>
          <div
            style={{
              backgroundColor:
                number === 1 || number === 3
                  ? "#F1F7F8"
                  : number === 0
                  ? "#FFFFF"
                  : "#EFFCFC",
              padding: "25px 40px",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              borderRight: number === 0 && "4px solid #F1F7F8",
            }}
          >
            <div>
              <div className="paragraph-style" style={{}}>
                {t("promoterLoan.scenarioTitle")} {number}.
              </div>
              <div>
                <div
                  className="title-primary"
                  style={{ marginBottom: 8, fontSize: 16 }}
                >
                  {t(title)}
                  <Tooltip
                    placement="bottom"
                    title={
                      <div style={{ fontSize: 12, fontWeight: 400 }}>
                        {t(tooltipText)}
                      </div>
                    }
                  >
                    <InfoCircleOutlined
                      style={{ color: "#02C3CD", marginLeft: 3 }}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={() =>
                  navigate(
                    `/promoter-loan/economic-study/${operationId}/${type}`
                  )
                }
              >
                <div
                  className="paragraph-style"
                  style={{ color: "#02C3CD", cursor: "pointer" }}
                >
                  {t("promoterLoan.seeMore")}
                </div>
                <RightOutlined
                  style={{ color: "#02C3CD", marginLeft: 4, fontSize: 15 }}
                />
              </div>
              <div>
                {number !== 0 && (
                  <>
                    {favorite && (
                      <HeartFilled
                        onClick={() =>
                          dispatch(patchFavoriteScenario(id, operationId))
                        }
                        style={{
                          color: "#02C3CD",
                          fontSize: 15,
                        }}
                      />
                    )}
                    {!favorite && (
                      <HeartOutlined
                        onClick={() =>
                          dispatch(patchFavoriteScenario(id, operationId))
                        }
                        style={{
                          color: "#02C3CD",
                          fontSize: 15,
                        }}
                      />
                    )}
                  </>
                )}
                {number !== 0 && (
                  <>
                    {!loading ? (
                      <DownloadOutlined
                        onClick={() =>
                          dispatch(
                            getPdf(number, operationId, (e) => setLoading(e))
                          )
                        }
                        style={{
                          color: "#02C3CD",
                          marginLeft: 12,
                          fontSize: 15,
                        }}
                      />
                    ) : (
                      <Spin
                        style={{
                          color: "#02C3CD",
                          marginLeft: 12,
                          fontSize: 15,
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "50%",
            padding: "20px 40px",
            borderRight:
              title !== "m_viability.viabilityTitle" && "4px solid #F1F7F8",
          }}
        >
          <div
            style={{
              textAlign: "center",
            }}
          >
            <div
              className="paragraph-style"
              style={{ fontWeight: 600, textAlign: "center", minHeight: 40 }}
            >
              {t("promoterLoan.amountFinancing")}
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 8,
                }}
              >
                <div style={{ width: "50%" }}>
                  <div
                    className="paragraph-project-data"
                    style={{
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    {t("promoterLoan.ownFunds")}
                  </div>
                  <div
                    className="paragraph-project-data"
                    style={{
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    {typeData === "OWN_FUNDING"
                      ? numeral(
                          dataPromoter &&
                            dataPromoter.capitalStructure &&
                            Number(dataPromoter.capitalStructure.equity) +
                              Number(
                                dataPromoter.capitalStructure.financingRequired
                              )
                        ).format("0,0")
                      : numeral(
                          dataPromoter &&
                            dataPromoter.capitalStructure &&
                            Number(dataPromoter.capitalStructure.equity)
                        ).format("0,0")}
                  </div>
                </div>
                <div style={{ width: "50%" }}>
                  <div
                    className="paragraph-project-data"
                    style={{
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    {t("promoterLoan.financingTitle")}
                  </div>
                  <div
                    className="paragraph-project-data"
                    style={{
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    {typeData === "OWN_FUNDING"
                      ? 0
                      : numeral(
                          dataPromoter &&
                            dataPromoter.capitalStructure &&
                            Number(
                              dataPromoter.capitalStructure.financingRequired
                            )
                        ).format("0,0")}
                  </div>
                </div>
              </div>

              <Progress
                percent={50}
                trailColor={"#02AFB8"}
                strokeColor={"#C0DAEE"}
                showInfo={false}
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  className="title-project-data"
                  style={{
                    textAlign: "center",
                  }}
                >
                  0
                </div>
                <div
                  className="title-project-data"
                  style={{
                    textAlign: "right",
                  }}
                >
                  {numeral(
                    dataPromoter &&
                      dataPromoter.capitalStructure &&
                      Number(dataPromoter.capitalStructure.equity) +
                        Number(dataPromoter.capitalStructure.financingRequired)
                  ).format("0,0")}
                  <div
                    className="paragraph-style"
                    style={{
                      fontWeight: 600,
                      textAlign: "center",
                      minHeight: 40,
                    }}
                  >
                    {t("promoterLoan.totalCosts")}
                  </div>
                </div>
              </div>
              {/* <div style={{ display: "flex", justifyContent: "right" }}>
                <div
                  className="paragraph-style"
                  style={{
                    fontWeight: 600,
                    textAlign: "center",
                    minHeight: 40,
                  }}
                >
                  {t("promoterLoan.totalCosts")}
                </div>
              </div> */}
            </div>

            <div style={{ borderRight: "4px solid #F1F7F8" }}> </div>
          </div>
        </div>
      )}
      <Row
        gutter={24}
        style={{
          width: "100%",
          padding: "20px 0px",
        }}
      >
        {sectionScenario(
          "promoterLoan.equityMultiple",
          numeral(equity).format("0,0.00")
        )}
        {sectionScenario(
          "promoterLoan.marginOnSales",
          numeral(margin).format("0.00 %")
        )}
        {viability !== undefined &&
          sectionScenario("m_viability.viabilityTitle", viability)}
      </Row>
    </div>
  );
};

export default DifferentScenarioCards;
