import React, { useEffect, useState } from 'react';
import { Card, Typography, Col, Row } from 'antd';
import googlePlay from '../../../assets/init/googlePlay.png';
import appleStore from '../../../assets/init/appleStore.png';
import { isMobileOnly } from 'react-device-detect';

import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';


const { Text } = Typography;

const CardLinkAmigobs = () => {
	const { t } = useTranslation();
	const [visible, setVisible] = useState(true);
	const [, setRandomNumber] = useState();

	useEffect(() => {
		if (sessionStorage.getItem('linksApps') === 'true') {
			sessionStorage.setItem('linksApps', true);
		} else {
			sessionStorage.setItem('linksApps', false);
		}
	}, [visible]);
	useEffect(() => {
		setRandomNumber(Math.floor(Math.random() * 2));
	}, [visible]);

	

	const handleChangeVisible = () => {
		sessionStorage.setItem('linksApps', false);
		setVisible(sessionStorage.getItem('linksApps'));
	};

	if (
		sessionStorage.getItem('linksApps') !== 'false' &&
		sessionStorage.getItem('linksApps') !== false
	) {
		// if (randomNumber === 1) {
		//   return (
		//     <Col
		//       style={{
		//         height: isMobileOnly ? "124px" : undefined,
		//         position: "fixed",
		//         bottom: 0,
		//         right: 0,
		//         zIndex: 1000,
		//         width: "100%",
		//       }}
		//     >
		//       <Card className="card-no-padding">
		//         <Row>
		//           <Col
		//             span={12}
		//             style={{
		//               background:
		//                 "linear-gradient(90deg, #0088C6 0%, #00B298 100%)",
		//               padding: 12,
		//               paddingLeft: 20,
		//               display: "flex",
		//               flexDirection: "column",
		//             }}
		//           >
		//             <div style={{ display: "flex"}}>
		//             <img
		//               src={iconAmigobs}
		//               alt="asdf"
		//               style={{ width: 16, height: 16 }}
		//             ></img>

		//             <Text
		//               style={{
		//                 color: "#FAFEFE",
		//                 fontSize: 14,
		//                 fontWeight: 400,
		//                 marginTop: -2,
		//                 marginLeft: 7
		//               }}
		//             >
		//               {t("header.plain")}
		//             </Text>
		//             </div>
		//             <div>
		//               <Text
		//                 style={{ color: "#FAFEFE", fontSize: 14, fontWeight: 600 }}
		//               >
		//                 {t("amigobs.bannerDes")}
		//               </Text>
		//             </div>

		//             <div style={{ marginTop: 8 }}>
		//               <Button
		//                 style={{
		//                   width: 100,
		//                   height: 28,
		//                   fontSize: 10,
		//                   color: "#02C3CD",
		//                   fontWeight: 500,
		//                   borderRadius: 4,
		//                   border: "none",
		//                 }}
		//                 onClick={() => handleClick()}
		//               >
		//                 {t("header.inviteFriend")}
		//               </Button>
		//             </div>
		//           </Col>
		//           <Col span={12}>
		//             <div
		//               onClick={() => handleChangeVisible()}
		//               style={{
		//                 fontSize: 18,
		//                 fontWeight: 700,
		//                 color: "#2F4858",
		//                 position: "absolute",
		//                 top: 14,
		//                 right: 0,
		//                 marginRight: 15,
		//                 fontFamily: "Inter",
		//                 border: "none",
		//                 background: "transparent",
		//                 width: 24,
		//                 height: 24,
		//               }}
		//             >
		//               <CloseOutlined style={{ fontSize: 20, fontWeight: 600 }} />
		//             </div>
		//             <img
		//               src={imageAmigobs}
		//               alt="asdf"
		//               style={{ width: "100%", height: 124 }}
		//             ></img>
		//           </Col>
		//         </Row>
		//       </Card>
		//     </Col>
		//   );
		// } else {
		return (
			<Col
				style={{
					height: isMobileOnly ? '236px' : undefined,
					position: 'fixed',
					bottom: 0,
					right: 0,
					zIndex: 1000,
					width: '100%'
				}}>
				<Card
					className="init-card-apps-mobile init-card-apps-mobile-background"
					style={{ height: 124 }}>
					<Row style={{ width: '90%' }}>
						<div
							onClick={() => handleChangeVisible()}
							style={{
								fontSize: 18,
								fontWeight: 700,
								position: 'absolute',
								top: 14,
								right: 0,
								marginRight: 15,
								fontFamily: 'Inter',
								border: 'none',
								
								width: 24,
								height: 24,
								color: "#FAFEFE",
							}}>
							<CloseOutlined  style={{fontSize: 20, fontWeight: 600}}/>
						</div>
						<Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: 8 }}>
							<Text
								style={{
									fontSize: 20,
									fontWeight: 600,
									color: '#FAFEFE',
									fontFamily: 'Inter',
									position: 'relative',
									top: -5
								}}>
								{t('init.downloadApp')}
							</Text>
						</Col>

						<Col
							lg={24}
							md={24}
							sm={24}
							xs={24}
							style={{
								display: 'flex'
							}}>
							<a
								style={{ width: 120, height: 36, marginRight: 9 }}
								target="_blank"
								href={'https://play.google.com/store/apps/details?id=com.gibobs.mobile'}>
								<img
									src={googlePlay}
									alt="asdf"
									style={{ width: 120, height: 40, marginRight: 9 }}></img>
							</a>
							<a
								style={{ width: 120, heigh: 20 }}
								target="_blank"
								href={'https://apps.apple.com/es/app/gibobs/id1582157732'}>
								<img src={appleStore} alt="asdf" style={{ width: 120, heigh: 20 }}></img>
							</a>
						</Col>
					</Row>
				</Card>
			</Col>
		);
		// }
	} else {
		return <></>;
	}
};

export default CardLinkAmigobs;
