import React from "react";
import { Layout, Row } from "antd";
import LayoutProducts from "./LayoutProducts";



function OperationsContratacion() {
 

  return (
    <Layout>
      <LayoutProducts />
      <Row
        gutter={24}
        className="operationsClientsCard"
        style={{ marginLeft: 0, marginRight: 0 }}
      >
        {/* {userOperations &&
          userOperations.slice(0, 2).map((infoMortgage, index) => {
            return (
              <Col {...colSpan}>
                <Card
                  key={infoMortgage.id}
                  style={{
                    width: "100%",
                    marginBottom: "20px",
                    boxShadow: "0px 0px 8px rgba(47, 72, 88, 0.1)",
                    borderRadius:"4px"
                  }}
                  cover={
                    <img
                      src={index !== 0 ? Mortgage : Subrogation}
                      alt="mortgage"
                      style={{ width: "100%", height: "auto", borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px", }}
                    />
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        style={{
                          color: "#2F4858",
                          fontSize: "16px",
                          fontWeight: "700",
                        }}
                      >
                        {infoMortgage.name}
                      </Text>
                      <LockOutlined
                        style={{ fontSize: "20px", color: "#C0DAEE" }}
                      />
                    </div>
                    <Text
                      style={{
                        color: "#748EA0",
                        fontSize: "12px",
                        marginTop: "8px",
                      }}
                    >
                      {infoMortgage.operationData.mortgage.province
                        ? infoMortgage.operationData.mortgage.province
                        : "-"}
                    </Text>
                    <div
                      style={{
                        marginTop: "16px",
                      }}
                    >
                      <Text
                        style={{
                          color: "#2F4858",
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        {t("init.requestedDeb")}
                      </Text>
                      <Text
                        style={{
                          color: "#02C3CD",
                          fontSize: "12px",
                          marginLeft: "4px",
                          fontWeight: "500",
                        }}
                      >
                        {numeral(
                          infoMortgage.operationData.mortgage.maxBudget
                        ).format("0,0 $")}
                      </Text>
                    </div>

                    <Button
                      type="dashed"
                      className="button-secundary-gibobs"
                      style={{ marginTop: "36px" }}
                    >
                      {t("createOperations.return")}
                    </Button>
                  </div>
                </Card>
              </Col>
            );
          })} */}
      </Row>
    </Layout>
  );
}

export default OperationsContratacion;
