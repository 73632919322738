import { SIMULATIONID, AMORTIZATIONCALCULATORDATA, PREVIOUSPAGE } from './constants';

export const simulationIdRedux = (simulation) => {
	localStorage.setItem('simulationId', simulation);
	return {
		type: SIMULATIONID,
		payload: simulation
	};
};

export const getAmortizationData = (data) => {
	return {
		type: AMORTIZATIONCALCULATORDATA,
		payload: data
	};
};

export const getPreviousPage = (page) => {
	return {
		type: PREVIOUSPAGE,
		payload: page
	};
};
