import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import { isMobileOnly } from "react-device-detect";
const { Text } = Typography;

function ViabilityComponent({
  viable,
  titulo,
  inmo,
  dataMortgage,
  viabilityData,
  optionsModal,
  mortgageInmo,
  calculator,
  ltv,
}) {
  const { t } = useTranslation();
  let textAdvices;

  const [property, setProperty] = useState(undefined);

  if (calculator) {
    dataMortgage.houseState = "have_decide";
  }

  let typeText = {
    true: {
      "viability.financingCeilingTitle": t(
        "viability.financingCeilingDescriptionTrueHouseDecide"
      ),
      "viability.indebtednessTitle": t(
        "viability.indebtednessDescriptionTrueHouseDecide"
      ),
      "viability.seniorityTitle": t(
        "viability.seniorityDescriptionTrueHouseDecide"
      ),
      "viability.incomesTitle": t(
        "viability.incomesDescriptionTrueHouseDecide"
      ),
      "viability.noresidentTitle": t(
        "viability.noResidentDescriptionTrueHouseDecide"
      ),
    },
    false: {
      "viability.financingCeilingTitle": t(
        "viability.financingCeilingDescriptionFalseHouseDecide"
      ),
      "viability.indebtednessTitle": t(
        "viability.indebtednessDescriptionFalseHouseDecide"
      ),
      "viability.seniorityTitle": t(
        "viability.seniorityDescriptionFalseHouseDecide"
      ),
      "viability.incomesTitle": t(
        "viability.incomesDescriptionFalseHouseDecide"
      ),
      "viability.noresidentTitle": t(
        "viability.noResidentDescriptionFalseHouseDecide"
      ),
    },
  };
  let typeTextSearchingSavings = {
    true: {
      "viability.financingCeilingTitle": t(
        "viability.financingCeilingDescriptionTrueSearchingSavings"
      ),
      "viability.indebtednessTitle": t(
        "viability.indebtednessDescriptionTrueHouseDecide"
      ),
      "viability.seniorityTitle": t(
        "viability.seniorityDescriptionTrueHouseDecide"
      ),
      "viability.incomesTitle": t(
        "viability.incomesDescriptionTrueHouseDecide"
      ),
      "viability.noresidentTitle": t(
        "viability.noResidentDescriptionTrueHouseDecide"
      ),
    },
    false: {
      "viability.financingCeilingTitle": t(
        "viability.financingCeilingDescriptionFalseHouseDecide"
      ),
      "viability.indebtednessTitle": t(
        "viability.indebtednessDescriptionFalseSearchingSavings"
      ),
      "viability.seniorityTitle": t(
        "viability.seniorityDescriptionFalseHouseDecide"
      ),
      "viability.incomesTitle": t(
        "viability.incomesDescriptionFalseHouseDecide"
      ),
      "viability.noresidentTitle": t(
        "viability.noResidentDescriptionFalseHouseDecide"
      ),
    },
  };
  let typeTextSearchingFixed = {
    true: {
      "viability.financingCeilingTitle": t(
        "viability.financingCeilingDescriptionTrueSearchingFixed"
      ),
      "viability.indebtednessTitle": t(
        "viability.indebtednessDescriptionTrueHouseDecide"
      ),
      "viability.seniorityTitle": t(
        "viability.seniorityDescriptionTrueHouseDecide"
      ),
      "viability.incomesTitle": t(
        "viability.incomesDescriptionTrueHouseDecide"
      ),
      "viability.noresidentTitle": t(
        "viability.noResidentDescriptionTrueHouseDecide"
      ),
    },
    false: {
      "viability.financingCeilingTitle": t(
        "viability.financingCeilingDescriptionFalseHouseDecide"
      ),
      "viability.indebtednessTitle": t(
        "viability.indebtednessDescriptionFalseSearchingFixed"
      ),
      "viability.seniorityTitle": t(
        "viability.seniorityDescriptionFalseHouseDecide"
      ),
      "viability.incomesTitle": t(
        "viability.incomesDescriptionFalseHouseDecide"
      ),
      "viability.noresidentTitle": t(
        "viability.noResidentDescriptionFalseHouseDecide"
      ),
    },
  };

  if (dataMortgage.houseState === "have_decide" || mortgageInmo === true) {
    if (viable === true) {
      textAdvices = typeText.true[titulo];
    } else {
      textAdvices = typeText.false[titulo];
    }
  }
  if (
    dataMortgage.houseState === "searching" &&
    viabilityData.operation &&
    viabilityData.operation.mortgageAmountLimit === "savings"
  ) {
    if (viable === true) {
      textAdvices = typeTextSearchingSavings.true[titulo];
    } else {
      textAdvices = typeTextSearchingSavings.false[titulo];
    }
  }
  if (
    dataMortgage.houseState === "searching" &&
    viabilityData.operation &&
    viabilityData.operation.mortgageAmountLimit === "fixed"
  ) {
    if (viable === true) {
      textAdvices = typeTextSearchingFixed.true[titulo];
    } else {
      textAdvices = typeTextSearchingFixed.false[titulo];
    }
  }

  useEffect(() => {
    if (dataMortgage && dataMortgage.maxBudget) {
      setProperty(dataMortgage.maxBudget);
    } else {
      if (viabilityData && viabilityData.operation) {
        setProperty(viabilityData.operation.property);
      }
    }
  }, []);

  return (
    <div>
      <Text
        style={{
          fontSize: inmo || isMobileOnly ? 16 : "14px",
          marginRight: "20px",
          marginLeft: inmo || isMobileOnly ? 0 : "20px",
          marginBottom: "40px",
          fontWeight: inmo ? 400 : "500",
          color: "#2F4858",
        }}
      >
        {viable && viable ? (
          <ArrowUpOutlined style={{ marginRight: "9px", color: "#52C41A" }} />
        ) : (
          <ArrowDownOutlined style={{ marginRight: "9px", color: "#FF4D4F" }} />
        )}

        {t(titulo)}
      </Text>
      <div
        className="StyleViabilityComponent"
        style={{
          width: optionsModal ? "240px" : inmo ? "auto" : null,
          marginRight: optionsModal ? 0 : "20px",
          margin: inmo && "8px 0 24px 0",
        }}
        // style={{
        //   width: isMobile ? "232px" : "352px",
        //   height: "auto",
        //   marginTop: "16px",
        //   marginRight: isMobile ? "8px" : "20px",
        //   marginLeft: isMobile ? "8px" : "20px",
        //   marginBottom: "42px",
        // }}
      >
        <Text
          style={{
            color: "#748EA0",
            fontSize: inmo || isMobileOnly ? 14 : "12px",
            fontWeight: inmo && 400,
          }}
        >
          {textAdvices}{" "}
          {textAdvices &&
            textAdvices ===
              t("viability.financingCeilingDescriptionTrueHouseDecide") &&
            viabilityData &&
            !calculator &&
            `(${numeral(viabilityData?.ltv).format("0.0 %")})`}
          {textAdvices &&
          textAdvices ===
            t("viability.financingCeilingDescriptionTrueHouseDecide")
            ? t("viability.financingCeilingDescriptionTrueHouseDecide2")
            : null}
          {textAdvices &&
          viabilityData &&
          textAdvices ===
            t("viability.financingCeilingDescriptionFalseHouseDecide")
            ? calculator
              ? numeral(ltv).format("0.0 %")
              : numeral(viabilityData?.ltv).format("0.0 %")
            : null}
          {textAdvices &&
          textAdvices ===
            t("viability.financingCeilingDescriptionFalseHouseDecide")
            ? t("viability.financingCeilingDescriptionFalseHouseDecide2") +
              " " +
              (ltv ? ltv : viabilityData?.ltv * 100 - 80).toFixed(2) +
              "% " +
              t("viability.financingCeilingDescriptionFalseHouseDecide3")
            : null}
          {textAdvices &&
          viabilityData &&
          textAdvices ===
            t("viability.financingCeilingDescriptionFalseHouseDecide")
            ? numeral(
                ((viabilityData?.ltv !== undefined ? viabilityData?.ltv : ltv) -
                  viabilityData.standardFinancingCeiling) *
                  property
              ).format("0,0 $")
            : null}{" "}
          {textAdvices &&
          textAdvices ===
            t("viability.financingCeilingDescriptionFalseHouseDecide")
            ? t("viability.financingCeilingDescriptionFalseHouseDecide4")
            : null}
          {textAdvices &&
          textAdvices ===
            t("viability.indebtednessDescriptionFalseHouseDecide") &&
          viabilityData
            ? numeral(viabilityData.debtPercentage).format("0.0 %")
            : null}
          {textAdvices &&
          textAdvices === t("viability.indebtednessDescriptionFalseHouseDecide")
            ? t("viability.indebtednessDescriptionFalseHouseDecide2")
            : null}
          {textAdvices &&
          textAdvices ===
            t("viability.financingCeilingDescriptionTrueSearchingFixed") &&
          viabilityData
            ? numeral(viabilityData?.ltv).format("0.0 %")
            : null}
          {textAdvices &&
          textAdvices ===
            t("viability.financingCeilingDescriptionTrueSearchingFixed")
            ? t("viability.financingCeilingDescriptionTrueSearchingFixed1")
            : null}
        </Text>
      </div>
    </div>
  );
}

export default ViabilityComponent;
