import React, { useEffect, useState } from 'react';
import {
	Row,
	Card,
	Skeleton,
	Col,
	Typography,
	Form,
	Select,
	Progress,
	Modal,
	Tabs,
	Button
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { isMobileOnly } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import env from '../../../environment';
import numeral from 'numeral';
import calculatorImage from '../../../assets/calculators/calculatorAmortization.png';
import DownOutlinedMobile from '../../../assets/icons/DownOutlinedMobile.svg';
import bulp from '../../../assets/loans/bulp.png';
import rightIcon from '../../../assets/loans/rigth.png';
import {
	simulationIdRedux,
	getAmortizationData,
	getPreviousPage
} from '../../../store/calculators/actions';
import { InputNumber } from 'afrodita';

const { Text } = Typography;
const { Option } = Select;

const CalculatorAmortization = ({}) => {
	const location = useLocation();
	const { t } = useTranslation();
	const navigate = useNavigate();;
	const [loading, setLoading] = useState(true);
	const [form] = Form.useForm();
	const [result, setResult] = useState(undefined);
	const userToken = useSelector((state) => state.auth.token);
	const [interest, setInterest] = useState(undefined);
	const [capitalPending, setCapitalPending] = useState(0);
	const [percent, setPercent] = useState(0);
	const [simulationData, setSimulationData] = useState({});
	const [visibleResult, setVisibleResult] = useState(false);
	const [visibleWantAmortize, setVisibleWantAmortize] = useState(false);
	const [loanList, setLoanList] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, [1500]);

		if (location?.state?.valuesCalculator !== undefined) {
			setInterest(location?.state?.valuesCalculator.interestRateType);
			onChangeForm(undefined, location?.state.valuesCalculator);
		}

		if (location?.state?.valuesDefaultMenu !== undefined) {
			setInterest(location?.state?.valuesDefaultMenu.interestRateType);
			onChangeForm(undefined, location?.state.valuesDefaultMenu);
		}
		getLoansList();
	}, []);

	const getLoansList = () => {
		axios
			.get(`${env.api.url}/v1/loans/get-loans-list`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setLoanList(response.data.data.loans);
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const getData = (id) => {
		axios
			.get(`${env.api.url}/mortgages/simulations/get-simulation/${id}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setSimulationData(response.data.data.simulationData);
				setResult(response.data.data.simulationData.result);
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const onChangeForm = (values, allValues) => {
		setCapitalPending(allValues.pendingCapital);
		if (allValues.amountToBeAmortized === undefined) {
			allValues.amountToBeAmortized = 10000;
		}

		if (allValues.pendingCapital && allValues.amountToBeAmortized) {
			const total = (allValues.amountToBeAmortized * 100) / allValues.pendingCapital;
			setPercent(total);
		}

		axios
			.post(
				`${env.api.url}/mortgages/simulations/amortization-calculation/mortgage-viability`,
				{
					id: result ? result?.id : undefined,
					pendingCapital: parseInt(allValues.pendingCapital),
					pendingQuotes: parseInt(allValues.pendingQuotes),
					interestRateType: allValues.interestRateType,
					actualTin: parseFloat(allValues.actualTin / 100),
					amountToBeAmortized: parseInt(allValues.amountToBeAmortized)
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				getData(response.data.data.id);
				dispatch(simulationIdRedux(response.data.data.id));
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const cardQuote = (loading, title, description1, description2, savings) => {
		return (
			<>
				<Skeleton active loading={loading} paragraph={false} />

				{!loading && (
					<div
						style={{
							textAlign: 'center',
							display: 'flex',
							flexDirection: 'column'
						}}>
						{!isMobileOnly && (
							<Text
								style={{
									color: '#2F4858',
									fontSize: 16,
									fontWeight: 600,
									marginBottom: 12
								}}>
								{t(title)}
							</Text>
						)}
						<Text
							style={{
								color: '#2F4858',
								fontSize: 16,
								fontWeight: 600,
								textAlign: 'left'
							}}>
							{t(description1)}
						</Text>
						<Text
							style={{
								color: '#2F4858',
								fontSize: 16,
								fontWeight: 400,
								textAlign: 'left'
							}}>
							<div
								dangerouslySetInnerHTML={{
									__html: description2
								}}
							/>
						</Text>
						<Card
							className="gb-card-24px"
							style={{
								marginTop: 12,
								background: '#EFFCFC',
								minHeight: 80,
								fontFamily: 'Inter'
							}}>
							<div className="savings-title" style={{ marginBottom: 4 }}>
								{t('amortization.youWillSave') + '*'}
							</div>
							<div className="savings-sub-title">{numeral(savings).format('0,0 $')}</div>
						</Card>
					</div>
				)}
			</>
		);
	};

	const formAmortizeInitial = (
		<Form
			labelAlign="left"
			colon={false}
			className="gb-form-profile-headlines-mobile"
			labelCol={{
				flex: !isMobileOnly ? '50%' : '40%'
			}}
			wrapperCol={{
				flex: isMobileOnly && '60%'
			}}
			layout={'horizontal'}
			scrollToFirstError={true}
			form={form}
			name="basic"
			initialValues={
				location?.state?.valuesCalculator !== undefined
					? location?.state?.valuesCalculator
					: location?.state?.valuesDefaultMenu
			}
			onValuesChange={(values, allValues) => onChangeForm(values, allValues)}>
			<Row
				style={{
					marginLeft: 'auto',
					marginRight: 'auto',
					marginTop: 16
				}}>
				<Col span={24}>
					<Form.Item
						name="pendingCapital"
						label={t('form.pendingCapital')}
						rules={[
							{
								validator: (e, value) => {
									return new Promise((resolve, reject) => {
										if (value > 0 && value < 100000000) {
											resolve();
										} else {
											reject(
												t('Capital pendiente debe ser un valor mayor que 0 y menor que 1000000')
											);
										}
									});
								}
							}
						]}>
						<InputNumber
							defaultValue={''}
							id={'pendingCapital'}
							gbType="currency"
							placeholder={'0'}
							suffix={'€'}
						/>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item
						name="pendingQuotes"
						label={t('loan.quotePending')}
						rules={[
							{
								validator: (e, value) => {
									return new Promise((resolve, reject) => {
										if (value >= 1 && value <= 480) {
											resolve();
										} else {
											reject(t('Numero de cuotas pendiente debe ser entre 1 y 480 meses'));
										}
									});
								}
							}
						]}>
						<InputNumber
							defaultValue={''}
							id={'pendingQuotes'}
							gbType="number"
							className='gb-input-new-quote'
							placeholder={'0'}
							suffix={t('onboarding.quotas')}
						/>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item name="interestRateType" label={t('suborgation.typeInterest')}>
						<Select
							placeholder={t('suborgation.typeInterest')}
							id="interestRateType"
							suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
							onChange={(e) => setInterest(e)}>
							<Option value="FIXED">{t('loanType.fixed')}</Option>
							<Option value="VARIABLE">{t('loanType.variable')}</Option>
						</Select>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item
						name="actualTin"
						style={{ marginTop: 7 }}
						label={interest === 'FIXED' ? t('amortization.tin') : t('loan.diferent')}
						rules={[
							{
								validator: (e, value) => {
									return new Promise((resolve, reject) => {
										if (value >= 0.1 && value <= 10) {
											resolve();
										} else {
											reject(t('Interes actual debe ser minimo 0.10% y maximo 10%'));
										}
									});
								}
							}
						]}>
						<InputNumber
							defaultValue={''}
							id={'actualTin'}
							gbType="number"
							className='gb-input-new-percent'
							placeholder={'0'}
							suffix={'%'}
						/>
					</Form.Item>
					{interest !== 'FIXED' && (
						<div style={{ fontSize: 12, color: '#748EA0', marginTop: -8, float: 'right' }}>
							{t('calculator.addCurrentEuribor')}
						</div>
					)}
				</Col>
			</Row>
		</Form>
	);

	const amortizationComponent = (
		<Row gutter={[24, 24]}>
			<Col xs={24} sm={24} md={24} lg={8} xl={8}>
				{!isMobileOnly && (
					<Card
						className="card-calculator-amortization"
						cover={<img src={calculatorImage} alt="asdf"></img>}>
						<Skeleton active loading={loading} paragraph={false} />

						{!loading && (
							<Text className="card-amortization-title">{t('fee.calculatorAmortization')}</Text>
						)}
						{!loading && <div>{formAmortizeInitial}</div>}
					</Card>
				)}
				{isMobileOnly && !loading && formAmortizeInitial}
			</Col>
			<Col xs={24} sm={24} md={24} lg={16} xl={16} style={{ textAlign: 'center' }}>
				<Skeleton active loading={loading} paragraph={false} />
				{!loading && !isMobileOnly && (
					<div style={{ textAlign: 'center' }}>
						<Text
							style={{
								color: '#2F4858',
								fontSize: 20,
								fontWeight: 600
							}}>
							{t('m_viability.title')}
						</Text>
						{/* <InfoCircleOutlined
							onClick={() => setVisibleResult(true)}
							style={{ color: '#02C3CD', fontSize: 18, marginLeft: 8 }}
						/> */}
					</div>
				)}
				{!loading && (
					<Row
						style={{
							width: '100%',
							marginTop: !isMobileOnly && 32,
							justifyContent: 'center'
						}}>
						<Form
							labelAlign="left"
							colon={false}
							className="gb-form-profile-headlines-mobile"
							labelCol={{
								flex: !isMobileOnly ? '150px' : '40%'
							}}
							labelWrap
							wrapperCol={{
								flex: 1
							}}
							layout="horizontal"
							scrollToFirstError={true}
							form={form}
							name="basic"
							onValuesChange={(values, allValues) => onChangeForm(values, allValues)}>
							<Row
								style={{
									marginLeft: 'auto',
									marginRight: 'auto',
									marginTop: isMobileOnly ? -16 : 16
								}}>
								<Col span={24}>
									<Form.Item
										name="amountToBeAmortized"
										label={
											<Text
												style={{
													color: '#02C3CD',
													fontSize: 14,
													fontWeight: 400,
													lineHeight: '16px'
												}}>
												{t('calculator.amorticeImport')}
											</Text>
										}
										rules={[
											{
												validator: (e, value) => {
													return new Promise((resolve, reject) => {
														if (parseInt(value) <= parseInt(capitalPending)) {
															resolve();
														} else {
															reject(
																<div style={{ maxWidth: 200, margin: 'auto' }}>
																	{t('calculator.amorticeError')}
																</div>
															);
														}
													});
												}
											}
										]}>
										<InputNumber
											defaultValue={''}
											id={'amountToBeAmortized'}
											gbType="currency"
											placeholder={'0'}
											suffix={'€'}
										/>
									</Form.Item>

									<Progress
										className={'gb-progress'}
										percent={percent}
										strokeColor={{
											'0%': '#80E1E6',
											'100%': '#02AFB8'
										}}
										style={{ width: '100%', height: 6 }}
										showInfo={false}
									/>
								</Col>
							</Row>
							{!loading && (
								<div className="wrapper-want-amortize" style={{ marginTop: 15, fontFamily: 'Inter' }}>
									<div className="title">{t('amortization.iWantToAmortize')}</div>
									<InfoCircleOutlined
										onClick={() => setVisibleWantAmortize(true)}
										className="icon"
									/>
								</div>
							)}
						</Form>
					</Row>
				)}
				<Skeleton active loading={loading} paragraph={false} />

				{!isMobileOnly && (
					<>
						<div style={{ display: 'flex', columnGap: 24 }}>
							<Card
								className="card-amortization-second"
								style={{
									marginTop: 40
								}}>
								{cardQuote(
									loading,
									'amortization.inTerm',
									'amortization.quotaIsMaintained',
									t('amortization.reducesMonthsDescription', {
										months: result === undefined ? '-' : result?.diferenceTerms,
										date: result === undefined ? '-' : result?.endDateWithReducedTerms
									}),
									result === undefined ? '-' : result?.totalSavingsWithReducedTerms
								)}
							</Card>
							<Card
								className="card-amortization-second"
								style={{
									marginTop: 40
								}}>
								{cardQuote(
									loading,
									'amortization.inInstallment',
									'amortization.endDateRemains',
									t('amortization.newFeeDescription', {
										quote:
											result === undefined ? '-' : numeral(result?.reducedQuote).format('0,0.00 $'),
										monthQuote:
											result === undefined
												? '-'
												: result?.diferenceQuote &&  (-result?.diferenceQuote).toFixed(2)
									}),

									result === undefined ? '-' : result?.totalSavingsWithReducedQuote
								)}
							</Card>
						</div>
						<div style={{ display: 'flex', marginTop: 16 }}>
							<Text style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>
								{t('amortization.textWarning')}
							</Text>
						</div>
					</>
				)}
				{isMobileOnly && (
					<Card
						className="card-amortization-second"
						style={{
							marginTop: 32
						}}>
						<Skeleton active loading={loading} paragraph={false} />
						{!loading && (
							<>
								<div style={{ textAlign: 'center' }}>
									<Text
										style={{
											color: '#2F4858',
											fontSize: 20,
											fontWeight: 600
										}}>
										{t('m_viability.title')}
									</Text>
									{/* <InfoCircleOutlined
										onClick={() => setVisibleResult(true)}
										style={{ color: '#02C3CD', fontSize: 18, marginLeft: 8 }}
									/> */}
								</div>
								<Tabs
									className={'tabs-onboarding-mortgage-mobile'}
									defaultActiveKey="1"
									centered
									style={{
										marginBottom: 200,
										width: !isMobileOnly && '60%',
										margin: 'auto',
										marginTop: 8
									}}>
									<Tabs.TabPane tab={t('amortization.inTerm')} key="1">
										{cardQuote(
											loading,
											'amortization.inTerm',
											'amortization.quotaIsMaintained',
											t('amortization.reducesMonthsDescription', {
												months: result === undefined ? '-' : result?.diferenceTerms,
												date: result === undefined ? '-' : result?.endDateWithReducedTerms
											}),
											result === undefined ? '-' : result.totalSavingsWithReducedTerms
										)}
									</Tabs.TabPane>
									<Tabs.TabPane tab={t('amortization.inInstallment')} key="2">
										{cardQuote(
											loading,
											'amortization.inInstallment',
											'amortization.endDateRemains',
											t('amortization.newFeeDescription', {
												quote:
													result === undefined
														? '-'
														: numeral(result?.reducedQuote).format('0,0.00 $'),
												monthQuote:
													result === undefined
														? '-'
														: (result?.diferenceQuote).toFixed(2)
											}),

											result === undefined ? '-' : result.totalSavingsWithReducedQuote
										)}
									</Tabs.TabPane>
								</Tabs>
								<div style={{ display: 'flex', marginTop: 16 }}>
									<Text
										style={{ color: '#748EA0', fontSize: 12, fontWeight: 400, textAlign: 'left' }}>
										{t('amortization.textWarning')}
									</Text>
								</div>
							</>
						)}
					</Card>
				)}
				{location?.state?.valuesDefaultMenu && loanList.length === 0 && (
					<Card
						className="card-save-data-calculators"
						style={{ marginTop: isMobileOnly ? 16 : 40 }}>
						<div className="wrapper-save-data">
							<img
								src={bulp}
								alt="bulp"
								height={24}
								style={{ marginTop: isMobileOnly ? -55 : -15 }}
							/>
							<div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
								<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
									{t('amortization.notProblem')}
								</Text>
								<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
									{t('amortization.saveAlert')}
								</Text>
							</div>
						</div>
						<div
							className="wrapper-title-icon"
							style={{ cursor: 'pointer' }}
							onClick={() => {
								const values = {
									amount: parseInt(simulationData.pendingCapital),
									term: parseInt(simulationData.pendingQuotes),
									rateType: simulationData.typeInterestRate === 'FIXED' ? 'fixed' : 'variable',
									rate: parseFloat(simulationData.interestRate *100),
									amountToBeAmortized: parseInt(simulationData.amountToBeAmortized),
									//finish: moment(simulationData.endDateWithReducedTerms)
								};
								
								dispatch(getAmortizationData(values));
								dispatch(getPreviousPage('amortization-calculator'));
								navigate('/my-financial-profile/credits/add/loan',{
									state: { calcul: values }
								});
							}}>
							<div className="sub-title" style={{ fontFamily: 'Inter' }}>{t('onboarding.saveData')}</div>
							<img src={rightIcon} alt="right" height={16} style={{ marginTop: 3 }} />
						</div>
					</Card>
				)}
			</Col>
		</Row>
	);

	return (
		<>
			<div style={{ padding: isMobileOnly ? 16 : 40, marginBottom: 100 }}>
				<Row style={{ width: '100%' }}>
					<Col span={24}>
						<a
							onClick={() =>
								location?.state?.valuesCalculator?.id
									? navigate(
											`/my-financial-profile/credits/all-detail-loan/${location?.state?.valuesCalculator?.id}`
									  )
									: navigate('/calculators')
							}>
							<ArrowLeftOutlined style={{ color: '#2F4858', fontSize: 20 }} />
						</a>
						<Text
							style={{
								color: '#2F4858',
								fontSize: 16,
								fontWeight: 600,
								marginLeft: 16
							}}>
							{t('fee.calculatorAmortization')}
						</Text>
					</Col>
				</Row>

				{!isMobileOnly && (
					<Card className="gb-card" style={{ marginTop: 24 }}>
						{amortizationComponent}
					</Card>
				)}
				{isMobileOnly && <>{amortizationComponent}</>}
			</div>

			{/* START MODAL SECTION */}
			<Modal
				className="ModalMobileFooterColum"
				okButtonProps={{ style: { display: 'none' } }}
				open={visibleResult}
				title={t('m_viability.title')}
				width={480}
				cancelButtonProps={{ style: { display: 'none' } }}
				closable={true}
				onCancel={() => setVisibleResult(false)}
				centered>
				<div style={{ marginBottom: -24 }}>
					<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
						{t('calculator.amorticeModalText')}
					</Text>
				</div>
			</Modal>
			<Modal
				className="ModalCommon"
				okButtonProps={{ style: { display: 'none' } }}
				open={visibleWantAmortize}
				title={t('amortization.modalTitle')}
				width={928}
				cancelButtonProps={{ style: { display: 'none' } }}
				closable={true}
				onCancel={() => setVisibleWantAmortize(false)}
				centered>
				<>
					<div style={{ marginTop: -20 }}>
						<Text className="modal-want-amortize">{t('onboarding.modalText1')}</Text>&nbsp;
						<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
							{t('onboarding.modalText2')}
						</Text>
						&nbsp;
						<Text className="modal-want-amortize">{t('onboarding.modalText3')}</Text>
					</div>

					<div style={{ marginTop: 16 }}>
						<Text className="modal-want-amortize-title">{t('onboarding.modalQuestion1')}</Text>
						&nbsp;
					</div>
					<div>
						<Text className="modal-want-amortize">{t('onboarding.request1')}</Text>
					</div>

					<div style={{ marginTop: 8 }}>
						<Text className="modal-want-amortize-title">{t('onboarding.modalQuestion2')}</Text>
						&nbsp;
					</div>
					<div>
						<div
							className="modal-want-amortize"
							dangerouslySetInnerHTML={{
								__html: t('onboarding.request2')
							}}></div>
					</div>

					<div style={{ marginTop: 8 }}>
						<Text className="modal-want-amortize-title">{t('onboarding.modalQuestion3')}</Text>
						&nbsp;
					</div>
					<div>
						<div
							className="modal-want-amortize"
							dangerouslySetInnerHTML={{
								__html: t('onboarding.request3')
							}}></div>
					</div>

					<div style={{ marginTop: 8 }}>
						<Text className="modal-want-amortize-title">{t('onboarding.modalQuestion4')}</Text>
						&nbsp;
					</div>
					<div>
						<div
							className="modal-want-amortize"
							dangerouslySetInnerHTML={{
								__html: t('onboarding.request4')
							}}></div>
					</div>

					<div style={{ marginTop: 8 }}>
						<Text className="modal-want-amortize-title">{t('onboarding.modalQuestion5')}</Text>
						&nbsp;
					</div>
					<div>
						<Text className="modal-want-amortize">{t('onboarding.request5')}</Text>
					</div>

					<div style={{ marginTop: 24 }}>
						<Text style={{ fontSize: 12, color: '#748EA0', fontWeight: 400 }}>
							{t('onboarding.modalTextfinihs')}
						</Text>
					</div>

					<div style={{ marginTop: 20, textAlignLast: 'center', marginBottom: -30 }}>
						<Button
							className="button-primari-gibobs"
							type="primary"
							onClick={() => setVisibleWantAmortize(false)}>
							{t('amortization.modalButton')}
						</Button>
					</div>
				</>
			</Modal>
		</>
	);
};

export default CalculatorAmortization;
