import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";

import {
    DoubleRightOutlined,
    MailOutlined,
    LockOutlined,
    UserOutlined,
    PhoneOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import axios from "axios";
import env from "../../environment";
import moment from "moment";
import numeral from "numeral";



const { Text } = Typography;

const ProfileHeadlines = ({ userMortgageId }) => {
    const { t } = useTranslation();


    const userToken = useSelector((state) => state.auth.token);

    const [valuesData, setValuesData] = useState([]);

    useEffect(() => {

        dataValueInfoUpload()
    }, []);

    const dataValueInfoUpload = () => {
        axios
            .get(
                `${env.api.url}/v1/operations/b2b-resume/${userMortgageId}`,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            .then((response) => {
                setValuesData(response.data.data);
            })
            .catch((error) => { 
                console.log(error)
            });
    };


    return (
        <div>
            {valuesData.createdAt ?
                <div>
                    <div>
                        <Text style={{ fontSize: "12px" }}>
                            {/*t("details.info")*/}
                            <div style={{ marginTop: -120, marginLeft: 500, display: "flex", }}>{t("details.create")} <div style={{ color: "#02C3CD" }}> {moment(valuesData.createdAt).format(
                                "L"
                            )}</div></div>
                        </Text>
                        <Text style={{ fontSize: "12px" }}>
                            {/*t("details.info")*/}
                            <div style={{ marginTop: 20, marginLeft: 500 }}>{valuesData.close.date !== null ? t("details.close") : null} {valuesData.close.date !== null ? moment(valuesData.close.date).format("L") : ""}</div>
                        </Text>
                        {valuesData.status === "open" ?
                            <div style={{ display: "flex" }}>
                                <DoubleRightOutlined style={{ color: 'green', fontSize: 16, float: "left", marginTop: valuesData.close.date !== null ? -55 : -35, marginLeft: 140 }}></DoubleRightOutlined>
                                <Text style={{ fontSize: "12px", color: 'green', marginTop: valuesData.close.date !== null ? -55 : -35, marginLeft: 5}}>
                                    {t("details.open")}
                                </Text>
                            </div>
                            :
                            <div style={{ display: "flex" }}>
                                <LockOutlined style={{ color: '#1890FF', fontSize: 16, float: "left", marginTop: -55, marginLeft: 180 }}></LockOutlined>
                                <Text style={{ fontSize: "12px", color: '#1890FF', marginTop: -55, marginLeft: 5}}>
                                    {t("details.close")}
                                </Text>
                            </div>

                        }
                    </div>


                    <div style={{ marginTop: 150, marginLeft: -25 }}>
                        <Text style={{ fontSize: "12px" }}>
                            {/*t("details.info")*/}
                            <div style={{ marginTop: -120, marginLeft: 65 }}>{valuesData.client !== undefined ? valuesData.client.name : undefined}</div>
                        </Text>
                        <UserOutlined style={{ fontSize: 16, float: "left", marginTop: -18, marginLeft: "40px", color: "#02C3CD" }} />

                        <Text style={{ fontSize: "12px" }}>
                            {/*t("details.info")*/}
                            <div style={{ marginTop: 18, marginLeft: 40, marginLeft: "65px" }}>{valuesData.client !== undefined ? valuesData.client.email : undefined}</div>
                        </Text>
                        <MailOutlined style={{ fontSize: 16, float: "left", marginTop: -18, marginLeft: "40px", color: "#02C3CD" }} />
                        <div style={{ float: "left", marginTop: "5px", marginLeft: 40, marginBottom: 10 }}>
                            <Text style={{ fontSize: "12px" }}>
                                {/*t("details.info")*/}
                                <div style={{ marginTop: 13, marginLeft: 25 }}>{valuesData.client !== undefined ? valuesData.client.phone : undefined}</div>
                            </Text>
                            <PhoneOutlined rotate="90" style={{ fontSize: 16, float: "left", marginTop: -18, color: "#02C3CD" }} />
                        </div>
                    </div>

                    <div style={{ marginTop: 220, marginLeft: -25 }}>
                        <Text style={{ fontSize: "12px" }}>
                            {/*t("details.info")*/}
                            <div style={{ marginTop: -160, marginLeft: 40 }}>{t("details.price")} {valuesData.operation !== undefined ? <Text style={{fontWeight: 500}}>{numeral(valuesData.operation.amount).format("0,0 $") }</Text>: undefined}</div>
                        </Text>

                        <Text style={{ fontSize: "12px" }}>
                            {/*t("details.info")*/}
                            <div style={{ marginTop: 20, marginLeft: 40 }}>{t("details.direction")} {valuesData.operation !== undefined ? <Text style={{fontWeight: 500}}>{valuesData.operation.address}</Text> : undefined}</div>
                        </Text>

                        {valuesData.operation !== undefined && valuesData.operation.propertyType === "" || valuesData.operation.propertyType === "secondHand" &&
                            <div>
                                <Text style={{ fontSize: "12px" }}>
                                    {/*t("details.info")*/}
                                    <div style={{ marginTop: 18, marginLeft: 40 }}> {t("details.status")} {valuesData.operation.propertyType === "secondHand" ? <Text style={{fontWeight: 500}}>{t("details.secondHand")} -</Text> : ""}</div>
                                </Text>
                                <Text style={{ fontSize: "12px", position: "relative", top: -37, left: 220 }}>
                                    {/*t("details.info")*/}
                                    <div style={{ marginTop: 19, marginLeft: 55 }}>{t("details.signed")} {valuesData.operation.houseArras === true ? <Text style={{ color: "#02C3CD" }}>Si</Text> : <Text style={{fontWeight: 500, color: "#02C3CD" }}>No</Text>}</div>

                                </Text>
                            </div>
                        }

                        {valuesData.operation !== undefined && valuesData.operation.propertyType === "newConstruction" &&
                            <div>
                                <Text style={{ fontSize: "12px" }}>
                                    {/*t("details.info")*/}
                                    <div style={{ marginTop: 18, marginLeft: 40 }}>Tipo de vivienda: </div>
                                </Text>
                                <Text style={{ fontSize: "12px" }}>
                                    {/*t("details.info")*/}
                                    <div style={{ marginTop: -18, marginLeft: 145 }}>{t("details.new")}</div>
                                </Text>

                                <Text style={{ fontSize: "12px" }}>
                                    {/*t("details.info")*/}
                                    <div style={{ marginTop: -18, marginLeft: 230 }}>{t("details.reserved")} {valuesData.operation.houseBook === true ?  <Text style={{ color: "#02C3CD" }}>: Si</Text> : <Text style={{ color: "#02C3CD" }}>: No</Text>}</div>

                                </Text>
                                <Text style={{ fontSize: "12px" }}>
                                    {/*t("details.info")*/}
                                    <div style={{ marginTop: -18, marginLeft: 370 }}>{t("details.buy2")} {valuesData.operation.houseBook === true ?  <Text style={{ color: "#02C3CD" }}>: Si</Text> : <Text style={{ color: "#02C3CD" }}>: No</Text>}</div>

                                </Text>
                            </div>
                        }


                    </div>
                    {valuesData.operation !== undefined && valuesData.operation.description !== null &&
                        <div style={{ marginTop: 180, marginLeft: -25 }}>
                            <Text style={{ fontSize: "14px", fontWeight: 500 }}>
                                {/*t("details.info")*/}
                                <div style={{ marginTop: -120, marginLeft: 40 }}>{t("details.desc")}</div>
                            </Text>
                            <Text style={{ fontSize: "12px" }}>
                                <div style={{ marginTop: 20, marginLeft: 40, color: "#748EA0" }}>{valuesData.operation.description}</div>

                            </Text>

                            <Text  style={{ fontSize: "14px", fontWeight: 500}}>
                                {/*t("details.info")*/}
                                <div style={{ marginTop: 40, marginLeft: 40 }}>{t("details.otherData")}</div>
                            </Text>
                            <Text style={{ fontSize: "12px" }}>
                                <div style={{ marginTop: 20, marginLeft: 40, color: "#748EA0" }}>{valuesData.operation.otherdata}</div>

                            </Text>


                        </div>
                    }

                    {valuesData.status === "closed" &&
                        <div style={{ marginTop: 180, marginLeft: -25 }}>
                            <Text strong style={{ fontSize: "14px" }}>
                                {/*t("details.info")*/}
                                <div style={{ marginTop: -120, marginLeft: 40 }}>{t("docuInmo.close")}</div>
                            </Text>
                            <Text style={{ fontSize: "12px" }}>
                                {/*t("details.info")*/}
                                <div strong style={{ marginTop: 10, marginLeft: 40, fontWeight: 600 }}>{t("docuInmo.cause")}{valuesData.close !== undefined ? <Text>{valuesData.close.reason}</Text> : ""}</div>
                            </Text>
                            <Text style={{ fontSize: "12px" }}>
                                {/*t("details.info")*/}
                                <div strong style={{ marginTop: 10, marginLeft: 40, fontWeight: 600 }}>{t("docuInmo.subcause")}{valuesData.close !== undefined ? <Text>{valuesData.close.subreason}</Text> : ""}</div>
                            </Text>
                            <Text style={{ fontSize: "12px" }}>
                                {/*t("details.info")*/}
                                <div strong style={{ marginTop: 30, marginLeft: 40, fontWeight: 600 }}>{t("docuInmo.desc")}</div>
                            </Text>
                            <Text style={{ fontSize: "12px" }}>
                                {/*t("details.info")*/}
                                < div style={{ marginTop: 10, marginLeft: 40 }}  dangerouslySetInnerHTML={{ __html: valuesData.close !== undefined ? valuesData.close.description : ""}}/>
                            </Text>


                        </div>
                    }
                </div>
                : null}
        </div>
    );
};

export default ProfileHeadlines;
