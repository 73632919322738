import React, { useState, useEffect } from 'react';
import {
	Tabs,
	Button,
	Badge,
	Progress,
	Typography,
	Modal,
	Row,
	Col,
	Card,
	notification,
	message,
	Skeleton
} from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined, LikeOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import RenderCards from './components/RenderCards';
import DeleteDocumentModal from './modals/DeleteDocumentModal';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { commonNotifications } from './CommonItems';
import { getTextFromDocumentsDownload } from './RenderTexts';
import LoaderLogo from './components/LoaderLogo';
import Afterbanks from './AfterBanks/Afterbanks';
import AutomaticDocumentationStartupModal from './AutomaticDocumentation/Modal/AutomaticDocumentationStartupModal'; //TODO: añadir onboarding

const { TabPane } = Tabs;
const { Text } = Typography;

type Props = {
	userMortgageId: any;
	stage: any;
	env: any;
	images: Images;
	platform: string;
	traceSadabdell: any;
	eventSabadell: any;
	setDocsNumber: any;
};

type Images = {
	tgss: any;
	aeat: any;
	Afterbanks: any;
	LoaderLogo: any;
	upIcon: any;
	tgssDisabled: any;
	aeatDisabled: any;
	tesoro: any;
	agencia: any;
	plus: any;
	banco: any;
};

export type ReduxStore = {
	auth: StoreAuth;
	data: any;
};

export type StoreAuth = {
	token: string;
	roles: string;
	operations: any;
};

export const DocumentBoxesComponent: React.FC<Props> = ({
	userMortgageId,
	stage,
	platform,
	env,
	traceSadabdell,
	eventSabadell,
	images: { tgss, aeat, upIcon, tgssDisabled, aeatDisabled, tesoro, agencia, plus, banco },
	setDocsNumber
}: Props) => {
	const { t, i18n } = useTranslation();
	const [saveFileDataField, setSaveFileDataField] = React.useState<any>({});
	const userToken = useSelector<ReduxStore, string>((state) => state.auth.token);
	const [valuesData, setValuesData] = useState([]);
	const [valuesDataForMortgage, setValuesDataForMortgage] = useState(undefined);
	const [participants, setParticipants] = useState<any>({});
	const [tags, setTags] = useState([]);
	const [clickTab, setClickTab] = useState<string | undefined>(undefined);
	const [percentN, setPercentN] = useState<number>(0);
	const [visibleUploadModal, setVisibleUploadModal] = useState(false);
	const [visibleAfterBanksModal, setVisibleAfterBanksModal] = useState(false);
	const [nameOperations, setNameOperations] = useState<any>(undefined);
	let countShowMessage = 0;
	const [loading, setLoading] = useState(false);
	const [widgetLink, setWidgetLink] = useState<any>(undefined);
	const [modalBankFlip, setModalBankFlip] = useState(false);
	let timerReference: NodeJS.Timer;
	const [completedBankFlip, setCompletedBankFlip] = useState(false);
	const [loading2, setLoading2] = useState<any>(false);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [deleteDoc, setDeleteDoc] = useState(undefined);
	const [movementBank, setMovementBank] = useState(false);
	const [availableButton, setAvailableButton] = useState(false);
	const [documentsDownload, setDocumentsDownload] = useState(false);
	const navigate = useNavigate();

	const [noTypeFile, setNoTypeFile] = useState(undefined);
	const [count, setCount] = useState(0);

	let counter = 0; //TODO: que es esto??? Esto está mal!!!!!

	useEffect(() => {
		if (stage === 'documents') {
			traceSadabdell(
				stage === 'documents' ? 'documentacion prioritaria' : 'documentacion adicional',
				i18n.language === 'cat' ? 'ca' : 'es'
			);
		}
		if (stage === 'analysis') {
			traceSadabdell(
				stage === 'documents' ? 'documentacion prioritaria' : 'documentacion adicional',
				i18n.language === 'cat' ? 'ca' : 'es'
			);
			eventSabadell('documentacion adicional');
		}

		window.scrollTo(0, 0);
		dataValueInfoUpload('');

		axios
			.get(`${env.api.url}/v1/mortgages/data/get/${userMortgageId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setParticipants(response.data.data);
			})
			.catch((error) => {});

		getDataOperation();
	}, []);

	useEffect(() => {
		if (count === 30) {
			notification.error({
				message: t('m_common.requestErrorTitle'),
				description: t('time.ToUpload')
			});
			clearInterval(timerReference);
		}
	}, [count]);

	useEffect(() => {
		documentsRefresh();
	}, [participants]);

	useEffect(() => {
		if (modalBankFlip && widgetLink) {
			timerReference = setInterval(() => {
				getBankFlipProcess();
			}, 2000);

			if (completedBankFlip) {
				clearInterval(timerReference);
			}
		}
	}, [widgetLink, modalBankFlip]);

	useEffect(() => {
		documentsRefresh();
	}, [clickTab, stage]);

	useEffect(() => {
		if (
			valuesDataForMortgage &&
			valuesDataForMortgage?.documentsCompleted !== undefined &&
			valuesDataForMortgage?.documentsNeeded !== undefined &&
			valuesDataForMortgage?.documentsCompleted === valuesDataForMortgage?.documentsNeeded &&
			stage === 'documents'
		) {
			navigate(`/operations/${userMortgageId}/analysis`);
		} else {
			if (
				valuesDataForMortgage &&
				valuesDataForMortgage?.documentsCompleted !== undefined &&
				valuesDataForMortgage?.documentsNeeded !== undefined &&
				valuesDataForMortgage?.documentsNeeded > valuesDataForMortgage?.documentsCompleted
			) {
				navigate(`/operations/${userMortgageId}/documents`);
			}
		}
	}, [valuesDataForMortgage]);

	const getDataOperation = () => {
		axios
			.get(`${env.api.url}/v1/operations/${userMortgageId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setNameOperations(response.data.data);
			})
			.catch((error) => {});
	};

	const autoStageGenerator = () => {
		axios
			.get(`${env.api.url}/v1/auto-things/AutoStagesGenerator/${userMortgageId}`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				if (response.data.success) {
					if (stage !== 'oferts' && stage !== 'appraisal') {
						navigate(`/operations/${userMortgageId}/${response.data.data.currentStage}`);
					}
					setLoading2(false);
				}
			});
	};

	const getBankFlipProcess = () => {
		axios
			.get(
				`${env.api.url}/docs/bankflip/check-provider-reference/${widgetLink.providerReferences.id}`,
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.data.data.flags.sessionCompleted) {
					setCompletedBankFlip(true);
					setModalBankFlip(false);
					clearInterval(timerReference);

					documentsRefresh();
					dataValueInfoUpload('');
				}
			})
			.catch((error) => {});
	};

	const calculateTab = (docs: any) => {
		const numParticipants = parseInt(_.get(participants, 'mortgage.participants', '0'));
		let tab = undefined;

		for (let i = 1; i <= numParticipants; i++) {
			if (docs?.documents) {
				// eslint-disable-next-line no-loop-func
				docs.documents.forEach((data: any) => {
					if (data.completed[`owner${i}`] === false) {
						if (data.participants[`owner${i}`] === 'required') {
							tab = `owner${i}`;
							if (clickTab === undefined) {
								setClickTab(`owner${i}`);
								return tab;
							}
						}
						if (clickTab === undefined && stage === 'analysis') {
							setClickTab('owner1');
							tab = `owner1`;
							return tab;
						}
					}
				});
			}
			if (tab) {
				if (clickTab) {
					return clickTab;
				}
				return tab;
			}
			if (tab === undefined && i === numParticipants) {
				if (clickTab) {
					setClickTab(clickTab);
					tab = clickTab;
					return tab;
				} else {
					setClickTab(`owner${i}`);
					tab = `owner${i}`;
					return tab;
				}
			}
		}

		if (tab) {
			if (clickTab) {
				return clickTab;
			}
			return tab;
		}
	};

	const documentsRefresh = () => {
		axios
			.get(`${env.api.url}/v1/documents/request-operation-documentation/${userMortgageId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then(async (response) => {
				let tab: any = undefined;
				tab = await calculateTab(response.data.data);
				if (tab) {
					response.data.data.documents = _.orderBy(
						response.data.data.documents,
						[`participants[${tab}]`, 'required'],
						['desc', 'asc']
					);

					setValuesDataForMortgage(response.data.data);
					const tmpbank = _.find(response.data.data.documents, function (o) {
						if (
							o.name === 'bankreader' &&
							((o.participants[tab] === 'required' && stage === 'documents') ||
								(o.participants[tab] === 'optional' && stage === 'analysis'))
						) {
							return true;
						}
					});
					setMovementBank(tmpbank);

					const tmpDownload = _.find(response.data.data.documents, function (o) {
						if (
							(o.name.includes('irpf') ||
								o.name.includes('laboralLife') ||
								o.name.includes('bankreader') ||
								o.name.includes('simpleNote')) &&
							(o.participants[tab] === 'required' || o.participants[tab] === 'optional')
						) {
							return true;
						}
					});
					setDocumentsDownload(tmpDownload);

					const tmpButton = _.find(response.data.data.documents, function (o) {
						if (
							(o.name === 'irpf' ||
								o.name === 'irpf2' ||
								o.name === 'laboralLife' ||
								o.name === 'statement130' ||
								o.name === 'statement303' ||
								o.name === 'statement390') &&
							o.participants[tab] === 'required' &&
							o.completed[tab] === false
						) {
							return true;
						}
					});

					const tmpButton2 = _.find(response.data.data.documents, function (o) {
						if (
							(o.name === 'irpf' ||
								o.name === 'irpf2' ||
								o.name === 'laboralLife' ||
								o.name === 'statement130' ||
								o.name === 'statement303' ||
								o.name === 'statement390') &&
							(o.participants[tab] === 'required' || o.participants[tab] === 'optional') &&
							o.completed[tab] === false
						) {
							return true;
						}
					});

					if (stage === 'documents') {
						setAvailableButton(tmpButton?.participants === undefined ? true : false);
					}

					if (
						stage === 'analysis' ||
						stage === 'banks' ||
						stage === 'oferts' ||
						stage === 'appraisal'
					) {
						setAvailableButton(tmpButton2?.participants === undefined ? true : false);
					}

					setPercentN(
						(response.data.data.documentByParticipant[tab].completed * 100) /
							response.data.data.documentByParticipant[tab].needed
					);
					if (
						response.data.data.documentByParticipant[tab].needed -
							response.data.data.documentByParticipant[tab].completed ===
						0
					) {
						autoStageGenerator();
					}
				}
				const documentsData = response.data.data;
				const optionalDocs = [];
				documentsData.documents.forEach((doc: any) => {
					const participants = doc.participants;
					const completed = doc.completed;
					for (let participant in participants) {
						if (participants[participant] === 'optional') {
							if (completed?.[participant] === false) {
								optionalDocs.push(doc);
								break;
							}
						}
					}
				});
				if (stage === 'documents') {
					setDocsNumber(documentsData.documentsNeeded - documentsData.documentsCompleted);
				} else {
					setDocsNumber(optionalDocs.length);
				}
			})
			.catch((error) => {});
	};

	const dataValueInfoUpload = (value: any) => {
		if (value === 'consent') {
			eventSabadell('consentimiento');
		}
		axios
			.get(`${env.api.url}/v1/documents/get-operation-documents/${userMortgageId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				const unsorted = response.data.data;

				const sorted = unsorted
					.sort(
						(a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
					)
					.reverse();

				setValuesData(sorted);

				const file = _.find(sorted, function (b) {
					return b.type === null;
				});
				setNoTypeFile(file);
			})
			.catch((error) => {});
	};

	const saveFileData = (file: any, name: any, index: any) => {
		const documentFile: any[] = [saveFileDataField];
		documentFile.push({
			file,
			fileName: name
		});
		setSaveFileDataField(documentFile);
		handleUploadFile(documentFile, index);
	};

	const uploadfiles = (file: any, data: any, index: any) => {
		handleClickPlus(data.name);
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);

			if (file.size < 50000000) {
				reader.onload = () => {
					const base64File = reader.result?.toString().split(',')[1];
					saveFileData(base64File, file.name, index);
				};
			} else {
				commonNotifications('error', t('document.waitUploadError'), t('documentation.tooHeavy'));
			}
		});
	};

	const playToBeforeUpload = (file: any, data: any, index: any) => {
		if (file.name.includes('.xlsx') && platform !== 'sabadell') {
			commonNotifications('error', t('document.waitUploadError'), t('documentation.excelFiles'));
			return;
		} else if (file.name.includes('.xlsx') && platform === 'sabadell') {
			uploadfiles(file, data, index);
		} else {
			uploadfiles(file, data, index);
		}
	};

	const deleteDocument = (documentId: any) => {
		axios
			.get(`${env.api.url}/v1/documents/delete-document/${documentId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then(() => {
				dataValueInfoUpload('');
				documentsRefresh();
			})
			.catch((error) => {});
	};

	const modalDocument = (documentId: any) => {
		setDeleteDoc(documentId);
		setDeleteOpen(true);
	};

	const renderDocumentInformation = () => {
		const callToBankFlip = (id: any) => {
			eventSabadell('conseguir aqui');
			axios
				.post(
					`${env.api.url}/docs/bankflip/create-session`,
					{
						operationId: id,
						personKey: clickTab
					},
					{
						headers: { Authorization: `Bearer ${userToken}` }
					}
				)
				.then((response) => {
					setWidgetLink(response.data.data);
					setModalBankFlip(true);
				});
		};

		const buttonsAutomaticDocs = () => {
			return (
				<div
					style={{ display: !isMobileOnly && 'flex', alignItems: 'center' }}
					className={'buttons-automatic-doc'}>
					<div
						style={{
							width: '100%',
							alignItems: 'center',
							display: 'flex',
							justifyContent: 'space-between',
							flexWrap: 'wrap'
						}}>
						<div
							style={{
								display: !isMobileOnly && 'flex',
								alignItems: 'center',
								textAlign: isMobileOnly && 'center'
							}}>
							<Text style={{ fontSize: 16, fontWeight: 500, color: '#212121' }}>
								{t('doc.saveTime')}
							</Text>
							<Text
								style={{
									fontSize: 16,
									fontWeight: 700,
									color: '#212121'
								}}>
								&nbsp;{t('doc.officers')}
							</Text>

							<img
								src={tgss}
								alt="tgss"
								style={{ height: isMobile ? 21 : 24, marginLeft: 8 }}></img>
							<img
								src={aeat}
								alt="aeat"
								style={{ height: isMobile ? 21 : 24, marginLeft: 8, marginRight: 24 }}></img>
						</div>
					</div>
					<div
						style={{
							display: isMobile && 'flex',
							justifyContent: 'center',
							marginTop: isMobile && 16
						}}>
						<Button
							disabled={availableButton}
							className="button-primari-gibobs"
							onClick={() => callToBankFlip(nameOperations.id)}
							type="primary"
							style={{ height: 'auto' }}>
							{t('doc.newBankflipButton')}
							<DownloadOutlined />
						</Button>
					</div>
				</div>
			);
		};

		return (
			<>
				<div
					style={{
						marginLeft: stage === 'documents' ? '15px' : 0,
						display: 'flex',
						alignItems: 'center'
					}}>
					{(stage === 'documents' || stage === 'analysis') && (
						<div
							style={{
								width: '48px',
								height: '48px'
							}}>
							<Progress
								type="circle"
								percent={parseInt(
									(percentN === 100 || percentN === 0
										? percentN
										: parseFloat(percentN!.toString()).toFixed()
									).toString()
								)}
								className="progress-circle-documents"
								format={(percent) => (
									<div style={{ marginTop: -4 }}>
										{valuesDataForMortgage && (
											<Text
												style={{
													fontSize: 12,
													color: '#2F4858',
													fontWeight: 500
												}}>{`${
												valuesDataForMortgage?.documentByParticipant[clickTab!]?.completed
											}/${valuesDataForMortgage?.documentByParticipant[clickTab!]?.needed}`}</Text>
										)}
									</div>
								)}
								width={48}
								strokeColor={platform === 'sabadell' ? '#212121' : '#2F4858'}
								trailColor={platform === 'sabadell' ? '#E0E0E0' : '#F1F7F8'}
								strokeWidth={14}
							/>
						</div>
					)}
					<div
						style={{
							color: '#2F4858',
							fontSize: '12px',
							marginLeft: isMobile ? 8 : 16
						}}>
						{participants[clickTab!] !== undefined && (
							<Text style={{ fontSize: 14, fontWeight: 400, color: '#2F4858' }}>
								{isMobileOnly ? t('file.mobileOf') + ' ' : t('document.fileUpload') + ' '}
								<span
									style={{
										color: '#2F4858',
										fontWeight: platform === 'sabadell' ? 700 : 500,
										fontSize: 14
									}}>
									{participants[clickTab!].name}
								</span>
							</Text>
						)}
					</div>
				</div>
				{!availableButton && <div style={{ marginTop: 16 }}>{buttonsAutomaticDocs()}</div>}
			</>
		);
	};

	const notificationB = () => {
		return (
			<div style={{ float: 'right', marginTop: '1px', marginRight: '-5px' }}>
				<Badge
					size="small"
					color="#FF4D4F"
					dot={true}
					style={{
						float: 'right',
						width: '4px',
						height: '6px',
						marginLeft: '4px'
					}}></Badge>
			</div>
		);
	};

	const sendToSabadell = (tag: any) => {
		if (tag === 'nif') {
			eventSabadell('dnipasaporte');
		} else if (tag === 'laboralLife') {
			eventSabadell('informe vida laboral');
		} else if (tag === 'irpf') {
			eventSabadell('IrpfRenta2021');
		} else if (tag === 'bankreader') {
			eventSabadell('movimientos bancarios');
		} else if (tag === 'payroll_2') {
			eventSabadell('nominas');
		} else if (tag === 'contract') {
			eventSabadell('contrato laboral');
		} else if (tag === 'docs.othersDocs') {
			eventSabadell('otros documentos');
		} else if (tag === 'irpf2') {
			eventSabadell('irpfrenta 2022');
		}
	};

	const handleUploadFile = (file: any, index: any) => {
		const loadingArray: any = {
			index: index,
			loading: true
		};
		//const parts = file[1].fileName.split('.');
		// jpg, jpeg, png, pdf, xlsx, doc, docx, heic, webp

		if (
			file[1].fileName.includes('.jpg') ||
			file[1].fileName.includes('.jpeg') ||
			file[1].fileName.includes('.png') ||
			file[1].fileName.includes('.pdf') ||
			file[1].fileName.includes('.xlsx') ||
			file[1].fileName.includes('.doc') ||
			file[1].fileName.includes('.docx') ||
			file[1].fileName.includes('.heic') ||
			file[1].fileName.includes('.webp')
		) {
			let timerReference: any = undefined;

			setLoading2(loadingArray);
			//inicio contador 30 seg
			timerReference = setInterval(() => {
				counter = counter + 1;
				setCount(counter);
			}, 1000);

			axios
				.put(
					`${env.api.url}/v1/documents/operation/attachment`,
					{
						operationId: userMortgageId,
						file: file[1].file,
						fileName: file[1].fileName,
						owner: clickTab,
						tags: index === 'othersDocs' ? undefined : tags,
						type: index === 'othersDocs' ? undefined : tags[0],
						page: index === 'othersDocs' ? undefined : 't'
					},
					{
						headers: { Authorization: `Bearer ${userToken}` }
					}
				)
				.then((response) => {
					//quitar contador
					if (response.data.success) {
						clearInterval(timerReference);
						dataValueInfoUpload('');
						documentsRefresh();
						if (countShowMessage === 0) {
							sendToSabadell(tags[0]);
						}
						if (platform === 'sabadell') {
							setLoading2(false);
							message.success({
								style: {
									border: '2px solid #239D45',
									width: 'fit-content',
									margin: '0 auto',
									background: '#fff',
									borderRadius: 4,
									textAlign: 'left'
								},
								content: (
									<Text style={{ fontSize: 16, color: '#239D45', fontWeight: 500 }}>
										{t('doc.notiNew')}
										<br />
										<span
											style={{ fontSize: 14, fontWeight: 400, color: '#212121', marginLeft: 24 }}>
											{t('doc.notiNew2')}
										</span>
									</Text>
								),
								icon: <LikeOutlined style={{ color: '#239D45' }} />
							});
						} else {
							setLoading2(false);
						}
					}
				})
				.catch(() => {
					setLoading(false);
					setLoading2(false);
					message.error({
						style: {
							border: '2px solid #BE0028',
							width: 'fit-content',
							margin: '0 auto',
							background: '#fff',
							borderRadius: 4,
							textAlign: 'left'
						},
						content: (
							<Text style={{ fontSize: 16, color: '#BE0028', fontWeight: 500 }}>
								{t('doc.errorOcurred')}
								<br />
								<span style={{ fontSize: 14, fontWeight: 400, color: '#212121', marginLeft: 24 }}>
									{t('doc.reuploadDocument')}
								</span>
							</Text>
						),
						icon: <ExclamationCircleOutlined style={{ color: '#BE0028' }} />
					});
				});
			countShowMessage = 0;
		} else {
			message.error({
				style: {
					border: '2px solid #BE0028',
					width: 'fit-content',
					margin: '0 auto',
					background: '#fff',
					borderRadius: 4,
					textAlign: 'left'
				},
				content: (
					<Text style={{ fontSize: 16, color: '#BE0028', fontWeight: 500 }}>
						{t('doc.errorOcurred')}
						<br />
						<span style={{ fontSize: 14, fontWeight: 400, color: '#212121', marginLeft: 24 }}>
							{t('files.allowedFormats')}
						</span>
					</Text>
				),
				icon: <ExclamationCircleOutlined style={{ color: '#BE0028' }} />
			});
		}
	};

	const handleClickPlus = (idowner: any) => {
		const array: any = [idowner];
		setTags(array);
	};

	const handleGetDocuments = () => {
		setVisibleUploadModal(true);
		setVisibleAfterBanksModal(false);
		dataValueInfoUpload('');
		documentsRefresh();
	};

	const renderTabs = (index: any, type: any) => {
		let requireFlag = false;
		if (valuesDataForMortgage?.documents) {
			valuesDataForMortgage.documents.forEach((data: any) => {
				if (data.completed[`${type}${index}`] === false) {
					if (data.participants[`${type}${index}`] === 'required') {
						requireFlag = true;
					}
				}
			});
		}

		return (
			<TabPane
				className="gb-tabpane"
				tab={
					<span>
						{requireFlag ? notificationB() : null}
						<Text className="tab-owner">{t(`mydocuments.${type}${index}`)}</Text>
					</span>
				}
				key={`${type}${index}`}>
				{renderDocumentInformation()}
			</TabPane>
		);
	};

	const numParticipants = parseInt(_.get(participants, 'mortgage.participants', '0'));
	const numGuarantors = parseInt(_.get(participants, 'mortgage.guarantors', '0'));

	const documentTabs: any = [];

	for (let i = 1; i <= numParticipants; i++) {
		documentTabs.push(renderTabs(i, 'owner'));
	}

	for (let i = 1; i <= numGuarantors; i++) {
		documentTabs.push(renderTabs(i, 'guarantor'));
	}
	const renderSkeleton = () => {
		const cardData = Array.from({ length: isMobileOnly ? 1 : 4 });
		return (
			<div style={{ width: '100%' }}>
				<Skeleton.Button
					active
					block={true}
					size="small"
					style={{ width: '100%', margin: !isMobile && '10px 0px' }}
				/>
				<Skeleton active paragraph={{ rows: 1 }} title={false} style={{ width: 250 }} />
				<div style={{ display: 'flex', gap: 16 }}>
					{cardData.map((_, index) => (
						<Card key={index} style={{ width: isMobileOnly ? '100%' : '50%' }}>
							<Skeleton active paragraph={{ rows: 4 }} title={false} />
						</Card>
					))}
				</div>
			</div>
		);
	};

	if (loading) {
		return (
			<div
				style={{
					textAlign: 'center',
					height: '80vh',
					backgroundColor: 'white'
				}}>
				<LoaderLogo />

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						position: 'relative',
						top: '45%'
					}}>
					<Text style={{ fontSize: 14, fontWeight: 400, color: '#4D4D4D' }}>
						{t('document.waitUpload') + '...'}
					</Text>
				</div>
			</div>
		);
	}

	return (
		<div className="gb-card-top-tab">
			{stage === 'analysis' && (
				<div className="wrapper-flex" style={{ marginBottom: 24 }}>
					<img
						src={upIcon}
						alt="upIcon"
						style={{ marginRight: 18, width: 40, height: 40, marginTop: -10 }}
					/>
					<div
						className="wrapper-flex"
						style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
						<div className="documents-title-analysis">{t('doc.allDocumentsUploaded')}</div>
						<div className="documents-title-analysis" style={{ color: '#748EA0', width: '100%' }}>
							{t('doc.WeNeedAnalyzeThemNow')}
						</div>
					</div>
				</div>
			)}
			{stage === 'banks' && platform === 'gibobs' && (
				<div className="wrapper-flex" style={{ justifyContent: 'center', marginBottom: 24 }}>
					<img
						src={banco}
						alt="upIcon"
						style={{ marginRight: 18, width: 32, height: 32, marginTop: -10 }}
					/>
					<div
						className="wrapper-flex"
						style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
						<div className="documents-title-banks">{t('bank.studing')}</div>
						<div
							className="documents-title-banks"
							style={{ color: '#748EA0', width: '100%', fontWeight: 400 }}>
							{t('bank.studing2')}
						</div>
					</div>
				</div>
			)}
			{clickTab && (
				<Tabs
					type="card"
					className="tabs-styles-documents"
					defaultActiveKey={clickTab}
					onChange={(values) => setClickTab(values)}>
					{documentTabs}
				</Tabs>
			)}
			<div style={{ marginTop: 16 }}>
				<Row gutter={[16, 16]}>
					{valuesDataForMortgage?.documents ? (
						valuesDataForMortgage.documents.map((data: any, index: any) => {
							return (
								<>
									{data.participants[clickTab!] === 'required' ? (
										<Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={6}>
											<Card
												key={index}
												className={
													data.completed[clickTab!] === false
														? 'gb-card-docs-not-up'
														: 'gb-card-docs-up'
												}
												style={{ height: '100%', minHeight: isMobile ? 88 : 96 }}>
												<RenderCards //TODO: repetido 3 veces????????!!!!!!
													data={data}
													clickTab={clickTab}
													index={index}
													handleClickPlus={handleClickPlus}
													playToBeforeUpload={playToBeforeUpload}
													visibleAfterBanksModal={visibleAfterBanksModal}
													setVisibleAfterBanksModal={setVisibleAfterBanksModal}
													loader={loading2.index === index && loading2.loading}
													valuesData={valuesData}
													modalDocument={(e: any) => modalDocument(e)}
													dataValueInfoUpload={(e: any) => dataValueInfoUpload(e)}
													documentsRefresh={() => documentsRefresh()}
													noTypeFile={undefined}
													env={env}
													images={{ tesoro, agencia, plus }}
													platform={platform}
												/>
											</Card>
										</Col>
									) : null}
								</>
							);
						})
					) : (
						<>{renderSkeleton()}</>
					)}
				</Row>
				{stage !== 'documents' && (
					<Row gutter={[16, 16]} style={{ marginTop: 24 }}>
						<Col span={24}>
							<div
								className="render-card-name"
								style={{ fontSize: 14, fontWeight: 700, color: '#212121' }}>
								{t('doc.additionalDocuments')}
							</div>
							<div className="subtitle-aditionals-documents">
								{t('doc.additionalDocumentsDescription')}
							</div>
						</Col>

						{valuesDataForMortgage?.documents ? (
							<>
								{valuesDataForMortgage.documents.map((data: any, index: any) => {
									return (
										<>
											{data.participants[clickTab!] === 'optional' ? (
												<Col xs={24} md={24} sm={12} lg={8} xl={8} xxl={6}>
													{console.log('adicionales', data.participants[clickTab!] === 'optional')}
													<Card
														key={index}
														className={
															data.completed[clickTab!] === false
																? 'gb-card-docs-not-up'
																: 'gb-card-docs-up'
														}
														style={{ height: '100%', minHeight: isMobile ? 88 : 96 }}>
														<RenderCards
															data={data}
															clickTab={clickTab}
															index={index}
															handleClickPlus={handleClickPlus}
															playToBeforeUpload={playToBeforeUpload}
															visibleAfterBanksModal={visibleAfterBanksModal}
															setVisibleAfterBanksModal={setVisibleAfterBanksModal}
															loader={loading2.index === index && loading2.loading}
															valuesData={valuesData}
															modalDocument={(e: any) => modalDocument(e)}
															dataValueInfoUpload={(e: any) => dataValueInfoUpload(e)}
															documentsRefresh={() => documentsRefresh()}
															noTypeFile={undefined}
															env={env}
															images={{ tesoro, agencia, plus }}
															platform={platform}
														/>
													</Card>
												</Col>
											) : null}
										</>
									);
								})}
								<Col xs={24} md={24} sm={12} lg={8} xl={8} xxl={6}>
									<Card
										key={'othersDocs'}
										className={noTypeFile === undefined ? 'gb-card-docs-not-up' : 'gb-card-docs-up'}
										style={{ height: '100%', minHeight: isMobile ? 88 : 96 }}>
										<RenderCards
											data={{
												name: 'docs.othersDocs',
												completed: { [clickTab!]: false }
											}}
											clickTab={clickTab}
											index={'othersDocs'}
											handleClickPlus={handleClickPlus}
											playToBeforeUpload={playToBeforeUpload}
											visibleAfterBanksModal={visibleAfterBanksModal}
											setVisibleAfterBanksModal={setVisibleAfterBanksModal}
											loader={loading2.index === 'othersDocs' && loading2.loading}
											valuesData={valuesData}
											modalDocument={(e: any) => modalDocument(e)}
											noTypeFile={noTypeFile}
											dataValueInfoUpload={(e: any) => dataValueInfoUpload(e)}
											documentsRefresh={() => documentsRefresh()}
											env={env}
											images={{ tesoro, agencia, plus }}
											platform={platform}
										/>
									</Card>
								</Col>
							</>
						) : (
							<>{renderSkeleton()}</>
						)}
					</Row>
				)}
			</div>
			{movementBank && (
				<>
					<Row style={{ marginTop: 24 }}>
						<Col>
							<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 500 }}>
								*{t('doc.rememberNew')}
							</Text>
						</Col>
					</Row>
					<Row>
						<Col>
							<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 300 }}>
								{t('doc.rememberNew2')}&nbsp;
							</Text>
							<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 500 }}>
								{t('doc.rememberNew3')}&nbsp;
							</Text>
							<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 300 }}>
								{t('doc.rememberNew4')}
							</Text>
						</Col>
					</Row>
				</>
			)}
			{documentsDownload &&
				getTextFromDocumentsDownload(
					platform,
					t('doc.doubtsHowFindDocTitle'),
					t('doc.doubtsHowFindDocDescription'),
					isMobileOnly
						? t('typeMortgage.text2TooltipMortgageMo')
						: t('typeMortgage.text2TooltipMortgage')
				)}
			<DeleteDocumentModal
				key={'deleteOpen'}
				visible={deleteOpen}
				onCreate={() => {
					deleteDocument(deleteDoc);
					setDeleteOpen(false);
				}}
				onCancel={() => {
					setDeleteOpen(false);
				}}
			/>
			<Modal
				className="ModalDesktopCert"
				okButtonProps={{ style: { width: '100%' }, className: 'button-primari-gibobs' }}
				okText={t('viability.continue')}
				cancelText={t('modal.cancelJump')}
				open={visibleUploadModal}
				title={<Text>{t('certificated.modal3mobileTitle1')}</Text>}
				width={480}
				cancelButtonProps={{ style: { width: '100%' }, className: 'button-secundary-gibobs' }}
				onOk={() => {
					setVisibleAfterBanksModal(true);
					setVisibleUploadModal(false);
				}}
				onCancel={() => {
					setVisibleUploadModal(false);
					dataValueInfoUpload('');
				}}>
				<div style={{ marginTop: 16 }}>
					<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
						{t('certificated.modal6mobileDesc1')}
					</Text>
				</div>
			</Modal>
			{widgetLink && (
				<Modal
					className="modalbankflip"
					open={modalBankFlip}
					onCancel={() => {
						setModalBankFlip(false);
						clearInterval(timerReference);
					}}
					okButtonProps={{
						style: { border: 'none', contentVisibility: 'hidden' }
					}}
					cancelButtonProps={{
						style: { border: 'none', contentVisibility: 'hidden' }
					}}
					destroyOnClose={true}
					closable={true}
					width={800}>
					<iframe
						width={'100%'}
						height={'800px'}
						src={widgetLink.widgetLink}
						style={{ marginTop: 0, border: 'none' }}></iframe>
				</Modal>
			)}
			<Modal
				className="modalMobileAfterBanks"
				open={visibleAfterBanksModal}
				onCancel={() => setVisibleAfterBanksModal(false)}
				onOk={() => {
					handleGetDocuments();
				}}
				cancelButtonProps={{
					style: { border: 'none', contentVisibility: 'hidden' }
				}}
				destroyOnClose={true}
				closable={true}>
				<Afterbanks type={'mortgage'} nameOwner={clickTab} env={env} />
			</Modal>
			{stage === 'documents' &&
				nameOperations &&
				nameOperations.accountId !== '65dc794201b91f4f002f078525' &&
				clickTab && (
					<AutomaticDocumentationStartupModal
						userMortgageId={userMortgageId}
						clickTab={'owner1'}
						mortgage={nameOperations}
						env={env}
						platform={platform}
						traceSadabdell={traceSadabdell}
					/>
				)}{' '}
		</div>
	);
};
