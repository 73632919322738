import React from 'react';
import Navigation from './components/navigation';
import { Layout } from 'antd';

const App = () => {

	return (
		<Layout className="pageLayout">
			<Navigation></Navigation>
		</Layout>
	);
};

export default App;
