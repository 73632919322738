import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import euro from '../../assets/loans/euro.png';
import roof from '../../assets/loans/roof.svg';
import amortization from '../../assets/loans/amortization.svg';
import arrowAmortization from '../../assets/loans/arrowAmortization.svg';
import arrowQuote from '../../assets/loans/arrowQuote.svg';
import arrowRoof from '../../assets/loans/arrowRoof.svg';
import { useNavigate } from 'react-router-dom';

const CalculatorsWidget = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const renderNav = (route) => {
		if (route === "/calculators/amortization") {
			navigate(`/calculators/amortization`,{
				state:{
					valuesDefaultMenu: {
						pendingCapital: 140000,
						pendingQuotes: 336,
						interestRateType: 'FIXED',
						actualTin: 2.5,
						amountToBeAmortized: 10000
					}
				}
			})
		}else {
			navigate(route)
		}
	
	}

	const typeOfCalculators = (logo, title, arrow, route, color) => {

		return (
			<div
				className="wrapper-type-calculator"
				onClick={() => renderNav(route)}
				style={{ backgroundColor: color }}>
				<div style={{ display: 'flex', columnGap: 6, alignItems: 'center' }}>
					<img src={logo} alt={'logo'} height={20} />
					<div className="text" style={{ fontFamily: 'Inter' }}>{t(title)}</div>
				</div>
				<img src={arrow} alt={'logo'} height={20} />
			</div>
		);
	};
	return (
		<Card className="init-card init-card-calculators init-card-24padding">
			<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
				<div className="calculators-widget-title" style={{ marginBottom: 4 }}>
					{t('menuComponent.Calculators')}
				</div>
				<div className="calculators-widget-subtitle" style={{ marginBottom: 32 }}>
					{t('init.widgetCalculatorsDescription')}
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%',
						rowGap: 8
					}}>
					{typeOfCalculators(
						euro,
						'init.widgetCalculatorsQuote',
						arrowQuote,
						'/calculators/fee',
						'#EFFCFC'
					)}
					{typeOfCalculators(
						roof,
						'init.widgetCalculatorsRoof',
						arrowRoof,
						'/calculators/financing',
						'#ECF7FC'
					)}
					{typeOfCalculators(
						amortization,
						'init.widgetCalculatorsAmortization',
						arrowAmortization,
						'/calculators/amortization',
						'#F1F7F8'
					)}
				</div>
			</div>
		</Card>
	);
};

export default CalculatorsWidget;
