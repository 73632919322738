import React from "react";
import { Form, Modal, Input, Col, Row, Select, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import numeral from "numeral";

const { Option } = Select;

function ModalAdd({ visible, onCancel, onCreate, dataEdit, showAddModal }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const title = {
    0: "balance.realeStateTitle",
    1: "balance.furniture",
    2: "balance.treasury",
    3: "balance.investments",
  };

  return (
    (<Modal
      className="ModalCommon"
      cancelButtonProps={{ className: "button-secundary-gibobs" }}
      okButtonProps={{ className: "button-primari-gibobs" }}
      open={visible}
      title={`${t("loan.addAsset")} ${t(title[showAddModal])}`}
      okText={t("form.send")}
      cancelText={t("mydocuments.deleteCancel")}
      width={672}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
            form.resetFields();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="Contacto"
        initialValues={
          dataEdit ? { name: dataEdit.name, active: dataEdit.value } : {}
        }
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="name"
              label={t("form.name")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                placeholder={t("form.name")}
                style={{
                  width: "280px",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="active"
              label={t("balance.currentValue")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                step={100}
                placeholder="Activos"
                formatter={(value) => numeral(value).format("0,0 $")}
                parser={(value) => numeral(value).value()}
                style={{
                  width: "280px",
                  borderColor: "#c0daee",
                  borderRadius: "4px",
                }}
              />
            </Form.Item>
          </Col>
          {showAddModal && showAddModal === 3 ? (
            <Col span={12}>
              <Form.Item
                name="liquidable"
                label="¿Es liquido?"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select>
                  <Option value="yes">{t("form.yes")}</Option>
                  <Option value="no">{t("form.no")}</Option>
                </Select>
              </Form.Item>
            </Col>
          ) : null}
          {showAddModal && showAddModal === 3 ? (
            <Col span={12}>
              <Form.Item name="porcentaje" label={t("form.percentLiq")}>
                <InputNumber
                  placeholder={t("form.percentLiq")}
                  style={{
                    width: "280px",
                    borderColor: "#c0daee",
                    borderRadius: "4px",
                  }}
                />
              </Form.Item>
            </Col>
          ) : null}
        </Row>
      </Form>
    </Modal>)
  );
}

export default ModalAdd;
