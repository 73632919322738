import React from "react";
import { Layout } from "antd";
import WidgetsRealState from "../components/widgets/DashBoard-Realestate/WidgetsRealState";
import { useSelector } from "react-redux";
import WidgetsDashboard from "../components/widgets/WidgetsDashboard";
import { isMobile, isMobileOnly } from "react-device-detect";
import DashboardPromoter from "./Promoter-loan/DashboardPromoter";
import LoaderLogo from "../components/LoaderLogo";

const DashBoardPage = () => {
  const rol = useSelector((state) => state.auth.roles);
  const allRoles = useSelector((state) => state.auth.allRoles);

  const renderDashBoard = () => {

    if (allRoles.includes("php")) {
      return <DashboardPromoter />;
    } else if (rol === "realestate") {
      return <WidgetsRealState />;
    } else {
      return <WidgetsDashboard />;
    }
  };

  const dashboard = allRoles.length === 0 ? <LoaderLogo /> : renderDashBoard()


  return (
    <Layout
      style={{
        marginTop:
          rol === "realestate" && isMobile
            ? 16
            : rol === "realestate" && !isMobile
              ? 0
              : rol === "friend"
                ? 0
                : isMobileOnly ? 0 : "40px",
        marginRight: isMobileOnly ? 0 : 30,
      }}
    >
      {dashboard}
    </Layout>
  );
};

export default DashBoardPage;
