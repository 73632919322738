import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  Layout,
  Form,
  Input,
  Button,
  Select,
  Card,
  Typography,
  Col,
  Row,
  Avatar,
  Upload,
  message,
  Image,
  Spin,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { renderComponent } from "../select/typeSelect";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import env from "../environment";
import ChangePassword from "./Operaciones/changePassword";
import { callAct, changeRol } from "../store/auth/actions";
import { dataProfile } from "../store/data/actions";
import { useTranslation } from "react-i18next";
import CompanySpace from "./CompanySpace";
import { isMobile } from "react-device-detect";
import { LoadingOutlined } from "@ant-design/icons";
import AvatarGenerico2 from "../assets/icons/foto_usuario.png";
import { notificationGibobs } from "./HerramientasVenta/reusableItems";
import { InputDate } from 'afrodita';

import dayjs from 'dayjs';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');
dayjs.locale('vi');

const { Option } = Select;
const { Text } = Typography;

const PersonalSpace = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();;
  const changeCallAct = useSelector((state) => state.auth.callAct);
  const rol = useSelector((state) => state.auth.roles);
  const userToken = useSelector((state) => state.auth.token);
  const dataProfileDetails = useSelector((state) => state.data.dataProfile);
  const [saveFileDataField, setSaveFileDataField] = useState(
    dataProfileDetails && dataProfileDetails.avatar
      ? dataProfileDetails.avatar.data
      : null
  );
  const [taskToEdit, setTaskToEdit] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [countries, setContries] = useState();
  const [loading, setLoading] = useState(false);
  const lang = localStorage.getItem("i18nextLng");
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

  const hideAddTaskModal = () => {
    setShowModal(false);
    setTaskToEdit(null);
  };

  useEffect(() => {
    dispatch(callAct(true));
    axios.get(`${env.api.url}/v1/countries`).then((response) => {
      setContries(response.data.data);
    });
  }, []);

  const changePassword = (values) => {
    axios
      .post(`${env.api.url}/v1/auth/change-password`, values, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        if (response.data.errorCode === "INVALID_PASSWORD_FORMAT") {
          message.error(t("paswd.format"));
        }else if (response.data.errorCode ===  "SESSION_TOKEN_EXPIRED") {
          message.error("paswd.session");
        }else {
          message.success(t("account.password"));

        }



      });
    setShowModal(false);
  };

  const eliminateFieldInvalidate = (obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      if (value === "") {
        return (obj[key] = null);
      } else if (obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      }
    });
  };

  const onFinish = (values) => {
    if (
      values.birthdate !== "Invalid date" &&
      values.birthdate !== null &&
      values.birthdate !== ""
    ) {
      values.birthdate = dayjs(values.birthdate, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      );
    } else if (values.birthdate === null) {
      values.birthdate = null;
    } else {
      values.birthdate = "";
    }

    eliminateFieldInvalidate(values);

    axios
      .post(`${env.api.url}/v1/user/update-profile`, values, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        if (response.data.success) {
          message.success(t("account.dataChanges"));
          if (rol === "friend") {
            dispatch(changeRol("client"));
          }
          dispatch(dataProfile(userToken));

          navigate("/dashboard-client");
        } else if (response.data.errorCode === "FRIENDNAME_ALREADY_IN_USE") {
          message.error(t("account.dataPersonalUser"));
        } else {
          message.error(t("account.dataChangesNot"));
        }
      });
  };

  const uploadProps = {
    beforeUpload: (file) => {
      return new Promise(() => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64File = reader.result.split(",")[1];
          setLoading(true);
          axios
            .post(
              `${env.api.url}/v1/user/change-avatar`,
              { avatar: base64File },
              {
                headers: {
                  Authorization: `Bearer ${userToken}`,
                },
              }
            )
            .then((response) => {
              if (response.data.success) {
                message.success(t("myAccount.updatedImage"));
                dispatch(callAct(!changeCallAct));
                dispatch(dataProfile(userToken));
                setLoading(false);
              } else {
                message.error(t("myAccount.notUpdatedImage"));
                dispatch(callAct(!changeCallAct));
                setLoading(false);
              }
            });
          setSaveFileDataField(base64File);
        };
      });
    },
  };
  const showSendMessagesModal = () => {
    setShowModal(true);
  };

  let momentAge;

  if (
    dataProfileDetails &&
    dataProfileDetails.birthdate &&
    rol !== "realestate"
  ) {
    if (
      dayjs(dataProfileDetails.birthdate).format("DD-MM-YYYY") !==
        "Fecha inválida" ||
        dayjs(dataProfileDetails.birthdate).format("DD-MM-YYYY") !==
        "Invalid date"
    ) {
      momentAge = dayjs(dataProfileDetails.birthdate).format("DD-MM-YYYY");
    }
    if (
      dayjs(dataProfileDetails.birthdate).format("DD-MM-YYYY") ===
        "Fecha inválida" ||
      dayjs(dataProfileDetails.birthdate).format("DD-MM-YYYY") ===
        "Invalid date"
    ) {
      momentAge = dataProfileDetails.birthdate;
    }
  } else {
    momentAge = null;
  }

  const validateMessages = {
    required: "${label}" + " " + t("form.formRequired"),
    types: {
      email: t("form.emailValidated"),
      number: "${label} is not a valid number!",
    },
    number: {
      range: t("form.maxBudgetNewRequestValidated") + "${min}",
    },
  };
  const openNotificationWithIcon = () => {
    notificationGibobs(
      "error",
      t("m_full.errorTitle"),
      t("form.incompleteInformation")
    );
  };

  return (
    <Layout>
      {rol !== "realestate" && (
        <Breadcrumb style={{ margin: isMobile ? "24px 16px" : "24px 40px" }}>
          <Breadcrumb.Item>
            <NavLink to="/dashboard-client">
              <Text style={{ color: "#748EA0", fontSize: "12px" }}>
                {t("menuComponent.initNav")}
              </Text>
            </NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Text strong style={{ color: "#02C3CD", fontSize: "12px" }}>
              {t("init.account")}
            </Text>
          </Breadcrumb.Item>
        </Breadcrumb>
      )}
      {rol !== "realestate" ? (
        <div style={{ height: "auto", marginBottom: "100px" }}>
          <Card
            className="CardStyleClientGibobs"
            style={{
              marginLeft: isMobile ? "16px" : "40px",
              width: isMobile ? "calc(100% - 32px)" : "calc(100% - 80px)",
            }}
          >
            <div style={{ width: "100%" }}>
              <Text strong style={{ height: "18px", marginRight: "32px" }}>
                {t("form.personalAccount")}
              </Text>
            </div>

            <Form
              name="nest-messages"
              onFinish={onFinish}
              onFinishFailed={openNotificationWithIcon}
              layout="vertical"
              validateMessages={validateMessages}
              initialValues={
                dataProfileDetails
                  ? { ...dataProfileDetails, birthdate: momentAge }
                  : {}
              }
              style={{
                marginLeft: isMobile ? 0 : "10%",
                marginRight: isMobile ? 0 : "10%",
                marginTop: "24px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexFlow: "column",
                  alignItems: "center",
                }}
              >
                <Upload {...uploadProps}>
                  <Avatar
                    size={{
                      xs: 80,
                      lg: 80,
                      xl: 114,
                      xxl: 130,
                    }}
                    icon={<UserOutlined />}
                    src={
                      loading ? (
                        <Spin indicator={antIcon} className="spin" />
                      ) : saveFileDataField !== null ? (
                        <Image
                          preview={false}
                          style={{ cursor: "pointer" }}
                          src={`data:image/png;base64,${saveFileDataField}`}
                          height={"100%"}
                          width={"100%"}
                        />
                      ) : (
                        <Image
                          src={AvatarGenerico2}
                          preview={false}
                          style={{ cursor: "pointer" }}
                        />
                      )
                    }
                  />
                </Upload>

                <Button
                  type="dashed"
                  style={{
                    padding: "0",
                    marginTop: "30px",
                    marginBottom: "60px",
                    height: "24px",
                    width: isMobile ? "100%" : "136px",
                    fontSize: "12px",
                    color: "#02C3CD",
                    fontWeight: 500,
                    borderRadius: "4px",
                  }}
                  onClick={showSendMessagesModal}
                >
                  {t("form.changePassword")}
                </Button>
              </div>

              <Row gutter={24}>
                <Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
                  <Form.Item
                    name="name"
                    label={t("form.name")}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("form.name")}
                      style={{
                        width: "100%",
                        height: "32px",
                        borderRadius: "4px",
                        fontSize: "12px",
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
                  <Form.Item
                    name="surname"
                    label={t("form.surname")}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("form.surname")}
                      style={{
                        width: "100%",
                        height: "32px",
                        borderRadius: "4px",
                        fontSize: "12px",
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
                  <Form.Item
                    name="phone"
                    label={t("form.phone")}
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("form.phone")}
                      style={{
                        width: "100%",
                        height: "32px",
                        borderRadius: "4px",
                        fontSize: "12px",
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
                  <Form.Item name="email" label={t("form.email")}>
                    <Input
                      disabled={true}
                      placeholder={t("form.email")}
                      style={{
                        width: "100%",
                        height: "32px",
                        borderRadius: "4px",
                        borderColor: "#C0DAEE",
                        fontSize: "12px",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
                  <Form.Item name="nif" label={t("form.identification")}>
                    <Input
                      placeholder={t("form.identification")}
                      style={{
                        width: "100%",
                        height: "32px",
                        borderRadius: "4px",
                        fontSize: "12px",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
                  <Form.Item
                    name="birthdate"
                    label={t("form.birthdate")}
                    // rules={[
                    //   {
                    //     required: true,
                    //     validator: (e, value, callback) => {
                    //       return new Promise((resolve, reject) => {
                    //         if (!dayjs(value, "YYYY-MM-DD").isValid()) {
                    //           reject(
                    //             t("form.birthdate") +
                    //               " " +
                    //               t("form.formRequired")
                    //           );
                    //         } else {
                    //           resolve();
                    //         }
                    //       });
                    //     },
                    //   },
                    // ]}
                  >
                    <InputDate
                      type="date"
                      // defaultValue={(momentAge !== undefined && momentAge !== null) ?  momentAge : ""}
                      style={{
                        width: "100%",
                        height: "32px",
                        borderRadius: "4px",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
                  <Form.Item
                    name="maritalStatus"
                    label={t("form.maritalStatus")}
                  >
                    <Select
                      placeholder={t("form.maritalStatus")}
                      style={{
                        width: "100%",
                        height: "32px",
                        fontSize: "12px",
                      }}
                    >
                      {renderComponent("maritalStatus").options.map(
                        (maritalStatus) => {
                          return (
                            <Option
                              key={maritalStatus.value}
                              value={maritalStatus.value}
                            >
                              {t(maritalStatus.label)}
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
                  <Form.Item name="nationality" label={t("form.nationality")}>
                    <Select
                      placeholder={t("form.nationality")}
                      showSearch
                      style={{
                        width: "100%",
                        height: "32px",
                        fontSize: "12px",
                      }}
                      dropdownStyle={{ color: "red" }}
                    >
                      {countries &&
                        countries.countries.map((paises) => {
                          return (
                            <Option key={paises.code} value={paises.code}>
                              {lang === "en" ? paises.name_en : paises.name_es}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
                  <Form.Item name="country" label={t("form.country")}>
                    <Select
                      placeholder={t("form.country")}
                      showSearch
                      style={{
                        width: "100%",
                        height: "32px",
                        fontSize: "12px",
                      }}
                      filterOption={(input, option) => {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          option.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {countries &&
                        countries.countries.map((paises) => {
                          return (
                            <Option key={paises.code} value={paises.code}>
                              {lang === "en" ? paises.name_en : paises.name_es}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
                  <Form.Item
                    name="contactChannel"
                    label={t("form.contactChannel")}
                  >
                    <Select
                      style={{
                        width: "100%",
                        height: "32px",
                        fontSize: "12px",
                      }}
                    >
                      <Option value="phone">{t("form.phone")}</Option>
                      <Option value="email">{t("form.email")}</Option>s
                      <Option value="whatsapp">Whatsapp</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
                  <Form.Item name="contactTime" label={t("form.contactTime")}>
                    <Select
                      style={{
                        width: "100%",
                        height: "32px",
                        fontSize: "12px",
                      }}
                    >
                      <Option value="morning">{t("form.morning")}</Option>
                      <Option value="afternoon">{t("form.afternoon")}</Option>
                      <Option value="evening">{t("form.evening")}</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
                  <Form.Item name="bankAccount" label={t("form.numberBank")}>
                    <Input
                      placeholder={t("ES00 0000 0000 0000 0000")}
                      style={{
                        width: "100%",
                        height: "32px",
                        borderRadius: "4px",
                        borderColor: "#C0DAEE",
                        fontSize: "12px",
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
                  <Form.Item
                    name="friendName"
                    label={t("amigobs.code")}
                    // rules={[
                    //   {
                    //     required: true,
                    //     validator: (e, value, callback) => {
                    //       return new Promise((resolve, reject) => {
                    //         let match = [];
                    //         if (value.match(/^[\-a-zA-Z1-9]+$/g)) {
                    //           match = value.match(/^[\-a-zA-Z1-9]+$/g);
                    //         }
                    //         if (match === null) {
                    //           reject("Formato inválido");
                    //         }
                    //         if (!value.match(/^[\-a-zA-Z1-9]+$/g)) {
                    //           reject("Formato inválido");
                    //         } else {
                    //           resolve();
                    //         }
                    //       });
                    //     },
                    //   },
                    // ]}
                  >
                    <Input
                      style={{
                        width: "100%",
                        height: "32px",
                        borderRadius: "4px",
                        borderColor: "#C0DAEE",
                        fontSize: "12px",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
                  <Form.Item>
                    <ChangePassword
                      key={taskToEdit}
                      visible={showModal || taskToEdit}
                      onCreate={changePassword}
                      onCancel={hideAddTaskModal}
                      width={800}
                      taskToEdit={taskToEdit}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Button
                type="primary"
                style={{
                  color: "#FFFFFF",
                  margin: "auto",
                  marginBottom: "62px",
                  display: "block",
                  height: "32px",
                  width: isMobile ? "100%" : "96px",
                  fontSize: "14px",
                  borderRadius: "4px",
                }}
                htmlType="submit"
              >
                {t("form.save")}
              </Button>
            </Form>
          </Card>
        </div>
      ) : (
        <CompanySpace />
      )}
    </Layout>
  );
};

export default PersonalSpace;
