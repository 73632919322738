import React, { useState, useEffect } from 'react';
import { Row, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import env from '../../environment';

const LayoutOperations = () => {
	const location = useLocation();
	const { t } = useTranslation();
	const [myLoan, setMyLoan] = useState(undefined);
	const userToken = useSelector((state) => state.auth.token);

	const styleTextTab = {
		fontSize: 14,
		fontWeight: 600,
		color: 'white',
		backgroundColor: '#02C3CD',
		borderRadius: '4px',
		border: 'none',
		height: 32,
		textAlign: 'center'
	};

	useEffect(async () => {
		const response2 = await axios.get(`${env.api.url}/v1/loans/get-loans-list`, {
			headers: { Authorization: `Bearer ${userToken}` }
		});

		if (response2.data.data.loans.length > 0) {
			const response = await axios.get(
				`${env.api.url}/v1/loans/get-loan/${response2.data.data.loans[0].id}`,
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			);
			setMyLoan(response.data.data);
		}
	}, []);

	return (
		<Row gutter={[24, 24]} style={{ marginLeft: 0, marginRight: 0 }}>
			<div className="newSubmenusTabs" style={{ width: '281px' }}>
				<div
					style={{
						background: '#EFFCFC',
						borderRadius: 5,
						borderBottom: 'none',
						borderBottomColor: 'white',
						display: 'flex',
						alignItems: 'center',
						overflowY: 'scroll',
						overflowX: 'none',
						padding: '8px 0'
					}}
					className="scroolHorizontal"
					selectedKeys={[`${location.pathname}${location.search}`]}
					mode="horizontal">
					<NavLink
						to={
							myLoan !== undefined
								? '/my-financial-profile/credits/list'
								: '/my-financial-profile/credits'
						}>
						<Button
							className="buttonOperations"
							style={
								location.pathname === '/my-financial-profile/credits' ||
								location.pathname === '/my-financial-profile/credits/list' ||
								location.pathname.includes('/my-financial-profile/credits/form')
									? styleTextTab
									: undefined
							}>
							{t('loan.myMortgages')}
						</Button>
					</NavLink>

					<NavLink to="/my-financial-profile/balance">
						<Button
							className="buttonOperations"
							style={
								location.pathname === '/my-financial-profile/balance' ? styleTextTab : undefined
							}>
							{t('menuComponent.balanceNav')}
						</Button>
					</NavLink>

					<NavLink className="buttonOperations" to="/my-financial-profile/score-gibobs">
						<Button
							className="buttonOperations"
							style={
								location.pathname === '/my-financial-profile/score-gibobs'
									? styleTextTab
									: undefined
							}>
							Scoring
						</Button>
					</NavLink>
				</div>
			</div>
		</Row>
	);
};

export default LayoutOperations;
