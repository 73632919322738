import React, { useState, useEffect } from "react";
import {
  Tabs,
  Button,
  Badge,
  Upload,
  Progress,
  Typography,
  Tooltip,
  Modal,
  message,
  notification,
  Form,
  Row,
  Input,
  Col,
} from "antd";
import "./document.css";
import { useTranslation } from "react-i18next";
import {
  FilePdfOutlined,
  PlusOutlined,
  DownloadOutlined,
  DeleteOutlined,
  CheckCircleFilled,
  ExclamationOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import axios from "axios";
import env from "../../../environment";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { trace } from "../../../components/tagmanager";
import { getAnalytics, logEvent } from "firebase/analytics";
import Afterbanks from "../../AfterBanks/Afterbanks";
import CountDown from "ant-design-pro/lib/CountDown";
import agencia from "../../../assets/Products/logo-agencia-tributaria.png";
import tesoreria from "../../../assets/Products/tesoreria-seguridad-social.png";
import bank from "../../../assets/Products/bank.png";
import countPin from "../../../assets/Products/countPin.png";
import LoaderLogo from "../../LoaderLogo";
//import ModalsDocumentation from "./ModalsDocumentation";
//import { FILE } from "dns";

const { TabPane } = Tabs;
const { Text } = Typography;
const { confirm } = Modal;

const Document = ({ userMortgageId, stage }) => {
  const { t } = useTranslation();
  const [saveFileDataField, setSaveFileDataField] = useState(null);

  const userToken = useSelector((state) => state.auth.token);

  const [valuesData, setValuesData] = useState([]);
  const [valuesDataForMortgage, setValuesDataForMortgage] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [, setOwner] = useState("");
  const [tags, setTags] = useState([]);
  const [clickTab, setClickTab] = useState("owner1");

  const [percentN, setPercentN] = useState("");

  const [visibleUploadModal, setVisibleUploadModal] = useState(false);
  const [visibleAfterBanksModal, setVisibleAfterBanksModal] = useState(false);
  const rol = useSelector((state) => state.auth.roles);

  const [nameOperations, setNameOperations] = useState(undefined);

  const location = useLocation();
  const analytics = getAnalytics();
  let countShowMessage = 0;
  const [visibleFormNbric, setVisibleFormNbric] = useState(false);
  const [form] = Form.useForm();
  const [visiblePin, setVisiblePin] = useState(false);
  const [visibleThanks, setVisibleThanks] = useState(false);
  const [visibleNotPin, setVisibleNotPin] = useState(false);
  const [visibleNotPinNot, setVisibleNotPinNot] = useState(false);
  const targetTime = new Date().getTime() + 300000;
  const [loading, setLoading] = useState(false);
  const lang = localStorage.getItem("i18nextLng");

  useEffect(() => {
    window.scrollTo(0, 0);
    dataValueInfoUpload();
    documentsRefresh();

    axios
      .get(`${env.api.url}/v1/mortgages/data/get/${userMortgageId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setParticipants(response.data.data);
      })
      .catch((error) => {
        console.log(error)
      });

    axios
      .get(`${env.api.url}/v1/operations/${userMortgageId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        setNameOperations(response.data.data);
      })
      .catch((error) => {
        console.log(error)
      });

    trace({
      location,
      userToken,
      rol,
      stage: "documents",
      operationName: nameOperations && nameOperations?.operationData?.name,
      //extra: operationsData
    });
  }, []);

  const sendKey = (values) => {
    axios
      .post(
        `${env.api.url}/v1/documents/nbric/confirm/otp`,
        {
          operationId: userMortgageId,
          owner: clickTab,
          otp: values.pin,
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        if (response.data.data.success) {
          setVisiblePin(!visiblePin);
          setVisibleThanks(!visibleThanks);
        } else {
          setVisibleNotPinNot(!visibleNotPinNot);
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const documentsRefresh = () => {
    axios
      .get(
        `${env.api.url}/v1/documents/request-operation-documentation/${userMortgageId}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        response.data.data.documents = _.orderBy(
          response.data.data.documents,
          [`participants[${clickTab}]`, "required"],
          ["desc", "asc"]
        );

        setValuesDataForMortgage(response.data.data);
        setPercentN(
          (response.data.data.documentsCompleted * 100) /
            response.data.data.documentsNeeded
        );
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const dataValueInfoUpload = () => {
    axios
      .get(
        `${env.api.url}/v1/documents/get-operation-documents/${userMortgageId}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        const unsorted = response.data.data;

        const sorted = unsorted
          .sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          )
          .reverse();

        setValuesData(sorted);
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const saveFileData = (file, name) => {
    const documentFile = [saveFileDataField];
    documentFile.push({
      file,
      fileName: name,
    });
    setSaveFileDataField(documentFile);
    handleUploadFile(documentFile);
  };

  const fileType = {
    "application/vnd.ms-excel": true,
    "application/msexcel": true,
    "application/x-msexcel": true,
    "application/x-ms-excel": true,
    "application/x-excel": true,
    "application/x-dos_ms_excel": true,
    "application/xls": true,
    "application/x-xls": true,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.ms-excel": true,
    "application/msexcel": true,
    "application/x-msexcel": true,
    "application/x-ms-excel": true,
    "application/x-excel": true,
    "application/x-dos_ms_excel": true,
    "application/xls": true,
    "application/x-xls": true,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": true,
    "text/csv": true,
  };

  const uploadProps = {
    beforeUpload: (file) => {
      return new Promise(() => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        if (file.size < 50000000 && !fileType[file.type]) {
          reader.onload = () => {
            const base64File = reader.result.split(",")[1];
            saveFileData(base64File, file.name);
          };
        } else if (fileType[file.type]) {
          notification.error({
            message: "Problema al subir archivo",
            description: t("document.NoSupportThisDocuments"),
          });
        } else {
          notification.error({
            message: "Problema al subir archivo",
            description:
              "Documento demasiado pesado, debe subir documentos que no superen los 50MB por seguridad.",
          });
        }
      });
    },
  };

  const handleFileDownload = (documentId) => {
    axios
      .get(
        `${env.api.url}/v1/documents/get-document-url/${documentId}?token=${userToken}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then((response) => {
        if (response.data.success) {
          window.open(response.data.data.url, "_blank"); //to open new page
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const buttonStyles = (documentId, item) => {
    return (
      <div
        className="gibobs-document-button-overlay"
        style={{
          position: "absolute",
          width: 104,
          height: 104,
          top: 0,
          left: 0,
          backgroundColor: "#02C3CD",
          lineHeight: 10,
          borderRadius: "4px",
        }}
      >
        {rol !== "realestate" &&
          item.type === null &&
          item.page === null &&
          item.owner && (
            <>
              <Button
                type="text"
                size="small"
                onClick={() => {
                  modalDocument(documentId);
                }}
              >
                <DeleteOutlined style={{ color: "white" }} />
              </Button>

              <Button
                type="text"
                size="small"
                onClick={() => {
                  handleFileDownload(documentId);
                }}
              >
                <DownloadOutlined style={{ color: "white" }} />
              </Button>
            </>
          )}

        {rol !== "realestate" &&
          item.type !== null &&
          item.page !== null &&
          item.owner && (
            <Button
              type="text"
              size="small"
              onClick={() => {
                handleFileDownload(documentId);
              }}
            >
              <DownloadOutlined style={{ color: "white" }} />
            </Button>
          )}
      </div>
    );
  };

  const deleteDocument = (documentId) => {
    axios
      .get(`${env.api.url}/v1/documents/delete-document/${documentId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then(() => {
        dataValueInfoUpload();
      })
      .catch((error) => {
        console.log(error)
      });
  };
  const modalDocument = (documentId) => {
    confirm({
      className: "ModalCommonConfirm",
      title: "Borrar documento",
      icon: null,
      content: "¿Seguro que deseas borrar este documento?",
      okText: "Borrar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteDocument(documentId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const renderDocumentInformation = (index, type) => {
    const data = valuesData.filter((item) => {
      let documentFlag = false;

      if (item.owner === `${type}${index}`) {
        documentFlag = true;
      } else if (type === "owner" && index === 1 && !item.owner) {
        documentFlag = true;
      }

      return documentFlag;
    });

    const buttonsAutomaticDocs = () => {
      return (
        <>
          <div
            className="PlusButton"
            style={{
              whiteSpace: "nowrap",
              width: "88px",
              float: "left",
              height: "120px",
              marginRight: "15px",
              marginTop: "10px",
            }}
          >
            <Button
              onClick={
                nameOperations &&
                nameOperations.operationData &&
                nameOperations.operationData[`${clickTab}`] &&
                nameOperations.operationData[`${clickTab}`]
                  .automaticDocOTPCompleted !== undefined &&
                nameOperations.operationData[`${clickTab}`]
                  .automaticDocOTPCompleted === true
                  ? undefined
                  : () => setVisibleFormNbric(!visibleFormNbric)
              }
              className="gibobs-document-button"
              style={{
                width: "104px",
                height: "104px",
                backgroundColor:
                  nameOperations &&
                  nameOperations.operationData &&
                  nameOperations.operationData[`${clickTab}`] &&
                  nameOperations.operationData[`${clickTab}`]
                    .automaticDocOTPCompleted !== undefined &&
                  nameOperations.operationData[`${clickTab}`]
                    .automaticDocOTPCompleted === true
                    ? "#C0DAEE"
                    : "#02C3CD",
                borderRadius: "4px",
                borderColor:
                  nameOperations &&
                  nameOperations.operationData &&
                  nameOperations.operationData[`${clickTab}`] &&
                  nameOperations.operationData[`${clickTab}`]
                    .automaticDocOTPCompleted !== undefined &&
                  nameOperations.operationData[`${clickTab}`]
                    .automaticDocOTPCompleted === true
                    ? "#C0DAEE"
                    : "#02C3CD",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginTop: 8,
                  marginLeft: 8,
                  marginBottom: -25,
                }}
              >
                <img
                  src={agencia}
                  alt="asf"
                  style={{ width: 30, height: 24 }}
                ></img>
                <img
                  src={tesoreria}
                  alt="asdf"
                  style={{ width: 30, height: 24 }}
                ></img>
              </div>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                  width: "72px",
                  height: "54px",
                  whiteSpace: "normal",
                  marginLeft: "0px",
                  color: "white",
                  marginTop: 30,
                }}
              >
                {t("button.docOfficialNbric")}
              </Text>
            </Button>
          </div>
          <div
            className="PlusButton"
            style={{
              whiteSpace: "nowrap",
              width: "88px",
              float: "left",
              height: "120px",
              marginRight: "15px",
              marginTop: "10px",
              marginLeft: 12,
            }}
          >
            <Button
              onClick={() => setVisibleAfterBanksModal(!visibleAfterBanksModal)}
              className="gibobs-document-button"
              style={{
                width: "104px",
                height: "104px",
                backgroundColor: "#02C3CD",
                borderRadius: "4px",
                borderColor: "#02C3CD",
              }}
            >
              <div
                style={{
                  marginBottom: -25,
                }}
              >
                <img
                  src={bank}
                  alt="asf"
                  style={{ width: 24, height: 24 }}
                ></img>
              </div>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                  width: "72px",
                  height: "54px",
                  whiteSpace: "normal",
                  marginLeft: "0px",
                  color: "white",
                  marginTop: 30,
                }}
              >
                {t("button.docOfficialBanks")}
              </Text>
            </Button>
          </div>
        </>
      );
    };

    const files = data.map((item) => {
      return (
        <div
          style={{
            whiteSpace: "nowrap",
            marginLeft: "-15px",
            float: "left",
            marginRight: "25px",
            marginTop: "10px",
          }}
        >
          <Button
            className="gibobs-document-button"
            style={{
              width: "104px",
              height: "104px",
              backgroundColor: "white",
              borderRadius: "4px",
              borderColor:
                item.type !== null && item.page !== null && item.owner
                  ? "#52C41A"
                  : "#C0DAEE",
            }}
          >
            {item.type !== null && item.page !== null && item.owner && (
              <div
                style={{
                  float: "right",
                  marginRight: "-5px",
                }}
              >
                <CheckCircleFilled
                  style={{
                    fontSize: "10px",
                    color: "#52C41A",
                  }}
                />
              </div>
            )}
            <div
              style={{
                marginBottom: "20px",
                marginTop: "10px",
                // marginLeft: "8px",
              }}
            >
              <FilePdfOutlined
                style={{
                  color: "#748EA0",
                  fontSize: "20px",
                }}
              />
            </div>
            <div
              className="textButton"
              style={{
                width: "72px",
                height: "54px",
                fontSize: "10px",
                whiteSpace: "normal",
                marginLeft: "0px",
                marginTop: "-20px",
              }}
            >
              {buttonStyles(item.id, item)}
              <div
                style={{
                  width: "100%",
                  height: "30px",
                  overflowWrap: "break-word",
                  marginBottom: "-7px",
                }}
              >
                {item.name.substr(0, 25)}...
              </div>
            </div>
          </Button>
        </div>
      );
    });

    return (
      <div style={{ marginLeft: "15px" }}>
        {files}
        <div
          className="PlusButton"
          style={{
            whiteSpace: "nowrap",
            width: "88px",
            float: "left",
            height: "120px",
            marginRight: "12px",
            marginTop: "10px",
          }}
        >
          <Upload multiple={true} {...uploadProps}>
            <Button
              type="dashed"
              onClick={() => handleClickPlus(clickTab)}
              className="buttonAttach"
              style={{
                borderColor: "#C0DAEE",
              }}
              onClickCapture={() =>
                logEvent(analytics, "select_content", {
                  content_type: "operation.documents",
                  content_id: "attach.document.button",
                })
              }
            >
              <div style={{ marginBottom: "20px", marginTop: "10px" }}>
                <PlusOutlined style={{ color: "#02C3CD", fontSize: "30px" }} />
              </div>
              <div
                className="textButton"
                style={{
                  width: "72px",
                  height: "34px",
                  fontSize: "12px",
                  whiteSpace: "break-spaces",
                  marginLeft: "0px",
                  marginTop: "-20px",
                  color: "#2F4858",
                }}
              >
                {t("document.attach")}
              </div>
            </Button>
          </Upload>
        </div>

        {stage === "documents" && buttonsAutomaticDocs()}
      </div>
    );
  };

  const notificationB = () => {
    
    return (
      <div style={{ float: "right", marginTop: "1px", marginRight: "-5px" }}>
        <Badge
          size="small"
          dot={true}
          style={{
            float: "right",
            width: "4px",
            height: "6px",
            marginLeft: "4px",
          }}
        ></Badge>
      </div>
    );
  };

  const handleUploadFile = (file) => {
    setLoading(true);
    axios
      .put(
        `${env.api.url}/api/mortgages/attachment`,
        {
          mortgageId: userMortgageId,
          file: file[1].file,
          fileName: file[1].fileName,
          owner: clickTab,
          tags: tags,
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then(() => {
        setLoading(false);
        dataValueInfoUpload();

        if (countShowMessage === 0) {
          message.success(t("document.message"));
          countShowMessage = 1;
        }
      })
      .catch((error) => {
        console.log(error)
        setLoading(false);
        notification.error({
          message: t("document.waitUploadError"),
          description: t("document.failedUpload"),
        });
      });
    countShowMessage = 0;
  };

  const handleClickPlus = (idowner) => {
    let array = [idowner];
    if (idowner === "buy") {
      setTags(array);
      setOwner("owner1");
    } else {
      setOwner(idowner);
    }
  };



  const handleGetDocuments = () => {
    setVisibleUploadModal(true);
    setVisibleAfterBanksModal(false);
    dataValueInfoUpload();
  };

  const renderTabs = (index, type) => {
    let requireFlag = false;

    if (valuesDataForMortgage.documents) {
      valuesDataForMortgage.documents.forEach((data) => {
        if (data.completed[`${type}${index}`] === false) {
          if (data.participants[`${type}${index}`] === "required") {
            requireFlag = true;
          }
        }
      });
    }

    return (
      <TabPane
        tab={
          <span>
            {requireFlag ? notificationB() : null}

            {t(`mydocuments.${type}${index}`)}
          </span>
        }
        key={`${type}${index}`}
      >
        <div style={{ color: "#748EA0", fontSize: 12, fontWeight: 400 }}>
          {t("document.NoSupportThisDocuments")}
        </div>
        {renderDocumentInformation(index, type)}
      </TabPane>
    );
  };

  const numParticipants = parseInt(
    _.get(participants, "mortgage.participants", 0)
  );
  const numGuarantors = parseInt(_.get(participants, "mortgage.guarantors", 0));

  const documentTabs = [];

  for (let i = 1; i <= numParticipants; i++) {
    documentTabs.push(renderTabs(i, "owner"));
  }

  for (let i = 1; i <= numGuarantors; i++) {
    documentTabs.push(renderTabs(i, "guarantor"));
  }

  const getDocumentInfoByDownlands = (title) => {
    if (title.includes("bankreader") && lang === "es") {
      window.open(
        "https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/extractos_bancarios_es.pdf",
        "_blank"
      );
    } else if (title.includes("bankreader") && lang === "en") {
      window.open(
        "https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/extractos_bancarios_en.pdf",
        "_blank"
      );
    } else if (title.includes("bankreader") && lang === "cat") {
      window.open(
        "https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/extractos_bancarios_cat.pdf",
        "_blank"
      );
    } else if (title.includes("simpleNote") && lang === "es") {
      window.open(
        "https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/solicitud_nota_simple_es.pdf",
        "_blank"
      );
    } else if (title.includes("simpleNote") && lang === "en") {
      window.open(
        "https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/solicitud_nota_simple_en.pdf",
        "_blank"
      );
    } else if (title.includes("simpleNote") && lang === "cat") {
      window.open(
        "https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/solicitud_nota_simple_cat.pdf",
        "_blank"
      );
    } else if (title.includes("irpf") && lang === "es") {
      window.open(
        "https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/solicitud_irpf_es.pdf",
        "_blank"
      );
    } else if (title.includes("irpf") && lang === "en") {
      window.open(
        "https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/solicitud_irpf_en.pdf",
        "_blank"
      );
    } else if (title.includes("irpf") && lang === "cat") {
      window.open(
        "https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/solicitud_irpf_cat.pdf",
        "_blank"
      );
    } else if (title.includes("laboralLife") && lang === "es") {
      window.open(
        "https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/vida_laboral_es.pdf",
        "_blank"
      );
    } else if (title.includes("laboralLife") && lang === "en") {
      window.open(
        "https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/vida_laboral_en.pdf",
        "_blank"
      );
    } else if (title.includes("laboralLife") && lang === "cat") {
      window.open(
        "https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/vida_laboral_cat.pdf",
        "_blank"
      );
    } else {
      window.open(
        "https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/vida_laboral_es.pdf",
        "_blank"
      );
    }
  };



  if (loading) {
    return (
      <div
        style={{
          textAlign: "center",
          height: "80vh",
          backgroundColor: "white",
        }}
      >
        <LoaderLogo />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            top: "45%",
          }}
        >
          <Text style={{ fontSize: 14, fontWeight: 400, color: "#4D4D4D" }}>
            {t("document.waitUpload") + "..."}
          </Text>
        </div>
      </div>
    );
  }

  return (
    (<div
      style={{
        margin: rol === "realestate" ? "100px auto auto" : "30px 15px auto",
        marginTop:
          stage === "analysis" ? -60 : stage === "oferts" ? 0 : "130px",
      }}
    >
      {stage === "documents" && (
        <>
          <div
            className="ProgressBar"
            style={{
              width: "72px",
              height: "72px",
              float: "right",
              marginRight: "2px",
              marginTop: "-120px",
            }}
          >
            <Progress
              type="circle"
              percent={
                percentN === "100" || percentN === ""
                  ? percentN
                  : percentN.toFixed()
              }
              width="72px"
              strokeColor="#02C3CD"
            />
            <Text
              strong
              style={{ fontSize: 12, marginLeft: 10, marginTop: 10 }}
            >
              {t("document.checked")}
            </Text>
          </div>
        </>
      )}
      {rol === "realestate" && (
        <div style={{ marginTop: "-50px", marginBottom: "80px" }}>
          <Text style={{ fontSize: "12px" }}>
            {/*t("details.info")*/}
            <div style={{ width: "550px" }}>
              Tu cliente todavía tiene documentación pendiente. Puedes ayudarnos
              y subir su documentación si tú la tienes disponible{" "}
            </div>
          </Text>
        </div>
      )}
      <Tabs
        type="card"
        style={{
          color: "#2F4858",
          fontStyle: "normal",
          fontWeight: "normal",
          marginTop: stage === "banks" ? -60 : undefined,
        }}
        onChange={(values) => setClickTab(values)}
        activeKey={clickTab}
      >
        {documentTabs}
      </Tabs>
      <div className="dark">
        <div
          style={{
            marginTop: "40px",
            marginBottom: "50px",
            color: "#2F4858",
            fontSize: "12px",
          }}
        >
          {participants[`${clickTab}`] !== undefined &&
            t("document.fileUpload") + " "}
          {participants[`${clickTab}`] !== undefined && (
            <span style={{ color: "#02C3CD", fontWeight: 600 }}>
              {participants[`${clickTab}`].name}
            </span>
          )}
        </div>

        <div
          style={{
            width: "100%",
            height: "auto",
            fontSize: "12px",
            backgroundColor: "white",
            marginTop: "-26px",
            marginLeft: "-5px",
            float: "left",
            color: "#748EA0",
          }}
        >
          {valuesDataForMortgage.documents
            ? valuesDataForMortgage.documents.map((data) => {
                return (
                  <>
                    {data.completed[clickTab] === false ? (
                      <div>
                        <div
                          style={{
                            marginLeft: "5px",
                            fontWeight:
                              data.participants[clickTab] === "required"
                                ? "600"
                                : "400",
                            color: "#2F4858",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {stage !== "documents" &&
                          (data.participants[clickTab] === "required" ||
                            data.participants[clickTab] === "optional")
                            ? t(`documentTypes.${data.name}`, data.title)
                            : null}

                          {stage === "documents" &&
                          data.participants[clickTab] === "required"
                            ? t(`documentTypes.${data.name}`, data.title)
                            : null}

                          {stage !== "documents" &&
                          (data.participants[clickTab] === "required" ||
                            data.participants[clickTab] === "optional") ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {(data.name.includes("irpf") ||
                                data.name.includes("laboralLife") ||
                                data.name.includes("bankreader") ||
                                data.name.includes("simpleNote")) && (
                                <div
                                  style={{
                                    color: "#02C3CD",
                                    fontSize: 14,
                                    fontWeight: 600,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    getDocumentInfoByDownlands(data.name)
                                  }
                                >
                                  {t("myDocuments.howDoIGetIt")}
                                </div>
                              )}
                              {data.participants[clickTab] === "required" && (
                                <div>
                                  <Tooltip
                                    placement="right"
                                    title={t("document.required")}
                                    color={"#2F4858"}
                                    overlayClassName="toolTipProperty"
                                  >
                                    <ExclamationOutlined
                                      style={{
                                        color: "#2F4858",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                          ) : undefined}

                          {stage === "documents" &&
                          data.participants[clickTab] === "required" ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {(data.name.includes("irpf") ||
                                data.name.includes("laboralLife") ||
                                data.name.includes("bankreader") ||
                                data.name.includes("simpleNote")) && (
                                <div
                                  style={{
                                    color: "#02C3CD",
                                    fontSize: 14,
                                    fontWeight: 600,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    getDocumentInfoByDownlands(data.name)
                                  }
                                >
                                  {t("myDocuments.howDoIGetIt")}
                                </div>
                              )}
                              {data.participants[clickTab] === "required" && (
                                <div>
                                  <Tooltip
                                    placement="right"
                                    title={t("document.required")}
                                    color={"#2F4858"}
                                    overlayClassName="toolTipProperty"
                                  >
                                    <ExclamationOutlined
                                      style={{
                                        color: "#2F4858",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                          ) : undefined}
                        </div>

                        {stage !== "documents" &&
                        (data.participants[clickTab] === "required" ||
                          data.participants[clickTab] === "optional") ? (
                          <div
                            style={{
                              borderTop: "1px solid #F1F7F8",
                              width: "100%",
                              marginBottom: 5,
                              marginTop: 5,
                              overflow: "hidden",
                            }}
                          ></div>
                        ) : null}

                        {stage === "documents" &&
                        data.participants[clickTab] === "required" ? (
                          <div
                            style={{
                              borderTop: "1px solid #F1F7F8",
                              width: "100%",
                              marginBottom: 5,
                              marginTop: 5,
                              overflow: "hidden",
                            }}
                          ></div>
                        ) : null}
                      </div>
                    ) : null}
                  </>
                );
              })
            : null}
        </div>
      </div>
      <Modal
        title={t("nbric.documentsTitle2")}
        className="ModalCommon"
        width={1148}
        cancelButtonProps={{
          className: "button-secundary-gibobs",
          style: { width: 197, position: "absolute", bottom: 32, left: 360 },
        }}
        okButtonProps={{
          className: "button-primari-gibobs",
          style: { display: "none" },
        }}
        cancelText={t("form.back")}
        open={visibleFormNbric}
        onCancel={() => setVisibleFormNbric(!visibleFormNbric)}
        // onOk={() => setVisibleFormNbric(!visibleFormNbric)}
        destroyOnClose={true}
        closable={true}
      >
        <div style={{ marginTop: -34, marginBottom: -10 }}>
          <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 400 }}>
            {t("nbric.documentsDecForm")}
          </Text>
        </div>
      
      </Modal>
      <Modal
        title={
          <Text style={{ color: "#2F4858", fontSize: 16, fontWeight: 600 }}>
            {t("nbric.notRegister")}
          </Text>
        }
        className="ModalCommon"
        width={480}
        cancelButtonProps={{
          className: "button-secundary-gibobs",
          style: { width: 197 },
        }}
        okButtonProps={{
          className: "button-primari-gibobs",
          style: { width: 197 },
        }}
        cancelText={t("form.back")}
        open={visibleNotPin}
        onCancel={() => setVisibleNotPin(!visibleNotPin)}
        onOk={() => setVisibleNotPin(!visibleNotPin)}
        destroyOnClose={true}
        closable={true}
      >
        <div style={{ marginTop: -30 }}>
          <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 400 }}>
            {t("nbric.notRegister2")}
          </Text>{" "}
          <a
            href="https://clave.gob.es/clave_Home/PIN24H/Obtencion-clave-pin.html"
            target="_blank"
          >
            <Text style={{ color: "#02C3CD", fontSize: 14, fontWeight: 400 }}>
              {
                "https://clave.gob.es/clave_Home/PIN24H/Obtencion-clave-pin.html"
              }
            </Text>
          </a>
        </div>
      </Modal>
      <Modal
        className="ModalCommonSmall"
        width={480}
        cancelButtonProps={{
          className: "button-secundary-gibobs",
          style: { width: "47%" },
        }}
        okButtonProps={{
          className: "button-primari-gibobs",
          style: { width: "48%", padding: 0 },
        }}
        open={visiblePin}
        title={
          <Text style={{ color: "#2F4858", fontSize: 16, fontWeight: 600 }}>
            {t("nbric.modal1titlte")}
          </Text>
        }
        okText={t("viability.confirmButton")}
        cancelText={t("form.back")}
        destroyOnClose={true}
        onCancel={() => {
          setVisiblePin(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              sendKey(values);
              form.resetFields();
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: -34,
            marginBottom: -20,
          }}
        >
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              src={countPin}
              alt="asdf"
              style={{ width: 176, heigh: 176, alignSelf: "center" }}
            ></img>

            <CountDown
              style={{ fontSize: 22, color: "#02C3CD", fontWeight: 600 }}
              target={targetTime}
            />
          </div>

          <Text
            style={{
              color: "#748EA0",
              fontSize: 14,
              fontWeight: 400,
              width: 400,
              marginTop: 20,
            }}
          >
            {t("nbric.modalPinDescTime")}
          </Text>
          <Text
            style={{
              color: "#748EA0",
              fontSize: 14,
              fontWeight: 400,
              width: 400,
              marginTop: 16,
            }}
          >
            {t("nbric.documentsDecPinMobile")}
          </Text>

          <Form
            form={form}
            layout="vertical"
            name="pin"
            style={{ marginTop: 16 }}
          >
            <Row gutter={24}>
              <Col lg={24} xs={24}>
                <Form.Item name="pin" label={""} style={{ width: "100%" }}>
                  <Input
                    placeholder={t("certificated.modalPin")}
                    style={{ width: "100%", height: 40 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
      <Modal
        className="ModalCommonSmall"
        width={480}
        cancelButtonProps={{
          className: "button-secundary-gibobs",
          style: { display: "none" },
        }}
        okButtonProps={{
          className: "button-primari-gibobs",
          style: { width: "96%" },
        }}
        open={visibleThanks}
        title={
          <Text style={{ color: "#2F4858", fontSize: 16, fontWeight: 600 }}>
            {t("form.thanks")}
          </Text>
        }
        okText={t("m_common.close")}
        destroyOnClose={true}
        onOk={() => setVisibleThanks(!visibleThanks)}
      >
        <div style={{ marginTop: -30, marginBottom: 10 }}>
          <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 400 }}>
            {t("form.nbricModalDescDocs")}
          </Text>
        </div>
      </Modal>
      <Modal
        className="ModalCommonSmall"
        width={480}
        cancelButtonProps={{
          className: "button-secundary-gibobs",
          style: { display: "none" },
        }}
        okButtonProps={{
          className: "button-primari-gibobs",
          style: { width: "96%" },
        }}
        open={visibleNotPinNot}
        title={
          <Text style={{ color: "#2F4858", fontSize: 16, fontWeight: 600 }}>
            {t("form.thanksNot")}
          </Text>
        }
        okText={t("m_common.close")}
        destroyOnClose={true}
        onOk={() => setVisibleNotPinNot(!visibleNotPinNot)}
      >
        <div style={{ marginTop: -30, marginBottom: 10 }}>
          <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 400 }}>
            {t("form.nbricModalDescDocsNot")}
          </Text>
        </div>
      </Modal>
      <Modal
        className="ModalDesktopCert"
        okButtonProps={{ style: { width: "100%" } }}
        okText={t("viability.continue")}
        cancelText={
          <Text style={{ color: "#02C3CD", fontSize: 14, fontWeight: 500 }}>
            {t("modal.cancelJump")}
          </Text>
        }
        open={visibleUploadModal}
        title={<Text>{t("certificated.modal3mobileTitle1")}</Text>}
        width={480}
        cancelButtonProps={{ style: { width: "100%" } }}
        onOk={() => {
          setVisibleAfterBanksModal(true);
          setVisibleUploadModal(false);
        }}
        onCancel={() => {
          setVisibleUploadModal(false);
          dataValueInfoUpload();
        }}
      >
        <div style={{ marginTop: 16 }}>
          <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 400 }}>
            {t("certificated.modal6mobileDesc1")}
          </Text>
        </div>
      </Modal>
      <Modal
        className="modalMobileAfterBanks"
        open={visibleAfterBanksModal}
        //  cancelText={<Text style={{ color: "#02C3CD", fontSize: 12, fontWeight: 500 }}>{t("offers.cancel")}</Text>}
        onCancel={() => setVisibleAfterBanksModal(false)}
        onOk={() => {
          handleGetDocuments();
        }}
        // okButtonProps={{ disabled: fileAfterbanks ? false : true }}
        cancelButtonProps={{
          style: { border: "none", contentVisibility: "hidden" },
        }}
        destroyOnClose={true}
        closable={true}
      >
        <Afterbanks type={"mortgage"} nameOwner={clickTab} />
      </Modal>
      {/*stage === "documents" && nameOperations && (
        <ModalsDocumentation
          userMortgageId={userMortgageId}
          clickTab={clickTab}
          mortgage={nameOperations}
          setValuesData={setValuesData}
          numParticipants={numParticipants}
          setClickTab={setClickTab}
        ></ModalsDocumentation>
      )*/}
    </div>)
  );
};

export default Document;
