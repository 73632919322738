import React from "react";
import { Steps } from "antd";
import { useTranslation } from "react-i18next";

const { Step } = Steps;

const StepPromoter = ({ data, status, width, denied, approved, promoter }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Steps
        // size="small"
        className={"gb-steps-promoter"}
        current={status}
        style={{ width: width, margin: promoter && "auto" }}
        direction={"horizontal"}
        responsive={false}
      >
        {data.map((infoSteps, i) => {
          return (
            <Step
              style={{ borderColor: "#52c41a" }}
              key={i}
              title=""
              status={i === 2 && denied && "error"}
              description={
                <div>
                  <div
                    style={{
                      marginLeft: i === 1 && status === 0 ? 0 : i !== 0 && -6,
                      fontSize: 12,
                      color: i == 2 && approved && "#52C41A",
                    }}
                  >
                    {i === 2 && denied ? "Denegada" : t(infoSteps.name)}
                  </div>
                </div>
              }
            />
          );
        })}
      </Steps>
    </div>
  );
};

export default StepPromoter;
